import React from "react";
import {Card} from "@/components/Card";
import {Table} from "@/components/table";
import {Label} from "@/components/primitive";
import router from "@/router";
import {SubjectClassMD} from "@/models/SubjectClass/SubjectClass";

export const SubjectClassDeclaration: React.FC<{ info: SubjectClassMD }> = x => {
    type Template = { [key: string]: string };
    const dataset: Template[] =  Array.from({ length:  1}, () => ({
        template: "Комплект устройств полимерных для удаления лейкоцитов и получения безлейкоцитных компонентов консервированной донорской крови стерильный",
        code: "32.50.13.190 Инструменты и приспособления, применяемые в медицинских целях, прочие, не включенные в другие группировки",
        date: "19.06.2019 21:39"
    }));

    return <Card>
        <Table<Template> dataset={dataset} columns={[
            Table.AutoColumn("Наименование СПГЗ", c =>
                <Label preset="readonlyValue" onClick={() => router.push("/")} style={{ textDecoration: "underline", cursor: "pointer" }}>
                    {c.item.template}
                </Label>),
            Table.AutoColumn("Код ОКПД 2", c =>
                <Label preset="readonlyValue">
                    {c.item.code}
                </Label>),
            Table.AutoColumn("Дата изменения", c =>
                <Label preset="readonlyValue">
                    {c.item.date}
                </Label>)
        ]}/>
    </Card>;
};