// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, "._38FQ_6f4Sl4HGlEPzC_kUw {\n    display: grid;\n    grid-template-columns: 1fr 500px;\n    grid-gap: 50px;\n    /*cursor: pointer; */\n    /*max-width: 1100px;*/\n    transition: all 0.1s ease;\n    position: relative;\n}\n\n._2nDiOFuR93nz3JfNyqbgrp {\n    width: 500px;\n    height: 330px;\n    display: flex;\n    align-items: center;\n    justify-content: center;\n    /*border: 1px solid #DFDFE0;*/\n    border-radius: 2px;\n}\n\n._2nDiOFuR93nz3JfNyqbgrp > img {\n    width: 500px;\n    height: 330px;\n}\n\n.k9SWEsu7_ayAcfX07onop {\n    max-width: 1100px;\n    min-width: 550px;\n    white-space: break-spaces;\n    font-size: 16px;\n    line-height: 20px;\n}\n\n._1bRNvnizaEYEni00b8lUhB {\n    font-size: 16px;\n    line-height: 20px;\n}\n\n@media screen and (max-width: 1250px) {\n    ._2nDiOFuR93nz3JfNyqbgrp {\n        width: 100%;\n        max-height: 330px;\n    }\n\n    ._2nDiOFuR93nz3JfNyqbgrp > img {\n        width: 100%;\n    }\n    ._38FQ_6f4Sl4HGlEPzC_kUw {\n        display: flex;\n        flex-direction: column-reverse;\n        /*grid-template-columns: 1fr;*/\n        gap: 30px;\n        max-width: unset;\n    }\n    .k9SWEsu7_ayAcfX07onop {\n        min-width: unset;\n        width: 100%;\n    }\n}\n", ""]);
// Exports
exports.locals = {
	"article": "_38FQ_6f4Sl4HGlEPzC_kUw",
	"image": "_2nDiOFuR93nz3JfNyqbgrp",
	"articleContent": "k9SWEsu7_ayAcfX07onop",
	"description": "_1bRNvnizaEYEni00b8lUhB"
};
module.exports = exports;
