












import CardModal from "@/components/CardModal.vue";
import DataTable from "@/components/vue/DataTable.vue";
import { EditType, ITableColumn } from "@/components/TableAbstractions";
import { ContractFinancialSource, ContractStage, ContractFinancialSourceUsage } from "@/models/Contracts";
import { FinancialSourceTypeStrings } from "@/models/enums";
import { Decimal } from "decimal.js";
import { Vue, Component, Prop } from "vue-property-decorator";
import { ContractScope } from './ContractScope';
import ContractFinancialSources from '../../api/ContractFinancialSources';
import { uuid } from "@/models/parsing";
import EventBus from "@/EventBus";
import ErrorModal from "@/views/CreateContract/modals/ErrorModal.vue";

const sourceName = ({ source, account }: ContractFinancialSource) => {
    return [source.year, FinancialSourceTypeStrings[source.type], source.code(),
        account && account.accountNumber || ""].join("/");
};

const sourceEq = (a: ContractFinancialSource, b: ContractFinancialSource) => {
    return a.source.id === b.source.id &&
        ((a.account === null && b.account === null) ||
            (!!a.account && !!b.account && a.account.accountNumber === b.account.accountNumber));
};

interface StageSourceUsage {
    index: number
    name: string
    percent: Decimal
    available: Decimal
    usage: ContractFinancialSourceUsage

    amount: Decimal
    stageAmount: Decimal
    advanceAmount: Decimal
    source: ContractFinancialSources
}

@Component({ components: { DataTable, CardModal } })
export default class AddStageFinances extends Vue {
    @Prop() private prop!: { scope: ContractScope; stage: ContractStage; stages: ContractStage[] };

    private getUsageStagesBySource = (stages: ContractStage[], sourceId: uuid) =>
        this.prop.stages
            .flatMap(stage => stage.stageSourceUsage)
            .filter(stage => stage.source.id === sourceId);

    public headers: ITableColumn<StageSourceUsage & { setAvailable(): Decimal }>[] = [
        {
            title: "Источник финансирования",
            getter: src => src.name,
        },
        {
            title: "Сумма источника, руб.",
            getter: src => src.amount,
            type: EditType.DECIMAL,
        },
        {
            title: "Нераспределено в этапы, руб.",
            getter: src => src.available,
            type: EditType.DECIMAL,
        },
        {
            title: "Сумма в этапе, руб.",
            getter: src => src.stageAmount,
            setter: (src, v) => src.stageAmount = v,
            blur: src => src.setAvailable(),
            type: EditType.DECIMAL,
            editable: true
        },
        {
            title: "В том числе авансовый платеж, руб.",
            getter: src => src.advanceAmount,
            setter: (src, v) => src.advanceAmount = v,
            type: EditType.DECIMAL,
            editable: true
        },
    ];

    private getTotalAmountFromStageSource(sources: ContractFinancialSourceUsage[] = []) {
        return sources.reduce((acc, source) => acc
            .add(source.stageAmount),
        new Decimal(0));
    }

    public dataset = this.prop.scope.financialSources.map((source, index) => {
        const usage = this.prop.stage.stageSourceUsage.find(x => x.source.id === source.id);
        const usageStages = this.getUsageStagesBySource(this.prop.stages, source.id);

        const getTotalAmountFromStageSource = this.getTotalAmountFromStageSource;

        return {
            index,
            usage: new ContractFinancialSourceUsage(source, usage?.stageAmount ?? new Decimal(0), usage?.advanceAmount ?? new Decimal(0)),
            name: sourceName(source),
            amount: source.amount,
            setAvailable() {
                 return this.available = this.amount
                    .sub(getTotalAmountFromStageSource(usageStages))
                    .sub(this.stageAmount);
            },
            available: source.amount.sub(getTotalAmountFromStageSource(usageStages))
                .sub(usage?.stageAmount ?? new Decimal(0)),
            get stageAmount() { return this.usage.stageAmount || new Decimal(NaN) },
            set stageAmount(v) { this.usage.stageAmount = v },
            get advanceAmount() { return this.usage.advanceAmount || new Decimal(NaN) },
            set advanceAmount(v) { this.usage.advanceAmount = v },
            isNew: !usage,
            get initialStageAmount() { return usage?.stageAmount ?? new Decimal(NaN) },
            get initialAdvanceAmount() { return usage?.advanceAmount ?? new Decimal(NaN) },
            sources: this.prop.stage.stageSourceUsage,
            source
        };
    });

    public save() {
        const hasInvalidStages = this.dataset.some(v => v.available.lt(0));
        const hasInvalidAdvancedAmount = this.dataset.some(v => v.advanceAmount.gt(v.stageAmount));

        if (hasInvalidStages) {
            EventBus.callModal(ErrorModal, {
                scope: "Невозможно сохранить",
                errors: ["Сумма финансирования этапа исполнения не может превышать общую сумму соответствующего источника финансирования."]
            });

            return;
        }

        if (hasInvalidAdvancedAmount) {
            EventBus.callModal(ErrorModal, {
                scope: "Невозможно сохранить",
                errors: ["Размер авансового платежа не может превышать сумму финансировании этапа по соответствующему источнику финансирования."]
            });

            return;
        }

        this.prop.stage.stageSourceUsage = this.dataset.map(x => new ContractFinancialSourceUsage(
            x.source,
            x.stageAmount,
            x.advanceAmount
        ))
            .filter(x => x.stageAmount && x.stageAmount.isFinite());

        this.$emit('close-modal');
    }
}
