import React from "react";
import { ContractObligationReceipt } from "@/models/Contracts/ContractObligationReceipt";
import { Card } from "@/components/Card";
import { Column, Pager, RemoteDataset, SelectionTable, Table } from "@/components/table";
import { VStack } from "@/components/layouts";
import { formatNumber } from "@/NumberFormatting";

type ReceiptsTableSectionProps = {
    receipts?: ContractObligationReceipt[]
    rds: RemoteDataset<ContractObligationReceipt>
};

export const ReceiptsTableSection = ({ receipts = [], rds }: ReceiptsTableSectionProps) => {
    const columns: Column<ContractObligationReceipt>[] = [
        Table.Column(
            "Наименование спецификации",
            ({ item }) => <>{item.subjectDeclaration.subject}</>,
            { width: "5fr", headerStyle: { textAlign: "center" }, cellStyle: { textAlign: "center" } }
        ),
        Table.Column(
            "Объем",
            ({ item }) => <>{formatNumber(item.volume)}</>,
            { headerStyle: { textAlign: "center" }, cellStyle: { textAlign: "center" } }
        ),
        Table.Column(
            "Сумма, руб.",
            ({ item }) => <>{formatNumber(item.actualCost)}</>,
            { headerStyle: { textAlign: "center" }, cellStyle: { textAlign: "center" } }
        ),
        Table.Column(
            "Страна происхождения",
            ({ item }) => <>{item.country}</>,
            { headerStyle: { textAlign: "center" }, cellStyle: { textAlign: "center" } }
        )
    ];

    return (
        <Card>
            <VStack spacing="10px">
                <SelectionTable dataset={receipts} columns={columns} mode="single" selectorPosition="hidden" />
                <Pager remoteDataset={rds} />
            </VStack>
        </Card>
    );
};
