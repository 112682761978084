import React, { ReactNode, useContext, useMemo, useState } from "react";
import styles from "./SpecificationsSection.module.css";
import { MonopolySpecification } from "@/models/Monopoly/MonopolySpecification";
import { Card } from "@/components/Card";
import { HSpread, HStack, VGrid, VStack } from "@/components/layouts";
import { Button, Label } from "@/components/primitive";
import { formatNumber } from "@/NumberFormatting";
import { MonopolySupplyStage } from "@/models/Monopoly/MonopolySupplyStage";
import { Column, Table } from "@/components/table";
import { formatDate } from "@/dateUtils";
import { j } from "@/reactUtils";
import { showModal } from "@/EventBus";
import { SpecificationEditModal } from "@/views/Monopoly/shared/modals/SpecificationEditModal";
import { MonopolyObjectStoreEvents } from "@/views/Monopoly/view/MonopolyObject/store";
import { replaceOrPush } from "@/arrayUtils";
import { FormStateContext } from "@/components/form";

type SpecificationsSectionProps = {
    specifications?: MonopolySpecification[]
    events: MonopolyObjectStoreEvents
};

export const SpecificationsSection = ({ specifications = [], events }: SpecificationsSectionProps) => {
    const edit = async (specification: MonopolySpecification) => {
        const newSpecification = await showModal(SpecificationEditModal, { specification });

        if (newSpecification) {
            const newSpecifications = replaceOrPush(specifications, specification, newSpecification);
            events.setSpecifications(newSpecifications);
        }
    };

    return (
        <Card>
            <ol>
                {specifications.map(spec => (
                    <li key={`${spec.id}-${spec.volume.toString()}`} style={{ paddingLeft: "50px" }}>
                        <HSpread>
                            <Specification specification={spec} onEdit={edit} />
                        </HSpread>
                    </li>
                ))}
            </ol>
        </Card>
    );
};

type SpecificationProps = {
    specification: MonopolySpecification
    onEdit: (specification: MonopolySpecification) => void
};

const Specification = ({ specification, onEdit }: SpecificationProps) => {
    const [isOpen, setIsOpen] = useState<boolean>(false);

    const editState = useContext(FormStateContext);
    const isEditing = editState === "enabled";

    const supplyStageColumns: Column<MonopolySupplyStage>[] = [
        Table.Column(
            "Объем поставки",
            ({ item }) => <>{formatNumber(item.volume, true, 0)} {specification.measurementUnit.name}</>
        ),
        Table.Column(
            "Дата начала",
            ({ item }) => <>{formatDate(item.absolutePeriod.startDate)}</>
        ),
        Table.Column(
            "Дата окончания",
            ({ item }) => <>{formatDate(item.absolutePeriod.endDate)}</>
        )
    ];

    return (
        <VStack spacing="20px" outerStyle={{ width: "100%" }}>
            <VGrid columns={`2.5fr 1fr 1fr 1fr${isEditing ? " 1fr" : ""}`}>
                <Label preset="boldSmall"
                       className={j(styles.subject, isOpen && styles.opened)}
                       onClick={() => setIsOpen(is => !is)}>
                    <span className={styles.expanderIcon} />
                    {specification.spgz.subject}
                </Label>
                <SpecificationItem title="Объем закупки"
                                   value={`${formatNumber(specification.volume, true, 0)} ${specification.measurementUnit.name}`} />
                <SpecificationItem title="Цена за единицу, руб" value={formatNumber(specification.unitPrice)} />
                <SpecificationItem title="Сумма, руб" value={formatNumber(specification.totalPrice)} />
                {isEditing && <VStack alignItems="center">
                    <Button icon="gPencil" onClick={() => onEdit(specification)} />
                </VStack>}
            </VGrid>
            <Label preset="readonlyValue">Адрес поставки: {specification.supplyAddress}</Label>
            {isOpen && <VStack>
                <HStack outerStyle={{ backgroundColor: "#f6fafb", padding: "10px 15px" }}>
                    <Label preset="readonlyValue">График поставки:</Label>
                </HStack>
                <Table columns={supplyStageColumns} dataset={specification.supplyStages} />
            </VStack>}
        </VStack>
    );
};

type SpecificationItem = {
    title: string
    value: ReactNode
};

const SpecificationItem = ({ title, value }: SpecificationItem) => (
    <VStack spacing="2px">
        <Label preset="disabled">
            {title}
        </Label>
        <Label preset="readonlyValue">
            {value}
        </Label>
    </VStack>
);
