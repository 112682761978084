import React, {useEffect, useState} from "react";
import {VStack} from "@/components/layouts";
import {Form, FormHeader} from "@/components/form";
import {getSubjectDeclarationById} from "@/api/directory";
import {SubjectDeclarationsInfoSection} from "@/views/ReferenceInfo/SubjectDeclarations/sections/SubjectDeclarationsInfoSection";
import {SubjectDeclarationsCharacteristic} from "@/views/ReferenceInfo/SubjectDeclarations/sections/SubjectDeclarationsCharacteristic";
import {SubjectDeclarationInstance} from "@/models/SubjectDeclaration/SubjectDeclaration";
import { ChangeHistorySection } from "@/views/ComposedLot/edit/tabs/change-history/ChangeHistorySection";

type ViewSubjectClassProps = {
    id?: string | null
};

export const SubjectDeclarationsView: React.FC<ViewSubjectClassProps> = x => {
    const [subjectDeclaration, setSubjectDeclaration] = useState<SubjectDeclarationInstance>();
    useEffect(() => {
        if (x.id) getSubjectDeclarationById(x.id).then(setSubjectDeclaration);
    }, []);

    const header = <VStack spacing="15px">
        <FormHeader title={`Информация о СПГЗ - ${x.id}`}/>
    </VStack>;

    const tabs = Form.Tab("info", "Общие сведения", [
        Form.Section("Общие сведения", <SubjectDeclarationsInfoSection info={subjectDeclaration!} />),
        Form.Section("Характеристики", <SubjectDeclarationsCharacteristic />),
        Form.Section("История изменений", <ChangeHistorySection />)
    ]);

    return <>
        {
            subjectDeclaration
                ? <Form header={header} tabs={[tabs]} />
                : <></>
        }
    </>;
};