import { usePopup } from "@/views/Products/ProductsCard";
import React, { useState } from "react";
import styles from './TooltipIcon.module.css';

export const TooltipIcon = () => {
    const [showTooltip, toggleTooltip, tooltipPopupRef] = usePopup();
    const [tooltipText, setTooltipText] = useState('');
    const onToggleOpen = (e: React.MouseEvent<unknown, MouseEvent>) => {
        e.stopPropagation();
        toggleTooltip();
    };
    
    return <>
        <div onClick={e => onToggleOpen(e)} className={styles.iconContainer}>
            <Icon />
        </div>
        {showTooltip && <div ref={tooltipPopupRef} className={`${styles.tooltopContainer}  ${showTooltip ? styles.tooltopContainerVisible : ''}`}>
            <textarea value={tooltipText} onChange={e => setTooltipText(e.target.value)} className={styles.textInput} />
            <span onClick={e => onToggleOpen(e)}>Закрыть</span>
        </div>}
    </>;
};

const Icon = () => <svg viewBox="0 0 100 100" fill="#53b2f8" version="1.0">
    <path d="M0 50c0 27.614 22.386 50 50 50s50-22.386 50-50c0-13.807-4.882-25.592-14.645-35.355C75.592 4.882 63.807 0 50 0 22.386 0 0 22.386 0 50zm8.3 0C8.3 26.97 26.97 8.3 50 8.3S91.7 26.97 91.7 50c0 11.515-4.071 21.344-12.214 29.486C71.344 87.63 61.516 91.7 50 91.7 26.97 91.7 8.3 73.03 8.3 50zm56.74-4.624c-1.226 2.505-2.79 4.376-5.04 6.024-1.81 1.255-3.095 2.683-4.152 4.615C54.79 57.947 54.28 59.799 54.2 62c.057 1.144-.292 2.14-1.052 2.996-.76.857-1.706 1.324-2.848 1.404H50a4.04 4.04 0 01-2.837-1.086A4.04 4.04 0 0145.8 62.6c-.004-3.598.722-6.653 2.346-9.864C49.769 49.526 51.8 47.129 54.7 45c1.234-.845 2.095-1.837 2.756-3.178.662-1.342.925-2.629.844-4.122.056-2.358-.723-4.39-2.341-6.106C54.34 29.878 52.357 28.982 50 28.9c-2.292 0-4.248.81-5.869 2.431-1.62 1.62-2.431 3.577-2.431 5.869a4.2 4.2 0 11-8.4 0c.028-4.594 1.668-8.505 4.927-11.744C41.485 22.217 45.406 20.6 50 20.6c4.667.055 8.63 1.752 11.892 5.091 3.26 3.339 4.864 7.342 4.808 12.009.08 2.787-.436 5.171-1.66 7.676zM54.2 75.7a4.035 4.035 0 01-1.453 2.527A4.035 4.035 0 0150 79.2h-.7a4.258 4.258 0 111.4-8.4c1.126.18 2.024.724 2.705 1.639.681.914.945 1.93.795 3.061v.2z" />
</svg>;