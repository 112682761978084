import { Component, Prop, Vue } from "vue-property-decorator";

export interface IStatsItem {
    title: string
    url: string
    count: number
    amount: number
}

@Component
export default class Stats extends Vue {
    @Prop() private stats!: IStatsItem[][];
}

export const defaultStats: Record<string, IStatsItem[][]> = {
    "purchase-objects": [
        [
            {
                title: "Всего",
                url: "/plan-objects",
                count: 214,
                amount: 188650.7,
            },
            {
                title: "Отменен",
                url: "/plan-objects",
                count: 24,
                amount: 14351.5,
            },
        ],

        [
            {
                title: "Формирование",
                url: "/plan-objects",
                count: 24,
                amount: 3883.13,
            },
            {
                title: "К корректировке",
                url: "/plan-objects",
                count: 0,
                amount: 0.0,
            },
        ],

        [
            {
                title: "Утвержден",
                url: "/plan-objects",
                count: 8,
                amount: 900.0,
            },
        ],

        [
            {
                title: "На согласовании ДФ",
                url: "/plan-objects",
                count: 0,
                amount: 0.0,
            },
            {
                title: "На согласовании ГРБС",
                url: "/plan-objects",
                count: 0,
                amount: 0.0,
            },
        ],

        [
            {
                title: "Включен в план закупок",
                url: "/plan-objects",
                count: 0,
                amount: 0.0,
            },
            {
                title: "Опубликован",
                url: "/plan-objects",
                count: 158,
                amount: 169516.07,
            },
        ],
    ],
    "purchase-objects-detailed": [
        [
            {
                title: "Всего",
                url: "/plan-objects/detailed",
                count: 137,
                amount: 120628.05,
            },
            {
                title: "Отменен",
                url: "/plan-objects/detailed",
                count: 0,
                amount: 0.0,
            },
        ],

        [
            {
                title: "Формирование",
                url: "/plan-objects/detailed",
                count: 3,
                amount: 12935.32,
            },
            {
                title: "К корректировке",
                url: "/plan-objects/detailed",
                count: 0,
                amount: 0.0,
            },
        ],

        [
            {
                title: "Утвержден",
                url: "/plan-objects/detailed",
                count: 23,
                amount: 3352.29,
            },
            {
                title: "Включен в лот",
                url: "/plan-objects/detailed",
                count: 67,
                amount: 39511.99,
            },
        ],

        [
            {
                title: "На согласовании ГРБС",
                url: "/plan-objects/detailed",
                count: 0,
                amount: 0.0,
            },
            {
                title: "Не состоялся",
                url: "/plan-objects/detailed",
                count: 0,
                amount: 0.0,
            },
        ],

        [
            {
                title: "Включен в план-график",
                url: "/plan-objects/detailed",
                count: 0,
                amount: 0.0,
            },
            {
                title: "Опубликован",
                url: "/plan-objects/detailed",
                count: 44,
                amount: 64828.45,
            },
        ],
    ],
    "lots": [
        [
            {
                title: "Всего",
                url: "/plan-objects/lots",
                count: 17,
                amount: 546.25,
            },
            {
                title: "Отменен",
                url: "/plan-objects/lots",
                count: 0,
                amount: 0.0,
            },
            {
                title: "План-график на согласовании ГРБС",
                url: "/plan-objects/lots",
                count: 0,
                amount: 0.0,
            },
            {
                title: "На согласовании РГ МРГ",
                url: "/plan-objects/lots",
                count: 0,
                amount: 0.0,
            },
            {
                title: "Готов к включению в совместный лот",
                url: "/plan-objects/lots",
                count: 0,
                amount: 0.0,
            },
        ],

        [
            {
                title: "Формирование",
                url: "/plan-objects/lots",
                count: 7,
                amount: 298.53,
            },
            {
                title: "К корректировке",
                url: "/plan-objects/lots",
                count: 0,
                amount: 0.0,
            },
            {
                title: "Согласование заявки",
                url: "/plan-objects/lots",
                count: 0,
                amount: 0.0,
            },
            {
                title: "Отклонен РГ МРГ",
                url: "/plan-objects/lots",
                count: 0,
                amount: 0.0,
            },
            {
                title: "Включен в совместный лот",
                url: "/plan-objects/lots",
                count: 0,
                amount: 0.0,
            },
        ],

        [
            {
                title: "Утвержден",
                url: "/plan-objects/lots",
                count: 0,
                amount: 0.0,
            },
            {
                title: "Внесение изменений",
                url: "/plan-objects/lots",
                count: 0,
                amount: 0.0,
            },
            {
                title: "Выданы замечания ГРБС",
                url: "/plan-objects/lots",
                count: 0,
                amount: 0.0,
            },
            {
                title: "Выданы замечания РГ МРГ",
                url: "/plan-objects/lots",
                count: 0,
                amount: 0.0,
            },
        ],

        [
            {
                title: "На согласовании ГРБС",
                url: "/plan-objects/lots",
                count: 4,
                amount: 80.45,
            },
            {
                title: "Не состоялся",
                url: "/plan-objects/lots",
                count: 0,
                amount: 0.0,
            },
            {
                title: "Согласовано ГРБС",
                url: "/plan-objects/lots",
                count: 0,
                amount: 0.0,
            },
            {
                title: "Исправлены замечания РГ МРГ",
                url: "/plan-objects/lots",
                count: 0,
                amount: 0.0,
            },
        ],

        [
            {
                title: "Включен в план-график",
                url: "/plan-objects/lots",
                count: 0,
                amount: 0.0,
            },
            {
                title: "Опубликовано в плане-графике",
                url: "/plan-objects/lots",
                count: 6,
                amount: 167.27,
            },
            {
                title: "Отклонен ГРБС",
                url: "/plan-objects/lots",
                count: 0,
                amount: 0.0,
            },
            {
                title: "Согласован РГ МРГ",
                url: "/plan-objects/lots",
                count: 0,
                amount: 0.0,
            },
        ],
    ],
};
