function easeInOutCubic(t: number) {
    return t < 0.5 ? 2 * t * t : -1 + (4 - 2 * t) * t;
}

function smoothScroll(offset: number, target?: Element) {
    const duration = 500;
    const clock = Date.now();

    const end = offset;

    // we use requestAnimationFrame to be called by the browser before every repaint
    const requestAnimationFrame =
        window.requestAnimationFrame ||
        window.mozRequestAnimationFrame ||
        window.webkitRequestAnimationFrame ||
        (fn => window.setTimeout(fn, 16));

    const step = () => {
        // the time elapsed from the beginning of the scroll
        const elapsed = Date.now() - clock;
        // calculate the scroll position we should be in
        let position = end;

        if (elapsed < duration) {
            if (target) {
                position = target.scrollTop + (end - target.scrollTop) * easeInOutCubic(elapsed / duration);
                requestAnimationFrame(step);

                target.scroll(0, position);
            } else {
                position = scrollY + (end - scrollY) * easeInOutCubic(elapsed / duration);
                requestAnimationFrame(step);

                scroll(0, position);
            }
        }
    };

    step();
}

export default smoothScroll;
