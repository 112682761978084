


























import { Component, Prop, Vue } from "vue-property-decorator";
import { ITableAction } from './TableAbstractions';

@Component
export default class PageHeader extends Vue {
    @Prop() private header!: string;
    @Prop({ default: false }) private canAdd!: boolean;
    @Prop({ default: false }) private canFilter!: boolean;
    @Prop({ default: false }) private canSearch!: boolean;
    @Prop({ default: false }) private canExport!: boolean;
    @Prop({ default: false }) private isAddButtonDisabled!: boolean;
    @Prop({ default: () => [] }) private actions!: ITableAction[];
}
