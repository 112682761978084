












import { Identified, ITableColumn } from "@/components/TableAbstractions";
import EventBus from "@/EventBus";
import { IRelatedObject } from "@/models/RelatedObjects";
import router from "@/router";
import { ModalResult } from "@/view-models/ModalRequest";
import RelatedModal from "@/views/modals/RelatedModal/RelatedModal.vue";
import { Component, Prop, Vue } from "vue-property-decorator";

@Component
export default class RelatedObject extends Vue {
    @Prop() private type!: "inline" | "modal";
    @Prop() private label!: string;
    @Prop() private subject!: string;
    @Prop() private url!: string;
    @Prop() private title!: string;
    @Prop() private headers!: ITableColumn<IRelatedObject>[];
    @Prop() private dataset!: Identified[];

    private showModal() {
        return EventBus.callModal(RelatedModal, {
            headers: this.headers,
            dataset: this.dataset,
            title: this.title,
        });
    }
}
