type NumberMapper<T> = (n: number, ix: number) => T;

export const identity = <T>(t: T): T => t;

export const sequence =
    <T = number>(start: number, count: number, step: number = 1,
        ...mapFn: T extends number ? [NumberMapper<T>?] : [NumberMapper<T>]): T[] => {

        if (count < 1) return [];

        const array = new Array<T>();
        for (let ix = 0, n = start; ix < count; ix++, n += step) {
            array[ix] = mapFn[0] ? mapFn[0](n, ix) : n as unknown as T;
        }
        return array;
    };

export const range =
    <T = number>(start: number, end: number, step: number = 1,
        ...mapFn: T extends number ? [NumberMapper<T>?] : [NumberMapper<T>]): T[] => {

        const count = start > end ? 0 : Math.trunc((end - start) / step) + 1;

        return sequence(start, count, step, ...mapFn);
    };

export const windowed =
    <T>(array: T[], windowSize: number): T[][] => {

        if (array.length === 0 || windowSize < 1) return [];

        const windows = new Array<T[]>(Math.ceil(array.length / windowSize));
        for (let i = 0; i < windows.length; i++) {
            windows[i] = array.slice(i * windowSize, (i + 1) * windowSize);
        }

        return windows;
    };

export type NArray<T, NumElements extends number> =
    NumElements extends 0
    ? never[]
    : [T, ...T[]] & { length: NumElements };

export const replaceOrPush = <T>(array: T[], oldI: T | undefined, newI: T): T[] => {
    const ix = oldI !== undefined ? array.indexOf(oldI) : -1;
    if (ix >= 0) {
        const copy = [...array];
        copy.splice(ix, 1, newI);

        return copy;
    } else {
        return [...array, newI];
    }
};

export const appendUniq = <T>(array: T[], value: T): T[] => {
    if (array.includes(value)) return array;
    return [...array, value];
};

export const removeUniq = <T>(array: T[], value: T): T[] => {
    return array.filter(x => x !== value);
};

export const firstMaybe = <T>(array: T[]): T | undefined => {
    return array[0] as T | undefined;
};