import React, {useState} from 'react';
import {Button, Error} from '@/components/primitive';
import {FormHeader} from "@/components/form/FormHeader";
import { Form, Tab } from "@/components/form/Form";
import {FormState} from "@/components/form";
import {
    InfoSection,
    MinimalRequirementsSection,
    PlannedPaymentsSection,
    ProvisionSection,
    PublicDiscussionSection,
    PurchasePerUnitSection
} from "../shared/sections";
import {actionToState, FormAction, formToTemplate, LotForm, replaceLot, SectionProps} from "../shared/store";
import {Expandee, HStack, VStack} from "@/components/layouts";
import {SpecificationsSection} from "@/views/ComposedLot/edit/tabs/specifications/SpecificationsSection";
import {
    AdditionalRequirementsSection
} from "@/views/ComposedLot/edit/tabs/additional-info/AdditionalRequirementsSection";
import {uuid} from "@/models/parsing";
import {DocumentsSection} from "@/views/ComposedLot/edit/tabs/lot-documents/DocumentsSection/DocumentsSection";
import {ResponsibilityInfoSection} from "@/views/ComposedLot/edit/tabs/additional-info/ResponsibilityInfoSection";
import {AdditionalInfoSection} from "@/views/ComposedLot/edit/tabs/additional-info/AdditionalInfoSection";
import {useMappedStore} from "@/storeUtils";
import {LawType} from "@/models/enums";
import {ChangeHistorySection} from "@/views/ComposedLot/edit/tabs/change-history/ChangeHistorySection";
import {showModal} from "@/EventBus";
import {SaveLotModal} from "@/modal-views/SaveLotModal";
import {
    approveLot,
    cancelLot,
    deleteLotById,
    setLotReady,
    updateLot,
    updateLotAdditionalInfo,
    updateLotDocumentSection,
    updateLotProductSpecifications,
    updateLotSpecifications,
    uploadLotDocument
} from "@/api/ComposedLots";
import {Specification, SpecificationDraft} from "@/models/ComposedLots/specifications";
import {Lot, LotVisibleStatusStrings} from "@/models/ComposedLots";
import {CriteriaSection} from "@/views/ComposedLot/edit/tabs/lot-documents/CriteriaSection/CriteriaSection";
import router from "@/router";
import {Store} from "effector";
import {LotDocuments} from "@/models/ComposedLots/documents/LotDocuments";
import {OnCalculationDone} from "@/views/ComposedLot/edit/start-price/StartPriceCalculator";
import {SafeFnFactory, useErrorHandler} from "@/reactUtils";
import {LotAdditionalRequirements} from "@/models/ComposedLots/LotAdditionalInfo";
import {EditReasonSection} from "@/views/ComposedLot/shared/sections/EditReasonSection";
import Contracts from "@/api/Contracts";
import {Relation} from "@/components/Relation";
import {createContractRelation, createLimitRelation,} from "@/views/Procedures2020/shared/relations";
import {GoodsListSection} from "@/views/ComposedLot/edit/tabs/lot-documents/GoodsListSection";
import {CancelLotModal} from "@/views/ComposedLot/shared/modals/CancelLotModal";
import {ConfirmationModal, ErrorModal} from "@/views/Contracts/modals/ConfirmationModal";
import {JointBiddingSection} from "@/views/ComposedLot/shared/sections/JointBiddingSection";
import {createLotDocumentFromTemplate} from "@/views/form-renderers/DocumentationSection/documentation";
import {addOrEditLotDocument} from "@/views/ComposedLot/shared/store/documents";
import {
    DocumentTypeSelectionModal
} from "@/views/ComposedLot/edit/tabs/lot-documents/DocumentsSection/DocumentTypeSelectionModal";
import Decimal from 'decimal.js';
import { getTotalPaymentAmount } from "@/views/ComposedLot/shared/store/plannedPayments";
import { getPaymentStagesTotal } from "@/views/ComposedLot/shared/store/lotInfo";
import { ValidationError } from "@/api/errorHandling";
import {isAggregatedLot} from "@/miscUtils";
import { LotSpecifications } from "@/views/ComposedLot/shared/store/specifications";

const Relations = (x: { store: Store<LotForm> }) => {
    const [self, procedure, contract] = useMappedStore(x.store, x => [
        x.head.instance, x.head.relatedProcedure, x.head.relatedContract
    ]);

    const procedureR: Relation<undefined> = {
        typeName: "Закупка",
        typeNamePlural: "Закупка",
        objects: [{
            item: undefined,
            name: procedure?.subject ?? ""
        }],
        columns: [],
        open: () => router.push(`/procedures/2020/${procedure?.id}`)
    };

    const lotR = (lot: Lot): Relation<undefined> => {
        return {
            typeName: "Лот",
            typeNamePlural: "Лот",
            objects: [{
                item: undefined,
                name: lot.basicInfo.subject
            }],
            columns: [],
            open: () => router.push(`/plan-objects/composed-lots/${lot.id}`)
        };
    };

    return <HStack>
        { self && <Relation relation={createLimitRelation([{ lot: self }])}/> }
        { self && <Relation relation={lotR(self)}/> }
        { procedure && <Relation relation={procedureR}/> }
        { contract && <Relation relation={createContractRelation(contract)}/> }
    </HStack>;
};

export const EditLot: React.FC<{ lotId: uuid; action: FormAction; storeContainer: { store: Store<LotForm> }; calculateStartPrice: (specification: SpecificationDraft, onDone: OnCalculationDone) => void }> = ({lotId, action, storeContainer: { store }, calculateStartPrice}) => {
    const [law, regNumber, status, realStatus, pst, procedureId, hasCriteria, assessmentOrder, cancellationReasons, isSmallVolume, lotJoinType, isAggregated, contractType, isGoodsUsed, basicInfo] = useMappedStore(store, x => [
        x.info.law,
        x.head.regNumber,
        x.head.visibleStatus,
        x.head.status,
        x.info.providerSelectionType,
        x.head.procedureId,
        x.criteria.criteria.length > 0,
        !!x.criteria.assessmentOrder?.length,
        x.relatedData.cancellationReasons,
        x.info.specialPurchaseType !== "NO" && x.info.specialPurchaseType !== "MEDICINES",
        x.info.lotJoinType,
        (x.info.providerSelectionType === "SINGLE_PROVIDER_PURCHASE" && (x.info.singleProviderReasonId === 1)),
        x.info.contractType,
        x.info.isGoodsUsed,
        x.head.instance?.basicInfo
    ]);

    const lotInstance = useMappedStore(store, x => x.head.instance);

    const changedTabs = useMappedStore(store, x => ({
        info: x.head.infoChanged,
        specs: x.head.specsChanged,
        docs: x.head.docsChanged,
        adds: x.head.addsChanged
    }));

    const title = action === "viewing" ? `Лот - ${regNumber ?? ""} - ${status ? LotVisibleStatusStrings[status] : ""}` : "Редактирование лота";

    const isMaster = lotJoinType === "MASTER";
    const isSlave = lotJoinType === "SLAVE";
    const isCommon = lotJoinType === "NO";

    const [error, makeSafe] = useErrorHandler();

    const header = <VStack spacing="15px">
        <Relations store={store} />
        <Error object={error}/>
        <FormHeader title={title}/>
    </VStack>;

    const [currentTab, setCurrentTab] = useState<string | undefined>();

    const dismiss = (action: FormAction, id?: uuid) => {
        switch (action) {
            case "viewing":
                router.replace("/plan-objects/composed-lots");
                break;
            case "creating":
                router.replace("/plan-objects/composed-lots");
                break;
            case "editing":
                if (id) router.replace(`/plan-objects/composed-lots/${id}`);
        }

    };

    const saveInfo = async (draft?: boolean) => {
        const state = store.getState();
        const template = formToTemplate(state, false, draft);

        await updateLot(lotId, template, draft);
    };

    const saveSpecs = async (draft?: boolean) => {
        const state = store.getState();
        const specs = state.specifications.specificationForms;
        // TODO: validation for product specs same as for specs
        const productSpecs = state.productSpecifications.productSpecificationForms;
        const validSpecs = specs.flatMap(v => {
            const validated = v.validated;
            const validatedStages = v.supplyStages.flatMap(ss => ss.isStageValid ? [ss.validatedStage] : []);
            return validated ? [{...validated, supplyStages: validatedStages}] : [];
        });

        await updateLotSpecifications(lotId, (draft ? specs.map(v => Specification.toTemplate({ ...v.validated, isNew: false })) : validSpecs.map(v => Specification.toTemplate({ ...v, isNew: false }))), state.purchasePerUnit.enabled, state.info.paymentStages, draft);

        if (productSpecs.length > 0) {
            await updateLotProductSpecifications(lotId, productSpecs.map(v => Specification.toTemplate({ ...v.validated, isNew: false })));
        }
    };

    const saveLotDocuments = async () => {
        const state = store.getState();
        const docs: LotDocuments = {
            requestDocuments: state.documents.lotRequestDocuments,
            lotCriteria: state.criteria.criteria,
            lotDocuments: state.documents.lotDocuments,
            lotTerms: state.glossary.selectedItems,
            lotNpa: state.legalActs.attachedActs,
            criteriaAssessmentOrder: state.criteria.assessmentOrder
        };

        await updateLotDocumentSection(lotId, docs);
    };

    const saveAdditionalInfo = async () => {
        const state = store.getState();

        const requirements: LotAdditionalRequirements = {
            ...state.additionalRequirements,
            section: state.additionalRequirements.hasAdditionalRequirements || state.additionalRequirements.hasAdditionalRequirements2022
                ? state.additionalRequirements.selectedOption
                : undefined,
            supplierRequirements: state.additionalRequirements.hasAdditionalSupplierRequirements
                ? state.additionalRequirements.supplierRequirements
                : undefined,
            supplierRequirementsIs2022: state.additionalRequirements.hasAdditionalRequirements2022
        };

        await updateLotAdditionalInfo(lotId, {
            requirements,
            purchaseInfo: state.additionalInfo,
            responsibilityInfo: state.responsibilityInfo
        });
    };

    const saveAll = (draft?: boolean) => (makeSafe(async () => {
        const result = await showModal(SaveLotModal);

        switch (result) {
            case "save-all":
                await saveInfo(draft);
                await saveSpecs(draft);
                await saveLotDocuments();
                await saveAdditionalInfo();
                break;

            case "save-current-tab":
                switch (currentTab) {
                    case "info":
                        await saveInfo(draft);
                        break;
                    case "specifications":
                        await saveSpecs(draft);
                        break;
                    case "lot-documents":
                        await saveLotDocuments();
                        break;
                    case "additional-info":
                        await saveAdditionalInfo();
                        break;
                }
                break;

            default:
                return;
        }

        router.replace(`/plan-objects/composed-lots/${lotId}`);
    }))();

    const setReady = makeSafe(async () => {
        const lot = store.getState().head.instance;
        if (!lot) return;

        replaceLot(await setLotReady(lotId));
    });

    const approve = makeSafe(async () => {
        const lot = store.getState().head.instance;
        if (!lot) return;
        const startPrice = getTotalPaymentAmount(lot.plannedPayments);
        const paymentStagesTotal = getPaymentStagesTotal(lot.paymentStages);
        if(!(isSmallVolume || lot.basicInfo.lotJoinType === "SLAVE" || lot.law === LawType.F223 || paymentStagesTotal.paymentAmount.eq(startPrice))) {
            throw new ValidationError("Общая сумма по источникам финансирования во всех этапах оплаты должна быть равна общей сумме финансирования лота");
        }
        if (lot.basicInfo.lotJoinType !== "NO") {
            const type = await showModal(DocumentTypeSelectionModal, { title: "Выбор шаблона соглашения о совместной закупке", options: [ "Соглашение о совместной закупке - 07.04.2020 20:57" ] });
            if (type) {
                const doc = await createLotDocumentFromTemplate("jointPurchaseAgreement", lot);
                if (doc) addOrEditLotDocument({ newV: await uploadLotDocument(lot.id, doc) });
            }
        }

        replaceLot(await approveLot(lotId));
    });

    const cancel = makeSafe(async (): Promise<void> => {
        const reason = await showModal(CancelLotModal, {reasons: cancellationReasons});
        if (reason)
            replaceLot(await cancelLot(lotId, reason.id));
    });

    const deleteLot = async () => {
        const confirmation = await showModal(ConfirmationModal, {text: "Вы действительно хотите удалить лот?", title: "Подтверждение удаления лота"});
        if (!confirmation)
            return;
        await deleteLotById(lotId);
        router.replace("/plan-objects/composed-lots");
    };

    const createProcedure = async () => {
        if (needCriteriaSection) {
            const criteria = store.getState().criteria;

            // https://rt.rtall.ru/redmine/issues/789 - этого поля больше нет в ЕАИСТ, поэтому валидация больше не нужна
            // if (!assessmentOrder) {
            //     await showModal(ErrorModal, { text: 'Не указан документ с типом "Порядок оценки заявок по критериям"' });
            //     return;
            // }

            if (criteria) {
                const total = criteria.criteria.map(c => c.value ?? new Decimal(0)).reduce((p, c) => p.add(c));
                if (!total.equals(new Decimal(100))) {
                    await showModal(ErrorModal, { text: "Значения критериев оценки заявок в сумме должны быть равны 100%"});
                    return;
                }
            }
        }
        router.push(`/procedures/2020/create/${law}?lotId=${lotId}`).ignore;
    };
    const createContract = async (id: uuid, law: LawType, isMonopoly?: boolean) => {
        const contract = isMonopoly ? await Contracts.createFromMonopoly(id, law) : await Contracts.createFromLot2020(id);
        if (contract)
            router.push("/contracts/" + contract.id).ignore;
    };

    const navInset = action === "viewing"
        ? <HStack spacing="10px">
            <Button icon="aEdit" onClick={() => router.push(`/plan-objects/composed-lots/${lotId}/edit?page=${currentTab}`)}/>
            <Button icon="aClone" disabled />
            {
                status != "PUBLISHED_IN_PLAN" && <Button icon="aDelete" color="red" onClick={deleteLot}/>
            }
            <Expandee/>
            {
                ((isSlave && status === "INCLUDED_IN_JOINT_PURCHASE" && lotInstance?.aggregatorStatus === "APPROVED") || ((isCommon || isMaster) && realStatus == "CREATING")) &&
                    <Button color="green" title={"утвердить"} onClick={approve}/>
            }
            {
                (isSlave && realStatus === "CREATING") && <Button color="green" title={"готов к включению в совместный лот"} onClick={setReady} />
            }
            {
                ((realStatus === "APPROVED") && !procedureId) && (pst !== "SINGLE_PROVIDER_PURCHASE") && !isSlave && !isAggregated && <Button color="green" title="создать процедуру" onClick={createProcedure}/>
            }

            {
                 // TODO: hide button when the contract already exists
                 (!isAggregatedLot(law, pst, lotInstance?.singleProviderReason?.id) && !isSlave && lotInstance?.singleProvider && status === "PUBLISHED_IN_PLAN" && pst === "SINGLE_PROVIDER_PURCHASE") && <Button color="green" title="создать контракт" onClick={() => createContract(lotId, law, basicInfo?.isMonopoly)}/>
            }
            {
                status === "PUBLISHED_IN_PLAN" &&
                    <Button color="red" title="отмена" onClick={async () => cancel()} />
            }
            <Button color={"red"} icon={"aClose"} onClick={() => dismiss(action, lotId)}/>
        </HStack>
        : <HStack spacing="10px">
            <Button icon={"aSave"} onClick={() => saveAll()}/>
            <Button icon={"aSaveThin"} onClick={() => saveAll(true)}/>
            <Expandee/>
            <Button color={"red"} icon={"aClose"} onClick={() => dismiss(action, lotId)}/>
        </HStack>;

    const sectionProps: SectionProps = {
        formStore: store,
        formAction: action,
        error,
        makeSafe: makeSafe as SafeFnFactory<void>
    };
    /*
      44-фз:
      Общие сведения
      Планируемые платежи
      Общественное обсуждение +
      Обеспечение заявки
      Обеспечение контракта
      Обеспечение гарантии
      223-фз:
      Общие сведения
      Планируемые платежи
      Минимальные требования
      Обеспечение заявки
      Обеспечение договора
   */
    const needEditReasonSection: boolean = (action === "editing") && (status === "PUBLISHED_IN_PLAN");
    const needGoodsListSection: boolean = pst === "E_AUC";
    const needCriteriaSection: boolean = hasCriteria && (pst === "E_EXAM" || pst === "E_OFFERS_REQUEST");
    const needSpecProducts: boolean = (contractType === "WORK_EXECUTION" || contractType === "SERVICE_PROVISION") && isGoodsUsed;

    const spreadIf = <T,>(cond: boolean, ...items: T[]): T[] => cond ? items : [];

    const F44Tabs = [
        Form.Tab("info", "Общие сведения", [
            ...spreadIf(needEditReasonSection, Form.Section("Причина изменения", <EditReasonSection {...sectionProps}/>)),
            Form.Section("Общие сведения", <InfoSection {...sectionProps} isLotAggregated={isAggregated}/>),
            ...spreadIf(!isMaster, Form.Section("Планируемые платежи", <PlannedPaymentsSection {...sectionProps}/>)),
            ...spreadIf(isMaster, Form.Section("Совместные торги", <JointBiddingSection {...sectionProps}/>)),
            ...spreadIf(!isSmallVolume, Form.Section("Общественное обсуждение", <PublicDiscussionSection {...sectionProps}/>)),
            ...spreadIf(!isSmallVolume, Form.Section("Обеспечение заявки", <ProvisionSection {...sectionProps} kind="request" is223={false}/>)),
            ...spreadIf(!isSmallVolume, Form.Section("Обеспечение контракта", <ProvisionSection {...sectionProps} kind="contract" is223={false}/>)),
            ...spreadIf(!isSmallVolume, Form.Section("Требования к гарантии качества", <ProvisionSection {...sectionProps} kind="guarantee" is223={false}/>)),
        ]),
        ...spreadIf(!isSmallVolume && !isMaster, Form.Tab("specifications", "Спецификации", [ //44 и 223 одинаково
            Form.Section("Закупка на единицу продукции", <PurchasePerUnitSection {...sectionProps}/>),
            Form.Section("Спецификации", <SpecificationsSection type="common" {...sectionProps} calculateStartPrice={calculateStartPrice}/>),
            ...spreadIf(needSpecProducts,
                Form.Section("Спецификация товаров", <SpecificationsSection type="product" {...sectionProps} calculateStartPrice={calculateStartPrice}/>)
            )
        ])),
        ...spreadIf(!isSmallVolume, Form.Tab("lot-documents", "Документы лота", [
            // Form.Section("Справочник терминов и определений", <GlossarySection {...sectionProps}/>), // #514 removed
            ...spreadIf(needCriteriaSection, Form.Section("Критерии оценки", <CriteriaSection {...sectionProps}/>)),
            // Form.Section("Список нормативно-правовых актов", <LegalActsListSection {...sectionProps}/>), // #514 removed
            ...spreadIf(needGoodsListSection, Form.Section("Список товаров для формы 2", <GoodsListSection {...sectionProps}/>)),
            Form.Section("Документы", <DocumentsSection {...sectionProps}/>)
        ])),
        Form.Tab("additional-info", "Дополнительная информация", [
            Form.Section("Информация об ответственном должностном лице и контрактной службе",
                <ResponsibilityInfoSection {...sectionProps}/>),
            ...spreadIf(!isSmallVolume ,Form.Section("Дополнительные сведения о закупке", <AdditionalInfoSection {...sectionProps}/>)),
            Form.Section("Дополнительные требования", <AdditionalRequirementsSection {...sectionProps}/>),
        ]),
        Form.Tab("changelog", "История изменений", [
            Form.Section("История изменений", <ChangeHistorySection {...sectionProps}/>),
        ])
    ];
    const F223Tabs = [
        Form.Tab("info", "Общие сведения", [
            ...spreadIf(needEditReasonSection, Form.Section("Причина изменения", <EditReasonSection {...sectionProps}/>)),
            Form.Section("Общие сведения", <InfoSection {...sectionProps} isLotAggregated={isAggregated}/>),
            Form.Section("Планируемые платежи", <PlannedPaymentsSection {...sectionProps}/>),
            ...spreadIf(isMaster, Form.Section("Совместные торги", <JointBiddingSection {...sectionProps}/>)),
            ...spreadIf(!isSmallVolume, Form.Section("Минимальные требования", <MinimalRequirementsSection {...sectionProps}/>)),
            ...spreadIf(!isSmallVolume && !isAggregated, Form.Section("Обеспечение заявки", <ProvisionSection {...sectionProps} kind="request" is223={true}/>)),
            ...spreadIf(!isSmallVolume && !isAggregated, Form.Section("Обеспечение договора", <ProvisionSection {...sectionProps} kind="contract" is223={true}/>)),
            ...spreadIf(!isSmallVolume && !isAggregated, Form.Section("Требования к гарантии качества", <ProvisionSection {...sectionProps} kind="guarantee" is223={true}/>))
        ]),
        ...spreadIf(!isSmallVolume && !isMaster && !isAggregated, Form.Tab("specifications", "Спецификации", [
            Form.Section("Закупка на единицу продукции", <PurchasePerUnitSection {...sectionProps}/>),
            Form.Section("Спецификации", <SpecificationsSection type="common" {...sectionProps} calculateStartPrice={calculateStartPrice}/>)
        ])),
        ...spreadIf(!isSmallVolume && !isAggregated, Form.Tab("lot-documents", "Документы лота", [
            ...spreadIf(needCriteriaSection, Form.Section("Критерии оценки", <CriteriaSection {...sectionProps}/>)),
            // Form.Section("Справочник терминов и определений", <GlossarySection {...sectionProps}/>),
            ...spreadIf(needGoodsListSection, Form.Section("Список товаров для формы 2", <GoodsListSection {...sectionProps}/>)),
            Form.Section("Документы", <DocumentsSection {...sectionProps}/>)
        ])),
        Form.Tab("additional-info", "Дополнительная информация", [
            Form.Section("Дополнительные требования", <AdditionalRequirementsSection {...sectionProps}/>),
        ]),
        Form.Tab("changelog", "История изменений", [
            Form.Section("История изменений", <ChangeHistorySection {...sectionProps}/>),
        ])
    ];

    const handleTabChange = async (tab: Tab | undefined) => {
        if (LotSpecifications.specificationForms.some(s => s.hasChanges || s.supplyStages.some(ss => ss.hasChanges))) {
            LotSpecifications.onUpdateSpecification?.(false);

            //await causes multiple modals to show after save, void doesn't show modal on tab change
            void showModal(ConfirmationModal, {title: "", text: "В связи с внесенными изменениями этапы оплаты скорректированы", notify: true});
            LotSpecifications.specificationForms.forEach(s => {
                s.wasSpecChanged = false;
                s.supplyStages.forEach(ss => ss.hasChanges = false);
            });
        }
        setCurrentTab(tab?.id);
    };

    return <FormState value={actionToState(action)}>
        <Form header={header}
              navInset={navInset}
              tabs={law == LawType.F44 ? F44Tabs : F223Tabs}
              onTabChange={handleTabChange}/>
    </FormState>;
};
