import { IDtoSubcontractSpecifications } from "@/models/json/Contracts/SubcontractSpecifications";
import { IJsonFormattable } from "@/models/IJsonFormattable";
import Decimal from 'decimal.js';
import { ContractSpecification } from "@/models/Contracts/ContractSpecification";

export class SubcontractSpecification implements IJsonFormattable<IDtoSubcontractSpecifications> {
    constructor(
       public id: string,
       public volume: Decimal,
       public cost: Decimal,
       public tax: "TAX_0" | "TAX_10" | "TAX_20" | "TAX_NONE",
       public specification: ContractSpecification
    ) {}

    public taxPercent() {
        switch (this.tax) {
            case "TAX_10":
                return 10;
            case "TAX_20":
                return 20;
            default:
                return 0;
        }
    }

    public toJson(): IDtoSubcontractSpecifications & { specificationId: string } {
        return {
            ...this,
            volume: this.volume.toString(),
            cost: this.cost.toString(),
            specification: this.specification.toJson(),
            specificationId: this.specification.id
        };
    }

    public static fromJson(dto: IDtoSubcontractSpecifications): SubcontractSpecification {
        return Object.assign(Object.create(SubcontractSpecification.prototype), dto, {
            volume: new Decimal(dto.volume),
            cost: new Decimal(dto.cost),
            specification: ContractSpecification.fromJson(dto.specification)
        });
    }
}
