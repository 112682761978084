


















import { Component, Prop, Vue } from "vue-property-decorator";
import DataTable from "./DataTable.vue";
import {ITableColumn, Selection, ITableAction, IRowConfiguration, Identified} from '../TableAbstractions';
import { PaginationContext } from '@/api/Pagination';
import { IFilterObject } from '@/api/http';

@Component({ components: { DataTable } })
export default class PaginatedDataTable extends Vue {
    @Prop() private showFilters!: boolean;
    @Prop() private headers!: ITableColumn<unknown>[];
    @Prop({ default: () => [] }) private actions!: ITableAction[];
    @Prop({ default: null }) private paginationContext!: PaginationContext<unknown> | null;
    @Prop({ default: null }) private config!: IRowConfiguration<unknown> | null;
    @Prop({ default: null }) private selectPrevIndex!: boolean;
    @Prop({ default: null }) private selection!: Selection<Identified> | null;

    public applyFilters(filter: IFilterObject) {
        if (this.paginationContext !== null) {
            this.paginationContext.filter = filter;
            this.paginationContext.load(0);
        }
    }

    public resetFilters() {
        (this.$refs.table as unknown as {resetFilters(): void}).resetFilters();
    }
}
