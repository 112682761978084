import { convertPaged, IPagedItems } from "./Pagination";
import { http, FilterValueType, IFilterObject, FilterConditionType } from "./http";
import { SubjectClass } from "@/models/SubjectClass";
import { IDtoSubjectClass } from "@/models/json";

export default class {
    public static async get(from: number, count: number, filters: IFilterObject): Promise<IPagedItems<SubjectClass>> {
        return convertPaged(
            await http.getPaged(
                "/api/directory/subjectClasses",
                {
                    from: from,
                    count: count,
                    filters
                },
                true,
            ),
            SubjectClass,
        );
    }

    public static async getAll(codeFilter: string | null = null): Promise<SubjectClass[]> {
        return (await http.getPaged(
            "/api/directory/subjectClasses/all",
            codeFilter
                ? {
                      filters: {
                          code: [{
                              type: FilterValueType.STRING,
                              conditionType: FilterConditionType.STARTS_WITH,
                              string: codeFilter,
                            }],
                      },
                  }
                : {},
            true,
        )).map((x: IDtoSubjectClass) => SubjectClass.fromJson(x));
    }
}
