import { asDate, AutoDto, AutoModel } from "@/models/parsing";

interface SupplyStageAbsolutePeriodMD {
    startDate: Date
    endDate: Date
}

export type SupplyStageAbsolutePeriod = AutoModel<SupplyStageAbsolutePeriodMD>;
export type DtoSupplyStageAbsolutePeriod = AutoDto<SupplyStageAbsolutePeriodMD>;

export const SupplyStageAbsolutePeriod = {
    fromDto(dtoSupplyStagePeriod: DtoSupplyStageAbsolutePeriod): SupplyStageAbsolutePeriod {
        return {
            startDate: asDate(dtoSupplyStagePeriod.startDate),
            endDate: asDate(dtoSupplyStagePeriod.endDate)
        };
    },
    toDto(supplyStagesPeriod: SupplyStageAbsolutePeriod): DtoSupplyStageAbsolutePeriod {
        return {
            startDate: supplyStagesPeriod.startDate.toISOString(),
            endDate: supplyStagesPeriod.endDate.toISOString()
        };
    }
};

