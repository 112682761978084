import React, {FormEvent, useState, MouseEvent, CSSProperties, forwardRef, Ref, useEffect} from "react";
import styles from "./TextBox.module.css";
import {TextAlignProperty} from "csstype";
import { formatNumber } from "@/NumberFormatting";
import Decimal from "decimal.js";
import {PrecisionMode} from "@/components/TableAbstractions";

interface CommonTextBoxProps {
    placeholder?: string
    disabled?: boolean
    readonly?: boolean
    alignment?: TextAlignProperty

    className?: string
    style?: CSSProperties

    onClick?: (e: MouseEvent) => void
}

interface TextBoxProps extends CommonTextBoxProps {
    value?: string
    onChange?: (x: string) => void
    lines?: number
    pattern?: RegExp
    password?: boolean
}

const TextBoxUnforwarded: React.RefForwardingComponent<HTMLInputElement | HTMLTextAreaElement, TextBoxProps> = (x, ref) => {
    const pattern: RegExp = x.pattern ?? /.*/s;
    const filter = (text: string) => {
        const result = pattern?.exec(text);
        return result ? result[0] : "";
    };

    const actualValue = x.value ? filter(x.value) : "";

    const input = (ev: FormEvent) => {
        const target = ev.target as HTMLInputElement | HTMLTextAreaElement;
        x.onChange?.(filter(target.value));
    };

    return x.lines && x.lines > 1
        ? <textarea
            ref={ref as Ref<HTMLTextAreaElement>}
            className={`${styles.textarea} ${x.className ?? ""}`}
            value={actualValue}
            placeholder={x.placeholder}
            disabled={x.disabled}
            readOnly={x.readonly}
            rows={99999}
            cols={99999}
            style={{...x.style, textAlign: x.alignment, height: `${x.lines * 19 + 15}px`}}
            onChange={input}
            onClick={x.onClick}
            // 19 - line height (css below), 15 - padding (6 + 6) + magic 3px for prevent scrollbars
        />
        : <input
            ref={ref as Ref<HTMLInputElement>}
            className={`${styles.input} ${x.className ?? ""}`}
            value={actualValue}
            type={x.password ? "password" : "text"}
            style={{...x.style, textAlign: x.alignment}}
            placeholder={x.placeholder}
            disabled={x.disabled}
            readOnly={x.readonly}
            onChange={input}
            onClick={x.onClick}
        />;
};

export const TextBox = forwardRef(TextBoxUnforwarded);

interface NumberBoxProps extends CommonTextBoxProps {
    value?: number
    onChange?: (x: number | undefined) => void
}

interface DecimalBoxProps extends CommonTextBoxProps {
    value?: Decimal
    precision?: number
    precisionMode?: PrecisionMode
    onChange?: (x: Decimal | undefined) => void
}

const normalizeNumber = (n: number | undefined) => n === undefined || isNaN(n) ? "" : n.toString();
const formatDecimal = (n: Decimal | undefined, precision: number | undefined) =>
    n === undefined || n.isNaN()
        ? ""
        : precision
            ? formatNumber(n, true, precision)
            : n.toString();

const normalize = (n: string) => n.replace(",", ".").replace(/\s/g, "");
const parseNumber = (n: string) => {
    const parsed = Number(n);
    return isNaN(parsed) ? undefined : parsed;
};
const parseDecimal = (n: string) => {
    try {
        return new Decimal(normalize(n));
    } catch {
        return undefined;
    }
};

const numberBox: (pattern: RegExp) => React.FC<NumberBoxProps> = pattern => x => {
    const [actualValue, setActualValue] = useState<string>(normalizeNumber(x.value));

    const hasTypeError = typeof x.value !== "number" && typeof x.value !== "undefined";
    if (!hasTypeError && x.value !== undefined && !isNaN(x.value)) {
        if (parseNumber(normalize(actualValue)) !== x.value) {
            setActualValue(normalizeNumber(x.value));
        }
    }

    const setValue = (v: string) => {
        setActualValue(v);
        x.onChange?.(parseNumber(normalize(v)));
    };

    return <TextBox
        {...x}
        value={hasTypeError ? "type error, expected number, got " + typeof x.value : actualValue}
        onChange={setValue}
        pattern={hasTypeError ? undefined : pattern} />;
};

export const IntBox = numberBox(/-?[0-9\s]+/);
export const FloatBox = numberBox(/-?[0-9\s]+[.,]?[0-9\s]*/);

const nan = new Decimal(NaN);
export const DecimalBox: React.FC<DecimalBoxProps> = x => {
    const [actualValue, setActualValue] = useState<string>(formatDecimal(x.value, x.precision));

    // noinspection SuspiciousTypeOfGuard
    const hasTypeError = typeof x.value !== "undefined" && !(x.value instanceof Decimal);


    // to avoid react infinite render error
    useEffect(() => {
        if (!hasTypeError && x.value !== undefined && !x.value.isNaN()) {
            if (!(parseDecimal(actualValue) ?? nan).eq(x.value)) {
                setActualValue(formatDecimal(x.value, x.precision));
            }
        }
    }, [hasTypeError, x.value, x.precision, actualValue]);


    const setValue = (v: string) => {
        setActualValue(v);
        x.onChange?.(parseDecimal(v));
    };

    const regex =
        x.precision === undefined
            ? /-?[0-9\s]+[.,]?[0-9\s]*/
            : new RegExp(`-?[0-9\\s]+[.,]?[0-9\\s]{0,${x.precision}}`);

    const valueToShow = x.value === undefined ? "" : actualValue.replace(".", ",");

    return <TextBox
        {...x}
        onClick={e => e.stopPropagation()}
        value={hasTypeError ? "type error, expected Decimal, got " + typeof x.value : valueToShow}
        onChange={setValue}
        pattern={hasTypeError ? undefined : regex} />;
};
