import React, {useEffect, useMemo, useState} from "react";
import {ModalFC, showModal} from "@/EventBus";
import {convertSupplementaryMaterialsArticleToUpdate, SupplementaryMaterialsApi, SupplementaryMaterialsArticle, SupplementaryMaterialsArticleLabel, SupplementaryMaterialsArticleUpdate} from "@/api/SupplementaryMaterials";
import {CardModal} from "@/components/modals/CardModal";
import {Expandee, HStack, VStack} from "@/components/layouts";
import {Button, ComboBox, TextBox} from "@/components/primitive";
import {
    Column,
    createStoredRemoteDataset,
    FilteredSelectionTable,
    Pager,
    Table,
    useStoredRemoteDataset
} from "@/components/table";
import Editor, {theme} from "rich-markdown-editor";
import {formatDate} from "@/dateUtils";
import {Field} from "@/components/form/Field";
import {SelectOption} from "@/models/enums";
import {getMediaUrl, MediaApi} from "@/api/Media";
import {Card} from "@/components/Card";
import {ConfirmationModal} from "@/views/Contracts/modals/ConfirmationModal";

const init: SupplementaryMaterialsArticleUpdate = {};

const EditArticleModal: ModalFC<{ item?: SupplementaryMaterialsArticle }, SupplementaryMaterialsArticle> = x => {
    const [labels, setLabels] = useState<SupplementaryMaterialsArticleLabel[]>();
    const labelOptions: SelectOption<string>[] = labels?.map(l => ({ desc: l.title, key: l.id })) ?? [];

    useEffect(() => {
        SupplementaryMaterialsApi.findLabels().then(setLabels);
    }, []);

    const [update, setUpdate] = useState<SupplementaryMaterialsArticleUpdate>(x.item ? convertSupplementaryMaterialsArticleToUpdate(x.item) : init);

    const save = async () => {
        if (x.item) {
            SupplementaryMaterialsApi.update(update).then(x.done);
        } else {
            SupplementaryMaterialsApi.create(update).then(x.done);
        }
    };

    let canSave = true;
    let descriptionAlert = false;
    if (!update.labelId) canSave = false;
    if (!update.description || update.description.length >= 500) {
        canSave = false;
        descriptionAlert = true;
    }
    if (!update.title) canSave = false;
    if (!update.mediaId) canSave = false;

    const close = async () => {
        const confirm = await showModal(ConfirmationModal, {});
        if (!confirm) return;
        x.done(undefined);
    };

    const bottom: React.ReactNode = <HStack spacing="10px">
        <Expandee />
        <Button color="white" title="Закрыть" onClick={close} />
        <Button color="blue" title="Сохранить" disabled={!canSave} onClick={save} />
    </HStack>;

    return <CardModal title={x.item ? "Редактирование файла" : "Создание файла"}
                      close="disabled"
                      bottom={bottom}
                      width="620px">
        <VStack spacing="10px">
            <Field required title="Заголовок">
                <TextBox value={update.title}
                         onChange={v => setUpdate({ ...update, title: v })} />
            </Field>
            <Field required title={`Описание (${update.description?.length ?? 0}/500)`}>
                <TextBox lines={5}
                         style={descriptionAlert ? { borderColor: "red" } : undefined}
                         placeholder={"Введите краткое описание загруженного файла - не более 500 символов"}
                         value={update.description ?? undefined}
                         onChange={v => setUpdate({ ...update, description: v })} />
            </Field>
            <Field required title="Лейбл">
                <ComboBox options={labelOptions} value={update.labelId} onChange={v => setUpdate({ ...update, labelId: v })} />
            </Field>
            <Field title="Файл" required>
                <input type="file"
                       multiple={false}
                       onChange={e => MediaApi.uploadFileHandler(e).then(v => setUpdate({ ...update, mediaId: v?.id ?? "123" }))} />
            </Field>
        </VStack>
    </CardModal>;
};

export const SupplementaryMaterialsAdminSection: React.FC = () => {
    const create = async () => {
        await showModal(EditArticleModal, {});
        rds.setCurrentPage(rds.currentPage, true);
    };


    const srds = useMemo(() => createStoredRemoteDataset<SupplementaryMaterialsArticle, { }>({
        initialFilters: {},
        async load(filters, from, count) {
            return await SupplementaryMaterialsApi.findAll({ filters, from, count });
        }
    }), []);
    const rds = useStoredRemoteDataset(srds);

    const edit = async (v: SupplementaryMaterialsArticle) => {
        await showModal(EditArticleModal, { item: v });
        rds.setCurrentPage(rds.currentPage, true);
    };

    const remove = async (v: SupplementaryMaterialsArticle) => {
        const confirm = await showModal(ConfirmationModal, {});
        if (!confirm) return;
        await SupplementaryMaterialsApi.remove(v.id);
        rds.setCurrentPage(rds.currentPage, true);
    };

    const columns: Column<SupplementaryMaterialsArticle>[] = [
        Table.Column("Заголовок", x => <>{x.item.title}</>),
        Table.Column("Описание", x => <>{x.item.description}</>),
        Table.Column("Создан", x => <>{formatDate(new Date(x.item.createdAt))}</>),
        Table.Column("Медиа", x => <a href={x.item.media ? getMediaUrl(x.item.media) : undefined}>{x.item.media?.originalName ?? "-"}</a>),
        Table.AutoColumn("", x => <Button icon="gPencil" color="blue" onClick={() => edit(x.item)} /> ),
        Table.AutoColumn("", x => <Button icon="faTrash" color="red" onClick={() => remove(x.item)} /> ),
    ];

    const title = <div style={{ display: "flex", gap: "20px" }}>
        <span>Управление файлами</span>
        <Expandee />
        <Button title="Создать" color="green" icon="aAdd" onClick={create} />
    </div>;

    return <Card style={{ display: "flex", flexFlow: "column", gap: "20px" }} title={title}>
        <FilteredSelectionTable dataset={rds.dataset}
                                selected={[]}
                                isFiltersVisible={false}
                                columns={columns}
                                filterStore={rds.filterStore} selectorPosition="hidden" mode="multi" />
        <Pager remoteDataset={rds} />
    </Card>;
};
