import { Component, Prop, Vue, Watch } from "vue-property-decorator";
import EventBus from '@/EventBus';
import TimePickerModal from "./TimePickerModal.vue";
import { Time } from './TimePickerModalEventArgs';
import { isThisQuarter } from 'date-fns';

@Component
export default class TimePicker extends Vue {
    @Prop()
    public value!: Date | null;
    
    @Prop({ default: false })
    public readonly!: boolean;

    @Prop({ default: false })
    public disabled!: boolean;

    private isSet = false;
    public get formatted() {
        if (!this.isSet) return "--:--";
        return this.time.hours.toString().padStart(2, '0') + ":" + 
            this.time.minutes.toString().padStart(2, '0');
    }

    public time: Time = { hours: 0, minutes: 0 };
    private skipRefresh = false;

    @Watch("time.hours") @Watch("time.minutes") public updateTime() {
        this.isSet = true;

        if (this.value) return;
        this.value!.setHours(this.time.hours);
        this.value!.setMinutes(this.time.minutes);
    }

    @Watch("value") public setValue(newv: Date | null, oldv: Date | null) {
        if (oldv === null && newv !== null) {
            this.updateTime();
            this.isSet = true;
            return;
        } else {
            if (newv !== null) {
                this.time.hours = newv.getHours();
                this.time.minutes = newv.getMinutes();
            }
            this.isSet = newv !== null;
        }
    }

    // @Watch("model.value") public updateValue() {
    //     if (this.skipRefresh) {
    //         this.skipRefresh = false
    //         return
    //     }

    //     this.$emit("change", this.model.value)
    // }

    public created() {
        this.skipRefresh = true;

        if (this.value) {
            this.time.hours = this.value.getHours();
            this.time.minutes = this.value.getMinutes();
            this.isSet = true;
        }
    }

    private forceClosePromiseResolver: (() => void) | null = null;

    public show() {
        if (this.readonly || this.disabled) return;

        const el = this.$el as HTMLElement;
        const rect = el.getBoundingClientRect();

        const modalHeight = 162;
        const innerHeight = window.innerHeight;

        let top = rect.top + 35;

        if (top + modalHeight > innerHeight) {
            if (rect.top - modalHeight - 5 >= 0) {
                top = rect.top - modalHeight - 5;
            } else {
                top = rect.top - modalHeight / 2;
            }
        }

        if (this.forceClosePromiseResolver)
            this.forceClosePromiseResolver();

        const forceClosePromise = new Promise(resolve => this.forceClosePromiseResolver = resolve);

        EventBus.callModal(TimePickerModal, {
            time: this.time,
            top: top,
            left: rect.left,
            forceClose: forceClosePromise
        });
    }
    
    public hide() {
        // do nothing (causes bugs)
    }
}
