import { Component, Prop, Vue } from "vue-property-decorator";
import CardModal from "@/components/CardModal.vue";
import TreeView from "@/components/TreeView.vue";

import ActionClasses from "@/api/ActionClasses";
import { ModalVue, ModalResult } from "@/view-models/ModalRequest";
import { ActionClass } from "@/models/ActionClass";
import {SubjectClass} from "@/models";

interface IActionClassContainer {
    id: number
    object: ActionClass
    collapsed: boolean
    selected: boolean
    level: number
}

let loadedClasses: IActionClassContainer[] | null = null;

@Component({ components: { CardModal, TreeView } })
export default class ActionClassSelectionModal extends ModalVue<ActionClass | null, ActionClass> {
    @Prop({ default: null }) private prop!: ActionClass | null;
    @Prop() private visible!: boolean;

    private entries: IActionClassContainer[] = [];
    private filter = "";

    private mounted() {
        const code = this.prop ? this.prop.code : "INVALID";

        if (loadedClasses) {
            this.entries = loadedClasses;
            for (const entry of this.entries) {
                entry.collapsed = !code.startsWith(entry.object.code) || code === entry.object.code;
                entry.selected = this.prop ? this.prop.id === entry.id : false;
            }
        } else {
            ActionClasses.getAll(this.prop ? this.prop.code.split(".")[0] : null).then(response => {
                this.entries = response
                    .sort((x, y) => (x.code > y.code ? 1 : -1))
                    .map(x => ({
                        id: x.id,
                        object: x,
                        collapsed: !code.startsWith(x.code) || code === x.code,
                        selected: this.prop ? this.prop.id === x.id : false,
                        level: x.code.split(".").length - 1,
                    }));
                loadedClasses = this.entries;
            });
        }
    }

    private subjClassPredicate(item: {object: SubjectClass}, filter: string) {
        return (
            filter === null ||
            filter.length < 3 ||
            item.object.description.toLowerCase().includes(filter.toLowerCase()) ||
            item.object.code.startsWith(filter)
        );
    }

    private resetItemsCollapse() {
        for (const item of this.entries) {
            item.collapsed = !this.filter || this.filter.length < 3;
        }
    }

    private selectItemAndDeselectOther(selected: unknown) {
        for (const item of this.entries) {
            item.selected = item === selected;
        }
    }

    private selectItemAndExit() {
        for (const item of this.entries) {
            if (item.selected) {
                this.$emit("close-modal", ModalResult.ok(item.object));
                return;
            }
        }
        this.$emit("close-modal");
    }
}
