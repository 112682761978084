import {AutoDto, AutoModel} from "@/models/parsing";

interface ProcedureOrderMD {
    address?: string
    room?: string
    email?: string
    phone?: string
    fax?: string
    contactPerson?: string
    copies?: number
    default?: boolean
    text?: string
}

export type ProcedureOrder = AutoModel<ProcedureOrderMD>;
export type DtoProcedureOrder = AutoDto<ProcedureOrderMD>;

export const ProcedureOrder = {
    toDto(ord: ProcedureOrder): DtoProcedureOrder {
        return {
            ...ord,
        };
    },
    fromDto(ord: DtoProcedureOrder): ProcedureOrder {
        return {
            ...ord,
            address: ord.address ?? undefined,
            room: ord.room ?? undefined,
            email: ord.email ?? undefined,
            phone: ord.phone ?? undefined,
            fax: ord.fax ?? undefined,
            contactPerson: ord.contactPerson ?? undefined,
            copies: ord.copies ?? undefined,
            default: ord.default ?? undefined,
            text: ord.text ?? undefined,
        };
    },
};

