import React, {useContext, useState} from "react";
import {Card} from "@/components/Card";
import {SectionProps} from "@/views/ComposedLot/shared/store";
import {Button, ComboBox, Label, TextBox} from "@/components/primitive";
import {Expandee, HSpread, HStack, VStack} from "@/components/layouts";
import {Field, Fields} from "@/components/form/Field";
import {CardModal} from "@/components/modals/CardModal";
import {ModalFC, ReactModalProps, showModal} from "@/EventBus";
import {Table} from "@/components/table/Table";
import {FormStateContext} from "@/components/form";
import {useMappedStore} from "@/storeUtils";
import {SelectionTable} from "@/components/table/SelectionTable";
import {fullName, Person, setClientResponsiblePerson} from "@/views/ComposedLot/shared/store/responsibilityInfo";

const ResponsibilityInfoModal: ModalFC<{ persons: Person[]; selected: undefined | Person }> = x => {

    const bottom = <HStack spacing={"10px"}>
        <Expandee/>
        <Button color={"green"} title={"Выбрать"} onClick={() => setClientResponsiblePerson(selected) && x.done()}/>
        <Button icon={"aClose"} onClick={x.done}/>
    </HStack>;

    const [selected, setSelected] = useState<Person>(x.selected !== undefined ? x.selected : x.persons[0]);
    const onSelect = (sel?: Person): void => {
        if (sel)
            setSelected(sel);
    };

    return <CardModal title={"Выбор контактного лица"} close={x.done} width={"70%"} bottom={bottom}>
        <SelectionTable<Person> mode={"single"}
                                selected={selected}
                                onChange={onSelect}
                                selectorPosition={"hidden"}
                                dataset={x.persons}
                                columns={[
                                    Table.AutoColumn("Основной", x => <div>{x.item.isGeneral ? "Да" : "Нет"}</div>),
                                    Table.AutoColumn("Фамилия", x => <div>{x.item.lastName}</div>),
                                    Table.AutoColumn("Имя", x => <div>{x.item.firstName}</div>),
                                    Table.AutoColumn("Отчество", x => <div>{x.item.middleName}</div>),
                                    Table.AutoColumn("E-mail", x => <div>{x.item.email}</div>),
                                    Table.AutoColumn("Телефон", x => <div>{x.item.cellPhone}</div>),
                                ]}/>
    </CardModal>;
};

export const ResponsibilityInfoSection: React.FC<SectionProps> = x => {

    const state = useContext(FormStateContext);

    const sectionStore = useMappedStore(x.formStore, x => ({
        persons: x.relatedData.persons,
        clientResponsiblePerson: x.responsibilityInfo.clientResponsiblePerson,
        clientResponsibleContractPerson: x.responsibilityInfo.clientResponsibleContractPerson
    }));

    const cardHeader = <HSpread alignItems="center">
        <span>Информация об ответственном должностном лице и контрактной службе</span>
    </HSpread>;


    return <Card title={cardHeader}>
        <VStack spacing={"10px"}>
            <div>
                <HStack spacing={"10px"}>
                    <Label preset={"headerLarge"} text={"Информация об ответственном должностном лице заказчика"}/>
                    {
                        x.formAction === "editing" &&
                            <Button icon={"aEdit"}
                                    onClick={async () => await showModal(ResponsibilityInfoModal, {
                                        persons: sectionStore.persons,
                                        selected: sectionStore.clientResponsiblePerson
                                    })}/>
                    }
                    <Expandee/>
                </HStack>
            </div>
            <Fields orientation={"vertical"}>
                <Field required title={"Контактное лицо"}><Label text={fullName(sectionStore.clientResponsiblePerson)}/></Field>
                <Field required title={"Телефон"}><Label text={sectionStore.clientResponsiblePerson ? sectionStore.clientResponsiblePerson.cellPhone : ""}/></Field>
                <Field required title={"Электронная почта"}><Label text={sectionStore.clientResponsiblePerson ? sectionStore.clientResponsiblePerson.email : ""}/></Field>
            </Fields>
            <div>
                <HStack spacing={"10px"}>
                    <Label preset={"headerLarge"}
                           text={"Информация о контрактной службе/управляющем, ответственных за заключение контракта"}/>
                    <Expandee/>
                </HStack>
            </div>
            <Fields orientation={"vertical"}>
                <Field required title={"Контактное лицо"}><Label text={fullName(sectionStore.clientResponsibleContractPerson)}/></Field>
                <Field required title={"Телефон"}><Label text={sectionStore.clientResponsibleContractPerson ? sectionStore.clientResponsibleContractPerson.cellPhone : ""}/></Field>
                <Field required title={"Электронная почта"}><Label text={sectionStore.clientResponsibleContractPerson ? sectionStore.clientResponsibleContractPerson.email : ""}/></Field>
            </Fields>
        </VStack>
    </Card>;
};
