import { http, IFilterObject } from "@/api/http";
import { LawType } from "@/models/enums";
import { uuid } from "@/models/parsing";
import { IPagedItems, mapPage } from "@/api/Pagination";
import { DtoProcedure, Procedure, ProcedureStatus, ProcedureTemplate } from "@/models/Procedures2020/Procedure";
import { DtoProcedureDocument, ProcedureDocument } from "@/models/Procedures2020/ProcedureDocument";
import { DtoProcedureLot, ProcedureLot } from "@/models/Procedures2020/ProcedureLot";
import { PublishTerms } from "@/models/Procedures2020/PublishTerms";
import {DtoHistoryItem, HistoryItem, Participant} from "@/models";

export const getProcedures = async (from: number, count: number, filters?: IFilterObject): Promise<IPagedItems<Procedure>> => {
    return mapPage(Procedure, await http.getPaged("/api/v2/procedures", {
        from: from,
        count: count,
        filters: filters
    }));
};

export const deleteProcedure = async (id: uuid): Promise<void> =>
    await http.delete(`/api/v2/procedures/${id}`);

export const getProcedure = async (id: uuid): Promise<Procedure> => (
    Procedure.fromDto(await http.get(`/api/v2/procedures/${id}`) as DtoProcedure)
);

export const getProcedureDocuments = async (id: uuid): Promise<ProcedureDocument[]> => (
    (await http.get(`/api/v2/procedures/${id}/documents`) as DtoProcedureDocument[]).map(ProcedureDocument.fromDto)
);

export const getProcedureLots = async (id: uuid): Promise<ProcedureLot[]> => (
    (await http.get(`/api/v2/procedures/${id}/lots`) as DtoProcedureLot[]).map(ProcedureLot.fromDto)
);

export const generateProcedureDocument = async (id: uuid): Promise<void> => {
    await http.post(`/api/v2/procedures/${id}/generateProcedureDocument`, null);
};

export const createProcedure = async (law: LawType, template: ProcedureTemplate): Promise<Procedure> =>
    Procedure.fromDto(await http.post(`/api/v2/procedures?law=${law}`, ProcedureTemplate.toDto(template)));

export const updateProcedure = async (id: uuid, proc: ProcedureTemplate): Promise<Procedure> =>
    Procedure.fromDto(await http.put(`/api/v2/procedures/${id}`, ProcedureTemplate.toDto(proc)));

export const sendProcedureToGrbs = async (id: uuid): Promise<Procedure> =>
    Procedure.fromDto(await http.post(`/api/v2/procedures/${id}/sendToGrbs`, null));

export const sendProcedureToFO = async (id: uuid): Promise<Procedure> =>
    Procedure.fromDto(await http.post(`/api/v2/procedures/${id}/sendToFO`, null));

export const revokeFromGrbs = async (id: uuid): Promise<Procedure> =>
    Procedure.fromDto(await http.post(`/api/v2/procedures/${id}/revokeGrbs`, null));

export const publishProcedure = async (id: uuid): Promise<Procedure> =>
    Procedure.fromDto(await http.post(`/api/v2/procedures/${id}/publish`, null));

export const confirmPublishProcedure = async (id: uuid): Promise<Procedure> =>
    Procedure.fromDto(await http.post(`/api/v2/procedures/${id}/confirmPublish`, null));

export const dismissProcedure = async (id: uuid): Promise<Procedure> =>
    Procedure.fromDto(await http.post(`/api/v2/procedures/${id}/dismiss`, null));

export const finishTrade = async (id: uuid): Promise<Procedure> =>
    Procedure.fromDto(await http.post(`/api/v2/procedures/${id}/finishTrade`, null));

export const setPublishTerms = async (id: uuid, publishTerms: PublishTerms): Promise<Procedure> =>
    Procedure.fromDto(await http.post(`/api/v2/procedures/${id}/setPublishTerms`, PublishTerms.toTemplate(publishTerms)));

export const getProcedureHistory = async (id: uuid): Promise<HistoryItem<ProcedureStatus>[]> =>
    (await http.get(`/api/v2/procedures/${id}/history`) as DtoHistoryItem<ProcedureStatus>[]).map(a => HistoryItem.fromDto(a));

export const getProtocol = async (id: uuid, procedure: Procedure): Promise<Procedure> =>
    (await http.put(`api/v2/procedures/${id}/getProtocol`, procedure));

export const setParticipant = async (id: uuid, procedureParticipantId: uuid, participantId?: number): Promise<Procedure> =>
    (await http.put(`api/v2/procedures/${id}/setParticipant`, {participantId: participantId ? participantId : null, procedureParticipantId: procedureParticipantId}));
