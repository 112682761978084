import React, { ReactNode } from "react";
import { Card } from "@/components/Card";
import { Column, Table } from "@/components/table";
import { Provision } from "@/models/Provision/Provision";
import { formatDate } from "@/dateUtils";

const LinkImitation = ({ children }: { children: ReactNode }) =>
    <div style={{ color: "#428bca", textDecoration: "underline", cursor: "pointer", userSelect: "none" }}>
        {children}
    </div>;

type EisExchangeSection = {
    provision?: Provision
};

export const EisExchangeSection = ({ provision }: EisExchangeSection) => {
    const columns: Column<never>[] = [
        Table.Column(
            "Номер",
            () => <>1</>
        ),
        Table.Column(
            "Исходящий файл",
            () => <LinkImitation>xml_to_oos.xml</LinkImitation>
        ),
        Table.Column(
            "Дата отправки",
            () => <>{provision?.approvedAt ? formatDate(provision.approvedAt) : ""}</>
        ),
        Table.Column(
            "Подтверждение получения",
            () => <LinkImitation>xml_to_oos.xml</LinkImitation>
        ),
        Table.Column(
            "Дата приема подтверждения",
            () => <>{provision?.approvedAt ? formatDate(provision.approvedAt) : ""}</>
        ),
        Table.Column(
            "Сведения о публикации",
            () => <>1</>
        ),
        Table.Column(
            "Дата получения сведений",
            () => <>1</>
        )
    ];

    return (
        <Card>
            <Table columns={columns} dataset={[{} as never]} />
        </Card>
    );
};
