

















import { SelectOption } from "@/models/enums";
import { Component, Vue, Prop } from "vue-property-decorator";

@Component
export default class RadioGroup extends Vue {
    @Prop({ default: false }) private inline!: boolean;
    @Prop({ default: false }) private readonly!: boolean;
    @Prop({ default: true }) private enabled!: boolean;
    @Prop() private value!: unknown;
    @Prop({ default: () => [] }) private options!: Array<SelectOption<unknown>>;
    @Prop({ default: "" }) private group!: string;
}
