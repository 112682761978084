import * as t from "io-ts";
import { participant, spgz } from "./DirectPurchases";
import { http2 as http, IPagedRequest } from './http';
import { handleCodecErrors, handleErrors, pagedCodec } from './httpHelpers';

export const offer = t.type({
    id: t.string,
    priceWithNds: t.number,
    participant
});

const characteristic = t.type({
    name: t.union([t.undefined, t.null, t.string]),
    value: t.union([t.undefined, t.null, t.string]),
});

export const productCodec = t.type({
    id: t.string,
    name: t.string,
    steId: t.union([t.number, t.null]),
    offers: t.array(offer),
    characteristics: t.union([t.undefined, t.null, t.array(characteristic)]),
    model: t.union([t.undefined, t.null, t.string]),
    manufacturer: t.union([t.undefined, t.null, t.string]),
    partNumber: t.union([t.undefined, t.null, t.string]),
    isPopular: t.union([t.undefined, t.null, t.boolean]),
    isFavorite: t.union([t.undefined, t.null, t.boolean]),
    referentPrice: t.union([t.undefined, t.null, t.string]),
    spgz
});

const pageOfProductsCodec = pagedCodec(productCodec);

const pageOfUnknownCodec = pagedCodec(t.unknown);

export type Characteristic = t.TypeOf<typeof characteristic>;

export type Offer = t.TypeOf<typeof offer>;

export type Product = t.TypeOf<typeof productCodec>;

export type PageOfProducts = t.TypeOf<typeof pageOfProductsCodec>;

export type PageOfUnknown = t.TypeOf<typeof pageOfUnknownCodec>;

export class ProductController {
    public static async get(request: IPagedRequest, favorite: boolean) {
        return await http.getPagedWithFavorite("/api/v2/products", request, favorite)
            .then(handleErrors)
            .then(result => pageOfProductsCodec.decode(result))
            .then(handleCodecErrors);
    }
    public static async getProduct(id: string) {
        return await http.get(`/api/v2/products/${id}`)
            .then(handleErrors)
            .then(result => productCodec.decode(result))
            .then(handleCodecErrors);
    }
    public static async setFavorite(id: string, favorite: boolean) {
        return await http.patch(`/api/v2/products/${id}?isFavorite=${favorite ? 'true' : 'false'}`)
            .then(handleErrors)
            .then(result => pageOfProductsCodec.decode(result))
            .then(handleCodecErrors);
    }
}