import { showModal } from '@/EventBus';
import { ErrorModal } from '@/views/Contracts/modals/ConfirmationModal';
import { either } from 'fp-ts';
import * as t from "io-ts";
import { Errors, Validation } from "io-ts";
import { reject } from 'lodash';
import { ApiError } from './ApiError';

export const pagedCodec = <C extends t.Mixed>(codec: C) =>
    t.type({
        totalCount: t.number,
        from: t.number,
        to: t.number,
        items: t.array(codec)
    });

export const arrayCodec = <C extends t.Mixed>(codec: C) =>
    t.array(codec);

export const handleCodecErrors = <T>(input: Validation<T>) =>
    new Promise<T>((resolve, _) => {
        either.fold<Errors, T, void>(
            e => {
                console.error('Codec error', e);
                return reject(e);
            },
            t => resolve(t)
        )(input);
    });

export const handleErrors = <T>(input: null | string | ApiError | T) => {
    if (input !== null && Object.prototype.hasOwnProperty.call(input, 'cause')) {
        const error = (input as ApiError).cause.join();

        showModal(ErrorModal, { text: error });
        throw new Error(error);
    }

    if (typeof input === "string") {
        const error = (input as string);

        showModal(ErrorModal, { text: error });
        throw new Error(error);
    }
    return input as T;
};