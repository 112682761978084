// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_GET_URL_IMPORT___ = require("../../../../../../../node_modules/css-loader/dist/runtime/getUrl.js");
var ___CSS_LOADER_URL_IMPORT_0___ = require("@/assets/icons/chevron-icon.svg");
exports = ___CSS_LOADER_API_IMPORT___(false);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
// Module
exports.push([module.id, "._2z5AxgWOXw5I7yMAXPlsP {\n    cursor: pointer;\n}\n\n._2z5AxgWOXw5I7yMAXPlsP.E8Hm11Q7Fou77pluuBZg4 > ._1uG2z0QchvoRhnFoiHRajf {\n    opacity: 1;\n}\n\n._1uG2z0QchvoRhnFoiHRajf {\n    display: inline-block;\n    width: 12px;\n    height: 12px;\n    margin-right: 10px;\n    margin-bottom: -1px;\n    opacity: .5;\n    background: url(" + ___CSS_LOADER_URL_REPLACEMENT_0___ + ") no-repeat;\n    rotate: 180deg;\n}\n\n._1efK9ibHPSHEh5LWxcvEdB {\n    border-collapse: collapse;\n}\n\n._3ejEk7JKMyfMQfeenM0cy {\n    border-top: 1px solid rgb(229, 232, 238);\n    border-bottom: 1px solid rgb(229, 232, 238);\n}\n\n._1R5DajaA_P2b1cyZoS7-Vb {\n    font-weight: 400;\n    color: rgba(153, 153, 153);\n}\n\n._3lwP-wFlt6hj5eFN03S9F9 {\n    border-bottom: 1px solid rgb(229, 232, 238);\n}\n\n.MX0-X1vAuopT3xxI50nZa {\n    padding: 10px 3%;\n}\n\n._3hmYcmwlI_3EzJ_VxZ4eXq {\n    display: flex;\n    justify-content: center;\n}\n\n._2qIBG2PwKVku63cRp8Ph-w {\n    display: grid;\n    grid-template-columns: 120px 150px 160px 160px 1fr 40px;\n    padding: 10px 5px 10px 64px;\n    border-top: 1px solid rgb(229, 232, 238);\n    border-bottom: 1px solid rgb(229, 232, 238);\n    align-items: center;\n    column-gap: 10px;\n    justify-items: center;\n}\n\n._1xphqExzChLJjQr-kw4MM0 {\n    color: #f66;\n    justify-content: center;\n    display: flex;\n    padding: 10px;\n}\n\n._2G8F6Zal7G9i948zgshxoF {\n    color: #f66;\n    padding: 10px 5px 10px 64px;\n    text-align: center;\n}\n\n._2EF3oYV4-S6gDO9A0Ht3Wo {\n    color: #f66;\n    position: absolute;\n    left: 0;\n    top: 35px;\n}", ""]);
// Exports
exports.locals = {
	"subject": "_2z5AxgWOXw5I7yMAXPlsP",
	"opened": "E8Hm11Q7Fou77pluuBZg4",
	"expanderIcon": "_1uG2z0QchvoRhnFoiHRajf",
	"widgetTable": "_1efK9ibHPSHEh5LWxcvEdB",
	"widgetHeaderRow": "_3ejEk7JKMyfMQfeenM0cy",
	"widgetHeaderItem": "_1R5DajaA_P2b1cyZoS7-Vb",
	"widgetStageRow": "_3lwP-wFlt6hj5eFN03S9F9",
	"widgetRowCell": "MX0-X1vAuopT3xxI50nZa",
	"widgetContentWrapper": "_3hmYcmwlI_3EzJ_VxZ4eXq",
	"widgetGridRow": "_2qIBG2PwKVku63cRp8Ph-w",
	"widgetEmptyError": "_1xphqExzChLJjQr-kw4MM0",
	"errorText": "_2G8F6Zal7G9i948zgshxoF",
	"volumeError": "_2EF3oYV4-S6gDO9A0Ht3Wo"
};
module.exports = exports;
