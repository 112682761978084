import {http} from "@/api/http";
import {FormEvent} from "react";
import EventBus from "@/EventBus";

export type Webinar = {
    channelID: number
    description: string
    pending?: boolean
    id: number
    published: boolean
    topic: number
    videoDate: string // instant
    videoName: string
    thumbnailLocation?: string
    videoLocation: string
};

export type WebinarUpdate = {
    description?: string
    published?: boolean
    videoDate?: string // instant
    videoName?: string
    thumbnailLocation?: string
};

export type WebinarChannel = {
    channelImage?: string | null
    channelName: string
    description: string
    nextStreamDate?: string | null // instant
    offlineImageLocation?: string | null
    chatEnabled: boolean
};

export type WebinarChannelUpdate = {
    channelName?: string
    description?: string
    offlineImageLocation?: string | null
    nextStreamDate?: string | null // instant
    chatEnabled?: boolean
};


export function convertWebinarToUpdate(p: Webinar): WebinarUpdate {
    return {
        description: p.description,
        published: p.published,
        videoDate: p.videoDate,
        videoName: p.videoName,
    };
}

function spin<T>(promise: Promise<T>): Promise<T> {
    EventBus.discardError();
    EventBus.$emit("show-webinars-spinner", promise);

    return promise;
}

export const WebinarsApi = {
    url: "",
    update: async (id: number, payload: WebinarUpdate): Promise<Webinar> => {
        const result = await spin(http.put(`/api/v2/webinars/video/${id}`, payload));
        console.log("Update request for id ", id, { payload }, { result });

        return result;
    },
    findStreamKey: async (channelLoc: string): Promise<{ streamKey: string }> => {
        return await spin(http.get(`/api/v2/webinars/channel/${channelLoc}/streamKey`));
    },
    updateStreamKey: async (p: string, channelLoc: string): Promise<{ streamKey: string }> => {
        return await spin(http.put(`/api/v2/webinars/channel/${channelLoc}/streamKey`, { streamKey: p }));
    },
    ospAuth: async (): Promise<void> => {
        return await spin(http.post("/api/v2/webinars/ospAuth", undefined));
    },
    findAll: async (): Promise<Webinar[]> => {
        const result = await spin(http.get("/api/v2/webinars/video"));
        return result;
    },
    findArchived: async (): Promise<Webinar[]> => {
        const result = await spin(http.get("/api/v2/webinars/video/archived"));
        return result;
    },
    remove: async (id: number) => {
        const result = await spin(http.delete(`/api/v2/webinars/video/${id}`));
        return result;
    },
    publish: async (id: number) => {
        const result = await spin(http.put(`/api/v2/webinars/video/${id}/publish`, undefined));
        console.log("Publish request for id ", id, result);
        return result;
    },
    unpublish: async () => {
        const result = await spin(http.delete(`/api/v2/webinars/channel/announcement`));
        return result;
    },
    getChannel: async (channelLoc: string): Promise<WebinarChannel> => {
        const result = await spin(http.get(`/api/v2/webinars/channel/${channelLoc}`));
        return result;
    },
    updateChannel: async (channelLoc: string, upd: WebinarChannelUpdate) => {
        const result = await spin(http.put(`/api/v2/webinars/channel/${channelLoc}`, upd));
        return result;
    },
    async uploadChannelMedia(d: FormData): Promise<{ id: string; path: string }> {
        const result = spin(fetch("/api/v2/webinars/channel/upload", {
            method: "POST",
            body: d
        }).then(r => r.json()));
        return result;
    },
    uploadChannelFileHandler: async (d: FormEvent<HTMLInputElement>): Promise<{ id: string; path: string }> => {
        const f = (d.target as HTMLInputElement).files;
        if (!f || !f.length) throw new Error("No files selected");

        const formData = new FormData();

        for (let i = 0; i < f.length; i++) {
            formData.append("file", f[i]);
        }
        const fileName = f[0].name;
        const result = await WebinarsApi.uploadChannelMedia(formData);
        return result;
    },
    async uploadVideo(
        d: FormData,
        onProgress: (p: { loaded: number; total: number }) => void,
        abortController?: AbortController
    ): Promise<{ id: string; path: string }> {
        return await new Promise((resolve, reject) => {
            const xhr = new XMLHttpRequest();
            if (abortController) {
                xhr.onabort = () => reject(new Error("aborted"));
                abortController.signal.addEventListener("abort", () => xhr.abort());
            }
            // xhr.onprogress = onProgress;
            xhr.upload.onprogress = onProgress;
            xhr.open("POST", "/api/v2/webinars/video/upload");
            // xhr.setRequestHeader("Authorization", "Bearer " + http.token);
            // xhr.onload = () => resolve();
            xhr.onreadystatechange = () => {
                if (xhr.readyState === 4 && xhr.status === 200) {
                    const json = JSON.parse(xhr.response);
                    resolve(json);
                }
            };
            xhr.onerror = e => reject(e);
            xhr.send(d);
        });
    },
    uploadVideoHandler: async (
        d: FormEvent<HTMLInputElement>,
        onProgress: (p: { loaded: number; total: number }) => void,
        abortController?: AbortController
    ): Promise<{ id: string; path: string }> => {
        const f = (d.target as HTMLInputElement).files;
        if (!f || !f.length) throw new Error("No files selected");

        const formData = new FormData();

        for (let i = 0; i < f.length; i++) {
            formData.append("file", f[i]);
        }
        const fileName = f[0].name;
        const result = await spin(WebinarsApi.uploadVideo(formData, onProgress, abortController));
        console.log("upload result", result);
        return result;
    },
    createVideo: async (p: WebinarUpdate & { videoLocation: string }) => {
        const result = await spin(http.post("/api/v2/webinars/video", p));
        return result;
    }
};