import React, { useState } from "react";
import styles from '../CreateSession.module.css';
import {
    deleteLotDocuments,
    ServicesStore, updateLotDocumentType, uploadLotDocuments
} from "../store";
import moment from "moment";
import { PrimaryButton } from "@/components/eshop";
import { Select } from "@/components/eshop/Select/Select";
import { TooltipIcon } from "@/components/TooltipIcon";
import { firstMaybe } from "@/arrayUtils";
import { LotDocument, Status } from "@/api/Sessions";
import { createLotDocumentFromTemplate } from "../../form-renderers/DocumentationSection/documentation";
import { formatBytes, HeaderWithTooltip } from "./CreateSession";
import { DragArea } from "./DragArea";
import { showModal } from "@/EventBus";
import { DocumentWizardModal } from "../../ComposedLot/edit/tabs/lot-documents/DocumentsSection/wizard/DocumentWizardModal";
import {Section} from "@/components/eshop/Section/Section";
import {Card} from "@/components/eshop/Card/Card";

interface LotDocumentsCardProps {
    store: ServicesStore
    status: Status | undefined
    showDocumentHistory: boolean
}

export const LotDocumentsCard = (props: LotDocumentsCardProps) => {
    const { store, status, showDocumentHistory } = props;
    const hasContractProject = store.lotDocuments.findIndex(d => d.type == 'CONTRACT_PROJECT') !== -1;
    const lot = store.lotsPage.items.find(i => i.regNumber.toString() === store.lotRegNumber?.toString());

    const onCreateProject = async () => {
        if (lot && !hasContractProject) {

            const wizardResult = await showModal(DocumentWizardModal, { organization: store.law === "F44" ? "EM-F44" : "EM-F223" });
            if (!wizardResult) return;
            const result = await createLotDocumentFromTemplate("contract", lot);
            if (result) {
                const document = {
                    id: result.file.id,
                    file: {
                        name: result.file.name,
                        length: result.file.length,
                        comment: result.file.comment || '',
                        createdAt: new Date().toISOString()
                    },
                    type: result.type
                };
                uploadLotDocuments([document]);
            }
        }
    };

    return (
        <Section canBeHidden required title={<>Документы котировочной сессии<TooltipIcon /></>}>
            <Card direction={"vertical"}>
                <div className={styles.createContractProject}>
                    <PrimaryButton
                        disabled={status !== 'DRAFT' || hasContractProject || !lot}
                        onClick={onCreateProject}>
                        + Сформировать проект {store.law === "F44" ? 'контракта' : 'договора'}
                    </PrimaryButton>
                    <PrimaryButton
                        disabled={status !== 'DRAFT' || hasContractProject || !lot}
                        onClick={onCreateProject}>
                        + Сформировать и изменить проект {store.law === "F44" ? 'контракта' : 'договора'}
                    </PrimaryButton>
                </div>
                <table className={styles.cardTable}>
                    <thead>
                    <tr className={styles.tableRow}>
                        <HeaderWithTooltip>Тип документа</HeaderWithTooltip>
                        <HeaderWithTooltip>Примечание</HeaderWithTooltip>
                        <HeaderWithTooltip>Наименование</HeaderWithTooltip>
                        <HeaderWithTooltip>Сформирован/добавлен</HeaderWithTooltip>
                        <HeaderWithTooltip>Размер</HeaderWithTooltip>
                        <td className={styles.cell}></td>
                        {/* <HeaderWithTooltip></HeaderWithTooltip> */}
                    </tr>
                    </thead>
                    <tbody>
                    {store.lotDocuments.map((d, i) => (<DocumentRow key={i} document={d} index={i} status={status} />))}
                    </tbody>
                </table>
                {(status === 'DRAFT' || status === undefined) && <DragArea />}
                {showDocumentHistory && <table className={styles.cardTable}>
                    <thead>
                    <tr className={styles.tableRow}>
                        <HeaderWithTooltip>Дата и время</HeaderWithTooltip>
                        <HeaderWithTooltip>Пользователь</HeaderWithTooltip>
                        <HeaderWithTooltip>Наименование документа</HeaderWithTooltip>
                        <HeaderWithTooltip>Действие</HeaderWithTooltip>
                    </tr>
                    </thead>
                    <tbody>
                    <tr className={styles.tableRow}>
                        <td className={`${styles.cell} ${styles.cellBody}`}>11.08.2021, 16:36</td>
                        <td className={`${styles.cell} ${styles.cellBody}`}>Большакова Екатерина Олеговна</td>
                        <td className={`${styles.cell} ${styles.cellBody}`}>Проект контракта.pdf</td>
                        <td className={`${styles.cell} ${styles.cellBody}`}>Сформирован</td>
                    </tr>
                    </tbody>
                </table>}
            </Card>
    </Section>
    );
};

const DocumentRow = (props: { document: LotDocument; index: number; status: string | undefined }) => {
    const { document, index, status } = props;
    const [showTooltip, setShowTooltip] = useState(false);
    const extension = firstMaybe(document.file.name.split('.').reverse()) || '';
    const documentOptions = [{ value: '-', name: '-' },
    { value: 'CONTRACT_PROJECT', name: 'Проект контракта', disabled: true, tooltip: "Данный тип документа можно сформировать только автоматически" },
    { value: 'CALC_EXPLANATION', name: 'Обоснование расчета НМЦ', disabled: !(extension === 'xls' || extension === 'xlsx'), tooltip: `Тип документа "Обоснование расчета НМЦ" можно загружать только в формате "xls" или "xlsx"` },
    { value: 'TECH_SPECS', name: 'Техническое задание' }];

    return <tr className={styles.tableRow}>
        <td className={`${styles.cell} ${styles.cellBody}`}>
            <Select
                value={document.type || ''}
                onSelect={option => option && updateLotDocumentType({ type: option, index: index })}
                options={documentOptions}
                disabled={document.type === 'CONTRACT_PROJECT' || (status !== undefined && status !== 'DRAFT')}
                fullWidth/>
        </td>
        <td className={`${styles.cell} ${styles.cellBody}`}>{document.file.comment}</td>
        <td className={`${styles.cell} ${styles.cellBody}`}>{document.file.name}</td>
        <td className={`${styles.cell} ${styles.cellBody}`}>{moment(document.file.createdAt).format("DD.MM.YYYY HH:mm")}</td>
        <td className={`${styles.cell} ${styles.cellBody}`}>{formatBytes(document.file.length)}</td>
        <td className={`${styles.cell} ${styles.cellBody}`}>
            {(status === undefined || status === 'DRAFT') &&
                <div className={styles.eshopTooltipContainer} onClick={() => setShowTooltip(!showTooltip)}>
                    <div className={styles.eshopTooltipIcon} />
                    {showTooltip && <ul className={styles.eshopTooltip}>
                        <li className={styles.eshopTooltipElement} onClick={() => deleteLotDocuments(index)}>Удалить</li>
                    </ul>}
                </div>
            }
        </td>
        {/* <td className={`${styles.cell} ${styles.cellBody}`}>
            <a className={styles.linkButton} onClick={() => setShowDocumentHistory(!showDocumentHistory)}>
                История изменений
            </a>
        </td> */}
    </tr>;
};
