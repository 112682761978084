import React from 'react';
import {Card} from "@/components/Card";
import {Field, Fields} from "@/components/form/Field";
import {CheckBox, Label, Radio, RadioGroup} from "@/components/primitive";
import router from "@/router";
import {HStack} from "@/components/layouts";
import {SubjectDeclarationInstance} from "@/models/SubjectDeclaration/SubjectDeclaration";
import {statusMapping} from "@/models/SubjectClass/SubjectClass";

export const SubjectDeclarationsInfoSection: React.FC<{ info: SubjectDeclarationInstance }> = x => {
    return <Card>
        <Fields orientation="horizontal">
            <Field title="Идентификатор">
                <Label>
                    {x.info.id}
                </Label>
            </Field>
            <Field title="КПГЗ">
                <Label onClick={() => router.push(`/reference-info/dict/subject-classes/${x.info.subjectClass.id}`)} style={{ textDecoration: "underline", cursor: "pointer" }}>
                    {x.info.subjectClass.description}
                </Label>
            </Field>
            <Field title="Наименование СПГЗ">
                <Label>
                    {x.info.subject}
                </Label>
            </Field>
            <Field title="Единицы измерения">
                <Label>
                    {x.info.measurementUnits[0].name}
                </Label>
            </Field>
            <Field title="Статус">
                <Label>
                    Утверждён
                </Label>
            </Field>
            <Field title="Возможность использования составных единиц измерения">
                <CheckBox value={false} disabled />
            </Field>
            <Field title="Код ОКПД">
                <Label style={{ textDecoration: "underline", cursor: "pointer" }}>
                    {x.info.okpd?.code ?? ""}
                </Label>
            </Field>
            <Field title="Код ОКПД 2">
                <Label style={{ textDecoration: "underline", cursor: "pointer" }}>
                    {x.info.okpd2.code ?? ""}
                </Label>
            </Field>
            <Field title="Код номенклатуры МЭР">
                <Label>

                </Label>
            </Field>
            <Field title="Код позиции КТРУ">
                <Label>

                </Label>
            </Field>
            <Field title="Информация о полезных моделях, промышленных образцах">
                <Label>

                </Label>
            </Field>
            <Field title="Шаблон контракта">
                <Label>

                </Label>
            </Field>
            <Field title="Шаблон ТЗ">
                <Label>

                </Label>
            </Field>
            <Field title="Шаблон формы 2">
                <Label>

                </Label>
            </Field>
            <Field title="Шаблоны формы обоснования НМЦ">
                <Label>

                </Label>
            </Field>
            <Field title="Товары">
                <Label>

                </Label>
            </Field>
            <Field title="Уровень детализации адреса">
                <Label>
                    Не определен
                </Label>
            </Field>
            <Field title="Признак стандартизированной позиции">
                <Label>
                    {x.info.subjectClass.standardProduct ? "Да" : "Нет"}
                </Label>
            </Field>
            <Field title="Есть ПЦП">
                <HStack spacing="20px">
                    <RadioGroup>
                        <Radio value={true} requiredValue={false} disabled>Да</Radio>
                        <Radio value={false} requiredValue={false} disabled>Нет</Radio>
                    </RadioGroup>
                </HStack>
            </Field>
            <Field title="Признак возможности определения поставщика путем проведения конкурса с ограниченным участием">
                <HStack spacing="20px">
                    <RadioGroup>
                        <Radio value={true} requiredValue={false} disabled>Да</Radio>
                        <Radio value={false} requiredValue={false} disabled>Нет</Radio>
                    </RadioGroup>
                </HStack>
            </Field>
            <Field title="Признак применимости критерия жизненного цикла (в соответствии с частью 3 статьи 32 44-ФЗ)">
                <HStack spacing="20px">
                    <RadioGroup>
                        <Radio value={true} requiredValue={false} disabled>Да</Radio>
                        <Radio value={false} requiredValue={false} disabled>Нет</Radio>
                    </RadioGroup>
                </HStack>
            </Field>
            <Field title="Признак результата интеллектуальной деятельности либо НИОКР (в соответствии с частью 6 статьи 32 44-ФЗ)">
                <HStack spacing="20px">
                    <RadioGroup>
                        <Radio value={true} requiredValue={false} disabled>Да</Radio>
                        <Radio value={false} requiredValue={false} disabled>Нет</Radio>
                    </RadioGroup>
                </HStack>
            </Field>
            <Field title="Признак возможности определения поставщика путем проведения двухэтапного конкурса">
                <HStack spacing="20px">
                    <RadioGroup>
                        <Radio value={true} requiredValue={false} disabled>Да</Radio>
                        <Radio value={false} requiredValue={false} disabled>Нет</Radio>
                    </RadioGroup>
                </HStack>
            </Field>
            <Field title="Преподавательские услуги физическим лицом">
                <HStack spacing="20px">
                    <RadioGroup>
                        <Radio value={true} requiredValue={false} disabled>Да</Radio>
                        <Radio value={false} requiredValue={false} disabled>Нет</Radio>
                    </RadioGroup>
                </HStack>
            </Field>
            <Field title="Услуги экскурсоводов физическим лицом">
                <HStack spacing="20px">
                    <RadioGroup>
                        <Radio value={true} requiredValue={false} disabled>Да</Radio>
                        <Radio value={false} requiredValue={false} disabled>Нет</Radio>
                    </RadioGroup>
                </HStack>
            </Field>
            <Field title="Признак возможности проведения закупки на единицу продукции">
                <HStack spacing="20px">
                    <RadioGroup>
                        <Radio value={true} requiredValue={false} disabled>Да</Radio>
                        <Radio value={false} requiredValue={false} disabled>Нет</Radio>
                    </RadioGroup>
                </HStack>
            </Field>
            <Field title="Требует нормирования">
                <HStack spacing="20px">
                    <RadioGroup>
                        <Radio value={true} requiredValue={false} disabled>Да</Radio>
                        <Radio value={false} requiredValue={false} disabled>Нет</Radio>
                    </RadioGroup>
                </HStack>
            </Field>
            <Field title="Проект">
                <Label>
                    {x.info.projectCheck ? "Да" : "Нет"}
                </Label>
            </Field>
            <Field title="Признак исключения применения позиции">
                <HStack spacing="20px">
                    <RadioGroup>
                        <Radio value={true} requiredValue={false} disabled>Да</Radio>
                        <Radio value={false} requiredValue={false} disabled>Нет</Radio>
                    </RadioGroup>
                </HStack>
            </Field>
            <Field title="Примечание">
                <HStack spacing="20px">
                    <RadioGroup>
                        <Radio value={true} requiredValue={false} disabled>Да</Radio>
                        <Radio value={false} requiredValue={false} disabled>Нет</Radio>
                    </RadioGroup>
                </HStack>
            </Field>

        </Fields>
    </Card>;
};