import React, {ReactNode} from 'react';
import styles from "./CheckBox.module.css";
import {Label, LabelPresets} from "@/components/primitive/Label";

interface CheckBoxProps {
    disabled?: boolean
    value?: boolean
    onChange?: (value: boolean) => void
    children?: ReactNode
    labelPreset?: LabelPresets
}

export const CheckBox: React.FC<CheckBoxProps> = x => {
    return <Label className={styles.component} preset={x.labelPreset} onClick={x.disabled ? undefined : ev => {
        if (ev.target === ev.currentTarget) return;
        x.onChange?.(!x.value);
    }}>
        <input
            className={styles.input}
            type="checkbox"
            checked={x.value}
            disabled={x.disabled}
            readOnly
        />

        {x.children}
    </Label>;
};
