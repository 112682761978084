import {StageNullable} from "@/api/Sessions";
import {Checkbox, PrimaryButton} from "@/components/eshop";
import {Select} from "@/components/eshop/Select/Select";
import {FormControlEshopInput} from "@/components/eshop/FormControlEshopInput";
import {Modal} from "@/components/eshop/Modal";
import {DatePicker} from "@/components/primitive";
import {DayType} from "@/models/enums";
import React, {useEffect, useState} from "react";
import {noStagesWarning, onChangeNumber, showValueEmpty, workDaysOptions} from "./SpecificationsCard";
import {defaultStage, saveStagesForSelected, SpecificationType, SubjectDeclarationForCreationService} from "../store";
import styles from '../CreateSession.module.css';
import {RadioButton} from "@/components/eshop/RadioButton/RadioButton";
import {RoundedButton} from "@/components/primitive/RoundedButton";
import {RemoveButton} from "@/components/eshop/IconButton/IconButtons";
import {HeaderWithTooltip} from "@/views/CreateSession/Session/CreateSession";
import {NumberInput} from "@/components/eshop/NumberInput";

interface ModalSupplyProps {
    supplyModalItem: { id: string; copyMode: boolean } | null
    setSupplyModalItem: (value: {id: string; copyMode: boolean} | null) => void
    data: SubjectDeclarationForCreationService[]
    dataType: SpecificationType
    setDeleteFunction: (value: (() => void) | null) => void
}

export const ModalSupply = (props: ModalSupplyProps) => {
    const { supplyModalItem, setSupplyModalItem, data, dataType, setDeleteFunction } = props;
    const foundSpecification = data.find(d => d.idForKey === supplyModalItem?.id);
    const [stages, setStages] = useState<StageNullable[]>([]);
    const [currentMode, setCurrentMode] = useState<"RELATIVE" | "ABSOLUTE">("RELATIVE");
    const [selectedIds, setSelectedIds] = useState<string[]>([]);
    const [filter, setFilter] = useState('');
    useEffect(() => {
        setStages(foundSpecification?.stages.length ? foundSpecification.stages.map(s => ({ ...s })) : [{ ...defaultStage }]);
        setSelectedIds(data.map(d => d.idForKey));
    }, [supplyModalItem?.id]);

    const onChangeFilter = (filter: string) => {
        setFilter(filter);
        setSelectedIds([]);
    };

    const onToggleSelect = (id: string) => {
        const foundIndex = selectedIds.indexOf(id);
        if (foundIndex === -1) {
            selectedIds.push(id);
        } else {
            selectedIds.splice(foundIndex, 1);
        }
        setSelectedIds([...selectedIds]);
    };

    const onToggleSelectAll = () => {
        if (selectedIds.length === data.length) {
            setSelectedIds([]);
        } else {
            setSelectedIds(data.map(d => d.idForKey));
        }
    };

    const onChangeDaytype = (type: string, stageNumber: number) => {
        const stage = stages[stageNumber];
        if (stage.relativePeriod) {
            stage.relativePeriod = { ...stage.relativePeriod, endOffsetType: type as DayType, startOffsetType: type as DayType };
        }
        stages[stageNumber] = stage;
        setStages([...stages]);
    };

    const onChangeSupplyBegin = (startOffset: number | null, stageNumber: number) => {
        const stage = stages[stageNumber];
        if (stage.relativePeriod) {
            stage.relativePeriod = { ...stage.relativePeriod, startOffset };
        }
        stages[stageNumber] = stage;
        setStages([...stages]);
    };

    const onChangeSupplyEnd = (endOffset: number | null, stageNumber: number) => {
        const stage = stages[stageNumber];
        if (stage.relativePeriod) {
            stage.relativePeriod = { ...stage.relativePeriod, endOffset };
        }
        stages[stageNumber] = stage;
        setStages([...stages]);
    };

    const onChangeSupplyStartDate = (startDate: Date, stageNumber: number) => {
        const stage = stages[stageNumber];
        if (stage.absolutePeriod) {
            stage.absolutePeriod = { ...stage.absolutePeriod, startDate: startDate };
        }
        stages[stageNumber] = stage;
        setStages([...stages]);
    };

    const onChangeSupplyEndDate = (endDate: Date, stageNumber: number) => {
        const stage = stages[stageNumber];
        if (stage.absolutePeriod) {
            stage.absolutePeriod = { ...stage.absolutePeriod, endDate: endDate };
        }
        stages[stageNumber] = stage;
        setStages([...stages]);
    };

    const onChangeAddress = (address: string, stageNumber: number) => {
        const stage = stages[stageNumber];
        stage.address = address;
        stages[stageNumber] = stage;
        setStages([...stages]);
    };

    const onChangeVolume = (volume: string, stageNumber: number) => {
        const stage = stages[stageNumber];
        stage.volume = volume;
        stages[stageNumber] = stage;
        setStages([...stages]);
    };

    const onChangeStageType = (type: "RELATIVE" | "ABSOLUTE") => {
        const newStages = stages.map(i => ({...i, type: type}));
        setStages(newStages);
    };

    useEffect(() => {
        onChangeStageType(currentMode);
    }, [currentMode]);

    const onDeleteStage = (stageNumber: number) => setStages(stages.splice(stageNumber, 1));
    const onAddNewStage = () => setStages(stages.concat(defaultStage));
    const onDeleteAllStages = () => setStages([]);

    const onSave = () => {
        saveStagesForSelected({ specType: dataType, stages, ids: selectedIds });
        setSupplyModalItem(null);
    };

    return supplyModalItem
        ? <Modal onClose={() => setSupplyModalItem(null)} controls={<PrimaryButton filled onClick={() => onSave()} >Применить</PrimaryButton>} title="Единый график поставки">
            <div className={styles.supplyModal_planTabs_warning}>В выбранные позиции спецификации будет добавлен указанный единый график поставки. Существующие графики поставки будут удалены.</div>
            <div className={styles.stageModal}>
                <div className={styles.supplyModal_planTabs}>
                    <div className={styles.buttonsPanel}>
                        <RadioButton id={"radio-relative"} value={currentMode} checked={currentMode === "RELATIVE"} onChange={() => setCurrentMode("RELATIVE")} label={"Сроки поставки"} />
                        <RadioButton id={"radio-absolute"} value={currentMode} checked={currentMode === "ABSOLUTE"} onChange={() => setCurrentMode("ABSOLUTE")} label={"Даты поставки"} />
                    </div>
                    {!supplyModalItem.copyMode && (
                        <div className={styles.buttonsPanel}>
                            <RoundedButton small color={"blueWhite"} onClick={() => onAddNewStage()} title={"+ Добавить"} />
                            <RemoveButton disabled={!stages.length} onClick={() => setDeleteFunction(() => () => onDeleteAllStages())} />
                        </div>
                    )}
                </div>
                {currentMode === "RELATIVE" && <table className={styles.cardTable}>
                    <thead>
                        <tr className={styles.tableRow}>
                            <HeaderWithTooltip>Количество</HeaderWithTooltip>
                            <HeaderWithTooltip>Тип дней</HeaderWithTooltip>
                            <HeaderWithTooltip>Начало поставки</HeaderWithTooltip>
                            <HeaderWithTooltip>Конец поставки</HeaderWithTooltip>
                            <HeaderWithTooltip>Адрес поставки</HeaderWithTooltip>
                            <th></th>
                        </tr>
                    </thead>
                    <tbody>
                        {!stages.length && noStagesWarning()}
                        {stages.map((stage, i) => <tr key={i}>
                            <td className={styles.cell}>
                                <FormControlEshopInput clearable
                                                       disabled={supplyModalItem.copyMode}
                                                       checkEmpty
                                                       value={showValueEmpty(stage.volume)}
                                                       small
                                                       onChange={e => onChangeVolume(e.target.value, i)}
                                                       placeholder="Введите значение" />
                            </td>
                            <td className={styles.cell}>
                                <Select
                                    disabled={supplyModalItem.copyMode}
                                    fullWidth
                                    small
                                    value={stage.relativePeriod ? stage.relativePeriod.startOffsetType : ''}
                                    onSelect={option => option && onChangeDaytype(option, i)}
                                    options={workDaysOptions} />
                            </td>
                            <td className={styles.cell}>
                                <NumberInput clearable
                                             small
                                             disabled={supplyModalItem.copyMode}
                                             checkEmpty
                                             disableFixed
                                             disableMask
                                             maxValue={1096}
                                             value={+showValueEmpty(stage.relativePeriod?.startOffset)}
                                             onChange={e => onChangeSupplyBegin(onChangeNumber(e.toString()), i)}
                                             validate={(v) => {
                                                 if (!stage.relativePeriod?.startOffset) return {text: "Обязательно для заполнения"};
                                                 if (stage.relativePeriod.endOffset && stage.relativePeriod.endOffset < +v) return {text: "Поле 'Начало поставки' не должно быть больше поля 'Окончание поставки'"};
                                                 return {text: ""};
                                             }}
                                             placeholder={"Введите значение"} />
                            </td>
                            <td className={styles.cell}>
                                <NumberInput clearable
                                             small
                                             disabled={supplyModalItem.copyMode}
                                             checkEmpty
                                             disableFixed
                                             disableMask
                                             maxValue={1096}
                                             value={+showValueEmpty(stage.relativePeriod?.endOffset)}
                                             onChange={e => onChangeSupplyEnd(onChangeNumber(e.toString()), i)}
                                             validate={(v) => {
                                                 if (!stage.relativePeriod?.endOffset) return {text: "Обязательно для заполнения"};
                                                 return {text: ""};
                                             }} placeholder={"Введите значение"} />
                            </td>
                            <td className={styles.cell}>
                                <FormControlEshopInput clearable
                                    disabled={supplyModalItem.copyMode}
                                    checkEmpty
                                    value={stage.address}
                                    small
                                    onChange={e => onChangeAddress(e.target.value, i)}
                                    placeholder="Введите значение" />
                            </td>
                            <td className={styles.cell}>
                                {!supplyModalItem.copyMode && <RemoveButton onClick={() => onDeleteStage(i)} />}
                            </td>
                        </tr>)}
                    </tbody>
                </table>}
                {currentMode === "ABSOLUTE" && <table className={styles.cardTable}>
                    <thead>
                        <tr className={styles.tableRow}>
                            <th className={styles.cell}>Количество</th>
                            <th className={styles.cell}>Начало поставки</th>
                            <th className={styles.cell}>Конец поставки</th>
                            <th className={styles.cell}>Адрес поставки</th>
                            <th className={styles.cell}></th>
                        </tr>
                    </thead>
                    <tbody>
                        {!stages.length && noStagesWarning()}
                        {stages.map((stage, i) => <tr key={i}>
                            <td className={styles.cell}>
                                <FormControlEshopInput clearable
                                                       disabled={supplyModalItem.copyMode}
                                                       checkEmpty
                                                       value={showValueEmpty(stage.volume)}
                                                       small
                                                       onChange={e => onChangeVolume(e.target.value, i)}
                                                       placeholder="Введите значение" />
                            </td>
                            <td className={styles.cell}>
                                <DatePicker value={stage.absolutePeriod?.startDate ? new Date(stage.absolutePeriod.startDate) : undefined}
                                            disabled={supplyModalItem.copyMode}
                                            mode="days"
                                            maxWidth={"300px"}
                                            appearance="eshop"
                                            small
                                            onChange={day => onChangeSupplyStartDate(day, i)} cantBeCleared />
                            </td>
                            <td className={styles.cell}>
                                <DatePicker value={stage.absolutePeriod?.endDate ? new Date(stage.absolutePeriod.endDate) : undefined}
                                            disabled={supplyModalItem.copyMode}
                                            mode="days"
                                            maxWidth={"300px"}
                                            appearance="eshop"
                                            small
                                            onChange={day => onChangeSupplyEndDate(day, i)} cantBeCleared />
                            </td>
                            <td className={styles.cell}>
                                <FormControlEshopInput disabled={supplyModalItem.copyMode} clearable checkEmpty value={stage.address} small onChange={e => onChangeAddress(e.target.value, i)} placeholder="Введите значение" />
                            </td>
                            <td className={styles.cell}>
                                {!supplyModalItem.copyMode && <RemoveButton onClick={() => void 0} />}
                            </td>
                        </tr>)}
                    </tbody>
                </table>}
                <div className={styles.multiSelect}>
                    Спецификации
                    <div className={styles.multiHeader}>
                        <Checkbox value={selectedIds.length === data.length} onChange={() => onToggleSelectAll()} />
                        <div className={styles.multiInputContainer}>
                            <FormControlEshopInput value={filter} small onChange={e => onChangeFilter(e.target.value)} placeholder="Введите значение" />
                        </div>
                    </div>
                    <div className={styles.multiBody}>
                        {data.filter(d => d.subjectDeclaration.subject.includes(filter)).map(d => <div key={d.idForKey} style={{margin: 10}}>
                            <Checkbox onChange={() => onToggleSelect(d.idForKey)}
                                value={selectedIds.indexOf(d.idForKey) !== -1}
                                caption={d.subjectDeclaration.subject} />
                        </div>)}
                    </div>
                </div>
            </div>
        </Modal>
        : <div />;
};
