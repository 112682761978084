import React from "react";
import { observer } from "mobx-react";
import styles from "./AdditionalInfo.module.css";
import { Product } from "@/api/Products";
import { formatNumber } from "@/NumberFormatting";
import { Purchase } from "@/api/DirectPurchases";

type SelectedInfoProps = {
    items: { name: string; referentPrice?: string | null; priceWithNds?: number }[]
    onClose: () => void
    popupRef: React.Ref<HTMLDivElement>
    unselectAll: () => void
};

export const SelectedInfo = (props: SelectedInfoProps) => {
    return <div className={`${styles.charsPopup} ${styles.selectedPopup}`} ref={props.popupRef}>
        <div className={styles.infoClose} onClick={props.onClose} />
        <table className={styles.infoTable}>
            <tbody>
                {props.items.map(x => {
                    const price = (x.referentPrice || x.priceWithNds) ?? 0;
                    return <tr key={x.name}>
                        <td className={styles.infoName}>
                            {x.name.length > 8
                                ? x.name.substring(0, 8) + "..."
                                : x.name
                            }
                        </td>
                        <td className={styles.infoValue}>{formatNumber(price)}</td>
                    </tr>;
                })}
            </tbody>
        </table>
        <a className={styles.undselectButton} onClick={() => {
            props.unselectAll();
            props.onClose();
        }}>
            Снять выделение
        </a>
    </div>;
};