











import { Component, Prop, Vue } from "vue-property-decorator";

@Component
export default class DocumentTextField extends Vue {
    @Prop() private id!: string;
    @Prop({ default: () => ({}) }) private table!: Record<string, string>;

    private active = false;

    private get html() {
        const div = document.createElement("div");

        div.innerText = this.table[this.id];

        return div.innerHTML.replace(/\n/g, "<br />");
    }

    private get text() {
        return this.table[this.id];
    }

    private activate() {
        this.active = true;
    }

    private update(e: Event) {
        this.table[this.id] = (e.target as HTMLTextAreaElement).value;
    }

    private close() {
        this.active = false;
        this.$emit("change");
    }
}
