






import { Component, Prop, Vue } from "vue-property-decorator";
import Card from "@/components/vue/Card.vue";
import EventBus from "@/EventBus";

@Component({ components: { Card } })
export default class Modal extends Vue {
    @Prop({ default: true }) private useScrim!: boolean;

    private handleOutOfModalEvent($event: Event) {
        this.$emit("close-modal");
    }
}
