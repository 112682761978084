import { SupplyRegion, supplyRegions } from '@/api/filterHelpers';
import { Checkbox, PrimaryButton } from '@/components/eshop';
import { Select } from "@/components/eshop/Select/Select";
import { NumberInput } from '@/components/eshop/NumberInput';
import { SubjectClassFilterStore } from '@/components/eshop/SubjectClassFilterStore';
import { SubjectClassSelector } from '@/components/eshop/SubjectClassSelector';
import React from "react";
import styles from "./ProductsFilters.module.css";
import { RubleSign } from './RubleSign';
import { changeKpgz, changeManufacturer, changeName, changePriceForm, changePriceTo, changeRegion, ProductsFilterStore, resetFilters, searchProducts, toggleDemanded, toggleHasOffers } from './store';
import {Input} from "@/components/eshop/Input/Input";

type ProductsFiltersProps = {
    filterStore: ProductsFilterStore
    subjectClassSelector: SubjectClassFilterStore
};

export const ProductsFilters = ({ filterStore, subjectClassSelector }: ProductsFiltersProps) => {
    return <div className={styles.sideFilters}>
        <div className={styles.filtersContainer}>
            <button className={styles.filtersCloseButton} />
            <form className={styles.filtersPanel}>
                <div className={styles.filtersField}>
                    <span className={styles.filtersLabel}>
                        Код КПГЗ
                    </span>
                    <div className={styles.filtersField}>
                        <SubjectClassSelector value={filterStore.kpgz || null} onSelect={e => changeKpgz(e)} store={subjectClassSelector} />
                    </div>
                </div>


                <div className={styles.filtersField}>
                    <span className={styles.filtersLabel}>
                        Наименование СТЕ
                    </span>
                    <Input value={filterStore.name || ""}
                            onChange={ev => changeName(ev.target.value)}
                            background={"#fff"}
                            fullWidth />
                </div>

                <div className={styles.filtersField}>
                    <span className={styles.filtersLabel}>
                        Регион поставки
                    </span>
                    <Select value={filterStore.region || ''} small onSelect={option => changeRegion(option as SupplyRegion || null)} options={supplyRegions} />
                </div>

                <div className={styles.filtersField}>
                    <span className={styles.filtersLabel}>
                        Референтная цена  (<RubleSign />)
                    </span>
                    <div className={styles.filtersInputRow}>
                        <span className={styles.filtersInputColumn}>
                            от
                            <NumberInput
                                checkEmpty
                                value={filterStore.priceFrom}
                                onChange={e => changePriceForm(e)} small />
                        </span>
                        <span className={styles.filtersInputColumn}>
                            до
                            <NumberInput
                                checkEmpty
                                value={filterStore.priceTo}
                                onChange={e => changePriceTo(e)} small />
                        </span>
                    </div>
                </div>

                <div className={styles.filtersField}>
                    <span className={styles.filtersLabel}>
                        Производитель
                    </span>
                    <Input background={"#fff"} value={filterStore.manufacturer || ''} onChange={ev => changeManufacturer(ev.target.value)} fullWidth />
                </div>

                <div className={styles.filtersField}>
                    <Checkbox onChange={() => toggleHasOffers()} value={filterStore.hasOffers === false} caption='Только СТЕ без предложений' />
                </div>

                <div className={styles.filtersField}>
                    <Checkbox onChange={() => toggleDemanded()} value={filterStore.isDemanded || undefined} caption='Только востребованная продукция' />
                </div>

                <div className={styles.filtersControls}>
                    <div onClick={() => resetFilters()} className={styles.resetFilters}>×︁ Сбросить</div>
                    <PrimaryButton filled onClick={() => searchProducts({ page: 0 })}>Найти</PrimaryButton>
                </div>
            </form>
        </div>
    </div>;
};
