import React from "react";
import { PaymentStoreState } from "@/views/Payments/view/store";
import { useStore } from "effector-react";
import { Store } from "effector";
import { Form, FormHeader, FormState } from "@/components/form";
import { VStack } from "@/components/layouts";
import { InfoSection } from "@/views/Payments/shared/sections/InfoSection";
import { ObjectsCommunicationSection } from "@/views/Payments/shared/sections/ObjectsCommunicationSection";
import { HistorySection } from "@/views/Payments/shared/sections/HistorySection";

type PaymentViewProps = {
    storeContainer: {
        store: Store<PaymentStoreState>
    }
};

export const PaymentView = ({ storeContainer: { store } }: PaymentViewProps) => {
    const { payment, history } = useStore(store);

    const title = payment?.regNumber
        ? `Платеж ${payment.regNumber} Загружено`
        : "Платеж";

    const header = (
        <VStack spacing="15px">
            <FormHeader title={title} />
        </VStack>
    );

    const tabs = Form.Tab("info", "Общая информация", [
        Form.Section("Общая информация", <InfoSection payment={payment} />),
        Form.Section("Связь с объектами ЕАИСТ", <ObjectsCommunicationSection contract={payment?.obligation?.contract} />),
        Form.Section("История изменений", <HistorySection history={history} />)
    ]);

    return (
        <FormState value="readonly">
            <Form header={header} tabs={[tabs]}  />
        </FormState>
    );
};
