import { Currency } from '@/models/enums';
import Decimal from 'decimal.js';
import { Participant } from '../Participant';
import { IJsonFormattable } from '../IJsonFormattable';
import { IDtoContractSpecification } from '../json/Contracts/IDtoContractSpecification';
import { Specification } from '../Specification';
import { ContractSpecificationSupplyStage } from './ContractSpecificationSupplyStage';

export class ContractSpecification implements IJsonFormattable<IDtoContractSpecification> {
    constructor(
        public id: string,
        public contractId: string,

        public createdAt: Date,

        public actualVolume: Decimal,
        public actualCost: Decimal,
        public tax: "TAX_0" | "TAX_10" | "TAX_20" | "TAX_NONE",

        public specification: Specification,

        public stages: ContractSpecificationSupplyStage[],
        public country: string | null,

        public spgzChanged: boolean
    ) {}

    public taxPercent() {
        switch (this.tax) {
            case "TAX_10":
                return 10;
            case "TAX_20":
                return 20;
            default:
                return 0;
        }
    }

    public toJson(): IDtoContractSpecification {
        return {
            ...this,
            createdAt: this.createdAt.toISOString(),
            actualVolume: this.actualVolume.toString(),
            actualCost: this.actualCost.toString(),
            specification: this.specification.toJson(),
            stages: this.stages.map(x => x.toJson())
        };
    }

    public static fromJson(dto: IDtoContractSpecification): ContractSpecification {
        return Object.assign(Object.create(ContractSpecification.prototype), dto, {
            createdAt: new Date(dto.createdAt),
            actualVolume: new Decimal(dto.actualVolume),
            actualCost: new Decimal(dto.actualCost),
            specification: Specification.fromJson(dto.specification),
            stages: dto.stages.map(x => ContractSpecificationSupplyStage.fromJson(x)),
            country: dto.country ?? null
        });
    }
}
