import {ModalFC} from "@/EventBus";
import {Expandee, HStack} from "@/components/layouts";
import {Button, CheckBox, ComboBox, IntBox, TextBox} from "@/components/primitive";
import {CardModal} from "@/components/modals/CardModal";
import {Field, Fields} from "@/components/form/Field";
import React, {useState} from "react";
import {addOrEditLotRequestDocument} from "@/views/ComposedLot/shared/store/documents";
import {LotRequestStage, LotRequestStageStrings} from "@/models/enums/LotRequestStage";
import {toSelectOptions} from "@/models/enums";
import {FormState, FTextBox} from "@/components/form";
import {LotRequestDocument} from "@/models/ComposedLots/documents";

export const NewRequiredDocumentModal: ModalFC<{ pending?: LotRequestDocument }> = x => {
    const [doc, setDoc] = useState<LotRequestDocument>(x.pending ??
        {
            name: "",
            order: "",
            comment: "",
            required: false,
            stage: LotRequestStage.FIRST
        } as LotRequestDocument
    );

    const validateLotRequestDocument = (a: LotRequestDocument): boolean => (a.order !==  "") && (a.name !== "");

    const bottom = <HStack spacing={"10px"}>
        <Expandee/>
        <Button color={"blue"}
                icon={"aSave"}
                onClick={() => addOrEditLotRequestDocument({oldV: x.pending, newV: doc}) && x.done()}
                disabled={!validateLotRequestDocument(doc)}
        />
        <Button color={"red"} icon={"aClose"} onClick={x.done}/>
    </HStack>;
    const applicationParts = [
        {key: "first", desc: "Первая часть заявки"},
        {key: "second", desc: "Вторая часть заявки"}
    ];
    return <CardModal title={"Новый требуемый документ к заявке"} close={x.done} width={"50%"} bottom={bottom}>
        <Fields orientation={"vertical"}>
            <FormState value={"enabled"}>
                <Field title={"Номер"} required>
                        <FTextBox pattern={/-?[0-9\s]+/}
                                  value={doc.order}
                                  onChange={a => setDoc({...doc, order: a ?? 0})}


                        />
                </Field>
                <Field title={"Наименование"} required>
                    <FTextBox lines={5}
                             value={doc.name}
                             onChange={a => setDoc({...doc, name: a})}

                    />
                </Field>
            </FormState>
            <Field title={"Примечание"}>
                <TextBox lines={5}
                         value={doc.comment}
                         onChange={a => setDoc({...doc, comment: a})}
                />
            </Field>
            <Field title={"Обязательность"}>
                <CheckBox
                    value={doc.required}
                    onChange={a => setDoc({...doc, required: a})}
                />
            </Field>
            <Field title={"Часть заявки"}>
                <ComboBox options={toSelectOptions<LotRequestStage>(LotRequestStageStrings)}
                          onChange={(f) => setDoc({...doc, stage: f})}
                          value={doc.stage}
                />
            </Field>
        </Fields>
    </CardModal>;
};