import { createEffect, createEvent, createStore, Event, Store } from "effector";
import { SubjectClass, SubjectDeclaration } from "@/models";
import Decimal from "decimal.js";
import { autoSV, createSVEvent, origEv } from "@/storeUtils";
import { getCharacteristicDeclarations } from "@/api/directory";
import { CharacteristicDeclaration, SpecificationDraft, SupplyStage } from "@/models/ComposedLots/specifications";
import { CharacteristicConditionType, LawType } from "@/models/enums";
import { CharacteristicValue } from "@/models/ComposedLots/specifications/CharacteristicValue";
import { CloneOptions } from "@/views/ComposedLot/edit/tabs/specifications/CloneSpecificationModal";
import { StartPrice } from "@/models/ComposedLots/specifications/StartPrice";
import { ActionClass } from "@/models/ActionClass";
import { LotSpecifications } from "@/views/ComposedLot/shared/store/specifications";

export interface CharacteristicValueStore {
    conditionType: CharacteristicConditionType
    enumValues: string[]
    numberValueFirst?: Decimal
    subConditionTypeFirst: CharacteristicConditionType
    numberValueSecond?: Decimal
    subConditionTypeSecond: CharacteristicConditionType
}

export interface CharacteristicValueStoreContainer {
    declaration: CharacteristicDeclaration

    store: Store<CharacteristicValueStore>
    setConditionType: Event<CharacteristicConditionType>
    update: Event<CharacteristicValueStore>
}

interface ExistingDraftInfo {
    subjectDeclaration: SubjectDeclaration
    volume: Decimal
    startPrice?: StartPrice
}

export interface SpecificationStore {
    existingDraft?: ExistingDraftInfo

    subjectClass?: SubjectClass
    subjectDeclaration?: SubjectDeclaration
    actionClass?: ActionClass

    idValue?: number
    idError?: string

    supplyStages: SupplyStage[]

    requiredChars: CharacteristicValueStoreContainer[]
    additionalChars: CharacteristicValueStoreContainer[]

    volume?: Decimal
    measurementUnitId?: number
    additionalCharacteristicJustification?: string
}

const createCharValueFromExisting = (char: CharacteristicValue): CharacteristicValueStoreContainer => {
    const update = createEvent<CharacteristicValueStore>();
    const setConditionType = createEvent<CharacteristicConditionType>();

    const store = createStore<CharacteristicValueStore>({...char});

    store.on(update, (x, v) => v);
    store.on(setConditionType, (x, v) => ({ ...x, conditionType: v }));

    return {
        declaration: char.declaration,
        store: store,
        update,
        setConditionType
    };
};

const createCharValue = (charDecl: CharacteristicDeclaration): CharacteristicValueStoreContainer =>
    createCharValueFromExisting({
        declaration: charDecl,
        conditionType: charDecl.conditionType,
        subConditionTypeFirst: "GREATER_THAN_OR_EQUAL",
        subConditionTypeSecond: "LESS_THAN_OR_EQUAL",
        enumValues: []
    });

export const setSubjectClass = createEvent<SubjectClass>("set subject class");
export const setSubjectDeclaration = createEffect({
    name: "set subject declaration",
    handler: async (d: SubjectDeclaration) => {
        const charDecls = await getCharacteristicDeclarations(d.id);

        return {
            subjectDeclaration: d,
            requiredChars: charDecls.filter(x => !x.isAdditional).map(createCharValue),
            additionalChars: charDecls.filter(x => x.isAdditional).map(createCharValue),
        };
    }
});

export const setActionClass = createSVEvent<SpecificationStore>()("actionClass");

export const setIdValue = createSVEvent<SpecificationStore>()("idValue");
export const setIdError = createSVEvent<SpecificationStore>()("idError");

export const setSupplyStages = createSVEvent<SpecificationStore>()("supplyStages");

export const setVolume = createSVEvent<SpecificationStore>()("volume");
export const setMeasurementUnitId = createSVEvent<SpecificationStore>()("measurementUnitId");

export const setAdditionalCharacteristicJustification = createSVEvent<SpecificationStore>()("additionalCharacteristicJustification");

export interface PickSpecificationProps {
    law: LawType
    perItem: boolean
    cloneOptions?: CloneOptions
    baseSubjectClass?: SubjectClass
    existingSpecificationId?: string
    readonly?: boolean
    isClone?: boolean
}

export const createSpecificationStore = (props: PickSpecificationProps) => {
    const existing = LotSpecifications.specificationForms.find(sp => sp.specId === props.existingSpecificationId)?.validated;

    const cloneSubjectDeclaration = props.cloneOptions?.cloneSubjectDeclaration ?? true;
    const cloneVolume = props.cloneOptions?.cloneVolume ?? true;
    const cloneStartPrice = props.cloneOptions?.cloneStartPrice ?? true;
    const cloneSupplyStages = props.cloneOptions?.cloneSupplyStages ?? true;

    return createStore<SpecificationStore>({
        existingDraft: existing
            ? {
                ...existing,
                startPrice: cloneStartPrice ? existing?.startPrice : undefined
            }
            : undefined,
        subjectClass: existing?.subjectDeclaration?.subjectClass ?? props.baseSubjectClass,
        subjectDeclaration: cloneSubjectDeclaration ? existing?.subjectDeclaration : undefined,
        idValue: cloneSubjectDeclaration ? existing?.subjectDeclaration?.id : undefined,
        measurementUnitId: cloneSubjectDeclaration ? existing?.measurementUnit?.id : undefined,
        volume: cloneVolume ? existing?.volume : undefined,
        actionClass: existing?.actionClass ? existing.actionClass : undefined,
        supplyStages: cloneSupplyStages ? existing?.supplyStages ?? [] : [],
        requiredChars: cloneSubjectDeclaration
            ? existing?.characteristics?.filter(x => !x.declaration.isAdditional)?.map(createCharValueFromExisting) ?? []
            : [],
        additionalChars: cloneSubjectDeclaration
            ? existing?.characteristics?.filter(x => x.declaration.isAdditional)?.map(createCharValueFromExisting) ?? []
            : [],
        additionalCharacteristicJustification: existing?.additionalCharacteristicJustification
    }).on(setSupplyStages[origEv], autoSV)
        .on(setSubjectClass, (x, v) => ({
            ...x,
            subjectClass: v,
            subjectDeclaration: undefined,
            actionClass: undefined,
            idValue: undefined,
            idError: undefined
        }))
        .on(setSubjectDeclaration.done, (x, {result}) => ({
            ...x,
            subjectClass: result.subjectDeclaration.subjectClass,
            subjectDeclaration: result.subjectDeclaration,
            actionClass: undefined,
            idValue: result.subjectDeclaration.id,
            idError: undefined,
            additionalChars: result.additionalChars,
            requiredChars: result.requiredChars,
            measurementUnitId: result.subjectDeclaration.measurementUnits[0]?.id
        }))
        .on(setActionClass[origEv], autoSV)
        .on(setIdValue[origEv], autoSV)
        .on(setIdError[origEv], autoSV)
        .on(setVolume[origEv], autoSV)
        .on(setMeasurementUnitId[origEv], autoSV)
        .on(setAdditionalCharacteristicJustification[origEv], autoSV);
};
