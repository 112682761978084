var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"table-grid-wrap"},[(_vm.currentPopupItem)?_c('div',{staticClass:"action-popup",style:({ top: _vm.currentPopupTop + 'px', left: _vm.currentPopupLeft - _vm.gridScrollX + 'px' })},_vm._l((_vm.actions),function(action,ix){return _c('div',{key:'act-' + ix,on:{"mousedown":function($event){return _vm.$emit('item-action', _vm.currentPopupItem, action.id)}}},[_vm._v(" "+_vm._s(action.title)+" ")])}),0):_vm._e(),(_vm.dataset.length > 0 || !_vm.hasWhenEmpty)?_c('TableGrid',{ref:"grid",staticClass:"table-grid",class:{fixedheight: !!_vm.height},style:({height: _vm.height}),attrs:{"rows":_vm.tableCells,"columnSizes":_vm.tableColumnSizes,"rowSizes":_vm.tableRowSizes},on:{"scroll":_vm.gridScroll},scopedSlots:_vm._u([{key:"empty-cell",fn:function(ref){
var _obj;

var src = ref.src;
var columnIndex = ref.columnIndex;return [_c('div',{staticClass:"table-cell",class:( _obj = {
                    norightborder: !!_vm.height && columnIndex === _vm.lastColumnIndex
                }, _obj[src ? src.className : '___unused'] = src && src.className, _obj )})]}},{key:"filter-cell",fn:function(ref){
                var ref_src = ref.src;
                var header = ref_src.header;
                var filter = ref_src.filter;
                var columnIndex = ref.columnIndex;
return [_c('DataTableFilterCell',{staticClass:"table-cell header-cell",class:{norightborder: !!_vm.height && columnIndex === _vm.lastColumnIndex, sticky: !!_vm.height},attrs:{"filterValue":filter,"header":header},on:{"update-filter":_vm.updateFilter}})]}},{key:"action-cell",fn:function(ref){
                var rowIndex = ref.rowIndex;
                var columnIndex = ref.columnIndex;
return [_c('DataTableActionCell',{ref:("actions-value-cell-" + (rowIndex - 1)),class:{norightborder: !!_vm.height && columnIndex === _vm.lastColumnIndex},on:{"open-actions":function($event){return _vm.openActions($event, rowIndex - 1)}}})]}},{key:"header-cell",fn:function(ref){
                var ref_src = ref.src;
                var title = ref_src.title;
                var alignment = ref_src.alignment;
                var columnIndex = ref.columnIndex;
return [_c('div',{staticClass:"table-cell header-cell",class:{norightborder: !!_vm.height && columnIndex === _vm.lastColumnIndex, sticky: !!_vm.height},style:({textAlign: alignment})},[_vm._v(_vm._s(title))])]}},{key:"value-cell",fn:function(ref){
                var ref_src = ref.src;
                var header = ref_src.header;
                var item = ref_src.item;
                var index = ref_src.index;
                var columnIndex = ref.columnIndex;
return [_c('div',{staticClass:"table-cell value-cell",class:{norightborder: !!_vm.height && columnIndex === _vm.lastColumnIndex},style:(_vm.rowStyle(item)),on:{"click":function($event){return _vm.select(item)}}},[(header.slot)?_vm._t(header.slot,null,{"item":item,"value":header.getter && header.getter(item, index)}):_c('DataTableCell',{attrs:{"header":header,"item":item,"ix":index,"canEdit":!_vm.readonly},on:{"item-action":function (i, a) { return _vm.$emit('item-action', i, a); },"change":function (i, v) { return _vm.setValue(header, i, v); },"blur":function (i, v) { return _vm.handleBlur(header, i, v); }}})],2)]}},{key:"group-header-cell",fn:function(ref){
                var groupHeader = ref.src;
                var columnIndex = ref.columnIndex;
return [_c('div',{staticClass:"group-header pure-cell",class:{norightborder: !!_vm.height && columnIndex === _vm.lastColumnIndex}},[_vm._v(" "+_vm._s(groupHeader)+" ")])]}},{key:"edit-button-cell",fn:function(ref){
                var item = ref.src;
                var columnIndex = ref.columnIndex;
return [_c('div',{staticClass:"table-cell value-cell",class:{norightborder: !!_vm.height && columnIndex === _vm.lastColumnIndex},style:(_vm.rowStyle(item))},[_c('x-button',{attrs:{"title":"Изменить","color":"white"},on:{"click":function($event){return _vm.$emit('edit', item)}}})],1)]}},{key:"delete-button-cell",fn:function(ref){
                var item = ref.src;
                var columnIndex = ref.columnIndex;
return [_c('div',{staticClass:"table-cell value-cell",class:{norightborder: !!_vm.height && columnIndex === _vm.lastColumnIndex},style:(_vm.rowStyle(item))},[_c('x-button',{attrs:{"title":"x","icon":{ type: 'CLASS', name: 'close-eaist' }},on:{"click":function($event){return _vm.$emit('delete', item)}}})],1)]}},{key:"joined-row-cell",fn:function(ref){
                var ref_src = ref.src;
                var pure = ref_src.pure;
                var item = ref_src.item;
                var columnIndex = ref.columnIndex;
return [(pure)?_vm._t("joined-row",null,{"item":item}):_c('div',{staticClass:"pure-cell joined-row",class:{norightborder: !!_vm.height && columnIndex === _vm.lastColumnIndex}},[_vm._t("joined-row",null,{"item":item})],2)]}},{key:"selector-cell",fn:function(ref){
                var ref_src = ref.src;
                var type = ref_src.type;
                var item = ref_src.item;
                var locked = ref_src.locked;
                var columnIndex = ref.columnIndex;
return [_c('div',{staticClass:"table-cell value-cell selector-cell",class:{norightborder: !!_vm.height && columnIndex === _vm.lastColumnIndex},style:(_vm.rowStyle(item))},[(_vm.selectable(item) || _vm.predicateAction === 'lock')?_c('input',{attrs:{"type":type,"disabled":locked || !_vm.selectable(item)},domProps:{"checked":_vm.isSelected(item)},on:{"change":function($event){return _vm.select(item)}}}):_vm._e()])]}},{key:"slot-actions-cell",fn:function(ref){
                var item = ref.src;
                var columnIndex = ref.columnIndex;
return [_c('div',{staticClass:"table-cell value-cell",class:{norightborder: !!_vm.height && columnIndex === _vm.lastColumnIndex},style:(_vm.rowStyle(item))},[_vm._t("actions-cell",null,{"src":item})],2)]}}],null,true)}):_vm._t("when-empty")],2)}
var staticRenderFns = []

export { render, staticRenderFns }