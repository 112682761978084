













































































import { Component, Prop, Vue } from "vue-property-decorator";
import FormTable from "@/components/vue/FormTable.vue";
import CardModal from "@/components/CardModal.vue";
import { ModalResult } from '@/view-models/ModalRequest';
import { ContractScope } from './ContractScope';
import { ContractSpecification } from '@/models/Contracts/ContractSpecification';
import { DayType, ObjectStatus, SelectOption, SupplyStagePeriodType } from '@/models/enums';
import { hs } from '@/components/TableHelpers';
import { ContractSpecificationSupplyStage } from '@/models/Contracts/ContractSpecificationSupplyStage';
import { IRowConfiguration } from '@/components/TableAbstractions';
import AddSupplyStage from './AddSupplyStage.vue';
import EventBus, { showModal } from '../../EventBus';

import ContractSpecifications from '@/api/ContractSpecifications';
import moment from "moment";
import { addBusinessDays, addDays, isFuture } from "date-fns";
import {ConfirmationModal} from "@/views/Contracts/modals/ConfirmationModal";
import { AdditionalConclusionChange } from "@/models/Contracts";
import {Address, SubjectDeclaration} from "@/models";
import { SubjectDeclarationModal } from "@/views/modals/SubjectDeclarationSelectionModal/SubjectDeclarationModal";
import {isConcludedEshopContract} from "@/form-declarations/contractStageActions";
import ErrorModal from "@/views/CreateContract/modals/ErrorModal.vue";
import {PickAddressModal} from "@/modal-views/PickAddressModal";
import {IDtoAddress} from "@/models/json";

@Component({ components: { FormTable, CardModal } })
export default class AddSpecification extends Vue {
    @Prop() private prop!: { scope: ContractScope; source: ContractSpecification };

    public cost = this.prop.source.actualCost;
    public volume = this.prop.source.actualVolume;
    public tax = this.prop.source.tax;
    public country = this.prop.source.country;
    public law = this.prop.scope.contract.law;
    public is223 = this.law === "F223";

    public subjectDeclaration = this.prop.source.specification.subjectDeclaration;
    public subjectClass = this.subjectDeclaration?.subjectClass ?? null;
    public isSpgzChanged = this.prop.source.spgzChanged || !this.prop.scope.contract.isEshop;

    private isConcludedEshopContract = isConcludedEshopContract(this.prop.scope.contract);

    public countries: SelectOption<string>[] = [
        { key: null!, desc: '' },
        { key: 'RU', desc: 'РОССИЯ' },
        { key: 'US', desc: 'СОЕДИНЕННЫЕ ШТАТЫ' }
    ];

    public get taxPercent() {
        switch (this.tax) {
            case "TAX_10":
                return 10;
            case "TAX_20":
                return 20;
            default:
                return 0;
        }
    }

    public taxOptions = [
        { key: "TAX_0", desc: "0%" },
        { key: "TAX_10", desc: "10%" },
        { key: "TAX_NONE", desc: "Без НДС" },
        { key: "TAX_20", desc: "20%" }
    ];
    /* #560 - allow specification edit in a signed contract */
    public get editAllowed() {
        const status = this.prop.scope.contract.status;

        const hasActiveCostChangeAdditionalConclusion = this.prop.scope.additionalConclusions
            .filter(conclusion => conclusion.changes.includes(AdditionalConclusionChange.COST_CHANGE)
                && conclusion.terms?.to && isFuture(conclusion.terms.to))
            .length > 0;

        return !this.prop.scope.contract.waitingForStageRegistration
            && (status === ObjectStatus.CREATING_FIRST
                || status === ObjectStatus.CREATING
                || status === ObjectStatus.SIGNED_BY_SUPPLIER
                || status === ObjectStatus.CONCLUDED
                || hasActiveCostChangeAdditionalConclusion);
    }

    public config: IRowConfiguration<unknown> = {
        useSlotForActionColumn: true
    };

    public async selectSubjectClass() {
        if (!this.editAllowed) {
            return;
        }

        const modalResult = await showModal(SubjectDeclarationModal, {
            law: this.law,
            subjectClass: this.subjectClass,
            selectedSubjectDeclaration: this.isSpgzChanged ? this.subjectDeclaration : null
        });

        if (modalResult) {
            this.subjectDeclaration = SubjectDeclaration.fromJson(modalResult);

            if (this.subjectDeclaration) {
                this.isSpgzChanged = true;
            }
        }
    }

    private formatDate(stage: ContractSpecificationSupplyStage, end: boolean): string {
        switch (stage.type) {
            case SupplyStagePeriodType.RELATIVE:
                const offset = end ? stage.relativePeriod.endOffset : stage.relativePeriod.startOffset;
                const type = end ? stage.relativePeriod.endOffsetType : stage.relativePeriod.startOffsetType;
                const startDate = this.prop.scope.contract.conclusionDate;
                if (startDate && offset !== null) {
                    if ((type === DayType.WORKDAY)) {
                        return moment(addBusinessDays(startDate, offset)).format("DD.MM.YYYY");
                    } else {
                        return moment(addDays(startDate, offset)).format("DD.MM.YYYY");
                    }
                }
                return "";
            default:
                return (
                    (end
                        ? stage.absolutePeriod.endDate ? stage.absolutePeriod.endDate .toLocaleDateString() : ""
                        : stage.absolutePeriod.startDate ? stage.absolutePeriod.startDate.toLocaleDateString() : "") + ""
                );
        }
    }

    public stageHeaders = hs<ContractSpecificationSupplyStage>(
        hs.decimal("Объем поставки", x => x.volume),
        hs.any("Дата начала поставки", x => this.formatDate(x, false)),
        hs.any("Дата окончания поставки", x => this.formatDate(x, true)),
        hs.any("Условия поставки", x => x.conditions),
        hs.slot("Адрес поставки", x => x, "address-picker"),
    );

    public loading = false;

    public async saveAndClose() {
        const errors = [];

        if (!this.country && this.subjectClass?.parents[0].code === "01") {
            errors.push("Поле \"Страна происхождения товара\" обязательное, необходимо заполнить.");
        }

        if (errors.length) {
            EventBus.callModal(ErrorModal, { errors });
            return;
        }

        this.loading = true;

        try {
            const newSrc = Object.assign(Object.create(ContractSpecification.prototype), this.prop.source);
            newSrc.actualCost = this.cost;
            newSrc.actualVolume = this.volume;
            newSrc.tax = this.tax;
            newSrc.country = this.country;
            newSrc.specification.subjectDeclaration = this.subjectDeclaration;
            newSrc.spgzChanged = this.isSpgzChanged;

            const src = await ContractSpecifications.update(newSrc);

            const ix = this.prop.scope.contract.specifications.indexOf(this.prop.source);
            this.prop.scope.contract.specifications.splice(ix, 1, newSrc);

            this.$emit("close-modal", ModalResult.ok(src));
        } finally {
            this.loading = false;
        }
    }

    public addSupplyStage() {
        EventBus.callModal(AddSupplyStage, {
            source: null,
            contract: this.prop.scope.contract,
            action: async (x: ContractSpecificationSupplyStage) => {
                const newStage = await ContractSpecifications.createStage(this.prop.source.id, x);

                this.prop.source.stages.push(newStage);
            }
        });
    }

    // #781 - pick address for supply stages
    public async pickAddress(item: ContractSpecificationSupplyStage) {
      const result = await showModal(PickAddressModal, {});
      if (result?.addressLine) {
        const address: Address = Address.fromJson(result as IDtoAddress);
        item.address = address;
        const newStage = await ContractSpecifications.updateStage(item);

        const ix = this.prop.source.stages.indexOf(item);
        this.prop.source.stages.splice(ix, 1, newStage);
      }
    }

    public editSupplyStage(src: ContractSpecificationSupplyStage) {
        EventBus.callModal(AddSupplyStage, {
            source: src,
            contract: this.prop.scope.contract,
            action: async (x: ContractSpecificationSupplyStage) => {
                const newStage = await ContractSpecifications.updateStage(x);

                const ix = this.prop.source.stages.indexOf(src);
                this.prop.source.stages.splice(ix, 1, newStage);
            }
        });
    }

    public async deleteSupplyStage(src: ContractSpecificationSupplyStage) {
        const confirmation = await showModal(ConfirmationModal, {title: "Удаление", text: "Вы уверены, что хотите удалить этап поставки?"});
        if (!confirmation)
            return;

        this.loading = true;

        try {
            await ContractSpecifications.deleteStage(src);

            const ix = this.prop.source.stages.indexOf(src);
            this.prop.source.stages.splice(ix, 1);
        } finally {
            this.loading = false;
        }
    }
}
