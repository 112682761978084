import React, { useState } from "react";
import styles from '../CreateSession.module.css';
import {
    ServicesStore
} from "../store";
import _ from "lodash";
import moment from "moment";
import { Checkbox } from "@/components/eshop";
import { TooltipIcon } from "@/components/TooltipIcon";
import { HeaderWithTooltip } from "./CreateSession";
import {Section} from "@/components/eshop/Section/Section";
import {Card} from "@/components/eshop/Card/Card";

interface HistoryCardProps {
    store: ServicesStore
    setAdditionalInfo: (value: object | null) => void
}

export const HistoryCard = (props: HistoryCardProps) => {
    const store = props.store;
    const [showHistoryAdditional, setShowHistoryAdditional] = useState(false);

    return <Section canBeHidden required title={<>История изменений<TooltipIcon /></>}>
        <Card direction={"vertical"}>
            <div className={styles.status__checkbox}>
                <Checkbox onChange={() => setShowHistoryAdditional(!showHistoryAdditional)}
                          value={showHistoryAdditional}
                          bold
                          caption={'Показывать системные статусы'} />
            </div>
            <table className={styles.cardTable}>
                <thead>
                <tr className={styles.tableRow}>
                    <HeaderWithTooltip>Дата и время</HeaderWithTooltip>
                    <HeaderWithTooltip>Пользователь</HeaderWithTooltip>
                    <HeaderWithTooltip>Статус</HeaderWithTooltip>
                    <HeaderWithTooltip>Сообщение</HeaderWithTooltip>
                    {showHistoryAdditional && <HeaderWithTooltip>Техническая информация</HeaderWithTooltip>}
                </tr>
                </thead>
                <tbody>
                {store.history.length ? _.orderBy(store.history, ['date'], ['desc']).map((h, i) => {
                    const userName = h.user ? `${h.user.lastName} ${h.user.firstName} ${h.user.middleName}` : '';
                    return (<tr className={styles.tableRow} key={i}>
                        <td className={`${styles.cell} ${styles.cellBody}`}>{moment(h.date).format("DD.MM.YYYY HH:mm")}</td>
                        <td className={`${styles.cell} ${styles.cellBody}`}>{userName}</td>
                        <td className={`${styles.cell} ${styles.cellBody}`}>{h.status}</td>
                        <td className={`${styles.cell} ${styles.cellBody}`}>{h.message}</td>
                        {showHistoryAdditional && <td className={`${styles.cell} ${styles.cellBody} ${false ? styles.cellSelected : ''}`}>
                            <a className={styles.linkButton} onClick={() => props.setAdditionalInfo(h)}>
                                Показать
                            </a>
                        </td>}
                    </tr>);
                }) : <tr><td className={styles.tableNoDataRow} colSpan={showHistoryAdditional ? 5 : 4}>Нет записей</td></tr>}
                </tbody>
            </table>
        </Card>
    </Section>;
};
