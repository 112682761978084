import { AutoDto, AutoModel, uuid } from "@/models/parsing";

interface NumberedLotMD {
    lotId: string
    regNumber: string
}

export type NumberedLot = AutoModel<NumberedLotMD>;
export type DtoNumberedLot = AutoDto<NumberedLotMD>;

export const NumberedLot = {
    toDto(l: NumberedLot): DtoNumberedLot {
        return {
            ...l
        };
    },
    fromDto(l: DtoNumberedLot): NumberedLot {
        return {
            ...l
        };
    },
};