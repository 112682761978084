import React from "react";
import { Card } from "@/components/Card";
import {Column, FilteredSelectionTable, RemoteDataset, Table} from "@/components/table";
import {ProvisionStatus, ProvisionStatusStrings} from "@/models/Provision/Provision";
import {HistoryItem} from "@/models";
import {formatDate} from "@/dateUtils";

type HistorySectionProps = {
    history: RemoteDataset<HistoryItem>
};

export const HistorySection = ({ history }: HistorySectionProps) => {
    const columns: Column<HistoryItem>[] = [
        Table.Column(
            "Дата",
            ({ item }) => <>{formatDate(item.date)}</>
        ),
        Table.Column(
            "Пользователь",
            ({ item }) => <>{item.user.fullName}</>
        ),
        Table.Column(
            "Действие",
            ({ item }) => <>{ProvisionStatusStrings[item.status as ProvisionStatus]}</>
        ),
        Table.Column(
            "Основание",
            ({ item }) => <>{item.message}</>
        ),
        Table.Column(
            "№ редакции",
            () => <>1</>
        )
    ];

    return (
        <Card>
            <FilteredSelectionTable columns={columns}
                                    filterStore={history.filterStore}
                                    mode="single"
                                    isFiltersVisible={false}
                                    selectorPosition="hidden"
                                    dataset={history.dataset} />
        </Card>
    );
};
