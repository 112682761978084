import { EnumStrings } from '../EnumStrings';

export const enum PaymentDocumentType {
    CASH_PAYMENT_VOUCHER = "CASH_PAYMENT_VOUCHER",
    PAYMENT_TRANSACTION_CONFIRMATION = "PAYMENT_TRANSACTION_CONFIRMATION",
    RETURN_DOCS = "RETURN_DOCS"
}

export const PaymentDocumentTypeStrings: EnumStrings = {
    [PaymentDocumentType.CASH_PAYMENT_VOUCHER]: "Платежное поручение",
    [PaymentDocumentType.PAYMENT_TRANSACTION_CONFIRMATION]: "Расходный кассовый ордер",
    [PaymentDocumentType.RETURN_DOCS]: "Документ, подтверждающий возврат заказчику излишне уплаченной суммы контракта"
};
