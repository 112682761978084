import { createStore, Store } from "effector";
import { ContractPayment } from "@/models/Contracts/ContractPayment";
import { createStoredRemoteDataset, StoredRemoteDataset } from "@/components/table";
import { getContractPayments } from "@/api/ContractPayments";
import Decimal from "decimal.js";
import { FilterConditionType, FilterValueType } from "@/api/http";

export type ContractPaymentsStore = {
    store: Store<ContractPaymentsState>
};

type ContractPaymentsState = {
    contractPaymentsSrd: StoredRemoteDataset<ContractPayment, ContractPaymentsFilters>
};

export type ContractPaymentsFilters = {
    regNumber: string
    registeredAt: Date
    kbk: string
    totalPrice: Decimal
    obligationRegNumber: string
    contractNumber: string
};

export const createContractPaymentsStore = (contractNumber?: string): ContractPaymentsStore => {
    return {
        store: createStore<ContractPaymentsState>({
            contractPaymentsSrd: createStoredRemoteDataset<ContractPayment, ContractPaymentsFilters>({
                async load(filters, from, count) {
                    return await getContractPayments(from, count, {
                        regNumber: [{
                            type: FilterValueType.STRING,
                            conditionType: FilterConditionType.CONTAINS,
                            string: filters.regNumber
                        }],
                        registeredAt: [{
                            type: FilterValueType.DATE,
                            conditionType: FilterConditionType.EQUAL,
                            date: filters.registeredAt
                        }],
                        kbk: [{
                            type: FilterValueType.STRING,
                            conditionType: FilterConditionType.CONTAINS,
                            string: filters.kbk
                        }],
                        totalPrice: [{
                            type: FilterValueType.DECIMAL,
                            conditionType: FilterConditionType.EQUAL,
                            decimal: filters.totalPrice
                        }],
                        obligationRegNumber: [{
                            type: FilterValueType.STRING,
                            conditionType: FilterConditionType.CONTAINS,
                            string: filters.obligationRegNumber
                        }],
                        contractNumber: [{
                            type: FilterValueType.STRING,
                            conditionType: FilterConditionType.CONTAINS,
                            string: contractNumber ?? filters.contractNumber
                        }]
                    });
                }
            })
        })
    };
};
