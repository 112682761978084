import {
    DtoLotRequestDocument,
    LotRequestDocument
} from "@/models/ComposedLots/documents/LotRequestDocument";
import {
    DtoLotDocument,
    LotDocument
} from "@/models/ComposedLots/documents/LotDocument";
import {
    DtoLotDocumentTerm,
    LotDocumentTerm
} from "@/models/ComposedLots/documents/LotDocumentTerms";
import {Criteria, DtoCriteria} from "@/models/ComposedLots/documents/LotCriteria";
import {AutoDto, AutoModel, MDMap} from "@/models/parsing";
import {DtoLotLegalAct, LotLegalAct} from "@/models/ComposedLots/documents/LotLegalAct";

interface LotDocumentsMD {
    requestDocuments: MDMap<LotRequestDocument[], DtoLotRequestDocument[]>
    lotDocuments: MDMap<LotDocument[], DtoLotDocument[]>
    lotTerms: MDMap<LotDocumentTerm[], DtoLotDocumentTerm[]>
    lotNpa: MDMap<LotLegalAct[], DtoLotLegalAct[]>
    lotCriteria: MDMap<Criteria[], DtoCriteria[]>
    criteriaAssessmentOrder?: string
}

export type LotDocuments = AutoModel<LotDocumentsMD>;
export type DtoLotDocuments = AutoDto<LotDocumentsMD>;

export const LotDocuments = {
    toDto(docs: LotDocuments): DtoLotDocuments {
        return {
            requestDocuments: docs.requestDocuments.map(LotRequestDocument.toDto),
            lotDocuments: docs.lotDocuments.map(LotDocument.toDto),
            lotTerms: docs.lotTerms.map(LotDocumentTerm.toDto),
            lotNpa: docs.lotNpa.map(LotLegalAct.toDto),
            lotCriteria: docs.lotCriteria.map(Criteria.toDto),
            criteriaAssessmentOrder: docs.criteriaAssessmentOrder
        };
    },
    fromDto(docs: DtoLotDocuments): LotDocuments {
        return {
            requestDocuments: docs.requestDocuments.map(LotRequestDocument.fromDto),
            lotDocuments: docs.lotDocuments.map(LotDocument.fromDto),
            lotTerms: docs.lotTerms.map(LotDocumentTerm.fromDto),
            lotNpa: docs.lotNpa.map(LotLegalAct.fromDto),
            lotCriteria: docs.lotCriteria.map(Criteria.fromDto),
            criteriaAssessmentOrder: docs.criteriaAssessmentOrder ?? undefined
        };
    },
};
