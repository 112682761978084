// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, "._3IfK2F47qFCxKVDWe5iyMI {\n    box-sizing: border-box;\n    transition: 0.3s background, border;\n    cursor: pointer;\n    padding: 0 15px;\n    position: relative;\n}\n\n._3IfK2F47qFCxKVDWe5iyMI:hover {\n    background: #EDEFF2;\n}\n\n.lFuVfCxzMUwGknHMt3akF:before {\n    padding: 10px 15px;\n    background: #EDEFF2;\n}\n.lFuVfCxzMUwGknHMt3akF:after {\n    content: '';\n    position: absolute;\n    bottom: -8px;\n    left: 0;\n    right: 0;\n    height: 3px;\n    background: #3f8fca;\n}\n\n._3118H2fVfpnPer04JtInoY {\n\n}\n", ""]);
// Exports
exports.locals = {
	"tab": "_3IfK2F47qFCxKVDWe5iyMI",
	"active": "lFuVfCxzMUwGknHMt3akF",
	"header": "_3118H2fVfpnPer04JtInoY"
};
module.exports = exports;
