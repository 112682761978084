/*
* @deprecated Use formatDate from dateUtils.ts
* */
export function formatDate(d: Date | null | undefined, withDay = false) {
    if (!d) return "";

    const prefix = withDay
        ? d.getDate()
        .toString()
        .padStart(2, "0") + "."
        : "";

    return (
        prefix +
        (d.getMonth() + 1)
            .toString()
            .padStart(2, "0") +
        "." +
        d.getFullYear().toString()
    );
}