import { Lot } from "@/models/ComposedLots";
import { Relation } from "@/components/Relation";
import { Table } from "@/components/table";
import { UnderlinedVueLink } from "@/components/VueLink";
import { LawType, LotStatusStrings } from "@/models/enums";
import router from "@/router";
import React from "react";
import { ProcedureLot } from "@/models/Procedures2020/ProcedureLot";
import { formatNumber } from "@/NumberFormatting";
import uniqBy from "lodash/uniqBy";
import { FinancialLimit } from "@/models/ComposedLots/FinancialLimit";
import { Contract } from "@/models/Contracts";

export const createLotRelation = (lots: ProcedureLot[]): Relation<Lot> => ({
    typeName: "Лот",
    typeNamePlural: "Лоты",
    objects: lots.map(x => ({
        name: x.lot.basicInfo.subject,
        item: x.lot
    })),
    columns: [
        Table.Column("№", x =>
            <UnderlinedVueLink href={`/plan-objects/composed-lots/${x.item.id}`}>{x.item.regNumber}</UnderlinedVueLink>),
        Table.Column("Наименование закупки", x =>
            <>{x.item.basicInfo.subject}</>),
        Table.Column("Статус", x =>
            <>{LotStatusStrings[x.item.status]}</>)
    ],
    open: l => router.push("/plan-objects/composed-lots/" + l.id)
});

export const createLimitRelation = (lots: { lot: Lot }[]): Relation<FinancialLimit> => {
    const limits = uniqBy(lots.flatMap(x => x.lot.plannedPayments).map(x => x.source), x => x.id);

    return {
        typeName: "Лимит",
        typeNamePlural: "Лимиты",
        objects: limits.map(x => ({
            name: `${x.kvr} - ${x.kosgu}`,
            item: x
        })),
        columns: [
            Table.Column("КБК / КВР - КОСГУ/КРП", x =>
                <UnderlinedVueLink href="/finance/pfhd">{`${x.item.kvr} - ${x.item.kosgu}`}</UnderlinedVueLink>),
            Table.Column("Сумма лимита", x =>
                <>{formatNumber(x.item.price)}</>),
            Table.Column("Год", x =>
                <>{x.item.year}</>)
        ],
        open: () => router.push("/finance/pfhd")
    };
};

export const createContractRelation = (contract: Contract): Relation<Contract> => {
    return {
        typeName: contract.law === LawType.F44 ? "Контракт" : "Договор",
        typeNamePlural: contract.law === LawType.F44 ? "Контракты" : "Договоры",
        objects: [{
            name: `${contract.subject}`,
            item: contract
        }],
        columns: [],
        open: () => router.push("/contracts/" + contract.id)
    };
};
