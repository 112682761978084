import React, { useEffect, useState } from "react";
import { ModalFC, showModal } from "@/EventBus";
import { MonopolyFile } from "@/models/Monopoly";
import { CardModal } from "@/components/modals/CardModal";
import { Field, Fields } from "@/components/form/Field";
import { HStack, VStack } from "@/components/layouts";
import { FilePicker } from "@/views/Monopoly/shared/components/FilePicker/FilePicker";
import { Button, TextBox } from "@/components/primitive";
import { replaceOrPush } from "@/arrayUtils";
import { ErrorModal } from "@/views/Contracts/modals/ConfirmationModal";

type CommentaryRequestModalReturn = {
    files: MonopolyFile[]
    commentary: string
};

export const CommentaryRequestModal: ModalFC<undefined, CommentaryRequestModalReturn> = ({ done }) => {
    const [files, setFiles] = useState<(MonopolyFile | undefined)[]>([undefined]);
    const [commentary, setCommentary] = useState<string>("");

    useEffect(() => {
        if (files.length === 0) {
            setFiles([undefined]);
        }
    }, [files]);

    const addFile = (file?: MonopolyFile) => setFiles(files => [...files, file]);

    const changeFile = (oldFile: MonopolyFile | undefined, newFile: MonopolyFile) =>
        setFiles(files => oldFile === undefined ? [...files.slice(0, -1), newFile] : replaceOrPush(files, oldFile, newFile));

    const deleteFile = (file: MonopolyFile) => setFiles(files => files.filter(f => f !== file));

    const send = async () => {
        if (!commentary) {
            await showModal(ErrorModal, { title: "Ошибка", text: "Поле \"Комментарий\" обязательное, необходимо заполнить." });
            return;
        }

        const exitingFiles = files.filter(Boolean) as MonopolyFile[];

        done({ files: exitingFiles, commentary });
    };

    const bottom = <HStack reverse>
        <Button title="Отправить" onClick={send} />
    </HStack>;

    return (
        <CardModal title="Запрос отказа от заключения контракта"
                   width="60%"
                   bottom={bottom}
                   close={() => done(undefined)}>
            <Fields orientation="horizontal">
                <Field title="Файлы" preset="medium">
                    <VStack spacing="10px">
                        {files.map((file, i) => (
                            <HStack key={`${file?.name} - ${i}`} spacing="10px">
                                <FilePicker file={file}
                                            onChange={newFile => changeFile(file, newFile)}
                                            disabled={!file}
                                            onDelete={deleteFile} />
                                <Button icon="aAdd"
                                        onClick={() => addFile(undefined)}
                                        disabled={i !== files.length - 1 || !file} />
                            </HStack>
                        ))}
                    </VStack>
                </Field>
                <Field title="Комментарий" preset="medium">
                    <TextBox value={commentary} lines={5} onChange={setCommentary} />
                </Field>
            </Fields>
        </CardModal>
    );
};
