import React from "react";
import {Card} from "@/components/Card";
import {Label} from "@/components/primitive";
import {Table} from "@/components/table";

export const SuppliersSchedule: React.FC = x => {
    type Template = { template: string };
    const dataset: Template[] = [{ template: ""}];

    return <Card>
        <Table<Template> dataset={dataset} columns={[
            Table.AutoColumn("Дни недели", x =>
                <Label>
                    {x.item.template}
                </Label>),
            Table.AutoColumn("Время работы", x =>
                <Label>
                    {x.item.template}
                </Label>),
        ]} />
    </Card>;
};