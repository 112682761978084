import React from "react";
import styles from "./Quiz.module.css";
import {uuid} from "@/models/parsing";
import { WizardQuestionView } from "@/views/ComposedLot/edit/tabs/lot-documents/DocumentsSection/wizard/tabs/Question";
import { TabAnswers } from "@/views/ComposedLot/edit/tabs/lot-documents/DocumentsSection/wizard/DocumentWizardModal";

export type WizardAnswer = {
    id: uuid
    text: string
};

export type WizardQuestion = {
    id: uuid
    question: string
    answers: WizardAnswer[]
};

interface WizardQuizTabProps {
    ready: boolean
    questions: WizardQuestion[]
    selectedAnswers: TabAnswers
    selectedQuestionIndex: number
    onChangeAnswer?: (question: WizardQuestion, answer: WizardAnswer) => void
    onChangeQuestion?: (question: WizardQuestion, index: number) => void
}

const getActiveClassName = (id: uuid, selected?: uuid): string => id === selected
    ? styles.question + " " + styles.activeQuestion
    : styles.question;

export const WizardQuizTab: React.FC<WizardQuizTabProps> = x => {
    const selectedQuestion = x.questions[x.selectedQuestionIndex ?? 0];
    const quiz: React.ReactNode = <div className={styles.layout}>
        <div className={styles.sidebar}>
            <div className={styles.title}>
                Список вопросов
            </div>
            <div className={styles.questions}>
                {
                    x.questions.map((q, i) => <div className={getActiveClassName(q.id, selectedQuestion?.id)}
                                                   onClick={() => x.onChangeQuestion?.(q, i)}
                                                   key={`question-${q.id}`}>
                        { `${i + 1}. ` }
                        { q.question }
                    </div>)
                }
            </div>
        </div>
        <div>
            {
                selectedQuestion
                    ? <WizardQuestionView
                        question={selectedQuestion}
                        onChangeAnswer={x?.onChangeAnswer}
                        selectedAnswer={x.selectedAnswers[selectedQuestion.id]}/>
                    : ""
            }
        </div>

    </div>;
    return <div>
        {
            x.ready
                ? <div>Вы ответили на вопросы.<br/>Нажмите кнопку назад, если хотите вернуться к вопросам.</div>
                : quiz
        }
    </div>;
};
