import { Card } from "@/components/Card";
import { Column, Table } from "@/components/table/Table";
import React from "react";
import { Store } from "effector";
import {
    CharacteristicValueStoreContainer,
    setAdditionalCharacteristicJustification,
    SpecificationStore
} from "@/modal-views/PickSpecificationModal/store";
import { CharacteristicValue } from "@/models/ComposedLots/specifications/CharacteristicValue";
import { CharacteristicSelectionModeStrings, getChildConditionTypes } from "@/models/ComposedLots/specifications";
import { CharacteristicConditionType, CharacteristicConditionTypeStrings } from "@/models/enums";
import { ComboBox, DecimalBox, MultiSelectBox, TextBox } from "@/components/primitive";
import { useMappedStore } from "@/storeUtils";
import { useStore } from "effector-react";
import { VGrid } from "@/components/layouts";
import { Field, Fields } from "@/components/form/Field";

const Operation = (x: { item: CharacteristicValueStoreContainer }) => {
    const conditionType = useMappedStore(x.item.store, x => x.conditionType);

    const childConditionTypes = getChildConditionTypes(x.item.declaration.conditionType);
    const childConditionTypeOptions = childConditionTypes.map(x => ({
        key: x,
        desc: CharacteristicConditionTypeStrings[x]
    }));

    if (childConditionTypes.length > 1) {
        return <ComboBox options={childConditionTypeOptions} value={conditionType}
                         onChange={newCt => x.item.setConditionType(newCt)}/>;
    } else {
        return <>{CharacteristicConditionTypeStrings[conditionType]}</>;
    }
};

export const CharValue: React.FC<{ item: CharacteristicValueStoreContainer }> = x => {
    const value = useStore(x.item.store);
    const decl = x.item.declaration;

    const change = (partial: Partial<CharacteristicValue>) => x.item.update({...value, ...partial});

    const firstCondTypes = (["GREATER_THAN", "GREATER_THAN_OR_EQUAL"] as CharacteristicConditionType[]).map(x => ({
        key: x,
        desc: CharacteristicConditionTypeStrings[x]
    }));

    const secondCondTypes = (["LESS_THAN", "LESS_THAN_OR_EQUAL"] as CharacteristicConditionType[]).map(x => ({
        key: x,
        desc: CharacteristicConditionTypeStrings[x]
    }));

    switch (decl.valueType) {
        case "NUMBER":
            switch (value.conditionType) {
                case "EQUAL":
                case "NOT_EQUAL":
                case "GREATER_THAN":
                case "LESS_THAN":
                case "GREATER_THAN_OR_EQUAL":
                case "LESS_THAN_OR_EQUAL":
                    return <DecimalBox value={value.numberValueFirst} onChange={v => change({numberValueFirst: v})}/>;
                case "RANGE":
                    return <VGrid columns="1fr 1fr 1fr 1fr" spacing="10px">
                        <ComboBox options={firstCondTypes} onChange={v => change({subConditionTypeFirst: v})}
                                  value={value.subConditionTypeFirst}/>
                        <DecimalBox value={value.numberValueFirst} onChange={v => change({numberValueFirst: v})}/>
                        <ComboBox options={secondCondTypes} onChange={v => change({subConditionTypeSecond: v})}
                                  value={value.subConditionTypeSecond}/>
                        <DecimalBox value={value.numberValueSecond} onChange={v => change({numberValueSecond: v})}/>
                    </VGrid>;
                case "ENUM":
                    return <>NUMBER:ENUM - NOT SUPPORTED</>;
                default:
                    return <>never</>;
            }
        case "TEXT":
            switch (decl.selectionMode) {
                case "NO_SELECTION":
                    return <ul style={{margin: 0, listStylePosition: "inside", padding: 0}}>
                        {decl.enumValues.map((v, k) => <li key={k}>{v}</li>)}
                    </ul>;
                case "SINGLE_SELECTION":
                    return <ComboBox options={decl.enumValues.map(x => ({key: x, desc: x}))} value={value.enumValues[0]}
                                     onChange={v => change({enumValues: [v]})}/>;
                case "MULTI_SELECTION":
                    return <MultiSelectBox options={decl.enumValues.map(x => ({key: x, desc: x}))}
                                           values={value.enumValues} onChange={v => change({enumValues: v})}/>;
                default:
                    return <>never</>;
            }
        case "SIGN":
        case "DATE":
        case "DICTIONARY":
            return <>UNSUPPORTED: {decl.valueType}</>;
    }
};

const charColumns: Column<CharacteristicValueStoreContainer>[] = [
    Table.Column("Характеристика", x => <>{x.item.declaration.name}</>),
    Table.Column("Тип выбора значений", x => <>{CharacteristicSelectionModeStrings[x.item.declaration.selectionMode]}</>),
    Table.Column("Единица измерения", x => <>{x.item.declaration.measurementUnit?.name}</>),
    Table.Column("Условная операция", x => <Operation item={x.item}/>),
    Table.Column("Значение", x => <CharValue item={x.item}/>, {width: "3fr"})
];

const additionalCharsTooltip = "В поле «Обоснование дополнительных\nхарактеристик» вы можете обосновать\nдополнительные характеристики, которые\nотсутствуют в КТРУ. Если в Техническом\nзадании у вас используются дополнительные\nхарактеристики, которые отсутствуют в СПГЗ,\nто вы можете вписать в данное поле такие\nхарактеристики и заполнить для них\nобоснование";

export const Chars = (x: { store: Store<SpecificationStore>; kind: "additional" | "required" | "rules" }) => {
    const isAdditional = x.kind === "additional";
    const title = {
        additional: "Необязательные характеристики",
        required: "Обязательные характеристики",
        rules: "Действующие НПА и правила проведения закупки"
    };
    const [chars, additionalInfo] = useMappedStore(x.store, x => [isAdditional ? x.additionalChars : x.requiredChars, x.additionalCharacteristicJustification]);

    return <Card title={title[x.kind]}>
        {x.kind !== "rules" && <Table dataset={chars} columns={charColumns}/>}
        {x.kind === "additional" && chars.length > 0 &&
            <Fields orientation="horizontal" style={{ marginTop: "10px" }}>
                <Field hint={{ text: additionalCharsTooltip }} preset="readonlyValue" title="Обоснование дополнительных характеристик">
                  <TextBox lines={4} value={additionalInfo} onChange={setAdditionalCharacteristicJustification} />
                </Field>
            </Fields>}
    </Card>;
};
