import {Card} from "@/components/Card";
import {Field, Fields} from "@/components/form/Field";
import {ComboBox, DecimalBox, Label} from "@/components/primitive";
import React from "react";
import {useMappedStore} from "@/storeUtils";
import {Store} from "effector";
import {setMeasurementUnitId, setVolume, SpecificationStore} from "@/modal-views/PickSpecificationModal/store";
import { StartPriceMethodStrings } from "@/models/enums/StartPriceMethod";

export const Price = (x: { store: Store<SpecificationStore>; perItem: boolean; readonly?: boolean}) => {
    const [subjectDeclaration, volume, measurementUnitId, existingDraft] =
        useMappedStore(x.store, x => [x.subjectDeclaration, x.volume, x.measurementUnitId, x.existingDraft]);
    const volumeString: string = `${volume} ${subjectDeclaration?.measurementUnits.find(u => u.id === measurementUnitId)?.name ?? ""}`;
    /* #552 - readonly view */
    return <Card title={x.perItem ? "Цена за единицу" : "Цена"}>
        {
            x.readonly
                ? <Fields orientation="horizontal">
                    {
                        existingDraft?.startPrice && <Field title="Метод определения цены" preset="boldSmall">
                            <Label text={StartPriceMethodStrings[existingDraft.startPrice.method]} />
                        </Field>
                    }
                    <Field title={x.perItem ? "Цена единицы товара, работы, услуги." : "Объем закупки"} preset="boldSmall">
                        <Label text={volumeString} />
                    </Field>
                    <Field title="Цена за единицу" preset="boldSmall">
                        <Label text={existingDraft?.startPrice?.perItem.toString() + " руб."} />
                    </Field>
                    <Field title="Сумма закупки" preset="boldSmall">
                        <Label text={existingDraft?.startPrice?.total.toString() + " руб."} />
                    </Field>
                </Fields>
                : <Fields orientation="horizontal">
                    <Field title={x.perItem ? "Цена единицы товара, работы, услуги." : "Объем закупки"} preset="boldSmall" required>
                        <DecimalBox disabled={x.readonly} style={{maxWidth: "150px"}} value={volume} onChange={setVolume}/>
                    </Field>
                    <Field title="Единица измерения" preset="boldSmall" required>
                        <ComboBox style={{ maxWidth: "150px" }}
                                  options={subjectDeclaration?.measurementUnits?.map(x => ({ key: x.id, desc: x.name })) ?? []}
                                  value={measurementUnitId}
                                  disabled={x.readonly}
                                  onChange={setMeasurementUnitId} />
                    </Field>
                </Fields>
        }

    </Card>;
};
