import { RelatedObjectsItem } from "@/models/RelatedObjects";
import { ModalVue } from "@/view-models/ModalRequest";
import { VueConstructor } from "vue";
import { Vue } from "vue-property-decorator";
import { VueClass } from "vue-class-component/lib/declarations";
import { IHint, IColumn } from "@/components/vue/form-table/FormFields";
import { Identified, ITableColumn } from "@/components/TableAbstractions";

export declare interface ISectionField {}

export declare interface SectionBase<T> {
    type: "FIELDS" | "TABLE" | "UNTYPED"
    name: string
    component: VueClass<Vue>
    allowComponentOverflow?: boolean
    customHeaderBottomPadding?: string
    visibleInNavPanel?: boolean
    visible?: (p: T) => boolean
    required?: boolean
    hint?: IHint
    isRawComponent?: boolean
    cardButtons?: CardButton[]
}

export declare interface FieldSectionData<T> {
    columnCount: number
    formStyle?: FormStyle
    columns: Array<IColumn<T>>
}

export declare interface IFieldSection<T> extends SectionBase<T> {
    type: "FIELDS"
    data: FieldSectionData<T>
}

export declare interface IHeader {
    title: string
}

export declare interface TableSectionData<T, TData> {
    headers: Array<ITableColumn<TData>>
    getter(v: T): TData[] | Promise<TData[]>
    emptyDescription?: string
}

export declare interface ITableSection<T, TData> extends SectionBase<T> {
    type: "TABLE"
    data: TableSectionData<T, TData>
}

export declare interface IUntypedSection<T> extends SectionBase<T> {
    type: "UNTYPED"
    data: unknown
}

export declare type ISection<T> = IFieldSection<T> | IUntypedSection<T> | ITableSection<T, unknown>;

export declare interface IFormStats {
    component: VueClass<Vue>
}

export const enum IconType {
    CLASS = "CLASS",
    FONT = "FONT",
    GLYPH = "GLYPH",
    IMAGE = "IMAGE",
}

export declare interface Icon {
    type: IconType
    name: string
}

export interface CardButton {
    eventName?: string
    title?: string
    icon?: Icon
    color?: string
}

export declare interface IAction<T> {
    name: string
    title?: string
    icon?: Icon
    color?: string
    isRightAligned?: boolean
    isEnabled?(source: T): boolean
}

export interface RelatedModalTable {
    headers: ITableColumn<unknown>[]
    dataset: Identified[]
    title: string
}

export const enum FormStyle {
    VERTICAL = "VERTICAL",
    HORIZONTAL = "HORIZONTAL",
}

export declare interface FormPage<T> {
    id: string
    title?: string
    showNavigationHeader: boolean
    visible?: (_: T) => boolean
    sections: Array<ISection<T>>
}

export declare interface IForm<T> {
    header: string
    stats?: IFormStats
    pages: Array<FormPage<T>>
    actions(t: T): Array<IAction<T>>
    showFooterActions?: boolean
    formStyle?: FormStyle
    relatedObjects?: Array<RelatedObjectsItem>
}
