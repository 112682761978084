import React, { useState } from "react";
import { ContractObligationStore, ObligationsTable } from "@/views/Obligations/shared";
import { useStore } from "effector-react";
import { Pager, useStoredRemoteDataset } from "@/components/table";
import { Button, Note } from "@/components/primitive";
import { Expandee, HStack, VStack } from "@/components/layouts";

type ObligationsListProps = {
    storeComponent: {
        storeContainer: ContractObligationStore
    }
};

export const ObligationsList = ({ storeComponent: { storeContainer } }: ObligationsListProps) => {
    const { contractObligationsSrd } = useStore(storeContainer.store);
    const contractObligations = useStoredRemoteDataset(contractObligationsSrd);

    const [isFiltersVisible, setIsFiltersVisible] = useState<boolean>(true);
    const toggleFiltersVisibility = () => setIsFiltersVisible(is => !is);

    return (
        <VStack spacing="15px" outerStyle={{ margin: "30px" }}>
            <Note preset="title">
                <HStack spacing="10px" alignItems="center">
                    <div>Денежные обязательства</div>
                    <Expandee />
                    <Button icon="aFilter" onClick={toggleFiltersVisibility} />
                </HStack>
            </Note>
            <Note preset="panel">
                <VStack spacing="15px">
                    <ObligationsTable obligations={contractObligations.dataset}
                                      filterStore={contractObligations.filterStore}
                                      isFiltersVisible={isFiltersVisible}
                                      linked />
                    <Pager remoteDataset={contractObligations} />
                </VStack>
            </Note>
        </VStack>
    );
};
