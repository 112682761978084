import { convertPaged, IPagedItems } from "./Pagination";
import { http, FilterValueType, IFilterObject, FilterConditionType } from "./http";
import { SubjectDeclaration } from "@/models/SubjectDeclaration";

export default class {
    public static async get(from: number, count: number, filters: IFilterObject): Promise<IPagedItems<SubjectDeclaration>> {
        return convertPaged(
            await http.getPaged(
                "/api/directory/subjectDeclarations",
                {
                    from: from,
                    count: count,
                    filters: filters
                },
                true,
            ),
            SubjectDeclaration,
        );
    }

    public static async getForClass(from: number, count: number, subjectClassId: number, filters?: IFilterObject): Promise<IPagedItems<SubjectDeclaration>> {
        return convertPaged(await http.getPaged(
            "/api/v2/directory/subjectDeclaration",
            {
                from: from,
                count: count,
                filters: {
                    ...(filters || {}),
                    subjectClassId: [{
                        type: FilterValueType.LONGINT,
                        conditionType: FilterConditionType.EQUAL,
                        longint: subjectClassId,
                    }],
                },
            },
            true,
        ), SubjectDeclaration);
    }

    public static async getForClassBySubjectClass(subjectClassId: number): Promise<SubjectDeclaration[]> {
        return http.get(`/api/v2/directory/subjectDeclaration/all?subjectClassId=${subjectClassId}`);
    }

    /* public static getById(id: string): Promise<IPurchaseObject> {
        return http.get(`/api/directory/subjectClasses/${id}`)
    } */
}
