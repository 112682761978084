import React, {useEffect, useState} from "react";
import {RegisterForm} from "@/views/Register/RegisterForm";
import {showModal} from "@/EventBus";
import {ForgotPasswordModal} from "@/views/Register/forgot/ForgotPasswordModal";
import Auth from "@/api/Auth";
import router from "@/router";
import styles from "./LoginPage.module.css";
import {j} from "@/reactUtils";
import {LoginWizardModal} from "@/views/Login/wizard/LoginWizardModal";
import {intervalToDuration} from "date-fns";

const LoginForm: React.FC<{ onRegister: () => void }> = x => {
    const [hasError, setHasError] = useState(false);

    const [username, setUsername] = useState("");
    const [password, setPassword] = useState("");

    useEffect(() => {
        setHasError(false);
    }, [username, password]);

    const forgot = () => showModal(ForgotPasswordModal, {});
    const register = x.onRegister;
    const login = async () => {
        try {
            const result = await Auth.login(username, password);
            const user = Auth.currentUserInfo;
            try {
                // eslint-disable-next-line @typescript-eslint/ban-ts-ignore
                // @ts-ignore
                if (user && (!user["lastLoginAt"])) await showModal(LoginWizardModal);
                    // eslint-disable-next-line @typescript-eslint/ban-ts-ignore
                // @ts-ignore
                else if (user && user["lastLoginAt"] && (intervalToDuration(new Date(user["lastLoginAt"]), new Date).years > 0)) await showModal(LoginWizardModal);

            } catch (e) {
                console.error(e, user);
            } finally {
                router.resetTo("/");
            }
        } catch (e) {
            console.error(e);
            setHasError(true);
        }
    };

    return <div className={styles.loginForm}>
        <div className={styles.header}>
            <div className={styles["login-mode"]}>
                <div className={styles.label}>Вход в систему</div>
                <div className={j(styles.mode, styles.active)}>По логину</div>
                <div className={j(styles.mode, styles.disabled)}>По сертификату</div>
            </div>
            <div className={styles["flex-spacing"]}/>
            {/*<div className={styles["close-button"]}>&#xe803;</div>*/}
    </div>
    <div className={styles.fields}>
        <div className={styles.field}>
            <div className={styles.icon}>&#xe828;</div>
            <input type="text" placeholder="Логин" value={username} onChange={e => setUsername(e.target.value)} className={hasError ? styles.hasError : undefined}/>
        </div>
        <div className={styles.field}>
            <div className={styles.icon}>&#xe82f;</div>
            <input type="password"
                   onKeyUp={e => {
                       if (e.key === "Enter") login();
                   }}
                   placeholder="Пароль"
                   value={password}
                   onChange={e => setPassword(e.target.value)}
                   className={hasError ? styles.hasError : undefined} />
        </div>
        {
            hasError && <div className={j(styles.field, styles["error-field"])}>
                <div className={styles.icon} style={{visibility: 'hidden', maxHeight: 0}}>&#xe82f;</div>
                <div className={styles.error}>Неверный логин или пароль</div>
            </div>

        }
    <div className={styles["login-button-sec"]}>
        <div className={styles.link} onClick={forgot}>Забыли пароль?</div>
    <div className={styles["flex-spacing"]}/>
    <div className={styles["login-button"]} onClick={login}>
        Войти
        </div>
</div>
</div>
    <div className={styles.footer}>
        <div className={j(styles.link, styles.disabled)}>Завершить активные сессии</div>
        <div className={styles["flex-spacing"]}/>
        <div className={styles.link} onClick={register}>Заявка на регистрацию</div>
 </div>
    </div>;
};

export const LoginPage: React.FC = () => {
    const [mode, setMode] = useState<"login" | "register">("login");
    useEffect(() => {
        const token = localStorage.getItem("sc44_access_token");
        if (token) router.resetTo("/plan-objects/composed-lots");
    }, []);
    return <div style={{ height: "calc(100vh - 85px - 50px)", background: "white", display: "flex", flexDirection: "column" }}>
        {
            mode === "login" ? <LoginForm onRegister={() => setMode("register")} /> : <RegisterForm onLogin={() => setMode("login")} />
        }
    </div>;
};
