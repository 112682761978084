













































































import CardModal from "@/components/CardModal.vue";
import DataTable from "@/components/vue/DataTable.vue";
import { makeFormHelpers } from "@/form-declarations/helpers";
import {
    ChargeType,
    ChargeTypeStrings,
    ClaimWorkReason,
    ClaimWorkReasonStrings,
    ChargeSource,
    ContractDocumentStatusStrings,
} from "@/models/enums/Contracts";
import AddDocument from "@/views/CreateContract/AddDocument.vue";
import { ContractScope } from "@/views/CreateContract/ContractScope";
import { Component, Prop, Vue, Watch } from "vue-property-decorator";
import { ContractClaimWork, ContractDocument, ContractDocumentType, ContractGuarranteeEvent } from "../../models/Contracts";
import { SelectOption, ObjectStatus, toSelectOptions } from "@/models/enums";
import { ModalResult } from "@/view-models/ModalRequest";
import EventBus from "@/EventBus";
import ContractDocumentModal from "../modals/ContractDocumentModal";
import { formatDate } from "@/DateFormatting";
import { ITableColumn } from "@/components/TableAbstractions";
import ErrorModal from './modals/ErrorModal.vue';
import { GuarranteeEventResultStrings } from '@/models/enums/Contracts/GuarranteeEventResult';
import { ContractSpecification } from '@/models/Contracts/ContractSpecification';
import ContractGuarranteeEvents from '@/api/ContractGuarranteeEvents';
import { ContractSpecificationSupplyStage } from '../../models/Contracts/ContractSpecificationSupplyStage';

@Component({ components: { DataTable, CardModal } })
export default class AddClaim extends Vue {
    @Prop() private prop!: { source: ContractGuarranteeEvent | null; scope: ContractScope };

    private findSpec(spec: ContractSpecification) {
        return this.prop.scope.contract.specifications.find(x => x.id === spec.id) || spec;
    }

    public source = new ContractGuarranteeEvent(
        this.prop.source ? this.prop.source.id : null!,
        this.prop.source ? this.prop.source.createdAt : null!,
        this.prop.source ? this.prop.source.date : null!,
        this.prop.source ? this.findSpec(this.prop.source.specification) : null!,
        this.prop.source ? this.prop.source.issues : null!,
        this.prop.source ? this.prop.source.result : null!,
        this.prop.source ? this.prop.source.comments : null!,
        this.prop.source ? this.prop.source.documents : [],
    );

    public async pickFile(src: ContractDocument | null) {
        const props = {
            document: src,
            exactTypeId: 15,
            createAction: async (doc: ContractDocument) => ContractGuarranteeEvents.createDocument(this.source, doc)
        };
        const result = await EventBus.callModal<typeof props, ContractDocument>(AddDocument, props);
        if (result.isOk) {
            const r = result.getResult();
            if (!src) {
                this.source.documents.push(r);
            } else {
                const ix = this.source.documents.findIndex(x => x.id === r.id);
                this.source.documents.splice(ix, 1, r);
            }
        }
    }

    public async deleteFile(src: ContractDocument) {
        if (this.source.id)
            await ContractGuarranteeEvents.deleteDocument(this.source, src);

        const i = this.source.documents.indexOf(src);

        if (i >= 0) {
            this.source.documents.splice(i, 1);
        }
    }

    public loading = false;

    public async saveAndClose() {
        const modelErrors = [];
        if (!this.source.date)
            modelErrors.push("Поле \"Дата обращения\" обязательное, необходимо заполнить.");

        if (!this.source.specification)
            modelErrors.push("Поле \"Спецификация контракта\" обязательное, необходимо заполнить.");

        if (!this.source.issues)
            modelErrors.push("Поле \"Обнаруженные недостатки\" обязательное, необходимо заполнить.");

        if (!this.source.result)
            modelErrors.push("Поле \"Результат обращения\" обязательное, необходимо заполнить.");

        if (modelErrors.length > 0) {
            EventBus.callModal(ErrorModal, { errors: modelErrors });
            return;
        }

        this.loading = true;

        try {
            const src = this.source.id
                ? await ContractGuarranteeEvents.update(this.source)
                : await ContractGuarranteeEvents.create(this.prop.scope.contract.id, this.source);
            this.$emit("close-modal", ModalResult.ok(src));
        } finally {
            this.loading = false;
        }
    }

    public get specifications(): SelectOption<ContractSpecification>[] {
        return [
            {
                key: null!,
                desc: "Выберите спецификацию контракта",
            },
            ...this.prop.scope.contract.specifications.map(x => ({
                key: x,
                desc: x.specification.subjectDeclaration?.subject ?? "",
            })),
        ];
    }

    public results = toSelectOptions(GuarranteeEventResultStrings, true, "Выберите результат обращения");

    public headers: ITableColumn<ContractDocument>[] = [
        {
            title: "№",
            getter: (p, i) => (i + 1).toString(),
            size: "auto",
        },
        {
            title: "Номер документа",
            getter: p => p.documentNumber,
        },
        {
            title: "Дата документа",
            getter: p => formatDate(p.date, true),
        },
        {
            title: "​Наименование документа",
            getter: p => p.file.comment,
        }
    ];
}
