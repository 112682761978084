import React from "react";
import { Column, Table } from "@/components/table";
import { Card } from "@/components/Card";

export const Products223Section = () => {
    const columns: Column<never>[] = [
        Table.Column(
            "Код",
            () => <></>
        ),
        Table.Column(
            "Наименование",
            () => <></>,
            { width: "3fr" }
        ),
        Table.Column(
            "С учетом дочерних",
            () => <></>
        )
    ];

    return (
        <Card>
            <Table columns={columns} dataset={[]} />
        </Card>
    );
};
