












import { Component, Vue, Prop } from "vue-property-decorator";
import Modal from "@/components/Modal.vue";
import { ReactWrapper } from "vuera";
import EventBus from "@/EventBus";
import {ModalResult} from "@/view-models/ModalRequest";

@Component({ components: { Modal, ReactWrapper } })
export default class ReactModalWrapper extends Vue {
    @Prop() public prop!: { fc: React.FC<unknown>; props: unknown };

    public get wrapperEl() {
        const wrapperVue = this.$refs.wrapper as Vue;
        return wrapperVue.$el as HTMLElement;
    }

    public done(result: unknown) {
        this.$emit("close-modal", ModalResult.ok(result));
    }

    public mounted() {
        this.wrapperEl.addEventListener("mousedown", this.handleModalEvent, false);
    }

    public beforeDestroy() {
        this.wrapperEl.removeEventListener("mousedown", this.handleModalEvent, false);
    }

    public handleModalEvent($event: Event) {
        if ($event.target === this.wrapperEl) return;

        $event.stopPropagation();
        EventBus.$emit("close-popup");
    }
}
