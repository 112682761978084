




import {Component, Vue, Prop} from "vue-property-decorator";
import {CustomersView} from './CustomersView';

@Component({ components: { komponent: CustomersView as unknown as typeof Vue } })
export default class ViewSubjectClassVueStub extends Vue {
    @Prop() public id!: string;
}
