import React from "react";
import {Card} from "@/components/Card";
import {Field, FieldGrid} from "@/components/form/Field";
import {Label} from "@/components/primitive";
import {SectionProps} from "@/views/Procedures2020/view/store";
import Auth from "@/api/Auth";
import {useMappedStore} from "@/storeUtils";
import {applyN} from "@/models/parsing";
import {formatNumber} from "@/NumberFormatting";
import Decimal from "decimal.js";

export const InfoSection = (x: SectionProps) => {
    const [regNumber, subject, lots, total] = useMappedStore(x.formStore, x => [
       x.head.regNumber,
       x.info.subject,
       x.lots.lots,
       x.head.lotSpecPriceTotal
    ]);

    const lotsTotalPrice = lots.reduce((p, x) => p.add(x.lot.totalPrice), new Decimal(0))
        .add(lots.reduce((p, x) => p.add(x.lot.includedLots.reduce((p, x) => p.add(x.totalPrice), new Decimal(0))), new Decimal(0)));

    return <Card>
        <FieldGrid>
            <Field title="Реестровый номер">
                <Label text={regNumber ?? ""}/>
            </Field>
            <Field title="Организатор">
                <Label>{Auth.currentUserInfo?.selectedParticipant?.fullName}</Label>
            </Field>
            <Field title="Наименование">
                <Label text={subject ?? ""}/>
            </Field>
            <Field title="Сумма">
                <Label text={formatNumber(lotsTotalPrice) ?? ""}/>
            </Field>
        </FieldGrid>
    </Card>;
};