import {createFilterStore, FilterStore} from "@/components/table/FilteredSelectionTable";
import {useEffect, useState} from "react";
import {IPagedItems} from "@/api/Pagination";
import {debounced} from "@/functionUtils";
import {createEvent, createStore, Event, Store} from "effector";
import {useStore} from "effector-react";

export interface RemoteDataset<T, F extends object = {}> {
    readonly dataset: T[]
    readonly total: number
    readonly filterStore: FilterStore<F>
    readonly currentPage: number
    readonly setCurrentPage: (page: number, forceReload?: boolean) => void
    readonly pageSize: number
}

const useFilterWatcher = <T,>(store: FilterStore<T>, handler: (filters: Partial<T>) => void, debouncingDelay: number = 200) => useEffect(() => {
    const immVal = store.getState();

    const debouncedHandler = debounced(debouncingDelay, handler);
    const skipFirstHandler = (filters: Partial<T>) => {
        if (filters === immVal) return;

        debouncedHandler(filters);
    };

    const sub = store.watch(skipFirstHandler);

    return () => sub.unsubscribe();
});

const usePromise = <T,>(promiseFn: () => Promise<T | undefined>, callbackfn: (t: T) => void) => useEffect(() => {
    let canceled = false;

    (async () => {
        const result = await promiseFn();
        if (!canceled && result !== undefined) callbackfn(result);
    })();

    return () => { canceled = true };
});

interface RemoteDatasetProps<T, F extends object> {
    readonly load: (filters: Partial<F>, from: number, count: number) => Promise<IPagedItems<T>>

    readonly initialFilters?: Partial<F>
    readonly startPage?: number
    readonly pageSize?: number
}

const fixPage = (n: number) => Math.max(0, n | 0);
const defaultPageSize = 20;

interface RemoteDatasetState<T, F extends object = {}> {
    readonly needReload: boolean
    readonly dataset: T[]

    readonly page: number
    readonly pageSize: number
    readonly total: number
}

interface RemoteDatasetStoreContent<T, F extends object = {}> extends RemoteDatasetState<T, F> {
    readonly props: RemoteDatasetProps<T, F>
    readonly filterStore: FilterStore<F>
    readonly setState: Event<RemoteDatasetState<T, F>>
}

export type StoredRemoteDataset<T, F extends object = {}> = Store<RemoteDatasetStoreContent<T, F>>;
export const createStoredRemoteDataset = <T, F extends object = {}>(props: RemoteDatasetProps<T, F>): StoredRemoteDataset<T, F> => {
    const content = {
        props,
        dataset: [],
        needReload: true,
        filterStore: createFilterStore<F>(props.initialFilters),
        page: fixPage(props.startPage ?? 0),
        pageSize: props.pageSize ?? defaultPageSize,
        total: 0,
        setState: createEvent<RemoteDatasetState<T, F>>()
    } as RemoteDatasetStoreContent<T, F>;

    return createStore(content)
        .on(content.setState, (x, v) => ({...x, ...v}));
};

export const setPageSize = <T, F extends object = {}>(on: StoredRemoteDataset<T, F>, size: number | undefined) => {
    const st = on.getState();
    if (st.pageSize !== (size ?? defaultPageSize))
        st.setState({...st, pageSize: size ?? defaultPageSize, needReload: true});
};

export const useStoredRemoteDataset = <T, F extends object = {}>(store: StoredRemoteDataset<T, F>): RemoteDataset<T, F> => {
    const state = useStore(store);
    const props = state.props;
    const setState = state.setState;

    const pageSize = state.pageSize;

    usePromise(async () => {
        if (!state.needReload) return;

        const filters = state.filterStore.getState();
        return await props.load(filters, state.page * pageSize, pageSize);
    }, (result) => {
        setState({
            dataset: result.items,
            needReload: false,
            page: state.page,
            total: result.totalCount,
            pageSize: state.pageSize
        });
    });

    useFilterWatcher(state.filterStore, () => {
        setState({ ...state, needReload: true });
    });

    const setCurrentPage = (page: number, forceReload?: boolean) => {
        const newPage = fixPage(page);

        if (newPage !== state.page || forceReload)
            setState({...state, page: fixPage(page), needReload: true});
    };

    return {
        dataset: state.dataset,
        filterStore: state.filterStore,
        currentPage: state.page,
        setCurrentPage: setCurrentPage,
        total: state.total,
        pageSize: state.pageSize
    };
};

const emptySrd: StoredRemoteDataset<never, never> = createStoredRemoteDataset({
    async load() {
        return {
            from: 0,
            to: -1,
            totalCount: 0,
            items: []
        };
    }
});

export const useOptionalStoredRemoteDataset = <T, F extends object = {}>(store: StoredRemoteDataset<T, F> | undefined): RemoteDataset<T, F> => {
  return useStoredRemoteDataset(store ?? (emptySrd as never));
};

/*
* @deprecated use for testing purposes only
* */
export const useRemoteDataset = <T, F extends object = {}>(props: RemoteDatasetProps<T, F>): RemoteDataset<T, F> => {
    return useStoredRemoteDataset(useState(() => createStoredRemoteDataset(props))[0]);
};