




import {Component, Vue} from "vue-property-decorator";
import {createSubjectClassStore} from "@/views/ReferenceInfo/SubjectClasses/list/store";
import {SubjectClasses} from "@/views/ReferenceInfo/SubjectClasses/list/SubjectClasses";

@Component({ components: { komponent: SubjectClasses as unknown as typeof Vue } })
export default class SubjectClassesVueStab extends Vue {
    public storeContainer = Object.freeze({ store: createSubjectClassStore() });
}
