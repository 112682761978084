import {asDate, AutoDto, AutoModel, uuid} from "@/models/parsing";
import {CharacteristicValueType} from "@/models/enums";

export const statusMapping = {
    "REGISTERED": "Зарегистрирован",
    "ARCHIVED": "Архив",
    "BLOCKED": "Заблокирован",
    "REGISTERIING": "Регистрация"
};

export interface Okpd {
    auctionProduct: boolean
    code: string
    createdDate: Date
    deletedDate: Date
    description: string
    id: uuid
    innovationProduct: string
    name: string
    regulationRequired: boolean
}

export interface Okpd2 {
    code: string
    description: string
    name: string
}

type CharacteristicsKPGZ = {
    id: uuid
    name: string
    valueType: CharacteristicValueType
};

export interface CharacteristicKpgz {
    characteristicskpgz: {
        characteristics: CharacteristicsKPGZ
        characteristicVariantId: string
        id: uuid
        okei: string
    }
    kpgzId: uuid

}

export interface SubjectClassParent {
    id: uuid
    code: string
    name: string
}

export interface SubjectClassMD {
    id: uuid
    capCurRepair: boolean
    cityObjectRef: boolean
    createdDate: Date
    deletedDate: Date
    merCode: string
    description: string
    code: string
    okpd: Okpd
    okpd2: Okpd2
    parents: SubjectClassParent[]
    projectCheck: boolean
    standardProduct: boolean
    requireExpertise: boolean
    kpgzCharacteristicKpgz: CharacteristicKpgz
}

export type DtoSubjectClass = AutoDto<SubjectClassMD>;
export type SubjectClass = AutoModel<SubjectClassMD>;

export const SubjectClass = {
    toDto: (info: SubjectClass) => ({
        ...info,
        createdDate: info.createdDate?.toISOString(),
        deletedDate: info.deletedDate?.toISOString(),
    }),
    fromDto: (info: DtoSubjectClass) => ({
        ...info,
        createdDate: asDate(info.createdDate),
        deletedDate: asDate(info.deletedDate)
    })
};
