import { EnumStrings } from '..';

export const enum ContractDocumentStatus {
    NOT_SENT = "NOT_SENT",
    SENT = "SENT",
}

export const ContractDocumentStatusStrings: EnumStrings = {
    [ContractDocumentStatus.NOT_SENT]: "Не отправлено",
    [ContractDocumentStatus.SENT]: "Отправлено",
};