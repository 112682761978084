import {Expandee, HStack, VStack} from "@/components/layouts";
import {Label} from "@/components/primitive";
import {RoundedButton} from "@/components/primitive/RoundedButton";
import {ModalFC} from "@/EventBus";
import {CardModal} from "@/components/modals/CardModal";
import React from "react";

export const AuditConfirmationModal: ModalFC<undefined, boolean> = x => {

    const bottom = (
        <HStack innerStyle={{padding: "10px 0"}} spacing={"10px"}>
            <Expandee/>
            <RoundedButton color={"blue"}
                           title={"Продолжить"}
                           onClick={() => x.done(true)}/>
        </HStack>
    );

    return (
        <CardModal
            title={"Предупреждение"}
            close={() => x.done(false)}
            width={"70%"}
            bottom={bottom}>
            <VStack spacing={"30px"}>
                <Label text="Установлен признак осуществления закупки услуг по проведению обязательного аудита в соответствии с 307-ФЗ."
                       preset="boldBig"/>
                <Label style={{whiteSpace: "pre-wrap"}} preset="thinMedium">
                    В соответствии с ч.4, ст.5 307-ФЗ от 30.12.2008 г. данные закупки осуществляются путём проведения
                    электронного конкурса в порядке,установленном законодательством РФ о контрактной системе в сфере
                    закупок товаров, работ и услуг.
                    <br/>
                    В соответствии с ч.5, ст.5 307-ФЗ от 30.12.2008 г. в случае, если объём выручки за предыдущий
                    отчётный год не превышает 1 млрд руб, закупка осуществляется среди субъектов МСП.
                </Label>
                <Label preset="boldBig"
                       style={{whiteSpace: "pre-wrap"}}>
                    В качестве способа определения поставщика в лоте будет автоматически выбран электронный конкурс по 44-ФЗ.
                    <br/>
                    В качестве способа размещения среди МСП в лоте будет автоматически установлен вариант &quot;Полностью&quot;.
                </Label>
            </VStack>
        </CardModal>
    );
};