import { AutoDto, AutoModel, uuid } from "@/models/parsing";

interface ShortContractObligationMD {
    id: uuid
    date: Date
    regNumber: string
    type: string
}

export type ShortContractObligation = AutoModel<ShortContractObligationMD>;
export type DtoShortContractObligation = AutoDto<ShortContractObligationMD>;

export const ShortContractObligation = {
    fromDto(dtoShortContractObligation: DtoShortContractObligation): ShortContractObligation {
        return {
            ...dtoShortContractObligation,
            date: new Date(dtoShortContractObligation.date)
        };
    },
    toDto(shortContractObligation: ShortContractObligation): DtoShortContractObligation {
        return {
            ...shortContractObligation,
            date: shortContractObligation.date.toISOString()
        };
    }
};
