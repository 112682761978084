// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, "._3XKqz75OD3C-FbOM77bV7s {\n    color: #303030;\n    font-size: 14px;\n    font-weight: 400;\n    margin: 5px;\n    -webkit-tap-highlight-color: transparent;\n    cursor: pointer;\n    display: flex;\n    align-items: center;\n    box-sizing: border-box;\n}\n\n._1wrzXmSofs5weINh2_wvpQ {\n    margin: 0;\n    visibility: hidden;\n}\n\n._3LvfFpGWZD4wjji0Yx6fA5 {\n    width: 20px;\n    height: 20px;\n    margin: 8px;\n    border: 2px solid #4c4c4c;\n    border-radius: 50%;\n}\n\n._1aWyJ6qu2zIc0mIL98z7Su {\n    width: 8px;\n    height: 8px;\n    border: 8px solid #4db2ff;\n    border-radius: 50%;\n}\n\n._358m2_n2INzwjeSmZlj0Zx:hover {\n    background-color: #b7e0ff;\n}\n\n._358m2_n2INzwjeSmZlj0Zx {\n    width: 40px;\n    height: 40px;\n    border-radius: 50%;\n    transition: background-color 100ms ease-out\n}\n", ""]);
// Exports
exports.locals = {
	"radio__wrapper": "_3XKqz75OD3C-FbOM77bV7s",
	"radio__input": "_1wrzXmSofs5weINh2_wvpQ",
	"radio__fakeInput": "_3LvfFpGWZD4wjji0Yx6fA5",
	"radio__fakeInput__mark_checked": "_1aWyJ6qu2zIc0mIL98z7Su",
	"radio__fakeInput__wrapper": "_358m2_n2INzwjeSmZlj0Zx"
};
module.exports = exports;
