import React, {useEffect, useState} from "react";
import {Section} from "@/components/eshop/Section/Section";
import {Checkbox} from "@/components/eshop";
import {changeIsJoin, ServicesStore} from "@/views/CreateSession/store";
import {Card} from "@/components/eshop/Card/Card";
import {Session, SessionNullable, SessionsController, Status} from "@/api/Sessions";
import {FieldsetText} from "@/components/eshop/FieldsetText/FieldsetText";
import {showModal} from "@/EventBus";
import {PickJointSessionMaster} from "@/views/modals/PickJointSessionMaster/PickJointSessionMaster";
import styles from "../CreateSession.module.css";
import router from "@/router";

interface JoinToSessionCardProps {
    store: ServicesStore
    status?: Status
    canChange: boolean
}

export const JoinToSessionCard = (props: JoinToSessionCardProps) => {

    const [aggregatedSession, setAggregatedSession] = useState<Session>();
    const aggregatedSessionId = props.store.aggregatingSessionId;

    useEffect(() => {
        if (aggregatedSessionId) {
            (async () => {
                const res = await SessionsController.getById(aggregatedSessionId);
                setAggregatedSession(res);
            })();
        }
    }, [aggregatedSessionId]);

    return (
        <>
            <Section title={"Совместная КС"} canBeHidden>
                <Card padded direction={"vertical"} gap={20}>
                    <Checkbox caption={"Совместная КС по соглашению"} value={props.store.isJoin} disabled={props.status !== "DRAFT"} onChange={() => changeIsJoin(!props.store.isJoin)} bold />
                    {props.store.isJoin && <div className={styles.jointSessionSection}>
                        <FieldsetText label={"Организатор совместной котировочной сессии"}
                                      value={aggregatedSession?.mainOrganizer?.shortName ?? "Выбрать"}
                                      onClick={async () => {
                                          await showModal(PickJointSessionMaster, {currentSession: props.store.currentSession!!});
                                      }}/>
                        <FieldsetText label={"Совместная КС"} value={aggregatedSession?.number ?? "-"} onClick={() => router.replace(`/shop/joint-sessions/${aggregatedSessionId}`)} />
                    </div>}
                </Card>
            </Section>
        </>
    );
};
