import React from "react";
import styles from './Modal.module.css';

interface ModalProps {
    title: React.ReactNode
    children?: React.ReactNode
    controls?: React.ReactNode
    onClose: () => void
    small?: boolean
    medium?: boolean
    contentOverflow?: boolean
}

export const Modal = (props: ModalProps) => <div className={`${styles.modalWrapper}`}>
    <div className={`${styles.modalContent} ${props.small ? styles.small : ''} ${props.medium ? styles.medium : ''}`}>
        <div className={`${styles.dialogBody} ${props.contentOverflow ? styles.dialogBodyOveflow : ''}`}>
            {!props.small && <h2 className={styles.modalTitle}>{props.title} <div className={styles.infoClose} onClick={props.onClose} /></h2>}
            {props.children}
        </div>
        {!props.small && <div className={styles.dialogFooter}>
            {props.controls}
        </div>}
    </div>
</div>;
