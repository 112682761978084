import {CompatEnum, UnionStrings} from './EnumStrings';

export type EditReasonType =
    | "TERMS_CHANGE"
    | "VALUE_CHANGE"
    | "GOV_FORCED"
    | "COMM_FORCED"
    | "ECONOMY"
    | "OTHER";

export const EditReasonType: CompatEnum<EditReasonType> = {
    TERMS_CHANGE: "TERMS_CHANGE",
    VALUE_CHANGE: "VALUE_CHANGE",
    GOV_FORCED: "GOV_FORCED",
    COMM_FORCED: "COMM_FORCED",
    ECONOMY: "ECONOMY",
    OTHER: "OTHER"
};

export const EditReasonTypeStrings: UnionStrings<EditReasonType> = {
    TERMS_CHANGE: "Изменение планируемых сроков приобретения товаров, работ, услуг, способа размещения заказа, срока исполнения контракта",
    VALUE_CHANGE: "Изменение более чем на 10% стоимости планируемых к приобретению товаров, работ, услуг, выявленные в результате подготовки к размещению конкретного заказа",
    GOV_FORCED: "Выдача предписания уполномоченного органа исполнительной власти об устранении нарушения законодательства РФ",
    COMM_FORCED: "Изменение по результатам обязательного общественного обсуждения",
    ECONOMY: "Образовавшаяся экономия от использования в текущем финансовом году бюджетных ассигнований",
    OTHER: "Возникновение непредвиденных обстоятельств"
};