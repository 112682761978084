import { convertPaged, IPagedItems } from "./Pagination";
import { http, FilterValueType, IFilterObject, FilterConditionType } from "./http";
import { ActionClass } from '@/models/ActionClass';
import { IDtoActionClass } from '@/models/json';

export default class {
    public static async get(from: number, count: number, filters: IFilterObject): Promise<IPagedItems<ActionClass>> {
        return convertPaged(
            await http.getPaged(
                "/api/directory/actionClasses",
                {
                    from: from,
                    count: count,
                    filters
                },
                true,
            ),
            ActionClass,
        );
    }

    public static async getAll(codeFilter: string | null = null): Promise<ActionClass[]> {
        return (await http.getPaged(
            "/api/directory/actionClasses/all",
            codeFilter
                ? {
                      filters: {
                          code: [{
                              type: FilterValueType.STRING,
                              conditionType: FilterConditionType.STARTS_WITH,
                              string: codeFilter
                            }],
                      },
                  }
                : {},
            true,
        )).map((x: IDtoActionClass) => ActionClass.fromJson(x));
    }
}
