


































import { FComponent } from "@/components/next/FComponent";
import { Component, Vue, Mixins } from "vue-property-decorator";
import { Scrollable } from "../Interfaces";
import smoothScroll from "../SmoothScroll";
import EventBus from "../../EventBus";
import { ApiError } from "../../api/ApiError";

@Component
export default class FForm extends Mixins(FComponent) implements Scrollable {
    private observer: MutationObserver | null = null;
    public sections = [] as { active: boolean; name: string; child: HTMLElement; visible: boolean }[];

    public mounted() {
        if (!this.$refs.body) return;

        this.resetSections();

        const body = this.$refs.body as HTMLDivElement;

        this.observer = new MutationObserver(this.resetSections);
        this.observer.observe(body, { childList: true, subtree: true }); // ???

        body.addEventListener("scroll", this.update);

        EventBus.$on("raise-error", this.onError);
    }

    public onError(e: ApiError | null) {
        if (e) this.smoothScrollTo("TOP");
    }

    private resetSections() {
        const body = this.$refs.main as HTMLDivElement;

        const sections = [] as { active: boolean; name: string; child: HTMLElement; visible: boolean }[];

        for (const child of (body.children as unknown) as Iterable<HTMLElement>) {
            const name = child.dataset.sectionName;
            const visible = child.dataset.visible === "true";

            if (!name) continue;

            sections.push({ name, child, active: false, visible });
        }

        this.sections = sections;

        this.update();
    }

    public beforeDestroy() {
        if (this.observer) {
            this.observer.disconnect();
        }

        const body = this.$refs.body as HTMLElement;
        body.removeEventListener("scroll", this.update);
        EventBus.$off("raise-error", this.onError);
    }

    public scroll(child: HTMLElement) {
        const head = this.$refs.head as HTMLElement;

        smoothScroll(child.offsetTop - 130 - head.getBoundingClientRect().height, this.$refs.body as HTMLElement);
    }

    public smoothScrollTo(target: "TOP") {
        switch (target) {
            case "TOP":
                smoothScroll(0, this.$refs.body as HTMLElement);
                break;
        }
    }

    public update() {
        if (!this.$refs.head || !this.$refs.body) return;

        const head = this.$refs.head as HTMLElement;
        const body = this.$refs.body as HTMLElement;

        const headBounds = head.getBoundingClientRect();

        if (headBounds.top <= 100) {
            head.classList.add("f-pinned");
        } else {
            head.classList.remove("f-pinned");
        }

        Promise.resolve().then(() => {
            let activeFound = false;

            this.sections = this.sections.map(x => {
                if (activeFound) {
                    return {
                        ...x,
                        active: false,
                    };
                }

                const childBounds = x.child.getBoundingClientRect();

                const active = x.visible && childBounds.top >= 100 + headBounds.height;

                activeFound = active;

                return {
                    ...x,
                    active,
                };
            });
        });
    }
}
