import {createStoredRemoteDataset, StoredRemoteDataset} from "@/components/table";
import {Supplier} from "@/models/Supplier";
import {createStore} from "effector";
import {getSuppliers} from "@/api/directory";
import {FilterConditionType, FilterValueType} from "@/api/http";

export interface SuppliersFilters {
    fullName: string
    shortName: string
    tin: string
    rrc: string
    regNumber: string
    spzCode: string
}

export interface SuppliersStore {
    suppliers: StoredRemoteDataset<Supplier, SuppliersFilters>
    loaded: boolean
}

export const createSuppliersStore = () => {
    return createStore<SuppliersStore>({
        suppliers: createStoredRemoteDataset<Supplier, SuppliersFilters>({
            async load(filters, from, count) {
                return getSuppliers(from, count, {
                    fullName: [{
                        type: FilterValueType.STRING,
                        conditionType: FilterConditionType.CONTAINS,
                        string: filters.fullName,
                    }],
                    shortName: [{
                        type: FilterValueType.STRING,
                        conditionType: FilterConditionType.CONTAINS,
                        string: filters.shortName,
                    }],
                    tin: [{
                        type: FilterValueType.STRING,
                        conditionType: FilterConditionType.CONTAINS,
                        string: filters.tin,
                    }],
                    rrc: [{
                        type: FilterValueType.STRING,
                        conditionType: FilterConditionType.CONTAINS,
                        string: filters.tin,
                    }],
                    regNumber: [{
                        type: FilterValueType.STRING,
                        conditionType: FilterConditionType.CONTAINS,
                        string: filters.regNumber,
                    }],
                    spzCode: [{
                        type: FilterValueType.STRING,
                        conditionType: FilterConditionType.CONTAINS,
                        string: filters.spzCode,
                    }],
                });
            }
        }),
        loaded: false,
    });};