import React from "react";
import { VStack } from "@/components/layouts";
import {
    Column,
    FilteredSelectionTable,
    Pager,
    StoredRemoteDataset,
    useStoredRemoteDataset
} from "@/components/table";
import { MonopolyFilters } from "@/views/Monopoly/list/store";
import { Monopoly } from "@/models/Monopoly";

interface MonopolyTableSection {
    isFiltersVisible: boolean
    columns: Column<Monopoly>[]
    srd: StoredRemoteDataset<Monopoly, MonopolyFilters>
}

export const MonopolyTable = ({ srd, isFiltersVisible, columns }: MonopolyTableSection) => {
    const rds = useStoredRemoteDataset(srd);

    return (
        <VStack spacing="10px">
            <FilteredSelectionTable<Monopoly, MonopolyFilters> dataset={rds.dataset}
                                                               filterStore={rds.filterStore}
                                                               mode="single"
                                                               selectorPosition="hidden"
                                                               columns={columns}
                                                               isFiltersVisible={isFiltersVisible} />
            <Pager remoteDataset={rds} />
        </VStack>
    );
};
