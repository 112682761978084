import React from "react";
import { Card } from "@/components/Card";
import { Field, Fields } from "@/components/form/Field";
import { UnderlinedVueLink } from "@/components/VueLink";
import { ShortContract } from "@/models/Contracts/ShortContract";

type ObjectsCommunicationSectionProps = {
    contract?: ShortContract
};

export const ObjectsCommunicationSection = ({ contract }: ObjectsCommunicationSectionProps) => {
    return (
        <Card>
            <Fields orientation="horizontal" spacing="10px" style={{ fontSize: 14 }}>
                <Field title="КОНТРАКТ:">
                    {contract ?
                        <UnderlinedVueLink href={`/contracts/${contract.id}`}>
                            {contract?.regNumber ?? ""}
                        </UnderlinedVueLink>
                        : ""}
                </Field>
                <Field title="ЭТАП КОНТРАКТА:">
                    Да
                </Field>
                <Field title="УЧТЕНО В ЭТАПЕ КОНТРАКТА:">
                    Да
                </Field>
            </Fields>
        </Card>
    );
};
