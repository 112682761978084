import React from "react";
import styles from "./FieldsetText.module.css";
import {TooltipIcon} from "@/components/TooltipIcon";
import {j} from "@/reactUtils";

interface FieldsetTextProps {
    value: string | React.ReactNode
    withTooltip?: boolean
    label?: string
    bordered?: boolean
    onClick?: () => void
    required?: boolean
}

export const FieldsetText = (props: FieldsetTextProps) => {
    return (
        <div className={styles.fieldsetText__wrapper}>
            <fieldset className={j(styles.fieldsetText__fieldset, props.bordered && styles.fieldsetText__fieldset_bordered)}>
                {props.label &&
                    <legend className={j(styles.fieldsetText__label, props.bordered && styles.fieldsetText__label_bordered)}>
                        {props.label}
                        {props.required ? <span className={styles.required}>*</span> : ''}
                        {props.withTooltip && <TooltipIcon />}
                    </legend>
                }
                <span className={j(styles.fieldsetText__field, props.onClick && styles.fieldsetText_clickable)} onClick={props.onClick}>
                    {props.value ?? "—"}
                </span>
            </fieldset>
        </div>
    );
};
