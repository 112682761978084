// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, "._1csSBM1l9tcOWyuKGiZvDJ {\n    font-weight: 400;\n    color: #3d8ecc;\n    text-decoration: none;\n\n    align-content: center;\n    align-items: center;\n    cursor: pointer;\n\n    white-space: normal;\n    overflow: hidden;\n    text-overflow: ellipsis;\n    display: -webkit-box;\n    -webkit-line-clamp: 2;\n    -webkit-box-orient: vertical;\n    -webkit-box-pack: center;\n    -webkit-box-align: start;\n}\n\n._2bkpM52q4Qm-YbZRUjRSyX {\n    color: #3d8ecc;\n    text-decoration: none;\n    cursor: pointer;\n}", ""]);
// Exports
exports.locals = {
	"eshopLinkOverflow": "_1csSBM1l9tcOWyuKGiZvDJ",
	"eshopLink": "_2bkpM52q4Qm-YbZRUjRSyX"
};
module.exports = exports;
