import React from "react";
import "./toasts.module.css";
import {Slide, ToastContainer} from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

export const ToastWrapper: React.FC = x => {
    return <div>
        <ToastContainer position={"top-center"}
                        theme={"colored"}
                        icon={false}
                        autoClose={5000}
                        hideProgressBar
                        newestOnTop={false}
                        closeOnClick={false}
                        rtl={false}
                        transition={Slide}
                        pauseOnFocusLoss
                        draggable
                        pauseOnHover />
    </div>;
};
