import {createStore} from "effector";
import {createStoredRemoteDataset, StoredRemoteDataset} from "@/components/table";
import {Lot} from "@/models/ComposedLots";
import {getLots} from "@/api/ComposedLots";
import {LawType} from "@/models/enums";
import {FilterConditionType, FilterValueType} from "@/api/http";
import Auth from "@/api/Auth";

export interface PlansState {
    lotsDataset: StoredRemoteDataset<Lot>
}

export const createPlansStore = (law: LawType) => createStore<PlansState>({

    lotsDataset: createStoredRemoteDataset({
        load: (filters, from, count) => getLots(Auth.selectedYear, from, count, {
            ...filters,
            law: [
                { type: FilterValueType.STRING, conditionType: FilterConditionType.EQUAL, string: law },
            ],
            status: [
                { type: FilterValueType.STRING, conditionType: FilterConditionType.EQUAL, string: "APPROVED" },
            ]
        })
    })
});