import { EnumStrings } from "@/models/enums/EnumStrings";

export const enum ObjectStatus {
    CREATING_FIRST = "CREATING_FIRST",
    CREATING_APPROVED = "CREATING_APPROVED",
    CREATING = "CREATING",
    PUBLISHED = "PUBLISHED",
    PUBLISH_PENDING = "PUBLISH_PENDING",
    PUBLISH_DATE_SET = "PUBLISH_DATE_SET",
    APPROVED = "APPROVED",
    DELETED = "DELETED",
    CANCELED = "CANCELED",
    GRBS_AGREEMENT_PENDING = "GRBS_AGREEMENT_PENDING",
    GRBS_AGREED = "GRBS_AGREED",
    FO_AGREEMENT_PENDING = "FO_AGREEMENT_PENDING",
    FO_AGREED = "FO_AGREED",
    INCLUDED_IN_PLAN = "INCLUDED_IN_PLAN",
    INCLUDED_IN_LOT = "INCLUDED_IN_LOT",
    SALES_FINISHED = "SALES_FINISHED",
    REGISTERING = "REGISTERING",
    REGISTERED = "REGISTERED",
    EXECUTING = "EXECUTING",
    COMPLETED = "COMPLETED",
    DISSOLVED = "DISSOLVED",
    CONCLUSION_PENDING = "CONCLUSION_PENDING",
    SIGNED_BY_SUPPLIER = "SIGNED_BY_SUPPLIER",
    ESHOP_SIGNED_BY_SUPPLIER = "ESHOP_SIGNED_BY_SUPPLIER",
    ESHOP_CONCLUDED = "ESHOP_CONCLUDED",
    NOTIFICATION_SENT = "NOTIFICATION_SENT",
    ACTUAL = "ACTUAL",
    ARCHIVED = "ARCHIVED",
    CONCLUDED = "CONCLUDED"
}

export const ObjectStatusStrings: EnumStrings = {
    [ObjectStatus.CREATING]: "Формирование",
    [ObjectStatus.CREATING_APPROVED]: "Формирование",
    // [ObjectStatus.CREATING_FIRST]: "Формирование",
    [ObjectStatus.PUBLISHED]: "Опубликован",
    [ObjectStatus.PUBLISH_PENDING]: "На публикации",
    [ObjectStatus.PUBLISH_DATE_SET]: "Установлены сроки размещения",
    [ObjectStatus.APPROVED]: "Утвержден",
    [ObjectStatus.DELETED]: "Удален",
    [ObjectStatus.CANCELED]: "Отменен",
    [ObjectStatus.GRBS_AGREEMENT_PENDING]: "На согласовании ГРБС",
    [ObjectStatus.GRBS_AGREED]: "Согласован",
    [ObjectStatus.FO_AGREED]: "Согласован",
    [ObjectStatus.INCLUDED_IN_PLAN]: "Включен в план",
    [ObjectStatus.INCLUDED_IN_LOT]: "Включен в лот",
    [ObjectStatus.SALES_FINISHED]: "Торги завершены",
    [ObjectStatus.ACTUAL]: "Действующее",
};

export const PurchaseObjectStatusStrings: EnumStrings = {
    ...ObjectStatusStrings,
    [ObjectStatus.INCLUDED_IN_PLAN]: "Включен в план закупок"
};

export const LotStatusStrings: EnumStrings = {
    ...ObjectStatusStrings,
    [ObjectStatus.PUBLISHED]: "Опубликовано в плане-графике",
    [ObjectStatus.INCLUDED_IN_PLAN]: "Включен в план-график",
    [ObjectStatus.GRBS_AGREED]: "Согласовано ГРБС",
};

export const PurchasePlanStatusStrings: EnumStrings = {
    ...ObjectStatusStrings
};

export const SchedulePlanStatusStrings: EnumStrings = {
    ...ObjectStatusStrings
};

export const ProcedureStatusStrings: EnumStrings = {
    ...ObjectStatusStrings,
    [ObjectStatus.PUBLISHED]: "Извещение опубликовано",
    [ObjectStatus.GRBS_AGREED]: "Согласовано ГРБС",
    [ObjectStatus.FO_AGREEMENT_PENDING]: "На согласовании ФО"
};

export const ContractStatusStrings: EnumStrings = {
    [ObjectStatus.CREATING_FIRST]: "Редактирование",
    [ObjectStatus.CREATING_APPROVED]: "Редактирование",
    [ObjectStatus.CREATING]: "Редактирование",
    [ObjectStatus.REGISTERING]: "Регистрация",
    [ObjectStatus.REGISTERED]: "Зарегистрирован",
    [ObjectStatus.EXECUTING]: "Исполнение",
    [ObjectStatus.COMPLETED]: "Исполнен",
    [ObjectStatus.DISSOLVED]: "Расторгнут",
    [ObjectStatus.FO_AGREED]: "Согласован",
    [ObjectStatus.FO_AGREEMENT_PENDING]: "Согласование",
    [ObjectStatus.CONCLUSION_PENDING]: "Заключение",
    [ObjectStatus.SIGNED_BY_SUPPLIER]: "Заключен",
    [ObjectStatus.ESHOP_SIGNED_BY_SUPPLIER]: "Подписано поставщиком",
    [ObjectStatus.CONCLUDED]: "Заключен",
    [ObjectStatus.ESHOP_CONCLUDED]: "Заключен",
    [ObjectStatus.ARCHIVED]: "Архив"
};

export const ContractStageStatusStrings: EnumStrings = {
    [ObjectStatus.CREATING_FIRST]: "Редактирование",
    [ObjectStatus.CREATING_APPROVED]: "Редактирование",
    [ObjectStatus.CREATING]: "Редактирование",
    [ObjectStatus.REGISTERING]: "Регистрация",
    [ObjectStatus.REGISTERED]: "Зарегистрирован",
    [ObjectStatus.EXECUTING]: "Исполнение",
    [ObjectStatus.COMPLETED]: "Исполнен",
    [ObjectStatus.DISSOLVED]: "Расторгнут",
    [ObjectStatus.FO_AGREEMENT_PENDING]: "Согласование",
    [ObjectStatus.FO_AGREED]: "Cогласован"
};

export const DissolutionStatusStrings: EnumStrings = {
    [ObjectStatus.CREATING]: "Проект",
    [ObjectStatus.NOTIFICATION_SENT]: "Направлено уведомление",
};

export const ObjectStatusStringMap: {[key: string]: EnumStrings} = {
    "object": PurchaseObjectStatusStrings,
    "lot": LotStatusStrings,
    "procedure": ProcedureStatusStrings,
    "purchase-plan": PurchasePlanStatusStrings,
    "schedule-plan": SchedulePlanStatusStrings,
    "contract-stage": ContractStageStatusStrings
};
