import {AutoDto, AutoModel, uuid} from "@/models/parsing";
import {ParticipantStatus} from "@/models/enums";

export interface SupplierMD {
    address: string
    fullName: string
    id: uuid
    isCustomer: boolean
    isExpert: boolean
    isSmall: boolean
    isSocial: boolean
    isSpecial: boolean
    isSupplier: boolean
    parentOrganization: boolean
    regNumber: string
    rrc: string
    shortName: string
    spzCode: string
    status: ParticipantStatus
    tin: string
}

export type DtoSupplier = AutoDto<SupplierMD>;
export type Supplier = AutoModel<SupplierMD>;

export const Supplier = {
    toDto: (info: Supplier) => ({
        ...info
    }),
    fromDto: (info: DtoSupplier) => ({
        ...info
    })
};
