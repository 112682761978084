import { EnumStrings } from "./EnumStrings";

export const enum ParticipantStatus {
    REGISTERIING = "REGISTERIING",
    REGISTERED = "REGISTERED",
    BLOCKED = "BLOCKED",
    ARCHIVED = "ARCHIVED",
}

export const ParticipantStatusStrings: EnumStrings = {
    [ParticipantStatus.REGISTERIING]: "На регистрации",
    [ParticipantStatus.REGISTERED]: "Зарегистрирован",
    [ParticipantStatus.BLOCKED]: "Блокирован",
    [ParticipantStatus.ARCHIVED]: "Архив",
};
