import React, {FC, useState} from "react";
import {Card} from "@/components/Card";
import {FormAction, SectionProps} from "@/views/ComposedLot/shared/store";
import {Column, ColumnFC, Table} from "@/components/table/Table";
import {Button, DatePicker, DecimalBox, Label} from "@/components/primitive";
import {HStack, VGrid, VStack} from "@/components/layouts";
import {useMappedStore} from "@/storeUtils";
import EventBus, {showModal} from "@/EventBus";
import {PickSpecificationModal} from "@/modal-views";
import {
    Address,
    CharacteristicValue,
    formatAddress,
    Specification,
    SpecificationDraft,
    SupplyStageCommon
} from "@/models/ComposedLots/specifications";
import {formatNumber} from "@/NumberFormatting";
import styles from "@/views/Monopoly/shared/sections/object/SpecificationsSection/SpecificationsSection.module.css";
import {SelectionTable} from "@/components/table/SelectionTable";
import {CharacteristicConditionType, CharacteristicConditionTypeStrings, DayType, LawType} from "@/models/enums";
import {applyN, uuid} from "@/models/parsing";
import {PickAddressModal} from "@/modal-views/PickAddressModal";
import {OnCalculationDone} from "@/views/ComposedLot/edit/start-price/StartPriceCalculator";
import {StartPrice} from "@/models/ComposedLots/specifications/StartPrice";
import Decimal from 'decimal.js';
import {CloneSpecificationModal} from "@/views/ComposedLot/edit/tabs/specifications/CloneSpecificationModal";
import {j, suppressEvent} from "@/reactUtils";
import {Event} from "effector";
import {createSpecification, deleteSpecificationById, updateSpecification} from "@/api/ComposedLots";
import HintModal from "@/views/CreateContract/modals/HintModal.vue";
import {ConfirmationModal} from "@/views/Contracts/modals/ConfirmationModal";
import {ChevronButton, CopyButton, PlusButton, RemoveButton} from "@/components/eshop/IconButton/IconButtons";
import { RadioButton } from "@/components/eshop/RadioButton/RadioButton";
import { FormControlEshopInput } from "@/components/eshop/FormControlEshopInput";
import { Select } from "@/components/eshop/Select/Select";
import { onChangeNumber, workDaysOptions } from "@/views/CreateSession/Specifications/SpecificationsCard";
import { NumberInput } from "@/components/eshop/NumberInput";
import { LotSpecifications, SpecificationForm } from "@/views/ComposedLot/shared/store/specifications";
import { observer } from "mobx-react";
import { ConfirmStagesDeletion } from "@/views/ComposedLot/edit/tabs/specifications/ConfirmStagesDeletionModal";
import { toJS } from "mobx";

const sig = (c: CharacteristicConditionType) => {
    switch (c) {
        case "GREATER_THAN": return ">";
        case "GREATER_THAN_OR_EQUAL": return "⩾";
        case "LESS_THAN": return "<";
        case "LESS_THAN_OR_EQUAL": return "⩽";
        default: return "?";
    }
};

const valueDesc = (c: CharacteristicValue) => {
    switch (c.conditionType) {
        case "ENUM":
            return c.enumValues.join("; ");
        case "RANGE":
            return `${sig(c.subConditionTypeFirst)} ${c.numberValueFirst?.toString() ?? ""} ${sig(c.subConditionTypeSecond)} ${c.numberValueSecond?.toString() ?? ""}`;
        default:
            return `${CharacteristicConditionTypeStrings[c.declaration.conditionType]} ${c.numberValueFirst?.toString() ?? ""}`;
    }
};

const Char = (x: { char: CharacteristicValue }) => {
    return <div style={{ fontSize: "13px", lineHeight: "20px" }}>{x.char.declaration.name}: {valueDesc(x.char)}</div>;
};

const viewSpecification = async (sd: SpecificationDraft) => {
    await showModal(PickSpecificationModal, {
        perItem: false,
        law: LawType.F44,
        existingSpecificationId: sd.id,
        baseSubjectClass: sd.subjectDeclaration.subjectClass,
        readonly: true
    });
};

const SubjectDeclarationName: ColumnFC<{draft: SpecificationDraft; formAction: FormAction}> = x => {
    const required = x.item.draft.characteristics.filter(x => !x.declaration.isAdditional);
    const additional = x.item.draft.characteristics.filter(x => x.declaration.isAdditional);

    /* Ticket #534, specification view modal should be clickable only when viewing - https://rt.rtall.ru/redmine/issues/534 */
    const onClick = x.item.formAction === "viewing"
        ? () => viewSpecification(x.item.draft)
        : undefined;

    return <VStack>
        <div onClick={onClick}
             style={ x.item.formAction === "viewing" ? { textDecoration: "underline", cursor: "pointer" } : {}}>
            {x.item.draft.subjectDeclaration.subject}
        </div>
        {
            x.item.draft.characteristics.length > 0 && <details style={{marginTop: "10px"}}>
                <summary>Характеристики</summary>
                {
                    required.length > 0 && <>
                        <p><b>Обязательные характеристики</b></p>
                        {required.map((c, i) => <Char key={'add-' + i} char={c}/>)}
                    </>
                }
                {
                    additional.length > 0 && <>
                        <p><b>Необязательные характеристики</b></p>
                        {additional.map((c, i) => <Char key={'add-' + i} char={c}/>)}
                    </>
                }
            </details>
        }
    </VStack>;
};

const SpecificationSupplyWidget: React.FC<{spec: SpecificationForm; isViewing: boolean}> = x => {
    const [currentMode, setCurrentMode] = useState(x.spec.specType);

    const selectAddress = async (address: Address | null, idx: number) => {
        const result = await showModal(PickAddressModal, { existing: toJS(address) ?? undefined });
        if (result) {
            x.spec.supplyStages[idx]!.stageAddress = result;
            x.spec.supplyStages[idx].hasChanges = true;
            // update store?
            console.log(result);
        }
    };

    const relativeStages = x.spec.supplyStages.flatMap(s => s.stageType === "RELATIVE" ? [s] : []);
    const absoluteStages = x.spec.supplyStages.flatMap(s => s.stageType === "ABSOLUTE" ? [s] : []);

    return (<div style={{width: "100%", backgroundColor: "#f1f1f1"}}>
                <VStack>
                    {!x.isViewing && <div style={{display: "flex", justifyContent: "space-between", padding: "10px 5px 10px 64px"}}>
                        <HStack spacing="20px">
                            <RadioButton id={"radio-relative" + x.spec.specId} checked={currentMode === "RELATIVE"} onChange={() => { 
                                setCurrentMode("RELATIVE");
                                x.spec.changeType("RELATIVE");
                                }} label={"Сроки поставки"} />
                            <RadioButton id={"radio-absolute" + x.spec.specId} checked={currentMode === "ABSOLUTE"} onChange={() => {
                                setCurrentMode("ABSOLUTE");
                                x.spec.changeType("ABSOLUTE");
                                }} label={"Даты поставки"} />
                        </HStack>
                        <HStack spacing="10px">
                            <PlusButton onClick={() => x.spec.addSupplyStage()}/>
                            <CopyButton onClick={() => console.log('copy')}/>
                            <RemoveButton onClick={async () => {
                                const isConfirmed = await showModal(ConfirmStagesDeletion);
                                if(isConfirmed)
                                    x.spec.clearStages();
                                }}/>
                        </HStack>
                    </div>}
                        {currentMode === "RELATIVE" && <>
                            <div className={styles.widgetGridRow} style={{justifyItems: "start"}}>
                                <div className={styles.widgetHeaderItem}>Объем</div>
                                <div className={styles.widgetHeaderItem}>Тип дней</div>
                                <div className={styles.widgetHeaderItem}>Начало поставки</div>
                                <div className={styles.widgetHeaderItem}>Конец поставки</div>
                                <div className={styles.widgetHeaderItem}>Адрес поставки</div>
                            </div>
                            {!relativeStages.length && <div className={styles.widgetEmptyError}>Необходимо добавить график поставки</div>}
                            {relativeStages.map((stage, i) => <div key={i} className={styles.widgetGridRow} style={{paddingBottom: stage.paddingForErrors, justifyItems: x.isViewing ? "start" : void 0}}>
                                    {x.isViewing ? <span>{stage.stageVolume?.valueOf() ?? "-"}</span>
                                    : <div style={{position: "relative"}}>
                                        <DecimalBox style={{maxWidth: "150px"}} 
                                                    value={stage.stageVolume ?? undefined} 
                                                    placeholder="Введите значение"
                                                    onChange={(v) => {
                                                        stage.stageVolume = v ?? null;
                                                        stage.hasChanges = true;
                                                        }}/>
                                        {!stage.stageVolume && <div className={styles.volumeError}>Обязательное поле</div>}
                                    </div>}
                                    {x.isViewing ? <span>{stage.stageDayType ? stage.stageDayType === DayType.NORMAL ? "Календарные" : "Рабочие" : "-"}</span>:
                                    <Select
                                        width={135}
                                        small
                                        value={stage.stageDayType ? stage.stageDayType : ''}
                                        onSelect={option => {
                                            if(option) {
                                                stage.stageDayType = option === DayType.NORMAL ? DayType.NORMAL : DayType.WORKDAY;
                                                stage.hasChanges = true;
                                            }
                                        }}
                                        options={workDaysOptions} />}
                                    {x.isViewing ? <span>{stage.startRelative ?? "-"}</span> :
                                    <NumberInput clearable
                                                    checkEmpty
                                                    value={stage.startRelative}
                                                    small
                                                    disableFixed
                                                    disableMask
                                                    validate={(v) => {
                                                        if (!stage.startRelative) return {text: "Обязательное поле"};
                                                        if (stage.endRelative && stage.endRelative < +v) return {text: "Поле 'Начало поставки' не должно быть больше поля 'Окончание поставки'"};
                                                        return {text: ""};
                                                    }}
                                                    onChange={v => {
                                                        stage.startRelative = v;
                                                        stage.hasChanges = true;
                                                    }}
                                                    placeholder={"Введите значение"} />}
                                    {x.isViewing ? <span>{stage.endRelative ?? "-"}</span> : 
                                    <NumberInput clearable
                                                    checkEmpty
                                                    value={stage.endRelative}
                                                    small
                                                    disableFixed
                                                    disableMask
                                                    validate={(v) => {
                                                        if (!stage.endRelative) return {text: "Обязательное поле"};
                                                        return {text: ""};
                                                    }}
                                                    onChange={v => {
                                                        stage.endRelative = v;
                                                        stage.hasChanges = true;
                                                    }}
                                                    placeholder={"Введите значение"} />}
                                    <span style={{ textDecoration: x.isViewing ? "none" : "underline", justifySelf: "baseline" }} onClick={suppressEvent(() => { 
                                        if(!x.isViewing)
                                            selectAddress(stage.stageAddress, i);
                                        })}>
                                        {applyN(formatAddress, stage.stageAddress) ?? "Не указан"}
                                    </span>
                                    {x.isViewing ? <div></div> : <RemoveButton onClick={() => x.spec.deleteStage(i)} />}
                            </div>)}
                        </>}
                        {currentMode === "ABSOLUTE" && <>
                            <div className={styles.widgetGridRow} style={{justifyItems: "start", gridTemplateColumns: "120px 150px 160px 1fr 40px"}}>
                                <div className={styles.widgetHeaderItem}>Объем</div>
                                <div className={styles.widgetHeaderItem}>Начало поставки</div>
                                <div className={styles.widgetHeaderItem}>Конец поставки</div>
                                <div className={styles.widgetHeaderItem}>Адрес поставки</div>
                            </div>
                            {!absoluteStages.filter(s => s.stageType === "ABSOLUTE").length && <div className={styles.widgetEmptyError}>Необходимо добавить график поставки</div>}
                            {absoluteStages.filter(s => s.stageType === "ABSOLUTE").map((stage, i) => <div key={i} className={styles.widgetGridRow} style={{gridTemplateColumns: "120px 150px 160px 1fr 40px", paddingBottom: stage.showStageErrors ? 28 : 10, justifyItems: x.isViewing ? "start" : void 0}}>
                                        {x.isViewing ? <span>{stage.stageVolume?.valueOf() ?? "-"}</span>:
                                         <VStack spacing="8px">
                                            <DecimalBox style={{maxWidth: "150px"}} 
                                                        value={stage.stageVolume ?? undefined} 
                                                        placeholder="Введите значение"
                                                        onChange={(v) => {
                                                            stage.stageVolume = v ?? null;
                                                            stage.hasChanges = true;
                                                            }}/>
                                            {!stage.stageVolume && <div className={styles.errorText}>Обязательное поле</div>}
                                        </VStack>}
                                        {x.isViewing ? <span>{stage.startAbsolute ? stage.startAbsolute.toLocaleDateString().replace("/", ".") : "-"}</span>: 
                                        <DatePicker value={stage.startAbsolute ?? undefined}
                                                    mode="days"
                                                    maxWidth={"150px"}
                                                    appearance="old"
                                                    small
                                                    cantBeCleared
                                                    onChange={day => {
                                                        stage.startAbsolute = day;
                                                        stage.hasChanges = true;
                                                        }} />}
                                        {x.isViewing ? <span>{stage.endAbsolute ? stage.endAbsolute.toLocaleDateString().replace("/", ".") : "-"}</span> : 
                                        <DatePicker value={stage.endAbsolute ?? undefined}
                                                    mode="days"
                                                    maxWidth={"150px"}
                                                    appearance="old"
                                                    small
                                                    cantBeCleared
                                                    onChange={day => {
                                                        stage.endAbsolute = day;
                                                        stage.hasChanges = true;
                                                        }} />}
                                    <span style={{ textDecoration: x.isViewing ? "none" : "underline", justifySelf: "baseline" }} onClick={suppressEvent(() => {
                                        if(!x.isViewing)
                                            selectAddress(stage.stageAddress, i);
                                        })}>
                                        {applyN(formatAddress, stage.stageAddress) ?? "Не указан"}
                                    </span>
                                    {!x.isViewing && <RemoveButton onClick={() => x.spec.deleteStage(i)} />}
                                </div>)}
                                </>}
                                {!x.spec.isVolumeValid && <div className={styles.errorText}>Суммарный объём поставки из графика поставки должен быть равен объёму поставки</div>}
                </VStack>               
            </div>);
};


interface SpecificationsSectionProps extends SectionProps {
    calculateStartPrice: (specification: SpecificationDraft, onDone: OnCalculationDone) => void
    type: "product" | "common"
}

export const SpecificationsSection: React.FC<SpecificationsSectionProps> = observer(x => {
    const viewing = x.formAction === "viewing";
    const [openedTabs, setOpenedTabs] = useState<string[]>([]);

    const isProduct = x.type === "product";

    const haveCommonSpecifications = LotSpecifications.specificationForms.length > 0;
    const [purchasePerUnit, specifications, law, lotId] = useMappedStore(x.formStore, s => [
        s.purchasePerUnit.enabled,
        x.type === "common" ? s.specifications.specificationForms : s.specifications.productSpecificationForms,
        s.info.law,
        s.head.id ?? ""
    ]);

    const addOrEdit = async (existing?: SpecificationDraft) => {
        const result = await showModal(PickSpecificationModal, {
            perItem: purchasePerUnit,
            law,
            //при нажатии редактировать падение из-за existing, с undefined модалка открывается
            existingSpecificationId: existing?.id,
            baseSubjectClass: x.formStore.getState().info.subjectClass
        });

        if (result) {
            const res = await createSpecification(lotId, Specification.toTemplate(result), isProduct);
            
            LotSpecifications.addOrEditSpecification(res);
        }
    };

    const setSpecificationStartPrice = async (spec: SpecificationDraft, startPrice: StartPrice) => {
        const draft = { ...spec, startPrice };

        const result = await updateSpecification(lotId, spec.id ?? "", Specification.toTemplate(draft), isProduct);
        LotSpecifications.addOrEditSpecification(result);
    };

    const clone = async (spec: SpecificationDraft) => {
        const cloneOptions = await showModal(CloneSpecificationModal);
        if (!cloneOptions) return;

        const clonedSpecification = await showModal(PickSpecificationModal, {
            perItem: purchasePerUnit,
            law,
            cloneOptions: cloneOptions,
            existingSpecificationId: spec.id,
            baseSubjectClass: x.formStore.getState().info.subjectClass,
            isClone: true
        });

        if (cloneOptions.cloneSubjectDeclaration &&
            cloneOptions.cloneSupplyStages &&
            cloneOptions.cloneVolume &&
            cloneOptions.cloneStartPrice &&
            clonedSpecification) {

            const draft: SpecificationDraft = {
                ...clonedSpecification,
                id: undefined,
                isNew: true
            };

            const result = await createSpecification(lotId, Specification.toTemplate(draft), isProduct);
            LotSpecifications.addOrEditSpecification(result);

            return;
        }

        // TODO: зачем сабмитить спецификацию второй раз? // i have no idea
        if (clonedSpecification) {
            const result = await createSpecification(lotId, Specification.toTemplate(clonedSpecification), isProduct);
            LotSpecifications.addOrEditSpecification(result);
        }
    };

    const csp = x.calculateStartPrice;

    const columns: Column<SpecificationDraft>[] = [
        Table.ObservableAutoColumn("#", x => (
            <div style={{display: "flex", alignItems: "center", justifyContent: "center"}}>
                        <Label preset="boldSmall" onClick={() => setOpenedTabs(prev => prev.includes(x.item.id!) ? prev.filter(tab => tab !== x.item.id!) : [...prev, x.item.id!])}
                               className={j(styles.subject, openedTabs.includes(x.item.id!) && styles.opened)}>
                            <span className={styles.expanderIcon}/>
                        </Label>
                        {x.rowIndex + 1}
                    </div>
                ),
        ),
        Table.ObservableColumn("СПГЗ", c => <SubjectDeclarationName {...c} item={{draft: c.item, formAction: x.formAction}}/>, { width: "3fr" }),
        ...((purchasePerUnit ? [
            Table.ObservableColumn(
                "Начальная сумма цен единиц товара, работы, услуги",
                x => <>{formatNumber(x.item.volume)}</>,
                { width: "2fr" }
            ),
        ...(x.type !== "product" ? [Table.Column("Сумма лимита, руб", x => <>
                {x.item.startPrice?.total.toString() ?? ""}
            </>)] as Column<SpecificationDraft>[] : []),
        ] : [
            Table.ObservableColumn("Объем", x => <>{formatNumber(x.item.volume)}</>),
            Table.ObservableColumn("Цена за единицу", x => <>{formatNumber((x.item.startPrice?.total ?? new Decimal(0)).div(x.item.volume))}</>),
            Table.ObservableColumn("Сумма, руб.", x => <>{formatNumber(x.item.startPrice?.total ?? 0)}</>),
        ]) as Column<SpecificationDraft>[]),
        ...((!viewing ? [
            Table.ObservableAutoColumn("Ред.", x => <Button icon={"aEdit"} onClick={suppressEvent(() => addOrEdit(x.item))}/>),
        ...(x.type !== "product" ? [Table.ObservableAutoColumn(purchasePerUnit ? "Расчет суммы лимита" : "НМЦ", x => <Button icon="faCalculator" onClick={() => {
                csp(x.item, r => {
                    if (r) setSpecificationStartPrice(x.item, r);
                });
            }}/>)] as Column<SpecificationDraft>[] : []),
            Table.AutoColumn("Копия", x => <Button icon="aClone" onClick={suppressEvent(() => clone(x.item))}/>)
        ] : []) as Column<SpecificationDraft>[])
    ];

    const insets = specifications.filter((s,i) => openedTabs.includes(s.specId)).map((s,i) => Table.InsetRow(specifications.findIndex(sp => sp.specId === s.specId) + 1,Table.Inset(<SpecificationSupplyWidget key={s.specId} spec={s} isViewing={viewing}/>, `1 / ${columns.length + 1}`)));

    const [selected, setSelected] = useState<SpecificationDraft[]>([]);

    const deleteSelected = async () => {
        await Promise.all(selected.map(v => deleteSpecificationById(lotId, v.id ?? "", isProduct)));
        LotSpecifications.remove(selected.map(v => v.id ?? ""));
        setSelected([]);
    };

    return (
        <Card>
            <VStack spacing={"15px"}>
                {
                    specifications.length
                        ? <>
                            <SelectionTable dataset={specifications.map(s => s.validated as SpecificationDraft)} columns={columns} mode="multi" selected={selected}
                                          onChange={viewing ? undefined : setSelected} selectorPosition="hidden" insets={insets}/>
                          </>
                        : <Label preset="boldSmall" text="Спецификаций нет"/>
                }
                {
                    !viewing && <VGrid columns={"auto auto 1fr"} spacing={"5px"}>
                        <Button disabled={x.type != "common" && !haveCommonSpecifications} color={"green"} title={"Добавить"} icon={"aAdd"} onClick={() => addOrEdit()}/>
                        <Button color={"red"} title={"удалить"} disabled={selected.length == 0} onClick={deleteSelected}/>
                    </VGrid>
                }
            </VStack>
        </Card>
    );
});
