import { AddressType } from "@/models/enums/AddressType";
import {AutoDto, AutoModel} from "@/models/parsing";

interface AddressCommonMD {
    type: AddressType
    addressLine: string
}

type AddressMD = AddressCommonMD & (
    { type: "LANDMARK"; additionalInfo: string } |
    { type: Exclude<AddressType, "LANDMARK"> });

export type Address = AutoModel<AddressMD>;
export type DtoAddress = AutoDto<AddressMD>;

export const Address = {
    fromDto(info: DtoAddress): Address {
        return info; // lol
    },
    toDto(info: Address): DtoAddress {
        return info; // lol
    },
};

export const formatAddress = (a: Address) => a.type === "LANDMARK"
    ? a.addressLine.concat(a.additionalInfo ? `: ${a.additionalInfo}` : "") : a.addressLine;