import {ValidationError} from "@/api/errorHandling";

type CheckError = { text: string; path?: string };
type CheckedPositive<T> = { result: true; value: T };
type CheckedNegative = { result: false; errors: CheckError[] };
export type Checked<T> = CheckedPositive<T> | CheckedNegative;
export type Assertable<T> = { [key in keyof T]: Checked<T[key]> };

export const vPropagate = <T>(x: Assertable<T>): Checked<T> => {
    const newObj = {} as T;
    let err: CheckError[] = [];

    for (const p in x) {
        if (x.hasOwnProperty(p)) {
            const r = x[p] as Checked<T[typeof p]>;

            if (r.result) {
                newObj[p] = r.value;
            } else {
                err = err.concat(r.errors.map(e => ({ text: e.text, path: e.path ? p + '.' + e.path : p })));
            }
        }
    }

    if (err.length) {
        return { result: false, errors: err };
    }

    return { result: true, value: newObj };
};

export const vAssert = <T>(x: Assertable<T>): T => {
    const propagated = vPropagate(x);

    if (!propagated.result) {
        console.warn("Validation error:\n\t" + propagated.errors.map(x => `${x.path} - ${x.text}`).join("\n\t"));
        throw new ValidationError(...propagated.errors.map(x => x.text));
    }

    return propagated.value;
};

export const vCheck = <T>(cond: boolean, cause: string): Checked<undefined> =>
    !cond
        ? { result: false, errors: [{ text: cause }] }
        : { result: true, value: undefined };

export const vOk = <T>(value: T): Checked<T> => ({ result: true, value });

export const vDefined = <T>(value: T | undefined, cause: string): Checked<T> =>
    value === undefined
        ? { result: false, errors: [{ text: cause }] }
        : { result: true, value };

export const vNotEmpty = <T extends unknown[] | string>(value: T | undefined, cause: string): Checked<T> =>
    value === undefined || !(typeof value === "string" ? value.trim().length : value.length)
        ? { result: false, errors: [{ text: cause }] }
        : { result: true, value };

export const vNotEmptyWhen = <T>(cond: boolean, value: T | undefined, cause: string): Checked<T | undefined> =>
    value === undefined && cond
        ? { result: false, errors: [{ text: cause }] }
        : { result: true, value };