import { asDate, AutoDto, AutoModel, MDMap, uuid } from "@/models/parsing";

interface MonopolyFileMD {
    name: string
    length: number
    comment?: string
    createdAt: Date
}

export type MonopolyFile = AutoModel<MonopolyFileMD>;
export type DtoMonopolyFile = AutoDto<MonopolyFileMD>;

export const MonopolyFile = {
    fromDto(dtoMonopolyFile: DtoMonopolyFile): MonopolyFile {
        return {
            ...dtoMonopolyFile,
            comment: dtoMonopolyFile?.comment ?? undefined,
            createdAt: asDate(dtoMonopolyFile.createdAt)
        };
    },
    toDto(monopolyFile: MonopolyFile): DtoMonopolyFile {
        return {
            ...monopolyFile,
            comment: monopolyFile?.comment ?? "",
            createdAt: monopolyFile.createdAt?.toISOString()

        };
    }
};

interface MonopolyDocumentMD {
    id: uuid
    file: MDMap<MonopolyFile, DtoMonopolyFile>
}

export type MonopolyDocument = AutoModel<MonopolyDocumentMD>;
export type DtoMonopolyDocument = AutoDto<MonopolyDocumentMD>;

export const MonopolyDocument = {
    fromDto(dtoMonopolyDocument: DtoMonopolyDocument): MonopolyDocument {
        return {
            ...dtoMonopolyDocument,
            file: MonopolyFile.fromDto(dtoMonopolyDocument.file)
        };
    },
    toDto(monopolyDocument: MonopolyDocument): DtoMonopolyDocument {
        return {
            ...monopolyDocument,
            file: MonopolyFile.toDto(monopolyDocument.file)
        };
    }
};

interface MonopolyDocumentTemplateMD {
    file: MDMap<MonopolyFile, DtoMonopolyFile>
    comment?: string
}

export type MonopolyDocumentTemplate = AutoModel<MonopolyDocumentTemplateMD>;
export type DtoMonopolyDocumentTemplate = AutoDto<MonopolyDocumentTemplateMD>;

export const MonopolyDocumentTemplate = {
    toDto(monopolyDocumentTemplate: MonopolyDocumentTemplate): DtoMonopolyDocumentTemplate {
        return {
            ...monopolyDocumentTemplate,
            file: MonopolyFile.toDto(monopolyDocumentTemplate.file)
        };
    }
};
