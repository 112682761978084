



import {Component, Vue, Prop} from "vue-property-decorator";
import {AdminPage} from './AdminPage';

@Component({ components: { komponent: AdminPage as unknown as typeof Vue } })
export default class AdminPageVueStub extends Vue {
}
