




import {Component, Vue} from "vue-property-decorator";
import {createSuppliersStore} from "@/views/ReferenceInfo/Suppliers/list/store";
import {Suppliers} from "@/views/ReferenceInfo/Suppliers/list/Suppliers";

@Component({ components: { komponent: Suppliers as unknown as typeof Vue } })
export default class SuppliersVueStab extends Vue {
  public storeContainer = Object.freeze({ store: createSuppliersStore() });
}
