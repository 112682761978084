import React, {ReactNode, FC, CSSProperties, ReactElement, MouseEvent} from "react";
import * as styles from "./Table.module.css";
import {GridColumnProperty} from "csstype";
import {observer} from "mobx-react";

export type TableProps<T, C> = {
    dataset: T[]
    columns: Column<T, C>[]
    insets?: Inset[]
    cellOverrides?: CellOverride<T, C>[]
    scope?: C
    hideHeaders?: boolean
};

export type ColumnFC<T, C = undefined> = FC<{ item: T; scope: C | undefined; rowIndex: number }>;

export type CellOverride<T, C = undefined> = (item: T, rowIndex: number, columnIndex: number, column: Column<T, C>) => Partial<CellOptions> | undefined;

interface CellOptions {
    readonly cellClassName: string
    readonly cellStyle: CSSProperties
    readonly cellOnClick?: (e: MouseEvent, rowIndex: number) => void
}

export interface ColumnOptions extends CellOptions {
    readonly width: string
    readonly headerClassName: string
    readonly headerStyle: CSSProperties
    readonly wrapCell: boolean
}

interface NonGenericColumn {
    options: ColumnOptions
    header: ReactNode
}

export interface Column<T, C = undefined> extends NonGenericColumn {
    cell: ColumnFC<T, C>
    observable?: boolean
}

type DynamicInsetCells = (row: number) => InsetCell[];

export interface Inset {
    cells: InsetCell[] | DynamicInsetCells
    at: number | ((n: number) => boolean)
}

type WrapLookLike = "raw" | "header" | "value";

export type InsetCell = {
    wrapLookLike?: WrapLookLike
    wrapStyle?: CSSProperties
    wrapClassName?: string
    column: GridColumnProperty
} & ({
    child?: undefined
    childFC: FC
} | {
    child: ReactNode
    childFC?: undefined
});

const TableHeaderCell: React.FC<{ column: NonGenericColumn }> = x => {
    return <div className={x.column.options.headerClassName} style={x.column.options.headerStyle}>
        {x.column.header}
    </div>;
};

const defined = <T,>(t: T | undefined): t is T => t !== undefined;

const TableValueCell = <T, C>(x: { column: Column<T, C>; item: T; rowIndex: number; columnIndex: number; overrides: CellOverride<T, C>[]; scope: C | undefined }): ReactElement => {
    const Cell = x.column.cell;

    const matchedOverrides = x.overrides.map(o => o(x.item, x.rowIndex, x.columnIndex, x.column))
        .filter(defined);

    const joinedClasses = x.column.options.cellClassName + " " + matchedOverrides.map(x => x.cellClassName ?? "").join(" ");
    const joinedStyles = matchedOverrides.reduce((a, b) => ({...a, ...b.cellStyle}), x.column.options.cellStyle);
    const joinedOnClicks = (e: MouseEvent) => {
        x.column.options.cellOnClick?.(e, x.rowIndex);
        for (const override of matchedOverrides) {
            override.cellOnClick?.(e, x.rowIndex);
        }
    };

    // If ColumnFC is wrapped in observer() `Cell(x)` crashes in runtime.
    // If ColumnFC is not wrapped in observer, <Cell /> is recreated when not necessary
    return <div className={joinedClasses} style={joinedStyles} onClick={joinedOnClicks}>
        { x.column.observable ? <Cell {...x} /> : Cell(x) }
    </div>;
};

const getWrapOpts = (x: InsetCell): [string?, CSSProperties?] => {
    switch (x.wrapLookLike) {
        case "raw": return [x.wrapClassName, x.wrapStyle];
        case "header": return [`${styles.TableHeaderCell} ${x.wrapClassName ?? ""}`, x.wrapStyle];
        case undefined:
        case "value": return [`${styles.TableValueCell} ${x.wrapClassName ?? ""}`, x.wrapStyle];
    }
};

const InsetWrap: React.FC<InsetCell> = x => {
    const [className, style] = getWrapOpts(x);

    if (x.childFC) {
        const Component = x.childFC;
        return <div className={className} style={{...style, gridColumn: x.column}}><Component/></div>;
    } else {
        return <div className={className} style={{...style, gridColumn: x.column}}>{x.child}</div>;
    }
};

const InsetRow: React.FC<{ cells: InsetCell[] }> = x => {
    return <>
        {
            x.cells.map((c, i) => <InsetWrap key={i} {...c} />)
        }
    </>;
};

/*
  Usage note:

  This component uses FCs for cells that called directly, e.g.

  `<>{Cell(props)}</>`

  This can cause issues in rendering and I'm not sure about this
  If any issues happen, replace it with `<Cell {...props}/>` and avoid
  creating `columns` in render-time. To pass additional data inside cells
  (which we can pass only in render-time) you can use `scope` prop
 */
export const Table = <T, C = undefined>(x: TableProps<T, C>) => {
    const overrides = x.cellOverrides ?? [];
    const headers = x.columns.map((c, ci) => <TableHeaderCell column={c} key={ci}/>);
    const items = x.dataset.flatMap((i, ri) => x.columns.map((c, ci) => {
        return <TableValueCell scope={x.scope} column={c} item={i} rowIndex={ri} key={`c-${ci}-${ri}`} columnIndex={ci} overrides={overrides}/>;
    }));

    if (x.insets) {
        const resultingInsets: { index: number; cells: InsetCell[] }[] = [];

        for (const inset of x.insets) {
            for (let i = 0; i < items.length; i++) {
                if (inset.at === i || (typeof inset.at !== "number" && inset.at(i))) {
                    resultingInsets.push({ index: i, cells: Array.isArray(inset.cells) ? inset.cells : inset.cells(i) });
                }
            }

            if (inset.at === items.length || (typeof inset.at !== "number" && inset.at(items.length))) {
                resultingInsets.push({ index: items.length, cells: Array.isArray(inset.cells) ? inset.cells : inset.cells(items.length) });
            }
        }

        resultingInsets.sort((x, y) => x.index - y.index);

        for (let i = 0; i < resultingInsets.length; i++) {
            items.splice(i + resultingInsets[i].index * x.columns.length, 0, <InsetRow key={'inset-' + i} cells={resultingInsets[i].cells} />);
        }
    }

    return <div className={styles.Table} style={{gridTemplateColumns: x.columns.map(c => c.options.width).join(" ")}}>
        {x.hideHeaders ? null : headers}
        {items}
    </div>;
};

export const defaultColumnOptions: ColumnOptions = {
    width: "1fr",
    headerClassName: styles.TableHeaderCell,
    headerStyle: {},
    wrapCell: true,
    cellClassName: styles.TableValueCell,
    cellStyle: {}
};

Table.Column = <T, C = undefined>(header: ReactNode, cell: ColumnFC<T, C>, options: Partial<ColumnOptions> = {}): Column<T, C> => ({
    header,
    cell,
    options: {...defaultColumnOptions, ...options}
});

Table.ObservableColumn = <T, C = undefined>(header: ReactNode, cell: ColumnFC<T, C>, options: Partial<ColumnOptions> = {}): Column<T, C> => ({
    header,
    observable: true,
    cell: observer(cell),
    options: {...defaultColumnOptions, ...options}
});

Table.AutoColumn = <T, C = undefined>(header: ReactNode, cell: ColumnFC<T, C>, options: Partial<ColumnOptions> = {}): Column<T, C> => ({
    header,
    cell,
    options: {...defaultColumnOptions, ...options, width: "auto" }
});

Table.ObservableAutoColumn = <T, C = undefined>(header: ReactNode, cell: ColumnFC<T, C>, options: Partial<ColumnOptions> = {}): Column<T, C> => ({
    header,
    observable: true,
    cell: observer(cell),
    options: {...defaultColumnOptions, ...options, width: "auto" }
});

Table.Inset = (child: ReactNode, column: GridColumnProperty, wrapLookLike?: WrapLookLike, wrapStyle?: CSSProperties, wrapClassName?: string): InsetCell =>
    ({ child, column, wrapStyle, wrapClassName, wrapLookLike });
Table.FCInset = (childFC: FC, column: GridColumnProperty, wrapLookLike?: WrapLookLike, wrapStyle?: CSSProperties, wrapClassName?: string): InsetCell =>
    ({ childFC, column, wrapStyle, wrapClassName, wrapLookLike });
Table.InsetRow = (at: Inset["at"], ...cells: InsetCell[]): Inset => ({ at, cells });
Table.DynInsetRow = (at: Inset["at"], cells: DynamicInsetCells): Inset => ({ at, cells });
