import React, { useState } from "react";
import { Expandee, HStack, VStack } from "@/components/layouts";
import { Button, Note } from "@/components/primitive";
import { Column, FilterColumn, FilteredSelectionTable, Pager, Table, useStoredRemoteDataset } from "@/components/table";
import { ContractPaymentsFilters, ContractPaymentsStore } from "@/views/Payments/list/store";
import { useStore } from "effector-react";
import { ContractPayment } from "@/models/Contracts/ContractPayment";
import { formatDate } from "@/dateUtils";
import { formatNumber } from "@/NumberFormatting";
import { UnderlinedVueLink } from "@/components/VueLink";
import {PaymentsTable} from "@/views/Payments/shared/PaymentsTable";

type PaymentsListProps = {
    storeComponent: {
        storeContainer: ContractPaymentsStore
    }
};

export const PaymentsList = ({ storeComponent: { storeContainer } }: PaymentsListProps) => {
    const { contractPaymentsSrd } = useStore(storeContainer.store);
    const contractPayments = useStoredRemoteDataset(contractPaymentsSrd);

    const [isFiltersVisible, setIsFiltersVisible] = useState<boolean>(true);
    const toggleFiltersVisibility = () => setIsFiltersVisible(is => !is);

    return (
        <VStack spacing="15px" outerStyle={{ margin: "30px" }}>
            <Note preset="title">
                <HStack spacing="10px" alignItems="center">
                    <div>Платежи</div>
                    <Expandee />
                    <Button icon="aFilter" onClick={toggleFiltersVisibility} />
                </HStack>
            </Note>
            <Note preset="panel">
                <VStack spacing="15px">
                    <PaymentsTable
                        filterStore={contractPayments.filterStore}
                        payments={contractPayments.dataset}
                        isFiltersVisible={isFiltersVisible}
                    />
                    <Pager remoteDataset={contractPayments} />
                </VStack>
            </Note>
        </VStack>
    );
};
