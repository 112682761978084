// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".GXbXfFPUFsARLz13qwCKG {\n    flex-grow: 1;\n}\n\n/*noinspection CssReplaceWithShorthandSafely*/\n._1VT-ZdLp25dpF-jGegv4r {\n    --spacing-vertical: 0;\n    --spacing-horizontal: 0;\n\n    /* do not merge (stops working when merged) */\n    margin-top: calc(0px - 0.5 * var(--spacing-vertical));\n    margin-bottom: calc(0px - 0.5 * var(--spacing-vertical));\n    margin-left: calc(0px - 0.5 * var(--spacing-horizontal));\n    margin-right: calc(0px - 0.5 * var(--spacing-horizontal));\n}\n\n/*noinspection CssReplaceWithShorthandSafely*/\n._1VT-ZdLp25dpF-jGegv4r > * {\n    margin-top: calc(0.5 * var(--spacing-vertical));\n    margin-bottom: calc(0.5 * var(--spacing-vertical));\n    margin-left: calc(0.5 * var(--spacing-horizontal));\n    margin-right: calc(0.5 * var(--spacing-horizontal));\n}\n\n._1VT-ZdLp25dpF-jGegv4r > .GXbXfFPUFsARLz13qwCKG:not(:first-child):not(:last-child) {\n    margin: 0;\n}", ""]);
// Exports
exports.locals = {
	"expandee": "GXbXfFPUFsARLz13qwCKG",
	"stack": "_1VT-ZdLp25dpF-jGegv4r"
};
module.exports = exports;
