import { IDtoSpecification } from "@/models/json";
import { IJsonFormattable } from "@/models/IJsonFormattable";
import Decimal from "decimal.js";
import { SupplyStage, SubjectDeclaration, StartPrice, MeasurementUnit } from "@/models";
import { ActionClass } from './ActionClass';
import {Address} from "@/models/ComposedLots/specifications";

export class Specification implements IJsonFormattable<IDtoSpecification> {
    constructor(
        public subjectDeclaration: SubjectDeclaration,
        public actionClass: ActionClass | null,
        public volume: Decimal,
        public singleItemPrice: Decimal,
        public sparePartsPrice: Decimal,
        public measurementUnit: MeasurementUnit | null,
        public supplyStages: SupplyStage[],
        public supplyAddress: Address | null,
        public startPrice: StartPrice | null
    ) {}

    public toJson(): IDtoSpecification {
        return {
            ...this,
            subjectDeclaration: this.subjectDeclaration.toJson(),
            volume: this.volume.toString(),
            singleItemPrice: this.volume.toString(),
            sparePartsPrice: this.volume.toString(),
            supplyStages: this.supplyStages.map(x => x.toJson()),
            startPrice: this.startPrice ? this.startPrice.toJson() : null,
            supplyAddress: this.supplyAddress ? Address.toDto(this.supplyAddress) : null,
            measurementUnit: this.measurementUnit?.toJson() ?? null,
            actionClass: this.actionClass ? this.actionClass.toJson() : null,
        };
    }

    // noinspection JSUnusedGlobalSymbols
    public static fromJson(dto: IDtoSpecification): Specification {
        return Object.assign(Object.create(Specification.prototype), dto, {
            subjectDeclaration: dto.subjectDeclaration ? SubjectDeclaration.fromJson(dto.subjectDeclaration) : null,
            volume: new Decimal(dto.volume),
            singleItemPrice: new Decimal(dto.singleItemPrice),
            sparePartsPrice: new Decimal(dto.sparePartsPrice),
            supplyStages: dto.supplyStages.map(x => SupplyStage.fromJson(x)),
            startPrice: dto.startPrice ? StartPrice.fromJson(dto.startPrice) : null,
            supplyAddress: dto.supplyAddress ? Address.fromDto(dto.supplyAddress) : null,
            measurementUnit: dto.measurementUnit ? MeasurementUnit.fromJson(dto.measurementUnit) : null,
            actionClass: dto.actionClass ? ActionClass.fromJson(dto.actionClass) : null,
        });
    }
}
