import React from "react";
import { Form, FormHeader, FormState } from "@/components/form";
import { VStack } from "@/components/layouts";
import { ObligationStoreState } from "@/views/Obligations/view/store";
import { Store } from "effector";
import { useStore } from "effector-react";
import { InfoSection } from "@/views/Obligations/shared/sections/InfoSection";
import { ObjectsCommunicationSection } from "@/views/Payments/shared/sections";
import { PaymentsTableSection } from "@/views/Obligations/shared/sections/PaymentsTableSection";
import { HistorySection } from "@/views/Obligations/shared/sections/HistorySection";
import { useStoredRemoteDataset } from "@/components/table";
import { ReceiptsTableSection } from "@/views/Obligations/shared";

type ObligationViewProps = {
    storeContainer: {
        store: Store<ObligationStoreState>
    }
};

export const ObligationView = ({ storeContainer: { store } }: ObligationViewProps) => {
    const { obligation, history, receipts } = useStore(store);
    const rds = useStoredRemoteDataset(receipts);

    const title = obligation?.regNumber
        ? `Денежное обязательство ${obligation.regNumber} Загружено`
        : "Денежное обязательство";

    const header = (
        <VStack spacing="15px">
            <FormHeader title={title} />
        </VStack>
    );

    const tabs = Form.Tab("info", "Общая информация", [
        Form.Section("Общая информация", <InfoSection obligation={obligation} />),
        Form.Section("Связь с объектами ЕАИСТ", <ObjectsCommunicationSection contract={obligation?.contract} />),
        Form.Section("Поступления", <ReceiptsTableSection receipts={rds.dataset} rds={rds} />),
        Form.Section("Платежи", <PaymentsTableSection payments={obligation?.payments} />),
        Form.Section("История изменений", <HistorySection history={history} />)
    ]);

    return (
        <FormState value="readonly">
            <Form header={header} tabs={[tabs]} />
        </FormState>
    );
};
