import React, {useState} from "react";
import {replaceProcedure, SectionProps} from "@/views/Procedures2020/view/store";
import {Card} from "@/components/Card";
import {VStack} from "@/components/layouts";
import {Button, Label} from "@/components/primitive";
import {useMappedStore} from "@/storeUtils";
import {Lot} from "@/models/ComposedLots";
import styles from "./ResultsSection.module.css";
import {CellOverride, Column, Table} from "@/components/table";
import {ProcedureParticipant} from "@/models/Procedures2020/ProcedureParticipant";
import {formatNumber} from "@/NumberFormatting";
import {LawType} from "@/models/enums";
import Contracts from "@/api/Contracts";
import router from "@/router";
import {showModal} from "@/EventBus";
import {ContractCreationModal} from "@/views/Procedures2020/trade/sections/ContractCreationModal";
import {ParticipantInfoModal} from "@/views/Procedures2020/trade/sections/ParticipantInfoModal";
import {ErrorModal} from "@/views/Contracts/modals/ConfirmationModal";
import {Text} from "office-ui-fabric-react";
import {setParticipant} from "@/api/Procedures2020";

const LotResult = (x: {law: LawType; lot: Lot; participants: ProcedureParticipant[]; index: number; procedureId?: string}) => {
    const [state, setState] = useState(true);

    const sorted = [...x.participants].sort((x, y) => x.orderNumber - y.orderNumber);

    const filtered = sorted.filter(p => p.status !== "REQUIREMENTS_FAILED");

    const place = (p: ProcedureParticipant) => {
        if (sorted.length === 1)
            return "Единственный участник";
        if (p.status === "REQUIREMENTS_FAILED")
            return "-";

        const place = filtered.indexOf(p) + 1;

        switch (place) {
            case 1: return "1 - Победитель";
            case 2: return "2 - Второй номер";
            default: return place.toString();
        }
    };

    const createContractLabel = x.law === "F44" ? "Формирование контракта" : "Формирование договора";

    const createContract = async (participant?: ProcedureParticipant) => {
        if(!participant?.participant?.id) {
            await showModal(ErrorModal, {text: <VStack spacing="15px">
                <Text>Невозможно сформировать договор.</Text>
                <Text>Отсутствует информация об участнике закупки для формирования договора.</Text>
                <Text>Необходимо выбрать участника из НСИ. Для этого нажмите на &quot;Наименование участника&quot; в таблице &quot;Результаты закупки&quot;</Text>
            </VStack>});
            return;
        }
        const result = await showModal(ContractCreationModal, {tin: participant.participant.tin});
        if (result) {
            if (x.lot.basicInfo.lotJoinType === "MASTER") {
                await Contracts.createJointPurchaseContracts2020(x.lot.law, participant.procedureId, x.lot.id, sorted[0].participant.id);
                return;
            }
            const contract = await Contracts.createFromProcedure2020(x.lot.law, participant.procedureId, x.lot.id, sorted[0].participant.id);
            router.push("/contracts/" + contract.id).ignore;
        }
    };

    const showParticipantInfo = async (participantIndex: number, participant?: ProcedureParticipant) => {
        const result = await showModal(ParticipantInfoModal, {participant: participant?.participant});
        if(result && result.participant ) {
            if (x.procedureId) {
                const procedure = await setParticipant(x.procedureId, x.participants[participantIndex - 1].id, result.participant.id);
                await replaceProcedure(procedure);
            }
        }
    };

    const columns: Column<ProcedureParticipant>[] = [
        Table.Column("Номер в журнале регистрации", x => <>{x.item.orderNumber}</>),
        Table.Column("Наименование участника", x => <div style={{textDecoration: "underline", cursor: "pointer"}} onClick={() => showParticipantInfo(x.item.orderNumber, x.item)}>
                {x.item.participant.fullName ? x.item.participant.fullName : "Не представлено"}
            </div>),
        Table.Column("Статус допуска", x => <>{
            x.item.status === "REQUIREMENTS_FAILED"
                ? "Не соответствует требованиям"
                : "Соответствует требованиям"
        }</>),
        Table.Column("Предлагаемая цена", x => <>{formatNumber(x.item.price)}</>),
        // Table.Column("Итоговый рейтинг", x => <>{x}</>),
        Table.Column("Порядковый номер", x => <>{place(x.item)}</>),
        Table.Column(createContractLabel, x => {
            if (x.item.status === "WINNER" || (x.item.status === "REQUIREMENTS_SUCCESSFUL" && x.item.id))
                return <Button title={createContractLabel} onClick={() => createContract(x.item)}/>;
            else
                return null;
        })
    ];

    const colorOverride: CellOverride<ProcedureParticipant> = i => {

        if (sorted.length === 1 && i.status === "REQUIREMENTS_SUCCESSFUL" && i.id) {
            return {
                cellStyle: {
                    background: "#dff0d8"
                }
            };
        }

        return i.status === "WINNER" ? {
            cellStyle: {
                background: "#dff0d8"
            }
        } : undefined;
    };

    return <VStack spacing="3px">
        <Label preset="boldSmall" onClick={() => setState(!state)}>
            <div className={styles.expanderIcon} />
            Лот №{x.index + 1} {x.lot.basicInfo.subject}
        </Label>
        { state && <Table dataset={sorted} columns={columns} cellOverrides={[colorOverride]} /> }
    </VStack>;
};

export const ResultsSection: React.FC<SectionProps> = x => {
    const [status, law, lots, procedureId] = useMappedStore(x.formStore, x => [x.head.status, x.head.law, x.lots.lots, x.head.id]);
    const mustShowSection = status === "TRADE_FINISHED";

    return mustShowSection ? <Card>
        <VStack spacing="15px">
            {lots.map((l, i) => <LotResult law={law} lot={l.lot} procedureId={procedureId} index={i} key={i} participants={l.participants} />)}
        </VStack>
    </Card> : null;
};
