import React from "react";
import { ModalFC } from "@/EventBus";
import { Participant } from "@/models";
import { CardModal } from "@/components/modals/CardModal";
import {Field, Fields, FieldStack, VertField} from "@/components/form/Field";
import { TextBox } from "@/components/primitive";
import {HStack, VStack} from "@/components/layouts";

type CustomerInfoModalProps = {
    customer: Participant
};

export const CustomerInfoModal: ModalFC<CustomerInfoModalProps> = ({ customer, done }) => {
    return (
        <CardModal title="Сведения о заказчике" close={done} width="40%">
            <Fields orientation="horizontal">
                <Field title="Наименование:" preset="boldSmall">
                    <TextBox value={customer.shortName} disabled />
                </Field>
                <Field title="Доп.информация:" preset="boldSmall">
                    <TextBox value={customer.fullName} lines={3} disabled />
                </Field>
            </Fields>
            <Fields orientation="horizontal">
                <Field title="ИНН:" preset="boldSmall">
                    <TextBox value={customer.shortName} disabled />
                </Field>
                <Field title="КПП:" preset="boldSmall">
                    <TextBox value={customer.shortName} disabled />
                </Field>
            </Fields>
            <Fields orientation="horizontal">
                <Field title="ОГРН:" preset="boldSmall">
                    <TextBox value={customer.shortName} disabled />
                </Field>
                <Field title="Телефон:" preset="boldSmall">
                    <TextBox value={customer.shortName} disabled />
                </Field>
                <Field title="Факс:" preset="boldSmall">
                    <TextBox value={customer.shortName} disabled />
                </Field>
                <Field title="E-mail:" preset="boldSmall">
                    <TextBox value={customer.shortName} disabled />
                </Field>
                <Field title="Web-сайт:" preset="boldSmall">
                    <TextBox value={customer.shortName} disabled />
                </Field>
                <Field title="Юридический адрес:" preset="boldSmall">
                    <TextBox value={customer.shortName} lines={3} disabled />
                </Field>
                <Field title="Почтовый адрес:" preset="boldSmall">
                    <TextBox value={customer.shortName} lines={3} disabled />
                </Field>
            </Fields>
        </CardModal>
    );
};
