import { ContractFinancialSource } from "@/models/Contracts";
import { IDtoContractFinancialSource } from "@/models/json/Contracts";
import { Decimal } from "decimal.js";
import { IDtoContractEconomy } from '@/models/json/Contracts';
import { IJsonFormattable } from '@/models/IJsonFormattable';

export class ContractEconomy implements IJsonFormattable<IDtoContractEconomy> {
    constructor(
        public id: string,
        public source: ContractFinancialSource,
        public amount: Decimal,
    ) {}

    public toJson(): IDtoContractEconomy {
        return {
            id: this.id,
            source: this.source.toJson(),
            amount: this.amount.toString(),
        };
    }

    public static fromJson(dto: IDtoContractEconomy): ContractEconomy {
        return Object.assign(Object.create(ContractEconomy.prototype), {
            id: dto.id,
            source: ContractFinancialSource.fromJson(dto.source),
            amount: new Decimal(dto.amount),
        });
    }
}
