import { createStoredRemoteDataset, StoredRemoteDataset } from "@/components/table";
import { Participant } from "@/models";
import { createStore } from "effector";
import Participants from "@/api/Participants";
import { FilterConditionType, FilterValueType } from "@/api/http";

export type ParticipantsFilters = { fullName: string };

export interface ParticipantsStore {
    participants: StoredRemoteDataset<Participant, ParticipantsFilters>
    loaded: boolean
}

export const createParticipantStore = () => {
    return createStore<ParticipantsStore>({
        participants: createStoredRemoteDataset<Participant, ParticipantsFilters>({
            async load(filters, from, count) {
                return Participants.get(from, count, {
                    tinOrName: [{
                        type: FilterValueType.STRING,
                        conditionType: FilterConditionType.CONTAINS,
                        string: filters.fullName
                    }]
                });
            }
        }),
        loaded: false
    });
};
