import { User } from "@/models/User";
import { MonopolyStatus } from "@/models/Monopoly/Monopoly";
import { asDate, AutoDto, AutoModel, MDMap } from "@/models/parsing";
import { IDtoUser } from "@/models/json/IDtoUser";

type MonopolyHistoryItemMD = {
    user: MDMap<User | null, IDtoUser | null>
    date: Date
    status: MonopolyStatus
    message: string
    commentary: string
    version: number
};

export type MonopolyHistoryItem = AutoModel<MonopolyHistoryItemMD>;
export type DtoMonopolyHistoryItem = AutoDto<MonopolyHistoryItemMD>;

export const MonopolyHistoryItem = {
    fromDto(dtoMonopolyHistoryItem: DtoMonopolyHistoryItem): MonopolyHistoryItem {
        return {
            ...dtoMonopolyHistoryItem,
            user: dtoMonopolyHistoryItem?.user ? User.fromJson(dtoMonopolyHistoryItem.user) : null,
            date: asDate(dtoMonopolyHistoryItem.date)
        };
    }
};
