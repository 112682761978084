import { Component, Prop, Vue, Watch } from "vue-property-decorator";
import Modal from "@/components/Modal.vue";
import { DatePickerModel } from './DatePickerModel';
import EventBus from '@/EventBus';
import { DatePickerModalEventArgs } from './DatePickerModalEventArgs';

@Component({ components: { Modal } })
export default class DatePickerModal extends Vue {
    @Prop() private prop!: DatePickerModalEventArgs;

    private get model() { return this.prop.model }

    public mounted() {
        this.model.open = true;
        this.prop.forceClose.then(() => this.$emit('close-modal'));
    }

    public handleModalEvent($event: Event) {
        $event.stopPropagation();
        EventBus.$emit("close-popup");
    }
    
    @Watch("model.open") public updateOpenState() {
        if (!this.model.open)
            this.$emit("close-modal");
    }

    public hide() {
        this.model.hide();
    }

    public prev() {
        this.model.prev();
    }

    public up() {
        this.model.up();
    }

    public next() {
        this.model.next();
    }

    public select(key: number) {
        this.model.select(key);
    }

    public now() {
        this.model.now();
    }

    public get formatted() {
        return this.model.formatted;
    }

    public get open() {
        return this.model.open;
    }

    public get hasPrev() {
        return this.model.hasPrev;
    }

    public get hasUp() {
        return this.model.hasUp;
    }

    public get hasNext() {
        return this.model.hasNext;
    }

    public get label() {
        return this.model.label;
    }

    public get columns() {
        return this.model.columns;
    }

    public get body() {
        return this.model.body;
    }

    public get footer() {
        return this.model.footer;
    }
}
