import { observer } from "mobx-react";
import React, { ChangeEvent } from "react";
import { TooltipIcon } from "../TooltipIcon";
import styles from "./FormControlEshopInput.module.css";

interface FormControlEshopInputProps {
    onChange?: (e: { target: { value: string } }) => void
    onKeyDown?: (e: React.KeyboardEvent<HTMLInputElement>) => void
    placeholder?: string
    value?: string | number | readonly string[] | undefined
    small?: boolean
    disabled?: boolean
    clearable?: boolean
    checkEmpty?: boolean
    type?: 'number'
}

export const FormControlEshopInput = observer((props: FormControlEshopInputProps) => {
    return <div className={styles.eshopInputWrap}>
        <input type={props.type}
            disabled={props.disabled}
            value={props.value}
            placeholder={props.placeholder}
            onChange={props.onChange}
            onKeyDown={props.onKeyDown}
            className={`${styles.formControlEshopInput} ${props.small ? styles.inputSmall : ''} ${props.checkEmpty && props.value === '' ? styles.error : ''} ${props.disabled ? styles.disabled : ''}`} />
        {(props.clearable && !props.disabled) && <div onClick={() => props.onChange && props.onChange({ target: { value: '' } })} className={styles.closeButton} />}
    </div>;
});

interface FormControlEshopProps {
    label: React.ReactNode
    children?: React.ReactElement
    withTooltip?: boolean
    boldLabel?: boolean
}

export const FormGroup = observer((props: FormControlEshopProps) => {
    return <div className={styles.form}>
        <label className={`${styles.formLabel} ${props.boldLabel ? styles.boldLabel : ''}`}>{props.label}{props.withTooltip && <TooltipIcon />}</label>
        {props.children}
    </div>;
});
