import React, {useState} from "react";
import {Expandee, HStack, VStack} from "@/components/layouts";
import {Button, Note} from "@/components/primitive";
import {Column, FilterColumn, FilteredSelectionTable, Pager, Table, useStoredRemoteDataset} from "@/components/table";
import {useStore} from "effector-react";
import {Store} from "effector";
import { Procedure, ProcedureStatus, ProcedureStatusStrings } from "@/models/Procedures2020/Procedure";
import { LawType, LawTypeStrings } from "@/models/enums";
import {applyN} from "@/models/parsing";
import {formatNumber} from "@/NumberFormatting";
import {formatDate} from "@/dateUtils";
import { ProviderSelectionType, ProviderSelectionTypeStrings } from "@/models/ComposedLots";
import Auth from "@/api/Auth";
import { TradesFilters, TradesState } from "@/views/Procedures2020/trade/list/store";
import {UnderlinedVueLink} from "@/components/VueLink";

/** Отображаются процедуры только со статусом "Извещение опубликовано" и "Торги завершены" */
export const Trades = (x: { storeContainer: { store: Store<TradesState> } }) => {
    const state = useStore(x.storeContainer.store);
    const rds = useStoredRemoteDataset(state.procedures);
    const [filtersVisible, setFiltersVisible] = useState(true);

    const columns: Column<Procedure>[] = [
        Table.Column("Реестровый номер", a => <>
            {a.item.regNumber}
        </>, {width: "2fr"}),
        FilterColumn<Procedure, TradesFilters>({
            header: "Закон-основание",
            cell: a => <>{LawTypeStrings[a.item.law]}</>,
            filter: {
                type: "select",
                options: Object.keys(LawTypeStrings).map(k => ({
                    key: k,
                    desc: LawTypeStrings[k as LawType]
                })),
                values: Object.keys(LawTypeStrings),
                key: "law"
            }
        }),
        FilterColumn<Procedure, TradesFilters>({
            header: "Наименование предмета закупки",
            cell: a => <UnderlinedVueLink href={`/procedures/2020/${a.item.id}/trade`} >
                {a.item.subject}
            </UnderlinedVueLink>,
            filter: {
                type: "string",
                key: "subject"
            },
            options: { width: "4fr" }
        }),
        Table.Column("Организатор", () => <>
            {Auth.currentUserInfo?.selectedParticipant?.shortName}
        </>),
        FilterColumn<Procedure, TradesFilters>({
            header: "Способ размещения",
            cell: a => <>{a.item.providerSelectionType ? ProviderSelectionTypeStrings[a.item.providerSelectionType] : ""}</>,
            filter: {
                type: "select",
                options: Object.keys(ProviderSelectionTypeStrings).map(k => ({
                    key: k,
                    desc: ProviderSelectionTypeStrings[k as ProviderSelectionType]
                })),
                values: Object.keys(ProviderSelectionTypeStrings),
                key: "providerSelectionType"
            },
            options: { width: "3fr" }
        }),
        Table.Column("Уровень размещения", () => <>
            Первый
        </>),
        FilterColumn<Procedure, TradesFilters>({
            header: "Сумма, руб.",
            cell: a => <>{a.item.winnerLotSpecPriceTotal ? formatNumber(a.item.winnerLotSpecPriceTotal): formatNumber(a.item.lotSpecPriceTotal)}</>,
            filter: {
                type: "decimal",
                key: "lotSpecPriceTotal"
            }
        }),
        Table.Column("Плановая дата публикации", a => <>
            {applyN(formatDate, a.item.publishTerms?.publishDate) ?? ""}
        </>),
        FilterColumn<Procedure, TradesFilters>({
            header: "Статус",
            cell: a => <>{ProcedureStatusStrings[a.item.status]}</>,
            filter: {
                type: "select",
                options: Object.keys(ProcedureStatusStrings).map(k => ({
                    key: k,
                    desc: ProcedureStatusStrings[k as ProcedureStatus]
                })),
                values: Object.keys(ProcedureStatusStrings),
                key: "status"
            }
        }),
    ];

    return <VStack outerStyle={{margin: "30px"}} spacing="15px">
        <Note preset="title">
            <HStack alignItems="center" spacing={"10px"}>
                <div>Все процедуры</div>
                <Expandee/>
                <Button icon="aFilter" onClick={() => setFiltersVisible(!filtersVisible)}/>
                <Button icon="faRedoAlt"/>
            </HStack>
        </Note>
        <Note preset="panel">
            <FilteredSelectionTable
                filterStore={rds.filterStore}
                isFiltersVisible={filtersVisible}
                mode="single"
                selectorPosition="hidden"
                dataset={rds.dataset}
                columns={columns}/>
        </Note>
        <Pager remoteDataset={rds}/>
    </VStack>;
};
