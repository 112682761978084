




import { CreateJointSession as CreateJointSessionR } from "./CreateJointSession";
import { Component, Prop } from "vue-property-decorator";
import { SubjectClassFilterStore } from '@/components/eshop/SubjectClassFilterStore';
import Vue from "vue";
import { createServicesStore } from '../store';

@Component({ components: { komponent: CreateJointSessionR as unknown as typeof Vue } })
export default class CreateJointSession extends Vue {
  @Prop() public ids!: string;
  @Prop() public sessionId!: string;
  @Prop() public service!: string;
  @Prop() public product!: string;
  @Prop() public purchase!: string;
  @Prop() public mode!: string;
  public storeContainer =  Object.freeze({ store: createServicesStore(true), subjectClassSelector: new SubjectClassFilterStore() });

}
