


















import { SelectOption } from "@/models/enums";
import { Component, Vue, Prop } from "vue-property-decorator";

@Component
export default class DocumentSelect extends Vue {
    @Prop() private options!: SelectOption<string>[];
    @Prop() private id!: string;
    @Prop({ default: () => ({}) }) private table!: Record<string, string>;

    private readonly group = ((Math.random() * 0xffffffff) | 0).toString(16);

    private active = false;

    private parseInt(x: string) {
        return parseInt(x, 10);
    }

    private activate() {
        this.active = true;
    }

    private close() {
        this.active = false;
        this.$emit("change");
    }
}
