/* @deprecated */
export declare type EnumStrings = {
    readonly [key: string]: string
};

export declare type UnionStrings<T extends string> = {
    readonly [key in T]: string
};

export declare type CompatEnum<T extends string> = {
    readonly [key in T]: T
};

export declare type AnyStrings<T extends string> = Partial<UnionStrings<T>> | EnumStrings;

export declare interface SelectOption<T> {
    key: T | undefined
    desc: string
    disabled?: boolean
}

export const iterStrs = <T extends string = string>(strings: AnyStrings<T>) => Object.entries(strings) as [T, string][];

export function toSelectOptions<T extends string = string>(strings: AnyStrings<T>, hasEmpty?: boolean, emptyDesc?: string): SelectOption<T>[] {
    const opts = Object.entries(strings).map(x => ({ key: x[0] as T, desc: x[1] } as SelectOption<T>));
    if (hasEmpty) {
        opts.splice(0, 0, { key: undefined, desc: emptyDesc ?? "" });
    }
    return opts;
}

export function createYearSelection(withNullValue: boolean, fromYear?: number, nullValueDesc?: string) {
    const from = fromYear || new Date().getFullYear();

    const variants = [...Array(2100 - from + 1).keys()].map(x => ({
        key: (x + from),
        desc: (x + from).toString()
    } as SelectOption<number>));

    if (withNullValue) {
        variants.splice(0, 0, {
            key: undefined,
            desc: nullValueDesc ?? "Выберите год"
        });
    }

    return variants;
}
