import React, {useEffect, useState} from "react";
import {Card} from "@/components/Card";
import {Column, Table} from "@/components/table";
import {Button, Note, TextBox} from "@/components/primitive";
import {ModalFC, showModal} from "@/EventBus";
import {Expandee, HStack, VStack} from "@/components/layouts";
import {CardModal} from "@/components/modals/CardModal";
import {Field} from "@/components/form/Field";
import {getMediaUrl, MediaApi} from "@/api/Media";
import {
    convertLoginWizardSlideToUpdate,
    LoginWizardApi,
    LoginWizardSlide,
    LoginWizardSlideUpdate
} from "@/api/LoginWizard";
import Editor, {theme} from "rich-markdown-editor";
import {LoginWizardModal} from "@/views/Login/wizard/LoginWizardModal";
import {ConfirmationModal} from "@/views/Contracts/modals/ConfirmationModal";

const init: LoginWizardSlideUpdate = {
    title: "",
    content: "",
    mediaItemId: null
};

const EditLoginWizardSlideModal: ModalFC<{ item?: LoginWizardSlide }, LoginWizardSlide> = x => {
    const [update, setUpdate] = useState<LoginWizardSlideUpdate>(x.item ? convertLoginWizardSlideToUpdate(x.item) : init);

    const save = async () => {
        if (x.item) {
            return await LoginWizardApi.update(x.item.id, update).then(x.done);
        } else {
            LoginWizardApi.create(update).then(x.done);
        }
    };

    let canSave = true;
    if (!update.title.length) canSave = false;
    if (!update.content.length) canSave = false;

    const close = async () => {
        const confirm = await showModal(ConfirmationModal, {});
        if (!confirm) return;
        x.done(undefined);
    };

    const bottom: React.ReactNode = <HStack spacing="10px">
        <Expandee />
        <Button color="white" title="Закрыть" onClick={close} />
        <Button color="blue" title="Сохранить" disabled={!canSave} onClick={save} />
    </HStack>;

    return <CardModal title={x.item ? "Редактирование слайда" : "Создание слайда"}
                      close="disabled"
                      bottom={bottom}
                      width="620px">
        <VStack spacing="10px">
            <Note preset="alert">
                Созданный слайд будет помещен в конец слайдшоу.<br />
                Изменить порядок слайдов можно на предыдущем экране.
            </Note>
            <Field required title="Заголовок">
                <TextBox value={update.title}
                         onChange={v => setUpdate({ ...update, title: v })} />
            </Field>
            <Field required title={`Текст слайда`}>
                <div style={{ width: "100%", boxSizing: "border-box", height: "400px", overflow: "auto", padding: "5px", border: "1px solid black" }}>
                    <Editor defaultValue={x.item?.content ?? undefined}
                            uploadImage={async file => {
                                const formData = new FormData();
                                formData.append("file", file.slice(0, file.size, file.type));
                                const result = await MediaApi.uploadMedia(formData);
                                const mediaItem = Object.values(result)[0]?.result;
                                return getMediaUrl(mediaItem) ?? "";
                            }}
                            onImageUploadStop={() => {console.log("upload completed")}}
                            placeholder={"Начните писать текст"}
                            theme={{ ...theme, zIndex: 1000000}}
                            onChange={v => setUpdate({ ...update, content: v() })} />
                </div>
            </Field>
            <Field title="Изображение">
                <input type="file" multiple={false} onChange={e => MediaApi.uploadFileHandler(e).then(v => setUpdate({ ...update, mediaItemId: v?.id ?? null }))} />
            </Field>
        </VStack>
    </CardModal>;
};

export const LoginWizardSlideAdminSection: React.FC = () => {
    const [LoginWizardSlides, setLoginWizardSlides] = useState<LoginWizardSlide[]>([]);
    const reload = async () => {
        const result = await LoginWizardApi.findAll();
        setLoginWizardSlides(result);
    };

    const create = async () => {
        await showModal(EditLoginWizardSlideModal, {});
        reload();
    };

    useEffect(() => {
        reload();
    }, []);

    const edit = async (item: LoginWizardSlide) => {
        const result = await showModal(EditLoginWizardSlideModal, { item });
        reload();
    };

    const remove = async (item: LoginWizardSlide) => {
        await LoginWizardApi.remove(item.id);
        reload();
    };

    const move = async (item: LoginWizardSlide, direction: "up" | "down") => {
        if (direction === "up") await LoginWizardApi.moveUp(item.id);
        else await LoginWizardApi.moveDown(item.id);

        reload();
    };


    const columns: Column<LoginWizardSlide>[] = [
        Table.Column("Заголовок", x => <>{x.item.title}</>),
        Table.Column("Контент", x => <>{x.item.content.slice(0, 100)}...</>),
        Table.Column("Порядок", x => <>{x.item.index}</>),
        Table.Column("Медиа", x => <a href={x.item.mediaItem ? getMediaUrl(x.item.mediaItem) : undefined}>{x.item.mediaItem?.originalName ?? "-"}</a>),
        Table.AutoColumn("", x => <div style={{ display: "flex", gap: "10px" }}>
            <Button disabled={x.rowIndex === 0} icon="faArrowUp" color="yellow" onClick={() => move(x.item, "up")} />
            <Button disabled={x.rowIndex === (LoginWizardSlides.length - 1)} icon="faArrowDown" color="yellow" onClick={() => move(x.item, "down")} />
        </div>),
        Table.AutoColumn("", x => <Button icon="gPencil" color="blue" onClick={() => edit(x.item)} /> ),
        Table.AutoColumn("", x => <Button icon="faTrash" color="red" onClick={() => remove(x.item)} /> ),
    ];

    const title = <div style={{ display: "flex", gap: "20px" }}>
        <span>Слайдшоу</span>
        <Expandee />
        <Button icon="faPlay" onClick={() => showModal(LoginWizardModal)} />
        <Button title="Добавить слайд" color="green" icon="aAdd" onClick={create} />
    </div>;

    return <Card title={title}>
        <Table dataset={LoginWizardSlides} columns={columns} />
    </Card>;
};