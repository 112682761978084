






















import { FieldAlignment, FieldWidth, valueSpanContext, FComponent } from "./FComponent";
import { FValueComponent } from "./FValueComponent";
import { Component, Prop, Vue, Inject, Mixins, Watch } from "vue-property-decorator";
import Decimal from 'decimal.js';
import { stringifyNumber } from '../../NumberFormatting';

@Component
export default class FDecimal extends Mixins(FValueComponent) {
    @Prop({ default: "" }) public placeholder!: string;
    @Prop({ default: false }) public focus!: boolean;
    @Prop({ default: "left" }) public alignment!: FieldAlignment;
    @Prop({ default: "wide" }) public width!: FieldWidth;
    @Prop({ default: null }) public precision!: number | null;
    @Prop({ default: null }) public disabledStyle!: "disabled" | "readonly";
    
    @Prop() public value!: Decimal | null;
    public decimalString = "";
    private suppressChange = false;

    public get commonProps() {
        const { alignment, width, f } = this;

        return { class: [f(alignment), f(width), ...this.classes] };
    }

    public get editableProps() {
        const { value, placeholder, disabled } = this;

        return { ...this.commonProps, value, placeholder, disabled };
    }

    public mounted() {
        this.update();
        this.setDecimalString();
    }

    public updated() {
        this.update();
    }

    public update() {
        if (!this.enabled) return;
        if (!this.$refs.input) return;

        const e = this.$refs.input as HTMLInputElement;

        if (this.focus) e.focus();
    }
    
    private setDecimalString() {
        if (this.value && this.value.isFinite())
            this.decimalString = this.precision
                ? stringifyNumber(this.value, this.precision)
                : this.value.toString();
        else this.decimalString = "";
    }

    @Watch("value") private updateValue() {
        if (!this.suppressChange) this.setDecimalString();
        else this.suppressChange = false;
    }

    private get inputString(): string {
        return this.decimalString;
    }

    private set inputString(v: string) {
        this.decimalString = v;

        try {
            this.$emit("input", new Decimal(v.replace(",", ".")));
        } catch {
            this.$emit("input", new Decimal(NaN));
        }

        this.suppressChange = true;
    }
}
