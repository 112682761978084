import React, {useState} from "react";
import {SectionProps} from "@/views/Procedures2020/view/store";
import {Card} from "@/components/Card";
import {Expandee, HStack, VStack} from "@/components/layouts";
import {Button, Label} from "@/components/primitive";
import {useMappedStore} from "@/storeUtils";
import {AddLotModal} from "@/views/Procedures2020/view/sections/lotsSection/AddLotModal";
import {showModal} from "@/EventBus";
import {Column, SelectionTable, Table, useStoredRemoteDataset} from "@/components/table";
import {LawTypeStrings, SmpTypeStrings} from "@/models/enums";
import {ProcedureLotsEvents} from "@/views/Procedures2020/view/store/lots";
import {ProcedureLot} from "@/models/Procedures2020/ProcedureLot";
import {formatNumber} from "@/NumberFormatting";

export const LotsSection: React.FC<SectionProps> = x => {

    const [selectedLot, setSelectedLot] = useState<ProcedureLot | undefined>();

    const columns: Column<ProcedureLot>[] = [
        Table.Column("#", a => <div>
            {a.rowIndex + 1}
        </div>, {width: "1fr"}),
        Table.Column("Номер лота", a => <div>
            {a.item.lot.regNumber}
        </div>, {width: "2fr"}),
        Table.Column("Закон-основание", a => <div>
            {LawTypeStrings[a.item.lot.law]}
        </div>, {width: "2fr"}),
        Table.Column("Наименование лота", a => <div>
            {a.item.lot.basicInfo.subject}
        </div>, {width: "2fr"}),
        Table.Column("НМЦ", a => <div>
            {formatNumber(a.item.lot.totalPrice)}
        </div>, {width: "1fr"}),
        Table.Column("Размещение среди СМП/МСП", a => <div>
            {SmpTypeStrings[a.item.lot.basicInfo.smpType]}
        </div>, {width: "1fr"}),

    ];

    const sectionStore = useMappedStore(x.formStore, x => ({
        lotDataset: x.lots.lotDataset,
        lots: x.lots.lots,
        providerSelectionType: x.info.providerSelectionType
    }));

    const rds = useStoredRemoteDataset(sectionStore.lotDataset);

    const addLot = async () => {
        const lot = await showModal(AddLotModal, {rds: rds});

        if (lot) {
            ProcedureLotsEvents.addLot(lot);
        }
    };

    const remove = () => {
        if (selectedLot)
            ProcedureLotsEvents.removeLot(selectedLot);
    };

    return <Card>
        <VStack spacing="15px">
            {
                (sectionStore.lots && sectionStore.lots.length) ?
                    <SelectionTable
                        dataset={sectionStore.lots}
                        columns={columns}
                        mode={"single"}
                        selected={selectedLot}
                        onChange={setSelectedLot}
                        selectorPosition={"hidden"}
                    />
                    :
                    <Label preset={"boldSmall"} text={"Лотов нет"}/>
            }
            {
                x.formAction !== "viewing" && <HStack spacing={"10px"}>
                    <Button icon="aAdd" onClick={addLot}/>
                    <Button icon="aDelete" color="red" onClick={remove}/>
                    <Expandee/>
                </HStack>
            }
        </VStack>
    </Card>;
};