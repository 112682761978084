import Decimal from "decimal.js";
import {createEvent, createStore, Event, Store} from "effector";
import {
    Column,
    ColumnFC,
    ColumnOptions,
    defaultColumnOptions, InsetCell,
    Table
} from "@/components/table/Table";
import {useMappedStore} from "@/storeUtils";
import { ComboBox, DatePicker, DecimalBox, styles, TextBox } from "@/components/primitive";
import {AnySelection, SelectionTable} from "@/components/table/SelectionTable";
import React from "react";
import { SelectOption } from "@/models/enums";
import {Dropdown, IDropdownOption} from "office-ui-fabric-react";

export type FilterType =
    | "string"
    | "decimal"
    | "date"
    | "select"
    | "multiselect"
    | "any";

type OnlyTypedKeys<T, KT> = { [key in keyof T]: T[key] extends KT ? key : never }[keyof T];

export type ColumnFilter<F> = { type: FilterType } & ({
    type: "string"
    key: OnlyTypedKeys<F, string>
    hideFilterInput?: boolean
} | {
    type: "any"
    key: OnlyTypedKeys<F, string>
    hideFilterInput?: boolean
} | {
    type: "decimal"
    key: OnlyTypedKeys<F, Decimal>
    hideFilterInput?: boolean
} | {
    type: "date"
    key: OnlyTypedKeys<F, Date>
    hideFilterInput?: boolean
} | {
    type: "select"
    values: F[keyof F][]
    options: SelectOption<string>[]
    key: OnlyTypedKeys<F, string>
    hideFilterInput?: boolean
} | {
    type: "multiselect"
    values: F[keyof F][]
    options: SelectOption<string>[]
    key: OnlyTypedKeys<F, string>
    hideFilterInput?: boolean
});

const evAccessor = Symbol();

export type FilterStore<T> = Store<Partial<T>> & {
    [evAccessor]: (n: keyof T) => Event<T[typeof n]>
};

export const createFilterStore = <T,>(initialFilters?: Partial<T>): FilterStore<T> => {
    const evs: { [key in keyof T]?: Event<T[key]> } = {};
    const store = createStore<Partial<T>>(initialFilters ?? {}) as FilterStore<T>;

    store[evAccessor] = (name: keyof T) => {
        if (!evs[name]) {
            const ev = createEvent<T[typeof name]>(`set filter property '${name}'`);
            evs[name] = ev;
            store.on(ev, (x, e) => ({...x, [name]: e}));

        }

        return evs[name]!;
    };

    return store;
};

export const getFilterValue = <T, K extends keyof T>(store: FilterStore<T>, key: K): T[K] | undefined => {
    return store.getState()[key];
};

export const setFilterValue = <T, K extends keyof T>(store: FilterStore<T>, key: K, value: T[K]) => {
    store[evAccessor](key)(value);
};

export interface FilterColumn<T, F> extends Column<T> {
    filter?: ColumnFilter<F>
}

const FilterCell = <T,>(x: {filter: ColumnFilter<T>; store: FilterStore<T>}) => {
    const v = useMappedStore(x.store, s => s[x.filter.key as keyof T]);
    const ev = x.store[evAccessor](x.filter.key);

    switch (x.filter.type) {
        case "any":
        case "string":
            return <TextBox value={v as never} onChange={ev as never}/>;
        case "decimal":
            return <DecimalBox value={v as never} onChange={ev as never}/>;
        case "date":
            return <DatePicker value={v as never} onChange={ev as never}/>;
        case "select":
            return <ComboBox options={x.filter.options} value={v as never} onChange={ev as never} />;
        case "multiselect":
            const changeEvent = (newValue?: { text: string; key: string; selected: boolean }) => {
                if (!newValue) {
                    return;
                }
                // crashes without fallback but fallback ruins typing
                const oldV = v ?? [];

                if (newValue.selected) {
                    // @ts-expect-error expects ColumnFilter<T> or FilterStore<T>;
                    // handled in http.ts and store.ts (monopolyObjects)
                    ev([...oldV, newValue.key]);
                } else {
                    // @ts-expect-error expects ColumnFilter<T> or FilterStore<T>;
                    // handled in http.ts and store.ts (monopolyObjects)
                    ev(oldV.filter(item => item !== newValue.key));
                }
            };
            // by default all filters in a tab are selected. Combobox had no support for multi so Dropdown is used here
            return <Dropdown options={x.filter.options.map(v => ({ text: v.desc, key: v.key, selected: true })) as IDropdownOption[]}
                             selectedKeys={v as never}
                             styles={{...styles, dropdown: {...(styles.dropdown as {}), width: 140}}}
                             onChange={(_, option) => changeEvent(option as { text: string; key: string; selected: boolean })}
                             multiSelect />;
        default:
            return null;
    }
};

type FilteredSelectionTableProps<T, F> = {
    dataset: T[]
    isFiltersVisible: boolean
    columns: FilterColumn<T, F>[]
    filterStore: FilterStore<F>
    selectorPosition: "atLeft" | "atRight" | "hidden"
} & AnySelection<T>;

export const FilteredSelectionTable = <T, F extends object = {}>(x: FilteredSelectionTableProps<T, F>) => {
    let insetCells: InsetCell[] | undefined = undefined;

    if (x.isFiltersVisible) {
        const genHeaders = (ofs: number) => x.columns.map((c, i) => Table.Inset(
            c.filter && !c.filter.hideFilterInput
                ? <FilterCell<F> filter={c.filter} store={x.filterStore}/>
                : <></>,
            i + 1 + ofs,
            "header"));

        switch (x.selectorPosition) {
            case undefined:
            case "atLeft":
                insetCells = [
                    Table.Inset("", 1, "header"),
                    ...genHeaders(1)
                ];
                break;
            case "atRight":
                insetCells = [
                    ...genHeaders(0),
                    Table.Inset("", x.columns.length + 1, "header")
                ];
                break;
            case "hidden":
                insetCells = genHeaders(0);
                break;
        }
    }

    const insets = x.isFiltersVisible && insetCells ? [Table.InsetRow(0, ...insetCells)] : undefined;

    return <SelectionTable<T> {...x} dataset={x.dataset}
                              columns={x.columns}
                              mode={x.mode as never}
                              selected={x.selected as never}
                              onChange={x.onChange as never}
                              insets={insets}/>;
};

interface FilterColumnProps<T, F> {
    header: string
    filter?: ColumnFilter<F>
    cell: ColumnFC<T>
    options?: Partial<ColumnOptions>
}

export const FilterColumn = <T, F>(x: FilterColumnProps<T, F>): FilterColumn<T, F> => ({
    ...x,
    options: { ...defaultColumnOptions, ...x.options }
});
