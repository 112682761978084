import { CharacteristicValue } from "@/api/Sessions";
import { PrimaryButton } from "@/components/eshop";
import { Select } from "@/components/eshop/Select/Select";
import { FormControlEshopInput } from "@/components/eshop/FormControlEshopInput";
import { Modal } from "@/components/eshop/Modal";
import React from "react";
import { onChangeCharacteristic, SubjectDeclarationForCreationProduct, SubjectDeclarationForCreationService } from "../store";
import styles from '../CreateSession.module.css';
import { CharacteristicDeclaration } from "@/models/ComposedLots/specifications";

interface ModalCharacteristicsProps {
    serviceId: string | null
    canChange: boolean
    data: SubjectDeclarationForCreationService[]
    setShowCharacteristicsId: (id: string| null) => void
}

export const ModalCharacteristics = (props: ModalCharacteristicsProps) => {
    const { serviceId, canChange, data, setShowCharacteristicsId} = props;
    const foundItem = data.find(s => s.idForKey === serviceId);
    const mainCharacteristicDeclarations = foundItem?.characteristicDeclarations.filter(c => !c.isAdditional);
    const additionalCharacteristicDeclarations = foundItem?.characteristicDeclarations.filter(c => c.isAdditional);

    const createControlsForCharacteristic = (declaration: CharacteristicDeclaration, value: CharacteristicValue | undefined) => {
        const valueFirst = value?.numberValueFirst;
        const valueSecond = value?.numberValueSecond;
        switch (declaration.conditionType) {
            case 'GREATER_THAN_OR_EQUAL': case "LESS_THAN_OR_EQUAL": case "EQUAL": case "NOT_EQUAL": case "GREATER_THAN": case "LESS_THAN":
                return <FormControlEshopInput
                    value={valueFirst && parseFloat(valueFirst) || undefined}
                    onChange={v => onChangeCharacteristic({ id: declaration.id, serviceId: foundItem!.idForKey, value: v.target.value, second: false })}
                    placeholder="Введите значение"
                    disabled={!canChange} />;
            case 'RANGE':
                return <>
                    <FormControlEshopInput
                        value={valueFirst && parseFloat(valueFirst) || undefined}
                        onChange={v => onChangeCharacteristic({ id: declaration.id, serviceId: foundItem!.idForKey, value: v.target.value, second: false })}
                        placeholder="Введите значение"
                        disabled={!canChange} />
                    <FormControlEshopInput
                        value={valueSecond && parseFloat(valueSecond) || undefined}
                        onChange={v => onChangeCharacteristic({ id: declaration.id, serviceId: foundItem!.idForKey, value: v.target.value, second: true })}
                        placeholder="Введите значение"
                        disabled={!canChange} />
                </>;
            case 'ENUM':
                const enumValue = value && value.enumValues.length > 0 ? value.enumValues[0] : '';
                return <Select
                    fullWidth
                    value={enumValue}
                    onSelect={v => onChangeCharacteristic({ id: declaration.id, serviceId: foundItem!.idForKey, value: v, second: false })}
                    options={declaration.enumValues.map(v => ({ name: v, value: v }))}
                    disabled={!canChange} />;
            default:
                return <div />;
        }
    };


    const controls = () => {
        return <>
            <PrimaryButton filled onClick={() => { }}>Применить</PrimaryButton>
        </>;
    };
    return foundItem
        ? <Modal medium onClose={() => setShowCharacteristicsId(null)} title="Характеристики">
            <div>
                {mainCharacteristicDeclarations?.map(d => <div className={styles.characteristicDeclarations} key={d.name}>
                    <div className={styles.characteristicDeclarationsItem}>{d.name}</div>
                    <div className={styles.characteristicDeclarationsItem}>{createControlsForCharacteristic(d, foundItem.characteristics.find(c => c.declaration.id === d.id))}</div>
                </div>)}
            </div>
            <div>
                {additionalCharacteristicDeclarations?.map(d => <div className={styles.characteristicDeclarations} key={d.name}>
                    <div className={styles.characteristicDeclarationsItem} >{d.name}</div>
                    <div className={styles.characteristicDeclarationsItem}>{createControlsForCharacteristic(d, foundItem.characteristics.find(c => c.declaration.id === d.id))}</div>
                </div>)}
            </div>
        </Modal >
        : <div />;
};

interface ModalCharacteristicsForProductsProps {
    id: string | null
    canChange: boolean
    data: SubjectDeclarationForCreationService[]
    setShowCharacteristicsId: (id: string| null) => void
}

export const ModalCharacteristicsForProducts = (props: ModalCharacteristicsForProductsProps) => {
    const { id, data, setShowCharacteristicsId} = props;
    const foundItem = data.find(s => s.idForKey === id);
    const chars = (foundItem as SubjectDeclarationForCreationProduct)?.product?.characteristics;

    return foundItem
        ? <Modal medium contentOverflow onClose={() => setShowCharacteristicsId(null)} title="Характеристики">
            <div>
                {chars?.map(d => <div className={styles.characteristicDeclarations} key={d.name}>
                    <div className={styles.characteristicDeclarationsItem}>{d.name}</div>
                    <div className={styles.characteristicDeclarationsItem}>
                        <FormControlEshopInput
                            disabled
                            value={d.value || undefined}
                            placeholder="Введите значение" />
                    </div>
                </div>)}
            </div>
        </Modal >
        : <div />;
};
