



import { Vue, Component, Prop } from "vue-property-decorator";
import { ProvisionView } from "@/views/Provision/view/ProvisionView";
import {createProvisionStore} from "@/views/Provision/view/store";

@Component({ components: { komponent: ProvisionView as unknown as typeof Vue } })
export default class ProvisionViewVueStub extends Vue {
    @Prop() private provisionId!: string;

    public storeContainer = Object.freeze(createProvisionStore(this.provisionId));
}
