import { ContractPayment } from "@/models/Contracts/ContractPayment";
import { http, IFilterObject } from "@/api/http";
import { IPagedItems, mapPage } from "@/api/Pagination";
import { uuid } from "@/models/parsing";
import { DtoHistoryItem, HistoryItem } from "@/models";

export const getContractPayments = async (from: number, count: number, filters?: IFilterObject): Promise<IPagedItems<ContractPayment>> => {
    return (
        mapPage(
            ContractPayment,
            await http.getPaged("/api/v2/contractPayments/payments", { filters, from, count })
        )
    );
};

export const getContractPayment = async (id: uuid): Promise<ContractPayment> =>
    ContractPayment.fromDto(await http.get(`/api/v2/contractPayments/payments/${id}`));

export const getContractPaymentHistory = async (id: uuid): Promise<HistoryItem[]> =>
    (await http.get(`/api/v2/contractPayments/payments/${id}/history`) as DtoHistoryItem[]).map(HistoryItem.fromDto);

export const generateContractObligations = async (contractStageId: uuid): Promise<void> =>
    await http.post(`/api/v2/contractPayments/generate?contractStageId=${contractStageId}`, undefined);
