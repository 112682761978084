import {ModalFC} from "@/EventBus";
import {Expandee, HStack, VStack} from "@/components/layouts";
import {Button, ComboBox, Label, TextBox} from "@/components/primitive";
import {CardModal} from "@/components/modals/CardModal";
import React, {useState} from "react";
import {Field, Fields} from "@/components/form/Field";
import {ProcedureDocument} from "@/models/Procedures2020/ProcedureDocument";
import {LawType, SelectOption, toSelectOptions} from "@/models/enums";
import {File} from "@/models/ComposedLots/documents/File";
import {
    Procedure223DocumentTypeStrings,
    Procedure44DocumentTypeStrings,
    ProcedureDocumentType
} from "@/models/enums/Documents";
import {FileInput} from "@/components/primitive/FileInput";
import {ProviderSelectionType} from "@/models/ComposedLots";
import {uuid} from "@/models/parsing";

export const UploadProcedureDocumentModal: ModalFC<{law: LawType; pst?: ProviderSelectionType; id?: uuid; procedureId?: uuid }, ProcedureDocument> = x => {
    const [file, setFile] = useState<File>();
    const [note, setNote] = useState("");
    const [type, setType] = useState<ProcedureDocumentType | undefined>();

    //TODO: file validation
    const validate = (note?: string, type?: ProcedureDocumentType, file?: File): boolean => !!file && !!type;

    const upload = (note: string, type?: ProcedureDocumentType, file?: File): ProcedureDocument | undefined => {
        return validate(note, type, file) ?
            ({file: {...file, comment: note ?? ""}, id: "", procedureId: "", sendToEis: false, type: type ?? "OTHER"} as ProcedureDocument)
            :
            undefined;
    };

    const docOptions: SelectOption<ProcedureDocumentType>[] = [
        ...toSelectOptions((x.law === LawType.F44) ? Procedure44DocumentTypeStrings : Procedure223DocumentTypeStrings)
            .filter(opt => opt.key !== "REQUEST_FORM" || (x.pst === "E_QUOTATIONS_REQUEST" && x.id && x.procedureId))
    ];

    const bottom = <HStack spacing={"10px"}>
        <Expandee/>
        <Button color={"green"}
                title={"загрузить"}
                disabled={!file || !type}
                onClick={() => x.done(upload(note, type, file))}
        />
        <Button color={"red"}
                icon={"aClose"}
                onClick={() => x.done(undefined)}
        />
    </HStack>;

    return <CardModal title={"Загрузка конкурсной документации"}
                      close={() => x.done(undefined)}
                      width={"70%"}
                      bottom={bottom}
    >
        <VStack spacing={"15px"}>
            <Fields orientation={"vertical"}>
                <Field title={"Тип документа"}>
                    <ComboBox options={[
                        {key: undefined, desc: "Выберите тип документа"},
                        ...docOptions
                    ]}
                              onChange={setType}
                              value={type}
                    />
                </Field>
                <Field title={"Примечание"}>
                    <TextBox lines={5}
                             onChange={setNote}
                             value={note}
                    />
                </Field>
                <HStack spacing={"15px"} alignItems={"center"}>
                    {
                        !file &&
                            <FileInput onChange={setFile}/>
                    }
                    <Label text={file?.name ?? ""} />
                    <Expandee/>
                    {
                        file &&
                            <Button color={"red"} icon={"aClose"} onClick={() => setFile(undefined)} />
                    }
                </HStack>
                <Label style={{whiteSpace: "normal"}}
                       text={"Допустимые типы файлов: doc, docx, xls, xlsx, pdf, tiff, jpg, jpeg, zip, rar"}
                />
                <Label style={{whiteSpace: "normal"}}
                       text={"Максимальный размер файла - 50 МБ"}
                />
            </Fields>
        </VStack>
    </CardModal>;
};