import { IDtoFinancialActionSource, IDtoFinancialSource } from "@/models/json";
import { IJsonFormattable } from "@/models/IJsonFormattable";
import { FinancialSourceType } from "@/models/enums/FinancialSourceType";
import Decimal from "decimal.js";
import { LawType } from './enums';

export abstract class FinancialSource implements IJsonFormattable<IDtoFinancialSource> {
    protected constructor(
        public id: number,
        public type: FinancialSourceType,
        public total: Decimal,
        public used: Decimal,
        public year: number
    ) {}

    public abstract toJson(): IDtoFinancialSource;

    // noinspection JSUnusedGlobalSymbols
    public static fromJson(dto: IDtoFinancialSource): FinancialSource {
        // noinspection JSRedundantSwitchStatement
        switch (dto.type) {
            case FinancialSourceType.FIN_ACTIONS:
                return FinancialActionSource.fromJson(dto as IDtoFinancialActionSource);
            default:
                throw new Error("Failed to parse financial source of type " + dto.type);
        }
    }

    public abstract format(prefix: boolean): string;
    public abstract code(): string;
}

export class FinancialActionSource extends FinancialSource implements IJsonFormattable<IDtoFinancialActionSource> {
    constructor(
        public id: number,
        public type: FinancialSourceType,
        public total: Decimal,
        public used: Decimal,
        public year: number,
        public chapterCode: string,
        public expenseKind: string,
        public govOperationClass: string,
        public provisionCode: string,
        public comment: string,
        public law: LawType,
    ) {
        super(id, type, total, used, year);
    }

    public toJson(): IDtoFinancialActionSource {
        return {
            ...this,
            total: this.total.toString(),
            used: this.used.toString(),
        };
    }

    // noinspection JSUnusedGlobalSymbols
    public static fromJson(dto: IDtoFinancialActionSource): FinancialActionSource {
        return Object.assign(Object.create(FinancialActionSource.prototype), dto, {
            total: new Decimal(dto.total),
            used: new Decimal(dto.used),
        });
    }

    public format(prefix: boolean) {
        return `${prefix ? "Код: " : "ПД:"}${this.expenseKind}-${this.govOperationClass}`;
    }

    public code() {
        return `${this.expenseKind}-${this.govOperationClass}`;
    }

    public typeString() {
        return "ПФХД";
    }
}
