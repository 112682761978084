import * as t from "io-ts";
import {http2 as http, IPagedRequest} from './http';
import {arrayCodec, handleCodecErrors, handleErrors, pagedCodec} from './httpHelpers';
import {directPurchase, participant, spgz, supplier} from "./DirectPurchases";
import {productCodec} from "./Products";
import {QuotationSessionExecutionStageTemplate} from "@/views/CreateSession/store";
import {localDateTime, nothingOr} from "@/io-ts-ext";

export const characteristicConditionType = t.union([t.literal('EQUAL'), t.literal('NOT_EQUAL'), t.literal('GREATER_THAN'),
t.literal('LESS_THAN'), t.literal('RANGE'), t.literal('GREATER_THAN_OR_EQUAL'), t.literal('LESS_THAN_OR_EQUAL'), t.literal('ENUM')]);

const declaration = t.type({
    id: t.number
});

const characteristic = t.type({
    declaration: declaration,
    conditionType: characteristicConditionType,
    enumValues: t.array(t.string),
    numberValueFirst: t.union([t.undefined, t.null, t.string]),
    subConditionTypeFirst: characteristicConditionType,
    numberValueSecond: t.union([t.undefined, t.null, t.string]),
    subConditionTypeSecond: characteristicConditionType,
});

export const dayType = t.union([t.literal('WORKDAY'), t.literal('NORMAL')]);

const relativePeriod = t.type({
    startOffset: t.number,
    startOffsetType: dayType,
    endOffset: t.number,
    endOffsetType: dayType,
});

const absolutePeriod = t.type({
    startDate: t.union([localDateTime, t.undefined, t.null]),
    endDate: t.union([localDateTime, t.undefined, t.null])
});

export const stageType = t.union([t.literal('RELATIVE'), t.literal('ABSOLUTE')]);

const supplyStage = t.type({
    volume: t.string,
    type: stageType,
    relativePeriod: t.union([t.null, relativePeriod]),
    absolutePeriod: t.union([t.null, absolutePeriod]),
    address: t.string,
    conditions: t.string
});

const subject = t.type({
    id: t.union([t.string, t.number]),
    name: t.string,
    type: t.string
});

const status = t.union([
    t.literal('DRAFT'),
    t.literal('UPDATED'),
    t.literal('PUBLICATION'),
    t.literal('PUBLICATION_ERROR'),
    t.literal('PUBLICATION_WAIT_JOIN'),
    t.literal('WAIT_PUBLICATION'),
    t.literal('PUBLISHED'),
    t.literal('CANCEL'),
    t.literal('SUPPLIER_FOUND'),
    t.literal('SUPPLIER_NOT_FOUND'),
    t.literal('CANCELED'),
    t.literal('CONTRACT_CREATED'),
    t.literal('CONTRACT_CREATION_ERROR'),
    t.literal('REMOVED_FROM_PUBLICATION')
]);

const contract = t.type({
    regNumber: t.string,
    totalPrice: t.string,
    id: t.string,
});

const lot = t.type({
    id: t.string,
    regNumber: t.number,
});

const bankDetail = t.type({
    accountNumber: t.string,
    id: t.number,
});

const specification = t.type({
    id: t.union([t.undefined, t.string]),
    totalPrice: t.number,
    unitPrice: t.number,
    characteristics: t.array(characteristic),
    product: t.union([t.undefined, t.null, productCodec]),
    directPurchase: t.union([t.undefined, t.null, directPurchase]),
    spgz: t.union([t.undefined, t.null, spgz]),
    supplyStages: t.array(supplyStage)
});

const contractProvisionBankDetail = t.type({
    id: t.number
});

export const executionStageCodec = t.type({
    id: t.string,
    paymentAmount: t.string,
    paymentDate: t.union([localDateTime, t.null, t.undefined]),
    paymentDateOffset: t.union([t.number, t.null, t.undefined]),
    quotationSessionId: t.string,
    specifications: t.array(specification),
    supplyStage: supplyStage
});

interface SessionCodecType {
    durationHours: number
    lot: t.TypeOf<typeof lot>  | null
    contract: t.TypeOf<typeof contract> | null
    bankDetail: t.TypeOf<typeof bankDetail> | null
    id: string
    law: string
    email: string
    fio: string
    phone: string
    created: string
    published: Date | undefined
    regNumber: number | null | undefined
    number: number
    finished: Date | undefined
    specifications: t.TypeOf<typeof specification>[]
    status: t.TypeOf<typeof status>
    participant: t.TypeOf<typeof participant>
    suppliers: t.TypeOf<typeof supplier>[]
    subject: t.TypeOf<typeof subject>
    eexecution: boolean
    contractProvisionPrice: string | null | undefined
    contractProvisionRecipient: string | null | undefined
    contractProvisionUsed: boolean
    contractProvisionBankDetail: t.TypeOf<typeof contractProvisionBankDetail> | null | undefined
    executionStages: t.TypeOf<typeof executionStageCodec> []
    isJoin: boolean | null
    isMaster: boolean | null
    includedSessions: SessionCodecType[]
    joinCustomers: t.TypeOf<typeof participant>[]
    joinPublishDate: Date | undefined
    subjectJoin: string | null
    mainOrganizer: t.TypeOf<typeof participant> | null | undefined
    aggregatingSessionId: string | null
    isGoodsDeliveryByMultipleConsignees: boolean
    isGoodsDeliveryByRecipientRequest: boolean
    sendTerm: string | null
    paymentTerm: string | null
    reviewTerm: string | null
}


export const sessionCodec: t.Type<SessionCodecType, unknown> = t.recursion("sessionCodec", self => t.type({
    durationHours: t.number,
    lot: t.union([lot, t.null]),
    contract: t.union([contract, t.null]),
    bankDetail: t.union([bankDetail, t.null]),
    id: t.string,
    law: t.string,
    email: t.string,
    fio: t.string,
    phone: t.string,
    created: t.string,
    published: nothingOr(localDateTime),
    regNumber: t.union([t.undefined, t.null, t.number]),
    number: t.number,
    finished: nothingOr(localDateTime),
    specifications: t.array(specification),
    status: status,
    participant: participant,
    suppliers: t.array(supplier),
    subject,
    eexecution: t.boolean,
    contractProvisionPrice: t.union([t.undefined, t.null, t.string]),
    contractProvisionRecipient: t.union([t.undefined, t.null, t.string]),
    contractProvisionUsed: t.boolean,
    contractProvisionBankDetail: t.union([t.undefined, t.null, contractProvisionBankDetail]),
    executionStages: t.array(executionStageCodec),
    isJoin: t.union([t.boolean, t.null]),
    isMaster: t.union([t.boolean, t.null]),
    includedSessions: t.array(self),
    joinCustomers: t.array(participant),
    joinPublishDate: nothingOr(localDateTime),
    subjectJoin: t.union([t.string, t.null]),
    mainOrganizer: t.union([participant, t.null, t.undefined]),
    aggregatingSessionId: t.union([t.string, t.null]),
    isGoodsDeliveryByMultipleConsignees: t.boolean,
    isGoodsDeliveryByRecipientRequest: t.boolean,
    sendTerm: t.union([t.string, t.null]),
    paymentTerm: t.union([t.string, t.null]),
    reviewTerm: t.union([t.string, t.null])
}));

export const statusToString = (s: Status | null) => {
    switch (s) {
        case 'DRAFT':
            return 'Черновик';
        case 'UPDATED':
            return 'Обновлена';
        case 'PUBLICATION':
            return 'Публикация';
        case 'PUBLICATION_ERROR':
            return 'Ошибка публикации';
        case 'PUBLICATION_WAIT_JOIN':
            return 'Ожидает публикации (В составе совместной КС)';
        case 'WAIT_PUBLICATION':
            return 'Ожидает публикации';
        case 'PUBLISHED':
            return 'Опубликована';
        case 'CANCEL':
            return 'Отмена';
        case 'SUPPLIER_FOUND':
            return 'Поставщик определен';
        case 'SUPPLIER_NOT_FOUND':
            return 'Поставщик не найден';
        case 'CONTRACT_CREATED':
            return 'Контракт создан';
        case 'CONTRACT_CREATION_ERROR':
            return 'Ошибка создания контракта';
        case 'REMOVED_FROM_PUBLICATION':
            return 'Снята с публикации';
        default:
            return '';
    }
};

const pageOfSessionsCodec = pagedCodec(sessionCodec);

const basicInfo = t.type({
    contractConclusionYear: t.union([t.undefined, t.null, t.number]),
    specialPurchaseType: t.union([t.undefined, t.null, t.string]),
    subject: t.union([t.undefined, t.null, t.string]),
    providerSelectionType: t.union([t.string, t.null, t.undefined])
});

const singleProviderReason = t.type({
    name: t.union([t.undefined, t.null, t.string]),
});

const plannedPayments = t.type({
    amount: t.string,
});

const lotsCodec = t.type({
    basicInfo,
    singleProviderReason: t.union([t.undefined, t.null, singleProviderReason]),
    regNumber: t.number,
    plannedPayments: t.union([t.undefined, t.null, t.array(plannedPayments)]),
    id: t.string
});

const historyCodec = t.type({
    date: t.union([localDateTime, t.null, t.undefined]),
    message: t.union([t.string, t.null, t.undefined]),
    status: t.union([t.string, t.null, t.undefined]),
    user: t.union([t.type({
        firstName: t.string,
        lastName: t.string,
        middleName: t.string
    }), t.null, t.undefined])
});

const executionCodec = t.type({
    price: t.string,
    timestamp: t.string
});

const documentCodec = t.type({
    id: t.union([t.string, t.null]),
    type: t.union([t.string, t.null]),
    file: t.type({
        comment: t.union([t.string, t.null]),
        createdAt: t.string,
        length: t.number,
        name: t.string
    })
});

const createContractCodec = t.type({
    id: t.string
});

const pageOfLotsCodec = pagedCodec(lotsCodec);

const historyItemsCodec = arrayCodec(historyCodec);

const executionHistoryCodec = arrayCodec(executionCodec);

const lotDocumentCodec = arrayCodec(documentCodec);

const pageOfUnknownCodec = pagedCodec(t.unknown);

export type StageType = t.TypeOf<typeof stageType>;

export type Status = t.TypeOf<typeof status>;

export type SPGZ = t.TypeOf<typeof spgz>;

export type Stage = t.TypeOf<typeof supplyStage>;

export type DayType = t.TypeOf<typeof dayType>;

export type Session = t.TypeOf<typeof sessionCodec>;

type RelativePeriodNullable = Omit<t.TypeOf<typeof relativePeriod>, 'startOffset' | 'endOffset'> & {
    startOffset: number | null
    endOffset: number | null
};

export type StageNullable = Omit<Stage, 'relativePeriod' | 'volume'> & {
    relativePeriod: RelativePeriodNullable | null
    volume: string | null
};

export type SpecificationNullable = Omit<t.TypeOf<typeof specification>, 'supplyStages'> & {
    supplyStages: StageNullable[]
};

export type SessionNullable = Omit<Session, 'specifications'> & {
    specifications: SpecificationNullable[]
};

export type CharacteristicValue = t.TypeOf<typeof characteristic>;

export type PageOfSessions = t.TypeOf<typeof pageOfSessionsCodec>;

export type PageOfUnknown = t.TypeOf<typeof pageOfUnknownCodec>;

export type Payment = t.TypeOf<typeof plannedPayments>;

export type Lot = t.TypeOf<typeof lotsCodec>;

export type PageOfLots = t.TypeOf<typeof pageOfLotsCodec>;

export type HistoryItems = t.TypeOf<typeof historyItemsCodec>;

export type ExecutionHistory = t.TypeOf<typeof executionHistoryCodec>;

export type LotDocuments = t.TypeOf<typeof lotDocumentCodec>;

export type LotDocument = t.TypeOf<typeof documentCodec>;

export type CreateContractResult = t.TypeOf<typeof createContractCodec>;

export const shortRegNumber = (regNumber: string) =>
    regNumber.split('-')[1] ?? '-';

export class SessionsController {
    public static async get(request: IPagedRequest) {
        return await http.getPaged("/api/v2/quotationSession", request)
            .then(handleErrors)
            .then(result => pageOfSessionsCodec.decode(result))
            .then(handleCodecErrors);
    }

    public static async getLots(request: IPagedRequest, law: string) {
        return await http.getPagedWithLaw("/api/v2/lots/lotsForQuotationSession", request, law)
            .then(handleErrors)
            .then(result => pageOfLotsCodec.decode(result))
            .then(handleCodecErrors);
    }

    public static async getHistory(sessionId: string) {
        return await http.get(`/api/v2/quotationSession/${sessionId}/history`)
            .then(handleErrors)
            .then(result => historyItemsCodec.decode(result))
            .then(handleCodecErrors);
    }

    public static async getExecutionHistory(sessionId: string) {
        return await http.get(`/api/v2/quotationSession/${sessionId}/execution`)
            .then(handleErrors)
            .then(result => executionHistoryCodec.decode(result))
            .then(handleCodecErrors);
    }

    public static async getExecutionStages(sessionId: string, templates: QuotationSessionExecutionStageTemplate[]) {
        return await http.put(`/api/v2/quotationSession/${sessionId}/executions`, templates)
            .then(handleErrors)
            .then(result => arrayCodec(executionCodec).decode(result))
            .then(handleCodecErrors);
    }

    public static async getLotDocuments(sessionId: string) {
        return await http.get(`/api/v2/quotationSession/${sessionId}/documents`)
            .then(handleErrors)
            .then(result => lotDocumentCodec.decode(result))
            .then(handleCodecErrors);
    }

    public static async getAggregations() {
        return await http.get(`/api/v2/quotationSession/aggregations`)
            .then(handleErrors)
            .then(result => arrayCodec(sessionCodec).decode(result))
            .then(handleCodecErrors);
    }

    public static async updateLotDocuments(sessionId: string, documents: LotDocuments): Promise<LotDocuments> {
        return await http.put(`/api/v2/quotationSession/${sessionId}/documents`, documents)
            .then(e => handleErrors(e));
    }

    public static async getRequisits(request: IPagedRequest, law: string) {
        return await http.getPagedWithLaw("/api/v2/lots/lotsForQuotationSession", request, law)
            .then(handleErrors)
            .then(result => pageOfLotsCodec.decode(result))
            .then(handleCodecErrors);
    }

    public static createContractFromProduct = async (contract: object): Promise<CreateContractResult> => {
        return await http.post(`/api/v2/contracts/fromTradeUnit`, contract)
            .then(e => handleErrors(e));
    };

    public static createContractFromDirectPurchase = async (contract: object): Promise<CreateContractResult> => {
        return await http.post(`/api/v2/contracts/fromDirectPurchase`, contract)
            .then(e => handleErrors(e));
    };

    public static createSession = async (session: object): Promise<Session> => {
        return await http.post(`/api/v2/quotationSession`, session)
            .then(e => handleErrors(e));
    };

    public static updateSession = async (session: { id: string | undefined }): Promise<Session> => {
        return await http.put(`/api/v2/quotationSession/${session.id}`, session)
            .then(e => handleErrors(e));
    };

    public static deleteSession = async (id: string): Promise<Session> => {
        return await http.delete(`/api/v2/quotationSession/${id}`)
            .then(e => handleErrors(e));
    };

    public static publishSession = async (id: string): Promise<Session> => {
        return await http.post(`/api/v2/quotationSession/${id}/publish`, {})
            .then(e => handleErrors(e));
    };

    public static supplierFoundForSession = async (id: string): Promise<Session> => {
        return await http.post(`/api/v2/quotationSession/${id}/emulateSupplierFound`, {})
            .then(e => handleErrors(e));
    };

    public static supplierNotFoundForSession = async (id: string): Promise<Session> => {
        return await http.post(`/api/v2/quotationSession/${id}/emulateNoSupplierFound`, {})
            .then(e => handleErrors(e));
    };

    public static cancelSession = async (id: string, reason: string): Promise<Session> => {
        return await http.post(`/api/v2/quotationSession/${id}/cancel?reason=${reason}`, {})
            .then(e => handleErrors(e));
    };

    public static cancelPublicationSession = async (id: string): Promise<Session> => {
        return await http.post(`/api/v2/quotationSession/${id}/cancelPublication`, {})
            .then(e => handleErrors(e));
    };

    public static recallSession = async (id: string): Promise<Session> => {
        return await http.post(`/api/v2/quotationSession/${id}/recall`, {})
            .then(e => handleErrors(e));
    };

    public static async getById(id: string) {
        return await http.get(`/api/v2/quotationSession/${id}`)
            .then(handleErrors)
            .then(result => sessionCodec.decode(result))
            .then(handleCodecErrors);
    }

    public static getSubjectDeclarationById = async (id: string): Promise<SPGZ> => {
        return await http.get(`/api/v2/directory/subjectDeclaration/${id}`)
            .then(e => handleErrors(e));
    };
}
