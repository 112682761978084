import {ModalFC} from "@/EventBus";
import {CardModal} from "@/components/modals/CardModal";
import React from "react";
import {Expandee, HStack} from "@/components/layouts";
import {Button, Label} from "@/components/primitive";


export const SaveDeniedModal: ModalFC<{}> = x => {

    const bottom = <HStack spacing={"10px"}>
        <Expandee/>
        <Button color="blue"
                title="Закрыть"
                onClick={() => x.done()} />
    </HStack>;

    return <CardModal title={"Невозможно сохранить"}
                      close={() => x.done()}
                      width={"50%"}
                      bottom={bottom}
    >
        <Label text={"В карточку контракта запрещено добавлять больше одного документа с типом документа 'Контракт'"} />
    </CardModal>;
};