import { debounced } from "@/functionUtils";
import { createEvent, createEffect, createStore } from "effector";
import { ISubjectClassContainer } from "@/components/eshop/SubjectClassFilterStore";
import { DirectPurchasesController } from "@/api/DirectPurchases";
import { IFilterObject, FilterValueType, FilterConditionType } from "@/api/http";
import { ProductController } from "@/api/Products";
import { ServicesController } from "@/api/Services";
import Decimal from "decimal.js";
import { SessionsController } from "@/api/Sessions";

export type CreationMode = 'SERVICES' | 'PRODUCTS' | 'PURCHASES';

export type ServicesFilterStore = {
    subject: string | null
    kpgz: ISubjectClassContainer | null
    id: number | null
    steId: number | null
    name: string | null
    referentPriceMin: number | null
    referentPriceMax: number | null
    isPopular: boolean | null
    purchaseNumber: string | null
    purchaseName: string | null
    purchasePriceMin: number | null
    purchasePriceMax: number | null
    quantityMin: number | null
    quantityMax: number | null
    startValidDate: Date | null
    endValidDate: Date | null
    participantName: string | null
    pageSize: number
    mode: CreationMode | null
    kpgzCode: string | null
};

export const changePageSize = createEffect({
    name: "changePageSize",
    handler: (payload: { pageSize: number }) => {
        return payload.pageSize;
    }
});

export const changeKpgzCode = createEffect({
    name: "changeKpgzCode",
    handler: (payload: { kpgzCode: string | null }) => {
        return payload.kpgzCode;
    }
});

export const changeKpgz = createEffect({
    name: "changeKpgz",
    handler: (payload: { kpgz: ISubjectClassContainer | null }) => {
        return payload.kpgz;
    }
});
export const changeSubject = createEvent<string>();
export const changeId = createEvent<number>();

export const changeSteId = createEvent<number>();
export const changeProductName = createEvent<string>();
export const changeReferentPriceMin = createEvent<number>();
export const changeReferentPriceMax = createEvent<number>();
export const changeIsPopular = createEvent<boolean | null>();
export const changePurchaseNumber = createEvent<string>();
export const changePurchaseName = createEvent<string>();
export const changePurchasePriceMin = createEvent<number>();
export const changePurchasePriceMax = createEvent<number>();
export const changePurchaseQuantityMin = createEvent<number>();
export const changePurchaseQuantityMax = createEvent<number>();
export const changePurchaseStartValidDate = createEvent<Date>();
export const changePurchaseEndValidDate = createEvent<Date>();
export const changePurchaseParticipantName = createEvent<string>();

export const onChangeMode = createEvent<CreationMode>();

export const loadServicesPage = createEffect({
    name: "loadServicesPage",
    handler: async (payload: { page: number }) => {
        const filtersState = $filters.getState();
        const filters: IFilterObject = {};
        filters["kpgzCode"] = [{
            type: FilterValueType.STRING,
            conditionType: FilterConditionType.STARTS_WITH,
            string: filtersState.kpgzCode
        }];

        return await ServicesController.get({ from: payload.page * filtersState.pageSize, count: filtersState.pageSize, filters: filters }, false);
    }
});

export const loadServices = createEffect({
    name: "loadServices",
    handler: async (payload: { ids: string[] }) => {
        return await Promise.all(payload.ids
            .map(serviceId => SessionsController.getSubjectDeclarationById(serviceId)));
    }
});

export const loadProductsPage = createEffect({
    name: "loadProductsPage",
    handler: async (payload: { page: number }) => {
        const filtersState = $filters.getState();
        const filters: IFilterObject = {};
        filters["kpgzCode"] = [{
            type: FilterValueType.STRING,
            conditionType: FilterConditionType.STARTS_WITH,
            string: filtersState.kpgzCode
        }];

        return await ProductController.get({ from: payload.page * filtersState.pageSize, count: filtersState.pageSize, filters: filters }, false);
    }
});

export const loadProducts = createEffect({
    name: "loadProducts",
    handler: async (payload: { ids: string[] }) => {
        return await Promise.all(payload.ids
            .map(productId => ProductController.getProduct(productId)));
    }
});

export const loadPurchasesPage = createEffect({
    name: "loadPurchasesPage",
    handler: async (payload: { page: number }) => {
        const filtersState = $filters.getState();
        const filters: IFilterObject = {};
        filters["kpgzCode"] = [{
            type: FilterValueType.STRING,
            conditionType: FilterConditionType.STARTS_WITH,
            string: filtersState.kpgzCode
        }];

        return await DirectPurchasesController.get({ from: payload.page * filtersState.pageSize, count: filtersState.pageSize, filters: filters }, false);
    }
});

export const loadPurchases = createEffect({
    name: "loadPurchases",
    handler: async (payload: { ids: string[] }) => {
        return await Promise.all(payload.ids
            .map(productId => DirectPurchasesController.getDirectPurchase(productId)));
    }
});

export const searchServices = createEffect({
    name: "searchServices",
    handler: async (payload: { page: number }) => {
        const filtersState = $filters.getState();
        const filters: IFilterObject = {};

        if (filtersState.subject) {
            filters["subject"] = [{
                type: FilterValueType.STRING,
                conditionType: FilterConditionType.CONTAINS,
                string: filtersState.subject
            }];
        }

        if (filtersState.id) {
            filters["id"] = [{
                type: FilterValueType.LONGINT,
                conditionType: FilterConditionType.CONTAINS,
                longint: filtersState.id
            }];
        }

        if (filtersState.kpgzCode) {
            filters["kpgzCode"] = [{
                type: FilterValueType.STRING,
                conditionType: FilterConditionType.STARTS_WITH,
                string: filtersState.kpgzCode
            }];
        }

        if (filtersState.kpgz?.object.code) {
            filters["kpgzCode"] = [{
                type: FilterValueType.STRING,
                conditionType: FilterConditionType.STARTS_WITH,
                string: filtersState.kpgz.object.code
            }];
        }

        return await ServicesController.get({
            from: payload.page * filtersState.pageSize,
            count: filtersState.pageSize,
            filters: filters
        }, false);
    }
});

export const searchProducts = createEffect({
    name: "searchProducts",
    handler: async (payload: {}) => {
        const filtersState = $filters.getState();
        const filters: IFilterObject = {};

        if (filtersState.name) {
            filters["stuName"] = [{
                type: FilterValueType.STRING,
                conditionType: FilterConditionType.CONTAINS,
                string: filtersState.name
            }];
        }

        if (filtersState.steId) {
            filters["stuNumber"] = [{
                type: FilterValueType.LONGINT,
                conditionType: FilterConditionType.EQUAL,
                longint: filtersState.steId
            }];
        }

        if (filtersState.referentPriceMin || filtersState.referentPriceMax) {
            filters["refPrice"] = [];
            if (filtersState.referentPriceMin) {
                filters["refPrice"] = [...filters["refPrice"], {
                    type: FilterValueType.DECIMAL,
                    conditionType: FilterConditionType.GREATER_THAN,
                    decimal: new Decimal(filtersState.referentPriceMin)
                }];
            }
            if (filtersState.referentPriceMax) {
                filters["refPrice"] = [...filters["refPrice"], {
                    type: FilterValueType.DECIMAL,
                    conditionType: FilterConditionType.LESS_THAN,
                    decimal: new Decimal(filtersState.referentPriceMax)
                }];
            }
        }

        if (filtersState.isPopular) {
            filters["isDemanded"] = [{
                type: FilterValueType.BOOLEAN,
                conditionType: FilterConditionType.EQUAL,
                boolean: filtersState.isPopular
            }];
        }

        if (filtersState.kpgzCode) {
            filters["kpgzCode"] = [{
                type: FilterValueType.STRING,
                conditionType: FilterConditionType.STARTS_WITH,
                string: filtersState.kpgzCode
            }];
        }

        return await ProductController.get({ from: 0, count: 10, filters: filters }, false);
    }
});

export const searchPurchases = createEffect({
    name: "searchPurchases",
    handler: async (payload: {}) => {
        const filtersState = $filters.getState();
        const filters: IFilterObject = {};

        if (filtersState.purchaseNumber) {
            filters["offerNumber"] = [{
                type: FilterValueType.STRING,
                conditionType: FilterConditionType.CONTAINS,
                string: filtersState.purchaseNumber
            }];
        }
        if (filtersState.purchaseName) {
            filters["directPurchaseName"] = [{
                type: FilterValueType.STRING,
                conditionType: FilterConditionType.CONTAINS,
                string: filtersState.purchaseName
            }];
        }
        if (filtersState.purchasePriceMin || filtersState.purchasePriceMax) {
            filters["priceWithNds"] = [];
            if (filtersState.purchasePriceMin) {
                filters["priceWithNds"] = [...filters["priceWithNds"], {
                    type: FilterValueType.DECIMAL,
                    conditionType: FilterConditionType.GREATER_THAN_OR_EQUAL,
                    decimal: new Decimal(filtersState.purchasePriceMin)
                }];
            }
            if (filtersState.purchasePriceMax) {
                filters["priceWithNds"] = [...filters["priceWithNds"], {
                    type: FilterValueType.DECIMAL,
                    conditionType: FilterConditionType.LESS_THAN_OR_EQUAL,
                    decimal: new Decimal(filtersState.purchasePriceMax)
                }];
            }
        }
        if (filtersState.quantityMin) {
            filters["quantityFrom"] = [{
                type: FilterValueType.LONGINT,
                conditionType: FilterConditionType.GREATER_THAN_OR_EQUAL,
                longint: filtersState.quantityMin
            }];
        }
        if (filtersState.quantityMax) {
            filters["quantityTo"] = [{
                type: FilterValueType.LONGINT,
                conditionType: FilterConditionType.LESS_THAN_OR_EQUAL,
                longint: filtersState.quantityMax
            }];
        }
        if (filtersState.startValidDate) {
            filters["startValidDate"] = [{
                type: FilterValueType.DATE,
                conditionType: FilterConditionType.GREATER_THAN_OR_EQUAL,
                date: filtersState.startValidDate
            }];
        }
        if (filtersState.endValidDate) {
            filters["endValidDate"] = [{
                type: FilterValueType.DATE,
                conditionType: FilterConditionType.LESS_THAN_OR_EQUAL,
                date: filtersState.endValidDate
            }];
        }
        if (filtersState.participantName) {
            filters["participantShortName"] = [{
                type: FilterValueType.STRING,
                conditionType: FilterConditionType.CONTAINS,
                string: filtersState.participantName
            }];
        }
        if (filtersState.kpgzCode) {
            filters["kpgzCode"] = [{
                type: FilterValueType.STRING,
                conditionType: FilterConditionType.STARTS_WITH,
                string: filtersState.kpgzCode
            }];
        }

        return await DirectPurchasesController.get({
            from: 0,
            count: 10,
            filters: filters
        }, false);
    }
});

const createFilterStore = () =>
    createStore<ServicesFilterStore>({
        subject: null,
        kpgz: null,
        id: null,
        steId: null,
        name: null,
        referentPriceMin: null,
        referentPriceMax: null,
        isPopular: null,
        purchaseNumber: null,
        purchaseName: null,
        purchasePriceMin: null,
        purchasePriceMax: null,
        quantityMin: null,
        quantityMax: null,
        startValidDate: null,
        endValidDate: null,
        participantName: null,
        pageSize: 10,
        mode: null,
        kpgzCode: null
    })
        .on(onChangeMode, (state, mode) => ({ ...state, mode }))
        .on(changeSubject, setFieldWithServicesTimeout('subject'))
        .on(changeId, setFieldWithServicesTimeout('id'))
        .on(changeKpgz, (state, { kpgz }) => {
            return ({ ...state, kpgz });
        })
        .on(changeKpgz.done, () => {
            searchServices({ page: 0 });
        })
        .on(changeSteId, setFieldWithProductsTimeout('steId'))
        .on(changeProductName, setFieldWithProductsTimeout('name'))
        .on(changeReferentPriceMin, setFieldWithProductsTimeout('referentPriceMin'))
        .on(changeReferentPriceMax, setFieldWithProductsTimeout('purchasePriceMax'))
        .on(changeIsPopular, setFieldWithProductsTimeout('isPopular'))
        .on(changePurchaseNumber, setFieldWithPurchasesTimeout('purchaseNumber'))
        .on(changePurchaseName, setFieldWithPurchasesTimeout('purchaseName'))
        .on(changePurchasePriceMin, setFieldWithPurchasesTimeout('purchasePriceMin'))
        .on(changePurchasePriceMax, setFieldWithPurchasesTimeout('purchasePriceMax'))
        .on(changePurchaseQuantityMin, setFieldWithPurchasesTimeout('quantityMin'))
        .on(changePurchaseQuantityMax, setFieldWithPurchasesTimeout('quantityMax'))
        .on(changePurchaseStartValidDate, setFieldWithPurchasesTimeout('startValidDate'))
        .on(changePurchaseEndValidDate, setFieldWithPurchasesTimeout('endValidDate'))
        .on(changePurchaseParticipantName, setFieldWithPurchasesTimeout('participantName'))
        .on(changeKpgzCode, (state, { kpgzCode }) => {
            return ({ ...state, kpgzCode });
        })
        .on(changePageSize, (state, { pageSize }) => {
            return ({ ...state, pageSize });
        })
        .on(changePageSize.done, (state) => {
            if (state.mode === 'PURCHASES') {
                timeoutPurchases();
            }
            if (state.mode === 'PRODUCTS') {
                timeoutProducts();
            }
            if (state.mode === 'SERVICES') {
                timeoutServices();
            }
            return { ...state };
        });

const setFieldWithPurchasesTimeout = <T extends keyof ServicesFilterStore>(key: T) => (state: ServicesFilterStore, val: ServicesFilterStore[T]) => {
    timeoutPurchases();
    return ({ ...state, [key]: val });
};

const setFieldWithProductsTimeout = <T extends keyof ServicesFilterStore>(key: T) => (state: ServicesFilterStore, val: ServicesFilterStore[T]) => {
    timeoutProducts();
    return ({ ...state, [key]: val });
};

const setFieldWithServicesTimeout = <T extends keyof ServicesFilterStore>(key: T) => (state: ServicesFilterStore, val: ServicesFilterStore[T]) => {
    timeoutServices();
    return ({ ...state, [key]: val });
};

export const $filters = createFilterStore();

const timeoutServices = debounced(1500, () => { searchServices({ page: 0 }) });
const timeoutProducts = debounced(1500, () => { searchProducts({ page: 0 }) });
const timeoutPurchases = debounced(1500, () => { searchPurchases({ page: 0 }) });