




import {Component} from "vue-property-decorator";
import Vue from 'vue';
import {createTradesStore} from "@/views/Procedures2020/trade/list/store";
import {Trades} from "@/views/Procedures2020/trade/list/Trades";

@Component({ components: { komponent: Trades as unknown as typeof Vue } })
export default class TradesVueStub extends Vue {
    public storeContainer = Object.freeze({ store: createTradesStore() });
}
