import {UnionStrings} from "@/models/enums";

export type PlanStatus =
    | "CREATING"
    | "APPROVED"
    | "SENT_TO_FO"
    | "FO_AGREED"
    | "PUBLISHING"
    | "PUBLISHED"
    | "DELETED";

export const PlanStatusStrings: UnionStrings<PlanStatus> = {
  DELETED: "Удален",
  CREATING: "Формирование",
  APPROVED: "Утвержден",
  SENT_TO_FO: "На согласовании в ФО",
  FO_AGREED: "Согласован в ФО",
  PUBLISHING: "Отправлен на публикацию",
  PUBLISHED: "Опубликован"
};