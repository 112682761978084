





























































































































import { Component, Prop, Vue } from "vue-property-decorator";
import DataTableCell from "@/components/vue/data-table/DataTableCell.vue";
import DataTableActionCell from "@/components/vue/data-table/DataTableActionCell.vue";
import DataTableFilterCell from "@/components/vue/data-table/DataTableFilterCell.vue";
import {
    ITableColumn,
    IRowConfiguration,
    ITableAction,
    SelectionStyle,
    Selection,
    SingleSelection,
    MultiSelection,
    Identified,
    SelectionColumnControl,
} from "@/components/TableAbstractions";
import EventBus from "@/EventBus";
import { TableGridRow, TableGridCell } from './TableGrid/TableGridProps';
import TableGrid from './TableGrid';
import { UIFilterValue } from './data-table/FilterValue';

// todo remove copy-paste
function debounced<FN extends (...args: any[]) => void>(delay: number, fn: FN) {
    let timerId: number | undefined;
    return ((...args) => {
        if (timerId) {
            clearTimeout(timerId);
        }
        timerId = setTimeout(() => {
            fn(...args);
            timerId = undefined;
        }, delay);
    }) as FN;
}

type RowType = "DATA" | "HEADER" | "FILTER" | "FULL_SPAN" | "FILLER";

@Component({ components: { DataTableCell, DataTableActionCell, DataTableFilterCell, TableGrid } })
export default class DataTable extends Vue {
    @Prop() private headers!: ITableColumn<unknown>[];
    @Prop() private dataset!: Identified[];
    @Prop({ default: null }) private actions!: ITableAction[] | null;
    @Prop({ default: null }) private config!: IRowConfiguration<unknown> | null;
    @Prop({ default: null }) private selectPrevIndex!: boolean;
    @Prop() private height?: string;

    @Prop() private showFilters!: boolean;
    @Prop({ default: null }) private selection!: Selection<Identified> | null;

    @Prop({ default: false }) private readonly!: boolean;
    @Prop({ default: false }) private hasWhenEmpty!: boolean;
    @Prop({ default: "904px" }) private minWidth!: string;

    private static readonly ActionsHeaderCell = {
        template: "header-cell",
        source: { title: "Действия" }
    };

    private static readonly ActionsHeaderCellSticky = {
        template: "header-cell",
        source: { title: "Действия" },
        sticky: true
    };

    private static readonly EditSpanCell = {
        template: "empty-cell",
        colSpan: 2,
        source: { className: "header-cell" }
    };

    private static readonly EditSpanCellSticky = {
        template: "empty-cell",
        colSpan: 2,
        source: { className: "header-cell" },
        sticky: true
    };

    private static readonly EmptyHeaderCell = {
        template: "empty-cell",
        source: { className: "header-cell" }
    };

    private static readonly EmptyHeaderCellSticky = {
        template: "empty-cell",
        source: { className: "header-cell" },
        sticky: true
    };

    private static readonly ActionsValueCell = {
        template: "action-cell"
    };

    private static readonly FillerCell = {
        template: "empty-cell",
        source: { className: "filler-cell" },
    };

    public get hasEditColumn() { return this.config ? this.config.showEditColumn || false : false }
    public get selectionLocked() { return this.config && this.config.selection ? this.config.selection.locked || false : false }
    public get predicateAction() { return this.config && this.config.selection ? this.config.selection.predicateAction || "hide" : "hide" }
    public get useSlotActions() { return this.config ? this.config.useSlotForActionColumn || false : false }
    public get isMultiSelection() { return this.selection !== null && this.selection.tag === "MULTI" }
    public get hasSelectionColumn() {
        return (this.selection || !!(this.config && this.config.selection && this.config.selection.explicitChecked)) && this.config && this.config.selection ? this.config.selection.showSelectionColumn || false : false;
    }
    public get selectionColumnControl() {
        return this.config && this.config.selection ? this.config.selection.selectionColumnControl : undefined;
    }

    private insertSelectionColumn(columns: (TableGridCell | null)[], column: TableGridCell | null) {
        const onRight = this.config && this.config.selection ? this.config.selection.selectionColumnPosition === "right" : undefined;
        if (onRight) {
            columns.push(column);
        } else {
            columns.splice(0, 0, column);
        }
    }

    private withAdditionalColumns(columns: (TableGridCell | null)[], rowType: RowType, item: unknown): (TableGridCell | null)[] {
        const emptyHeaderCell = this.height ? DataTable.EmptyHeaderCellSticky : DataTable.EmptyHeaderCell;
        switch (rowType) {
            case "HEADER":
                if (this.useSlotActions || (this.actions && this.actions.length))
                    columns.push(this.height ? DataTable.ActionsHeaderCellSticky : DataTable.ActionsHeaderCell);
                if (this.hasEditColumn) {
                    columns.push(this.height ? DataTable.EditSpanCellSticky : DataTable.EditSpanCell);
                    columns.push(null);
                }

                if (this.hasSelectionColumn) {
                    const header = this.config && this.config.selection ? this.config.selection.selectionColumnHeader : undefined;
                    if (header)
                        this.insertSelectionColumn(columns, {
                            template: "header-cell",
                            source: { title: header, alignment: "center" },
                            sticky: !!this.height
                        });
                    else
                        this.insertSelectionColumn(columns, emptyHeaderCell);
                }

                break;
            case "FILTER":
                if (this.useSlotActions || (this.actions && this.actions.length))
                    columns.push(emptyHeaderCell);
                if (this.hasEditColumn) {
                    columns.push(emptyHeaderCell);
                    columns.push(emptyHeaderCell);
                }

                if (this.hasSelectionColumn)
                    this.insertSelectionColumn(columns, emptyHeaderCell);

                break;
            case "FULL_SPAN":
                if (this.useSlotActions || (this.actions && this.actions.length))
                    columns.push(null);
                if (this.hasEditColumn) {
                    columns.push(null);
                    columns.push(null);
                }

                if (this.hasSelectionColumn)
                    this.insertSelectionColumn(columns, null);

                break;
            case "DATA":
                if (this.useSlotActions)
                    columns.push({
                        template: "slot-actions-cell",
                        source: item
                    });
                else if (this.actions && this.actions.length)
                    columns.push(DataTable.ActionsValueCell);
                if (this.hasEditColumn) {
                    columns.push({
                        template: "edit-button-cell",
                        source: item
                    });
                    columns.push({
                        template: "delete-button-cell",
                        source: item
                    });
                }

                if (this.hasSelectionColumn)
                    this.insertSelectionColumn(columns, {
                        template: "selector-cell",
                        source: {
                            type: this.selectionColumnControl || (this.isMultiSelection ? "checkbox" : "radio"),
                            item,
                            locked: this.selectionLocked
                        }
                    });
                break;
            case "FILLER":
                if (this.useSlotActions)
                    columns.push(null);
                else if (this.actions && this.actions.length)
                    columns.push(null);
                if (this.hasEditColumn) {
                    columns.push(null);
                    columns.push(null);
                }

                if (this.hasSelectionColumn)
                    this.insertSelectionColumn(columns, null);

                if (!columns.length) {
                    columns.push(DataTable.FillerCell);
                } else {
                    let hasFiller = false;

                    for (const column of columns) {
                        if (column !== null) {
                            hasFiller = true;
                        }
                    }

                    if (!hasFiller)
                        columns[0] = DataTable.FillerCell;
                }

                break;
        }
        return columns;
    }

    private rowCells(item: unknown, index: number) {
        return this.withAdditionalColumns(this.filteredHeaders.map(header => ({
            template: "value-cell",
            source: { header, item, index }
        })), "DATA", item);
    }

    private itemRows(item: unknown, index: number) {
        const rowCells = this.rowCells(item, index);

        if (this.config && this.config.showJoinedRow && this.config.showJoinedRow !== "last" && this.config.showJoinedRow(item)) {
            const pure = this.config.isRawJoinedRow && this.config.isRawJoinedRow(item);
            const nulls: (TableGridCell | null)[] = this.filteredHeaders.map(() => null);
            if (nulls.length) {
                nulls[0] = {
                    template: "joined-row-cell",
                    source: { pure, item },
                    colSpan: "FULL",
                    pure: pure
                };
            }

            return [
                { cells: rowCells },
                { cells: this.withAdditionalColumns(nulls, "FULL_SPAN", null) }
            ];
        } else {
            return [{ cells: rowCells }];
        }
    }

    public get tableCells(): TableGridRow[] {
        let datasetCells: TableGridRow[];

        if (this.config && this.config.group) {
            const grouped = this.config.group(this.dataset || []);
            datasetCells = grouped.flatMap(x => {
                const valueCells = x.items.flatMap((item, ix) => this.itemRows(item, ix));

                if (x.header) {
                    const cells: (TableGridCell | null)[] = this.filteredHeaders.map(() => null);
                    cells[0] = {
                        template: "group-header-cell",
                        source: x.header,
                        colSpan: "FULL"
                    };

                    valueCells.splice(0, 0, {
                        cells: this.withAdditionalColumns(cells, "FULL_SPAN", null)
                    });
                }

                return valueCells;
            });
        } else {
            datasetCells = (this.dataset || []).flatMap((item, ix) => this.itemRows(item, ix));

            if (this.height) {
                const nulls: (TableGridCell | null)[] = this.filteredHeaders.map(() => null);

                if (nulls.length) {
                    nulls[0] = {
                        template: "empty-cell",
                        colSpan: "FULL",
                        source: { className: "filler-cell" }
                    };
                }

                datasetCells.push({cells: this.withAdditionalColumns(nulls, "FILLER", null)});
            }
        }
        if (this.showFilters) {
            datasetCells.splice(0, 0, {
                cells: this.withAdditionalColumns(this.filteredHeaders.map(header => ({
                    template: "filter-cell",
                    source: { header, filter: this.filters[this.filteredHeaders.indexOf(header)] }
                })), "FILTER", null)
            });
        }
        datasetCells.splice(0, 0, {
            cells: this.withAdditionalColumns(this.filteredHeaders.map(header => ({
                template: "header-cell",
                source: { title: header.title, alignment: header.headerAlignment || "left" },
                sticky: !!this.height
            })), "HEADER", null)
        });

        if (this.config && this.config.showJoinedRow === "last") {
            const nulls: (TableGridCell | null)[] = this.filteredHeaders.map(() => null);
            nulls[0] = {
                template: "joined-row-cell",
                source: { pure: true, item: null },
                colSpan: "FULL",
                pure: true
            };

            datasetCells.push({ cells: this.withAdditionalColumns(nulls, "FULL_SPAN", null) });
        }

        return datasetCells;
    }

    public get tableColumnSizes(): string[] {
        const headerSizes = this.filteredHeaders.map(header => header.size || (header.action ? "auto" : "1fr"));

        if (this.useSlotActions || (this.actions && this.actions.length))
            headerSizes.push("auto");
        if (this.hasEditColumn) {
            headerSizes.push("auto");
            headerSizes.push("auto");
        }

        if (this.hasSelectionColumn) {
            const onRight = this.config && this.config.selection ? this.config.selection.selectionColumnPosition === "right" : undefined;
            if (onRight) {
                headerSizes.push("auto");
            } else {
                headerSizes.splice(0, 0, "auto");
            }
        }

        return headerSizes;
    }


    public get tableRowSizes(): string[] {
        const sizes = this.tableCells.map(x => "auto");
        if (this.height) sizes[sizes.length - 1] = "1fr";
        return sizes;
    }

    public get lastColumnIndex() { return this.tableColumnSizes.length - 1 }

    private get filteredHeaders(): ITableColumn<unknown>[] {
        return this.headers.filter(x => !x.visible || x.visible());
    }

    private filters: UIFilterValue[] = this.filteredHeaders.map(x => ({
        filter: x.filter,
        values: []
    }));

    public resetFilters() {
        for (const f of this.filters) {
            f.values = [];
        }

        this.reallyUpdateFilter();
    }

    private createFilterObject(): unknown {
        const o: {[key: string]: unknown} = {};

        for (let i = 0; i < this.filteredHeaders.length; i++) {
            if (this.filteredHeaders[i].filter) {
                o[this.filteredHeaders[i].filter!.property] = this.filters[i].values;
            }
        }

        return o;
    }

    private reallyUpdateFilter() {
        this.$emit("apply-filters", this.createFilterObject());
    }

    private updateFilter = debounced(200, this.reallyUpdateFilter);

    private currentPopupActions: ITableAction[] = [];
    private currentPopupItem: unknown = null;
    private currentPopupTop = 0;
    private currentPopupLeft = 0;

    private gridScrollX = 0;

    private gridScroll(ev: Event) {
        this.gridScrollX = (ev.target as HTMLElement).scrollLeft;
    }

    private openActionsIx: number | null = null;

    private closeActions() {
        this.currentPopupItem = null;
    }

    private setValue(column: ITableColumn<unknown>, item: unknown, value: unknown) {
        if (column.setter) column.setter(item, value as never);
        this.$emit("set-value", column, item, value);
    }

    private handleBlur(column: ITableColumn<unknown>, item: unknown, value: unknown) {
        if (column.blur) {
            column.blur(item, value as never);
        }
    }

    private created() {
        EventBus.$on("close-popup", this.closeActions);
    }

    private beforeDestroy() {
        EventBus.$off("close-popup", this.closeActions);
    }

    private openActions(ev: MouseEvent, ix: number) {
        const grid = (this.$refs.grid as Vue).$el as HTMLElement;
        const cell = (this.$refs[`actions-value-cell-${ix}`] as Vue).$el as HTMLElement;

        const tarRect = cell.getBoundingClientRect();
        const gridRect = grid.getBoundingClientRect();

        this.currentPopupLeft = tarRect.left - gridRect.left - grid.scrollLeft;
        this.currentPopupTop = tarRect.top - gridRect.top - grid.scrollTop;
        if(this.selectPrevIndex) {
            this.currentPopupItem = this.dataset[ix - 1];
        } else {
            this.currentPopupItem = this.dataset[ix];
        }

        ev.stopPropagation();
    }

    private selectable(item: Identified) {
        return !this.config || !this.config.selection || !this.config.selection.predicate || this.config.selection.predicate(item);
    }

    private select(item: Identified) {
        if (!this.selectionLocked && this.selectable(item)) {
            if (this.selectionColumnControl === SelectionColumnControl.RADIO)
                this.$emit("selected", item);
            else
                this.$emit(this.isSelected(item) ? "deselected" : "selected", item);

            if (!this.selection)
                return;

            if (this.selection.tag === "MULTI") {
                this.toggle(item);
                return;
            }

            const radio =
                (this.selectionColumnControl !== SelectionColumnControl.CHECKBOX) &&
                this.hasSelectionColumn;

            if (this.selection.item && this.selection.item.id === item.id && !radio) {
                this.selection.item = null;
                this.$forceUpdate(); // TODO!!!
                return;
            }

            if (this.selection && this.selection.tag === "SINGLE") {
                this.selection.item = item;
            }
        }
    }

    private toggle(item: Identified) {
        if (this.selectable(item)) {
            this.$emit(this.isSelected(item) ? "deselected" : "selected", item);

            if (this.selection && this.selection.tag === "MULTI") {
                const ix = this.selection.items.findIndex(x => x.id === item.id);
                if (ix === -1) this.selection.items.push(item);
                else this.selection.items.splice(ix, 1);
                this.$forceUpdate(); // TODO!!!
            }
        }
    }

    public rowStyle(item: Identified) {
        if (this.config && this.config.highlight) {
            const highlight = this.config.highlight(item);
            if (highlight !== null) {
                return { backgroundColor: highlight };
            }
        }

        if (!this.config || !this.config.selection || !this.config.selection || !this.config.selection.background || !this.isSelected(item))
            return {};

        return { backgroundColor: this.config.selection.background };
    }

    private isSelected(item: Identified) {
        if (this.config && this.config.selection && this.config.selection.explicitChecked) {
            return this.config.selection.explicitChecked(item);
        }

        if (!this.selection) return false;

        switch (this.selection.tag) {
            case "SINGLE":
                return !!this.selection.item && this.selection.item.id === item.id;
            case "MULTI":
                return !!this.selection.items.find(x => x.id === item.id);
            default:
                return false;
        }
    }
}
