import {Currency, CurrencyStrings, LawType, LawTypeStrings, ObjectStatus, SupplyStagePeriodType, toSelectOptions} from "@/models/enums";
import AdditionalAgreements from "@/views/CreateContract/AdditionalAgreements.vue";
import Claims from "@/views/CreateContract/Claims.vue";
import ContractDocsPage from "@/views/CreateContract/ContractDocsPage.vue";
import {ContractScope} from "@/views/CreateContract/ContractScope";
import Documents from "@/views/CreateContract/Documents.vue";
import Economy from "@/views/CreateContract/Economy.vue";
import Liability from "@/views/CreateContract/Liability.vue";
import OrdererAccounts from "@/views/CreateContract/OrdererAccounts.vue";
import ResponsiblePerson from "@/views/CreateContract/ResponsiblePerson.vue";
import Sources from "@/views/CreateContract/Sources.vue";
import Specifications from "@/views/CreateContract/Specifications.vue";
import Stages from "@/views/CreateContract/Stages.vue";
import GuaranteeEvents from "@/views/CreateContract/GuaranteeEvents.vue";
import Subcontractors from "@/views/CreateContract/Subcontractors.vue";
import ExecutionProvision from "@/views/CreateContract/ExecutionProvision.vue";
import GuaranteeProvision from "@/views/CreateContract/GuaranteeProvision.vue";
import VendorAccounts from "@/views/CreateContract/VendorAccounts.vue";
import ContractStages from "@/views/CreateContract/execution/ContractStages.vue";
import {ContractExecutionStatus} from "@/models/enums/Contracts/ContractExecutionStatus";
import ExecutionHistorySection from "@/views/form-renderers/ExecutionHistorySection/ExecutionHistorySection.vue";
import ChangeHistorySection from "@/views/form-renderers/ChangeHistorySection/ChangeHistorySection.vue";
import FieldSection from "@/views/form-renderers/FieldSection/FieldSection.vue";
import {FormStyle, IAction, IconType, IForm} from "@/views/FormAbstractions";
import {actionL, actionR, makeFormHelpers, pathfactory} from "./helpers";
import {isAfter, isBefore} from "date-fns";
import {formatNumber} from "@/NumberFormatting";
import {ContractExecutionOperationState} from "@/models/enums/Contracts";
import {DateTimeRange} from "@/models";
import {LotProvision} from "@/models/ComposedLots/LotProvision";
import {durationDates, executionDates} from "@/form-declarations/hints";
import { contractStagesByLaw, isConcludedEshopContract } from "@/form-declarations/contractStageActions";
import SectionTitle from "@/views/CreateContract/SectionTitle.vue";

const h = makeFormHelpers<ContractScope>();
const p = pathfactory<ContractScope>();

export function FormDeclarationBuilder(readonly: boolean, headerProvider: { header: string }, isEshop?: boolean, law?: LawType, lotGuaranteeProvision?: LotProvision): IForm<ContractScope> {
    return {
        get header() {
            return headerProvider.header;
        },
        actions(scope) {
            if (scope.contract.isEshop) {
                return contractStagesByLaw[scope.contract.law](true)(scope.contract);
            }

            const actions: IAction<ContractScope>[] = [];

            const status = scope.contract && scope.contract.status;
            const waitingForStageRegistration = scope.contract ? scope.contract.waitingForStageRegistration : true;

            const law = scope.contract.isMonopoly ? LawType.F44 : scope.contract.law;

            const creating = status === ObjectStatus.CREATING_FIRST || status === ObjectStatus.CREATING;

            // if (status === ObjectStatus.CREATING_FIRST) {
            //     actions.push(actionL("delete", "red", "Удалить", x => !x.loading))
            //     actions.push(actionL("save", "blue", { type: IconType.CLASS, name: "save" }, x => !x.loading))
            // } else
            if (
                status === ObjectStatus.CREATING_APPROVED ||
                (creating && (scope.contract.foApprovalDate || law === LawType.F223))
            ) {
                actions.push(actionL("save", "blue", { type: IconType.CLASS, name: "save" }, x => !x.loading));
                actions.push(actionL("register", "blue", "Зарегистрировать", x => !x.loading));
                if (scope.contract.versionNumber >= 0)
                    actions.push(actionL("rollback", "blue", "Вернуться к последней редакции"));
            } else if (creating) {
                actions.push(actionL("save", "blue", { type: IconType.CLASS, name: "save" }, x => !x.loading));
                actions.push(actionL("register", "blue", "Согласовать ФО", x => !x.loading));
                if (scope.contract.versionNumber >= 0)
                    actions.push(actionL("rollback", "blue", "Вернуться к последней редакции"));
            } else if (status === ObjectStatus.FO_AGREED && !waitingForStageRegistration) {
                if (scope.contract.electronicConclusionState === "NOT_SENT") {
                    actions.push(actionL("make-changes", "blue", "Внести изменения", x => !x.loading));
                    actions.push(actionL("electronic-conclusion", "blue", "Электронное заключение", x => !x.loading));
                } else if (scope.contract.isEshop) {
                    actions.push(actionL("make-changes", "blue", "Внести изменения", x => !x.loading));
                    actions.push(actionL("electronic-conclusion", "blue", "Электронное заключение", x => !x.loading));
                } else {
                    if (scope.executionHistory.filter(eh => (eh.state === ContractExecutionOperationState.SIGNED)).length)
                        actions.push(actionL("register", "blue", "Зарегистрировать", x => !x.loading));
                    actions.push(actionL("make-changes", "blue", "Внести изменения", x => !x.loading));
                }
            } else if (status === ObjectStatus.EXECUTING && !waitingForStageRegistration) {
                actions.push(actionL("make-changes", "blue", "Внести изменения", x => !x.loading));
                actions.push(actionR("offload-contract", "blue", "Выгрузить контракт в АИС «Портал поставщиков»", x => !x.loading));
            } else if (status === ObjectStatus.ESHOP_CONCLUDED || status === ObjectStatus.SIGNED_BY_SUPPLIER) {
                actions.push(actionL("save", "blue", { type: IconType.CLASS, name: "save" }, x => !x.loading));
                actions.push(actionL("register", "blue", "Согласовать ФО", x => !x.loading));
            } else if (status === ObjectStatus.COMPLETED) {
                actions.push(actionL("make-changes", "blue", "Внести изменения", x => !x.loading));
            } else if (status === ObjectStatus.DISSOLVED) {
                actions.push(actionL("make-changes", "blue", "Внести изменения", x => !x.loading));
                actions.push(actionL("try-again", "blue", "Повторно опубликовать заявку", x => !x.loading));
            }

            actions.push(actionR("close", "red", { type: IconType.CLASS, name: "close" }));

            return actions;
        },
        pages: [
            {
                id: "info",
                title: law === LawType.F44 ? "Информация по контракту" : "Информация по договору",
                showNavigationHeader: true,
                sections: [
                    h.fields(
                        "Общая информация",
                        FormStyle.HORIZONTAL,
                        2,
                        {
                            rows: [
                                h.const().text("Реестровый номер", p("contract", "regNumber")),
                                h.multiline(law === LawType.F44 ? "Номер контракта" : "Номер договора", p("contract", "contractNumber"), {
                                    lines: 2,
                                    required: true,
                                    readonly: scope => scope.contract.isEshop &&
                                        scope.contract.status === ObjectStatus.FO_AGREED || isConcludedEshopContract(scope.contract)
                                }),
                                h.explicit().date("Дата заключения",
                                    x => x.contract && x.contract.conclusionDate,
                                    (x, v) => {
                                        if (!x.contract)
                                            return;

                                        x.contract.conclusionDate = v;

                                        if (x.contract.isMonopoly) {
                                            return;
                                        }

                                        if (!x.contract.durationDates)
                                            x.contract.durationDates = new DateTimeRange(null!, null!);
                                        else {
                                            x.contract.durationDates.from = null!;
                                            x.contract.durationDates.to = null!;
                                        }
                                        if (!x.contract.executionDates)
                                            x.contract.executionDates = new DateTimeRange(null!, null!);
                                        else {
                                            x.contract.executionDates.from = null!;
                                            x.contract.executionDates.to = null!;
                                        }
                                    }, { required: !isEshop || law == "F44", readonly: isEshop || law == "F44" }),
                                h.readonly().date("Дата регистрации", p("contract", "registrationDate")),
                                h.dateRange("Срок исполнения поставщиком", p("contract", "executionDates"), {
                                    rangeStart(t) {
                                        // https://rt.rtall.ru/redmine/issues/784 - не ограничиваем поля "Срок исполнения" пока не введен срок действия
                                        const durationDates = t.contract?.durationDates;
                                        if (durationDates?.from && durationDates?.to)
                                            return t.contract && t.contract.durationDates && t.contract.durationDates.from;
                                        else return null;
                                    },
                                    rangeEnd(t) {
                                        // https://rt.rtall.ru/redmine/issues/784 - не ограничиваем поля "Срок исполнения" пока не введен срок действия
                                        const durationDates = t.contract?.durationDates;
                                        if (durationDates?.from && durationDates?.to)
                                            return t.contract && t.contract.durationDates && t.contract.durationDates.to;
                                        else return null;
                                    },
                                    hint: law === "F44" ? { text: durationDates } : undefined,
                                    readonly: (scope) => scope.contract.isMonopoly
                                }),
                                h.explicit().dateRange("Срок исполнения", x => x.contract && x.contract.durationDates, (x, v) => {
                                    if (!x.contract) return;

                                    x.contract.durationDates = v;
                                    // https://rt.rtall.ru/redmine/issues/784 - не очищаем поля "Срок исполнения" пока не введен срок действия
                                    if (v && x.contract.executionDates && x.contract.durationDates.to && x.contract.durationDates.from) {
                                        if (x.contract.executionDates.from && v.from && isBefore(x.contract.executionDates.from, v.from.setHours(3, 0, 0, 0))) // TODO: костыль
                                            x.contract.executionDates.from = null!;
                                        if (x.contract.executionDates.to && v.to && isAfter(x.contract.executionDates.to, v.to.setHours(3, 0, 0, 0)))
                                            x.contract.executionDates.to = null!;
                                    }

                                }, {
                                    hint: law === "F44" ? { text: executionDates } : undefined,
                                    readonly: (scope) => scope.contract.isMonopoly
                                }),
                            ],
                        },
                        {
                            rows: [
                                h.const().text("Реестровый номер ЕИС", p("contract", "purchaseCode")),
                                h.readonly(({ contract }) => isConcludedEshopContract(contract)).multiline(law === LawType.F44 ? "Предмет контракта" : "Предмет договора", p("contract", "subject"), {
                                    lines: 5,
                                }),
                            ],
                        },
                        {
                            rows: [
                                h
                                    .readonly(({ contract }) => isConcludedEshopContract(contract))
                                    .explicit()
                                    .text(
                                        law === LawType.F44 ? "Сумма контракта" : "Сумма договора",
                                        x => x.contract && formatNumber(x.contract.cost),
                                        () => {},
                                        {
                                            readonlyStyle: "nodisable",
                                        },
                                    ),
                                h
                                    .readonly()
                                    .visible(
                                        x =>
                                            x.contract &&
                                            x.contract.currency !== Currency.RUB &&
                                            x.contract.currency !== null,
                                    )
                                    .decimal(law === LawType.F44 ? "Сумма контракта, руб." : "Сумма договора, руб.", p("contract", "cost"), {
                                        readonlyStyle: "nodisable",
                                    }),
                                h   .readonly()
                                    .explicit()
                                    .checkbox(
                                        law === LawType.F44
                                            ? "Аукцион на право заключения контракта"
                                            : "Аукцион на право заключения договора",
                                        x => !x.contract?.cost.toNumber(),
                                        x => x.contract.isAuctionRequired
                                        ),

                                h
                                    .readonly()
                                    .visible(
                                        x =>
                                            !(
                                                x.contract &&
                                                x.contract.currency !== Currency.RUB &&
                                                x.contract.currency !== null
                                            ),
                                    )
                                    .text("Номер последнего изменения, \nопубликованного на ЕИС", p(
                                        "contract",
                                        "versionNumber",
                                    )),
                            ],
                        },
                        {
                            rows: [
                                h.readonly(({ contract }) => isConcludedEshopContract(contract))
                                    .select(law === LawType.F44 ?
                                    "Валюта контракта" : "Валюта договора",
                                    p("contract", "currency"),
                                    toSelectOptions(CurrencyStrings, false),
                                ),
                                h
                                    .visible(
                                        x =>
                                            x.contract &&
                                            x.contract.isAuctionRequired === true
                                    )
                                    .required()
                                    .decimal(law === LawType.F44 ? "Цена за право заключения контракта:" : "Цена за право заключения договора:", p("contract","auctionCost")
                                    ),
                                h
                                    .readonly()
                                    .visible(
                                        x =>
                                            x.contract &&
                                            x.contract.currency !== Currency.RUB &&
                                            x.contract.currency !== null,
                                    )
                                    .text("Номер последнего изменения, \nопубликованного на ЕИС", p(
                                        "contract",
                                        "versionNumber",
                                    )),
                                h
                                    .select(
                                        "Электронное исполнение",
                                        p("contract", "isElectronicExecution"),
                                        [{ key: null, desc: "Не указано" }, { key: true, desc: "Да" }, { key: false, desc: "Нет" }]
                                ),
                                // h
                                //     .readonly()
                                //     .explicit()
                                //     .text("Сумма НДС, руб.", () => "", () => {}),
                            ],
                        },
                        {
                            rows: [h.enum("Закон-основание", p("contract", "law"), LawTypeStrings)],
                        },
                    ),
                    // h.fields("Основание заключения", FormStyle.HORIZONTAL, 1, {
                    //     rows: [
                    //         h.select("ОСНОВАНИЕ ЗАКУПКИ У ЕДИНСТВЕННОГО ПОСТАВЩИКА:", ["reason"] as any, {
                    //             4: "4. Осуществление закупки малого объема (до 100 тысяч рублей)",
                    //             5: "5. Осуществление закупки малого объема (до 400 тысяч рублей) ",
                    //         }),
                    //         h.readonly().text("ОСНОВАНИЕ ЗАКЛЮЧЕНИЯ:", ["reason"] as any),
                    //     ],
                    // }),
                    h.visible(v => v.contract.isEshop).fields("Основание заключения", FormStyle.HORIZONTAL, 1,  {
                        rows: [
                            h.visible(v => v.contract.law === LawType.F44).readonly().text("РЕЕСТРОВЫЙ НОМЕР ЛОТА:", p("contract", "lotRegNumber")),
                        ],
                    }, {
                        rows: [
                            h.readonly().visible(v => v.contract.providerSelectionType === "SINGLE_PROVIDER_PURCHASE").text("ОСНОВАНИЕ ЗАКУПКИ У ЕДИНСТВЕННОГО ПОСТАВЩИКА:", p("contract", "conclusionReason")),
                        ],
                    }, {
                        rows: [
                            h.readonly().visible(v => v.contract.providerSelectionType !== "SINGLE_PROVIDER_PURCHASE").text("ОСНОВАНИЕ ЗАКУПКИ У ЕДИНСТВЕННОГО ПОСТАВЩИКА:", p("contract", "conclusionReason")),
                            h.readonly().visible(v => v.contract.providerSelectionType !== "SINGLE_PROVIDER_PURCHASE" && v.contract.law === LawType.F223).explicit().text("ОСНОВАНИЕ ЗАКЛЮЧЕНИЯ:", () => LawTypeStrings.F223, () => {})
                        ],
                    }, {
                        rows: [
                            h.readonly().visible(v => v.contract.providerSelectionType === "SINGLE_PROVIDER_PURCHASE" && v.contract.law === LawType.F44).explicit().text("ОСНОВАНИЕ ЗАКЛЮЧЕНИЯ:", () => LawTypeStrings.F44, () => {}),
                        ],
                    }, {
                        rows: [
                            h.readonly().visible(v => v.contract.providerSelectionType === "SINGLE_PROVIDER_PURCHASE" && v.contract.law !== LawType.F44).explicit().text("ОСНОВАНИЕ ЗАКЛЮЧЕНИЯ:", () => "Положение о закупке", () => {}),
                        ],
                    }),
                    h.visible(v => !v.contract.isEshop).fields("Основание заключения", FormStyle.HORIZONTAL, 2, {
                        rows: [
                            h.readonly().text("РЕЕСТРОВЫЙ НОМЕР ПРОЦЕДУРЫ:", p("contract", "procedureRegNumber")),
                        ],
                    }, {
                        rows: [
                            h.readonly().text("РЕЕСТРОВЫЙ НОМЕР ЛОТА:", p("contract", "lotRegNumber")),
                        ],
                    }, {
                        rows: [
                            h.readonly().text("ОСНОВАНИЕ ЗАКЛЮЧЕНИЯ:", p("contract", "conclusionReason")),
                        ],
                        columnSpan: 2
                    }),
                    h.fields("Реквизиты сторон", FormStyle.HORIZONTAL, 1, {
                        rows: [
                            h.readonly().text("ЗАКАЗЧИК", p("contract", "customer", "participant", "fullName")),
                            h.raw(OrdererAccounts, p("contract", "customer", "accounts")),
                            h.raw(SectionTitle, p("contract")),
                            h.raw(ResponsiblePerson, p("contract", "responsiblePerson")),
                            h.readonly().text("ПОСТАВЩИК", p("contract", "supplier", "participant", "fullName")),
                            h.raw(VendorAccounts, p("contract", "supplier", "accounts")),
                        ],
                    }),
                    h.fields("Спецификации", FormStyle.HORIZONTAL, 1, {
                        rows: [h.raw(Specifications, p("contract", "specifications"))],
                    }),
                    h.fields("Финансирование", FormStyle.HORIZONTAL, 1, {
                            rows: [h.raw(Sources, ["financialSources"])],
                        }),
                    {
                        type: "FIELDS",
                        name: "Этапы исполнения",
                        component: FieldSection,
                        data: {
                            formStyle: FormStyle.HORIZONTAL,
                            columnCount: 1,
                            columns: [
                                {
                                    rows: [h.raw(Stages, ["stageScopes"])],
                                },
                            ],
                        },
                        allowComponentOverflow: true,
                        customHeaderBottomPadding: '60px',
                        cardButtons: readonly
                            ? []
                            : [
                                  {
                                      eventName: "add-stage",
                                      title: "Добавить этап",
                                  },
                              ],
                    },
                    h.fields(law === LawType.F44 ? "Обеспечение исполнения контракта" : "Обеспечение исполнения договора", FormStyle.HORIZONTAL, 1, {
                        rows: [h.raw(ExecutionProvision, ["executionProvision"])],
                    }),
                    {
                        type: "FIELDS",
                        name: "Документы",
                        component: FieldSection,
                        data: {
                            formStyle: FormStyle.HORIZONTAL,
                            columnCount: 1,
                            columns: [
                                {
                                    rows: [h.raw(Documents, ["documents"])],
                                },
                            ],
                        },
                        cardButtons: [
                            {
                                eventName: "viewDocsHistory",
                                title: "Просмотреть историю изменения документов",
                            },
                        ],
                    },
                    h.visible((r) => !r.contract.isEshop).fields("Экономия", FormStyle.HORIZONTAL, 1, {
                        rows: [h.raw(Economy, ["economy"])],
                    }),
                    h.fields("Бюджетные обязательства", FormStyle.HORIZONTAL, 1, {
                        rows: [h.raw(Liability, ["_"] as never)],
                    }),
                    h.fields("Субподрядчики", FormStyle.HORIZONTAL, 1, {
                        rows: [h.raw(Subcontractors, ["subcontractors"])],
                    }),
                    h.visible(() => !!lotGuaranteeProvision?.used).fields("Обеспечение гарантийных обязательств", FormStyle.HORIZONTAL, 1, {
                        rows: [h.raw(GuaranteeProvision, ["guaranteeProvision"])],
                    }),
                ],
            },
            {
                id: "supplierInteraction",
                title: "Взаимодействие с поставщиком",
                showNavigationHeader: false,
                sections: [
                    {
                        name: "Взаимодействие с поставщиком",
                        type: "UNTYPED",
                        data: undefined,
                        component: ExecutionHistorySection,
                    },
                ],
            },
            {
                id: "execution",
                title: law === LawType.F44 ? "Исполнение контракта" : "Исполнение договора",
                showNavigationHeader: true,
                visible: v => v.contract && v.contract.executionStatus !== ContractExecutionStatus.PENDING,
                sections: [
                    h.fields("Дополнительные соглашения", FormStyle.HORIZONTAL, 1, {
                        rows: [h.raw(AdditionalAgreements, ["additionalConclusions"])],
                    }),
                    h.fields("Исполнение этапов", FormStyle.HORIZONTAL, 1, {
                        rows: [h.raw(ContractStages, ["contract"])],
                    }),
                    h.fields("Претензионная работа", FormStyle.HORIZONTAL, 1, {
                        rows: [h.raw(Claims, ["claimWorks"])],
                    }),
                    h.fields("Гарантийные случаи", FormStyle.HORIZONTAL, 1, {
                        rows: [h.raw(GuaranteeEvents, ["guarranteeEvents"])],
                    }),
                ],
            },
            {
                id: "history",
                title: "История изменений",
                showNavigationHeader: false,
                sections: [
                    {
                        name: "История изменений",
                        type: "UNTYPED",
                        data: undefined,
                        component: ChangeHistorySection,
                    },
                ],
            },
            {
                id: "documents",
                title: law === LawType.F44 ? "Документы контракта" : "Документы договора",
                showNavigationHeader: false,
                sections: [
                    {
                        name: law === LawType.F44 ? "Документы контракта" : "Документы договора",
                        type: "UNTYPED",
                        data: undefined,
                        component: ContractDocsPage,
                        cardButtons: law === "F223" ? [
                            {
                                eventName: "eis-upload",
                                title: "Дополнительная загрузка файлов в ЕИС",
                            },
                        ] : [],
                    },
                ],
            },
        ],
    };
}
