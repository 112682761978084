import {createEvent, createStore, Store} from "effector";
import {ProcedureForm} from "@/views/Procedures2020/view/store";
import {autoSV, createSVEvent, origEv} from "@/storeUtils";
import {Participant} from "@/models";
import {SelectOption} from "@/models/enums";

const marketplaces: SelectOption<number>[] = [
    {key: 0, desc: "ЗАО \"Сбербанк - Автоматизированная система торгов\""},
    {key: 1, desc: "ООО \"Единая электронная торговая площадка\""},
    {key: 2, desc: "ООО \"РТС тендер\""},
];

export interface ProcedureRelatedDataStore {
    /** Справочник специализированных организаций */
    specials: Participant[]

    /** Справочник (электронных) торговых площадок */
    marketplaces: SelectOption<number>[]
}

const replace = createEvent<ProcedureRelatedDataStore>("replace related data");

export const ProcedureRelatedDataEvents = {
    setSpecials: createSVEvent<ProcedureRelatedDataStore>()("specials"),
};

/** Секция "Документация и прием заявок" -> Место и порядок предоставления документации о процедуре закупки */
export const ProcedureRelatedDataStore = {
    createStore: (): Store<ProcedureRelatedDataStore> => {
        return createStore<ProcedureRelatedDataStore>({
            specials: [],
            marketplaces: marketplaces

        })
            .on(replace, (_, l) => l)
            .on(ProcedureRelatedDataEvents.setSpecials[origEv], autoSV)
            ;
    },

    replace: replace,
    observeOnCombinedStore: (combinedStore: Store<ProcedureForm>) => {
    }
};