import {DtoLot, Lot, LotTemplate, PaymentStage, PaymentStageTemplate} from "@/models/ComposedLots";
import {http, IFilterObject} from "@/api/http";
import {convertPaged, IPagedItems, mapPage} from "@/api/Pagination";
import {uuid} from "@/models/parsing";
import {DtoSpecification, Specification, SpecificationTemplate} from "@/models/ComposedLots/specifications";
import {DtoLotDocuments, LotDocument, LotDocuments} from "@/models/ComposedLots/documents";
import {LawType} from "@/models/enums";
import { LotAdditionalInfo } from '@/models/ComposedLots/LotAdditionalInfo';
import { ProcedureStatus } from "@/models/Procedures2020/Procedure";
import { DtoHistoryItem, HistoryItem } from "@/models";

export const getLots = async (year: number, from: number, count: number, filters: IFilterObject): Promise<IPagedItems<Lot>> => {
    return mapPage(Lot, await http.getPagedWithYear("/api/v2/lots", year, {
        from: from,
        count: count,
        filters: filters
    }));
};

export const createLot = async (law: LawType, template: LotTemplate): Promise<Lot> =>
    Lot.fromDto(await http.post("/api/v2/lots?law=" + law, LotTemplate.toDto(template)));

export const updateLot = async (id: uuid, template: LotTemplate, draft?: boolean): Promise<Lot> =>
    Lot.fromDto(await http.put("/api/v2/lots/" + id + (draft ? "/save" : ""), LotTemplate.toDto(template)));

export const approveLot = async (id: uuid): Promise<Lot> =>
    Lot.fromDto(await http.post("/api/v2/lots/" + id + "/approve", null));

export const setLotReady = async (id: uuid): Promise<Lot> =>
    Lot.fromDto(await http.post("/api/v2/lots/" + id + "/setReady", null));

export const cancelLot = async (id: uuid, reasonId: uuid): Promise<Lot> =>
    Lot.fromDto(await http.post(`/api/v2/lots/${id}/cancel?reason=${reasonId}`, null));

export const getLotById = async (id: uuid): Promise<Lot> =>
    Lot.fromDto(await http.get("/api/v2/lots/" + id));

export const deleteLotById = async (id: uuid): Promise<void> =>
    await http.delete("/api/v2/lots/" + id);

export const copyLotById = async (id: uuid): Promise<DtoLot> =>
    await http.post("/api/v2/lots/" + id + "/copy", undefined);

export const getLotSpecifications = async (id: uuid): Promise<Specification[]> =>
    (await http.get(`/api/v2/lots/${id}/specifications`) as DtoSpecification[])
        .map(Specification.fromDto);

export const getLotProductSpecifications = async (id: uuid): Promise<Specification[]> =>
    (await http.get(`/api/v2/lots/${id}/productSpecifications`) as DtoSpecification[])
        .map(Specification.fromDto);

export const updateLotSpecifications = async (id: uuid, templates: SpecificationTemplate[], purchasePerUnit: boolean, paymentStages: PaymentStage[], draft?: boolean): Promise<Specification[]> =>
    (await http.put(`/api/v2/lots/${id}/specifications`, {
        specifications: templates.map(SpecificationTemplate.toDto),
        paymentStages: paymentStages.map(PaymentStageTemplate.toDto),
        purchasePerUnit,
        draft: !!draft,
    }) as DtoSpecification[])
        .map(Specification.fromDto);

// lot specifications crud
export const createSpecification = async (lotId: uuid, template: SpecificationTemplate, product: boolean): Promise<Specification> =>
    http.post(`/api/v2/lots/${lotId}/${product ? "productSpecifications" : "specifications"}`, SpecificationTemplate.toDto(template))
        .then(Specification.fromDto);

export const updateSpecification = async (lotId: uuid, id: uuid, template: SpecificationTemplate, product: boolean): Promise<Specification> =>
    http.put(`/api/v2/lots/${lotId}/${product ? "productSpecifications" : "specifications"}/${id}`, SpecificationTemplate.toDto(template))
        .then(Specification.fromDto);

export const deleteSpecificationById = async (lotId: uuid, id: uuid, product: boolean): Promise<void> =>
    await http.delete(`/api/v2/lots/${lotId}/${product ? "productSpecifications" : "specifications"}/${id}`);


export const updateLotProductSpecifications = async (id: uuid, templates: SpecificationTemplate[]): Promise<Specification[]> =>
    (await http.put(`/api/v2/lots/${id}/productSpecifications`, templates.map(SpecificationTemplate.toDto)) as DtoSpecification[])
        .map(Specification.fromDto);

export const updateLotDocumentSection = async (id: uuid, template: LotDocuments): Promise<LotDocuments> =>
    LotDocuments.fromDto((await http.put(`/api/v2/lots/${id}/documents`, LotDocuments.toDto(template)) as DtoLotDocuments));

export const uploadLotDocument = async (id: uuid, document: LotDocument): Promise<LotDocument> =>
    LotDocument.fromDto(await http.post(`/api/v2/lots/${id}/document`, LotDocument.toDto(document)));

export const updateLotAdditionalInfo = async (id: uuid, info: LotAdditionalInfo): Promise<LotAdditionalInfo> =>
    LotAdditionalInfo.fromDto(await http.put(`/api/v2/lots/${id}/additionalInfo`, LotAdditionalInfo.toDto(info)));

export const getLotHistory = async (id: uuid): Promise<HistoryItem<ProcedureStatus>[]> =>
    (await http.get(`/api/v2/lots/${id}/history`) as DtoHistoryItem<ProcedureStatus>[]).map(a => HistoryItem.fromDto(a));

export const getIncludableLots = async (from: number, count: number, filters: IFilterObject, id?: uuid): Promise<IPagedItems<Lot>> => {
    const path = id ? `/api/v2/lots/${id}/includableLots` : "/api/v2/lots/lotsForAggregating";
    return mapPage(Lot, await http.getPaged(path, {
        from: from,
        count: count,
        filters: { ...filters, }
    }));
};
