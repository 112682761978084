import {UnionStrings} from "@/models/enums";

export type CharacteristicSelectionMode =
    | "NO_SELECTION"
    | "SINGLE_SELECTION"
    | "MULTI_SELECTION";

export const CharacteristicSelectionModeStrings: UnionStrings<CharacteristicSelectionMode> = {
    NO_SELECTION: "Нет выбора",
    SINGLE_SELECTION: "Единственный выбор",
    MULTI_SELECTION: "Множественный выбор"
};