import { IJsonFormattable } from '@/models/IJsonFormattable';
import { IDtoUser } from './json/IDtoUser';
import { Participant } from '.';

export type UserCreate = Omit<UserUpdate, "id">;

export interface UserUpdate {
    id: string

    firstName: string | null
    lastName: string
    middleName: string | null

    role: "USER" | "SUPERUSER"
    username: string
    regNumber: string
    comment?: string

    selectedParticipantId: number
    allowedParticipantIds: number[]
    group?: string | null
}

export type UserExcelResponse = {
    errors: string[]
    totalUsers: number
    totalCreatedUsers: number
    totalErroredUsers: number
};

export class User implements IJsonFormattable<IDtoUser> {
    constructor(
        public id: string,

        public firstName: string | null,
        public lastName: string,
        public middleName: string | null,

        public role: "USER" | "SUPERUSER",
        public status: "ACTIVE" | "DELETED" | "LOCKED",
        public username: string,
        public regNumber: string,

        public selectedParticipant: Participant,
        public allowedParticipants: Participant[],
        public impersonator: User | null,
        public comment?: string | null,
        public group?: string | null,
        public created?: Date | null
    ) {}

    public get fullName() {
        return `${this.lastName} ${this.firstName} ${this.middleName}`;
    }

    public get shortName() {
        const parts = [];
        if (this.firstName) parts.push(this.firstName.substr(0, 1) + '.');
        if (this.middleName) parts.push(this.middleName.substr(0, 1) + '.');

        if (parts.length) {

            return `${this.lastName} ${parts.join('')}`;
        }

        return this.lastName;
    }

    public toJson(): IDtoUser {
        return {
            ...this,
            allowedParticipants: this.allowedParticipants.map(p => p.toJson()),
            selectedParticipant: this.selectedParticipant.toJson(),
            impersonator: this.impersonator ? this.impersonator.toJson() : undefined,
            comment: this.comment ?? undefined,
            created: this.created ? this.created.toISOString() : undefined
        };
    }

    public static fromJson(dto: IDtoUser): User {
        return Object.assign(Object.create(User.prototype), dto, {
            allowedParticipants: dto.allowedParticipants?.map(Participant.fromJson) ?? [],
            selectedParticipant: Participant.fromJson(dto.selectedParticipant),
            impersonator: dto.impersonator ? User.fromJson(dto.impersonator) : null,
            created: dto.created ? new Date(dto.created) : undefined
        });
    }
}
