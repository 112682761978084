import {UnionStrings} from "@/models/enums";

export type ContractType =
    | "GOODS_PROVISION"
    | "WORK_EXECUTION"
    | "SERVICE_PROVISION";

export const ContractTypeStrings: UnionStrings<ContractType> = {
    GOODS_PROVISION: "Поставка товаров",
    SERVICE_PROVISION: "Оказание услуг",
    WORK_EXECUTION: "Выполнение работ",
};
