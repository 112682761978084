import {createEvent, createStore, Store} from "effector";
import {ProcedureProtocol} from "@/models/Procedures2020/Procedure";
import {ProcedureForm} from "@/views/Procedures2020/view/store/index";

export interface ProcedureProtocolsStore {
    procedureProtocols: ProcedureProtocol[]
}

const replace = createEvent<ProcedureProtocolsStore>("replace protocols section");
const setProcedureProtocols = createEvent<ProcedureProtocol[]>();

export const ProcedureProtocolsStore = {
    createStore: (): Store<ProcedureProtocolsStore> => {
        return createStore<ProcedureProtocolsStore>({
            procedureProtocols: []
        })
            .on(replace, (_, p) => p)
            .on(setProcedureProtocols, (state, payload) => ({...state, procedureProtocols: payload}));
    },
    replace: replace,
    setProcedureProtocols: setProcedureProtocols,
    observeOnCombinedStore: (combinedStore: Store<ProcedureForm>) => {
    }
};
