import React, {createContext} from "react";

interface RadioGroupProps {
    disabled?: boolean
    group?: string
}

const genRandName = () =>
    Math.random().toString(36).substring(2, 15) + Math.random().toString(36).substring(2, 15);

export const RadioGroupContext = createContext<RadioGroupProps>({});
export const RadioGroup: React.FC<RadioGroupProps> = x => {
    const group = x.group ?? genRandName();
    return <RadioGroupContext.Provider value={{group, disabled: x.disabled}}>
        {x.children}
    </RadioGroupContext.Provider>;
};