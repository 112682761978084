import { observer } from "mobx-react";
import React, { useState } from "react";
import { TooltipIcon } from "@/components/TooltipIcon";
import { Checkbox, ContainerButtons, FavoriteButton, Pager } from "@/components/eshop";
import { changeId, changeKpgz, changePageSize, changeSubject, goCreate, onSelectItem, searchServices, ServicesFilterStore, ServicesStore, setFavorite, toggleShowInfo, } from "./store";
import styles from "@/components/eshop/ServicesTable.module.css";
import { EshopLink } from "@/components/eshop/EshopLink";
import { FormControlEshopInput } from "@/components/eshop/FormControlEshopInput";
import { SubjectClassSelector } from "@/components/eshop/SubjectClassSelector";
import { Service } from "@/api/Services";
import { SubjectClassFilterStore } from "@/components/eshop/SubjectClassFilterStore";
import router from "@/router";

type ServiceTableProps = {
    data: Service[]
    store: ServicesStore
    filtersStore: ServicesFilterStore
    subjectClassSelector: SubjectClassFilterStore
};

export const ServicesTable = observer((props: ServiceTableProps) => {
    const isSelected = (id: number) => props.store.selectedServices.findIndex(s => s.id === id) !== -1;
    const page = props.store.page;

    return <>
        <div className={styles.offersTable}>
            <TableHeader subjectClassSelector={props.subjectClassSelector} store={props.store} filterStore={props.filtersStore} />
            <div className={styles.tableBodyWrap} id="tableContent">
                {props.data.map(r => <ServiceRow checked={isSelected(r.id)} key={r.id} item={r} />)}
            </div>
        </div>
        <div className={styles.pager}>
            <Pager
                page={{ totalCount: page.totalCount, from: page.from, to: page.to, items: props.data }}
                goTo={searchServices}
                pageSize={props.filtersStore.pageSize}
                changePageSize={v => changePageSize({ pageSize: v })}>
                <ContainerButtons showCreateJointSession={false} disableCreateJointSession onCreateJointSession={() => undefined} onCreateSession={() => goCreate()} disableCreateSession={props.store.selectedServices.length === 0} showCreateContract={false} disableCreateContract={true} showCreateSession={true} />
            </Pager>
        </div>
        <TableInfo store={props.store} />
    </>;
});

const ServiceRow = (props: { item: Service; checked: boolean }) => {
    const [cardFavorite, setCardFavorite] = useState(!!props.item.isFavorite);
    const { item } = props;
    const handleSetFavorite = () => {
        setFavorite({ id: item.id, favorite: !cardFavorite });
        setCardFavorite(!cardFavorite);
    };


    return <ul className={styles.tableRow}>
        <li className={styles.controlContainer}>
            <Checkbox onChange={() => onSelectItem(props.item)} value={props.checked} />
        </li>
        <li className={styles.controlContainer}>
            <FavoriteButton onClick={() => handleSetFavorite()} favorite={cardFavorite} />
        </li>
        <li className={styles.tableCellContent}>
            <EshopLink onClick={() => router.replace(`/reference-info/dict/subject-declarations/${item.id}`)}>
                {item.id}
            </EshopLink>
        </li>
        <li className={styles.tableCellContent}>
            <EshopLink onClick={() => router.replace(`/reference-info/dict/subject-declarations/${item.id}`)}>
                {item.subject}
            </EshopLink>
        </li>
        <li className={styles.tableCellContent}>
            <EshopLink onClick={() => router.replace(`/reference-info/dict/subject-classes/${item.subjectClass.id}`)}>
                {props.item.subjectClass.code} - {props.item.subjectClass.description}
            </EshopLink>
        </li>
    </ul>;

};

const TableHeader = observer((props: { store: ServicesStore; subjectClassSelector: SubjectClassFilterStore; filterStore: ServicesFilterStore }) => {
    const { isSelectedName, isFilterOpened } = props.store;
    return <div className={styles.offersTableHeader} id="tableHeader">
        <ul className={styles.headerContainer}>
            <li className={styles.controlContainerHeader}>
                <Checkbox />
            </li>
            <li className={styles.tableCellContent}>
                <div className={styles.eshopSortButton}>
                    <div className={`${styles.arrowIcon} ${styles.arrowIconUp}`} />
                    <div className={`${styles.arrowIcon}`} />
                </div>
                Идентификатор СПГЗ <TooltipIcon />
            </li>
            <li className={styles.tableCellContent}>
                <div className={styles.eshopSortButton}>
                    <div className={`${styles.arrowIcon} ${styles.arrowIconUp}`} />
                    <div className={`${styles.arrowIcon}`} />
                </div>
                Наименование СПГЗ <TooltipIcon />
            </li>
            <li className={styles.tableCellContent}>
                КПГЗ <TooltipIcon />
            </li>
        </ul>
        {isFilterOpened && <div className={styles.filtersContainer}>
            <div className={styles.filterInput}>
                <FormControlEshopInput
                    onChange={e => isSelectedName ? changeSubject(e.target.value) : changeId(parseInt(e.target.value))}
                    placeholder={isSelectedName ? "Введите наименование СПГЗ" : "Введите идентификатор СПГЗ"} />
            </div>
            <div className={styles.filterInput}>
                <SubjectClassSelector value={props.filterStore.kpgz || null} onSelect={e => changeKpgz(e)} store={props.subjectClassSelector} />
            </div>
        </div>}
    </div>;
});

const TableInfo = observer((props: { store: ServicesStore }) => {
    const { showInfo } = props.store;
    return <div className={styles.tableInfoBlock}>
        <div className={`${styles.openTableInfoIcon} ${!showInfo ? styles.openTableInfoIconClosed : ''}`} onClick={() => toggleShowInfo()} />
        {showInfo && <div className={styles.eshopTableInfo}>
            <div className={styles.warningInfo}>
                <div className={styles.iconTableInfo} /> В данном разделе находятся только работы и услуги
            </div>
        </div>}
    </div>;
});
