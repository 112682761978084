import { http } from "@/api/http";

export type UserRegistrationRequest = {
    name: string
    middleName: string
    lastName: string
    email: string
    phoneNumber: string
    insuranceNumber: string
    certificateNumber: string
    organizationId: string
    department: string
    isHead: boolean
    position: string
    group?: string
};

export const sendRegistrationRequest = async (r: UserRegistrationRequest): Promise<void> => {
    const result = await http.postBypassReady("/api/v2/admin/users/regRequests", r);
    return;
};

export const forgotPasswordAction = async (e: string): Promise<void> => {
    const result = http.postBypassReady(`/api/v2/admin/users/forgot?email=${e}`, undefined);
};