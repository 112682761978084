import React, { useState } from "react";
import { observer } from "mobx-react";
import { FavoriteButton, Checkbox } from "@/components/eshop";
import commonStyles from "@/components/eshop/ProductCard.module.css";
import styles from "./DirectPurchaseCard.module.css";
import { EshopLink } from "@/components/eshop/EshopLink";
import { RubleSign } from "../Products/RubleSign";
import { Purchase } from "@/api/DirectPurchases";
import { DirectPurchasesStore, onSelectItem, setFavorite } from "./store";
import moment from "moment";
import { formatNumber } from "@/NumberFormatting";

export const DirectCard = observer((props: { data: Purchase; store: DirectPurchasesStore }) => {
    const isSelected = (id: string) => props.store.selectedPurchases.findIndex(p => p.id === id) !== -1;
    const datesFormat = 'DD.MM.YYYY';
    const [cardFavorite, setCardFavorite] = useState(!!props.data.isFavorite);

    const handleSetFavorite = () => {
        setFavorite({ id: props.data.id, favorite: !cardFavorite });
        setCardFavorite(!cardFavorite);
    };

    return <div className={`${commonStyles.cardContent} ${props.store.isFilterOpened ? commonStyles.cardContentHalved : ''} ${getBorderColor(props.data.allowContract44, props.data.allowContract223)}`}>
        <div className={commonStyles.buttonsPanel}>
            <FavoriteButton onClick={() => handleSetFavorite()} favorite={cardFavorite} />
            <Checkbox value={isSelected(props.data.id)} onChange={() => onSelectItem(props.data)} />
        </div>
        <img className={commonStyles.tableCardImage} src={`/api/v2/directPurchase/${props.data.id}/image`} alt="picture" />
        <div className={commonStyles.itemDemandedIconContainer}>
            {false && <div className={commonStyles.itemDemandedIcon} />}
        </div>
        <div className={commonStyles.tableCardWrapperInfo}>

            <div className={commonStyles.tableCardWrapperAllInfo}>
                <div className={commonStyles.tableCardInfo}>
                    <a className={commonStyles.cardNameLink} href={`https://zakupki.mos.ru/sku/view/${props.data.offerId}`}>
                        {props.data.offerNumber} | {props.data.name}
                    </a>
                    <div className={commonStyles.cardInfoRow}>
                        Поставщик: <EshopLink withoutOverflow>{props.data.participant.shortName}</EshopLink>
                    </div>
                    <div className={commonStyles.cardInfoRow}>
                        КПГЗ: {props.data.spgz.subjectClass.code} - {props.data.spgz.subjectClass.description}
                    </div>
                    <div className={commonStyles.cardInfoRow}>
                        Время действия: {moment(props.data.startValidDate).format(datesFormat)} - {moment(props.data.endValidDate).format(datesFormat)}
                    </div>
                </div>
                <div className={commonStyles.offersPriceInfo}>
                    <div className={commonStyles.pricesBlock}>
                        <div className={`${commonStyles.priceBlock} ${commonStyles.priceBlockWithBorder}`}>
                            {formatNumber(props.data.priceWithNds)}
                            <RubleSign />
                        </div>
                    </div>
                    <div className={styles.offerQuantity}>{props.data.quantityFrom} - {props.data.quantityTo || <span className={styles.infinityIcon} />}</div>
                    <div className={styles.offerQuantity} title={props.data.packageUnit || undefined}>{props.data.packageUnit}</div>
                </div>
            </div>
        </div>
    </div >;
});

const getBorderColor = (allowContract44: boolean, allowContract223: boolean) => {
    if (allowContract44 && allowContract223) {
        return styles.yellow;
    }
    if (allowContract44) {
        return styles.red;
    }
    if (allowContract223) {
        return styles.blue;
    }
    return '';
};