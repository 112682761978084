






import { RelatedObjectsItem } from '@/models/RelatedObjects';
import { Component, Vue, Prop } from "vue-property-decorator";

import RelatedObject from "./RelatedObject.vue";

@Component({ components: { RelatedObject } })
export default class RelatedObjects extends Vue {
    @Prop({ default: () => [] })
    private readonly items!: RelatedObjectsItem[];
}
