import { http } from "./http";
import { Tariff } from '@/models/Tariff';
import { IDtoTariff } from '@/models/json/IDtoTariff';

export default class {
    public static async getForSubjectDeclaration(subjDeclId: number): Promise<Tariff[]> {
        return await http.get(
            `/api/directory/tariffs/${subjDeclId}`,
            true,
        ).then((x: IDtoTariff[]) => x.map(Tariff.fromJson));
    }
}
