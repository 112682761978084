import React, { ReactNode, useState } from 'react';
import {Button, CheckBox, ContextMenuHolder, Note} from "@/components/primitive";
import {HSpread, VStack} from "@/components/layouts";
import {Table} from "@/components/table";
import {Store} from "effector";
import {PlansState} from "@/views/Plans2020/list/store";
import {useStore} from "effector-react";
import {Plan} from "@/models/Plans/Plan";
import {PlanStatusStrings} from "@/models/Plans/PlanStatus";
import router from "@/router";
import styles from "./Plans.module.css";
import { j } from "@/reactUtils";
import {format} from "date-fns";
import {UnderlinedVueLink} from "@/components/VueLink";

const linkStyle = {textDecoration: "underline", cursor: "pointer"};

type PlanDisplayMode = "actual" | "archived44" | "archived223";

export const Plans = (x: { storeContainer: { store: Store<PlansState> } }) => {
    const state = useStore(x.storeContainer.store);

    const law44Plans = {
        actual: state.plans.filter(x => x.law === "F44"),
        archived: state.archivedPlans.filter(x => x.law === "F44" && x.wasPublished)
    };

    const law223Plans = {
        actual: state.plans.filter(x => x.law === "F223"),
        archived: state.archivedPlans.filter(x => x.law === "F223" && x.wasPublished)
    };

    const [mode, setMode] = useState<PlanDisplayMode>("actual");
    const [displayNotPublished, setNotPublishedDisplay] = useState(false);

    const f44: ReactNode = <>
        <Note preset="title">
            <HSpread alignItems="center">
                <div>Планы-графики закупок 44-ФЗ</div>
                <Button icon="aAdd" disabled={!state.loaded || law44Plans.actual.length > 1}
                        onClick={() => router.push("/plans/2020/new?law=F44")}/>
            </HSpread>
            {mode !== "actual" && <CheckBox value={displayNotPublished}
                                            onChange={() => setNotPublishedDisplay(!displayNotPublished)}>
                Показать неопубликованные версии
            </CheckBox>}
        </Note>
        <Note preset="panel">
            <Table<Plan> dataset={mode === "actual" ? law44Plans.actual :
                    !displayNotPublished ? law44Plans.archived : []} columns={[
                Table.Column("Версия в ЕАИСТ", x => <UnderlinedVueLink href={"/plans/2020/" + x.item.id}>
                    {x.item.orderNumber}
                </UnderlinedVueLink>),
                Table.Column("Период", x => <>{x.item.periodStartYear}-{x.item.periodStartYear + 2}</>),
                Table.Column("Статус", x => <>{PlanStatusStrings[x.item.status]}</>),
                Table.Column("Признак актуальности", x => <>{x.item.isActual ? "Актуален" : "Не актуален"}</>),
                Table.Column("Утвержден", x => <>{x.item.approvalDate ? format(x.item.approvalDate, "dd.MM.yyyy HH:mm") : ""}</>),
                Table.Column("Опубликован", x => <>{x.item.publishDate ? format(x.item.publishDate, "dd.MM.yyyy HH:mm") : ""}</>),
                Table.Column("Версия в ЕИС", x => <>{x.item.orderNumber ?? "Не публиковался"}</>),
                Table.AutoColumn("Действие", x => {
                    const [shown, setShown] = useState(false);

                    return <ContextMenuHolder shown={shown}
                                              hide={() => setShown(false)}
                                              items={[
                                                  ["Просмотр", () => router.push("/plans/2020/" + x.item.id)],
                                              ]}>
                        <Button title="действие" onClick={() => setShown(true)}/>
                    </ContextMenuHolder>;
                }),
            ]}/>
        </Note>
    </>;
    const f223: ReactNode = <>
        <Note preset="title">
            <HSpread alignItems="center">
                <div>Планы закупок 223-ФЗ</div>
                <Button icon="aAdd" disabled={!state.loaded || law223Plans.actual.length > 1}
                        onClick={() => router.push("/plans/2020/new?law=F223")}/>
            </HSpread>
            {mode !== "actual" && <CheckBox value={displayNotPublished}
                                            onChange={() => setNotPublishedDisplay(!displayNotPublished)}>
              Показать неопубликованные версии
            </CheckBox>}
        </Note>
        <Note preset="panel">
            <Table<Plan> dataset={mode === "actual" ? law223Plans.actual :
                !displayNotPublished ? law223Plans.archived : []} columns={[
                Table.Column("Версия в ЕАИСТ", x => <span style={linkStyle}
                                                        onClick={() => router.push("/plans/2020/" + x.item.id)}>
                    {x.item.orderNumber}
                </span>),
                Table.Column("Период", x => <>{x.item.periodStartYear}-{x.item.periodStartYear + 2}</>),
                Table.Column("Статус", x => <>{PlanStatusStrings[x.item.status]}</>),
                Table.Column("Признак актуальности", x => <>{x.item.isActual ? "Актуален" : "Не актуален"}</>),
                Table.Column("Утвержден", x => <>{x.item.approvalDate ? format(x.item.approvalDate, "dd.MM.yyyy HH:mm") : ""}</>),
                Table.Column("Опубликован", x => <>{x.item.publishDate ? format(x.item.publishDate, "dd.MM.yyyy HH:mm") : ""}</>),
                Table.Column("Версия в ЕИС", x => <>{x.item.orderNumber ?? "Не публиковался"}</>),
                Table.AutoColumn("Действие", x => {
                    const [shown, setShown] = useState(false);

                    return <ContextMenuHolder shown={shown}
                                              hide={() => setShown(false)}
                                              items={[
                                                  ["Просмотр", () => router.push("/plans/2020/" + x.item.id)],
                                              ]}>
                        <Button title="действие" onClick={() => setShown(true)}/>
                    </ContextMenuHolder>;
                }),
            ]}/>
        </Note>
    </>;

    return <VStack outerStyle={{margin: "30px"}} spacing="15px">
        <div className={styles.tabs}>
            <div className={j(styles.tab, mode === "actual" ? styles.active : undefined)}
                 onClick={() => {setNotPublishedDisplay(false); setMode("actual")}}>
                Актуальные
            </div>
            <div className={j(styles.tab, mode === "archived44" ? styles.active : undefined)}
                 onClick={() => {setNotPublishedDisplay(false); setMode("archived44")}}>
            Архивные 44-ФЗ
            </div>
            <div className={j(styles.tab, mode === "archived223" ? styles.active : undefined)}
                 onClick={() => {setNotPublishedDisplay(false); setMode("archived223")}}>
            Архивные 223-ФЗ
            </div>
        </div>
        {
            mode === "actual" && <>
                { f44 }
                { f223 }
            </>
        }
        {
            (mode === "archived44") && <>
                { f44 }
            </>
        }
        {
            (mode === "archived223") && <>
                { f223 }
            </>
        }
    </VStack>;
};
