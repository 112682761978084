import {ModalFC} from "@/EventBus";
import {CardModal} from "@/components/modals/CardModal";
import React from "react";
import {Expandee, HStack, VStack} from "@/components/layouts";
import {RoundedButton} from "@/components/primitive/RoundedButton";
import {Table} from "@/components/table";
import {ContractDocument} from "@/models/Contracts";

export const EisUploadFilesModal: ModalFC<{ docs: ContractDocument[] }, void> = x => {
    const bottom: React.ReactNode = <HStack spacing="10px">
        <Expandee />
        <RoundedButton color="blue" title="Выбрать" onClick={() => x.done()} />
        <RoundedButton color="white" title="Закрыть" onClick={() => x.done()} />
    </HStack>;

    return <CardModal title="Дополнительная загрузка файлов в ЕИС"
                      close={x.done}
                      cardContentStyle={{ padding: 0 }}
                      cardHeaderStyle={{ backgroundColor: "white" }}
                      bottom={bottom}
                      width="60%">
        <VStack spacing="10px" outerStyle={{ padding: "20px" }}>
            <div style={{
                background: "#dfe7eb",
                borderRadius: "5px",
                padding: "20px",
            }}>
                <span style={{
                    fontSize: "16px",
                    fontWeight: 300,
                    color: "#4c4c4c"
                }}>Дополнительная загрузка файлов в ЕИС предназначена для отправки обязательных типов документов при первичной регистрации договора и при регистрации внесения изменений в договор.</span>
            </div>
            <Table<ContractDocument> dataset={x.docs} columns={[
                Table.Column("Документ", x => <span>{x.item.file.fileName}</span>),
                Table.AutoColumn("Размер", x => <span>{x.item.file.length / 1000 + " КБ"}</span>),
                Table.AutoColumn("Отправить отдельно", x => <VStack alignItems="center">
                    <input type="checkbox" disabled={x.item.type.name === "Контракт"}/>
                </VStack>)
            ]} />
            { !x.docs.length && <div style={{ height: "150px" }}></div> }
        </VStack>
    </CardModal>;
};
