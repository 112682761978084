import { autoSV, condSV, createSVEvent, extend, origEv } from "@/storeUtils";
import { createEffect, createEvent, createStore, Event, Store } from "effector";
import { Participant, SubjectClass } from "@/models";
import { showModal } from "@/EventBus";
import { PickSubjectClassModal } from "@/modal-views";
import {
    Article15Accordance,
    BankingSupport,
    ContractType,
    ProviderSelectionType,
    SpecialPurchaseType,
    PaymentStage
} from "@/models/ComposedLots";
import Decimal from "decimal.js";
import {LawType, SmpType, SupplyStagePeriodType} from "@/models/enums";
import { AddPaymentStageModal } from "@/modal-views/AddPaymentStageModal";
import { LotForm } from "@/views/ComposedLot/shared/store/index";
import { setInfoChanged } from "@/views/ComposedLot/shared/store/head";
import { ProvisionsStore } from "@/views/ComposedLot/shared/store/provisions";
import { ElectronicExecution } from "@/models/ComposedLots/ElectronicExecution";
import {
    Specification,
    SpecificationTemplate,
    SupplyStage
} from "@/models/ComposedLots/specifications";
import {
    PaymentTermType,
    ReviewDocumentsTermType,
    SendDocumentsTermType,
    SendDocumentsTermTypeStrings
} from "@/models/ComposedLots/PaymentStageTerm";
import {addDays} from "date-fns";
import {LotPlannedPayment} from "@/models/ComposedLots/LotPlannedPayment";
import {LotFinancialSourceUsage} from "@/models/ComposedLots/LotFinancialSourceUsage";
import { reaction } from "mobx";

export interface LotInfo {
    law: LawType
    subject: string
    specialPurchaseType: SpecialPurchaseType
    providerSelectionType?: ProviderSelectionType
    singleProviderReasonId?: number
    singleProvider?: Participant
    article15Accordance: Article15Accordance
    contractType?: ContractType
    smpType: SmpType
    smpPercent?: Decimal
    isGoodsUsed: boolean
    paymentType: "STAGED" | "SINGLE"
    singlePaymentDate?: Date
    contractStartPriceReason?: string
    paymentStages: PaymentStage[]
    lotJoinType: "NO" | "MASTER" | "SLAVE"
    plannedPublishDate?: Date
    isPlannedPublishDatePrecise: boolean
    plannedNotificationPublishYear: number
    contractConclusionYear: number
    isAuditServicesPurchase: boolean
    contractTerm?: Date
    advancePercent?: Decimal
    isRepairRelated: boolean
    bankingSupport: BankingSupport
    subjectClass?: SubjectClass
    creationDate: Date
    aggregatingLotId?: string
    aggregatingLotRegNumber?: number
    electronicExecution: ElectronicExecution
    isGoodsDeliveryByMultipleConsignees?: boolean
    isGoodsDeliveryByRecipientRequest?: boolean
    sendDocumentsTerm?: SendDocumentsTermType
    reviewDocumentsTerm?: ReviewDocumentsTermType
    paymentTerm?: PaymentTermType
}

export const setSubject = createSVEvent<LotInfo>()("subject");
export const setSubjectClass = createSVEvent<LotInfo>()("subjectClass");
export const setSpecialPurchaseType = createSVEvent<LotInfo>()("specialPurchaseType");
export const setProviderSelectionType = createSVEvent<LotInfo>()("providerSelectionType");
export const setArticle15Accordance = createSVEvent<LotInfo>()("article15Accordance");
export const setContractType = createSVEvent<LotInfo>()("contractType");
export const setIsGoodsUsed = createSVEvent<LotInfo>()("isGoodsUsed");
export const setSmpType = createSVEvent<LotInfo>()("smpType");
export const setSmpPercent = createSVEvent<LotInfo>()("smpPercent");
export const setPaymentType = createSVEvent<LotInfo>()("paymentType");
export const setPaymentDate = createSVEvent<LotInfo>()("singlePaymentDate");
export const setPaymentStages = createSVEvent<LotInfo>()("paymentStages");
export const setLotJoinType = createSVEvent<LotInfo>()("lotJoinType");
export const setPlannedPublishDate = createSVEvent<LotInfo>()("plannedPublishDate");
export const setPlannedPublishDatePrecise = createSVEvent<LotInfo>()("isPlannedPublishDatePrecise");
export const setPlannedNotificationPublishYear = createSVEvent<LotInfo>()("plannedNotificationPublishYear");
export const setContractConclusionYear = createSVEvent<LotInfo>()("contractConclusionYear");
export const setIsAuditServicesPurchase = createSVEvent<LotInfo>()("isAuditServicesPurchase");
export const setContractTerm = createSVEvent<LotInfo>()("contractTerm");
export const setAdvancePercent = createSVEvent<LotInfo>()("advancePercent");
export const setIsRepairRelated = createSVEvent<LotInfo>()("isRepairRelated");
export const setBankingSupport = createSVEvent<LotInfo>()("bankingSupport");
export const setSingleProviderReasonId = createSVEvent<LotInfo>()("singleProviderReasonId");
export const setSingleProvider = createSVEvent<LotInfo>()("singleProvider");
export const setContractStartPriceReason = createSVEvent<LotInfo>()("contractStartPriceReason");
export const setElectronicExecution = createSVEvent<LotInfo>()("electronicExecution");
export const setGoodsDeliveryByMultipleConsignees = createSVEvent<LotInfo>()("isGoodsDeliveryByMultipleConsignees");
export const setGoodsDeliveryByRecipientRequest = createSVEvent<LotInfo>()("isGoodsDeliveryByRecipientRequest");
export const setPaymentTerm = createSVEvent<LotInfo>()("paymentTerm");
export const setReviewDocumentsTerm = createSVEvent<LotInfo>()("reviewDocumentsTerm");
export const setSendDocumentsTerm = createSVEvent<LotInfo>()("sendDocumentsTerm");
export const addOrEditPaymentStage = createEvent<{ new: PaymentStage; old?: PaymentStage }>("addOrEditPaymentStage");


export const pickSubjectClass = async (selected?: SubjectClass) => {
    const result = await showModal(PickSubjectClassModal, { selected });
    if (result) {
        setSubjectClass(result);
    }
};

export const deletePaymentStage = createEvent<PaymentStage>("delete payment stage");

const emptyLot: LotInfo = {
    law: LawType.F44,
    subject: "",
    specialPurchaseType: "NO",
    article15Accordance: "NO",
    isGoodsUsed: false,
    smpType: "NO",
    smpPercent: new Decimal(0),
    paymentType: "SINGLE",
    contractStartPriceReason: "В соответствии с требованиями статьи 22 44-ФЗ",
    paymentStages: [],
    lotJoinType: "NO",
    plannedNotificationPublishYear: 2024,
    contractConclusionYear: 2024,
    isAuditServicesPurchase: false,
    isRepairRelated: false,
    bankingSupport: "NO",
    isPlannedPublishDatePrecise: false,
    plannedPublishDate: new Date(),
    creationDate: new Date(),
    electronicExecution: "UNDEFINED",
    sendDocumentsTerm: undefined,
    reviewDocumentsTerm: undefined,
    paymentTerm: undefined
};

const replace = createEvent<LotInfo>("replace lot info");
const contractTypes: ContractType[] = ["GOODS_PROVISION", "WORK_EXECUTION", "SERVICE_PROVISION"];

export const LotInfoStore = {
    createStore: (law: LawType) => {
        const store = createStore({...emptyLot, law})
            .on(replace, (_, l) => l)
            .on(setSubject[origEv], autoSV)
            .on(setSpecialPurchaseType[origEv], autoSV)
            .on(setProviderSelectionType[origEv], autoSV)
            .on(setArticle15Accordance[origEv], autoSV)
            .on(setContractType[origEv], autoSV)
            .on(setIsGoodsUsed[origEv], autoSV)
            .on(setSmpType[origEv], autoSV)
            .on(setSmpPercent[origEv], autoSV)
            .on(setPaymentType[origEv], autoSV)
            .on(setPaymentDate[origEv], condSV(x => x.paymentType === "SINGLE"))
            .on(setPaymentStages[origEv], condSV(x => x.paymentType === "STAGED"))
            .on(setLotJoinType[origEv], autoSV)
            .on(setPlannedPublishDate[origEv], autoSV)
            .on(setPlannedPublishDatePrecise[origEv], autoSV)
            .on(setPlannedNotificationPublishYear[origEv], autoSV)
            .on(setContractConclusionYear[origEv], autoSV)
            .on(setIsAuditServicesPurchase[origEv], autoSV)
            .on(setContractTerm[origEv], autoSV)
            .on(setAdvancePercent[origEv], autoSV)
            .on(setIsRepairRelated[origEv], autoSV)
            .on(setBankingSupport[origEv], autoSV)
            .on(setSingleProviderReasonId[origEv], autoSV)
            .on(setSingleProvider[origEv], autoSV)
            .on(setSubjectClass[origEv], autoSV)
            .on(setContractStartPriceReason[origEv], autoSV)
            .on(setElectronicExecution[origEv], autoSV)
            .on(setGoodsDeliveryByMultipleConsignees[origEv], autoSV)
            .on(setGoodsDeliveryByRecipientRequest[origEv], autoSV)
            .on(addOrEditPaymentStage, (s, p) => {
                if (!p) return s;
                const copy = [...s.paymentStages];
                let insertAt = copy.length;
                let remove = 0;
                if (p.old) {
                    const index = copy.indexOf(p.old);
                    if (index !== -1) {
                        insertAt = index;
                        remove = 1;
                    }
                }
                copy.splice(insertAt, remove, p.new);
                return extend(s, { paymentStages: copy });
            })
            .on(deletePaymentStage, (x, s) =>
                extend(x, { paymentStages: x.paymentStages.filter(ss => ss !== s) }))
            .on(setPaymentTerm[origEv], autoSV)
            .on(setSendDocumentsTerm[origEv], autoSV)
            .on(setReviewDocumentsTerm[origEv], autoSV)
            .on(setPaymentStages[origEv], autoSV);

        const mapReasons: {[key in SpecialPurchaseType]?: number} = {
            SMALL_VOLUME_SIMPLE: 1000004,
            SMALL_VOLUME_CARE: 1000005,
            BUILDING_MAINTENANCE: 1000023,
            BUSINESS_TRIPS: 1000026,
            TEACHING_SERVICE: 1000033,
            SPORT_SERVICE: 1000512,
            SPECIAL_SERVICE: 1000412
        };

        store.watch(setSpecialPurchaseType, (x, s) => {
            switch (s) {
                case "NO":
                    break;
                case "MEDICINES":
                    setProviderSelectionType("E_OFFERS_REQUEST");
                    break;
                default:
                    if (s === "SMALL_VOLUME_SIMPLE" || s === "SMALL_VOLUME_CARE")
                        ProvisionsStore.reset();

                    const reason = mapReasons[s];
                    if (reason)
                        setSingleProviderReasonId(reason);


                    setProviderSelectionType("SINGLE_PROVIDER_PURCHASE");
                    break;
            }
        });

        const mapTermDays: {[key in SendDocumentsTermType | ReviewDocumentsTermType | PaymentTermType]: number} = {
            NORMAL_3_DAYS: 3,
            WORK_3_DAYS: 3,
            WORK_7_DAYS: 7,
            WORK_9_DAYS: 9,
            WORK_30_DAYS: 30,
        };


        store.watch(setProviderSelectionType, x => {
            if (x.providerSelectionType !== "SINGLE_PROVIDER_PURCHASE") {
                if(x.law === LawType.F223 && x.providerSelectionType === "E_AUC") {
                    setSmpType("NO");
                } else {
                    setSmpType("FULL");
                }
            }
        });

        store.watch(setSmpType, (x, t) => {
            switch (t) {
                case "NO":
                    setSmpPercent(new Decimal(0));
                    break;
                case "SUBCONTRACTING":
                    setSmpPercent(new Decimal(1));
                    break;
                case "FULL":
                    setSmpPercent(new Decimal(100));
                    break;
            }
        });

        store.watch(setSubjectClass, (x, t) => {
            if (t) {
                const ix = parseInt(t.code.substring(0, t.code.indexOf('.')), 10) - 1;
                setContractType(contractTypes[ix]);
            }
        });

        store.watch(setContractType, (_, p) => {
            if (p !== "GOODS_PROVISION") {
                setGoodsDeliveryByMultipleConsignees(false);
            }
        });

        store.watch(setGoodsDeliveryByMultipleConsignees, (x) => {
            if (!x.isGoodsDeliveryByMultipleConsignees) {
                setGoodsDeliveryByRecipientRequest(false);
            }
        });

        store.watch(setReviewDocumentsTerm, (x, s) => {
            if (s && x.sendDocumentsTerm && x.paymentTerm){
                const days = mapTermDays[s];
                const sendDays = mapTermDays[x.sendDocumentsTerm];
                const paymentDays = mapTermDays[x.paymentTerm];
                x.paymentStages.map(stage => {
                    switch (stage.supplyStage.type) {
                        case "RELATIVE":
                            stage.paymentDateOffset = stage.supplyStage.endOffset + days + sendDays + paymentDays;
                            break;
                        case "ABSOLUTE":
                            stage.paymentDate = addDays(stage.supplyStage.endDate, days + paymentDays + sendDays);
                            break;
                    }
                });
            }
        });

        store.watch(setSendDocumentsTerm, (x, s) => {
            if (s && x.reviewDocumentsTerm && x.paymentTerm) {
                const days = mapTermDays[s];
                const reviewDays = mapTermDays[x.reviewDocumentsTerm];
                const paymentDays = mapTermDays[x.paymentTerm];
                x.paymentStages.map(stage => {
                    switch (stage.supplyStage.type) {
                        case "RELATIVE":
                            stage.paymentDateOffset = stage.supplyStage.endOffset + days + reviewDays + paymentDays;
                            break;
                        case "ABSOLUTE":
                            stage.paymentDate = addDays(stage.supplyStage.endDate, days + reviewDays + paymentDays);
                            break;
                    }
                });
            }
        });

        store.watch(setPaymentTerm, (x, s) => {
            if (s && x.sendDocumentsTerm && x.reviewDocumentsTerm) {
                const days = mapTermDays[s];
                const reviewDays = mapTermDays[x.reviewDocumentsTerm];
                const sendDays = mapTermDays[x.sendDocumentsTerm];
                x.paymentStages.map(stage => {
                    switch (stage.supplyStage.type) {
                        case "RELATIVE":
                            stage.paymentDateOffset = stage.supplyStage.endOffset + days + reviewDays + sendDays;
                            break;
                        case "ABSOLUTE":
                            stage.paymentDate = addDays(stage.supplyStage.endDate, days + reviewDays + sendDays);
                            break;
                    }
                });
            }
        });

        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        const observeOn: Event<any>[] = [
            setSubject,
            setSpecialPurchaseType,
            setProviderSelectionType,
            setArticle15Accordance,
            setContractType,
            setIsGoodsUsed,
            setSmpType,
            setSmpPercent,
            setPaymentType,
            setPaymentDate,
            setPaymentStages,
            setLotJoinType,
            setPlannedPublishDate,
            setPlannedPublishDatePrecise,
            setPlannedNotificationPublishYear,
            setContractConclusionYear,
            setIsAuditServicesPurchase,
            setContractTerm,
            setAdvancePercent,
            setIsRepairRelated,
            setBankingSupport,
            setSingleProviderReasonId,
            setSingleProvider,
            setSubjectClass,
            setElectronicExecution,
            addOrEditPaymentStage
        ];

        for (const o of observeOn)
            store.watch(o, () => setInfoChanged(true));

        return store;
    },

    replace: replace,
    observeOnCombinedStore: (combinedStore: Store<LotForm>) => {}
};

export const getPaymentStagesTotal = (p: PaymentStage[]) =>
    p.flatMap(it => it.financialSourceUsage).reduce((acc, v) => ({
        advanceAmount: acc.advanceAmount.add(v?.advanceAmount ?? new Decimal(0)),
        paymentAmount: acc.paymentAmount.add(v.paymentAmount ?? new Decimal(0))
    }), { advanceAmount: new Decimal(0), paymentAmount: new Decimal(0) });
