import { ModalFC } from "@/EventBus";
import { CardModal } from "@/components/modals/CardModal";
import { Button, ComboBox } from "@/components/primitive";
import React, { useState } from "react";
import { Expandee, HStack } from "@/components/layouts";

export const DocumentTypeSelectionModal: ModalFC<{title: string; options: string[]}, string> = x => {
    const [selected, setSelected] = useState<string>();

    return <CardModal title={x.title}
                      close={() => x.done(undefined)}
                      bottom={<HStack>
                        <Expandee />
                        <Button title="Выбрать" onClick={() => x.done(selected)} />
                      </HStack>}
                      width="600px">
        <ComboBox options={x.options.map(o => ({ desc: o, key: o }))}
                  value={selected}
                  onChangeNonStrict={setSelected} />
    </CardModal>;
};