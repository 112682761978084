import {
    EditType,
    ITableColumn,
    UIFilter,
    UIFilterType,
    UINumberFilterDataType,
    UIStringFilterComparison
} from './TableAbstractions';
import Decimal from 'decimal.js';
import { EnumStrings, SelectOption } from '@/models/enums';
import { formatDate } from '@/DateFormatting';

export function startsWithFilter(prop: string): UIFilter {
    return {
        property: prop,
        type: UIFilterType.STRING,
        comparisonMode: UIStringFilterComparison.STARTS_WITH
    };
}

export function containsFilter(prop: string): UIFilter {
    return {
        property: prop,
        type: UIFilterType.STRING,
        comparisonMode: UIStringFilterComparison.CONTAINS
    };
}

export function longIntFilter(prop: string): UIFilter {
    return {
        property: prop,
        type: UIFilterType.LONGINT,
        dataType: UINumberFilterDataType.LONGINT
    };
}

export function decimalGteFilter(prop: string, value: Decimal): UIFilter {
    return {
        property: prop,
        type: UIFilterType.RANGE,
        dataType: UINumberFilterDataType.DECIMAL,

        startInclusive: true,
        endInclusive: false,

        explicitEnd: "INFINITE"
    };
}

export function decimalFilter(prop: string): UIFilter {
    return {
        property: prop,
        type: UIFilterType.NUMBER,
        dataType: UINumberFilterDataType.DECIMAL,
    };
}

export function selectFilter(prop: string, enumStrings: EnumStrings, multiselect: boolean): UIFilter {
    return {
        property: prop,
        type: UIFilterType.SELECT,
        multiselect: multiselect,

        options: Object.entries(enumStrings)
            .map(([k, v]) => ({key: k, desc: v}))
    };
}

export function boolFilter(prop: string, falseV: string, trueV: string): UIFilter {
    return {
        property: prop,
        type: UIFilterType.BOOLEAN,

        falseDescription: falseV,
        trueDescription: trueV
    };
}

export function dateFilter(prop: string, withDay = true): UIFilter {
    return {
        property: prop,
        type: UIFilterType.DATE,

        withDay
    };
}

export const hs = <T>(...l: ITableColumn<T>[]) => l;
hs.any = <T>(t: string, g: (_: T, __: number) => unknown, w?: string) => ({
    title: t,
    getter: g,
    size: w
} as ITableColumn<T>);
hs.decimal = <T>(t: string, g: (_: T, __: number) => Decimal | null, w?: string) => ({
    title: t,
    getter: g,
    size: w,
    type: EditType.DECIMAL
} as ITableColumn<T>);
hs.editableDecimal = <T>(t: string, g: (_: T, __: number) => Decimal | null, s: (_: T, __: Decimal) => void, w?: string) => ({
    title: t,
    getter: g,
    setter: s,
    size: w,
    type: EditType.DECIMAL,
    editable: true
} as ITableColumn<T>);
hs.date = <T>(t: string, g: (_: T, __: number) => Date | null, w?: string) => ({
    title: t,
    getter: (x, i) => formatDate(g(x, i), true),
    size: w
} as ITableColumn<T>);
hs.select = <T, TS>(t: string, g: (_: T, __: number) => TS | null, s: (_: T, __: TS) => void, selectOptions: SelectOption<TS>[]) => ({
    title: t,
    getter: g,
    setter: s,
    selectOptions: selectOptions,
    type: EditType.SELECT,
    editable: true
} as ITableColumn<T>);
hs.slot = <T>(t: string, g: (_: T, __: number) => unknown, slot: string, width?: string) => ({
    title: t,
    getter: (x, i) => g(x, i),
    slot,
    size: width
} as ITableColumn<T>);
