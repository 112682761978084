import {StartPriceMethod} from "@/models/enums/StartPriceMethod";
import Decimal from "decimal.js";
import {asDecimal, AutoDto, AutoModel} from "@/models/parsing";

interface StartPriceMD {
    method: StartPriceMethod
    taxPercent?: Decimal
    total: Decimal
    perItem: Decimal
    ratio: Decimal
    justification?: string
}

export type StartPrice = AutoModel<StartPriceMD>;
export type DtoStartPrice = AutoDto<StartPriceMD>;

export const StartPrice = {
    fromDto(obj: DtoStartPrice): StartPrice {
        return {
            method: obj.method,
            justification: obj.justification ?? undefined,
            taxPercent: asDecimal(obj.taxPercent) ?? undefined,
            total: new Decimal(obj.total),
            perItem: new Decimal(obj.perItem),
            ratio: new Decimal(obj.ratio),
        };
    },
    toDto(obj: StartPrice): DtoStartPrice {
        return {
            method: obj.method,
            justification: obj.justification,
            taxPercent: obj.taxPercent?.toString(),
            total: obj.total.toString(),
            perItem: obj.perItem.toString(),
            ratio: obj.ratio.toString(),
        };
    }
};