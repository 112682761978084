import {http} from "@/api/http";
import {NewsArticle, NewsArticleUpdate} from "@/api/News";
import {MediaItem} from "@/api/Media";

export type LoginWizardSlide = {
    id: string
    title: string
    content: string
    mediaItem: MediaItem | null
    index: number
};

export type LoginWizardSlideUpdate = {
    title: string
    content: string
    mediaItemId: string | null
};

export const convertLoginWizardSlideToUpdate = (s: LoginWizardSlide): LoginWizardSlideUpdate => ({
    title: s.title,
    content: s.content,
    mediaItemId: s.mediaItem?.id ?? null
});

export const LoginWizardApi = {
    async findAll(): Promise<LoginWizardSlide[]> {
        const result = await fetch("/api/v2/slides").then(r => r.json());

        return result;
    },
    async create(data: LoginWizardSlideUpdate): Promise<LoginWizardSlide> {
        const result = await http.post("/api/v2/slides", data);

        return result;
    },
    async update(id: string, data: LoginWizardSlideUpdate): Promise<LoginWizardSlide> {
        const result = await http.put("/api/v2/slides/" + id, data);

        return result;
    },
    async moveUp(id: string): Promise<LoginWizardSlide> {
        const result = await http.put(`/api/v2/slides/${id}/down`, undefined);

        return result;
    },
    async moveDown(id: string): Promise<LoginWizardSlide> {
        const result = await http.put(`/api/v2/slides/${id}/up`, undefined);

        return result;
    },
    async remove(id: string): Promise<void> {
        const result = await http.delete("/api/v2/slides/" + id);

        return result;
    },
};