













import CardModal from "@/components/CardModal.vue";
import {ModalResult} from "@/view-models/ModalRequest";
import {Component, Prop, Vue} from "vue-property-decorator";

@Component({ components: { CardModal } })
export default class HintModal extends Vue {
    @Prop() private prop!: { text: string; header: string };

    public reason = "";

    public ok() {
        this.$emit('close-modal', ModalResult.ok(null));
    }
}
