import React, {useState} from "react";
import {SectionProps} from "@/views/Procedures2020/view/store";
import {Card} from "@/components/Card";
import {VStack} from "@/components/layouts";
import {Button, ContextMenuHolder} from "@/components/primitive";
import {useMappedStore} from "@/storeUtils";
import {Column, Table} from "@/components/table";
import {LotStatusStrings} from "@/models/enums";
import {ProcedureLot} from "@/models/Procedures2020/ProcedureLot";
import router from "@/router";
import {UnderlinedVueLink} from "@/components/VueLink";

export const LotsSection: React.FC<SectionProps> = x => {
    const columns: Column<ProcedureLot>[] = [
        Table.AutoColumn("№", a => <div>
            {a.rowIndex + 1}
        </div>),
        Table.Column("Наименование лота", a => <UnderlinedVueLink href={"/plan-objects/composed-lots/" + a.item.lot.id}>
            {a.item.lot.basicInfo.subject}
        </UnderlinedVueLink>, {width: "3fr"}),
        Table.Column("Статус", a => <div>
            {procedureStatus === "TRADE_FINISHED" ? "Закупка завершена" : LotStatusStrings[a.item.lot.status]}
        </div>),
        Table.Column("Контракт", () => <div>
            {/*TODO*/}
        </div>),
        Table.Column("Действие", x => {
            const [shown, setShown] = useState(false);

            return <ContextMenuHolder shown={shown} hide={() => setShown(false)} align="left" items={[
                ["Просмотр", () => router.push("/plan-objects/composed-lots/" + x.item.lot.id)]]}>
                <Button title="действие" onClick={() => setShown(true)}/>
            </ContextMenuHolder>;
        }, { width: "3fr" })
    ];

    const procedureStatus = useMappedStore(x.formStore, x => x.head.status);
    const lots = useMappedStore(x.formStore, x => x.lots.lots);

    return <Card>
        <VStack spacing="15px">
            <Table dataset={lots} columns={columns} />
        </VStack>
    </Card>;
};