// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, "._4H-N2dTAkss1IyJ-m0hhu{\n    position: fixed;\n    background-color: #fff;\n    width: 100%;\n    z-index: 99;\n    height: 100%;\n    left: 0;\n    top: 0;\n    background-color: #00000042;\n    display: flex;\n    align-items: center;\n}\n\n._87nKe8NpfK4HTq2t533G0{\n    overflow: auto;\n}\n\n._147f1n3NDfdlwKyopcra4v{\n    padding-top: 20px;\n}\n\n@media (max-width: 1440px) {\n    .vWt6blI6dl4Bu4T1UKrbL {\n        width: 1060px !important;\n    }\n}\n\n.vWt6blI6dl4Bu4T1UKrbL{\n    max-height: calc(100% - 170px);\n    min-height: 150px;\n    margin: 0 auto;\n    background-color: #fff;\n    width: 1300px;\n    position: relative;\n    padding-bottom: 30px;\n    margin-top: 20px;\n    top: -5px;\n    display: flex;\n    flex-direction: column;\n    justify-content: space-between;\n    border: 1px solid rgba(0,0,0,0.2);\n    border-radius: .3rem;\n}\n\n._2LTJe6D9cXjwYnOzjdTZhx {\n    width: 620px !important;\n    height: 340px !important;\n}\n\n.-LymbwqQvitAKnRsIaFVF {\n    width: 550px !important;\n    height: 150px !important;\n}\n\n.RKCIiYX8IdYxO0qYEDqb2{\n    display: flex;\n    justify-content: flex-end;\n    padding: 16px 30px 0px;\n    border-top: 1px solid #e6e6e6;\n}\n\n._1aC7HY-1n8nTL_Xbv-DdGI{\n    font-size: 18px;\n    font-weight: 400;\n    color: #4c4c4c;\n    border-bottom: 1px solid #e6e6e6;\n    margin: 0px 0px;\n    padding: 0px 30px 20px;\n\n    display: flex;\n    justify-content: space-between;\n}\n\n.Wuql10e9OX52UUNKLsPLD {\n    width: 21px;\n    height: 21px;\n    top: calc(50% - 10.5px - 0px);\n    cursor: pointer;\n    background-position: center;\n    background-image: url(\"https://eaist2-f.mos.ru/eshop/img/icon-close-999.9770e030323c1c0f85a1.svg\");\n    background-size: 15px;\n    z-index: 1;\n    border-radius: 50%;\n    right: 34px;\n}", ""]);
// Exports
exports.locals = {
	"modalWrapper": "_4H-N2dTAkss1IyJ-m0hhu",
	"dialogBodyOveflow": "_87nKe8NpfK4HTq2t533G0",
	"dialogBody": "_147f1n3NDfdlwKyopcra4v",
	"modalContent": "vWt6blI6dl4Bu4T1UKrbL",
	"medium": "_2LTJe6D9cXjwYnOzjdTZhx",
	"small": "-LymbwqQvitAKnRsIaFVF",
	"dialogFooter": "RKCIiYX8IdYxO0qYEDqb2",
	"modalTitle": "_1aC7HY-1n8nTL_Xbv-DdGI",
	"infoClose": "Wuql10e9OX52UUNKLsPLD"
};
module.exports = exports;
