

















































import {IRowConfiguration, ITableColumn, SelectionColumnControl} from "@/components/TableAbstractions";
import EventBus, {showModal} from "@/EventBus";
import {Component, Prop, Vue} from "vue-property-decorator";
import DataTable from "@/components/vue/DataTable.vue";
import AddSupplyStage from "./AddSupplyStage.vue";
import {IFieldProps} from '@/components/vue/form-table/FieldBase';
import {ContractFinancialSource} from '@/models/Contracts';
import {SimpleRow} from '@/components/vue/form-table/FormFields';
import {formatNumber} from '@/NumberFormatting';
import {ContractSpecification} from '@/models/Contracts/ContractSpecification';
import {ContractScope} from './ContractScope';
import AddSpecification from './AddSpecification.vue';
import ContractSpecifications from '../../api/ContractSpecifications';
import SetTaxModal from './SetTaxModal.vue';
import HintModal from './modals/HintModal.vue';
import {CountrySelectModal} from "@/views/Contracts/modals/CountrySelectModal";
import {ContractSpecificationSupplyStage} from "@/models/Contracts/ContractSpecificationSupplyStage";
import {isConcludedEshopContract} from "@/form-declarations/contractStageActions";
import {Specification} from "@/models";

@Component({ components: { DataTable } })
export default class Specifications extends Vue {
    @Prop() public props!: IFieldProps<SimpleRow<ContractScope>>;

    public isActionsLocked = isConcludedEshopContract(this.props.source.contract);
    public selected: {tag: string; items: ContractSpecification[]} = {
      tag: "MULTI",
      items: []
    };

    public async setSchedule() {
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
        const newStages = await EventBus.callModal<any, ContractSpecificationSupplyStage[]>(AddSupplyStage, {
            source: null,
            contract: this.props.source.contract,
            hideVolume: true,
            isBulk: true,
            selectedItems: this.selected.items,
            action: async (x: ContractSpecificationSupplyStage[]) => {
              return await Promise.all(x.map(item => ContractSpecifications.updateStage(item)));
            }
        }) as unknown as ContractSpecificationSupplyStage[];

        if (newStages) {
            newStages.forEach(stage => {
                const specIdx = this.props.source.contract.specifications.findIndex(spec =>{ 
                    return spec.id === stage.specificationId;
                    });

                if(specIdx !== -1) {
                    this.props.source.contract.specifications[specIdx].stages = newStages.filter(
                        x => this.props.source.contract.specifications[specIdx].id === x.specificationId);
                }
            });
        }
    }

    private isSpgzChanged = (specification: ContractSpecification) =>
        specification.spgzChanged || !this.props.source.contract.isEshop;

    public get headers(): ITableColumn<ContractSpecification>[] {
        return [
            {
                title: "№",
                getter: (s, i) => (i + 1).toString(),
                size: "auto"
            },
            {
                title: "СПГЗ",
                getter: s => this.isSpgzChanged(s) ? s.specification.subjectDeclaration.subject : "",
            },
            {
                title: "ОКПД2",
                getter: s => this.isSpgzChanged(s)
                    ? (s.specification.subjectDeclaration?.okpd2.code ?? "") + " " + (s.specification.subjectDeclaration?.okpd2.name ?? "")
                    : "",
            },
            {
                title: "​Объем",
                getter: s => formatNumber(s.actualVolume),
            },
            {
                title: "​Цена за единицу, руб.",
                getter: s => formatNumber(s.actualCost.div(s.actualVolume)),
            },
            {
                title: "​Цена за единицу без НДС, руб.",
                getter: s => formatNumber(s.actualCost.div(s.actualVolume).mul(100 - s.taxPercent()).div(100)),
            },
            {
                title: "​Сумма, руб.",
                getter: s => formatNumber(s.actualCost),
            }
        ];
    }

    public createConfig(): IRowConfiguration<ContractFinancialSource> {
        const that = this;
        
        return {
            selection: {
                showSelectionColumn: true,
                selectionColumnPosition: "left",
                selectionColumnControl: SelectionColumnControl.CHECKBOX,
                selectionColumnHeader: "Выбрать",
                get locked() { return that.props.state !== "ENABLED" },
                predicateAction: "lock"
            },
            get useSlotForActionColumn() { return that.props.state === 'ENABLED' }
        };
    }

    public config = this.createConfig();

    public get dataset(): ContractSpecification[] { return (this.props.row.getter(this.props.source) || []) as ContractSpecification[] }

    public async editSpecification(spec: ContractSpecification) {
        const modalResult = await EventBus.callModal<{ scope: ContractScope; source: ContractSpecification }, ContractSpecification>(AddSpecification, {
            scope: this.props.source,
            source: spec
        });

        const resultSpec = modalResult.getResult();

        if (modalResult.isOk && resultSpec.spgzChanged) {
            spec.spgzChanged = true;
        }
    }

    public async cloneSpecification(spec: ContractSpecification) {
        const newSpec = await ContractSpecifications.clone(spec.id);

        this.props.source.contract.specifications.push(newSpec);

        EventBus.callModal(AddSpecification, {
            scope: this.props.source,
            source: newSpec
        });
    }

    public async deleteSpecification(spec: ContractSpecification) {
        if (!(await EventBus.callModal(HintModal, { header: "Удаление", text: "Вы уверены, что хотите удалить спецификацию?" })).isOk)
            return;

        await ContractSpecifications.delete(spec);

        const ix = this.props.source.contract.specifications.indexOf(spec);
        this.props.source.contract.specifications.splice(ix, 1);
    }

    public setTax() {
        EventBus.callModal(SetTaxModal, this.props.source);
    }

    public async setCountry() {
        const result = await showModal(CountrySelectModal, {});
        if (result) {
            for (const spec of this.props.source.contract.specifications) {
                const updated = Object.assign(ContractSpecification.prototype, spec,{country: result});
                await ContractSpecifications.update(updated);
                this.$set(spec, "country", result);
            }
        }
    }
}
