// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, "._1BuCrAsPoqS1KU7M6pbWHi {\n    border-radius: 3px;\n    border: 1px solid;\n    box-sizing: border-box;\n\n    padding: 10px;\n}\n\n._1vxPiqC9L20fNJAgGr_MHO {\n    background: #F6FAFB;\n    border-color: #EDF6F8;\n    color: black;\n}\n\n.Z_T_464XaPy4BZ40L3oL2 {\n    background: #f2dede;\n    border-color: #ebccd1;\n    color: #a94442;\n    padding: 15px 30px;\n    font-size: 14px;\n}\n\n._3mFm5CMCR77ulQwm5jaj2o {\n    background: #f7f8fa;\n    border-color: transparent #ccd1de #dadfea;\n    padding: 9px 30px;\n    color: #6e7c90;\n    font-size: 30px;\n    font-weight: 400;\n}\n\n._1KUrOaP9u-PeSMMbuIM_AJ {\n    background: #fff;\n    border-color: transparent #ccd1de #a4adbd;\n    padding: 20px 0;\n}\n\n._9PTS4PCXzJxc_SW-w9eRK {\n    background: #fff;\n    border-color: transparent #ccd1de #a4adbd;\n    padding: 20px 30px;\n}\n\n._1DEunNguoBs7v_BHmXF4Xt {\n    color: #8a6d3b;\n    background-color: #fcf8e3;\n    padding: 15px;\n    border: 1px solid transparent;\n    border-radius: 4px;\n}", ""]);
// Exports
exports.locals = {
	"note": "_1BuCrAsPoqS1KU7M6pbWHi",
	"white": "_1vxPiqC9L20fNJAgGr_MHO",
	"error": "Z_T_464XaPy4BZ40L3oL2",
	"title": "_3mFm5CMCR77ulQwm5jaj2o",
	"panel": "_1KUrOaP9u-PeSMMbuIM_AJ",
	"panelPadded": "_9PTS4PCXzJxc_SW-w9eRK",
	"alert": "_1DEunNguoBs7v_BHmXF4Xt"
};
module.exports = exports;
