import React from "react";
import { MonopolyFile } from "@/models/Monopoly";
import { HStack } from "@/components/layouts";
import { Button, Label } from "@/components/primitive";
import { showModal } from "@/EventBus";
import { SelectFileModal } from "@/views/Monopoly/shared/components/FilePicker/SelectFileModal";
import { ConfirmationModal } from "@/views/Contracts/modals/ConfirmationModal";

type FilePickerProps = {
    file?: MonopolyFile
    onChange: (file: MonopolyFile) => void
    onDelete?: (file: MonopolyFile) => void
    disabled?: boolean
};

export const FilePicker = ({ file, onChange, onDelete, disabled }: FilePickerProps) => {
    const select = async () => {
        const newFile = await showModal(SelectFileModal, { monopolyFile: file });

        if (newFile) {
            onChange(newFile);
        }
    };

    const deleteFile = async (file: MonopolyFile) => {
        const accepted = await showModal(ConfirmationModal, {
            title: "Удалить?",
            text: "Вы действительно хотите удалить файл?"
        });

        if (accepted) {
            onDelete?.(file);
        }
    };

    return (
        <HStack outerStyle={{ width: "100%" }}>
            <HStack outerStyle={{ padding: "6px 10px", border: "1px solid #ccc", flex: 1 }}>
                <Label preset="readonlyValue" style={{ textDecoration: "underline" }}>{file?.name}</Label>
            </HStack>
            <Button icon="faSearch" color="white" style={{ borderRadius: 0 }} onClick={select} />
            <Button icon="gCross"
                    color="white"
                    disabled={disabled}
                    style={{ borderRadius: 0 }}
                    onClick={() => file && deleteFile(file)} />
        </HStack>
    );
};
