<template>
    <div class="panel" :class="[isPageHeader ? '' : 'padded']">
        <div class="sections">
            <template v-for="(section, index) in sections">
                <div
                    v-if="section.visible || section.visible === undefined"
                    :key="index"
                    @click="$emit('section-selected', section.anchor, index)"
                    :class="[!forceInactiveSections && section.active ? 'active' : '']"
                >
                    {{ section.title }}
                </div>
            </template>
        </div>
        <div class="actions" v-if="actions.length">
            <x-button
                v-for="(action, index) in actions.filter(x => !x.isRightAligned)"
                :disabled="action.isEnabled && !action.isEnabled(source)"
                :color="action.color || 'green'"
                :key="'lb-' + index"
                :icon="action.icon"
                :title="action.title"
                @click="$emit('action', action.name)"
            />
            <div class="actions-spacing" />
            <x-button
                v-for="(action, index) in actions.filter(x => x.isRightAligned)"
                :disabled="action.isEnabled && !action.isEnabled(source)"
                :color="action.color || 'red'"
                :key="'rb-' + index"
                :icon="action.icon"
                :title="action.title"
                @click="$emit('action', action.name)"
            />
        </div>
    </div>
</template>

<script>
export default {
    name: "NavPanel",
    props: {
        isPageHeader: Boolean,
        forceInactiveSections: Boolean,
        sections: Array,
        actions: Array,
        source: Object,
    },
};
</script>

<style scoped>
.panel {
    display: flex;
    flex-direction: column;
    background: white;

    border-radius: 0 0 3px 3px;
    border-width: 0 0 1px 1px;
    border-style: solid;
    border-color: #bbc1d0;

    padding: 10px 20px;
}

.padded {
    border-width: 0 1px 1px;
    border-style: solid;
    border-color: transparent #ccd1de #bbc1d0;
    border-radius: 3px;
}

.sections {
    display: flex;
    flex-wrap: wrap;
}

.sections > div {
    display: flex;
    align-items: center;

    min-height: 40px;
    box-sizing: border-box;
    padding: 4px 7px;
    border: 1px solid transparent;
    border-radius: 3px;

    min-width: 0;
    max-width: 150px;

    font-size: 12px;
    color: #2a394f;
    user-select: none;
    cursor: pointer;

    margin-right: 10px;
}

.sections > div:hover,
.sections > div.active {
    background: #d5dae6;
    border-color: #aaaeb7 #c7cbd7 #d5dae6;
}

.actions {
    display: flex;

    border-top: 1px solid #ccc;
    margin-top: 10px;
    padding-top: 10px;
}

.actions-spacing {
    flex-grow: 1;
}
</style>
