import React, {useState} from 'react';
import {Button, ComboBox, DecimalBox, Error as ErrorComponent, Label, Note} from "@/components/primitive";
import {HSpread, HStack, VStack} from "@/components/layouts";
import {
    Table,
    useStoredRemoteDataset
} from "@/components/table";
import {createPlan} from "@/api/Plans2020";
import router from "@/router";
import {Field, FieldGrid, FieldInset} from "@/components/form/Field";
import {range} from "@/arrayUtils";
import Decimal from "decimal.js";
import {FileInput} from "@/components/primitive/FileInput";
import {LawType, LawTypeStrings} from "@/models/enums";
import {Store} from "effector";
import {PlansState} from "@/views/Plans2020/create/store";
import {useMappedStore} from "@/storeUtils";
import {LotsTable} from "@/views/Plans2020/shared/LotsTable";
import {useErrorHandler} from "@/reactUtils";

const periodOptions = range(2024, 2050, 1, x => ({ key: x, desc: `${x}-${x + 2}`}));
const yearOptions = range(2024, 2050, 1, x => ({ key: x, desc: x + ""}));

export const CreatePlan = (x: {law: LawType; storeContainer: { store: Store<PlansState> }}) => {
    const [period, setPeriod] = useState(2024);
    const [yearlyValue, setYearlyValue] = useState<Decimal | undefined>();

    const storedRds = useMappedStore(x.storeContainer.store, x => x.lotsDataset);
    const rds = useStoredRemoteDataset(storedRds);

    const yearSelectOptions = x.law === "F44" ? periodOptions : yearOptions;
    const yearSelectTitle = x.law === "F44" ? "Период" : "Год";
    const yearlyValueTitle = x.law === "F44"
        ? "Совокупный годовой объем закупок, определенный в соответствии с п. 16 статьи 3 44-ФЗ"
        : "Совокупный годовой объем закупок";

    const [error, makeSafe] = useErrorHandler();

    const create = makeSafe(async () => {
        if (yearlyValue === undefined || yearlyValue.isZero()) {
            throw new Error("Необходимо указать cовокупный годовой объем закупок");
        }

        const plan = await createPlan(x.law, period);
        router.replace("/plans/2020/");
    });

    return <VStack outerStyle={{margin: "30px"}} spacing="15px">
        <ErrorComponent object={error}/>
        <Note preset="title">{
            x.law === "F44" ? "Новый план-график закупок" : "Новый план закупок"
        }</Note>
        <Note preset="title">
            <HSpread>
                <Button icon="aSave" onClick={create}/>
                <Button color="red" icon="aClose" onClick={() => router.resetTo("/plans/2020")}/>
            </HSpread>
        </Note>
        <Note preset="panelPadded">
            <FieldGrid>
                <Field title="Закон-основание">
                    <Label>{LawTypeStrings[x.law]}</Label>
                </Field>
                <Field title={yearSelectTitle}>
                    <ComboBox options={yearSelectOptions} value={period} onChange={setPeriod} style={{maxWidth: "200px"}}/>
                </Field>
                <Field title={yearlyValueTitle}>
                    <HStack spacing="15px" alignItems="center">
                        <DecimalBox value={yearlyValue} onChange={setYearlyValue} style={{maxWidth: "200px"}}/>
                        <Label>Российский рубль</Label>
                    </HStack>
                </Field>
                {
                    x.law === "F223" && <>
                        <Field title="Совокупный годовой стоимостной объём закупок, планируемых к осуществлению:">
                            <Label style={{ textAlign: "right", marginRight: "150px" }}>0,00 Российский рубль</Label>
                        </Field>
                        <Field title="Объём закупок, исключённых из расчёта совокупного годового стоимостного объёма:">
                            <Label style={{ textAlign: "right", marginRight: "150px" }}>0,00 Российский рубль</Label>
                        </Field>
                        <FieldInset>
                            <Table dataset={["item?"]} hideHeaders columns={[
                                Table.Column("", () => <>Планируемый объём закупок у субъектов МСП:</>, { width: "4fr"}),
                                Table.Column("", () => <>0,00</>),
                                Table.Column("", () => <><b>0,00 %</b></>)
                            ]}/>
                        </FieldInset>
                        <Field title="Совокупный годовой стоимостной объём договоров:">
                            <Label style={{ textAlign: "right", marginRight: "150px" }}>0,00 Российский рубль</Label>
                        </Field>
                        <FieldInset>
                            <Table dataset={[false, true]} hideHeaders columns={[
                                Table.Column("", x => x.item
                                    ? <>Годовой объём закупок только среди субъектов МСП:</>
                                    : <>Годовой объём закупок у субъектов МСП:</>, { width: "4fr"}),
                                Table.Column("", () => <>0,00</>),
                                Table.Column("", () => <><b>0,00 %</b></>)
                            ]}/>
                        </FieldInset>
                        <Field title="Описание изменений, внесенных в план закупок">
                            <FileInput onChange={() => {}}/>
                        </Field>
                    </>
                }
            </FieldGrid>
        </Note>
        <LotsTable rds={rds} top={
            x.law === "F44" && <Note preset="alert" style={{ marginBottom: "15px" }}>
                Лоты, подлежащие экспертизе НМЦ/ПСД, будут включены в состав плана-графика закупок после получения экспертного заключения или подтверждения прохождения экспертизы ПСД
            </Note>
        } />
    </VStack>;
};
