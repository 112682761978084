// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".nlXi3eJ36oSrH_9tP6B7y {\n    opacity: 0;\n    position: absolute;\n    right: 0;\n    bottom: 0;\n    top: 0;\n    left: 0;\n    display: flex;\n    align-items: center;\n    justify-content: center;\n    backdrop-filter: blur(5px);\n    background: rgba(0,0,0,0);\n    padding: 10px;\n\n    transition: opacity 0.2s ease;\n}\n\n.nlXi3eJ36oSrH_9tP6B7y > input {\n    top: 0;bottom: 0;left: 0;right: 0;\n    position: absolute;\n    opacity: 0;\n\n    cursor: pointer;\n}\n\n.nlXi3eJ36oSrH_9tP6B7y:hover {\n    opacity: 1;\n    visibility: visible;\n    background: rgba(0,0,0,0.2);\n}\n\n._3MZOmcVZl9VIqWClNVo8-S {\n    position: relative;\n}\n\n._3LxVdtPAyaXAt92aETxNgL {\n    position: absolute;\n    top: calc(50% + 45px);\n    margin: 0 auto;\n\n    font-family: GothamPro;\n    font-size: 24px;\n    color: white;\n    font-weight: 400;\n}", ""]);
// Exports
exports.locals = {
	"uploadBg": "nlXi3eJ36oSrH_9tP6B7y",
	"uploadButton": "_3MZOmcVZl9VIqWClNVo8-S",
	"uploadLabel": "_3LxVdtPAyaXAt92aETxNgL"
};
module.exports = exports;
