// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, "._3QgQrX6GhtB-9zWgW8MVr2 {\n    position: relative;\n}\n\n._3XuUT_ALfelAWUWUQqUyM0 {\n    position: absolute;\n\n    top: 5px;\n    background: white;\n    box-shadow: 1px 1px 3px 0 #7e7e7e;\n    border: 1px solid rgba(0, 0, 0, .15);\n    border-radius: 4px;\n    padding: 5px 0;\n\n    display: flex;\n    flex-direction: column;\n    align-items: stretch;\n\n    z-index: var(--z-index-max-below-modal);\n    user-select: none;\n}\n\n._3XuUT_ALfelAWUWUQqUyM0:focus {\n    outline: none;\n}\n\n._1qA4kMATRhzNUIQ2dFDCti {\n    margin: 5px 0;\n    height: 1px;\n    background: #e5e5e5;\n}\n\n._1hnycGJn4_8z2ad0ScCcJY {\n    padding: 7px 20px;\n    font-size: 12px;\n    font-family: 'Roboto', sans-serif;\n    font-weight: 500;\n\n    cursor: pointer;\n}\n\n._1hnycGJn4_8z2ad0ScCcJY:hover {\n    background: #e8f0f3;\n}", ""]);
// Exports
exports.locals = {
	"holder": "_3QgQrX6GhtB-9zWgW8MVr2",
	"menu": "_3XuUT_ALfelAWUWUQqUyM0",
	"sep": "_1qA4kMATRhzNUIQ2dFDCti",
	"item": "_1hnycGJn4_8z2ad0ScCcJY"
};
module.exports = exports;
