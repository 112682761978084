import Decimal from "decimal.js";
import { PrecisionMode } from './components/TableAbstractions';

export const format = new Intl.NumberFormat("ru-ru", { minimumFractionDigits: 2, maximumFractionDigits: 2 });

function manualFormat(decimal: Decimal, nanAsZero: boolean, decimalPlaces: number = 2): string {
    if (nanAsZero && !decimal.isFinite()) return "0,00";

    if (decimalPlaces === 0) {
        return decimal.toNumber().toFixed(0);
    }

    const fixed = decimal.toFixed(decimalPlaces);

    const [head, rest] = fixed.split(".");
    const lte = fixed[0] === "-";
    let firstBlkLen = (head.length - (lte ? 1 : 0)) % 3;
    if (firstBlkLen > 0 && lte) {
        firstBlkLen++;
    }

    const regex = /-?\d{3}/g;
    const headPart = head.substr(0, firstBlkLen);
    const parts = headPart.length ? [headPart] : [];
    const remaining = head.substr(firstBlkLen);

    do {
        const match = regex.exec(remaining);
        if (match === null) {
            break;
        }

        parts.push(match[0]);
    } while (true);

    return parts.join(" ") + "," + rest;
}

export function formatNumber(num: number | Decimal | string, nanAsZero = true, decimalPlaces = 2) {
    if (typeof num === "string") {
        try {
            const decimal = new Decimal(num);
            return manualFormat(decimal, nanAsZero, decimalPlaces);
        } catch {
            return num;
        }
    } else if (typeof num === "number") {
        return manualFormat(new Decimal(num), nanAsZero, decimalPlaces); // format.format(num)
    } else if (num instanceof Decimal) {
        return manualFormat(num, nanAsZero, decimalPlaces);
    } else {
        return "?number?";
    }
}

const trimRegex = /(?:\.0*|(\.[1-9]+)0+)$/;

export function stringifyNumber(num: Decimal, precision: number, mode: PrecisionMode = "FIXED") {
    switch (mode) {
        case "FIXED":
            const fixed = num.toFixed(precision);

            return fixed.replace(trimRegex, (_, r) => r || "");
        case "INITIAL":
            const str = num.toString().replace(trimRegex, (_, r) => r || "");
            const ix = str.indexOf(".") === -1 ? str.indexOf(",") : str.indexOf(".");
            const reqd = ix === -1 ? precision : Math.max(0, precision - (str.length - ix - 1));

            return ix === -1 ? str + "." + "0".repeat(reqd) : str + "0".repeat(reqd);
    }
}
