import React, {useState} from "react";
import {Card} from "@/components/Card";
import {Button, Note} from "@/components/primitive";
import {WebinarsApi} from "@/api/Webinars";
import {Expandee} from "@/components/layouts";

export const ChatAdminSection = () => {
    const [isLoggedIn, setIsLoggedIn] = useState(false);

    const title = <div style={{ display: "flex", gap: "20px" }}>
        <span>Модерация чата трансляции</span>
        <Expandee />
        { !isLoggedIn && <Button title="Войти в чат" onClick={() => WebinarsApi.ospAuth().then(() => setIsLoggedIn(true))} /> }
    </div>;

    return <Card title={title}>
        {
            isLoggedIn
                ? <iframe style={{ width: "100%", height: "800px" }} src={`${window.location.origin}/webinars/stream?adminui=true`}/>
                : <Note preset="alert">
                    {`Чтобы приступить к просмотру чата нажмите кнопку "Войти в чат"`}
                </Note>
        }
    </Card>;
};
