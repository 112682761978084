import { Decimal } from "decimal.js";
import { IJsonFormattable } from '@/models/IJsonFormattable';
import { ObjectStatus } from '@/models/enums';
import { ContractDocument } from './ContractDocument';
import { ContractSpecification } from './ContractSpecification';
import { IDtoContractStageExecutionInfo, IDtoContractStageExecutionInfoCreationDto } from '../json/Contracts/IDtoContractStageExecutionInfo';
import {ShortContractObligation} from "@/models/Contracts/ShortContractObligation";

export declare interface ExecutionInfoSpecification {
    address: string | null
    createdAt: Date
    specification: ContractSpecification
    volume: Decimal
    cost: Decimal
    eisNumber: string | null
    country: string | null // Страна происхождения
    registrationCountry: string | null // Страна регистрации производителя
}

export class ContractStageExecutionInfo implements IJsonFormattable<IDtoContractStageExecutionInfo> {
    constructor(
        public id: string,
        public createdAt: Date,
        public status: ObjectStatus,
        public documents: ContractDocument[],
        public specifications: ExecutionInfoSpecification[],
        public primaryDocumentId: string,
        public contractObligation: ShortContractObligation | null
    ) {}

    public toJson(): IDtoContractStageExecutionInfo {
        return {
            id: this.id,
            status: this.status,
            createdAt: this.createdAt && this.createdAt.toISOString(),
            documents: this.documents.map(x => x.toJson()),
            specifications: this.specifications.map(x => ({
                address: x.address,
                createdAt: x.createdAt && x.createdAt.toISOString(),
                specification: x.specification.toJson(),
                volume: x.volume.isNaN() ? '0' : x.volume.toString(),
                cost: x.cost.isNaN() ? '0' : x.cost.toString(),
                eisNumber: x.eisNumber,
                country: x.country,
                registrationCountry: x.registrationCountry
            })),
            primaryDocumentId: this.primaryDocumentId,
            contractObligation: this.contractObligation ? ShortContractObligation.toDto(this.contractObligation) : null
        };
    }

    public static fromJson(dto: IDtoContractStageExecutionInfo): ContractStageExecutionInfo {
        return Object.assign(Object.create(ContractStageExecutionInfo.prototype), {
            id: dto.id,
            status: dto.status,
            createdAt: new Date(dto.createdAt),
            documents: dto.documents.map(x => ContractDocument.fromJson(x)),
            specifications: dto.specifications.map(x => ({
                address: x.address,
                createdAt: new Date(x.createdAt),
                specification: ContractSpecification.fromJson(x.specification),
                volume: new Decimal(x?.volume ?? 0),
                cost: new Decimal(x?.cost ?? 0),
                country: x.country,
                eisNumber: x.eisNumber,
                registrationCountry: x.registrationCountry
            })),
            primaryDocumentId: dto.primaryDocumentId,
            contractObligation: dto.contractObligation ?  ShortContractObligation.fromDto(dto.contractObligation) : null
        });
    }
}

export class ContractStageExecutionInfoCreationDto implements IJsonFormattable<IDtoContractStageExecutionInfoCreationDto> {
    constructor(
        public documents: ContractDocument[],
        public specifications: ExecutionInfoSpecification[],
        public primaryDocumentIndex: number,
    ) {}

    public toJson(): IDtoContractStageExecutionInfoCreationDto {
        return {
            documents: this.documents.map(x => x.toJson()),
            specifications: this.specifications.map(x => ({
                address: x.address,
                createdAt: x.createdAt && x.createdAt.toISOString(),
                specification: x.specification.toJson(),
                volume: x.volume.isNaN() ? '0' : x.volume.toString(),
                cost: x.cost.isNaN() ? '0' : x.cost.toString(),
                country: x.country,
                eisNumber: x.eisNumber,
                registrationCountry: x.registrationCountry
            })),
            primaryDocumentIndex: this.primaryDocumentIndex,
        };
    }

    public static fromJson(dto: IDtoContractStageExecutionInfoCreationDto): ContractStageExecutionInfoCreationDto {
        return Object.assign(Object.create(ContractStageExecutionInfoCreationDto.prototype), {
            documents: dto.documents.map(x => ContractDocument.fromJson(x)),
            specifications: dto.specifications.map(x => ({
                createdAt: new Date(x.createdAt),
                specification: ContractSpecification.fromJson(x.specification),
                volume: new Decimal(x?.volume ?? 0),
                cost: new Decimal(x?.cost ?? 0),
                country: x.country,
                eisNumber: x.eisNumber,
                registrationCountry: x.registrationCountry
            })),
            primaryDocumentIndex: dto.primaryDocumentIndex,
        });
    }
}
