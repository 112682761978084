var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{class:[
        _vm.realIcon && _vm.realIcon.type === 'IMAGE' ? '' : 'btn',
        _vm.loading ? 'white' : _vm.color,
        _vm.nomargin ? null : (_vm.orientation === 'VERTICAL' ? 'v' : 'h'),
        _vm.realIcon && _vm.realIcon.type === 'CLASS' ? 'btn-' + _vm.realIcon.name : '',
        _vm.realIcon && _vm.realIcon.type === 'CLASS' && _vm.title ? 'btn-noleftpad' : '',
        _vm.disabled || _vm.loading ? 'btn-disabled' : '' ],on:{"click":_vm.onClick}},[(_vm.realIcon && _vm.realIcon.type === 'CLASS' && !_vm.title)?_c('div',{staticClass:"bg-host bg-host-standalone"}):_vm._e(),(_vm.realIcon && _vm.realIcon.type === 'FONT')?_c('fa-icon',{staticClass:"fa",attrs:{"icon":_vm.realIcon.name}}):_vm._e(),(_vm.realIcon && _vm.realIcon.type === 'IMAGE')?_c('div',{class:'btn-' + _vm.realIcon.name}):_vm._e(),(_vm.realIcon && _vm.realIcon.type === 'GLYPH')?_c('div',{staticClass:"btn-glyphicon",style:({
            fontFamily: _vm.glyphicons[_vm.realIcon.name].font, 
            fontSize: _vm.glyphicons[_vm.realIcon.name].size, 
            marginLeft: _vm.glyphicons[_vm.realIcon.name].margin,
            marginRight: _vm.glyphicons[_vm.realIcon.name].margin
        })},[_vm._v(_vm._s(_vm.glyphicons[_vm.realIcon.name].char))]):_vm._e(),(_vm.title)?_c('div',{staticClass:"text-host"},[(_vm.realIcon && _vm.realIcon.type === 'CLASS')?_c('div',{staticClass:"bg-host"}):_vm._e(),_vm._v(" "+_vm._s(_vm.title)+" ")]):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }