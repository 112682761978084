import React, { useState } from "react";
import { Expandee, HStack, VStack } from "@/components/layouts";
import { Button, Note } from "@/components/primitive";
import { Column, FilterColumn, FilteredSelectionTable, useStoredRemoteDataset } from "@/components/table";
import { Provision } from "@/models/Provision/Provision";
import { ProvisionListFilters, ProvisionListStore } from "@/views/Provision/list/store";
import { useStore } from "effector-react";
import { UnderlinedVueLink } from "@/components/VueLink";

type ProvisionListProps = {
    storeComponent: {
        storeContainer: ProvisionListStore
    }
};

export const ProvisionList = ({ storeComponent: { storeContainer } }: ProvisionListProps) => {
    const { provisionsSrd } = useStore(storeContainer.store);
    const provisions = useStoredRemoteDataset(provisionsSrd);

    const [isFiltersVisible, setIsFiltersVisible] = useState<boolean>(true);
    const toggleIsFiltersVisible = () => setIsFiltersVisible(is => !is);

    const columns: Column<Provision>[] = [
        FilterColumn<Provision, ProvisionListFilters>({
            header: "Заказчик",
            filter: {
                key: "customer",
                type: "string"
            },
            cell: ({ item }) => (
                <UnderlinedVueLink href={`/provision/${item.id}`}>
                    <div style={{ textTransform: "uppercase" }}>{item.customer.shortName}</div>
                </UnderlinedVueLink>
            )
        }),
        FilterColumn<Provision, ProvisionListFilters>({
            header: "Статус",
            filter: {
                key: "status",
                type: "string"
            },
            cell: ({ item }) => (
                <>{item.status}</>
            )
        })
    ];

    return (
        <VStack spacing="15px" outerStyle={{ margin: "30px" }}>
            <Note preset="title">
                <HStack spacing="10px" alignItems="center">
                    <div>Положения о закупках</div>
                    <Expandee />
                    <Button icon="aAdd" onClick={() => {}} />
                    <Button icon="aFilter" onClick={toggleIsFiltersVisible} />
                </HStack>
            </Note>
            <Note preset="panel">
                <VStack spacing="15px">
                    <FilteredSelectionTable<Provision, ProvisionListFilters>
                        columns={columns}
                        dataset={provisions.dataset}
                        mode="single"
                        selectorPosition="hidden"
                        isFiltersVisible={isFiltersVisible}
                        filterStore={provisions.filterStore}
                    />
                </VStack>
            </Note>
        </VStack>
    );
};
