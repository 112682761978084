import React from "react";
import { SectionProps } from '../store';
import { Card } from '@/components/Card';
import { useMappedStore } from '@/storeUtils';
import {Field, Fields} from "@/components/form/Field";
import {ComboBox, Note} from "@/components/primitive";
import {toSelectOptions} from "@/models/enums";
import {EditReasonTypeStrings} from "@/models/enums/EditReasonType";
import {setEditReason} from "@/views/ComposedLot/shared/store/head";
import {calculateLotVisibleStatus} from "@/models/ComposedLots";


export const EditReasonSection: React.FC<SectionProps> = x => {
    const editReason = useMappedStore(x.formStore, x => x.head.editReason);
    const lotInstance = useMappedStore(x.formStore, s => s.head.instance);

    const editing = x.formAction === "editing";

    const needPublishedEditNotice = editing && lotInstance && (calculateLotVisibleStatus(lotInstance) === "PUBLISHED_IN_PLAN");

    return <>
        {
            needPublishedEditNotice &&
            <Note preset={"alert"} style={{marginBottom: "10px"}}>
                {`Данный лот находится в плане-графике. Изменение атрибутов планирования изменит статуса лота на "Не
                    утвержден".`}
            </Note>
        }
        <Card>
            <Fields orientation={"horizontal"}>
                <Field title="Причина изменения опубликованного лота" required preset={"medium"}>
                    <ComboBox options={toSelectOptions(EditReasonTypeStrings)} onChange={setEditReason} value={editReason}/>
                </Field>
            </Fields>
        </Card>
        </>;
};