




import {Component, Vue} from "vue-property-decorator";
import {createCustomersStore} from "@/views/ReferenceInfo/Customers/list/store";
import {Customers} from "@/views/ReferenceInfo/Customers/list/Customers";

@Component({ components: { komponent: Customers as unknown as typeof Vue } })
export default class CustomersVueStab extends Vue {
  public storeContainer = Object.freeze({ store: createCustomersStore() });
}
