import React from "react";
import { Card } from "@/components/Card";
import { SectionProps } from "@/views/ComposedLot/shared/store";
import { HStack, VStack } from "@/components/layouts";
import { Table } from "@/components/table/Table";
import { useMappedStore } from "@/storeUtils";
import moment from "moment";
import { HistoryItem } from "@/models";

export const ChangeHistorySection: React.FC<Partial<SectionProps>> = x => {
    const cardHeader = <HStack>
        <span>История изменений</span>
    </HStack>;
    const historyItems = x?.formStore ? useMappedStore(x.formStore, x => x.historyStore.historyItems) : [];


    return <Card hasCollapseButton title={cardHeader}>
        <VStack spacing={"15px"}>
            <Table<HistoryItem> dataset={historyItems} columns={[
                Table.AutoColumn("Пользователь", x => <>{x.item.user.fullName}</>),
                Table.AutoColumn("Дата и время изменения", x => <>{moment(x.item.date).format("DD.MM.YYYY HH:mm")}</>),
                Table.AutoColumn("Статус", x => <>{x.item.status}</>),
            ]}/>
        </VStack>
    </Card>;
};
