// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".h1PNMlrZ2xNNO0xTxCPyT {\n    padding: 20px 30px 10px 60px;\n    border-bottom: 2px solid rgba(0,0,0,0.1);\n\n    justify-content: space-between;\n    justify-items: center;\n    align-items: center;\n}\n\n._3aWRKwSEitS00gXY9HNyr3 {\n    color: #4c4c4c;\n    line-height: 24px;\n    font-size: 24px;\n    display: flex;\n    justify-content: flex-start;\n    align-items: flex-end;\n}\n\n._1y-0pcWzwDZkpjEh9OLytw{\n    display: flex;\n    margin-top: 15px;\n    overflow-x: hidden;\n}\n\n._1z0qiWVodUbDZBHH-vVx3T{\n    border-style: none none dotted none;\n    margin: 2px 15px 2px 4px;\n    white-space: nowrap;\n    cursor: pointer;\n    font-weight: 300;\n}\n\n._1z0qiWVodUbDZBHH-vVx3T:hover, menuOptionSelected{\n    color: #4db2ff;\n    border-color: #4db2ff;\n    transition: all .2s ease-in;\n    font-weight: 300;\n}\n\n._1hiMC61gTNij-Tq50UpSWN {\n    border-style: none none dotted none;\n    margin: 2px 15px 2px 4px;\n    white-space: nowrap;\n    cursor: pointer;\n    color: #4db2ff;\n}\n\n._1hiMC61gTNij-Tq50UpSWN:hover, menuOptionSelected{\n    color: #4db2ff;\n    border-color: #4db2ff;\n    transition: all .2s ease-in;\n}\n\n._7-l_CBWKQw03mO7u8AU1r{\n    background-color: #80dd7e;\n    font-size: 11px;\n    color: white;\n    text-transform: uppercase;\n    padding: 0 5px;\n    margin-left: 5px;\n}\n\n._3QQMEfyAqe7juZA4IRS1jo{\n    background-color: #e2e7eb;\n    color: #4c4c4c;\n}\n\n._36X9aSno8X9tf3azOh_0l1{\n    background-color: #ff6666;\n}", ""]);
// Exports
exports.locals = {
	"headerContainer": "h1PNMlrZ2xNNO0xTxCPyT",
	"pageHeader": "_3aWRKwSEitS00gXY9HNyr3",
	"sessionsBreadcrumbs": "_1y-0pcWzwDZkpjEh9OLytw",
	"menuOption": "_1z0qiWVodUbDZBHH-vVx3T",
	"menuOptionsVisible": "_1hiMC61gTNij-Tq50UpSWN",
	"status": "_7-l_CBWKQw03mO7u8AU1r",
	"draft": "_3QQMEfyAqe7juZA4IRS1jo",
	"cancelled": "_36X9aSno8X9tf3azOh_0l1"
};
module.exports = exports;
