import { UserRegistrationRequest } from "@/api/Register";

type ValidationResult<T> = {
    success: true
    obj: T
} | {
    success: false
    message: string
};

const invalid = <T, >(reason: string): ValidationResult<T> => ({ success: false, message: reason });

const insuranceNumberRegex = /^\d{3}-\d{3}-\d{3} \d{2}$/;
const phoneRegex = /^[+]*\d/;
const emailRegex = /[^@]+@[^\.]+\..+/;

export const validateUserRegistrationRequest = (r: Partial<UserRegistrationRequest>): ValidationResult<UserRegistrationRequest> => {
    const hasAllRequiredFields = r.name && r.lastName && r.organizationId && r.department && r.position && r.certificateNumber;
    if (!hasAllRequiredFields)
        return {
            success: false,
            message: "Не заполнены все обязательные поля"
        };
    if (!phoneRegex.test(r?.phoneNumber ?? "")) return invalid("Введён некорректный номер телефона");
    if (!insuranceNumberRegex.test(r?.insuranceNumber ?? "")) return invalid("Введён некорректный номер СНИЛС");
    if (!emailRegex.test(r?.email ?? "")) return invalid("Введён некорректный адрес электронной почты");

    return {
        success: true,
        obj: { ...r, insuranceNumber: "111-111-111 11", phoneNumber: "+71111111111" } as UserRegistrationRequest
    };
};