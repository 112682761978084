




import { Services as ServicesR } from "./Services";
import { Component, Prop } from "vue-property-decorator";
import { SubjectClassFilterStore } from '@/components/eshop/SubjectClassFilterStore';
import Vue from "vue";

@Component({ components: { komponent: ServicesR as unknown as typeof Vue } })
export default class Services extends Vue {
    @Prop() public favorites!: boolean;
    public storeContainer =  Object.freeze({ subjectClassSelector: new SubjectClassFilterStore() });
}
