import React, {createRef, CSSProperties, useState} from "react";
import * as styles from "./DatePicker.module.css";
import * as eshopStyles from "./DatePickerEshop.module.css";
import wrapperStyles from "./TextBox.module.css";
import {DateFormatMode, formatDate, truncateDate} from "@/dateUtils";
import {ModalFC, showModal} from "@/EventBus";
import {Cell, DatePickerModalProps, DatePickerProps} from "@/components/primitive/DatePicker.types";
import {HGrid, VGrid} from "@/components/layouts";
import {
    createCells,
    getAdjPeriodDate,
    getDateMaskUtils,
    getHeader,
    getLargerMode,
    weekDays
} from "@/components/primitive/DatePicker.utils";
import {j} from "@/reactUtils";
import {compareAsc} from 'date-fns';
import {IMaskInput} from "react-imask";


const modalWidthPx = 240;
const modalHeightPx = 230;
const marginPx = 5;

const sortedModes: DateFormatMode[] = ["decades", "years", "months", "days"];
const canChangeMode = (next: DateFormatMode, max: DateFormatMode) => sortedModes.indexOf(next) <= sortedModes.indexOf(max);

const calendarSvg = <svg className="calendar" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
    <path
        d="M18,2H15V1a1,1,0,0,0-2,0V2H7V1A1,1,0,0,0,5,1V2H2A2.006,2.006,0,0,0,0,4V17a2.006,2.006,0,0,0,2,2H18a2.006,2.006,0,0,0,2-2V4A2.006,2.006,0,0,0,18,2Zm0,15H2V4H5V5A1,1,0,0,0,7,5V4h6V5a1,1,0,0,0,2,0V4h3ZM5,8H7v2H5ZM9,8h2v2H9ZM5,12H7v2H5Zm4,0h2v2H9Zm4-4h2v2H13Z"
        transform="translate(2 2)"></path>
</svg>;

const crossSvg = <svg className="clear" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
    <path
        d="M18.3,5.71a1,1,0,0,0-1.41,0L12,10.59,7.11,5.7A1,1,0,0,0,5.7,7.11L10.59,12,5.7,16.89A1,1,0,0,0,7.11,18.3L12,13.41l4.89,4.89a1,1,0,0,0,1.41-1.41L13.41,12,18.3,7.11A1,1,0,0,0,18.3,5.71Z"
        transform="translate(0)"></path>
</svg>;

const crossSvgSmall = <svg className="clear_small" width="18" height="18" viewBox="0 0 18 18" xmlns="http://www.w3.org/2000/svg">
        <path d="M13.725 4.28249C13.5845 4.1428 13.3944 4.06439 13.1963 4.06439C12.9981 4.06439 12.808 4.1428 12.6675 4.28249L9 7.94249L5.3325 4.27499C5.18902 4.15212 5.00447 4.08791 4.81571 4.0952C4.62695 4.10249 4.4479 4.18074 4.31433 4.31431C4.18076 4.44789 4.10251 4.62694 4.09522 4.8157C4.08793 5.00445 4.15213 5.18901 4.275 5.33249L7.9425 8.99999L4.275 12.6675C4.15213 12.811 4.08793 12.9955 4.09522 13.1843C4.10251 13.373 4.18076 13.5521 4.31433 13.6857C4.4479 13.8192 4.62695 13.8975 4.81571 13.9048C5.00447 13.9121 5.18902 13.8479 5.3325 13.725L9 10.0575L12.6675 13.725C12.811 13.8479 12.9955 13.9121 13.1843 13.9048C13.373 13.8975 13.5521 13.8192 13.6857 13.6857C13.8192 13.5521 13.8975 13.373 13.9048 13.1843C13.9121 12.9955 13.8479 12.811 13.725 12.6675L10.0575 8.99999L13.725 5.33249C13.8624 5.19229 13.9394 5.0038 13.9394 4.80749C13.9394 4.61117 13.8624 4.42268 13.725 4.28249Z" />
    </svg>
;

const DatePickerModal: ModalFC<DatePickerModalProps> = x => {
    const [timeString, setTimeString] = useState("00:00");

    const style: CSSProperties = {
        width: modalWidthPx,
        height: modalHeightPx,
        top: x.top,
        left: x.left
    };

    const isEshop = x.appearance === "eshop";

    if (isEshop) {
        style.width = 256;
        style.height = 360;
    }

    const [currentMode, setCurrentMode] = useState<DateFormatMode>(x.mode);
    const [currentDate, setCurrentDate] = useState(x.value || x.minDate || new Date());

    const showColumns = currentMode === "days";
    const cells = createCells(currentDate, currentMode);

    const selectExact = (date: Date, nextMode: DateFormatMode | undefined) => {
        if (nextMode && canChangeMode(nextMode, x.mode)) {
            setCurrentMode(nextMode);
            setCurrentDate(date);
        } else {
            x.done();
        }

        x.onChange(date);
    };

    const selectCell = (cell: Cell | null) => {
        if (!cell) return;

        selectExact(cell.value, cell.nextMode);
    };

    const inRange = (date: Date): boolean =>
        (x.minDate === undefined || compareAsc(truncateDate(date, currentMode), truncateDate(x.minDate, currentMode)) >= 0) &&
        (x.maxDate === undefined || compareAsc(truncateDate(date, currentMode), truncateDate(x.maxDate, currentMode)) <= 0);

    const prev = getAdjPeriodDate(currentDate, currentMode, "prev");
    const next = getAdjPeriodDate(currentDate, currentMode, "next");
    const now = truncateDate(new Date(), x.mode);
    const header = getHeader(currentDate, currentMode);

    const isLargestMode = currentMode === "decades";
    const selectLargerMode = () => setCurrentMode(getLargerMode(currentMode));

    const time = x.value
        ? `${x.value.getHours().toString().padStart(2, "0")}:${x.value.getMinutes().toString().padStart(2, "0")}`
        : "00:00";

    return <HGrid className={j(styles.picker, isEshop && eshopStyles.eshopModal)} style={style}
                  rows="auto auto 1fr auto auto">
        <VGrid className={styles.pickerHeader} columns="auto 1fr auto" spacing={"3px"}>
            <div className={j(styles.button, {[styles.invisible]: !inRange(prev)})}
                 onClick={() => selectExact(prev, currentMode)}>&#x2190;</div>
            <div className={j(styles.button, {[styles.disabled]: isLargestMode})}
                 onClick={selectLargerMode}>{header}</div>
            <div className={j(styles.button, {[styles.invisible]: !inRange(next)})}
                 onClick={() => selectExact(next, currentMode)}>&#x2192;</div>
        </VGrid>
        <div className={styles.pickerColumns}>
            {
                showColumns && weekDays.map(x => <div key={x}>
                    {x}
                </div>)
            }
        </div>
        <div className={styles.pickerContent}>
            {
                isEshop && (
                    <div className={j(isEshop && eshopStyles.row)}>
                        {
                            ["Пн", "Вт", "Ср", "Чт", "Пт", "Сб", "Вс"].map((v, i) => (
                                <div className={j(eshopStyles.button, eshopStyles.legend)} key={v}>
                                    {v}
                                </div>
                            ))
                        }
                    </div>
                )
            }
            {
                cells.map((row, i) => <div className={j(isEshop && eshopStyles.row)} key={i}>
                    {
                        row.map((cell, i) => {
                            const classes = isEshop
                                ? {
                                    [eshopStyles.inactive]: cell?.inactive,
                                    [eshopStyles.invisible]: !cell || !inRange(cell.value),
                                    [eshopStyles.selected]: cell?.selected
                                }
                                : {
                                    [styles.inactive]: cell?.inactive,
                                    [styles.invisible]: !cell || !inRange(cell.value),
                                    [styles.selected]: cell?.selected
                                };
                            return <div
                                className={j(isEshop ? eshopStyles.button : styles.button, styles.cell, classes)}
                                key={i} onClick={() => selectCell(cell)}>
                                {cell?.text}
                            </div>;
                        })
                    }
                </div>)
            }
        </div>
        <div
            className={j(isEshop && eshopStyles.todayButton, styles.pickerFooter, {[styles.disallowed]: !inRange(now)})}
            onClick={() => selectExact(now, undefined)}>
            {formatDate(now, x.mode)}
        </div>
        {
            x.mode === "time" && <div className={j(isEshop && eshopStyles.timepicker)}>
                <p>Выберите время:</p>
                <input
                    type="time"
                    value={x.value ? x.value.toISOString().slice(11, 16) : ''}
                    onChange={(e) => {
                        console.log("here!", e.target.value);
                        const selectedTime = e.target.value;
                        const currentDateTime = x.value || new Date();
                        const newDateTime = new Date(`${currentDateTime.toISOString().slice(0, 11)}${selectedTime}:00.000Z`);
                        selectExact(newDateTime, "time");
                    }}
                />
            </div>
        }
    </HGrid>;
};

const getPlaceholder = (mode: DateFormatMode | undefined): string | undefined => {
    switch (mode) {
        case "days":
            return "ДД.ММ.ГГГГ";
        case "months":
            return "ММ.ГГГГ";
        case "years":
            return "ГГГГ";
        case "time":
            return "ДД.ММ.ГГГГ ЧЧ:ММ";
    }
};

export const DatePicker: React.FC<DatePickerProps> = x => {
    const ref = createRef<HTMLDivElement>();

    const isEshop = x.appearance === "eshop";

    const showPickerModal = () => {
        if (x.readonly || x.disabled) return;

        const el = ref.current!;
        const rect = el.getBoundingClientRect();

        const innerHeightPx = window.innerHeight;

        let top = rect.top + rect.height + marginPx;

        if (top + modalHeightPx > innerHeightPx) {
            if (rect.top - modalHeightPx - marginPx >= 0) {
                top = rect.top - modalHeightPx - marginPx;
            } else {
                top = rect.top - modalHeightPx / 2;
            }
        }

        return showModal(DatePickerModal, {
            ...x,
            top: top,
            left: rect.left - (x.alignRight ? el.clientWidth / 2 : 0),
            mode: x.mode ?? "days",
            onChange: x.onChange ?? (() => {
            })
        });
    };

    const maxWidth = x.maxWidth ? x.maxWidth : isEshop ? "500px" : "180px";
    const placeholder = getPlaceholder(x.mode) || x.placeholder;

    const {format, pattern, blocks, parse} = getDateMaskUtils(x.mode ?? "days");

    const handleKeyDown = (event: React.KeyboardEvent<HTMLInputElement>) => {
        if (event.key === 'Enter') {
            event.currentTarget.blur();
        }
    };

    let calendar = (
        <div className={styles.calendar}
             onClick={showPickerModal}
             style={{cursor: (!x.readonly && !x.disabled) ? "pointer" : 'unset'}}/>
    );
    if (isEshop) {
        calendar = (
            <div className={eshopStyles.calendar} onClick={showPickerModal} style={{cursor: (!x.readonly && !x.disabled) ? "pointer" : 'unset'}}>
                { calendarSvg }
            </div>
        );
    }

    let clear = (
        <div className={styles.clear}
             onClick={() => {
                 x.onChange && x.onChange(undefined!!);
             }}
             style={{cursor: (!x.readonly && !x.disabled) ? "pointer" : 'unset'}}
        >{""}</div>
    );
    if (isEshop) {
        clear = (
            <div className={j(x.small ? eshopStyles.clear_small : eshopStyles.clear)} onClick={() => {
                x.onChange && x.onChange(undefined!!);
            }}
                 style={{cursor: (!x.readonly && !x.disabled) ? "pointer" : 'unset'}}>
                { x.small ? crossSvgSmall : crossSvg }
            </div>
        );
    }

    return <div className={styles.pickerWrapper} style={{maxWidth: maxWidth, width: isEshop ? maxWidth : undefined}} ref={ref}>
        <IMaskInput className={j(isEshop && eshopStyles.input, x.textBoxClassName || wrapperStyles.textarea, x.small && eshopStyles.input_small)}
                    mask={Date}
            // eslint-disable-next-line @typescript-eslint/ban-ts-ignore
            // @ts-ignore
                    value={x.value}
                    unmask={'typed'}
                    pattern={pattern}
                    blocks={blocks}
                    parse={parse}
                    format={format}
            // eslint-disable-next-line @typescript-eslint/ban-ts-ignore
            // @ts-ignore
                    onAccept={
                        (value: Date | null) => x.onChange && value && x.onChange(value)
                    }
                    onKeyDown={handleKeyDown}
                    lazy={false}
                    placeholder={placeholder}
                    readOnly={x.readonly}
                    disabled={x.disabled}/>
        <div className={eshopStyles.input_icons}>
            { !!x.value && !x.cantBeCleared && clear }
            { calendar }
        </div>
    </div>;
};

export const DateTimePickerLib: React.FC<{ value?: Date; onChange: (v?: Date) => void }> = x => {
    return <DatePicker value={x.value}
                       mode="time"
                       maxWidth={"500px"}
                       appearance="eshop"
                       onChange={v => x.onChange(v ?? undefined)}/>;
};
