import { createEffect, createEvent, createStore, Store } from "effector";
import { LawType } from "@/models/enums";
import { showModal } from "@/EventBus";
import { PickFinancialSourceModal } from "@/modal-views";
import Decimal from "decimal.js";
import { LotForm } from "@/views/ComposedLot/shared/store/index";
import { convertFromFinancialSource, LotPlannedPayment } from "@/models/ComposedLots/LotPlannedPayment";
import { setInfoChanged } from "@/views/ComposedLot/shared/store/head";
import FinancialSources from "@/api/FinancialSources";

export interface LotPlannedPayments {
    sources: LotPlannedPayment[]
}

export const addPlannedPaymentFromId = createEffect({
    handler: async(limitId: string) => {
        const financialSource = await FinancialSources.getById(limitId);
        const financialLimit = convertFromFinancialSource(financialSource);

        return {
            source: financialLimit,
            amount: new Decimal(0)
        };
    }
});

export const addPlannedPaymentInteractively = createEffect({
    handler: async (law: LawType) => await showModal(PickFinancialSourceModal, { law })
});

export const editPlannedPayment = createEffect({
    handler: async (existingSource: LotPlannedPayment) =>
        await showModal(PickFinancialSourceModal, { existingSource })
});

export const deletePlannedPayment = createEvent<LotPlannedPayment>("delete planned payment");
export const setPlannedPaymentAmount =
    createEvent<{ item: LotPlannedPayment; amount?: Decimal}>("set planned payment amount");

const updatePayment = (x: LotPlannedPayments, source: LotPlannedPayment | undefined) => source ? {
    sources: [...x.sources.filter(x => x.source.id !== source.source.id), source]
} : x;

const replace = createEvent<LotPlannedPayments>("replace planned payments");

export const PlannedPaymentsStore = {
    createStore: (financialLimitId?: string): Store<LotPlannedPayments> => {
        const store = createStore<LotPlannedPayments>({ sources: [] })
            .on(replace, (_, l) => l)
            .on(addPlannedPaymentInteractively.done, (x, {result}) => updatePayment(x, result))
            .on(editPlannedPayment.done, (x, {result}) => updatePayment(x, result))
            .on(addPlannedPaymentFromId.done, (x, { result }) => updatePayment(x, result))
            .on(deletePlannedPayment, (x, p) => ({
                sources: x.sources.filter(x => x.source.id !== p.source.id)
            }))
            // TODO: amount type
            .on(setPlannedPaymentAmount, (x, { item, amount }) =>
                updatePayment(x, { source: item.source, amount: amount ?? new Decimal(0)}));

        store.watch(addPlannedPaymentInteractively, (s, p) => setInfoChanged(true));
        store.watch(editPlannedPayment, (s, p) => setInfoChanged(true));
        store.watch(deletePlannedPayment, (s, p) => setInfoChanged(true));
        store.watch(setPlannedPaymentAmount, (s, p) => setInfoChanged(true));

        if (financialLimitId) addPlannedPaymentFromId(financialLimitId).ignore;

        return store;
    },

replace: replace,
    observeOnCombinedStore: (combinedStore: Store<LotForm>) => {}
};

export const getTotalPaymentAmount = (p: LotPlannedPayment[]) =>
    p.reduce((p, v) => p.add(v.amount), new Decimal(0));
