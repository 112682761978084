import {createEvent, createStore, Store} from "effector";
import {ProcedureForm} from "@/views/Procedures2020/view/store";
import {autoSV, createSVEvent, origEv} from "@/storeUtils";
import {PublishTerms} from "@/models/Procedures2020/PublishTerms";

export interface TimeLimitationsStore {
    publishTerms?: PublishTerms
}

const replace = createEvent<TimeLimitationsStore>("replace time limitations data");

export const TimeLimitationsEvents = {
    setPublishTerms: createSVEvent<TimeLimitationsStore>()("publishTerms"),
};

/** Секция Сроки проведения */
export const TimeLimitationsStore = {
    createStore: (): Store<TimeLimitationsStore> => {
        return createStore<TimeLimitationsStore>({
        })
            .on(replace, (_, l) => l)
            .on(TimeLimitationsEvents.setPublishTerms[origEv], autoSV)
            ;
    },

    replace: replace,
    observeOnCombinedStore: (combinedStore: Store<ProcedureForm>) => {
    }
};