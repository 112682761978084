import { Component, Prop, Vue } from "vue-property-decorator";
import CardModal from "@/components/CardModal.vue";
import TreeView from "@/components/TreeView.vue";

import SubjectClasses from "@/api/SubjectClasses";
import { ModalVue, ModalResult } from "@/view-models/ModalRequest";
import { SubjectClass } from "@/models/SubjectClass";
import { ISubjectClassSelectionModalArgs } from '../ModalArgs';

interface ISubjectClassContainer {
    id: number
    object: SubjectClass
    collapsed: boolean
    selected: boolean
    level: number
}

let loadedClasses: ISubjectClassContainer[] | null = null;

@Component({ components: { CardModal, TreeView } })
export default class SubjectClassSelectionModal extends ModalVue<ISubjectClassSelectionModalArgs, SubjectClass> {
    @Prop({ default: null }) private prop!: ISubjectClassSelectionModalArgs;
    @Prop() private visible!: boolean;

    private entries: ISubjectClassContainer[] = [];
    private filter = "";

    private mounted() {
        const code = this.prop.subjectClass ? this.prop.subjectClass.code : "INVALID";
        const rootCode = this.prop.root ? this.prop.root.code : "";

        if (loadedClasses) {
            this.entries = loadedClasses.filter(x => x.object.code.startsWith(rootCode.split(".")[0]));
            for (const entry of this.entries) {
                entry.collapsed = !code.startsWith(entry.object.code) || code === entry.object.code;
                entry.selected = this.prop.subjectClass ? this.prop.subjectClass.id === entry.id : false;
            }
        } else {
            SubjectClasses.getAll(null).then(response => {
                loadedClasses = response
                    .sort((x, y) => (x.code > y.code ? 1 : -1))
                    .map(x => ({
                        id: x.id,
                        object: x,
                        collapsed: !code.startsWith(x.code) || code === x.code,
                        selected: this.prop.subjectClass ? this.prop.subjectClass.id === x.id : false,
                        level: x.code.split(".").length - 1,
                    }));
                this.entries = loadedClasses.filter(x => x.object.code.startsWith(rootCode.split(".")[0]));
            });
        }
    }

    private subjClassPredicate(item: {object: SubjectClass}, filter: string) {
        return (
            filter === null ||
            filter.length < 3 ||
            item.object.description.toLowerCase().includes(filter.toLowerCase()) ||
            item.object.code.startsWith(filter)
        );
    }

    private resetItemsCollapse() {
        for (const item of this.entries) {
            item.collapsed = !this.filter || this.filter.length < 3;
        }
    }

    private selectItemAndDeselectOther(selected: unknown) {
        for (const item of this.entries) {
            item.selected = item === selected;
        }
    }

    private selectItemAndExit() {
        for (const item of this.entries) {
            if (item.selected) {
                this.$emit("close-modal", ModalResult.ok(item.object));
                return;
            }
        }
        this.$emit("close-modal");
    }

    private openInfo() {
        const routeData = this.$router.resolve("/reference-info/dict/subject-classes");
        window.open(routeData.href, "_blank");
    }
}
