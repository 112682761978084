import React, { CSSProperties, ReactNode, useContext } from "react";
import { Card } from "@/components/Card";
import { HStack, VGrid, VStack } from "@/components/layouts";
import { CheckBox, DecimalBox, Label } from "@/components/primitive";
import { Monopoly } from "@/models/Monopoly";
import { formatNumber } from "@/NumberFormatting";
import { FormStateContext } from "@/components/form";
import { MonopolyObjectStoreEvents } from "@/views/Monopoly/view/MonopolyObject/store";

type InfoSectionProps = {
    object?: Monopoly
    events: MonopolyObjectStoreEvents
};

export const InfoSection = ({ object, events }: InfoSectionProps) => {
    const editState = useContext(FormStateContext);

    return (
        <Card>
            <VStack spacing="20px">
                <VGrid columns="repeat(4, 1fr)">
                    <InfoItem title="Заказчик" value={object?.customer.shortName} />
                    <InfoItem title="Поставщик" value={object?.supplier.shortName} />
                </VGrid>
                <VGrid columns="repeat(4, 1fr)">
                    <InfoItem title="Идентификатор пакета" value="123981263128" />
                    <InfoItem title="Идентификатор контракта" value={object?.requestId} />
                    <InfoItem title="НМЦ контракта" value={formatNumber(object?.total ?? 0)} />
                    {editState !== "enabled"
                        ? <InfoItem title="Авансовый платеж" value={`${object?.advancedPercent.toString() ?? 0}%`}/>
                        : <HStack alignItems="center" spacing="10px">
                            <InfoItem title="Авансовый платеж" value="Ежемесячный" />
                            <CheckBox value={object?.monthlyPayment} onChange={() => events.toggleMonthlyPayment()} />
                            <DecimalBox value={object?.advancedPercent}
                                        onChange={percent => events.setAdvancedPercent(percent)}
                                        style={{ width: "20%" }} />
                            &nbsp;%
                        </HStack>}
                </VGrid>
            </VStack>
        </Card>
    );
};

type InfoItemProps = {
    title: string
    value?: ReactNode
    onClick?: () => void
};

const InfoItem = ({ title, value, onClick }: InfoItemProps) => {
    const clickableStyles: CSSProperties | undefined = onClick ? { cursor: "pointer", color: "#428bca" } : undefined;

    return (
        <VStack spacing="2px">
            <Label preset="disabled">{title}</Label>
            <Label preset="readonlyValue" style={clickableStyles} onClick={onClick}>{value ?? ""}</Label>
        </VStack>
    );
};
