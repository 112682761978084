import React from "react";
import {Card} from "@/components/Card";
import {Field, Fields} from "@/components/form/Field";
import {CheckBox} from "@/components/primitive";

export const CustomersPaper: React.FC = x => {
    return <Card>
        <Fields orientation="horizontal">
            <Field title="Открытый конкурс">
                <CheckBox value={false} disabled  />
            </Field>
            <Field title="Конкурс с ограниченным участием">
                <CheckBox value={false} disabled />
            </Field>
            <Field title="Двухэтапный конкурс">
                <CheckBox value={false} disabled />
            </Field>
            <Field title="Запрос предложений">
                <CheckBox value={false} disabled />
            </Field>
            <Field title="Запрос котировок">
                <CheckBox value={false} disabled />
            </Field>
        </Fields>
    </Card>;
};