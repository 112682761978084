// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, "._2lunUvKYxwfMGr1Pk80Uaq {\n    display: grid;\n    box-sizing: border-box;\n    grid-template-rows: auto 1fr;\n    height: 100%;\n    width: 100%;\n    grid-gap: 10px;\n    padding: 10px;\n}\n\n._26iAcwRNQenS4mDhLVJCeM {\n    font-weight: bold;\n    text-align: center;\n}\n", ""]);
// Exports
exports.locals = {
	"layout": "_2lunUvKYxwfMGr1Pk80Uaq",
	"title": "_26iAcwRNQenS4mDhLVJCeM"
};
module.exports = exports;
