import { IJsonFormattable } from '@/models/IJsonFormattable';
import { ContractSpecification } from './ContractSpecification';
import { GuarranteeEventResult } from '../enums/Contracts/GuarranteeEventResult';
import { ContractDocument } from './ContractDocument';
import { IDtoContractGuarranteeEvent } from '../json/Contracts/IDtoContractGuarranteeEvent';

export class ContractGuarranteeEvent implements IJsonFormattable<IDtoContractGuarranteeEvent> {
    constructor(
        public id: string,
        public createdAt: Date,
        public date: Date,
        public specification: ContractSpecification,
        public issues: string,
        public result: GuarranteeEventResult,
        public comments: string,
        public documents: ContractDocument[]
    ) {}

    public toJson(): IDtoContractGuarranteeEvent {
        return {
            ...this,
            createdAt: this.createdAt && this.createdAt.toISOString(),
            date: this.date.toISOString(),
            specification: this.specification.toJson(),
            documents: [] // not needed
        };
    }

    public static fromJson(dto: IDtoContractGuarranteeEvent): ContractGuarranteeEvent {
        return Object.assign(Object.create(ContractGuarranteeEvent.prototype), dto, {
            createdAt: dto.createdAt && new Date(dto.createdAt),
            date: new Date(dto.date),
            specification: ContractSpecification.fromJson(dto.specification),
            documents: dto.documents.map(ContractDocument.fromJson)
        });
    }
}
