

































































import { IColumn, IRow, ISetValueEvent, RowType, MutatingRow } from "./form-table/FormFields";
import TextField from "./form-table/TextField.vue";
import DecimalField from "./form-table/DecimalField.vue";
import IntegerField from "./form-table/IntegerField.vue";
import CheckBoxField from "./form-table/CheckBoxField.vue";
import ComboBoxField from "./form-table/ComboBoxField.vue";
import RadioGroupField from "./form-table/RadioGroupField.vue";
import PickField from "./form-table/PickField.vue";
import LabelButtonField from "./form-table/LabelButtonField.vue";
import StaticField from "./form-table/StaticField.vue";
import FileField from "./form-table/FileField.vue";
import DateRangeField from "./form-table/DateRangeField.vue";
import TermsField from "./form-table/TermsField.vue";
import TableField from "./form-table/TableField.vue";
import DateField from "./form-table/DateField.vue";
import AmountPercentField from "./form-table/AmountPercentField.vue";

import { FormStyle } from "@/views/FormAbstractions";

import { Component, Prop, Vue } from "vue-property-decorator";
import { IFieldProps, FieldState } from "@/components/vue/form-table/FieldBase";
import { VueClass } from 'vue-class-component/lib/declarations';

interface FieldConfig {
    component: VueClass<Vue>
    additionalProps?: (r: IRow<unknown>) => object
}

@Component
export default class FormTable extends Vue {
    @Prop({ default: true }) private isVerticalLayout!: boolean;
    @Prop() private columns!: IColumn<unknown>[];
    @Prop() private columnCount!: number;
    @Prop({ default: false }) private autospan!: boolean;
    @Prop() private source!: unknown;
    @Prop() private bus!: Vue;

    // TODO: RowType
    private static readonly fields: { [type in RowType]: FieldConfig } = {
        TEXT: { component: TextField },
        MULTILINE_TEXT: { component: TextField },
        DECIMAL: { component: DecimalField },
        CHECKBOX: { component: CheckBoxField },
        SELECT: { component: ComboBoxField },
        RADIO_SELECT: { component: RadioGroupField },
        INLINE_RADIO_SELECT: { component: RadioGroupField },
        PICK: { component: PickField },
        DATE_RANGE: { component: DateRangeField },
        FILE: { component: FileField },
        INTEGER: { component: IntegerField },
        AMOUNT_PERCENT: { component: AmountPercentField },
        DATE_FIELD: { component: DateField, additionalProps: () => ({
            mode: "days",
            format: { day: "numeric", month: "numeric", year: "numeric" }
        })},
        MONTH_FIELD: { component: DateField },
        EMPTY: null!, // special case
        RAW: null! // special case
    };

    public get fields() { return FormTable.fields }

    private fieldProps(row: IRow<unknown>): IFieldProps<unknown> {
        return { bus: this.bus, source: this.source, state: this.state, row };
    }

    @Prop({ default: FieldState.READONLY }) private state!: FieldState;
    @Prop({ default: FormStyle.VERTICAL }) private formStyle!: FormStyle;

    private get filteredColumns(): IColumn<unknown>[] {
        return this.columns.flatMap(x => [
            { ...x, rows: x.rows.filter(y => y.visible === undefined || y.visible) },
            ...[...new Array((x.columnSpan || 1) - 1).keys()].map(() => null!)
        ]);
    }

    public buttonClick(id: string) {
        alert(id);
    }

    public reallyVisible(row: IRow<unknown>) {
        return !row.visible || row.visible(this.source);
    }

    public setValue(ev: ISetValueEvent<unknown>) {
        const setter = (ev.row as MutatingRow<unknown>).setter;
        if (setter) setter(this.source, ev.value as never);

        this.$emit("set-value", ev);
    }

    public editable(row: IRow<unknown>) {
        const editable: undefined | ((_: unknown) => boolean) | boolean = (row as {editable: undefined | ((_: unknown) => boolean) | boolean}).editable;
        return typeof editable === "function" ? editable(this.source) : (editable === undefined ? true : editable);
    }
}
