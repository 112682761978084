import {ModalFC} from "@/EventBus";
import {Expandee, HStack, VStack} from "@/components/layouts";
import {Button} from "@/components/primitive";
import {CardModal} from "@/components/modals/CardModal";

import React, {useEffect, useState} from "react";
import {Lot} from "@/models/ComposedLots";
import {
    Column,
    FilteredSelectionTable, Pager, RemoteDataset, Table, useStoredRemoteDataset
} from "@/components/table";
import {LotFilters} from "@/views/Procedures2020/view/store/lots";
import {LawTypeStrings, SmpTypeStrings} from "@/models/enums";


export const AddLotModal: ModalFC<{ rds: RemoteDataset<Lot, LotFilters> }, Lot> = x => {
    const columns: Column<Lot>[] = [
        Table.Column("Номер лота", a => <div>
            {a.item.regNumber}
        </div>, {width: "2fr"}),
        Table.Column("Закон-основание", a => <div>
            {LawTypeStrings[a.item.law]}
        </div>, {width: "2fr"}),
        Table.Column("Наименование лота", a => <div>
            {a.item.basicInfo.subject}
        </div>, {width: "2fr"}),
        Table.Column("НМЦ", a => <div>
            {"НМЦ"}
        </div>, {width: "1fr"}),
        Table.Column("Размещение среди СМП/МСП", a => <div>
            {SmpTypeStrings[a.item.basicInfo.smpType]}
        </div>, {width: "1fr"}),
    ];

    const [lots, setLots] = useState<Lot[] | undefined>();
    const [selectedLot, setSelectedLot] = useState<Lot | undefined>();
    const [filters, setFilters] = useState(false);


    const bottom = <HStack spacing={"10px"}>
        <Expandee/>
        <Button color={"green"}
                icon={"aAdd"}
                title={"Добавить"}
                disabled={!selectedLot}
                onClick={() => x.done(selectedLot)}
        />
        <Button color={"red"}
                icon={"aClose"}
                onClick={() => x.done(undefined)}
        />
    </HStack>;

    return <CardModal title={"Добавить лот"}
                      close={() => x.done(undefined)}
                      width={"60%"}
                      bottom={bottom}
    >
        <VStack spacing={"15px"}>
            <HStack>
                <Button icon={"aFilter"} onClick={() => setFilters(!filters)}/>
                <Expandee/>
            </HStack>

            {x.rds &&
            //    TODO: lot filters
            <>
                <FilteredSelectionTable<Lot, LotFilters>
                    selected={selectedLot}
                    filterStore={x.rds.filterStore}
                    isFiltersVisible={filters}
                    mode="single"
                    onChange={(a: Lot | undefined) => setSelectedLot(a)}
                    selectorPosition="hidden"
                    dataset={x.rds.dataset}
                    columns={columns}
                />
                < Pager remoteDataset={x.rds}/>
            </>
            }

        </VStack>
    </CardModal>;
};