import {createEvent, createStore, Store} from "effector";
import {ProcedureForm} from "@/views/Procedures2020/view/store";
import {autoSV, createSVEvent, origEv} from "@/storeUtils";


export interface RequestAcceptanceOrderStore {
    /** Адрес */
    address?: string

    /** Кабинет */
    room?: string

    /** Электронная почта */
    email?: string

    /** Телефон */
    phone?: string

    /** Факс */
    fax?: string

    /** Контактное лицо */
    contactPerson?: string

    /** Количество копий заявки */
    copies?: number

    /** Стандартный порядок */
    default?: boolean

    /** Порядок предоставления */
    text?: string
}

const replace = createEvent<RequestAcceptanceOrderStore>("replace requestAcceptanceOrder at documents section");

export const RequestAcceptanceOrderEvents = {
    setAddress: createSVEvent<RequestAcceptanceOrderStore>()("address"),
    setRoom: createSVEvent<RequestAcceptanceOrderStore>()("room"),
    setEmail: createSVEvent<RequestAcceptanceOrderStore>()("email"),
    setPhone: createSVEvent<RequestAcceptanceOrderStore>()("phone"),
    setFax: createSVEvent<RequestAcceptanceOrderStore>()("fax"),
    setContactPerson: createSVEvent<RequestAcceptanceOrderStore>()("contactPerson"),
    setCopies: createSVEvent<RequestAcceptanceOrderStore>()("copies"),
    setDefault: createSVEvent<RequestAcceptanceOrderStore>()("default"),
    setText: createSVEvent<RequestAcceptanceOrderStore>()("text"),

};

/** Секция "Документация и прием заявок" -> Место и порядок приема заявок на участие в процедуре закупки */
export const RequestAcceptanceOrderStore = {
    createStore: (): Store<RequestAcceptanceOrderStore> => {
        return createStore<RequestAcceptanceOrderStore>({
        })
            .on(replace, (_, l) => l)
            .on(RequestAcceptanceOrderEvents.setAddress[origEv], autoSV)
            .on(RequestAcceptanceOrderEvents.setRoom[origEv], autoSV)
            .on(RequestAcceptanceOrderEvents.setEmail[origEv], autoSV)
            .on(RequestAcceptanceOrderEvents.setPhone[origEv], autoSV)
            .on(RequestAcceptanceOrderEvents.setFax[origEv], autoSV)
            .on(RequestAcceptanceOrderEvents.setContactPerson[origEv], autoSV)
            .on(RequestAcceptanceOrderEvents.setCopies[origEv], autoSV)
            .on(RequestAcceptanceOrderEvents.setDefault[origEv], autoSV)
            .on(RequestAcceptanceOrderEvents.setText[origEv], autoSV)
            ;
    },

    replace: replace,
    observeOnCombinedStore: (combinedStore: Store<ProcedureForm>) => {
    }
};