import React, {useEffect, useState} from "react";
import styles from "./ScrollToTop.module.css";

export function ScrollToTop() {
    const [hidden, setHidden] = useState(false);
    useEffect(() => {
        const cb = () => {
            if (window.pageYOffset > 300) setHidden(false);
            else setHidden(true);
        };
        cb();
        window.addEventListener("scroll", cb);
        return () => {
            window.removeEventListener("scroll", cb);
        };
    }, []);

    return <div className={styles.scrollTop} style={{ display: hidden ? "none" : undefined }} onClick={() => window.scrollTo({ top: 0, behavior: "smooth" })}>
        <svg width="60" height="60" viewBox="0 0 60 60" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M60 30C60 22.0435 56.8393 14.4129 51.2132 8.7868C45.5871 3.16071 37.9565 0 30 0C22.0435 0 14.4129 3.16071 8.7868 8.7868C3.16071 14.4129 0 22.0435 0 30C0 37.9565 3.16071 45.5871 8.7868 51.2132C14.4129 56.8393 22.0435 60 30 60C37.9565 60 45.5871 56.8393 51.2132 51.2132C56.8393 45.5871 60 37.9565 60 30ZM31.875 43.125C31.875 43.6223 31.6775 44.0992 31.3258 44.4508C30.9742 44.8025 30.4973 45 30 45C29.5027 45 29.0258 44.8025 28.6742 44.4508C28.3225 44.0992 28.125 43.6223 28.125 43.125V21.4012L20.0775 29.4525C19.7254 29.8046 19.2479 30.0024 18.75 30.0024C18.2521 30.0024 17.7746 29.8046 17.4225 29.4525C17.0704 29.1004 16.8726 28.6229 16.8726 28.125C16.8726 27.6271 17.0704 27.1496 17.4225 26.7975L28.6725 15.5475C28.8467 15.3729 29.0536 15.2344 29.2814 15.1398C29.5092 15.0453 29.7534 14.9966 30 14.9966C30.2466 14.9966 30.4908 15.0453 30.7186 15.1398C30.9464 15.2344 31.1533 15.3729 31.3275 15.5475L42.5775 26.7975C42.9296 27.1496 43.1274 27.6271 43.1274 28.125C43.1274 28.6229 42.9296 29.1004 42.5775 29.4525C42.2254 29.8046 41.7479 30.0024 41.25 30.0024C40.7521 30.0024 40.2746 29.8046 39.9225 29.4525L31.875 21.4012V43.125Z" fill="url(#paint0_linear_1776_14099)" fillOpacity="0.3"/>
            <path d="M60 30C60 22.0435 56.8393 14.4129 51.2132 8.7868C45.5871 3.16071 37.9565 0 30 0C22.0435 0 14.4129 3.16071 8.7868 8.7868C3.16071 14.4129 0 22.0435 0 30C0 37.9565 3.16071 45.5871 8.7868 51.2132C14.4129 56.8393 22.0435 60 30 60C37.9565 60 45.5871 56.8393 51.2132 51.2132C56.8393 45.5871 60 37.9565 60 30ZM31.875 43.125C31.875 43.6223 31.6775 44.0992 31.3258 44.4508C30.9742 44.8025 30.4973 45 30 45C29.5027 45 29.0258 44.8025 28.6742 44.4508C28.3225 44.0992 28.125 43.6223 28.125 43.125V21.4012L20.0775 29.4525C19.7254 29.8046 19.2479 30.0024 18.75 30.0024C18.2521 30.0024 17.7746 29.8046 17.4225 29.4525C17.0704 29.1004 16.8726 28.6229 16.8726 28.125C16.8726 27.6271 17.0704 27.1496 17.4225 26.7975L28.6725 15.5475C28.8467 15.3729 29.0536 15.2344 29.2814 15.1398C29.5092 15.0453 29.7534 14.9966 30 14.9966C30.2466 14.9966 30.4908 15.0453 30.7186 15.1398C30.9464 15.2344 31.1533 15.3729 31.3275 15.5475L42.5775 26.7975C42.9296 27.1496 43.1274 27.6271 43.1274 28.125C43.1274 28.6229 42.9296 29.1004 42.5775 29.4525C42.2254 29.8046 41.7479 30.0024 41.25 30.0024C40.7521 30.0024 40.2746 29.8046 39.9225 29.4525L31.875 21.4012V43.125Z" fill="white" fillOpacity="0.05"/>
            <defs>
                <linearGradient id="paint0_linear_1776_14099" x1="-54.6454" y1="63.7864" x2="22.3422" y2="111.882" gradientUnits="userSpaceOnUse">
                    <stop stopColor="#00B9FF"/>
                    <stop offset="0.552083" stopColor="#8C32E6"/>
                    <stop offset="1" stopColor="#E5083B"/>
                </linearGradient>
            </defs>
        </svg>
    </div>;
}
