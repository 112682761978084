import { ContractFinancialSource, ContractStageExecutionInfo } from "@/models/Contracts";
import { Decimal } from "decimal.js";
import { IJsonFormattable } from '@/models/IJsonFormattable';
import { File } from '../Documents';
import { ObjectStatus } from '@/models/enums';
import { PaymentDocumentType } from '@/models/enums/Contracts/PaymentDocumentType';
import { IDtoContractStagePaymentInfo } from '@/models/json/Contracts/IDtoContractStagePaymentInfo';

export class ContractStagePaymentInfo implements IJsonFormattable<IDtoContractStagePaymentInfo> {
    constructor(
        public id: string,
        public createdAt: Date,
        public financialSource: ContractFinancialSource,
        public documentType: PaymentDocumentType,
        public documentNumber: string,
        public documentDate: Date,
        public debitingCustomerDate: Date | null,
        public file: File,
        public amount: Decimal,
        public tax: Decimal,
        public status: ObjectStatus,
        public executions: ContractStageExecutionInfo[] | null
    ) {}

    public toJson(): IDtoContractStagePaymentInfo {
        return {
            ...this,
            createdAt: this.createdAt && this.createdAt.toISOString(),
            financialSource: this.financialSource.toJson(),
            documentDate: this.documentDate.toISOString(),
            debitingCustomerDate: this.debitingCustomerDate?.toISOString(),
            file: this.file.toJson(),
            amount: this.amount.toString(),
            tax: this.tax && this.tax.toString(),
            executions: this.executions ? this.executions.map(v => v.toJson()) : null,
        };
    }

    public static fromJson(dto: IDtoContractStagePaymentInfo): ContractStagePaymentInfo {
        return Object.assign(Object.create(ContractStagePaymentInfo.prototype), dto, {
            createdAt: new Date(dto.createdAt),
            financialSource: ContractFinancialSource.fromJson(dto.financialSource),
            documentDate: new Date(dto.documentDate),
            debitingCustomerDate: dto.debitingCustomerDate ? new Date(dto.debitingCustomerDate) : null,
            file: File.fromJson(dto.file),
            amount: new Decimal(dto.amount),
            tax: dto.tax && new Decimal(dto.tax),
            executions: dto.executions ? dto.executions.map(ContractStageExecutionInfo.fromJson) : null
        });
    }
}
