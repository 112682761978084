import {autoSV, createSVEvent, origEv} from "@/storeUtils";
import {createEffect, createEvent, createStore, Store} from "effector";
import {LotForm} from "@/views/ComposedLot/shared/store/index";
import {LotDocumentTerm as GlossaryEntry} from "@/models/ComposedLots/documents/LotDocumentTerms";
import {createLotDocumentTerm, getLotDocumentTerms} from "@/api/directory/documents";
import {IFilterObject} from "@/api/http";
import {replaceOrPush} from "@/arrayUtils";

export interface LotGlossary {
    glossaryItems: GlossaryEntry[]
    selectedItems: GlossaryEntry[]
}

export const setGlossaryItems = createSVEvent<LotGlossary>()("glossaryItems");
export const setGlossarySelectedItems = createSVEvent<LotGlossary>()("selectedItems");
export const addGlossaryItem = async (payload: GlossaryEntry) => {
    await createLotDocumentTerm(payload);
    await getGlossaryItems({from: 0, count: 100, filters: {}});
    return;
};

export const selectGlossaryItem = createEvent<GlossaryEntry>("select glossary item");
export const unselectGlossaryItem = createEvent<GlossaryEntry>("add term");

export const getGlossaryItems = createEffect({
    name: "getGlossaryItems",
    handler: async (payload: { from: number; count: number; filters: IFilterObject }) => {
        return await getLotDocumentTerms(payload.from, payload.count, payload.filters);
    }
});

const replace = createEvent<LotGlossary>("replace glossary store");

export const GlossaryStore = {
    createStore: (): Store<LotGlossary> => {
        return createStore<LotGlossary>({
            glossaryItems: [],
            selectedItems: [],
        })
            .on(replace, (_, l) => l)
            .on(setGlossaryItems[origEv], autoSV)
            .on(setGlossarySelectedItems[origEv], autoSV)
            .on(getGlossaryItems.done, ((state, {result}) => ({...state, glossaryItems: result.items})))
            .on(selectGlossaryItem, (state: LotGlossary, payload: GlossaryEntry) => ({
                ...state,
                selectedItems: replaceOrPush(state.selectedItems, state.selectedItems.find(a => a === payload), payload)
            }))
            .on(unselectGlossaryItem, (state: LotGlossary, payload: GlossaryEntry) => ({
                ...state,
                selectedItems: [...state.selectedItems.filter(a => a !== payload)]
            }))
            ;
    },

    replace: replace,
    observeOnCombinedStore: (combinedStore: Store<LotForm>) => {
    }
};