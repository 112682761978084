import React from "react";
import {Form, FormHeader, FormState} from "@/components/form";
import {actionToState, FormAction} from "@/views/ComposedLot/shared/store";
import {Expandee, HStack, VStack} from "@/components/layouts";
import {Button} from "@/components/primitive";
import {InfoSection} from "@/views/Procedures2020/trade/sections/InfoSection";
import {Store} from "effector";
import {ProcedureForm} from "@/views/Procedures2020/view/store";
import {SectionProps} from "@/views/Procedures2020/view/store";
import {LotsSection} from "@/views/Procedures2020/trade/sections/LotsSection";
import {useMappedStore} from "@/storeUtils";
import router from "@/router";
import {Relation} from "@/components/Relation";
import {ProviderSelectionTypeStrings} from "@/models/ComposedLots";
import {
    createContractRelation,
    createLimitRelation,
    createLotRelation
} from "@/views/Procedures2020/shared/relations";
import {ResultsSection} from "@/views/Procedures2020/trade/sections/ResultsSection";
import {finishTrade} from "@/api/Procedures2020";
import {ProtocolsSection} from "@/views/Procedures2020/trade/sections/ProtocolsSection";

const Relations = (x: { store: Store<ProcedureForm> }) => {
    const [id, subj, lots, contract] = useMappedStore(x.store, x => [
        x.head.id, x.info.subject, x.lots.lots, x.head.relatedContract
    ]);
    if (!id) return null;

    const tradeR: Relation<undefined> = {
        typeName: "Закупка",
        typeNamePlural: "Закупка",
        objects: [{
            item: undefined,
            name: subj ?? ""
        }],
        columns: [],
        open: () => router.push(`/procedures/2020/${id}`)
    };

    return <HStack>
        <Relation relation={createLimitRelation(lots)}/>
        <Relation relation={createLotRelation(lots)}/>
        <Relation relation={tradeR}/>
        { contract && <Relation relation={createContractRelation(contract)}/> }
    </HStack>;
};

export const ViewTrade = (x: { storeContainer: { store: Store<ProcedureForm> }; action: FormAction }) => {
    // const [error, makeSafe] = useErrorHandler();

    const [regNumber, selectionType, status, law] = useMappedStore(x.storeContainer.store, x => [
        x.head.regNumber,
        x.info.providerSelectionType,
        x.head.status,
        x.head.law
    ]);

    const title = selectionType
        ? `${ProviderSelectionTypeStrings[selectionType]} ${regNumber}`
        : "";

    const sectionProps: SectionProps = {
        formStore: x.storeContainer.store,
        formAction: x.action
    };

    const header = <VStack spacing="15px">
        <Relations store={x.storeContainer.store} />
        {/*<Error object={error} />*/}
        <FormHeader title={title}/>
    </VStack>;

    const finishTradeTest = async () => {
        await finishTrade(x.storeContainer.store.getState().head.id!);
    };
    const navInset = <HStack spacing="10px">
        { status !== "TRADE_FINISHED" && <Button color="orange" title="Завершить торги (тест)" onClick={finishTradeTest}/> }
        <Expandee/>
        <Button color="red" icon="aClose"/>
    </HStack>;

    const tab = Form.Tab("info", "Общие сведения", [
        Form.Section("Общие сведения", <InfoSection {...sectionProps}/>),
        Form.Section("Лоты закупки", <LotsSection {...sectionProps}/>),
        ... law === "F223" ? [Form.Section("Протоколы закупки", <ProtocolsSection {...sectionProps} />)] : [],
        Form.Section("Результаты закупки", <ResultsSection {...sectionProps}/>),
    ]);

    return <FormState value={actionToState(x.action)}>
        <Form header={header} navInset={navInset} tabs={[tab]}/>
    </FormState>;
};

