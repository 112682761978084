





















































































































import ContractAdditionalConclusions from '@/api/ContractAdditionalConclusions';
import { Component, Vue, Prop } from "vue-property-decorator";
import FormTable from "@/components/vue/FormTable.vue";
import CardModal from "@/components/CardModal.vue";
import {
    AdditionalConclusionChange,
    ContractAdditionalConclusion,
    ContractDocumentType,
    AdditionalConclusionChangeStrings44,
    AdditionalConclusionExplanationType, AdditionalConclusionChangeStrings223,
} from '@/models/Contracts';
import EventBus from "@/EventBus";
import ContractDocumentModal from "../modals/ContractDocumentModal";
import { ModalResult } from "@/view-models/ModalRequest";
import { File } from "@/models/Documents";
import { SelectOption, toSelectOptions, ObjectStatus } from "@/models/enums";
import { ContractScope } from './ContractScope';
import { DateTimeRange } from '@/models';
import ErrorModal from './modals/ErrorModal.vue';
import AdditionalConclusionConfirmation from './AdditionalConclusionConfirmation.vue';

let cachedExplanations: AdditionalConclusionExplanationType[] = null!;

@Component({ components: { FormTable, CardModal } })
export default class AddAdditionalConclusion extends Vue {
    @Prop() private prop!: {
        additionalConclusion: ContractAdditionalConclusion | null
        scope: ContractScope
        editable: boolean
    };

    private law = this.prop.scope.contract.law;

    private options = this.law === "F44"
        ? AdditionalConclusionChangeStrings44
        : AdditionalConclusionChangeStrings223;

    public changeTypes = toSelectOptions(this.options);
    public source = this.prop.additionalConclusion &&
        ContractAdditionalConclusion.fromJson(this.prop.additionalConclusion.toJson()) ||
        ContractAdditionalConclusion.empty();

    public async created() {
        if (!cachedExplanations) {
            cachedExplanations = await ContractAdditionalConclusions.costChangeExplanations();
        }

        this.explanations =
            cachedExplanations.filter(x => x.foundingLaw === this.law).map(x => ({ key: x, desc: x.name }));

        this.explanations.splice(0, 0, {key: null!, desc: ""});

        this.source.costChangeExplanation =
            this.source.costChangeExplanation &&
            cachedExplanations.find(x => x.id === this.source.costChangeExplanation!.id) || null;

        if (!this.source.terms)
            this.source.terms =
                new DateTimeRange(
                    this.prop.scope.contract.durationDates!.from,
                    this.prop.scope.contract.durationDates!.to);
    }

    public changes = this.source.changes;

    public explanations: SelectOption<AdditionalConclusionExplanationType>[] = [];

    public toggle(chg: AdditionalConclusionChange, on: boolean) {
        const ix = this.changes.indexOf(chg);

        if (on && ix === -1)
            this.changes.push(chg);
        else if (!on && ix !== -1)
            this.changes.splice(ix, 1);
    }

    public setTerms(from: Date, to: Date) {
        this.source.terms = new DateTimeRange(from, to);
    }

    private static RequiresProject = [
        AdditionalConclusionChange.COST_CHANGE,
        AdditionalConclusionChange.SUPPLIER_CHANGE,
        AdditionalConclusionChange.GOODS_CHANGE,
        AdditionalConclusionChange.TERMS_CHANGE,
        AdditionalConclusionChange.FINANCIAL_SOURCE_CHANGE
    ];

    public get projectRequired() {
        return !!AddAdditionalConclusion.RequiresProject.find(x => this.changes.includes(x));
    }

    public documentTypes: SelectOption<ContractDocumentType>[] = [];

    public async pickFile() {
        const result = await EventBus.callModal<File | null, File>(ContractDocumentModal, this.source.document);
        if (result.isOk) {
            this.source.document = result.getResult();
        }
    }

    public mockDocuments: { [key: string]: File } = {};

    public async pickMockFile(key: string) {
        const result = await EventBus.callModal<File | null, File>(ContractDocumentModal, this.mockDocuments[key]);
        if (result.isOk) {
            this.$set(this.mockDocuments, key, result.getResult());
        }
    }

    public async pickProjectFile() {
        const result = await EventBus.callModal<File | null, File>(ContractDocumentModal, this.source.projectDocument);
        if (result.isOk) {
            this.source.projectDocument = result.getResult();
        }
    }

    public loading = false;

    public async saveAndClose() {
        const empty = [];
        if (!this.source.number)
            empty.push("Номер");
        if (!this.source.isElectronic && !this.source.date)
            empty.push("Дата заключения");
        if (this.projectRequired && !this.source.projectDocument)
            empty.push("Проект дополнительного соглашения");
        if (!this.projectRequired && !this.source.document)
            empty.push("Дополнительное соглашение");
        if (this.changes.includes(AdditionalConclusionChange.COST_CHANGE) && !this.source.costChangeExplanation)
            empty.push("Тип обоснования изменения суммы контракта");
        if (this.changes.includes(AdditionalConclusionChange.OTHER) && !this.source.comment)
            empty.push("Примечание");

        const errors = [];
        if (empty.length > 0)
            errors.push("Для дополнительного соглашения не заполнены следующие поля:\n" +
                empty.map(x => "- " + x).join("\n"));

        if (this.changes.length === 0)
            errors.push("Необходимо выбрать хотя бы один вид изменений, вносимого дополнительным соглашением.");

        if (errors.length > 0) {
            EventBus.callModal(ErrorModal, { errors });
            return;
        }

        if (!this.source.id &&
            !(await EventBus.callModal(AdditionalConclusionConfirmation, null)).isOk)
            return;

        this.loading = true;

        try {
            const src = this.source.id
                ? await ContractAdditionalConclusions.update(this.source)
                : await ContractAdditionalConclusions.create(this.prop.scope.contract.id, this.source);
            if (!this.source.id) {
                this.prop.scope.contract.status = ObjectStatus.CREATING_APPROVED;
                this.prop.scope.realContract.status = ObjectStatus.CREATING_APPROVED;
            }
            this.$emit("close-modal", ModalResult.ok(src));
        } finally {
            this.loading = false;
        }
    }
}
