import * as t from "io-ts";
import {Validation} from "io-ts";

export const dateTimeType = new t.Type<Date, string, string>(
    "Date",
    (i: unknown): i is Date => i instanceof Date,
    (i, c) => {
        const d = new Date(i);
        return isNaN(d.getDay()) ? t.failure(i, c) : t.success(d);
    },
    x => x.toISOString(),
);

function toLocalIsoString(date: Date) {
    const tzo = -date.getTimezoneOffset(),
        dif = tzo >= 0 ? "+" : "-",
        pad = function (num: number) {
            const norm = Math.floor(Math.abs(num));
            return (norm < 10 ? "0" : "") + norm;
        };

    return date.getFullYear()
        + "-" + pad(date.getMonth() + 1)
        + "-" + pad(date.getDate())
        + "T" + pad(date.getHours())
        + ":" + pad(date.getMinutes())
        + ":" + pad(date.getSeconds())
        + dif + pad(tzo / 60)
        + ":" + pad(tzo % 60);
}

export const localDateTimeType = new t.Type<Date, string, string>(
    "Date",
    (i: unknown): i is Date => i instanceof Date,
    (i, c) => {
        const d = new Date(i);
        return isNaN(d.getDay()) ? t.failure(i, c) : t.success(d);
    },
    x => toLocalIsoString(x),
);

export const dateType = new t.Type<Date, string, unknown>(
    "Date",
    (i: unknown): i is Date => i instanceof Date,
    (i, c) => {
        if (typeof i !== "string") return t.failure(i, c);
        const d = new Date(i);
        return isNaN(d.getDay()) ? t.failure(i, c) : t.success(d);
    },
    x => x.toISOString().replace(/T.*/, ""),
);

export const date = t.string.pipe(dateType);
export const localDateTime = t.string.pipe(localDateTimeType);
export const dateTime = t.string.pipe(dateTimeType);

export const time = new t.Type<Date, string, unknown>(
    "Time",
    (i: unknown): i is Date => i instanceof Date,
    (i, c): Validation<Date> => {
        if (typeof i === "string") {
            const [hour, minute, second] = i.split(":").map(v => +v);
            const d = new Date();
            d.setHours(hour, minute, second);
            return t.success(d);
        } else if (i instanceof Date) {
            return t.success(i);
        }
        return t.failure(i, c, "Expected string");

    },
    x => x.toLocaleTimeString("ru-RU", { hour: "2-digit", minute: "2-digit", second: "2-digit" }),
);

export const nothingType = new t.Type<undefined, undefined, unknown>(
    "Nothing",
    (i: unknown): i is undefined => i === undefined,
    (i, c) => i === null || i === undefined ? t.success(undefined) : t.failure(i, c),
    t.identity,
);

export function nothingOr<T extends t.Mixed>(tc: T) {
    return t.union([tc, nothingType]);
}
