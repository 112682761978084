import {applyN, asDate, AutoDto, AutoModel, MDMap, uuid} from "@/models/parsing";
import {ProviderSelectionType} from "@/models/ComposedLots";
import {LawType} from "@/models/enums";
import {DtoProcedureOrder, ProcedureOrder} from "@/models/Procedures2020/ProcedureOrder";
import {DtoPaymentOrder, PaymentOrder} from "@/models/Procedures2020/PaymentOrder";
import {
    DtoPublishTerms,
    PublishTerms
} from "@/models/Procedures2020/PublishTerms";
import {DtoProcedurePerformer, ProcedurePerformer} from "@/models/Procedures2020/ProcedurePerformer";
import Decimal from 'decimal.js';
import {UnionStrings} from "@/models/enums/EnumStrings";
import {ContactPerson, DtoContactPerson} from "@/models/Procedures2020/ContactPerson";
import {ProcedureDocument} from "@/models/Procedures2020/ProcedureDocument";

export type ProcedureStatus =
    | "CREATING"
    | "GRBS_AGREEMENT_PENDING"
    | "GRBS_AGREED"
    | "PUBLISH_PENDING"
    | "PUBLISHED"
    | "TRADE_FINISHED"
    | "PUBLICATION_WAITING"
    | "FO_AGREED"
    | "FO_AGREEMENT_PENDING";

export type ProcedureProtocol = {
    id: string
    name: string
    publishDate: string
    signingDate: string
    status: string
};

export const ProcedureStatusStrings: UnionStrings<ProcedureStatus> = {
    CREATING: "Формирование",
    GRBS_AGREEMENT_PENDING: "На рассмотрении ГРБС",
    GRBS_AGREED: "Согласована ГРБС",
    PUBLISH_PENDING: "На публикации",
    PUBLISHED: "Извещение опубликовано",
    TRADE_FINISHED: "Торги завершены",
    PUBLICATION_WAITING: "Ожидает публикации",
    FO_AGREED: "Установлены сроки размещения",
    FO_AGREEMENT_PENDING: "На согласовании ФО"
};

export interface ProcedureMD {
    id: uuid
    regNumber: string
    status: ProcedureStatus
    law: LawType
    subject?: string
    providerSelectionType?: ProviderSelectionType
    marketplace?: number
    documentationPreparer?: number
    procedurePerformer?: MDMap<ProcedurePerformer, DtoProcedurePerformer>
    protocols?: ProcedureProtocol[]
    decisionNumber?: string
    decisionDate?: Date
    documentationOrder?: MDMap<ProcedureOrder, DtoProcedureOrder>
    requestAcceptanceOrder?: MDMap<ProcedureOrder, DtoProcedureOrder>
    paymentOrder: MDMap<PaymentOrder, DtoPaymentOrder>
    publishTerms?: MDMap<PublishTerms, DtoPublishTerms>
    lotSpecPriceTotal: Decimal
    contactPerson: MDMap<ContactPerson, DtoContactPerson>
    contractId?: uuid
    winnerLotSpecPriceTotal?: Decimal
}

export type Procedure = AutoModel<ProcedureMD>;
export type DtoProcedure = AutoDto<ProcedureMD>;

export const Procedure = {
    fromDto(proc: DtoProcedure): Procedure {
        return {
            ...proc,
            subject: proc.subject ?? undefined,
            providerSelectionType: proc.providerSelectionType ?? undefined,
            marketplace: proc.marketplace ?? undefined,
            documentationPreparer: proc.documentationPreparer ?? undefined,
            procedurePerformer: applyN(ProcedurePerformer.fromDto, proc.procedurePerformer),
            protocols: proc.protocols ?? undefined,
            decisionNumber: proc.decisionNumber ?? undefined,
            decisionDate: asDate(proc.decisionDate),
            documentationOrder: applyN(ProcedureOrder.fromDto, proc.documentationOrder),
            requestAcceptanceOrder: proc.requestAcceptanceOrder ? ProcedureOrder.fromDto(proc.requestAcceptanceOrder) : undefined,
            paymentOrder: PaymentOrder.fromDto(proc.paymentOrder),
            publishTerms: proc.publishTerms ? PublishTerms.fromDto(proc.publishTerms) : undefined,
            lotSpecPriceTotal: new Decimal(proc.lotSpecPriceTotal),
            contractId: proc.contractId ?? undefined,
            winnerLotSpecPriceTotal: proc.winnerLotSpecPriceTotal ? new Decimal(proc.winnerLotSpecPriceTotal) : undefined,
        };
    }
};

interface ProcedureTemplateMD {
    subject: string
    providerSelectionType: ProviderSelectionType
    marketplace?: number
    documentationPreparer?: number
    procedurePerformer?: MDMap<ProcedurePerformer, DtoProcedurePerformer>
    decisionNumber?: string
    decisionDate?: Date
    documentationOrder?: MDMap<ProcedureOrder, DtoProcedureOrder>
    requestAcceptanceOrder?: MDMap<ProcedureOrder, DtoProcedureOrder>
    paymentOrder: MDMap<PaymentOrder, DtoPaymentOrder>
    procedureDocuments: ProcedureDocument[]
    procedureLotIds: uuid[]
    contactPerson: MDMap<ContactPerson, DtoContactPerson>
}

export type ProcedureTemplate = AutoModel<ProcedureTemplateMD>;
export type DtoProcedureTemplate = AutoDto<ProcedureTemplateMD>;

export const ProcedureTemplate = {
    toDto(tpl: ProcedureTemplate): DtoProcedureTemplate {
        return {
            ...tpl,
            subject: tpl.subject,
            providerSelectionType: tpl.providerSelectionType,
            marketplace: tpl.marketplace,
            documentationPreparer: tpl.documentationPreparer,
            procedurePerformer: tpl.procedurePerformer,
            decisionNumber: tpl.decisionNumber,
            decisionDate: tpl.decisionDate ? tpl.decisionDate.toISOString() : null,
            documentationOrder: tpl.documentationOrder,
            requestAcceptanceOrder: tpl.requestAcceptanceOrder,
            paymentOrder: tpl.paymentOrder,
        };
    }
};
