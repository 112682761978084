import { SupplyRegion, supplyRegions } from '@/api/filterHelpers';
import { PrimaryButton } from '@/components/eshop';
import { Select } from "@/components/eshop/Select/Select";
import { FormControlEshopInput } from '@/components/eshop/FormControlEshopInput';
import { NumberInput } from '@/components/eshop/NumberInput';
import { SubjectClassFilterStore } from '@/components/eshop/SubjectClassFilterStore';
import { SubjectClassSelector } from '@/components/eshop/SubjectClassSelector';
import { DatePicker } from '@/components/primitive/DatePicker';
import React from "react";
import { RubleSign } from '../Products/RubleSign';
import styles from "./DirectPurchasesFilters.module.css";
import { changeEndValidDateFrom, changeEndValidDateTo, changeKpgz, changeParticipantName, changePriceMax, changePriceMin, changePublicationDateFrom, changePublicationDateTo, changePurchaseName, changePurchaseNumber, changeQuantityMax, changeQuantityMin, changeRegion, changeStartValidDateFrom, changeStartValidDateTo, DirectPurchasesFilterStore, resetFilters, searchPurchases } from './store';

interface DirectPurchasesFiltersProps {
    filterStore: DirectPurchasesFilterStore
    subjectClassSelector: SubjectClassFilterStore
}

export const DirectPurchasesFilters = ({ filterStore, subjectClassSelector }: DirectPurchasesFiltersProps) => {
    const { kpgz, purchaseName, purchaseNumber, region, priceMin, priceMax, quantityMin, quantityMax, participantName, startValidDateFrom, startValidDateTo, endValidDateFrom, endValidDateTo, publicationDateFrom, publicationDateTo } = filterStore;

    return <div className={styles.sideFilters}>
        <div className={styles.filtersContainer}>
            <button className={styles.filtersCloseButton} />
            <form className={styles.filtersPanel}>
                <div className={styles.filtersField}>
                    <span className={styles.filtersLabel}>
                        Код КПГЗ
                    </span>
                    <div className={styles.filtersField}>
                        <SubjectClassSelector value={kpgz || null} onSelect={e => changeKpgz(e)} store={subjectClassSelector} />
                    </div>
                </div>


                <div className={styles.filtersField}>
                    <span className={styles.filtersLabel}>
                        Наименование оферты
                    </span>
                    <FormControlEshopInput value={purchaseName || ''} onChange={e => changePurchaseName(e.target.value)} />
                </div>


                <div className={styles.filtersField}>
                    <span className={styles.filtersLabel}>
                        Реестровый номер оферты
                    </span>
                    <FormControlEshopInput value={purchaseNumber || ''} onChange={e => changePurchaseNumber(e.target.value)} />
                </div>

                <div className={styles.filtersField}>
                    <span className={styles.filtersLabel}>
                        Регион поставки
                    </span>
                    <Select value={region || ''} onSelect={option => changeRegion(option as SupplyRegion || null)} options={supplyRegions} fullWidth />
                </div>

                <div className={styles.filtersField}>
                    <span className={styles.filtersLabel}>
                        Начало действия оферты
                    </span>
                    <div className={styles.filtersInputRow}>
                        <span className={styles.filtersInputColumn}>
                            от
                            <DatePicker value={startValidDateFrom || undefined} onChange={e => changeStartValidDateFrom(e)} />
                        </span>
                        <span className={styles.filtersInputColumn}>
                            до
                            <DatePicker value={startValidDateTo || undefined} onChange={e => changeStartValidDateTo(e)} alignRight />
                        </span>
                    </div>
                </div>

                <div className={styles.filtersField}>
                    <span className={styles.filtersLabel}>
                        Окончание действия оферты
                    </span>
                    <div className={styles.filtersInputRow}>
                        <span className={styles.filtersInputColumn}>
                            от
                            <DatePicker value={endValidDateFrom || undefined} onChange={e => changeEndValidDateFrom(e)} />
                        </span>
                        <span className={styles.filtersInputColumn}>
                            до
                            <DatePicker value={endValidDateTo || undefined} onChange={e => changeEndValidDateTo(e)} alignRight />
                        </span>
                    </div>
                </div>

                <div className={styles.filtersField}>
                    <span className={styles.filtersLabel}>
                        Дата публикации оферты
                    </span>
                    <div className={styles.filtersInputRow}>
                        <span className={styles.filtersInputColumn}>
                            от
                            <DatePicker value={publicationDateFrom || undefined} onChange={e => changePublicationDateFrom(e)} />
                        </span>
                        <span className={styles.filtersInputColumn}>
                            до
                            <DatePicker value={publicationDateTo || undefined} onChange={e => changePublicationDateTo(e)} alignRight />
                        </span>
                    </div>
                </div>

                <div className={styles.filtersField}>
                    <span className={styles.filtersLabel}>
                        Цена (<RubleSign />)
                    </span>
                    <div className={styles.filtersInputRow}>
                        <span className={styles.filtersInputColumn}>
                            от
                            <NumberInput
                                checkEmpty
                                value={priceMin}
                                onChange={e => changePriceMin(e)} />
                        </span>
                        <span className={styles.filtersInputColumn}>
                            до
                            <NumberInput
                                checkEmpty
                                value={priceMax}
                                onChange={e => changePriceMax(e)} />
                        </span>
                    </div>
                </div>

                <div className={styles.filtersField}>
                    <span className={styles.filtersLabel}>
                        Количество
                    </span>
                    <div className={styles.filtersInputRow}>
                        <span className={styles.filtersInputColumn}>
                            от
                            <FormControlEshopInput value={quantityMin || ''} onChange={e => changeQuantityMin(parseFloat(e.target.value))} />
                        </span>
                        <span className={styles.filtersInputColumn}>
                            до
                            <FormControlEshopInput value={priceMax || ''} onChange={e => changeQuantityMax(parseFloat(e.target.value))} />
                        </span>
                    </div>
                </div>

                <div className={styles.filtersField}>
                    <span className={styles.filtersLabel}>
                        Поставщик (наименование или ИНН)
                    </span>
                    <FormControlEshopInput value={participantName || ''} onChange={e => changeParticipantName(e.target.value)} />
                </div>

                <div className={styles.filtersControls}>
                    <div onClick={() => resetFilters()} className={styles.resetFilters}>×︁ Сбросить</div>
                    <PrimaryButton filled onClick={() => searchPurchases({ page: 0 })}>Найти</PrimaryButton>
                </div>
            </form>
        </div>
    </div>;
};
