// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, "._3o5x3piooUF_wuc1SnwIWr {\n    position: absolute;\n    background-color: #fff;\n    z-index: 2;\n    border: 1px solid #e1e7eb;\n    border-radius: 5px;\n    padding: 10px;\n        padding-right: 10px;\n    padding-right: 6px;\n\n    display: flex;\n    flex-direction: column;\n    align-items: end;\n}\n\n._2rprl5V8qvfiyrfKGWf5nT {\n    top: 40px;\n}\n\n.Yy3tlM_3nbk0lDLJuekAl {\n    min-width: 200px;\n    max-width: 600px;\n    min-height: 100px;\n    max-height: 200px;\n    overflow: auto;\n}\n\n._25XDAHzizUEerk12LXeXGg {\n    color: #a2aeb3;\n    line-height: 2em;\n}\n\n._3HFTLHO0oJ9ctFFeDQziB9{\n    color: #4c4c4c;\n    padding-left: 20px;\n}\n\n._2q49PAoH9tR-2fMjJFd-v0 {\n    width: 21px;\n    height: 21px;\n    top: calc(50% - 10.5px - 0px);\n    cursor: pointer;\n    background-position: center;\n    background-image: url(\"https://eaist2-f.mos.ru/eshop/img/icon-close-999.9770e030323c1c0f85a1.svg\");\n    background-size: 15px;\n    z-index: 1;\n    border-radius: 50%;\n    right: 34px;\n}\n\n.HQ4wBqAGCRhi__w-rRn9U {\n    color: #3d8ecc;\n    cursor: pointer;\n    text-decoration: underline;\n}", ""]);
// Exports
exports.locals = {
	"charsPopup": "_3o5x3piooUF_wuc1SnwIWr",
	"selectedPopup": "_2rprl5V8qvfiyrfKGWf5nT",
	"infoTable": "Yy3tlM_3nbk0lDLJuekAl",
	"infoName": "_25XDAHzizUEerk12LXeXGg",
	"infoValue": "_3HFTLHO0oJ9ctFFeDQziB9",
	"infoClose": "_2q49PAoH9tR-2fMjJFd-v0",
	"undselectButton": "HQ4wBqAGCRhi__w-rRn9U"
};
module.exports = exports;
