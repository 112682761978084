import {autoSV, createSVEvent, origEv} from "@/storeUtils";
import {createEvent, createStore, Store} from "effector";
import {LotForm} from "@/views/ComposedLot/shared/store/index";
import {setInfoChanged, setSpecsChanged} from "@/views/ComposedLot/shared/store/head";
import {setSpecialOrder} from "@/views/ComposedLot/shared/store/provisions";

export interface LotPurchasePerUnit {
    enabled: boolean
}

export const setPurchasePerUnitEnabled = createSVEvent<LotPurchasePerUnit>()("enabled");

const replace = createEvent<LotPurchasePerUnit>("replace purchase per unit info");

export const PurchasePerUnitStore = {
    createStore: (): Store<LotPurchasePerUnit> => {
        const store = createStore<LotPurchasePerUnit>({enabled: false})
            .on(replace, (_, l) => l)
            .on(setPurchasePerUnitEnabled[origEv], autoSV);

        store.watch(setPurchasePerUnitEnabled, (s, p) => setSpecsChanged(true));

        return store;
    },

    replace: replace,
    observeOnCombinedStore: (combinedStore: Store<LotForm>) => {}
};