



















import { Component, Prop, Vue } from "vue-property-decorator";
import { IHint } from '../form-table/FormFields';
import EventBus from '@/EventBus';

export const enum LabelStyle {
    NORMAL = "NORMAL",
    LIGHT = "LIGHT",
    SEARCH_MODAL_HEADER = "SEARCH-MODAL-HEADER"
}

@Component
export default class Label extends Vue {
    @Prop() private text!: string;
    @Prop({default: LabelStyle.NORMAL}) private labelStyle!: LabelStyle;
    @Prop({default: null}) private hint!: IHint | null;
    @Prop({default: false}) private required!: boolean;
    @Prop({default: "unset"}) private whitespace!: string;

    private openHint(hint: IHint, ev: MouseEvent) {
        EventBus.$emit("show-hint", hint, ev);
    }
}
