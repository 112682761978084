import {Cell} from "@/components/primitive/DatePicker.types";
import {addDays, addYears, getDate, getDay, getDaysInMonth, getMonth, getYear, isEqual, set, setMonth} from "date-fns";
import {range, sequence, windowed} from "@/arrayUtils";
import {DateFormatMode, formatDate, truncateDate} from "@/dateUtils";
import {addMonths} from 'date-fns/esm';
import IMask, {AnyMaskedOptions} from "imask";

export const months = [
    "Янв.", "Февр.", "Март", "Апр.",
    "Май", "Июнь", "Июль", "Авг.",
    "Сент.", "Окт.", "Нояб.", "Дек."
];

export const weekDays = [
    "пн", "вт", "ср", "чт", "пт", "сб", "вс"
];

export const createCells = (date: Date, mode: DateFormatMode): (Cell | null)[][] => {
    const year = getYear(date);

    switch (mode) {
        case "decades": {
            const start = Math.trunc(year / 100) * 100;

            const cells = range<Cell | null>(start - 10, start + 100, 10, (n, ix) => {
                return {
                    nextMode: "years",
                    value: set(truncateDate(date, "years"), {
                        year: n,
                        month: getMonth(date),
                        date: getDate(date)
                    }),
                    selected: year >= n && year <= n + 9,
                    inactive: ix == 0 || ix == 11,
                    text: `${n}—${n + 9}`
                };
            });
            return windowed(cells, 4);
        }
        case "years": {
            const start = Math.trunc(year / 10) * 10;

            const cells = range<Cell | null>(start - 1, start + 10, 1, (n, ix) => {
                return {
                    nextMode: "months",
                    value: set(truncateDate(date, "years"), {
                        year: n,
                        month: getMonth(date),
                        date: getDate(date)
                    }),
                    selected: year === n,
                    inactive: ix == 0 || ix == 11,
                    text: n.toString()
                };
            });

            return windowed(cells, 4);
        }
        case "months": {
            const cells = sequence<Cell | null>(0, 12, 1, ix => {
                return {
                    nextMode: "days",
                    value: setMonth(truncateDate(date, "days"), ix),
                    selected: getMonth(date) === ix,
                    inactive: false,
                    text: months[ix]
                };
            });

            return windowed(cells, 4);
        }
        case "time":
        case "days": {
            const weekOfMonth = (getDay(truncateDate(date, "months")) + 6) % 7;
            const daysInMonth = getDaysInMonth(date);

            const cells = sequence<Cell | null>(-weekOfMonth, 42, 1, n => {
                const value = addDays(truncateDate(date, "months"), n);

                return {
                    nextMode: undefined,
                    value: value,
                    selected: isEqual(truncateDate(date, "days"), value),
                    inactive: n < 0 || n >= daysInMonth,
                    text: value.getDate().toString()
                };
            });

            return windowed(cells, 7);
        }
    }
};

export const getHeader = (date: Date, mode: DateFormatMode): string => {
    switch (mode) {
        case "decades": {
            const start = Math.trunc(getYear(date) / 100) * 100;

            return `${start}—${start + 99}`;
        }
        case "years": {
            const start = Math.trunc(getYear(date) / 10) * 10;

            return `${start}—${start + 9}`;
        }
        case "months": {
            return formatDate(date, "years");
        }
        case "days": {
            return formatDate(date, "months");
        }
        case "time": {
            return formatDate(date, "time");
        }
    }
};

export const getLargerMode = (mode: DateFormatMode): DateFormatMode => {
    switch (mode) {
        case "decades":
        case "years": return "decades";
        case "months": return "years";
        case "days": return "months";
        case "time": return "days";
    }
};

export const getAdjPeriodDate = (date: Date, mode: DateFormatMode, direction: "prev" | "next"): Date => {
    const multiplier = direction === "prev" ? -1 : 1;

    switch (mode) {
        case "decades": {
            return addYears(date, multiplier * 100);
        }
        case "years": {
            return addYears(date, multiplier * 10);
        }
        case "months": {
            return addYears(date, multiplier);
        }
        case "time": // behaviour should stay the same as regular date picker
        case "days": {
            return addMonths(date, multiplier);
        }
    }
};

export function getDateMaskUtils(mode: DateFormatMode) {
    const patternTime = 'd.M.Y h:mm';
    const blocksTime: { [key: string]: AnyMaskedOptions } = {
        d: {
            mask: IMask.MaskedRange,
            from: 1,
            to: 31,
            maxLength: 2,
        },
        M: {
            mask: IMask.MaskedRange,
            from: 1,
            to: 12,
            maxLength: 2,
        },
        Y: {
            mask: IMask.MaskedRange,
            from: 1900,
            to: 2100,
            maxLength: 4,
        },
        h: {
            mask: IMask.MaskedRange,
            from: 0,
            to: 23,
            maxLength: 2,
            autofix: true
        },
        mm: {
            mask: IMask.MaskedRange,
            from: 0,
            to: 59,
            maxLength: 2,
            autofix: true
        },
    };

    const parseTime = function (str: string) {
        const [d, t] = str.split(" ");
        const date = d.split(".");
        const time = t.split(":");

        const parsed = new Date(`${date[2]}-${date[1]}-${date[0]}T${time[0]}:${time[1]}:00+03:00`);

        return parsed;
    };

    const formatTime = function (date?: Date) {
        if (!date) return "";
        const day = date.getDate().toString().padStart(2, "0");
        const month = (date.getMonth() + 1).toString().padStart(2, "0");
        const year = date.getFullYear();

        const hours = date.getHours().toString().padStart(2, "0");
        const minutes = date.getMinutes().toString().padStart(2, "0");

        return `${day}.${month}.${year} ${hours}:${minutes}`;
    };

    const patternMonth = 'm{.}`Y';
    const blocksMonth = {
        m: {
            mask: IMask.MaskedRange,
            from: 1,
            to: 12,
            maxLength: 2,
        },
        Y: {
            mask: IMask.MaskedRange,
            from: 1900,
            to: 2100,
        }
    };

    const parseMonth = function (str: string) {
        const d = str.split('.');
        return new Date(`${d[0]}.01.${d[1]} 03:00 +0300`);
    };

    const formatMonth = function (date: Date) {
        if (!date) return '';
        return Intl.DateTimeFormat("ru", {month: "numeric", year: "numeric"}).format(date);
    };

    const patternDay = 'd{.}`m{.}`Y';
    const blocksDay = {
        d: {
            mask: IMask.MaskedRange,
            from: 1,
            to: 31,
            maxLength: 2,
        },
        m: {
            mask: IMask.MaskedRange,
            from: 1,
            to: 12,
            maxLength: 2,
        },
        Y: {
            mask: IMask.MaskedRange,
            from: 1900,
            to: 2100,
        }
    };
    const parseDay = function (str: string) {
        const d = str.split('.');
        return new Date(`${d[1]}.${d[0]}.${d[2]} 03:00 +0300`);
    };

    const formatDay = function (date: Date) {
        if (!date) return '';
        return Intl.DateTimeFormat("ru", {day: "numeric", month: "numeric", year: "numeric"}).format(date);
    };

    if (mode === "days") {
        return {
            format: formatDay,
            pattern: patternDay,
            blocks: blocksDay,
            parse: parseDay
        };
    }

    if (mode === "time") {
        return {
            format: formatTime,
            pattern: patternTime,
            blocks: blocksTime,
            parse: parseTime
        };
    }

    return {
        format: formatMonth,
        pattern: patternMonth,
        blocks: blocksMonth,
        parse: parseMonth
    };
}
