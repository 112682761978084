import React, { useEffect, useState } from "react";
import styles from "./RegisterForm.module.css";
import { RoundedButton } from "@/components/primitive/RoundedButton";
import { Expandee, HStack } from "@/components/layouts";
import { showModal } from "@/EventBus";
import { UserParticipantSelectionModal } from "@/views/modals/UserParticipantSelectionModal/UserParticipantSelectionModal";
import { Participant } from "@/models";
import { getLotParticipantsBypassReady } from "@/api/directory";
import router from "@/router";
import { Field, Fields } from "@/components/form/Field";
import { CheckBox, TextBox } from "@/components/primitive";
import { sendRegistrationRequest, UserRegistrationRequest } from "@/api/Register";
import { validateUserRegistrationRequest } from "@/views/Register/validation";
import { ConfirmationModal, ErrorModal } from "@/views/Contracts/modals/ConfirmationModal";
import { RegisterFormOrganizationCard } from "@/views/Register/OrganizationCard";
import MaskedInput from "react-text-mask";
import { j } from "@/reactUtils";

const Footer: React.FC<{ onSubmit: () => void; onLogin?: () => void }> = x => {
    const [blocked, setBlocked] = useState<boolean>(false);
    return <div className={styles.footer}>
        <div className={styles.footerContent}>
            <div>
                <a href="http://eaist2-f.mos.ru/admin/api/userRegistration/regManualFile"
                   className={styles.footerLink}>
                    Помощь в регистрации
                </a>
            </div>
            <div>
                <a href="http://eaist2-f.mos.ru/wiki/api/section/DOCUMENTS/articles/109559/files/%D0%A2%D0%B8%D0%BF%D0%BE%D0%B2%D0%BE%D0%B9%20%D0%BF%D1%80%D0%B8%D0%BA%D0%B0%D0%B7%20%D0%BD%D0%B0%20%D0%B4%D0%BE%D1%81%D1%82%D1%83%D0%BF%20%D0%B2%20%D0%95%D0%90%D0%98%D0%A1%D0%A2%202.0.docx"
                   className={styles.footerLink}>
                    Типовая форма приказа на доступ
                </a>
            </div>
            <HStack spacing="20px">
                <RoundedButton title="Отмена"
                               onClick={() => x.onLogin ? x.onLogin() : router.resetTo("/login")}
                               color="white" />
                <RoundedButton title="Отправить"
                               disabled={blocked}
                               onClick={() => {
                                   x.onSubmit();
                                   setBlocked(true);
                                   setTimeout(() => setBlocked(false), 10000);
                               }}
                               color="blue" />
            </HStack>
        </div>
    </div>;
};

const Form: React.FC<{ request: Partial<UserRegistrationRequest>; setRequest: (u: Partial<UserRegistrationRequest>) => void }> = x => {
    const [hasMiddleName, setHasMiddleName] = useState<boolean>(true);
    const [organizationName, setOrganizationName] = useState<string>("");
    const [organization, setOrganization] = useState<Participant>();

    const setName = (s: string) => x.setRequest({ ...x.request, name: s });
    const setMiddleName = (s: string) => x.setRequest({ ...x.request, middleName: s });
    const setLastName = (s: string) => x.setRequest({ ...x.request, lastName: s });
    const setEmail = (s: string) => x.setRequest({ ...x.request, email: s });
    const setPhoneNumber = (s: string) => x.setRequest({ ...x.request, phoneNumber: s });
    const setInsuranceNumber = (s: string) => x.setRequest({ ...x.request, insuranceNumber: s });
    const setCertificateNumber = (s: string) => x.setRequest({ ...x.request, certificateNumber: s });
    const setOrganizationId = (s: string) => x.setRequest({ ...x.request, organizationId: s });
    const setDepartment = (s: string) => x.setRequest({ ...x.request, department: s });
    const setIsHead = (s: boolean) => x.setRequest({ ...x.request, isHead: s });
    const setPosition = (s: string) => x.setRequest({ ...x.request, position: s });
    const setGroup = (s: string) => x.setRequest({ ...x.request, group: s });

    const selectParticipant = (p: Participant) => {
        setOrganizationId(p.id?.toString());
        setOrganizationName(p.shortName);
        setOrganization(p);
    };
    const clearOrganization = () => {
        setOrganization(undefined);
        setOrganizationId("");
        setOrganizationName("");
    };

    const pickOrganization = () => showModal(UserParticipantSelectionModal, {})
        .then(r => r ? selectParticipant(r) : {});

    return <div className={styles.form}>
        <div className={styles.formPageTitle}>
            Регистрация нового пользователя
        </div>
        <div className={styles.formStepHeader}>
            <span>
                Шаг 1:
            </span>
            <span>
                Данные пользователя
            </span>
        </div>

        <div className={styles.fieldGrid}>
            <Fields spacing="15px" orientation="horizontal">
                <Field required preset="thinMedium" title="Фамилия">
                    <TextBox value={x.request.lastName}
                             onChange={setLastName}
                             className={styles.eaistInput} />
                </Field>
                <Field required preset="thinMedium" title="Имя">
                    <TextBox value={x.request.name}
                             onChange={setName}
                             className={styles.eaistInput} />
                </Field>
                <Field preset="thinMedium" title="Отчество">
                    <TextBox value={hasMiddleName ? x.request.middleName : undefined}
                             onChange={setMiddleName}
                             disabled={!hasMiddleName}
                             placeholder={!hasMiddleName ? "Нет отчества" : undefined}
                             className={styles.eaistInput} />
                </Field>
                <Field preset="thinMedium" title="">
                    <CheckBox value={!hasMiddleName} onChange={() => setHasMiddleName(!hasMiddleName)}>
                        Нет отчества
                    </CheckBox>
                </Field>
                <Field required preset="thinMedium" title="СНИЛС">
                    <MaskedInput
                        mask={[/\d/,/\d/,/\d/,"-",/\d/,/\d/,/\d/,"-",/\d/,/\d/,/\d/," ",/\d/,/\d/]}
                        placeholder="000-000-000 00"
                        value={x.request.insuranceNumber ?? ""}
                        className={j(styles.pioInput, styles.eaistInput)}
                        onChange={e => setInsuranceNumber(e.target.value)}
                    />
                </Field>
                <Field required preset="thinMedium" title="№ сертификата о прохождении обучения">
                    <TextBox className={styles.eaistInput}
                             onChange={setCertificateNumber}
                             value={x.request.certificateNumber} />
                </Field>
            </Fields>
            <Fields spacing="15px" orientation="horizontal">
                <Field required preset="thinMedium" title="Организация">
                    <TextBox className={styles.eaistInput}
                             value={organizationName}
                             onClick={pickOrganization} />
                </Field>
                <Field required preset="thinMedium" title="Подразделение">
                    <TextBox className={styles.eaistInput}
                             value={x.request.department}
                             onChange={setDepartment} />
                </Field>
                <Field required preset="thinMedium" title="Должность">
                    <TextBox className={styles.eaistInput}
                             value={x.request.position}
                             onChange={setPosition} />
                </Field>
                <Field preset="thinMedium" title="">
                    <CheckBox value={x.request.isHead}
                              onChange={setIsHead}>
                        Руководитель
                    </CheckBox>
                </Field>
                <Field required preset="thinMedium" title="E-mail">
                    <TextBox className={styles.eaistInput}
                             value={x.request.email}
                             onChange={setEmail}/>
                </Field>
                <Field required preset="thinMedium" title="Телефон">
                    <MaskedInput
                        mask={[..."+7(".split(""), /\d/,/\d/,/\d/,")",/\d/,/\d/,/\d/,"-",/\d/,/\d/,"-",/\d/,/\d/,..." доб. ".split(""), /\d/,/\d/,/\d/,/\d/,/\d/,/\d/]}
                        placeholder="+7 (999) 999-99-99 доб. 999999"
                        value={x.request.phoneNumber ?? ""}
                        className={j(styles.pioInput, styles.eaistInput)}
                        onChange={e => setPhoneNumber(e.target.value)}
                    />
                </Field>
                <Field required preset="thinMedium" title="Группа">
                    <TextBox className={styles.eaistInput}
                             value={x.request.group}
                             onChange={setGroup} />
                </Field>
            </Fields>
        </div>
        <div style={{ display: "flex", alignItems: "center", width: "100%" }}>
            <div className={styles.formStepHeader}>
                <span>
                    Шаг 2:
                </span>
                    <span>
                    Определение полномочий
                </span>
            </div>
            <Expandee />
            <div>
                <RoundedButton title="Добавить организацию"
                               onClick={pickOrganization}
                               color="blueWhite" />
            </div>
        </div>
        {
            organization && <div style={{ width: "100%" }}>
                <RegisterFormOrganizationCard organization={organization}
                                              onDelete={clearOrganization} />
            </div>
        }
    </div>;
};

export const RegisterForm: React.FC<{ onLogin?: () => void }> = x => {
    const [request, setRequest] = useState<Partial<UserRegistrationRequest>>({});
    const sumbit = async () => {
        const confirm = await showModal(ConfirmationModal, {
            title: "Согласие на обработку персональных данных",
            text: <>Регистрируясь в системе, вы подтверждаете свое согласие с <a target="_blank" href="personal_data_policy.pdf">политикой в отношении обработки персональных данных</a></>
        });
        if (!confirm) {
            if (x.onLogin) {
                x.onLogin();
            } else {
                router.resetTo("/");
            }
            return;
        }
        const result = validateUserRegistrationRequest(request);
        if (result.success) {
            sendRegistrationRequest(result.obj)
                .then(() => showModal(ConfirmationModal, { title: "Заявка успешно отправлена", text: "Ожидайте верификации от преподавателя. Информация об учетной записи будет отправлена на указанный вами адрес электронной почты", notify: true, width: "500px"  }))
                .then(() => x.onLogin ? x.onLogin() : router.resetTo("/"));
        } else {
            showModal(ErrorModal, { title: "Ошибка", text: result.message });
        }
    };

    return <div className={styles.page}>
        <Form request={request} setRequest={setRequest}/>
        <Footer onSubmit={sumbit} onLogin={x.onLogin} />
    </div>;
};
