import React, {useEffect, useState} from "react";
import {VStack} from "@/components/layouts";
import {Button, DecimalBox} from "@/components/primitive";
import {Column, Table} from "@/components/table";
import { showModal } from "@/EventBus";
import { LinkWithSupplierExecution } from "@/views/modals/LinkWithSupplierExecution/LinkWithSupplierExecution";
import {ContractDocument, ContractStageExecutionInfo, ContractStagePaymentInfo} from "@/models/Contracts";
import {ContractStageScope} from "@/views/CreateContract/ContractScope";
import Decimal from "decimal.js";
import { formatDate } from "@/dateUtils";

const PrimaryDocNumberCell: React.FC<{ exec: ContractStageExecutionInfo }> = x => {
    const doc = x.exec.documents.find(doc => doc.id === x.exec.primaryDocumentId);
    if (!doc) return <>-</>;
    return <>
        { doc.documentNumber }
    </>;
};

const PrimaryDocDateCell: React.FC<{ exec: ContractStageExecutionInfo }> = x => {
    const doc = x.exec.documents.find(doc => doc.id === x.exec.primaryDocumentId);
    if (!doc) return <>-</>;
    return <>
        { formatDate(doc.createdAt, "days") }
    </>;
};

const PrimaryDocSumCell: React.FC<{ exec: ContractStageExecutionInfo; execInfo: ContractStageExecutionInfo[] }> = x => {
    if(!x.execInfo.length) return <>-</>;
    const doc = x.exec.documents.find(doc => doc.id === x.exec.primaryDocumentId);
    if (!doc) return <>-</>;

    const calculateSum = (docId: string) => {
        const executionSpec = x.execInfo.find(i => i.primaryDocumentId === docId)?.specifications;

        if(!executionSpec || !executionSpec.length) {
            return null;
        }
        return executionSpec.reduce((acc, spec) => acc.plus(spec.cost), new Decimal(0));
    };
    const result = calculateSum(doc.id);
    if(!result) return <>-</>;

    return <DecimalBox value={result} disabled precision={2}/>;
};

export const RelatedExecutions: React.FC<{ paymentInfo: ContractStagePaymentInfo; stageScope: ContractStageScope | null }> = x => {

    const handleLinkClick = async () => {
        if (!x.stageScope?.executionInfo) return;
        const result = await showModal(LinkWithSupplierExecution, {executionInfo: x.stageScope?.executionInfo, executions: x.paymentInfo.executions});
        if (result) x.paymentInfo.executions = result.selected;
    };

    const columns: Column<ContractStageExecutionInfo>[] = [
        Table.Column("Дата документа", cell => <PrimaryDocDateCell exec={cell.item}/>),
        Table.Column("Номер документа", cell => <PrimaryDocNumberCell exec={cell.item} />),
        Table.Column("Сумма, руб", cell => <PrimaryDocSumCell exec={cell.item} execInfo={x.paymentInfo.executions ?? []}/>),
    ];

    return <VStack spacing="15px">
        <Button color="green" title="Связать с исполнениями поставщиком" style={{ width: "300px" }} onClick={handleLinkClick} />
        <span style={{ fontSize: "16px", fontWeight: 500 }}>Связанные исполнения поставщиком</span>
        <Table dataset={x.paymentInfo.executions ?? []} columns={columns} />
    </VStack>;
};
