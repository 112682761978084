






import {Component, Prop, Vue} from "vue-property-decorator";
import {MonopolySection} from "@/views/Monopoly/list/MonopolySection";
import { createConclusionRequestsStore } from "@/views/Monopoly/list/store";

type ConclusionRequestsType = "in-progress" | "accepted" | "processed";

@Component({ components: { komponent: MonopolySection as unknown as typeof Vue } })
export default class ConclusionRequestsAcceptedVueStub extends Vue {
    @Prop() public type!: ConclusionRequestsType;
    public storeContainer = Object.freeze(createConclusionRequestsStore(this.type));
}
