export const colors = {
    blue:  {
        idle: {
            bg: "#4db2ff",
            border: "transparent"
        },
        hover: {
            bg: "#4ea1df",
            border: "transparent",
        },
        active: {
            bg: "#3f8fca",
            border: "#246da2 #3f8fca #3f8fca"
        },
        text: undefined
    },
    white: {
        idle: {
            bg: "white",
            border: "#c4cbd4",
        },
        hover: {
            bg: "#eaedf0",
            border: "#c4cbd4",
        },
        active: {
            bg: "#d2d7df",
            border: "#a5adb9 #d2d7df #d2d7df"
        },
        text: "#999"
    },
    blueWhite: {
        idle: {
            bg: "white",
            border: "#4db2ff",
        },
        hover: {
            bg: "#eaedf0",
            border: "#4db2ff",
        },
        active: {
            bg: "white",
            border: "#4db2ff"
        },
        text: "#4db2ff"
    },
};