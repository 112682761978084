import { observer } from "mobx-react";
import React, { useState } from "react";
import { FormControlEshopInput } from "./FormControlEshopInput";
import { ISubjectClassContainer, SubjectClassFilterStore } from "./SubjectClassFilterStore";
import styles from "./SubjectClassSelector.module.css";
import {Input} from "@/components/eshop/Input/Input";

type SubjectClassSelectorProps = {
    store: SubjectClassFilterStore
    onSelect?: (entry: ISubjectClassContainer | null) => void
    default?: string
    value?: ISubjectClassContainer | null
    showTree?: boolean
};

export const SubjectClassSelector = observer((props: SubjectClassSelectorProps) => {
    const { filter, filteredDataset, selectEntry, selectedEntry, toggleCollapseItem, setFilter } = props.store;
    const [showTree, setShowTree] = useState(props.showTree ?? false);
    const toggleShowTree = () => setShowTree(!showTree);
    const onSelect = (entry: ISubjectClassContainer | null) => {
        selectEntry(entry);
        if (props.onSelect) {
            props.onSelect(entry);
        }
        setShowTree(false);
    };

    selectEntry(props.value ?? null);

    return <div className={styles.selectTreeContainer}>
        <div className={styles.selectControl} onClick={toggleShowTree}>
            {selectedEntry
                ? `${selectedEntry.object.code} ${selectedEntry.object.description}`
                : <span className={styles.selectHint}>
                    Выберите значение из списка
                </span>}
        </div>
        <div className={styles.treeReset} onClick={() => onSelect(null)} />
        <div className={`${styles.treeArrow} ${showTree && styles.treeArrowOpen}`} onClick={toggleShowTree} />
        {showTree && <div className={styles.treeWrapper}>
            <Input
                value={filter}
                onChange={e => setFilter(e.target.value)}
                placeholder="Введите часть кода КПГЗ"
                fullWidth
                background={"#fff"}/>
            <div className={styles.treeContainer}>
                {filteredDataset.map(d => <div
                    className={`${styles.dataRow} ${d.children.length && d.collapsed ? styles.dataRowHasChildrenCollapsed : ''} ${d.children.length ? styles.dataRowHasChildren : ''}`}
                    style={{ marginLeft: d.item.level * 10 + 10 }}
                    onClick={() => toggleCollapseItem(d.actualIndex)}
                    key={d.item.id}
                >
                    <div
                        className={`${styles.itemName} ${selectedEntry?.id === d.item.id && styles.itemNameSelected}`}
                        onClick={() => onSelect(d.item)}>
                        {d.item.object.code} {d.item.object.description}
                    </div>
                </div>)}
            </div>
        </div>}
    </div>;
});
