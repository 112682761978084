import {createEvent, createStore, Store} from "effector";
import {ProviderSelectionType} from "@/models/ComposedLots";
import {ProcedureForm} from "@/views/Procedures2020/view/store";
import {autoSV, createSVEvent, origEv} from "@/storeUtils";
import {ContactPerson} from "@/models/Procedures2020/ContactPerson";

export interface ProcedureInfoSectionStore {
    /** Организатор закупки */
    marketplace?: number

    /** Описание закупки */
    subject?: string

    /** Способ определения поставщика */
    providerSelectionType?: ProviderSelectionType

    /** Организация по проведению закупки */
    procedurePerformer?: number

    /** Совпадает с организацией, разрабатывющей документацию */
    isPerformerEqualToDocPreparer: boolean

    /** Организация по подготовке документации  */
    documentationPreparer?: number

    /** Ответственное должностное лицо */
    contactPerson?: ContactPerson

    /** Номер решения о проведении закупки */
    decisionNumber?: string

    /** Дата решения о проведении закупки*/
    decisionDate?: Date
}

const replace = createEvent<ProcedureInfoSectionStore>("replace info section");

export const ProcedureEvents = {
    setMarketplace: createSVEvent<ProcedureInfoSectionStore>()("marketplace"),
    setSubject: createSVEvent<ProcedureInfoSectionStore>()("subject"),
    setProviderSelectionType: createSVEvent<ProcedureInfoSectionStore>()("providerSelectionType"),
    setProcedurePerformer: createSVEvent<ProcedureInfoSectionStore>()("procedurePerformer"),
    setDocumentationPreparer: createSVEvent<ProcedureInfoSectionStore>()("documentationPreparer"),
    setIsPerformerEqualToDocPreparer: createSVEvent<ProcedureInfoSectionStore>()("isPerformerEqualToDocPreparer"),
    setContactPerson: createSVEvent<ProcedureInfoSectionStore>()("contactPerson"),
    setDecisionNumber: createSVEvent<ProcedureInfoSectionStore>()("decisionNumber"),
    setDecisionDate: createSVEvent<ProcedureInfoSectionStore>()("decisionDate"),
};

/** Секция "Общие сведения" */
export const ProcedureInfoSectionStore = {
    createStore: (): Store<ProcedureInfoSectionStore> => {
        return createStore<ProcedureInfoSectionStore>({
            isPerformerEqualToDocPreparer: false,
            contactPerson: {
                name: "Иванов Иван Иванович",
                phoneNumber: "+7 (495) 123-13-37",
                email: "main@eaist.emu"
            }
        })
            .on(replace, (_, l) => l)
            .on(ProcedureEvents.setMarketplace[origEv], autoSV)
            .on(ProcedureEvents.setSubject[origEv], autoSV)
            .on(ProcedureEvents.setProviderSelectionType[origEv], autoSV)
            .on(ProcedureEvents.setProcedurePerformer[origEv], autoSV)
            .on(ProcedureEvents.setDocumentationPreparer[origEv], autoSV)
            .on(ProcedureEvents.setContactPerson[origEv], autoSV)
            .on(ProcedureEvents.setDecisionNumber[origEv], autoSV)
            .on(ProcedureEvents.setDecisionDate[origEv], autoSV)
            .on(ProcedureEvents.setIsPerformerEqualToDocPreparer[origEv], autoSV);
    },

    replace: replace,
    observeOnCombinedStore: (combinedStore: Store<ProcedureForm>) => {}
};