import { SubjectClass, SubjectDeclaration } from "@/models";
import { typifyVueModal } from "@/EventBus";
import { ISubjectDeclarationSelectionModalArgs } from "@/views/modals/ModalArgs";
import SubjectDeclarationSelectionModal
    from "@/views/modals/SubjectDeclarationSelectionModal/SubjectDeclarationSelectionModal.vue";
import { LawType } from "@/models/enums";

interface PickSubjectClassProps {
    subjectClass: SubjectClass
    selected?: SubjectDeclaration
    law: LawType
    isEmpty?: boolean
}

export const PickSubjectDeclarationModal = typifyVueModal<
    PickSubjectClassProps,
    SubjectDeclaration,
    ISubjectDeclarationSelectionModalArgs>(
    SubjectDeclarationSelectionModal,
    m => ({ subjectClass: m.subjectClass, selectedSubjectDeclaration: m.selected ?? null, law: m.law, isEmpty: m.isEmpty }));