// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, "._1LZo5dwAWtrGlh11Dw0g8 {\n    border: 1px solid #e1e7eb;\n    width: 100%;\n    padding: 20px 10px;\n    border-radius: 4px;\n    color: #4c4c4c;\n    font-size: 1em;\n    transition: 0.3s all;\n    background: #fff;\n    box-shadow: none;\n    font-weight: 300;\n}", ""]);
// Exports
exports.locals = {
	"eaistInput": "_1LZo5dwAWtrGlh11Dw0g8"
};
module.exports = exports;
