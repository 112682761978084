import {Provision, ProvisionStatus} from "@/models/Provision/Provision";
import { HistoryItem } from "@/models";
import { createEvent, createStore, Store } from "effector";
import {getProvision, getProvisionHistory} from "@/api/Provision";
import { uuid } from "@/models/parsing";
import {createStoredRemoteDataset, StoredRemoteDataset} from "@/components/table";

export type ProvisionStoreState = {
    provision?: Provision
    historySrd: StoredRemoteDataset<HistoryItem>
};

export type ProvisionStore = {
    store: Store<ProvisionStoreState>
};

export const createProvisionStore = (id: uuid): ProvisionStore => {
    const setProvision = createEvent<Provision>();
    const setHistory = createEvent<HistoryItem<ProvisionStatus>[]>();

    const store = createStore<ProvisionStoreState>({
        provision: undefined,
        historySrd: createStoredRemoteDataset({
            async load(filters, count, from) {
                return await getProvisionHistory(id, from, count, filters);
            }
        })
    })
        .on(setProvision, (s, provision) => ({ ...s, provision }))
        .on(setHistory, (s, history) => ({ ...s, history }));

    getProvision(id).then(setProvision);

    return {
        store
    };
};
