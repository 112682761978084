// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, "._3JRr-BIFSyKXD6b9RhZ45d:visited, ._3JRr-BIFSyKXD6b9RhZ45d:link, ._3JRr-BIFSyKXD6b9RhZ45d:active, ._3JRr-BIFSyKXD6b9RhZ45d:hover {\n    color: unset;\n    text-decoration: none;\n}", ""]);
// Exports
exports.locals = {
	"underlinedLink": "_3JRr-BIFSyKXD6b9RhZ45d"
};
module.exports = exports;
