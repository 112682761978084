















































































import { Component, Prop, Vue } from "vue-property-decorator";
import { ITableColumn } from "@/components/TableAbstractions";
import TextCellEditor from "./cell-editors/TextCellEditor.vue";
import DecimalCellEditor from "./cell-editors/DecimalCellEditor.vue";
import SelectCellEditor from "./cell-editors/SelectCellEditor.vue";
import DateCellEditor from "./cell-editors/DateCellEditor.vue";
import { IFilterValue } from "@/api/http";
import { UIFilterValue } from './FilterValue';
import { EnumStrings, SelectOption } from '@/models/enums';

@Component({ components: { TextCellEditor, DecimalCellEditor, SelectCellEditor, DateCellEditor } })
export default class DataTableFilterCell extends Vue {
    @Prop() private header!: ITableColumn<unknown>;
    @Prop() private filterValue!: UIFilterValue;
    // @Prop() private placeholder!: string

    public get filter() { return this.filterValue.filter }

    public distinctOptions(s: SelectOption<unknown>[]) {
        // thanks to js for no distinct method
        return [...new Set(s.map(({desc}) => desc))]
            .map(x => s.find(({desc}) => desc === x)); // filtering for single enum value per description
    }

    public allPossibleFiltersForDescription(s: SelectOption<unknown>[], key: string) {
        const description = s.find(x => x.key === key)!.desc;

        return s
            .filter(({desc}) => desc === description)
            .map(({key}) => key)
            .map(k => ({
                type: 'STRING',
                conditionType: 'EQUAL',
                string: k,
                boolean: null,
                longint: null,
                date: null
            }));
    }
}
