import React, { useEffect, useState } from "react";
import {VStack} from "@/components/layouts";
import {Form, FormHeader} from "@/components/form";
import {SubjectClassInfoSection} from "@/views/ReferenceInfo/SubjectClasses/sections/SubjectClassInfoSection";
import {SubjectClassDeclaration} from "@/views/ReferenceInfo/SubjectClasses/sections/SubjectClassDeclaration";
import {SubjectClassCharacteristic} from "@/views/ReferenceInfo/SubjectClasses/sections/SubjectClassCharacteristic";
import { getSubjectClassById } from "@/api/directory";
import {SubjectClassMD} from "@/models/SubjectClass/SubjectClass";
import { ChangeHistorySection } from "@/views/ComposedLot/edit/tabs/change-history/ChangeHistorySection";

type ViewSubjectClassProps = {
    id?: string | null
};

export const SubjectClassView: React.FC<ViewSubjectClassProps> = x => {
    const [subjectClass, setSubjectClass] = useState<SubjectClassMD>();
    useEffect(() => {
        if (x.id) getSubjectClassById(x.id).then(setSubjectClass);
    }, []);


    const header = <VStack spacing="15px">
        <FormHeader title={`Информация о КПГЗ - ${x.id}`}/>
    </VStack>;

    const tabs = Form.Tab("info", "Общие сведения", [
        Form.Section("Общие сведения", <SubjectClassInfoSection info={subjectClass!} />),
        Form.Section("Информацию о СПГЗ", <SubjectClassDeclaration info={subjectClass!} />),
        Form.Section("Характеристики КПГЗ", <SubjectClassCharacteristic info={subjectClass!} />),
        Form.Section("История изменений", <ChangeHistorySection />)
    ]);

    return <>
    {
        subjectClass
            ? <Form header={header} tabs={[tabs]} />
            : <></>
    }
    </>;
};