





















import { FieldAlignment, FieldWidth, valueSpanContext, FComponent } from "./FComponent";
import { Component, Prop, Vue, Inject, Mixins, Watch } from "vue-property-decorator";
import Decimal from 'decimal.js';
import { stringifyNumber } from '../../NumberFormatting';

@Component
export default class FInteger extends Mixins(FComponent) {
    @Prop({ default: "" }) public placeholder!: string;
    @Prop({ default: false }) public focus!: boolean;
    @Prop({ default: "left" }) public alignment!: FieldAlignment;
    @Prop({ default: "wide" }) public width!: FieldWidth;
    @Prop({ default: null }) public precision!: number | null;
    
    @Prop() public value!: number | null;
    public numberString = "";
    private suppressChange = false;

    public get commonProps() {
        const { alignment, width, f } = this;

        return { class: [f(alignment), f(width), ...this.classes] };
    }

    public get editableProps() {
        const { value, placeholder, disabled } = this;

        return { ...this.commonProps, value, placeholder, disabled };
    }

    public mounted() {
        this.update();
        this.setNumberString();
    }

    public updated() {
        this.update();
    }

    public update() {
        if (!this.enabled) return;
        if (!this.$refs.input) return;

        const e = this.$refs.input as HTMLInputElement;

        if (this.focus) e.focus();
    }
    
    private setNumberString() {
        this.numberString = this.value === null ? "" : this.value.toString();
    }

    @Watch("value") private updateValue() {
        if (!this.suppressChange) this.setNumberString();
        else this.suppressChange = false;
    }

    private get inputString(): string {
        return this.numberString;
    }

    private set inputString(v: string) {
        this.numberString = v;

        let num = Number(v);
        if (num !== (num | 0)) num = NaN;

        this.$emit("input", num);

        this.suppressChange = true;
    }
}
