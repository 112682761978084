import { EnumStrings } from '../EnumStrings';

export const enum GuarranteeEventResult {
    DECLINED = "DECLINED",
    ISSUES_FIXED = "ISSUES_FIXED",
    ISSUES_NOT_FIXED = "ISSUES_NOT_FIXED"
}

export const GuarranteeEventResultStrings: EnumStrings = {
    [GuarranteeEventResult.DECLINED]: "В гарантийном обслуживании отказано",
    [GuarranteeEventResult.ISSUES_NOT_FIXED]: "Недостатки не устранены",
    [GuarranteeEventResult.ISSUES_FIXED]: "Недостатки устранены",
};