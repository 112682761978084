import { IJsonFormattable } from '@/models/IJsonFormattable';
import { File } from '../Documents';
import { ObjectStatus } from '@/models/enums';
import { IDtoContractStageDissolutionInfo } from '@/models/json/Contracts/IDtoContractStageDissolutionInfo';
import { DissolutionType, DissolutionReason } from '../enums/Contracts/Dissolution';

export class ContractStageDissolutionInfo implements IJsonFormattable<IDtoContractStageDissolutionInfo> {
    constructor(
        public id: string,
        public createdAt: Date,
        public status: ObjectStatus,
        public executionDate: Date | null,
        public type: DissolutionType,
        public reason: DissolutionReason,
        public decisionNumber: string,
        public decisionDate: Date | null,
        public isDecisionElectronic: boolean,
        public decision: File,
        public isNotificationImpossible: boolean,
        public notificationDate: Date | null,
        public fasLetter: File | null,
        public fasLetterDate: Date | null,
        public fasLetterNumber: string | null,
        public isContractBreachResolved: boolean,
    ) {}
    
    public toJson(): IDtoContractStageDissolutionInfo {
        return {
            ...this,
            createdAt: this.createdAt && this.createdAt.toISOString(),
            executionDate: this.executionDate && this.executionDate.toISOString(),
            decisionDate: this.decisionDate && this.decisionDate.toISOString(),
            decision: this.decision && this.decision.toJson(),
            notificationDate: this.notificationDate && this.notificationDate.toISOString(),
            
            fasLetter: this.fasLetter && this.fasLetter.toJson(),
            fasLetterDate: this.fasLetterDate && this.fasLetterDate.toISOString(),
        };
    }
    
    public static fromJson(dto: IDtoContractStageDissolutionInfo): ContractStageDissolutionInfo {
        return Object.assign(Object.create(ContractStageDissolutionInfo.prototype), dto, {
            createdAt: new Date(dto.createdAt),
            executionDate: dto.executionDate && new Date(dto.executionDate),
            decisionDate: dto.decisionDate && new Date(dto.decisionDate),
            decision: dto.decision && File.fromJson(dto.decision),
            notificationDate: dto.notificationDate && new Date(dto.notificationDate),
            
            fasLetter: dto.fasLetter && File.fromJson(dto.fasLetter),
            fasLetterDate: dto.fasLetterDate && new Date(dto.fasLetterDate),
        });
    }
}
