import React, { useMemo, useState } from "react";
import { Card } from "@/components/Card";
import { SectionProps } from "@/views/ComposedLot/shared/store";
import { Button, CheckBox, Label, Note } from "@/components/primitive";
import { Expandee, HSpread, HStack, VStack } from "@/components/layouts";
import { Column, Table } from "@/components/table/Table";
import { showModal } from "@/EventBus";
import { NewRequiredDocumentModal } from "@/views/ComposedLot/edit/tabs/lot-documents/DocumentsSection/NewRequiredDocumentModal";
import { ViewDocumentHistoryModal } from "@/views/ComposedLot/edit/tabs/lot-documents/DocumentsSection/ViewDocumentHistoryModal";
import { AddDocumentModal } from "@/views/ComposedLot/edit/tabs/lot-documents/DocumentsSection/AddDocumentModal";
import { useMappedStore } from "@/storeUtils";
import { LotDocument } from "@/models/ComposedLots/documents/LotDocument";
import {
    addLotRequestDocument,
    addOrEditLotDocument,
    removeLotDocument,
    removeLotRequestDocument,
    resetLotRequestDocuments
} from "@/views/ComposedLot/shared/store/documents";
import {  LotRequestStageNumberStrings } from "@/models/enums/LotRequestStage";
import { LotRequestDocument } from "@/models/ComposedLots/documents";
import { LotDocumentType, LotDocumentTypeStrings } from "@/models/enums/Documents";
import { SelectionTable } from "@/components/table/SelectionTable";
import {
    createLotDocumentFromTemplate,
    createStartPriceReportDocument,
    EditableDoc
} from "@/views/form-renderers/DocumentationSection/documentation";
import { uploadLotDocument } from "@/api/ComposedLots";
import _ from "lodash";
import { DocumentWizardModal } from "@/views/ComposedLot/edit/tabs/lot-documents/DocumentsSection/wizard/DocumentWizardModal";
import { NewRequestDocumentModal } from "@/views/ComposedLot/edit/tabs/lot-documents/DocumentsSection/NewRequestDocumentModal";
import { LawType } from "@/models/enums";
import { ProviderSelectionType } from "@/models/ComposedLots";

export const requiredDocs: LotDocumentType[] = ["MSV_CALC", "MSV_PROTOCOL", "TECH_SPECS", "PROJECT_DOCS", "CONTRACT_PROJECT"];

export const DocumentsSection: React.FC<SectionProps> = x => {
    const cardHeader = <HSpread alignItems="center">
        <span>Документы</span>
    </HSpread>;

    const sectionStore = useMappedStore(x.formStore, a => ({
        requestDocuments: a.documents.lotRequestDocuments,
        lotDocuments: a.documents.lotDocuments,
        status: a.head.visibleStatus,
        lotJoinType: a.info.lotJoinType,
        lawType: a.info.law,
        year: a.info.plannedNotificationPublishYear,
        providerSelectionType: a.info.providerSelectionType,
        lot: { lotId: a.head.id!, regNumber: `${a.head.regNumber}` }
    }));

    const warningMessages = {
        "CONTRACT_PROJECT": sectionStore.lawType === "F44" ? "Проект контракта" : "Проект договора",
        "TECH_SPECS": "ТЗ",
        "MSV_CALC": "Расчет НМЦ"
    };
    const [warningMessage, setWarningMessage] = useState<string>("");

    const [selectedDocs, setSelectedDocs] = useState<LotDocument[]>([]);
    const docColumns: Column<LotDocument>[] = [
        ...((sectionStore.lotJoinType === "MASTER") ?
                [Table.Column("Наименование лота", (s) => <a rel="noreferrer" target="_blank" href={"/#/plan-objects/composed-lots/" + s.item.lot.lotId} style={{ textDecoration: "underline", cursor: "pointer", color: "unset" }}>
                    {s.item.lot.regNumber}
                </a> , {width: "3fr"})] as Column<LotDocument>[]
                :
                []
        ),
        Table.Column("Тип документа", s => <>{LotDocumentTypeStrings[s.item.type]}</>),
        ...[],
        Table.Column("Документ", s => <>{s.item.file.name}</>),
        ...((x.formAction !== "editing") ?
                [Table.Column("ЭП", () => <></>)] as Column<LotDocument>[]
                :
                []
        ),
        ...((x.formAction !== "editing") ?
                [Table.Column("Отправить в ЕИС/ЭТП", (s) => <>{s.item.sendToEis ? "Да" : "Нет"}</>)] as Column<LotDocument>[]
                :
                    [Table.Column("Отправить в ЕИС/ЭТП", (s) => <>
                        <CheckBox value={s.item.sendToEis}
                                  onChange={() => addOrEditLotDocument({oldV: s.item, newV: {...s.item, sendToEis: !s.item.sendToEis}})}
                                  disabled={!!requiredDocs.find(a => a === s.item.type)} />
                    </>)] as Column<LotDocument>[]
        ),
        Table.Column("Дата добавления", () => <></>),
        Table.Column("Размер", s => <>{s.item.file.length / 1000 + " КБ"}</>),
        Table.Column("Признак типовой документации", () => <></>),
    ];

    const getRequestDocsColumns = (year: number, lawType: LawType, providerSelectionType?: ProviderSelectionType): Column<LotRequestDocument>[] => {
        const baseRequestDocsColumns: Column<LotRequestDocument>[] = [
            Table.Column("Номер", s => <>{s.item.order}</>, {width: "70px"}),
            Table.AutoColumn("Наименование", s => <>{s.item.name}</>),
            Table.AutoColumn("Примечание", s => <>{s.item.comment}</>, {width: "40px"}),
            Table.Column("Обязательность", s => <>{s.item.required ? "Обязателен" : "Не обязателен"}</>, {width: "140px"})
        ];

        if (year >= 2022 && lawType === "F44" && providerSelectionType === "E_AUC") {
            return baseRequestDocsColumns;
        }

        return [
            ...baseRequestDocsColumns,
            Table.Column("Этап заявки", s => <>{LotRequestStageNumberStrings[s.item.stage]}</>, {width: "120px"})
        ];
    };

    const requestDocsColumns: Column<LotRequestDocument>[] = useMemo(
        () => getRequestDocsColumns(sectionStore.year, sectionStore.lawType, sectionStore.providerSelectionType),
        [sectionStore.year, sectionStore.lawType]
    );

    const requestDocsColumnsEdit: Column<LotRequestDocument>[] = [
        Table.Column("Удалить", s => <>
            {
                s.item.required ||
                <Button title={"Удалить"}
                        color={"red"}
                        icon={"aDelete"}
                        onClick={() => removeLotRequestDocument(s.item)}
                />
            }
        </>, {width: "120px"}),
        Table.Column("Ред.", s => <>
            <Button icon={"aEdit"}
                    onClick={async () => await showModal(NewRequiredDocumentModal, {pending: s.item})}
            />
        </>, {width: "60px"}),
    ];

    const addDocument = async () => {
        /** To be filled later, left these three for now */
        const disabledOptions: LotDocumentType[] = false ? _.uniqBy(sectionStore.lotDocuments, a => a.type).map(a => a.type) : ["TECH_SPECS", "CONTRACT_PROJECT"];
        const result = await showModal(AddDocumentModal,{disabledOptions: disabledOptions, lot: sectionStore.lot });
        console.log("add document result:", result);
        if (result) {
            addOrEditLotDocument({ newV: result });
        }
    };

    const addRequiredDocument = async () => {
        const result = await showModal(NewRequestDocumentModal,
            { order: sectionStore.requestDocuments.sort((a, b) => Math.sign(~~b.order - ~~a.order))[0].order });

        result && addLotRequestDocument(result);
    };

    const createAutoDoc = (type: EditableDoc) => x.makeSafe(async () => {
        const docToLotStringsTypes = {
            contract: "CONTRACT_PROJECT",
            spec: "TECH_SPECS",
            jointPurchaseAgreement: "MSV_CALC"
        };

        const lotType = docToLotStringsTypes[type] as keyof typeof warningMessages;
        if (sectionStore.lotDocuments.some(lot => lot.type === lotType)) {
            setWarningMessage(warningMessages[lotType]);
            return;
        }

        const lot = x.formStore.getState().head.instance;
        if (!lot) return;

        if (type === "contract") {
            const wizardResult: boolean | undefined = await showModal(DocumentWizardModal,
                { organization: sectionStore.lawType });
            if (!wizardResult) return;
        }

        const template = await createLotDocumentFromTemplate(type, lot);
        if (template)
            addOrEditLotDocument({ newV: await uploadLotDocument(lot.id, template) });
    });

    const createStartPriceReport = x.makeSafe(async () => {
        if (sectionStore.lotDocuments.some(lot => lot.type === "MSV_CALC")) {
            setWarningMessage(warningMessages["MSV_CALC"]);
            return;
        }
        const lot = x.formStore.getState().head.instance;
        if (!lot) return;

        const template = createStartPriceReportDocument(lot);
        addOrEditLotDocument({ newV: await uploadLotDocument(lot.id, template) });
    });

    const isAutoDocsAllowed = x.formAction === "viewing";
    return <Card title={cardHeader}>
        <VStack spacing={"25px"}>
            <Label preset={"headerLarge"}>
                Документы лота
            </Label>
            <Label preset={"readonlyValue"}>
                Сформировать документы:
            </Label>
            <HStack spacing={"10px"} innerStyle={{flexWrap: "wrap"}}>
                <Button disabled={!isAutoDocsAllowed}
                        onClick={createAutoDoc("contract")}
                        color="green"
                        title={sectionStore.lawType === "F44" ? "Проект контракта" : "Проект договора"} />
                <Button style={{width: 100}}
                        disabled={!isAutoDocsAllowed}
                        onClick={createAutoDoc("spec")}
                        color="green"
                        title="ТЗ" />
                <Button disabled={!isAutoDocsAllowed}
                        onClick={createStartPriceReport}
                        color="green"
                        title="Расчет НМЦ" />
                <Button disabled
                        color="green"
                        title="Форма 2" />
                <Button disabled
                        color="green"
                        title="Критерии оценки" />
            </HStack>
            {
                x.formAction !== "viewing" && <Note preset="alert">
                  Формирование типовых документов доступно в карточке просмотра лота
                </Note>
            }
            {
                warningMessage && <Note preset="alert">
                    {`В списке уже есть сформированный документ с типом ${warningMessage}. Необходимо удалить предыдущую версию документа перед добавлением новой.`}
                </Note>
            }

            {
                sectionStore.lotDocuments.length ?
                    <SelectionTable dataset={sectionStore.lotDocuments}
                                    columns={docColumns}
                                    selected={x.formAction === "viewing" ? [] : selectedDocs}
                                    selectorPosition={x.formAction === "viewing" ? "hidden" : undefined}
                                    onChange={x.formAction === "viewing" ? undefined : setSelectedDocs}
                                    mode="multi"/>
                    :
                    <Label>Документов нет</Label>
            }

            <HStack spacing={"10px"} innerStyle={{flexWrap: "wrap"}}>
                {
                    (x.formAction === "editing") &&
                    <>
                      <Button color={"green"}
                              icon={"aAdd"}
                              title={"Добавить"}
                              onClick={addDocument}/>
                      <Button color={"red"}
                              title={"Удалить"}
                              onClick={() => selectedDocs.forEach(a => removeLotDocument(a))}/>
                    </>
                }

                <Button color={"green"}
                        title={"Просмотреть историю изменений документов"}
                        onClick={async () => await showModal(ViewDocumentHistoryModal)}/>
            </HStack>
            <Label preset={"headerLarge"}>{sectionStore.lawType === "F44"
                ? <>Требования к содержанию, составу заявки на участие</>
                : <>Документы к заявке</>}</Label>
            <HStack>
                {
                    (x.formAction === "editing") ?
                        <Button color={"green"}
                                title={"Сбросить перечень документов"}
                                onClick={() => resetLotRequestDocuments()}/>
                        :
                        <div/>
                }

            </HStack>
            <Table<LotRequestDocument> dataset={sectionStore.requestDocuments}
                                       columns={
                                           (x.formAction === "editing") ?
                                               ([...requestDocsColumns, ...requestDocsColumnsEdit])
                                               :
                                               requestDocsColumns
                                       }/>
        </VStack>
        { x.formAction === "editing" &&
            <div style={{ marginTop: "10px" }}>
                <Button color="green"
                        icon="aAdd"
                        title="Добавить обязательный документ"
                        onClick={addRequiredDocument} />
                <Expandee />
            </div>
        }
    </Card>;
};
