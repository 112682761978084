// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, "._2HBXyp3KGr5s3ddLR3hEdA {\n    cursor: pointer;\n    display: flex;\n    flex-direction: row;\n    gap: 20px;\n    align-items: center;\n    width: 100%;\n    min-height: 40px;\n    padding-left: 10px;\n}\n\n._2HBXyp3KGr5s3ddLR3hEdA:hover {\n    background-color: #d9f1ff;\n}", ""]);
// Exports
exports.locals = {
	"addressRow": "_2HBXyp3KGr5s3ddLR3hEdA"
};
module.exports = exports;
