// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, "._3bjogbUPGv-ZtploMkVc4B {\n    display: flex;\n    min-height: 53px;\n    margin: 5px 0;\n    flex-direction: column;\n    font-family: \"Roboto\", sans-serif;\n}\n\n.KWm-owUdP67YqWj3s6s6- {\n    border: none;\n    margin-inline-start: 0;\n    margin-inline-end: 0;\n    padding-block-start: 0;\n    padding-inline-start: 0;\n    padding-inline-end: 0;\n    padding-block-end: 0;\n    min-inline-size: 0;\n    display: flex;\n    align-items: center;\n    justify-content: space-between;\n    padding: 0 10px 5px;\n}\n\n._38hfxNjlF81WMLFlWgnxiT {\n    color: #999999;\n    display: flex;\n    align-items: center;\n    font-size: 14px;\n    margin: 0;\n    padding-left: 0;\n}\n", ""]);
// Exports
exports.locals = {
	"fieldSetWrapper__wrapper": "_3bjogbUPGv-ZtploMkVc4B",
	"fieldSetWrapper__fieldset": "KWm-owUdP67YqWj3s6s6-",
	"fieldSetWrapper__label": "_38hfxNjlF81WMLFlWgnxiT"
};
module.exports = exports;
