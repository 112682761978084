




import {Component, Vue} from "vue-property-decorator";
import {CreateConclusionRequest} from "@/views/Monopoly/create/CreateConclusionRequest/CreateConclusionRequest";

@Component({ components: { komponent: CreateConclusionRequest as unknown as typeof Vue } })
export default class CreateConclusionRequestsVueStub extends Vue {
    public storeContainer = Object.freeze({ store: {} });
}
