import { Component, Vue, Mixins, Inject, Prop, Provide } from "vue-property-decorator";
import { FComponent, valueSpanContext } from './FComponent';

@Component
export class FValueComponent extends Mixins(FComponent) {
    @Inject({ from: valueSpanContext, default: () => () => "auto" }) public injectedGridColumn!: () => string;
    
    public get resolvedGridColumn() {
        return this.span 
            ? `auto / span ${this.span}`
            : this.gridColumn || this.injectedGridColumn() || `auto / span 1`;
    }
}