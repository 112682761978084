













































































import { Component, Vue, Prop } from "vue-property-decorator";
import FormTable from "@/components/vue/FormTable.vue";
import CardModal from "@/components/CardModal.vue";
import { ContractExecutionProvision, ContractDocument, ContractDocumentType, ContractExecutionHistoryEntry, ContractExecutionOperation } from '@/models/Contracts';
import { ChargeSourceStrings, ContractExecutionOperationStateStrings, ContractExecutionOperationState } from '@/models/enums/Contracts';
import { ObjectStatus } from '@/models/enums';
import EventBus, { showModal } from '../../EventBus';
import { ContractScope } from './ContractScope';
import { ContractExecutionActionStrings } from '@/models/enums/Contracts/ContractExecutionAction';

import ContractExecutionHistory from '@/api/ContractExecutionHistory';
import { hs } from '@/components/TableHelpers';
import { TransmissionStatusStrings } from '@/models/enums/Contracts/TransmissionStatus';
import { ContractExecutionOperationType, ContractExecutionOperationTypeStrings } from '@/models/enums/Contracts/ContractExecutionOperationType';
import AddDocument from './AddDocument.vue';
import { ErrorModal } from "@/views/Contracts/modals/ConfirmationModal";

@Component({ components: { FormTable, CardModal } })
export default class ExecutionOperation extends Vue {
    @Prop() private prop!: { source: ContractExecutionHistoryEntry; scope: ContractScope };

    public source = this.prop.source;
    public isEshop = this.prop.scope.contract.isEshop;

    private isSaved = false;

    public get isSigned() {
        return this.prop.scope.executionHistory.some(exec => exec.state === ContractExecutionOperationState.SIGNED);
    }

    public get documents() {
        return this.isSigned ? this.source.documents : this.source.documents.filter(doc => doc.type.id !== 16);
    }

    public get operations() {
        return this.isSigned ? this.source.operations : this.source.operations.filter(op => op.operation !== ContractExecutionOperationType.ELECTRONIC_CONTRACT_PROJECT_SENT);
    }

    public get hasProjectDocument() {
        return !!this.source.documents.find(doc => doc.type.code === "1");
    }

    mounted() {}

    public get editable() { return this.source.state === ContractExecutionOperationState.PROJECT || this.source.state === ContractExecutionOperationState.CHANGING }

    public get action() { return ContractExecutionActionStrings[this.source.action] }
    public get state() { return ContractExecutionOperationStateStrings[this.source.state] }
    public get canSend() { return !!this.source.documents.find(d => d.type.code === "1") }

    public get documentsHeaders() {
        const headers = hs<ContractDocument>(
            hs.any("№", (_, i) => (i + 1).toString(), "auto"),
            hs.any("Тип документа", d => d.type.name),
            hs.any("Номер документа", d => d.documentNumber),
            hs.date("Дата документа", d => d.date),
            hs.any("Наименование документа", d => d.file.comment),
        );

        if (this.editable) {
            headers.push(hs.slot("", () => {}, "delete-btn", "auto"));
        }

        return headers;
    }

    public operationsHeaders = hs<ContractExecutionOperation>(
        hs.any("№", (_, i) => (i + 1).toString(), "auto"),
        hs.any("Операция", d => ContractExecutionOperationTypeStrings[d.operation]),
        hs.date("Дата отправки / получения", d => d.sendReceiveDate),
        // hs.date("Крайний срок ответа", d => d.replyDeadline),
        hs.any("Оператор", d => d.operator && d.operator.fullName),
        // hs.any("Комментарий", d => d.comment),
        // hs.any("Протокол разногласий", d => ""),
        hs.any("Статус отправки", d => TransmissionStatusStrings[d.transmissionStatus]),
    );

    public provisionHeaders = hs<ContractExecutionProvision>(
        hs.any("№ в реестре", (_, i) => (i + 1).toString(), "auto"),
        hs.any("Вид обеспечения", d => ChargeSourceStrings[d.source]),
        hs.decimal("Сумма обеспечения, руб.", d => d.amountRub),
        hs.any("Срок начала действия", () => ""),
        hs.any("Срок окончания действия", () => ""),
    );

    public loading = false;

    public async save() {
        this.loading = true;
        try {
            const newSource =
                !this.source.id
                    ? await ContractExecutionHistory.create(this.prop.scope.contract.id, this.source)
                    : await ContractExecutionHistory.save(this.source);

            const ix = this.prop.scope.executionHistory.indexOf(this.source);
            if (ix === -1) {
                this.prop.scope.executionHistory.push(this.source = newSource);
            } else {
                this.prop.scope.executionHistory.splice(ix, 1, this.source = newSource);
            }

            // костыли
            this.prop.scope.contract.electronicConclusionState = "SENT";
        } finally {
            this.isSaved = true;
            this.loading = false;
        }
    }

    public async publishProject(simulateDisagreements: boolean) {
        if (!this.source.documents.length || !this.canSend) {
            return await showModal(ErrorModal, { text: `Добавьте документ с типом "Контракт"` });
        }

        this.loading = true;
        try {
            const newSource =
                this.source.id
                    ? await ContractExecutionHistory.send(this.source, simulateDisagreements, this.prop.scope.contract)
                    : await ContractExecutionHistory.createAndSend(this.prop.scope.contract.id, this.source, simulateDisagreements);

            const ix = this.prop.scope.executionHistory.indexOf(this.source);
            if (ix === -1) {
                this.prop.scope.executionHistory.push(this.source = newSource);
            } else {
                this.prop.scope.executionHistory.splice(ix, 1, this.source = newSource);
            }

            // костыли
            this.prop.scope.contract.status = ObjectStatus.CONCLUSION_PENDING;
            this.prop.scope.contract.electronicConclusionState = "SENT";
        } finally {
            this.loading = false;
        }
    }

    public async makeChanges() {
        this.loading = true;
        try {
            const newSource =
                await ContractExecutionHistory.makeChanges(this.source);

            const ix = this.prop.scope.executionHistory.indexOf(this.source);
            if (ix === -1) {
                this.prop.scope.executionHistory.push(this.source = newSource);
            } else {
                this.prop.scope.executionHistory.splice(ix, 1, this.source = newSource);
            }
        } finally {
            this.loading = false;
        }
    }

    public async cancelChanges() {
        this.loading = true;
        try {
            const newSource =
                await ContractExecutionHistory.cancelChanges(this.source);

            const ix = this.prop.scope.executionHistory.indexOf(this.source);
            if (ix === -1) {
                this.prop.scope.executionHistory.push(this.source = newSource);
            } else {
                this.prop.scope.executionHistory.splice(ix, 1, this.source = newSource);
            }
        } finally {
            this.loading = false;
        }
    }

    public async eisSign() {
        this.loading = true;
        try {
            const newSource =
                await ContractExecutionHistory.eisSign(this.source);

            const ix = this.prop.scope.executionHistory.indexOf(this.source);
            if (ix === -1) {
                this.prop.scope.executionHistory.push(this.source = newSource);
            } else {
                this.prop.scope.executionHistory.splice(ix, 1, this.source = newSource);
            }
        } finally {
            this.loading = false;
            setTimeout(() => window.location.reload(), 3000);
        }
    }

    public addEditDocument() {
        // only add now

        const props = {
            contract: this.prop.scope.contract,
            document: null,
            autoNumber: (t: ContractDocumentType) => t.id === 4 ? this.prop.scope.contract.contractNumber : null!, // TODO remove hardcode
            hasDate: (t: ContractDocumentType) => t.id !== 4, // TODO remove hardcode
            createAction: async (r: ContractDocument) => {
                this.source.documents.push(r);
            },
            documentGroup: "document",
            isExecutionOperation: true
        };

        EventBus.callModal<typeof props, ContractDocument>(AddDocument, props).then(v => v ? this.save() : {});
    }

    public deleteDocument(doc: ContractDocument) {
        const ix = this.source.documents.indexOf(doc);
        if (ix !== -1)
            this.source.documents.splice(ix, 1);
    }
}
