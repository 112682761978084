import React, { useState, useCallback, useRef } from "react";
import { Checkbox, FavoriteButton } from "@/components/eshop";
import { onSelectItem, ProductsStore, setFavorite } from "./store";
import { observer } from "mobx-react";
import styles from "./ProductsCard.module.css";
import commonStyles from "@/components/eshop/ProductCard.module.css";
import { AdditionalInfo } from "./AdditionalInfo";
import { OffersInfo } from "./OffersInfo";
import { Characteristic, Product } from "@/api/Products";
import { RubleSign } from "./RubleSign";
import { EshopLink } from "@/components/eshop/EshopLink";
import {formatNumber} from "@/NumberFormatting";

export const usePopup = () => {
    const [showPopup, setShowPopup] = useState(false);
    const showPopupRef = useRef(showPopup);
    const popupRef = useRef<HTMLDivElement>(null);

    const handleOutsideClick = useCallback((e: MouseEvent) => {
        if(!popupRef.current?.contains(e.target as (Node | null))) {
            togglePopup();
        }
    }, []);

    const togglePopup = () => {
        if(!showPopupRef.current) {
            document.addEventListener('click', handleOutsideClick, false);
        } else {
            document.removeEventListener('click', handleOutsideClick, false);
        }

        setShowPopup(!showPopupRef.current);
        showPopupRef.current = !showPopupRef.current;
    };

    return [showPopup, togglePopup, popupRef] as const;
};


export const ProductsCard = observer((props: { data: Product; store: ProductsStore }) => {
    const isSelected = (id: string) => props.store.selectedProducts.findIndex(p => p.id === id) !== -1;

    const [showAdditionalInfo, toggleShowAdditionalInfo, additionalInfoPopupRef] = usePopup();
    const [showOffersInfo, toggleShowOffersInfo, showOffersInfoPopupRef] = usePopup();
    const [cardFavorite, setCardFavorite] = useState(!!props.data.isFavorite);

    const handleSetFavorite = () => {
        setFavorite({ id: props.data.id, favorite: !cardFavorite });
        setCardFavorite(!cardFavorite);
    };

    return <div className={`${commonStyles.cardContent} ${props.store.twoColumnMode ? commonStyles.cardContentHalved : ''} `}>
        <div className={commonStyles.buttonsPanel}>
            <FavoriteButton onClick={() => handleSetFavorite()} favorite={cardFavorite} />
            <Checkbox value={isSelected(props.data.id)} onChange={() => onSelectItem(props.data)} />
        </div>
        <img className={commonStyles.tableCardImage} src={`/api/v2/products/${props.data.id}/image`} alt="picture" />
        <div className={commonStyles.itemDemandedIconContainer} title="Востребованная продукция">
            {props.data.isPopular && <div className={commonStyles.itemDemandedIcon} />}
        </div>

        <div className={commonStyles.tableCardWrapperInfo}>
            <div className={commonStyles.tableCardWrapperAllInfo} >
                {props.data.isPopular && <div className={commonStyles.isPopular}></div>}
                <div className={commonStyles.tableCardInfo}>
                    <a className={commonStyles.cardNameLink} href={`https://zakupki.mos.ru/sku/view/${props.data.steId}`}>
                        {props.data.name}
                    </a>
                    <div className={commonStyles.cardInfoRow}>
                        Производитель: <EshopLink withoutOverflow>{props.data.manufacturer}</EshopLink>
                    </div>
                    <div className={commonStyles.cardInfoRow}>
                        КПГЗ: {props.data.spgz.subjectClass.code} - {props.data.spgz.subjectClass.description}
                    </div>
                </div>

                <div className={commonStyles.offersPriceInfo}>
                    <div className={commonStyles.pricesBlock}>
                        <div className={`${styles.priceBlock} ${styles.averagePrice} `}>
                            <div className={styles.price}>
                                {props.data.referentPrice ? formatNumber(props.data.referentPrice) : '-- '}
                                <RubleSign />
                            </div>
                            <div className={styles.priceTitle}>
                                Референтная цена
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div className={styles.OffersActions}>
                <>
                    <a className={styles.productOfferButton} onClick={toggleShowAdditionalInfo}>
                        Показать характеристики
                    </a>
                    {showAdditionalInfo && props.data.characteristics &&
                        <AdditionalInfo
                            onClose={toggleShowAdditionalInfo}
                            popupRef={additionalInfoPopupRef}
                            characteristics={props.data.characteristics as Characteristic[]} />}
                </>
                <>
                    <a className={styles.productOfferButton} onClick={toggleShowOffersInfo}>
                        Показать предложения
                    </a>
                    {showOffersInfo && props.data.offers.length > 0 &&
                        <OffersInfo
                            onClose={toggleShowOffersInfo}
                            popupRef={showOffersInfoPopupRef}
                            offers={props.data.offers} />}
                </>
            </div>
        </div>
    </div>;
});

