// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, "._2wgoWvpO5h4qiSNhFf_7rk {\n    padding: 75px 0;\n    flex-direction: column;\n    justify-content: flex-start;\n    margin: 0 auto;\n    overflow: hidden;\n    display: flex;\n}", ""]);
// Exports
exports.locals = {
	"wrapper": "_2wgoWvpO5h4qiSNhFf_7rk"
};
module.exports = exports;
