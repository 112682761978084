// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, "._1z7fY8pfHvOIH3Hhp1MXNq {\n    padding-right: 28px;\n}\n\n._1sZi039Kne1Ie-ENg7B-0R{\n    min-width: 150px;\n    text-overflow: ellipsis;\n    overflow: hidden;\n}\n\n.vwozYLFyAE5-8hRRFDoqA{\n    min-width: 90px;\n}\n\n._1NUj2BuOt7SZo46U3Ii9pU {\n    min-width: 91px;\n}\n\n._10BR6T6lR5uk8PM_eYaWNe {\n    min-width: 125px;\n}\n\n._2ruw3HDbGAWErGxsEa4uUG {\n    min-width: 80px;\n}\n\n\n._2B-seXoLkewNhMezrDQQpm {\n    min-width: 1315px !important;\n}\n\n.e15sWqXG2m-MMWBQ2cvSL{\n    min-width: 30px;\n    flex: 0.5;\n}\n\n\n.Ar61t-KSq3XhcU0r4mZsa {\n    height: calc(100% - 186px);\n    display: flex;\n    flex-direction: column;\n    overflow: auto;\n}\n\n._12ga4aOIkVnUvcsgZqZN3D {\n    position: relative;\n    display: flex;\n    align-items: center;\n    cursor: pointer;\n}\n\n._3W3AlDPFRmFQCH2MovA95p {\n    background-image: url(\"https://eaist2-f.mos.ru/eshop/img/dots.44ef2665158085880288.svg\");\n    background-size: 25px;\n    height: 24px;\n    width: 25px;\n    background-repeat: no-repeat;\n}\n\n.t9ZBSJ7DHYwIII9UBhDBn {\n    display: flex;\n    flex-direction: column;\n    position: absolute;\n    right: 50%;\n    top: 50%;\n    border-radius: 5px;\n    border: solid 1px #e1e7eb;\n    background-color: #fff;\n    width: 185px;\n    list-style: none;\n}\n\n._1to4ZLVDn_itAAkB1xHqjw {\n    padding: 15px 10px;\n}\n\n\n._28NUx-WPj8jDWjGkdPuMnE {\n    padding: 0px 10px 20px 69px;\n    display: flex;\n    color: rgba(70,93,104,0.5);\n    justify-content: flex-start;\n}\n\n._1NkADua3aOhaJ5j2tpY1dr {\n    padding-bottom: 5px;\n}\n\n._3_lb0Le2TxEobhjngc4iQ9 {\n    margin: 20px 30px;\n}\n\n._3mpBnnQp2r-qdukzrLfZEc {\n    display: flex;\n    color: #a2aeb3;\n    margin-bottom: 0;\n    list-style-type: none;\n    padding-left: 0;\n    margin-top: 0;\n    align-items: flex-start;\n}", ""]);
// Exports
exports.locals = {
	"controlContainerSessions": "_1z7fY8pfHvOIH3Hhp1MXNq",
	"sessionProductName": "_1sZi039Kne1Ie-ENg7B-0R",
	"sessionNumber": "vwozYLFyAE5-8hRRFDoqA",
	"sessionStatus": "_1NUj2BuOt7SZo46U3Ii9pU",
	"sessionDate": "_10BR6T6lR5uk8PM_eYaWNe",
	"sessionLaw": "_2ruw3HDbGAWErGxsEa4uUG",
	"sessionsTable": "_2B-seXoLkewNhMezrDQQpm",
	"sessionAction": "e15sWqXG2m-MMWBQ2cvSL",
	"offersTableSessions": "Ar61t-KSq3XhcU0r4mZsa",
	"eshopTooltipContainer": "_12ga4aOIkVnUvcsgZqZN3D",
	"eshopTooltipIcon": "_3W3AlDPFRmFQCH2MovA95p",
	"eshopTooltip": "t9ZBSJ7DHYwIII9UBhDBn",
	"eshopTooltipElement": "_1to4ZLVDn_itAAkB1xHqjw",
	"filtersContainerSessions": "_28NUx-WPj8jDWjGkdPuMnE",
	"filtersDate": "_1NkADua3aOhaJ5j2tpY1dr",
	"pager": "_3_lb0Le2TxEobhjngc4iQ9",
	"headerContainer": "_3mpBnnQp2r-qdukzrLfZEc"
};
module.exports = exports;
