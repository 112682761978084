import React from "react";
import {Card} from "@/components/Card";
import {Label} from "@/components/primitive";
import router from "@/router";

export const CustomersRoles: React.FC = x => {
    return <Card>
        <Label onClick={() => router.push("/")} style={{ textDecoration: "underline", cursor: "pointer" }}>
            Заказчик
        </Label>
    </Card>;
};