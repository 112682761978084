









































































import CardModal from "@/components/CardModal.vue";
import { IRow, IColumn } from "@/components/vue/form-table/FormFields";
import FormTable from "@/components/vue/FormTable.vue";
import { SupplyStage } from "@/models";
import { createYearSelection, DayType, SupplyStagePeriodType } from "@/models/enums";
import { ModalResult, ModalVue } from "@/view-models/ModalRequest";
import { Decimal } from "decimal.js";
import { Component, Prop, Vue } from "vue-property-decorator";
import { ISupplyStageModalArgs } from "../modals/ModalArgs";
import { ContractSpecificationSupplyStage } from '@/models/Contracts/ContractSpecificationSupplyStage';
import { showModal } from "@/EventBus";
import { ConfirmationModal } from "@/views/Contracts/modals/ConfirmationModal";
import Label from "@/components/vue/primitive/Label.vue";
import { addBusinessDays, addDays } from "date-fns";
import { Contract } from "@/models/Contracts";
import { ContractSpecification } from "@/models/Contracts/ContractSpecification";

@Component({ components: {Label, CardModal, FormTable } })
export default class AddSupplyStage extends Vue {
    @Prop() private prop!: { contract: Contract; source: ContractSpecificationSupplyStage; action(_: ContractSpecificationSupplyStage | ContractSpecificationSupplyStage[]): Promise<void>; hideVolume: boolean; isBulk: boolean | null; selectedItems?: ContractSpecification[] };

    private get stage() {
        return this.prop.source;
    }

    public selection: ContractSpecificationSupplyStage = new ContractSpecificationSupplyStage(
        this.stage ? this.stage.id : null!,
        this.stage ? this.stage.specificationId : null!,
        this.stage ? this.stage.volume : new Decimal(0),
        this.stage ? this.stage.type : SupplyStagePeriodType.RELATIVE,
        {
            startOffset: this.stage ? this.stage.relativePeriod.startOffset : null,
            startOffsetType: this.stage ? this.stage.relativePeriod.startOffsetType : DayType.NORMAL,
            endOffset: this.stage ? this.stage.relativePeriod.endOffset : null,
            endOffsetType: this.stage ? this.stage.relativePeriod.endOffsetType : DayType.NORMAL,
            finishYear: this.stage ? this.stage.relativePeriod.finishYear : null,
        },
        {
            startDate: this.stage ? this.stage.absolutePeriod.startDate : null,
            endDate: this.stage ? this.stage.absolutePeriod.endDate : null,
        },
        this.stage ? this.stage.conditions : "в соответствии с проектом Контракта и Техническим заданием",
        null
    );

    public stages: ContractSpecificationSupplyStage[]  = [];

    public fillStages() {       
            this.prop.selectedItems!.forEach(selectedSpec => selectedSpec.stages.forEach(stage => {
            this.stages.push(new ContractSpecificationSupplyStage(stage.id, stage.specificationId, stage.volume, this.selection.type,{
                startOffset: this.selection.relativePeriod.startOffset,
                startOffsetType: this.selection.relativePeriod.startOffsetType,
                endOffset: this.selection.relativePeriod.endOffset,
                endOffsetType: this.selection.relativePeriod.endOffsetType,
                finishYear: this.selection.relativePeriod.finishYear
                },{startDate: this.selection.absolutePeriod.startDate, endDate: this.selection.absolutePeriod.endDate}, this.selection.conditions, stage.address));
            }));
    }

    public formColumns: IColumn<ContractSpecificationSupplyStage>[] = [
        {
            rows: [
                {
                    title: "Объём поставки",
                    type: "DECIMAL",
                    style: "SHORT",

                    getter(src) {
                        return src.volume;
                    },
                    setter(src, value: Decimal) {
                        src.volume = value;
                    },
                },
            ],
        },
    ];

    public relative: IRow<ContractSpecificationSupplyStage>[] = [
        {
            title: "Начало поставки",
            type: "INTEGER",
            fieldDescription: "дней с момента заключения контракта",

            getter(src) {
                return src.relativePeriod.startOffset;
            },
            setter(src, value: number) {
                src.relativePeriod.startOffset = value;
            },
        },
        {
            title: "Расчетная дата начала поставки:",
            type: "DATE_FIELD",

            getter: (src) => {
                if (src.relativePeriod.startOffset !== null) {
                    switch (src.relativePeriod.startOffsetType) {
                        case DayType.NORMAL:
                            return this.prop.contract.conclusionDate && addDays(this.prop.contract.conclusionDate, src.relativePeriod.startOffset);
                        case DayType.WORKDAY:
                            return this.prop.contract.conclusionDate && addBusinessDays(this.prop.contract.conclusionDate, src.relativePeriod.startOffset);
                    }
                }
            },
            setter(obj: ContractSpecificationSupplyStage, value: never) {
            },
            editable: false,
            readonly: true
        },
        {
            title: "",
            type: "INLINE_RADIO_SELECT",
            groupName: "startDayType",

            selectOptions: [
                { key: DayType.WORKDAY, desc: "Рабочие дни" },
                { key: DayType.NORMAL, desc: "Календарные дни" },
            ],

            getter(src) {
                return src.relativePeriod.startOffsetType;
            },
            setter(src, value: DayType) {
                src.relativePeriod.startOffsetType = value;
            },
        },
        {
            title: "Окончание поставки",
            type: "INTEGER",
            fieldDescription: "дней с момента заключения контракта",

            getter(src) {
                return src.relativePeriod.endOffset;
            },
            setter(src, value: number) {
                src.relativePeriod.endOffset = value;
            },
        },
        {
            title: "Расчетная дата окончания поставки:",
            type: "DATE_FIELD",

            getter: (src) => {
                if (src.relativePeriod.endOffset !== null) {
                    switch (src.relativePeriod.endOffsetType) {
                        case DayType.NORMAL:
                            return this.prop.contract.conclusionDate && addDays(this.prop.contract.conclusionDate, src.relativePeriod.endOffset);
                        case DayType.WORKDAY:
                            return this.prop.contract.conclusionDate && addBusinessDays(this.prop.contract.conclusionDate, src.relativePeriod.endOffset);
                    }
                }
            },
            setter(obj: ContractSpecificationSupplyStage, value: never) {
            },
            editable: false,
            readonly: true
        },
        {
            title: "",
            type: "INLINE_RADIO_SELECT",
            groupName: "endDayType",

            selectOptions: [
                { key: DayType.WORKDAY, desc: "Рабочие дни" },
                { key: DayType.NORMAL, desc: "Календарные дни" },
            ],

            getter(src) {
                return src.relativePeriod.endOffsetType;
            },
            setter(src, value: DayType) {
                src.relativePeriod.endOffsetType = value;
            },
        },
        {
            title: "Год окончания поставки",
            type: "SELECT",

            selectOptions: createYearSelection(true),

            getter(src) {
                return src.relativePeriod.finishYear + "";
            }, // todo
            setter(src, value: string) {
                src.relativePeriod.finishYear = value ? Number(value) : null;
            },
        },
    ];

    public absolute: IRow<ContractSpecificationSupplyStage>[] = [
        {
            title: "Дата начала поставки",
            type: "DATE_FIELD",
            style: "SHORT",

            getter(src) {
                return src.absolutePeriod.startDate;
            },
            setter(src, value: Date) {
                src.absolutePeriod.startDate = value;
            },
        },
        {
            title: "Дата окончания поставки",
            type: "DATE_FIELD",
            style: "SHORT",

            getter(src) {
                return src.absolutePeriod.endDate;
            },
            setter(src, value: Date) {
                src.absolutePeriod.endDate = value;
            },
        },
    ];

    public conditions: IRow<ContractSpecificationSupplyStage>[] = [
        {
            title: "Условия поставки",
            type: "TEXT",

            getter(src) {
                return src.conditions;
            },
            setter(src, value: string) {
                src.conditions = value;
            },
        }
    ];

    public loading = false;

    public async selectItemAndExit() {
        if (this.prop.isBulk) {
            const confirm = await showModal(ConfirmationModal, {
                title: "Подтверждение изменения этапов поставок",
                text: `Вы действительно хотите изменить график поставки для ${this.prop.selectedItems && this.prop.selectedItems.length ? "выбранных" : "всех"} спецификаций контракта?`
            });
            if (!confirm)
                return;
        }

        if (this.isOk) {
            this.loading = true;

            if(this.prop.selectedItems && this.prop.selectedItems.length) {
                try {
                this.fillStages();
                const result = await this.prop.action(this.stages);
                this.$emit("close-modal", result);
                } finally {
                    this.loading = false;
                }
            } else {
                try {
                    const result = await this.prop.action(this.selection);
                    this.$emit("close-modal", result);
                } finally {
                    this.loading = false;
                }
            }
        }
    }

    public selectSupplyTermsGroup(v: SupplyStagePeriodType) {
        this.selection.type = v;
        
        switch (v) {
            case SupplyStagePeriodType.ABSOLUTE:
                this.selection.relativePeriod.startOffset = null;
                this.selection.relativePeriod.startOffsetType = DayType.NORMAL;
                this.selection.relativePeriod.endOffset = null;
                this.selection.relativePeriod.endOffsetType = DayType.NORMAL;
                this.selection.relativePeriod.finishYear = null;
                break;
            case SupplyStagePeriodType.RELATIVE:
                this.selection.absolutePeriod.endDate = null;
                this.selection.absolutePeriod.startDate = null;
                break;
        }
    }

    public get isOk(): boolean {
        return (
            (this.selection.type === SupplyStagePeriodType.ABSOLUTE
                ? this.selection.absolutePeriod.startDate !== null &&
                  this.selection.absolutePeriod.endDate !== null &&
                  +this.selection.absolutePeriod.endDate >= +this.selection.absolutePeriod.startDate
                : this.selection.relativePeriod.endOffset !== null &&
                  this.selection.relativePeriod.endOffset > 0 &&
                  this.selection.relativePeriod.startOffset !== null &&
                  this.selection.relativePeriod.startOffset >= 0 &&
                  this.selection.relativePeriod.endOffset >= this.selection.relativePeriod.startOffset &&
                  this.selection.relativePeriod.finishYear !== null) &&
            !!this.selection.conditions &&
            this.selection.conditions.trim().length > 0
        );
    }
}
