import { http } from "./http";
import { ContractEconomy } from '@/models/Contracts';
import { IDtoContractEconomy } from '@/models/json/Contracts';

export default class {
    public static async getFromContract(contractId: string): Promise<ContractEconomy[]> {
        return (await http.get(`/api/contractEconomies/fromContract/${contractId}`, undefined) as IDtoContractEconomy[])
            .map(ContractEconomy.fromJson);
    }

    public static async create(contractId: string, src: ContractEconomy): Promise<ContractEconomy> {
        return ContractEconomy.fromJson(await http.post(`/api/contractEconomies/createFor/${contractId}`, src.toJson()));
    }

    public static async update(obj: ContractEconomy): Promise<ContractEconomy> {
        return ContractEconomy.fromJson(await http.put(`/api/contractEconomies/${obj.id}`, obj.toJson()));
    }

    public static async delete(obj: ContractEconomy): Promise<void> {
        await http.delete(`/api/contractEconomies/${obj.id}`);
    }
}
