import { ContractExecutionOperation, ContractDocument } from "@/models/Contracts";
import { ContractExecutionOperationState } from "@/models/enums/Contracts";
import { IDtoContractExecutionHistoryEntry } from '@/models/json/Contracts';
import { IJsonFormattable } from '@/models/IJsonFormattable';
import { ContractExecutionAction } from '../enums/Contracts/ContractExecutionAction';
import { ContractPriceEditReason } from "@/models/enums/Contracts/ContractPriceEditReason";
import {ContractDisagreementsResult} from "@/models/enums/Contracts/ContractDisagreementsResult";

export class ContractExecutionHistoryEntry implements IJsonFormattable<IDtoContractExecutionHistoryEntry> {
    constructor(
        public id: string,
        public createdAt: Date,
        public action: ContractExecutionAction,
        public maxResponseDate: Date | null,
        public startDate: Date | null,
        public endDate: Date | null,
        public state: ContractExecutionOperationState,
        public contractPriceEditReason: ContractPriceEditReason,
        public contractDisagreementsResult: ContractDisagreementsResult | null,
        public documents: Array<ContractDocument>,
        public operations: Array<ContractExecutionOperation>
    ) {}
    
    public toJson(): IDtoContractExecutionHistoryEntry {
        return {
            id: this.id,
            createdAt: this.createdAt && this.createdAt.toISOString(),
            action: this.action,
            maxResponseDate: this.maxResponseDate && this.maxResponseDate.toISOString(),
            startDate: this.startDate && this.startDate.toISOString(),
            endDate: this.endDate && this.endDate.toISOString(),
            state: this.state,
            contractPriceEditReason: this.contractPriceEditReason,
            documents: this.documents.map(x => x.toJson()),
            operations: this.operations.map(x => x.toJson())
        };
    }
    
    public static fromJson(dto: IDtoContractExecutionHistoryEntry): ContractExecutionHistoryEntry {
        return Object.assign(Object.create(ContractExecutionHistoryEntry.prototype), {
            id: dto.id,
            createdAt: new Date(dto.createdAt),
            action: dto.action,
            maxResponseDate: dto.maxResponseDate && new Date(dto.maxResponseDate),
            startDate: dto.startDate && new Date(dto.startDate),
            endDate: dto.endDate && new Date(dto.endDate),
            state: dto.state,
            contractPriceEditReason: dto.contractPriceEditReason,
            documents: dto.documents.map(x => ContractDocument.fromJson(x)),
            operations: dto.operations.map(x => ContractExecutionOperation.fromJson(x)).sort((a, b) => +b.createdAt - +a.createdAt)
        });
    }
}
