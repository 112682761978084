import { IJsonFormattable } from "@/models/IJsonFormattable";
import { IDtoDateTimeRange } from './json/IDtoDateTimeRange';

export class DateTimeRange implements IJsonFormattable<IDtoDateTimeRange> {
    constructor(
        public from: Date | null,
        public to: Date | null
    ) {}

    public toJson(): IDtoDateTimeRange {
        return { 
            from: this.from ? this.from.toISOString() : null,
            to: this.to ? this.to.toISOString() : null
        };
    }

    // noinspection JSUnusedGlobalSymbols
    public static fromJson(dto: IDtoDateTimeRange): DateTimeRange {
        return new DateTimeRange(
            dto.from ? new Date(dto.from) : null,
            dto.to ? new Date(dto.to) : null
       );
    }
}