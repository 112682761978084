import { IDtoContractDocumentType } from '@/models/json/Contracts';
import { IJsonFormattable } from '@/models/IJsonFormattable';

export class ContractDocumentType implements IJsonFormattable<IDtoContractDocumentType> {
    constructor(
        public id: number,
        public name: string,
        public groupCode: string,
        public code: string
    ) {}

    public toJson(): IDtoContractDocumentType {
        return {
            id: this.id,
            name: this.name,
            groupCode: this.groupCode,
            code: this.code
        };
    }

    public static fromJson(dto: IDtoContractDocumentType): ContractDocumentType {
        return Object.assign(Object.create(ContractDocumentType.prototype), {
            id: dto.id,
            name: dto.name,
            groupCode: dto.groupCode,
            code: dto.code
        });
    }
}
