import { http } from "@/api/http";
import { DtoPlan, Plan } from "@/models/Plans/Plan";
import { LawType } from "@/models/enums";
import { uuid } from "@/models/parsing";
import { ProcedureStatus } from "@/models/Procedures2020/Procedure";
import { DtoHistoryItem, HistoryItem } from "@/models";

export const getPlans = async (year: number): Promise<Plan[]> => {
    return ((await http.get(`/api/v2/plans?year=${year}`)) as DtoPlan[]).map(Plan.fromDto);
};

export const getPlan = async (id: uuid): Promise<Plan> => {
    return Plan.fromDto(await http.get("/api/v2/plans/" + id));
};

export const createPlan = async (law: LawType, periodStartYear: number): Promise<Plan> => {
    return Plan.fromDto(await http.post(`/api/v2/plans?law=${law}&periodStartYear=${periodStartYear}`, null));
};

export const approvePlan = async (id: uuid): Promise<Plan> => {
    return Plan.fromDto(await http.post(`/api/v2/plans/${id}/approve`, null));
};

export const sendPlanToFo = async (id: uuid): Promise<Plan> => {
    return Plan.fromDto(await http.post(`/api/v2/plans/${id}/sendToFo`, null));
};

export const publishPlan = async (id: uuid): Promise<Plan> => {
    return Plan.fromDto(await http.post(`/api/v2/plans/${id}/publish`, null));
};

export const deletePlan = async (id: uuid): Promise<void> => {
    await http.delete(`/api/v2/plans/${id}`);
};

export const getPlanHistory = async (id: uuid): Promise<HistoryItem<ProcedureStatus>[]> =>
    (await http.get(`/api/v2/plans/${id}/history`) as DtoHistoryItem<ProcedureStatus>[]).map(a => HistoryItem.fromDto(a));

export const getArchivedPlans = async (year: number): Promise<Plan[]> => {
    return ((await http.get(`/api/v2/plans/archived?year=${year}`)) as DtoPlan[]).map(Plan.fromDto);
};
