import {Decimal} from 'decimal.js';
import {DayType, SupplyStagePeriodType} from "@/models/enums";
import {isEqual} from "date-fns";
import { Address, DtoAddress } from "@/models/ComposedLots/specifications";
import { applyN } from "@/models/parsing";

export interface SupplyStageCommon {
    readonly volume: Decimal
    readonly type: SupplyStagePeriodType
    readonly conditions: string
    readonly address: Address
}

export type SupplyStage = SupplyStageCommon & ({
    readonly type: "RELATIVE"
    readonly startOffset: number
    readonly startOffsetType: DayType
    readonly endOffset: number
    readonly endOffsetType: DayType
    readonly finishYear: number
} | {
    readonly type: "ABSOLUTE"
    readonly startDate: Date
    readonly endDate: Date
});

interface DtoSupplyStageCommon {
    readonly volume: string
    readonly type: SupplyStagePeriodType
    readonly conditions: string
    address: DtoAddress
}

export type DtoSupplyStage = DtoSupplyStageCommon & ({
    readonly type: "RELATIVE"
    readonly relativePeriod: {
        readonly startOffset: number
        readonly startOffsetType: DayType
        readonly endOffset: number
        readonly endOffsetType: DayType
        readonly finishYear: number
    }
} | {
    readonly type: "ABSOLUTE"
    readonly absolutePeriod: {
        readonly startDate: string
        readonly endDate: string
    }
});

export const SupplyStage = {
    toDto(info: SupplyStage): DtoSupplyStage {
        return info.type === "ABSOLUTE"
            ? {
                type: "ABSOLUTE",
                volume: info.volume.toString(),
                conditions: info.conditions,
                absolutePeriod: {
                    startDate: info.startDate.toISOString(),
                    endDate: info.endDate.toISOString()
                },
                address: applyN(Address.toDto, info.address)
            }
            : {
                type: "RELATIVE",
                volume: info.volume.toString(),
                conditions: info.conditions,
                relativePeriod: {
                    startOffset: info.startOffset,
                    startOffsetType: info.startOffsetType,
                    endOffset: info.endOffset,
                    endOffsetType: info.endOffsetType,
                    finishYear: info.finishYear
                },
                address: applyN(Address.toDto, info.address)
            };
    },
    fromDto(info: DtoSupplyStage): SupplyStage {
        return info.type === "RELATIVE"
            ? {
                type: "RELATIVE",
                volume: new Decimal(info.volume),
                conditions: info.conditions,
                address: applyN(Address.fromDto, info.address),
                ...info.relativePeriod
            }
            : {
                type: "ABSOLUTE",
                volume: new Decimal(info.volume),
                conditions: info.conditions,
                address: applyN(Address.fromDto, info.address),
                startDate: new Date(info.absolutePeriod.startDate),
                endDate: new Date(info.absolutePeriod.endDate),
            };
    }
};

export const compareSupplyStageByDate = (firstSupplyStage: SupplyStage, secondSupplyStage: SupplyStage): boolean => {
    if (firstSupplyStage.type != secondSupplyStage.type) {
        return false;
    }

    if (firstSupplyStage.type == "RELATIVE" && secondSupplyStage.type == "RELATIVE") {
        return firstSupplyStage.endOffset == secondSupplyStage.endOffset && firstSupplyStage.startOffset == secondSupplyStage.startOffset;
    } else if (firstSupplyStage.type == "ABSOLUTE" && secondSupplyStage.type == "ABSOLUTE") {
        return isEqual(firstSupplyStage.endDate, secondSupplyStage.endDate) && isEqual(firstSupplyStage.startDate, secondSupplyStage.startDate);
    }
    return false;
};
