









import { FComponent } from "@/components/next/FComponent";
import { Component, Mixins, Prop } from "vue-property-decorator";

@Component
export default class FSection extends Mixins(FComponent) {
    @Prop() public name?: string;
    @Prop({default: true}) public visible!: boolean;
}
