// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, "._3P6HsW7dmfS-tQ5YudN_zl {\n    font-size: 14px;\n    padding: 8px 0 8px 8px;\n    border: 1px solid #ccc;\n    cursor: pointer;\n    text-decoration: underline;\n}\n\n._3TLfWKBgbJyS5M7ulfKkpo {\n    display: inline;\n    margin-left: 8px;\n}", ""]);
// Exports
exports.locals = {
	"treeNode": "_3P6HsW7dmfS-tQ5YudN_zl",
	"inner": "_3TLfWKBgbJyS5M7ulfKkpo"
};
module.exports = exports;
