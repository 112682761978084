












































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































import { DocumentTable } from "@/form-declarations/documentation";
import { Component, Vue, Prop } from "vue-property-decorator";
import DocumentTextField from "@/components/DocumentTextField.vue";
import DocumentSelect from "@/components/DocumentSelect.vue";

@Component({ components: { DocumentTextField, DocumentSelect } })
export default class TechSpec1 extends Vue {
    @Prop({ default: () => ({}) }) private table!: DocumentTable;
}
