import React, {useState} from "react";
import {Card} from "@/components/Card";
import {SectionProps} from "@/views/ComposedLot/shared/store";
import {Button, ComboBox} from "@/components/primitive";
import {Expandee, HSpread, HStack, VStack} from "@/components/layouts";
import {Field, Fields} from "@/components/form/Field";
import {
    createStoredRemoteDataset,
    FilterColumn,
    FilteredSelectionTable,
    Pager,
    useStoredRemoteDataset
} from "@/components/table";
import {FinancialLimit} from "@/models/ComposedLots/FinancialLimit";
import FinancialSources from "@/api/FinancialSources";
import {FilterConditionType, FilterValueType} from "@/api/http";
import {Lot, ProviderSelectionTypeStrings} from "@/models/ComposedLots";
import {FinancialSourceType, LawType, LawTypeStrings} from "@/models/enums";
import {formatNumber} from "@/NumberFormatting";
import {applyN} from "@/models/parsing";
import {formatDate} from "@/dateUtils";
import {IPagedItems} from "@/api/Pagination";

export const GoodsListSection: React.FC<SectionProps> = x => {
    const [filtersVisible, setFiltersVisible] = useState(true);
    const cardHeader = <HStack alignItems="center" spacing="10px">
        <span>Список товаров для формы 2</span>
        <Expandee />
        <Button icon={"aFilter"} onClick={() => setFiltersVisible(!filtersVisible)} />
        <Button title={"Обновить"} />
    </HStack>;

    type Filters = {
        name: string
        parameter: string
        value: string
        unit: string
    };

    const [remoteDatasetStore] = useState(() => createStoredRemoteDataset<string, Filters>({
        pageSize: 10,
        initialFilters: { name: "", parameter: "", value: "", unit: "" } as Filters,
        async load(filters, from, count) {
            const result: string[] = ["asd"];

            return {
                from: 0,
                to: 10,
                totalCount: 0,
                items: result
            } as IPagedItems<string>;
        }
    }));
    const remoteDataset = useStoredRemoteDataset(remoteDatasetStore);

    return <Card title={cardHeader}>
        <VStack spacing="15px">
            <FilteredSelectionTable<string, Filters> dataset={remoteDataset.dataset}
                                            isFiltersVisible={filtersVisible}
                                            columns={[
                                                FilterColumn({
                                                    header: "Название",
                                                    /* TODO: make this table display actual data */
                                                    filter: {type: "string", key: "name"},
                                                    cell: x => <></>
                                                }),
                                                FilterColumn({
                                                    header: "Требуемый параметр",
                                                    /* TODO: make this table display actual data */
                                                    filter: {type: "string", key: "parameter"},
                                                    cell: x => <></>
                                                }),
                                                FilterColumn({
                                                    header: "Требуемое значение",
                                                    /* TODO: make this table display actual data */
                                                    filter: {type: "string", key: "value"},
                                                    cell: x => <></>
                                                }),
                                                FilterColumn({
                                                    header: "Единица измерения",
                                                    /* TODO: make this table display actual data */
                                                    filter: {type: "string", key: "unit"},
                                                    cell: x => <></>,
                                                })
                                            ]}
                                            /* TODO: make this table display actual data */
                                            /* eslint-disable-next-line @typescript-eslint/ban-ts-ignore */
                                            //@ts-ignore
                                            filterStore={remoteDataset.filterStore}
                                            mode="single"
                                            selectorPosition="hidden"  />
            <Pager remoteDataset={remoteDataset}/>
        </VStack>
    </Card>;
};