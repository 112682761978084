import React, { useState } from "react";
import {
    changeSortBy,
    ProductsStore,
    ProductsFilterStore,
    toggleTwoColumnMode,
    toggleSortDescending,
    goCreate,
    unselectAll,
    createJointSession
} from './store';
import { ContainerButtons } from "@/components/eshop";
import { Select } from "@/components/eshop/Select/Select";
import { TooltipIcon } from "@/components/TooltipIcon";
import styles from "@/components/eshop/Header.module.css";
import { SortBy, sortByOptions } from "@/api/filterHelpers";
import { addProductToContract } from "./store";
import { SelectedInfo } from "./SelectedInfo";
import { usePopup } from "./ProductsCard";

export const Header = (props: { store: ProductsStore; filterStore: ProductsFilterStore }) => {
    const selectedProducts = props.store.selectedProducts;
    const disableCreateContract = selectedProducts.some(p => p.isPopular === false);

    const [showSelected, toggleShowSelected, selectedPopupRef] = usePopup();
    const [sortBy, setSortBy] = useState<SortBy | null>("stuNumber");
    const updateSortBy = (option: SortBy | null) => {
        changeSortBy(option);
        setSortBy(option);
    };

    const anySelected = !!props.store.selectedProducts.length;
    return <div className={styles.filtersHeader}>
        <ContainerButtons
            onCreateJointSession={() => createJointSession()}
            showCreateJointSession
            disableCreateJointSession={false}
            onCreateSession={() => goCreate()}
            disableCreateSession={props.store.selectedProducts.length === 0}
            onCreateContract={() => addProductToContract(selectedProducts)}
            disableCreateContract={disableCreateContract || selectedProducts.length === 0}
            showCreateContract
            showCreateSession/>
        <div className={styles.panelSection}
            onMouseLeave={() => anySelected && toggleShowSelected()}
            onMouseEnter={() => anySelected && toggleShowSelected()}
        >
            Выбрано: {props.store.selectedProducts.length} из {props.store.page.totalCount}
            {showSelected &&
                <SelectedInfo
                    unselectAll={() => unselectAll()}
                    items={props.store.selectedProducts}
                    onClose={toggleShowSelected}
                    popupRef={selectedPopupRef} />}
        </div>
        <div className={styles.panelSection}>
            <span className={styles.sortTitle}>
                Сортировка
            </span>
            <Select value={sortBy || ''} onSelect={option => updateSortBy(option as SortBy || null)} options={sortByOptions} />
            <button onClick={() => toggleSortDescending()}
                className={`${styles.sortAscButton} ${props.filterStore.sortDescending ? styles.sortDesc : styles.sortAsc}`} />
        </div>
        <div className={styles.panelSection}>
            <div className={`${styles.iconButtonOneCol} ${!props.store.twoColumnMode ? styles.iconButtonOneColEnabled : ''}`} onClick={() => toggleTwoColumnMode()} />
            <div className={`${styles.iconButtonTwoCol} ${props.store.twoColumnMode ? styles.iconButtonTwoColEnabled : ''}`} onClick={() => toggleTwoColumnMode()} />
            <TooltipIcon />
        </div>
    </div>;
};
