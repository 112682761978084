// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, "._35pHMxbK9pW1QTLlmZXA4d > *:first-child {\n    margin-top: calc(0.5 * var(--spacing-vertical));\n    margin-bottom: unset;\n}\n\n._35pHMxbK9pW1QTLlmZXA4d > *:nth-child(2n + 3) {\n    margin-top: 20px;\n    margin-bottom: unset;\n}\n\n._35pHMxbK9pW1QTLlmZXA4d > *:nth-child(2n) {\n    margin-top: 5px;\n    margin-bottom: unset;\n}\n\n._35pHMxbK9pW1QTLlmZXA4d > *:last-child {\n    margin-bottom: calc(0.5 * var(--spacing-vertical));\n}\n\n._2QR-vnGLAubCZcUoO5hRKo {\n    grid-column: span 2;\n}\n\n._39uu1drr8ruJYbH0sxieVk {\n    display: none;\n}\n\n._35pHMxbK9pW1QTLlmZXA4d > ._39uu1drr8ruJYbH0sxieVk {\n    display: block;\n    margin-top: 0;\n    margin-bottom: 0;\n}", ""]);
// Exports
exports.locals = {
	"fieldStack": "_35pHMxbK9pW1QTLlmZXA4d",
	"fieldInset": "_2QR-vnGLAubCZcUoO5hRKo",
	"fieldInsetAfter": "_39uu1drr8ruJYbH0sxieVk"
};
module.exports = exports;
