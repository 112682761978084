import {Card} from "@/components/Card";
import {FilterColumn, FilteredSelectionTable, Pager, RemoteDataset} from "@/components/table";
import {Lot, ProviderSelectionTypeStrings} from "@/models/ComposedLots";
import {LawTypeStrings} from "@/models/enums";
import {formatNumber} from "@/NumberFormatting";
import React from "react";
import {applyN} from "@/models/parsing";
import {formatDate} from "@/dateUtils";
import { getTotalPaymentAmount } from "@/views/ComposedLot/shared/store/plannedPayments";

export const LotsTable = (x: { rds: RemoteDataset<Lot>; top?: React.ReactNode }) => <>
    <Card title="Лоты">
        {x.top}
        <FilteredSelectionTable<Lot> dataset={x.rds.dataset} isFiltersVisible={false} columns={[
            FilterColumn({
                header: "Реестровый номер",
                cell: x => <>{x.item.regNumber}</>
            }),
            FilterColumn({
                header: "Закон-основание",
                cell: x => <>{LawTypeStrings[x.item.law]}</>
            }),
            FilterColumn({
                header: "Наименование лота",
                cell: x => <>{x.item.basicInfo.subject}</>
            }),
            FilterColumn({
                header: "НМЦ контракта",
                cell: x => <>{x.item.basicInfo.providerSelectionType !== "SINGLE_PROVIDER_PURCHASE"
                    ? formatNumber(
                        x.item.includedLots.length > 0
                            ? x.item.includedLots.reduce((p, v) => p.add(v.totalPrice), x.item.totalPrice) : x.item.totalPrice)
                            : formatNumber(getTotalPaymentAmount(x.item.plannedPayments))}</>,
            }),
            FilterColumn({
                header: "Способ определения поставщика",
                cell: x => <>{ProviderSelectionTypeStrings[x.item.basicInfo.providerSelectionType]}</>
            }),
            FilterColumn({
                header: "Дата начала закупки",
                cell: x => <>{applyN(formatDate, x.item.basicInfo.plannedPublishDate)}</>,
            }),
            FilterColumn({
                header: "Размещение среди СМП/МСП",
                cell: x => <>{
                    x.item.basicInfo.smpType === "SUBCONTRACTING"
                        ? formatNumber(x.item.basicInfo.smpPercent) + "%"
                        : ProviderSelectionTypeStrings[x.item.basicInfo.providerSelectionType]}</>
            }),
            FilterColumn({
                header: "Замечание",
                cell: () => <></>
            }),
        ]} filterStore={x.rds.filterStore} mode="single" selectorPosition="hidden"  />
    </Card>
    <Pager remoteDataset={x.rds} />
</>;
