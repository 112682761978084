













import CardModal from "@/components/CardModal.vue";
import DataTable from "@/components/vue/DataTable.vue";
import { EditType, IRowConfiguration, ITableColumn, SingleSelection } from "@/components/TableAbstractions";
import { Vue, Component, Prop } from "vue-property-decorator";
import { ContractResponsiblePerson } from "@/models/Contracts/ContractResponsiblePerson";
import { ModalResult } from "@/view-models/ModalRequest";
import { toJS } from "mobx";

@Component({ components: { DataTable, CardModal } })
export default class AddResponsiblePerson extends Vue {
    @Prop() private prop!: { existing: ContractResponsiblePerson | null; data: ContractResponsiblePerson[] };

    public selection: SingleSelection<ContractResponsiblePerson> = { tag: "SINGLE", item: this.prop.existing };

    public config: IRowConfiguration<ContractResponsiblePerson> = {
        selection: {
            showSelectionColumn: false,
            background: "#ccc"
        }
    };

    public headers: ITableColumn<ContractResponsiblePerson>[] = [
        {
            title: "Основной",
            getter: src => src.isMain ? "Да" : "Нет",
        },
        {
            title: "Фамилия",
            getter: src => src.lastName,
            type: EditType.DECIMAL,
        },
        {
            title: "Имя",
            getter: src => src.firstName,
        },
        {
            title: "Отчество",
            getter: src => src.patronymic,
        },
        {
            title: "Электронная почта",
            getter: src => src.email,
        },
        {
            title: "Контактный телефон",
            getter: src => src.phone,
        },
    ];

    public save() {
        this.$emit('close-modal', ModalResult.ok(this.selection.item!));
    }
}
