





























import { Component, Prop, Vue } from "vue-property-decorator";
import { Icon, IconType } from '@/views/FormAbstractions';

@Component
export default class Button extends Vue {
    @Prop({ default: 'blue' }) private color!: string;
    @Prop({ default: null }) private title!: string | null;
    @Prop({ default: null }) private icon!: Icon | null;
    @Prop({ default: false }) private nomargin!: boolean; // todo: DEFAULT - true
    @Prop({ default: "HORIZONTAL" }) private orientation!: string; // todo ButtonOrientation
    @Prop({ default: false }) private disabled!: boolean;
    @Prop({ default: false }) private loading!: boolean;

    public glyphicons = {
        ok: { char: "\ue013", font: "Glyphicons Halflings", size: "14px", margin: "0" },
        cross: { char: "\ue014", font: "Glyphicons Halflings", size: "14px", margin: "0" },
        pencil: { char: "\u270f", font: "Glyphicons Halflings", size: "14px", margin: "0" },
        mail: { char: "\u2709", font: "Glyphicons Halflings", size: "14px", margin: "0" },
        user: { char: "\ue828", font: "EaistFont", size: "18px", margin: "-2px" },
    };

    public get realIcon() {
        return this.loading ? { type: IconType.CLASS, name: 'load' } : this.icon;
    }

    public onClick(ev: Event) {
        if (!this.disabled && !this.loading) this.$emit("click", ev);
    }
}
