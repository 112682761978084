
import React, { ReactNode } from "react";
import styles from "./Buttons.module.css";

interface PrimaryButtonProps {
    children?: ReactNode
    filled?: boolean
    onClick?: () => void
    disabled?: boolean
}

export const PrimaryButton = (props: PrimaryButtonProps) => <a className={`${styles.primaryButton} ${props.filled ? styles.primaryFilled : ""} ${props.disabled ? styles.disabled : ''}`} onClick={props.onClick}>
    {props.children}
</a>;

interface WarningButtonProps {
    children?: ReactNode
    onClick?: () => void
    filled?: boolean
    disabled?: boolean
}

export const WarningButton = (props: WarningButtonProps) => <a className={`${styles.primaryButton} ${props.disabled ? styles.disabled : ''} ${styles.warning} ${props.filled ? styles.warningFilled : ""}`} onClick={props.onClick}>
    {props.children}
</a>;

export const TestButton = (props: WarningButtonProps) => <a className={`${styles.primaryButton} ${styles.test} ${props.filled ? styles.testFilled : ""}`} onClick={props.onClick}>
    {props.children}
</a>;

export const CopyButton = (props: WarningButtonProps) => <a className={`${styles.primaryButton} ${styles.copy} ${props.filled ? styles.copyFilled : ""}`} onClick={props.onClick}>
    {props.children}
</a>;

export const CancelButton = (props: WarningButtonProps) => <a className={`${styles.primaryButton} ${styles.cancel} ${props.filled ? styles.cancelFilled : ""}`} onClick={props.onClick}>
    {props.children}
</a>;