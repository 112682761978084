import React from "react";
import { observer } from "mobx-react";
import styles from "./AdditionalInfo.module.css";
import { Characteristic } from "@/api/Products";

type AdditionalInfoProps = {
    characteristics: Characteristic[]
    onClose: () => void
    popupRef: React.Ref<HTMLDivElement>
};

export const AdditionalInfo = observer((props: AdditionalInfoProps) => {
    return <div className={styles.charsPopup} ref={props.popupRef}>
        <div className={styles.infoClose} onClick={props.onClose} />
        <table className={styles.infoTable}>
            <tbody>
                {props.characteristics.map(x => <tr key={x.name}>
                    <td className={styles.infoName}>{x.name}:</td>
                    <td className={styles.infoValue}>{x.value}</td>
                </tr>)}
            </tbody>
        </table>
    </div>;
});