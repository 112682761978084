import {
    subMonths,
    subYears,
    addMonths,
    addYears,
    setYear,
    getYear,
    getDay,
    isSameMonth,
    subDays,
    addDays,
    setDate,
    setMonth,
    setHours,
    setMinutes,
    setSeconds,
    setMilliseconds,
    lastDayOfMonth,
    isSameDay,
    isSameYear,
    setDay,
} from "date-fns";
import { action, makeObservable } from "mobx";

// region
const firstYearOfDecade = (date: Date | number) => setYear(date, Math.floor(getYear(date) / 10) * 10);
const lastYearOfDecade = (date: Date | number) => addYears(firstYearOfDecade(date), 9);

const firstDecadeOfCentury = (date: Date | number) => setYear(date, Math.floor(getYear(date) / 100) * 100);

const decadeStart = (date: Date | number) => yearStart(firstYearOfDecade(date));
const yearStart = (date: Date | number) => monthStart(setMonth(date, 0));
const monthStart = (date: Date | number) => dayStart(setDate(date, 1));
const dayStart = (date: Date | number) => setMilliseconds(setSeconds(setMinutes(setHours(date, 0), 0), 0), 0);

const decadeEnd = (date: Date | number) => yearEnd(lastYearOfDecade(date));
const yearEnd = (date: Date | number) => monthEnd(setMonth(date, 11));
const monthEnd = (date: Date | number) => dayEnd(lastDayOfMonth(date));
const dayEnd = (date: Date | number) => setMilliseconds(setSeconds(setMinutes(setHours(date, 23), 59), 59), 999);

const getWeekday = (date: Date| number) => {
    const day = getDay(date);

    return day === 0 ? 6 : day - 1;
};

const isSameDecade = (a: Date| number, b: Date| number) => Math.floor(getYear(a) / 10) * 10 === Math.floor(getYear(b) / 10) * 10;

const hardmin = new Date("2000-01-01T00:00:00.000Z").valueOf();
const hardmax = new Date("2099-12-31T23:59:59.999Z").valueOf();
const defaultDate = 0;
// endregion

export class DatePickerCell {
    public readonly classes: ReadonlyArray<string>;

    constructor(
        readonly key: number,
        readonly date: Date,
        readonly unit: "day" | "month" | "year" | "decade",
        readonly allowed: boolean,
        readonly body: boolean,
        readonly now: boolean,
        readonly selected: boolean,
        readonly locale: string,
        readonly format: string,
    ) {
        const list = [];

        if (this.allowed) list.push("allowed");
        if (this.body) list.push("body");
        if (this.now) list.push("now");
        if (this.selected) list.push("selected");

        list.push(unit);

        this.classes = list;
    }

    public toString() {
        if (this.unit === "decade") {
            const { format } = new Intl.DateTimeFormat(this.locale, {
                year: this.format,
            });

            return `${format(firstYearOfDecade(this.date))}—${format(lastYearOfDecade(this.date))}`;
        }

        return new Intl.DateTimeFormat(this.locale, {
            [this.unit]: this.format,
        }).format(this.date);
    }
}

export class DatePickerModel {
    public open: boolean;
    public value!: Date | number;
    private valueBeforePick: Date | number;
    public resultValue!: Date | number;

    private readonly locale = "ru";

    private level: "days" | "months" | "years" | "decades";
    private table: Map<number, Date>;
    private readonly weekdays: ReadonlyArray<string>;

    public body: DatePickerCell[][];

    private rangeStart: Date | null;
    private rangeEnd: Date | null;
    public get minDate() { return this.rangeStart ? dayStart(this.rangeStart).valueOf() : hardmin }
    public get maxDate() { return this.rangeEnd ? dayStart(this.rangeEnd).valueOf() : hardmax }

    public setRangeStart(d: Date | null) {
        this.rangeStart = d;
        this.valueBeforePick = this.getStartDate();
        this.body = this.generateBody();
    }

    public setRangeEnd(d: Date | null) {
        this.rangeEnd = d;
        this.valueBeforePick = this.getStartDate();
        this.body = this.generateBody();
    }

    private get options() {
        switch (this.mode) {
            case "days": return {
                day: "numeric",
                month: "numeric",
                year: "numeric"
            };
            case "months": return {
                month: "numeric",
                year: "numeric"
            };
        }
    }

    public setMode(mode: "days" | "months") {
        this.level = this.mode = mode;
    }

    constructor(
        public empty: boolean,
        value: Date | number | null | undefined,
        private mode: "days" | "months",
        rangeStart: Date | null,
        rangeEnd: Date | null
    ) {
        this.setValue(value);
        this.resultValue = this.value;
        this.level = mode;
        this.open = false;
        this.table = new Map();

        this.rangeStart = rangeStart;
        this.rangeEnd = rangeEnd;
        this.valueBeforePick = this.getStartDate();

        this.body = this.generateBody();

        const { format } = new Intl.DateTimeFormat(this.locale, { weekday: "short" });

        this.weekdays = [1, 2, 3, 4, 5, 6, 0].map(x => format(setDay(new Date(), x)));

        makeObservable(this, {
            setRangeStart: action,
            setRangeEnd: action
        });
    }

    private isDecadeAllowed = (date: Date) =>
        decadeEnd(date).valueOf() >= this.minDate && decadeStart(date).valueOf() <= this.maxDate;
    private isYearAllowed = (date: Date) =>
        yearEnd(date).valueOf() >= this.minDate && yearStart(date).valueOf() <= this.maxDate;
    private isMonthAllowed = (date: Date) =>
        monthEnd(date).valueOf() >= this.minDate && monthStart(date).valueOf() <= this.maxDate;
    private isDayAllowed = (date: Date) =>
        dayEnd(date).valueOf() >= this.minDate && dayStart(date).valueOf() <= this.maxDate;

    public assign(
        empty: boolean,
        value: Date | number | null | undefined,
        mode: "days" | "months") {
        this.empty = empty;
        this.level = mode;
        this.setValue(value);
        this.resultValue = this.value;

        this.table = new Map();
        this.body = this.generateBody();
    }

    private getStartDate() {
        const dateNow = new Date();
        if (this.rangeStart && this.rangeEnd) {
            return this.rangeStart.valueOf() < dateNow.valueOf() ? dateNow : this.rangeStart;
        } else if (this.rangeStart) {
            return this.rangeStart.valueOf() < dateNow.valueOf() ? dateNow : this.rangeStart;
        } else if (this.rangeEnd) {
            return dateNow.valueOf() < this.rangeEnd.valueOf() ? dateNow : this.rangeEnd;
        }
        return dateNow;
    }

    private partialAssign(
        empty: boolean,
        value: Date | number | null | undefined,
        mode: "days" | "months" | "years" | "decades") {
        this.empty = empty;
        if (this.level !== "decades" || mode !== "years") {
            this.setValue(value || new Date());
        }
        this.level = mode;

        this.table = new Map();
        this.body = this.generateBody();
    }

    private flipWithoutAssign(
        empty: boolean,
        value: Date | number,
        mode: "days" | "months" | "years" | "decades") {
        this.empty = empty;
        this.level = mode;
        this.valueBeforePick = value;

        this.table = new Map();
        this.body = this.generateBody();
    }

    private assignOrFill(
        empty: boolean,
        value: Date | number,
        mode: "days" | "months" | "years" | "decades") {
        if (this.isDatePicked) {
            this.partialAssign(empty, value, mode);
        } else {
            this.flipWithoutAssign(empty, value, mode);
        }
    }

    private format(options: Intl.DateTimeFormatOptions) {
        return new Intl.DateTimeFormat(this.locale, options).format(this.resultValue);
    }

    private formatInternal(options: Intl.DateTimeFormatOptions) {
        return new Intl.DateTimeFormat(this.locale, options).format(this.isDatePicked ? this.value : this.valueBeforePick);
    }

    private setValue(date: Date | number | null | undefined) {
        if (date) {
            if (this.mode === "days") this.value = dayStart(date);
            else this.value = monthStart(date);

            return this;
        }
        this.value = defaultDate;

        return this;
    }

    public get formatted() {
        return this.empty ? "" : this.format(this.options);
    }

    public get isDatePicked() {
        return this.value !== defaultDate;
    }

    public get hasUp() {
        return this.level !== "decades";
    }

    public get hasPrev() {
        if (!this.value) {
            // // resolves situation when date picker value is not initialized
            // let init = new Date();
            // if (this.rangeStart) init = this.rangeStart;
            // if (this.rangeEnd) init = this.rangeEnd;
            this.setValue(0); // // https://rt.rtall.ru/redmine/issues/784 - фиксим поведение дейтпикера с проставлением сегодняшней даты автоматически
        }
        const value = this.isDatePicked ? this.value : this.valueBeforePick;

        switch (this.level) {
            case "days":
                return this.isMonthAllowed(subMonths(value, 1));
            case "months":
                return this.isYearAllowed(subYears(value, 1));
            case "years":
                return this.isDecadeAllowed(subYears(value, 10));
            default:
                return false;
        }
    }

    public get hasNext() {
        const value = this.isDatePicked ? this.value : this.valueBeforePick;
        switch (this.level) {
            case "days":
                return this.isMonthAllowed(addMonths(value, 1));
            case "months":
                return this.isYearAllowed(addYears(value, 1));
            case "years":
                return this.isDecadeAllowed(addYears(value, 10));
            default:
                return false;
        }
    }

    public get label() {
        const date = this.isDatePicked ? this.value : this.valueBeforePick;
        switch (this.level) {
            case "days":
                return this.formatInternal({ month: "long", year: "numeric" });
            case "months":
                return this.formatInternal({ year: "numeric" });
            default: {
                const { format } = new Intl.DateTimeFormat(this.locale, {
                    year: "numeric",
                });

                return `${format(firstYearOfDecade(date))}—${format(lastYearOfDecade(date))}`;
            }
        }
    }

    public get columns() {
        return this.level !== "days" ? null : this.weekdays;
    }

    public get footer() {
        let date = new Date();
        date = this.rangeStart && date.valueOf() < this.minDate
            ? this.rangeStart
            : this.rangeEnd && date.valueOf() > this.maxDate
                ? this.rangeEnd
                : date;

        return new Intl.DateTimeFormat(this.locale, this.options).format(date);
    }

    private generateBody() {
        this.table.clear();
        const date = this.isDatePicked && this.value ? this.value : this.valueBeforePick;

        switch (this.level) {
            case "days": {
                const first = setDate(date, 1);
                const rows: DatePickerCell[][] = [[]];

                let state = 1;
                let current = subDays(first, getWeekday(first));
                let row = rows[0];
                let i = 0;

                while (state) {
                    const isSame = isSameMonth(date, current);

                    switch (state) {
                        // Tail of previous month:
                        case 1:
                            if (isSame) state++; // Go to body of current.

                            break;
                        // Body of current month:
                        case 2:
                            if (!isSame) state++; // Go to head of next.

                            break;
                        // Head of next month:
                        default:
                            if (i % 7 >= 6) state = 0; // Exit.

                            break;
                    }

                    row.push(
                        new DatePickerCell(
                            i,
                            current,
                            "day",
                            this.isDayAllowed(current),
                            state === 2,
                            isSameDay(current, new Date()),
                            isSameDay(current, this.value),
                            this.locale,
                            "numeric",
                        ),
                    );

                    this.table.set(i, current);

                    if (i % 7 >= 6 && state) {
                        row = [];
                        rows.push(row);
                    }

                    i++;
                    current = addDays(current, 1);
                }

                return rows;
            }
            case "months": {
                const rows: DatePickerCell[][] = [[]];

                let current = setMonth(date, 0);
                let row = rows[0];
                let i = 0;

                while (i < 12) {
                    row.push(
                        new DatePickerCell(
                            i,
                            current,
                            "month",
                            this.isMonthAllowed(current),
                            true,
                            isSameMonth(current, new Date()),
                            isSameMonth(current, this.value),
                            this.locale,
                            "short",
                        ),
                    );

                    this.table.set(i, current);

                    if (i % 4 >= 3 && i < 11) {
                        row = [];
                        rows.push(row);
                    }

                    i++;
                    current = addMonths(current, 1);
                }

                return rows;
            }
            case "years": {
                const first = firstYearOfDecade(date);
                const rows: DatePickerCell[][] = [[]];

                let current = subYears(first, 1);
                let row = rows[0];
                let i = 0;

                while (i < 12) {
                    row.push(
                        new DatePickerCell(
                            i,
                            current,
                            "year",
                            this.isYearAllowed(current),
                            i > 0 && i < 11,
                            isSameYear(current, new Date()),
                            isSameYear(current, this.value),
                            this.locale,
                            "numeric",
                        ),
                    );

                    this.table.set(i, current);

                    if (i % 4 >= 3 && i < 11) {
                        row = [];
                        rows.push(row);
                    }

                    i++;
                    current = addYears(current, 1);
                }

                return rows;
            }
            default: {
                const first = firstDecadeOfCentury(date);
                const rows: DatePickerCell[][] = [[]];

                let current = subYears(first, 10);
                let row = rows[0];
                let i = 0;

                while (i < 12) {
                    row.push(
                        new DatePickerCell(
                            i,
                            current,
                            "decade",
                            this.isDecadeAllowed(current),
                            i > 0 && i < 11,
                            isSameDecade(current, new Date()),
                            isSameDecade(current, this.value),
                            this.locale,
                            "numeric",
                        ),
                    );

                    this.table.set(i, current);

                    if (i % 4 >= 3 && i < 11) {
                        row = [];
                        rows.push(row);
                    }

                    i++;
                    current = addYears(current, 10);
                }

                return rows;
            }
        }
    }

    public up() {
        if (!this.hasUp) return this;
        const value = this.isDatePicked ? this.value : this.valueBeforePick;

        switch (this.level) {
            case "days":
                this.assignOrFill(this.empty, value, "months");
                break;
            case "months":
                this.assignOrFill(this.empty, value, "years");
                break;
            default:
                this.assignOrFill(this.empty, value, "decades");
                break;
        }

        return this;
    }

    public prev() {
        if (!this.hasPrev) return this;
        const value = this.isDatePicked ? this.value : this.valueBeforePick;

        switch (this.level) {
            case "days":
                this.assignOrFill(this.empty, subMonths(value, 1), "days");
                break;
            case "months":
                this.assignOrFill(this.empty, subYears(value, 1), "months");
                break;
            case "years":
                this.assignOrFill(this.empty, subYears(value, 10), "years");
                break;
            default:
                break;
        }

        return this;
    }

    public next() {
        if (!this.hasNext) return this;
        const value = this.isDatePicked ? this.value : this.valueBeforePick;

        switch (this.level) {
            case "days":
                this.assignOrFill(this.empty, addMonths(value, 1), "days");
                break;
            case "months":
                this.assignOrFill(this.empty, addYears(value, 1), "months");
                break;
            case "years":
                this.assignOrFill(this.empty, addYears(value, 10), "years");
                break;
            default:
                break;
        }

        return this;
    }

    public show() {
        this.open = true;

        return this;
    }

    public hide() {
        this.open = false;
        this.level = this.mode;

        return this;
    }

    public select(key: number) {
        if (!this.table.has(key)) return this;

        const v = this.table.get(key)!;

        switch (this.level) {
            case "days":
                this.partialAssign(true, v, "days");
                this.resultValue = v;
                this.hide();
                break;
            case "months":
                if (this.mode === "months") {
                    this.assignOrFill(true, v, "months");
                    this.resultValue = v;
                    this.hide();
                } else {
                    this.assignOrFill(false, v, "days");
                }
                break;
            case "years":
                this.assignOrFill(false, v, "months");
                break;
            default:
                this.assignOrFill(false, v, "years");
                break;
        }

        return this;
    }

    public now() {
        const date = new Date();

        this.partialAssign(
            false,
            this.rangeStart && date.valueOf() < this.minDate
                ? this.rangeStart
                : this.rangeEnd && date.valueOf() > this.maxDate
                    ? this.rangeEnd
                    : date,
            this.mode);
        this.resultValue = this.value;

        this.hide();

        return this;
    }
}
