import React, { useState } from "react";
import { ModalFC } from "@/EventBus";
import { Participant } from "@/models";
import { CardModal } from "@/components/modals/CardModal";
import { Button, TextBox } from "@/components/primitive";
import {
    FilteredSelectionTable, getFilterValue,
    Pager, setFilterValue,
    Table,
    useStoredRemoteDataset
} from "@/components/table";
import { Expandee, HStack } from "@/components/layouts";
import { createParticipantStore, ParticipantsFilters } from "@/views/Contracts/modals/ParticipantSelectionModal/participantsStore";
import { debounced } from "@/functionUtils";

export const ParticipantSelectionModal: ModalFC<undefined, Participant> = x => {
    const [selectedParticipant, setSelectedParticipant] = useState<Participant | undefined>();
    const [fullNameFilter, setFullNameFilter] = useState<string>();
    const [store] = useState(() => createParticipantStore());

    const rds = useStoredRemoteDataset(store.getState().participants);
    const debouncedSetFilterValue = debounced(300, (v) => setFilterValue(rds.filterStore, "fullName", v));

    const bottom = <HStack spacing="10px">
        <Expandee />
        <Button onClick={() => x.done(selectedParticipant)} icon="aSave" color="blue" />
        <Button onClick={() => x.done(undefined)} icon="aClose" color="red" />
    </HStack>;

    return <CardModal title="Выбор поставщика"
                      close={() => x.done(selectedParticipant)}
                      bottom={bottom}
                      width="35%">
        <div style={{ display: "flex", flexDirection: "column" }}>
            <div style={{ padding: "0 20px 20px 20px" }}>
                <TextBox onChange={v => { setFullNameFilter(v); debouncedSetFilterValue(v) }}
                         value={fullNameFilter} />
            </div>
            <div style={{ display: "flex", flexDirection: "column", marginBottom: "10px"}}>
                <FilteredSelectionTable<Participant, ParticipantsFilters> dataset={rds.dataset}
                                             mode="single"
                                             filterStore={rds.filterStore}
                                             isFiltersVisible={false}
                                             selectorPosition="hidden"
                                             selected={selectedParticipant}
                                             selectedStyle={{ backgroundColor: "#ccc" }}
                                             onChange={setSelectedParticipant}
                                             columns={[
                    Table.Column(
                        "Название поставщика",
                        c => <>{c.item.fullName}</>,
                        { width: "60%"}
                    ),
                    Table.AutoColumn(
                        "ИНН",
                        c => <>{c.item.id}</>
                    ),
                    Table.AutoColumn(
                        "КПП",
                        c => <>{c.item.isSmp ? "Да" : "Нет"}</>
                    ),
                    Table.AutoColumn(
                        "СОНО",
                        c => <>{c.item.isSocial ? "Да" : "Нет"}</>
                    )
                ]} />
                <Pager remoteDataset={rds} />
            </div>
        </div>
    </CardModal>;
};