import React, { useState } from "react";
import { ModalFC } from "@/EventBus";
import { LotRequestDocument } from "@/models/ComposedLots/documents";
import { Expandee, HStack } from "@/components/layouts";
import { Button, CheckBox, ComboBox, TextBox } from "@/components/primitive";
import { CardModal } from "@/components/modals/CardModal";
import { Field, Fields } from "@/components/form/Field";
import { toSelectOptions } from "@/models/enums";
import { LotRequestStage, LotRequestStageStrings } from "@/models/enums/LotRequestStage";

export const NewRequestDocumentModal: ModalFC<{ order: string }, LotRequestDocument> = x => {
    const [requestDoc, setRequestDoc] = useState<Omit<LotRequestDocument, "id">>({
        order: x.order ?? "",
        name: "",
        comment: "",
        required: false,
        stage: LotRequestStage.FIRST
    });

    const isOk = requestDoc.order !== "" && requestDoc.name !== "";

    const bottom = <HStack spacing={"10px"}>
        <Expandee/>
        <Button color="blue"
                icon="aSave"
                onClick={() => x.done(requestDoc as LotRequestDocument)}
                disabled={!isOk} />
        <Button color="red" icon="aClose" onClick={() => x.done(undefined)} />
    </HStack>;

    return <CardModal title="Новый требуемый документ к заявке"
                      close={() => x.done(undefined)}
                      width="50%"
                      bottom={bottom}>
        <Fields orientation="vertical">
            <Field title="Номер" required>
                <TextBox lines={1}
                         value={requestDoc.order}
                         onChange={v => setRequestDoc({ ...requestDoc, order: v })} />
            </Field>
            <Field title="Наименование" required>
                <TextBox lines={5}
                         value={requestDoc.name}
                         onChange={v => setRequestDoc({ ...requestDoc, name: v })} />
            </Field>
            <Field title="Примечание">
                <TextBox lines={5}
                         value={requestDoc.comment}
                         onChange={v => setRequestDoc({ ...requestDoc, comment: v })} />
            </Field>
            <Field title="Обязательность">
                <CheckBox value={requestDoc.required}
                          onChange={v => setRequestDoc({ ...requestDoc, required: v })} />
            </Field>
            <Field title="Часть заявки">
                <ComboBox options={toSelectOptions<LotRequestStage>(LotRequestStageStrings)}
                          onChange={v => setRequestDoc({ ...requestDoc, stage: v })}
                          value={requestDoc.stage}/>
            </Field>
        </Fields>
    </CardModal>;
};