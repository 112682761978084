import React, { useEffect, useState } from "react";
import { CardModal } from "@/components/modals/CardModal";
import { ModalFC, showModal, typifyVueModal } from "@/EventBus";
import { Expandee, HStack, VStack } from "@/components/layouts";
import { Button, Label, Radio, RadioGroup } from "@/components/primitive";
import { Field, Fields } from "@/components/form/Field";
import { Participant } from "@/models";
import Participants from "@/api/Participants";
import { FilterConditionType, FilterValueType } from "@/api/http";
import ParticipantSelectionModal from "@/views/modals/ParticipantSelectionModal";

const PickParticipantModal = typifyVueModal<{ participants: Participant[] }, Participant>(ParticipantSelectionModal);

type ParticipantInfoModalReturn = {
    participant: Participant | undefined
};

export const ParticipantInfoModal: ModalFC<{participant?: Participant}, ParticipantInfoModalReturn> = x => {
    const [participants, setParticipants] = useState<Participant[]>([]);

    useEffect(() => {
        Participants.get(0, 1000, {
            isSupplier: [{
                type: FilterValueType.BOOLEAN,
                conditionType: FilterConditionType.EQUAL,
                boolean: true,
            }]
        })
            .then(result => setParticipants(result.items));
    }, []);

    const pickParticipant =  async (): Promise<Participant | undefined> => {
        const result = await showModal(PickParticipantModal, { participants });
        if (result) {
            x.done({participant: result});
        } else return;
    };

    const bottom: React.ReactNode = <HStack spacing="10px">
        <Expandee />
        <Button title="Выбрать поставщика" color="blue" onClick={() => pickParticipant()} />
        <Button title="Закрыть" color="red" onClick={() => x.done({participant: undefined})} />
    </HStack>;

    return <CardModal title="Сведения об участнике"
                      width="1000px"
                      bottom={bottom}
                      close={() => x.done({participant: undefined})}>
        <VStack spacing="15px">
            <Fields orientation="vertical">
                <Field title="Наименование участника">
                    <Label text={x.participant?.fullName ?? "Не предоставлено"}/>
                </Field>
                <Field title="ИНН">
                    <Label text={x.participant?.tin ?? "Не предоставлено"}/>
                </Field>
                <Field title="КПП">
                    <Label text={x.participant?.rrc ?? "Не предоставлено"}/>
                </Field>
                <Field title="Адрес">
                    <Label text={x.participant?.address ?? "Не предоставлено"}/>
                </Field>
            </Fields>
        </VStack>
    </CardModal>;
};
