

























import { Component, Prop, Vue } from "vue-property-decorator";
import { PaginationContext } from '@/api/Pagination';

@Component
export default class PaginationController extends Vue {
    @Prop({ default: null }) private paginationContext!: PaginationContext<unknown> | null;

    public get itemCount() { return this.paginationContext ? this.paginationContext.items.length : 0 }

    public get activePage() { return this.paginationContext ? this.paginationContext.activePage : 0 }
    public get pages() { return this.paginationContext ? this.paginationContext.pages : [] }

    public get totalCount() { return this.paginationContext ? this.paginationContext.totalCount : 0 }
    public get pageSize() { return this.paginationContext ? this.paginationContext.pageSize : 0 }

    public get lastPage() { return ((this.totalCount / this.pageSize) | 0) - (this.totalCount % this.pageSize === 0 ? 1 : 0) }

    public changePage(page: number) {
        if (page > this.lastPage || page < 0) return;

        if (this.paginationContext) {
            this.paginationContext.load(page);
        }
    }
}
