

























































import { Component, Vue, Prop, Watch } from "vue-property-decorator";
import FormTable from "@/components/vue/FormTable.vue";
import CardModal from "@/components/CardModal.vue";
import { makeFormHelpers } from "@/form-declarations/helpers";
import { IColumn } from "@/components/vue/form-table/FormFields";
import { ContractDocument, ContractDocumentType, Contract } from '../../models/Contracts';
import { ContractDocumentStatus } from '../../models/enums/Contracts';
import EventBus, {showModal} from '../../EventBus';
import ContractDocumentModal from '../modals/ContractDocumentModal';
import { ModalResult, ModalVue } from '../../view-models/ModalRequest';
import { File } from '../../models/Documents';
import ContractDocuments from '@/api/ContractDocuments';
import { SelectOption, LawType } from '../../models/enums';
import {ContractScope} from "@/views/CreateContract/ContractScope";
import {SaveDeniedModal} from "@/views/Contracts/modals/SaveDeniedModal";
import {approveLot, getLotById, uploadLotDocument} from "@/api/ComposedLots";
import {ProviderSelectionType} from "@/models/ComposedLots";
import {ErrorModal} from "@/views/Contracts/modals/ConfirmationModal";
import { DocumentWizardModal } from "@/views/ComposedLot/edit/tabs/lot-documents/DocumentsSection/wizard/DocumentWizardModal";
import {
  createElecContractDocument,
  createLotDocumentFromTemplate
} from "@/views/form-renderers/DocumentationSection/documentation";
import { addOrEditLotDocument } from "@/views/ComposedLot/shared/store/documents";
import { replaceLot } from "@/views/ComposedLot/shared/store";

let cachedDocumentTypes: SelectOption<ContractDocumentType>[] | null = null;

@Component({ components: { FormTable, CardModal } })
export default class AddDocument extends Vue {
    @Prop() private prop!: {
        contract?: Contract
        document: ContractDocument | null
        readonly?: boolean
        createAction: (_: ContractDocument) => Promise<ContractDocument>
        noPost?: boolean
        autoNumber?: (t: ContractDocumentType) => string | null
        hasDate?: (t: ContractDocumentType) => boolean
        documentGroup?: string
        blockType?: "acceptance" | "supplierExecution"
        exactTypeId?: number
        contractScope?: ContractScope
        isExecutingBySupplier?: boolean | null
        isExecutionOperation?: boolean | null
        // validation fn that returns list of errors (or empty list if no errors are present)
        validateFn?: ((d: ContractDocument) => string[]) | null
    };

    /*
        01	Товарная накладная
        06	Акт о приемке товаров
        07	Акт о приемке товара, поступившего без счета поставщика
        08	Акт о приеме-передаче объекта основных средств (кроме зданий, сооружений)
        09	Акт о приеме-передаче здания (сооружения)
        10	Акт о приеме-передаче групп объектов основных средств (кроме зданий, сооружений)
        11	Акт о приеме-сдаче отремонтированных, реконструированных, модернизированных объектов основных средств
        12	Акт о приемке выполненных работ
        13	Акт приемки законченного строительством объекта
        20	Акт выполненных работ (оказанных услуг)
        99	Прочие документы о приемке, не указанные выше
    */
    private readonly closingDocumentsCodes = ["01", "06", "07", "08", "09", "10", "11", "12", "13", "20", "37", "99"];

    public source = new ContractDocument(
        this.prop.document ? this.prop.document.id : null!,
        this.prop.document ? this.prop.document.file : null!,
        this.prop.document ? this.prop.document.type : null!,
        this.prop.document ? this.prop.document.documentNumber : null!,
        this.prop.document ? this.prop.document.date : null!,
        this.prop.document ? this.prop.document.dateSigned : null!,
        this.prop.document ? this.prop.document.createdAt : null!,
        this.prop.document ? this.prop.document.status : ContractDocumentStatus.NOT_SENT);

    @Watch("source.type") private autoNumber() {
        if (this.prop.autoNumber) {
            this.source.documentNumber = this.source.type ? this.prop.autoNumber(this.source.type)! : null!;
        }
        if (this.source.type.name === "Контракт"){
            this.source.documentNumber = this.prop.contract?.contractNumber ?? null!;
            this.source.date = this.prop.contract?.conclusionDate ?? null;
        }
    }
    get hasContractWizard() {
        return this.prop.contract?.law === "F44" && (this.source?.type?.name === 'Контракт' || this.source?.type?.name === 'Договор') ;
    }

    public formContract = async () => {
        const wizardResult: boolean | undefined = await showModal(DocumentWizardModal,
            { organization: "CONTRACT-TEMPLATE"});
        if (!wizardResult) return null;
        const lotId = this.prop.contract?.lotId || this.prop.contract?.lot2020Id;
        if (!lotId) return null;

        const lot = await getLotById(lotId);
        const template = await createLotDocumentFromTemplate("contract", lot);
        const elecTemplate = createElecContractDocument(lot);

        if (!template) {
          console.error("No document template was provided");
          return;
        }

        const contractDocument = new ContractDocument (
                template.id ?? null,
                template.file ? new File(template.file.id, template.file.length,template.file.name, template.file.comment ?? "") : null!,
                this.source.type,
                this.source.documentNumber ?? "1",
                new Date(),
                null,
                new Date(),
                this.source.status,
        );
        
        const elecContract = new ContractDocument (
            elecTemplate.id ?? null,
            elecTemplate.file ? new File(elecTemplate.file.id, elecTemplate.file.length,elecTemplate.file.name, elecTemplate.file.comment ?? "") : null!,
            new ContractDocumentType(16, "Печатная форма электронного контракта", "", ""),
            this.source.documentNumber ?? "1",
            new Date(),
            null,
            new Date(),
            this.source.status,
        );

        const result = await this.prop.createAction(contractDocument);
        await this.prop.createAction(elecContract);
        this.$emit("close-modal", ModalResult.ok(result));
    };

    public documentTypes: SelectOption<ContractDocumentType>[] = [];

    public async created() {
        if (this.prop.exactTypeId !== undefined) return;

        if (!cachedDocumentTypes) {
            cachedDocumentTypes =
                (await ContractDocuments.getTypes()).map(x => ({ key: x, desc: x.name }));
            cachedDocumentTypes.splice(0, 0, { key: null!, desc: "" });
        }

        this.documentTypes = this.prop.documentGroup ? cachedDocumentTypes!.filter(x => !x.key || x.key.groupCode === this.prop.documentGroup) : cachedDocumentTypes!;
        if(this.prop.isExecutionOperation){
            this.documentTypes = this.documentTypes.filter(x => x.key?.name == 'Контракт'
                || x.key?.name == 'Контракт (приложение)'
                || x.key?.name == 'Результат учета замечаний протокола разногласий'
                || x.key?.name == 'Сведения о добросовестности участника');
        }
        if(this.prop.contract?.law === "F44" && this.prop.blockType === "supplierExecution") {
            this.documentTypes = this.documentTypes.filter(x => x.key?.name === "Акт – рекламация" 
                || x.key?.name === "Акт выполненных работ (оказанных услуг)"
                || x.key?.name === "Акт о выявлении недостатков выполненных работ (оказанных услуг)"
                || x.key?.name === "Акт о завесе тары"
                || x.key?.name === "Акт о невыполнении работ (оказании услуг)"
                || x.key?.name === "Акт о приеме (поступлении) оборудования"
                || x.key?.name === "Акт о приеме-передаче групп объектов основных средств (кроме зданий, сооружений)"
                || x.key?.name === "Акт о приеме-передаче здания (сооружения)"
                || x.key?.name === "Акт о приеме-передаче объекта основных средств (кроме зданий, сооружений)"
                || x.key?.name === "Акт о приеме-сдаче отремонтированных, реконструированных, модернизированных объектов основных средств"
                || x.key?.name === "Акт о приемке выполненных работ"
                || x.key?.name === "Акт о приемке материалов"
                || x.key?.name === "Акт о приемке товара, поступившего без счета поставщика"
                || x.key?.name === "Акт о приемке товаров"
                || x.key?.name === "Акт об оприходовании тары, не указанной в счете поставщика"
                || x.key?.name === "Акт приемки законченного строительством объекта"
                || x.key?.name === "Акт (уведомление) о наступлении гарантийного случая"
                || x.key?.name === "Железнодорожная накладная"
                || x.key?.name === "Коносамент"
                || x.key?.name === "Счет на оплату"
                || x.key?.name === "Счет-фактура"
                || x.key?.name === "Товарная накладная"
                || x.key?.name === "Товарно-транспортная накладная"
                || x.key?.name === "Универсальный передаточный документ"
                // to make first element in list empty and not auto selected
                || x.key === null
            );
        }
        if(this.prop.contract?.law === "F44" && this.prop.blockType === "acceptance") {
            this.documentTypes = this.documentTypes.filter(x => x.key?.name === "Авианакладная"
                || x.key?.name === "Акт выполнения обязательств по гарантии"
                || x.key?.name === "Акт об установлении расхождений по количеству и качеству при приемке импортных товаров"
                || x.key?.name === "Акт об установлении расхождения по количеству и качеству при приемке товарно-материальных ценностей"
                || x.key?.name === "Документ о приемке поставленных товаров, выполненных работ (их результатов, в том числе этапов), оказанных услуг в электронной форме"
                || x.key?.name === "Результаты проведенной экспертизы поставленного товара, выполненной работы, оказанной услуги"
                || x.key?.name === "Требование безвозмездного устранения недостатков товара (работы, услуги) в разумный срок"
                || x.key?.name === "Требование возмещения своих расходов на устранение недостатков товара (работы, услуги)"
                || x.key?.name === "Требование допоставки"
                || x.key?.name === "Требование заказчика в адрес банка об осуществлении уплаты денежной суммы по банковской гарантии"
                || x.key?.name === "Требование замены постановленного товара"
                || x.key?.name === "Требование о возмещение убытков"
                || x.key?.name === "Требование поставщика об уплате неустойки (штрафа, пени)"
                || x.key?.name === "Требование соразмерного уменьшения покупной цены"
                || x.key?.name === "Требования заказчика об уплате неустойки (штрафа, пени)"
                || x.key === null
            );
        }
        this.documentTypes = this.documentTypes.map(x => {
            if (x.desc.includes("Контракт") && this.prop.contract?.law == LawType.F223) {
                return {...x, desc: x.desc.replace(/Контракт/,"Договор") };
            }
            else if(x.desc.includes("контракт") && this.prop.contract?.law == LawType.F223) {
                return {...x, desc: x.desc.replace(/контракт/,"договор") };
            }
            return x;
        });

        if (this.source.type) {
            const opt = cachedDocumentTypes.find(x => x.key && x.key.id === this.source.type.id);
            this.source.type = opt && opt.key || null!;
        }
    }

    public async pickFile() {
        const result = await EventBus.callModal<File | null, File>(ContractDocumentModal, this.source.file);
        if (result.isOk) {
            this.source.file = result.getResult();
        }
    }

    // TODO: условие: 44-ФЗ по итогам электронного конкурса, электронного аукциона, электронного запроса котировок и электронного запроса предложений
    public get needContractDocumentWarning() {
        return !this.prop.isExecutionOperation &&
            this.prop.contract?.law === "F44" &&
            this.source?.type?.name === "Контракт" &&
            (["E_EXAM", "E_AUC", "E_QUOTATIONS_REQUEST", "E_OFFERS_REQUEST"] as ProviderSelectionType[]).find(p => p === this.prop.contract?.providerSelectionType);
    }

    public get isOk() {
        return (this.prop.exactTypeId !== undefined || !!this.source.type) &&
            !this.needContractDocumentWarning &&
            !!this.source.file &&
            !!this.source.documentNumber && this.source.documentNumber.length > 0 &&
            ((this.prop.hasDate && !this.prop.hasDate(this.source.type)) || !!this.source.date) &&
            true; // todo: this.source.type check
    }

    public loading = false;

    public async saveAndClose() {
        if (this.isOk) {
            if (this.prop.validateFn) {
              const errors = this.prop.validateFn(this.source);
              if (errors.length) {
                showModal(ErrorModal, { text: errors.join("\n") });
                return;
              }
            }
            this.loading = true;

            if (this.source.type.name === "Контракт") {
                if (this.prop.contractScope?.documents.find(a => a.type.name === "Контракт")) {
                    showModal(SaveDeniedModal, {});
                    this.loading = false;
                    return;
                }
                const conclusionDate = this.prop.contract?.conclusionDate;
                this.source.date = conclusionDate ? conclusionDate : null;
            }

            try {
                if (this.prop.exactTypeId !== undefined) {
                    this.source.type = new ContractDocumentType(this.prop.exactTypeId, "", "", "");
                }

                if (this.prop.noPost) {
                    const src = this.prop.document ? Object.assign(this.prop.document, this.source) : this.source;
                    this.$emit("close-modal", ModalResult.ok(src));
                } else {
                    const src =
                        this.source.id
                            ? await ContractDocuments.update(this.source)
                            : await this.prop.createAction(this.source);
                    this.$emit("close-modal", ModalResult.ok(src));
                }
            } catch (e) {
              console.error("Unable to upload document", e);
            } finally {
                this.loading = false;
            }
        }
    }
}
