import React, { useMemo, useState } from "react";
import styles from '../CreateSession.module.css';
import {
    changeAccountId,
    ServicesStore
} from "../store";
import _ from "lodash";
import { TooltipIcon } from "@/components/TooltipIcon";
import { FormControlEshopInput } from "@/components/eshop/FormControlEshopInput";
import { HeaderWithTooltip, isSorted } from "./CreateSession";
import { SortIcons } from "@/components/SortIcons";
import { IDtoBankAccount } from "@/models/json/IDtoBankAccount";
import {Section} from "@/components/eshop/Section/Section";
import {RefreshButton, ResetFiltersButton, ShowFiltersButton} from "@/components/eshop/IconButton/IconButtons";
import {Card} from "@/components/eshop/Card/Card";
import { Select } from "@/components/eshop/Select/Select";
import { HStack } from "@/components/layouts";
import { IconButton } from "@/components/eshop/IconButton/IconButton";
import DetailsIcon from "@/assets/icons/details-squared-icon.svg";
import { showModal } from "@/EventBus";
import { ShowAccountInfoDetailsModal } from "@/modal-views/ShowAccoundInfoDetailsModal";


interface AccountInfoCardProps {
    store: ServicesStore
    canChange: boolean
}

export const AccountInfoCard = (props: AccountInfoCardProps) => {
    const { store, canChange } = props;
    const [sortRec, setSortRec] = useState<{ field: keyof IDtoBankAccount; sort: 'asc' | 'desc' } | null>(null);

    const [isFilterOpened, setFilterOpened] = useState(false);
    const [accNumber, setAccNumber] = useState('');
    const [correspondentNumber, setСorrespondentNumber] = useState('');
    const [transactionalNumber, setTransactionalNumber] = useState('');
    const [bankName, setBankNameNumber] = useState('');
    const [bin, setBin] = useState('');

    const clearFilters = () => {
        setAccNumber('');
        setСorrespondentNumber('');
        setTransactionalNumber('');
        setBankNameNumber('');
        setBin('');
    };

    const filtered = store.bankAccounts
        .filter(i => i.accountNumber.indexOf(accNumber) !== -1)
        .filter(i => (i.correspondentAccount || '').indexOf(correspondentNumber) !== -1)
        .filter(i => i.transactionalAccountNumber.indexOf(transactionalNumber) !== -1)
        .filter(i => i.bankName.indexOf(bankName) !== -1)
        .filter(i => i.bin.indexOf(bin) !== -1);

    const recordsSorted = () => sortRec
        ? _.orderBy(filtered, a => a[sortRec.field], [sortRec.sort])
        : filtered;

    const getValue = useMemo(() => {
        const records = recordsSorted();
        if(store.selectedAccount){
            return records.find(r => r.id === store.selectedAccount)?.id ?? "";
        }
        return "";
    }, [store.selectedAccount]);

    const handleDetailItemClick = async () => {
        const acc = recordsSorted().find(r => r.id === store.selectedAccount);
        await showModal(ShowAccountInfoDetailsModal, {account: acc});
    };

    return (
        <Section required canBeHidden title={<>Реквизиты заказчика<TooltipIcon /></>}>
            <Card direction={"vertical"}>
                <div className={styles.controlsWrapper}>
                    <div className={styles.buttonsContainer}>
                        <ShowFiltersButton onClick={() => setFilterOpened(!isFilterOpened)} />
                        <ResetFiltersButton onClick={clearFilters} />
                        <RefreshButton />
                    </div>
                    <HStack spacing="10px" alignItems="center">
                        <div style={{padding: "2px 0 5px 8px"}}>
                            <Select label="Заказчик"
                                value={String(getValue)}
                                options={recordsSorted().map(r => ({name: r.receiver ? r.receiver : "Название заказчика не указано", value: String(r.id) }))}
                                width={500}
                                onSelect={v => changeAccountId(v ? parseFloat(v) : null)}
                                disabled={!canChange}
                                withTooltip />
                        </div>
                        <IconButton icon={DetailsIcon} disabled={!canChange} gray onClick={handleDetailItemClick}/>
                    </HStack>
                </div>
                <table className={styles.cardTable}>
                    <thead>
                    <tr className={styles.tableRow}>
                        <HeaderWithTooltip>
                            <SortIcons sorted={isSorted('accountNumber', sortRec)} setSort={sort => setSortRec({ field: 'accountNumber', sort })} />
                            Лицевой счет
                        </HeaderWithTooltip>
                        <HeaderWithTooltip>
                            <SortIcons sorted={isSorted('correspondentAccount', sortRec)} setSort={sort => setSortRec({ field: 'correspondentAccount', sort })} />
                            Корреспондентский счет
                        </HeaderWithTooltip>
                        <HeaderWithTooltip>
                            <SortIcons sorted={isSorted('transactionalAccountNumber', sortRec)} setSort={sort => setSortRec({ field: 'transactionalAccountNumber', sort })} />
                            Рассчетный счет
                        </HeaderWithTooltip>
                        <HeaderWithTooltip>
                            <SortIcons sorted={isSorted('bankName', sortRec)} setSort={sort => setSortRec({ field: 'bankName', sort })} />
                            Банк
                        </HeaderWithTooltip>
                        <HeaderWithTooltip>
                            <SortIcons sorted={isSorted('bin', sortRec)} setSort={sort => setSortRec({ field: 'bin', sort })} />
                            БИК
                        </HeaderWithTooltip>
                    </tr>
                    {isFilterOpened && <tr>
                        <th className={styles.cell}><FormControlEshopInput small onChange={e => setAccNumber(e.target.value)} placeholder="Введите значение" /></th>
                        <th className={styles.cell}><FormControlEshopInput small onChange={e => setСorrespondentNumber(e.target.value)} placeholder="Введите значение" /></th>
                        <th className={styles.cell}><FormControlEshopInput small onChange={e => setTransactionalNumber(e.target.value)} placeholder="Введите значение" /></th>
                        <th className={styles.cell}><FormControlEshopInput small onChange={e => setBankNameNumber(e.target.value)} placeholder="Введите значение" /></th>
                        <th className={styles.cell}><FormControlEshopInput small onChange={e => setBin(e.target.value)} placeholder="Введите значение" /></th>
                    </tr>}
                    </thead>
                    <tbody>
                    {recordsSorted().map(a => <tr onClick={() => canChange && changeAccountId(a.id)} key={a.id} className={styles.tableRow}>
                        <td className={`${styles.cellSelectable} ${styles.cell} ${styles.cellBody} ${store.selectedAccount === a.id ? styles.cellSelected : ''}`}>{a.accountNumber}</td>
                        <td className={`${styles.cellSelectable} ${styles.cell} ${styles.cellBody} ${store.selectedAccount === a.id ? styles.cellSelected : ''}`}>{a.correspondentAccount}</td>
                        <td className={`${styles.cellSelectable} ${styles.cell} ${styles.cellBody} ${store.selectedAccount === a.id ? styles.cellSelected : ''}`}>{a.transactionalAccountNumber}</td>
                        <td className={`${styles.cellSelectable} ${styles.cell} ${styles.cellBody} ${store.selectedAccount === a.id ? styles.cellSelected : ''}`}>{a.bankName}</td>
                        <td className={`${styles.cellSelectable} ${styles.cell} ${styles.cellBody} ${store.selectedAccount === a.id ? styles.cellSelected : ''}`}>{a.bin}</td>
                    </tr>)}
                    </tbody>
                </table>
            </Card>
    </Section>
    );
};

