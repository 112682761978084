




import {DirectPurchase as DirectPurchaseR} from "./DirectPurchase";
import {Component, Prop} from "vue-property-decorator";
import { SubjectClassFilterStore } from '@/components/eshop/SubjectClassFilterStore';

import Vue from 'vue';

@Component({ components: { komponent: DirectPurchaseR as unknown as typeof Vue } })
export default class DirectPurchase extends Vue {
    @Prop() public favorites!: boolean;
    public storeContainer =  Object.freeze({ subjectClassSelector: new SubjectClassFilterStore() });
}
