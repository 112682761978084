import React from "react";
import { Card } from "@/components/Card";
import { Field, Fields } from "@/components/form/Field";

export const ProvisionTextSection = () => {
    return (
        <Card>
            <Fields orientation="horizontal">
                <Field title="Файл">
                    <div style={{ cursor: "pointer", color: "#428bca", textDecoration: "underline", userSelect: "none" }}>
                        Provision_2022_05_16_11_44.pdf
                    </div>
                </Field>
            </Fields>
        </Card>
    );
};
