import { createStore, Store } from "effector";
import { Provision } from "@/models/Provision/Provision";
import { createStoredRemoteDataset, StoredRemoteDataset } from "@/components/table";
import { getProvisions } from "@/api/Provision";
import { FilterConditionType, FilterValueType } from "@/api/http";

export type ProvisionListFilters = {
    customer: string
    status: string
};

type ProvisionListState = {
    provisionsSrd: StoredRemoteDataset<Provision, ProvisionListFilters>
};

export type ProvisionListStore = {
    store: Store<ProvisionListState>
};

export const createProvisionListStore = (): ProvisionListStore => {
    return {
        store: createStore<ProvisionListState>({
            provisionsSrd: createStoredRemoteDataset<Provision, ProvisionListFilters>({
                async load(filters, from, count) {
                    return await getProvisions(from, count, {
                        customer: [{
                            type: FilterValueType.STRING,
                            conditionType: FilterConditionType.CONTAINS,
                            string: filters.customer
                        }],
                        status: [{
                            type: FilterValueType.STRING,
                            conditionType: FilterConditionType.EQUAL,
                            string: filters.status
                        }]
                    });
                }
            })
        })
    };
};
