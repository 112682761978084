import React from "react";
import { Provision } from "@/models/Provision/Provision";
import { Card } from "@/components/Card";
import { Field, Fields } from "@/components/form/Field";
import { formatNumber } from "@/NumberFormatting";

type ContractProvisionProps = {
    provision?: Provision
};

export const ContractProvision = ({ provision }: ContractProvisionProps) => {
    return (
        <Card>
            <Fields orientation="horizontal">
                <Field title="Минимальный размер обеспечения исполнения договора, %" preset="readonlyValue">
                    {formatNumber(provision?.minValueEnforcement ?? 0, true, 0)}%
                </Field>
                <Field title="Срок действия обеспечения исполнения договора" preset="boldSmall">
                    <div></div>
                </Field>
                <Field title="Банковская гарантия">
                    + {provision?.bankWarrantyDays ?? 0} дней с момента поставки товаров (выполнение работ, оказание услуг)
                </Field>
                <Field title="Залог денежных средств">
                    + {provision?.depositDays ?? 0} дней с момента поставки товаров (выполнение работ, оказание услуг)
                </Field>
            </Fields>
        </Card>
    );
};
