import {asDecimal, AutoDto, AutoModel, MDMap} from "@/models/parsing";
import Decimal from "decimal.js";

interface CriteriaMD {
    name: string
    orderNumber: number
    value?: Decimal
    content: string
    assessmentOrder: string
    canAddSubcriteria: boolean
    subcriteria: MDMap<SubCriteria, DtoSubCriteria>[]
}

export type DtoCriteria = AutoDto<CriteriaMD>;
export type Criteria = AutoModel<CriteriaMD>;

export const Criteria = {
    toDto(cr: Criteria): DtoCriteria {
        return {
            ...cr,
            value: cr.value?.toString(),
            subcriteria: cr.subcriteria.map(SubCriteria.toDto)

        };
    },
    fromDto(cr: DtoCriteria): Criteria {
        return {
            ...cr,
            value: asDecimal(cr.value),
            subcriteria: cr.subcriteria.map(SubCriteria.fromDto)
        };
    }
};

interface SubCriteriaMD {
    name: string
    value?: Decimal
    content: string
    assessmentOrder: string
}

export type DtoSubCriteria = AutoDto<SubCriteriaMD>;
export type SubCriteria = AutoModel<SubCriteriaMD>;

export const SubCriteria = {
    toDto(cr: SubCriteria): DtoSubCriteria {
        return {
            ...cr,
            value: cr.value?.toString()
        };
    },
    fromDto(cr: DtoSubCriteria): SubCriteria {
        return {
            ...cr,
            value: asDecimal(cr.value),
        };
    }
};
