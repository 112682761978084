import { http } from "./http";
import { User } from '@/models/User';
import Vue from 'vue';
import { createEvent, createStore } from "effector";
import { uuid } from "@/models/parsing";

const defaultYear = 2024;

export const setSelectedParticipant = async (userId: uuid, participantId: number) => {
    const dtoUser = await http.post(`/api/users/${userId}/setCurrentParticipant/${participantId}`, undefined);
    if (dtoUser) return User.fromJson(dtoUser);
};

const Auth = class {
    // making it reactive (pls use vuex)
    public static _currentUserInfoWrap = new Vue({ data: {
        user: null as User | null,
        year: (localStorage["selected_year"]|0) || 2024
    } });

    public static get currentUserInfo() { return this._currentUserInfoWrap.user }
    public static set currentUserInfo(u) { this._currentUserInfoWrap.user = u }

    public static get selectedYear() { return this._currentUserInfoWrap.year }
    public static set selectedYear(y) {
        this._currentUserInfoWrap.year = y;
        localStorage["selected_year"] = y;
    }

    public static async login(username: string, password: string): Promise<unknown> {
        const r = await http.postBypassReady("/api/users/login", { username, password }) as { accessToken: string };

        try {
            http.token = r.accessToken;
            this._currentUserInfoWrap.user = await User.fromJson(await http.getBypassReady("/api/users/info"));
            // eslint-disable-next-line @typescript-eslint/ban-ts-ignore
            // @ts-ignore
            window.OpenReplay?.setMetadata("login", username);
        } catch (e) {
            http.token = null;
            throw e;
        }

        return r;
    }

    public static async getUserInfo(): Promise<User> {
        this._currentUserInfoWrap.user = User.fromJson(await http.getBypassReady("/api/users/info"));
        // set openreplay metadata on session change
        // eslint-disable-next-line @typescript-eslint/ban-ts-ignore
        // @ts-ignore
        window.OpenReplay?.setMetadata("login", this._currentUserInfoWrap.user.username);
        return this._currentUserInfoWrap.user;
    }

    public static async getUserInfoIfRequired(): Promise<User> {
        return this._currentUserInfoWrap.user ?? (this._currentUserInfoWrap.user = User.fromJson(await http.getBypassReady("/api/users/info")));
    }

    public static logout() {
        this.reset();
    }

    public static reset() {
        this._currentUserInfoWrap.user = null;
        this._currentUserInfoWrap.year = defaultYear;
        localStorage.removeItem("sc44_access_token");
        http.token = null;
    }
};

export default Auth;

const changeUser = createEvent<User | undefined>("change user in user info store");
const changeYear = createEvent<number>("change year in user info store");

export const userInfoStore =
    createStore<{ user?: User; year: number }>({
        user: Auth._currentUserInfoWrap.user ?? undefined,
        year: Auth._currentUserInfoWrap.year
    }).on(changeUser, (x, u) => ({ user: u, year: x.year }))
    .on(changeYear, (x, y) => ({ user: x.user, year: y }));

Auth._currentUserInfoWrap.$watch("user", u => changeUser(u));
Auth._currentUserInfoWrap.$watch("year", u => changeYear(u));
