import {FilterConditionType, FilterValueType, http, IFilterObject} from "@/api/http";
import {CharacteristicDeclaration, DtoCharacteristicDeclaration} from "@/models/ComposedLots/specifications";
import {CancellationReason, DtoCancellationReason} from "@/models/ComposedLots/CancellationReason";
import {Participant, ParticipantMD} from "@/models";
import {IDtoParticipant} from "@/models/json";
import {IPagedItems, mapPage} from "@/api/Pagination";
import {SubjectClass} from "@/models/SubjectClass/SubjectClass";
import {SubjectDeclaration, SubjectDeclarationInstance} from "@/models/SubjectDeclaration/SubjectDeclaration";
import {Customer} from "@/models/Customer";
import {Supplier} from "@/models/Supplier";
import { PurchaseCategory } from "@/models/ComposedLots/PurchaseCategory";

export const getSubjectClasses = async (from: number, count: number, filters?: IFilterObject): Promise<IPagedItems<SubjectClass>> => {
    return mapPage(SubjectClass, await http.getPaged("/api/v2/directory/subjectClass", {
        from,
        count,
        filters
    }));
};

export const getSubjectDeclarations = async (from: number, count: number, filters?: IFilterObject): Promise<IPagedItems<SubjectDeclaration>> => {
    return mapPage(SubjectDeclaration, await http.getPaged("/api/v2/directory/subjectDeclaration", {
        from,
        count,
        filters
    }));
};

export const getCustomers = async (from: number, count: number, filters?: IFilterObject): Promise<IPagedItems<Customer>> => {
    return mapPage(Customer, await http.getPaged("/api/v2/directory/reference-info/customers", {
        from,
        count,
        filters
    }));
};

export const getSuppliers = async (from: number, count: number, filters?: IFilterObject): Promise<IPagedItems<Supplier>> => {
    return mapPage(Supplier, await http.getPaged("/api/v2/directory/reference-info/suppliers", {
        from,
        count,
        filters
    }));
};

export const getAllParticipants = async (): Promise<Participant[]> =>
    (await http.get('/api/v2/directory/reference-info/suppliers/all', true) as IDtoParticipant[])
        .map(Participant.fromJson);

export const getCharacteristicDeclarations = async (subjectClassId: number): Promise<CharacteristicDeclaration[]> =>
    (await http.get(`/api/v2/directory/subjectDeclaration/${subjectClassId}/characteristicDeclarations`) as DtoCharacteristicDeclaration[])
        .map(CharacteristicDeclaration.fromDto);

export const getLotCancellationReasons = async (): Promise<CancellationReason[]> =>
    (await http.get(`/api/v2/directory/lotCancellationReasons`) as DtoCancellationReason[])
        .map(CancellationReason.fromDto);

export const getLotParticipants = async (): Promise<Participant[]> =>
    (await http.get(`/api/v2/directory/participants`) as IDtoParticipant[])
        .map(Participant.fromJson);

export const getLotParticipantsBypassReady = async (): Promise<Participant[]> =>
    (await http.getReady(`/api/directory/participants`, {
        from: 0,
        count: 600,
        filters: {
            isValidForUserSelection: [
                {
                    type: FilterValueType.BOOLEAN,
                    conditionType: FilterConditionType.EQUAL,
                    boolean: true
                }
            ]
        }
    }, true).then(o => o.items));

export const getPossibleMainCustomers = async (): Promise<Participant[]> =>
    (await http.get(`/api/v2/directory/customers`) as IDtoParticipant[])
        .map(a => Participant.fromJson(a));

export const getSubjectClassById = async (id: string): Promise<SubjectClass> => {
    return await http.get(`/api/v2/directory/subjectClass/${id}`);
};

export const getSubjectDeclarationById = async (id: string): Promise<SubjectDeclarationInstance> => {
    return await http.get(`/api/v2/directory/subjectDeclaration/${id}`);
};

export const getParticipantsById = async (id: string): Promise<ParticipantMD> => {
    return await http.get(`/api/v2/directory/participants/${id}`);
};

export const getPurchaseCategories = async (): Promise<PurchaseCategory[]> => {
    return await http.get('/api/v2/directory/purchaseCategories');
};
