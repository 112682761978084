



















import CardModal from "@/components/CardModal.vue";
import {ModalResult} from "@/view-models/ModalRequest";
import {Component, Prop, Vue} from "vue-property-decorator";

@Component({ components: { CardModal } })
export default class ErrorModal extends Vue {
    @Prop() private prop!: { completed: boolean };

    public reason = "";

    public ok() {
        this.$emit('close-modal', ModalResult.ok(null));
    }
}
