// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_GET_URL_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/getUrl.js");
var ___CSS_LOADER_URL_IMPORT_0___ = require("../../assets/icons.png");
exports = ___CSS_LOADER_API_IMPORT___(false);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
// Module
exports.push([module.id, "\n.pagination[data-v-d5b6e372] {\n    display: flex;\n    align-items: center;\n    margin-top: 15px;\n}\n.page-selector[data-v-d5b6e372] {\n    display: flex;\n\n    background: white;\n    border-radius: 3px;\n    border-width: 0 1px 1px;\n    border-style: solid;\n    border-color: transparent #ccd1de #bbc1d0;\n}\n.page-link[data-v-d5b6e372] {\n    display: flex;\n    justify-content: center;\n    align-items: center;\n\n    min-width: 22px;\n    padding: 0 5px;\n    height: 24px;\n    box-sizing: border-box;\n\n    font-weight: 500;\n    font-size: 13px;\n\n    border: 1px solid transparent;\n\n    cursor: pointer;\n    user-select: none;\n}\n.page-link.to-page + .page-link.to-page[data-v-d5b6e372] {\n    margin-left: 3px;\n}\n.page-link[data-v-d5b6e372]:not(.to-page):hover {\n    border-top: 1px solid #999;\n}\n.page-link.to-page[data-v-d5b6e372]:hover,\n.page-link.active[data-v-d5b6e372] {\n    border: 1px solid #3f8fca;\n}\n.to-start[data-v-d5b6e372] {\n    background: url(" + ___CSS_LOADER_URL_REPLACEMENT_0___ + ") -9px -527px no-repeat;\n}\n.to-prev[data-v-d5b6e372] {\n    background: url(" + ___CSS_LOADER_URL_REPLACEMENT_0___ + ") -9px -447px no-repeat;\n}\n.to-next[data-v-d5b6e372] {\n    background: url(" + ___CSS_LOADER_URL_REPLACEMENT_0___ + ") -47px -447px no-repeat;\n}\n.to-end[data-v-d5b6e372] {\n    background: url(" + ___CSS_LOADER_URL_REPLACEMENT_0___ + ") -47px -527px no-repeat;\n}\n.page-info[data-v-d5b6e372] {\n    font-size: 12px;\n    margin-left: 10px;\n}\n", ""]);
// Exports
module.exports = exports;
