import {UnionStrings} from "@/models/enums/EnumStrings";

export type SpecialPurchaseType =
    | "NO"
    | "SMALL_VOLUME_SIMPLE"
    | "SMALL_VOLUME_CARE"
    | "MEDICINES"
    | "BUILDING_MAINTENANCE"
    | "BUSINESS_TRIPS"
    | "TEACHING_SERVICE"
    | "SPORT_SERVICE"
    | "SPECIAL_SERVICE";

export const SpecialPurchaseTypeStrings: UnionStrings<SpecialPurchaseType> = {
    NO: "Нет",
    SMALL_VOLUME_SIMPLE: "п.4 ч.1 ст.93 (закупка малого объема)",
    SMALL_VOLUME_CARE: "п.5 ч.1 ст.93 (для учреждений культуры, спорта, образования)",
    MEDICINES: "п.7 ч.2 ст.83, п.3 ч.2 ст.83.1 (лекарственные препараты)",
    BUILDING_MAINTENANCE: "п.23 ч.1 ст.93 (содержание и ремонт помещений)",
    BUSINESS_TRIPS: "п.26 ч.1 ст.93 (направление работника в служебную командировку)",
    TEACHING_SERVICE: "п.33 ч.1 ст.93 (преподавательские услуги, услуги экскурсовода (гида))",
    SPORT_SERVICE: "ч.12 ст.93 (по п. 5 ч.1 ст. 93)",
    SPECIAL_SERVICE: "ч.12 ст.93 (по п. 4 ч.1 ст. 93)"
};