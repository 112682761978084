import { http } from "./http";
import { ContractSubcontractor } from '@/models/Contracts/ContractSubcontractor';
import { IDtoContractSubcontractor } from '@/models/json/Contracts/IDtoContractSubcontractor';

export default class {
    public static async getFromContract(contractId: string): Promise<ContractSubcontractor[]> {
        return (await http.get(`/api/contractSubcontractors/fromContract/${contractId}`, undefined) as IDtoContractSubcontractor[])
            .map(ContractSubcontractor.fromJson);
    }

    public static async create(contractId: string, src: ContractSubcontractor): Promise<ContractSubcontractor> {
        return ContractSubcontractor.fromJson(await http.post(`/api/contractSubcontractors/createFor/${contractId}`, src.toJson()));
    }

    public static async update(obj: ContractSubcontractor): Promise<ContractSubcontractor> {
        return ContractSubcontractor.fromJson(await http.put(`/api/contractSubcontractors/${obj.id}`, obj.toJson()));
    }

    public static async delete(id: string): Promise<void> {
        await http.delete(`/api/contractSubcontractors/${id}`);
    }
}
