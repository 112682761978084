import React, {useEffect, useState} from "react";
import {
    changeDuration,
    changeLaw,
    changeSubjectId,
    changeSubjectJoin,
    ServicesStore,
    SubjectDeclarationForCreationService
} from "@/views/CreateSession/store";
import {Section} from "@/components/eshop/Section/Section";
import {Card} from "@/components/eshop/Card/Card";
import {Input} from "@/components/eshop/Input/Input";
import styles from "../CreateSession.module.css";
import {Select} from "@/components/eshop/Select/Select";
import {FieldsetText} from "@/components/eshop/FieldsetText/FieldsetText";
import {formatNumber} from "@/NumberFormatting";
import {showModal} from "@/EventBus";
import {SubjectClassSelectModal} from "@/views/modals/SubjectClassSelectModal/SubjectClassSelectModal";
import {ISubjectClassContainer, SubjectClassFilterStore} from "@/components/eshop/SubjectClassFilterStore";
import Auth from "@/api/Auth";

interface GeneralInfoCardProps {
    store: ServicesStore
    canChange: boolean
    data: SubjectDeclarationForCreationService[]
    initialAmount: number
    subjectClassSelector: SubjectClassFilterStore
}

export const GeneralInfoCard = (props: GeneralInfoCardProps) => {
    const {store, canChange, data, initialAmount} = props;

    const [kpgz, setKpgz] = useState(store.subject.id);
    const lawOptions = [{ name: "44-ФЗ", value: "F44" }, { name: "223-ФЗ", value: "F223" }];
    const hoursOptions = [{ name: "3", value: "3" }, { name: "6", value: "6" }, { name: "24", value: "24" }];

    useEffect(() => {
        const foundSubject = props.subjectClassSelector.loadedClasses?.find(i => (i.id).toString() === store.subject.id);
        if (foundSubject) {
            setKpgz(`${foundSubject.object.code}/${getKPGZFullName(foundSubject)}`);
        }
    }, [props.store.subject]);

    const handleChangeKPGZ = async () => {
        const res = await showModal(SubjectClassSelectModal, {store: props.subjectClassSelector, value: kpgz});
        if (res) {
            setKpgz(`${res.object.code}/${getKPGZFullName(res)}`);
            changeSubjectId((res.object.id).toString());
        }
    };

    const getKPGZFullName = (subject: ISubjectClassContainer) => {
        return [ ...subject.object.parents.map(i => i.name), subject.object.description].join("/");
    };

    return (
        <Section title={"Общие сведения"} required canBeHidden>
            <Card direction={"vertical"}>
                <Card direction={"horizontal"} padded border={"thin"}>
                    <div style={{width: "100%"}}>
                        <Input label={"Предмет совместной КС"} value={store.subjectJoin} fullWidth onChange={e => changeSubjectJoin(e.target.value)} required withTooltip />
                    </div>
                </Card>
                <div className={styles.innerCardsWrapper}>
                    <Card direction={"vertical"} padded border={"thin"}>
                        <Select label={"Закон-основание"}
                                value={store.law}
                                options={lawOptions}
                                onSelect={v => v && changeLaw(v)}
                                required
                                withTooltip
                                disabled={!canChange} />
                        <FieldsetText label={"Начальная цена"} withTooltip value={`${formatNumber(initialAmount)} руб`} />
                        <FieldsetText label={"Продолжительность КС, часа"} withTooltip value={store.duration} />
                    </Card>
                    <Card direction={"vertical"} padded border={"thin"}>
                        <FieldsetText required label={"КПГЗ совместной КС"} withTooltip value={!kpgz ? "Выбрать" : kpgz} onClick={() => handleChangeKPGZ()} />
                        <FieldsetText label={"Организатор совместной КС"} withTooltip value={Auth.currentUserInfo?.selectedParticipant.fullName} onClick={() => {}} />
                        <FieldsetText label={"Реестровый номер на ПП"} withTooltip value={store.currentSession?.regNumber ?? "Не сформирован"} />
                    </Card>
                </div>
            </Card>
        </Section>
    );
};
