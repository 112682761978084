








import { Component, Vue, Provide, Mixins } from "vue-property-decorator";
import { labelSpanContext, valueSpanContext, FComponent } from "./FComponent";

@Component
export default class FField extends Mixins(FComponent) {
    public get grid() {
        if (this.resolvedLayout() === "vertical") return "none";

        const [a, b] = this.resolvedRatio();
        const s = a + b;

        return `auto-flow / repeat(${this.total}, 1fr)`;
    }

    public get total() {
        const [a, b] = this.resolvedRatio();
        const s = a + b;

        return s;
    }

    @Provide(labelSpanContext)
    public get labelSpan() {
        return () => {
            if (this.resolvedLayout() === "vertical") return "auto";

            const [a] = this.resolvedRatio();

            return `auto / span ${a}`;
        };
    }

    @Provide(valueSpanContext)
    public get valueSpan() {
        return () => {
            if (this.resolvedLayout() === "vertical") return "auto";

            const [a, b] = this.resolvedRatio();

            return `auto / span ${this.total - a}`;
        };
    }
}
