import { http } from "./http";
import {Contract, ContractExecutionHistoryEntry} from '@/models/Contracts';
import { IDtoContractExecutionHistoryEntry } from '@/models/json/Contracts';

export default class {
    public static async getFromContract(contractId: string): Promise<ContractExecutionHistoryEntry[]> {
        return (await http.get(`/api/contractExecutionHistory/fromContract/${contractId}`, undefined) as IDtoContractExecutionHistoryEntry[])
            .map(ContractExecutionHistoryEntry.fromJson);
    }

    public static async create(contractId: string, src: ContractExecutionHistoryEntry): Promise<ContractExecutionHistoryEntry> {
        return ContractExecutionHistoryEntry.fromJson(await http.post(`/api/contractExecutionHistory/createFor/${contractId}`, src.toJson()));
    }

    public static async createAndSend(contractId: string, src: ContractExecutionHistoryEntry, simulateDisagreements: boolean): Promise<ContractExecutionHistoryEntry> {
        return ContractExecutionHistoryEntry.fromJson(await http.post(`/api/contractExecutionHistory/createFor/${contractId}?send=true&simulateDisagreements=${simulateDisagreements}`, src.toJson()));
    }

    public static async send(obj: ContractExecutionHistoryEntry, simulateDisagreements: boolean, contract: Contract): Promise<ContractExecutionHistoryEntry> {
        return ContractExecutionHistoryEntry.fromJson(await http.post(`/api/contractExecutionHistory/${obj.id}/send?simulateDisagreements=${simulateDisagreements}`, contract.toJson()));
    }

    public static async save(obj: ContractExecutionHistoryEntry): Promise<ContractExecutionHistoryEntry> {
        return ContractExecutionHistoryEntry.fromJson(await http.put(`/api/contractExecutionHistory/${obj.id}`, obj.toJson()));
    }

    public static async makeChanges(obj: ContractExecutionHistoryEntry): Promise<ContractExecutionHistoryEntry> {
        return ContractExecutionHistoryEntry.fromJson(await http.post(`/api/contractExecutionHistory/${obj.id}/makeChanges`, undefined));
    }

    public static async cancelChanges(obj: ContractExecutionHistoryEntry): Promise<ContractExecutionHistoryEntry> {
        return ContractExecutionHistoryEntry.fromJson(await http.post(`/api/contractExecutionHistory/${obj.id}/cancelChanges`, undefined));
    }

    public static async eisSign(obj: ContractExecutionHistoryEntry): Promise<ContractExecutionHistoryEntry> {
        return ContractExecutionHistoryEntry.fromJson(await http.post(`/api/contractExecutionHistory/${obj.id}/sign`, undefined));
    }
}
