import {ModalFC} from "@/EventBus";
import {Expandee, HStack, VStack} from "@/components/layouts";
import {Button, TextBox} from "@/components/primitive";
import {CardModal} from "@/components/modals/CardModal";
import React, {useState} from "react";
import {
    Column, SelectionTable, Table
} from "@/components/table";
import {Field, Fields} from "@/components/form/Field";
import {ContactPerson} from "@/models/Procedures2020/ContactPerson";

/** Temporary type, waiting for backend */
export interface Person {
    primary: boolean
    firstName: string
    lastName: string
    middleName: string
    mail: string
    phone: string
}

export const SelectContactPersonModal: ModalFC<{ existing?: ContactPerson; persons: Person[] }, ContactPerson> = x => {
    const columns: Column<Person>[] = [
        Table.Column("Основной", a => <div>
            {a.item.primary ? "Да" : "Нет"}
        </div>, {width: "1fr"}),
        Table.Column("Фамилия", a => <div>
            {a.item.lastName}
        </div>, {width: "3fr"}),
        Table.Column("Имя", a => <div>
            {a.item.firstName}
        </div>, {width: "3fr"}),
        Table.Column("Отчество", a => <div>
            {a.item.middleName}
        </div>, {width: "3fr"}),
        Table.Column("E-mail", a => <div>
            {a.item.mail}
        </div>, {width: "3fr"}),
        Table.Column("Телефон", a => <div>
            {a.item.phone}
        </div>, {width: "3fr"}),

    ];

    const [contactPerson, setContactPerson] = useState<Partial<ContactPerson>>(x.existing ?? {});
    const [selectedTemplate, setSelectedTemplate] = useState<Person | undefined>();

    const select = (person: Person) => {
        setSelectedTemplate(person);
        setContactPerson({
            email: person.mail,
            name: `${person.firstName} ${person.lastName} ${person.middleName}`,
            phoneNumber: person.phone
        });
    };

    const change = <K extends keyof ContactPerson>(k: K, v: ContactPerson[K]) => {
        setSelectedTemplate(undefined);
        setContactPerson({...contactPerson, [k]: v});
    };

    const isOk = !!(contactPerson.name && contactPerson.email && contactPerson.phoneNumber);
    const save = () => {
        if (contactPerson.name && contactPerson.email && contactPerson.phoneNumber)
            x.done({
                name: contactPerson.name,
                email: contactPerson.email,
                phoneNumber: contactPerson.phoneNumber
            });
    };

    const bottom = <HStack spacing={"10px"}>
        <Expandee/>
        <Button color="green"
                title="Выбрать"
                disabled={!isOk}
                onClick={save} />
        <Button color="red"
                icon="aClose"
                onClick={() => x.done(undefined)} />
    </HStack>;

    return <CardModal title="Выбор контактного лица"
                      close={() => x.done(undefined)}
                      width="70%"
                      bottom={bottom}>
        <VStack spacing={"15px"}>
            <Fields orientation={"horizontal"}>
                <Field title="ФИО">
                    <TextBox value={contactPerson.name}
                             onChange={x => change("name", x)} />
                </Field>
                <Field title="Email">
                    <TextBox value={contactPerson.email}
                             onChange={x => change("email", x)} />
                </Field>
                <Field title="Телефон">
                    <TextBox value={contactPerson.phoneNumber}
                             onChange={x => change("phoneNumber", x)} />
                </Field>
            </Fields>

            <SelectionTable<Person>
                selected={selectedTemplate}
                mode="single"
                disallowDeselection
                onChange={select}
                selectorPosition="hidden"
                dataset={x.persons}
                columns={columns}
            />
        </VStack>
    </CardModal>;
};