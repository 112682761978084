import React, { CSSProperties, useState } from "react";
import { ModalFC } from "@/EventBus";
import { mockRoles, Role } from "@/views/Register/roles/roles";
import { CardModal } from "@/components/modals/CardModal";
import { Expandee, HStack, VStack } from "@/components/layouts";
import { RoundedButton } from "@/components/primitive/RoundedButton";
import { SearchBox } from "office-ui-fabric-react";
import { Column, Table } from "@/components/table";
import { CheckBox } from "@/components/primitive";

const headerStyle: CSSProperties = {
    backgroundColor: "white",
    fontWeight: 300,
    fontSize: "18px",
    paddingBottom: "20px",
    paddingTop: "20px",
    color: "rgba(70, 93, 104, 0.5)"
};

const cellStyle: CSSProperties = {
    fontWeight: 300,
    fontSize: "16px"
};

export const RoleSelectModal: ModalFC<{}, Role[]> = x => {
    const [selected, setSelected] = useState<Role[]>([]);
    const [filter, setFilter] = useState<string>("");

    const bottom: React.ReactNode = <HStack spacing="10px">
        <Expandee />
        <RoundedButton color="blue" title="Выбрать" disabled={!selected} onClick={() => x.done(selected)} />
        <RoundedButton color="white" title="Закрыть" onClick={() => x.done(undefined)} />
    </HStack>;



    const filtered = filter ? mockRoles.filter(r => `${r.name}${r.desc}`.toLowerCase().includes(filter.toLowerCase())) : mockRoles;
    const checkAll = (v: boolean) => v
        ? setSelected(filtered)
        : setSelected([]);
    const addOrRemoveRole = (r: Role) => selected.find(s => s.id === r.id)
        ? setSelected(selected.filter(s => s.id !== r.id))
        : setSelected([...selected, r]);

    const columns: Column<Role>[] = [
        Table.AutoColumn(<CheckBox value={selected.length === filtered.length} onChange={checkAll}/>, x => <CheckBox value={!!selected.find(s => s.id === x.item.id)}
                                                                                                                     onChange={() => addOrRemoveRole(x.item)} />, { headerStyle, cellStyle }),
        Table.Column("Наименование роли", x => <>{ x.item.name }</>, { headerStyle, cellStyle }),
        Table.Column("Описание роли", x => <>{ x.item.desc }</>, { headerStyle, cellStyle }),
    ];

    return <CardModal close={() => x.done(undefined)}
                      width="45%"
                      cardHeaderStyle={{ backgroundColor: "white" }}
                      bottom={bottom}
                      title="Выбор роли">
        <VStack spacing="20px">
            <SearchBox style={{ width: "450px" }}
                       value={filter}
                       onChange={e => setFilter(e?.target.value ?? "")}
                       placeholder="Поиск по наименованию и описанию" />
            <Table columns={columns}
                   dataset={filtered} />
        </VStack>
    </CardModal>;
};
