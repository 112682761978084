import { Select } from "@/components/eshop/Select/Select";
import { FormControlEshopInput } from "@/components/eshop/FormControlEshopInput";
import { Modal } from "@/components/eshop/Modal";
import React, { } from "react";
import styles from '../CreateSession.module.css';
import { PrimaryButton, WarningButton } from "@/components/eshop/Buttons";
import {
    cancelPublicationSession,
    cancelSession,
    changeCancelReason,
    changeCancelReasonOther,
    publishSession, recallSession,
    ServicesStore
} from "../store";
import {sum} from "lodash";

interface ModalCancelProps {
    show: boolean
    setShowCancel: (show: boolean) => void
    store: ServicesStore
}

export const ModalCancel = (props: ModalCancelProps) => {
    const { store, show, setShowCancel } = props;
    const cancelOptions = [
        'Ошибка создания/содержания котировочной сессии',
        'Дубль котировочной сессии',
        'Избыточные потребительские свойства',
        'Количество продукции не соответствует спецификации',
        'Вид продукции не соответствует спецификации',
        'Иное'
    ].map(o => ({ name: o, value: o }));

    const onCancelSession = () => {
        const currentSession = store.currentSession;
        if (currentSession) {
            const reason = (store.cancelReason === 'Иное' ? store.cancelReasonOther : store.cancelReason) || '';
            cancelSession({ id: currentSession.id, reason });
            setShowCancel(false);
        }
    };

    const controls = () => {
        return <>
            <WarningButton onClick={() => onCancelSession()}>Отменить публикацию КС</WarningButton>
        </>;
    };
    return show
        ? <Modal onClose={() => setShowCancel(false)} controls={controls()} title="Отменить публикацию котировочной сессии">
            <div className={styles.cancelControls}>
                <span className={styles.cancelWarning}>Внимание!</span> Котировочная сессия <b>{store.currentSession?.subject.id}</b> на сумму <b>490,00</b> рублей будет снята с публикации.
            </div>

            <div className={styles.cancelControls}>
                <div className={styles.cancelControlsItem}>Причина отзыва</div>
                <div className={styles.cancelControlsItem}><Select value={store.cancelReason ?? ''} fullWidth onSelect={v => v && changeCancelReason(v)} options={cancelOptions} /></div>
            </div>
            {store.cancelReason === 'Иное' && <div className={styles.cancelControls}>
                <div className={styles.cancelControlsItem}>Укажите причину</div>
                <div className={styles.cancelControlsItem}>
                    <FormControlEshopInput
                        small
                        value={store.cancelReasonOther || undefined}
                        onChange={e => e && changeCancelReasonOther(e.target.value)}
                        placeholder="Введите значение" />
                </div>
            </div>}
        </Modal >
        : <div />;
};

interface ModalAdditionalInfoProps {
    message: object | null
    setAdditionalInfo: (value: object | null) => void
}

export const ModalAdditionalInfo = (props: ModalAdditionalInfoProps) => props.message !== null
    ? <Modal onClose={() => props.setAdditionalInfo(null)} title={'Системный статус'}>
        <div className={styles.infoModal}>
            {JSON.stringify(props.message).replaceAll(',', ', ')}
        </div>
    </Modal >
    : <div />;

interface ModalSendProps {
    show: boolean
    setShowSend: (show: boolean) => void
    store: ServicesStore
}

export const ModalSend = (props: ModalSendProps) => {
    const { store, show, setShowSend } = props;

    const sessionToSend = store.currentSession;

    const onSend = () => {
        if (sessionToSend) {
            publishSession({ id: sessionToSend.id });
        }
        setShowSend(false);
    };

    return show
        ? <Modal small onClose={() => setShowSend(false)} title={<div />}>
            <div className={styles.modalControlsContainer}>
                <div>
                    {`Действительно хотите создать котировочную сессию?`}
                </div>
                <div className={styles.buttonsContainerModal}>
                    <PrimaryButton filled onClick={() => setShowSend(false)}>Нет</PrimaryButton>
                    <PrimaryButton filled onClick={() => onSend()}>Да</PrimaryButton>
                </div>
            </div>
        </Modal >
        : <div />;

};

interface ModalCancelPublicationProps {
    show: boolean
    setShowCancelPublication: (showCancelPublication: boolean) => void
    store: ServicesStore
}

export const ModalCancelPublication = (props: ModalCancelPublicationProps) => {
    const { store, show, setShowCancelPublication } = props;

    const session = store.currentSession;

    const totalPaymentIncludedSession = sum(
        session?.includedSessions.flatMap(x =>
            x.specifications.map(s => s.totalPrice))
    );

    const onSend = () => {
        if (session) {
            cancelPublicationSession({ id: session.id });
        }
        setShowCancelPublication(false);
    };

    const controls = () => {
        return <>
            <PrimaryButton onClick={() => onSend()}>Отменить публикацию КС</PrimaryButton>
        </>;
    };

    return show
        ? <Modal medium onClose={() => setShowCancelPublication(false)}  controls={controls()} title="Отменить публикацию совместной КС по соглашению">
            <div className={styles.modalControlsContainer}>
                <div>
                    {`Внимание! Совместная котировочная сессия по соглашению на сумму ${totalPaymentIncludedSession} рублей будет снята с публикации.`}
                </div>
            </div>
        </Modal >
        : <div />;

};


interface ModalRecallProps {
    show: boolean
    setShowRecall: (showRecall: boolean) => void
    store: ServicesStore
}

export const ModalRecall = (props: ModalRecallProps) => {
    const { store, show, setShowRecall } = props;

    const session = store.currentSession;

    const totalPaymentIncludedSession = sum(
        session?.specifications.flatMap(s => s.totalPrice)
    );

    const onSend = () => {
        if (session) {
            recallSession({ id: session.id });
        }
        setShowRecall(false);
    };

    const controls = () => {
        return <>
            <PrimaryButton onClick={() => onSend()}>Отменить публикацию КС</PrimaryButton>
        </>;
    };

    return show
        ? <Modal medium onClose={() => setShowRecall(false)}  controls={controls()} title="Отозвать котировочную сессию из совместной КС по соглашению">
            <div className={styles.modalControlsContainer}>
                <div>
                    {`Внимание! Котировочная сессия на сумму ${totalPaymentIncludedSession} рублей будет отозвана из совместной котировочной сессии по соглашению ${session?.subject.id} .`}
                </div>
            </div>
        </Modal >
        : <div />;

};


