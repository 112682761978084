import React, { useState } from "react";
import { ModalFC } from "@/EventBus";
import { CardModal } from "@/components/modals/CardModal";
import styles from "./DocumentWizardModal.module.css";
import { Expandee, HStack } from "@/components/layouts";
import { WizardFinishTab } from "@/views/ComposedLot/edit/tabs/lot-documents/DocumentsSection/wizard/tabs/Finish";
import {
    WizardAnswer,
    WizardQuestion,
    WizardQuizTab
} from "@/views/ComposedLot/edit/tabs/lot-documents/DocumentsSection/wizard/tabs/Quiz";
import { QuizData, QuizTypes } from "@/views/ComposedLot/edit/tabs/lot-documents/DocumentsSection/wizard/mockData";
import {
    WizardEditableField,
    WizardEditTab
} from "@/views/ComposedLot/edit/tabs/lot-documents/DocumentsSection/wizard/tabs/Edit";
import { Button } from "@/components/primitive";

type WizardStage = "quiz" | "edit" | "final";
const getActiveClassName = (s: WizardStage, cur: WizardStage): string => s === cur
    ? styles.tab + " " + styles.active
    : styles.tab;

interface WizardNavigationState {
    mode: WizardStage
    questionIndex: number
    isReady: boolean
}

export interface TabAnswers {
    [key: string]: WizardAnswer
}

export const DocumentWizardModal: ModalFC<{ organization: QuizTypes }, boolean> = x => {
    const [navigationState, setNavigationState] = useState<WizardNavigationState>({
        mode: "quiz", 
        questionIndex: 0,
        isReady: false
    });
    const { questions, fields, documentName } = QuizData[x.organization];

    const [quizData, setQuizData] = useState<TabAnswers>({});
    const [fieldsData, setFieldsData] = useState<TabAnswers>({});

    const isQuizCompleted = questions.every(question => Boolean(quizData[question.id]));
    const isFieldsCompleted = fields.every(field => Boolean(fieldsData[field.id]));
    const isAllQuestionsAnswered = isQuizCompleted && isFieldsCompleted;

    const mapModeToQuestions: { [key: string]: WizardQuestion[] | WizardEditableField[] } = {
        quiz: questions,
        edit: fields
    };

    const getCompletionState = (mode: WizardStage) => {
        if (mode === "final") return false;

        const mapModesToState = {
            quiz: isQuizCompleted,
            edit: isFieldsCompleted
        };

        return mapModesToState[mode];
    };

    const modeNavigate =
      (questions: WizardQuestion[] | WizardEditableField[], direction: "prev" | "next") => () => {
        const modes: WizardStage[] = [...Object.keys(mapModeToQuestions)
            .filter(mode => Boolean(mapModeToQuestions[mode].length)) as WizardStage[],
            "final"];
        // const modes: WizardStage[] = ["quiz", "edit", "final"];
        const moveMode = (mode: WizardStage): void => {
            const currentModeIndex = modes.indexOf(mode);
            const newMode = direction === "next" ? modes[currentModeIndex + 1] : modes[currentModeIndex - 1] ?? "quiz";

            if (direction === "prev" && getCompletionState(mode) && navigationState.isReady) {
                setNavigationState(state => ({ ...state,
                    questionIndex: 0,
                    isReady: false
                }));

                return;
            }

            setNavigationState({
                mode: newMode,
                questionIndex: direction === "prev" ? mapModeToQuestions.quiz.length - 1 : 0,
                isReady: getCompletionState(newMode)
            });
        };

        const moveIndex = (currentIndex: number): void =>
            setNavigationState(state => ({...state, questionIndex: direction === "next" ? currentIndex + 1 : currentIndex - 1 ?? 0 }));

        const navigate = (exprForChangeMode: boolean): void => exprForChangeMode || navigationState.isReady
            ? moveMode(navigationState.mode)
            : moveIndex(navigationState.questionIndex);

        const navigationMapping = {
            prev: () => navigate(navigationState.questionIndex === 0),
            next: () => navigate(navigationState.questionIndex === questions.length - 1)
        };

        navigationMapping[direction]();
    };

    const onClickModeHandler = (mode: WizardStage) => () => {
        setNavigationState(({ mode, isReady: getCompletionState(mode), questionIndex: 0 }));
    };

    const title: React.ReactNode = <div className={styles.header}>
        <HStack>
            {!!questions.length && <div onClick={onClickModeHandler("quiz")}
                  className={getActiveClassName("quiz", navigationState.mode)}>
                Вопросы
            </div>}
            {!!fields.length && <div onClick={onClickModeHandler("edit")}
                  className={getActiveClassName("edit", navigationState.mode)}>
                Редактируемые области
            </div>}
            <div onClick={onClickModeHandler("final")}
                 className={getActiveClassName("final", navigationState.mode)}>
                Формирование
            </div>
        </HStack>
    </div>;

    const bottom: React.ReactNode = <HStack spacing="5px">
        <Expandee />
        {
            navigationState.mode === "final"
                ? <Button title={`Сформировать проект ${documentName}`} onClick={() => x.done(true)} disabled={!isAllQuestionsAnswered} />
                : <>
                    <Button title="Назад"
                            onClick={modeNavigate(mapModeToQuestions[navigationState.mode], "prev")}
                            disabled={navigationState.mode === "quiz"
                            && navigationState.questionIndex === 0
                            && !navigationState.isReady}/>
                    <Button title="Далее" onClick={modeNavigate(mapModeToQuestions[navigationState.mode], "next")} color="green" />
                </>
        }
    </HStack>;

    return <CardModal title={title}
                      close={() => x.done(undefined)}
                      bottom={bottom}
                      cardContentStyle={navigationState.mode === "final" || navigationState.isReady
                        ? { margin: "20px 10px 0px", padding: "0 20px 20px" }
                        : { padding: "unset", overflowY: "auto" }}
                      width="50%">
            {navigationState.mode === "quiz" && <WizardQuizTab
              questions={questions}
              ready={navigationState.isReady}
              selectedQuestionIndex={navigationState.questionIndex}
              onChangeQuestion={(_, i) =>
                setNavigationState(state => ({ ...state, questionIndex: i }))}
              onChangeAnswer={(q, a) =>
                setQuizData((state) => ({ ...state, [q.id]: a }))}
              selectedAnswers={quizData}
            />}
            {navigationState.mode === "edit" && <WizardEditTab
              fields={fields}
              ready={navigationState.isReady}
              selectedFieldIndex={navigationState.questionIndex}
              onChangeField={(_, i) =>
                setNavigationState(state => ({ ...state, questionIndex: i }))}
              onChangeAnswer={(q, a) =>
                setFieldsData(state => ({ ...state, [q.id]: a }))}
              selectedAnswers={fieldsData}
               />}
            {navigationState.mode === "final" && <WizardFinishTab ready={isAllQuestionsAnswered} />}
    </CardModal>;
};
