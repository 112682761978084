// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".r9LreKSElna0SLMok1jWc {\n    --color: #E5083B;\n    --bg: #E5083B1A;\n;\n}\n\n._3UaUSEtFNbqTbrJH4iDQuf {\n    --color: #00B9FF;\n    --bg: #00B9FF1A;\n}\n\n.uDIdkFtSBXkdqeRbC0P2Z {\n    --color: #005EEC;\n    --bg: #005EEC1A;\n}\n\n._1Olg6IsK30QqlgV8S_pNoa {\n    --color: #8C32E6;\n    --bg: #8C32E61A;\n}\n\n._1ePdTAWS9SBSrLkN0SPERX {\n    display: flex;\n    align-items: center;\n    justify-content: center;\n    user-select: none;\n\n    position: relative;\n    z-index: 1;\n    color: var(--color);\n    background: var(--bg);\n    padding: 8px 15px;\n    border-radius: 3px;\n\n    font-size: 14px;\n    font-weight: 400;\n    font-family: \"GothamPro-Medium\", sans-serif;\n\n    transition: all 0.1s ease;\n    cursor: pointer;\n    text-transform: uppercase;\n}\n\n._1ePdTAWS9SBSrLkN0SPERX:hover {\n    background: var(--color);\n    color: white;\n}\n", ""]);
// Exports
exports.locals = {
	"red": "r9LreKSElna0SLMok1jWc",
	"blue": "_3UaUSEtFNbqTbrJH4iDQuf",
	"green": "uDIdkFtSBXkdqeRbC0P2Z",
	"violet": "_1Olg6IsK30QqlgV8S_pNoa",
	"label": "_1ePdTAWS9SBSrLkN0SPERX"
};
module.exports = exports;
