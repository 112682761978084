import React, {useState} from "react";
import styles from "./LoginPage2.module.css";
import {Navbar2} from "@/views/Login/widgets/Navbar2";
import {NewsPage} from "@/views/Login/news/NewsPage";
import {LoginPage} from "@/views/Login/login/LoginPage";


export const LoginPage2: React.FC<{ route: "login" | "news" }> = x => {
    const [route, setRoute] = useState<string>(x.route);
    return <div className={styles.page}>
        <Navbar2 route={route} onRouteChange={setRoute} />
        <div style={{ width: "100%", height: "85px", background: "#F0F3F5" }}></div>
        <div style={{ width: "100vw", height: "50px", background: "#F0F3F5" }}></div>
        {
            route === "login" && <LoginPage />
        }
        {
            route === "news" && <NewsPage />
        }
    </div>;
};
