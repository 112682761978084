
import React from "react";
import { PrimaryButton, WarningButton } from "./Buttons";
import styles from "./Buttons.module.css";

interface SessionButtonsProps {
    onSend: () => void
    onDelete: () => void
    disabled?: boolean
}

export const SessionButtons = (props: SessionButtonsProps) => <div className={styles.containerButtons}>
    <PrimaryButton filled disabled={props.disabled} onClick={() => !props.disabled && props.onSend()}>Отправить</PrimaryButton>
    <WarningButton disabled={props.disabled} onClick={() => !props.disabled && props.onDelete()}>Удалить</WarningButton>
</div>;