










import { SelectOption } from '@/models/enums';
import { Component, Prop, Vue, Watch } from "vue-property-decorator";
import Decimal from "decimal.js";

@Component
export default class SelectCellEditor extends Vue {
    @Prop() public cellValue!: unknown;
    @Prop() public selectOptions!: Array<SelectOption<unknown>>;
    @Prop({ default: true }) public nullable!: boolean;
}
