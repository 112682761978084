

























import { ITableColumn, IRowConfiguration } from "@/components/TableAbstractions";
import EventBus from "@/EventBus";
import { Component, Vue, Prop } from "vue-property-decorator";
import DataTable from "@/components/vue/DataTable.vue";
import AddAdditionalConclusion from "./AddAdditionalConclusion.vue";
import {
    ContractAdditionalConclusion,
    AdditionalConclusionChangeStrings44,
    AdditionalConclusionChangeStrings223
} from "../../models/Contracts";
import { SimpleRow } from "@/components/vue/form-table/FormFields";
import { IFieldProps, FieldState } from "@/components/vue/form-table/FieldBase";
import { formatDate } from "@/DateFormatting";
import { ContractScope } from "./ContractScope";
import ContractAdditionalConclusions from "@/api/ContractAdditionalConclusions";

@Component({ components: { DataTable } })
export default class AdditionalAgreements extends Vue {
    @Prop() public props!: IFieldProps<SimpleRow<ContractScope>>;

    private law = this.props.source.contract.law;
    private additionalConclusions = this.law === 'F44'
        ? AdditionalConclusionChangeStrings44
        : AdditionalConclusionChangeStrings223;

    public get headers(): ITableColumn<ContractAdditionalConclusion>[] {
        return [
            {
                title: "​Номер",
                getter: src => src.number,
                size: "auto"
            },
            {
                title: "Дата заключения",
                getter: src => src.isElectronic ? "" : formatDate(src.date, true),
            },
            {
                title: "Вид вносимого изменения",
                getter: src => src.changes.map(x => this.additionalConclusions[x]).join("; "),
                size: "2fr"
            },
            {
                title: "Дата начала действия",
                getter: src => formatDate(src.terms.from, true),
            },
            {
                title: "Дата окончания действия",
                getter: src => formatDate(src.terms.to, true),
            }
        ];
    }

    public createConfig(): IRowConfiguration<ContractAdditionalConclusion> {
        const that = this;

        return {
            get useSlotForActionColumn() {
                return true; // !!that.props.source.contract && that.props.source.contract.status === ObjectStatus.EXECUTING
            },
        };
    }

    public config = this.createConfig();

    public get dataset(): ContractAdditionalConclusion[] {
        return this.props.source.additionalConclusions;
    }

    public async addAdditionalConclusion(src: ContractAdditionalConclusion | null) {
        const props = {
            additionalConclusion: src,
            scope: this.props.source,
            editable: this.props.state === FieldState.ENABLED
        };
        const result = await EventBus.callModal<typeof props, ContractAdditionalConclusion>(
            AddAdditionalConclusion, props);
        if (result.isOk) {
            const r = result.getResult();
            if (!src) {
                this.dataset.push(r);
            } else {
                const ix = this.dataset.findIndex(x => x.id === r.id);
                this.dataset.splice(ix, 1, r);
            }
        }
    }

    public async deleteAdditionalConclusion(src: ContractAdditionalConclusion) {
        await ContractAdditionalConclusions.delete(src);

        const ix = this.dataset.indexOf(src);
        if (ix !== -1) this.dataset.splice(ix, 1);
    }
}
