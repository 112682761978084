import { Field, FieldGrid, FieldInset } from "@/components/form/Field";
import { BoxPicker, Button, IntBox, Label } from "@/components/primitive";
import { VGrid } from "@/components/layouts";
import { Card } from "@/components/Card";
import React, { useEffect, useState } from "react";
import { useMappedStore } from "@/storeUtils";
import { Store } from "effector";
import {
    setActionClass,
    setIdError,
    setIdValue,
    setSubjectClass,
    setSubjectDeclaration,
    SpecificationStore
} from "@/modal-views/PickSpecificationModal/store";
import { showModal, typifyVueModal } from "@/EventBus";
import { PickSubjectClassModal, PickSubjectDeclarationModal } from "@/modal-views";
import { SubjectClass } from "@/models";
import SubjectDeclarations from "@/api/SubjectDeclarations";
import { FilterConditionType, FilterValueType } from "@/api/http";
import { LawType } from "@/models/enums";
import { ActionClass } from "@/models/ActionClass";
import ActionClassSelectionModal from "@/views/modals/ActionClassSelectionModal/ActionClassSelectionModal.vue";
import { useStore } from "effector-react";

const checkSubjectClassForDeclarations = async (id: number): Promise<boolean> => {
    const result = await SubjectDeclarations.get(0, 1, {
        subjectClassId: [{
            type: FilterValueType.LONGINT,
            conditionType: FilterConditionType.EQUAL,
            longint: id,
        }],
    });

    return result.totalCount > 0;
};

const PickActionClassModal = typifyVueModal<{ selected?: ActionClass }, ActionClass>(
    ActionClassSelectionModal,
    x => x.selected ?? null);

export const Selection = (x: { store: Store<SpecificationStore>; baseSubjectClass?: SubjectClass; law: LawType }) => {
    const [subjectClass, subjectDeclaration, actionClass, idValue, idError] = useMappedStore(x.store, x => [
        x.subjectClass, x.subjectDeclaration, x.actionClass, x.idValue, x.idError
    ]);

    const [versionId, setVersionId] = useState<number | undefined>(idValue);
    const [versionError, setVersionError] = useState<string | undefined>();

    const [hasDeclarations, setHasDeclarations] = useState<boolean | undefined>();

    useEffect(() => {
        if (hasDeclarations === undefined) {
            if (subjectClass)
                checkSubjectClassForDeclarations(subjectClass.id).then(setHasDeclarations);
            else
                setHasDeclarations(false);
        }
    });

    const pickSubjectClass = async () => {
        const result = await showModal(PickSubjectClassModal, { selected: subjectClass, root: x.baseSubjectClass });
        if (result && result.id !== subjectClass?.id) {
            setSubjectClass(result);
            setHasDeclarations(undefined);
        }
    };

    const pickSubjectDeclaration = async () => {
        if (!subjectClass) return;

        const result = await showModal(PickSubjectDeclarationModal, { selected: subjectDeclaration, subjectClass, law: x.law, isEmpty: !hasDeclarations });

        if (result) {
            await setSubjectDeclaration(result);
            setVersionId(result.id);
        }
    };

    const pickActionClass = async () => {
        const result = await showModal(PickActionClassModal, { selected: actionClass });
        if (result) {
            setActionClass(result);
        }
    };

    const selectById = async () => {
        if (!idValue) {
            setIdError("Некорректный идентификатор СПГЗ");
            return;
        }

        const subjDecl = (await SubjectDeclarations.get(0, 1, {
            id: [{ type: FilterValueType.LONGINT, conditionType: FilterConditionType.EQUAL, longint: idValue }]})).items[0];

        if (!subjDecl) {
            setIdError("Некорректный идентификатор СПГЗ. СПГЗ удален или не существует");
            return;
        }
        if (x.baseSubjectClass) {
            const codePrefix = subjDecl.subjectClass.code;
            const curPrefix = x.baseSubjectClass.code;

            if (!codePrefix.startsWith(curPrefix)) {
                setIdError("Некорректный идентификатор СПГЗ. В объекте закупок выбран КПГЗ из другой ветки");
                return;
            }
        }
        await setSubjectDeclaration(subjDecl);
        setHasDeclarations(true);
        setVersionId(idValue);
    };

    const selectVersionById = async () => {
        if (!versionId) {
            setVersionError("Некорректный идентификатор версии СПГЗ");
            return;
        }

        const subjDecl = (await SubjectDeclarations.get(0, 1, {
            id: [{ type: FilterValueType.LONGINT, conditionType: FilterConditionType.EQUAL, longint: versionId }]})).items[0];

        if (!subjDecl) {
            setVersionError("Некорректный идентификатор версии СПГЗ. СПГЗ удален или не существует");
            return;
        }
        if (x.baseSubjectClass) {
            const codePrefix = subjDecl.subjectClass.code;
            const curPrefix = x.baseSubjectClass.code;

            if (!codePrefix.startsWith(curPrefix)) {
                setVersionError("Некорректный идентификатор версии СПГЗ. В объекте закупок выбран КПГЗ из другой ветки");
                return;
            }
        }
        await setSubjectDeclaration(subjDecl);
        setHasDeclarations(true);
    };

    return <Card title="Содержание закупки">
        <FieldGrid>
            <FieldInset>
                <Label preset="boldSmall">Для добавления спецификации в лот укажите конечный КПГЗ.</Label>
            </FieldInset>
            <Field title="КПГЗ" required>
                <BoxPicker value={subjectClass?.toString()} onPick={pickSubjectClass}/>
            </Field>
            <Field title="СПГЗ" hidden={!subjectClass} required>
                <BoxPicker value={subjectDeclaration?.toString()} onPick={pickSubjectDeclaration}/>
            </Field>
            <Field hidden={!subjectClass}
                   title={<Button color="green" title="выбрать спгз по идентификатору" onClick={selectById}/>}>
                <VGrid columns="150px 1fr">
                    <IntBox value={idValue} onChange={setIdValue}/>
                    <div style={{
                        display: "flex",
                        alignItems: "center",
                        color: "red",
                        fontSize: "15px",
                        maxHeight: "2em",
                        marginLeft: "10px"
                    }}>{idError}</div>
                </VGrid>
            </Field>
            <Field hidden={!subjectClass}
                   title={<Button color="green" title="выбрать спгз по идентификатору версии" onClick={selectVersionById}/>}>
                <VGrid columns="150px 1fr">
                    <IntBox value={versionId} onChange={setVersionId}/>
                    <div style={{
                        display: "flex",
                        alignItems: "center",
                        color: "red",
                        fontSize: "15px",
                        maxHeight: "2em",
                        marginLeft: "10px"
                    }}>{versionError}</div>
                </VGrid>
            </Field>
            <Field title="ОКПД-2" hidden={!subjectClass || !subjectDeclaration}>
                <Label>{subjectDeclaration?.okpd2?.toString()}</Label>
            </Field>
            {
                x.law === "F223" && <Field title="ОКВЭД-2" hidden={!subjectClass || !subjectDeclaration} required>
                    <BoxPicker value={actionClass?.toString()} onPick={pickActionClass}/>
                </Field>
            }
        </FieldGrid>
    </Card>;
};
