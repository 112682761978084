














import { valueSpanContext, FComponent } from "./FComponent";
import { Component, Prop, Vue, Inject, Mixins } from "vue-property-decorator";
import { FValueComponent } from './FValueComponent';

@Component
export default class FPick extends Mixins(FValueComponent) {
    @Prop()
    public search?: boolean;

    @Prop()
    public add?: boolean;

    private emitFormPick() {
        if (this.enabled) this.$emit("form-pick", this.value);
    }

    public get commonProps() {
        const { f } = this;

        return { class: [...this.classes] };
    }

    public get editableProps() {
        const { value, disabled } = this;

        return { ...this.commonProps, value, disabled };
    }

    private clearDocument() {}
}
