import React from "react";
import {Card} from "@/components/Card";
import {Table} from "@/components/table";
import {Label} from "@/components/primitive";

export const CustomersContracts: React.FC = x => {
    return <Card>
        <Table<{}> dataset={[{}]} columns={[
            Table.AutoColumn("Номер контракта", x =>
                <Label>
                </Label>),
            Table.AutoColumn("Дата контракта", x =>
                <Label>
                </Label>),
            Table.AutoColumn("Специализированная организация", x =>
                <Label>
                </Label>),
            Table.AutoColumn("Дата начала действия контракта", x =>
                <Label>
                </Label>),
            Table.AutoColumn("Дата окончания действия контракта", x =>
                <Label>
                </Label>)
        ]} />
    </Card>;
};