




















import { Component, Vue, Prop } from "vue-property-decorator";
import { IRowConfiguration } from '@/components/TableAbstractions';

@Component
export default class StageTable extends Vue {
    @Prop() public filterVisible!: boolean;
    @Prop({default: true}) public actionsVisible!: boolean;
    @Prop() public headers!: [];
    @Prop() public dataset!: [];
    @Prop({default: () => []}) public slots!: string[];
    @Prop() public tableHeight!: string;

    public config: IRowConfiguration<{}> = {
        useSlotForActionColumn: this.actionsVisible
    };
}
