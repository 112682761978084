import { Component, Prop, Vue, Watch } from "vue-property-decorator";
import Modal from "@/components/Modal.vue";
import EventBus from '@/EventBus';
import { TimePickerModalEventArgs, Time } from './TimePickerModalEventArgs';

@Component({ components: { Modal } })
export default class TimePickerModal extends Vue {
    @Prop() private prop!: TimePickerModalEventArgs;

    public get time() { return this.prop.time }

    public mounted() {
        this.prop.forceClose.then(() => this.$emit('close-modal'));
    }

    public setNowTime() {
        const now = new Date();
        this.time.hours = now.getHours();
        this.time.minutes = now.getMinutes();
    }

    public handleModalEvent($event: Event) {
        $event.stopPropagation();
        EventBus.$emit("close-popup");
    }
}
