import React, { useLayoutEffect, useState } from "react";
import styles from '../CreateSession.module.css';
import {
    ServicesStore
} from "../store";
import { firstMaybe } from "@/arrayUtils";
import _ from "lodash";
import moment from "moment";
import { VictoryChart, VictoryVoronoiContainer, VictoryAxis, VictoryLine, VictoryScatter } from "victory";
import {Section} from "@/components/eshop/Section/Section";
import {Card} from "@/components/eshop/Card/Card";

function useWindowSize() {
    const [size, setSize] = useState([0, 0]);
    useLayoutEffect(() => {
        function updateSize() {
            setSize([window.innerWidth, window.innerHeight]);
        }
        window.addEventListener('resize', updateSize);
        updateSize();
        return () => window.removeEventListener('resize', updateSize);
    }, []);
    return size;
}

interface ProgressCardProps {
    store: ServicesStore
}

export const ProgressCard = (props: ProgressCardProps) => {
    const [width, height] = useWindowSize();

    const store = props.store;

    const executionHistoryData = _.orderBy(
        store.executionHistory.map(e => {
            return { x: moment(e.timestamp).unix(), y: parseFloat(e.price) };
        }),
        execution => execution.x
    );

    const firstQuotationRate = firstMaybe(executionHistoryData) || { x: 0, y: 0 };
    const ultimateQuotationRate = executionHistoryData.length > 1 ? executionHistoryData[executionHistoryData.length - 1] : null;
    const penultimateQuotationRate = executionHistoryData.length > 2 ? executionHistoryData[executionHistoryData.length - 2] : null;

    return <Section required title='Ход котировочной сессии' hidden canBeHidden>
        <Card padded>
            {(ultimateQuotationRate || penultimateQuotationRate)
                ? <div className={styles.executionHistoryContainer}>
                    <div className={styles.chartDescriptionContainer}>
                        {ultimateQuotationRate && <>
                            <div className={styles.quotationRateDescription}>Последняя ставка: </div>
                            <div className={styles.quotationRate}>
                                {`${moment.unix(ultimateQuotationRate.x).format("DD.MM.YYYY HH:mm:ss")}: `}<b>{`${ultimateQuotationRate.y} руб`}</b>
                            </div>
                            <div className={styles.quotationRateDescription}>
                                Снижение: <span className={styles.quotationRate}>{firstQuotationRate.y - ultimateQuotationRate.y} руб ({(100 - (100 * ultimateQuotationRate.y / firstQuotationRate.y)).toFixed(2)}%)</span>
                            </div>
                        </>}
                        {penultimateQuotationRate && <>
                            <div className={styles.quotationRateDescription}>Предпоследняя ставка: </div>
                            <div className={styles.quotationRate}>
                                {`${moment.unix(penultimateQuotationRate.x).format("DD.MM.YYYY HH:mm:ss")}: `}<b>{`${penultimateQuotationRate.y} руб`}</b>
                            </div>
                            <div className={styles.quotationRateDescription}>
                                Снижение: <span className={styles.quotationRate}>{firstQuotationRate.y - penultimateQuotationRate.y} руб ({(100 - (100 * penultimateQuotationRate.y / firstQuotationRate.y)).toFixed(2)}%)</span>
                            </div>
                        </>}
                    </div>
                    <div className={styles.chartContainer}>
                        <VictoryChart
                            polar={false}
                            width={width - 500}
                            containerComponent={
                                <VictoryVoronoiContainer
                                    labels={({ datum }) => `${moment.unix(datum.x).format("DD.MM.YYYY HH:mm:ss")} ${datum.y} руб`}
                                    voronoiBlacklist={['scatter']}
                                    voronoiPadding={5} />
                            }>
                            <VictoryAxis tickFormat={(tick) => `${moment.unix(tick).format("DD.MM.YYYY")}`} />
                            <VictoryAxis dependentAxis label={'Руб'} />
                            <VictoryLine
                                interpolation='catmullRom'
                                data={executionHistoryData}
                                style={{ data: { stroke: "#8da1c4" } }} />
                            <VictoryScatter
                                name="scatter"
                                data={executionHistoryData}
                                size={5}
                                style={{ data: { fill: "#8da1c4" } }} />
                        </VictoryChart>
                    </div>
                </div>
                : executionHistoryData.length > 0
                    ? <div className={styles.quotationRate}>Начальная цена: {firstQuotationRate.y} руб. ({moment.unix(firstQuotationRate.x).format("DD.MM.YYYY HH:mm:ss")})</div>
                    : <div className={styles.quotationRateNoData}>Данные отсутствуют</div>
            }
        </Card>
    </Section>;
};
