import {ModalFC} from "@/EventBus";
import React, {useState} from "react";
import {Expandee, HStack, VStack} from "@/components/layouts";
import {Button, Label, TextBox} from "@/components/primitive";
import {CardModal} from "@/components/modals/CardModal";

export const AddContentModal: ModalFC<{title: string; content?: string},string> = x => {
    const [content, setContent] = useState<string | undefined>(x.content);
    const title = <HStack spacing={"10px"}>
        <Label preset={"headerLarge"}
               text={x.title}
        />
        <Expandee/>
        <Button color={"blue"}
                icon={"aSave"}
                onClick={() => x.done(content)}
        />
        <Button color={"red"}
                icon={"aClose"}
                onClick={() => x.done(undefined)}
        />
    </HStack>;

    return <CardModal title={title}
                      close={"disabled"}
                      width={"60%"}
    >
        <VStack spacing={"15px"}>
            <Label text={x.title}
                   preset={"boldSmall"}
            />
            <TextBox lines={5}
                     onChange={setContent}
                     value={content}
             />
        </VStack>
    </CardModal>;
};