













import { Component, Prop, Vue } from "vue-property-decorator";

@Component
export default class CheckBox extends Vue {
    @Prop() private value!: boolean;
    @Prop({ default: false }) private disabled!: boolean;

    private noop() {}
}
