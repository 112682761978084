import React, {CSSProperties, MouseEvent} from "react";
import * as styles from "./RoundedButton.module.css";
import {colors} from "./RoundedButton.colors";
import {j} from "@/reactUtils";

interface ButtonProps {
    onClick?: (e: MouseEvent) => void

    color?: keyof typeof colors
    title?: string
    disabled?: boolean
    loading?: boolean
    small?: boolean
    style?: CSSProperties
    className?: string
}

const createColorStyle = (colorName: keyof typeof colors) => {
    const color = colors[colorName];
    return {
        "--idleBg": color.idle.bg,
        "--idleBorder": color.idle.border,
        "--activeBg": color.active.bg,
        "--activeBorder": color.active.border,
        "--hoverBg": color.hover.bg,
        "--hoverBorder": color.hover.border,
        "color": color.text
    } as unknown as CSSProperties;
};

export const RoundedButton: React.FC<ButtonProps> = x => {
    const realColor: keyof typeof colors = x.loading ? "white" : x.color ?? "blue";

    return <div
        onClick={x.disabled ? undefined : x.onClick}
        style={{...createColorStyle(realColor), ...x.style}}
        className={j(styles.button, (x.disabled || x.loading) && styles.disabled, x.className, x.small && styles.small)}>
        {x.title}
    </div>;
};
