import {autoSV, createSVEvent, origEv} from "@/storeUtils";
import {createEvent, createStore, Store} from "effector";
import {LotForm} from "@/views/ComposedLot/shared/store/index";
import Decimal from 'decimal.js';

export interface LotAdditionalInfo {
    advantagesForUis?: Decimal
    advantagesForDisabledPeopleOrganizations?: Decimal
    foreignProductsForbidConditions?: string
    foreignProductsAmount?: Decimal
    options: string[]
}

export const AdditionalInfoEvents = {
    setAdvantagesForUis: createSVEvent<LotAdditionalInfo>()("advantagesForUis"),
    setAdvantagesForDisabledPeopleOrganizations: createSVEvent<LotAdditionalInfo>()("advantagesForDisabledPeopleOrganizations"),
    setForeignProductsForbidConditions: createSVEvent<LotAdditionalInfo>()("foreignProductsForbidConditions"),
    setForeignProductsAmount: createSVEvent<LotAdditionalInfo>()("foreignProductsAmount"),
    setOptions: createSVEvent<LotAdditionalInfo>()("options"),
};

const replace = createEvent<LotAdditionalInfo>("replace additional info");

export const AdditionalInfoStore = {
    createStore: (): Store<LotAdditionalInfo> => {
        return createStore<LotAdditionalInfo>({
            options: []
        })
            .on(replace, (_, l) => l)
            .on(AdditionalInfoEvents.setAdvantagesForUis[origEv], autoSV)
            .on(AdditionalInfoEvents.setAdvantagesForDisabledPeopleOrganizations[origEv], autoSV)
            .on(AdditionalInfoEvents.setForeignProductsForbidConditions[origEv], autoSV)
            .on(AdditionalInfoEvents.setForeignProductsAmount[origEv], autoSV)
            .on(AdditionalInfoEvents.setOptions[origEv], autoSV);
    },

    replace: replace,
    observeOnCombinedStore: (combinedStore: Store<LotForm>) => {}
};

export type AdditionalInfoOptions = { [key: string]: string };
export const AdditionalInfoOptions: AdditionalInfoOptions = {
    "ai-cb1": "Товары, происходящие из иностранного государства или группы иностранных государств, допускаемые на территорию Российской Федерации; преимущества предоставляются в соответствии с приказом Минфина РФ от 4 июня 2018 г. № 126н",
    "ai-cb2": "Применение запрета для товаров, входящих в Перечень сельскохозяйственной продукции, сырья и продовольствия, страной происхождения которых является Турецкая Республика и которые запрещены с 1 января 2016 г. к ввозу в Российскую Федерацию (утверждено постановлением Правительства Российской Федерации от 30 ноября 2015 г. № 1296)",
    "ai-cb4": "Применение запрета на допуск промышленных товаров, происходящих из иностранных государств (за исключением государств - членов Евразийского экономического союза), для целей осуществления закупок в соответствии с постановлением Правительства РФ от 30 апреля 2020 г. № 616",
    "ai-cb16": "Применение ограничений допуска отдельных видов промышленных товаров, происходящих из иностранных государств (за исключением государств - членов Евразийского экономического союза), для целей осуществления закупок в соответствии с постановлением Правительства РФ от 30 апреля 2020 г. № 617",
    "ai-cb5": "Применение запрета на допуск программ для электронных вычислительных машин и баз данных, реализуемых независимо от вида договора на материальном носителе и (или) в электронном виде по каналам связи, происходящих из иностранных государств, а также исключительных прав на такое программное обеспечение и прав использования такого программного обеспечения, в соответствии с положениями постановления Правительства Российской Федерации от 16 ноября 2015 г. № 1236",
    "ai-cb6": "Применение ограничений допуска отдельных видов пищевых продуктов, происходящих из иностранных государств в соответствии с постановлением Правительства Российской Федерации от 22 августа 2016 г. № 832",
    "ai-cb7": "Применение ограничений допуска отдельных видов медицинских изделий, происходящих из иностранных государств в соответствии с постановлением Правительства Российской Федерации от 05 февраля 2015 г. № 102",
    "ai-cb8": "Применение ограничений допуска отдельных видов лекарственных препаратов, включенных в Перечень жизненно необходимых и важнейших лекарственных препаратов и происходящих из иностранных государств в соответствии с постановлением Правительства Российской Федерации от 30 ноября 2015 г. № 1289",
    "ai-cb9": "Применение ограничений и условий допуска отдельных видов радиоэлектронной продукции, происходящих из иностранных государств, для целей осуществления закупок для обеспечения государственных и муниципальных нужд в соответствии с постановлением Правительства Российской Федерации от 10 июля 2019 г. N 878",
    "ai-cb10": "Предметом контракта является поставка товара, необходимого для нормального жизнеобеспечения в случаях, указанных в ч. 9 ст. 37 44-ФЗ",
    "ai-cb11": "Выполнение научно-исследовательских, опытно-конструкторских, технологических работ или оказание консультационных услуг",
    // "ai-cb12": "Возможность снижения цены контракта без изменения количества товара, объема работы или услуги, качества поставляемого товара, выполняемой работы, оказываемой услуги и иных условий контракта (при исполнении контракта) (44-ФЗ, ст. 95, п. 1, пп. 1, а)",
    // "ai-cb13": "Возможность изменения количества товара, объема работы или услуги в пределах 10% (при исполнении контракта) (44-ФЗ, ст. 95, п. 1, пп. 1, б)",
    // "ai-cb14": "Возможность изменения объема и (или) видов выполняемых работ по контракту на выполнение работ по строительству, реконструкции, капитальному ремонту, сносу объекта капитального строительства, проведению работ по сохранению объектов культурного наследия (44-ФЗ, ст. 95, ч.1, п.1, пп. в)",
    // "ai-cb15": "Возможность увеличения количества поставляемого товара при заключении контракта (44-ФЗ, ст. 34, п. 18)"
};

export const AdditionalInfoOptions2022: Partial<AdditionalInfoOptions> = Object.fromEntries(
    Object.entries(AdditionalInfoOptions)
        .filter(([id, _option]) => id < "ai-cb12")
);
