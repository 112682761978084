import React from "react";
import { Column, FilterColumn } from "@/components/table";
import { Monopoly, MonopolyStatus, MonopolyStatusStrings } from "@/models/Monopoly";
import { MonopolyFilters } from "@/views/Monopoly/list/store";
import { formatNumber } from "@/NumberFormatting";
import { formatDate } from "@/dateUtils";
import { UnderlinedVueLink } from "@/components/VueLink";

const getMonopolyStatusStrings = (type: string) => {
    const keys = Object.keys(MonopolyStatusStrings);
    switch(type) {
        case "in-progress":
            return keys.filter(k => k === MonopolyStatus.CREATING);
        case "accepted":
            return keys.filter(k => k === MonopolyStatus.ACCEPTED);
        case "processed":
            return keys.filter(k => k !== MonopolyStatus.ACCEPTED && k !== MonopolyStatus.CREATING);
        default:
            return keys;
    }
};

export const monopolyObjectsColumns = (type: string): Column<Monopoly>[] => {

    return [
        FilterColumn<Monopoly, MonopolyFilters>({
            header: "Реестровый номер объекта",
            filter: {
                type: "string",
                key: "regNumber"
            },
            cell: x => <UnderlinedVueLink href={`/plan-objects/monopoly-objects/${x.item.id}`}>{x.item.regNumber}</UnderlinedVueLink>,
        }),
        FilterColumn<Monopoly, MonopolyFilters>({
            header: "Предмет закупки",
            filter: {
                type: "string",
                key: "regNumber"
            },
            cell: x => <>{x.item.subjectPurchase}</>,
        }),
        FilterColumn<Monopoly, MonopolyFilters>({
            header: "Идентификатор контракта",
            filter: {
                type: "string",
                key: "regNumber"
            },
            cell: x => <>{x.item.requestId}</>,
        }),
        FilterColumn<Monopoly, MonopolyFilters>({
            header: "Поставщик",
            filter: {
                type: "string",
                key: "regNumber"
            },
            cell: x => <>{x.item.supplier.shortName}</>,
        }),
        FilterColumn<Monopoly, MonopolyFilters>({
            header: "Адрес поставки",
            filter: {
                type: "string",
                key: "regNumber"
            },
            cell: x => <>{x.item.customer.address}</>,
        }),
        FilterColumn<Monopoly, MonopolyFilters>({
            header: "Дата получения",
            filter: {
                type: "string",
                key: "regNumber"
            },
            cell: x => <>{formatDate(x.item.createdAt)}</>,
        }),
        FilterColumn<Monopoly, MonopolyFilters>({
            header: "Сумма, руб",
            filter: {
                type: "string",
                key: "regNumber"
            },
            cell: x => <>{formatNumber(x.item.total)}</>,
        }),
        FilterColumn<Monopoly, MonopolyFilters>({
            header: "Статус",
            filter: {
                type: "multiselect",
                options: getMonopolyStatusStrings(type).map(k => ({
                    key: k,
                    desc: MonopolyStatusStrings[k as MonopolyStatus]
                })),
                values: getMonopolyStatusStrings(type),
                key: "status",
            },
            options: {
                width: "1fr"
            },
            cell: x => <>{MonopolyStatusStrings[x.item.status]}</>,
        }),
    ];
};

export const conclusionRequestsColumns: Column<Monopoly>[] = [
    FilterColumn<Monopoly, MonopolyFilters>({
        header: "Реестровый номер объекта",
        filter: {
            type: "string",
            key: "regNumber"
        },
        cell: x => <>{x.item.regNumber}</>,
    }),
    FilterColumn<Monopoly, MonopolyFilters>({
        header: "Поставщик",
        filter: {
            type: "string",
            key: "regNumber"
        },
        cell: x => <>{x.item.supplier.shortName}</>,
    }),
    FilterColumn<Monopoly, MonopolyFilters>({
        header: "Адрес поставки",
        filter: {
            type: "string",
            key: "regNumber"
        },
        cell: x => <>{x.item.customer.address}</>,
    }),
    FilterColumn<Monopoly, MonopolyFilters>({
        header: "Дата получения",
        filter: {
            type: "string",
            key: "regNumber"
        },
        cell: x => <>{formatDate(x.item.createdAt)}</>,
    }),
    FilterColumn<Monopoly, MonopolyFilters>({
        header: "Дата получения",
        filter: {
            type: "string",
            key: "regNumber"
        },
        // TODO: Заменить на дату получения
        cell: x => <>{formatDate(x.item.createdAt)}</>,
    }),
    FilterColumn<Monopoly, MonopolyFilters>({
        header: "Сумма, руб",
        filter: {
            type: "string",
            key: "regNumber"
        },
        cell: x => <>{formatNumber(x.item.total)}</>,
    }),
    FilterColumn<Monopoly, MonopolyFilters>({
        header: "Статус",
        filter: {
            type: "multiselect",
            options: Object.keys(MonopolyStatusStrings).map(k => ({
                key: k,
                desc: MonopolyStatusStrings[k as MonopolyStatus]
            })),
            values: Object.keys(MonopolyStatusStrings),
            key: "status",
        },
        options: {
            width: "2fr"
        },
        cell: x => <>{MonopolyStatusStrings[x.item.status]}</>,
    }),
];
