import type { Monopoly, MonopolyDocument, MonopolyDocumentTemplate } from "@/models/Monopoly";
import { uuid } from "@/models/parsing";
import { createEffect, createEvent, createStore, Effect, Event, guard, merge, Store } from "effector";
import {
    acceptMonopolyObject,
    cancelDivergence,
    divergenceMonopolyObject,
    getHistory,
    getMonopolyObject,
    sendMonopolyObject
} from "@/api/Monopoly";
import { createStoredRemoteDataset, StoredRemoteDataset } from "@/components/table";
import { MonopolyHistoryItem } from "@/models/Monopoly/MonopolyHistory";
import { showModal } from "@/EventBus";
import { SelectLawModal } from "@/views/ComposedLot/shared/modals/SelectLawModal";
import { AcceptObjectModal } from "@/views/Monopoly/shared/modals/AcceptObjectModal";
import { LotPlannedPaymentTemplate } from "@/models/ComposedLots/LotPlannedPayment";
import { CommentaryRequestModal } from "@/views/Monopoly/shared/modals/CommentaryRequestModal";
import Decimal from "decimal.js";
import { MonopolySpecification } from "@/models/Monopoly/MonopolySpecification";
import { monopolyToTemplate } from "@/models/Monopoly/MonopolyTemplate";
import { Lot } from "@/models/ComposedLots";
import { getLotById } from "@/api/ComposedLots";
import { ErrorModal } from "@/views/Contracts/modals/ConfirmationModal";

export type MonopolyObjectState = {
    object?: Monopoly
    lot?: Lot
    history: StoredRemoteDataset<MonopolyHistoryItem>
};

export type MonopolyObjectStoreEvents = {
    setAdvancedPercent: Event<Decimal | undefined>
    setSpecifications: Event<MonopolySpecification[]>
    toggleMonthlyPayment: Event<void>
};

export interface MonopolyObjectStore {
    acceptFx: Effect<void, void |  Monopoly, Error>
    divergenceFx: Effect<{ documents: MonopolyDocument[]; commentary: string }, Monopoly>
    divergenceFullFx: Effect<void, Monopoly | undefined>
    cancelDivergenceFx: Effect<void, Monopoly>
    sendFx: Effect<{ object: Monopoly }, Monopoly>
    events: MonopolyObjectStoreEvents
    store: Store<MonopolyObjectState>
}

export const createMonopolyObjectStore = (objectId: uuid): MonopolyObjectStore => {
    const setObject = createEvent<Monopoly>();
    const setLot = createEvent<Lot | undefined>();
    const setAdvancedPercent = createEvent<Decimal | undefined>();
    const setSpecifications = createEvent<MonopolySpecification[]>();
    const toggleMonthlyPayment = createEvent<void>();

    const acceptFx = createEffect({
        handler: async () => {
            const law = await showModal(SelectLawModal);

            if (!law) {
                return;
            }

            const plannedPayments = await showModal(AcceptObjectModal, { law });

            if (!plannedPayments) {
                return;
            }

            const plannedPaymentsTemplates: LotPlannedPaymentTemplate[] = plannedPayments.map(
                ({ source, amount }) => ({
                    sourceId: source.id,
                    amount
                })
            );

            // TODO: REFACTOR
            return await acceptMonopolyObject(objectId, law, plannedPaymentsTemplates)
                .catch((response) => response.cause && showModal(ErrorModal, { text: response.cause.join("\n") }));
        }
    });

    const divergenceFullFx = createEffect({
        handler: async () => {
            const result = await showModal(CommentaryRequestModal);

            if (!result) {
                return;
            }

            const documents: MonopolyDocumentTemplate[] = result.files.map(file => ({
                file,
                comment: result.commentary
            }));

            return await divergenceMonopolyObject(objectId, documents, result.commentary);
        }
    });

    const divergenceFx = createEffect({
        handler: async ({ documents, commentary }: { documents: MonopolyDocument[]; commentary: string }) =>
            await divergenceMonopolyObject(objectId, documents, commentary)
    });

    const sendFx = createEffect({
        handler: async ({ object }: { object: Monopoly }) =>
            await sendMonopolyObject(
                object.id,
                monopolyToTemplate(object)
            )
    });

    const cancelDivergenceFx = createEffect({
        handler: async () => await cancelDivergence(objectId)
    });

    guard(
        merge([
            acceptFx.doneData,
            divergenceFullFx.doneData,
            divergenceFx.doneData,
            sendFx.doneData,
            cancelDivergenceFx.doneData
        ]),
        { filter: Boolean, target: setObject }
    );

    const store = createStore<MonopolyObjectState>({
        history: createStoredRemoteDataset<MonopolyHistoryItem>({
            async load(_filters, from, count) {
                return await getHistory(objectId, from, count);
            }
        }),
        object: undefined,
        lot: undefined
    })
        .on(setObject, (s, object) => ({ ...s, object }))
        .on(setLot, (s, lot) => ({ ...s, lot }))
        .on(setSpecifications, (s, specifications) => (s.object ? {
            ...s,
            object: {
                ...s.object,
                specifications
            }
        } : s))
        .on(toggleMonthlyPayment, s => (s.object ? {
            ...s,
            object: {
                ...s.object,
                monthlyPayment: !s.object?.monthlyPayment
            }
        } : s))
        .on(setAdvancedPercent, (s, advancedPercent) => (s.object ? {
            ...s,
            object: {
                ...s.object,
                advancedPercent: advancedPercent ?? new Decimal(0)
            }
        } : s));

    getMonopolyObject(objectId)
        .then(setObject)
        .then(({ lotId }) => lotId ? getLotById(lotId) : undefined)
        .then(setLot);


    const events = {
        setAdvancedPercent,
        setSpecifications,
        toggleMonthlyPayment
    };

    return {
        store,
        acceptFx,
        divergenceFx,
        divergenceFullFx,
        sendFx,
        cancelDivergenceFx,
        events
    };
};
