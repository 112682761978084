import { ModalFC } from "@/EventBus";
import { Expandee, HStack } from "@/components/layouts";
import { CardModal } from "@/components/modals/CardModal";
import { Button } from "@/components/primitive";
import React from "react";
import styles from "@/views/CreateSession/CreateSession.module.css";


export const ConfirmStagesDeletion: ModalFC<void, boolean> = x => {
    const bottom: React.ReactNode = <HStack spacing="10px">
        <Expandee />
        <Button color="green" title="Удалить" onClick={() => x.done(true)} />
        <Button color="red" title="Отменить" onClick={() => x.done(false)} />
    </HStack>;

    return <CardModal title={"Подтверждение"}
                close={() => x.done(false)}
                bottom={bottom}
                width="620px">
                <div style={{display: "flex"}}>
                    <div style={{fontSize: 16}}>
                        Вы действительно хотите удалить все графики для этой спецификации?
                    </div>
                </div>
            </CardModal>;
};