








import { Component, Prop, Vue } from "vue-property-decorator";

@Component
export default class TextCellEditor extends Vue {
    @Prop() public cellValue!: unknown;
    @Prop({ default: false }) public disabled!: boolean;
}
