





















import { Component, Prop, Vue } from "vue-property-decorator";
import { MutatingFieldBase } from "@/components/vue/form-table/FieldBase";
import { IRow, TextAlignment } from "./FormFields";
import { mixins } from "vue-class-component";

@Component
export default class TextField extends mixins(MutatingFieldBase) {
    @Prop({ default: TextAlignment.LEFT }) private alignment!: TextAlignment;
}
