import React from "react";
import {Card} from "@/components/Card";
import {Field, Fields} from "@/components/form/Field";
import {Label} from "@/components/primitive";

export const SuppliersContactInformation: React.FC = x => {
    return <Card>
        <Fields orientation="horizontal">
            <Field title="Место нахождения">
                <Label>	115304 г. Москва Кантемировская дом 3, корп.3, пом VIII, комн.2</Label>
            </Field>
            <Field title="Индекс">
                <Label>115304</Label>
            </Field>
            <Field title="Часовая зона">
                <Label>Московское время</Label>
            </Field>
            <Field title="Факс">
                <Label>

                </Label>
            </Field>
            <Field title="Адрес интернет сайта">
                <Label>

                </Label>
            </Field>
        </Fields>
    </Card>;
};