// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_GET_URL_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/getUrl.js");
var ___CSS_LOADER_URL_IMPORT_0___ = require("@/assets/icons/favorite-empty-icon.svg");
var ___CSS_LOADER_URL_IMPORT_1___ = require("@/assets/icons/favorite-fill-icon.svg");
exports = ___CSS_LOADER_API_IMPORT___(false);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
var ___CSS_LOADER_URL_REPLACEMENT_1___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_1___);
// Module
exports.push([module.id, "._2dirYlyWE9Bbrm7CCESxuX {\n    max-width: 30px;\n    min-width: 30px;\n    min-height: 30px;\n    max-height: 30px;\n    user-select: none;\n    transition-duration: 0.2s;\n    word-break: break-word;\n    font-weight: 300;\n}\n\n._2esn2Gx2esIjYAa-dy7U4R {\n    cursor: pointer;\n    background-image: url(" + ___CSS_LOADER_URL_REPLACEMENT_0___ + ");\n    background-size: 16px;\n    background-repeat: no-repeat;\n    background-position: center center;\n    min-width: 30px;\n    height: 30px;\n}\n\n._2ZVaoxgkeY-N8CR-EL3Yo4{\n    background-image: url(" + ___CSS_LOADER_URL_REPLACEMENT_1___ + ");\n}", ""]);
// Exports
exports.locals = {
	"favoriteButtonComponent": "_2dirYlyWE9Bbrm7CCESxuX",
	"favorite": "_2esn2Gx2esIjYAa-dy7U4R",
	"favoriteSelected": "_2ZVaoxgkeY-N8CR-EL3Yo4"
};
module.exports = exports;
