import {FormEvent} from "react";

export interface MediaItem {
    id: string
    originalMime: string
    originalName: string
    presentations: MediaPresentation[]
    uploadedAt: string // Instant
}

export interface MediaPresentation {
    id: string
    mime: string
    name: string
    status: string // "READY" | ???
    type: string // "ORIGINAL" | ???
    updatedAt: string // Instant
}

export function getMediaUrl(item?: MediaItem): string | undefined {
    return item?.presentations?.[0]?.id
        ? `/api/v2/media/presentations/${item.presentations[0].id}`
        : undefined;
}

export const MediaApi = {
    async uploadMedia(d: FormData): Promise<Record<string, { result: MediaItem }>> {
        const result = fetch("/api/v2/upload", {
            method: "POST",
            body: d
        }).then(r => r.json());
        return result;
    },
    uploadFileHandler: async (d: FormEvent<HTMLInputElement>): Promise<MediaItem> => {
        const f = (d.target as HTMLInputElement).files;
        if (!f || !f.length) throw new Error("No files selected");

        const formData = new FormData();

        for (let i = 0; i < f.length; i++) {
            formData.append("file", f[i]);
        }
        const fileName = f[0].name;
        const result = await MediaApi.uploadMedia(formData);

        return Object.values(result)[0]?.result;
    }
};



