




import { Component, Vue } from "vue-property-decorator";
import {ObligationsList} from "@/views/Obligations/list/ObligationsList";
import {createContractObligationStore} from "@/views/Obligations/shared";

@Component({ components: { komponent: ObligationsList as unknown as typeof Vue } })
export default class ObligationsVueStub extends Vue {
    public storeContainer = Object.freeze(createContractObligationStore());
}
