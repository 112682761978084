













import { TextAlignment } from '@/components/vue/form-table/FormFields';
import { Component, Prop, Vue } from "vue-property-decorator";

@Component
export default class Slider extends Vue {
    @Prop() public value!: string;
    @Prop({ default: "" }) public placeholder!: string;
    @Prop({ default: false }) public multiline!: boolean;
    @Prop({ default: false }) public disabled!: boolean;
    @Prop({ default: TextAlignment.LEFT }) public alignment!: TextAlignment;
    @Prop({ default: 0 }) public minValue!: number;
    @Prop({ default: 100 }) public maxValue!: number;
}
