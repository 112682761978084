import EventBus from "@/EventBus";
import Decimal from "decimal.js";
import { DtoApiError } from './ApiError';
import router from '@/router';
import Auth from './Auth';
import { deserializeError, throwError } from "@/api/errorHandling";

function spin<T>(promise: Promise<T>): Promise<T> {
    EventBus.discardError();
    EventBus.$emit("show-spinner", promise);

    return promise;
}

export const enum FilterValueType {
    NONE = "NONE",
    STRING = "STRING",
    DECIMAL = "DECIMAL",
    LONGINT = "LONGINT",
    BOOLEAN = "BOOLEAN",
    DATE = "DATE",
    GUID = "GUID",
    ANY = "ANY"
}

export const enum FilterConditionType {
    EQUAL = "EQUAL",
    NOT_EQUAL = "NOT_EQUAL",
    GREATER_THAN = "GREATER_THAN",
    LESS_THAN = "LESS_THAN",
    GREATER_THAN_OR_EQUAL = "GREATER_THAN_OR_EQUAL",
    LESS_THAN_OR_EQUAL = "LESS_THAN_OR_EQUAL",
    STARTS_WITH = "STARTS_WITH",
    CONTAINS = "CONTAINS"
}

// TODO: Convert to DU
export declare interface IFilterValue {
    type: FilterValueType
    conditionType: FilterConditionType
    string?: string | null
    guid?: string | null
    decimal?: Decimal | null
    longint?: number | null
    boolean?: boolean | null
    date?: Date | null
}

export declare interface IFilterObject {
    [filter: string]: IFilterValue[]
}

export declare interface IPagedRequest {
    from?: number
    count?: number
    sortBy?: string
    sortDescending?: boolean
    filters?: IFilterObject
}

function objectFromEntries(entries: [string, unknown][]): unknown {
    const o: { [key: string]: unknown } = {};

    for (const ent of entries) {
        o[ent[0]] = ent[1];
    }

    return o;
}

function escapeSlashed(s: string): string {
    return s.replace(
        /[\u007f-\uffff]/g,
        x =>
            "\\u" +
            x
                .charCodeAt(0)
                .toString(16)
                .padStart(4, "0"),
    );
}

export function prepareRequest(r: IPagedRequest) {
    const newr = {
        ...r,
        filters: r.filters
            ? objectFromEntries(
                Object.entries(r.filters)
                    .map(([n, vl]) => [
                        n,
                        vl.map(x => ({
                            ...x,
                            decimal: x.decimal ? x.decimal.toString() : x.decimal,
                            date: x.date ? x.date.toISOString() : x.date
                        })).filter(x => {
                            switch (x.type) {
                                case FilterValueType.ANY:
                                case FilterValueType.STRING:
                                    // handle type errors from FilteredSelectionTable
                                    if(Array.isArray(x.string)) return (x.string as unknown as string[]).every(s => s.trim() !== "");
                                    return x.string && x.string.trim() !== "";
                                case FilterValueType.BOOLEAN:
                                    return x.boolean !== undefined;
                                case FilterValueType.LONGINT:
                                    return x.longint !== undefined;
                                case FilterValueType.DECIMAL:
                                    return x.decimal !== undefined;
                                case FilterValueType.DATE:
                                    return x.date !== undefined;
                                case FilterValueType.GUID:
                                    return x.guid !== undefined;
                                default:
                                    return false;
                            }
                        }),
                    ] as unknown as [string, IFilterValue[]])
                    .filter(([, vl]: [string, IFilterValue[]]) => vl.length > 0),
            )
            : undefined,
    };

    return btoa(escapeSlashed(JSON.stringify(newr)));
}

async function handle(response: Response, route: string, errScope?: string) {
    const contentType = response.headers.get("Content-Type") || "?";

    if (response.status !== 200 && response.status !== 204) {
        if (response.status === 401) {
            Auth.reset();
            router.resetTo("/news");

            throwError({ cause: ["Unauthorized"], route: route, status: 401 }, errScope);
        } else if (contentType.startsWith("application/json")) {
            const obj = await response.json() as DtoApiError;
            const err = deserializeError(obj, route, response.status);

            throwError(err, errScope);
        } else {
            throwError({ cause: ["Unknown backend error"], route: route, status: response.status }, errScope);
        }
    } else if (response.status === 200) {
        if (contentType.startsWith("application/json")) {
            return await response.json();
        } else {
            const text = await response.text();

            if (text.length === 0)
                return null;

            throwError({ cause: ["Invalid content type: " + contentType], route: route, status: 200 }, errScope);
        }
    } else {
        return null;
    }
}

async function handleSafely(response: Response, route: string) {
    const contentType = response.headers.get("Content-Type") || "?";

    if (response.status !== 200 && response.status !== 204) {
        if (response.status === 401) {
            Auth.reset();
            router.resetTo("/news");
        } else if (contentType.startsWith("application/json")) {
            const obj = await response.json() as DtoApiError;
            const err = deserializeError(obj, route, response.status);

            return err;
        } else {
            const text = await response.text();

            if (text.length === 0)
                return null;

            return text;
        }
    } else if (response.status === 200) {
        if (contentType.startsWith("application/json")) {
            return await response.json();
        } else {
            const text = await response.text();

            if (text.length === 0)
                return null;

            return text;
        }
    } else {
        return null;
    }
}

function headers(token: string | null, contentType: string | null) {
    const object: { [key: string]: string } = {};

    if (token) object.Authorization = "Bearer " + token;
    if (contentType) object["Content-Type"] = contentType;

    const expPtId = Auth.currentUserInfo?.selectedParticipant?.id;
    if (expPtId) object["X-Expected-Participant-Id"] = expPtId.toString();

    return object;
}

async function ready() {
    await Auth.getUserInfoIfRequired();
}

export const baseUrl = "";
export const http = {
    get token() { return localStorage.getItem("sc44_access_token") },
    set token(v: string | null) {
        if (v)
            localStorage.setItem("sc44_access_token", v);
        else
            localStorage.removeItem("sc44_access_token");
    },
    get: (url: string, cache = false) =>
        spin(
            ready().then(() => fetch(`${baseUrl}${url}`, {
                cache: cache ? "force-cache" : "no-cache",
                headers: headers(http.token, null)
            }))
                .then(x => handle(x, url))),
    getBypassReady: (url: string, cache = false) =>
        spin(
            fetch(`${baseUrl}${url}`, {
                cache: cache ? "force-cache" : "no-cache",
                headers: headers(http.token, null)
            })
                .then(x => handle(x, url))),
    getReady: (url: string, request: IPagedRequest, cache = false) =>
        spin(
            fetch(`${baseUrl}${url}?request=${prepareRequest(request)}`, {
                cache: cache ? "force-cache" : "no-cache",
                headers: headers(http.token, null)
            })
                .then(x => handle(x, url))),
    getPaged: (url: string, request: IPagedRequest, cache = false) => {
        const base = new URL(`${window.location.origin}${baseUrl}${url}`);

        base.searchParams.append("request", prepareRequest(request));

        return spin(
            ready().then(() => fetch(base.toString(), {
                cache: cache ? "force-cache" : "no-cache",
                headers: headers(http.token, "application/json")
            }))
                .then(x => handle(x, url)));
    },
    getPagedWithFavorite: (url: string, request: IPagedRequest, favorite: boolean, cache = false) =>
        spin(
            ready().then(() => fetch(`${baseUrl}${url}?request=${prepareRequest(request)}${favorite ? '&isFavorite=true' : ''}`, {
                cache: cache ? "force-cache" : "no-cache",
                headers: headers(http.token, "application/json")
            }))
                .then(x => handle(x, url))),
    getPagedServicesWithFavorite: (url: string, request: IPagedRequest, favorite: boolean, cache = false) =>
        spin(
            ready().then(() => fetch(`${baseUrl}${url}?request=${prepareRequest(request)}${favorite ? '&isFavorite=true' : ''}&isServiceOrWork=true`, {
                cache: cache ? "force-cache" : "no-cache",
                headers: headers(http.token, "application/json")
            }))
                .then(x => handle(x, url))),
    getPagedWithLaw: (url: string, request: IPagedRequest, law: string, cache = false) =>
        spin(
            ready().then(() => fetch(`${baseUrl}${url}?request=${prepareRequest(request)}&law=${law}`, {
                cache: cache ? "force-cache" : "no-cache",
                headers: headers(http.token, "application/json")
            }))
                .then(x => handle(x, url))),
    getPagedWithYear: (url: string, year: number, request: IPagedRequest, cache = false) =>
        spin(
            ready().then(() => fetch(`${baseUrl}${url}?year=${year}&request=${prepareRequest(request)}`, {
                cache: cache ? "force-cache" : "no-cache",
                headers: headers(http.token, "application/json")
            }))
                .then(x => handle(x, url))),
    delete: (url: string, errScope?: string) =>
        spin(
            ready().then(() => fetch(`${baseUrl}${url}`, {
                method: "DELETE",
                headers: headers(http.token, null)
            }))
                .then(x => handle(x, url, errScope))),
    post: (url: string, obj: unknown, errScope?: string) =>
        spin(
            ready().then(() => fetch(`${baseUrl}${url}`, {
                method: "POST",
                body: JSON.stringify(obj),
                headers: headers(http.token, "application/json")
            }))
                .then(x => handle(x, url, errScope))),
    patch: (url: string, obj?: unknown, errScope?: string) =>
        spin(
            ready().then(() => fetch(`${baseUrl}${url}`, {
                method: "PATCH",
                body: JSON.stringify(obj),
                headers: headers(http.token, "application/json")
            }))
                .then(x => handle(x, url, errScope))),
    postBypassReady: (url: string, obj: unknown, errScope?: string) =>
        spin(
            fetch(`${baseUrl}${url}`, {
                method: "POST",
                body: JSON.stringify(obj),
                headers: headers(http.token, "application/json")
            }))
            .then(x => handle(x, url, errScope)),
    put: (url: string, obj: unknown, errScope?: string) =>
        spin(
            ready().then(() => fetch(`${baseUrl}${url}`, {
                method: "PUT",
                body: JSON.stringify(obj),
                headers: headers(http.token, "application/json")
            }))
                .then(x => handle(x, url, errScope))),
};

export const http2 = {
    get token() { return localStorage.getItem("sc44_access_token") },
    set token(v: string | null) {
        if (v)
            localStorage.setItem("sc44_access_token", v);
        else
            localStorage.removeItem("sc44_access_token");
    },
    get: (url: string, cache = false) =>
        spin(
            ready().then(() => fetch(`${baseUrl}${url}`, {
                cache: cache ? "force-cache" : "no-cache",
                headers: headers(http.token, null)
            }))
                .then(x => handleSafely(x, url))),
    getPaged: (url: string, request: IPagedRequest, cache = false) =>
        spin(
            ready().then(() => fetch(`${baseUrl}${url}?request=${prepareRequest(request)}`, {
                cache: cache ? "force-cache" : "no-cache",
                headers: headers(http.token, "application/json")
            }))
                .then(x => handleSafely(x, url))),
    getPagedWithFavorite: (url: string, request: IPagedRequest, favorite: boolean, cache = false) =>
        spin(
            ready().then(() => fetch(`${baseUrl}${url}?request=${prepareRequest(request)}${favorite ? '&isFavorite=true' : ''}`, {
                cache: cache ? "force-cache" : "no-cache",
                headers: headers(http.token, "application/json")
            }))
                .then(x => handleSafely(x, url))),
    getPagedServicesWithFavorite: (url: string, request: IPagedRequest, favorite: boolean, cache = false) =>
        spin(
            ready().then(() => fetch(`${baseUrl}${url}?request=${prepareRequest(request)}${favorite ? '&isFavorite=true' : ''}&isServiceOrWork=true`, {
                cache: cache ? "force-cache" : "no-cache",
                headers: headers(http.token, "application/json")
            }))
                .then(x => handleSafely(x, url))),
    getPagedWithLaw: (url: string, request: IPagedRequest, law: string, cache = false) =>
        spin(
            ready().then(() => fetch(`${baseUrl}${url}?request=${prepareRequest(request)}&law=${law}`, {
                cache: cache ? "force-cache" : "no-cache",
                headers: headers(http.token, "application/json")
            }))
                .then(x => handleSafely(x, url))),
    delete: (url: string) =>
        spin(
            ready().then(() => fetch(`${baseUrl}${url}`, {
                method: "DELETE",
                headers: headers(http.token, null)
            }))
                .then(x => handleSafely(x, url))),
    post: (url: string, obj: unknown) =>
        spin(
            ready().then(() => fetch(`${baseUrl}${url}`, {
                method: "POST",
                body: JSON.stringify(obj),
                headers: headers(http.token, "application/json")
            }))
                .then(x => handleSafely(x, url))),
    patch: (url: string, obj?: unknown) =>
        spin(
            ready().then(() => fetch(`${baseUrl}${url}`, {
                method: "PATCH",
                body: JSON.stringify(obj),
                headers: headers(http.token, "application/json")
            }))
                .then(x => handleSafely(x, url))),
    postBypassReady: (url: string, obj: unknown) =>
        spin(
            fetch(`${baseUrl}${url}`, {
                method: "POST",
                body: JSON.stringify(obj),
                headers: headers(http.token, "application/json")
            }))
            .then(x => handleSafely(x, url)),
    put: (url: string, obj: unknown) =>
        spin(
            ready().then(() => fetch(`${baseUrl}${url}`, {
                method: "PUT",
                body: JSON.stringify(obj),
                headers: headers(http.token, "application/json")
            }))
                .then(x => handleSafely(x, url)))
};
