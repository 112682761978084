import React, { useEffect, useRef, useState } from "react";
import styles from "./SubjectDeclarationModal.module.css";
import { SubjectClass, SubjectDeclaration } from "@/models";
import { LawType } from "@/models/enums";
import { CardModal } from "@/components/modals/CardModal";
import { ModalFC } from "@/EventBus";
import { Expandee, HStack, VStack } from "@/components/layouts";
import { Button, IntBox, TextBox } from "@/components/primitive";
import { Field, Fields } from "@/components/form/Field";
import SubjectDeclarations from "@/api/SubjectDeclarations";
import { clsx } from "@/functionUtils";

interface SubjectDeclarationModalProps {
    subjectClass: SubjectClass | null
    selectedSubjectDeclaration: SubjectDeclaration | null
    law: LawType
}

interface SubjectDeclarationItemProps {
    title: string
    active: boolean
    onClick: () => void
}

const SubjectDeclarationItem = ({ title, active, onClick }: SubjectDeclarationItemProps) => {
    return <div onClick={onClick} className={clsx(styles.item, active && styles.active)}>
        {title}
    </div>;
};

export const SubjectDeclarationModal: ModalFC<SubjectDeclarationModalProps, SubjectDeclaration> = (props) => {
    const [selectedSubjectDeclaration, setSubjectDeclaration] = useState<SubjectDeclaration | null>(null);
    const [subjectDeclarations, setSubjectDeclarations] = useState<SubjectDeclaration[]>([]);

    const [subjectDeclarationId, setSubjectDeclarationId] = useState<number | undefined>();
    const [searchName, setSearchName] = useState<string | undefined>();

    const initialSubjectDeclarations = useRef<SubjectDeclaration[]>([]);

    useEffect(() => {
        if (!props.subjectClass?.id) {
            return;
        }

        SubjectDeclarations.getForClassBySubjectClass(props.subjectClass.id)
            .then(subjectDeclarations => initialSubjectDeclarations.current = subjectDeclarations)
            .then(setSubjectDeclarations);
    }, []);

    const reset = () => {
        setSubjectDeclaration(null);
        setSearchName(undefined);
        setSubjectDeclarations(initialSubjectDeclarations.current);
    };

    const filter = () => {
        const filteredSubjectDeclarations = subjectDeclarations
            .filter(subjectDeclaration => subjectDeclarationId ? subjectDeclaration.id === subjectDeclarationId : true)
            .filter(subjectDeclaration => searchName
                ? subjectDeclaration.subjectClass.description
                    .toLocaleLowerCase()
                    .includes(searchName.trim().toLowerCase())
                : true);

        setSubjectDeclarations(filteredSubjectDeclarations);
    };

    const bottom = (
        <HStack innerStyle={{ padding: "10px 0" }} spacing="10px">
            <Expandee/>
            {selectedSubjectDeclaration &&
              <Button color="blue"
                      title="Выбрать"
                      onClick={() => props.done(selectedSubjectDeclaration)} />}
            <Button color="white"
                    title="Отмена"
                    onClick={() => props.done(undefined)} />
        </HStack>
    );

    return <CardModal title="1Выбор СПГЗ"
                      close={() => props.done(undefined)}
                      width="40%"
                      bottom={bottom}>
        <VStack spacing="20px">
            <VStack spacing="10px" outerStyle={{ padding: "20px", backgroundColor: "#f7f8fa" }}>
                <Fields orientation="vertical">
                    <HStack spacing="20px">
                        <div>
                            <Field title="Идентификатор СПГЗ:">
                                <IntBox value={subjectDeclarationId}
                                        onChange={setSubjectDeclarationId}
                                        placeholder="Введите ID"/>
                            </Field>
                        </div>
                        <div style={{ width: "100%" }}>
                            <Field title="Наименование:">
                                <TextBox value={searchName}
                                         onChange={setSearchName}/>
                            </Field>
                        </div>
                    </HStack>
                </Fields>
                <HStack spacing="10px">
                    <Expandee />
                    <Button color="white"
                            title="Отмена"
                            onClick={reset}/>
                    <Button color="blue"
                            title="Применить"
                            onClick={filter}/>
                </HStack>
            </VStack>
            <VStack outerClassName={styles.list} innerClassName={styles.listInner}>
                {subjectDeclarations.map(subjectDeclaration =>
                    <SubjectDeclarationItem key={subjectDeclaration.id}
                                            active={selectedSubjectDeclaration?.id === subjectDeclaration.id}
                                            title={subjectDeclaration.subject}
                                            onClick={() => setSubjectDeclaration(subjectDeclaration)} />)}
            </VStack>
        </VStack>
    </CardModal>;
};
