import React from "react";
import { $contracts, $filters, $products, addProductToContract, changeFavorites, changePageSize, loadProductsPage, searchProducts } from "./store";
import { Pager } from "@/components/eshop";
import { ProductsFilters } from "./ProductsFilters";
import { ProductsTable } from "./ProductsTable";
import { useStore } from "effector-react";
import { useEffect } from "react";
import { Header } from "./Header";
import styles from "./Products.module.css";
import { ContractCreate } from "./ContractCreate";
import { SubjectClassFilterStore } from "@/components/eshop/SubjectClassFilterStore";

interface ProductsProps {
    favorites: boolean
    storeContainer: { subjectClassSelector: SubjectClassFilterStore }
}

export const Products: React.FC<ProductsProps> = x => {
    const store = useStore($products);
    const filterStore = useStore($filters);
    const contractStore = useStore($contracts);
    const showModal = !!contractStore.products.length;
    const closeModal = () => addProductToContract(null);
    useEffect(() => {
        changeFavorites(!!x.favorites);
        loadProductsPage({ page: 0 });
        return;
    }, [x.favorites]);

    return <>
        <div className={styles.container}>
            <ContractCreate show={showModal} closeModal={closeModal} />
            <Header store={store} filterStore={filterStore} />
            <div className={styles.tableAndFilters}>
                <div className={styles.tableAndPager}>
                    <ProductsTable store={store} />
                    <div className={styles.pager}>
                        <Pager
                            page={store.page}
                            goTo={searchProducts}
                            pageSize={filterStore.pageSize}
                            changePageSize={v => changePageSize({ pageSize: v })} />
                    </div>
                </div>
                {!store.twoColumnMode && <ProductsFilters subjectClassSelector={x.storeContainer.subjectClassSelector} filterStore={filterStore} />}
            </div>
        </div>
    </>;
};
