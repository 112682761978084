import { convertPaged, IPagedItems } from "./Pagination";
import { http, IFilterObject } from "./http";
import { Contract } from "@/models/Contracts/Contract";
import { LawType } from '@/models/enums';
import { ContractFinancialSource } from '@/models/Contracts';
import { IDtoContractFinancialSource } from '@/models/json/Contracts';

export default class {
    public static async getFromContract(contractId: string): Promise<ContractFinancialSource[]> {
        return (await http.get(`/api/contractFinancialSources/fromContract/${contractId}`, undefined) as IDtoContractFinancialSource[])
            .map(ContractFinancialSource.fromJson);
    }

    public static async create(contractId: string, src: ContractFinancialSource): Promise<ContractFinancialSource> {
        return ContractFinancialSource.fromJson(await http.post(`/api/contractFinancialSources/createFor/${contractId}`, src.toJson()));
    }

    public static async update(obj: ContractFinancialSource): Promise<ContractFinancialSource> {
        return ContractFinancialSource.fromJson(await http.put(`/api/contractFinancialSources/${obj.id}`, obj.toJson()));
    }

    public static async delete(obj: ContractFinancialSource): Promise<void> {
        await http.delete(`/api/contractFinancialSources/${obj.id}`);
    }
}
