




import {Component, Vue} from "vue-property-decorator";
import {SubjectDeclarations} from "@/views/ReferenceInfo/SubjectDeclarations/list/SubjectDeclarations";
import {createSubjectDeclarationsStore} from "@/views/ReferenceInfo/SubjectDeclarations/list/store";

@Component({ components: { komponent: SubjectDeclarations as unknown as typeof Vue } })
export default class SubjectDeclarationVueStub extends Vue {
    public storeContainer = Object.freeze({ store: createSubjectDeclarationsStore() });
}
