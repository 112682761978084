import { EnumStrings } from "@/models/enums";

export const enum ContractPriceEditReason {
    NONE= "NONE",
    F44S28= "F44S28",
    F44S29= "F44S29",
    F44S14= "F44S14",
    PARTICIPANT_AGREEMENT= "PARTICIPANT_AGREEMENT",
    F44C2S2= "F44C2S2",
    F44C2S10= "F44C2S10",
}

export const ContractPriceEditReasonStrings: EnumStrings = {
    [ContractPriceEditReason.NONE]: "Требуется обеспечение исполнения контракта",
    [ContractPriceEditReason.F44S28]: "На основании ст. 28 44-ФЗ",
    [ContractPriceEditReason.F44S29]: "На основании ст. 29 44-ФЗ",
    [ContractPriceEditReason.F44S14]: "На основании ч. 4 ст. 14 44-ФЗ, приказа Минфина России № 126н",
    [ContractPriceEditReason.PARTICIPANT_AGREEMENT]: "Уменьшение цены контракта по согласованию с участником закупки",
    [ContractPriceEditReason.F44C2S2]: "На основании п. 2 ч. 2 ст. 51 44-ФЗ (увеличение количества поставляемого товара)",
    [ContractPriceEditReason.F44C2S10]: "На основании п. 2 ч. 10 ст. 31 44-ФЗ (снижение цены контракта для закупок лекарственных препаратов, включенных в перечень ЖНВЛП)",
};