import React, {CSSProperties, useEffect, useState} from "react";
import {Expandee, HStack} from "@/components/layouts";
import {NewsArticleLabel} from "@/api/News";
import {ArticleLabel} from "@/views/Login/news/widgets/ArticleLabel";
import styles from "./SearchBar.module.css";

const searchIcon = <svg width="27" height="28" viewBox="0 0 27 28" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path fillRule="evenodd" clipRule="evenodd" d="M12.9663 0.000244141C20.1158 0.000244141 25.9312 5.8157 25.9312 12.9652C25.9312 16.3383 24.6367 19.4147 22.5184 21.7236L26.6866 25.8831C27.0767 26.2732 27.0781 26.9043 26.688 27.2944C26.4936 27.4914 26.2366 27.5886 25.981 27.5886C25.7267 27.5886 25.4711 27.4914 25.2754 27.2971L21.0569 23.0903C18.8377 24.8675 16.024 25.9315 12.9663 25.9315C5.81678 25.9315 0 20.1147 0 12.9652C0 5.8157 5.81678 0.000244141 12.9663 0.000244141ZM12.9663 1.99731C6.91783 1.99731 1.99706 6.91674 1.99706 12.9652C1.99706 19.0136 6.91783 23.9344 12.9663 23.9344C19.0134 23.9344 23.9342 19.0136 23.9342 12.9652C23.9342 6.91674 19.0134 1.99731 12.9663 1.99731Z" fill="#848484"/>
</svg>;
const clearIcon = <svg width="13" height="13" viewBox="0 0 13 13" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g clipPath="url(#clip0_1832_15225)">
        <path d="M7.39805 6.46972L11.8531 2.01472C11.9532 1.89782 12.0055 1.74744 11.9995 1.59364C11.9936 1.43983 11.9298 1.29394 11.821 1.1851C11.7122 1.07627 11.5663 1.01251 11.4125 1.00657C11.2587 1.00063 11.1083 1.05294 10.9914 1.15306L6.53639 5.60806L2.08139 1.14695C1.96448 1.04683 1.8141 0.994515 1.6603 1.00046C1.5065 1.0064 1.3606 1.07015 1.25177 1.17899C1.14293 1.28783 1.07917 1.43372 1.07323 1.58752C1.06729 1.74133 1.11961 1.89171 1.21972 2.00861L5.67472 6.46972L1.21361 10.9247C1.14964 10.9795 1.09768 11.0469 1.061 11.1227C1.02432 11.1986 1.00371 11.2811 1.00046 11.3653C0.997205 11.4495 1.01139 11.5334 1.04211 11.6118C1.07283 11.6902 1.11943 11.7615 1.17899 11.821C1.23855 11.8806 1.30977 11.9272 1.38819 11.9579C1.46661 11.9886 1.55054 12.0028 1.6347 11.9995C1.71886 11.9963 1.80144 11.9757 1.87726 11.939C1.95308 11.9023 2.02049 11.8504 2.07528 11.7864L6.53639 7.33139L10.9914 11.7864C11.1083 11.8865 11.2587 11.9388 11.4125 11.9329C11.5663 11.9269 11.7122 11.8632 11.821 11.7543C11.9298 11.6455 11.9936 11.4996 11.9995 11.3458C12.0055 11.192 11.9532 11.0416 11.8531 10.9247L7.39805 6.46972Z" fill="#848484"/>
    </g>
    <defs>
        <clipPath id="clip0_1832_15225">
            <rect width="13" height="13" fill="white"/>
        </clipPath>
    </defs>
</svg>;

const inputStyle: CSSProperties = {
    border: "none",
    fontSize: "18px",
    fontWeight: 400,
};

export function SearchBar(x: { query?: string; label?: NewsArticleLabel; onChange: (v: string) => void; onLabelRemove: () => void }) {
    const [active, setActive] = useState(false);

    const [query, setQuery] = useState<string>(() => x.query ?? "");

    useEffect(() => {
        if (x.query || x.label) {
            setActive(true);
            if (x.query) setQuery(x.query);
        }
    }, [x.query, x.label]);

    const handleClear = () => {
        if (x.query || x.label) x.onLabelRemove();
        setQuery("");
        setActive(false);
    };

    const handleEnterPress = () => {
        x.onChange(query);
    };
     return <div className={styles.searchbar}>
         <div style={{ display: "flex", alignItems: "center", gap: "15px", width: "100%" }}>
             <div className={styles.svgWrapper}>
                 { searchIcon }
             </div>
             { x.label
                 ? <ArticleLabel item={x.label} />
                 : <input style={inputStyle}
                          className={styles.input}
                          value={query}
                          placeholder="Поиск по разделу"
                          onChange={e => setQuery(e.target.value)}
                          onKeyDown={e => e.key === "Enter" && handleEnterPress()} /> }
         </div>
             <div style={{ cursor: "pointer" }} onClick={handleClear}>{ clearIcon }</div>
        </div>;
}
