import { asDate, AutoDto, AutoModel, MDMap, uuid } from "@/models/parsing";
import Decimal from "decimal.js";
import { Participant } from "@/models";
import { DtoMonopolyDocument, MonopolyDocument } from "@/models/Monopoly/Document";
import { IDtoParticipant } from "@/models/json";
import { DtoMonopolySpecification, MonopolySpecification } from "@/models/Monopoly/MonopolySpecification";

export const enum MonopolyStatus {
    CREATING = "CREATING",
    AGREEMENT = "AGREEMENT",
    ACCEPTED = "ACCEPTED",
    DENIED = "DENIED",
    DIVERGENCE = "DIVERGENCE",
    LOT_PUBLISHED = "LOT_PUBLISHED",
    CONTRACT_CONCLUDED = "CONTRACT_CONCLUDED"
}

export const MonopolyStatusStrings: Record<keyof typeof MonopolyStatus, string> = {
    [MonopolyStatus.CREATING]: "Обработка",
    [MonopolyStatus.ACCEPTED]: "Принят",
    [MonopolyStatus.DIVERGENCE]: "Протокол разногласий",
    [MonopolyStatus.AGREEMENT]: "Согласование",
    [MonopolyStatus.DENIED]: "Откат от заключения",
    [MonopolyStatus.LOT_PUBLISHED]: "Лот опубликован",
    [MonopolyStatus.CONTRACT_CONCLUDED]: "Контракт заключен"
};

interface MonopolyMD {
    id: uuid
    lotId: uuid
    regNumber: number
    status: MonopolyStatus
    customer: MDMap<Participant, IDtoParticipant>
    supplier: MDMap<Participant, IDtoParticipant>
    total: Decimal
    advancedPercent: Decimal
    monthlyPayment: boolean
    specifications: MDMap<MonopolySpecification, DtoMonopolySpecification>[]
    documents: MDMap<MonopolyDocument, DtoMonopolyDocument>[]
    comment?: string
    requestId: number
    subjectPurchase: string
    createdAt: Date
    publishedAt: Date | null
    finishedAt: Date | null
}

export type Monopoly = AutoModel<MonopolyMD>;
export type DtoMonopoly = AutoDto<MonopolyMD>;

export const Monopoly = {
    fromDto(dtoMonopoly: DtoMonopoly): Monopoly {
        return {
            ...dtoMonopoly,
            customer: Participant.fromJson(dtoMonopoly.customer),
            supplier: Participant.fromJson(dtoMonopoly.supplier),
            total: new Decimal(dtoMonopoly.total),
            advancedPercent: new Decimal(dtoMonopoly.advancedPercent),
            specifications: dtoMonopoly.specifications.map(MonopolySpecification.fromDto),
            documents: dtoMonopoly.documents.map(MonopolyDocument.fromDto),
            comment: dtoMonopoly?.comment ?? "",
            createdAt: asDate(dtoMonopoly.createdAt),
            finishedAt: dtoMonopoly.finishedAt ? asDate(dtoMonopoly.finishedAt) : undefined,
            publishedAt: dtoMonopoly.publishedAt ? asDate(dtoMonopoly.publishedAt) : undefined,

            // mock
            monthlyPayment: false
        };
    },
    toDto(monopoly: Monopoly): DtoMonopoly {
        return {
            ...monopoly,
            customer: monopoly.customer.toJson(),
            supplier: monopoly.supplier.toJson(),
            total: monopoly.total.toString(),
            advancedPercent: monopoly.advancedPercent.toString(),
            specifications: monopoly.specifications.map(MonopolySpecification.toDto),
            documents: monopoly.documents.map(MonopolyDocument.toDto),
            createdAt: monopoly.createdAt.toISOString(),
            finishedAt: monopoly.finishedAt ? monopoly.finishedAt.toISOString() : null,
            publishedAt: monopoly.publishedAt ? monopoly.publishedAt.toISOString() : null,

            // mock
            monthlyPayment: undefined as unknown as boolean
        };
    }
};
