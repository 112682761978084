import React from "react";
import {Card} from "@/components/Card";
import {Field, Fields} from "@/components/form/Field";
import {CheckBox, Label} from "@/components/primitive";
import {Table} from "@/components/table";
import {CustomerMD} from "@/models/Customer";
import {ParticipantMD} from "@/models";

export const CustomersLegalRequisites: React.FC<{ info: ParticipantMD }> = x => {
    type Template = { [key: string]: string };
    const temp: Template[]= [{ template: "Торговая площадка ГКУ \"Дирекция по обеспечению деятельности ГУ здравоохранения города Москвы\"" }];

    return <Card>
        <Fields orientation="horizontal">
            <Field title="Тип учреждения">
                <Label>
                    {x.info.fullName}
                </Label>
            </Field>
            <Field title="Код по СПЗ">
                <Label>
                    {x.info.spzCode}
                </Label>
            </Field>
            <Field title="Код ИКО">
                <Label>
                    {x.info.regNumber}
                </Label>
            </Field>
            <Field title="Код ИКУ">
                <Label>

                </Label>
            </Field>
            <Field title="УНК бюджетополучателя">
                <Label>
                    
                </Label>
            </Field>
            <Field title="ГРБС">
                <Label>
                    
                </Label>
            </Field>
            <Field title="Код ГРБС">
                <Label>
                    
                </Label>
            </Field>
            <Field title="Управляющий орган в КС">
                <Label>
                    {x.info.parentOrganization}
                </Label>
            </Field>
            <Field title="ФИО контрактного управляющего / руководителя контрактной службы">
                <Label>
                    
                </Label>
            </Field>
            <Field title="Телефон контрактного управляющего / руководителя контрактной службы">
                <Label>
                    
                </Label>
            </Field>
            <Field title="E-MAIL контрактного управляющего / руководителя контрактной службы">
                <Label>
                    
                </Label>
            </Field>
            <Field title="Торговые площадки">
                <Table<Template> dataset={temp} columns={[
                    Table.AutoColumn("Торговые площадки", c =>
                        <Label>
                            {c.item.template}
                        </Label>
                    )
                ]} />
            </Field>
            <Field title="Заказчик по 44-ФЗ">
                <CheckBox value={false} disabled />
            </Field>
            <Field title="Заказчик по 223-ФЗ">
                <CheckBox value={false} disabled />
            </Field>
            <Field title="Необходимость проведения мониторинга соответствия планов закупки (ПП РФ №1169)">
                <CheckBox value={false} disabled />
            </Field>
            <Field title="Необходимость проведения оценки проектов плана закупок (ПП РФ №1169)">
                <CheckBox value={false} disabled />
            </Field>
            <Field title="Закупка на собственные средства">
                <CheckBox value={false} disabled />
            </Field>
            <Field title="Учреждение культуры, спорта или образования">
                <CheckBox value={false} disabled />
            </Field>
            <Field title="Региональный заказчик">
                <CheckBox value={false} disabled />
            </Field>
            <Field title="Муниципальное учреждение">
                <CheckBox value={false} disabled />
            </Field>
            <Field title="Департамент городского имущества является собственником имущества организации (согласованию подлежат крупные индивидуальные и условно-определенные сделки)">
                <CheckBox value={false} disabled />
            </Field>
            <Field title="ФИО руководителя">
                <Label>

                </Label>
            </Field>
            <Field title="Должность руководителя">
                <Label>

                </Label>
            </Field>
        </Fields>
    </Card>;
};