import { createStore, Store } from "effector";
import { ContractObligation } from "@/models/Contracts/ContractObligation";
import { createStoredRemoteDataset, StoredRemoteDataset } from "@/components/table";
import { uuid } from "@/models/parsing";
import Decimal from "decimal.js";
import { getContractObligations } from "@/api/ContractObligations";
import { FilterConditionType, FilterValueType } from "@/api/http";

export type ContractObligationFilters = {
    customerId: uuid
    contractStageId: uuid
    regNumber: string
    registeredAt: Date
    type: string
    totalPrice: Decimal
    contractNumber: string
};

type ContractObligationState = {
    contractObligationsSrd: StoredRemoteDataset<ContractObligation, ContractObligationFilters>
};

export type ContractObligationStore = {
    store: Store<ContractObligationState>
};

export const createContractObligationStore = (contractNumber?: string): ContractObligationStore => {
    return {
        store: createStore<ContractObligationState>({
            contractObligationsSrd: createStoredRemoteDataset<ContractObligation, ContractObligationFilters>({
                async load(filters, from, count) {
                    return await getContractObligations(from, count, {
                        customerId: [{
                            type: FilterValueType.STRING,
                            conditionType: FilterConditionType.EQUAL,
                            string: filters.customerId
                        }],
                        contractStageId: [{
                            type: FilterValueType.STRING,
                            conditionType: FilterConditionType.EQUAL,
                            string: filters.contractStageId
                        }],
                        regNumber: [{
                            type: FilterValueType.STRING,
                            conditionType: FilterConditionType.CONTAINS,
                            string: filters.regNumber
                        }],
                        registeredAt: [{
                            type: FilterValueType.DATE,
                            conditionType: FilterConditionType.EQUAL,
                            date: filters.registeredAt
                        }],
                        totalPrice: [{
                            type: FilterValueType.DECIMAL,
                            conditionType: FilterConditionType.EQUAL,
                            decimal: filters.totalPrice
                        }],
                        type: [{
                            type: FilterValueType.STRING,
                            conditionType: FilterConditionType.EQUAL,
                            string: filters.type
                        }],
                        contractNumber: [{
                            type: FilterValueType.STRING,
                            conditionType: FilterConditionType.CONTAINS,
                            string: contractNumber ?? filters.contractNumber
                        }]
                    });
                }
            })
        })
    };
};
