import React, {CSSProperties, FC} from "react";
import * as styles from "./ContextMenuHolder.module.css";

interface ContextMenuHolderProps {
    shown: boolean
    hide: () => void
    items: ([ string, () => void ] | "sep")[]
    align?: "left" | "right"
}

export const ContextMenuHolder: FC<ContextMenuHolderProps> = x => {
    const style = x.shown ? { [x.align ?? "right"]: 0 } as CSSProperties : undefined;

    return <div className={styles.holder}>
        {x.children}
        {
            x.shown && <div className={styles.menu} tabIndex={-1} onBlur={x.hide} ref={input => input && input.focus()} style={style}>
                {
                    x.items.map((item, i) => {
                        return item === "sep"
                            ? <div className={styles.sep} key={i} />
                            : <div className={styles.item} onClick={() => {
                                x.hide();
                                item[1]();
                            }} key={i}>{item[0]}</div>;
                    })
                }
            </div>
        }
    </div>;
};
