import { convertPaged, IPagedItems } from "./Pagination";
import { http, FilterValueType, IFilterObject } from "./http";
import { FinancialActionSource, FinancialSource } from "@/models";

export default class {
    public static async get(
        from: number,
        count: number,
        filters: IFilterObject
    ): Promise<IPagedItems<FinancialSource>> {
        return convertPaged(
            await http.getPaged("/api/finance", {
                from: from,
                count: count,
                filters: filters
            }),
            FinancialActionSource,
        );
    }

    public static async getById(id: string): Promise<FinancialSource> {
        return FinancialSource.fromJson(await http.get(`/api/finance/${id}`));
    }
}
