// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, "._2-adTGeOLPYQVGzBLBXExo {\n    cursor: pointer;\n\n    transition: all 0.1s ease;\n    position: fixed;\n    bottom: 0;\n    top: 0;\n    left: 0;\n    width: 30px;\n    padding: 3px;\n    display: flex;\n    align-items: center;\n    justify-content: center;\n}\n\n._2-adTGeOLPYQVGzBLBXExo > svg {\n    opacity: 0.3;\n}\n\n._2-adTGeOLPYQVGzBLBXExo:hover > svg {\n    opacity: 1;\n}\n\n\n._2-adTGeOLPYQVGzBLBXExo:hover:before {\n    background: linear-gradient(0deg, rgba(255, 255, 255, 0.05), rgba(255, 255, 255, 0.05)), linear-gradient(121.99deg, #00B9FF -15.18%, #8C32E6 45.44%, #E5083B 94.62%);\n    opacity: 0.05;\n    content: \"\";\n    height: 100%;\n    width: 100%;\n    position: absolute;\n}\n\n@media (max-width: 590px) {\n    ._2-adTGeOLPYQVGzBLBXExo {\n        display: none;\n    }\n}", ""]);
// Exports
exports.locals = {
	"scrollTop": "_2-adTGeOLPYQVGzBLBXExo"
};
module.exports = exports;
