// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, "._2E40sbzkm8vBEkKL1_BK0V {\n    display: grid;\n    grid-template-columns: 1fr 1fr 1fr;\n    grid-gap: 2px;\n\n    width: 600px;\n}\n\n.xl2YucdxgQf_bBn3UQXo6 {\n    box-sizing: border-box;\n    padding: 10px 15px;\n    width: 200px;\n    color: #428bca;\n    display: flex;\n    align-items: center;\n    justify-content: center;\n\n    cursor: pointer;\n    transition: all 0.1s ease;\n    font-weight: bold;\n    font-size: 16px;\n    line-height: 17px;\n}\n\n.xl2YucdxgQf_bBn3UQXo6:hover {\n    color: #2a6496;\n}\n\n._10opANhHssfVRF6fagVHm- {\n    background-color: #428bca;\n    color: white;\n    border-radius: 8px 8px 2px 2px;\n}\n\n._10opANhHssfVRF6fagVHm-:hover {\n    background-color: #1c9dbd;\n    color: white;\n}", ""]);
// Exports
exports.locals = {
	"tabs": "_2E40sbzkm8vBEkKL1_BK0V",
	"tab": "xl2YucdxgQf_bBn3UQXo6",
	"active": "_10opANhHssfVRF6fagVHm-"
};
module.exports = exports;
