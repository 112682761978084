export function debounced<FN extends (...args: any[]) => void>(delay: number, fn: FN) {
    let timerId: number | undefined;
    return ((...args) => {
        if (timerId) {
            clearTimeout(timerId);
        }
        timerId = setTimeout(() => {
            fn(...args);
            timerId = undefined;
        }, delay);
    }) as FN;
}

export const droppedByDebouncer = Symbol.for("#droppedByDebouncer");

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function debouncedAsync<FN extends (...args: any[]) => Promise<any>>(delay: number, fn: FN) {
    type PromiseResult = FN extends (...args: any[]) => Promise<infer X> ? X : never;

    let timerId: number | undefined;
    let promiseResolve: ((value?: PromiseResult) => void) | undefined;
    let promiseReject: ((reason?: typeof droppedByDebouncer) => void) | undefined;

    return ((...args) => {
        if (timerId) {
            clearTimeout(timerId);
            promiseReject?.(droppedByDebouncer);
        }
        const promise = new Promise<PromiseResult>((rs, rj) => { promiseResolve = rs; promiseReject = rj });
        timerId = setTimeout(async () => {
            const result = await fn(...args);
            promiseResolve?.(result);
            promiseResolve = undefined;
            promiseReject = undefined;
            timerId = undefined;
        }, delay);

        return promise;
    }) as FN;
}

export const clsx = (...classes: (string | boolean | null | undefined)[]) => classes
    .filter(Boolean)
    .join(' ');
