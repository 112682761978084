import {AutoDto, AutoModel} from "@/models/parsing";


interface ProcedurePerformerMD {
    isEqualToDocPreparer: boolean
    organization?: number
}


export type ProcedurePerformer = AutoModel<ProcedurePerformerMD>;
export type DtoProcedurePerformer = AutoDto<ProcedurePerformerMD>;

export const ProcedurePerformer = {
    toDto(prf: ProcedurePerformer): DtoProcedurePerformer {
        return {
            ...prf,
        };
    },
    fromDto(prf: DtoProcedurePerformer): ProcedurePerformer {
        return {
            ...prf,
            organization: prf.organization ?? undefined
        };
    },
};

