


































import CardModal from "@/components/CardModal.vue";
import { Vue, Component, Prop } from "vue-property-decorator";
import { ContractScope } from './ContractScope';
import EventBus from '../../EventBus';
import HintModal from './modals/HintModal.vue';
import ErrorModal from './modals/ErrorModal.vue';
import ContractDocumentModal from "../modals/ContractDocumentModal";
import Contracts from '../../api/Contracts';
import { SupplierEvasion } from '../../models/Contracts';
import { ObjectStatus, ContractStatusStrings } from '../../models/enums';
import { File } from '../../models/Documents';

@Component({ components: { CardModal } })
export default class Evasion extends Vue {
    @Prop() private prop!: ContractScope;

    public evasion = this.prop.contract.supplierEvasion 
        ? SupplierEvasion.fromJson(this.prop.contract.supplierEvasion.toJson())
        : new SupplierEvasion(null!, null!, null!, null!, ObjectStatus.CREATING);

    public get evasionStatus() { return ContractStatusStrings[this.evasion.status] }

    public check() {
        const errors = [];
        if (!this.evasion.reason || this.evasion.reason.trim().length === 0)
            errors.push('Поле "Причина уклонения" обязательное, необходимо заполнить.');
        if (!this.evasion.protocol)
            errors.push('Поле "Протокол уклонения" обязательное, необходимо заполнить.');
        if (!this.evasion.date)
            errors.push('Поле "Дата протокола" обязательное, необходимо заполнить.');
        if (!this.evasion.number || this.evasion.number.trim().length === 0)
            errors.push('Поле "​Номер протокола" обязательное, необходимо заполнить.');

        if (errors.length) {
            EventBus.callModal(ErrorModal, { errors });
        }

        return !errors.length;
    }

    public async register() {
        if (!this.check()) return;

        // keep for future
        const trueMessage = "Вы уверены, что хотите зарегистрировать сведения об " + 
            "уклонении поставщика от заключения контракта? В случае подтверждения в " + 
            "текущем контракте будет изменен Поставщик на организацию, заявка которой " + 
            "заняла второе место по итогам определения победителя конкурентной процедуры. " + 
            "В случае отсутствия такой организации, контракт будет переведен в статус 'Аннулирован'";

        const message = "Вы уверены, что хотите зарегистрировать сведения об " + 
            "уклонении поставщика от заключения контракта? В случае подтверждения, " + 
            "контракт будет переведен в статус 'Аннулирован'";

        if (await EventBus.callModal(HintModal, {
            header: "Регистрация",
            text: message
        })) {
            this.prop.contract = await Contracts.registerEvasion(this.prop.contract, this.evasion);
            this.$emit("close-modal");
        }
    }

    public async pickProtocol() {
        const result = await EventBus.callModal<File | null, File>(ContractDocumentModal, this.evasion.protocol);
        if (result.isOk) {
            this.evasion.protocol = result.getResult();
        }
    }

    public async save() {
        if (!this.check()) return;

        this.prop.contract.supplierEvasion = (await Contracts.saveEvasion(this.prop.contract, this.evasion)).supplierEvasion;
        this.$emit("close-modal");
    }
}
