import React from "react";
import { SectionProps } from "@/views/Procedures2020/view/store";
import { useMappedStore } from "@/storeUtils";
import { Column, Table } from "@/components/table";
import { Card } from "@/components/Card";
import { VStack } from "@/components/layouts";
import moment from "moment";
import { ProcedureStatus } from "@/models/Procedures2020/Procedure";
import { HistoryItem } from "@/models";

/** История изменений */
export const ChangesHistorySection: React.FC<SectionProps> = x => {

    /*
        Пользователь
        Дата и время изменения
        Статус
    */
    type ProcedureHistoryItem = HistoryItem<ProcedureStatus>;

    const columns: Column<ProcedureHistoryItem>[] = [
        Table.Column("Пользователь", a => <div></div>, { width: "1fr" }),
        Table.Column("Дата и время изменения", a => <div></div>, { width: "1fr" }),
        Table.Column("Статус", a => <div></div>, { width: "1fr" }),
    ];

    const [history] = useMappedStore(x.formStore, s => [s.head.history]);

    if (!history)
        return null;

    return <Card>
        <VStack spacing={"15px"}>
            <Table<ProcedureHistoryItem> dataset={history} columns={[
                Table.AutoColumn("Пользователь", x => <>{x.item.user.fullName}</>),
                Table.AutoColumn("Дата и время изменения", x => <>{moment(x.item.date).format("DD.MM.YYYY HH:mm")}</>),
                Table.AutoColumn("Статус", x => <>{x.item.status}</>),
            ]}/>
        </VStack>
    </Card>;
};
