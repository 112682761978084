import { Monopoly } from "@/models/Monopoly";
import { createStoredRemoteDataset, StoredRemoteDataset } from "@/components/table";
import { createEffect, createEvent, createStore, Effect, forward, Store } from "effector";
import { clearMonopolyObjects, getMonopolyObjectsPaged, mockMonopolyObjects } from "@/api/Monopoly";
import { FilterConditionType, FilterValueType, IFilterValue } from "@/api/http";
import Auth from "@/api/Auth";

export type MonopolyFilters = {
    supplier: string
    regNumber: string
    status: string
};

type MonopolyState = {
    conclusionRequests: StoredRemoteDataset<Monopoly, MonopolyFilters>
    monopolyObjects: StoredRemoteDataset<Monopoly, MonopolyFilters>
};

export type MonopolyStore = {
    store: Store<MonopolyState>
    mockMonopolyObjectsFx: Effect<void, void>
    clearMonopolyObjectsFx: Effect<void, void>
    type: "in-progress" | "accepted" | "processed"
};

const getFilters = (filters: Partial<MonopolyFilters>): Record<keyof MonopolyFilters, IFilterValue[]> => ({
    supplier: [{
        type: FilterValueType.STRING,
        conditionType: FilterConditionType.CONTAINS,
        string: filters.supplier
    }],
    status: [{
        type: FilterValueType.STRING,
        conditionType: FilterConditionType.CONTAINS,
        string: Array.isArray(filters.status) ? undefined : filters.status
    }],
    regNumber: [{
        type: FilterValueType.STRING,
        conditionType: FilterConditionType.CONTAINS,
        string: filters.regNumber
    }]
});

export type ConclusionRequestsType = "in-progress" | "accepted" | "processed";

export const createConclusionRequestsStore = (type: ConclusionRequestsType): MonopolyStore => {
    const { selectedYear } = Auth;

    const mockMonopolyObjectsFx = createEffect({
        handler: async () => await mockMonopolyObjects()
    });

    const clearMonopolyObjectsFx = createEffect({
        handler: async () => await clearMonopolyObjects()
    });

    const updateSrd = createEvent();

    forward({
        from: mockMonopolyObjectsFx.done,
        to: updateSrd
    });

    const getMonopolyFiltersByPageType = (type: ConclusionRequestsType) => {
        switch(type) {
            case "in-progress":
                return [{
                    type: FilterValueType.STRING,
                    conditionType: FilterConditionType.CONTAINS,
                    string: "CREATING"
                }];
            case "accepted":
                return [{
                    type: FilterValueType.STRING,
                    conditionType: FilterConditionType.CONTAINS,
                    string: "ACCEPTED"
                }];
            case "processed":
                return [
                    {
                        type: FilterValueType.STRING,
                        conditionType: FilterConditionType.CONTAINS,
                        string: "AGREEMENT"
                    },
                    {
                        type: FilterValueType.STRING,
                        conditionType: FilterConditionType.CONTAINS,
                        string: "DENIED"
                    },
                    {
                        type: FilterValueType.STRING,
                        conditionType: FilterConditionType.CONTAINS,
                        string: "DIVERGENCE"
                    },
                    {
                        type: FilterValueType.STRING,
                        conditionType: FilterConditionType.CONTAINS,
                        string: "LOT_PUBLISHED"
                    },
                    {
                        type: FilterValueType.STRING,
                        conditionType: FilterConditionType.CONTAINS,
                        string: "CONTRACT_CONCLUDED"
                    },
                ];
            default:
                return [];
        }
    };

    const monopolyObjects = createStoredRemoteDataset<Monopoly, MonopolyFilters>({
        async load(filters, from, count) {
            const patchedFilters = getFilters(filters);
            if(!filters.status || !filters.status.length) {
                // if status is empty apply default filters
                getMonopolyFiltersByPageType(type).forEach(f => patchedFilters.status.push(f));
            } else {
                // filter only selected items. Push only strings to filters
                // because backend cant parse filter string: [string1, string2]
                getMonopolyFiltersByPageType(type).filter(f => filters.status?.includes(f.string)).forEach(f => {
                    // handle type errors from FilteredSelectionTable
                    if(Array.isArray(f.string)) return;
                    patchedFilters.status.push(f);
                });
            }
            return await getMonopolyObjectsPaged(selectedYear, from, count, patchedFilters);
        }
    })
        .on(updateSrd, s => {
            return ({ ...s, needReloaded: true, page: 1 });
        });

    return {
        store: createStore<MonopolyState>({
            conclusionRequests: createStoredRemoteDataset<Monopoly, MonopolyFilters>({
                async load(filters, from, count) {
                    const patchedFilters = getFilters(filters);
                    patchedFilters.status.push({
                        type: FilterValueType.STRING,
                        conditionType: FilterConditionType.CONTAINS,
                        string: type === "in-progress" ? "CREATING" : "ACCEPTED"
                    });
                    return await getMonopolyObjectsPaged(selectedYear, from, count, patchedFilters);
                }
            }),
            monopolyObjects,
        }),
        mockMonopolyObjectsFx,
        clearMonopolyObjectsFx,
        type: type,
    };
};
