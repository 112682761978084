import {WebinarChannel, WebinarChannelUpdate, WebinarsApi, WebinarUpdate} from "@/api/Webinars";
import React, {useEffect, useState} from "react";
import {Card} from "@/components/Card";
import {Expandee, HStack, VStack} from "@/components/layouts";
import {Field} from "@/components/form/Field";
import {Button, TextBox} from "@/components/primitive";
import Editor from "rich-markdown-editor";
import {getMediaUrl, MediaApi} from "@/api/Media";
import {showModal} from "@/EventBus";
import {ConfirmationModal} from "@/views/Contracts/modals/ConfirmationModal";

const init: WebinarUpdate = { };

export const PrivateWebinarSection: React.FC <{ channelLoc: string }> = x => {
    const [item, setItem] = useState<WebinarChannel>();
    const [update, setUpdate] = useState<WebinarChannelUpdate>();
    const [show, setShow] = useState(false);
    const [key, setKey] = useState<string>();



    const reload = async () => {
        const res = await WebinarsApi.getChannel(x.channelLoc);
        setUpdate(res);
        setItem(res);
        const key =await WebinarsApi.findStreamKey(x.channelLoc);
        setKey(key.streamKey);
    };

    useEffect(() => {
        reload();
    }, []);

    const save = async () => {
        const res = await  WebinarsApi.updateChannel(x.channelLoc, {
            ...update
        });
        reload();
    };

    const updateKey = async () => {
        const confirm = await showModal(ConfirmationModal, {});
        if (!confirm || !key) return;
        const result = await WebinarsApi.updateStreamKey(key, x.channelLoc);
        reload();
    };

    const bottom: React.ReactNode = <HStack spacing="10px">
        <Expandee />
        <Button color="blue" title="Сохранить" onClick={save} />
    </HStack>;

    const title = <div style={{ display: "flex", gap: "20px" }}>
        <span>{`Ключ ${x.channelLoc === "first" ? "первой" : "второй"} закрытой трансляции`}</span>
        <Expandee />
        <Button title="Обновить ключ" color="yellow" onClick={save} disabled={!key} />
    </div>;

    return <Card title={title} bottom={bottom}>
        <div style={{ display: "grid", gridTemplateColumns: "1fr 1fr", gap: "25px" }}>
            <VStack spacing="15px">
                <Field title="Название стрима">
                    <HStack spacing="15px">
                        <TextBox style={{ width: "400px" }} value={update?.channelName}
                                 onChange={v => setUpdate({ ...update, channelName: v})} />
                    </HStack>
                </Field>
            </VStack>
            <VStack spacing="15px">
                <Field title="Описание">
                    <div style={{ width: "100%", boxSizing: "border-box", height: "400px", overflow: "auto", padding: "5px", border: "1px solid black" }}>
                        <Editor defaultValue={item?.description ?? undefined}
                                key={item?.description}
                                uploadImage={async file => {
                                    const formData = new FormData();
                                    formData.append("file", file.slice(0, file.size, file.type));
                                    const result = await MediaApi.uploadMedia(formData);
                                    const mediaItem = Object.values(result)[0]?.result;
                                    return getMediaUrl(mediaItem) ?? "";
                                }}
                                onImageUploadStop={() => {console.log("upload completed")}}
                                placeholder={"Начните писать текст"}
                                onChange={v => setUpdate({ ...update, description: v() })} />
                    </div>
                </Field>
            </VStack>
                <VStack spacing="15px">
                    <Field title="Сервер">
                        <HStack spacing="15px">
                            <TextBox style={{ width: "400px" }} value={`rtmp://${window.location.host}/stream`} readonly />
                        </HStack>
                    </Field>
                </VStack>
                <VStack spacing="15px">
                    <Field title="Ключ потока">
                        <HStack spacing="15px">
                            <TextBox style={{ width: "500px" }} password={!show} value={key} onChange={setKey} />
                            <Button icon="faEye" onClick={() => setShow(!show)} />
                        </HStack>
                    </Field>
                </VStack>
                <VStack spacing="15px">
                    <Field title="Ссылка потока">
                        <HStack spacing="15px">
                            <TextBox style={{ width: "400px" }} value={`${window.location.protocol}//${window.location.host}/webinars/view/${x.channelLoc}/`} readonly />
                        </HStack>
                    </Field>
                </VStack>
        </div>
    </Card>;
};