








import { SelectOption } from '@/models/enums';
import { Component, Prop, Vue, Watch } from "vue-property-decorator";
import Decimal from "decimal.js";
import { subMinutes } from 'date-fns';

@Component
export default class DateCellEditor extends Vue {
    @Prop() public cellValue!: unknown;
    @Prop({ default: true }) public withDay!: boolean;

    public change(v: Date) {
        this.$emit('change', v ? subMinutes(v,  v.getTimezoneOffset()) : 0);
    }
}
