// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".vv_kA70_0cUKasHi6ENeH {\n    height: 100%;\n    overflow-y: auto;\n}", ""]);
// Exports
exports.locals = {
	"scrollBox": "vv_kA70_0cUKasHi6ENeH"
};
module.exports = exports;
