



import { Component, Prop, Vue } from "vue-property-decorator";
import { MonopolyObjectView } from "@/views/Monopoly/view/MonopolyObject/MonopolyObjectView";
import { createMonopolyObjectStore } from "@/views/Monopoly/view/MonopolyObject/store";

@Component({ components: { komponent: MonopolyObjectView as unknown as typeof Vue } })
export default class MonopolyObjectViewVueStub extends Vue {
    @Prop() private objectId!: string;

    public storeContainer = Object.freeze(createMonopolyObjectStore(this.objectId));
}
