































































































import { Component, Prop, Vue } from "vue-property-decorator";
import CardModal from "@/components/CardModal.vue";
import { ModalResult } from "@/view-models/ModalRequest";
import { User } from '../models/User';
import EventBus from '../EventBus';
import { Participant } from '../models';
import ParticipantSelectionModal2 from './modals/ParticipantSelectionModal2/ParticipantSelectionModal2.vue';
import { EditType, IRowConfiguration, ITableColumn, MultiSelection } from "@/components/TableAbstractions";

@Component({ components: { CardModal } })
export default class AddUserModal extends Vue {
    @Prop() public prop!: User | null;

    public user = new User(
        this.prop ? this.prop.id : null!,
        this.prop ? this.prop.firstName : null!,
        this.prop ? this.prop.lastName : null!,
        this.prop ? this.prop.middleName : null!,
        this.prop ? this.prop.role : "USER",
        this.prop ? this.prop.status : "ACTIVE",
        this.prop ? this.prop.username : null!,
        this.prop ? this.prop.regNumber : null!,
        // eslint-disable-next-line @typescript-eslint/ban-ts-ignore
        //@ts-ignore
        this.prop ? this.prop.selectedParticipant : { id: -1 }!,
        this.prop?.allowedParticipants ?? [],
        null,
        this.prop?.comment ?? null
    );

    public participantTableHeaders: ITableColumn<Participant>[] = [{
      title: "Название",
      type: EditType.TEXT,
      getter(x) { return x.shortName }
    }, {
      title: "ИНН",
      type: EditType.TEXT,
      size: "auto",
      getter(x) { return x.tin }
    }];

    public participantSelection: MultiSelection<Participant> = {
      tag: "MULTI",
      items: []
    };

    public participantTableConfig: IRowConfiguration<Participant> = {
      selection: {
        showSelectionColumn: true
      }
    };

    public get userParticipantOptions() {
      return this.user.allowedParticipants.map(x => ({ key: x.id, desc: x.shortName }));
    }

    public selectParticipant(id: number) {
      this.user.selectedParticipant = this.user.allowedParticipants.find(x => x.id == id) ?? this.user.selectedParticipant;
    }

    public async addParticipant() {
        const r = await EventBus.callModal<unknown, Participant>(ParticipantSelectionModal2, { onlyValidForUserSelection: true });
        if (r.isOk) {
          const p = r.getResult();
          if (!this.user.allowedParticipants.some(i => i.id == p.id))
            this.user.allowedParticipants.push(p);
            this.selectParticipant(p.id);
        }
    }

    public deleteParticipants() {
      this.user.allowedParticipants = this.user.allowedParticipants
          .filter(x => !this.participantSelection.items.some(i => i.id == x.id));

      if (!this.user.allowedParticipants.some(i => i.id == this.user.selectedParticipant.id))
        this.user.selectedParticipant = this.user.allowedParticipants[0];

      this.participantSelection.items = [];
    }

    public get isOk() {
        return !!this.user.lastName && this.user.lastName.trim().length > 0 &&
            !!this.user.firstName && this.user.firstName.trim().length > 0 &&
            !!this.user.username && this.user.username.trim().length > 0 &&
            !!this.user.regNumber && this.user.regNumber.trim().length > 0 &&
            (!!this.prop || (!!this.password && this.password.trim().length > 0)) &&
            !!this.user.selectedParticipant && this.user.selectedParticipant.id !== -1;
    }

    public saveAndClose() {
        if (this.isOk) {
            if (this.user.middleName && !this.user.middleName.trim().length)
                this.user.middleName = null;

            if (this.password && !this.password.trim().length)
                this.password = null;

            this.$emit("close-modal", ModalResult.ok({ user: this.user, password: this.password }));
        }
    }

    public password: string | null = null;
}
