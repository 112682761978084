import {ModalFC} from "@/EventBus";
import {Expandee, HStack, VStack} from "@/components/layouts";
import {Button, ComboBox, Label} from "@/components/primitive";
import {CardModal} from "@/components/modals/CardModal";
import React, {useState} from "react";
import {Participant} from "@/models";

export const SelectParticipantModal: ModalFC<{participants: Participant[]}, Participant> = x => {

    const [participant, setParticipant] = useState<Participant>();

    const bottom = <HStack spacing={"10px"}>
        <Expandee />
        <Button color="red"
                title="закрыть"
                onClick={() => x.done(undefined)} />
        <Button color="green"
                title="сохранить"
                disabled={!participant}
                onClick={() => x.done(participant)} />
    </HStack>;

    return <CardModal title={"Выбор организатора закупки"}
                      close={() => x.done(undefined)}
                      width={"60%"}
                      bottom={bottom}
    >
        <VStack spacing={"15px"}>
            <Label text={participant?.fullName ?? ""} />
            <ComboBox value={participant}
                      options={[{key: undefined, desc: "Выберите организатора закупки"},...x.participants.map(a => ({key: a, desc: a.shortName}))]}
                      onChange={a => setParticipant(a)} />
        </VStack>
    </CardModal>;
};