import React, { useRef, useState } from "react";
import { CardModal } from "@/components/modals/CardModal";
import {ModalFC, showModal} from "@/EventBus";
import { Button } from "@/components/primitive";
import {HStack, VStack} from "@/components/layouts";
import { createUsersFromExcel } from "@/api/Admin";
import { UserExcelResponse } from "@/models/User";
import {ConfirmationModal, ErrorModal} from "@/views/Contracts/modals/ConfirmationModal";

export const UploadUsersModal: ModalFC<void, UserExcelResponse> = x => {

    const [file, setFile] = useState<File>();
    const [spin, setSpin] = useState(false);

    const handleUpload = async (file: File) => {
        const formData = new FormData();
        formData.append('file', file);

        const result = await createUsersFromExcel(formData);
        console.log('result: ',result);
        return result;
    };

    //probably need to move x.done to bottom save button
    const handleFileInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const _file = e.currentTarget.files?.[0];
        if (!_file) {
            return null;
        }
        setFile(_file);
    };

    const save = async () => {
        if (!file) return;
        try {
            setSpin(true);
            const result = await handleUpload(file);
            if (result) {
                if (result.errors.length > 0) {
                    const text = `Создано ${result.totalCreatedUsers} из ${result.totalUsers}.
${result.errors.join("\n")}`;
                    await showModal(ErrorModal, { text });
                } else {
                    const text = `Создано ${result.totalCreatedUsers} из ${result.totalUsers}.`;
                    await showModal(ConfirmationModal, { text, notify: true, title: "Результат" });
                }
                return x.done(result);
            }
        } catch (e) {
            console.error(e);
            await showModal(ErrorModal, { });
        } finally {
            setSpin(false);
        }
        return null;
    };

    const bottom = <Button icon={spin ? "faSpinner" : "aSave"}
                           disabled={spin}
                           onClick={save} />;

    const select = <>
        <input type="file"
                          accept=".xlsx"
                          id="uploadInput"
                          multiple={false}
                          onChange={handleFileInputChange}
                          hidden />
        <label htmlFor="uploadInput">
            <Button color="green" icon="aAdd" title="Загрузить файл с пользователями" />
        </label>
    </>;

    const current = <HStack spacing="20px" alignItems="center">
        <div>{ file?.name ?? "-" }</div>
        <Button icon="faTrash"
                color="red"
                disabled={spin}
                onClick={() => setFile(undefined)} />
    </HStack>;

    return <CardModal title="Добавление группы пользователей"
                      bottom={bottom}
                      close={() => x.done(undefined)}
                      width="550px">
                <VStack spacing="20px" innerStyle={{alignItems: "center"}} outerStyle={{justifyContent: "center"}}>
                    { file ? current : select }
                    <a href="/api/v2/admin/users/templateTable" target="_blank" style={{textDecoration: "none"}}>
                        <Button color="green" icon="aSave" title="Скачать шаблон"/>
                    </a>
                </VStack>
    </CardModal>;
};
