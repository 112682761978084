





















































import { Component, Vue, Prop } from "vue-property-decorator";
import {showModal} from "@/EventBus";
import {ConfirmationModal} from "@/views/Contracts/modals/ConfirmationModal";

@Component
export default class ShopSidebar extends Vue {
    @Prop({ default: false })
    public collapsed!: boolean;

    public toggle() {
        this.collapsed = !this.collapsed;
        this.$emit("toggle", this.collapsed);
    }

    public async supplierPortalLink(e: Event) {
      e.stopPropagation();
      const link = "https://zakupki.mos.ru/external/eaist/startAuth?targetUrl=https%3A%2F%2Fzakupki.mos.ru%2Fexternal%2Feaist%2FauthResult";
      const result = await showModal(ConfirmationModal, { title: "Предупреждение", notify: true, text: "Реестр и подача заявки на СТЕ размещены в системе АИС \"Портал поставщиков\". Вы будете перенаправлены из Электронного магазина ЕАИСТ на страницу просмотра и создания заявки на СТЕ.", width: "550px" });
      if (result) window.open(link, '_blank');
    }
}
