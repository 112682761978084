import { ContractClaimWork } from "@/models/Contracts/ContractClaimWork";
import { IDtoContractClaimWork } from "@/models/json/Contracts/IDtoContractClaimWork";
import { http } from "./http";
import { ContractDocument, ContractDocumentType, ContractExecutionProvision } from "@/models/Contracts";
import { IDtoContractDocument, IDtoContractDocumentType, IDtoContractExecutionProvision } from "@/models/json/Contracts";

export default class {
    public static async getFromContract(contractId: string): Promise<ContractClaimWork[]> {
        return ((await http.get(
            `/api/contractClaimWorks/fromContract/${contractId}`,
            undefined,
        )) as IDtoContractClaimWork[]).map(ContractClaimWork.fromJson);
    }

    public static async create(
        contractId: string,
        src: ContractClaimWork,
    ): Promise<ContractClaimWork> {
        return ContractClaimWork.fromJson(
            await http.post(`/api/contractClaimWorks/createFor/${contractId}`, src.toJson()),
        );
    }

    public static async update(obj: ContractClaimWork): Promise<ContractClaimWork> {
        return ContractClaimWork.fromJson(
            await http.put(`/api/contractClaimWorks/${obj.id}`, obj.toJson()),
        );
    }

    public static async register(obj: ContractClaimWork): Promise<ContractClaimWork> {
        return ContractClaimWork.fromJson(
            await http.post(`/api/contractClaimWorks/${obj.id}/register`, undefined, "Ошибка при подтверждении"),
        );
    }

    public static async makeChanges(obj: ContractClaimWork): Promise<ContractClaimWork> {
        return ContractClaimWork.fromJson(
            await http.post(`/api/contractClaimWorks/${obj.id}/makeChanges`, undefined),
        );
    }

    public static async delete(obj: ContractClaimWork): Promise<void> {
        await http.delete(`/api/contractClaimWorks/${obj.id}`);
    }

    public static async createDocument(obj: ContractClaimWork, doc: ContractDocument): Promise<ContractDocument> {
        return ContractDocument.fromJson(
            await http.post(`/api/contractClaimWorks/${obj.id}/documents`, doc.toJson()));
    }

    public static async deleteDocument(obj: ContractClaimWork, doc: ContractDocument): Promise<void> {
        await http.delete(`/api/contractClaimWorks/${obj.id}/documents/${doc.id}`);
    }

    public static async sendDocument(obj: ContractClaimWork, doc: ContractDocument): Promise<ContractDocument> {
        return ContractDocument.fromJson(
            await http.post(`/api/contractClaimWorks/${obj.id}/documents/${doc.id}/send`, undefined));
    }
}
