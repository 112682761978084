import { IDtoMeasurementUnit } from "@/models/json";
import { IJsonFormattable } from "@/models/IJsonFormattable";

export class MeasurementUnit implements IJsonFormattable<IDtoMeasurementUnit> {
    constructor(public name: string, public id: number) {}

    public toJson(): IDtoMeasurementUnit {
        return { ...this };
    }

    // noinspection JSUnusedGlobalSymbols
    public static fromJson(dto: IDtoMeasurementUnit): MeasurementUnit {
        return Object.assign(Object.create(MeasurementUnit.prototype), dto);
    }
}
