import React from "react";
import {SectionProps} from "@/views/Procedures2020/view/store";
import {useMappedStore} from "@/storeUtils";
import {Column, Table} from "@/components/table";
import {Card} from "@/components/Card";
import {VStack} from "@/components/layouts";

/** Запросы в МКР */
export const MkrRequestsSection: React.FC<SectionProps> = x => {

    /*
        №
        Статус по запросу
        Комментарий
        Дата запроса
        Пользователь
    */
    const columns: Column<undefined>[] = [
        Table.Column("№", a => <div></div>, {width: "1fr"}),
        Table.Column("Статус по запросу", a => <div></div>, {width: "2fr"}),
        Table.Column("Комментарий", a => <div></div>, {width: "2fr"}),
        Table.Column("Дата запроса", a => <div></div>, {width: "2fr"}),
        Table.Column("Пользователь", a => <div></div>, {width: "2fr"}),
    ];

    const sectionStore = useMappedStore(x.formStore, x => ({

    }));


    return <Card>
        <VStack spacing="15px">
            <Table dataset={[]} columns={columns}/>
        </VStack>
    </Card>;
};