import React, {ReactElement, useState} from "react";
import styles from "./TreeView.module.css";
import {j} from "@/reactUtils";

export interface Branch<T> {
    item: T
    children: Branch<T>[]
}

interface TreeNodeProps<T> {
    node: Branch<T>
    level?: number
    selected?: T
    className?: string
    selectedClassName?: string
    onClick?: (item: T) => void
    content?: (item: T) => ReactElement
}

const TreeExpandIcon: React.FC<{ expanded: boolean }> = x => {
    return <i className={j(styles.arrow, x.expanded ? styles.arrowDown : styles.arrowRight)} />;
};

const TreeNode: <T>(x: TreeNodeProps<T>) => JSX.Element = x => {
    const content = (x.content && x.content(x.node.item))
        ?? <div className={styles.TreeNodeInner}>
            { x.node.item }
      </div>;
    const expandable = !!x.node.children.length;
    const level = x.level ?? 0;
    const inactive = x.className ? x.className : styles.TreeNode;
    const selected = x.selectedClassName ? x.selectedClassName : j(styles.TreeNodeSelected, styles.TreeNode);
    const className = (x.selected === x.node.item)
        ? selected
        : inactive;

    const [expanded, setExpanded] = useState(false);

    const handleClick: () => void = expandable
            ? () => setExpanded(a => !a)
        : () => x.onClick?.(x.node.item);

    return <>
        <div className={className}
             style={level > 0 ? { borderTop: "none" } : undefined}
             onClick={handleClick}>
            <div className={styles.node} style={{ paddingLeft: level * 24 + "px" }}>
                {
                    expandable &&
                    <TreeExpandIcon expanded={expanded} />
                }
                { content }
            </div>
        </div>
        {
            (expanded && expandable) &&
            x.node.children.map((item, key) =>
                <TreeNode node={item}
                          onClick={x.onClick}
                          level={level + 1}
                          selected={x.selected}
                          content={x.content}
                          className={x.className}
                          selectedClassName={x.selectedClassName}
                          key={`level-${level + 1}-node-${key}`} />
            )
        }
    </>;
};
interface TreeViewProps<T> {
    dataset: Branch<T>[]
    selected?: T
    canSelect?: boolean
    onClick?: (item: T) => void
    nodeClassName?: string
    selectedClassName?: string
    content?: (item: T) => ReactElement
}

export const TreeView: <T>(x: TreeViewProps<T>) => JSX.Element = x => {
    type T = typeof x.dataset[0]["item"];
    const [selected, setSelected] = useState<T | undefined>(x.selected);

    return (
        <div>
            {
                x.dataset.map((node, key) =>
                    <TreeNode<T> node={node}
                                 key={`top-level-node-${key}`}
                                 selected={selected}
                                 className={x.nodeClassName}
                                 selectedClassName={x.selectedClassName}
                                 content={x.content}
                                 onClick={a => {
                                     x.onClick?.(a);
                                     x.canSelect && setSelected(node.item);
                                 }} />)
            }
        </div>
    );
};