import React from "react";
import styles from "./Card.module.css";
import {j} from "@/reactUtils";
import { Expandee } from "@/components/layouts";

interface CardProps {
    children?: React.ReactNode
    direction?: "vertical" | "horizontal"
    className?: string
    padded?: boolean
    title?: string | React.ReactNode
    border?: "thick" | "thin" | "none"
    gap?: string | number
    endAdornment?: React.ReactNode
}

export const Card = (props: CardProps) => {

    return (
        <div className={j(styles.card__wrapper, props.direction === "vertical" ? styles.card__wrapper_vertical : styles.card__wrapper_horizontal, props.className, props.padded && styles.card_padded)}
             style={{border: props.border === "none" ? "none" : props.border === "thin" ? "0.5px solid #eff3f6" : "1px solid #eff3f6", gap: props.gap, borderRadius: "0 0 5px 5px"}}>
                {props.title && <div className={styles.card__title}>
                    {props.title}
                    {props.endAdornment 
                        ? <>
                        <Expandee/>
                        {props.endAdornment}
                        </>
                        : <></> }
                </div>}
                    {props.children}
        </div>
    );
};
