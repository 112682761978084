import React from "react";
import {Card} from "@/components/Card";
import {SectionProps} from "@/views/ComposedLot/shared/store";
import {CheckBox, ComboBox, Label} from "@/components/primitive";
import {Expandee, HStack, VGrid, VStack} from "@/components/layouts";
import {Field, FieldInset, Fields} from "@/components/form/Field";
import {FCheckBox, FTextBox} from "@/components/form";
import {useMappedStore} from "@/storeUtils";
import {
    AdditionalRequirementsEvents as E
} from "@/views/ComposedLot/shared/store/additionalRequirements";
import {
    AdditionalRequirementsSections,
    AdditionalRequirementsSections2022,
    mergeText
} from "@/views/ComposedLot/edit/tabs/additional-info/AdditionalRequirementsData";

export const AdditionalRequirementsSection: React.FC<SectionProps> = x => {
    const [additionalRequirements, law, isSmallVolume] = useMappedStore(x.formStore, x => [x.additionalRequirements, x.info.law, x.info.specialPurchaseType !== "NO" && x.info.specialPurchaseType !== "MEDICINES"]);

    const secs = additionalRequirements.hasAdditionalRequirements2022 ? AdditionalRequirementsSections2022[law] : AdditionalRequirementsSections[law];
    const options = secs.map(x => ({
        key: x.title,
        desc: x.title
    }));

    const selected = secs.find(sec => sec.title === additionalRequirements.selectedOption) ?? secs[0];
    const viewing = x.formAction === "viewing";

    const optionSelection = Object.entries(selected.options).map(([id, text]) => (
        <React.Fragment key={id}>
            <FieldInset>
                <CheckBox value={!!additionalRequirements.options.find(fnd => fnd == id)}
                          onChange={a => a
                              ? E.setOptions(additionalRequirements.options.concat([id]))
                              : E.setOptions(additionalRequirements.options.filter(a => a !== id))
                          }>
                    {text}
                </CheckBox>
            </FieldInset>
        </React.Fragment>
    ));

    const FZ223 = <Card>
        <VStack spacing={"15px"}>
            <Fields orientation={"horizontal"}>
                <Field title="Дополнительные требования согласно Приложению 1 положения о закупках товаров, работ, услуг отдельных видов юридических лиц">
                    <HStack alignItems="center">
                        <FCheckBox value={additionalRequirements.hasAdditionalRequirements}
                                   onChange={E.setHasAdditionalRequirements} />
                        <Expandee/>
                        {
                            !viewing && additionalRequirements.hasAdditionalRequirements &&
                                <ComboBox
                                    value={additionalRequirements.selectedOption}
                                    onChange={ard => {
                                        E.setOptions([]);
                                        E.setSelectedOption(ard);
                                    }}
                                    options={options}
                                    style={{minWidth: "250px"}} />
                        }
                    </HStack>
                </Field>
            </Fields>
            {
                additionalRequirements.hasAdditionalRequirements && <>
                    {
                        !viewing && <Fields orientation={"horizontal"}>
                            {optionSelection}
                        </Fields>
                    }
                    <Fields orientation={"horizontal"}>
                        {selected.texts.map(text => {
                            const merged = mergeText(text, selected, additionalRequirements.options);
                            return (
                                <React.Fragment key={"textbox-" + text.title}>
                                    <Field title={text.title}>
                                        <FTextBox disabled lines={10} value={merged} style={{whiteSpace: "normal"}} />
                                    </Field>
                                </React.Fragment>
                            );
                        })}
                    </Fields>
                </>
            }
        </VStack>
    </Card>;

    const FZ44 =
        <Card>
            <VStack spacing="15px">
                <VGrid spacing="10px" columns="3fr 8fr">
                    <HStack spacing="10px">
                        <Label text="Дополнительные требования к поставщику"/>
                        {
                            !viewing && <CheckBox value={additionalRequirements.hasAdditionalSupplierRequirements}
                                      onChange={E.setHasAdditionalSupplierRequirements}/>
                        }
                    </HStack>
                    {
                        additionalRequirements.hasAdditionalSupplierRequirements
                            ? <FTextBox lines={5}
                                        value={additionalRequirements.supplierRequirements}
                                        onChange={E.setSupplierRequirements}/>
                            : <div/>
                    }
                </VGrid>

                <Fields orientation={"horizontal"}>
                    {
                        additionalRequirements.hasAdditionalRequirements && (
                            <>
                                {!viewing && optionSelection}
                                {selected.texts.map(x => {
                                    return <React.Fragment key={"textbox-" + x.title}>
                                        <Field title={x.title}>
                                            <FTextBox
                                                disabled
                                                lines={10}
                                                value={`${x.start} ${x.end ?? ""}`}
                                                style={{whiteSpace: "normal"}}
                                            />
                                        </Field>
                                    </React.Fragment>;
                                })}
                            </>
                        )
                    }
                    {
                        additionalRequirements.hasAdditionalSupplierRequirements && additionalRequirements.hasAdditionalRequirements &&
                            <Field title={"Описание дополнительного требования"} required>
                                <FTextBox value={additionalRequirements.supplierRequirementsSubstantiation}
                                          onChange={E.setSupplierRequirementsSubstantiation}
                                          lines={5} />
                            </Field>
                    }
                </Fields>
                <Fields orientation={"horizontal"}>
                    {
                        !isSmallVolume && <Field title={
                            <HStack alignItems={"center"} spacing={"10px"}>
                                <Label
                                    text=" Дополнительные требования к участникам закупки согласно ч. 2 ст. 31 Закона № 44-ФЗ (ПП РФ от 29.12.2021 № 2571)"/>
                                {
                                    !viewing && <CheckBox value={additionalRequirements.hasAdditionalRequirements2022}
                                                          onChange={E.setHasAdditionalRequirements2022}/>
                                }
                            </HStack>
                        }>
                            {
                                !viewing && additionalRequirements.hasAdditionalRequirements2022
                                    ? <ComboBox options={options}
                                                value={additionalRequirements.selectedOption}
                                                onChange={E.setSelectedOption}/>
                                    : viewing
                                        ? <FCheckBox value={additionalRequirements.hasAdditionalRequirements2022}/>
                                        : <div/>
                            }
                        </Field>
                    }
                    {
                        additionalRequirements.hasAdditionalRequirements2022 && (
                            <>
                                {!viewing && optionSelection}
                                {selected.texts.map(x => {
                                    return <React.Fragment key={"textbox-" + x.title}>
                                        <Field title={x.title}>
                                            <FTextBox
                                                disabled
                                                lines={10}
                                                value={`${x.start} ${x.end ?? ""}`}
                                                style={{whiteSpace: "normal"}}
                                            />
                                        </Field>
                                    </React.Fragment>;
                                })}
                            </>
                        )
                    }
                    {
                        additionalRequirements.hasAdditionalSupplierRequirements && additionalRequirements.hasAdditionalRequirements2022 &&
                        <Field title={"Описание дополнительного требования"} required>
                            <FTextBox value={additionalRequirements.supplierRequirementsSubstantiation}
                                      onChange={E.setSupplierRequirementsSubstantiation}
                                      lines={5} />
                        </Field>
                    }
                </Fields>
            </VStack>
        </Card>;

    return law === "F223" ? FZ223 : FZ44;
};
