import {asDecimal, AutoDto, AutoModel, MDMap} from "@/models/parsing";
import {Decimal} from 'decimal.js';
import { LotResponsibilityInfo } from "@/views/ComposedLot/shared/store/responsibilityInfo";

interface LotAdditionalPurchaseInfoMD {
    advantagesForUis?: Decimal
    advantagesForDisabledPeopleOrganizations?: Decimal
    foreignProductsForbidConditions?: string
    foreignProductsAmount?: Decimal
    options: string[]
}

export type DtoLotAdditionalPurchaseInfo = AutoDto<LotAdditionalPurchaseInfoMD>;
export type LotAdditionalPurchaseInfo = AutoModel<LotAdditionalPurchaseInfoMD>;

export const LotAdditionalPurchaseInfo = {
    toDto(info: LotAdditionalPurchaseInfo): DtoLotAdditionalPurchaseInfo {
        return {
            ...info,
            advantagesForUis: info.advantagesForUis?.toString(),
            advantagesForDisabledPeopleOrganizations: info.advantagesForDisabledPeopleOrganizations?.toString(),
            foreignProductsAmount: info.foreignProductsAmount?.toString()
        };
    },
    fromDto(info: DtoLotAdditionalPurchaseInfo): LotAdditionalPurchaseInfo {
        return {
            options: info.options,

            advantagesForUis: asDecimal(info.advantagesForUis),
            advantagesForDisabledPeopleOrganizations: asDecimal(info.advantagesForDisabledPeopleOrganizations),
            foreignProductsAmount: asDecimal(info.foreignProductsAmount),

            foreignProductsForbidConditions: info.foreignProductsForbidConditions ?? undefined
        };
    }
};

interface LotAdditionalRequirementsMD {
    supplierRequirements?: string
    supplierRequirementsSubstantiation?: string
    section?: string
    options: string[]
    supplierRequirementsIs2022: boolean
}

export type DtoLotAdditionalRequirements = AutoDto<LotAdditionalRequirementsMD>;
export type LotAdditionalRequirements = AutoModel<LotAdditionalRequirementsMD>;

export const LotAdditionalRequirements = {
    toDto(info: LotAdditionalRequirements): DtoLotAdditionalRequirements {
        return {
            ...info
        };
    },
    fromDto(info: DtoLotAdditionalRequirements): LotAdditionalRequirements {
        return {
            options: info.options,
            supplierRequirementsIs2022: info.supplierRequirementsIs2022,
            supplierRequirements: info.supplierRequirements ?? undefined,
            supplierRequirementsSubstantiation: info.supplierRequirementsSubstantiation ?? undefined,
            section: info.section ?? undefined
        };
    }
};

interface LotAdditionalInfoMD {
    requirements: MDMap<LotAdditionalRequirements, DtoLotAdditionalRequirements>
    purchaseInfo: MDMap<LotAdditionalPurchaseInfo, DtoLotAdditionalPurchaseInfo>
    responsibilityInfo: LotResponsibilityInfo
}

export type DtoLotAdditionalInfo = AutoDto<LotAdditionalInfoMD>;
export type LotAdditionalInfo = AutoModel<LotAdditionalInfoMD>;

export const LotAdditionalInfo = {
    toDto(info: LotAdditionalInfo): DtoLotAdditionalInfo {
        return {
            requirements: LotAdditionalRequirements.toDto(info.requirements),
            purchaseInfo: LotAdditionalPurchaseInfo.toDto(info.purchaseInfo),
            responsibilityInfo: info.responsibilityInfo
        };
    },
    fromDto(info: DtoLotAdditionalInfo): LotAdditionalInfo {
        return {
            requirements: LotAdditionalRequirements.fromDto(info.requirements),
            purchaseInfo: LotAdditionalPurchaseInfo.fromDto(info.purchaseInfo),
            responsibilityInfo: info.responsibilityInfo
        };
    }
};
