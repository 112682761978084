import { CharacteristicValueType, CharacteristicConditionType } from "@/models/enums";
import { IDtoCharacteristic } from "@/models/json";
import { IJsonFormattable } from "@/models/IJsonFormattable";
import { MeasurementUnit } from "./MeasurementUnit";

export class Characteristic implements IJsonFormattable<IDtoCharacteristic> {
    constructor(
        public isAdditional: boolean,
        public name: string,
        public valueType: CharacteristicValueType,
        public measurementUnit: MeasurementUnit | null,
        public enumValues: string[],
        public defaultValue: string,
        public conditionType: CharacteristicConditionType,
    ) {}

    public toJson(): IDtoCharacteristic {
        return {
            ...this,
            enumValues: [...this.enumValues],
            measurementUnit: this.measurementUnit ? this.measurementUnit.toJson() : null,
        };
    }

    // noinspection JSUnusedGlobalSymbols
    public static fromJson(dto: IDtoCharacteristic): Characteristic {
        return Object.assign(Object.create(Characteristic.prototype), dto, {
            enumValues: [...dto.enumValues],
            measurementUnit: dto.measurementUnit ? MeasurementUnit.fromJson(dto.measurementUnit) : null,
        });
    }
}
