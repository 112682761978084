import { AddressType } from "@/models/enums/AddressType";
import {IDtoAddress} from "@/models/json";
import {IJsonFormattable} from "@/models/IJsonFormattable";

export class Address implements IJsonFormattable<IDtoAddress> {
    constructor(
        public type: AddressType,
        public addressLine: string,
        public additionalInfo: string
    ) {}

    public toJson(): IDtoAddress {
        return {
            ...this
        };
    }

    public get isNotEmpty() {
        return !!this.addressLine && this.addressLine.length > 0 &&
            (this.type !== AddressType.LANDMARK || (!!this.additionalInfo && this.additionalInfo.length > 0));
    }

    public static fromJson(dto: IDtoAddress): Address {
        return Object.assign(Object.create(Address.prototype), {
            ...dto
        });
    }
}
