import React, {useEffect, useState} from "react";
import {Card} from "@/components/Card";
import {Field} from "@/components/form/Field";
import {Button, TextBox} from "@/components/primitive";
import {Expandee, HStack, VStack} from "@/components/layouts";
import {WebinarsApi} from "@/api/Webinars";
import {showModal} from "@/EventBus";
import {ConfirmationModal} from "@/views/Contracts/modals/ConfirmationModal";

export const StreamKeySection: React.FC<{ type: "private" | "public"; channelLoc: string}> = x => {
    const [key, setKey] = useState<string>();
    const [show, setShow] = useState(false);
    const reload = async () => {
        const result = await WebinarsApi.findStreamKey(x.channelLoc);
        setKey(result.streamKey);
    };

    useEffect(() => {
        reload();
    }, []);

    const save = async () => {
        const confirm = await showModal(ConfirmationModal, {});
        if (!confirm || !key) return;
        const result = await WebinarsApi.updateStreamKey(key, x.channelLoc);
        reload();
    };

    const title = <div style={{ display: "flex", gap: "20px" }}>
        <span>{x.type === "public" ? "Ключ трансляции" : `Ключ ${x.channelLoc === "first" ? "первой" : "второй"} закрытой трансляции`}</span>
        <Expandee />
        <Button title="Обновить ключ" color="yellow" onClick={save} disabled={!key} />
    </div>;

    return <Card title={title}>
        <div style={{ display: "flex", gap: "30px", alignItems: "center" }}>
            <VStack spacing="15px">
                <Field title="Сервер">
                    <HStack spacing="15px">
                        <TextBox style={{ width: "400px" }} value={`rtmp://${window.location.host}/stream`} readonly />
                    </HStack>
                </Field>
            </VStack>
            <VStack spacing="15px">
                <Field title="Ключ потока">
                    <HStack spacing="15px">
                        <TextBox style={{ width: "500px" }} password={!show} value={key} onChange={setKey} />
                        <Button icon="faEye" onClick={() => setShow(!show)} />
                    </HStack>
                </Field>
            </VStack>
        </div>
    </Card>;
};