import _ from "./LawSelectionModal.vue";

import { LawType } from "@/models/enums";

import { ModalVue } from "@/view-models/ModalRequest";
import { VueConstructor } from "vue";
import { IObjectDeletionModalArgs } from "../ModalArgs";

const __ = _ as VueConstructor<ModalVue<IObjectDeletionModalArgs, LawType>>;

export default __;
