import { SessionsController, PageOfLots } from "@/api/Sessions";
import { DirectPurchasesController, PageOfPurchases, Purchase } from "@/api/DirectPurchases";
import { SupplyRegion } from "@/api/filterHelpers";
import { IFilterObject, FilterValueType, FilterConditionType } from "@/api/http";
import { showModal } from "@/EventBus";
import router from "@/router";
import { createEffect, createEvent, createStore } from "effector";
import { ErrorModal } from "../Contracts/modals/ConfirmationModal";
import _ from 'lodash';
import Decimal from 'decimal.js';
import { ISubjectClassContainer } from "@/components/eshop/SubjectClassFilterStore";
import { firstMaybe } from "@/arrayUtils";

export type DirectPurchasesFilterStore = {
    kpgz: ISubjectClassContainer | null
    purchaseName: string | null
    purchaseNumber: string | null
    region: SupplyRegion | null | undefined
    priceMin: number | null
    priceMax: number | null
    quantityMin: number | null
    quantityMax: number | null
    startValidDateFrom: Date | null
    startValidDateTo: Date | null
    endValidDateFrom: Date | null
    endValidDateTo: Date | null
    publicationDateFrom: Date | null
    publicationDateTo: Date | null
    participantName: string | null
    favorites: boolean
    pageSize: number
};

export const changeFavorites = createEvent<boolean>();
export const changeKpgz = createEvent<ISubjectClassContainer | null>();
export const changePurchaseName = createEvent<string>();
export const changePurchaseNumber = createEvent<string>();
export const changeRegion = createEvent<SupplyRegion | null>();
export const changePriceMin = createEvent<number>();
export const changePriceMax = createEvent<number>();
export const changeQuantityMin = createEvent<number>();
export const changeQuantityMax = createEvent<number>();
export const changeStartValidDateFrom = createEvent<Date>();
export const changeStartValidDateTo = createEvent<Date>();
export const changeEndValidDateFrom = createEvent<Date>();
export const changeEndValidDateTo = createEvent<Date>();
export const changePublicationDateFrom = createEvent<Date>();
export const changePublicationDateTo = createEvent<Date>();
export const changeParticipantName = createEvent<string>();

export const changePageSize = createEffect({
    name: "changePageSize",
    handler: (payload: { pageSize: number }) => {
        return payload.pageSize;
    }
});
export const resetFilters = createEffect({
    name: "resetFilters",
    handler: () => { }
});

const defaultFilters = {
    kpgz: null,
    purchaseName: null,
    purchaseNumber: null,
    region: null,
    priceMin: null,
    priceMax: null,
    quantityMin: null,
    quantityMax: null,
    startValidDateFrom: null,
    startValidDateTo: null,
    endValidDateFrom: null,
    endValidDateTo: null,
    participantName: null,
    publicationDateFrom: null,
    publicationDateTo: null,
    favorites: false,
    pageSize: 10
};

const createFilterStore = () =>
    createStore<DirectPurchasesFilterStore>(defaultFilters)
        .on(changeKpgz, (state, kpgz) => ({ ...state, kpgz }))
        .on(changePurchaseName, (state, purchaseName) => ({ ...state, purchaseName }))
        .on(changePurchaseNumber, (state, purchaseNumber) => ({ ...state, purchaseNumber }))
        .on(changeRegion, (state, region) => ({ ...state, region }))
        .on(changePriceMin, (state, priceMin) => ({ ...state, priceMin }))
        .on(changePriceMax, (state, priceMax) => ({ ...state, priceMax }))
        .on(changeQuantityMin, (state, quantityMin) => ({ ...state, quantityMin }))
        .on(changeQuantityMax, (state, quantityMax) => ({ ...state, quantityMax }))
        .on(changeStartValidDateFrom, (state, startValidDateFrom) => ({ ...state, startValidDateFrom }))
        .on(changeStartValidDateTo, (state, startValidDateTo) => ({ ...state, startValidDateTo }))
        .on(changeEndValidDateFrom, (state, endValidDateFrom) => ({ ...state, endValidDateFrom }))
        .on(changeEndValidDateTo, (state, endValidDateTo) => ({ ...state, endValidDateTo }))
        .on(changePublicationDateFrom, (state, publicationDateFrom) => ({ ...state, publicationDateFrom }))
        .on(changePublicationDateTo, (state, publicationDateTo) => ({ ...state, publicationDateTo }))
        .on(changeParticipantName, (state, participantName) => ({ ...state, participantName }))
        .on(changeFavorites, (state, favorites) => ({ ...state, favorites }))
        .on(resetFilters, (state) => ({ ...state, ...defaultFilters }))
        .on(resetFilters.done, () => {
            searchPurchases({ page: 0 });
            changeKpgz(null);
        })
        .on(changePageSize, (state, { pageSize }) => ({ ...state, pageSize }))
        .on(changePageSize.done, (state) => {
            searchPurchases({ page: 0 });
            return { ...state };
        });

export const $filters = createFilterStore();

export const loadPurchasesPage = createEffect({
    name: "loadpurchasesPage",
    handler: async (payload: { page: number }) => {
        const filtersState = $filters.getState();
        return await DirectPurchasesController.get({ from: payload.page * filtersState.pageSize, count: filtersState.pageSize }, filtersState.favorites);
    }
});

export const setFavorite = createEffect({
    name: "setFavorite",
    handler: async (payload: { id: string; favorite: boolean }) => {

        return await DirectPurchasesController.setFavorite(
            payload.id, payload.favorite
        );
    }
});

export const toggleTwoColumnMode = createEvent();
export const toggleShowInfo = createEvent();
export const onSelectItem = createEvent<Purchase>();
export const unselectAll = createEvent();
export const goCreate = createEvent();

export type DirectPurchasesStore = {
    isFilterOpened: boolean
    page: PageOfPurchases
    selectedPurchases: Purchase[]
    showInfo: boolean
};

export const searchPurchases = createEffect({
    name: "searchPurchases",
    handler: async (payload: { page: number }) => {
        const filtersState = $filters.getState();
        const filters: IFilterObject = {};

        if (filtersState.kpgz?.object.code) {
            filters["kpgzCode"] = [{
                type: FilterValueType.STRING,
                conditionType: FilterConditionType.STARTS_WITH,
                string: filtersState.kpgz?.object.code
            }];
        }
        if (filtersState.purchaseNumber) {
            filters["offerNumber"] = [{
                type: FilterValueType.STRING,
                conditionType: FilterConditionType.CONTAINS,
                string: filtersState.purchaseNumber
            }];
        }
        if (filtersState.purchaseName) {
            filters["directPurchaseName"] = [{
                type: FilterValueType.STRING,
                conditionType: FilterConditionType.CONTAINS,
                string: filtersState.purchaseName
            }];
        }
        if (filtersState.region) {
            filters["region"] = [{
                type: FilterValueType.STRING,
                conditionType: FilterConditionType.EQUAL,
                string: filtersState.region
            }];
        }
        if (filtersState.startValidDateFrom || filtersState.startValidDateTo) {
            filters["startValidDate"] = [];
            if (filtersState.startValidDateFrom) {
                filters["startValidDate"] = [...filters["startValidDate"], {
                    type: FilterValueType.DATE,
                    conditionType: FilterConditionType.GREATER_THAN_OR_EQUAL,
                    date: filtersState.startValidDateFrom
                }];
            }
            if (filtersState.startValidDateTo) {
                filters["startValidDate"] = [...filters["startValidDate"], {
                    type: FilterValueType.DATE,
                    conditionType: FilterConditionType.LESS_THAN_OR_EQUAL,
                    date: filtersState.startValidDateTo
                }];
            }
        }
        if (filtersState.endValidDateFrom || filtersState.endValidDateTo) {
            filters["endValidDate"] = [];
            if (filtersState.endValidDateFrom) {
                filters["endValidDate"] = [...filters["endValidDate"], {
                    type: FilterValueType.DATE,
                    conditionType: FilterConditionType.GREATER_THAN_OR_EQUAL,
                    date: filtersState.endValidDateFrom
                }];
            }
            if (filtersState.endValidDateTo) {
                filters["endValidDate"] = [...filters["endValidDate"], {
                    type: FilterValueType.DATE,
                    conditionType: FilterConditionType.LESS_THAN_OR_EQUAL,
                    date: filtersState.endValidDateTo
                }];
            }
        }
        if (filtersState.publicationDateFrom || filtersState.publicationDateTo) {
            filters["publicationDate"] = [];
            if (filtersState.publicationDateFrom) {
                filters["publicationDate"] = [...filters["publicationDate"], {
                    type: FilterValueType.DATE,
                    conditionType: FilterConditionType.GREATER_THAN_OR_EQUAL,
                    date: filtersState.publicationDateFrom
                }];
            }
            if (filtersState.publicationDateTo) {
                filters["publicationDate"] = [...filters["publicationDate"], {
                    type: FilterValueType.DATE,
                    conditionType: FilterConditionType.LESS_THAN_OR_EQUAL,
                    date: filtersState.publicationDateTo
                }];
            }
        }
        if (filtersState.priceMin || filtersState.priceMax) {
            filters["priceWithNds"] = [];
            if (filtersState.priceMin) {
                filters["priceWithNds"] = [...filters["priceWithNds"], {
                    type: FilterValueType.DECIMAL,
                    conditionType: FilterConditionType.GREATER_THAN_OR_EQUAL,
                    decimal: new Decimal(filtersState.priceMin)
                }];
            }
            if (filtersState.priceMax) {
                filters["priceWithNds"] = [...filters["priceWithNds"], {
                    type: FilterValueType.DECIMAL,
                    conditionType: FilterConditionType.LESS_THAN_OR_EQUAL,
                    decimal: new Decimal(filtersState.priceMax)
                }];
            }
        }
        if (filtersState.quantityMin) {
            filters["quantityFrom"] = [{
                type: FilterValueType.LONGINT,
                conditionType: FilterConditionType.GREATER_THAN_OR_EQUAL,
                longint: filtersState.quantityMin
            }];
        }
        if (filtersState.quantityMax) {
            filters["quantityTo"] = [{
                type: FilterValueType.LONGINT,
                conditionType: FilterConditionType.LESS_THAN_OR_EQUAL,
                longint: filtersState.quantityMax
            }];
        }
        if (filtersState.participantName) {
            filters["participantShortName"] = [{
                type: FilterValueType.STRING,
                conditionType: FilterConditionType.CONTAINS,
                string: filtersState.participantName
            }];
        }

        return await DirectPurchasesController.get({
            from: payload.page * filtersState.pageSize,
            count: filtersState.pageSize,
            filters: filters
        }, filtersState.favorites);
    }
});


const createDirectPurchasesStore = () =>
    createStore<DirectPurchasesStore>({
        isFilterOpened: false,
        page: {
            totalCount: 0,
            from: 0,
            to: 0,
            items: []
        },
        selectedPurchases: [],
        showInfo: true
    })
        .on(onSelectItem, (state, purchase) => {
            const index = state.selectedPurchases.findIndex(p => p.id === purchase.id);
            if (index === -1) {
                state.selectedPurchases.push(purchase);
            } else {
                state.selectedPurchases.splice(index, 1);
            }
            return { ...state, selectedPurchases: state.selectedPurchases };
        })
        .on(unselectAll, (state) => {
            return { ...state, selectedPurchases: [] };
        })
        .on(goCreate, (state) => {
            const kpgzGroup = firstMaybe(state.selectedPurchases)?.spgz.subjectClass.code.slice(0, 5);
            const isValidKpgz = state.selectedPurchases.every(x => x.spgz.subjectClass.code.slice(0, 5) === kpgzGroup);
            if (!isValidKpgz) {
                showModal(ErrorModal, { text: 'Невозможно создать одну котировочную сессию на основе оферт по разным товарным группам продукции' });
                return;
            }
            router.replace(`/shop/create-session/purchase/${state.selectedPurchases.map(p => p.id).join('&')}`);
            return { ...state };
        })
        .on(loadPurchasesPage.done, (state, { result }) => ({ ...state, page: result }))
        .on(searchPurchases.done, (state, { result }) => ({ ...state, page: result }))
        .on(toggleTwoColumnMode, state => ({ ...state, isFilterOpened: !state.isFilterOpened }))
        .on(toggleShowInfo, state => ({ ...state, showInfo: !state.showInfo }));

export const $directPurchases = createDirectPurchasesStore();


export const changeLotId = createEvent<{ groupKey: string; lotId: string | null }>();
export const changeLaw = createEvent<{ groupKey: string; law: string }>();
export const changeVolume = createEvent<{ purchaseId: string; volume: number }>();
export const loadLots44 = createEffect({
    name: "loadLots44",
    handler: async (payload: {}) => {
        return await SessionsController.getLots({
            from: 0,
            count: 10
        }, 'F44');
    }
});
export const loadLots223 = createEffect({
    name: "loadLots223",
    handler: async (payload: {}) => {
        return await SessionsController.getLots({
            from: 0,
            count: 10
        }, 'F223');
    }
});

type PurchaseLaw = Purchase & { selectedLaw: string };
export const addPurchaseToContract = createEvent<PurchaseLaw[] | null>();

interface DirectPurchase {
    amount: number
    directPurchaseId: string
}

interface CreateContractPayload {
    lotId: string
    law: string
    directPurchases: DirectPurchase[]
    supplierId: number
}

export const createContract = createEffect({
    name: "createContract",
    handler: async (payload: CreateContractPayload) => {
        return await SessionsController.createContractFromDirectPurchase(payload);
    }
});


export interface PurchaseForCreation {
    purchase: PurchaseLaw
    volume: number
}

export type ContractStore = {
    lotsPage44: PageOfLots
    lotsPage223: PageOfLots
    purchases: IGrouppedPurchases[]
};

interface IGrouppedPurchases {
    purchases: PurchaseForCreation[]
    law: string
    lotId: string | null
    key: string
}

const createContractStore = () =>
    createStore<ContractStore>({
        lotsPage44: {
            totalCount: 0,
            from: 0,
            to: 0,
            items: []
        },
        lotsPage223: {
            totalCount: 0,
            from: 0,
            to: 0,
            items: []
        },
        purchases: []
    })
        .on(addPurchaseToContract, (state, purchases) => {
            const grouppedPurchases = _.chain(purchases ? purchases.map(p => ({ purchase: p, volume: 1 })) : [])
                .groupBy(x => x.purchase.participant.id + x.purchase.spgz.subjectClass.code.slice(0, 5) + x.purchase.selectedLaw)
                .map((values, key) => ({ key: key, purchases: values, law: getSameLaw(values.map(v => v.purchase)) ?? 'F44', lotId: null }))
                .value();
            return ({ ...state, purchases: grouppedPurchases });
        })
        .on(changeLaw, (state, { groupKey, law }) => {
            const newPurchases = state.purchases.map(x => {
                if (x.key === groupKey) {
                    return {
                        ...x,
                        law: law,
                        purchases: x.purchases.map(p => {
                            const lawsForPurchase = getAllLaws([p.purchase]);
                            return {
                                ...p, purchase: {
                                    ...p.purchase, selectedLaw: lawsForPurchase.includes(law)
                                        ? law
                                        : firstMaybe(lawsForPurchase) ?? 'F44'
                                }
                            };
                        })
                    };
                }
                return x;
            }).flatMap(p => p.purchases.map(pc => ({...pc, selectedLaw: p.law})));

            const purchases = _.chain(newPurchases.map(p => ({ purchase: p.purchase, volume: p.volume })))
                .groupBy(x => x.purchase.participant.id + x.purchase.spgz.subjectClass.code.slice(0, 5) + x.purchase.selectedLaw)
                .map((values, key) => ({ key: key, purchases: values, law: firstMaybe(values)?.purchase.selectedLaw ?? 'F44', lotId: null }))
                .value();

            return ({ ...state, purchases });
        })
        .on(changeVolume, (state, { purchaseId, volume }) => {
            const grouppedPurchases = state.purchases.map(x => {
                const purchases = x.purchases.map(p => {
                    if (p.purchase.id === purchaseId) {
                        return { ...p, volume: volume };
                    }
                    return p;
                });
                return { ...x, purchases };
            });

            return ({ ...state, purchases: grouppedPurchases });
        })
        .on(changeLotId, (state, { groupKey, lotId }) => {
            const purchases = state.purchases.map(x => {
                if (x.key === groupKey) {
                    return {
                        ...x,
                        lotId: lotId
                    };
                }
                return x;
            });

            return ({ ...state, purchases });
        })
        .on(loadLots44.done, (state, { result }) => ({ ...state, lotsPage44: result }))
        .on(loadLots223.done, (state, { result }) => ({ ...state, lotsPage223: result }));

export const $contracts = createContractStore();

export const getSameLaw = (purchases: Purchase[]) => {
    return purchases.filter(p => p.allowContract223).length === purchases.length ? 'F223' : 'F44';
};

export const getAllLaws = (purchases: Purchase[]) => {
    const laws: string[] = [];
    return laws.concat(purchases.findIndex(p => p.allowContract223) !== -1 ? 'F223' : [])
        .concat(purchases.findIndex(p => p.allowContract44) !== -1 ? 'F44' : []);
};
