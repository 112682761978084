// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, "._2Yq_L6j8UCc9a0LfioP2Dc {\n    display: flex;\n    align-items: center;\n}\n\n.QKdMT3Y87PULXpZiAltBZ {\n    position: relative;\n    display: inline-block;\n    width: 40px;\n    height: 14px;\n    margin: 5px;\n}\n\n._304ph_b5dWL_zed0LU_hk {\n    position: absolute;\n    top: 0;\n    left: 0;\n    right: 0;\n    bottom: 0;\n    transition: 0.3s;\n    cursor: pointer;\n    background: #e1e7eb;\n    border-radius: 30px;\n}\n\n.v9jQ1ojElGqZ03FOex7Jr {\n    cursor: not-allowed;\n}\n\n._1yq9uj6NyBeCZa6wtrNdo9 {\n    opacity: 0;\n    width: 0;\n    height: 0;\n}\n\n._304ph_b5dWL_zed0LU_hk:before {\n    position: absolute;\n    content: \"\";\n    height: 20px;\n    width: 20px;\n    left: -2px;\n    top: -3px;\n    bottom: 0;\n    background-color: #ffffff;\n    border-radius: 50%;\n    transition: 0.3s;\n    box-shadow: 0 2px 2px rgba(0,0,0,.24);\n}\n\n._1yq9uj6NyBeCZa6wtrNdo9:checked + span {\n    background-color: #4db2ff;\n}\n\n._1yq9uj6NyBeCZa6wtrNdo9:checked + span:before {\n    transform: translateX(24px);\n}\n", ""]);
// Exports
exports.locals = {
	"toggleButton__wrapper": "_2Yq_L6j8UCc9a0LfioP2Dc",
	"toggleButton__label": "QKdMT3Y87PULXpZiAltBZ",
	"toggleButton__check": "_304ph_b5dWL_zed0LU_hk",
	"toggleButton__check_disabled": "v9jQ1ojElGqZ03FOex7Jr",
	"toggleButton__input": "_1yq9uj6NyBeCZa6wtrNdo9"
};
module.exports = exports;
