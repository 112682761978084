import {createStoreObject, Store} from "effector";
import {LawType} from "@/models/enums";
import {uuid} from "@/models/parsing";
import {FormAction} from "@/views/ComposedLot/shared/store";
import { getProcedure, getProcedureDocuments, getProcedureHistory, getProcedureLots } from "@/api/Procedures2020";
import {Procedure, ProcedureTemplate} from "@/models/Procedures2020/Procedure";
import {ProcedureEvents, ProcedureInfoSectionStore} from "@/views/Procedures2020/view/store/info";
import {ProcedureHeadStore} from "@/views/Procedures2020/view/store/head";
import {ProcedureLotsEvents, ProcedureLotsSectionStore} from "@/views/Procedures2020/view/store/lots";
import {PaymentOrderStore} from "@/views/Procedures2020/view/store/DocumentsRequestsSection/paymentOrder";
import {DocumentationOrderStore} from "@/views/Procedures2020/view/store/DocumentsRequestsSection/documentationOrder";
import {RequestAcceptanceOrderStore} from "@/views/Procedures2020/view/store/DocumentsRequestsSection/requestAcceptanceOrder";
import Participants from "@/api/Participants";
import {ProcedureRelatedDataEvents, ProcedureRelatedDataStore} from "@/views/Procedures2020/view/store/relatedData";
import {vAssert, vDefined, vNotEmpty} from "@/api/validation";
import {getLotById} from "@/api/ComposedLots";
import {ProcedureDocsSectionStore} from "@/views/Procedures2020/view/store/docs";
import {TimeLimitationsStore} from "@/views/Procedures2020/view/store/timeLimitations";
import Contracts from "@/api/Contracts";
import {ProcedureProtocolsStore} from "@/views/Procedures2020/view/store/protocols";


export interface ProcedureForm {
    head: ProcedureHeadStore
    info: ProcedureInfoSectionStore
    docs: ProcedureDocsSectionStore
    lots: ProcedureLotsSectionStore
    paymentOrder: PaymentOrderStore
    documentationOrder: DocumentationOrderStore
    requestAcceptanceOrder: RequestAcceptanceOrderStore
    relatedDataStore: ProcedureRelatedDataStore
    timeLimitations: TimeLimitationsStore
    protocols: ProcedureProtocolsStore
}

export interface SectionProps {
    formStore: Store<ProcedureForm>
    formAction: FormAction
}
const loadRelatedData = async () => {
    // Специализированные организации по подготовке документации & Специализированные организации по проведению процедуры закупки
    const specialParticipants = await Participants.getSpecial(0, 100).then(specials => specials.items);

    ProcedureRelatedDataEvents.setSpecials(specialParticipants);
};

const loadProcedure = async (id: uuid) => {
    const proc = await getProcedure(id);
    const docs = await getProcedureDocuments(id);
    const lots = await getProcedureLots(id);
    const history = await getProcedureHistory(id);


    ProcedureHeadStore.replace({...proc, history: history});
    ProcedureInfoSectionStore.replace({
        marketplace: proc.marketplace,
        subject: proc.subject,
        providerSelectionType: proc.providerSelectionType,
        procedurePerformer: proc.procedurePerformer?.organization,
        documentationPreparer: proc.documentationPreparer,
        contactPerson: proc.contactPerson,
        decisionNumber: proc.decisionNumber,
        decisionDate: proc.decisionDate,
        isPerformerEqualToDocPreparer: proc.procedurePerformer?.isEqualToDocPreparer ?? false,
    });
    TimeLimitationsStore.replace({publishTerms: proc.publishTerms ?? undefined});
    ProcedureDocsSectionStore.replace({ procedureDocuments: docs });

    PaymentOrderStore.replace(proc.paymentOrder ?? {});
    DocumentationOrderStore.replace(proc.documentationOrder ?? {});
    RequestAcceptanceOrderStore.replace(proc.requestAcceptanceOrder ?? {});

    ProcedureLotsEvents.setLots(lots);

    ProcedureProtocolsStore.setProcedureProtocols(proc.protocols ?? []);

    (async () => {
        if (proc.contractId)
            ProcedureHeadStore.setRelatedContract(await Contracts.getById(proc.contractId));
    })().ignore;
};

export const replaceProcedure = async (proc: Procedure) => {
    const lots = await getProcedureLots(proc.id);


    ProcedureHeadStore.replace(proc);
    ProcedureInfoSectionStore.replace({
        marketplace: proc.marketplace,
        subject: proc.subject,
        providerSelectionType: proc.providerSelectionType,
        procedurePerformer: proc.procedurePerformer?.organization,
        documentationPreparer: proc.documentationPreparer,
        contactPerson: proc.contactPerson,
        decisionNumber: proc.decisionNumber,
        decisionDate: proc.decisionDate,
        isPerformerEqualToDocPreparer: proc.procedurePerformer?.isEqualToDocPreparer ?? false,
    });

    TimeLimitationsStore.replace({publishTerms: proc.publishTerms});
    PaymentOrderStore.replace(proc.paymentOrder ?? {});
    DocumentationOrderStore.replace(proc.documentationOrder ?? {});
    RequestAcceptanceOrderStore.replace(proc.requestAcceptanceOrder ?? {});
    ProcedureProtocolsStore.setProcedureProtocols(proc.protocols ?? []);

    ProcedureLotsEvents.setLots(lots);
};

const loadFromLot = async (lotId: uuid, law: LawType) => {
    const lot = await getLotById(lotId);

    if (lot.law !== law) return;

    ProcedureEvents.setProviderSelectionType(lot.basicInfo.providerSelectionType);
    ProcedureEvents.setSubject(lot.basicInfo.subject);
    ProcedureLotsEvents.addLot(lot);
};

export const createProcedureStore = (law: LawType, id?: uuid, lotId?: uuid): Store<ProcedureForm> => {
    const combined = createStoreObject({
        head: ProcedureHeadStore.createStore(law),
        info: ProcedureInfoSectionStore.createStore(),
        docs: ProcedureDocsSectionStore.createStore(),
        lots: ProcedureLotsSectionStore.createStore(law),
        paymentOrder: PaymentOrderStore.createStore(),
        documentationOrder: DocumentationOrderStore.createStore(),
        requestAcceptanceOrder: RequestAcceptanceOrderStore.createStore(),
        relatedDataStore: ProcedureRelatedDataStore.createStore(),
        timeLimitations: TimeLimitationsStore.createStore(),
        protocols: ProcedureProtocolsStore.createStore()

    });

    ProcedureHeadStore.observeOnCombinedStore(combined);
    ProcedureInfoSectionStore.observeOnCombinedStore(combined);
    ProcedureDocsSectionStore.observeOnCombinedStore(combined);
    ProcedureLotsSectionStore.observeOnCombinedStore(combined);
    PaymentOrderStore.observeOnCombinedStore(combined);
    DocumentationOrderStore.observeOnCombinedStore(combined);
    RequestAcceptanceOrderStore.observeOnCombinedStore(combined);
    ProcedureRelatedDataStore.observeOnCombinedStore(combined);
    TimeLimitationsStore.observeOnCombinedStore(combined);
    ProcedureProtocolsStore.observeOnCombinedStore(combined);

    loadRelatedData().ignore;
    if (id)
        loadProcedure(id).ignore;
    else if (lotId)
        loadFromLot(lotId, law).ignore;

    return combined;
};

export const procedureToTemplate = (state: ProcedureForm): ProcedureTemplate => {
    const asserted = vAssert({
        subject: vNotEmpty(state.info.subject, "Заполните \"Предмет закупки\""),
        providerSelectionType: vDefined(state.info.providerSelectionType, "Заполните \"Способ определения поставщика\""),

        contactPersonEmail: vDefined(state.info.contactPerson?.email, "Заполните \"E-mail ответственного должностного лица\""),
        contactPersonPhoneNumber: vDefined(state.info.contactPerson?.phoneNumber, "Заполните \"Телефон ответственного должностного лица\""),
        contactPersonName: vDefined(state.info.contactPerson?.name, "Заполните \"ФИО ответственного должностного лица\"")
    });

    return {
        subject: asserted.subject,
        providerSelectionType: asserted.providerSelectionType,
        marketplace: state.info.marketplace,
        documentationPreparer: state.info.documentationPreparer,
        procedurePerformer: {
            isEqualToDocPreparer: state.info.isPerformerEqualToDocPreparer,
            organization: state.info.procedurePerformer
        },
        decisionNumber: state.info.decisionNumber,
        decisionDate: state.info.decisionDate,
        procedureDocuments: state.docs.procedureDocuments,
        procedureLotIds: state.lots.lots.map(x => x.lot.id),
        paymentOrder: {
            ...state.paymentOrder
        },
        contactPerson: {
            email: asserted.contactPersonEmail,
            phoneNumber: asserted.contactPersonPhoneNumber,
            name: asserted.contactPersonName,
        }
        // documentationOrder: state.info.documentationOrder,
        // requestAcceptanceOrder: state.info.requestAcceptanceOrder,
        // paymentOrder: state.info.paymentOrder,
        // publishTerms: state.info.publishTerms,
    };
};
