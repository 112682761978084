import { IJsonFormattable } from '@/models/IJsonFormattable';
import { ContractDocument } from './ContractDocument';
import { IDtoContractStageAcceptanceInfo } from '../json/Contracts/IDtoContractStageAcceptanceInfo';
import { Participant } from '../Participant';

export class ContractStageAcceptanceInfo implements IJsonFormattable<IDtoContractStageAcceptanceInfo> {
    constructor(
        public id: string,
        public stageId: string,
        public createdAt: Date,
        public documents: ContractDocument[],
        public violationsDetected: boolean,
        public isExternal: boolean,
        public expert: Participant | null,
        public expertContractNumber: string | null,
    ) {}
    
    public toJson(): IDtoContractStageAcceptanceInfo {
        return {
            id: this.id,
            stageId: this.stageId,
            createdAt: this.createdAt && this.createdAt.toISOString(),
            documents: this.documents.map(x => x.toJson()),
            violationsDetected: this.violationsDetected,
            isExternal: this.isExternal,
            expert: this.expert && this.expert.toJson(),
            expertContractNumber: this.expertContractNumber,
        };
    }
    
    public static fromJson(dto: IDtoContractStageAcceptanceInfo): ContractStageAcceptanceInfo {
        return Object.assign(Object.create(ContractStageAcceptanceInfo.prototype), {
            id: dto.id,
            stageId: dto.stageId,
            createdAt: dto.createdAt && new Date(dto.createdAt),
            documents: dto.documents.map(x => ContractDocument.fromJson(x)),
            violationsDetected: dto.violationsDetected,
            isExternal: dto.isExternal,
            expert: dto.expert && Participant.fromJson(dto.expert),
            expertContractNumber: dto.expertContractNumber,
        });
    }
}