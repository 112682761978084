import React from "react";
import { Card } from "@/components/Card";
import { Column, StoredRemoteDataset, Table, useStoredRemoteDataset } from "@/components/table";
import { MonopolyHistoryItem } from "@/models/Monopoly/MonopolyHistory";
import { formatDate } from "@/dateUtils";
import { MonopolyStatusStrings } from "@/models/Monopoly";
import { Button } from "@/components/primitive";

type HistorySectionProps = {
    historySrd: StoredRemoteDataset<MonopolyHistoryItem>
};

export const HistorySection = ({ historySrd }: HistorySectionProps) => {
    const { dataset } = useStoredRemoteDataset(historySrd);

    const columns: Column<MonopolyHistoryItem>[] = [
        Table.Column(
            "Операция",
            ({ item }) => <>{item.message}</>
        ),
        Table.Column(
            "Пользователь",
            ({ item }) => <>{item.user ? item.user.fullName : ""}</>
        ),
        Table.Column(
            "Дата и время изменения",
            ({ item }) => <>{formatDate(item.date, "time")}</>
        ),
        Table.Column(
            "Статус",
            ({ item }) => <>{MonopolyStatusStrings[item.status]}</>
        ),
        Table.AutoColumn(
            "Действие",
            () => <Button icon="faEye" color="green" />
        ),
        Table.Column(
            "Комментарий",
            ({ item }) => <>{item.commentary}</>
        ),
        Table.Column(
            "Номер версии",
            ({ item }) => <>{item.version}</>
        ),
        Table.Column(
            "Скачать XML",
            () => <>Пакет.xml</>
        )
    ];

    return <Card>
        <Table columns={columns} dataset={dataset} />
    </Card>;
};
