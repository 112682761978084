



















import CardModal from "@/components/CardModal.vue";
import { ModalResult, ModalVue } from "@/view-models/ModalRequest";
import { Component, Prop } from "vue-property-decorator";

@Component({ components: { CardModal } })
export default class AdditionalConclusionConfirmation extends ModalVue<null, null> {
    public ok() {
        this.$emit('close-modal', ModalResult.ok(null));
    }
}
