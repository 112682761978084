// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, "._37tNx8Nblinb46KCrgkFJn {\n    display: flex;\n    box-sizing: border-box;\n    margin: 5px 0;\n    flex-direction: column;\n    font-family: \"Roboto\", sans-serif;\n}\n\n.Yi-FO4thI2x9tfgD72WR8,\n.ShlfUR3SE24GgPMduerDp {\n    margin: 0 !important;\n}\n\n._2KZ871IxtqbOtZQux2bgM2 {\n    border: 1px solid #e1e7eb;\n    border-radius: 5px;\n    display: flex;\n    align-items: center;\n    justify-content: space-between;\n    padding: 0 10px 5px;\n    margin: 0 10px;\n}\n\n._2KZ871IxtqbOtZQux2bgM2:focus-within {\n    border: 1px solid #CBD5DB;\n}\n\n._3ACE7UzG7qb10EJk0o3th7 {\n    background-color: #f5f5f5;\n    cursor: not-allowed;\n}\n\n._3xMaFhZkAq4hkDkbWOkyfP {\n    color: #999999;\n    display: flex;\n    align-items: center;\n    font-size: 14px;\n    margin: 0 10px;\n    padding-left: 5px;\n}\n\n._19ZatAacXpEv2CKi3RH2di {\n    margin: 0;\n    padding-left: 0;\n}\n\n._11xRLzgfYqaFe-QjwwjYYS {\n    border: none;\n    font-size: 16px;\n    background-color: transparent;\n    padding: 5px 0;\n    margin-left: 10px;\n    width: 100%;\n}\n\n._2RttKvPbwDRYVYuN8TzJuu {\n    color: red;\n}\n\n._264cHJIMOjrAQ5nTNv2tP6 {\n    margin: 4px 14px 0;\n    color: #999999;\n    font-size: 14px;\n    letter-spacing: 0;\n}\n\n._11xRLzgfYqaFe-QjwwjYYS:focus {\n    outline: none !important;\n    border: none;\n}\n\n._1n3wzD8IQUgOIvuB8SxD_R{\n    background-image: url(\"https://eaist2-f.mos.ru/eshop/img/icon-close-999.9770e030323c1c0f85a1.svg\");\n    width: 20px;\n    height: 20px;\n    background-size: 20px 20px;\n    background-repeat: no-repeat;\n    cursor: pointer;\n}\n", ""]);
// Exports
exports.locals = {
	"input__wrapper": "_37tNx8Nblinb46KCrgkFJn",
	"input__wrapper_fullWidth": "Yi-FO4thI2x9tfgD72WR8",
	"input__fieldset_fullWidth": "ShlfUR3SE24GgPMduerDp",
	"input__fieldset": "_2KZ871IxtqbOtZQux2bgM2",
	"input__fieldset_disabled": "_3ACE7UzG7qb10EJk0o3th7",
	"input__label": "_3xMaFhZkAq4hkDkbWOkyfP",
	"input__label_disabled": "_19ZatAacXpEv2CKi3RH2di",
	"input__field": "_11xRLzgfYqaFe-QjwwjYYS",
	"required": "_2RttKvPbwDRYVYuN8TzJuu",
	"input__hint": "_264cHJIMOjrAQ5nTNv2tP6",
	"closeButton": "_1n3wzD8IQUgOIvuB8SxD_R"
};
module.exports = exports;
