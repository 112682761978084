<template>
    <div class="card" :style="allowOverflow ? { overflow: 'visible' } : {}">
        <div
            class="card-header"
            :class="[!hasBorder ? 'no-border' : '']"
            :style="customHeaderBottomPadding ? {'paddingBottom': customHeaderBottomPadding} : {}"
            v-if="notNeedHeader === null || notNeedHeader === false"
        >
            <div class="card-header-title" :class="[isLargeHeader ? 'large-header' : '']">
                <div>
                    <slot name="title"/>
                    {{ title }}
                    <span style="color: red" v-if="required">*</span>
                    <img v-if="hint" src="@/assets/question.png" class="question" @click="openHint(hint, $event)" />
                </div>

                <div v-if="hasCloseButton" @click="$emit('close')" class="close-button"></div>

                <x-button
                    v-for="(btn, i) in buttons"
                    :key="'btn-' + i"
                    @click="e => emitEvent(btn.eventName, e)"
                    :title="btn.hintTitle ? '' : btn.title"
                    :icon="btn.icon"
                    :color="btn.color"
                />
            </div>
        </div>
        <div
            :style="{
                'padding': !!padding || padding === 0 ? padding + 'px' : '10px',
                'flexGrow': 1,
                [allowOverflow ? 'overflow' : 'overflowY']: allowOverflow ? 'visible' : 'auto',
                'display': 'flex',
                'flexDirection': 'column',
            }"
        >
            <slot />
        </div>
    </div>
</template>

<script>
import EventBus from "../../EventBus";

export default {
    name: "Card",
    props: {
        title: String,
        padding: Number,
        notNeedHeader: Boolean,
        isLargeHeader: Boolean,
        hasBorder: { type: Boolean, default: true },
        required: { type: Boolean, default: false },
        hasCloseButton: { type: Boolean, default: false },
        hint: Object,
        buttons: Array,
        allowOverflow: { type: Boolean, default: false },
        customHeaderBottomPadding: String,
    },
    methods: {
        openHint(hint, ev) {
            EventBus.$emit("show-hint", hint, ev);
        },

        emitEvent(name, e) {
            this.$emit(name, e);
            EventBus.$emit(name, e);
        },
    },
};
</script>

<style scoped>
.card {
    display: flex;
    flex-direction: column;
    background: white;

    border-radius: 3px;
    border-width: 0 1px 1px;
    border-style: solid;
    border-color: transparent #ccd1de #bbc1d0;
    overflow: hidden;
}

.card + .card {
    margin-top: 15px;
}

.card-header {
    display: flex;
    align-items: center;

    background: #eef1f7;
    border-bottom: 1px solid #dadfea;
    padding: 20px 30px 20px;
}

.card-header.no-border {
    border-bottom: none;
}

.card-header-title {
    font-size: 20px;
    flex-grow: 1;
    display: flex;
}

.card-header-title > :first-child {
    flex-grow: 1;
}

.close-button {
    font-family: "EaistFont";
    padding-top: 3px;
    cursor: pointer;
    user-select: none;
}

.card-header-title.large-header {
    font-size: 24px;
    flex-grow: 1;
}

.question {
    display: inline-block;
    vertical-align: top;
    cursor: pointer;
}

.buttons-wrapper {
    display: flex;
    width: 10%;
}
</style>
