












import { Component } from "vue-property-decorator";
import { MutatingFieldBase } from "@/components/vue/form-table/FieldBase";
import { mixins } from "vue-class-component";

@Component
export default class RadioGroupField extends mixins(MutatingFieldBase) {}
