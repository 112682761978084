import {autoSV, createSVEvent, origEv} from "@/storeUtils";
import {createEvent, createStore, Store} from "effector";
import {LotForm} from "@/views/ComposedLot/shared/store/index";
import {Criteria as Criteria, SubCriteria as Subcriteria} from "@/models/ComposedLots/documents/LotCriteria";
import {replaceOrPush} from "@/arrayUtils";

export interface LotCriteria {
    criteria: Criteria[]
    assessmentOrder?: string
}

export const setCriteria = createSVEvent<LotCriteria>()("criteria");
export const setAssessmentOrder = createSVEvent<LotCriteria>()("assessmentOrder");
export const addOrEditCriteria = createEvent<{ oldV?: Criteria; newV: Criteria }>("add sub criteria");

const replace = createEvent<LotCriteria>("replace additional info");

export const CriteriaStore = {
    createStore: (): Store<LotCriteria> => {
        return createStore<LotCriteria>({
            criteria: [],
        })
            .on(replace, (_, l) => l)
            .on(setCriteria[origEv], autoSV)
            .on(setAssessmentOrder[origEv], autoSV)
            .on(addOrEditCriteria, ((state, payload) => {
                return ({...state, criteria: replaceOrPush(state.criteria, payload.oldV, payload.newV)});

            }));
    },

    replace: replace,
    observeOnCombinedStore: (combinedStore: Store<LotForm>) => {
    }
};


export const addOrReplaceSubCriteria = (parent: Criteria, newSub: Subcriteria, oldSub?: Subcriteria): Criteria => {
    return ({...parent, subcriteria: replaceOrPush(parent.subcriteria, oldSub, newSub)});
};

export const removeSubCriteria = (parent: Criteria, remove: Subcriteria): Criteria => {
    const filter = parent.subcriteria.filter(a => a !== remove);
    return ({...parent, subcriteria: filter});
};