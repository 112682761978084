import { http } from "./http";
import { ContractDocument, ContractDocumentType } from '@/models/Contracts';
import { IDtoContractDocument, IDtoContractDocumentType } from '@/models/json/Contracts';

export default class {
    public static async getFromContract(contractId: string): Promise<ContractDocument[]> {
        return (await http.get(`/api/contractDocuments/fromContract/${contractId}`, undefined) as IDtoContractDocument[])
            .map(ContractDocument.fromJson);
    }

    public static async create(contractId: string, src: ContractDocument): Promise<ContractDocument> {
        return ContractDocument.fromJson(await http.post(`/api/contractDocuments/createFor/${contractId}`, src.toJson()));
    }

    public static async update(obj: ContractDocument): Promise<ContractDocument> {
        return ContractDocument.fromJson(await http.put(`/api/contractDocuments/${obj.id}`, obj.toJson()));
    }

    public static async delete(obj: ContractDocument): Promise<void> {
        await http.delete(`/api/contractDocuments/${obj.id}`);
    }

    public static async getTypes(): Promise<ContractDocumentType[]> {
        return (await http.get(`/api/contractDocuments/types`, undefined) as IDtoContractDocumentType[])
            .map(ContractDocumentType.fromJson);
    }
}
