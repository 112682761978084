import { AutoDto, AutoModel, MDMap } from "@/models/parsing";
import { User } from "@/models/User";
import { IDtoUser } from "@/models/json/IDtoUser";

interface HistoryItemMD<Status extends string = string> {
    date: Date
    message: string
    ownerTag: string
    status: Status
    user: MDMap<User, IDtoUser>
}

export type HistoryItem<Status extends string = string> = AutoModel<HistoryItemMD<Status>>;
export type DtoHistoryItem<Status extends string = string> = AutoDto<HistoryItemMD<Status>>;

export const HistoryItem = {
    fromDto<Status extends string = string>(dtoHistoryItem: DtoHistoryItem<Status>): HistoryItem<Status> {
        return {
            ...dtoHistoryItem,
            date: new Date(dtoHistoryItem.date),
            user: User.fromJson(dtoHistoryItem.user),
            // Modelize can't parse generic types
            status: dtoHistoryItem.status as unknown as HistoryItem<Status>["status"]
        };
    }
};
