import React, {useState} from "react";
import styles from "./ToggleButton.module.css";
import {j} from "@/reactUtils";

interface ToggleButtonProps {
    toggled?: boolean
    onClick?: () => void
    caption?: string
    disabled?: boolean
}

export const ToggleButton = (props: ToggleButtonProps) => {

    return (
        <div className={styles.toggleButton__wrapper}>
            <label className={styles.toggleButton__label}>
                <input className={styles.toggleButton__input} checked={props.toggled} disabled={props.disabled} type="checkbox" onClick={() => !props.disabled && props.onClick?.()} />
                <span className={j(styles.toggleButton__check, props.disabled && styles.toggleButton__check_disabled)}></span>
            </label>
            <div>{props.caption}</div>
        </div>
    );
};
