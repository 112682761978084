

















































































































import { Component, Vue, Prop } from "vue-property-decorator";
import FormTable from "@/components/vue/FormTable.vue";
import CardModal from "@/components/CardModal.vue";
import { makeFormHelpers } from "@/form-declarations/helpers";
import { IColumn, TextAlignment } from "@/components/vue/form-table/FormFields";
import { Decimal } from "decimal.js";
import { ContractExecutionProvision, ContractDocument, ContractDocumentType } from '../../models/Contracts';
import { ChargeSource, ContractDocumentStatus, ChargeSourceStrings, PledgeSourceStrings } from '../../models/enums/Contracts';
import { toSelectOptions, CurrencyStrings, ObjectStatusStrings } from '../../models/enums';
import { File } from '../../models/Documents';
import ContractDocumentModal from '../modals/ContractDocumentModal';
import EventBus from '../../EventBus';
import { ModalResult, ModalVue } from '../../view-models/ModalRequest';
import ContractExecutionProvisions from '@/api/ContractExecutionProvisions';
import { DateTimeRange } from '../../models';
import {ContractGuaranteeProvision} from "@/models/Contracts/ContractGuaranteeProvision";
import ContractGuaranteeProvisions from "@/api/ContractGuaranteeProvisions";

const h = makeFormHelpers<ContractGuaranteeProvision>();

@Component({ components: { FormTable, CardModal } })
export default class AddGuaranteeProvision extends Vue {
    @Prop() private prop!: { src: ContractGuaranteeProvision | null; contractId: string };

    public source = new ContractGuaranteeProvision(
        this.prop.src ? this.prop.src.id : null!,
        this.prop.src ? this.prop.src.createdAt : null!,
        this.prop.src ? this.prop.src.source : null!, 
        this.prop.src ? this.prop.src.pledgeSource : null!, 
        this.prop.src ? this.prop.src.status : null!, 
        this.prop.src ? this.prop.src.amount : null!, 
        this.prop.src ? this.prop.src.amountRub : null!, 
        this.prop.src ? this.prop.src.currency : null!, 
        this.prop.src ? this.prop.src.documentNumber : null!,
        this.prop.src ? this.prop.src.documentDate : null!,
        this.prop.src ? this.prop.src.documentFile && File.fromJson(this.prop.src.documentFile.toJson()) : null!,

        this.prop.src ? this.prop.src.duration : null,
        this.prop.src ? this.prop.src.guaranteeDuration : null,
        this.prop.src ? this.prop.src.guaranteeServiceRequirementsInfo : null,
        this.prop.src ? this.prop.src.regNumber : null,
        this.prop.src ? this.prop.src.bin : null,
        this.prop.src ? this.prop.src.orgName : null,
        this.prop.src ? this.prop.src.tin : null,
        this.prop.src ? this.prop.src.orgAddress : null);
        
    public chargeSourceOptions = 
        toSelectOptions(ChargeSourceStrings, true, "Выберите вид залога");

    public pledgeSourceOptions = 
        toSelectOptions(PledgeSourceStrings, true, "Выберите вид обеспечения");

    public currencyOptions = 
        toSelectOptions(CurrencyStrings, true, "Выберите валюту");

    public setDuration(from: Date, to: Date) {
        this.source.duration = new DateTimeRange(from, to);
    }

    public setGuaranteeDuration(from: Date, to: Date) {
        this.source.guaranteeDuration = new DateTimeRange(from, to);
    }

    public get status() {
        return this.source.status && ObjectStatusStrings[this.source.status];
    }

    public async pickDocument() {
        const result = await EventBus.callModal<File | null, File>(ContractDocumentModal, this.source.documentFile);
        if (result.isOk) {
            this.source.documentFile = result.getResult();
        }
    }

    public get isOk() {
        return !!this.source.source &&
            !!this.source.amount && this.source.amount.gt(0) &&
            (this.source.currency === 'RUB' || (!!this.source.amountRub && this.source.amountRub.gt(0))) &&
            !!this.source.currency &&
            !!this.source.documentNumber &&
            !!this.source.documentDate &&
            !!this.source.documentFile &&
            (this.source.source === ChargeSource.PLEDGE
                ? !!this.source.pledgeSource
                : !!this.source.duration &&
                    !!this.source.duration.from &&
                    !!this.source.duration.to &&
                    !!this.source.bin &&
                    !!this.source.tin &&
                    !!this.source.orgName &&
                    !!this.source.orgAddress);
    }

    public loading = false;

    public async saveAndClose() {
        if (this.isOk) {
            this.loading = true;

            if (this.source.currency === 'RUB')
                this.source.amountRub = this.source.amount;

            try {
                const src = 
                    this.source.id
                        ? await ContractGuaranteeProvisions.update(this.source)
                        : await ContractGuaranteeProvisions.create(this.prop.contractId, this.source);
                
                this.$emit("close-modal", ModalResult.ok(src));
            } finally {
                this.loading = false;
            }
        }
    }
}
