import {autoSV, createSVEvent, origEv} from "@/storeUtils";
import {createEvent, createStore, Store} from "effector";
import {LotForm} from "@/views/ComposedLot/shared/store/index";
import {replaceOrPush} from "@/arrayUtils";
import {LotRequestDocument} from "@/models/ComposedLots/documents";
import {LotDocument} from "@/models/ComposedLots/documents/LotDocument";

export interface LotDocuments {
    lotRequestDocuments: LotRequestDocument[]
    lotDocuments: LotDocument[]
}

export const setLotRequestDocuments = createSVEvent<LotDocuments>()("lotRequestDocuments");
export const addOrEditLotRequestDocument = createEvent<{ oldV?: LotRequestDocument; newV: LotRequestDocument }>("add or edit lotRequestDocument");
export const removeLotRequestDocument = createEvent<LotRequestDocument>("opt lotRequestDocument out");
export const resetLotRequestDocuments = createEvent<void>("reset lot request documents");

export const addOrEditLotDocument = createEvent<{ oldV?: LotDocument; newV: LotDocument }>("add or edit lotDocument");
export const addLotRequestDocument = createEvent<LotRequestDocument>("Add lotRequestDocument");
export const removeLotDocument = createEvent<LotDocument>("remove lot document");

const replace = createEvent<LotDocuments>("replace additional info");

export const DocumentsStore = {
    createStore: (): Store<LotDocuments> => {
        return createStore<LotDocuments>({
            lotRequestDocuments: [],
            lotDocuments: []
        })
            .on(replace, (_, l) => l)
            .on(setLotRequestDocuments[origEv], autoSV)
            .on(addOrEditLotRequestDocument, (state: LotDocuments, payload) => ({
                ...state,
                lotRequestDocuments: replaceOrPush(state.lotRequestDocuments, payload.oldV, payload.newV)
            }))
            .on(removeLotRequestDocument, (state: LotDocuments, payload: LotRequestDocument) => {
                return {
                    ...state,
                    lotRequestDocuments: state.lotRequestDocuments.filter(a => a !== payload)
                };
            })
            .on(resetLotRequestDocuments, (state => ({...state, lotRequestDocuments: []})))
            .on(removeLotDocument, (state, payload) => (
                {
                    ...state,
                    lotDocuments: state.lotDocuments.filter(a => a !== payload)
                }))
            .on(addOrEditLotDocument, (state, payload) => ({
                    ...state,
                    lotDocuments: replaceOrPush(state.lotDocuments, payload.oldV, payload.newV)
            }))
            .on(addLotRequestDocument, (state, requestDocument) => ({
                ...state,
                lotRequestDocuments: [...state.lotRequestDocuments, requestDocument]
            }));
    },

    replace: replace,
    observeOnCombinedStore: (combinedStore: Store<LotForm>) => {}
};