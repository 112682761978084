import { ContractDocument, ContractStage } from "@/models/Contracts";
import { ChargeSource, ChargeType, ClaimWorkInitiator, ClaimWorkReason } from "@/models/enums/Contracts";
import { IJsonFormattable } from "@/models/IJsonFormattable";
import { IDtoContractClaimWork } from "@/models/json/Contracts";
import { Decimal } from "decimal.js";
import { ObjectStatus } from '../enums';

export class ContractClaimWork implements IJsonFormattable<IDtoContractClaimWork> {
    constructor(
        public id: string,
        public status: ObjectStatus,
        public initiator: ClaimWorkInitiator,
        public startDate: Date,
        public stage: ContractStage,
        public chargeType: ChargeType,
        public chargeSource: ChargeSource,
        public reason: ClaimWorkReason,
        public assessedCharge: Decimal,
        public endDate: Date | null,
        public paidCharge: Decimal | null,
        public documents: Array<ContractDocument>,
    ) {}

    public static empty(): ContractClaimWork {
        return new ContractClaimWork(
            null!,
            ObjectStatus.CREATING,
            ClaimWorkInitiator.CUSTOMER,
            null!,
            null!,
            null!,
            ChargeSource.NONE,
            null!,
            null!,
            null!,
            null!,
            [],
        );
    }

    public toJson(): IDtoContractClaimWork {
        return {
            id: this.id,
            status: this.status,
            initiator: this.initiator,
            startDate: this.startDate.toISOString(),
            stage: this.stage.toJson(),
            chargeType: this.chargeType,
            chargeSource: this.chargeSource,
            reason: this.reason,
            assessedCharge: this.assessedCharge.toString(),
            endDate: this.endDate && this.endDate.toISOString(),
            paidCharge: this.paidCharge && this.paidCharge.toString(),
            documents: this.documents.map(x => x.toJson()),
        };
    }

    public static fromJson(dto: IDtoContractClaimWork): ContractClaimWork {
        return Object.assign(Object.create(ContractClaimWork.prototype), {
            id: dto.id,
            status: dto.status,
            initiator: dto.initiator,
            startDate: new Date(dto.startDate),
            stage: ContractStage.fromJson(dto.stage),
            chargeType: dto.chargeType,
            chargeSource: dto.chargeSource,
            reason: dto.reason,
            assessedCharge: new Decimal(dto.assessedCharge),
            endDate: dto.endDate && new Date(dto.endDate),
            paidCharge: dto.paidCharge && new Decimal(dto.paidCharge),
            documents: dto.documents.map(x => ContractDocument.fromJson(x)),
        });
    }
}
