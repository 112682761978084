import { http } from "./http";
import { ContractEconomy } from '@/models/Contracts';
import { IDtoContractEconomy } from '@/models/json/Contracts';
import { ContractSpecification } from '@/models/Contracts/ContractSpecification';
import { ContractSpecificationSupplyStage } from '@/models/Contracts/ContractSpecificationSupplyStage';

export default class {
    // public static async getFromContract(contractId: string): Promise<ContractEconomy[]> {
    //     return (await http.get(`/api/contractEconomies/fromContract/${contractId}`, undefined) as IDtoContractEconomy[])
    //         .map(ContractEconomy.fromJson)
    // }

    public static async clone(specificationId: string): Promise<ContractSpecification> {
        return ContractSpecification.fromJson(await http.post(`/api/contractSpecifications/${specificationId}/clone`, undefined));
    }

    public static async update(obj: ContractSpecification): Promise<ContractSpecification> {
        return ContractSpecification.fromJson(await http.put(`/api/contractSpecifications/${obj.id}`, obj.toJson()));
    }

    public static async delete(obj: ContractSpecification): Promise<void> {
        await http.delete(`/api/contractSpecifications/${obj.id}`, "Удаление невозможно");
    }

    public static async createStage(specificationId: string, stage: ContractSpecificationSupplyStage): Promise<ContractSpecificationSupplyStage> {
        return ContractSpecificationSupplyStage.fromJson(await http.post(`/api/contractSpecifications/${specificationId}/newStage`, stage.toJson()));
    }

    public static async updateStage(obj: ContractSpecificationSupplyStage): Promise<ContractSpecificationSupplyStage> {        
        return ContractSpecificationSupplyStage.fromJson(await http.put(`/api/contractSpecifications/${obj.specificationId}/stage/${obj.id}`, obj.toJson()));
    }

    public static async deleteStage(obj: ContractSpecificationSupplyStage): Promise<void> {
        await http.delete(`/api/contractSpecifications/${obj.specificationId}/stage/${obj.id}`);
    }

    public static async setTaxForAll(contractId: string, tax: string/* todo */): Promise<void> {
        await http.put(`/api/contractSpecifications/fromContract/${contractId}/tax/${tax}`, undefined);
    }

    public static async setSupplyStagesForAllSpecifications(contractId: string, stage: ContractSpecificationSupplyStage): Promise<ContractSpecification[]> {
        return (await http.put(`/api/contractSpecifications/fromContract/${contractId}/stages/`, stage.toJson())).map(ContractSpecification.fromJson);
    }
}
