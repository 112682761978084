import {AutoDto, AutoModel, uuid} from "@/models/parsing";

interface LotLegalActMD {
    id: string
    number: string
    name: string
    date: Date
    type: string
    creator: string
}

export type DtoLotLegalAct = AutoDto<LotLegalActMD>;
export type LotLegalAct = AutoModel<LotLegalActMD>;

export const LotLegalAct = {
    toDto(act: LotLegalAct): DtoLotLegalAct {
        return {
            ...act,
            date: act.date.toISOString()
        };
    },
    fromDto(act: DtoLotLegalAct): LotLegalAct {
        return {
            ...act,
            date: new Date(act.date)
        };
    },
    toTemplate(act: LotLegalAct): LotLegalActTemplate {
        return {
            id: act.id,
        };
    }
};

interface LotLegalActTemplateMD {
    id: string
}

export type DtoLotLegalActTemplate = AutoDto<LotLegalActTemplateMD>;
export type LotLegalActTemplate = AutoModel<LotLegalActTemplateMD>;

export const LotLegalActTemplate = {
    toDto(act: LotLegalActTemplate): DtoLotLegalActTemplate {
        return {
            ...act
        };
    },
    fromDto(act: DtoLotLegalActTemplate): LotLegalActTemplate {
        return {
            ...act
        };
    }
};

interface LotLegalActTypeMD {
    id: uuid
    type: string
}

export type LotLegalActType = AutoModel<LotLegalActMD>;
export type DtoLotLegalActType = AutoModel<LotLegalActMD>;

export const LotLegalActType = {
    toDto(type: LotLegalActType) {
        return {
            ...type
        };
    },
    fromDto(type: DtoLotLegalActType) {
        return {
            ...type
        };
    }
};
