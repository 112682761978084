




import {Component, Prop, Vue} from "vue-property-decorator";
import {createSubjectClassStore} from "@/views/ReferenceInfo/SubjectClasses/list/store";
import {SubjectClasses} from "@/views/ReferenceInfo/SubjectClasses/list/SubjectClasses";
import {SubjectClassView} from "@/views/ReferenceInfo/SubjectClasses/sections/SubjectClassView";

@Component({ components: { komponent: SubjectClassView as unknown as typeof Vue } })
export default class SubjectClassesViewStub extends Vue {
    @Prop() public id!: string;
}
