import * as t from "io-ts";
import { http2 as http, IPagedRequest } from './http';
import { handleCodecErrors, handleErrors, pagedCodec } from './httpHelpers';
import {ParticipantStatus} from "@/models/enums";

export const subjectClassParent = t.type({
    code: t.string,
    name: t.string,
    id: t.number,
});

export const subjectClass = t.type({
    code: t.string,
    description: t.string,
    id: t.number,
    parents: t.array(subjectClassParent)
});

const measurementUnits = t.type({
    id: t.number,
    name: t.string
});

export const participant = t.type({
    id: t.union([t.null, t.number]),
    shortName: t.union([t.string, t.null]),
    fullName: t.union([t.string, t.null]),
    isSpecial: t.union([t.null, t.boolean]),
    tin: t.union([t.null, t.string]),
    rrc: t.union([t.null, t.string]),
    regNumber: t.union([t.null, t.string]),
    status: t.union([t.null, t.string]),
    isSmall: t.union([t.null, t.boolean]),
    isSocial: t.union([t.null, t.boolean]),
    address: t.union([t.null, t.string, t.undefined]),
    isSmp: t.union([t.null, t.boolean, t.undefined])


});

// public id: number,
//     public fullName: string,
//     public shortName: string,
//     public isSpecial: boolean,
//     public tin: string,
//     public rrc: string,
//     public regNumber: string,
//     public status: ParticipantStatus,
//     public isSmall: boolean,
//     public isSocial: boolean,
//     public address?: string,
//     public isSmp?: boolean

export const supplier = t.type({
    status: t.union([t.literal('WINNER'), t.literal('PARTICIPANT')]),
    participant: participant
});

export const spgz = t.type({
    id: t.number,
    subject: t.string,
    measurementUnits: t.array(measurementUnits),
    subjectClass
});

export const directPurchase = t.type({
    isFavorite: t.union([t.undefined, t.null, t.boolean]),
    id: t.string,
    name: t.string,
    spgz,
    participant,
    startValidDate: t.string,
    endValidDate: t.string,
    packageUnit: t.union([t.undefined, t.null, t.string]),
    quantityFrom: t.number,
    quantityTo: t.union([t.undefined, t.null, t.number]),
    priceWithNds: t.number,
    offerId: t.union([t.undefined, t.null, t.number]),
    offerNumber: t.union([t.string, t.null]),
    allowContract44: t.boolean,
    allowContract223: t.boolean
});

const pageOfPurchasesCodec = pagedCodec(directPurchase);

const pageOfUnknownCodec = pagedCodec(t.unknown);

export type Purchase = t.TypeOf<typeof directPurchase>;

export type PageOfPurchases = t.TypeOf<typeof pageOfPurchasesCodec>;

export type PageOfUnknown = t.TypeOf<typeof pageOfUnknownCodec>;

export type ParticipantShort = t.TypeOf<typeof participant>;

export class DirectPurchasesController {
    public static async get(request: IPagedRequest, favorite: boolean) {
        return await http.getPagedWithFavorite("/api/v2/directPurchase", request, favorite)
            .then(handleErrors)
            .then(result => pageOfPurchasesCodec.decode(result))
            .then(handleCodecErrors);
    }
    public static async getDirectPurchase(id: string) {
        return await http.get(`/api/v2/directPurchase/${id}`)
            .then(handleErrors)
            .then(result => directPurchase.decode(result))
            .then(handleCodecErrors);
    }
    public static async setFavorite(id: string, favorite: boolean) {
        return await http.patch(`/api/v2/directPurchase/${id}?isFavorite=${favorite ? 'true' : 'false'}`)
            .then(handleErrors)
            .then(result => pageOfPurchasesCodec.decode(result))
            .then(handleCodecErrors);
    }
}
