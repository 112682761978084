import React, {CSSProperties, MouseEvent} from "react";
import * as styles from "./Button.module.css";
import * as faIcons from "@fortawesome/free-solid-svg-icons";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {IconProp} from "@fortawesome/fontawesome-svg-core";
import {assetIcons, colors, glyphIcons} from "./Button.icons";

type FaOnlyIcons = keyof typeof faIcons;
type IconName = keyof typeof glyphIcons | FaOnlyIcons | keyof typeof assetIcons;

interface ButtonProps {
    onClick?: (e: MouseEvent) => void

    color?: keyof typeof colors
    title?: string
    icon?: IconName
    disabled?: boolean
    loading?: boolean

    style?: CSSProperties
    className?: string
}

const isGlyph = (name: IconName): name is keyof typeof glyphIcons => glyphIcons.hasOwnProperty(name);
const isFa = (name: IconName): name is FaOnlyIcons => Reflect.has(faIcons, name);
const isAsset = (name: IconName): name is keyof typeof assetIcons => assetIcons.hasOwnProperty(name);

const createColorStyle = (colorName: keyof typeof colors) => {
    const color = colors[colorName];
    return {
        "--idleBg": color.idle.bg,
        "--idleBorder": color.idle.border,
        "--activeBg": color.active.bg,
        "--activeBorder": color.active.border,
        "--hoverBg": color.hover.bg,
        "--hoverBorder": color.hover.border,
        "color": color.text
    } as unknown as CSSProperties;
};

const createAssetIconStyle = (icon: keyof typeof assetIcons) => {
    const assetIcon = assetIcons[icon];
    return {
        backgroundImage: `url(${assetIcon.asset})`,
        backgroundPosition: assetIcon.pos,
        backgroundSize: assetIcon.size,
        backgroundRepeat: "no-repeat"
    };
};

const createGlyphIconStyle = (icon: keyof typeof glyphIcons) => {
    return {
        fontFamily: glyphIcons[icon].font,
        fontSize: glyphIcons[icon].size,
        marginLeft: glyphIcons[icon].margin,
        marginRight: glyphIcons[icon].margin
    };
};

const Icon: React.FC<ButtonProps & { inTitle?: boolean }> = ({icon, title, inTitle}) => {
    if (!icon)
        return inTitle ? <div className={styles.noIcon}/> : null;

    if (inTitle) {
        if (isAsset(icon))
            return <div
                style={createAssetIconStyle(icon)}
                className={styles.assetHost} />;

        return null;
    }

    if (isFa(icon)) {
        const className = title ? styles.fontIcon : styles.fontIconSingle;
        return <FontAwesomeIcon className={className} icon={faIcons[icon] as IconProp} />;
    } else if (isGlyph(icon)) {
        return <div className={title ? styles.glyphWrapper : null}>
            <div className={styles.glyph} style={createGlyphIconStyle(icon)}>
                {glyphIcons[icon].char}
            </div>
        </div>;
    }

    return null;
};

export const Button: React.FC<ButtonProps> = x => {
    const realIcon: IconName | undefined = x.loading ? "aLoad" : x.icon;
    const realColor: keyof typeof colors = x.loading ? "white" : x.color ?? "blue";

    return <div
        onClick={x.disabled ? undefined : x.onClick}
        style={{...createColorStyle(realColor), ...x.style}}
        className={
            [
                styles.button,
                x.icon && !x.title ? styles.noPadding : null,
                x.disabled || x.loading ? styles.disabled : null,
                x.className ?? ""
            ].join(" ")
        }>
        <Icon {...x} icon={realIcon} />
        <div className={styles.textHost}>
            <Icon {...x} icon={realIcon} inTitle />
            { x.title || " " }
        </div>
    </div>;
};
