import React, {ChangeEvent} from "react";
import {File} from "@/models/ComposedLots/documents/File";

interface FileInputProps {
    value?: string
    onChange: (a: File) => void
    disabled?: boolean
    accept?: string
}

export const FileInput: React.FC<FileInputProps> = x => {
    const select = (e: ChangeEvent<HTMLInputElement>) => {
        if (e.currentTarget.files && e.currentTarget.files.length > 0) {
            const file = e.currentTarget.files[0];
            x.onChange({id: undefined!, length: file.size, name: file.name, comment: file.name});
        }
    };

    return <div style={{ position: "relative" }}>
        <button>Выбрать файл</button>
        <span>{x.value}</span>
        <input type={"file"} onChange={select} accept={x.accept} disabled={x.disabled}
               style={{ position: "absolute", top: 0, left: 0, right: 0, bottom: 0, opacity: 0 }}/>
    </div>;
};
