import {asDate, asDecimal, AutoDto, AutoModel, MDMap, uuid} from "@/models/parsing";
import {IDtoParticipant} from "@/models/json";
import {Participant} from "@/models";
import Decimal from "decimal.js";

export const enum ProcedureParticipantStatus {
    PARTICIPANT = "PARTICIPANT",
    REQUIREMENTS_FAILED = "REQUIREMENTS_FAILED",
    WINNER = "WINNER",
    WINNER_CONTRACT_PENDING = "WINNER_CONTRACT_PENDING",
    WINNER_CONTRACT_DONE = "WINNER_CONTRACT_DONE",
    REQUIREMENTS_SUCCESSFUL = "REQUIREMENTS_SUCCESSFUL"
}

interface ProcedureParticipantMD {
    procedureId: uuid
    participant: MDMap<Participant, IDtoParticipant>
    status: ProcedureParticipantStatus
    registrationDate: Date
    price: Decimal
    orderNumber: number
    id: uuid
}

export type ProcedureParticipant = AutoModel<ProcedureParticipantMD>;
export type DtoProcedureParticipant = AutoDto<ProcedureParticipantMD>;

export const ProcedureParticipant = {
    fromDto(pp: DtoProcedureParticipant): ProcedureParticipant {
        return {
            ...pp,
            participant: Participant.fromJson(pp.participant),
            price: asDecimal(pp.price),
            registrationDate: asDate(pp.registrationDate)
        };
    },
};
