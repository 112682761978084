import {UnionStrings} from "@/models/enums";

export type SpecialPurchaseType =
    | "NO"
    | "SMALL_VOLUME_SIMPLE"
    | "SMALL_VOLUME_CARE"
    | "MEDICINES"
    | "BUILDING_MAINTENANCE"
    | "BUSINESS_TRIPS"
    | "TEACHING_SERVICE"
    | "SPORT_SERVICE"
    | "SPECIAL_SERVICE";

export const SpecialPurchaseTypeStrings: UnionStrings<SpecialPurchaseType> = {
    NO: "Нет",
    SMALL_VOLUME_SIMPLE: "п.4 ч.1 ст.93 (закупка малого объема)",
    SMALL_VOLUME_CARE: "п.5 ч.1 ст.93 (для учреждений культуры, спорта, образования)",
    MEDICINES: "п.7 ч.2 ст.83, п.3 ч.2 ст.83.1 (лекарственные препараты)",
    BUILDING_MAINTENANCE: "п.23 ч.1 ст.93 (содержание и ремонт помещений)",
    BUSINESS_TRIPS: "п.26 ч.1 ст.93 (направление работника в служебную командировку)",
    TEACHING_SERVICE: "п.33 ч.1 ст.93 (преподавательские услуги, услуги экскурсовода (гида))",
    SPORT_SERVICE: "ч.12 ст.93 (по п. 5 ч.1 ст. 93)",
    SPECIAL_SERVICE: "ч.12 ст.93 (по п. 4 ч.1 ст. 93)"
};

export type SendDocumentsTermType =
    | "WORK_30_DAYS"
    | "WORK_3_DAYS";

export const SendDocumentsTermTypeStrings: UnionStrings<SendDocumentsTermType> = {
    WORK_3_DAYS: "в течение 3 рабочих дней",
    WORK_30_DAYS: "в течение 30 рабочих дней"
};

export type ReviewDocumentsTermType =
    | "WORK_30_DAYS"
    | "NORMAL_3_DAYS";

export const ReviewDocumentsTermTypeStrings: UnionStrings<ReviewDocumentsTermType> = {
    WORK_30_DAYS: "30 рабочих дней",
    NORMAL_3_DAYS: "3 календарных дня"
};

export type PaymentTermType =
    | "WORK_9_DAYS"
    | "WORK_7_DAYS";

export const PaymentTermTypeStrings: UnionStrings<PaymentTermType> = {
    WORK_7_DAYS: "7 рабочих дней",
    WORK_9_DAYS: "9 рабочих дней"
};

export type SpecificationSendDocumentsTermType =
    | "NORMAL_6_DAYS"
    | "WORK_5_DAYS"
    | "WORK_7_DAYS"
    | "WORK_9_DAYS"
    | "WORK_8_DAYS"
    | "WORK_20_DAYS_FROM_MONTH_START";

export const SpecificationSendDocumentsTermTypeStrings: UnionStrings<SpecificationSendDocumentsTermType> = {
    NORMAL_6_DAYS: "6 календарных дней",
    WORK_5_DAYS: "5 рабочих дней",
    WORK_7_DAYS: "7 рабочих дней",
    WORK_9_DAYS: "9 рабочих дней",
    WORK_8_DAYS: "8 рабочих дней",
    WORK_20_DAYS_FROM_MONTH_START: "в течение 20 рабочих дней с начала текущего месяца"
};

export type SpecificationReviewDocumentsTermType =
    | "NORMAL_6_DAYS"
    | "WORK_5_DAYS"
    | "WORK_7_DAYS"
    | "WORK_9_DAYS"
    | "WORK_4_DAYS"
    | "WORK_8_DAYS"
    | "WORK_DURING_1_DAY";

export const SpecificationReviewDocumentsTermTypeStrings: UnionStrings<SpecificationReviewDocumentsTermType> = {
    NORMAL_6_DAYS: "6 календарных дней",
    WORK_5_DAYS: "5 рабочих дней",
    WORK_7_DAYS: "7 рабочих дней",
    WORK_9_DAYS: "9 рабочих дней",
    WORK_4_DAYS: "4 (четырех) рабочих дней ",
    WORK_8_DAYS: "8 рабочих дней",
    WORK_DURING_1_DAY: "в течение 1 рабочего дня"
};

export type SpecificationPaymentTermType =
    | "NORMAL_6_DAYS"
    | "WORK_5_DAYS"
    | "WORK_7_DAYS"
    | "WORK_9_DAYS"
    | "WORK_2_DAYS"
    | "WORK_8_DAYS"
    | "WORK_DURING_15_DAYS";

export const SpecificationPaymentTermTypeStrings: UnionStrings<SpecificationPaymentTermType> = {
    NORMAL_6_DAYS: "6 календарных дней",
    WORK_5_DAYS: "5 рабочих дней",
    WORK_7_DAYS: "7 рабочих дней",
    WORK_9_DAYS: "9 рабочих дней",
    WORK_2_DAYS: "2 (двух) рабочих дней",
    WORK_8_DAYS: "8 рабочих дней",
    WORK_DURING_15_DAYS: "в течение 15 рабочих дней"
};
