import { EnumStrings } from "./EnumStrings";

export const enum LotContractType {
    GOOD_PROVIDING = "GOOD_PROVIDING",
    DO_SERVICES = "DO_SERVICES",
    WORK_COMPLETES = "WORK_COMPLETES",
}

export const LotContractTypeStrings: EnumStrings = {
    [LotContractType.GOOD_PROVIDING]: "Поставка товаров",
    [LotContractType.DO_SERVICES]: "Оказание услуг",
    [LotContractType.WORK_COMPLETES]: "Выполнение работ",
};
