import React, { ReactNode } from "react";
import { Card } from "@/components/Card";
import { Provision } from "@/models/Provision/Provision";
import { Field, Fields } from "@/components/form/Field";
import { formatDate } from "@/dateUtils";
import { Label } from "@/components/primitive";

type InfoSectionProps = {
    provision?: Provision
};

export const InfoSection = ({ provision }: InfoSectionProps) => {
    return (
        <Card>
            <Fields orientation="horizontal">
                <SectionItem title="Заказчик" value={provision?.customer.shortName} />
                <SectionItem title="Дата утверждения"
                             value={provision?.approvedAt ? formatDate(provision.approvedAt) : undefined} />
                <SectionItem title="Статус" value={provision?.status} />
                <SectionItem title="Документ, подтверждающий факт согласования зам. Мэра"
                             value={<div style={{ color: "#428bca", textDecoration: "underline", cursor: "pointer" }}>
                                 {provision?.id ? "Приказ закупка.docx" : ""}
                                </div>} />
                <SectionItem title="Реестровый номер ЕИС" value={provision?.regNumber} />
                <SectionItem title="Дата вступления в силу" value={provision?.effectiveAt ? formatDate(provision.effectiveAt) : undefined} />
                <SectionItem title="Наименование органа, утвердившего положение" value={provision?.approvedAuthority} />
                <SectionItem title="В положении учтены изменения по 505-ФЗ" value={provision?.changed505FZ ? "Да" : "Нет"} />
                <SectionItem title="Дата вступления в силу изменений по 505-ФЗ в положении"
                             value={provision?.effectiveAt505FZ ? formatDate(provision.effectiveAt505FZ) : undefined} />
                <SectionItem title="Положение на основании типового положения о закупке"
                             value={provision?.typicalProvision ? "Да" : "Нет"} />
            </Fields>
        </Card>
    );
};

interface SectionItemProps {
    title: string
    value?: ReactNode
}

const SectionItem = ({ title, value }: SectionItemProps) => (
    <Field title={title}>
        <Label preset="readonlyValue">{value}</Label>
    </Field>
);
