import React from "react";
import {Expandee, HStack, VStack} from "@/components/layouts";
import {Label} from "@/components/primitive";
import {RoundedButton} from "@/components/primitive/RoundedButton";
import {ModalFC} from "@/EventBus";
import {CardModal} from "@/components/modals/CardModal";

export const AggregationSessionModal: ModalFC<undefined, boolean> = x => {
    const bottom = (
        <HStack innerStyle={{padding: "10px 0"}} spacing={"10px"}>
            <Expandee/>
            <RoundedButton color={"blue"}
                           title={"Продолжить"}
                           onClick={() => x.done(true)}/>
        </HStack>
    );

    return (
        <CardModal
            title={"Предупреждение"}
            close={() => x.done(false)}
            width={"70%"}
            bottom={bottom}>
            <VStack spacing="10px">
                <Label text="Выбран способ определения поставщика с признаком агрегированной закупки" preset="boldBig" />
                <Label text="В соответствии с Положением о закупке для выбранного способа определения поставщика будет создан агрегированный лот"
                       preset="readonlyValue" />
            </VStack>
        </CardModal>
    );
};
