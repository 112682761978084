/* eslint-disable @typescript-eslint/no-explicit-any */

import Vue, { VueConstructor } from "vue";
import { ModalVue, ModalRequest, ModalResult } from "@/view-models/ModalRequest";
import {DtoApiErrorV1} from './api/ApiError';
import {ReactElement, MouseEvent} from "react";
import ReactModalWrapper from "@/ReactModalWrapper.vue";

import {hot} from "react-hot-loader";
import {Hint} from "@/components/vue/form-table/FormFields";

type Defined = number | string | object | boolean | symbol;

type DoneFn<TResult> = [TResult] extends [Defined]
    ? { done(result: TResult | undefined): void }
    : { done(): void };

export type ReactModalProps<TProps = undefined, TResult = undefined> =
    TProps extends undefined
        ? DoneFn<TResult>
        : TProps & DoneFn<TResult>;

export type ModalFC<TProps = undefined, TReturn = undefined> =
    (props: ReactModalProps<TProps, TReturn>) => ReactElement;
export type TypedVueModal<TProps = undefined, TReturn = undefined, TVueReturn = any, TVueProps = any> =
    { vueModalComponent: VueConstructor; mapProps?: ((p: TProps) => TVueProps); mapReturn?: ((p: TVueReturn) => TReturn) };
export type AnyTypedModal<TProps = undefined, TReturn = undefined> =
    ModalFC<TProps, TReturn> | TypedVueModal<TProps, TReturn, any, any>;
type UnwrapProps<TModal extends AnyTypedModal<any, any>> =
    TModal extends AnyTypedModal<infer TProps, any> ? TProps : never;
type UnwrapReturn<TModal extends AnyTypedModal<any, any>> =
    TModal extends AnyTypedModal<any, infer TReturn> ? TReturn : never;

class EventBus extends Vue {
    public discardError() {
        this.$emit("discard-error");
    }

    /* @deprecated */
    public raiseError(error: string | DtoApiErrorV1) {
        const apiError = typeof error === "string" ? { errors: [ error ] } : error;
        this.$emit("raise-error", apiError);
    }

    /* @deprecated */
    public raiseErrors(errors: string[]) {
        this.raiseError({ version: undefined, errors });
    }

    /* @deprecated */
    public callModal<TProp, TResult>(modal: VueConstructor<ModalVue<TProp, TResult>>, prop: TProp) {
        return new Promise<ModalResult<TResult>>(resolve =>
            this.$emit("request-modal", new ModalRequest(modal, prop, resolve)),
        );
    }
}

const bus = new EventBus();

const isVueModal = (m: AnyTypedModal<any, any>): m is TypedVueModal<any, any> =>
    (m as TypedVueModal<any, any>).vueModalComponent !== undefined;

export async function showModal<TModal extends AnyTypedModal<any, any>>(
    modal: TModal,
    ...props: UnwrapProps<TModal> extends undefined ? [] : [UnwrapProps<TModal>]
): Promise<UnwrapReturn<TModal> | undefined> {
    if (isVueModal(modal)) {
        const result = await new Promise<ModalResult<UnwrapReturn<TModal>>>(resolve => {
            const prop = modal.mapProps ? modal.mapProps(props[0] as never) : props[0];
            bus.$emit("request-modal", new ModalRequest(modal.vueModalComponent, prop, resolve));
        });

        return result.isOk ? modal.mapReturn ? modal.mapReturn(result.getResult()) : result.getResult() : undefined;
    }

    const result = await new Promise<ModalResult<UnwrapReturn<TModal>>>(resolve =>
        bus.$emit("request-modal", new ModalRequest(ReactModalWrapper, {
            fc: hot(module)(modal),
            props: props[0]
        }, resolve)));

    return result.isOk ? result.getResult() : undefined;
}

export function typifyVueModal<TProps, TReturn, TVueProps = any, TVueReturn = any>(
    modal: VueConstructor,
    mapProps?: (p: TProps) => TVueProps,
    mapReturn?: (p: TVueReturn) => TReturn):
    TypedVueModal<TProps, TReturn, TVueReturn, TVueProps> {
    return { vueModalComponent: modal, mapProps, mapReturn };
}

export function showHint(ev: MouseEvent, hint: Hint | string) {
    bus.$emit("show-hint", typeof hint === "string" ? { text: hint } as Hint : hint, ev);
}

export default bus;
