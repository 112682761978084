import { SessionsController, PageOfLots } from '@/api/Sessions';
import { SortBy, SupplyRegion } from '@/api/filterHelpers';
import { FilterConditionType, FilterValueType, IFilterObject } from '@/api/http';
import { PageOfProducts, Product, ProductController } from '@/api/Products';
import { ISubjectClassContainer } from '@/components/eshop/SubjectClassFilterStore';
import { showModal } from '@/EventBus';
import router from '@/router';
import Decimal from 'decimal.js';
import { createEffect, createEvent, createStore } from 'effector';
import { ErrorModal } from '../Contracts/modals/ConfirmationModal';
import { firstMaybe } from "@/arrayUtils";
import Auth from "@/api/Auth";

export type ProductsFilterStore = {
    name: string | null
    region: SupplyRegion | null | undefined
    number: number | null
    manufacturer: string | null
    kpgz: ISubjectClassContainer | null
    priceFrom: number | null
    priceTo: number | null
    isDemanded: boolean | null
    hasOffers: boolean | null
    sortDescending: boolean | null
    sortBy: SortBy | null
    favorites: boolean
    pageSize: number
};

export type ProductsStore = {
    twoColumnMode: boolean
    page: PageOfProducts
    selectedProducts: Product[]
};

export const toggleTwoColumnMode = createEvent();
export const onSelectItem = createEvent<Product>();
export const unselectAll = createEvent();

export const loadProductsPage = createEffect({
    name: "loadProductsPage",
    handler: async (payload: { page: number }) => {
        const filtersState = $filters.getState();
        return await ProductController.get({ from: payload.page * filtersState.pageSize, count: filtersState.pageSize }, filtersState.favorites);
    }
});

export const changeName = createEvent<string>();
export const changeNumber = createEvent<number>();
export const changeKpgz = createEvent<ISubjectClassContainer | null>();
export const changeManufacturer = createEvent<string>();
export const toggleDemanded = createEvent();
export const toggleHasOffers = createEvent();
export const changePriceForm = createEvent<number>();
export const changePriceTo = createEvent<number>();
export const changeRegion = createEvent<SupplyRegion | null>();
export const toggleSortDescending = createEvent();
export const changeSortBy = createEvent<SortBy | null>();
export const changeFavorites = createEvent<boolean>();

export const goCreate = createEvent();

export const createJointSession = createEvent();

export const resetFilters = createEffect({
    name: "resetFilters",
    handler: () => { }
});

export const changePageSize = createEffect({
    name: "changePageSize",
    handler: (payload: { pageSize: number }) => {
        return payload.pageSize;
    }
});

const defaultFilters = {
    name: null,
    number: null,
    manufacturer: null,
    kpgz: null,
    priceFrom: null,
    priceTo: null,
    region: null,
    isDemanded: null,
    hasOffers: null,
    sortDescending: null,
    sortBy: null,
    favorites: false,
    pageSize: 10
};

const createFilterStore = () =>
    createStore<ProductsFilterStore>(defaultFilters)
        .on(changeName, (state, name) => ({ ...state, name: name }))
        .on(changeNumber, (state, number) => ({ ...state, number: number }))
        .on(changeKpgz, (state, kpgz) => ({ ...state, kpgz }))
        .on(toggleDemanded, (state) => ({ ...state, isDemanded: !state.isDemanded }))
        .on(toggleHasOffers, (state) => ({ ...state, hasOffers: state.hasOffers === false ? null : false }))
        .on(changePriceForm, (state, priceFrom) => ({ ...state, priceFrom }))
        .on(changePriceTo, (state, priceTo) => ({ ...state, priceTo }))
        .on(changeRegion, (state, region) => ({ ...state, region }))
        .on(changeManufacturer, (state, manufacturer) => ({ ...state, manufacturer: manufacturer }))
        .on(toggleSortDescending, state => ({ ...state, sortDescending: !state.sortDescending }))
        .on(changeSortBy, state => ({ ...state, sortDescending: !state.sortDescending }))
        .on(changeFavorites, (state, favorites) => ({ ...state, favorites }))
        .on(changePageSize, (state, { pageSize }) => {
            return { ...state, pageSize };
        })
        .on(changePageSize.done, (state) => {
            searchProducts({ page: 0 });
            return { ...state };
        })
        .on(resetFilters.done, state => ({ ...state, ...defaultFilters }))
        .on(resetFilters, () => {
            searchProducts({ page: 0 });
        });

export const $filters = createFilterStore();

export const searchProducts = createEffect({
    name: "searchProducts",
    handler: async (payload: { page: number }) => {
        const filtersState = $filters.getState();
        const filters: IFilterObject = {};

        if (filtersState.name) {
            filters["stuName"] = [{
                type: FilterValueType.STRING,
                conditionType: FilterConditionType.CONTAINS,
                string: filtersState.name
            }];
        }

        if (filtersState.kpgz?.id) {
            filters["kpgzId"] = [{
                type: FilterValueType.LONGINT,
                conditionType: FilterConditionType.EQUAL,
                longint: filtersState.kpgz.id
            }];
        }

        if (filtersState.manufacturer) {
            filters["manufacturer"] = [{
                type: FilterValueType.STRING,
                conditionType: FilterConditionType.CONTAINS,
                string: filtersState.manufacturer
            }];
        }

        if (filtersState.number) {
            filters["stuNumber"] = [{
                type: FilterValueType.LONGINT,
                conditionType: FilterConditionType.EQUAL,
                longint: filtersState.number
            }];
        }

        if (filtersState.priceFrom || filtersState.priceTo) {
            filters["refPrice"] = [];
            if (filtersState.priceFrom) {
                filters["refPrice"] = [...filters["refPrice"], {
                    type: FilterValueType.DECIMAL,
                    conditionType: FilterConditionType.GREATER_THAN,
                    decimal: new Decimal(filtersState.priceFrom)
                }];
            }
            if (filtersState.priceTo) {
                filters["refPrice"] = [...filters["refPrice"], {
                    type: FilterValueType.DECIMAL,
                    conditionType: FilterConditionType.LESS_THAN,
                    decimal: new Decimal(filtersState.priceTo)
                }];
            }
        }

        if (filtersState.region) {
            filters["region"] = [{
                type: FilterValueType.STRING,
                conditionType: FilterConditionType.EQUAL,
                string: filtersState.region
            }];
        }

        if (filtersState.isDemanded) {
            filters["isDemanded"] = [{
                type: FilterValueType.BOOLEAN,
                conditionType: FilterConditionType.EQUAL,
                boolean: filtersState.isDemanded
            }];
        }

        if (filtersState.hasOffers === false) {
            filters["hasOffers"] = [{
                type: FilterValueType.BOOLEAN,
                conditionType: FilterConditionType.EQUAL,
                boolean: filtersState.hasOffers
            }];
        }

        return await ProductController.get({
            from: payload.page * filtersState.pageSize,
            count: filtersState.pageSize,
            filters: filters,
            sortDescending: !!filtersState.sortDescending,
            sortBy: filtersState.sortBy || undefined
        }, !!filtersState.favorites);
    }
});

export const setFavorite = createEffect({
    name: "setFavorite",
    handler: async (payload: { id: string; favorite: boolean }) => {

        return await ProductController.setFavorite(
            payload.id, payload.favorite
        );
    }
});

export const createProductsStore = () =>
    createStore<ProductsStore>({
        twoColumnMode: false,
        selectedProducts: [],
        page: {
            totalCount: 0,
            from: 0,
            to: 0,
            items: []
        }
    })
        .on(onSelectItem, (state, product) => {
            const index = state.selectedProducts.findIndex(p => p.id === product.id);
            if (index === -1) {
                state.selectedProducts.push(product);
            } else {
                state.selectedProducts.splice(index, 1);
            }
            return { ...state, selectedProducts: state.selectedProducts };
        })
        .on(unselectAll, (state) => {
            return { ...state, selectedProducts: [] };
        })
        .on(goCreate, (state) => {
            const kpgzGroup = firstMaybe(state.selectedProducts)?.spgz.subjectClass.code.slice(0, 5) || '';
            const isValidKpgz = state.selectedProducts.every(x => x.spgz.subjectClass.code.slice(0, 5) === kpgzGroup);

            if (!isValidKpgz) {
                showModal(ErrorModal, { text: 'Невозможно создать одну котировочную сессию на основе СТЕ по разным товарным группам продукции' });
                return;
            }
            router.replace(`/shop/create-session/product/${state.selectedProducts.map(p => p.id).join('&')}`);
            return { ...state };
        })
        .on(createJointSession, (state) => {
            router.replace("/shop/joint-sessions/new");
            return {...state};
        })
        .on(loadProductsPage.done, (state, { result }) => ({ ...state, page: result }))
        .on(searchProducts.done, (state, { result }) => ({ ...state, page: result }))
        .on(toggleTwoColumnMode, state => ({ ...state, twoColumnMode: !state.twoColumnMode }));

export const $products = createProductsStore();

export const changeLotId = createEvent<{ productId: string; lotId: string | null }>();
export const changeLaw = createEvent<{ productId: string; law: string }>();
export const changeVolume = createEvent<{ productId: string; volume: number }>();

export const loadLots44 = createEffect({
    name: "loadLots44",
    handler: async (payload: {}) => {
        return await SessionsController.getLots({
            from: 0,
            count: 10,
            filters: {
                year: [{
                    type: FilterValueType.LONGINT,
                    conditionType: FilterConditionType.EQUAL,
                    longint: Auth.selectedYear
                }]
            }
        }, 'F44');
    }
});
export const loadLots223 = createEffect({
    name: "loadLots223",
    handler: async (payload: {}) => {
        return await SessionsController.getLots({
            from: 0,
            count: 10,
            filters: {
                year: [{
                    type: FilterValueType.LONGINT,
                    conditionType: FilterConditionType.EQUAL,
                    longint: Auth.selectedYear
                }]
            }
        }, 'F223');
    }
});

export const addProductToContract = createEvent<Product[] | null>();

interface CreateContractPayload {
    lotId: string
    law: string
    productId: string
    amount: number
}

export const createContract = createEffect({
    name: "createContract",
    handler: async (payload: CreateContractPayload) => {
        return await SessionsController.createContractFromProduct(payload);
    }
});


export interface ProductForCreation {
    product: Product
    volume: number
    law: string
    lotId: string | null
}

export type ContractStore = {
    products: ProductForCreation[]
    lotsPage44: PageOfLots
    lotsPage223: PageOfLots
};

const createContractStore = () =>
    createStore<ContractStore>({
        products: [],
        lotsPage44: {
            totalCount: 0,
            from: 0,
            to: 0,
            items: []
        },
        lotsPage223: {
            totalCount: 0,
            from: 0,
            to: 0,
            items: []
        },
    })
        .on(addProductToContract, (state, products) => ({ ...state, products: products ? products.map(p => ({ product: p, volume: 1, law: 'F44', lotId: null })) : [] }))
        .on(changeLaw, (state, { productId, law }) => {
            const products = state.products.map(x => {
                if (x.product.id === productId) {
                    return {
                        ...x,
                        law: law
                    };
                }
                return x;
            });

            return ({ ...state, products });
        })
        .on(changeVolume, (state, { productId, volume }) => {
            const products = state.products.map(x => {
                if (x.product.id === productId) {
                    return {
                        ...x,
                        volume: volume
                    };
                }
                return x;
            });

            return ({ ...state, products });
        })
        .on(changeLotId, (state, { productId, lotId }) => {
            const products = state.products.map(x => {
                if (x.product.id === productId) {
                    return {
                        ...x,
                        lotId: lotId
                    };
                }
                return x;
            });

            return ({ ...state, products });
        })
        .on(loadLots44.done, (state, { result }) => ({ ...state, lotsPage44: result }))
        .on(loadLots223.done, (state, { result }) => ({ ...state, lotsPage223: result }));

export const $contracts = createContractStore();
