import {IJsonFormattable} from "@/models/IJsonFormattable";
import {IFilterObject} from './http';
import {MDDeserializer} from "@/models/parsing";

export declare interface IPagedItems<T> {
    totalCount: number
    from: number
    to: number
    items: T[]
}

export function getActivePage<T>(items: IPagedItems<T>, pageSize = 20): number {
    return (items.from / pageSize) | 0;
}

export function getAvailablePages<T>(items: IPagedItems<T>, pageSize = 20): number[] {
    const activePage = getActivePage(items, pageSize);

    if (items.totalCount === 0) {
        return [0];
    }

    const maxPages = 5;
    const endPage = ((items.totalCount / pageSize) | 0) - (items.totalCount % pageSize > 0 ? 0 : 1);

    let lPages = Math.min(activePage, maxPages - 1);
    let rPages = Math.min(endPage - activePage, maxPages - 1);

    if (lPages < rPages) {
        rPages = Math.min(maxPages - lPages - 1, endPage - activePage);
    } else if (rPages < lPages) {
        lPages = Math.min(maxPages - rPages - 1, activePage);
    } else {
        const total = Math.min(maxPages - 1, lPages + rPages);
        rPages = lPages = total / 2;
    }

    const pages = [];
    for (let i = 0; i < lPages; i++) {
        pages.push(activePage - (lPages - i));
    }
    pages.push(activePage);
    for (let i = 0; i < rPages; i++) {
        pages.push(activePage + i + 1);
    }

    return pages;
}

export declare type PaginationRequest = "TO_START" | "TO_PREVIOUS" | "TO_NEXT" | "TO_END" | number;

export function handlePaginationRequest<T>(
    items: IPagedItems<T> | null,
    request: PaginationRequest,
    callback: (page: number) => void,
    force = false,
) {
    const currentPage = items ? getActivePage(items) : 0;
    let actualPage;

    const endPage = items ? ((items.totalCount / 20) | 0) - (items.totalCount % 20 > 0 ? 0 : 1) : 0;

    switch (request) {
        case "TO_START":
            actualPage = 0;
            break;
        case "TO_END":
            actualPage = endPage;
            break;
        case "TO_NEXT":
            actualPage = currentPage + 1;
            break;
        case "TO_PREVIOUS":
            actualPage = currentPage - 1;
            break;
        default:
            actualPage = request;
            break;
    }

    if (actualPage >= 0 && actualPage <= endPage && (force || actualPage !== currentPage)) {
        callback(actualPage);
    }
}

/* @deprecated */
export function convertPaged<T extends IJsonFormattable<TDto, TDtoInfo>, TDto extends TDtoInfo, TDtoInfo>(
    pagedDto: IPagedItems<TDto>,
    proto: { fromJson(dto: TDto): T },
): IPagedItems<T> {
    return { ...pagedDto, items: pagedDto.items.map(x => proto.fromJson(x)) };
}

export function mapPage<T, TDto>(
    mapper: MDDeserializer<T, TDto>,
    items: IPagedItems<TDto>
): IPagedItems<T> {
    return {
        ...items,
        items: items.items.map(mapper.fromDto)
    };
}

export declare interface PaginationContext<T> {
    items: T[]
    activePage: number
    pages: number[]
    filter: IFilterObject

    readonly pageSize: number

    totalCount: number

    load(page: number): Promise<T[]>
}

export async function createPaginationContext<T>(
    loader: (n: number, sz: number, f: IFilterObject) => Promise<IPagedItems<T>>, 
    pageSize = 20, initialFilter?: IFilterObject): Promise<PaginationContext<T>> {

    const first = await loader(0, pageSize, initialFilter || {});

    return {
        items: first.items,
        activePage: 0,
        pages: getAvailablePages(first),
        filter: initialFilter || {},

        pageSize: pageSize,
        totalCount: first.totalCount,

        async load(page) {
            const result = await loader(page * pageSize, pageSize, this.filter);

            this.items = result.items;
            this.activePage = getActivePage(result, pageSize);
            this.pages = getAvailablePages(result, pageSize);
            this.totalCount = result.totalCount;

            return result.items;
        }
    } as PaginationContext<T>;
}