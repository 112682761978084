import React from "react";
import { Card } from "@/components/Card";
import { Label } from "@/components/primitive";

export const ContractCorrectionSection = () => {
    return (
        <Card>
            <Label preset="readonlyValue" style={{ fontWeight: "bold" }}>
                Возможность изменения существенных условий договора для основания закупки у единственного поставщика
            </Label>
        </Card>
    );
};
