













import { Component, Prop, Vue, Watch } from "vue-property-decorator";
import Decimal from "decimal.js";
import { stringifyNumber } from '@/NumberFormatting';
import { PrecisionMode } from '@/components/TableAbstractions';

@Component
export default class DecimalCellEditor extends Vue {
    @Prop() public cellValue!: Decimal;
    @Prop({ default: true }) public valid!: boolean;
    @Prop({ default: false }) public disabled!: boolean;
    @Prop() public precision?: number;
    @Prop({ default: "FIXED" }) public precisionMode!: PrecisionMode;
    @Prop() public minValue?: PrecisionMode;
    @Prop() public maxValue?: PrecisionMode;

    private decimalString = "";
    private suppressChange = false;

    private setDecimalString(explicit?: Decimal) {
        const value = explicit || this.cellValue;

        if (value && value.isFinite())
            this.decimalString =
                this.precision
                    ? stringifyNumber(value, this.precision, this.precisionMode)
                    : value.toString();
        else this.decimalString = "";
    }

    public mounted() {
        this.setDecimalString();
    }

    @Watch("cellValue") private updateValue() {
        if (!this.suppressChange) this.setDecimalString();
        else this.suppressChange = false;
    }

    private get inputString(): string {
        return this.decimalString;
    }
    private set inputString(v: string) {
        this.decimalString = v;

        try {
            const origDecimal = new Decimal(v.replace(',', '.'));
            let decimal = origDecimal;
            if (this.minValue) decimal = Decimal.max(this.minValue, decimal);
            if (this.maxValue) decimal = Decimal.min(this.maxValue, decimal);

            if (!decimal.eq(origDecimal))
                Promise.resolve().then(() =>  this.setDecimalString(decimal));

            this.$emit("change", decimal);
        } catch {
            this.$emit("change", new Decimal(NaN));
        }

        this.suppressChange = true;
    }
}
