import { VueClass } from "vue-class-component/lib/declarations";
import Contract1 from "@/components/documentation-templates/Contact1.vue";
import Spec1 from "@/components/documentation-templates/TechSpec1.vue";
import Joint1 from "@/components/documentation-templates/Joint1.vue";
import { Vue } from "vue/types/vue";
import {
    Lot,
    ProviderSelectionType,
    ProviderSelectionTypeStrings
} from "@/models/ComposedLots";
import { LotDocument } from "@/models/ComposedLots/documents";
import { showModal, typifyVueModal } from "@/EventBus";
import DocumentModal from "@/views/modals/DocumentModal/DocumentModal.vue";
import { applyN, uuid } from "@/models/parsing";
import { defaultDocumentationTables, DocumentModalProps } from "@/form-declarations/documentation";
import { Lot as LotForDocument } from "@/api/Sessions";

export type DocumentTable = Record<string, string>;
export type EditableDoc = "contract" | "spec" | "jointPurchaseAgreement";

interface Draft {
    content: DocumentTable
    type: EditableDoc
    lotId: uuid
}

const CreateDocumentModal = typifyVueModal<
    { table: DocumentTable; title: string; component: VueClass<Vue> },
    { table: DocumentTable; save?: "permanent" | "draft" },
    DocumentModalProps,
    void>(
        DocumentModal,
        x => ({ ...x, table: { ...x.table } }),
        x => {
            const realReturn = x as unknown as { table: DocumentTable; save?: "permanent" | "draft" };
            return { table: { ...realReturn.table }, save: realReturn.save };
        });

const getNextDraftNumber = (): number => {
    const key = "local-doc-draft-v2-number";

    const curNumber = parseInt(localStorage.getItem(key) ?? "1", 10) | 0;
    localStorage.setItem(key, (curNumber + 1).toString());

    return curNumber;
};

const draftKey = "local-doc-draft-v2";
const getDraft = (lot: Lot, type: EditableDoc): Draft => {
    const savedDraft = applyN(JSON.parse, localStorage.getItem(draftKey)) as Draft | undefined;

    if (!savedDraft || savedDraft.lotId !== lot.id || savedDraft.type !== type) {
        setDraft(undefined);

        return {
            content: defaultDocumentationTables[type](
                lot.basicInfo.subject!,
                ProviderSelectionTypeStrings[lot.basicInfo.providerSelectionType as ProviderSelectionType],
                lot.basicInfo.contractType),
            lotId: lot.id,
            type: type
        };
    }

    return savedDraft;
};

const setDraft = (draft: Draft | undefined) => {
    if (draft)
        localStorage.setItem(draftKey, JSON.stringify(draft));
    else
        localStorage.removeItem(draftKey);
};

const createLotDoc = {
    contract: (lot: Lot | LotForDocument): LotDocument => ({
        type: "CONTRACT_PROJECT",
        file: {
            id: null!,
            name: `Проект_контракта_${lot.regNumber}-${getNextDraftNumber()}.pdf`,
            length: 10000,
            comment: "Создан автоматически"
        },
        comment: "Создан автоматически",
        id: null!,
        lot: null!,
        sendToEis: true
    }),
    spec: (lot: Lot | LotForDocument): LotDocument => ({
        type: "TECH_SPECS",
        file: {
            id: null!,
            name: `Техническое_задание_${lot.regNumber}-${getNextDraftNumber()}.pdf`,
            length: 20000,
            comment: "Создан автоматически"
        },
        comment: "Создан автоматически",
        id: null!,
        lot: null!,
        sendToEis: true
    }),
    jointPurchaseAgreement: (lot: Lot | LotForDocument): LotDocument => ({
        type: "JOINT_PURCHASE_AGREEMENT", // TODO: joint purchase agreement document type
        file: {
            id: null!,
            name: `Соглашение_о_совместной_закупке_${lot.regNumber}-${getNextDraftNumber()}.pdf`,
            length: 20000,
            comment: "Создан автоматически"
        },
        comment: "Создан автоматически",
        id: null!,
        lot: null!,
        sendToEis: true
    }),
    msv: (lot: Lot | LotForDocument): LotDocument => ({
        type: "MSV_CALC",
        file: {
            id: null!,
            name: `Расчет_НМЦ_${lot.regNumber}-${getNextDraftNumber()}.pdf`,
            length: 30000,
            comment: "Создан автоматически"
        },
        comment: "Создан автоматически",
        id: null!,
        lot: null!,
        sendToEis: true
    }),
    contractElec: (lot: Lot | LotForDocument): LotDocument => ({
        type: "CONTRACT_PROJECT_ELEC",
        file: {
            id: null!,
            name: `Печатная форма электронного контракта.html`,
            length: 30000,
            comment: "Создан автоматически"
        },
        comment: "Создан автоматически",
        id: null!,
        lot: null!,
        sendToEis: true
    })
};

const modalProps = {
    jointPurchaseAgreement: (lot: Lot | LotForDocument): DocumentModalProps => ({
        title: "Формирование соглашения о совместной закупке",
        table: getDraft(lot as Lot, "jointPurchaseAgreement").content,
        component: Joint1,
    }),
    contract: (lot: Lot | LotForDocument): DocumentModalProps => ({
        title: "Формирование проекта контракта",
        table: getDraft(lot as Lot, "contract").content,
        component: Contract1,
    }),
    spec: (lot: Lot | LotForDocument): DocumentModalProps => ({
        title: "Формирование ТЗ",
        table: getDraft(lot as Lot, "spec").content,
        component: Spec1,
    }),
};

export const createLotDocumentFromTemplate = async (type: EditableDoc, lot: Lot | LotForDocument): Promise<LotDocument | undefined> => {
    const result = await showModal(CreateDocumentModal, modalProps[type](lot));

    if (!result || !result.save) return;
    if (result.save === "permanent") {
        const doc = createLotDoc[type](lot);
        setDraft(undefined);
        return doc;
    } else {
        setDraft({
            content: result.table,
            type: type,
            lotId: lot.id
        });
    }
};

export const createStartPriceReportDocument = (lot: Lot): LotDocument => createLotDoc.msv(lot);
export const createElecContractDocument = (lot: Lot): LotDocument => createLotDoc.contractElec(lot);
