import { Component, Vue, Prop } from "vue-property-decorator";
import DataTable from "@/components/vue/DataTable.vue";
import { HistoryEntry } from '@/models/HistoryEntry';
import { ITableColumn, EditType } from '@/components/TableAbstractions';
import moment from "moment";
import { HistoryEntry2020 } from "@/models/Contracts/ContractHistoryEntry2020";

@Component({ components: { DataTable } })
export default class ChangeHistorySection extends Vue { 
    @Prop() private source!: { history: HistoryEntry[] };

    // eslint-disable-next-line @typescript-eslint/ban-ts-ignore
    // @ts-ignore
    public dataset = this.source?.contract?.history2020 || [];

    public headers: ITableColumn<HistoryEntry2020>[] = [
        { 
            type: EditType.TEXT,
            title: 'Пользователь',
            getter(n) { return n.user.fullName ?? n.user.username }
        },
        { 
            type: EditType.TEXT,
            title: 'Дата и время изменения',
            getter(n) { return moment(n.date).format("DD.MM.YYYY HH:mm") }
        },
        { 
            type: EditType.TEXT,
            title: 'Статус', 
            getter(n) { return n.operation ?? "" }
        }
    ];
}
