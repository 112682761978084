// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".vUXnoVzO5YSlrEekTDqbO {\n    border: 1px solid #c3cdd4;\n    overflow-y: scroll;\n    min-height: 400px;\n}\n\n.t0N7MW-VHWon_RcGx7vFv {\n    display: flex;\n    align-items: center;\n    padding: 5px 7px;\n    border-bottom: 1px solid #808080;\n    cursor: pointer;\n}\n\n.t0N7MW-VHWon_RcGx7vFv:nth-child(2n - 1) {\n    background-color: #f0f3f5;\n}\n\n.t0N7MW-VHWon_RcGx7vFv._1JCJOkaW-GKiEZIET8Lw5z {\n    background-color: #428bca;\n    color: #fff;\n}\n", ""]);
// Exports
exports.locals = {
	"list": "vUXnoVzO5YSlrEekTDqbO",
	"item": "t0N7MW-VHWon_RcGx7vFv",
	"active": "_1JCJOkaW-GKiEZIET8Lw5z"
};
module.exports = exports;
