import {Card} from "@/components/Card";
import React, {useContext} from "react";
import {FormStateContext, FTextBox} from "@/components/form";
import {Field, Fields} from "@/components/form/Field";

export const CommentarySection = () => {
    const state = useContext(FormStateContext);
    const orientation = state === "readonly" ? "vertical" : "horizontal";

    return <Card>
        <Fields orientation={orientation}>
            <Field title="Комментарий" preset="boldSmall">
                <FTextBox value="" lines={5} />
            </Field>
        </Fields>
    </Card>;
};
