import { FilterConditionType, IFilterValue, FilterValueType } from '@/api/http';
import { SelectOption } from '@/models/enums';
import Decimal from 'decimal.js';

export declare interface Identified {
    id: string | number | null
}

export declare interface SingleSelection<T extends Identified> {
    tag: "SINGLE"
    item: T | null
}
export declare interface MultiSelection<T extends Identified> {
    tag: "MULTI"
    items: T[]
}
export declare type Selection<T extends Identified> = SingleSelection<T> | MultiSelection<T>;

export const enum SelectionStyle {
    RADIO = "RADIO",
    BACKGROUND = "BACKGROUND",
    COMBINED = "COMBINED",
    MULTIPLE = "MULTIPLE",
}

export const enum EditType {
    TEXT = "TEXT",
    DECIMAL = "DECIMAL",
    SELECT = "SELECT",
    CHECK = "CHECK",
    LONGINT = "LONGINT"
}

export const enum SelectionColumnControl {
    RADIO = "radio",
    CHECKBOX = "checkbox"
}

export declare interface IRowConfiguration<T> {
    selection?: {
        predicate?: (i: T) => boolean
        predicateAction?: "hide" | "lock"
        showSelectionColumn: boolean
        selectionColumnControl?: SelectionColumnControl
        background?: string
        selectionColumnHeader?: string
        selectionColumnPosition?: "left" | "right"
        explicitChecked?: (i: T) => boolean
        locked?: boolean
    }

    group?(items: T[]): Array<ITableGroup<T>>
    showJoinedRow?: ((row: T) => boolean) | "last"
    isRawJoinedRow?(row: T): boolean
    highlight?(row: T): string | null
    showEditColumn?: boolean
    useSlotForActionColumn?: boolean
}

export declare type ValidationResult = { error: false } | { error: true; description?: string };

export function validationResult(r: boolean, desc?: string): ValidationResult {
    if (r) return { error: false };
    else return { error: true, description: desc };
}

export const enum UIFilterType {
    STRING = "STRING",
    NUMBER = "NUMBER",
    BOOLEAN = "BOOLEAN",
    SELECT = "SELECT",
    RANGE = "RANGE",
    DATE = "DATE",
    LONGINT = "LONGINT"
}

export const enum UIStringFilterComparison {
    STARTS_WITH = "STARTS_WITH",
    CONTAINS = "CONTAINS",
    EQUAL = "EQUAL"
}

export const enum UINumberFilterDataType {
    DECIMAL = "DECIMAL",
    LONGINT = "LONGINT"
}

export declare interface UIFilterBase {
    property: string
    type: UIFilterType
}

export declare interface UISelectFilter extends UIFilterBase {
    type: UIFilterType.SELECT

    multiselect: boolean
    options: SelectOption<string>[]
}


export declare interface UIBooleanFilter extends UIFilterBase {
    type: UIFilterType.BOOLEAN

    falseDescription: string
    trueDescription: string
}

export declare interface UIStringFilter extends UIFilterBase {
    type: UIFilterType.STRING

    comparisonMode: UIStringFilterComparison
}

export declare type ExplicitRangeValue<T> = T | "INFINITE";

export declare interface UIDecimalRangeFilter extends UIFilterBase {
    type: UIFilterType.RANGE
    dataType: UINumberFilterDataType.DECIMAL

    startInclusive: boolean
    endInclusive: boolean

    explicitStart?: ExplicitRangeValue<Decimal>
    explicitEnd?: ExplicitRangeValue<Decimal>
}

export declare interface UILongintRangeFilter extends UIFilterBase {
    type: UIFilterType.RANGE
    dataType: UINumberFilterDataType.LONGINT

    startInclusive: boolean
    endInclusive: boolean

    explicitStart?: ExplicitRangeValue<number>
    explicitEnd?: ExplicitRangeValue<number>
}

export declare interface UIDateFilter extends UIFilterBase {
    type: UIFilterType.DATE

    withDay: boolean
}

export declare interface UIDecimalFilter extends UIFilterBase {
    type: UIFilterType.NUMBER
    dataType: UINumberFilterDataType.DECIMAL
}

export declare interface UILongintFilter extends UIFilterBase {
    type: UIFilterType.LONGINT
    dataType: UINumberFilterDataType.LONGINT
}

export declare type UIFilter =
    | UIStringFilter
    | UIDateFilter
    | UIBooleanFilter
    | UIDecimalRangeFilter
    | UILongintRangeFilter
    | UIDecimalFilter
    | UILongintFilter
    | UISelectFilter;

export declare interface TableColumnBase<T> {
    title: string
    type?: EditType
    editable?: boolean | ((r: T) => boolean)
    disabled?: boolean | ((r: T) => boolean)
    visible?: () => boolean

    headerAlignment?: "center" | "left" | "right"
    itemAlignment?: "center" | "left" | "right"

    filter?: UIFilter

    action?(row: T): {
        id: string
        title?: string
        color?: string
        icon?: {
            type: string
            name: string
        }
    } | null

    size?: string

    getter(row: T, index: number): unknown
    url?(row: T): string | (() => void) | null
    setter?(row: T, value: never): void
    blur?(row: T, value: never): void

    validate?(row: T): ValidationResult
}

export declare interface UntypedTableColumn<T> extends TableColumnBase<T> {
    slot?: string
}

export declare interface TextTableColumn<T> extends TableColumnBase<T> {
    type: EditType.TEXT
}

export declare interface SelectTableColumn<T> extends TableColumnBase<T> {
    type: EditType.SELECT
    selectOptions: Array<{ key: unknown; desc: string }>
}

export declare type PrecisionMode = "FIXED" | "INITIAL";

export declare interface DecimalTableColumn<T> extends TableColumnBase<T> {
    type: EditType.DECIMAL
    precision?: number
    precisionMode?: PrecisionMode
    minValue?: Decimal
    maxValue?: Decimal
}

export declare type ITableColumn<T> = SelectTableColumn<T> | DecimalTableColumn<T> | TextTableColumn<T> | UntypedTableColumn<T>;

export declare interface ITableGroup<T> {
    header: string
    items: T[]
}

export declare interface ITableAction {
    id: string
    title: string
}
