













import { Component, Vue, Prop } from "vue-property-decorator";
import FormTable from "@/components/vue/FormTable.vue";
import CardModal from "@/components/CardModal.vue";
import { makeFormHelpers } from "@/form-declarations/helpers";
import { IColumn, TextAlignment } from "@/components/vue/form-table/FormFields";
import Decimal from "decimal.js";
import { ModalVue, ModalResult } from '../../view-models/ModalRequest';
import { Contract, ContractEconomy, ContractFinancialSource } from '../../models/Contracts';
import { ContractScope } from './ContractScope';

import ContractEconomies from '@/api/ContractEconomies';

const h = makeFormHelpers<ContractEconomy>();

@Component({ components: { FormTable, CardModal } })
export default class AddEconomy extends Vue {
    @Prop() private prop!: { scope: ContractScope; source: ContractEconomy | null};

    public findExistingSource(src: ContractFinancialSource) {
        for (const exs of this.prop.scope.financialSources) {
            if (exs.id === src.id) return exs;
        }

        return null!;
    }

    private calculateEconomy() {
        if (this.prop.scope.financialSources.length > 1) return null!;

        const startPrice = this.prop.scope.contract.specifications.reduce((r, v) => r.add(v.specification.startPrice!.multiplied), new Decimal(0));
        const economies = this.prop.scope.economy.reduce((r, v) => r.add(v.amount), new Decimal(0));

        return startPrice.sub(this.prop.scope.contract.cost).sub(economies);
    }

    public source: ContractEconomy = new ContractEconomy(
        this.prop.source ? this.prop.source.id : null!,
        this.prop.source ? this.findExistingSource(this.prop.source.source) : null!,
        this.prop.source ? this.prop.source.amount : this.calculateEconomy());

    public formColumns: IColumn<ContractEconomy>[] = [
        {
            rows: [
                h.required().select("Источник финансирования:", "source", [
                    {key: null, desc: ""},
                    ...this.prop.scope.financialSources.map(x => ({
                        key: x,
                        desc: x.source.format(false) + " " + (x.account && x.account.accountNumber || "")
                    })),
                ]),
                h.required().decimal("Сумма экономии, руб.:", "amount", { alignment: TextAlignment.LEFT }),
            ],
        },
    ];

    public get isOk() { return !!this.source.source && !!this.source.amount && this.source.amount.gt(0) }

    public loading = false;

    public async saveAndClose() {
        if (this.isOk) {
            this.loading = true;

            try {
                const src =
                    this.source.id
                        ? await ContractEconomies.update(this.source)
                        : await ContractEconomies.create(this.prop.scope.contract.id, this.source);

                this.$emit("close-modal", ModalResult.ok(src));
            } finally {
                this.loading = false;
            }
        }
    }
}
