


































import { Component, Prop, Vue } from "vue-property-decorator";
import Card from "@/components/vue/Card.vue";
import EventBus from "@/EventBus";
import Modal from "./Modal.vue";

@Component({ components: { Card, Modal } })
export default class CardModal extends Vue {
    @Prop() private title!: string | null;
    @Prop({ default: "80%"}) private width!: string;
    @Prop({ default: null}) private height!: string | null;
    @Prop({ default: "20px 30px" }) private padding!: string;
    @Prop({ default: true }) private useScrim!: boolean;
    @Prop({ default: true }) private hasCloseButton!: boolean;
    @Prop({ default: true }) private hasFooter!: boolean;

    private handleModalEvent($event: Event) {
        $event.stopPropagation();
        EventBus.$emit("close-popup");
    }
}
