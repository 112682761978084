// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, "._1IEj2KSK1Sy2fevL6FaHbt {\n    display: inline-flex;\n    justify-content: center;\n    align-items: center;\n    position: relative;\n    z-index: 0;\n\n    padding: 0 30px;\n\n    box-sizing: border-box;\n    min-width: 100px;\n    min-height: 40px;\n\n    border-style: solid;\n    border-width: 1px;\n    border-radius: 30px;\n\n    color: white;\n    font-family: \"Roboto\", Helvetica, Arial, sans-serif;\n    font-size: 16px;\n    font-weight: 400;\n    /* text-transform: lowercase; */\n    user-select: none;\n    cursor: pointer;\n}\n\n/* baseline correction */\n._1IEj2KSK1Sy2fevL6FaHbt::before {\n    content: '';\n    display: inline-block;\n    height: calc(100%);\n}\n\n._1IEj2KSK1Sy2fevL6FaHbt:active::before {\n    margin-top: -1px;\n}\n\n._1IEj2KSK1Sy2fevL6FaHbt:active:not(._3ksNM7bqmAHWHdrMUyL5eH) > div {\n    margin-top: -1px;\n}\n\n._1IEj2KSK1Sy2fevL6FaHbt {\n    /* suppressing IDEA errors */\n    --idleBg: unset;\n    --idleBorder: unset;\n    --hoverBg: unset;\n    --hoverBorder: unset;\n    --activeBg: unset;\n    --activeBorder: unset;\n\n    background-color: var(--idleBg);\n    border-color: var(--idleBorder);\n    box-sizing: border-box;\n}\n\n._1IEj2KSK1Sy2fevL6FaHbt:hover,\n._3ksNM7bqmAHWHdrMUyL5eH {\n    background-color: var(--hoverBg);\n    border-color: var(--hoverBorder);\n}\n\n._3ksNM7bqmAHWHdrMUyL5eH {\n    opacity: 0.8;\n}\n\n._392bAEfA6iYWzzRu4EQwP2 {\n    height: 32px;\n    min-height: 32px;\n    font-size: 12px;\n    padding: 15px;\n}\n\n._1IEj2KSK1Sy2fevL6FaHbt:not(._3ksNM7bqmAHWHdrMUyL5eH):active {\n    background-color: var(--activeBg);\n    border-color: var(--activeBorder);\n    border-width: 1px;\n    padding-top: 1px;\n    padding-bottom: 1px;\n}\n", ""]);
// Exports
exports.locals = {
	"button": "_1IEj2KSK1Sy2fevL6FaHbt",
	"disabled": "_3ksNM7bqmAHWHdrMUyL5eH",
	"small": "_392bAEfA6iYWzzRu4EQwP2"
};
module.exports = exports;
