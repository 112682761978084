import {StartPriceMethod, StartPriceMethodStrings} from "@/models/enums/StartPriceMethod";
import {Store} from "effector";
import {
    CalculatorStore,
    setCurrentMethod, setJustification, setSelectedTariffGroup, setSelectedTariffs,
    setSimpleRatioValue,
    setSimpleValue, setTaxPercent
} from "@/views/ComposedLot/edit/start-price/store";
import {useMappedStore} from "@/storeUtils";
import Decimal from "decimal.js";
import {Card} from "@/components/Card";
import styles from "./CommonPage.module.css";
import {Button, ComboBox, DecimalBox, Label, Radio, RadioGroup, TextBox} from "@/components/primitive";
import {formatNumber} from "@/NumberFormatting";
import {HSpread, HStack, VGrid, VStack} from "@/components/layouts";
import {Table} from "@/components/table";
import React, { useEffect } from "react";
import {StartPrice} from "@/models/ComposedLots/specifications/StartPrice";
import {CalculationImbalanceModal} from "./CalculationImbalanceModal";
import {showModal} from "@/EventBus";
import {Tariff} from "@/models/Tariff";

type CalculationMethod = { name: string; id: StartPriceMethod } & (
    | { type: "unacceptable" }
    | { type: "simple"; reasonTitleIfRequired?: string }
    | { type: "market-prices" }
    | { type: "tariff" }
    | { type: "with_vat" }
);

const calculationMethods: CalculationMethod[] = [
    { id: "MARKET_PRICES", name: "Метод анализа рыночной стоимости закупаемых товаров, работ, услуг", type: "market-prices" },
    { id: "CONTRACT_REGISTRY_PRICES", name: "Анализ цен, содержащихся в реестре контрактов", type: "unacceptable" },
    { id: "STANDARD", name: "Нормативный", type: "unacceptable" },
    { id: "TARIFF", name: "Тарифный", type: "tariff" },
    { id: "RELATIVE_RATE", name: "Удельных показателей", type: "unacceptable" },
    { id: "ESTIMATE", name: "Сметный", type: "simple" },
    { id: "COST_BASED", name: "Затратный", type: "simple", reasonTitleIfRequired: "Обоснование выбора затратного метода расчета:" },
    { id: "ALTERNATIVE_PRICES", name: "Анализ стоимости аналогов", type: "simple" },
    { id: "PARAMETRIC", name: "Параметрический", type: "simple" },
    { id: "OTHER", name: "Иной", type: "simple", reasonTitleIfRequired: "Обоснование выбора иного метода расчета:" },
];


export const taxPercentOptions = [
    {key: undefined, desc: ""},
    {key: 0, desc: "0%"},
    {key: 10, desc: "10%"},
    {key: 20, desc: "20%"},
];

const CommonPageInformationSection = (x: {store: Store<CalculatorStore>}) => {
    const [specification]
        = useMappedStore(x.store, x => [x.specification]);

    return <Card title="Информация">
        <div className={styles.informationCard}>
            <Label className={styles.informationCardLabel}>СПГЗ:</Label>
            <Label className={styles.informationCardLabel}>Количество:</Label>
            <Label className={styles.informationCardLabel}>Ед. изм.:</Label>
            <Label className={styles.informationCardLabel}>Ориентировочная цена:</Label>
            <Label className={styles.informationCardLabel}>Дата расчета:</Label>

            <TextBox disabled value={specification.subjectDeclaration.subject} />
            <TextBox disabled value={formatNumber(specification.volume)}/>
            <TextBox disabled value={specification.measurementUnit.name}/>
            <TextBox disabled/>
            <TextBox disabled/>
        </div>
    </Card>;
};

const CommonPageMethodJustification = (x: {store: Store<CalculatorStore>}) => {
    const [currentMethod, justification]
        = useMappedStore(x.store, x => [x.currentMethod, x.justification]);
    const methodType = calculationMethods.find(t => t.id === currentMethod);

    return methodType?.type === "simple" && !!methodType.reasonTitleIfRequired ? <VGrid columns="1fr 1fr" spacing="15px">
        <Label preset="boldSmall" required>{methodType.reasonTitleIfRequired}</Label>
        <TextBox lines={5} value={justification} onChange={setJustification}/>
        <Label preset="boldSmall" required>Подтверждающий документ:</Label>
        <input type="file"/>
    </VGrid> : null;
};

const TariffSelectionCell = (x: {store: Store<CalculatorStore>}) => {
    const [currentMethod, tariffs, isSupplierFixed, selectedTariffGroup, selectedTariffs]
        = useMappedStore(x.store, x => [
            x.currentMethod,
            x.tariffs,
            x.isSupplierFixed,
            x.selectedTariffGroup,
            x.selectedTariffs
        ]);

    return <VStack spacing="10px">
        <div>Тарифный</div>
        {
            tariffs && tariffs.length > 0 && currentMethod === "TARIFF" && <>
                <div>Поставщик:</div>
                <ComboBox options={tariffs ?? []}
                          value={selectedTariffGroup}
                          disabled={isSupplierFixed}
                          onChange={setSelectedTariffGroup} />
                <div>Тарифная зона:</div>
                <ComboBox options={selectedTariffGroup?.opts ?? []}
                          value={selectedTariffs}
                          disabled={!selectedTariffGroup}
                          onChange={setSelectedTariffs} />
            </>
        }
    </VStack>;
};

const calculateTariffPrice = (tariffs: Tariff[], volume: Decimal) =>
    tariffs.map(x => x.priceWithTax).reduce((p, v) => p.add(v), new Decimal(0))
        .div(tariffs.length)
        .toNearest(0.01, Decimal.ROUND_UP)
        .mul(volume);

const CommonPageMethodTable = (x: {store: Store<CalculatorStore>; calculateMarketPrice: () => void}) => {
    const [currentMethod, marketPricesResult, simpleValue, simpleRatioValue, tariffs, selectedTariffs,
            volume, specification, taxPercent = 0]
        = useMappedStore(x.store, x => [
            x.currentMethod,
            x.marketPricesResult,
            x.simpleValue,
            x.simpleRatioValue,
            x.tariffs,
            x.selectedTariffs,
            x.specification.volume,
            x.specification,
            x.taxPercent
        ]);

    const acceptable = (m: CalculationMethod) => {
        if (m.type === "tariff") {
            if (specification.subjectDeclaration.subjectClass.code.includes("03.11"))
                return true;
            else
                return false;
        }
       return  m.type !== "unacceptable" &&
        (!!tariffs) &&
        (m.type !== "market-prices" || !!marketPricesResult);
    };

    const calculateMarketPrice = x.calculateMarketPrice;

    const getCalculatedValue =
        (type: CalculationMethod["type"], id: StartPriceMethod, withRatio: boolean, input?: boolean) => {
        const multiplier = withRatio ? simpleRatioValue ?? new Decimal(0) : new Decimal(1);

        switch (type) {
            case "simple": {
                if (withRatio) {
                    return id === currentMethod ? simpleValue?.minus(
                        simpleValue.div(1 + taxPercent / 100).minus(simpleValue).mul(-1))
                        .mul(simpleRatioValue ?? 1).toFixed(2) : "0.00";
                }

                if (input) {
                    return id === currentMethod ? <DecimalBox value={simpleValue ?? new Decimal(0)}
                                                       onChange={setSimpleValue}
                                                       precision={2}
                                                       precisionMode="INITIAL"/>
                                         : <TextBox value="0.00" disabled />;
                }

                return id === currentMethod ? simpleValue?.mul(simpleRatioValue ?? 1).toFixed(2) ?? "0.00" : "0.00";
            }
            case "market-prices": {
                if (withRatio) {
                    return id === currentMethod ? marketPricesResult?.minus(
                        marketPricesResult.div(1 + taxPercent / 100).minus(marketPricesResult).mul(-1))
                        .mul(simpleRatioValue ?? 1).toFixed(2) : "0.00";
                }

                return id === currentMethod ? marketPricesResult?.mul(simpleRatioValue ?? 1).toFixed(2) ?? "0.00" : "0.00";
            }
            case "tariff":
                return selectedTariffs
                    ? formatNumber(multiplier.mul(calculateTariffPrice(selectedTariffs, volume)))
                    : "0.00";
            case "unacceptable":
                return "0.00";
        }
    };

    return <RadioGroup>
        <Table<CalculationMethod> dataset={calculationMethods} columns={[
            Table.AutoColumn("", x => <>
                {
                    acceptable(x.item) &&
                    <Radio requiredValue={x.item.id} value={currentMethod} onChange={setCurrentMethod} />
                }
            </>),
            Table.Column("Метод", t => <>
                {
                    t.item.type === "market-prices"
                        ? <span style={{ textDecoration: "underline" }} onClick={calculateMarketPrice}>{t.item.name}</span>
                        : t.item.type === "tariff"
                            ? <TariffSelectionCell store={x.store}/>
                            : t.item.name
                }
            </>, { width: "3.5fr", cellStyle: { minWidth: "0" } }),
            Table.Column("НМЦ Рассчитанная", x => <>{getCalculatedValue(x.item.type, x.item.id, false, true)}</>, { width: "2.5fr" }),
            Table.Column("Коэффициент оптимизации заказчика", x => <>
                {
                    x.item.id === currentMethod
                        ? <DecimalBox value={simpleRatioValue} onChange={setSimpleRatioValue} precision={2} precisionMode="INITIAL"/>
                        : <TextBox value="1.00" disabled/>
                }
            </>, { width: "3fr" }),
            Table.Column("Цена за единицу", x => <>
                <span>{ getCalculatedValue(x.item.type, x.item.id, false) }</span>

            </>),
            Table.Column("Цена за единицу без НДС", x => <>
                <span>{ getCalculatedValue(x.item.type, x.item.id, true) }</span>
            </>, { width: "2fr" }),
            Table.Column("НМЦ Итоговая", x => <>{getCalculatedValue(x.item.type, x.item.id, true)}</>),
        ]}/>
    </RadioGroup>;
};

const CommonPageMethodTableSection = (x: {store: Store<CalculatorStore>; calculateMarketPrice: () => void}) => {
    return <Card title="НМЦ расчетная">
        <VStack spacing="15px">
            <CommonPageMethodTable store={x.store} calculateMarketPrice={x.calculateMarketPrice}/>
            <CommonPageMethodJustification store={x.store}/>
        </VStack>
    </Card>;
};

export const CommonPageResultSection = (x: {store: Store<CalculatorStore>; save: (result: StartPrice | undefined) => void})=> {
    const [specification, currentMethod, marketPricesResult, simpleValue, simpleRatioValue, taxPercent, selectedTariffs, volume]
        = useMappedStore(x.store, x => [x.specification, x.currentMethod, x.marketPricesResult, x.simpleValue, x.simpleRatioValue, x.taxPercent, x.selectedTariffs, x.specification.volume]);

    const methodType = calculationMethods.find(t => t.id === currentMethod);

    useEffect(() => {
        if (taxPercent === undefined) {
            setTaxPercent(20);
        }
    }, []);

    let resultStartPrice: Decimal;

    switch (methodType?.type) {
        case "simple":
            resultStartPrice = simpleValue ?? new Decimal(0);
            break;
        case "market-prices":
            resultStartPrice = marketPricesResult ?? new Decimal(0);
            break;
        case "tariff":
            resultStartPrice =
                calculateTariffPrice(selectedTariffs ?? [], volume);
            break;
        default:
            resultStartPrice = new Decimal(0);
    }

    const resultStartPriceMultiplied = resultStartPrice.mul(simpleRatioValue ?? 0);

    const canSave =
        !!currentMethod &&
        !!simpleRatioValue &&
        simpleRatioValue.gt(0) &&
        resultStartPrice.gt(0);

    const save = async () => {
        const state = x.store.getState();

        if (!state.currentMethod)
            return;

        if (!simpleRatioValue || simpleRatioValue.lte(0) || resultStartPrice.lte(0)) {
            alert("Не указана сумма");
            return;
        }

        const multiplied = resultStartPrice.mul(simpleRatioValue);

        let resultValue = resultStartPrice;
        let resultRatio = simpleRatioValue;
        let resultPerItem = multiplied.div(specification.volume).toNearest(0.01, Decimal.ROUND_DOWN);
        let forced = false;

        if (!resultPerItem.mul(specification.volume).eq(multiplied)) {
            const priceChangeAllowed =
                currentMethod !== "MARKET_PRICES" &&
                currentMethod !== "TARIFF";

            const suggestedRatio =
                specification.volume.mul(resultPerItem).div(resultStartPrice).toNearest(0.00000000001, Decimal.ROUND_UP);

            const r = await showModal(CalculationImbalanceModal, {
                priceOptions: priceChangeAllowed ? {
                    lower: specification.volume.mul(resultPerItem),
                    higher: multiplied.div(specification.volume).toNearest(0.01, Decimal.ROUND_UP).mul(specification.volume)
                } : undefined,
                suggestedRatio
            });

            if (!r) {
                return;
            }

            switch (r.type) {
                case "force":
                    forced = true;
                    break;
                case "price":
                    resultValue = r.value;
                    resultRatio = new Decimal(1);
                    resultPerItem = r.value.div(specification.volume);
                    setSimpleValue(resultValue);
                    setSimpleRatioValue(resultRatio);
                    break;
                case "ratio":
                    resultRatio = suggestedRatio;
                    setSimpleRatioValue(resultRatio);
                    break;
            }
        }

        const resultMultiplied = resultValue.mul(resultRatio);

        if (!forced) {
            if (!resultPerItem.mul(specification.volume).eq(resultMultiplied)) return;
            if (resultMultiplied.eq(0)) return;
        }

        x.save({
            method: state.currentMethod,
            justification: state.justification,
            ratio: resultRatio,
            taxPercent: new Decimal(taxPercent ?? 0),
            total: resultValue,
            perItem: resultPerItem
        });
    };

    return <Card title={
        <HSpread alignItems="center">
            <div>НМЦ</div>
            <HStack spacing="15px">
                { canSave && <Button icon="aSave" onClick={save}/> }
                <Button icon="aClose" color="red" onClick={() => x.save(undefined)}/>
            </HStack>
        </HSpread>
    }>
        <Table dataset={["single row!"]} columns={[
            Table.Column("Метод", () => <>{currentMethod && StartPriceMethodStrings[currentMethod]}</>),
            Table.Column("НМЦ", () => <>{formatNumber(resultStartPriceMultiplied)}</>),
            Table.Column("Ставка НДС", () => <ComboBox options={taxPercentOptions} value={taxPercent} onChange={setTaxPercent} />, { width: "2fr" }),
            Table.Column("Сумма НДС", () => <>{formatNumber(resultStartPriceMultiplied.div(1 + (taxPercent ?? 0) / 100).minus(resultStartPriceMultiplied).mul(-1))}</>),
            Table.Column("Валюта", () => <>Российский рубль</>),
            Table.Column("Ед. изм.", () => <>{specification.measurementUnit.name}</>)
        ]}/>
    </Card>;
};

export const CommonPage = (x: {store: Store<CalculatorStore>; calculateMarketPrice: () => void; save: (result: StartPrice | undefined) => void}) => {
    return <>
        <CommonPageInformationSection store={x.store}/>
        {/*<Card title="Расчет суммы лимита" />*/}
        {/*<Card title="Лимит" />*/}
        <CommonPageMethodTableSection store={x.store} calculateMarketPrice={x.calculateMarketPrice}/>
        <CommonPageResultSection store={x.store} save={x.save} />
    </>;
};
