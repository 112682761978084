import {CompatEnum, UnionStrings} from '../EnumStrings';

export type LotDocumentType =
    | "CONTRACT_PROJECT"
    | "CONTRACT_PROJECT_ELEC"
    | "TECH_SPECS"
    | "MSV_CALC"
    | "MSV_PROTOCOL"
    | "PRODUCT_REQUIREMENTS"
    | "PRESENTATION_MATERIALS"
    | "EXPLANATORY_NOTE"
    | "EXPECTING_EFFECT_INFO"
    | "MSV_CALC_INFO"
    | "MSV_EXPLANATION"
    | "PROJECT_DOCS"
    | "REQUEST_EVALUATION_PROCEDURE"
    | "PRODUCT_INFO"
    | "OTHER"
    | "TECH_SPECS_APPLICATIONS"
    | "PAYMENT_ORDER_FORM"
    | "BANK_WARRANTY_FORM"
    | "PURCHASE_APPROVE_REQUEST"
    | "CONCLUSION_CONFIRMATION_DOC"
    | "REQUEST_EXPLANATORY_LETTER"
    | "JOINT_PURCHASE_AGREEMENT"
    | "CONTRACT_COPY"
    | "CHARACTERISTICS_EXPLANATION";

export const LotDocumentType: CompatEnum<LotDocumentType> = {
    CONTRACT_PROJECT: "CONTRACT_PROJECT",
    CONTRACT_PROJECT_ELEC: "CONTRACT_PROJECT_ELEC",
    TECH_SPECS: "TECH_SPECS",
    MSV_CALC: "MSV_CALC",
    MSV_PROTOCOL: "MSV_PROTOCOL",
    PRODUCT_REQUIREMENTS: "PRODUCT_REQUIREMENTS",
    PRESENTATION_MATERIALS: "PRESENTATION_MATERIALS",
    EXPLANATORY_NOTE: "EXPLANATORY_NOTE",
    EXPECTING_EFFECT_INFO: "EXPECTING_EFFECT_INFO",
    MSV_CALC_INFO: "MSV_CALC_INFO",
    MSV_EXPLANATION: "MSV_EXPLANATION",
    PROJECT_DOCS: "PROJECT_DOCS",
    REQUEST_EVALUATION_PROCEDURE: "REQUEST_EVALUATION_PROCEDURE",
    PRODUCT_INFO: "PRODUCT_INFO",
    OTHER: "OTHER",
    TECH_SPECS_APPLICATIONS: "TECH_SPECS_APPLICATIONS",
    PAYMENT_ORDER_FORM: "PAYMENT_ORDER_FORM",
    BANK_WARRANTY_FORM: "BANK_WARRANTY_FORM",
    PURCHASE_APPROVE_REQUEST: "PURCHASE_APPROVE_REQUEST",
    CONCLUSION_CONFIRMATION_DOC: "CONCLUSION_CONFIRMATION_DOC",
    REQUEST_EXPLANATORY_LETTER: "REQUEST_EXPLANATORY_LETTER",
    CONTRACT_COPY: "CONTRACT_COPY",
    JOINT_PURCHASE_AGREEMENT: "JOINT_PURCHASE_AGREEMENT",
    CHARACTERISTICS_EXPLANATION: "CHARACTERISTICS_EXPLANATION"
};

export const LotDocumentTypeStrings: UnionStrings<LotDocumentType> = {
    CONTRACT_PROJECT: "Проект контракта",
    CONTRACT_PROJECT_ELEC: "Печатная форма электронного контракта",
    TECH_SPECS: "Техническое задание",
    MSV_CALC: "Расчет НМЦ",
    MSV_PROTOCOL: "Протокол НМЦ",
    PRODUCT_REQUIREMENTS: "Требования к товарам",
    PRESENTATION_MATERIALS: "Краткие презентационные материалы",
    EXPLANATORY_NOTE: "Пояснительная записка",
    EXPECTING_EFFECT_INFO: "Информация по ожидаемому социально-экономическому эффекту",
    MSV_CALC_INFO: "Информационные материалы по расчету НМЦ",
    MSV_EXPLANATION: "Обоснование НМЦ",
    PROJECT_DOCS: "Проектно-сметная документация",
    REQUEST_EVALUATION_PROCEDURE: "Порядок оценки заявок по критериям",
    PRODUCT_INFO: "Сведения о товаре (форма №2)",
    OTHER: "Другое",
    TECH_SPECS_APPLICATIONS: "Приложения к Техническому заданию",
    PAYMENT_ORDER_FORM: "Форма платежного поручения",
    BANK_WARRANTY_FORM: "Форма банковской гарантии",
    PURCHASE_APPROVE_REQUEST: "Запрос на согласование закупки ответственным отраслевым ОИВ",
    CONCLUSION_CONFIRMATION_DOC: "Документ для подтверждения бумажного заключения",
    REQUEST_EXPLANATORY_LETTER: "Пояснительное письмо о причинах подачи заявки на подтверждение",
    CONTRACT_COPY: "Копии договоров (соглашений), в соответствии со ст.15 44-ФЗ",
    CHARACTERISTICS_EXPLANATION: "Обоснование характеристик",
    JOINT_PURCHASE_AGREEMENT: "Соглашение о совместной закупке"
};

export const LotDocumentAllowedFiles: { [key: string]: string[] } = {
    [LotDocumentType.MSV_CALC]: ["xls", "xlsx"],
    [LotDocumentType.MSV_PROTOCOL]: ["pdf"]
};

export const LotDocumentAllowedFilesGeneric = ["doc", "docx", "xls", "xlsx", "pdf", "tiff", "jpg", "jpeg", "zip", "rar"];