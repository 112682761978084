import React, {CSSProperties, MouseEvent} from "react";
import * as styles from "./Label.module.css";
import questionMark from "@/assets/question.png";
import {showHint} from "@/EventBus";
import {Hint} from "@/components/vue/form-table/FormFields";

const presets = {
    medium: styles.medium,
    disabled: styles.disabled,
    readonlyTitle: styles.readonlyTitle,
    readonlyValue: styles.readonlyValue,
    headerLarge: styles.headerLarge,
    boldBig: styles.boldBig,
    boldSmall: styles.boldSmall,
    pageTitle: styles.pageTitle,
    thinMedium: styles.thinMedium,
};

type LabelProps = {
    className?: string
    style?: CSSProperties
    preset?: LabelPresets

    onClick?: (e: MouseEvent) => void

    required?: boolean
    hint?: Hint | string
} & ({ text: string; children?: never } | { children: React.ReactNode });

export type LabelPresets = keyof typeof presets;

const InlineHint: React.FC<{ hint: Hint | string }> = ({hint}) =>
    <span className={styles.labelRequired} onClick={e => showHint(e, hint)}>
        <img src={questionMark} alt="question mark"/>
    </span>;

interface HintMarkProps {
    hint: Hint | string
    children?: []
    className?: string
    style?: CSSProperties
}

export const HintMark: React.FC<HintMarkProps> = x =>
    <div className={`${styles.label} ${x.className ?? ""}` } style={x.style}>
        <InlineHint hint={x.hint} />
    </div>;


export const Label: React.FC<LabelProps> = x =>
    <label className={`${styles.label} ${presets[x.preset!] ?? ""} ${x.className ?? ""}` } style={x.style} onClick={x.onClick}>
        { (x as {text?: string}).text ?? x.children }
        { x.required && <span className={styles.labelRequired}>*</span> }
        { x.hint && <InlineHint hint={x.hint}/> }
    </label>;