import React, { CSSProperties, useContext } from "react";
import { Label, LabelPresets } from "@/components/primitive";
import { Grid, LayoutPropsWithoutGeneric, Stack, VStack } from "@/components/layouts";
import * as styles from "./Field.module.css";
import { FormStateContext } from "@/components/form/FormAttached";
import { Hint } from "@/components/vue/form-table/FormFields";

interface FieldProps {
    title: React.ReactNode
    required?: boolean
    preset?: LabelPresets
    hidden?: boolean
    hint?: Hint
    children: React.ReactNode
}

export const Field: React.FC<FieldProps> = x => {
    const state = useContext(FormStateContext);
    const preset = x.preset ?? (state === "readonly" ? "readonlyTitle" : undefined);

    return x.hidden ? null : <>
        <Label preset={preset} hint={x.hint} required={x.required}>{x.title}</Label>
        {x.children}
    </>;
};

export const FieldInset: React.FC = x =>
    <>
        <div className={styles.fieldInset}>
            {x.children}
        </div>
        <div className={styles.fieldInsetAfter}/>
    </>;

export const VertField: React.FC<FieldProps & { spacing?: string }> = x =>
    x.hidden ? null : <>
        <VStack spacing={x.spacing ?? "5px"} outerStyle={{ gridColumn: "span 2" }}>
            <Label required={x.required}>{x.title}</Label>
            {x.children}
        </VStack>
        {/*
            div below will be treated as normal part of FieldStack,
            but removed from FieldGrid layout and won't destroy layout
        */}
        <div style={{ display: "none" }}/>
    </>;

interface FieldsProps {
    className?: string
    style?: CSSProperties
    spacing?: string
    children: React.ReactNode
    orientation: "vertical" | "horizontal"
}

export const Fields: React.FC<FieldsProps> = x =>
    x.orientation === "vertical"
        ? <FieldStack spacing={x.spacing} style={x.style} className={x.className}>{x.children}</FieldStack>
        : <FieldGrid spacing={x.spacing} style={x.style} className={x.className}>{x.children}</FieldGrid>;

export const FieldGrid: React.FC<LayoutPropsWithoutGeneric> = x =>
    <Grid {...x} orientation="vertical" areas="3fr 8fr" spacing={x.spacing ?? "15px"} alignItems="center" />;

export const FieldStack: React.FC<LayoutPropsWithoutGeneric> = x =>
    <Stack {...x} orientation="vertical" spacing={x.spacing ?? "15px"} innerClassName={styles.fieldStack + " " + (x.className ?? "")}/>;
