// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, "._2I7mYzjggn3Acbxvo6Tm8N {\n    background-color: white;\n\n    position: absolute;\n    width: calc(100%);\n    height: calc(100% - 0px);\n    transition: 0.3s;\n}\n\n._1ryxXSeWhabeOWWwEpk3tZ {\n    display: flex;\n    justify-content: flex-start;\n    align-items: stretch;\n    height: calc(100% - 68px);\n}\n\n._1EQsDbSI3t2otkOqLXyWMz {\n    width: 100%;\n    display: block;\n    overflow: auto;\n}\n\n._2kcWYv5Z7vbj7MmpQQrQPZ {\n    margin: 20px 30px;\n}\n\n._3fM50fQNdwl3EAuCvfiPf- {\n    position: relative;\n}\n\n.t1HH4wqUhrn2yBDNXdk27 {\n    display: flex;\n    align-items: center;\n    flex-wrap: wrap;\n    text-transform: uppercase;\n    margin: 0;\n    justify-content: center;\n    border-top: 1px solid #e6e6e6;\n    list-style-type: none;\n    padding-left: 0;\n    font-size: 11px;\n    font-weight: 300;\n    transition: 0.3s;\n}\n\n._3fOR53OwQS1YVTGRC33_hz {\n    padding: 10px 50px;\n}\n\n._3fOR53OwQS1YVTGRC33_hz:before {\n    margin-left: 35px;\n    margin-right: 5px;\n    width: 8px;\n    height: 8px;\n    content: '';\n    border-radius: 4px;\n    display: inline-block;\n}\n\n._17E1jp8b8FeyLa9n76PvkE:before {\n    background-color: rgba(255, 102, 102, 0.4);\n}\n\n._2csorm4X7SD8HnRGq0L-lW:before {\n    background-color: rgba(77, 178, 255, 0.4);\n}\n\n.wjwlz5neVs4kwqyHE6AAC:before {\n    background-color: rgba(248, 203, 78, 0.45);\n}\n\n.pb5MkrZA6Qe4lmVSahYM7 {\n    position: absolute;\n    font-family: \"FontAwesome\";\n    transform: translateY(calc(100%));\n    top: -35px;\n    left: 50%;\n    cursor: pointer;\n    font-weight: 900;\n    z-index: 1;\n}\n\n.pb5MkrZA6Qe4lmVSahYM7::after {\n    content: '⌄︁';\n    transition: 0.3s;\n    position: absolute;\n    right: 50%;\n    transform: rotate(0deg);\n    top: 14px;\n    font-size: 14px;\n    font-weight: 900;\n    opacity: 0.4;\n}\n\n._3vTaIT7k45wPpkuJn3gCQi::after {\n    transform: rotate(180deg);\n}", ""]);
// Exports
exports.locals = {
	"directPurchasePage": "_2I7mYzjggn3Acbxvo6Tm8N",
	"contentContainer": "_1ryxXSeWhabeOWWwEpk3tZ",
	"tableContainer": "_1EQsDbSI3t2otkOqLXyWMz",
	"pager": "_2kcWYv5Z7vbj7MmpQQrQPZ",
	"tableInfoBlock": "_3fM50fQNdwl3EAuCvfiPf-",
	"tableInfoColorLegend": "t1HH4wqUhrn2yBDNXdk27",
	"circle": "_3fOR53OwQS1YVTGRC33_hz",
	"red": "_17E1jp8b8FeyLa9n76PvkE",
	"blue": "_2csorm4X7SD8HnRGq0L-lW",
	"yellow": "wjwlz5neVs4kwqyHE6AAC",
	"openTableInfoIcon": "pb5MkrZA6Qe4lmVSahYM7",
	"openTableInfoIconClosed": "_3vTaIT7k45wPpkuJn3gCQi"
};
module.exports = exports;
