












import { Component, Prop, Vue, Watch } from "vue-property-decorator";
import { MutatingFieldBase } from "@/components/vue/form-table/FieldBase";
import { mixins } from "vue-class-component";

@Component
export default class FileField extends mixins(MutatingFieldBase) {
    @Watch("fieldValue") public reset(n: File | null) {
        const file = this.$refs.file as HTMLInputElement | undefined;
        if (!n && file) file.value = null!;
    }
}
