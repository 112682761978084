import {UnionStrings} from './EnumStrings';

export type SmpType = "NO" | "SUBCONTRACTING" | "FULL";
export type SmpTypeShort = "NO" | "SUBCONTRACTING";

/* @deprecated Left for compatibility */
export const SmpType: { [key in SmpType]: SmpType } = {
    NO: "NO",
    SUBCONTRACTING: "SUBCONTRACTING",
    FULL: "FULL"
};

export const SmpTypeShort: { [key in SmpTypeShort]: SmpTypeShort } = {
    NO: "NO",
    SUBCONTRACTING: "SUBCONTRACTING",
};

export const SmpTypeStrings: UnionStrings<SmpType> = {
    NO: "Нет",
    SUBCONTRACTING: "Субподряд",
    FULL: "Полностью",
};

export const SmpTypeStringsShort: UnionStrings<SmpTypeShort> = {
    NO: "Нет",
    SUBCONTRACTING: "Субподряд",
};