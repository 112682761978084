










import { FieldAlignment, FieldWidth, valueSpanContext, FComponent } from "./FComponent";
import { Component, Prop, Vue, Inject, Mixins } from "vue-property-decorator";
import { FValueComponent } from './FValueComponent';

@Component
export default class FCheckbox extends Mixins(FValueComponent) {
    public get commonProps() {
        return { class: this.classes };
    }

    public get editableProps() {
        const { disabled } = this;

        return { ...this.commonProps, disabled };
    }

    public onInput(e: Event) {
        const target = e.target as HTMLInputElement;

        this.$emit("input", target.checked);
    }
}
