import { getYear, getMonth, getDate, set, subMinutes, getHours, getMinutes } from "date-fns";

export type DateFormatMode = "decades" | "years" | "months" | "days" | "time";

const padInt = (num: number, digits: number = 2) => (num | 0).toString().padStart(digits, "0");

export const formatDate = (d: Date, mode: DateFormatMode = "days"): string => {
    switch (mode) {
        case "decades": {
            const start = Math.trunc(getYear(d) / 10) * 10;
            return `${start}—${start + 9}`;
        }
        case "years":
            return getYear(d).toString();
        case "months":
            return `${padInt(getMonth(d) + 1)}.${getYear(d)}`;
        case "days":
            return `${padInt(getDate(d))}.${padInt(getMonth(d) + 1)}.${getYear(d)}`;
        case "time":
            return `${padInt(getDate(d))}.${padInt(getMonth(d) + 1)}.${getYear(d)} ${padInt(getHours(d))}:${padInt(getMinutes(d))}`;
    }
};

export const extractDate = (d: Date): Date =>
    subMinutes(
        set(d, { hours: 0, minutes: 0, seconds: 0, milliseconds: 0 }),
        d.getTimezoneOffset());

export const truncateDate = (d: Date, mode: DateFormatMode): Date => {
    const date = extractDate(d);

    switch (mode) {
        case "days": return date;
        case "months": return set(date, { date: 1 });
        case "years": return set(date, { date: 1, month: 0 });
        case "decades": return set(date, { date: 1, month: 0, year: Math.trunc(getYear(date) / 10) * 10 });
        case "time": return set(date, { date: 1, month: 0, year: Math.trunc(getYear(date) / 10) * 10 }); // no idea?
    }
};

export const getCurrentYear = () => getYear(new Date());
