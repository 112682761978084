import { uuid } from "@/models/parsing";
import { createEvent, createStore, Store } from "effector";
import { ContractPayment } from "@/models/Contracts/ContractPayment";
import { getContractPayment, getContractPaymentHistory } from "@/api/ContractPayments";
import { HistoryItem } from "@/models";

export type PaymentStoreState = {
    payment?: ContractPayment
    history: HistoryItem[]
};

export type PaymentStore = {
    store: Store<PaymentStoreState>
};

export const createPaymentStore = (id: uuid): PaymentStore => {
    const setPayment = createEvent<ContractPayment>();
    const setHistory = createEvent<HistoryItem[]>();

    const store = createStore<PaymentStoreState>({
        payment: undefined,
        history: []
    })
        .on(setPayment, (s, payment) => ({ ...s, payment }))
        .on(setHistory, (s, history) => ({ ...s, history }));

    getContractPayment(id).then(setPayment);
    getContractPaymentHistory(id).then(setHistory);

    return {
        store
    };
};
