import {AutoDto, AutoModel, uuid} from "@/models/parsing";

interface LotDocumentTermMD {
    id?: string
    name: string
    definition: string
}

export type DtoLotDocumentTerm = AutoDto<LotDocumentTermMD>;
export type LotDocumentTerm = AutoModel<LotDocumentTermMD>;

export const LotDocumentTerm = {
    toDto(term: LotDocumentTerm): DtoLotDocumentTerm {
        return {
            ...term
        };
    },
    fromDto(term: DtoLotDocumentTerm): LotDocumentTerm {
        return {
            ...term,
            id: term.id ?? undefined
        };
    }
};
