// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, "._3-isx3w2awAISifqbKJe19 {\n    color: #2e4358;\n    font-weight: 500;\n    font-size: 14px;\n    padding: 10px 20px;\n    max-width: 140px;\n    border: 1px solid transparent;\n    box-sizing: border-box;\n    border-radius: 3px;\n}\n\n._3-isx3w2awAISifqbKJe19:hover {\n    background: #c9cfdc;\n    border-color: #acb3c5;\n}\n\n._3-isx3w2awAISifqbKJe19 > ._3t9kMGn49qcUmBne8AFagx, ._3-isx3w2awAISifqbKJe19 > ._25YexH6Y5xGN6qZQMSPT-n {\n    display: inline-block;\n    border-bottom: 1px dashed #2e4358;\n}\n\n._3-isx3w2awAISifqbKJe19 > ._25YexH6Y5xGN6qZQMSPT-n {\n    cursor: pointer;\n    font-weight: 600;\n}\n\n._3-isx3w2awAISifqbKJe19 > ._2VpTez97pF0w7USnBA82cj {\n    cursor: pointer;\n    text-overflow: ellipsis;\n    overflow: hidden;\n    margin-top: 10px;\n}\n\n._3-isx3w2awAISifqbKJe19:hover > ._2VpTez97pF0w7USnBA82cj {\n    text-overflow: unset;\n    overflow: visible;\n    word-break: break-all;\n}", ""]);
// Exports
exports.locals = {
	"relationItem": "_3-isx3w2awAISifqbKJe19",
	"singleItem": "_3t9kMGn49qcUmBne8AFagx",
	"multiItem": "_25YexH6Y5xGN6qZQMSPT-n",
	"object": "_2VpTez97pF0w7USnBA82cj"
};
module.exports = exports;
