import React from "react";
import {Field, Fields} from "@/components/form/Field";
import {CheckBox, Label} from "@/components/primitive";
import {Card} from "@/components/Card";

export const SubjectClassDeliveryAddresses: React.FC = x => {
    return <Card>
        <Fields orientation="horizontal">
            <Field title="Учреждение/предприятие УИС">
                <CheckBox value={false} disabled />
            </Field>
            <Field title="Организация инвалидов">
                <CheckBox value={false} disabled />
            </Field>
            <Field title="Социально ориентированная некоммерческая организация">
                <CheckBox value={false} disabled />
            </Field>
            <Field title="ФИО руководителя">
                <Label>

                </Label>
            </Field>
            <Field title="Должность руководителя">
                <Label>

                </Label>
            </Field>
            <Field title="Регулируемая организация">
                <CheckBox value={false} disabled />
            </Field>
        </Fields>
    </Card>;
};