// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, "._3V9Tilwlrus_t1vWkjuKOZ > * {\n    vertical-align: middle;\n}\n\n._13fhscNmDeS8lWquYm-M_s {\n    vertical-align: middle;\n    margin: 0 10px 0 0;\n}\n\n.PKkl7zwLdewxCx3Vv6-jv {\n    margin-right: 0;\n}", ""]);
// Exports
exports.locals = {
	"component": "_3V9Tilwlrus_t1vWkjuKOZ",
	"input": "_13fhscNmDeS8lWquYm-M_s",
	"inputEmpty": "PKkl7zwLdewxCx3Vv6-jv"
};
module.exports = exports;
