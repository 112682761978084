// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, "._3zmKQ0LNSzRF22v4NEfKb3 {\n    display: grid;\n    grid-template-rows: repeat(8, auto);\n    grid-template-columns: 3fr 4fr 3fr;\n    grid-auto-flow: column;\n    grid-gap: 15px;\n    align-items: center;\n}\n\n._3ZFpXz1EMieiXcFKJNaXW1 {\n    text-align: right;\n    font-size: 14px;\n}\n\n._1juAXqgdcMDwFVXF2bizo_ {\n    display: grid;\n    grid-template-columns: 3fr 2fr 1fr 2fr 1fr 2fr 3fr;\n    grid-template-rows: repeat(3, auto);\n    grid-gap: 15px;\n    align-items: center;\n}\n\n._1juAXqgdcMDwFVXF2bizo_ > :nth-child(2) {\n    grid-column: span 5;\n}\n\n._1juAXqgdcMDwFVXF2bizo_ > :nth-child(3) {\n    grid-column: 1;\n}\n\n._1juAXqgdcMDwFVXF2bizo_ > :nth-child(9) {\n    grid-column: 1;\n    margin-right: auto;\n}\n\n._1juAXqgdcMDwFVXF2bizo_ > :nth-child(10) {\n    grid-column: 3;\n}\n\n._1juAXqgdcMDwFVXF2bizo_ > :nth-child(12) {\n    grid-column: 7;\n    margin-left: auto;\n}\n\n._2irwxgxl-zMfOMLUmF75SH {\n    font-size: 14px;\n    color: red;\n    font-style: italic;\n    margin-bottom: 10px;\n}\n\n._2aes4FiLi4Ug0ENE6QNRSZ {\n    background: #D5DAE6;\n    padding: 10px;\n    border-radius: 3px;\n    font-size: 14px;\n    text-align: center;\n\n    font-weight: 700;\n}\n\n._2OKw79frHx-ccSF1YuglB_ {\n    visibility: hidden;\n    height: 0;\n    overflow: hidden;\n    margin: 0;\n}", ""]);
// Exports
exports.locals = {
	"informationCard": "_3zmKQ0LNSzRF22v4NEfKb3",
	"informationCardLabel": "_3ZFpXz1EMieiXcFKJNaXW1",
	"marketPriceInformationCard": "_1juAXqgdcMDwFVXF2bizo_",
	"marketPriceCalculationError": "_2irwxgxl-zMfOMLUmF75SH",
	"noInfo": "_2aes4FiLi4Ug0ENE6QNRSZ",
	"hidden": "_2OKw79frHx-ccSF1YuglB_"
};
module.exports = exports;
