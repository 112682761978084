export type Role = {
    id: number
    name: string
    desc: string
};

export const mockRoles: Role[] = [
    {
        id: 169,
        name: "Председатель комиссии",
        desc: "Председатель оценочной комиссии по проведению закупки; в перспективе — утверждающий протоколы."
    },
    {
        id: 1243804,
        name: "Электронный магазин – чтение",
        desc: "Роль предназначена для пользователей, которые имеют право на просмотр сведений об офертах, добавленных в корзину заказчика, и котировочных сессиях (электронный магазин"
    },
    {
        id: 1243805,
        name: "Электронный магазин – просмотр и запись",
        desc: "Роль предназначена для пользователей, которые имеют право на просмотр и изменение сведений об офертах, добавленных в корзину заказчика, и котировочных сессиях (электронный магазин)"
    },
    {
        id: 167,
        name: "Эксперт НМЦ",
        desc: "Роль предназначена для сотрудника экспертной организации, проводящий экспертизу НМЦ и готовящий экспертное заключение (без права подписания)"
    },
    {
        id: 160,
        name: "Руководитель ГЗ",
        desc: "Должностное лицо заказчика, обладающее правом подписи документов"
    },
    {
        id: 174,
        name: "Реестр контрактов - чтение",
        desc: "Роль предназначена для пользоватлей, которые имеют право на просмотр сведений о контрактах в рамках 44-ФЗ"
    },
    {
        id: 173,
        name: "Реестр контрактов -  просмотр и запись",
        desc: "Роль предназначена для пользователей, которые имеют право на просмотр и изменение сведений о контрактах в рамках 44-ФЗ"
    },
    {
        id: 157,
        name: "Размещение - просмотр и запись",
        desc: "Оператор заказчика, формирующий сведения о процессе публикации закупок и внесения в них изменений"
    },
    {
        id: 155,
        name: "Планирование - просмотр и запись",
        desc: "Оператор заказчика, формирующий сведения о процессе планирования"
    },
    {
        id: 212,
        name: "Оператор КПГЗ/СПГЗ",
        desc: "Роль предназначена для специалистов, отвечающих за ведение справочников КПГЗ, СПГЗ, обработку заявок на добавление и изменение элементов КПГЗ/СПГЗ и ведение характеристик"
    },
    {
        id: 1201,
        name: "Планирование – чтение",
        desc: "Оператор заказчика, обладающий полномочиями на просмотр сведений о процессе планирования"
    },
    {
        id: 1202,
        name: "Размещение – чтение",
        desc: "Оператор заказчика, обладающий полномочиями на просмотр сведений о процессе публикации закупок без внесения в них изменений"
    },
    {
        id: 1015,
        name: "Член комиссии",
        desc: "Член оценочной комиссии по проведению закупки; в перспективе — лично голосующий за свои решения в процессе формирования протоколов с использованием электронной подписи"
    },
    {
        id: 736708,
        name: "Просмотр КПГЗ/СПГЗ",
        desc: "Роль предназначена для просмотра справочника КПГЗ/СПГЗ"
    },
    {
        id: 1002,
        name: "Администратор Библиотеки ТЗ",
        desc: "Роль для пользователей, которые формируют и размечают шаблоны технических заданий для последующего формирования ТЗ в Планировании закупки."
    },
    {
        id: 1011,
        name: "Секретарь комиссии (оценочной)",
        desc: "Роль предназначена для формирования сведений о протоколах проведения закупки, в том числе ввода сведений о голосовании членов комиссии до реализации механизма личного голосования"
    },
    {
        id: 1102,
        name: "Формирование комиссии",
        desc: "Специалист, отвечающий за формирование приказов и комиссий"
    },
    {
        id: 1278955,
        name: "Формирование обращений в службу технической поддержки",
        desc: "Роль для пользователей, которые могут формировать обращения в техническую поддержку через систему ЕАИСТ."
    },
    {
        id: 1942203,
        name: "Тест регр 85",
        desc: "еуые11109"
    },
    {
        id: 1942454,
        name: "Показывать при регистрации-1",
        desc: "Показывать при регистрации"
    },
    {
        id: 656297,
        name: "Подача заявок на КПГЗ/СПГЗ",
        desc: "Роль для сотрудников ГРБС или сотрудников, определённых распоряжением ГРБС, ответственных за подачу заявок на добавление/изменение КПГЗ/СПГЗ"
    },
    {
        id: 1288771,
        name: "Администратор шаблонов типовых документов процедуры",
        desc: "Роль для пользователей, которые формируют шаблоны типовых документов процедуры, размечают и утверждают шаблоны типовых документов процедуры для последующего формирования типовой документации в процедуре"
    },
    {
        id: 786047,
        name: "Положение о закупках",
        desc: "Сотрудник, отвечающий за формирование и регистрацию Положения о закупках в рамках 223-ФЗ."
    },
    {
        id: 1638954,
        name: "Планирование - просмотр и запись (без утверждения)",
        desc: "Планирование - просмотр и запись (без утверждения)"
    },
    {
        id: 1894766,
        name: "АвтоТестовая роль Глобальный администратор (не трогать)",
        desc: "АвтоТестовая роль Глобальный администратор (не трогать)"
    },
    {
        id: 2121606,
        name: "все права например",
        desc: "все права например"
    },
    {
        id: 2128208,
        name: "Тест регр",
        desc: "Тост"
    },
    {
        id: 2189706,
        name: "Тест регр 19/11",
        desc: "Тест регр 19/11"
    },
    {
        id: 2278606,
        name: "Тест регресс 03/02",
        desc: "вкра"
    },
    {
        id: 2261066,
        name: "Тест регресс 21/01",
        desc: "Регресс 21/01/2021"
    },
    {
        id: 1995904,
        name: "Тестовая роль 04.06.2020",
        desc: "Роль для теста\nРедактированный текст"
    },
    {
        id: 2166104,
        name: "Роль для Тестирования 223-ФЗ",
        desc: "Роль для Тестирования 223-ФЗ"
    },
    {
        id: 2173508,
        name: "0511",
        desc: "0511Тест"
    },
    {
        id: 2206956,
        name: "Регресс тест роль",
        desc: "Регресс 02/12"
    },
    {
        id: 2088754,
        name: "глоб админ без управления шаблонами",
        desc: "тестовая роль"
    }
];