














import { ITableColumn, IRowConfiguration, SelectionColumnControl } from "@/components/TableAbstractions";
import { Component, Vue, Prop } from "vue-property-decorator";
import DataTable from "@/components/vue/DataTable.vue";
import { SimpleRow } from '../../components/vue/form-table/FormFields';
import { IFieldProps } from '../../components/vue/form-table/FieldBase';
import { ContractScope } from './ContractScope';
import { ContractResponsiblePerson } from "@/models/Contracts/ContractResponsiblePerson";
import { ObjectStatus } from "@/models/enums";
import EventBus from "@/EventBus";
import AddResponsiblePerson from "@/views/CreateContract/AddResponsiblePerson.vue";
import _ from "lodash";
import { mockPersons } from "@/views/ComposedLot/shared/store/relatedData";

@Component({ components: { DataTable } })
export default class ResponsiblePerson extends Vue {
    @Prop() public props!: IFieldProps<SimpleRow<ContractScope>>;

    public get headers(): ITableColumn<ContractResponsiblePerson>[] {
        return [
            {
                title: "Фамилия",
                getter: p => p.lastName,
            },
            {
                title: "Имя",
                getter: p => p.firstName,
            },
            {
                title: "Отчество",
                getter: p => p.patronymic,
            },
            {
                title: "Электронная почта",
                getter: p => p.email,
            },
            {
                title: "Контактный телефон",
                getter: p => p.phone,
            },
        ];
    }

    public async addEditPerson(src: ContractResponsiblePerson | null) {
        const props = {
            existing: src, 
            data: this.responsibleMockPersons
        };

        const result = await EventBus.callModal<typeof props, ContractResponsiblePerson>(AddResponsiblePerson, props);
        if (result.isOk) {
            const r = result.getResult();
            //update person somehow
            this.props.source.contract.responsiblePerson = r;
        }
    }

    public createConfig(): IRowConfiguration<ResponsiblePerson> {
        const that = this;
        
        return  {
            get useSlotForActionColumn() {
                return that.props.state === "ENABLED";
            }
        };
    }

    public get responsibleMockPersons(): ContractResponsiblePerson[] {
        return mockPersons.map((p, i) => new ContractResponsiblePerson(i.toString(), p.firstName, p.lastName, p.middleName, p.email, p.cellPhone, p.isGeneral));
    }

    public config = this.createConfig();

    public get dataset() { 
        const set = (this.props.source.contract.responsiblePerson ?  [this.props.source.contract.responsiblePerson] : [{firstName: null, lastName: null, patronymic: null, email: null, phone: null, isMain: false}]) as ContractResponsiblePerson[];
        return set;
    }
}
