import React, {CSSProperties, createRef, useEffect, useState} from "react";

import {ModalFC, showModal} from "@/EventBus";
import {Address} from "@/models/ComposedLots/specifications";
import {CardModal} from "@/components/modals/CardModal";
import {HStack, VGrid, VStack} from "@/components/layouts";
import {RoundedButton} from "@/components/primitive/RoundedButton";
import {CheckBox, Label, Radio, RadioGroup, TextBox} from "@/components/primitive";
import {AddressType} from "@/models/enums";
import styles from "./PickAddressModal.module.css";
import {debouncedAsync, droppedByDebouncer} from "@/functionUtils";
import { PickAddressFromListModal } from "@/modal-views/PickAddressFromListModal";

interface PickAddressModal {
    existing?: Address
}

interface AddressSearchResult {
    aoguid: string
    aolevel: string
    formalname: string
    shortname: string
    parent?: AddressSearchResult
    directHouseCnt: number
}

interface HouseSearchResult {
    houseguid: string
    aoguid: string
    housenum: string
    buildnum: string
    strucnum: string
    postalcode: string
}

interface SearchResult {
    aoguid: string
    houseguid: string | null
    formalname: string
    fullname: string

    original?: AddressSearchResult
    parent?: AddressSearchResult
    isStreet: boolean
}

const matchAllDigits = (s: string) => {
    const re = /(\d+)/g;
    let m: RegExpExecArray | null = null;
    const result: string[] = [];

    do {
        m = re.exec(s);
        if (m) {
            result.push(m[1]);
        }
    } while (m);

    return result;
};

const sendFiasRequest = async (address: string, parent: AddressSearchResult | undefined): Promise<SearchResult[]> => {
    const isStreet = parent ? parent.directHouseCnt > 0 : false;
    if (isStreet) {
        return await fetch(`/fias/api/search/address/${parent!.aoguid}/houses`, {
            method: "GET",
            mode: "cors",
        })
            .then(response => response.json())
            .then(response => {
                const result = response as HouseSearchResult[];
                return result.filter(x => {
                    const digitGroups = matchAllDigits(address);

                    for (const dg of digitGroups) {
                        if (x.buildnum === dg || x.housenum === dg || x.strucnum === dg)
                            return true;
                    }

                    return false;
                }).map(x => ({
                    aoguid: x.aoguid,
                    houseguid: x.houseguid,
                    formalname: x.housenum,
                    fullname: `д. ${x.housenum}${x.buildnum ? ', корп. ' + x.buildnum : ''}${x.strucnum ? ', стр. ' + x.strucnum : ''}`,
                    parent: parent!,
                    isStreet: false
                }));
            });
    } else {
        return await fetch(`/fias/api/search/address`, {
            method: "POST",
            mode: "cors",
            headers: {
                "Content-Type": "application/json",
            },
            body: JSON.stringify({
                text: address,
                aoguid: parent ? parent.aoguid : null
            }),
        })
            .then(response => response.json())
            .then(response => {
                const result = response.searchResults as AddressSearchResult[];
                return result.map(x => ({
                    aoguid: x.aoguid,
                    houseguid: null,
                    formalname: x.formalname,
                    fullname: x.shortname + " " + x.formalname,
                    parent: x.parent,
                    isStreet: x.directHouseCnt > 0,
                    original: x
                }));
            });
    }
};

interface FiasTextBoxProps {
    value: string | undefined
    onChange: (x: [string, SearchResult | undefined]) => void

    options: SearchResult[]
    disabled?: boolean
    style?: CSSProperties
}

const formatResult = (result: SearchResult) => {
    const resultArr = [];

    let parent = result.parent;
    while (parent) {
        resultArr.push(`${parent.shortname} ${parent.formalname}`);
        parent = parent.parent;
    }

    resultArr.reverse();
    resultArr.push(`${result.fullname}`);

    return resultArr.join(", ");
};

const FiasTextBox = (x: FiasTextBoxProps) => {
    const [requireAutoFocus, setRequireAutoFocus] = useState(false);
    const textBoxRef = createRef<HTMLInputElement>();

    useEffect(() => {
        if (requireAutoFocus && textBoxRef.current) {
            textBoxRef.current.focus();
            textBoxRef.current.setSelectionRange(x.value?.length ?? 0, x.value?.length ?? 0);
        }

        setRequireAutoFocus(false);
    });

    const selectResult = (result: SearchResult) => {
        const line = formatResult(result) + " "; // extra space for easy input

        setRequireAutoFocus(true);
        x.onChange([line, result]);
    };

    return <div className={styles.fiasBox} >
        <TextBox value={x.value} onChange={v => x.onChange([v, undefined])} ref={textBoxRef}
                 className={styles.fiasTextBox} disabled={x.disabled} style={x.style}/>

        {
            x.options.length > 0 && <div className={styles.fiasDropdownWrap}>
                <div className={styles.fiasDropdown} tabIndex={-1}>
                    {x.options.map((x, i) => <div key={i} onClick={() => selectResult(x)}>{formatResult(x)}</div>)}
                </div>
            </div>
        }
    </div>;
};

const parseInput = (prevResult: SearchResult | undefined, val: string) => {
    if (!prevResult) return { address: val, parent: undefined };

    const nodeArr = [];

    let parent = prevResult.parent;
    while (parent) {
        nodeArr.push(parent);
        parent = parent.parent;
    }

    nodeArr.reverse();
    if (prevResult.original)
        nodeArr.push(prevResult.original);

    let prevParent: AddressSearchResult | undefined;
    let tailIx = 0;
    for (const node of nodeArr) {
        const ixOf = val.indexOf(node.formalname, tailIx);
        if (ixOf === -1) {
            break;
        }

        prevParent = node;
        tailIx = ixOf + node.formalname.length;
    }

    return { address: val.substring(tailIx), parent: prevParent };
};

export const PickAddressModal: ModalFC<PickAddressModal, Address> = x => {
    const [{d: debouncedFiasInput}] = useState({d: debouncedAsync(200, sendFiasRequest) });

    const [type, setType] = useState<AddressType>(x.existing !== undefined ? x.existing!!.type : "FIAS");
    const [fiasAddressLine, setFiasAddressLine] = useState<string | undefined>(
        x.existing?.type === "FIAS" || x.existing?.type === "LANDMARK" ? x.existing.addressLine : undefined
    );
    const [fiasLineOptions, setFiasLineOptions] = useState<SearchResult[]>([]);
    const [fiasLastSelectedResult, setFiasLastSelectedResult] = useState<SearchResult | undefined>();

    const [outsideAddressLine, setOutsideAddressLine] = useState<string | undefined>(
        x.existing?.type === "OUTSIDE_OF_RF" ? x.existing.addressLine : undefined
    );
    const [additionalInfo, setAdditionalInfo] = useState<string | undefined>(
        x.existing?.type === "LANDMARK" ? x.existing.additionalInfo : undefined
    );

    const changeType = (t: AddressType) => {
        if (type === t) return;
        setType(t);

        setFiasAddressLine(undefined);
        setFiasLastSelectedResult(undefined);
        setOutsideAddressLine(undefined);
        setAdditionalInfo(undefined);
    };

    const changeFiasAddressLine = async ([line, result]: [string, SearchResult | undefined]) => {
        try {
            setFiasAddressLine(line);

            if (!result) {
                if (line.length >= 3) {
                    const { address, parent } = parseInput(fiasLastSelectedResult, line);
                    setFiasLineOptions(await debouncedFiasInput(address, parent));
                } else {
                    setFiasLineOptions([]);
                }
            } else {
                setFiasLastSelectedResult(result);
                setFiasLineOptions([]);
            }
        }
        catch (err) {
            if (err === droppedByDebouncer) return;

            throw err;
        }
    };

    let topAddressLine: string | undefined;
    switch (type) {
        case "LANDMARK":
        case "FIAS": topAddressLine = fiasAddressLine; break;
        case "BTI": topAddressLine = undefined; break;
        case "OUTSIDE_OF_RF": topAddressLine = outsideAddressLine; break;
    }

    const save = () => {
        if (!topAddressLine) return;

        const addr: Address = {
            type: type as "LANDMARK",
            addressLine: topAddressLine,
            additionalInfo: additionalInfo ?? ""
        };

        x.done(addr);
    };

    const pickFromList = async () => {
        const addressFromList = await showModal(PickAddressFromListModal, {existing: topAddressLine});
        if(addressFromList) {
            changeType("FIAS");
            setFiasAddressLine(addressFromList);
        }
    };

    const bottom = <HStack reverse spacing="15px">
        <RoundedButton color="white" title="Закрыть" onClick={() => x.done(undefined)}/>
        <RoundedButton color="blue" title="Сохранить" onClick={save}/>
    </HStack>;

    return <CardModal title="Адрес поставки" width="40%" close={() => x.done(undefined)} bottom={bottom}
                      cardStyle={{ overflow: "unset" }} cardContentStyle={{ overflow: "unset" }}>
        <a style={{ fontWeight: 300, color: "#2d77af", fontSize: "18px", cursor: "pointer" }}
           onClick={() => alert("Не поддерживается в учебной версии")}>Указать на карте</a>
        <VGrid columns="1fr 2fr" alignItems={"center"}>
            <Label required preset="boldBig">Адрес поставки</Label>
            <HStack spacing="10px" innerStyle={{width: "100%"}} alignItems="center">
                <div style={{width: 300}}>
                    <FiasTextBox value={topAddressLine} onChange={changeFiasAddressLine} options={fiasLineOptions}
                                disabled={type !== "FIAS"}/>
                </div>
                <RoundedButton style={{paddingLeft: 15, paddingRight: 15}} color="blue" title="Из списка" onClick={pickFromList}/>
            </HStack>

            <div className={styles.spacingRow} />

            <Label preset="boldBig">Отсутствует в справочнике</Label>
            <CheckBox value={type !== "FIAS"} onChange={v => changeType(v ? "BTI" : "FIAS")}/>

            <div className={styles.spacingRow} />

            {
                type !== "FIAS" && <RadioGroup>
                    <Radio requiredValue="BTI" value={type} onChange={changeType} className={styles.radio}>
                        Адрес из БТИ
                    </Radio>
                    {
                        type === "BTI" ? <TextBox value="не поддерживается" disabled /> : <div/>
                    }

                    <Radio requiredValue="LANDMARK" value={type} onChange={changeType} className={styles.radio}>
                        Условные ориентиры
                    </Radio>
                    {
                        type === "LANDMARK" ? <VStack spacing="10px">
                            <FiasTextBox value={fiasAddressLine} onChange={changeFiasAddressLine} options={fiasLineOptions} />
                            <TextBox value={additionalInfo} onChange={setAdditionalInfo} />
                        </VStack> : <div/>
                    }

                    <Radio requiredValue="OUTSIDE_OF_RF" value={type} onChange={changeType} className={styles.radio}>
                        За пределами РФ
                    </Radio>
                    {
                        type === "OUTSIDE_OF_RF"
                            ? <TextBox value={outsideAddressLine} onChange={setOutsideAddressLine} />
                            : <div/>
                    }
                </RadioGroup>
            }
        </VGrid>
    </CardModal>;
};
