import { Component, Vue, Prop } from "vue-property-decorator";
import DataTable from "@/components/vue/DataTable.vue";
import { ContractScope } from '@/views/CreateContract/ContractScope';
import { hs } from '@/components/TableHelpers';
import { ContractExecutionHistoryEntry } from '@/models/Contracts';
import { ContractExecutionActionStrings } from '@/models/enums/Contracts/ContractExecutionAction';
import { ContractExecutionOperationTypeStrings } from '@/models/enums/Contracts/ContractExecutionOperationType';
import EventBus from '@/EventBus';

import ExecutionOperation from "@/views/CreateContract/ExecutionOperation.vue";

@Component({ components: { DataTable } })
export default class ExecutionHistorySection extends Vue { 
    @Prop() private source!: ContractScope;

    public headers = hs<ContractExecutionHistoryEntry>(
        hs.any("Тип взаимодействия", x => ContractExecutionActionStrings[x.action]),
        hs.any("Последняя операция", x => x.operations[0] && ContractExecutionOperationTypeStrings[x.operations[0].operation]),
        hs.date("Крайний срок ответа", x => x.maxResponseDate),
        hs.date("Дата начала", x => x.startDate),
        hs.date("Дата окончания", x => x.endDate),
        hs.slot("Действие", x => x, "actions", "auto")
    );

    public showItem(item: ContractExecutionHistoryEntry) {
        EventBus.callModal(ExecutionOperation, { source: item, scope: this.source });
    }
}
