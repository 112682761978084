import React, { ChangeEvent, useRef, useState } from "react";
import { CardModal } from "@/components/modals/CardModal";
import { MonopolyFile } from "@/models/Monopoly";
import { ModalFC } from "@/EventBus";
import { Expandee, HSpread, HStack, VStack } from "@/components/layouts";
import { Button, Label, TextBox } from "@/components/primitive";
import { Field, Fields } from "@/components/form/Field";

type SelectFileModalProps = {
    monopolyFile?: MonopolyFile
};

export const SelectFileModal: ModalFC<SelectFileModalProps, MonopolyFile> = ({ monopolyFile, done }) => {
    const [file, setFile] = useState<MonopolyFile | undefined>(monopolyFile);

    const inputRef = useRef<HTMLInputElement>(null);

    const dispatchFileInputEvent = () => {
        if (inputRef.current) {
            inputRef.current.value = "";
            inputRef.current.click();
        }
    };

    const select = (e: ChangeEvent<HTMLInputElement>) => {
        if (e.currentTarget.files && e.currentTarget.files.length > 0) {
            const file = e.currentTarget.files[0];
            setFile({ name: file.name, length: file.size, comment: "", createdAt: new Date() });
        }
    };

    const remove = () => setFile(undefined);

    const bottom = (
        <HStack spacing="10px">
            <Expandee  />
            <Button title="Подтвердить"
                    disabled={!file}
                    color="green"
                    onClick={() => done(file)} />
            <Button title="Отмена" color="red" onClick={() => done(undefined)} />
        </HStack>
    );

    return <CardModal title="Добавление файла"
                      width="60%"
                      bottom={bottom}
                      close={() => done(undefined)}>
        <VStack spacing="10px">
            {file
                ? <HSpread>
                    <Label preset="readonlyValue"
                           style={{ color: "#428bca", textDecoration: "underline" }}>
                        {file.name}
                    </Label>
                    <Button icon="faTrash" onClick={remove} />
                </HSpread>
                : <Button title="Выберите файл"
                          color="white"
                          style={{ alignSelf: "start" }}
                          onClick={dispatchFileInputEvent} />}
            <VStack spacing="2px">
                <Label preset="boldSmall">
                    Допустимые типы файлов: doc, docx, xls, xlsx, pdf, tiff, jpg, jpeg, zip, rar.
                </Label>
                <Label preset="boldSmall">
                    Максимальный размер файла - 500 МБ
                </Label>
            </VStack>
            <Fields orientation="horizontal">
                <Field title="Наименование:" preset="boldSmall">
                    <TextBox value={file?.name} readonly />
                </Field>
            </Fields>
        </VStack>
        <input ref={inputRef} onChange={select} type="file" style={{ display: "none" }} />
    </CardModal>;
};
