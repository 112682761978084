import React from "react";
import styles from "@/views/ComposedLot/edit/tabs/lot-documents/DocumentsSection/wizard/tabs/Quiz.module.css";
import { uuid } from "@/models/parsing";
import { WizardEditableTextField } from "@/views/ComposedLot/edit/tabs/lot-documents/DocumentsSection/wizard/tabs/EditableField";
import { WizardAnswer } from "@/views/ComposedLot/edit/tabs/lot-documents/DocumentsSection/wizard/tabs/Quiz";
import { TabAnswers } from "@/views/ComposedLot/edit/tabs/lot-documents/DocumentsSection/wizard/DocumentWizardModal";

export type WizardEditableField = {
    id: uuid
    name: string
};

interface WizardEditTabProps {
    ready: boolean
    fields: WizardEditableField[]
    selectedAnswers: TabAnswers
    selectedFieldIndex: number
    onChangeAnswer?: (field: WizardEditableField, answer: WizardAnswer) => void
    onChangeField?: (field: WizardEditableField, index: number) => void
}

const getActiveClassName = (id: uuid, selected?: uuid): string => id === selected
    ? styles.question + " " + styles.activeQuestion
    : styles.question;

export const WizardEditTab: React.FC<WizardEditTabProps> = x => {
    const selectedField = x.fields[x.selectedFieldIndex ?? 0];
    const edit: React.ReactNode = <div className={styles.layout}>
        <div className={styles.sidebar}>
            <div className={styles.title}>
                Список вопросов
            </div>
            <div className={styles.questions}>
                {
                    x.fields.map((f, i) => <div className={getActiveClassName(f.id, selectedField?.id)}
                                                onClick={() => x.onChangeField?.(f, i)}
                                                key={`question-${f.id}`}>
                        { `${i + 1}. ` }
                        { f.name }
                    </div>)
                }
            </div>
        </div>
        <div>
            {
                <WizardEditableTextField
                  value={x.selectedAnswers[selectedField.id]?.text ?? ""}
                  onChange={(input) => x.onChangeAnswer?.(selectedField, { id: selectedField.id, text: input })}
                  field={selectedField} />
            }
        </div>

    </div>;

    return <div>
        {
            x.ready
                ? <div>Вы заполнили все поля редактирования.<br />Нажмите кнопку назад, если хотите вернуться к редактируемым областям.</div>
                : edit
        }
    </div>;
};
