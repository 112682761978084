import { Component, Prop, Vue, Watch } from "vue-property-decorator";
import CardModal from "@/components/CardModal.vue";
import TreeView from "@/components/TreeView.vue";
import FormTable from "@/components/vue/FormTable.vue";
import { ITableColumn, SingleSelection, IRowConfiguration, SelectionColumnControl } from "@/components/TableAbstractions";
import { ModalVue, ModalResult } from '@/view-models/ModalRequest';
import { Participant } from '@/models';
import Participants from '@/api/Participants';
import { PaginationContext, createPaginationContext } from '@/api/Pagination';
import { FilterValueType, FilterConditionType } from '@/api/http';

@Component({ components: { CardModal, TreeView, FormTable } })
export default class ParticipantSelectionModal2 extends ModalVue<null, Participant> {
    @Prop({ default: null }) public prop: { onlyValidForUserSelection?: boolean; anyone?: boolean; overrideTitle?: string } | undefined;

    public get isCustomer() { return !!this.prop && this.prop.onlyValidForUserSelection }

    public participantHeaders: ITableColumn<Participant>[] = [
        {
            title: this.isCustomer ? "Название организации" : "Название поставщика",
            getter(p) { return p.shortName }
        },
        {
            title: "ИНН",
            getter(p) { return p.tin },
            size: "auto"
        },
        {
            title: "КПП",
            getter(p) { return p.rrc },
            size: "auto"
        },
        {
            title: "СМП",
            getter(p) { return p.isSmall ? "Да" : "Нет" },
            size: "auto"
        },
        {
            title: "СОНО",
            getter(p) { return p.isSocial ? "Да" : "Нет" },
            size: "auto"
        },
    ];

    public context: PaginationContext<Participant> | null = null;

    public selection: SingleSelection<Participant> = { tag: "SINGLE", item: null };
    public config: IRowConfiguration<Participant> = {
        selection: {
            showSelectionColumn: true,
            selectionColumnControl: SelectionColumnControl.CHECKBOX,
            background: "#ccc"
        }
    };

    public readonly tinFilter = {
        type: FilterValueType.STRING,
        conditionType: FilterConditionType.CONTAINS,
        string: "",
    };

    @Watch("tinFilter.string") public reload() {
        if (this.context) this.context.load(0);
    }

    public async created() {
        this.context = await createPaginationContext((n, sz, f) => Participants.get(n, sz, f), 15, {
            isSupplier: !this.prop?.anyone && !this.isCustomer ? [{
                type: FilterValueType.BOOLEAN,
                conditionType: FilterConditionType.EQUAL,
                boolean: true,
            }] : [],
            isValidForUserSelection: !this.prop?.anyone && (this.prop && this.prop.onlyValidForUserSelection)
                ? [{
                    type: FilterValueType.BOOLEAN,
                    conditionType: FilterConditionType.EQUAL,
                    boolean: true,
                }] 
                : [],
            tinOrName: [this.tinFilter]
        });
    }

    public select() {
        if (this.selection.item)
            this.$emit("close-modal", ModalResult.ok(this.selection.item));
    }
}
