import React from "react";
import { Card } from "@/components/Card";
import { Field, Fields } from "@/components/form/Field";

export const ProcurementRegulationSection = () => {
    return (
        <Card>
            <Fields orientation="horizontal">
                <Field title="Описание изменения положения">
                    <div></div>
                </Field>
            </Fields>
        </Card>
    );
};
