import {CompatEnum, UnionStrings} from './EnumStrings';

export type StartPriceMethod =
    | "MARKET_PRICES"
    | "CONTRACT_REGISTRY_PRICES"
    | "STANDARD"
    | "TARIFF"
    | "RELATIVE_RATE"
    | "ESTIMATE"
    | "COST_BASED"
    | "ALTERNATIVE_PRICES"
    | "PARAMETRIC"
    | "OTHER";

export const StartPriceMethod: CompatEnum<StartPriceMethod> = {
    MARKET_PRICES: "MARKET_PRICES",
    CONTRACT_REGISTRY_PRICES: "CONTRACT_REGISTRY_PRICES",
    STANDARD: "STANDARD",
    TARIFF: "TARIFF",
    RELATIVE_RATE: "RELATIVE_RATE",
    ESTIMATE: "ESTIMATE",
    COST_BASED: "COST_BASED",
    ALTERNATIVE_PRICES: "ALTERNATIVE_PRICES",
    PARAMETRIC: "PARAMETRIC",
    OTHER: "OTHER"
};

export const StartPriceMethodStrings: UnionStrings<StartPriceMethod> = {
    MARKET_PRICES: "Метод анализа рыночной стоимости закупаемых товаров, работ, услуг",
    CONTRACT_REGISTRY_PRICES: "Анализ цен, содержащихся в реестре контрактов",
    STANDARD: "Нормативный",
    TARIFF: "Тарифный",
    RELATIVE_RATE: "Удельных показателей",
    ESTIMATE: "Сметный",
    COST_BASED: "Затратный",
    ALTERNATIVE_PRICES: "Анализ стоимости аналогов",
    PARAMETRIC: "Параметрический",
    OTHER: "Иной",
};