import React from "react";
import { ContractPayment } from "@/models/Contracts/ContractPayment";
import { Card } from "@/components/Card";
import { Column, Table } from "@/components/table";
import { formatNumber } from "@/NumberFormatting";
import { formatDate } from "@/dateUtils";

type PaymentsTableSectionProps = {
    payments?: ContractPayment[]
};

export const PaymentsTableSection = ({ payments = [] }: PaymentsTableSectionProps) => {
    const columns: Column<ContractPayment>[] = [
        Table.Column(
            "Регистрационный номер",
            ({ item }) => <>{item?.regNumber ?? ""}</>,
            { headerStyle: { textAlign: "center" }, cellStyle: { textAlign: "center" } }
        ),
        Table.Column(
            "Дата регистрации",
            ({ item }) => <>{item?.registeredAt ? formatDate(item.registeredAt) : ""}</>,
            { headerStyle: { textAlign: "center" }, cellStyle: { textAlign: "center" } }
        ),
        Table.Column(
            "КБК",
            ({ item }) => <>{item?.kbk ?? ""}</>,
            { headerStyle: { textAlign: "center" }, cellStyle: { textAlign: "center" } }
        ),
        Table.Column(
            "Сумма, руб.",
            ({ item }) => <>{item?.totalPrice ? formatNumber(item.totalPrice) : ""}</>,
            { headerStyle: { textAlign: "center" }, cellStyle: { textAlign: "center" } }
        ),
        Table.Column(
            "Учтено в этапе контракта",
            () => <>Да</>,
            { headerStyle: { textAlign: "center" }, cellStyle: { textAlign: "center" } }
        )
    ];

    return (
        <Card>
            <Table columns={columns} dataset={payments} />
        </Card>
    );
};
