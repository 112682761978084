




















import { Component, Vue, Prop } from "vue-property-decorator";
import FormTable from "@/components/vue/FormTable.vue";
import CardModal from "@/components/CardModal.vue";
import ContractSpecifications from '@/api/ContractSpecifications';
import { ContractScope } from './ContractScope';
import { ContractSpecification } from '../../models/Contracts/ContractSpecification';

@Component({ components: { FormTable, CardModal } })
export default class SetTaxModal extends Vue {
    @Prop() public prop!: ContractScope;

    public loading = false;

    public tax: ContractSpecification["tax"] = "TAX_0";

    public taxOptions = [
        { key: "TAX_0", desc: "0%" },
        { key: "TAX_10", desc: "10%" },
        { key: "TAX_NONE", desc: "Без НДС" },
        { key: "TAX_20", desc: "20%" }
    ];

    public async saveAndClose() {
        this.loading = true;

        try {
            await ContractSpecifications.setTaxForAll(this.prop.contract.id, this.tax);

            for (const spec of this.prop.contract.specifications) {
                spec.tax = this.tax;
            }
            this.$emit("close-modal");
        } finally {
            this.loading = false;
        }
    }
}
