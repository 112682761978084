













import { ITableColumn, IRowConfiguration, SelectionColumnControl } from "@/components/TableAbstractions";
import { Component, Vue, Prop } from "vue-property-decorator";
import DataTable from "@/components/vue/DataTable.vue";
import { ContractParticipantAccount, Contract } from '../../models/Contracts';
import { IFieldProps } from '../../components/vue/form-table/FieldBase';
import { SimpleRow } from '../../components/vue/form-table/FormFields';
import { ContractScope } from './ContractScope';
import Evasion from './Evasion.vue';
import EventBus from '../../EventBus';

@Component({ components: { DataTable } })
export default class VendorAccounts extends Vue {
    @Prop() public props!: IFieldProps<SimpleRow<ContractScope>>;

    public get status() { return this.props.source.contract && this.props.source.contract.status }

    public createTableConfig(): IRowConfiguration<ContractParticipantAccount> {
        const that = this;

        return {
            selection: {
                showSelectionColumn: true,
                selectionColumnPosition: "right",
                selectionColumnControl: SelectionColumnControl.CHECKBOX,
                selectionColumnHeader: "Выбрать",
                explicitChecked(t) { return t.selected },
                get locked() { return that.props.state !== "ENABLED" },
            }
        };
    }

    public tableConfig = this.createTableConfig();

    public get headers(): ITableColumn<ContractParticipantAccount>[] {
        return [
            {
                title: "Корреспондентский счет",
                getter: p => p.account.correspondentAccount,
            },
            {
                title: "​Расчётный счёт",
                getter: p => p.account.transactionalAccountNumber,
            },
            {
                title: "Банк",
                getter: p => p.account.bankName,
            },
            {
                title: "​Бик",
                getter: p => p.account.bin,
            },
        ];
    }

    public get dataset() { 
        const set = (this.props.row.getter(this.props.source) || []) as ContractParticipantAccount[];
        if(set.length) {
            return set.sort((a, b) => a.account.id - b.account.id);
        }
        return set;
        }

    public showEvasion() {
        EventBus.callModal(Evasion, this.props.source);
    }
}
