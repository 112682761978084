// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, "._3ZH0OJ38FYEDLcKPMcn-_R {\n    box-sizing: border-box;\n    width: 100%;\n    padding: 20px 60px;\n    border-top: 1px solid #c0c0c0;\n\n    cursor: pointer;\n}\n\n._3IFnMHrreGpYkJVA2W5JXr {\n    background-color: #4db2ff;\n    color: white;\n}\n\n._3ZH0OJ38FYEDLcKPMcn-_R:hover:not(._3IFnMHrreGpYkJVA2W5JXr):not(:first-child) {\n    background-color: #f0f3f5;\n}\n", ""]);
// Exports
exports.locals = {
	"listItem": "_3ZH0OJ38FYEDLcKPMcn-_R",
	"selected": "_3IFnMHrreGpYkJVA2W5JXr"
};
module.exports = exports;
