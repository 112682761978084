import {createEvent, createStore} from "effector";
import {StartPriceMethod} from "@/models/enums/StartPriceMethod";
import Decimal from "decimal.js";
import {autoSV, createSVEvent, origEv} from "@/storeUtils";
import {MarketPrice, Participant} from "@/models";
import {MarketPriceDataSource, SelectOption} from "@/models/enums";
import {createStoredRemoteDataset, StoredRemoteDataset} from "@/components/table";
import MarketPrices from "@/api/MarketPrices";
import {SpecificationDraft} from "@/models/ComposedLots/specifications";
import {Tariff} from "@/models/Tariff";
import Tariffs from "@/api/Tariffs";

type Filters = {
    name: string
};

type GroupedTariff = { supplier: Participant; items: Array<{ zone: Tariff["zone"]; items: Tariff[] }> };
type TariffOptionKey = { supplier: Participant; opts: SelectOption<Tariff[]>[] };
type TariffOption = SelectOption<TariffOptionKey>;

export interface CalculatorStore {
    specification: SpecificationDraft

    currentMethod?: StartPriceMethod
    justification?: string

    // main
    simpleValue?: Decimal
    simpleRatioValue?: Decimal
    taxPercent?: number

    // market-prices
    marketPricesResult?: Decimal
    selectedPrices: { [key in MarketPriceDataSource]: MarketPrice[] }
    priceDatasets: {
        [key in MarketPriceDataSource]: StoredRemoteDataset<MarketPrice, Filters>
    }

    // tariffs
    tariffs?: TariffOption[]
    isSupplierFixed: boolean
    selectedTariffGroup?: TariffOptionKey
    selectedTariffs?: Tariff[]

    // view
    isNewPriceSectionVisible: boolean
    isPriceReloadRequired: boolean
}

export const setCurrentMethod = createSVEvent<CalculatorStore>()("currentMethod");
export const setSimpleValue = createSVEvent<CalculatorStore>()("simpleValue");
export const setSimpleRatioValue = createSVEvent<CalculatorStore>()("simpleRatioValue");
export const setMarketPricesResult = createSVEvent<CalculatorStore>()("marketPricesResult");
export const setJustification = createSVEvent<CalculatorStore>()("justification");
export const setTaxPercent = createSVEvent<CalculatorStore>()("taxPercent");
export const setIsNewPriceSectionVisible = createSVEvent<CalculatorStore>()("isNewPriceSectionVisible");
export const setIsPriceReloadRequired = createSVEvent<CalculatorStore>()("isPriceReloadRequired");
export const setTariffs = createSVEvent<CalculatorStore>()("tariffs");
export const setIsSupplierFixed = createSVEvent<CalculatorStore>()("isSupplierFixed");
export const setSelectedTariffGroup = createSVEvent<CalculatorStore>()("selectedTariffGroup");
export const setSelectedTariffs = createSVEvent<CalculatorStore>()("selectedTariffs");

export const setSelectedMarketPrices = createEvent<{
    dataSource: MarketPriceDataSource
    selected: MarketPrice[]
}>("set selected market prices");

const createDataset = (id: number, t: MarketPriceDataSource) => createStoredRemoteDataset<MarketPrice, Filters>({
    async load(filters, from, count) {
        return await MarketPrices.get(from, count, t, id, {
            name: filters.name ? [{ type: "STRING", conditionType: "CONTAINS", string: filters.name }] as never : []
        });
    }
});

const loadTariffs = async (spec: SpecificationDraft) => {
    const ungrouped = await Tariffs.getForSubjectDeclaration(spec.subjectDeclaration.id);

    // TODO
    const specWithSupplier = undefined as SpecificationDraft | undefined; // this.detail.specifications.filter(x => x.startPrice && x.startPrice.supplier)[0];
    // TODO
    const supplier = undefined as Participant | undefined; // specWithSupplier && specWithSupplier.startPrice && specWithSupplier.startPrice.supplier || null;

    let grouped: TariffOption[] =
        ungrouped.reduce((groups, tariff) => {
            let existing = groups.find(x => x.supplier.id === tariff.supplier.id);

            if (!existing) {
                existing = { supplier: tariff.supplier, items: [] };
                groups.push(existing);
            }

            let innerExisting = existing.items.find(x => x.zone.id === tariff.zone.id);
            if (!innerExisting) {
                innerExisting = { zone: tariff.zone, items: [] };
                existing.items.push(innerExisting);
            }

            innerExisting.items.push(tariff);

            return groups;
        }, [] as GroupedTariff[])
            .filter(x => !supplier || x.supplier.id === supplier.id)
            .map(x => ({
                key: {
                    supplier: x.supplier,
                    opts: x.items.map(y => ({
                        key: y.items,
                        desc: y.zone.name
                    }))
                },
                desc: x.supplier.shortName
            }));

    if (supplier && grouped.length === 0) {
        grouped = [{
            key: {
                supplier,
                opts: []
            },
            desc: supplier.shortName
        }];
    }

    setTariffs(grouped);

    if (supplier) {
        setIsSupplierFixed(true);
        setSelectedTariffGroup(grouped[0]?.key);
    }

    setTariffs(grouped);
};

export const createCalculatorStore = (spec: SpecificationDraft) => {
    const store = createStore<CalculatorStore>({
        specification: spec,
        selectedPrices: {
            MARKET_PRICE: [],
            PROVIDER_OFFER: [],
            PROVIDER_PORTAL: []
        },
        priceDatasets: {
            MARKET_PRICE: createDataset(spec.subjectDeclaration.id, "MARKET_PRICE"),
            PROVIDER_OFFER: createDataset(spec.subjectDeclaration.id, "PROVIDER_OFFER"),
            PROVIDER_PORTAL: createDataset(spec.subjectDeclaration.id, "PROVIDER_PORTAL"),
        },
        isNewPriceSectionVisible: false,
        isPriceReloadRequired: false,
        isSupplierFixed: false
    })
        .on(setCurrentMethod[origEv], autoSV)
        .on(setSimpleValue[origEv], autoSV)
        .on(setSimpleRatioValue[origEv], autoSV)
        .on(setMarketPricesResult[origEv], autoSV)
        .on(setJustification[origEv], autoSV)
        .on(setTaxPercent[origEv], autoSV)
        .on(setIsNewPriceSectionVisible[origEv], autoSV)
        .on(setIsPriceReloadRequired[origEv], autoSV)
        .on(setSelectedMarketPrices, (x, { dataSource, selected }) => ({
            ...x,
            selectedPrices: {
                ...x.selectedPrices,
                [dataSource]: selected
            }
        }))
        .on(setTariffs[origEv], autoSV)
        .on(setIsSupplierFixed[origEv], autoSV)
        .on(setSelectedTariffs[origEv], autoSV)
        .on(setSelectedTariffGroup[origEv], autoSV);

    store.watch(setCurrentMethod, () => {
        setSimpleValue(new Decimal(0));
        setSimpleRatioValue(new Decimal(1));
    });

    loadTariffs(spec).ignore;

    return store;
};