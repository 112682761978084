import React, {useState} from "react";
import {ModalFC} from "@/EventBus";
import {CardModal} from "@/components/modals/CardModal";
import {Button, Label, TextBox} from "@/components/primitive";
import {Field, Fields} from "@/components/form/Field";

export const AuthorizationEisModal: ModalFC = x => {
    const [login, setLogin] = useState<string>("");
    const [passwd, setPasswd] = useState<string>("");

    const bottom = <div style={{ display: "flex", justifyContent: "space-between", alignItems: "center"}}>
        <Label preset="medium" style={{ cursor: "pointer", color: "#428bca" }} onClick={x.done}>ГДЕ Я МОГУ УЗНАТЬ ЛОГИН И ПАРОЛЬ?</Label>
        <div style={{ display: "flex", justifyContent: "space-between" }}>
            <Button style={{ marginRight: "10px" }} color="green" title="Продолжить" onClick={x.done} />
            <Button icon="aClose" onClick={x.done} />
        </div>
    </div>;

    return <CardModal title="Аутентификация в ЕИС" close={x.done} width="600px" bottom={bottom}>
        <Fields orientation="horizontal">
            <Field title="Логин" preset="medium">
                <TextBox lines={1} value={login} onChange={(v) => setLogin(v)} />
            </Field>
            <Field title="Пароль" preset="medium">
                <TextBox lines={1} value={passwd} onChange={(v) => setPasswd(v)} />
            </Field>
        </Fields>
    </CardModal>;
};