






import { IHint } from "@/components/vue/form-table/FormFields";
import { Component, Inject, Vue, Mixins, Prop } from "vue-property-decorator";
import { labelSpanContext, FComponent } from "./FComponent";
import EventBus from "@/EventBus";

@Component
export default class FLabel extends Mixins(FComponent) {
    @Inject({ from: labelSpanContext, default: () => () => "auto" }) public injectedGridColumn!: () => string;
    
    public get resolvedGridColumn() {
        return this.span 
            ? `auto / span ${this.span}`
            : this.gridColumn || this.injectedGridColumn() || `auto / span 1`;
    }
}
