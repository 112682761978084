import {MouseEvent, ReactElement, ReactPortal, RefObject, useEffect, useState} from "react";
import {InteractionError} from "@/api/errorHandling";
import {createEvent, createStore, Event as EEvent, Store} from "effector";
import {CombinedVueInstance} from "vue/types/vue";
import {Vue} from "vue-property-decorator";

// "useful" type declarations

interface NonTextNodeArray extends Array<Elements> {}
type NonTextFragment = {} | NonTextNodeArray;

export type Elements = ReactElement | NonTextFragment | ReactPortal;

export const fixEls = (els: Elements): ReactElement[] => Array.isArray(els) ? els.flatMap(fixEls) : (els as ReactElement).type ? [els as ReactElement] : [];

// className generation

const known = <T>(x: T | null | undefined): x is T => x !== null && x !== undefined;

export type ClassMap = {[key: string]: boolean | undefined | null};

export const j = (...classes: (ClassMap | string | undefined | null)[]): string =>
    classes
        .filter(known)
        .flatMap(x => typeof(x) === "string"
            ? [x] : Object.entries(x).filter(([, r]) => r).map(([c]) => c))
        .join(" ");

type PromiseResult<T> = { state: "fulfilled"; value: T } | { state: "rejected"; error: unknown };

type Error = { text: string; handled: boolean };
export type HandleableError = { store: Store<Error | null>; handle: EEvent<void>; drop: EEvent<void> };

export type SafeFnFactory<T> = (x: () => Promise<T>) => () => Promise<PromiseResult<T>>;

export const useErrorHandler = <T,>(): [HandleableError, SafeFnFactory<T>] => {
    const [error] = useState(() => {
        const set = createEvent<string>();
        const handle = createEvent();
        const drop = createEvent();

        const store = createStore<Error | null>(null)
            .on(set, (x, s) => ({ text: s, handled: false }))
            .on(handle, x => x ? ({ text: x.text, handled: true}) : null)
            .on(drop, () => null);

        return {store, set, handle, drop};
    });

    return [error, (promise: () => Promise<T>) => async () => {
        try {
            const result = await promise();
            return { state: "fulfilled", value: result };
        } catch (e) {
            if (e instanceof InteractionError)
                error.set(e.scope ?? e.cause.join("\n"));
            else if (e instanceof Error)
                error.set(e.message);
            else
                error.set("Unknown error");

            return { state: "rejected", error: e };
        }
    }];
};

// vue interaction

type ExtD<T> =  T extends CombinedVueInstance<infer V, infer D, infer U1, infer U2, infer U3> ? D : T;

export const useVueMutable = <T extends Vue>(v: T): ExtD<T> => {
    const [state, setState] = useState<ExtD<T>>({...v.$data} as ExtD<T>);

    useEffect(() => {
        return v.$watch(() => v.$data, () => setState({...v.$data} as ExtD<T>), { deep: true });
    });

    return state;
};

export const useVueMutableK = <T extends Vue, K extends keyof ExtD<T>>(v: T, k: K): ExtD<T>[K] => {
    const [state, setState] = useState(v.$data[k as string] as ExtD<T>[K]);

    useEffect(() => {
        return v.$watch(() => v.$data[k as string], setState);
    });

    return state;
};

// event utils
export const suppressEvent: (h: () => void) => (e: MouseEvent) => void = h => {
    return e => {
        e.stopPropagation();
        h();
    };
};

export const useOnClickOutside = <T extends HTMLElement, >(
    ref: RefObject<T>,
    handler: (e: Event) => void
) => {
    useEffect(() => {
        const listener = (event: Event) => {
            if (!ref.current || ref.current.contains(event.target as Node)) {
                return;
            }

            handler(event);
        };

        document.addEventListener("mousedown", listener);
        document.addEventListener("touchstart", listener);

        return () => {
            document.removeEventListener("mousedown", listener);
            document.removeEventListener("touchstart", listener);
        };
    }, [ref, handler]);
};

export function useOnScreen(ref: RefObject<HTMLElement>, rootRef?: RefObject<HTMLElement>) {
    const [isIntersecting, setIntersecting] = useState(false);

    useEffect(() => {
        const observer = new IntersectionObserver(
            a => {
                const [entry] = a;
                // console.log("use on screen callback fired", a);
                setIntersecting(entry.isIntersecting);
            },
            {
                root: document.body
            }
        );

        if (ref.current) {
            observer.observe(ref.current);
        }

        return () => { observer.disconnect() };
    }, [ref, ref.current, rootRef?.current]);

    return isIntersecting;
}
