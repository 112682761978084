import { Component, Prop, Vue } from "vue-property-decorator";
import { TableGridCell, Span, TableGridRow } from './TableGridProps';

@Component
export default class TableGrid extends Vue {
    @Prop({ default: () => [] }) public rows!: (TableGridRow | null)[];
    @Prop({ default: () => [] }) public columnSizes!: string[];
    @Prop({ default: null }) public rowSizes!: string[] | null;

    public get columnCount() { return this.rows.reduce((p, v) => v === null || p > v.cells.length ? p : v.cells.length, 0) }

    public get rowTemplate() { return this.rowSizes && this.rowSizes.join(" ") || "unset" }

    public get columnTemplate() {
        const columnCount = this.columnCount;
        const widths = [];

        for (let i = 0; i < columnCount; i++)
            widths.push(this.columnSizes[i] || "1fr");

        return widths.join(" ");
    }

    public gridLine(isRow: boolean, span: Span | undefined, index: number, rowIndex: number) {
        switch (span) {
            case undefined: 
                return (index + 1).toString();
            case "FULL":
                let maxIndex = index;
                if (isRow) {
                    for (let i = index + 1; i < this.rows.length; i++) {
                        if (this.rows[i] !== null)
                            break;
                        maxIndex = i;
                    }
                } else {
                    const columnCount = this.columnCount;
                    const row = this.rows[rowIndex];
                    if (row !== null) {
                        for (let i = index + 1; i < columnCount; i++) {
                            if (row.cells[i] !== null)
                                break;
                            maxIndex = i;
                        }
                    }
                }
                return `${index + 1} / ${maxIndex + 2}`;
            default:
                return `${index + 1} / span ${span}`;
        }
    }

    public rowLine(cell: TableGridCell, rowIndex: number, columnIndex: number) { 
        return this.gridLine(true, cell.rowSpan, rowIndex, rowIndex);
    }

    public columnLine(cell: TableGridCell, rowIndex: number, columnIndex: number) { 
        return this.gridLine(false, cell.colSpan, columnIndex, rowIndex);
    }
}