import React from "react";
import {ModalFC} from "@/EventBus";
import Decimal from "decimal.js";
import {CardModal} from "@/components/modals/CardModal";
import {Button} from "@/components/primitive";
import {formatNumber} from "@/NumberFormatting";
import {Expandee, HStack} from "@/components/layouts";

interface CalculationImbalanceModalProps {
    priceOptions?: {
        lower: Decimal
        higher: Decimal
    }
    suggestedRatio: Decimal
}

type CalculationImbalanceModalResult =
    | { type: "force" }
    | { type: "price"; value: Decimal }
    | { type: "ratio" };

export const CalculationImbalanceModal: ModalFC<CalculationImbalanceModalProps, CalculationImbalanceModalResult> = x => {
    const priceOptions = x.priceOptions;
    const bottom = <HStack spacing="10px">
        <Expandee/>
        {
            priceOptions && <>
                <Button color="green"
                        title={'НМЦ ' + formatNumber(priceOptions.lower)}
                        onClick={() => x.done({ type: "price", value: priceOptions.lower })} />
                <Button color="green"
                        title={'НМЦ ' + formatNumber(priceOptions.higher)}
                        onClick={() => x.done({ type: "price", value: priceOptions.higher })} />
            </>
        }
        <Button color="green" title={'Коэффициент ' + formatNumber(x.suggestedRatio, undefined, 11)}
                onClick={() => x.done({ type: "ratio" })} />
        <Button color="green" title="Вернуться к расчету"
                onClick={() => x.done(undefined)} />
        <Button color="red" title="Продолжить"
                onClick={() => x.done({ type: "force" })} />
    </HStack>;

    return <CardModal title="Подтверждение" bottom={bottom} close="disabled" width="50%">
        <span>
            Произведение цены за единицу и количества не равно сумме закупки.
            <br/>
        </span>
        {
            priceOptions
                ? <span>
                    Сумма закупки должна быть равна: {formatNumber(priceOptions.lower)} или {formatNumber(priceOptions.higher)} или
                    <br/>
                    необходимо использовать коэффициент оптимизации {formatNumber(x.suggestedRatio, undefined, 11)}
                </span>
                : <span>
                    Рекоммендуемый коэффициент {formatNumber(x.suggestedRatio)}
                </span>
        }
    </CardModal>;
};