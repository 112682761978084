import React, { useEffect } from "react";
import { $filters, $services, changeFavorites, loadServicesPage } from "./store";
import { observer } from "mobx-react";
import { ServicesTable } from "./ServicesTable";
import { Header } from "./Header";
import styles from "./Services.module.css";
import { useStore } from "effector-react";
import { SubjectClassFilterStore } from "@/components/eshop/SubjectClassFilterStore";

interface ServicesProps {
    favorites: boolean
    storeContainer: { subjectClassSelector: SubjectClassFilterStore }
}

export const Services: React.FC<ServicesProps> = x => {
    const store = useStore($services);
    const filtersStore = useStore($filters);
    useEffect(() => {
        changeFavorites(!!x.favorites);
        loadServicesPage({ page: 0 });
        return;
    }, [x.favorites]);
    return <div className={styles.offersPage}>
        <div className={`${styles.servicesPageContent} ${!store.showInfo ? styles.servicesPageContentFull : ''}`} >
            <Header store={store} />
            <ServicesTable
                subjectClassSelector={x.storeContainer.subjectClassSelector}
                filtersStore={filtersStore}
                store={store}
                data={store.page.items} />
        </div>
    </div>;

};
