


















import { FieldAlignment, FieldWidth, valueSpanContext, FComponent } from "./FComponent";
import { Component, Prop, Vue, Inject, Mixins } from "vue-property-decorator";
import { FValueComponent } from './FValueComponent';

@Component
export default class FSelect extends Mixins(FValueComponent) {
    @Prop({ default: "wide" }) public width!: FieldWidth;
    @Prop({ default: () => [] }) public options!: { key: string | null | undefined; desc: string }[];

    public get commonProps() {
        const { width, f } = this;

        return { class: [f(width), ...this.classes] };
    }

    public get editableProps() {
        const { disabled } = this;

        return { ...this.commonProps, disabled };
    }

    public get active() {
        return this.options.find(x => x.key === this.value);
    }

    public get text() {
        const { active } = this;

        return active ? active.desc : "";
    }

    public get key() {
        const { active } = this;

        return active ? active.key : this.options[0] ? this.options[0].key : "";
    }

    public onInput(e: Event) {
        const target = e.target as HTMLSelectElement;

        this.$emit("input", this.options[target.value as unknown as number].key);
    }
}
