import DatePicker from "@/components/vue/primitive/DatePicker";
import TimePicker from "@/components/vue/primitive/TimePicker";
import RadioGroup from "@/components/vue/primitive/RadioGroup.vue";
import Vue from "vue";
import App from "./App.vue";
import router from "./router";

import "./assets/global.css";

import {library} from "@fortawesome/fontawesome-svg-core";
import {faCalculator, faSearch, faSync} from "@fortawesome/free-solid-svg-icons";
import {FontAwesomeIcon} from "@fortawesome/vue-fontawesome";
import {setupRoutedModals} from "./routed-modals";

import Button from "@/components/vue/primitive/Button.vue";
import TextBox from "@/components/vue/primitive/TextBox.vue";
import Label from "@/components/vue/primitive/Label.vue";
import Radio from "@/components/vue/primitive/Radio.vue";
import ComboBox from "@/components/vue/primitive/ComboBox.vue";
import CheckBox from "@/components/vue/primitive/CheckBox.vue";
import Slider from "@/components/vue/primitive/Slider.vue";
import {formatDate} from "./DateFormatting";
import {formatNumber} from "./NumberFormatting";
import Decimal from "decimal.js";
import {ObjectStatus, ObjectStatusStringMap, ObjectStatusStrings} from "./models/enums";

import FGrid from "@/components/next/FGrid.vue";
import FField from "@/components/next/FField.vue";
import FLabel from "@/components/next/FLabel.vue";
import FText from "@/components/next/FText.vue";
import FDecimal from "@/components/next/FDecimal.vue";
import FInteger from "@/components/next/FInteger.vue";
import FFloat from "@/components/next/FFloat.vue";
import FSection from "@/components/next/FSection.vue";
import FReq from "@/components/next/FReq.vue";
import FHint from "@/components/next/FHint.vue";
import FForm from "@/components/next/FForm.vue";
import FHeader from "@/components/next/FHeader.vue";
import FSelect from "@/components/next/FSelect.vue";
import FCheckbox from "@/components/next/FCheckbox.vue";
import FDate from "@/components/next/FDate.vue";
import FPick from "@/components/next/FPick.vue";
import FNotice from "@/components/next/FNotice.vue";
import FError from "@/components/next/FError.vue";

import Card from "@/components/vue/Card.vue";
import DataTable from "@/components/vue/DataTable.vue";
import PaginatedDataTable from "@/components/vue/PaginatedDataTable.vue";
import PaginationController from "@/components/vue/PaginationController.vue";
import {VuePlugin} from "vuera";
import {initializeIcons} from 'office-ui-fabric-react';

library.add(faSearch, faSync, faCalculator);
Vue.component("fa-icon", FontAwesomeIcon);

Vue.config.productionTip = false;

Vue.use(VuePlugin);

setupRoutedModals(router).then(() => {});

Vue.component("x-button", Button);
Vue.component("x-textbox", TextBox);
Vue.component("x-label", Label);
Vue.component("x-radio", Radio);
Vue.component("x-combobox", ComboBox);
Vue.component("x-radiogroup", RadioGroup);
Vue.component("x-datepicker", DatePicker);
Vue.component("x-timepicker", TimePicker);
Vue.component("x-checkbox", CheckBox);
Vue.component("x-slider", Slider);
Vue.component("x-card", Card);

Vue.component("f-grid", FGrid);
Vue.component("f-field", FField);
Vue.component("f-label", FLabel);
Vue.component("f-text", FText);
Vue.component("f-decimal", FDecimal);
Vue.component("f-integer", FInteger);
Vue.component("f-float", FFloat);
Vue.component("f-section", FSection);
Vue.component("f-req", FReq);
Vue.component("f-hint", FHint);
Vue.component("f-form", FForm);
Vue.component("f-header", FHeader);
Vue.component("f-select", FSelect);
Vue.component("f-checkbox", FCheckbox);
Vue.component("f-date", FDate);
Vue.component("f-pick", FPick);
Vue.component("f-notice", FNotice);
Vue.component("f-error", FError);

Vue.component("d-table", DataTable);
Vue.component("d-paged-table", PaginatedDataTable);
Vue.component("d-pager", PaginationController);

const defined = (o: unknown | undefined | null): boolean => o !== undefined && o !== null;

Vue.filter("day", (d?: Date) => (d ? formatDate(d, true) : ""));
Vue.filter("month", (d?: Date) => (d ? formatDate(d, false) : ""));
Vue.filter("decimal", (d?: Decimal) => (d ? formatNumber(d) : ""));
Vue.filter("status", (s: ObjectStatus, strings?: string) => strings ? ObjectStatusStringMap[strings][s] : ObjectStatusStrings[s]);
Vue.filter("pad", (s: {toString(): string} | undefined | null, ln: number, ch: string) => (defined(s) ? s!.toString().padStart(ln, ch) : ""));

Vue.directive('focus', {
    inserted: (el) => {
        Vue.nextTick(() => {
            el.focus();
        });
    }
});

initializeIcons();

// IDEA thinks that "render" is unused
// noinspection JSUnusedGlobalSymbols
new Vue({
    router,
    render: h => h(App),
}).$mount("#app");
