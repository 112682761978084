// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, "\n._3B315b6_YH6ah3L09NiRus {\n    height: 100% !important;\n    background-color: white;\n}\n\n.Dzx88YxhBMzi-ci5XdBxp {\n    height: calc(100% - 40px);\n    transition: 0.3s;\n}\n\n._2uUue2cBM5LQidZAEoIe3q {\n    height: calc(100% - 15px);\n}", ""]);
// Exports
exports.locals = {
	"offersPage": "_3B315b6_YH6ah3L09NiRus",
	"servicesPageContent": "Dzx88YxhBMzi-ci5XdBxp",
	"servicesPageContentFull": "_2uUue2cBM5LQidZAEoIe3q"
};
module.exports = exports;
