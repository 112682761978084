






import { ITableColumn } from "@/components/TableAbstractions";
import { Component, Vue } from "vue-property-decorator";
import DataTable from "@/components/vue/DataTable.vue";

@Component({ components: { DataTable } })
export default class Liability extends Vue {
    public get headers(): ITableColumn<unknown>[] {
        return [
            {
                title: "Номер",
                getter: () => "",
            },
            {
                title: "Дата постановки на учет",
                getter: () => "",
            },
            {
                title: "Год бюджета​",
                getter: () => "",
            },
            {
                title: "КБК",
                getter: () => "",
            },
            {
                title: "​Сумма, руб.",
                getter: () => "",
            },
            {
                title: "​Статус",
                getter: () => "",
            },
            {
                title: "Дата последнего изменения",
                getter: () => "",
            },
        ];
    }

    public get dataset(): unknown[] {
        return [];
    }
}
