import React, { useState } from "react";
import styles from '../CreateSession.module.css';
import {
    changeLotRegNumber,
    ServicesStore
} from "../store";
import _ from "lodash";
import { Select } from "@/components/eshop/Select/Select";
import { TooltipIcon } from "@/components/TooltipIcon";
import { FormControlEshopInput } from "@/components/eshop/FormControlEshopInput";
import { HeaderWithTooltip, isSorted, SortFilter } from "./CreateSession";
import { SortIcons } from "@/components/SortIcons";
import { Lot } from "@/api/Sessions";
import { formatNumber } from "@/NumberFormatting";
import { NumberInput } from "@/components/eshop/NumberInput";
import {Section} from "@/components/eshop/Section/Section";
import {RefreshButton, ResetFiltersButton, ShowFiltersButton} from "@/components/eshop/IconButton/IconButtons";
import {Pager} from "@/components/eshop";
import {Card} from "@/components/eshop/Card/Card";

interface LotCardProps {
    store: ServicesStore
    canChange: boolean
}

export const LotCard = (props: LotCardProps) => {
    const { store, canChange } = props;

    const [sortLot, setSortLot] = useState<SortFilter>(null);

    const [isFilterOpened, setFilterOpened] = useState(false);
    const [regNumber, setRegNumber] = useState('');

    const allYears = store.lotsPage.items.map(i => i.basicInfo.contractConclusionYear);
    const yearOptions = [{ name: '-', value: '' }].concat((_.uniq(_.without(allYears, null, undefined)) as number[]).map(r => ({ name: r.toString(), value: r.toString() })));
    const [year, setYear] = useState('');

    const [name, setName] = useState('');
    const allReasons = store.lotsPage.items.map(i => i.singleProviderReason?.name);
    const reasonOptions = [{ name: '-', value: '' }].concat((_.uniq(_.without(allReasons, null, undefined)) as string[]).map(r => ({ name: r, value: r })));
    const [reason, setReason] = useState('');

    const [priceFrom, setPriceFrom] = useState<number | null>(null);
    const [priceTo, setPriceTo] = useState<number | null>(null);
    const [lotPageSize, setLotPageSize] = useState<number>(10);

    const clearFilters = () => {
        setRegNumber('');
        setYear('');
        setName('');
        setReason('');
        setPriceFrom(null);
        setPriceTo(null);
    };

    const getSum = (l: Lot) => l.plannedPayments?.reduce((a, b) => a + parseFloat(b.amount), 0) ?? 0;
    const getSumString = (l: Lot) => formatNumber(l.plannedPayments?.reduce((a, b) => a + parseFloat(b.amount), 0) ?? 0);

    const filtered = store.lotsPage.items
        .filter(i => i.regNumber.toString().indexOf(regNumber) !== -1)
        .filter(i => i.basicInfo?.contractConclusionYear?.toString().indexOf(year) !== -1 ?? true)
        .filter(i => i.basicInfo?.subject?.indexOf(name) !== -1 ?? true)
        .filter(i => i.singleProviderReason?.name?.toString().indexOf(reason) !== -1 ?? true)
        .filter(i => i.singleProviderReason?.name?.toString().indexOf(reason) !== -1 ?? true)
        .filter(i => i.singleProviderReason?.name?.toString().indexOf(reason) !== -1 ?? true)
        .filter(i => (priceFrom ? getSum(i) > priceFrom : true) && (priceTo ? priceTo > getSum(i) : true));

    const lotsSorted = () => sortLot
        ? _.orderBy(filtered, l => l[sortLot.field as keyof Lot], [sortLot.sort])
        : filtered;

    return (
        <Section canBeHidden required title={<>Лот<TooltipIcon /></>}>
            <Card direction={"vertical"}>
                <div className={styles.controlsWrapper}>
                    <div className={styles.buttonsContainer}>
                        <ShowFiltersButton onClick={() => setFilterOpened(!isFilterOpened)} />
                        <ResetFiltersButton onClick={clearFilters} />
                        <RefreshButton />
                    </div>
                </div>
                <table className={styles.cardTable}>
                    <thead>
                    <tr className={styles.tableRow}>
                        <HeaderWithTooltip>
                            <SortIcons sorted={isSorted('regNumber', sortLot)} setSort={sort => setSortLot({ field: 'regNumber', sort })} />
                            Реестр. № лота
                        </HeaderWithTooltip>
                        <HeaderWithTooltip>
                            <SortIcons sorted={isSorted('basicInfo.contractConclusionYear', sortLot)} setSort={sort => setSortLot({ field: 'basicInfo.contractConclusionYear', sort })} />
                            Год
                        </HeaderWithTooltip>
                        <HeaderWithTooltip>
                            <SortIcons sorted={isSorted('basicInfo.subject', sortLot)} setSort={sort => setSortLot({ field: 'basicInfo.subject', sort })} />
                            Наименование лота
                        </HeaderWithTooltip>
                        <HeaderWithTooltip>
                            <SortIcons sorted={isSorted('singleProviderReason.name', sortLot)} setSort={sort => setSortLot({ field: 'singleProviderReason.name', sort })} />
                            Основание заключения
                        </HeaderWithTooltip>
                        <HeaderWithTooltip>
                            <SortIcons sorted={isSorted('singleProviderReason.name', sortLot)} setSort={sort => setSortLot({ field: 'singleProviderReason.name', sort })} />
                            Остаток финансирования
                        </HeaderWithTooltip>
                    </tr>
                    {isFilterOpened && <tr>
                        <th className={styles.cell}><FormControlEshopInput small onChange={e => setRegNumber(e.target.value)} placeholder="Введите значение" /></th>
                        <th className={styles.cell}><Select small value={year} options={yearOptions} onSelect={v => setYear(v ? v : '')} /></th>
                        <th className={styles.cell}><FormControlEshopInput small onChange={e => setName(e.target.value)} placeholder="Введите значение" /></th>
                        <th className={styles.cell}><Select small value={reason} options={reasonOptions} onSelect={v => setReason(v ? v : '')} /></th>
                        <th className={styles.cell}>
                            <NumberInput small value={priceFrom} onChange={e => setPriceFrom(e)} placeholder="от" />
                            <NumberInput small value={priceTo} onChange={e => setPriceTo(e)} placeholder="до" />
                        </th>
                    </tr>}
                    </thead>
                    <tbody>
                    {lotsSorted().map(l => <tr onClick={() => canChange && changeLotRegNumber(l.regNumber)} key={l.regNumber} className={styles.tableRow}>
                        <td className={`${styles.cellSelectable} ${styles.cell} ${styles.cellBody} ${l.regNumber === store.lotRegNumber ? styles.cellSelected : ''}`}>{l.regNumber}</td>
                        <td className={`${styles.cellSelectable} ${styles.cell} ${styles.cellBody} ${l.regNumber === store.lotRegNumber ? styles.cellSelected : ''}`}>{l.basicInfo.contractConclusionYear}</td>
                        <td className={`${styles.cellSelectable} ${styles.cell} ${styles.cellBody} ${l.regNumber === store.lotRegNumber ? styles.cellSelected : ''}`}>{l.basicInfo.subject}</td>
                        <td className={`${styles.cellSelectable} ${styles.cell} ${styles.cellBody} ${l.regNumber === store.lotRegNumber ? styles.cellSelected : ''}`}>{l.singleProviderReason?.name}</td>
                        <td className={`${styles.cellSelectable} ${styles.cell} ${styles.cellBody} ${l.regNumber === store.lotRegNumber ? styles.cellSelected : ''}`}>
                            {getSumString(l)}
                        </td>
                    </tr>)}
                    </tbody>
                </table>
                <div className={styles.lotCardPager}>
                    <Pager page={{from: 0, to: 1, totalCount: lotsSorted().length, items: lotsSorted()}} goTo={() => {}} pageSize={lotPageSize} changePageSize={v => setLotPageSize(v)} />
                    <div>Всего записей {lotsSorted().length}</div>
                </div>
            </Card>
        </Section>);
};
