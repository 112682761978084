














import { SelectOption } from "@/models/enums";
import { Component, Prop, Vue, Watch } from "vue-property-decorator";

@Component
export default class ComboBox extends Vue {
    @Prop({ default: () => [] }) private options!: SelectOption<unknown>[] | Promise<SelectOption<unknown>[]>;
    @Prop({ default: null }) private value!: unknown | null | undefined;
    @Prop({ default: false }) private disabled!: boolean;
    @Prop({ default: false }) private allowNullValue!: boolean;
    @Prop({ default: null }) private nullValueText!: string | null;

    public created() {
        this.updateOptions();
    }

    @Watch("options") private updateOptions() {
        if ((this.options as SelectOption<unknown>[]).length !== undefined) {
            this.realOptions = this.options as SelectOption<unknown>[];
            this.loaded = true;
        } else {
            const promise = this.options as Promise<SelectOption<unknown>[]>;
            promise.then(r => {
                this.realOptions = r;
                this.loaded = true;
            });
        }
    }

    public loaded = false;
    public realOptions: SelectOption<unknown>[] = [];

    private change(e: Event) {
        if (!this.loaded) return;

        const idxString = (e.target! as HTMLSelectElement).value;
        const idx = parseInt(idxString, 10);

        // ?? null - for back compat
        this.$emit("change", isFinite(idx) && idx >= 0 ? this.realOptions[idx].key ?? null : null);
        this.$emit("input", isFinite(idx) && idx >= 0 ? this.realOptions[idx].key ?? null : null);
    }
}
