import {createStore} from "effector";
import {getSubjectDeclarations} from "@/api/directory";
import {createStoredRemoteDataset, StoredRemoteDataset} from "@/components/table";
import {SubjectDeclaration} from "@/models/SubjectDeclaration/SubjectDeclaration";
import {FilterConditionType, FilterValueType} from "@/api/http";

export interface SubjectDeclarationFilters {
    id: number
    characteristics: string
    measurementUnit: string
    okpd2: string
    projectCheck: string
    standardProduct: string
    subject: string
}

export interface SubjectDeclarationsStore {
    subjectDeclarations: StoredRemoteDataset<SubjectDeclaration, SubjectDeclarationFilters>
    loaded: boolean
}

export const createSubjectDeclarationsStore = () => {
    return createStore<SubjectDeclarationsStore>({
        subjectDeclarations: createStoredRemoteDataset<SubjectDeclaration, SubjectDeclarationFilters>({
            async load(filters, from, count) {
                return getSubjectDeclarations(from, count, {
                    id: [{
                        type: FilterValueType.LONGINT,
                        conditionType: FilterConditionType.EQUAL,
                        longint: filters.id
                    }],
                    characteristics: [{
                        type: FilterValueType.STRING,
                        conditionType: FilterConditionType.CONTAINS,
                        string: filters.characteristics
                    }],
                    measurementUnit: [{
                        type: FilterValueType.STRING,
                        conditionType: FilterConditionType.CONTAINS,
                        string: filters.measurementUnit
                    }],
                    okpd2: [{
                        type: FilterValueType.STRING,
                        conditionType: FilterConditionType.CONTAINS,
                        string: filters.okpd2
                    }],
                    projectCheck: [{
                        type: FilterValueType.STRING,
                        conditionType: FilterConditionType.CONTAINS,
                        string: filters.projectCheck
                    }],
                    standardProduct: [{
                        type: FilterValueType.STRING,
                        conditionType: FilterConditionType.CONTAINS,
                        string: filters.standardProduct
                    }],
                    subject: [{
                        type: FilterValueType.ANY,
                        conditionType: FilterConditionType.CONTAINS,
                        string: filters.subject
                    }]
                });
            }
        }),
        loaded: false
    });
};
