
























import { ITableColumn, IRowConfiguration } from "@/components/TableAbstractions";
import EventBus, { showModal } from "@/EventBus";
import { Component, Vue, Prop } from "vue-property-decorator";
import DataTable from "@/components/vue/DataTable.vue";
import AddSupply from "./AddSupply.vue";
import { IFieldProps } from '../../components/vue/form-table/FieldBase';
import { SimpleRow } from '../../components/vue/form-table/FormFields';
import { Contract, ContractExecutionProvision } from '../../models/Contracts';
import { ChargeSourceStrings } from '../../models/enums/Contracts';
import { formatNumber } from '../../NumberFormatting';
import { ContractScope } from './ContractScope';
import ContractExecutionProvisions from '../../api/ContractExecutionProvisions';
import { ObjectStatusStrings } from '../../models/enums';
import { ConfirmationModal } from "@/views/Contracts/modals/ConfirmationModal";
import {ContractGuaranteeProvision} from "@/models/Contracts/ContractGuaranteeProvision";
import ContractGuaranteeProvisions from "@/api/ContractGuaranteeProvisions";
import AddGuaranteeProvision from "@/views/CreateContract/AddGuaranteeProvision.vue";
import {formatDate} from "@/dateUtils";
import React from "react";

@Component({ components: { DataTable } })
export default class Supply extends Vue {
    @Prop() public props!: IFieldProps<SimpleRow<ContractScope>>;

    get headers(): ITableColumn<ContractGuaranteeProvision>[] {
        return [
            {
                title: "№ в реестре",
                getter: (p, i) => (i + 1).toString(),
                size: "auto"
            },
            {
                title: "Вид обеспечения",
                getter: p => ChargeSourceStrings[p.source],
            },
            {
                title: "​Сумма обеспечения, руб.",
                getter: p => formatNumber(p.amountRub),
            },
            {
                title: "Срок начала действия",
                getter: p => p.guaranteeDuration?.from ? formatDate(p.guaranteeDuration.from) : "",
            },
            {
                title: "​Срок окончания действия",
                getter: p => p.guaranteeDuration?.to ? formatDate(p.guaranteeDuration.to) : "",
            },
            {
                title: "​Статус",
                getter: p => p.status && ObjectStatusStrings[p.status],
            },
        ];
    }

    public createConfig(): IRowConfiguration<ContractGuaranteeProvision> {
        const that = this;

        return  {
            get useSlotForActionColumn() { return that.props.state === 'ENABLED' }
        };
    }

    public config = this.createConfig();

    public get dataset() { return this.props.source.guaranteeProvision }

    public async addEditSupply(src: ContractGuaranteeProvision | null) {
        const result = await EventBus.callModal<{ src: ContractGuaranteeProvision | null; contractId: string }, ContractGuaranteeProvision>(
            AddGuaranteeProvision, { src, contractId: this.props.source.contract.id });
        if (result.isOk) {
            const r = result.getResult();
            if (!src) {
                this.dataset.push(r);
            } else {
                const ix = this.dataset.findIndex(x => x.id === r.id);
                this.dataset.splice(ix, 1, r);
            }
        }
    }

    public async deleteSupply(src: ContractGuaranteeProvision) {
        const confirmation = await showModal(ConfirmationModal, {title: "Удаление", text: "Вы уверены, что хотите удалить обеспечение?"});
        if (!confirmation)
            return;

        await ContractGuaranteeProvisions.delete(src);
        
        const ix = this.dataset.indexOf(src);
        if (ix !== -1) this.dataset.splice(ix, 1);
    }
}
