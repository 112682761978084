import React from "react";
import { ProductsStore } from "./store";
import { observer } from "mobx-react";
import { ProductsCard } from "./ProductsCard";
import styles from "./ProductsTable.module.css";

type ProductsTableProps = {
    store: ProductsStore
};

export const ProductsTable = observer(({ store }: ProductsTableProps) => {
    return <div className={styles.tableContent}>
        {store.page.items.map(d => <ProductsCard key={d.id} store={store} data={d} />)}
    </div>;
});
