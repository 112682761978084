import React, {FC, useContext, useMemo, useState} from "react";
import {
    FBoxPicker,
    FCheckBox,
    FComboBox,
    FDatePicker,
    FDecimalBox,
    FormStateContext,
    FTextBox
} from "@/components/form";
import {Card} from "@/components/Card";
import {HStack, VGrid, VStack} from "@/components/layouts";
import {Button, HintMark, Label, Note, Underlined} from "@/components/primitive";
import {Field, Fields, VertField} from "@/components/form/Field";
import {
    createYearSelection,
    DayType,
    LawType,
    LawTypeStrings,
    SelectOption,
    SmpTypeStrings,
    SmpTypeStringsShort,
    toSelectOptions
} from "@/models/enums";
import {
    addOrEditPaymentStage,
    getPaymentStagesTotal, LotInfo,
    pickSubjectClass,
    setAdvancePercent,
    setArticle15Accordance,
    setBankingSupport,
    setContractConclusionYear,
    setContractTerm,
    setContractType,
    setElectronicExecution,
    setGoodsDeliveryByMultipleConsignees,
    setGoodsDeliveryByRecipientRequest,
    setIsAuditServicesPurchase,
    setIsGoodsUsed,
    setIsRepairRelated,
    setLotJoinType,
    setPaymentDate,
    setPaymentTerm,
    setPlannedNotificationPublishYear,
    setPlannedPublishDate,
    setPlannedPublishDatePrecise,
    setProviderSelectionType,
    setReviewDocumentsTerm,
    setSendDocumentsTerm,
    setSingleProvider,
    setSingleProviderReasonId,
    setSmpPercent,
    setSmpType,
    setSpecialPurchaseType,
    setSubject
} from "@/views/ComposedLot/shared/store/lotInfo";
import {
    Article15AccordanceStrings,
    BankingSupportStrings,
    ContractTypeStrings, LotBasicInfo,
    LotVisibleStatusStrings,
    PaymentStage,
    ProviderSelectionType,
    ProviderSelectionType223AtAggregatedLot,
    ProviderSelectionType223Strings,
    ProviderSelectionType223StringsAtAggregatedLot,
    ProviderSelectionType44Strings,
    ProviderSelectionType44StringsBeyond2021,
    SpecialPurchaseTypeStrings
} from "@/models/ComposedLots";
import {Column, Table} from "@/components/table/Table";
import {formatNumber} from "@/NumberFormatting";
import {useMappedStore} from "@/storeUtils";
import {SectionProps} from "@/views/ComposedLot/shared/store";
import {getTotalPaymentAmount} from "@/views/ComposedLot/shared/store/plannedPayments";
import EventBus, {showModal} from "@/EventBus";
import {PickAnyParticipantModal, PickParticipantModal} from "@/modal-views/PickParticipantModal";
import {AuditConfirmationModal} from "@/views/ComposedLot/shared/sections/modals/AuditConfirmationModal";
import {EditReasonTypeStrings} from "@/models/enums/EditReasonType";
import {SelectParticipantModal} from "@/views/ComposedLot/shared/modals/SelectParticipantModal";
import {setMainCustomer, setPerformer} from "@/views/ComposedLot/shared/store/head";
import Auth from "@/api/Auth";
import {ElectronicExecutionString} from "@/models/ComposedLots/ElectronicExecution";
import {PurchaseCategory} from "@/models/ComposedLots/PurchaseCategory";
import {addPurchaseCategory, removePurchaseCategory} from "@/views/ComposedLot/shared/store/purchaseCategories";
import Decimal from "decimal.js";
import {
    PaymentTermTypeStrings,
    ReviewDocumentsTermTypeStrings,
    SendDocumentsTermTypeStrings
} from "@/models/ComposedLots/PaymentStageTerm";
import {compareSupplyStageByDate, Specification, SupplyStage, SupplyStageCommon} from "@/models/ComposedLots/specifications";
import {j} from "@/reactUtils";
import styles from "@/views/Monopoly/shared/sections/object/SpecificationsSection/SpecificationsSection.module.css";
import SpecificationDetailsModal from "@/views/modals/SpecificationDetailsModal/SpecificationDetailsModal.vue";
import {spreadIf} from "@/miscUtils";
import {AddPaymentStageModal} from "@/modal-views";
import { LotSpecifications, SpecificationForm } from "@/views/ComposedLot/shared/store/specifications";
import { formatDate } from "@/modal-views/PickSpecificationModal/SupplyStages";
import Hint from "@/components/Hint.vue";
import { observer } from "mobx-react";
import { toJS } from "mobx";
import _ from "lodash";

type RelativeSupplyStage = SupplyStageCommon & {
    readonly type: "RELATIVE"
    readonly startOffset: number
    readonly startOffsetType: DayType
    readonly endOffset: number
    readonly endOffsetType: DayType
    readonly finishYear: number
};

type AbsoluteSupplyStage = SupplyStageCommon & {
    readonly type: "ABSOLUTE"
    readonly startDate: Date
    readonly endDate: Date
};

const minSupplyDateHint = `Определяется автоматически как дата оплаты последнего этапа, если сумма авансовых платежей по такому этапу составляет менее 100% от суммы платежа в этапе. Если сумма авансовых платежей по такому этапу составляет 100% - определяется автоматически как дата поставки товара (оказания услуги, выполнения работы) плюс сроки направления и рассмотрения отчётных документов`;

const maxPeriodHint = `Определяется автоматически как дата начала исполнения первого этапа`;
const subjectHint = `Поле должно содержать развернутое описание закупаемых работ/товаров/услуг
                        
Пример:

- Поставка бумаги для нужд Департамента градостроительной политики города Москвы на первое полугодие 2016 года

- Услуги почты России на 2016 год

- Системное обслуживание информационно-вычислительных комплексов`;




const pickSingleProvider = async () => {
    const participant = await showModal(PickParticipantModal);
    if (participant) setSingleProvider(participant);
};

const formatPaymentDate = (paymentStage: PaymentStage, end: boolean, payment: boolean): string => {
    switch (paymentStage.supplyStage.type) {

        case "RELATIVE":
            if (payment) {
                const type = end ? paymentStage.supplyStage.endOffsetType : paymentStage.supplyStage.startOffsetType;
                const typeStr = type === DayType.NORMAL ? "календарных" : "рабочих";

                return `${paymentStage.paymentDateOffset} ${typeStr} дней с момента заключения контракта`;
            }

            const offset = end ? paymentStage.supplyStage.endOffset : paymentStage.supplyStage.startOffset;
            const type = end ? paymentStage.supplyStage.endOffsetType : paymentStage.supplyStage.startOffsetType;
            const typeStr = type === DayType.NORMAL ? "календарных" : "рабочих";
            return `${offset} ${typeStr} дней с момента заключения контракта`;
        case "ABSOLUTE":
            if (payment) {
                return paymentStage.paymentDate!.toLocaleDateString();
            }

            return end
                ? paymentStage.supplyStage.endDate.toLocaleDateString()
                : paymentStage.supplyStage.startDate.toLocaleDateString();
    }
};

interface InfoSection extends SectionProps {
    isLotAggregated: boolean
}
export const InfoSection: FC<InfoSection> = observer(x => {
    const [lot, head, singleProviderReasons, payments, editReason, participants, specifications, purchaseCategoriesData, purchaseCategoriesIds] = useMappedStore(x.formStore,
        s => [
            s.info,
            s.head,
            s.info.law === "F44"
                ? s.relatedData.singleProviderReasons44
                : s.relatedData.singleProviderReasons223,
            s.plannedPayments.sources,
            s.head.editReason,
            s.relatedData.participants,
            s.specifications,
            s.relatedData.purchaseCategories,
            s.purchaseCategories.categories
        ]);

    const [openedTabs, setOpenedTabs] = useState<number[]>([]);
    const toggleTab = (tabIndex: number) => setOpenedTabs(
        tabs => ~tabs.indexOf(tabIndex) ? tabs.filter(tab => tab !== tabIndex) : [...tabs, tabIndex]
    );

    const callSpecModal = async (v: {spec?: Specification; id: number}): Promise<void> => {
        if (v.spec)
            await EventBus.callModal(SpecificationDetailsModal, v);
        // await EventBus.callModal(SpecificationDetailsModal, v);
    };

    const openedTabsSet = useMemo(() => new Set(openedTabs), [openedTabs]);

    // срок начала исполнения контракта для фз44
    const getMinSupplyDate = (specs: SpecificationForm[]) => {
        let minResult: SupplyStage | undefined = undefined;
        let minAbsoluteResult: SupplyStage | undefined = undefined;
        const stages = specs.flatMap(s => s.supplyStages);
        stages.forEach((stage, idx) => {
            if(stage.stageType === "RELATIVE") {
                if(minResult === undefined) {
                    minResult = stage.validatedStage;
                    return;
                }
                //@ts-expect-error
                if(minResult && stage.startRelative && stage.startRelative <= minResult!.startRelative) {
                    minResult = stage.validatedStage;
                }
                return;
            }
            if(stage.stageType === "ABSOLUTE") {
                if(minAbsoluteResult === undefined) {
                    minAbsoluteResult = stage.validatedStage;
                    return;
                }
                //@ts-expect-error
                if(stage.startAbsolute && stage.startAbsolute.getTime() <= minAbsoluteResult!.startDate.getTime()) {
                    minAbsoluteResult = stage.validatedStage;
                }
                return;
            }
        });
        if(minResult) return formatDate(minResult, false);
        if(minAbsoluteResult) return formatDate(minAbsoluteResult, false);
        return "Не указан";
    };

    const minSupplyDate = getMinSupplyDate(specifications.specificationForms);

    // срок исполнения для фз 44
    const getMaxPeriod = (stages: PaymentStage[]) => {
        const maxStage = stages.reduce((prev, current) => {
            if (current.paymentDateOffset === undefined) {
              return prev;
            }
            if (prev.paymentDateOffset === undefined || prev.paymentDateOffset > current.paymentDateOffset) {
              return current;
            }
            return prev;
          }, stages[0]);

          return maxStage ? formatPaymentDate( maxStage ,false, true) : "Не указан";
    };

    const maxPeriod = getMaxPeriod(lot.paymentStages);

    const state = useContext(FormStateContext);

    const changeIsAudit = () => lot.isAuditServicesPurchase ? setIsAuditServicesPurchase(false) : callAuditModal();

    const callAuditModal = async (): Promise<void> => {
        const confirmation = await showModal(AuditConfirmationModal) ?? false;
        if (confirmation) {
            setIsAuditServicesPurchase(confirmation);
        }
    };

    const viewing = x.formAction === "viewing";
    const editing = x.formAction === "editing";
    const creating = x.formAction === "creating";

    const required = !viewing;
    const orientation = state === "readonly" ? "vertical" : "horizontal";

    const goodUsageTitlePart = lot.contractType === "SERVICE_PROVISION"
        ? "оказания услуг"
        : "выполнения работ";

    const purchaseItemAction = {
        "GOODS_PROVISION": "Поставка товаров",
        "WORK_EXECUTION": "Выполнение работ",
        "SERVICE_PROVISION": "Оказание услуг"
    };

    const goodUsageTitle = `Для ${goodUsageTitlePart} требуется товар`;
    const isGoodUsageHidden = (lot.contractType ?? "GOODS_PROVISION") === "GOODS_PROVISION";

    const isMedicines = lot.specialPurchaseType === "MEDICINES";
    const [isSmallVolume] = useMappedStore(x.formStore, s => [s.info.specialPurchaseType === "SMALL_VOLUME_SIMPLE" || s.info.specialPurchaseType === "SMALL_VOLUME_CARE"]);

    const totalPrice = getTotalPaymentAmount(payments);

    const totalPriceSpecification = specifications.specificationForms.map(s => s.startPrice?.total ?? new Decimal(0)).reduce((v, s) => s.add(v), new Decimal(0));

    const isMaster = lot.lotJoinType === "MASTER";
    const isSlave = lot.lotJoinType === "SLAVE";
    const isCommon = lot.lotJoinType === "NO";

    const singleProviderReasonOptions = singleProviderReasons.map(x => {
        return {key: x.id, desc: x.name};
    }).filter(item => {
        if (lot.specialPurchaseType === "SMALL_VOLUME_SIMPLE" || lot.specialPurchaseType === "SMALL_VOLUME_CARE") {
            return true;
        } else return !(item.key === 1000004 || item.key === 1000005); // Ticket #555 - filter out 4. and 5. options when they are not selected via radio
    });

    const isJoined = lot.lotJoinType === "SLAVE";

    const is44 = lot.law === "F44";
    const is223 = lot.law === "F223";

    const smpName = is223 ? "МСП" : "СМП";
    const contractName = is223 ? "договор" : "контракт";

    const isQuotationSession = false;
    const isSingleProviderPurchase = lot.providerSelectionType === "SINGLE_PROVIDER_PURCHASE";
    const isSingleProviderPurchaseUnder600k = isSingleProviderPurchase && (lot.singleProviderReasonId === 1 || lot.singleProviderReasonId === 2);

    const isAggregated = isSingleProviderPurchaseUnder600k || isQuotationSession;

    const isProviderSelectionTypeLocked = (lot.specialPurchaseType !== "NO") || lot.isAuditServicesPurchase;
    const canSelectSmp = (isSingleProviderPurchase || is223 || isMaster || totalPrice.toNumber() >= 3e6) && !lot.isAuditServicesPurchase;
    const providerSelectionTypeOptions = is223
        ? ProviderSelectionType223StringsAtAggregatedLot
        : ProviderSelectionType44Strings;
    const providerSelectionTypeSelectOptions: (SelectOption<ProviderSelectionType> | SelectOption<ProviderSelectionType223AtAggregatedLot>)[] =
        lot.isAuditServicesPurchase
            ? [{key: "E_EXAM", desc: "Электронный конкурс по 44-ФЗ"}]
            : toSelectOptions(providerSelectionTypeOptions, true, "Выберите способ определения поставщика");

    const {FULL: SmpFullSelectOption, ...SmpDefaultSelectOptions} = SmpTypeStrings;

    const subjectClassParentsNames = lot.subjectClass?.parents
        .reduce((acc, p) => [...acc, p.name], [] as string[])
        .join("/");

    const subjectClassDescription = lot.subjectClass?.description
        ? `${subjectClassParentsNames}/${lot.subjectClass?.description}`
        : "";

    const subjectClassesCode = lot.subjectClass?.code;
    const kpgzProductNames = specifications.specificationForms.map(spec => spec.subjectDeclaration?.subject ?? "");
    const paymentsResults = getPaymentStagesTotal(lot.paymentStages);
    const paymentIsChanged = lot.paymentStages.map(p => p.financialSourceUsage).some(f => f.length > 0);

    const isBeyond2021Year = Number(lot.plannedNotificationPublishYear) > 2021;
    const beyond2021ProviderType44SelectionTypes = toSelectOptions(ProviderSelectionType44StringsBeyond2021, true, "Выберите способ определения поставщика");

    const purchaseCategoriesOptions = useMemo(() => [
        {
            key: undefined,
            desc: "Выберите категорию"
        },
        ...purchaseCategoriesData.map(category => ({
                key: category,
                desc: `${category.paragraph} ${category.name}`
            })
        )], [purchaseCategoriesData]);

    const selectedPurchaseCategories = useMemo(() =>
            purchaseCategoriesIds.map(({id}) => purchaseCategoriesData.find(category => category.id === id) as PurchaseCategory),
        [purchaseCategoriesIds, purchaseCategoriesData]);

    const purchaseCategoriesTable = <Table<PurchaseCategory> dataset={selectedPurchaseCategories} columns={[
        Table.Column("paragraph", x => <>{x.item.paragraph}</>, {width: "min-content"}),
        Table.Column("name", x => <>{x.item.name}</>),
        ...(state !== "readonly" ? [Table.Column(
            "delete",
            x => <Button color="red" icon="aClose" onClick={() => removePurchaseCategory(x.item)}/>,
            {width: "min-content"}
        )] : []) as Column<PurchaseCategory>[]
    ]} hideHeaders/>;

    const spgzColumns: Column<Specification>[] = [
        Table.Column(
            "СПГЗ",
            ({ item }) => <>{item.subjectDeclaration.subject}</>
        ),
        Table.Column(
            "Объем",
            ({ item }) => <>{item.volume}</>
        ),
        Table.Column(
            "Цена за единицу ТРУ, руб.",
            ({ item }) => <>{item.startPrice?.perItem}</>
        ),
        Table.Column(
            "Сумма,руб.",
            ({ item }) => <>{item.startPrice?.total}</>
        )
    ];

    const bottomBlock = isSmallVolume ? null : <>
        {
            is44 && !isMaster && <Field title="Закупка осуществляется в соответствии с ч. 4-6 ст.15 44-ФЗ">
                <FComboBox options={toSelectOptions(Article15AccordanceStrings)}
                           value={lot.article15Accordance}
                           onChange={setArticle15Accordance}/>
            </Field>
        }
        {
            is44 ? <Field title="Планируемая дата публикации">
                <FDatePicker mode="months" value={lot.plannedPublishDate} onChange={setPlannedPublishDate}/>
            </Field> : <Field
                title={lot.isPlannedPublishDatePrecise ? "Планируемая дата публикации" : "Планируемый срок публикации"}
                hidden={isQuotationSession || isSingleProviderPurchaseUnder600k}>
                <HStack spacing="15px">
                    <FDatePicker mode={lot.isPlannedPublishDatePrecise ? "days" : "months"}
                                 value={lot.plannedPublishDate} onChange={setPlannedPublishDate}/>
                    {
                        state === "enabled" && <Button
                            onClick={() => setPlannedPublishDatePrecise(!lot.isPlannedPublishDatePrecise)}
                            title={lot.isPlannedPublishDatePrecise ? "указать срок" : "указать точную дату"}/>
                    }
                </HStack>
            </Field>
        }
        {
            (!isMaster || isSlave || isCommon) && <Field title="КПГЗ" required={required} hidden={isAggregated}>
                <FBoxPicker value={subjectClassesCode && lot.subjectClass?.description
                    ? `${subjectClassesCode} ${subjectClassDescription}` : ""}
                            onPick={() => pickSubjectClass(lot.subjectClass)}/>
            </Field>
        }
        {/*made as label below (should we clone that?)*/}
        <VertField title="ОКПД2" hidden={!lot.subjectClass?.okpd2}>
            <Label preset="readonlyValue">
                {lot.subjectClass?.okpd2?.toString()}
            </Label>
        </VertField>
        <Field title={`Тип ${contractName}а`} required={required} hidden={isAggregated}>
            <FComboBox options={toSelectOptions(ContractTypeStrings, true, `Выберите тип ${contractName}а`)}
                       value={lot.contractType}
                       onChange={setContractType}/>
        </Field>
        <Field title="Поставка товаров будет осуществляться нескольким грузополучателями"
               hidden={lot.contractType !== "GOODS_PROVISION"}>
            <FCheckBox value={lot.isGoodsDeliveryByMultipleConsignees}
                       onChange={setGoodsDeliveryByMultipleConsignees}/>
        </Field>
        <Field title="Поставка товаров будет осуществляться по заявкам получателей"
               hidden={!lot.isGoodsDeliveryByMultipleConsignees}>
            <FCheckBox value={lot.isGoodsDeliveryByRecipientRequest}
                       onChange={setGoodsDeliveryByRecipientRequest}/>
        </Field>
        <Field title={goodUsageTitle} hidden={isGoodUsageHidden}>
            <FCheckBox value={lot.isGoodsUsed} onChange={setIsGoodsUsed}/>
        </Field>
        {
            !isMaster && <Field title="Данный лот должен быть включен в совместный лот" hidden={isAggregated}>
                <FCheckBox value={isSlave} onChange={x => setLotJoinType(x ? "SLAVE" : "NO")}/>
            </Field>
        }
        {/*should be pickable (and maybe clickable in view-mode)*/}
        <Field title="Главный заказчик" required={required} hidden={!isSlave}>
            <HStack alignItems="center" spacing="10px">
                <Label preset="readonlyValue" text={head.mainCustomer?.fullName ?? ""}/>
                {!viewing && <Button icon="aEdit" onClick={async () => {
                    const participant = await showModal(PickAnyParticipantModal, {overrideTitle: "Выбор организатора торгов"});
                    if (participant)
                        setMainCustomer(participant);
                }}/>}
            </HStack>
        </Field>
        {
            !lot.aggregatingLotId ? <Field title="Совместная закупка" hidden={isAggregated}>
                <FCheckBox disabled={!creating} value={isMaster} onChange={x => setLotJoinType(x ? "MASTER" : "NO")}/>
            </Field> : <Field title="Совместная закупка">
                <a rel="noreferrer" target="_blank" href={"/#/plan-objects/composed-lots/" + lot.aggregatingLotId}
                   style={{textDecoration: "underline", cursor: "pointer", color: "unset"}}>
                    {lot.aggregatingLotRegNumber}
                </a>
            </Field>
        }
        <Field title={`Размещение среди ${smpName}`} hidden={isQuotationSession || (isSingleProviderPurchase && lot.singleProviderReasonId === 2)}>
            <FComboBox options={totalPrice.gte(20e6) && lot.law === LawType.F44
                ? toSelectOptions(SmpDefaultSelectOptions)
                : toSelectOptions(lot.law === LawType.F223 && lot.providerSelectionType === "E_AUC" ? SmpTypeStringsShort : SmpTypeStrings)}
                       value={lot.smpType}
                       onChange={setSmpType}
                       disabled={lot.law === LawType.F223 && lot.providerSelectionType === "E_AUC_MSP"}/>
        </Field>
        <Field title={`Доля ${smpName}`} hidden={lot.smpType === "NO" || isAggregated}>
            <FDecimalBox value={lot.smpPercent} onChange={(v) =>
                setSmpPercent(v?.greaterThan(99) ? new Decimal(v.toString().substring(0, 2)) : v)}
                         disabled={(!canSelectSmp && lot.smpType !== "SUBCONTRACTING") || lot.law === LawType.F223 && lot.providerSelectionType === "E_AUC_MSP"}/>
        </Field>
        <Field title={`Сумма ${smpName}`} hidden={lot.smpType === "NO" || isAggregated}>
            <Label preset="readonlyValue" text={formatNumber(totalPrice.mul(lot.smpPercent ?? 0).div(100))}/>
        </Field>
        <Field title="Электронное исполнение"
               required
               hidden={(lot.providerSelectionType === "SINGLE_PROVIDER_PURCHASE"
                       && lot.specialPurchaseType !== "SPECIAL_SERVICE" && lot.specialPurchaseType !== "SPORT_SERVICE")}>
            <FComboBox options={toSelectOptions(ElectronicExecutionString)}
                       value={lot.electronicExecution}
                       onChange={setElectronicExecution}/>
        </Field>
        <Field title="Срок начала исполнения контракта"
                hidden={!is44 || isAggregated}
                hint={{ text: minSupplyDateHint}}>
            <Label preset="readonlyValue" text={minSupplyDate}/>
        </Field>
        <Field
            title="Категории закупки, которые не учитываются при расчёте годового объёма закупок у субъектов МСП (в соответствии с ПП РФ № 1352)"
            hidden={lot.law === "F44" || lot.singleProviderReasonId === 1}>
            {state === "readonly"
                ? purchaseCategoriesIds.length > 0 ? purchaseCategoriesTable : <Label preset="readonlyValue">Нет</Label>
                : <FComboBox options={purchaseCategoriesOptions}
                             value={undefined}
                             onChange={v => !purchaseCategoriesIds.find(({id}) => id === v.id)
                                 && addPurchaseCategory(v)}/>}
        </Field>
        <Field title="" hidden={state === "readonly" || purchaseCategoriesIds.length === 0}>
            {purchaseCategoriesTable}
        </Field>
        {
            /**
             * That field exists only when F223 law type is selected, calls a confirmation modal when checked
             * No modal being called when unchecked
             * */
            is223 &&
            <Field title="Закупка услуг по проведению обязательного аудита в соответствии с 307-ФЗ"
                   hidden={isAggregated}>
                <FCheckBox value={lot.isAuditServicesPurchase} onChange={() => changeIsAudit()}/>
            </Field>
        }
        <Field title={`Срок исполнения ${contractName}а`} hidden={isAggregated} hint={{text: maxPeriodHint}}>
            {is223 
                ? <FDatePicker mode="months" value={lot.contractTerm} onChange={setContractTerm}/>
                : <Label preset="readonlyValue" text={maxPeriod}/>
            }
        </Field>
        <Field title="Размер аванса (%)" hidden={isAggregated}>
            <FDecimalBox disabled={creating} precision={2} value={lot.advancePercent} onChange={setAdvancePercent}/>
        </Field>
        <Field title="Дата оплаты" hidden={lot.paymentType === "STAGED" || isMaster || isAggregated || !isSmallVolume}>
            <FDatePicker mode="months" value={lot.singlePaymentDate} onChange={setPaymentDate}/>
        </Field>

    </>;

    const PaymentStagesBlock: React.FC = observer(() => {
        if (isSmallVolume) return null;

        const calculateSpecificationsTotalPriceAtPaymentStage = (ps: PaymentStage): Decimal => {
            /*
                В колонке "Сумма платежа" изначально должны появляться суммы исходя из того что стоит в блоке "Общая сумма закупки"
                а у вас расчет идет от поля "НМЦ", пример скриншот из тестового ПАК ПИО

                UPD:
                Предзаполенное поле "Сумма платежа" должно вычисляться как сумма всех спецификаций в этом этапе оплаты..

                UPD2:
                Изначально было правильно
             */
            // const amount = getTotalPaymentAmount(payments).div(lot.paymentStages.length);
            // return amount;
            console.log(ps);
            let price = new Decimal(0);
            ps.specifications?.forEach(spec => {
                spec.supplyStages.forEach(ss => {
                    if (compareSupplyStageByDate(ps.supplyStage, ss))
                        price = price.add(ss.volume.mul(spec.startPrice?.perItem ?? new Decimal(0)));
                });
            });

            return price;
        };

        // Процента от НМЦ ${contractName}a,
        const getStartPricePercent = (ps: PaymentStage): { value: Decimal; formatted: string } => {
            let result = new Decimal(0);
            if (ps.financialSourceUsage.length > 0) {
                result = ps.financialSourceUsage.map(s => s.paymentAmount ?? new Decimal(0)).reduce((p, s) => s.add(p), new Decimal(0));
            } else {
                // для каждой спецификации в этапе оплаты надо найти соответствующий этап в графике поставки и сложить это дело
                result = calculateSpecificationsTotalPriceAtPaymentStage(ps);
            }

            result = result.div(totalPriceSpecification).mul(100);

            return { formatted: formatNumber(result), value: result };
        };

        // Процент суммы закупки от НМЦ
        console.log(lot.paymentStages);
        const startPricePercent = lot.paymentStages.reduce((p, c) => p.add(getStartPricePercent(c).value), new Decimal(0));

        // Сумма платежа
        const getPayment = (ps: PaymentStage): { value: Decimal; formatted: string } => {
            let result = new Decimal(0);

            if (ps.financialSourceUsage.length > 0) {
                result = ps.financialSourceUsage.map(f => f.paymentAmount ?? new Decimal(0)).reduce((p, s) => s.add(p), new Decimal(0));
            } else {
                result = calculateSpecificationsTotalPriceAtPaymentStage(ps);
            }

            return { formatted: formatNumber(result, false, 2), value: result };
        };
            // Размер аванса
        const getAdvanceSize = (ps: PaymentStage): { value: Decimal; formatted: string } => {
            const result = ps.financialSourceUsage.map(s => s.advanceAmount ?? new Decimal(0)).reduce((p, s) => s.add(p), new Decimal(0)).div(ps.financialSourceUsage.map(s => s.paymentAmount ?? new Decimal(0)).reduce((p, s) => s.add(p), new Decimal(0))).mul(100);
            return { formatted: formatNumber(result), value: result };
        };
        // Сумма аванса
        const getAdvanceAmount = (ps: PaymentStage): { value: Decimal; formatted: string } => {
            const result = ps.financialSourceUsage.map(f => f.advanceAmount ?? new Decimal(0)).reduce((p, s) => s.add(p), new Decimal(0));
            return { formatted: formatNumber(result), value: result };
        };

        const editPaymentStage = async (stage: PaymentStage) => {
            const law = lot.law;
            //без клонирования падает на обзервере. Не должно ничего сломать
            //потому что при сохранении из результата(raw) берутся только paymentDate, paymentDateOffset и financialSourceUsage
            const nonObservableStage = _.cloneDeep(stage);
            const nonObservableStages = _.cloneDeep(lot.paymentStages);
            const nonObservablePayments = _.cloneDeep(payments);
            const nonObservablePayment = getPayment(nonObservableStage).value;
            const nonObservablePercent = getStartPricePercent(nonObservableStage).value;
            console.log("Just stage:", stage);
            console.log("nonObservableStage: ", nonObservableStage);
            const raw = await showModal(AddPaymentStageModal, {
                totalPrice: totalPriceSpecification,
                selectedStage: nonObservableStage,
                payment: nonObservablePayment,
                startPricePercent: nonObservablePercent,
                plannedPayments: nonObservablePayments,
                paymentStages: nonObservableStages,
                law,
            });
            if (!raw) return;

            const result = {
                new: {
                    paymentDate: raw.paymentDate,
                    paymentDateOffset: raw.paymentDateOffset,
                    specifications: [...(stage?.specifications ?? [])],
                    supplyStage: stage?.supplyStage,
                    financialSourceUsage: raw.financialSourceUsage
                },
                old: stage
            };

            addOrEditPaymentStage(result);
        };

        if(specifications.hasChanges) specifications.onUpdateSpecification?.();

        const paymentStagesColumns: Column<PaymentStage>[] = [
            Table.AutoColumn("Номер этапа", x => (
                    <div style={{display: "flex", alignItems: "center", justifyContent: "center"}}>
                        <Label preset="boldSmall"
                               className={j(styles.subject, openedTabsSet.has(x.rowIndex) && styles.opened)}>
                            <span className={styles.expanderIcon}/>
                        </Label>
                        {x.rowIndex + 1}
                    </div>
                ),
                {cellStyle: {cursor: "pointer"}, cellOnClick: (_, index) => toggleTab(index)}
            ),
            Table.Column("Срок начала этапа", x => <> {formatPaymentDate(x.item, false, false)}</>),
            Table.Column("Срок начала поставки товаров, оказания услуг, выполнения работ", x => <> {formatPaymentDate(x.item, false, false)}</>),
            Table.Column("Срок окончания поставки товаров, оказания услуг, выполения работ", x => <> {formatPaymentDate(x.item, true, false)}</>),
            Table.Column("Срок оплаты", x => <> {formatPaymentDate(x.item, false, true)}</>),
            Table.Column("Срок окончания этапа", x => <> {formatPaymentDate(x.item, false, true)}</>),

            Table.Column("Сумма платежа", x => <>{getPayment(x.item).formatted}</>),
            Table.Column("Размер аванса", x => <>{getAdvanceSize(x.item).formatted}</>),
            Table.Column("Сумма аванса", x => <>{getAdvanceAmount(x.item).formatted}</>),
        ...spreadIf(state !== "readonly",
            Table.AutoColumn<PaymentStage>("Действие", x => (
                <Button icon="gPencil" onClick={() => editPaymentStage(x.item)} />
            )),
        ),
            ...spreadIf(state === "readonly", Table.Column<PaymentStage>("", () => <></>, { width: "0", headerStyle: { padding: 0 }, cellStyle: { padding: 0 }  }),)
        ];

        const insets = lot?.paymentStages?.length ? [
            Table.InsetRow(lot?.paymentStages?.length ?? 0 + 1,
                Table.Inset(<>Итого</>, "1 / 2"),
                Table.Inset(<></>, "2 / 3"),
                Table.Inset(<></>, "3 / 4"),
                Table.Inset(<></>, "4 / 5"),
                Table.Inset(<>{formatNumber(paymentIsChanged ? paymentsResults.paymentAmount.div(totalPriceSpecification).mul(100) : startPricePercent)}%</>, "5 / 6"),
                Table.Inset(<>{formatNumber(paymentIsChanged ? paymentsResults.paymentAmount : totalPriceSpecification)}</>, "6 / 7"),
                Table.Inset(<>{formatNumber(paymentsResults.advanceAmount.div(totalPriceSpecification).mul(100))}%</>, "7 / 8"),
                Table.Inset(<>{formatNumber(paymentsResults.advanceAmount)}</>, "8 / 9"),
                Table.Inset(<></>, "9 / -1", undefined, state === "readonly" ? { width: 0, padding: 0 } : undefined),),
            ...openedTabs.map(tabIndex => {
                // Берем этап поставки из этого этапа оплаты
                const paymentSupplyStage = lot?.paymentStages[tabIndex].supplyStage;
                // Ищем подходящий в спецификации
                const getVolumeAndTotalPriceForSpecificationBySupplyStage = (sp: Specification, ss?: SupplyStage): { volume: string; price: string } => {
                    if(!ss) return { volume: "0", price: "0" };
                    const found = sp.supplyStages.find(st => compareSupplyStageByDate(st, ss));
                    if (!found) return { volume: "0", price: "0" };

                    const volume = formatNumber(found.volume);
                    const price = formatNumber(found.volume.mul(sp.startPrice?.perItem ?? 0));
                    return { volume, price };
                };

                const insetColumns: Column<Specification>[] = [
                    Table.Column("СПГЗ", x => (
                        <Underlined>
                            <a href="#"
                               style={{
                                   color: "unset",
                                   textDecoration: "none"
                               }}
                               onClick={() => callSpecModal({
                                   spec: x.item,
                                   id: tabIndex
                               })}>
                                {x.item?.subjectDeclaration?.subject}
                            </a>
                        </Underlined>
                    )),
                    Table.Column("Объем", x => (
                        <> {getVolumeAndTotalPriceForSpecificationBySupplyStage(x.item, paymentSupplyStage).volume}</>
                    )),
                    Table.Column("Цена за единицу ТРУ, руб.", x => (
                        <> {formatNumber(x.item?.startPrice?.perItem ?? 0)}</>
                    )),
                    Table.Column("\tСумма, руб.", x => (
                        <> {getVolumeAndTotalPriceForSpecificationBySupplyStage(x.item, paymentSupplyStage).price}</>
                    ))];
                return Table.InsetRow(tabIndex + 1,
                    Table.Inset(
                        <Table dataset={lot?.paymentStages[tabIndex].specifications ?? []} columns={insetColumns}/>, "1 / -1 "));
            }
            )
        ] : [];

        const paymentStageNote = lot.law === LawType.F223 ?
            <Note preset="alert">
                Сроки направления, рассмотрения отчетных документов и оплаты будут учтены при расчете даты платежа и при формировании проекта договора
            </Note>
                    :
            <Note preset="alert">
                C 1 апреля 2024 года становится обязательным заключение контрактов в структурированным виде по электронныым процедурам в соответствиее с Законом № 44-ФЗ. При формировании структурированного
                контракта сроки исполнения контракта и его этапов будут браться из извещения (лота). <br/>
                Сроки направления, рассмотрения отчетных документов и оплаты будут учтены при расчете даты платежа и при формировании проекта контракта
            </Note>;
        return <>
            {
                <VStack spacing="20px" innerStyle={{paddingTop: "20px"}}>
                    {
                        !isAggregated && <>
                            <VGrid columns="repeat(6, 1fr)" spacing="10px">
                                <VertField title="Срок направления отчетных документов">
                                    <FComboBox
                                        options={toSelectOptions(SendDocumentsTermTypeStrings, false)}
                                        value={lot.sendDocumentsTerm}
                                        onChange={setSendDocumentsTerm}
                                        disabled={specifications.specificationForms.length === 0}
                                    />
                                </VertField>
                                <VertField title="Срок рассмотрения отчетных документов">
                                    <FComboBox
                                        options={toSelectOptions(ReviewDocumentsTermTypeStrings, false)}
                                        value={lot.reviewDocumentsTerm}
                                        onChange={setReviewDocumentsTerm}
                                        disabled={specifications.specificationForms.length === 0}
                                    />
                                </VertField>
                                <VertField title="Срок оплаты">
                                    <FComboBox
                                        options={toSelectOptions(PaymentTermTypeStrings, false)}
                                        value={lot.paymentTerm}
                                        onChange={setPaymentTerm}
                                        disabled={specifications.specificationForms.length === 0}
                                    />
                                </VertField>
                            </VGrid>
                            {!isAggregated && specifications.specificationForms.length > 0
                                ? paymentStageNote
                                : <Note preset="alert">
                                    Этапы оплаты формируются автоматически при сохранении спецификации
                                </Note>
                            }
                            {
                                !(specifications.specificationForms.length === 0) &&
                                    <Table<PaymentStage>
                                        dataset={[...(lot?.paymentStages ?? [])].sort((a, b) => (a.paymentDateOffset ?? 0) - (b.paymentDateOffset ?? 0))}
                                        key={JSON.stringify(lot?.paymentStages ?? [])}
                                        columns={paymentStagesColumns}
                                        insets={insets}/>
                            }
                        </>
                    }
                    <Fields orientation={orientation} spacing="20px">
                        <Field title="Требуется заключение госэкспертизы/заключение о достоверности сметной стоимости" hidden={isMaster || isAggregated}>
                            <FCheckBox value={lot.isRepairRelated} onChange={setIsRepairRelated}/>
                        </Field>
                        {
                            is44 && <Field title={`Информация о банковском сопровождении ${contractName}а`} hidden={isMaster}>
                                <FComboBox options={toSelectOptions(BankingSupportStrings)} value={lot.bankingSupport}
                                           onChange={setBankingSupport}/>
                            </Field>
                        }
                        <Field title="Закупка товаров, работ, услуг, удовлетворяющих критериям отнесения к инновационной продукции, высокотехнологичной продукции"
                               hidden={isMedicines || isAggregated}>
                            <FCheckBox disabled/>
                        </Field>
                    </Fields>
                </VStack>

            }
        </>;
    });

    return <Card>
        <Fields orientation={orientation} spacing="20px">
            {/*visible if exist*/}
            <Field title="Причина изменения опубликованного лота" hidden={!(editReason && viewing)}>
                <Label preset="readonlyValue" text={editReason ? EditReasonTypeStrings[editReason] : ""}/>
            </Field>
            <Field title="Причина отмены опубликованного лота"
                   hidden={!head.cancellationReason || head.visibleStatus !== "CANCELLED"}>
                <Label preset="readonlyValue" text={head.cancellationReason?.reason ?? ""}/>
            </Field>
            <Field title="Закон-основание">
                <Label preset="readonlyValue" text={LawTypeStrings[lot.law]}/>
            </Field>
            <Field title="ИКЗ" hidden={isJoined || is223 || isMaster}>
                <Label preset="readonlyValue"
                       text={(head.visibleStatus === "PUBLISHED_IN_PLAN" ? head.purchaseCode : "") ?? "не сгенерирован "}/>
            </Field>
            <Field title="Статус" hidden={!editing}>
                <Label preset="readonlyValue"
                       text={head.visibleStatus ? LotVisibleStatusStrings[head.visibleStatus] : ""}/>
            </Field>
            <Field title="Реестровый номер" required hidden={!editing}>
                <Label preset="readonlyValue" text={head.regNumber?.toString() ?? ""}/>
            </Field>
            {/*should be clickable with info modal*/}
            <Field title={isMaster ? "Главный заказчик" : "Заказчик"} required={required}>
                {
                    x.formAction === "creating"
                        ? <Label preset="readonlyValue"
                                 text={Auth.currentUserInfo?.selectedParticipant.fullName ?? "нет"}/>
                        : <Label preset="readonlyValue" text={head.customer?.fullName ?? ""}/>
                }
            </Field>
            {/*should be pickable when creating/editing*/}
            {/*should be clickable with info modal when readonly*/}
            <Field title="Уполномоченный орган (учреждение)" required={required} hidden={isAggregated}>
                <HStack alignItems="center" spacing="10px">
                    <Label required preset="readonlyValue" text={head.performer?.fullName ?? "Не выбран"}/>
                    {!viewing && <Button icon="aEdit" onClick={async () => {
                        const participant = await showModal(SelectParticipantModal, {participants: participants});
                        if (participant)
                            setPerformer(participant);
                    }}/>}
                </HStack>
            </Field>
            {
                is44 && <Field
                    title={lot.specialPurchaseType == "SMALL_VOLUME_CARE"
                    || lot.specialPurchaseType == "SMALL_VOLUME_SIMPLE"
                    || lot.providerSelectionType == "SINGLE_PROVIDER_PURCHASE"
                        ? "Планируемый год заключения контракта с ед. поставщиком"
                        : "Планируемый год публикации извещения"}
                    required={required}>
                    <FComboBox options={createYearSelection(false)} value={lot.plannedNotificationPublishYear}
                               onChange={setPlannedNotificationPublishYear}/>
                </Field>
            }
            <Field
                title="Год заключения контракта"
                required={required}
                hidden={!(lot.specialPurchaseType === "SMALL_VOLUME_CARE" || lot.specialPurchaseType === "SMALL_VOLUME_SIMPLE" || isAggregated)}>
                <FComboBox options={createYearSelection(false)} value={lot.contractConclusionYear}
                           onChange={setContractConclusionYear}/>
            </Field>
            <Field title="Предмет закупки по КПГЗ" hidden={isAggregated}>
                <Label text={purchaseItemAction[lot.contractType!] && kpgzProductNames.length > 0
                    ? `${purchaseItemAction[lot.contractType!]}: ${kpgzProductNames.join(", ")}`
                    : ""}/>
            </Field>
            <Field title="Предмет закупки" required>
                <VStack spacing="5px">
                    <FTextBox value={lot.subject} onChange={setSubject} lines={5}/>
                    {!viewing && <HintMark hint={subjectHint}/>}
                </VStack>
            </Field>
            {
                is44 && <Field title="Особая закупка" required={required} hidden={isJoined || isMaster}>
                    <FComboBox options={toSelectOptions(SpecialPurchaseTypeStrings)}
                               value={lot.specialPurchaseType}
                               onChange={setSpecialPurchaseType}/>
                </Field>
            }
            <Field title="Способ определения поставщика" required={required}>
                <FComboBox
                    options={isBeyond2021Year && is44
                        ? beyond2021ProviderType44SelectionTypes
                        : providerSelectionTypeSelectOptions}
                    value={lot.isAuditServicesPurchase ? "E_EXAM" : lot.providerSelectionType}
                    onChange={setProviderSelectionType}
                    disabled={isProviderSelectionTypeLocked}/>
            </Field>
            <Field title={`Основание заключения ${contractName}а с единственным поставщиком`} required={required}
                   hidden={!isSingleProviderPurchase}>
                <FComboBox options={singleProviderReasonOptions}
                           value={lot.singleProviderReasonId}
                           onChange={setSingleProviderReasonId}
                           disabled={isProviderSelectionTypeLocked}/>
            </Field>
            <Field title="Поставщик" required={isSingleProviderPurchase}
                   hidden={lot.providerSelectionType !== "SINGLE_PROVIDER_PURCHASE" || isSingleProviderPurchaseUnder600k
                       || lot.specialPurchaseType === "SMALL_VOLUME_SIMPLE" || lot.specialPurchaseType === "SMALL_VOLUME_CARE"}>
                {
                    state === "readonly"
                        ? <Label preset="readonlyValue">{lot.singleProvider?.shortName}</Label>
                        : <VStack spacing="10px">
                            <Button title="выбрать поставщика" color="green" icon="aAdd" onClick={pickSingleProvider}
                                    style={{alignSelf: "flex-start"}}/>
                            {
                                lot.singleProvider && <HStack spacing="10px" alignItems="center">
                                    <Label preset="readonlyValue">{lot.singleProvider.shortName}</Label>
                                    <Button icon="aClose" onClick={() => setSingleProvider(undefined)}/>
                                </HStack>
                            }
                        </VStack>
                }
            </Field>
            {bottomBlock}
        </Fields>
        <PaymentStagesBlock/>
    </Card>;
});
