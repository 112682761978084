import { UnionStrings } from "@/models/enums";

export type ProviderSelectionTypeCommon =
    | "E_AUC"
    | "E_EXAM"
    | "E_TWO_STAGE_EXAM"
    | "E_OFFERS_REQUEST"
    | "E_QUOTATIONS_REQUEST"
    | "SINGLE_PROVIDER_PURCHASE"
    ;

export type ProviderSelectionType44 = ProviderSelectionTypeCommon | "E_LIMITED_EXAM";

export type ProviderSelectionType223 = ProviderSelectionTypeCommon
    | "OPEN_AUC"
    | "OPEN_EXAM"
    | "QUOTATIONS_REQUEST"
    | "OFFERS_REQUEST"
    | "TWO_STAGE_EXAM"
    | "E_AUC_MSP";

export type ProviderSelectionType223AtAggregatedLot = ProviderSelectionType223;

export type ProviderSelectionType = ProviderSelectionType44 | ProviderSelectionType223 | ProviderSelectionType223AtAggregatedLot;

export const ProviderSelectionType44Strings: UnionStrings<ProviderSelectionType44> = {
    E_AUC: "Электронный аукцион",
    E_EXAM: "Электронный конкурс",
    E_TWO_STAGE_EXAM: "Электронный двухэтапный конкурс",
    E_LIMITED_EXAM: "Электронный конкурс с ограниченным участием",
    E_OFFERS_REQUEST: "Электронный запрос предложений",
    E_QUOTATIONS_REQUEST: "Электронный запрос котировок",
    SINGLE_PROVIDER_PURCHASE: "Закупка у единственного поставщика",
};

export const ProviderSelectionType44StringsBeyond2021: Partial<UnionStrings<Partial<ProviderSelectionType44>>> = {
    E_AUC: ProviderSelectionType44Strings.E_AUC,
    E_EXAM: ProviderSelectionType44Strings.E_EXAM,
    E_QUOTATIONS_REQUEST: ProviderSelectionType44Strings.E_QUOTATIONS_REQUEST,
    SINGLE_PROVIDER_PURCHASE: ProviderSelectionType44Strings.SINGLE_PROVIDER_PURCHASE,
};

export const ProviderSelectionType223Strings: UnionStrings<ProviderSelectionType223> = {
    E_AUC: "Электронный аукцион",
    E_EXAM: "Электронный конкурс",
    E_AUC_MSP: "Электронный аукцион среди МСП",
    E_TWO_STAGE_EXAM: "Электронный двухэтапный конкурс",
    E_OFFERS_REQUEST: "Электронный запрос предложений",
    OPEN_AUC: "Открытый аукцион",
    E_QUOTATIONS_REQUEST: "Электронный запрос котировок",
    OPEN_EXAM: "Открытый конкурс",
    QUOTATIONS_REQUEST: "Запрос котировок",
    SINGLE_PROVIDER_PURCHASE: "Закупка у единственного поставщика",
    OFFERS_REQUEST: "Запрос предложений",
    TWO_STAGE_EXAM: "Двухэтапный конкурс",
};

export const ProviderSelectionType223StringsAtAggregatedLot: UnionStrings<ProviderSelectionType223AtAggregatedLot>
    = Object.fromEntries(Object.entries(ProviderSelectionType223Strings).filter(([k]) => k !== "QUOTATION_SESSION")) as
    UnionStrings<ProviderSelectionType223AtAggregatedLot>;

export const ProviderSelectionTypeStrings: UnionStrings<ProviderSelectionType> = {
    ...ProviderSelectionType44Strings,
    ...ProviderSelectionType223Strings
};
