import React, { useRef, useState } from "react";
import { ModalFC } from "@/EventBus";
import { ContractPayment } from "@/models/Contracts/ContractPayment";
import { createContractPaymentsStore } from "@/views/Payments/list/store";
import { useStore } from "effector-react";
import { useStoredRemoteDataset } from "@/components/table";
import { HStack } from "@/components/layouts";
import { Button } from "@/components/primitive";
import { CardModal } from "@/components/modals/CardModal";
import { PaymentsTable } from "@/views/Payments/shared/PaymentsTable";

export type AddObligationPaymentModalReturn = {
    payment: ContractPayment
};

type AddObligationPaymentModalProps = {
    contractNumber?: string
};

export const AddObligationPaymentModal: ModalFC<AddObligationPaymentModalProps, AddObligationPaymentModalReturn> = ({ done, contractNumber }) => {
    const storeRef = useRef(createContractPaymentsStore(contractNumber).store);

    const { contractPaymentsSrd } = useStore(storeRef.current);
    const { dataset, filterStore } = useStoredRemoteDataset(contractPaymentsSrd);

    const [selectedPayment, setSelectedPayment] = useState<ContractPayment | undefined>();

    const bottom = (
        <HStack reverse>
            <Button icon="aSave"
                    disabled={!selectedPayment}
                    onClick={() => selectedPayment && done({ payment: selectedPayment })} />
        </HStack>
    );

    return (
        <CardModal title="Выбор платежа"
                   width="60%"
                   bottom={bottom}
                   close={() => done(undefined)}>
            <PaymentsTable
                payments={dataset}
                isFiltersVisible
                filterStore={filterStore}
                onChange={setSelectedPayment}
                selected={selectedPayment}
            />
        </CardModal>
    );
};
