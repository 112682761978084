import React, {useEffect, useState} from "react";
import {CreationMode} from "@/views/CreateSession/filtersStore";
import {Store} from "effector";
import {
    changeMainOrganizerId, createOrUpdateSession,
    findSupplier,
    getType, loadExecutionHistory, loadHistory, loadSession,
    ServicesStore,
    SpecificationType, supplierNotFound
} from "@/views/CreateSession/store";
import {SubjectClassFilterStore} from "@/components/eshop/SubjectClassFilterStore";
import styles from "../CreateSession.module.css";
import headerStyles from "../Header.module.css";
import {GeneralInfoCard} from "@/views/CreateSession/JointSession/GeneralInfoCard";
import {useStore} from "effector-react";
import {firstMaybe} from "@/arrayUtils";
import {useOnScreen} from "@/reactUtils";
import {JointSessionsCard} from "@/views/CreateSession/JointSession/JointSessions";
import {ListJointSessionsCard} from "@/views/CreateSession/JointSession/ListJointSessions";
import {HistoryCard} from "@/views/CreateSession/Session/HistoryCard";
import {CancelButton, CopyButton, PrimaryButton, TestButton, WarningButton} from "@/components/eshop/Buttons";
import router from "@/router";
import {statusToString} from "@/api/Sessions";
import {
    ModalAdditionalInfo,
    ModalCancel,
    ModalCancelPublication,
    ModalSend
} from "@/views/CreateSession/Session/CreateSessionModals";
import Auth from "@/api/Auth";
import {toastSuccess} from "@/components/toasts/toasts";

interface CreateJointSessionProps {
    sessionId?: string
    ids?: string
    mode?: CreationMode
    storeContainer: {store: Store<ServicesStore>; subjectClassSelector: SubjectClassFilterStore}
}

export const CreateJointSession: React.FC<CreateJointSessionProps> = x => {
    const store = useStore(x.storeContainer.store);

    const { currentSession } = store;

    const dataType = getType(store);
    const data = dataType === SpecificationType.Services ? store.services : dataType === SpecificationType.Products ? store.products : store.purchases;
    const initialAmount = data.filter(x => x.stages.length > 0).reduce((prev, item) => prev + (+((firstMaybe(item.stages)?.volume) ?? 0) * item.price), 0);

    const [additionalInfo, setAdditionalInfo] = useState<object | null>(null);
    const [showCancel, setShowCancel] = useState(false);
    const [showSend, setShowSend] = useState(false);
    const [showCancelPublication, setShowCancelPublication] = useState(false);

    const status = currentSession?.status;
    const isMaster = !!currentSession?.isMaster;
    const canChange = currentSession === null || status === 'DRAFT' || status === 'UPDATED';
    const canBeSent = status === 'DRAFT' || status === 'CANCEL';
    const canBeCancelPublished = status === "WAIT_PUBLICATION" && isMaster;
    const sessionCancelled = status === 'SUPPLIER_NOT_FOUND' || status === 'CANCEL';
    const sessionPublished = status === 'PUBLISHED';
    const canSaveSession = store.subjectJoin?.length !== 0 && store.joinPublishDate && store.subjectId;

    const generalInfoRef = React.createRef<HTMLDivElement>();
    const jointSessionRef = React.createRef<HTMLDivElement>();
    const listJoinSessionsRef = React.createRef<HTMLDivElement>();
    const historyRef = React.createRef<HTMLDivElement>();

    const visibleStyleElement = [
        { name: 'generalInfoRefVisible', visible: useOnScreen(generalInfoRef) },
        { name: 'jointSessionRefVisible', visible: useOnScreen(jointSessionRef) },
        { name: 'listJoinSessionsRefVisible', visible: useOnScreen(listJoinSessionsRef) },
        { name: 'historyRefVisible', visible: useOnScreen(historyRef) },
    ].find(x => x.visible);

    const visibleStyle = visibleStyleElement && visibleStyleElement.name;
    useEffect(() => {
        (async function () {
            if (x.sessionId) {
                loadSession({ id: x.sessionId });
            }
        })();
    }, []);

    useEffect(() => {
        const currentParticipantId = Auth.currentUserInfo?.selectedParticipant.id;
        if (!store.mainOrganizerId && currentParticipantId) {
            changeMainOrganizerId(currentParticipantId);
        }
    }, []);

    useEffect(() => {
        if (store.currentSession?.id) {
            loadHistory({ id: store.currentSession.id });
            loadExecutionHistory({ id: store.currentSession.id });
            loadSession({ id: store.currentSession.id });
        }
        return;
    }, [store.currentSession?.id]);

    const scroll = (ref: React.RefObject<HTMLDivElement>) =>
        ref.current && ref.current.scrollIntoView({ behavior: 'smooth' });

    const saveSession = async () => {
        if (canSaveSession) {
            await createOrUpdateSession({
                id: store.currentSession?.id,
                lotId: null,
                services: dataType === SpecificationType.Services ? store.services : [],
                products: dataType === SpecificationType.Products ? store.products : [],
                purchases: dataType === SpecificationType.DirectPurchase ? store.purchases : [],
                status: store.currentSession?.status || "DRAFT",
                bankId: null,
                duration: store.duration || '',
                law: store.law || '',
                electronicExecution: !!store.electronicExecution,
                fio: store.fio,
                email: store.email,
                phone: store.phone,
                purchasePerUnit: store.purchasePerUnit,
                contractProvisionUsed: store.contractProvisionUsed,
                contractProvisionPrice: store.contractProvisionUsed ? parseInt(store.contractProvisionPrice) : 0,
                contractProvisionBankDetailId: store.contractProvisionUsed ? store.contractProvisionBankDetailId : null,
                contractProvisionRecipient: store.contractProvisionUsed ? store.contractProvisionRecipient : '',
                maxPrice: store.maxPrice,
                isGoodsDeliveryByMultipleConsignees: store.isGoodsDeliveryByMultipleConsignees,
                isGoodsDeliveryByRecipientRequest: store.isGoodsDeliveryByRecipientRequest,
                executionStages: store.executionStages,
                isJoin: true,
                isMaster: true,
                includedSessionsIds: store.includedSessionsIds,
                includedSessions: store.includedSessions,
                joinCustomersIds: store.joinCustomersIds,
                joinCustomers: store.joinCustomers,
                subjectId: store.subjectId,
                joinPublishDate: store.joinPublishDate,
                subjectJoin: store.subjectJoin,
                mainOrganizerId: store.mainOrganizerId,
                aggregatingSessionId: store.aggregatingSessionId,
                paymentTerm: null,
                reviewTerm: null,
                sendTerm: null
            });
            if (status === "WAIT_PUBLICATION") {
                toastSuccess({ message: "Совместная котировочная сессия успешно опубликована" });
            } else {
                toastSuccess({ message: "Сохранение котировочной сессии прошло успешно" });
            }
        }
    };


    const createSession = async () => {
        await saveSession();
        setShowSend(true);
        toastSuccess({ message: "Приглашения для выбранных участников совместной КС успешно отправлены" });
    };

    const publishSession = async () => {
        setShowSend(true);
        toastSuccess({ message: "Приглашения для выбранных участников совместной КС успешно отправлены" });
    };

    const cancelPublicationSession = async () => {
        setShowCancelPublication(true);
    };

    return (
        <div className={styles.offersPage}>
            <div className={styles.sessionsPageContent}>
                <div className={headerStyles.headerContainer}>
                    <ModalAdditionalInfo message={additionalInfo} setAdditionalInfo={setAdditionalInfo} />
                    <ModalCancel store={store} show={showCancel} setShowCancel={setShowCancel} />
                    <ModalSend show={showSend} setShowSend={setShowSend} store={store} />
                    <ModalCancelPublication show={showCancelPublication} setShowCancelPublication={setShowCancelPublication} store={store} />
                    <div className={headerStyles.pageHeader}>
                        <span>{store.currentSession && store.currentSession.subject
                            ? "Совместная котировочная сессия"
                            : "Новая совместная котировочная сессия"}</span>
                        <span className={`${headerStyles.status} ${(status === 'DRAFT' || status === 'WAIT_PUBLICATION' || status === 'REMOVED_FROM_PUBLICATION') ? headerStyles.draft : ''}`}>
                        {statusToString(status || null)}
                    </span>
                    </div>
                    <div className={headerStyles.sessionsBreadcrumbs}>
                        <div className={visibleStyle === 'generalInfoRefVisible' ? headerStyles.menuOptionsVisible : headerStyles.menuOption} onClick={() => scroll(generalInfoRef)}>
                            Общая информация
                        </div>
                        <div className={visibleStyle === 'jointSessionRefVisible' ? headerStyles.menuOptionsVisible : headerStyles.menuOption} onClick={() => scroll(jointSessionRef)}>
                            Совместные котировочные сессии
                        </div>
                        <div className={visibleStyle === 'listJoinSessionsRefVisible' ? headerStyles.menuOptionsVisible : headerStyles.menuOption} onClick={() => scroll(listJoinSessionsRef)}>
                            Список позиций котировочных сессий для формирования совместной КС
                        </div>
                        <div className={visibleStyle === 'historyRefVisible' ? headerStyles.menuOptionsVisible : headerStyles.menuOption} onClick={() => scroll(historyRef)}>
                            История изменений
                        </div>
                    </div>
                </div>
                <div className={`${styles.cardsContainer}`}>
                    <div ref={generalInfoRef}>
                        <GeneralInfoCard store={store} canChange={canChange} data={data} initialAmount={initialAmount} subjectClassSelector={x.storeContainer.subjectClassSelector} />
                    </div>
                    <div ref={jointSessionRef}>
                        <JointSessionsCard store={store} canChange={canChange} />
                    </div>
                    <div ref={listJoinSessionsRef}>
                        <ListJointSessionsCard store={store} canChange={canChange} />
                    </div>
                    <div ref={historyRef}>
                        <HistoryCard store={store} setAdditionalInfo={setAdditionalInfo} />
                    </div>
                </div>
                <div className={`${styles.sessionsPageFooter}`} >
                    <>
                        <WarningButton onClick={() => router.replace("/shop/sessions")}>Отмена</WarningButton>
                    </>
                    <div>
                        <CopyButton>Закрыть</CopyButton>
                        {canBeCancelPublished && <CancelButton onClick={cancelPublicationSession}> Отменить публикацию </CancelButton>}
                        {(status === undefined || status === 'DRAFT' || status === 'WAIT_PUBLICATION') &&
                            <PrimaryButton disabled={!canSaveSession} onClick={saveSession}>Сохранить</PrimaryButton>}
                        {canBeSent && <PrimaryButton filled onClick={createSession}>Организовать КС</PrimaryButton>}
                        {(status === "WAIT_PUBLICATION") && <PrimaryButton onClick={saveSession}>Опубликовать КС</PrimaryButton>}
                        {(status === "WAIT_PUBLICATION") && <PrimaryButton filled onClick={publishSession}>Организовать КС</PrimaryButton>}

                    </div>
                    {sessionPublished && <>
                        {/*{canCancell && <WarningButton onClick={() => setShowCancel(true)}>Отменить публикацию КС</WarningButton>}*/}
                        <TestButton filled onClick={() => store.currentSession && findSupplier({ id: store.currentSession.id })}>Поставщик найден</TestButton>
                        <TestButton filled onClick={() => store.currentSession && supplierNotFound({ id: store.currentSession.id })}>Поставщик не найден</TestButton>
                    </>}
                </div>
            </div>
        </div>
    );
};
