import {asDate, AutoDto, AutoModel, uuid} from "@/models/parsing";
import {LawType} from "@/models/enums";
import {PlanStatus} from "@/models/Plans/PlanStatus";

interface PlanMD {
    id: uuid
    userId: uuid
    orderNumber: number
    law: LawType
    periodStartYear: number
    status: PlanStatus
    approvalDate?: Date
    publishDate?: Date
    grbsAgreementDate?: Date
    isActual: boolean
    wasPublished: boolean
}

export type DtoPlan = AutoDto<PlanMD>;
export type Plan = AutoModel<PlanMD>;

export const Plan = {
    toDto(info: Plan): DtoPlan {
        return {
            ...info,

            approvalDate: info.approvalDate?.toISOString(),
            publishDate: info.publishDate?.toISOString(),
            grbsAgreementDate: info.grbsAgreementDate?.toISOString()
        };
    },
    fromDto(info: DtoPlan): Plan {
        return {
            ...info,

            approvalDate: asDate(info.approvalDate),
            publishDate: asDate(info.publishDate),
            grbsAgreementDate: asDate(info.grbsAgreementDate)
        };
    }
};