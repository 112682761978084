import { AutoDto, AutoModel, MDMap, uuid } from "@/models/parsing";
import {
    DtoSubjectDeclaration,
    MeasurementUnit,
    SubjectDeclaration
} from "@/models/SubjectDeclaration/SubjectDeclaration";
import Decimal from "decimal.js";
import { DtoMonopolySupplyStage, MonopolySupplyStage } from "@/models/Monopoly/MonopolySupplyStage";
import { CharacteristicValue, DtoCharacteristicValue } from "@/models/ComposedLots/specifications";

interface MonopolySpecificationMD {
    id: uuid
    spgz: MDMap<SubjectDeclaration, DtoSubjectDeclaration>
    measurementUnit: MeasurementUnit
    unitPrice: Decimal
    totalPrice: Decimal
    volume: Decimal
    supplyStages: MDMap<MonopolySupplyStage, DtoMonopolySupplyStage>[]
    characteristics: MDMap<CharacteristicValue, DtoCharacteristicValue>[]
    supplyAddress: string
}

export type MonopolySpecification = AutoModel<MonopolySpecificationMD>;
export type DtoMonopolySpecification = AutoDto<MonopolySpecificationMD>;

export const MonopolySpecification = {
    fromDto(dtoMonopolySpecification: DtoMonopolySpecification): MonopolySpecification {
        return {
            ...dtoMonopolySpecification,
            unitPrice: new Decimal(dtoMonopolySpecification.unitPrice),
            totalPrice: new Decimal(dtoMonopolySpecification.totalPrice),
            volume: new Decimal(dtoMonopolySpecification.volume),
            spgz: SubjectDeclaration.fromDto(dtoMonopolySpecification.spgz),
            supplyStages: dtoMonopolySpecification.supplyStages.map(MonopolySupplyStage.fromDto),
            characteristics: dtoMonopolySpecification.characteristics.map(CharacteristicValue.fromDto)
        };
    },
    toDto(monopolySpecification: MonopolySpecification): DtoMonopolySpecification {
        return {
            ...monopolySpecification,
            unitPrice: monopolySpecification.unitPrice.toString(),
            totalPrice: monopolySpecification.totalPrice.toString(),
            volume: monopolySpecification.volume.toString(),
            spgz: SubjectDeclaration.toDto(monopolySpecification.spgz),
            supplyStages: monopolySpecification.supplyStages.map(MonopolySupplyStage.toDto),
            characteristics: monopolySpecification.characteristics.map(CharacteristicValue.toDto)
        };
    }
};

interface MonopolySpecificationUpdateTemplateMD {
    id: uuid
    spgzId: uuid
    measurementUnitId: number
    unitPrice: Decimal
    totalPrice: Decimal
    volume: Decimal
    supplyStages: MDMap<MonopolySupplyStage, DtoMonopolySupplyStage>[]
    supplyAddress: string
    characteristics: MDMap<CharacteristicValue, DtoCharacteristicValue>[]
}

export type MonopolySpecificationUpdateTemplate = AutoModel<MonopolySpecificationUpdateTemplateMD>;
export type DtoMonopolySpecificationUpdateTemplate = AutoDto<MonopolySpecificationUpdateTemplateMD>;

export const MonopolySpecificationUpdateTemplate = {
    toDto(specification: MonopolySpecificationUpdateTemplate): DtoMonopolySpecificationUpdateTemplate {
        return {
            ...specification,
            unitPrice: specification.unitPrice.toString(),
            totalPrice: specification.unitPrice.toString(),
            volume: specification.volume.toString(),
            supplyStages: specification.supplyStages.map(MonopolySupplyStage.toDto),
            characteristics: specification.characteristics.map(CharacteristicValue.toDto),
        };
    }
};
