import { IDtoSupplyStage } from "@/models/json";
import { IJsonFormattable } from "@/models/IJsonFormattable";
import Decimal from "decimal.js";
import { SupplyStagePeriodType } from "@/models/enums/SupplyStagePeriodType";
import { DayType } from "@/models/enums/DayType";
import { Address } from "@/models/ComposedLots/specifications";

export class SupplyStage implements IJsonFormattable<IDtoSupplyStage> {
    constructor(
        public volume: Decimal,
        public type: SupplyStagePeriodType,
        public relativePeriod: {
            startOffset: number | null
            startOffsetType: DayType
            endOffset: number | null
            endOffsetType: DayType
            finishYear: number | null
        },
        public absolutePeriod: {
            startDate: Date | null
            endDate: Date | null
        },
        public conditions: string,
        public address: Address | null
    ) {}

    public toJson(): IDtoSupplyStage {
        return {
            ...this,
            volume: this.volume.toString(),
            relativePeriod: {
                ...this.relativePeriod,
                startOffset: this.relativePeriod.startOffset || 0,
                endOffset: this.relativePeriod.endOffset || 0,
                finishYear: this.relativePeriod.finishYear || 0,
            },
            absolutePeriod: {
                startDate: this.type === SupplyStagePeriodType.ABSOLUTE ? this.absolutePeriod.startDate!.toISOString() : null,
                endDate: this.type === SupplyStagePeriodType.ABSOLUTE ? this.absolutePeriod.endDate!.toISOString() : null,
            },
            address: this.address ? Address.toDto(this.address) : null
        };
    }

    // noinspection JSUnusedGlobalSymbols
    public static fromJson(dto: IDtoSupplyStage): SupplyStage {
        return Object.assign(Object.create(SupplyStage.prototype), dto, {
            volume: new Decimal(dto.volume),
            relativePeriod: { 
                ...dto.relativePeriod,
                startOffset: dto.type === SupplyStagePeriodType.RELATIVE ? dto.relativePeriod.startOffset : null,
                endOffset: dto.type === SupplyStagePeriodType.RELATIVE ? dto.relativePeriod.endOffset : null,
                finishYear: dto.type === SupplyStagePeriodType.RELATIVE ? dto.relativePeriod.finishYear : null
            },
            absolutePeriod: {
                startDate: dto.type === SupplyStagePeriodType.ABSOLUTE ? new Date(dto.absolutePeriod.startDate!) : null,
                endDate: dto.type === SupplyStagePeriodType.ABSOLUTE ? new Date(dto.absolutePeriod.endDate!) : null,
            },
            address: dto.address ? Address.fromDto(dto.address) : null,
        });
    }
}
