


























import { Component, Vue, Prop } from "vue-property-decorator";
import FormTable from "@/components/vue/FormTable.vue";
import CardModal from "@/components/CardModal.vue";
import { makeFormHelpers } from "@/form-declarations/helpers";
import { IColumn, TextAlignment } from "@/components/vue/form-table/FormFields";
import Decimal from "decimal.js";
import { ModalVue, ModalResult } from '@/view-models/ModalRequest';
import { Contract, ContractStagePaymentInfo, ContractFinancialSource, ContractStage } from '@/models/Contracts';
import {ContractScope, ContractStageScope} from '../ContractScope';
import { StagePayment } from "@/api/Stages";

import ContractEconomies from '@/api/ContractEconomies';
import { toSelectOptions } from '@/models/enums';
import { PaymentDocumentType, PaymentDocumentTypeStrings } from '@/models/enums/Contracts/PaymentDocumentType';
import { File } from '@/models/Documents';
import ContractDocumentModal from '@/views/modals/ContractDocumentModal';
import EventBus from '@/EventBus';
import {hs} from "@/components/TableHelpers";
import { ITableColumn } from "@/components/TableAbstractions";
import { RelatedExecutions } from './RelatedExecutions';
import {ReactWrapper} from "vuera";

const h = makeFormHelpers<ContractStagePaymentInfo>();

@Component({components: {FormTable, CardModal, 'react-wrapper': ReactWrapper}})
export default class AddPayment extends Vue {
    @Prop() private prop!: { scope: ContractScope; stage: ContractStage; source: ContractStagePaymentInfo | null };

    public get editable() { return this.prop.stage.status === 'CREATING' || this.prop.stage.status === 'CREATING_FIRST' }
    public get stageScope(): ContractStageScope | null {
      const found = this.prop.scope.stageScopes.find(sc => sc.stage.id === this.prop.stage.id);
      return found ?? null;
    }

    public relatedExecutions = RelatedExecutions;
    public source: ContractStagePaymentInfo = new ContractStagePaymentInfo(
        this.prop.source ? this.prop.source.id : null!,
        this.prop.source ? this.prop.source.createdAt : null!,
        this.prop.source ? this.prop.scope.financialSources[0] : null!,
        this.prop.source ? this.prop.source.documentType : PaymentDocumentType.CASH_PAYMENT_VOUCHER,
        this.prop.source ? this.prop.source.documentNumber : null!,
        this.prop.source ? this.prop.source.documentDate : null!,
        this.prop.source ? this.prop.source.debitingCustomerDate : null!,
        this.prop.source ? this.prop.source.file : null!,
        this.prop.source ? this.prop.source.amount : null!,
        this.prop.source ? this.prop.source.tax : null!,
        this.prop.source ? this.prop.source.status : null!,
        this.prop.source ? this.prop.source.executions : [],
        );

    // public suppliersPerfomanceHeaders: ITableColumn<>

    public formColumns: IColumn<ContractStagePaymentInfo>[] = [
        {
            rows: [
                h.required().select("Источник финансирования", "financialSource", [
                    {key: null, desc: ""},
                    ...this.prop.scope.financialSources.map((x, i) => {
                        const find = this.prop.stage.stageSourceUsage[i];
                        // const find = this.prop.stage.stageSourceUsage.find(ssu => x.source.id === ssu.source.source.id); //TODO: refactor
                        // this.prop.scope.stageScopes.map(scp => scp.stage.stageSourceUsage.map(ssu => ssu.stageAmount));
                        return ({
                            key: x,
                            desc: x.source.format(false) + " " + (x.account && x.account.accountNumber || "") + (find ? " " + find.stageAmount.toFixed(2) + " руб." : "")
                        });
                    }),
                ]),
                h.required().select("Тип документа", "documentType", toSelectOptions(PaymentDocumentTypeStrings, true, "")),
                h.required().text("Номер документа", "documentNumber"),
                h.required().date("Дата документа", "documentDate"),
                h.date("Дата списания средств со счет заказчика", "debitingCustomerDate"),
                h.required().pick("Документ", "file", "file", { add: true }),
                h.required().decimal("Сумма, руб.", "amount", {alignment: TextAlignment.LEFT}),
                h.decimal("Сумма НДС, Российский рубль", "tax", {alignment: TextAlignment.LEFT}),
            ],
        },
    ];

    public async pick(what: string) {
        if (what === "file") {
            const result = await EventBus.callModal<File | null, File>(ContractDocumentModal, this.source.file);
            if (result.isOk) {
                this.source.file = result.getResult();
            }
        }
    }

    public get isOk() {
        return !!this.source.financialSource &&
            !!this.source.amount && this.source.amount.gt(0) &&
            !!this.source.documentType &&
            !!this.source.documentNumber && this.source.documentNumber.trim().length > 0 &&
            !!this.source.file &&
            !!this.source.documentDate;
        }

    public loading = false;

    public async saveAndClose() {
            this.loading = true;

            try {
                const src =
                    this.source.id
                        ? await StagePayment.update(this.source)
                        : await StagePayment.create(this.prop.stage.id, this.source);

                this.$emit("close-modal", ModalResult.ok(src));
            } finally {
                this.loading = false;
            }
    }
}
