import { Component, Prop, Vue } from "vue-property-decorator";
import FormTable from "@/components/vue/FormTable.vue";
import { FormStyle, FieldSectionData } from "@/views/FormAbstractions";
import { FieldState } from "@/components/vue/form-table/FieldBase";

@Component({ components: { FormTable } })
export default class FieldSection extends Vue {
    @Prop() private data!: FieldSectionData<unknown>;
    @Prop() private source!: unknown;
    @Prop() private bus!: Vue;
    @Prop() private state!: FieldState;
    @Prop() private formStyle!: FormStyle;
}
