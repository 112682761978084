import {makeAutoObservable, observable, toJS} from "mobx";
import {
    ExecutionSpecificationsModalProps
} from "@/views/CreateContract/execution/ExecutionSpecificationsModal/ExecutionSpecificationsModal";
import {ContractStage, ExecutionInfoSpecification} from "@/models/Contracts";
import {Country} from "@/views/Contracts/modals/CountrySelectModal";
import {ContractScope} from "@/views/CreateContract/ContractScope";
import {showModal} from "@/EventBus";
import {PickAddressModal} from "@/modal-views/PickAddressModal";
import {ErrorModal} from "@/views/Contracts/modals/ConfirmationModal";
import Decimal from "decimal.js";
import { Address } from "@/models";

export class ExecutionSpecificationsModalViewModel {
    constructor(props: ExecutionSpecificationsModalProps) {

        this.draft = props.scope.contract.specifications.flatMap(spec => {
            const totalVolume = spec.stages.reduce((acc, st) => acc.add(st.volume), new Decimal(0));
                return spec.stages.map((stage, idx) => {
                    const volumeRatio = stage.volume.div(totalVolume);

                    // если потребуется заполнять uncommited при редактировании, то необходимо раскоментить и добавить в ExecutionSpecification id === stage.id
                    // если надо будет после нажатия на Выгрузить из ЕИС заполнять автоматически, то потребуется помимо id добавить признак isFromEis: boolean
                    // if(props.uncommittedSpecifications.length) {
                    //     const item = props.uncommittedSpecifications.find(unc => unc. === stage.id);

                    //     if(item) {
                    //         return {
                    //             id: stage.id,
                    //             index: idx,
                    //             specification: item.specification ?? spec ,
                    //             address: item.address ?? stage.address?.addressLine ?? "",
                    //             createdAt: item.createdAt ?? spec.createdAt,
                    //             volume: item.volume ?? stage.volume,
                    //             cost: item.cost ?? spec.actualCost.mul(volumeRatio),
                    //             country: item.country ?? spec.country,
                    //             eisNumber: null,
                    //             registrationCountry: item?.registrationCountry ?? "RU"
                    //         };
                    //     }
                    // }

                    return {
                        id: stage.id,
                        index: idx,
                        specification: spec,
                        address: stage.address?.addressLine ?? "",
                        createdAt: spec.createdAt,
                        volume: new Decimal(0),
                        cost: new Decimal(0),
                        country: "",
                        eisNumber: null,
                        registrationCountry: ""
                    };
                }
            );
        });

        this.scope = props.scope;
        this.stage = props.stage;
        makeAutoObservable(this);
        this.setSelected(this.draft);
    }

    readonly scope: ContractScope;
    private readonly stage: ContractStage;

    public draft: ExecutionInfoSpecification[];
    public selected: ExecutionInfoSpecification[] = [];
    public setSelected(v: ExecutionInfoSpecification[]) {
        this.selected = v;
    }

    public country: Country | null = null;


    public setCountry = () => {
        // На тестовом eaist нет возможности занулить страну поставки таким образом
        if (!this.country) return;
        for (const item of this.draft) {
            const isSelected = !!this.selected.find(a => a.specification.id === item.specification.id);

            if (isSelected) {
                item.country = this.country;
                item.registrationCountry = this.country;
            }
        }
    };

    public setCompleteDelivery = () => {
        this.draft = this.scope.contract.specifications.flatMap(spec => {
            const totalVolume = spec.stages.reduce((acc, st) => acc.add(st.volume), new Decimal(0));
                return spec.stages.map((stage, idx) => {
                    const volumeRatio = stage.volume.div(totalVolume);

                    return {
                        id: stage.id,
                        index: idx,
                        specification: spec,
                        address: stage.address?.addressLine ?? "",
                        createdAt: spec.createdAt,
                        volume: stage.volume,
                        cost: spec.actualCost.mul(volumeRatio),
                        country: spec.country,
                        eisNumber: null,
                        registrationCountry: "RU"
                    };
                }
            );
        });

        this.setSelected(this.draft);
    };

    public pickAddress = async (v: ExecutionInfoSpecification, idx: number) => {
        const result = await showModal(PickAddressModal, {existing: v.specification.stages[idx].address ?? undefined});
        if (result) {
            v.specification.stages[idx]!.address = new Address(result.type, result.addressLine, result.type === "LANDMARK" ? result.additionalInfo : "");
            const execSpecIdx = this.draft.findIndex(exec  => (exec as ExecutionInfoSpecification & {id: string}).id === v.specification.stages[idx].id);
            const selectedIdx = this.selected.findIndex(exec  => (exec as ExecutionInfoSpecification & {id: string}).id === v.specification.stages[idx].id);
            if(execSpecIdx !== -1) {
                this.draft[execSpecIdx].address = result.addressLine;
            }
            if(selectedIdx !== -1) {
                this.selected[selectedIdx].address = result.addressLine;
            }
        }
    };

    private validate = (): string[] => {
        const errors: string[] = [];
        this.selected.forEach(v => {
            if (v.volume && v.volume.gt(0)) {
                if (!v.country) errors.push(`Поле "Страна происхождения" не заполнено`);
                if (!v.registrationCountry) errors.push(`Поле "Страна регистрации производителя" не заполнено`);
                if (v.specification.stages.some(s => !s.address)) errors.push(`Поле "Адрес поставки" не заполнено для спецификации с указанным объемом поставки`);
                if (v.volume.gt(v.specification.actualVolume)) errors.push(`Объем поставки не может быть больше общего объема спецификации`);
            }
        });
        return errors;
    };

    public save = (): ExecutionInfoSpecification[] | null => {
        const errors = this.validate();
        if (errors.length === 0) {
            (this.selected as (ExecutionInfoSpecification & {index?: number; id?: string})[]).forEach(sel => {
                delete sel.index;
                delete sel.id;
            });
            return toJS(this.selected);
        } else {
            showModal(ErrorModal, { text: errors.join("\n") });
            return null;
        }
    };
    public dispose() {}
}
