import {asDate, AutoDto, AutoModel, uuid} from "@/models/parsing";
import {CharacteristicConditionType, CharacteristicValueType} from "@/models/enums";
import {CharacteristicKpgz, Okpd, Okpd2, SubjectClassMD} from "@/models/SubjectClass/SubjectClass";

export interface MeasurementUnit {
    id: number
    name: string
}

export interface Characteristic {
    isAdditional: boolean
    name: string
    valueType: CharacteristicValueType
    measurementUnit: MeasurementUnit | null
    enumValues: string[]
    defaultValue: string
    conditionType: CharacteristicConditionType
}

export interface SubjectDeclarationInstance {
    createdDate: string
    id: uuid
    measurementUnits: MeasurementUnit[]
    okpd: Okpd
    okpd2: Okpd2
    projectCheck: boolean
    standardProduct: boolean
    subject: string
    subjectClass: SubjectClassMD
    kpgzCharacteristicKpgz: CharacteristicKpgz
}

interface SubjectDeclarationMD {
    id: uuid
    characteristics: Characteristic
    innovationProduct: boolean
    measurementUnit: MeasurementUnit
    okpd2: Okpd2
    projectCheck: boolean
    standardProduct: boolean
    subject: string
    subjectClass: SubjectClassMD
    createdDate: Date
}

export type DtoSubjectDeclaration = AutoDto<SubjectDeclarationMD>;
export type SubjectDeclaration = AutoModel<SubjectDeclarationMD>;

export const SubjectDeclaration = {
    toDto: (info: SubjectDeclaration) => ({
        ...info,
        createdDate: info.createdDate.toISOString()
    }),
    fromDto: (info: DtoSubjectDeclaration) => ({
        ...info,
        createdDate: asDate(info.createdDate)
    })
};
