import React from "react";
import {Card} from "@/components/Card";
import {Table} from "@/components/table";
import {Label} from "@/components/primitive";

export const CustomersBankDetails: React.FC = x => {
    type Template = { code: string; type: string; bic: string; name: string; date: string };

    const dataset: Template[] = [
        {
            code: "40702810003000000297",
            type: "Расчетный счет",
            bic: "044525768",
            name: "",
            date: ""
        },
        {
            code: "40702810003000000297",
            type: "Расчетный счет",
            bic: "044525768",
            name: "",
            date: ""
        }
    ];

    return <Card>
        <Table<Template> dataset={dataset} columns={[
            Table.AutoColumn("Номер счета", x =>
                <Label>
                    {x.item.code}
                </Label>),
            Table.AutoColumn("Тип счета", x =>
                <Label>
                    {x.item.type}
                </Label>),
            Table.AutoColumn("БИК", x =>
                <Label>
                    {x.item.bic}
                </Label>),
            Table.AutoColumn("Наименование банка", x =>
                <Label>
                    {x.item.name}
                </Label>),
            Table.AutoColumn("Дата открытия счета", x =>
                <Label>
                    {x.item.date}
                </Label>),
            Table.AutoColumn("Получатель", x =>
                <Label>

                </Label>),
        ]} />
    </Card>;
};