




import { Component, Vue } from "vue-property-decorator";
import { ProvisionList } from "@/views/Provision/list/ProvisionList";
import {createProvisionListStore} from "@/views/Provision/list/store";

@Component({ components: { komponent: ProvisionList as unknown as typeof Vue } })
export default class ProvisionListVueStub extends Vue {
    public storeContainer = Object.freeze(createProvisionListStore());
}
