// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, "._13OpM-KleftQWw6SiiudpH {\n    border: solid black;\n    border-width: 0 3px 3px 0;\n    padding: 3px;\n    display: inline-block;\n}\n\n._2fRckfv3V8vHcfupxiM3Wr {\n    transform: rotate(-135deg);\n}\n\n._3lBq79kJAI6WjcPLqR4K2Z {\n    transform: rotate(45deg);\n}\n\n", ""]);
// Exports
exports.locals = {
	"arrow": "_13OpM-KleftQWw6SiiudpH",
	"up": "_2fRckfv3V8vHcfupxiM3Wr",
	"down": "_3lBq79kJAI6WjcPLqR4K2Z"
};
module.exports = exports;
