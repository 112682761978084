import {applyN, AutoDto, AutoModel, MDMap} from "@/models/parsing";
import {ProcedureDocumentType} from "@/models/enums/Documents";
import {File, DtoFile} from "@/models/ComposedLots/documents";

interface ProcedureDocumentMD {
    id: string
    procedureId: string
    file: MDMap<File, DtoFile>
    type: ProcedureDocumentType
    sendToEis: boolean
}

export type ProcedureDocument = AutoModel<ProcedureDocumentMD>;
export type DtoProcedureDocument = AutoDto<ProcedureDocumentMD>;

export const ProcedureDocument = {
    toDto(pd: ProcedureDocument): DtoProcedureDocument {
        return {
            ...pd,
        };
    },
    fromDto(pd: DtoProcedureDocument): ProcedureDocument {
        return {
            ...pd,
            file: applyN(File.fromDto, pd.file)
        };
    },
};