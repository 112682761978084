import { http, IFilterObject } from "@/api/http";
import { IPagedItems, mapPage } from "@/api/Pagination";
import { ContractObligation } from "@/models/Contracts/ContractObligation";
import { uuid } from "@/models/parsing";
import { DtoHistoryItem, HistoryItem } from "@/models";
import { ContractObligationReceipt } from "@/models/Contracts/ContractObligationReceipt";

export const getContractObligations = async (from: number, count: number, filters?: IFilterObject): Promise<IPagedItems<ContractObligation>> =>
    mapPage(
        ContractObligation,
        await http.getPaged("/api/v2/contractPayments/obligations", { from, count, filters })
    );

export const getContractObligation = async (id: uuid) =>
    ContractObligation.fromDto(await http.get(`/api/v2/contractPayments/obligations/${id}`));

export const getContractObligationHistory = async (id: uuid) =>
    (await http.get(`/api/v2/contractPayments/obligations/${id}/history`) as DtoHistoryItem[]).map(HistoryItem.fromDto);

export const getContractObligationReceipts = async (id: uuid, from: number, count: number): Promise<IPagedItems<ContractObligationReceipt>> =>
    mapPage(
        ContractObligationReceipt,
        await http.getPaged(`/api/v2/contractPayments/obligations/${id}/receipts`, { from, count  })
    );

export const addObligationToContractStage = async (contractStageId: uuid, obligationId: uuid) =>
    await http.post(`/api/v2/contractPayments/add?contractStageId=${contractStageId}&obligationId=${obligationId}`, undefined);
