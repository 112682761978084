// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, "._2onRjVMys_XTMnigd9UutJ {\n    display: grid;\n    grid-template-rows: repeat(5, auto);\n    grid-template-columns: 3fr 4fr 3fr;\n    grid-auto-flow: column;\n    grid-gap: 15px;\n    align-items: center;\n}\n\n._2zD1OtxOjBaDoKXgCptqew {\n    text-align: right;\n    font-size: 14px;\n}", ""]);
// Exports
exports.locals = {
	"informationCard": "_2onRjVMys_XTMnigd9UutJ",
	"informationCardLabel": "_2zD1OtxOjBaDoKXgCptqew"
};
module.exports = exports;
