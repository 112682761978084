











import { FieldAlignment, FieldWidth, valueSpanContext, FComponent } from "./FComponent";
import { Component, Prop, Vue, Inject, Mixins } from "vue-property-decorator";
import { FValueComponent } from './FValueComponent';

@Component
export default class FText extends Mixins(FValueComponent) {
    @Prop({ default: false }) public multiline!: boolean;
    @Prop({ default: 3 }) public lines!: number;
    @Prop({ default: "" }) public placeholder!: string;
    @Prop({ default: false }) public focus!: boolean;
    @Prop({ default: 0 }) public caret!: number;
    @Prop({ default: "left" }) public alignment!: FieldAlignment;
    @Prop({ default: "wide" }) public width!: FieldWidth;
    @Prop({ default: () => /.*/s }) public pattern!: RegExp;
    @Prop({ default: 'text' }) public type!: string;
    @Prop({ default: false }) public isReadonly!: boolean;

    public get commonProps() {
        const { alignment, width, f } = this;

        return { class: [f(alignment), f(width), ...this.classes] };
    }

    public get editableProps() {
        const { value, placeholder, disabled } = this;

        return { ...this.commonProps, value, placeholder, disabled };
    }

    public mounted() {
        this.update();
    }

    public updated() {
        this.update();
    }

    public onInput(e: Event) {
        const target = e.target! as HTMLInputElement;
        const result = this.pattern.exec(target.value);
        const fixed = result ? result[0] : "";

        target.value = fixed;

        this.$emit("input", fixed);
    }

    public update() {
        if (!this.enabled) return;
        if (!this.$refs.input) return;

        const e = this.$refs.input as HTMLInputElement;

        if (this.focus) e.focus();
        if (this.caret) e.setSelectionRange(this.caret, this.caret);
    }
}
