import React from "react";
import styles from "./FavoriteButton.module.css";

type FavoriteButtonProps = {
    favorite: boolean
    onClick?: () => void
};

export const FavoriteButton = ({ favorite, onClick }: FavoriteButtonProps) =>
    <div onClick={onClick} className={styles.favoriteButtonComponent}>
        <div className={`${styles.favorite}  ${favorite ? styles.favoriteSelected : ''}`} />
    </div>;