import {autoSV, createSVEvent, origEv} from "@/storeUtils";
import {createEvent, createStore, Store} from "effector";
import {LotForm} from "@/views/ComposedLot/shared/store/index";
import {setInfoChanged} from "@/views/ComposedLot/shared/store/head";

export interface LotMinimalRequirements {
    text?: string
}

export const setMinimalRequirements = createSVEvent<LotMinimalRequirements>()("text");

const replace = createEvent<LotMinimalRequirements>("replace minimal requirements");

export const MinimalRequirementsStore = {
    createStore: (): Store<LotMinimalRequirements> => {
        const store = createStore<LotMinimalRequirements>({})
            .on(replace, (_, l) => l)
            .on(setMinimalRequirements[origEv], autoSV);

        store.watch(setMinimalRequirements, () => setInfoChanged(true));

        return store;
    },

    replace: replace,
    observeOnCombinedStore: (combinedStore: Store<LotForm>) => {}
};