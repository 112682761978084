import React, { useMemo, useState } from "react";
import { ModalFC, showModal } from "@/EventBus";
import { CardModal } from "@/components/modals/CardModal";
import { Card } from "@/components/Card";
import { Expandee, HStack, VStack } from "@/components/layouts";
import { Button, Label } from "@/components/primitive";
import { formatNumber } from "@/NumberFormatting";
import { PickFinancialSourceModal } from "@/modal-views";
import { LawType } from "@/models/enums";
import { LotPlannedPayment } from "@/models/ComposedLots/LotPlannedPayment";
import Decimal from "decimal.js";
import { Column, Table } from "@/components/table";
import { FinancialLimit, FinancialSourceTypeStrings } from "@/models/ComposedLots/FinancialLimit";
import { FComboBox, FDecimalBox } from "@/components/form";

type AcceptObjectModalProps = {
    law: LawType
};

export const AcceptObjectModal: ModalFC<AcceptObjectModalProps, LotPlannedPayment[]> = ({ law, done }) => {
    const [plannedPayments, setPlannedPayments] = useState<LotPlannedPayment[]>([]);

    const total = useMemo(() => plannedPayments.reduce(
        (total, plannedPayment) => total.add(plannedPayment.amount),
        new Decimal(0)),
    [plannedPayments]);

    const pickFinancialPayments = async () => {
        const plannedPayment = await showModal(PickFinancialSourceModal, { law });

        if (plannedPayment) {
            setPlannedPayments(plannedPayments => [...plannedPayments, plannedPayment]);
        }
    };

    const editPlannedPayment = async (plannedPayment: LotPlannedPayment) => {
        const result = await showModal(PickFinancialSourceModal, {
            existingSource: plannedPayment
        });

        if (result) {
            setPlannedPayments(plannedPayments => [
                ...plannedPayments.filter(p => p !== plannedPayment),
                result
            ]);
        }
    };

    const deletePlannedPayment = (plannedPaymentIndex: number) => {
        setPlannedPayments(plannedPayments => plannedPayments.filter((_, i) => i !== plannedPaymentIndex));
    };

    const plannedPaymentsColumns: Column<LotPlannedPayment>[] = [
        Table.AutoColumn("Тип", x =>
            <Label preset="readonlyValue">
                {FinancialSourceTypeStrings[x.item.source.type]}
            </Label>
        ),
        Table.Column("Код", x =>
            <Label preset="readonlyValue">
                <b>
                    {FinancialLimit.format(x.item.source)}
                </b>
            </Label>
        ),
        Table.Column(<>Лимит, руб.<br/>Всего /<br/>Остаток</>, x =>
            <Label preset="readonlyValue">
                <b>
                    {formatNumber(x.item.source.price)}
                    <br/>
                    {formatNumber(x.item.source.price.sub(x.item.source.plannedAmount))}
                </b>
            </Label>
        ),
        Table.Column("Сумма, руб.", x =>
                <FDecimalBox value={x.item.amount} />,
            { width: "2fr" }
        ),
        Table.Column("Титул АИП", () =>
            <FComboBox options={[]} />
        ),
        Table.Column(<>Лимит по титулу, руб.<br/>Всего /<br/>Остаток</>, () => <></>),
        Table.AutoColumn("", x =>
            <Button onClick={() => editPlannedPayment(x.item)} icon="aEdit"/>
        ),
        Table.AutoColumn("", x =>
            <div style={{ color: "#9eafba", fontSize: "16pt", cursor: "pointer" }}
                 onClick={() => deletePlannedPayment(x.rowIndex)}>
                x
            </div>
        )
    ];

    const bottom = <HStack spacing="10px">
        <Expandee />
        <Button title="Принять"
                disabled={plannedPayments.length === 0}
                color="green"
                onClick={() => done(plannedPayments)} />
        <Button icon="aClose" onClick={() => done(undefined)} />
    </HStack>;

    return (
        <CardModal title="Принятие"
                   width="60%"
                   cardContentStyle={{ padding: 0 }}
                   bottom={bottom}
                   close={() => done(undefined)}>
            <VStack>
                <Label preset="medium" style={{ justifySelf: "center", padding: "10px 10px 10px 30px" }}>
                    Для принятия Объекта данных монополиста необходимо выбрать финансирование лота.
                    Для выбора финансирования лота нажмите кнопку Добавить.
                </Label>
                <Card title="Планируемые платежи"
                      style={{ border: "none" }}>
                    <VStack spacing="10px">
                        <HStack alignItems="center"
                                spacing="10px"
                                outerStyle={{ padding: "10px", backgroundColor: "#f6fafb", border: "1px solid #edf6f8" }}>
                            <Label preset="readonlyValue">Общая сумма закупки:</Label>
                            <Label preset="boldSmall">{formatNumber(total)} Российский рубль</Label>
                        </HStack>
                        <Button title="Добавить"
                                color="white"
                                style={{ alignSelf: "start" }}
                                onClick={pickFinancialPayments} />
                        <Table columns={plannedPaymentsColumns} dataset={plannedPayments} />
                        {plannedPayments.length === 0 && (
                            <Label preset="readonlyValue" style={{ alignSelf: "flex-end" }}>
                                Не указано финансирование
                            </Label>
                        )}
                        <Label preset="readonlyValue">
                            Принятые сведения будут перенесены в Лот без возможности их последующего редактирования.
                        </Label>
                        <Label preset="readonlyValue">Вы уверены в принятии Объекта данных монополиста?</Label>
                    </VStack>
                </Card>
            </VStack>
        </CardModal>
    );
};
