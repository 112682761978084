import {AutoDto, AutoModel} from "@/models/parsing";
import {Decimal} from 'decimal.js';
import {UnionStrings} from "@/models/enums";

export type FinancialSourceType =
    | "FIN_ACTIONS"
    | "OWN_FUNDS"
    | "OWN_FUNDS_SUB"
    | "LIMIT"
    | "FEDERAL_FUNDS";

export const FinancialSourceTypeStrings: UnionStrings<FinancialSourceType> = {
    FIN_ACTIONS: "ПФХД",
    OWN_FUNDS: "Собственные средства",
    OWN_FUNDS_SUB: "",
    LIMIT: "Лимит",
    FEDERAL_FUNDS: "",
};

interface FinancialLimitMD {
    id: number
    year: number
    type: FinancialSourceType
    kbk?: string
    csr?: string
    kvr?: string
    kosgu?: string
    grbs?: string
    code?: string
    comment?: string
    kvfo?: string
    price: Decimal
    plannedAmount: Decimal
    is223: boolean
}

export type DtoFinancialLimit = AutoDto<FinancialLimitMD>;
export type FinancialLimit = AutoModel<FinancialLimitMD>;

export const FinancialLimit = {
    toDto(info: FinancialLimit): DtoFinancialLimit {
        return {
            ...info,
            price: info.price.toString(),
            plannedAmount: info.plannedAmount.toString()
        };
    },
    fromDto(info: DtoFinancialLimit): FinancialLimit {
        return {
            ...info,
            kbk: info.kbk ?? undefined,
            csr: info.csr ?? undefined,
            kvr: info.kvr ?? undefined,
            kosgu: info.kosgu ?? undefined,
            grbs: info.grbs ?? undefined,
            code: info.code ?? undefined,
            comment: info.comment ?? undefined,
            kvfo: info.kvfo ?? undefined,
            price: new Decimal(info.price),
            plannedAmount: new Decimal(info.plannedAmount)
        };
    },
    format(limit: FinancialLimit, prefix: string = "Код"): string {
        return `${prefix}: ${limit.kvr}-${limit.kosgu}`;
    }
};