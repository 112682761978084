import React, { useEffect } from "react";
import { $contracts, $directPurchases, $filters, addPurchaseToContract, changeFavorites, changePageSize, DirectPurchasesStore, loadPurchasesPage, searchPurchases, toggleShowInfo } from './store';
import { Pager } from "@/components/eshop";
import { DirectPurchaseTable } from "./DirectPurchaseTable";
import styles from "./DirectPurchase.module.css";
import { Header } from "./Header";
import { DirectPurchasesFilters } from "./DirectPurchasesFilters";
import { useStore } from "effector-react";
import { ContractCreate } from "./ContractCreate";
import { observer } from "mobx-react";
import { SubjectClassFilterStore } from "@/components/eshop/SubjectClassFilterStore";

interface DirectPurchaseProps {
    favorites: boolean
    storeContainer: { subjectClassSelector: SubjectClassFilterStore }
}

export const DirectPurchase: React.FC<DirectPurchaseProps> = x => {
    const store = useStore($directPurchases);
    const filterStore = useStore($filters);

    const contractStore = useStore($contracts);
    const showModal = !!contractStore.purchases.length;
    const closeModal = () => addPurchaseToContract(null);

    useEffect(() => {
        changeFavorites(!!x.favorites);
        loadPurchasesPage({ page: 0 });
        return;
    }, [x.favorites]);

    const { page } = store;
    return <div className={styles.directPurchasePage}>
        <ContractCreate show={showModal} closeModal={closeModal} />
        <Header store={store} />
        <div className={styles.contentContainer}>
            <div className={styles.tableContainer}>
                <DirectPurchaseTable store={store} />
                <div className={styles.pager}>
                    <Pager
                        page={page}
                        goTo={searchPurchases}
                        pageSize={filterStore.pageSize}
                        changePageSize={v => changePageSize({ pageSize: v })}>
                    </Pager>
                </div>
                <TableInfo store={store} />
            </div>
            {!store.isFilterOpened && <DirectPurchasesFilters filterStore={filterStore} subjectClassSelector={x.storeContainer.subjectClassSelector} />}
        </div>
    </div>;
};

const TableInfo = observer((props: { store: DirectPurchasesStore }) => {
    const { showInfo } = props.store;
    return <div className={styles.tableInfoBlock}>
        <div className={`${styles.openTableInfoIcon} ${!showInfo ? styles.openTableInfoIconClosed : ''}`} onClick={() => toggleShowInfo()} />
        {showInfo && <ul className={styles.tableInfoColorLegend}>
            <li className={`${styles.circle} ${styles.red}`}>
                Прямая закупка по 44-ФЗ
            </li>
            <li className={`${styles.circle} ${styles.blue}`}>
                Прямая закупка по 223-ФЗ
            </li>
            <li className={`${styles.circle} ${styles.yellow}`}>
                Прямая закупка по 44 и 223-ФЗ
            </li>
        </ul>}
    </div>;
});