














import { Component } from "vue-property-decorator";
import { MutatingFieldBase } from "@/components/vue/form-table/FieldBase";
import { mixins } from "vue-class-component";
import { SelectOption } from '@/models/enums';
import { SelectionRow } from './FormFields';

@Component
export default class ComboBoxField extends mixins(MutatingFieldBase) {
    private resultingReadonlyValue: unknown = null;

    public get selectOptions() {
        return (this.row as SelectionRow<unknown>).selectOptions;
    }

    public get readonlyValue() {
        if (this.fieldValue === null) return null;

        if ((this.selectOptions as {then: unknown}).then) {
            this.awaitOptionsAndSetValue();
            return this.resultingReadonlyValue;
        } else {
            return ((this.selectOptions as SelectOption<unknown>[])
                .find(x => x.key === this.fieldValue) || {desc: null}).desc || this.fieldValue;
        }
    }

    private async awaitOptionsAndSetValue() {
        const selectOptions = await (this.selectOptions as Promise<SelectOption<unknown>[]>);
        this.resultingReadonlyValue = (selectOptions.find(x => x.key === this.fieldValue) || {desc: null}).desc || this.fieldValue;
    }
}
