// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, "._1Bww2kS6_HCrDT0yP5UrTa {\n    display: flex;\n    width: 100%;\n    margin: 0;\n    padding: 0;\n    align-items: stretch;\n    justify-content: space-between;\n    box-sizing: border-box;\n}\n\n._1p9z4Uk1DVXoHrRE0bSBfI {\n    flex-direction: column;\n}\n\n.JJQXRL4rkfMULrpH6euH- {\n    padding: 16px 20px;\n}\n\n._3poiYb0lwIQSB104t9R1-r {\n    border: none;\n}\n\n._3Rg2GiB8_MJ1-3itD71LZG {\n    font-size: 18px;\n    font-weight: 500;\n    display: flex;\n    align-items: center;\n    max-height: 40px;\n    font-family: inherit;\n    box-sizing: border-box;\n    user-select: none;\n    white-space: nowrap;\n}\n\n._1NKym2yoPH0WkrWBFFjEYY {\n    flex-direction: row;\n}\n\n", ""]);
// Exports
exports.locals = {
	"card__wrapper": "_1Bww2kS6_HCrDT0yP5UrTa",
	"card__wrapper_vertical": "_1p9z4Uk1DVXoHrRE0bSBfI",
	"card_padded": "JJQXRL4rkfMULrpH6euH-",
	"card__wrapper_noBorder": "_3poiYb0lwIQSB104t9R1-r",
	"card__title": "_3Rg2GiB8_MJ1-3itD71LZG",
	"card__wrapper_horizontal": "_1NKym2yoPH0WkrWBFFjEYY"
};
module.exports = exports;
