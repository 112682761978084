import React, {useEffect, useState} from "react";
import styles from "./LoginWizardModal.module.css";
import {ModalFC} from "@/EventBus";
import {LoginWizardViewModel} from "@/views/Login/wizard/LoginWizard.vm";
import Editor from "rich-markdown-editor";
import {getMediaUrl} from "@/api/Media";
import {Expandee, VStack} from "@/components/layouts";
import {j} from "@/reactUtils";
import {colors} from "@/components/primitive/RoundedButton.colors";
import {Spinner} from "@/components/primitive/Spinner";
import {RoundedButton} from "@/components/primitive/RoundedButton";
import {observer} from "mobx-react";

export function GradientButton(x: { title: string; solid?: boolean; disabled?: boolean; onClick: () => void }) {
    return <button className={j(styles.button, x.solid ? styles.solid : undefined, x.disabled ? styles.disabled : undefined)}
                   onClick={x.onClick}
                   disabled={x.disabled}>
        <div className={styles.text}>
            { x.title }
        </div>
    </button>;
}

function Dots(x: { count: number; active: number }) {
    if (x.count === 0) return <></>;
    return <div style={{ display: "flex", gap: "20px", alignItems: "center" }}>
        {
            [...Array(x.count).keys()].map(i => <div key={`${x.active}-${i}`} 
                                                     className={j(styles.dot, i === x.active ? styles.dotActive : undefined)} />)
        }
    </div>;
}

export const LoginWizardModal: ModalFC = observer(x => {
    const [vm, setVm] = useState<LoginWizardViewModel>();
    useEffect(() => {
        const _vm = new LoginWizardViewModel();
        setVm(_vm);
    }, []);

    if (!vm) return <div className={j(styles.modal, styles.fullscreen)}>
        <VStack spacing="10px" alignItems="center">
            <div>Не удалось загрузить слайдшоу</div>
        </VStack>
    </div>;

    if (vm.isLoading) return <div className={j(styles.modal, styles.fullscreen)}>
        <Spinner color={colors.blue.idle.bg} />
    </div>;

    const current = vm.currentSlide;
    if (!current) return <div className={j(styles.modal, styles.fullscreen)}>
            <VStack spacing="10px" alignItems="center">
                <div>Не удалось загрузить слайдшоу</div>
                <RoundedButton title="Повторить попытку" onClick={() => vm?.load()} />
        </VStack>
    </div>;

    const title = `Шаг ${(vm.current ?? 0) + 1}/${vm.slides.length}. ${current.title}`;

    return <div className={styles.modal}>
        <div className={styles.closeButton} onClick={() => x.done()}>
            <svg width="19" height="19" viewBox="0 0 19 19" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M11.41 9.00026L18.7 1.71026C18.8638 1.51896 18.9494 1.27288 18.9397 1.02121C18.93 0.769532 18.8256 0.530793 18.6475 0.352698C18.4694 0.174604 18.2307 0.0702709 17.979 0.0605497C17.7274 0.0508286 17.4813 0.136435 17.29 0.300261L9.99997 7.59026L2.70997 0.290261C2.51867 0.126435 2.2726 0.0408293 2.02092 0.0505505C1.76924 0.0602716 1.53051 0.164604 1.35241 0.342698C1.17432 0.520793 1.06998 0.759531 1.06026 1.01121C1.05054 1.26288 1.13615 1.50896 1.29997 1.70026L8.58997 9.00026L1.28997 16.2903C1.18529 16.3799 1.10027 16.4902 1.04025 16.6143C0.980225 16.7384 0.946494 16.8735 0.941175 17.0112C0.935855 17.1489 0.959061 17.2863 1.00934 17.4146C1.05961 17.5429 1.13587 17.6595 1.23332 17.7569C1.33078 17.8544 1.44733 17.9306 1.57565 17.9809C1.70398 18.0312 1.84131 18.0544 1.97903 18.0491C2.11675 18.0437 2.25188 18.01 2.37594 17.95C2.50001 17.89 2.61033 17.8049 2.69997 17.7003L9.99997 10.4103L17.29 17.7003C17.4813 17.8641 17.7274 17.9497 17.979 17.94C18.2307 17.9303 18.4694 17.8259 18.6475 17.6478C18.8256 17.4697 18.93 17.231 18.9397 16.9793C18.9494 16.7276 18.8638 16.4816 18.7 16.2903L11.41 9.00026Z" fill="#C4C4C4"/>
            </svg>
        </div>
        <div className={current.mediaItem ? styles.columns : styles.single}>
            <div>
                <h1>{ title }</h1>
                <div className={styles.content}>
                    <Editor value={vm.currentSlide?.content}
                            readOnly />
                </div>
            </div>
            <div className={styles.image}>
                { current.mediaItem && <img src={getMediaUrl(current.mediaItem)}/> }
            </div>
        </div>
        <div className={styles.bottom}>
            <GradientButton title="В начало" onClick={() => vm.backToStart()} />
            <Expandee />
            <Dots active={vm.current ?? 0} count={vm.slides.length} />
            <Expandee />
            <div style={{ display: "flex", gap: "20px", minWidth: "310px", justifyContent: "flex-end" }}>
                {
                    vm.hasNext
                        ? <>
                            <GradientButton disabled={!vm.hasPrev} title="Назад" onClick={() => vm.prev()} />
                            <GradientButton title="Далее" onClick={() => vm.next()} solid />
                        </>
                        : <GradientButton title="Приступить к работе" onClick={() => x.done()} />
                }
            </div>
        </div>
    </div>;
});