import {makeAutoObservable, runInAction} from "mobx";
import {LoginWizardApi, LoginWizardSlide} from "@/api/LoginWizard";

export class LoginWizardViewModel {
    constructor() {
        makeAutoObservable(this);
        this.load();
    }

    isLoading = false;
    slides: LoginWizardSlide[] = [];
    public current: number | null = 0;

    get currentSlide(): LoginWizardSlide | null {
        if (this.current !== null) return this.slides[this.current] ?? null;
        return null;
    }

    backToStart = () => {
        if (this.current === null) return;
        this.current = 0;
    };

    next = () => {
        if (this.current === null) return;
        this.current++;
    };

    prev = () => {
        if (this.current === null) return;
        this.current--;
    };

    get hasNext() {
        if (this.current === null) return false;
        if (this.current >= (this.slides.length - 1)) return false;
        return true;
    }

    get hasPrev() {
        if (this.current === null) return false;
        if (this.current === 0) return false;
        return true;
    }

    load = async () => {
        try {
            this.isLoading = true;
            const result = await LoginWizardApi.findAll();
            runInAction(() => {
                this.slides = result;
                this.current = 0;
            });
        } catch (e) {
            console.error("unable to load slides", e);
        } finally {
            this.isLoading = false;
            console.log("finished", this);
        }
    };
}