import { IDtoParticipant } from "@/models/json";
import { IJsonFormattable } from "@/models/IJsonFormattable";
import { ParticipantStatus } from './enums';
import {uuid} from "@/models/parsing";

export interface ParticipantMD {
    address: string
    fullName: string
    id: uuid
    isCustomer: boolean
    isExpert: boolean
    isSmall: boolean
    isSocial: boolean
    isSpecial: boolean
    isSupplier: boolean
    parentOrganization: boolean
    deliveryAddresses: {id: number; addressLine: string}[]
    regNumber: string
    rrc: string
    shortName: string
    spzCode: string
    status: ParticipantStatus
    tin: string
}

export class Participant implements IJsonFormattable<IDtoParticipant> {
    constructor(
        public id: number,
        public fullName: string,
        public shortName: string,
        public isSpecial: boolean,
        public tin: string,
        public rrc: string,
        public regNumber: string,
        public status: ParticipantStatus,
        public isSmall: boolean,
        public isSocial: boolean,
        public address?: string,
        public isSmp?: boolean
    ) {}

    public toJson(): IDtoParticipant {
        return { ...this };
    }

    // noinspection JSUnusedGlobalSymbols
    public static fromJson(dto: IDtoParticipant): Participant {
        return Object.assign(Object.create(Participant.prototype), dto);
    }
}
