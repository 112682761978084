/* eslint-disable @typescript-eslint/ban-ts-ignore */
import React from "react";
import {NewsArticle, NewsArticleLabel} from "@/api/News";
import {Expandee, HStack} from "@/components/layouts";
import {formatDate} from "@/dateUtils";
import {ArticleLabel} from "@/views/Login/news/widgets/ArticleLabel";
import styles from "./NewsItem.module.css";
import {getMediaUrl} from "@/api/Media";
import Editor, {theme} from "rich-markdown-editor";

type NewsItemProps = {
    item: NewsArticle
    onLabelSelect: (l: NewsArticleLabel) => void
};

export function NewsImagePlaceholder() {
    return <svg width="206" height="255" viewBox="0 0 206 255" fill="none" xmlns="http://www.w3.org/2000/svg">
            <g opacity="0.15" clipPath="url(#clip0_1937_15498)">
                <path d="M0 0V86.1547H18.5932V18.5932H186.432V193.138C186.204 199.343 183.637 205.231 179.246 209.621C174.856 214.012 168.967 216.579 162.763 216.807H138.805C125.69 216.958 112.977 221.35 102.565 229.325C92.152 221.33 79.4266 216.928 66.2991 216.781H42.2358C36.0312 216.552 30.1426 213.985 25.7523 209.595C21.3621 205.205 18.7951 199.316 18.5669 193.112V130.521H0V193.138C0.149752 204.293 4.64766 214.949 12.5362 222.838C20.4247 230.726 31.0807 235.224 42.2358 235.374H66.2465C77.4478 235.609 88.113 240.215 95.9641 248.208L102.565 254.73L109.113 248.234C116.951 240.233 127.608 235.617 138.805 235.374H162.789C173.963 235.251 184.645 230.765 192.556 222.874C200.467 214.983 204.98 204.311 205.13 193.138V0H0Z" fill="url(#paint0_linear_1937_15498)"/>
                <path d="M0 0V86.1547H18.5932V18.5932H186.432V193.138C186.204 199.343 183.637 205.231 179.246 209.621C174.856 214.012 168.967 216.579 162.763 216.807H138.805C125.69 216.958 112.977 221.35 102.565 229.325C92.152 221.33 79.4266 216.928 66.2991 216.781H42.2358C36.0312 216.552 30.1426 213.985 25.7523 209.595C21.3621 205.205 18.7951 199.316 18.5669 193.112V130.521H0V193.138C0.149752 204.293 4.64766 214.949 12.5362 222.838C20.4247 230.726 31.0807 235.224 42.2358 235.374H66.2465C77.4478 235.609 88.113 240.215 95.9641 248.208L102.565 254.73L109.113 248.234C116.951 240.233 127.608 235.617 138.805 235.374H162.789C173.963 235.251 184.645 230.765 192.556 222.874C200.467 214.983 204.98 204.311 205.13 193.138V0H0Z" fill="white" fillOpacity="0.05"/>
                <path d="M42.5514 42.5513V86.1546H61.1446V61.1445H143.88V70.2438L102.565 111.638C92.1544 103.635 79.4293 99.2244 66.2991 99.0673H0V117.66H66.2465C77.4533 117.894 88.1221 122.511 95.9641 130.521L102.565 137.016L143.88 95.6484V174.255H61.1446V130.521H42.5514V192.849H162.5V42.5513H42.5514Z" fill="url(#paint1_linear_1937_15498)"/>
                <path d="M42.5514 42.5513V86.1546H61.1446V61.1445H143.88V70.2438L102.565 111.638C92.1544 103.635 79.4293 99.2244 66.2991 99.0673H0V117.66H66.2465C77.4533 117.894 88.1221 122.511 95.9641 130.521L102.565 137.016L143.88 95.6484V174.255H61.1446V130.521H42.5514V192.849H162.5V42.5513H42.5514Z" fill="white" fillOpacity="0.05"/>
            </g>
            <defs>
                <linearGradient id="paint0_linear_1937_15498" x1="-186.824" y1="270.805" x2="105.2" y2="417.716" gradientUnits="userSpaceOnUse">
                    <stop stopColor="#00B9FF"/>
                    <stop offset="0.552083" stopColor="#8C32E6"/>
                    <stop offset="1" stopColor="#E5083B"/>
                </linearGradient>
                <linearGradient id="paint1_linear_1937_15498" x1="-147.998" y1="202.333" x2="51.0677" y2="336.791" gradientUnits="userSpaceOnUse">
                    <stop stopColor="#00B9FF"/>
                    <stop offset="0.552083" stopColor="#8C32E6"/>
                    <stop offset="1" stopColor="#E5083B"/>
                </linearGradient>
                <clipPath id="clip0_1937_15498">
                    <rect width="205.025" height="254.73" fill="white"/>
                </clipPath>
            </defs>
        </svg>;
}

// @ts-ignore
function getImageChildren(parent, options) {
    for (const child in parent.children) {
        if (
            parent.children[child].children &&
            parent.children[child].children.length > 0
        ) {
            getImageChildren(parent.children[child], options);
        }

        if (parent.children[child].type === "image") {
            const data = (parent.children[child].data = {});

            // @ts-ignore
            const props = (data.hProperties = {});
            // @ts-ignore
            props.width = options.width ? options.width : "100%";
        }
    }
}

function imageSize(options = {}) {
    // @ts-ignore
    return (node) => {
        for (const child in node.children) {
            getImageChildren(node.children[child], options);
        }
    };
}

export const NewsItem: React.FC<NewsItemProps> = x => {
    const media = x.item.urlImage ? x.item.urlImage : getMediaUrl(x.item.media ?? undefined);
    return <div className={styles.article}
                id={`article-${x.item.id}`}>
        <div style={{ display: "flex", flexDirection: "column", gap: "20px", maxWidth: "550px" }}>
            <div style={{ fontWeight: 400, fontSize: "24px", lineHeight: "30px", fontFamily: "GothamPro-Medium" }}>
                { x.item.title }
            </div>
            <HStack alignItems="center">
                <div style={{ color: "#B0B0B0", fontSize: "16px" }}>
                    { formatDate(new Date(x.item.createdAt), "time") }
                </div>
                <Expandee />
                { x.item.label && <ArticleLabel onLabelSelect={x.onLabelSelect} item={x.item.label} /> }
            </HStack>
            <div className={styles.articleContent}>
                <Editor value={x.item?.content}
                        theme={{ ...theme, fontFamily: "GothamPro", primary: "#344049" }}
                        readOnly />
            </div>
        </div>
        <div className={styles.image}>
            {
                media
                    ? <img style={{ background: "#DFDFE0", objectFit: "cover" }} src={media} alt={x.item.title} />
                    : <NewsImagePlaceholder />
            }
        </div>
    </div>;
};

export const CompactNewsItem: React.FC<NewsItemProps> = x => {
    const scroll = () => {
        const el = document.getElementById(`article-${x.item.id}`);
        if (el) {
            el.scrollIntoView({ behavior: "smooth", block: "center" });
        }
    };

    return <div style={{ display: "flex", flexDirection: "column", gap: "10px", marginBottom: "50px" }} onClick={scroll}>
        <HStack alignItems="center">
            <div style={{ color: "#B0B0B0", fontSize: "16px" }}>
                { formatDate(new Date(x.item.createdAt), "time") }
            </div>
            <Expandee />
            { x.item.label && <ArticleLabel onLabelSelect={x.onLabelSelect} item={x.item.label} /> }
        </HStack>
        <div style={{ fontSize: "18px", lineHeight: "22px", fontWeight: "400", fontFamily: "GothamPro-Medium" }}>
            { x.item.title }
        </div>
        <div className={styles.description}>
            { x.item.description }
        </div>
    </div>;
};
