// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, "/* YourToastFile.module.css */\n\n._3Qvnued3wcWVNvNrygZtoK {\n    display: flex;\n    flex-direction: column;\n    gap: 10px;\n}\n\n._1Oxt73hSL4WXlbpPCNRud9 {\n    font-weight: 600;\n    font-size: 16px;\n}\n\n._3-xS5J6wiXvhfwiRZs6Ol9 {\n    font-size: 14px;\n    font-weight: 400;\n    line-height: 1.5;\n    margin: 0;\n    display: flex;\n    flex-direction: column;\n    gap: 5px;\n}\n\n._2AhyyyjgTc5ssklj1xBKpp {\n    position: absolute;\n    top: 20px;\n    right: 20px;\n}\n", ""]);
// Exports
exports.locals = {
	"ToastWrapper": "_3Qvnued3wcWVNvNrygZtoK",
	"ToastTitle": "_1Oxt73hSL4WXlbpPCNRud9",
	"ToastMessage": "_3-xS5J6wiXvhfwiRZs6Ol9",
	"CopyIconContainer": "_2AhyyyjgTc5ssklj1xBKpp"
};
module.exports = exports;
