




import {Procedures} from "./Procedures";
import {Component} from "vue-property-decorator";
import Vue from 'vue';
import {createProceduresStore} from "@/views/Procedures2020/list/store";

@Component({ components: { komponent: Procedures as unknown as typeof Vue } })
export default class ProceduresVueStub extends Vue {
    public storeContainer = Object.freeze({ store: createProceduresStore() });
}
