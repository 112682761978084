import {createEvent, createStore, Store} from "effector";
import {Lot} from "@/models/ComposedLots";
import {ProcedureForm} from "@/views/Procedures2020/view/store";
import {createStoredRemoteDataset, StoredRemoteDataset} from "@/components/table";
import {getLots} from "@/api/ComposedLots";
import {autoSV, createSVEvent, origEv} from "@/storeUtils";
import {ProcedureLot} from "@/models/Procedures2020/ProcedureLot";
import Auth from "@/api/Auth";
import { Participant } from "@/models";
import {LawType} from "@/models/enums";
import {FilterConditionType, FilterValueType} from "@/api/http";

export interface LotFilters {
    number: string
}

export interface ProcedureLotsSectionStore {
    /** Лоты процедуры */
    lots: ProcedureLot[]

    /** Все лоты */
    lotDataset: StoredRemoteDataset<Lot, LotFilters>
}

const replace = createEvent<ProcedureLotsSectionStore>("replace documents section");

export const ProcedureLotsEvents = {
    addLot: createEvent<Lot>("add lot"),
    removeLot: createEvent<ProcedureLot>("remove lot"),
    setLots: createSVEvent<ProcedureLotsSectionStore>()("lots"),
    participantUpdate: createEvent<{participant: Participant; idx: number; orderNumber: number}>()

};

/** Секция "Лоты" */
export const ProcedureLotsSectionStore = {
    createStore: (law?: LawType): Store<ProcedureLotsSectionStore> => {
        return createStore<ProcedureLotsSectionStore>({
            lots: [],
            lotDataset: createStoredRemoteDataset({
                load: (filters, from, count) => getLots(Auth.selectedYear, from, count, {
                    law: [
                        { type: FilterValueType.STRING, conditionType: FilterConditionType.EQUAL, string: law },
                    ]})
            }),
        })
            .on(replace, (_, l) => l)
            .on(ProcedureLotsEvents.addLot, ((state, payload) => {
                return {...state, lots: [...state.lots, { lot: payload, documents: [], includedLots: [], participants: [] }]};
            }))
            .on(ProcedureLotsEvents.removeLot, ((state, payload) => {
                return ({...state, lots: state.lots.filter(a => a !== payload)});
            }))
            .on(ProcedureLotsEvents.setLots[origEv], autoSV)
            .on(ProcedureLotsEvents.participantUpdate, (state, payload) => {
                const newParticipantIdx = state.lots[payload.idx].participants.findIndex(p => p.orderNumber === payload.orderNumber);
                if(newParticipantIdx !== -1) {
                    const prevCopy = {...state};
                    // @ts-expect-error thinks participant is readonly property
                    prevCopy.lots[payload.idx].participants[newParticipantIdx].participant = payload.participant;
                    return prevCopy;
                }
            });
    },

    replace: replace,
    observeOnCombinedStore: (combinedStore: Store<ProcedureForm>) => {
    }
};
