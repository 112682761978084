import { createEffect, createEvent, createStore } from 'effector';
import { PageOfSessions, SessionsController } from "@/api/Sessions";
import { IFilterObject, FilterValueType, FilterConditionType } from "@/api/http";
import router from "@/router";
import { debounced } from '@/functionUtils';
import Decimal from 'decimal.js';

export type SessionsFilterStore = {
    subject: string | null
    regNumber: number | null
    status: string | null
    law: string | null
    createdFrom: Date | null
    createdTo: Date | null
    finishedFrom: Date | null
    finishedTo: Date | null
    contractPriceFrom: number | null
    contractPriceTo: number | null
    startPriceFrom: number | null
    startPriceTo: number | null
    contractRegNumber: string | null
    pageSize: number
    sortDescending: boolean
    sortBy: string
    currentPage: number
};

export type SessionsStore = {
    isFilterOpened: boolean
    page: PageOfSessions
    selectedIds: string[]
};
export const onSelectItem = createEvent<string>();
export const onPublishSelected = createEffect({
    name: "onPublishSelected",
    handler: async (payload: { ids: string[] }) => {
        const a = await Promise.all(payload.ids
            .map(async sessionId => ({ sessionId, session: await SessionsController.publishSession(sessionId) })));
        return a;
    }
});

export const onDeleteSelected = createEffect({
    name: "onDeleteSelected",
    handler: async (payload: { ids: string[] }) => {
        const a = await Promise.all(payload.ids
            .map(async sessionId => ({ sessionId, session: await SessionsController.deleteSession(sessionId) })));
        return a;
    }
});
export const goToSessionCard = createEvent<string>();
export const goToJointSessionCard = createEvent<string>();
export const goToCustomerPage = createEvent<string>();

export const toggleFilterOpened = createEvent();
export const resetFilters = createEffect({
    name: "resetFilters",
    handler: () => { }
});

export const loadSessionsPage = createEffect({
    name: "loadSessionsPage",
    handler: async (payload: { page: number }) => {
        const filtersState = $filters.getState();
        return await SessionsController.get({
            from: payload.page * filtersState.pageSize,
            count: filtersState.pageSize,
            sortDescending: !!filtersState.sortDescending,
            sortBy: filtersState.sortBy || undefined
        });
    }
});

export const changeSubject = createEvent<string>();
export const changeRegNumber = createEvent<number>();
export const changeContractRegNumber = createEvent<string>();
export const changeStatus = createEvent<string | null>();
export const changeLaw = createEvent<string | null>();
export const changeCreatedFrom = createEvent<Date>();
export const changeCreatedTo = createEvent<Date>();
export const changeFinishedFrom = createEvent<Date>();
export const changeFinishedTo = createEvent<Date>();
export const changeContractPriceFrom = createEvent<number>();
export const changeContractPriceTo = createEvent<number>();
export const changeStartPriceFrom = createEvent<number>();
export const changeStartPriceTo = createEvent<number>();
export const changePageSize = createEffect({
    name: "changePageSize",
    handler: (payload: { pageSize: number }) => {
        return payload.pageSize;
    }
});
export const changePageNumber = createEvent<number>();

const defaultFilters = {
    subject: null,
    regNumber: null,
    law: null,
    status: null,
    createdFrom: null,
    createdTo: null,
    finishedFrom: null,
    finishedTo: null,
    contractPriceFrom: null,
    contractPriceTo: null,
    startPriceFrom: null,
    startPriceTo: null,
    contractRegNumber: null,
    pageSize: 10,
    sortDescending: true,
    sortBy: 'createdFrom',
    currentPage: 0
};

const createFilterStore = () =>
    createStore<SessionsFilterStore>(defaultFilters)
        .on(changeSubject, (state, subject) => {
            timeout();
            return ({ ...state, subject });
        })
        .on(changeRegNumber, (state, regNumber) => {
            timeout();
            return ({ ...state, regNumber });
        })
        .on(changeContractRegNumber, (state, contractRegNumber) => {
            timeout();
            return ({ ...state, contractRegNumber });
        })
        .on(changeStatus, (state, status) => {
            timeout();
            return ({ ...state, status });
        })
        .on(changeLaw, (state, law) => {
            timeout();
            return ({ ...state, law });
        })
        .on(changeCreatedFrom, (state, createdFrom) => {
            timeout();
            return ({ ...state, createdFrom });
        })
        .on(changeCreatedTo, (state, createdTo) => {
            timeout();
            return ({ ...state, createdTo });
        })
        .on(changeFinishedFrom, (state, finishedFrom) => {
            timeout();
            return ({ ...state, finishedFrom });
        })
        .on(changeFinishedTo, (state, finishedTo) => {
            timeout();
            return ({ ...state, finishedTo });
        })
        .on(changeContractPriceFrom, (state, contractPriceFrom) => {
            timeout();
            return ({ ...state, contractPriceFrom });
        })
        .on(changeContractPriceTo, (state, contractPriceTo) => {
            timeout();
            return ({ ...state, contractPriceTo });
        })
        .on(changeStartPriceFrom, (state, startPriceFrom) => {
            timeout();
            return ({ ...state, startPriceFrom });
        })
        .on(changeStartPriceTo, (state, startPriceTo) => {
            timeout();
            return ({ ...state, startPriceTo });
        })
        .on(changePageSize, (state, { pageSize }) => {
            return { ...state, pageSize };
        })
        .on(changePageSize.done, (state) => {
            searchSessions({ page: 0 });
            return { ...state };
        })
        .on(changePageNumber, (state, page) => {
            return { ...state, currentPage: page };
        })
        .on(resetFilters.done, state => ({ ...state, ...defaultFilters }))
        .on(resetFilters, () => {
            searchSessions();
        });

export const $filters = createFilterStore();

export const searchSessions = createEffect({
    name: "searchSessions",
    handler: async (payload?: { page: number }) => {
        const filtersState = $filters.getState();
        const filters: IFilterObject = {};
        const page = payload?.page ?? filtersState.currentPage;
        changePageNumber(page);

        if (filtersState.subject) {
            filters["subject"] = [{
                type: FilterValueType.STRING,
                conditionType: FilterConditionType.CONTAINS,
                string: filtersState.subject
            }];
        }

        if (filtersState.regNumber) {
            filters["regNumber"] = [{
                type: FilterValueType.LONGINT,
                conditionType: FilterConditionType.EQUAL,
                longint: filtersState.regNumber
            }];
        }

        if (filtersState.contractRegNumber) {
            filters["contractRegNumber"] = [{
                type: FilterValueType.STRING,
                conditionType: FilterConditionType.CONTAINS,
                string: filtersState.contractRegNumber
            }];
        }

        if (filtersState.law) {
            filters["law"] = [{
                type: FilterValueType.STRING,
                conditionType: FilterConditionType.EQUAL,
                string: filtersState.law
            }];
        }

        if (filtersState.createdFrom) {
            filters["createdFrom"] = [{
                type: FilterValueType.DATE,
                conditionType: FilterConditionType.GREATER_THAN_OR_EQUAL,
                date: filtersState.createdFrom
            }];
        }

        if (filtersState.createdTo) {
            filters["createdTo"] = [{
                type: FilterValueType.DATE,
                conditionType: FilterConditionType.LESS_THAN_OR_EQUAL,
                date: filtersState.createdTo
            }];
        }

        if (filtersState.finishedFrom) {
            filters["finishedFrom"] = [{
                type: FilterValueType.DATE,
                conditionType: FilterConditionType.GREATER_THAN_OR_EQUAL,
                date: filtersState.finishedFrom
            }];
        }

        if (filtersState.finishedTo) {
            filters["finishedTo"] = [{
                type: FilterValueType.DATE,
                conditionType: FilterConditionType.LESS_THAN_OR_EQUAL,
                date: filtersState.finishedTo
            }];
        }

        if (filtersState.contractPriceFrom) {
            filters["contractPrice"] = [{
                type: FilterValueType.DECIMAL,
                conditionType: FilterConditionType.GREATER_THAN,
                decimal: new Decimal(filtersState.contractPriceFrom)
            }];
        }

        if (filtersState.contractPriceTo) {
            filters["contractPrice"] = [{
                type: FilterValueType.DECIMAL,
                conditionType: FilterConditionType.LESS_THAN,
                decimal: new Decimal(filtersState.contractPriceTo)
            }];
        }
        if (filtersState.startPriceFrom) {
            filters["startPrice"] = [{
                type: FilterValueType.DECIMAL,
                conditionType: FilterConditionType.GREATER_THAN,
                decimal: new Decimal(filtersState.startPriceFrom)
            }];
        }

        if (filtersState.startPriceTo) {
            filters["startPrice"] = [{
                type: FilterValueType.DECIMAL,
                conditionType: FilterConditionType.LESS_THAN,
                decimal: new Decimal(filtersState.startPriceTo)
            }];
        }

        if (filtersState.status) {
            filters["status"] = [{
                type: FilterValueType.STRING,
                conditionType: FilterConditionType.EQUAL,
                string: filtersState.status
            }];
        }

        return await SessionsController.get({
            from: page * filtersState.pageSize,
            count: filtersState.pageSize,
            filters: filters,
            sortDescending: !!filtersState.sortDescending,
            sortBy: filtersState.sortBy || undefined
        });
    }
});

const createSessionsStore = () =>
    createStore<SessionsStore>({
        isFilterOpened: false,
        page: {
            totalCount: 0,
            from: 0,
            to: 0,
            items: []
        },
        selectedIds: []
    })
        .on(goToSessionCard, (state, id) => {
            router.replace(`/shop/sessions/${id}`);
            return { ...state };
        })
        .on(goToJointSessionCard, (state, id) => {
            router.replace(`/shop/joint-sessions/${id}`);
            return {...state};
        })
        .on(goToCustomerPage, (state, id) => {
            router.replace(`/reference-info/participants/customers/${id}`);
            return {...state};
        })
        .on(onSelectItem, (state, id) => {
            const index = state.selectedIds.indexOf(id);
            if (index === -1) {
                state.selectedIds.push(id);
            } else {
                state.selectedIds.splice(index, 1);
            }
            return { ...state, selectedIds: state.selectedIds };
        })
        .on(onPublishSelected.done, (state, { result }) => {
            const items = state.page.items;
            result.forEach(newSession => {
                const index = items.findIndex(session => session.id === newSession.sessionId);
                items[index] = newSession.session;
            });

            return { ...state, page: { ...state.page, items } };
        })
        .on(onDeleteSelected.done, state => {
            searchSessions();
            return { ...state };
        })
        .on(loadSessionsPage.done, (state, { result }) => ({ ...state, page: result, selectedIds: [] }))
        .on(searchSessions.done, (state, { result }) => ({ ...state, page: result, selectedIds: [] }))
        .on(toggleFilterOpened, state => ({ ...state, isFilterOpened: !state.isFilterOpened }));

export const $sessions = createSessionsStore();

const timeout = debounced(1500, () => { searchSessions({ page: 0 }) });
