export const colors = {
    blue:  {
        idle: {
            bg: "#3f8fca",
            border: "#3f8fca #3f8fca #246da2"
        },
        hover: {
            bg: "#4ea1df",
            border: "#4ea1df #4ea1df #246da2",
        },
        active: {
            bg: "#3f8fca",
            border: "#246da2 #3f8fca #3f8fca"
        },
        text: undefined
    },
    green: {
        idle: {
            bg: "#00b191",
            border: "#00b191 #00b191 #007e67"
        },
        hover: {
            bg: "#03bd9b",
            border: "#03bd9b #03bd9b #0d9c83",
        },
        active: {
            bg: "#00b191",
            border: "#007e67 #00b191 #00b191"
        },
        text: undefined
    },
    red: {
        idle: {
            bg: "#e84c3d",
            border: "#e84c3d #e84c3d #7e1b00"
        },
        hover: {
            bg: "#f66456",
            border: "#f66456 #f66456 #a52f0f",
        },
        active: {
            bg: "#e84c3d",
            border: "#7e1b00 #e84c3d #e84c3d"
        },
        text: undefined
    },
    yellow: {
        idle: {
            bg: "#e8cb3b",
            border: "#e8cb3b #e8cb3b #806a00"
        },
        hover: {
            bg: "#f6db55",
            border: "#f6db55 #f6db55 #a38b0f",
        },
        active: {
            bg: "#e8cb3b",
            border: "#806a00 #e8cb3b #e8cb3b"
        },
        text: undefined
    },
    orange: {
        idle: {
            bg: "#e8913b",
            border: "#e8913b #e8913b #804000"
        },
        hover: {
            bg: "#f6a655",
            border: "#f6a655 #f6a655 #a3590f",
        },
        active: {
            bg: "#e8913b",
            border: "#804000 #e8913b #e8913b"
        },
        text: undefined
    },
    white: {
        idle: {
            bg: "white",
            border: "#c4cbd4",
        },
        hover: {
            bg: "#eaedf0",
            border: "#c4cbd4",
        },
        active: {
            bg: "#d2d7df",
            border: "#a5adb9 #d2d7df #d2d7df"
        },
        text: "#999"
    },
};

export const glyphIcons = {
    gOk: { char: "\ue013", font: "Glyphicons Halflings", size: "14px", margin: "0" },
    gCross: { char: "\ue014", font: "Glyphicons Halflings", size: "14px", margin: "0" },
    gPencil: { char: "\u270f", font: "Glyphicons Halflings", size: "14px", margin: "0" },
    gMail: { char: "\u2709", font: "Glyphicons Halflings", size: "14px", margin: "0" },
    gUser: { char: "\ue828", font: "EaistFont", size: "18px", margin: "-2px" },
};

import defaultAsset from "@/assets/buttons.png";
import load from "@/assets/preloader.svg";
import lock from "@/assets/locked-padlock.svg";
import unlock from "@/assets/unlocked-padlock.svg";

export const assetIcons = {
    aAdd: { pos: "-4px -387px", asset: defaultAsset, size: undefined },
    aFilter: { pos: "-5px -2px", asset: defaultAsset, size: undefined },
    aClose: { pos: "-4px -99px", asset: defaultAsset, size: undefined },
    aSave: { pos: "-4px -35px", asset: defaultAsset, size: undefined },
    aSaveThin: { pos: "-4px -67px", asset: defaultAsset, size: undefined },
    aEdit: { pos: "-4px -419px", asset: defaultAsset, size: undefined },
    aDelete: { pos: "-4px -131px", asset: defaultAsset, size: undefined },
    aClone: { pos: "-4px -450px", asset: defaultAsset, size: undefined },
    aPrint: { pos: "-4px -195px", asset: defaultAsset, size: undefined },
    aReject: { pos: "-5px -355px", asset: defaultAsset, size: undefined },
    aApprove: { pos: "-4px -259px", asset: defaultAsset, size: undefined },
    aLoad: { pos: "50% 50%", asset: load, size: "80%" },
    aLock: { pos: "50% 50%", asset: lock, size: "60%" },
    aUnlock: { pos: "-4px -387px", asset: unlock, size: "60%" },
};
