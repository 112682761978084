import Decimal from "decimal.js";
import { AutoDto, AutoModel, MDMap } from "@/models/parsing";
import { DtoSupplyStageAbsolutePeriod, SupplyStageAbsolutePeriod } from "@/models/Monopoly/SupplyStageAbsolutePeriod";

interface MonopolySupplyStageMD {
    volume: Decimal
    absolutePeriod: MDMap<SupplyStageAbsolutePeriod, DtoSupplyStageAbsolutePeriod>
}

export type MonopolySupplyStage = AutoModel<MonopolySupplyStageMD>;
export type DtoMonopolySupplyStage = AutoDto<MonopolySupplyStageMD>;

export const MonopolySupplyStage = {
    fromDto(dtoMonopolySupplyStage: DtoMonopolySupplyStage): MonopolySupplyStage {
        return {
            volume: new Decimal(dtoMonopolySupplyStage.volume),
            absolutePeriod: SupplyStageAbsolutePeriod.fromDto(dtoMonopolySupplyStage.absolutePeriod)
        };
    },
    toDto(monopolySupplyStage: MonopolySupplyStage): DtoMonopolySupplyStage {
        return {
            volume: monopolySupplyStage.volume.toString(),
            absolutePeriod: SupplyStageAbsolutePeriod.toDto(monopolySupplyStage.absolutePeriod)
        };
    }
};
