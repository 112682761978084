import {getAvailablePages} from "@/api/Pagination";
import {HStack} from "@/components/layouts";
import styles from "./Pager.module.css";
import {j} from "@/reactUtils";
import React from "react";
import {RemoteDataset} from "@/components/table/RemoteDataset";

const lastPage = (totalCount: number, pageSize: number) =>
    ((totalCount / pageSize) | 0) - (totalCount % pageSize === 0 ? 1 : 0);

export const Pager = <T, F extends object = {}>(x: {remoteDataset: RemoteDataset<T, F>}) => {
    const {currentPage, total, pageSize} = x.remoteDataset;
    const itemCount = x.remoteDataset.dataset.length;

    const note =
        total > 0 && itemCount > 0
            ? `Записи ${ currentPage * pageSize + 1 } - ${ currentPage * pageSize + 1 + itemCount - 1 } из ${ total }`
            : `Записи 0 - 0 из 0`;

    const pages = getAvailablePages({totalCount: total, from: currentPage * pageSize, to: currentPage * pageSize + itemCount, items: x.remoteDataset.dataset}, pageSize);

    return <HStack alignItems="center">
        <HStack innerClassName={styles.pagerPanel}>
            <div className={j(styles.pagerLink, styles.toStart)}
                 onClick={() => x.remoteDataset.setCurrentPage(0)}/>
            <div className={j(styles.pagerLink, styles.toPrev)}
                 onClick={() => x.remoteDataset.setCurrentPage(currentPage - 1)}/>
            {
                pages.map(page => <div className={j(styles.pagerLink, { [styles.active]: currentPage === page })}
                                       key={page}
                                       onClick={() => x.remoteDataset.setCurrentPage(page)}>
                    {page + 1}
                </div>)
            }
            <div className={j(styles.pagerLink, styles.toNext)}
                 onClick={() => {
                     const last = lastPage(total, pageSize);
                     if (currentPage < last)
                         x.remoteDataset.setCurrentPage(currentPage + 1);
                 }}/>
            <div className={j(styles.pagerLink, styles.toEnd)}
                 onClick={() => x.remoteDataset.setCurrentPage(lastPage(total, pageSize))}/>
        </HStack>
        <div className={styles.pageInfo}>{note}</div>
    </HStack>;
};