import React, {CSSProperties, ReactNode} from "react";
import * as styles from "./SelectionTable.module.css";
import {CellOverride, Column, Table, TableProps} from "@/components/table/Table";
import {CheckBox, RadioBase} from "@/components/primitive";

type Selector = "check" | "radio";
type SelectorPosition = "atLeft" | "atRight" | number | "hidden";

interface Selection<T> {
    selector?: Selector
    selectorHeader?: ReactNode
    selectorPosition?: SelectorPosition
    selectedClassName?: string
    selectedStyle?: CSSProperties
    comparer?: (a: T, b: T) => boolean
}

interface SingleStrictSelection<T> extends Selection<T> {
    mode: "single"
    disallowDeselection: true
    selected?: T
    onChange?: (selection: T) => void
}

interface SingleNonStrictSelection<T> extends Selection<T> {
    mode: "single"
    disallowDeselection?: false
    selected?: T
    onChange?: (selection: T | undefined) => void
}

interface MultiSelection<T> extends Selection<T> {
    mode: "multi"
    selected: T[]
    onChange?: (selection: T[]) => void
}

export type AnySelection<T> = MultiSelection<T> | SingleStrictSelection<T> | SingleNonStrictSelection<T>;
export type SelectionTableProps<T, C> = TableProps<T, C> & AnySelection<T>;

const compare = <T,>(comparer: ((a: T, b: T) => boolean) | undefined, a: T | undefined, b: T) =>
    a !== undefined && (comparer?.(a, b) ?? a === b);

const isSelected = <T, C>(x: SelectionTableProps<T, C>, v: T) =>
    x.mode === "single"
        ? compare(x.comparer, x.selected, v)
        : x.selected.findIndex(i => compare(x.comparer, i, v)) >= 0;

const change = <T, C>(x: SelectionTableProps<T, C>, v: T) => {
    if (x.mode === "single") {
        const selected = isSelected(x, v);
        if (x.disallowDeselection) {
            if (!selected) {
                x.onChange?.(v);
            }
        } else {
            x.onChange?.(selected ? undefined : v);
        }
    } else {
        x.onChange?.(
            isSelected(x, v)
                ? x.selected.filter(i => !compare(x.comparer, i, v))
                : [...x.selected.filter(i => !compare(x.comparer, i, v)), v]);
    }
};

const Selector: React.FC<{ selector: Selector; selected: boolean }> = x => {
    switch (x.selector) {
        case "radio": return <RadioBase value={x.selected} group={undefined!} />;
        case "check": return <CheckBox value={x.selected} />;
    }
};

const makeSelectorColumn = <T, C>(x: SelectionTableProps<T, C>): Column<T, C> => Table.Column<T, C>(
    x.selectorHeader ?? <></>,
    c => <Selector selector={x.selector ?? "check"} selected={isSelected(x, c.item)}/>, {
        width: "auto"
    });

export const SelectionTable = <T, C = undefined>(x: SelectionTableProps<T, C>) => {
    if (x.mode === "multi") {
        const filtered = x.selected.filter(s => x.dataset.includes(s));
        if (filtered.length !== x.selected.length) {
            x.onChange?.(filtered);
        }
    }

    const selectionOverride: CellOverride<T, C> = (i) => {
        if (isSelected(x, i)) return {
            cellClassName: styles.selectedRow + " " + (x.selectedClassName ?? ""),
            cellStyle: x.selectedStyle
        };
    };
    const clickOverride: CellOverride<T, C> = (i) => {
        return {
            cellOnClick: () => change(x, i)
        };
    };

    let columns: Column<T, C>[];

    switch (x.selectorPosition) {
        case undefined:
        case "atLeft":
            columns = [makeSelectorColumn<T, C>(x), ...x.columns];
            break;
        case "atRight":
            columns = [...x.columns, makeSelectorColumn<T, C>(x)];
            break;
        case "hidden":
            columns = x.columns;
            break;
        default:
            columns = [...x.columns];
            columns.splice(x.selectorPosition, 0, makeSelectorColumn<T, C>(x));
            break;
    }
    return <Table {...x} cellOverrides={[...x.cellOverrides ?? [], selectionOverride, clickOverride]} columns={columns}/>;
};
