import React from "react";
import {Field, Fields} from "@/components/form/Field";
import {Label, Radio, RadioGroup} from "@/components/primitive";
import {Card} from "@/components/Card";
import {Table} from "@/components/table";
import router from "@/router";
import {HStack} from "@/components/layouts";
import {SubjectClassMD} from "@/models/SubjectClass/SubjectClass";

export const SubjectClassInfoSection: React.FC<{ info: SubjectClassMD }> = x => {
    type Template = { [key: string]: string };
    const tables: Template[] = [
        {
            name: "Поставка медицинских товаров (Приказ Минздрава № 724н)",
            status: "Утверждён",
            kpgz: "01.02.10.30.02.01 - КОМПЛЕКТЫ УСТРОЙСТВ ДЛЯ УДАЛЕНИЯ ЛЕЙКОЦИТОВ И ПОЛУЧЕНИЯ БЕЗЛЕЙКОЦИТНЫХ КОМПОНЕНТОВ КОНСЕРВИРОВАННОЙ ДОНОРСКОЙ КРОВИ"
        }
    ];

    const parentKPGZ = x.info.parents.sort((a, b) => Math.sign(b.code.length - a.code.length))[0];

    return <Card>
        <Fields orientation="horizontal">
            <Field title="Идентификатор">
                <Label>
                    {x.info.id}
                </Label>
            </Field>
            {parentKPGZ && <Field title="Вышестоящий КПГЗ">
                <Label onClick={() => router.push(`/reference-info/dict/subject-classes/${parentKPGZ.id}`)} style={{ textDecoration: "underline", cursor: "pointer" }}>
                    {parentKPGZ.code}
                </Label>
            </Field>}
            <Field title="Код КПГЗ">
                <Label>
                    {x.info.code}
                </Label>
            </Field>
            <Field title="Наименование КПГЗ">
                <Label>
                    {x.info.description}
                </Label>
            </Field>
            <Field title="Описание КПГЗ">
                <Label>
                    Загружена из Гормедтехника
                </Label>
            </Field>
            {x.info?.okpd && <>
                <Field title="Статус">
                    <Label>
                        {x.info.okpd.innovationProduct ? "Утверждён" : "Проект"}
                    </Label>
                </Field>
                <Field title="Код ОКПД">
                    <Label onClick={() => router.push("/")} style={{ textDecoration: "underline", cursor: "pointer" }}>
                        {x.info.okpd.code}
                    </Label>
                </Field>
            </>}
            <Field title="Код ОКПД 2">
                <Label  onClick={() => router.push("/")} style={{ textDecoration: "underline", cursor: "pointer" }}>
                    {x.info.okpd2.code}
                </Label>
            </Field>
            <Field title="Код номенклатуры МЭР">
                <Label>

                </Label>
            </Field>
            <Field title="Код позиции КТРУ">
                <Label>

                </Label>
            </Field>
            <Field title="Шаблон контракта">
                <Table<Template> dataset={tables} columns={[
                    Table.AutoColumn("Наименование", x =>
                        <Label preset="readonlyValue" onClick={() => router.push("/")} style={{ textDecoration: "underline", cursor: "pointer" }}>
                            {x.item.name}
                        </Label>),
                    Table.AutoColumn("Статус", x =>
                        <Label preset="readonlyValue">
                            {x.item.status}
                        </Label>),
                    Table.AutoColumn("КПГЗ", x =>
                        <Label preset="readonlyValue">
                            {x.item.kpgz}
                        </Label>)
                ]} />
            </Field>
            <Field title="Шаблон ТЗ">
                <Table<Template> dataset={tables} columns={[
                    Table.AutoColumn("Наименование", x =>
                        <Label preset="readonlyValue" onClick={() => router.push("/")} style={{ textDecoration: "underline", cursor: "pointer" }}>
                            {x.item.name}
                        </Label>),
                    Table.AutoColumn("Статус", x =>
                        <Label preset="readonlyValue">
                            {x.item.status}
                        </Label>),
                    Table.AutoColumn("КПГЗ", x =>
                        <Label preset="readonlyValue">
                            {x.item.kpgz}
                        </Label>)
                ]} />
            </Field>
            <Field title="Шаблон формы 2">
                <Label>

                </Label>
            </Field>
            <Field title="Стандартизованный критерий оценки">
                <Label>
                    {x.info.standardProduct ? "Да" : "Нет"}
                </Label>
            </Field>
            <Field title="Шаблоны формы обоснования НМЦ">
                <Label>

                </Label>
            </Field>
            <Field title="Необходимость привязки к объекту городского хозяйства">
                <HStack spacing="20px">
                    <RadioGroup>
                        <Radio value={true} requiredValue={true} disabled>Да</Radio>
                        <Radio value={false} requiredValue={true} disabled>Нет</Radio>
                    </RadioGroup>
                </HStack>
            </Field>
            <Field title="Признак стандартизированной позиции">
                <HStack spacing="20px">
                    <RadioGroup>
                        <Radio value={x.info.standardProduct} requiredValue={true} disabled>Да</Radio>
                        <Radio value={x.info.standardProduct} requiredValue={true} disabled>Нет</Radio>
                    </RadioGroup>
                </HStack>
            </Field>
            <Field title="Есть ПЦП">
                <HStack spacing="20px">
                    <RadioGroup>
                        <Radio value={true} requiredValue={true} disabled>Да</Radio>
                        <Radio value={false} requiredValue={true} disabled>Нет</Radio>
                    </RadioGroup>
                </HStack>
            </Field>
            <Field title="Требуется проведение государственной экспертизы">
                <HStack spacing="20px">
                    <RadioGroup>
                        <Radio value={x.info.requireExpertise} requiredValue={true} disabled>Да</Radio>
                        <Radio value={x.info.requireExpertise} requiredValue={false} disabled>Нет</Radio>
                    </RadioGroup>
                </HStack>
            </Field>
            <Field title="Относится к капитальному и текущему ремонту, благоустройству, устройству пешеходных зон, сносу и т.п.">
                <HStack spacing="20px">
                    <RadioGroup>
                        <Radio value={true} requiredValue={true} disabled>Да</Radio>
                        <Radio value={false} requiredValue={true} disabled>Нет</Radio>
                    </RadioGroup>
                </HStack>
            </Field>
            <Field title="Проект">
                <HStack spacing="20px">
                    <RadioGroup>
                        <Radio value={true} requiredValue={true} disabled>Да</Radio>
                        <Radio value={false} requiredValue={true} disabled>Нет</Radio>
                    </RadioGroup>
                </HStack>
            </Field>
            <Field title="Признак исключения применения позиции">
                <Label>
                    Нет
                </Label>
            </Field>
            <Field title="Примечание">
                <HStack spacing="20px">
                    <RadioGroup>
                        <Radio value={true} requiredValue={true} disabled>Да</Radio>
                        <Radio value={false} requiredValue={true} disabled>Нет</Radio>
                    </RadioGroup>
                </HStack>
            </Field>
        </Fields>
    </Card>;
};
