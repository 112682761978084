<template>
    <div :class="['expander', { flat, fullwidth }]">
        <div class="expander-toggle" @click="isOpen = !isOpen">
            <div class="expander-icon" :class="[isOpen ? 'open' : 'closed']"></div>
            <div class="expander-text">
                <slot name="header">
                    {{ isOpen ? openTitle : closedTitle }}
                </slot>
            </div>
        </div>
        <slot v-if="isOpen" />
    </div>
</template>

<script>
export default {
    name: "Expander",
    data() {
        return { isOpen: false };
    },
    props: {
        closedTitle: String,
        openTitle: String,
        flat: { type: Boolean, default: false },
        fullwidth: { type: Boolean, default: false },
    },
};
</script>

<style scoped>
.expander {
    display: flex;
    flex-direction: column;
    background: white;

    border-radius: 3px;
    border-width: 0 1px 1px;
    border-style: solid;
    border-color: transparent #ccd1de #bbc1d0;

    padding: 20px 20px 20px 38px;
}

.flat {
    border: none;
}

.fullwidth {
    width: calc(100% - 58px);
}

.fullwidth .expander-toggle {
    width: 100%;
}

.fullwidth .expander-text {
    flex-grow: 1;
}

.expander-toggle {
    display: flex;
    align-items: center;

    cursor: pointer;
    user-select: none;
}

.expander-icon {
    width: 12px;
    height: 12px;
    background: url("../assets/list_markers.png") no-repeat;
}

.expander-icon.open {
    background-position-y: -12px;
}

.expander-text {
    font-size: 12px;
    font-weight: 500;

    margin-left: 10px;
    width: 100%;
}
</style>
