import React from "react";
import { $contracts, changeLaw, changeLotId, changeVolume, createContract, getAllLaws, loadLots223, loadLots44 } from "./store";
import { Checkbox, PrimaryButton } from "@/components/eshop";
import { Select } from "@/components/eshop/Select/Select";
import { useStore } from "effector-react";
import { useEffect } from "react";
import styles from "../CreateSession/CreateSession.module.css";
import { Modal } from "@/components/eshop/Modal";
import { FormGroup, FormControlEshopInput } from "@/components/eshop/FormControlEshopInput";
import { TooltipIcon } from "@/components/TooltipIcon";
import router from "@/router";
import { firstMaybe } from "@/arrayUtils";
import { formatNumber } from "@/NumberFormatting";
import {Card} from "@/components/eshop/Card/Card";
import {Section} from "@/components/eshop/Section/Section";

export const ContractCreate = (props: { show: boolean; closeModal: () => void }) => {
    const { show, closeModal } = props;
    const store = useStore($contracts);

    const grouppedPurchases = store.purchases;
    const firstPurchase = firstMaybe(grouppedPurchases);

    useEffect(() => {
        loadLots44({});
        loadLots223({});
        return;
    }, []);

    const lawOptions = [{ name: "44-ФЗ", value: "F44" }, { name: "223-ФЗ", value: "F223" }];

    const controls = () => {
        const create = () => {
            if (!grouppedPurchases) return;
            grouppedPurchases.map(async group => {
                const response = await createContract({
                    directPurchases: group.purchases.map(x => ({ directPurchaseId: x.purchase.id, amount: x.volume })),
                    lotId: group.lotId!,
                    law: group.law,
                    supplierId: group.purchases[0].purchase.participant.id || 0
                });
                if (response) {
                    const routeData = router.resolve({ path: `/contracts/${response.id}` });
                    window.open(routeData.href, '_blank');
                }
            });
            closeModal();
        };

        return <>
            <PrimaryButton filled disabled={grouppedPurchases.some(p => !p.lotId)} onClick={create}>Создать контракт</PrimaryButton>
        </>;
    };

    const HeaderWithTooltip = (props: { children: React.ReactNode }) =>
        <th className={styles.cell}>
            <div style={{ display: 'flex' }}>
                {props.children}
                <TooltipIcon />
            </div>
        </th>;

    return show
        ? <Modal contentOverflow onClose={() => closeModal()} controls={controls()} title="Создание контракта">
            <div className={styles.createContractModalContainer}>
                {grouppedPurchases.map(group => {
                    const groupTotalPrice = group.purchases.reduce((acc, purchase) => {
                        const priceWithNds = purchase.purchase.priceWithNds || 0;
                        const totalPrice = (purchase.volume || 0) * priceWithNds;
                        return acc + totalPrice;
                    }, 0);

                    const laws = getAllLaws(group.purchases.map(p => p.purchase));

                    const options = lawOptions.filter(o => laws.includes(o.value));

                    return <div key={group.key} className={styles.createContractModalItemContainer}>
                        <Section title={<>Общие сведения<TooltipIcon /></>} required>
                            <Card padded gap={30}>
                                <div className={styles.paper}>
                                    <FormGroup label="Закон-основание" withTooltip boldLabel>
                                        <Select disabled={options.length === 1}
                                            value={group.law}
                                            onSelect={v => v && changeLaw({ groupKey: group.key, law: v })}
                                            options={options}
                                            fullWidth />
                                    </FormGroup>
                                </div>
                                <div>
                                    <FormGroup label="Код КПГЗ" withTooltip boldLabel>
                                        <div>{group.purchases[0].purchase.spgz.subjectClass.code.slice(0, 5)}</div>
                                    </FormGroup>
                                </div>
                                <div>
                                    <FormGroup label="Общая сумма, руб" withTooltip boldLabel>
                                        <div>{formatNumber(groupTotalPrice)} руб</div>
                                    </FormGroup>
                                </div>
                                <div className={styles.participant}>
                                    <FormGroup label="Поставщик" withTooltip boldLabel>
                                        <div>{group.purchases[0].purchase.participant.shortName}</div>
                                    </FormGroup>
                                </div>
                            </Card>
                        </Section>
                        <Section title={<>Лот<TooltipIcon /></>} required>
                            <div className={styles.controlsWrapper}>
                                <div className={styles.buttonsContainer}>
                                    <div className={`${styles.iconControl} ${styles.filter}`} title="Показать/скрыть фильтр" />
                                    <div className={`${styles.iconControl} ${styles.refresh}`} title="Сбросить параметры поиска" />
                                </div>
                            </div>
                            <table className={styles.cardTable}>
                                <thead>
                                    <tr className={styles.tableRow}>
                                        <HeaderWithTooltip>Реестр. № лота</HeaderWithTooltip>
                                        <HeaderWithTooltip>Год</HeaderWithTooltip>
                                        <HeaderWithTooltip>Наименование лота</HeaderWithTooltip>
                                        <HeaderWithTooltip>Основание заключения</HeaderWithTooltip>
                                        <HeaderWithTooltip>Остаток финансирования</HeaderWithTooltip>
                                    </tr>
                                </thead>
                                <tbody>
                                    {(group.law === 'F44' ? store.lotsPage44 : store.lotsPage223).items.map(l => <tr onClick={() => changeLotId({ groupKey: group.key, lotId: l.id })} key={l.regNumber} className={styles.tableRow}>
                                        <td className={`${styles.cell} ${styles.cellBody} ${l.id === group.lotId ? styles.cellSelected : ''}`}>{l.regNumber}</td>
                                        <td className={`${styles.cell} ${styles.cellBody} ${l.id === group.lotId ? styles.cellSelected : ''}`}>{l.basicInfo.contractConclusionYear}</td>
                                        <td className={`${styles.cell} ${styles.cellBody} ${l.id === group.lotId ? styles.cellSelected : ''}`}>{l.basicInfo.subject}</td>
                                        <td className={`${styles.cell} ${styles.cellBody} ${l.id === group.lotId ? styles.cellSelected : ''}`}>{l.singleProviderReason?.name}</td>
                                        <td className={`${styles.cell} ${styles.cellBody} ${l.id === group.lotId ? styles.cellSelected : ''}`}>
                                            {formatNumber(l.plannedPayments?.reduce((a, b) => a + parseFloat(b.amount), 0) ?? 0)}
                                        </td>
                                    </tr>)}
                                </tbody>
                            </table>
                        </Section>
                        <Section title={<>Спецификации<TooltipIcon /></>} required>
                            <table className={styles.cardTable}>
                                <thead>
                                    <tr className={styles.tableRow}>
                                        <th className={styles.cell}></th>
                                        <HeaderWithTooltip>Наименование</HeaderWithTooltip>
                                        <HeaderWithTooltip>Цена</HeaderWithTooltip>
                                        <HeaderWithTooltip>Количество</HeaderWithTooltip>
                                        <HeaderWithTooltip>Ед.измерения</HeaderWithTooltip>
                                        <HeaderWithTooltip>Сумма, руб</HeaderWithTooltip>
                                    </tr>
                                </thead>
                                <tbody>
                                    {group.purchases.map(purchase => {
                                        const priceWithNds = purchase.purchase.priceWithNds || 0;
                                        return <tr key={purchase.purchase.id} className={styles.tableRow}>
                                            <td className={`${styles.cell} ${styles.cellBody}`}>
                                                <Checkbox  />
                                            </td>
                                            <td className={`${styles.cell} ${styles.cellBody}`}>{purchase.purchase.name}</td>
                                            <td className={`${styles.cell} ${styles.cellBody}`}>
                                                <FormControlEshopInput
                                                    disabled
                                                    value={priceWithNds || undefined}
                                                    small
                                                    onChange={() => { }} placeholder="Введите значение" />
                                            </td>
                                            <td className={`${styles.cell} ${styles.cellBody}`}>
                                                <FormControlEshopInput value={purchase.volume}
                                                    small
                                                    onChange={e => changeVolume({
                                                        purchaseId: purchase.purchase.id,
                                                        volume: Number(e.target.value.replace(RegExp("[^0-9.]"), ""))
                                                    })} placeholder="Введите значение" /></td>
                                            <td className={`${styles.cell} ${styles.cellBody}`}>{purchase?.purchase.spgz.measurementUnits[0]?.name}</td>
                                            <td className={`${styles.cell} ${styles.cellBody}`}>{formatNumber(priceWithNds * purchase.volume)} руб.</td>
                                        </tr>;
                                    })}
                                </tbody>
                            </table>
                        </Section>
                    </div>;
                })}
            </div>
        </Modal>
        : <div />;
};
