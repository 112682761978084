import { EnumStrings } from "./EnumStrings";

export const enum LotRequestStage {
    FIRST = "FIRST",
    SECOND = "SECOND",
    THIRD = "THIRD"
}

export const LotRequestStageNumberStrings: EnumStrings = {
    [LotRequestStage.FIRST]: "Первый",
    [LotRequestStage.SECOND]: "Второй",
    [LotRequestStage.THIRD]: "Третий"
};

export const LotRequestStageStrings: EnumStrings = {
    [LotRequestStage.FIRST]: "Первая часть заявки",
    [LotRequestStage.SECOND]: "Вторая часть заявки",
    [LotRequestStage.THIRD]: "Третья часть заявки"
};
