import {autoSV, createSVEvent, origEv} from "@/storeUtils";
import {createEffect, createEvent, createStore, Store} from "effector";
import {LotForm} from "@/views/ComposedLot/shared/store/index";
import {LotLegalAct, LotLegalActType} from "@/models/ComposedLots/documents/LotLegalAct";
import {getLegalActs, getNpaDocumentTypes} from "@/api/directory/documents";
import {IFilterObject} from "@/api/http";
import {replaceOrPush} from "@/arrayUtils";

export interface LotLegalActs {
    attachedActs: LotLegalAct[]
    listedActs: LotLegalAct[]
    types: LotLegalActType[]
}

export const setListedActs = createSVEvent<LotLegalActs>()("listedActs");
export const attachLegalAct = createEvent<LotLegalAct>("add act to lot");
export const removeLegalAct = createEvent<LotLegalAct>("remove act from lot");

export const getActTypes = createEffect({
    name: "getActTypes",
    handler: async (payload: {from: number; count: number; filters: IFilterObject}) => {
        return await getNpaDocumentTypes(payload.from, payload.count, payload.filters);
    }
});
export const listLegalActs = createEffect({
    name: "listLegalActs",
    handler: async (payload: {from: number; count: number; filters: IFilterObject}) => {
        return await getLegalActs(payload.from, payload.count, payload.filters);
    }
});

const replace = createEvent<LotLegalActs>("replace purchase per unit info");

export const LotLegalActsStore = {
    createStore: (): Store<LotLegalActs> => {
        return createStore<LotLegalActs>({
            attachedActs: [],
            listedActs: [],
            types: []
        })
            .on(replace, (_, l) => l)
            .on(setListedActs[origEv], autoSV)
            .on(attachLegalAct, (state, payload) => ({...state, attachedActs: replaceOrPush(state.attachedActs, state.attachedActs.find(a => a === payload), payload)}))
            .on(removeLegalAct, (state, payload) => ({...state, attachedActs: state.attachedActs.filter(x => x !== payload)}))
            .on(listLegalActs.done, (state, {result}) => ({...state, listedActs: result.items}))
            .on(getActTypes.done, (state, {result}) => ({...state, types: result.items}))
        ;

    },

    replace: replace,
    observeOnCombinedStore: (combinedStore: Store<LotForm>) => {}
};