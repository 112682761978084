import React from "react";
import styles from '../DragArea.module.css';
import { uploadLotDocuments } from "../store";

export const DragArea = () => {
    const handleExplorer = (e: React.ChangeEvent<HTMLInputElement>) => {
        e.preventDefault();
        e.stopPropagation();
        const files = e.target.files;
        if (files) {
            handleFile(files);
        }
    };

    const handleDrop = (e: React.DragEvent<HTMLDivElement>) => {
        e.preventDefault();
        e.stopPropagation();
        const files = e.dataTransfer.files;
        handleFile(files);
    };

    const handleFile = (files: FileList) => uploadLotDocuments(
        [...files].map(file => ({
            id: null,
            file: {
                name: file.name,
                length: file.size,
                comment: 'Загружен пользователем',
                createdAt: new Date().toISOString()
            },
            type: '-'
        }))
    );

    return <div className={`${styles.dragAreaContent}`}>
        <div onDragOver={e => e.preventDefault()} onDrop={handleDrop} className={`${styles.dragArea}`}>
            Перетащите сюда документы или
            <input
                accept='.htm, .html, .bmp, .jpeg, .jpg, .png, .gif, .txt, .log, .unl, .csv, .pdf, .doc, .docx, .xls, .xlsx, .xlsb, .ppt, .pptx, .mpp, .mppx, .7z, .zip, .tif, .tiff'
                multiple
                style={{ display: 'none' }}
                id='upload-file'
                type='file'
                onChange={handleExplorer}
            />
            <label htmlFor='upload-file' className={styles.linkButton} >выберите их в проводнике</label> <br />
            (Один файл до 10 Мб, общий вес файлов до 50 Мб. Максимум 3 файлов)
        </div>
        <span className={styles.dragAreaInfo}>
            Допустимые форматы: htm, html, bmp, jpeg, jpg, png, gif, txt, log, unl, csv, pdf, doc, docx, xls, xlsx, xlsb, ppt, pptx, mpp, mppx, 7z, zip, tif, tiff
        </span>
    </div>;
};
