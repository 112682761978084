import {LotContractType} from "@/models/enums";
import {VueClass} from "vue-class-component/lib/declarations";
import {Vue} from "vue/types/vue";
import {ContractType} from "@/models/ComposedLots";

interface Drafts {
    jointPurchaseAgreement: Record<string, DocumentTable>
    contract: Record<string, DocumentTable>
    spec: Record<string, DocumentTable>
    last: number
}

export type DocumentTable = Record<string, string>;

export interface DocumentModalProps {
    title: string
    table: DocumentTable
    component: VueClass<Vue>
}

const drafts: Drafts = JSON.parse(localStorage.getItem("local-doc-drafts") || "null") || {
    contract: {},
    spec: {},
    jointPurchaseAgreement: {},
    last: 0,
};

export const defaultDocumentationTables = {
    jointPurchaseAgreement: (subject: string, providerSelectionType: string): DocumentTable => ({
        subject: subject,
        providerSelectionType: providerSelectionType
    }),
    contract: (subject: string, providerSelectionType: string, contractType: ContractType | LotContractType | null): DocumentTable => {
        const contactTypeToString: Record<ContractType | LotContractType, string> = {
            GOODS_PROVISION: "оказать поставку товаров",
            SERVICE_PROVISION: "оказать услуги",
            WORK_EXECUTION: "выполнить работы",
            DO_SERVICES: "оказать услуги",
            WORK_COMPLETES: "выполнить работы",
            GOOD_PROVIDING: "оказать поставку товаров"
        };

        return {
            ida58405ca0e55a8dee61c53f9c6bf8735: "_______________",
            iddfabb5d397c024dc6a2042a9635e0f5f: "___________",
            idf71ff1725bdf7d2ced73de707c372861: "___________________",
            id2c4f738003bcd419f75272956333fca4: "_____________",
            id63345d44e155ea1da6231cc082a28054: `${contactTypeToString[contractType ?? "GOODS_PROVISION"]} _________________ `,
            id9f14c614750e14b51ef867c9f5205a7b: "с",
            id0dbdb40ea2012590b018260f14993011: '"__" _____201_ г. по "__"_________ 20__ г.',
            idf19cd84dfd6026a40487c09a800823f7: "0",
            id40d5b9b412d0db8e9a54cba643de5aaa: "0",
            id4d5b235a86044fc371134eab0b015656: "0",
            id48eca9b898da2852940921e74fb2bd17: "____________",
            id7b6d0344b58268c21c1cbaf33530587c: "«___» ________ 20__",
            id0249bb9a46a55b4102a657cf967ca598: "«___» _______ 201_",
            id8f14e45fceea167a5a36dedd4bea2543: "0",
            idd690b19258e89fd3d7f2d00e27ed84e9: "0",
            id6b193bf5d5437d4b1f19b18f65d8268b: 'по "____" ___ 20__ года включительно. ',
            id9b9825df4526069452053d87fc8bf77c: "0",
            id89beddab0d793fe4ae3f5e7c22c6ed19: "_______________",
            id364dd61cd5a25a33a9562f830437ef30: " ____________",
            id6c31b91dabb48322e687df76c01575e1: "__________________",
            idf57e1bf9eeea14a73d7704aaa78f41d1: "_________________________",
            idf986fbf4d8cbefb8e167b668d2e6b13f: "__________________________",
            id2a52f03f9c171a48e8b6f3b70995e00e: "____________",

            subject: subject,
            providerSelectionType: providerSelectionType
        };
    },
    spec: (subject: string) => ({
        subject: subject,
    }),
};
