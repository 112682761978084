import React from "react";
import {Column, Table} from "@/components/table";
import {CardModal} from "@/components/modals/CardModal";
import {ModalFC, showModal} from "@/EventBus";
import styles from "./Relation.module.css";

interface RelatedObject<T> {
    readonly name: string
    readonly item: T
}

export interface Relation<T> {
    readonly typeName: string
    readonly typeNamePlural: string
    readonly objects: RelatedObject<T>[]
    readonly columns: Column<T>[]
    readonly open: (item: T) => void
}

interface RelationProps<T> {
    readonly relation: Relation<T>
}

const RelationsModal = <T,>(x: { done: () => void; relation: Relation<T> }) => {
    return <CardModal title={x.relation.typeNamePlural} close={x.done} width="50%">
        <Table dataset={x.relation.objects.map(x => x.item)} columns={x.relation.columns}/>
    </CardModal>;
};

export const Relation = <T,>(props: RelationProps<T>) => {
    const showMultiItems = () => {
        if (props.relation.objects.length > 1) {
            showModal<ModalFC<{relation: Relation<T>}>>(RelationsModal, { relation: props.relation })
                .ignore;
        }
    };

    const openFirstItem = () => props.relation.open(props.relation.objects[0].item);

    return props.relation.objects.length ? <div className={styles.relationItem}>
        <div className={props.relation.objects.length > 1 ? styles.multiItem : styles.singleItem} onClick={showMultiItems}>
            {props.relation.typeName}
        </div>
        {
            props.relation.objects.length === 1 && <div className={styles.object} onClick={openFirstItem}>
                {props.relation.objects[0].name}
            </div>
        }
    </div> : null;
};