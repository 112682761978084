import { observer } from "mobx-react";
import React from "react";
import { Checkbox } from "@/components/eshop";
import styles from "@/components/eshop/ServicesTable.module.css";
import { EshopLink } from "@/components/eshop/EshopLink";
import { FormControlEshopInput } from "@/components/eshop/FormControlEshopInput";
import { PageOfPurchases, Purchase } from "@/api/DirectPurchases";
import { DatePicker } from "@/components/primitive";
import moment from "moment";
import { firstMaybe } from "@/arrayUtils";
import { onSelectItemService, ServicesStore } from "../store";
import {
    changePurchaseNumber, changePurchaseName, changePurchasePriceMin, changePurchasePriceMax,
    changePurchaseQuantityMin, changePurchaseQuantityMax, changePurchaseStartValidDate,
    changePurchaseEndValidDate, changePurchaseParticipantName
} from "../filtersStore";
import { NumberInput } from "@/components/eshop/NumberInput";
import { formatNumber } from "@/NumberFormatting";

type PurchaseTableProps = {
    page: PageOfPurchases
    store: ServicesStore
};

export const CreateSessionPurchasesTable = (props: PurchaseTableProps) => {
    const isSelected = (id: string) => props.store.selectedNewIds.indexOf(id) !== -1;
    const alreadyAdded = (id: string) => props.store.purchases.map(p => p.idForKey).includes(id);
    const { page } = props;

    return <div className={styles.tableWrapper}>
        <div className={styles.offersTable}>
            <TableHeader store={props.store} />
            <div className={styles.tableBodyWrap} id="tableContent">
                {page.items.map((r, index) => <PurchaseRow alreadyAdded={alreadyAdded(r.id)} checked={isSelected(r.id)} onSelect={onSelectItemService} key={index} item={r} />)}
            </div>
        </div>
    </div>;
};

const PurchaseRow = (props: { item: Purchase; alreadyAdded: boolean; checked: boolean; onSelect: (id: string) => void }) => {
    const { item } = props;

    return <ul className={styles.tableRow}>
        <li className={styles.controlContainer}>
            <Checkbox onChange={() => !props.alreadyAdded && props.onSelect(props.item.id)}
                value={props.checked || props.alreadyAdded} />
        </li>
        <li className={styles.tableCellContent}>
            <EshopLink>
                {item.offerNumber ? item.offerNumber : 'Не присвоен'}
            </EshopLink>
        </li>
        <li className={styles.tableCellContent}>
            <EshopLink>
                {item.name}
            </EshopLink>
        </li>
        <li className={styles.tableCellContent}>
            <EshopLink>
                {formatNumber(item.priceWithNds)}
            </EshopLink>
        </li>
        <li className={styles.tableCellContent}>
            <EshopLink>
                {`${item.quantityFrom} - ${item.quantityTo ? item.quantityTo : '∞'}`}
            </EshopLink>
        </li>
        <li className={styles.tableCellContent}>
            <EshopLink>
                {firstMaybe(item.spgz.measurementUnits)?.name || ""}
            </EshopLink>
        </li>
        <li className={styles.tableCellContent}>
            <EshopLink>
                {`${moment(item.startValidDate).format("DD.MM.YYYY")} - ${moment(item.endValidDate).format("DD.MM.YYYY")}`}
            </EshopLink>
        </li>
        <li className={styles.tableCellContent}>
            <EshopLink>
                {item.participant ? item.participant.shortName : ""}
            </EshopLink>
        </li>
    </ul>;
};

const TableHeader = observer((props: { store: ServicesStore }) => {
    const { purchaseStartValidDate, purchaseEndValidDate } = props.store;
    return <div className={styles.offersTableHeader} id="tableHeader">
        <ul className={styles.headerContainer}>
            <li className={styles.controlContainerHeader}>
            </li>
            <li className={styles.tableCellContent}>
                Реестр. №
            </li>
            <li className={styles.tableCellContent}>
                Название оферты
            </li>
            <li className={styles.tableCellContent}>
                <div className={styles.eshopSortButton}>
                    <div className={`${styles.arrowIcon} ${styles.arrowIconUp}`} />
                    <div className={`${styles.arrowIcon}`} />
                </div>
                Цена, руб.
            </li>
            <li className={styles.tableCellContent}>
                Количество
            </li>
            <li className={styles.tableCellContent}>
                Ед. изм.
            </li>
            <li className={styles.tableCellContent}>
                Время действия
            </li>
            <li className={styles.tableCellContent}>
                Поставщик
            </li>
        </ul>
        <ul className={styles.headerContainer}>
            <li className={styles.controlContainerHeader}>
            </li>
            <li className={styles.tableCellContent}>
                <FormControlEshopInput onChange={e => changePurchaseNumber(e.target.value)} placeholder="Введите значение" />
            </li>
            <li className={styles.tableCellContent}>
                <FormControlEshopInput onChange={e => changePurchaseName(e.target.value)} placeholder="Введите значение" />
            </li>
            <li className={styles.tableFilterCellContent}>
                <NumberInput
                    placeholder="от"
                    onChange={e => changePurchasePriceMin(e)} />
                <NumberInput
                    placeholder="до"
                    onChange={e => changePurchasePriceMax(e)} />
            </li>
            <li className={styles.tableFilterCellContent}>
                <FormControlEshopInput onChange={e => changePurchaseQuantityMin(parseFloat(e.target.value))} placeholder="от" />
                <FormControlEshopInput onChange={e => changePurchaseQuantityMax(parseFloat(e.target.value))} placeholder="до" />
            </li>
            <li className={styles.tableCellContent}>
            </li>
            <li className={styles.tableFilterCellContent}>
                <DatePicker placeholder="от" value={purchaseStartValidDate} onChange={e => changePurchaseStartValidDate(e)} />
                <DatePicker placeholder="до" value={purchaseEndValidDate} onChange={e => changePurchaseEndValidDate(e)} />
            </li>
            <li className={styles.tableCellContent}>
                <FormControlEshopInput onChange={e => changePurchaseParticipantName(e.target.value)} placeholder="Введите значение" />
            </li>
        </ul>
    </div>;
});
