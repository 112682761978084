// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, "._25roSYpgS3mLjL8S4SH6zk {\n    color: #3d8ecc;\n    cursor: pointer;\n    text-decoration: none;\n}\n\n\n.xJZR0bt7LLO4WxRoses8x {\n    display: flex;\n    justify-content: space-between;\n    margin-right: 5px;\n    margin-left: 5px;\n}\n\n._3FIxiKAlf-lKbUyjI810tH {\n    margin-left: 10px;\n    padding-left: 10px;\n    border-left: 1px solid #f5f5f5;\n    color: #999;\n}\n\n.ZpnSCluDjmuNqdYILYMU1 {\n    color: #4c4c4c;\n}\n\n._8craitaIHumeLPa6jJ5Qc{\n    font-size: 8px;\n    font-weight: 300;\n    text-transform: uppercase;\n    line-height: 20px;\n}\n\n._2Fon8Kfp-z8GBH0VqKx_75{\n    font-size: 24px;\n    line-height: 30px;\n    font-weight: normal;\n}\n\n._1fThjJlT1T5IMBYHNaLNIT {\n    display: block;\n    width: 20px;\n    height: 20px;\n    position: absolute;\n    left: -30px;\n    padding: 0 40px;\n    background-repeat: no-repeat;\n    background-image: url(https://eaist2-f.mos.ru/eshop/img/fire.6e54fff5cabc6aa04171.svg);\n    background-position: 17px;\n}", ""]);
// Exports
exports.locals = {
	"productOfferButton": "_25roSYpgS3mLjL8S4SH6zk",
	"OffersActions": "xJZR0bt7LLO4WxRoses8x",
	"priceBlock": "_3FIxiKAlf-lKbUyjI810tH",
	"averagePrice": "ZpnSCluDjmuNqdYILYMU1",
	"priceTitle": "_8craitaIHumeLPa6jJ5Qc",
	"price": "_2Fon8Kfp-z8GBH0VqKx_75",
	"isPopular": "_1fThjJlT1T5IMBYHNaLNIT"
};
module.exports = exports;
