import { ContractAdditionalConclusion, AdditionalConclusionExplanationType } from "@/models/Contracts/ContractAdditionalConclusion";
import { IDtoContractAdditionalConclusion } from "@/models/json/Contracts/IDtoContractAdditionalConclusion";
import { http } from "./http";
import { ContractDocument, ContractDocumentType, ContractExecutionProvision } from "@/models/Contracts";
import { IDtoContractDocument, IDtoContractDocumentType, IDtoContractExecutionProvision } from "@/models/json/Contracts";
import {LawType} from "@/models/enums";

export default class {
    public static async getFromContract(contractId: string): Promise<ContractAdditionalConclusion[]> {
        return ((await http.get(
            `/api/contractAdditionalConclusions/fromContract/${contractId}`,
            undefined,
        )) as IDtoContractAdditionalConclusion[]).map(ContractAdditionalConclusion.fromJson);
    }

    public static async create(
        contractId: string,
        src: ContractAdditionalConclusion,
    ): Promise<ContractAdditionalConclusion> {
        return ContractAdditionalConclusion.fromJson(
            await http.post(`/api/contractAdditionalConclusions/createFor/${contractId}`, src.toJson()),
        );
    }

    public static async update(obj: ContractAdditionalConclusion): Promise<ContractAdditionalConclusion> {
        return ContractAdditionalConclusion.fromJson(
            await http.put(`/api/contractAdditionalConclusions/${obj.id}`, obj.toJson()),
        );
    }

    public static async delete(obj: ContractAdditionalConclusion): Promise<void> {
        await http.delete(`/api/contractAdditionalConclusions/${obj.id}`);
    }

    public static async costChangeExplanations(): Promise<AdditionalConclusionExplanationType[]> {
        return ((await http.get('/api/directory/costChangeExplanations')) as {id: number; name: string; foundingLaw: LawType}[])
            .map(x => new AdditionalConclusionExplanationType(
                x.id,
                x.name,
                x.foundingLaw
            ));
    }
}
