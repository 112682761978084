import React from "react";
import styles from '../CreateSession.module.css';
import {
    changeContractProvisionBankDetailId,
    changeContractProvisionPrice,
    changeContractProvisionRecipient,
    changeContractProvisionUsed,
    changeDeliveryByMultipleConsignees,
    changeDeliveryByRecipientRequest,
    changeElectronicExecution,
    ServicesStore,
    SpecificationType,
    SubjectDeclarationForCreationService
} from "../store";
import {TooltipIcon} from "@/components/TooltipIcon";
import {getValue} from "./CreateSession";
import {firstMaybe} from "@/arrayUtils";
import {Section} from "@/components/eshop/Section/Section";
import {Select} from "@/components/eshop/Select/Select";
import {Card} from "@/components/eshop/Card/Card";
import {FieldsetText} from "@/components/eshop/FieldsetText/FieldsetText";
import {Input} from "@/components/eshop/Input/Input";
import {Checkbox} from "@/components/eshop";

interface ContractConditionsCardProps {
    store: ServicesStore
    canChange: boolean
    dataType: SpecificationType
    data: SubjectDeclarationForCreationService[]
    initialAmount: number
}

export const ContractConditionsCard = (props: ContractConditionsCardProps) => {
    const {store, canChange, dataType, initialAmount, data} = props;

    const digitalOptions = [{ name: "Да", value: "true" }, { name: "Нет", value: "false" }];
    const contractProvisionOptions = [{ name: "Да", value: "true" }, { name: "Нет", value: "false" }];
    const contractAmountOptions = [
        { name: "—", value: "0" },
        { name: "10000", value: "10000" },
        { name: "20000", value: "20000" },
        { name: "30000", value: "30000" },
        { name: "50000", value: "50000" },
        { name: "100000", value: "100000" }
    ];
    const digitalExecutionOnly = store.law === "F44" && dataType === SpecificationType.Products;
    const contractProvisionBank = store.bankAccounts.find(x => x.id === store.contractProvisionBankDetailId);
    const bankDetailOptions = [{ value: '-', name: '-' }, ...store.bankAccounts.map(x => {
        return { value: x.id.toString(), name: `${x.accountNumber} ${x.bankName}` };
    })];

    const code = (firstMaybe(data)?.subjectDeclaration.subjectClass.code.slice(0, 2));
    const isServiceType = dataType === SpecificationType.Services || code === '03' || code === '02';

    return <>
        <Section required canBeHidden title={<>Условия {store.law === 'F44' ? 'контракта' : 'договора'}<TooltipIcon /></>}>
            <Card padded>
                <div className={styles.conditionsContainer}>
                    <div className={styles.conditions}>
                        <Select label="Электронное исполнение"
                                 value={store.electronicExecution === null
                                     ? ''
                                     : store.electronicExecution ? "true" : "false"}
                                 options={digitalOptions}
                                 onSelect={v => changeElectronicExecution(v ? getValue(v) : false)}
                                 disabled={digitalExecutionOnly || !canChange}
                                 withTooltip />
                        <Select label="Требуется обеспечение контракта"
                                 value={store.contractProvisionUsed ? "true" : "false"}
                                 options={contractProvisionOptions}
                                 onSelect={v => changeContractProvisionUsed(v ? getValue(v) : false)}
                                 disabled={initialAmount < 100000 || !isServiceType || !canChange}
                                 withTooltip />
                        <Select label="Сумма обеспечения, руб."
                                 value={store.contractProvisionPrice.toString()}
                                 options={contractAmountOptions}
                                 onSelect={v => changeContractProvisionPrice(v ? v : '0')}
                                 disabled
                                 withTooltip />
                    </div>
                    <div className={`${styles.conditions} ${styles.conditionsCheckboxes}`}>
                        <Checkbox value={store.isGoodsDeliveryByMultipleConsignees}
                                  onChange={() => changeDeliveryByMultipleConsignees(!store.isGoodsDeliveryByMultipleConsignees)}
                                  caption="Поставка множеству грузополучателей, выполнение работ, оказание услуг для пользователей"
                                  bold disabled={!canChange} />
                        <Checkbox value={store.isGoodsDeliveryByRecipientRequest}
                                  onChange={() => changeDeliveryByRecipientRequest(!store.isGoodsDeliveryByRecipientRequest)}
                                  caption="Поставка товаров, выполнение работ, оказание услуг будет осуществляться по заявкам пользователей"
                                  bold disabled={!canChange} />
                    </div>
                    {store.contractProvisionUsed &&
                        <Card title={"Банковские реквизиты заказчика для перечисления обеспечения контракта"} direction={"vertical"}>
                            <div className={styles.contractProvisionBankDetail}>
                                <Select label={"Лицевой счет"}
                                         value={store.contractProvisionBankDetailId.toString()}
                                         onSelect={v => changeContractProvisionBankDetailId(v ? parseInt(v) : 0)}
                                         options={bankDetailOptions}
                                         disabled={!canChange}
                                         required
                                         withTooltip />
                                <FieldsetText withTooltip label="Наименование банка" value={contractProvisionBank && contractProvisionBank.bankName} />
                                <FieldsetText withTooltip label="Корреспондентский счет" value={contractProvisionBank && contractProvisionBank.correspondentAccount} />
                                <FieldsetText withTooltip label="БИК" value={contractProvisionBank && contractProvisionBank.bin} />
                                <FieldsetText withTooltip label="Расчетный счет" value={contractProvisionBank && contractProvisionBank.transactionalAccountNumber} />
                                <FieldsetText withTooltip label="Город" value={contractProvisionBank && contractProvisionBank.city} />
                                <div style={{gridColumn: "span 2"}}>
                                    <Input label="Получатель"
                                            value={store.contractProvisionRecipient}
                                            onChange={e => e && changeContractProvisionRecipient(e.target.value)}
                                            disabled={!canChange}
                                            placeholder="Введите значение"
                                            required />
                                </div>
                            </div>
                        </Card>
                }
                </div>
            </Card>
        </Section>
    </>;
};
