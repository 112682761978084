import {ModalFC} from "@/EventBus";
import {Expandee, HStack, VStack} from "@/components/layouts";
import {Button, Label, TextBox} from "@/components/primitive";
import {CardModal} from "@/components/modals/CardModal";
import {Field, Fields} from "@/components/form/Field";
import {FileInput} from "@/components/primitive/FileInput";
import React, {useState} from "react";
import {SelectOption, toSelectOptions} from "@/models/enums";
import {LotDocumentType, LotDocumentTypeStrings} from "@/models/enums/Documents";
import {LotDocument} from "@/models/ComposedLots/documents/LotDocument";
import {requiredDocs} from "@/views/ComposedLot/edit/tabs/lot-documents/DocumentsSection/DocumentsSection";
import {SearchSelect} from "@/components/primitive/SearchSelect";
import {NumberedLot} from "@/models/ComposedLots/documents/NumberedLot";

export const AddDocumentModal: ModalFC<{disabledOptions: LotDocumentType[]; lot: NumberedLot}, LotDocument> = x => {
    const options: SelectOption<LotDocumentType>[] = toSelectOptions<LotDocumentType>(LotDocumentTypeStrings)
        .map(option => {
            if (x.disabledOptions.find(dis => dis === option.key))
                return {...option, disabled: true};
            else return option;
        });

    const [doc, setDoc] = useState<Partial<LotDocument>>(
        {
            id: undefined!, // todo: type-checked
            lot: x.lot,
            type: undefined,
            file: undefined,
            sendToEis: false,
            comment: undefined
        }
    );

    const save = () => {
        if (doc.type && doc.file)
            x.done(doc as LotDocument); // todo: type-checked
    };

    const acceptableFileTypes = (a: LotDocumentType): string | undefined => {
        switch (a) {
            // Допустимые типы файлов: xls, xlsx
            case "MSV_CALC":
                return ".xls, .xlsx";
            // Допустимые типы файлов: pdf
            case "MSV_PROTOCOL":
                return ".pdf";
            // Допустимые типы файлов: doc, docx, xls, xlsx, pdf, tiff, jpg, jpeg, zip, rar
            default:
                return undefined;
        }
    };

    const isOk = doc.type && doc.file;

    const bottom = <HStack spacing={"10px"}>
        <Button color="green"
                title="Загрузить"
                disabled={!isOk}
                onClick={save} />
        <Expandee/>
        <Button color="red"
                icon="aClose"
                onClick={() => x.done(undefined)} />
    </HStack>;

    return <CardModal title={"Загрузка документации лота"}
                      close={() => x.done(undefined)}
                      width={"50%"}
                      bottom={bottom}
    >
        <VStack spacing={"15px"}>
            <Fields orientation={"vertical"}>
                <Field title={"Тип документа"}>
                    <SearchSelect options={options}
                                  value={doc.type}
                                  placeholder="Выберите тип документа"
                                  onChange={(a) => {
                                      const sendToEis: boolean = !!requiredDocs.find(doc => a === doc);
                                      setDoc({...doc, type: a, sendToEis: sendToEis});
                                  }} />
                </Field>
                <Field title={"Примечание"}>
                        <TextBox lines={5}
                                 value={doc.comment}
                                 onChange={a => setDoc({...doc, comment: a})}
                        />
                </Field>
                <Field title={"Документ"}>
                    <Expandee/>
                </Field>
            </Fields>
            <HStack spacing="15px" alignItems="center">
                {
                    !doc.file &&
                        <FileInput onChange={a => setDoc({...doc, file: a})}
                                   disabled={doc.type === undefined}
                                   accept={doc.type ? acceptableFileTypes(doc.type) : undefined}/>
                }

                <Label text={doc.file?.name ?? ""} />
                <Expandee/>
                {
                    doc.file &&
                        <Button color={"red"} icon={"aClose"} onClick={() => setDoc({...doc, file: undefined})} />
                }
            </HStack>
            {
                doc.type === "MSV_CALC"
                    ? <Label style={{whiteSpace: "normal"}}
                             text={"Допустимые типы файлов: xls, xlsx"}
                    />
                    : doc.type === "MSV_PROTOCOL"
                    ? <Label style={{whiteSpace: "normal"}}
                             text={"Допустимые типы файлов: pdf"}
                    /> : <Label style={{whiteSpace: "normal"}}
                                text={"Допустимые типы файлов: doc, docx, xls, xlsx, pdf, tiff, jpg, jpeg, zip, rar"}
                    />
            }
            <Label style={{whiteSpace: "normal"}}
                   text={"Максимальный размер файла - 50 МБ"}
            />
        </VStack>
    </CardModal>;
};
