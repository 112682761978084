import React from "react";
import { Card } from "@/components/Card";
import { Field, Fields } from "@/components/form/Field";
import { ContractObligation } from "@/models/Contracts/ContractObligation";
import { formatDate } from "@/dateUtils";
import { HStack } from "@/components/layouts";
import { Button, TextBox } from "@/components/primitive";
import { showModal } from "@/EventBus";
import { CustomerInfoModal } from "@/views/Payments/shared/modals/CustomerInfoModal";
import { formatNumber } from "@/NumberFormatting";
import { UnderlinedVueLink } from "@/components/VueLink";

type InfoSectionProps = {
    obligation?: ContractObligation
};

export const InfoSection = ({ obligation }: InfoSectionProps) => {
    const showCustomerInfo = async () => {
        obligation && await showModal(CustomerInfoModal, { customer: obligation.customer });
    };

    return (
        <Card>
            <Fields orientation="horizontal" spacing="10px" style={{ fontSize: 14 }}>
                <Field title="РЕГИСТРАЦИОННЫЙ НОМЕР:">
                    {obligation?.regNumber ?? ""}
                </Field>
                <Field title="ДАТА РЕГИСТРАЦИИ:">
                    {obligation?.registeredAt ? formatDate(obligation?.registeredAt) : ""}
                </Field>
                <Field title="ЗАКАЗЧИК:">
                    <HStack>
                        <TextBox value={obligation?.customer.shortName} readonly />
                        <Button color="white"
                                icon="faExternalLinkAlt"
                                onClick={showCustomerInfo}
                                style={{ borderTopLeftRadius: 0, borderBottomLeftRadius: 0 }} />
                    </HStack>
                </Field>
                <Field title="РЕЕСТРОВЫЙ НОМЕР КОНТРАКТА:">
                    {obligation?.contract?.regNumber ?? ""}
                </Field>
                <Field title="НОМЕР КОНТРАКТА:">
                    {obligation?.contract?.contractNumber ?? ""}
                </Field>
                <Field title="ДАТА ЗАКЛЮЧЕНИЯ КОНТРАКТА:">
                    {obligation?.contract?.conclusionDate ? formatDate(obligation.contract.conclusionDate) : ""}
                </Field>
                <Field title="ИКЗ:">
                    {obligation?.contract?.purchaseCode ?? ""}
                </Field>
                <Field title="СУММА , РУБ.:">
                    {obligation?.totalPrice ? formatNumber(obligation.totalPrice) : ""}
                </Field>
                <Field title="НОМЕР БЮДЖЕТНОГО ОБЯЗАТЕЛЬСТВА:">
                    <div />
                </Field>
                <Field title="ПЛАНОВАЯ ДАТА ОКОНЧАНИЯ ЭТАПА:">
                    {obligation?.endDateStage ? formatDate(obligation.endDateStage) : ""}
                </Field>
                <Field title="ТИП ДОКУМЕНТА:">
                    {obligation?.type ?? ""}
                </Field>
                <Field title="НОМЕР ДОКУМЕНТА:">
                    {obligation?.typeNum ?? ""}
                </Field>
                <Field title="ДАТА ДОКУМЕНТА:">
                    {obligation?.date ? formatDate(obligation.date) : ""}
                </Field>
                <Field title="ОБРАЗЫ ДОКУМЕНТА:">
                    <UnderlinedVueLink href="#">report.pdf</UnderlinedVueLink>
                </Field>
            </Fields>
        </Card>
    );
};
