import {uuid} from "@/models/parsing";
import {http, IFilterObject} from "@/api/http";
import {LotDocumentTerm} from "@/models/ComposedLots/documents/LotDocumentTerms";
import {DtoLotDocuments, LotDocuments} from "@/models/ComposedLots/documents/LotDocuments";
import {LotLegalAct, LotLegalActType} from "@/models/ComposedLots/documents/LotLegalAct";
import {IPagedItems, mapPage} from "@/api/Pagination";

export const getLotDocuments = async (id: uuid): Promise<LotDocuments> => LotDocuments.fromDto(await http.get(`/api/v2/lots/${id}/documents`) as DtoLotDocuments);

// Creates a common term-definition pair, not lot specific one
export const createLotDocumentTerm = async (term: LotDocumentTerm) => (await http.post("/api/v2/directory/terms", LotDocumentTerm.toDto(term)));

export const getLotDocumentTerms = async (from: number, count: number, filters: IFilterObject): Promise<IPagedItems<LotDocumentTerm>> => {
    return mapPage(LotDocumentTerm, await http.getPaged("/api/v2/directory/terms", {
        from: from,
        count: count,
        filters: filters
    }));
};

export const getLegalActs = async (from: number, count: number, filters: IFilterObject): Promise<IPagedItems<LotLegalAct>> => {
    return mapPage(LotLegalAct, await http.getPaged("/api/v2/directory/npa", {
        from: from,
        count: count,
        filters: filters
    }));
};

export const getNpaDocumentTypes = async (from: number, count: number, filters: IFilterObject): Promise<IPagedItems<LotLegalActType>> => {
    return mapPage(LotLegalActType, await http.getPaged("/api/v2/directory/npaDocumentTypes", {
        from: from,
        count: count,
        filters: filters
    }));
};



