import React from "react";
import { Store } from "effector";
import { ProvisionStoreState } from "@/views/Provision/view/store";
import { Form, FormHeader, FormState } from "@/components/form";
import { VStack } from "@/components/layouts";
import { useStore } from "effector-react";
import { EisExchangeSection, InfoSection } from "@/views/Provision/shared/sections";
import { Card } from "@/components/Card";
import { Products223Section } from "@/views/Provision/shared/sections/Products223Section";
import { ConclusionSection } from "@/views/Provision/shared/sections/ConclusionSection";
import { ContractCorrectionSection } from "@/views/Provision/shared/sections/ContractCorrectionSection";
import { ContractProvision } from "@/views/Provision/shared/sections/ContractProvision";
import { RequestProvision } from "@/views/Provision/shared/sections/RequestProvision";
import { ProvisionDatesSection } from "@/views/Provision/shared/sections/ProvisionDatesSection";
import { ProvisionTextSection } from "@/views/Provision/shared/sections/ProvisionTextSection";
import { ProcurementRegulationSection } from "@/views/Provision/shared/sections/ProcurementRegulationSection";
import { HistorySection } from "@/views/Provision/shared/sections/HistorySection";
import { CodesSection } from "@/views/Provision/shared/sections/CodesSection";
import { ArchivalMethods } from "@/views/Provision/shared/sections/ArchivalMethods";
import {AnnualRevenue} from "@/views/Provision/shared/sections/AnnualRevenue";
import {PurchasingSection} from "@/views/Provision/shared/sections/PurchasingSection";
import {useStoredRemoteDataset} from "@/components/table";

type ProvisionViewProps = {
    storeContainer: {
        store: Store<ProvisionStoreState>
    }
};

export const ProvisionView = ({ storeContainer: { store } }: ProvisionViewProps) => {
    const { provision, historySrd } = useStore(store);
    const history = useStoredRemoteDataset(historySrd);

    const header = <VStack spacing="15px">
        <FormHeader title="Информация о положении о закупке" />
    </VStack>;

    const tabs = Form.Tab("info", "Общая информация", [
        Form.Section("Общая информация", <InfoSection provision={provision} />),
        Form.Section("Дополнительные способы определения поставщика (закупки не в электронной форме)", <Card />),
        Form.Section("Основания закупки у единственного поставщика", <PurchasingSection reasons={provision?.reasonsSingleProvider} />),
        Form.Section("Продукция, не закупаемая в рамках 223-ФЗ", <Products223Section />),
        Form.Section("Заключение договора", <ConclusionSection provision={provision} />),
        Form.Section("Изменение договора", <ContractCorrectionSection />),
        Form.Section("Обеспечение исполнения договора", <ContractProvision provision={provision} />),
        Form.Section("Обеспечение заявки", <RequestProvision provision={provision} />),
        Form.Section("Сроки проведения", <ProvisionDatesSection provision={provision} />),
        Form.Section("Текст положения", <ProvisionTextSection />),
        Form.Section("Изменение положения о закупке", <ProcurementRegulationSection />),
        Form.Section("Обмен с ЕИС", <EisExchangeSection provision={provision} />),
        Form.Section("История изменений", <HistorySection history={history} />),
        Form.Section("Сведения о кодах способов определения поставщика в ЕИС", <CodesSection />),
        Form.Section("Коды архивных способов определения поставщика в ЕИС", <ArchivalMethods />),
        Form.Section("Годовая выручка заказчика за отчетный финансовый год", <AnnualRevenue />),
    ]);

    return (
        <FormState value="readonly">
            <Form header={header} tabs={[tabs]} hideTabs />
        </FormState>
    );
};
