// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, "._3pbtMQJExKscQ0B_GZAlTz {\n    /*position: absolute;*/\n    width: 100%;\n    background: #F0F3F5;\n    letter-spacing: 0.05em;\n\n    font-family: \"GothamPro\", sans-serif;\n    color: #344049;\n}\n", ""]);
// Exports
exports.locals = {
	"page": "_3pbtMQJExKscQ0B_GZAlTz"
};
module.exports = exports;
