import {createEffect, createStore, Store} from "effector";
import Participants from "@/api/Participants";
import {SingleProviderReason} from "@/models/SingleProviderReason";
import {BankAccount} from "@/models/BankAccount";
import {LotForm} from "@/views/ComposedLot/shared/store/index";
import {Person} from "@/views/ComposedLot/shared/store/responsibilityInfo";
import {CancellationReason} from "@/models/ComposedLots/CancellationReason";
import {
    getLotCancellationReasons,
    getLotParticipants,
    getPossibleMainCustomers,
    getPurchaseCategories
} from "@/api/directory";
import {Participant} from "@/models";
import { on } from "office-ui-fabric-react";
import { PurchaseCategory } from "@/models/ComposedLots/PurchaseCategory";

export interface LotRelatedData {
    singleProviderReasons223: SingleProviderReason[]
    singleProviderReasons44: SingleProviderReason[]
    accounts: BankAccount[]
    persons: Person[]
    cancellationReasons: CancellationReason[]
    participants: Participant[]
    mainCustomers: Participant[]
    purchaseCategories: PurchaseCategory[]
}

export const mockPersons: Person[] = [{
    isGeneral: true,
    firstName: "Елена",
    middleName: "Викторовна",
    lastName: "Матерова",
    email: "sp11@zdrav.mos.ru",
    cellPhone: "+7 (495) 685-38-04 (3)",
},
    {
        isGeneral: false,
        firstName: "Имя",
        middleName: "Отчество",
        lastName: "Фамилия",
        email: "address@mail.com",
        cellPhone: "+7 (999) 123-45-67 (727)",
    },];

export const RelatedDataStore = {
    createStore: (): Store<LotRelatedData> => {
        const loadSingleProviderReasons44 = createEffect({
            handler: () => Participants.getSingleProviderReasons(6196, "F44")
        });
        const loadSingleProviderReasons223 = createEffect({
            handler: () => Participants.getSingleProviderReasons(6196, "F223")
        });
        const loadAccounts = createEffect({
            handler: () => Participants.getAccounts(6196)
        });
        const loadPersons = createEffect({
            handler: async (): Promise<Person[]> => { //mock
                return mockPersons;
            }
        });
        const loadCancellationReasons = createEffect({
            handler: async (): Promise<CancellationReason[]> => await getLotCancellationReasons()
        });
        const loadParticipants = createEffect({
            handler: async (): Promise<Participant[]> => await getLotParticipants()
        });
        const loadPossibleMainCustomers = createEffect({
            handler: async (): Promise<Participant[]> => await getPossibleMainCustomers()
        });

        const loadPurchasesCategories = createEffect({
            handler: async (): Promise<PurchaseCategory[]> => await getPurchaseCategories()
        });

        const store = createStore<LotRelatedData>({
            singleProviderReasons44: [],
            singleProviderReasons223: [],
            accounts: [],
            persons: [],
            cancellationReasons: [],
            participants: [],
            mainCustomers: [],
            purchaseCategories: []
        })
            .on(loadSingleProviderReasons44.done, (x, {result}) =>
                ({...x, singleProviderReasons44: result}))
            .on(loadSingleProviderReasons223.done, (x, {result}) =>
                ({...x, singleProviderReasons223: result}))
            .on(loadAccounts.done, (x, {result}) =>
                ({...x, accounts: result}))
            .on(loadPersons.done, (x, {result}) =>
                ({...x, persons: result}))
            .on(loadCancellationReasons.done, (x, {result}) =>
                ({...x, cancellationReasons: result}))
            .on(loadParticipants.done, (x, {result}) =>
                ({...x, participants: result}))
            .on(loadPossibleMainCustomers.done, (x, {result}) =>
                ({...x, mainCustomers: result}))
            .on(loadPurchasesCategories.done, (x, { result }) => ({ ...x, purchaseCategories: result }));

        loadSingleProviderReasons44().ignore;
        loadSingleProviderReasons223().ignore;
        loadAccounts().ignore;
        loadPersons().ignore;
        loadCancellationReasons().ignore;
        loadParticipants().ignore;
        loadPossibleMainCustomers().ignore;
        loadPurchasesCategories().ignore;

        return store;
    },

    observeOnCombinedStore: (combinedStore: Store<LotForm>) => {
    }
};
