import {UnionStrings} from "@/models/enums/EnumStrings";

export type LawType = "F44" | "F223";

// @deprecated Left for compatibility
export const LawType: { [key in LawType]: LawType } = {
    F223: "F223",
    F44: "F44"
};

export const LawTypeStrings: UnionStrings<LawType> = {
    F223: "223-ФЗ",
    F44: "44-ФЗ",
};
