import React, { CSSProperties, useCallback, useRef } from "react";
import { Card } from "@/components/Card";
import * as styles from "./CardModal.module.css";

interface CardModalProps {
    title: React.ReactNode
    bottom?: React.ReactNode
    close: (() => void) | "disabled"
    children: React.ReactNode
    width: string
    style?: CSSProperties
    cardStyle?: CSSProperties
    cardHeaderStyle?: CSSProperties
    cardContentStyle?: CSSProperties
    padding?: string
}

export const CardModal: React.FC<CardModalProps> = x => {
    const close = x.close === "disabled" ? undefined : x.close;

    const modalRef = useRef<HTMLDivElement | null>(null);

    const closeOnEscape = useCallback((e: KeyboardEvent) => {
        if (e.key === "Escape") {
            close?.();
        }
    }, []);

    const refHandlers = useCallback(node => {
        if (modalRef.current) {
            window.removeEventListener("keyup", closeOnEscape);

            modalRef.current = null;
        }

        modalRef.current = node;

        if (modalRef.current) {
            modalRef.current.focus();

            window.addEventListener("keyup", closeOnEscape);
        }
    }, []);

    // yet another wrapper, needed to override vue modal wrapper if close is set to "disabled"
    return <div style={{ width: "100%", height: "100%", display: "grid", overflow: "auto", justifyContent: "stretch" }} onClick={e => {e.stopPropagation(); close?.()}}>
            <div className={styles.wrapper} style={{ width: x.width, ...x.style }} tabIndex={0}>
            <Card onClick={e => e.stopPropagation()}
                  ref={refHandlers}
                  style={{ overflow: "hidden", ...x.cardStyle }}
                  headerStyle={x.cardHeaderStyle}
                  contentStyle={{ overflowY: "auto", ...x.cardContentStyle }}
                  padding={x.padding}
                  bottom={x.bottom} title={x.title} hasCloseButton={!!close} onClose={close}>
                {x.children}
            </Card>
        </div>
    </div>;
};
