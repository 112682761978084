import { EnumStrings } from '../EnumStrings';

export const enum TransmissionStatus {
    NOT_SENT = "NOT_SENT",
    SENDING = "SENDING",
    SENT = "SENT",
    ACCEPTED = "ACCEPTED",
}

export const TransmissionStatusStrings: EnumStrings = {
    [TransmissionStatus.NOT_SENT]: "Не отправлено",
    [TransmissionStatus.SENDING]: "Отправка",
    [TransmissionStatus.SENT]: "Отправлено",
    [TransmissionStatus.ACCEPTED]: "Принято",
};