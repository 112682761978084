

































































import { Component, Prop, Vue } from "vue-property-decorator";
import { ITableColumn, EditType } from "@/components/TableAbstractions";
import TextCellEditor from "./cell-editors/TextCellEditor.vue";
import DecimalCellEditor from "./cell-editors/DecimalCellEditor.vue";
import SelectCellEditor from "./cell-editors/SelectCellEditor.vue";
import CheckCellEditor from "./cell-editors/CheckCellEditor.vue";
import { formatNumber } from '@/NumberFormatting';
import Decimal from 'decimal.js';

@Component({ components: { TextCellEditor, DecimalCellEditor, SelectCellEditor, CheckCellEditor } })
export default class DataTableCell extends Vue {
    @Prop() private header!: ITableColumn<unknown>;
    @Prop() private item!: unknown;
    @Prop() private ix!: number;
    @Prop() private canEdit!: boolean;

    public get headerAction() {
        return this.header.action ? this.header.action(this.item) : null;
    }

    public get urlResult() {
        return this.header.url ? this.header.url(this.item) : null;
    }

    public get isDisabled() {
        switch (typeof this.header.disabled) {
            case "boolean":
                return this.header.disabled;
            case "function":
                return this.header.disabled(this.item);
        }

        return false;
    }

    public get isEditable() {
        if (!this.canEdit) return false;

        switch (typeof this.header.editable) {
            case "boolean":
                return this.header.editable;
            case "function":
                return this.header.editable(this.item);
        }

        return false;
    }

    public value() {
        const editType = this.header.type;
        if (editType === EditType.DECIMAL)
            return formatNumber(this.header.getter(this.item, this.ix) as Decimal);
        else return this.header.getter(this.item, this.ix);
    }
}
