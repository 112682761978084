import {AutoDto, AutoModel, MDMap} from "@/models/parsing";
import {DtoLot, Lot} from "@/models/ComposedLots";
import {DtoLotDocument, LotDocument} from "@/models/ComposedLots/documents";
import {DtoProcedureParticipant, ProcedureParticipant} from "@/models/Procedures2020/ProcedureParticipant";

interface ProcedureLotMD {
    lot: MDMap<Lot, DtoLot>
    includedLots: MDMap<ProcedureLot, DtoProcedureLot>[]
    documents: MDMap<LotDocument, DtoLotDocument>[]
    participants: MDMap<ProcedureParticipant, DtoProcedureParticipant>[]
}

export type ProcedureLot = AutoModel<ProcedureLotMD>;
export type DtoProcedureLot = AutoDto<ProcedureLotMD>;

export const ProcedureLot = {
    fromDto(l: DtoProcedureLot): ProcedureLot {
        return {
            lot: Lot.fromDto(l.lot),
            documents: l.documents.map(LotDocument.fromDto),
            participants: l.participants.map(ProcedureParticipant.fromDto),
            includedLots: l.includedLots.map(ProcedureLot.fromDto),
        };
    },
};