import { EnumStrings } from '../EnumStrings';

export const enum DissolutionType {
    AGREEMENT = "AGREEMENT",
    LAW_DECISION = "LAW_DECISION",
    CUSTOMER_DECISION = "CUSTOMER_DECISION",
    SUPPLIER_DECISION = "SUPPLIER_DECISION",
}

export const enum DissolutionReason {
    CUSTOMER_FAULT = "CUSTOMER_FAULT",
    SUPPLIER_FAULT = "SUPPLIER_FAULT",
    ACT_OF_PROVIDENCE = "ACT_OF_PROVIDENCE",
}

export const DissolutionTypeStrings: EnumStrings = {
    [DissolutionType.AGREEMENT]: "По соглашению сторон",
    [DissolutionType.LAW_DECISION]: "По решению суда",
    [DissolutionType.CUSTOMER_DECISION]: "По решению заказчика в одностороннем порядке",
    [DissolutionType.SUPPLIER_DECISION]: "По решению поставщика в одностороннем порядке",
};

export const DissolutionReasonStrings: EnumStrings = {
    [DissolutionReason.SUPPLIER_FAULT]: "По вине поставщика",
    [DissolutionReason.ACT_OF_PROVIDENCE]: "В связи с обстоятельствами, не зависящими от воли сторон",
    [DissolutionReason.CUSTOMER_FAULT]: "По вине заказчика",
};