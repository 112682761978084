import { LawType, ObjectStatus, Currency } from "@/models/enums";
import { DateTimeRange } from "@/models";
import { Decimal } from "decimal.js";
import {
    ContractParticipant,
} from "@/models/Contracts";
import {
    IDtoContractInfo, IDtoSupplierEvasion,
} from "@/models/json/Contracts";
import { IDtoContract } from "@/models/json/Contracts";
import { IJsonFormattable } from "@/models/IJsonFormattable";
import { ContractStage } from "./ContractStage";
import { ContractSpecification } from './ContractSpecification';
import { ContractExecutionStatus } from '../enums/Contracts/ContractExecutionStatus';
import { IRelatedObject } from '../RelatedObjects';
import { File } from '../Documents';
import { ProviderSelectionType } from "@/models/ComposedLots";
import { ContractQuotationSession } from "@/models/Contracts/ContractQuotationSession";
import { ReviewDocumentsTermType, SendDocumentsTermType, PaymentTermType } from "@/models/ComposedLots/PaymentStageTerm";
import { ContractResponsiblePerson } from "@/models/Contracts/ContractResponsiblePerson";

export class SupplierEvasion implements IJsonFormattable<IDtoSupplierEvasion>  {
    constructor(
        public reason: string,
        public protocol: File,
        public date: Date,
        public number: string,
        public status: ObjectStatus
    ) {}

    public toJson(): IDtoSupplierEvasion {
        return {
            ...this,

            protocol: this.protocol.toJson(),
            date: this.date.toISOString()
        };
    }

    public static fromJson(dto: IDtoSupplierEvasion): SupplierEvasion {
        return Object.assign(Object.create(SupplierEvasion.prototype), dto, {
            protocol: File.fromJson(dto.protocol),
            date: new Date(dto.date)
        });
    }

}

export class Contract implements IJsonFormattable<IDtoContract, IDtoContractInfo> {
    constructor(
        public id: string,
        public regNumber: string,
        public purchaseCode: string,
        public law: LawType,
        public status: ObjectStatus,
        public executionStatus: ContractExecutionStatus,
        public versionNumber: number,
        public contractNumber: string | null,
        public subject: string | null,
        public conclusionDate: Date | null,
        public registrationDate: Date | null,
        public executionDates: DateTimeRange | null,
        public durationDates: DateTimeRange | null,
        public currency: Currency,
        public customer: ContractParticipant,
        public supplier: ContractParticipant,
        public newStages: Array<ContractStage>,
        public specifications: Array<ContractSpecification>,

        public supplierEvasion: SupplierEvasion | null,

        public procedureRegNumber: string,
        public lotRegNumber: string,
        public conclusionReason: string,

        public auctionCost: Decimal,
        public isAuctionRequired: boolean | null,
        public isEshop: boolean,
        public isAggregated: boolean,
        public isElectronicExecution: boolean | null,
        public waitingForStageRegistration: boolean,
        public foApprovalDate: Date | null,
        public electronicConclusionState: string, // TODO

        public relatedObjects: IRelatedObject[],
        public lot2020Id: string | null,
        public lotId: string | null,
        public providerSelectionType: ProviderSelectionType,
        public quotationSession: ContractQuotationSession | null,
        public quotationSessionId: string | null,
        public isMonopoly: boolean,
        public reviewDocumentsTerm: ReviewDocumentsTermType,
        public sendDocumentsTerm: SendDocumentsTermType,
        public paymentTerm: PaymentTermType,
        public responsiblePerson: ContractResponsiblePerson | null
    ) {}

    public get cost() {
        return this.specifications.reduce((r, x) => r.add(x.actualCost), new Decimal(0));
    }

    public toJson(): IDtoContractInfo {
        return {
            ...this,

            purchaseCode: this.purchaseCode,
            contractNumber: this.contractNumber,
            subject: this.subject,
            conclusionDate: this.conclusionDate && this.conclusionDate.toISOString(),
            registrationDate: this.registrationDate && this.registrationDate.toISOString(),
            executionDates: this.executionDates && this.executionDates.toJson(),
            durationDates: this.durationDates && this.durationDates.toJson(),
            currency: this.currency,
            customer: this.customer.toJson(),
            supplier: this.supplier.toJson(),
            stages: this.newStages.map(x => x.toJson()),
            responsiblePerson: this.responsiblePerson ? this.responsiblePerson.toJson() : null,
            isAuctionRequired: this.isAuctionRequired,
            isElectronicExecution: this.isElectronicExecution,
            auctionCost: this.isAuctionRequired? this.auctionCost.toString() : null,
            specifications: this.specifications.map(x => x.toJson()),
            foApprovalDate: this.foApprovalDate && this.foApprovalDate.toISOString()
        };
    }

    public static fromJson(dto: IDtoContract): Contract {
        return Object.assign(Object.create(Contract.prototype), dto, {
            id: dto.id,
            regNumber: dto.regNumber,
            purchaseCode: dto.purchaseCode,
            law: dto.law,
            status: dto.status,
            contractNumber: dto.contractNumber,
            subject: dto.subject,
            conclusionDate: dto.conclusionDate && new Date(dto.conclusionDate),
            registrationDate: dto.registrationDate && new Date(dto.registrationDate),
            executionDates: dto.executionDates && DateTimeRange.fromJson(dto.executionDates),
            durationDates: dto.durationDates && DateTimeRange.fromJson(dto.durationDates),
            currency: dto.currency,
            customer: ContractParticipant.fromJson(dto.customer),
            supplier: ContractParticipant.fromJson(dto.supplier),
            newStages: [],
            isAuctionRequired: dto.isAuctionRequired,
            isElectronicExecution: dto.isElectronicExecution,
            isEshop: dto.isEshop,
            isAggregated: dto.isAggregated,
            auctionCost: dto.auctionCost && new Decimal(dto.auctionCost),
            specifications: dto.specifications.map(ContractSpecification.fromJson),
            foApprovalDate: dto.foApprovalDate && new Date(dto.foApprovalDate),
            supplierEvasion: dto.supplierEvasion && SupplierEvasion.fromJson(dto.supplierEvasion),
            lot2020Id: dto.lot2020Id,
            lotId: dto.lotId,
            providerSelectionType: dto.providerSelectionType,
            quotationSessionId: dto.quotationSessionId,
            reviewDocumentsTerm: dto.reviewDocumentsTerm,
            sendDocumentsTerm: dto.sendDocumentsTerm,
            paymentTerm: dto.paymentTerm,
            responsiblePerson: dto.responsiblePerson ? ContractResponsiblePerson.fromJson(dto.responsiblePerson) : null,
        });
    }

    public clone(): Contract {
        return Object.assign(Object.create(Contract.prototype), this, {
            executionDates: this.executionDates && DateTimeRange.fromJson(this.executionDates.toJson()),
            durationDates: this.durationDates && DateTimeRange.fromJson(this.durationDates.toJson()),
            customer: this.customer && ContractParticipant.fromJson(this.customer.toJson()),
            supplier: this.supplier && ContractParticipant.fromJson(this.supplier.toJson()),
            newStages: [],
            specifications: this.specifications.map(x => ContractSpecification.fromJson(x.toJson())),
            supplierEvasion: this.supplierEvasion && SupplierEvasion.fromJson(this.supplierEvasion.toJson())
        });
    }
}
