




































import Stats from "@/components/Stats.vue";
import Expander from "@/components/Expander.vue";
import { defaultStats, IStatsItem } from "@/components/Stats";
import { Component, Prop, Vue } from "vue-property-decorator";
import PageHeader from "@/components/PageHeader.vue";
import { ITableColumn, SelectionStyle } from "@/components/TableAbstractions";
import { PaginationContext } from "../api/Pagination";

@Component({ components: { PageHeader, Stats, Expander } })
export default class SingleTableView extends Vue {
    @Prop() private header!: string;
    @Prop({ default: false }) private canAdd!: boolean;
    @Prop({ default: false }) private selectPrevIndex!: boolean;
    @Prop({ default: false }) private canSearch!: boolean;
    @Prop({ default: false }) private canFilter!: boolean;
    @Prop({ default: false }) private embedded!: boolean;
    @Prop() private headers!: ITableColumn<unknown>[];
    @Prop() private context!: PaginationContext<unknown>;
    @Prop({ default: () => [] }) private itemActions!: unknown[];
    @Prop({ default: () => [] }) private actions!: unknown[];
    @Prop({ default: false }) private canExport!: boolean | null;
    @Prop({ default: null }) private statsContext!: null | string;

    private isFiltersOpened = true;

    private get stats(): IStatsItem[][] {
        return defaultStats[this.statsContext as string] || [];
    }
}
