import { applyN, AutoDto, AutoModel, MDMap, uuid } from "@/models/parsing";
import { Participant } from "@/models/Participant";
import Decimal from "decimal.js";
import { IDtoParticipant } from "@/models/json";
import { ContractObligation, DtoContractObligation } from "@/models/Contracts/ContractObligation";

interface ContractPaymentMD {
    id: uuid
    customer: MDMap<Participant, IDtoParticipant>
    kbk: string
    regNumber: string
    registeredAt: Date
    totalPrice: Decimal
    userId: uuid
    obligation?: MDMap<ContractObligation, DtoContractObligation>
}

export type ContractPayment = AutoModel<ContractPaymentMD>;
export type DtoContractPayment = AutoDto<ContractPaymentMD>;

export const ContractPayment = {
    fromDto(dtoContractPayment: DtoContractPayment): ContractPayment {
        return {
            ...dtoContractPayment,
            customer: Participant.fromJson(dtoContractPayment.customer),
            registeredAt: new Date(dtoContractPayment.registeredAt),
            totalPrice: new Decimal(dtoContractPayment.totalPrice),
            obligation: applyN(ContractObligation.fromDto, dtoContractPayment.obligation)
        };
    },
    toDto(contractPayment: ContractPayment): DtoContractPayment {
        return {
            ...contractPayment,
            customer: contractPayment.customer.toJson(),
            registeredAt: contractPayment.registeredAt.toISOString(),
            totalPrice: contractPayment.totalPrice.toString(),
            obligation: applyN(ContractObligation.toDto, contractPayment.obligation)
        };
    }
};
