




import {Component, Prop, Vue} from "vue-property-decorator";
import {CreateLot} from './CreateLot';
import {LawType} from "@/models/enums";
import {createLotStore} from "@/views/ComposedLot/shared/store";

@Component({ components: { komponent: CreateLot as unknown as typeof Vue } })
export default class CreateLotVueStub extends Vue {
    @Prop() public lawType!: LawType;
    @Prop() public financialLimitId!: string | undefined;

    public storeContainer = Object.freeze({
        store: createLotStore({
            law: this.lawType,
            financialLimitId: this.financialLimitId
        })
    });
}
