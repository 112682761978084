import {createEvent, createStore, Store} from "effector";
import {ProcedureForm} from "@/views/Procedures2020/view/store";
import {ProcedureDocument} from "@/models/Procedures2020/ProcedureDocument";
import {replaceOrPush} from "@/arrayUtils";

export interface ProcedureDocsSectionStore {
    /** Документы закупки */
    procedureDocuments: ProcedureDocument[]
}

const replace = createEvent<ProcedureDocsSectionStore>("replace documents section");

export const ProcedureDocsEvents = {
    addOrReplaceProcedureDocument: createEvent<{ oldV?: ProcedureDocument; newV: ProcedureDocument }>("add or replace procedure document"),
    removeProcedureDocument: createEvent<ProcedureDocument>("add or replace procedure document"),
};

/** Секция "Лоты" */
export const ProcedureDocsSectionStore = {
    createStore: (): Store<ProcedureDocsSectionStore> => {
        return createStore<ProcedureDocsSectionStore>({
            procedureDocuments: []
        })
            .on(replace, (_, l) => l)
            .on(ProcedureDocsEvents.addOrReplaceProcedureDocument, (state, payload) => ({
                ...state,
                procedureDocuments: replaceOrPush(state.procedureDocuments ?? [], payload.oldV, payload.newV)
            }))
            .on(ProcedureDocsEvents.removeProcedureDocument, (state, payload) => ({
                ...state,
                procedureDocuments: state.procedureDocuments?.filter(a => a.file !== payload.file)
            }));
    },

    replace: replace,
    observeOnCombinedStore: (combinedStore: Store<ProcedureForm>) => {
    }
};