






import { ChargeSourceStrings, ChargeSource, ContractExecutionOperationState } from "@/models/enums/Contracts";
import { ContractScope } from "@/views/CreateContract/ContractScope";
import { Component, Vue, Prop } from "vue-property-decorator";
import DataTable from "@/components/vue/DataTable.vue";
import { HistoryEntry } from "@/models/HistoryEntry";
import { ITableColumn, EditType } from "@/components/TableAbstractions";
import { ObjectStatusStrings, LawType } from "@/models/enums";
import { ContractDocument, ContractDocumentType } from "@/models/Contracts";
import { formatDate } from "@/DateFormatting";

@Component({ components: { DataTable } })
export default class ContractDocsPage extends Vue {
    @Prop() private source!: ContractScope | null;

    public get documents(): ContractDocument[] {
        if (!this.source) return [];
        const uniqueContractDocuments: ContractDocument[] = [];
        const uniqueDocumentIds: string[] = [];
        const isSigned = this.source.executionHistory.some(exec => exec.state === ContractExecutionOperationState.SIGNED);
        const docs = this.source.documents;

        const contractDocumentsWithDuplicates = [
            ...docs,
            ...this.source.additionalConclusions.flatMap(
                c => {
                    const d = new ContractDocument(
                        null!,
                        c.document!,
                        new ContractDocumentType(2501, "Дополнительное соглашение", null!, ""),
                        c.number,
                        c.date,
                        c.createdAt,
                        c.createdAt, //TODO: replace with signedAt
                        null!
                    );
                    return c.projectDocument === null
                        ? [d]
                        : [
                            d,
                             new ContractDocument(
                                null!,
                                c.projectDocument!,
                                new ContractDocumentType(null!, "Проект дополнительного соглашения", null!, ""),
                                c.number,
                                c.date,
                                c.createdAt,
                                c.createdAt, //TODO: replace with signedAt
                                null!
                            )
                        ];
                }
            ),
            ...this.source.claimWorks.flatMap(c => c.documents),
            ...this.source.executionProvision.map(
                p =>
                    new ContractDocument(
                        null!,
                        p.documentFile,
                        new ContractDocumentType(null!, ChargeSourceStrings[p.source], null!, ""),
                        p.documentNumber,
                        p.documentDate,
                        p.createdAt,
                        p.createdAt, //TODO: replace with signedAt
                        null!
                    ),
            ),
            ...this.source.executionHistory.flatMap(x => x.documents),
            ...this.source.stageScopes.flatMap(x => [
                ...x.executionInfo.flatMap(i => i.documents),
                ...x.acceptanceInfo.flatMap(i => i.documents)
            ])
        ];

        // some contract documents can display twice
        // e.g. this.document.source and this.source.executionHistory
        // so we have to return unique docs only (issue #854)
        contractDocumentsWithDuplicates.forEach(c => {
            if(!isSigned && c.type.id === 16) return;
            if(!uniqueDocumentIds.includes(c.id)) {
                uniqueContractDocuments.push(c);
                if(c.id !== null) {
                    uniqueDocumentIds.push(c.id);
                }
            }
        });

        return uniqueContractDocuments.sort((a, b) => {
            return (a.createdAt || new Date()).valueOf() - (b.createdAt || new Date()).valueOf();
        });
    }

    public get headers(): ITableColumn<ContractDocument>[] {
        return [
            {
                title: "№",
                getter: (p, i) => (i + 1).toString(),
                size: "auto",
            },
            {
                title: "Тип документа",
                getter: p => {
                    if (p.type.name.includes("Контракт") && this.source!.contract.law == LawType.F223) {
                        return p.type.name.replace(/Контракт/, "Договор");
                    } else return p.type.name;
                },
            },
            {
                title: "Номер документа",
                getter: p => p.documentNumber,
            },
            {
                title: "Дата документа",
                getter: p => formatDate(p.date, true),
            },
            {
                title: "​Наименование документа",
                getter: p => p.file.comment,
            },
            {
                title: "Дата добавления",
                getter: p => formatDate(p.createdAt, true),
            },
            ...(
                this.source?.contract?.law === "F223" ? [
                    {
                      title: "Обязательность отправки в ЕИС",
                      getter: (p: ContractDocument) => p.isSendingToEisRequired223 ? "Да" : "Нет",
                    },
                    {
                      title: "Размер",
                      getter: (p: ContractDocument) => p.file.length
                    }
                ] : []
            ),
            {
                title: "Действие",
                getter: () => "",
            },
        ];
    }
}
