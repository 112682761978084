import {LawType} from "@/models/enums";
import {ProviderSelectionType223, ProviderSelectionType44} from "@/models/ComposedLots";

export const simulateDownload = (fileName: string, href: string) => {
    const a = document.createElement("a");

    a.setAttribute('download', fileName);
    a.setAttribute('href', href);

    a.click();
};

export const isAggregatedLot = (
    law: LawType,
    selectionType?: ProviderSelectionType44 | ProviderSelectionType223,
    reasonId?: number
) =>
    selectionType === "SINGLE_PROVIDER_PURCHASE" && (
        law === LawType.F44 && (reasonId === 1000004 || reasonId === 1000005)
        ||
        law === LawType.F223 && reasonId === 1
    );


export const spreadIf = <T,>(cond: boolean, ...items: T[]): T[] => cond ? items : [];
