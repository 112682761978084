import {UnionStrings} from "@/models/enums/EnumStrings";

export type BankingSupport =
    | "NO"
    | "BANKING_SUPPORT"
    | "FISCAL_SUPPORT"
    | "BOTH_SUPPORT";

export const BankingSupportStrings: UnionStrings<BankingSupport> = {
    NO: "Нет",
    BANKING_SUPPORT: "Требуется банковское сопровождение",
    FISCAL_SUPPORT: "Требуется казначейское сопровождение",
    BOTH_SUPPORT: "Требуется банковское и казначейское сопровождение"
};