// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, "._2U8uAlZbp1wf8qema-sFLE {\n    font-size: 16px;\n    font-weight: bold;\n    padding-left: 20px;\n}", ""]);
// Exports
exports.locals = {
	"yearInset": "_2U8uAlZbp1wf8qema-sFLE"
};
module.exports = exports;
