import {Field, FieldGrid, FieldStack} from "@/components/form/Field";
import {Button, CheckBox, ComboBox, Label, TextBox} from "@/components/primitive";
import {LawTypeStrings, toSelectOptions} from "@/models/enums";
import {HStack, VGrid} from "@/components/layouts";
import Auth from "@/api/Auth";
import {ProcedureEvents} from "@/views/Procedures2020/view/store/info";
import {
    ProviderSelectionType, ProviderSelectionType223Strings, ProviderSelectionType223StringsAtAggregatedLot,
    ProviderSelectionType44Strings,
    ProviderSelectionTypeStrings
} from "@/models/ComposedLots";
import React from "react";
import {useMappedStore} from "@/storeUtils";
import {SectionProps} from "@/views/Procedures2020/view/store";
import {formatDate} from "@/dateUtils";

const getMarketplaceTitle = (a: ProviderSelectionType) => [
    "E_AUC",
    "E_EXAM",
    "E_TWO_STAGE_EXAM",
    "E_OFFERS_REQUEST",
    "E_QUOTATIONS_REQUEST",
    "E_LIMITED_EXAM",
].find(key => key === a) ? "Электронная торговая площадка" : "Торговая площадка";

export const EditableInfoBlock = (x: SectionProps) => {
    const [law, info, specials, marketplaces, lots] = useMappedStore(x.formStore, x => [
        x.head.law,
        x.info,
        x.relatedDataStore.specials,
        x.relatedDataStore.marketplaces,
        x.lots.lots
    ]);

    const specialOptions = specials.map(a => ({key: a.id, desc: a.shortName}));
    const docPreparer = specials.find(a => a.id === info.documentationPreparer)?.id;
    const performer = info.isPerformerEqualToDocPreparer
        ? docPreparer
        : specials.find(a => a.id === info.procedurePerformer)?.id;

    return <FieldGrid>
        <Field title={"Закон-основание"}>
            <Label preset={"boldSmall"} text={law ? LawTypeStrings[law] : ""}/>
        </Field>
        <Field required title={"Организатор закупки"}>
            <HStack spacing="15px" alignItems="center">
                <Label preset="readonlyValue">{Auth.currentUserInfo?.selectedParticipant?.fullName}</Label>
                <Button icon="aEdit" color="green" disabled/>
            </HStack>
        </Field>
        <Field required title={"Описание закупки"}>
            <TextBox lines={5} onChange={a => ProcedureEvents.setSubject(a)} value={info.subject}/>
        </Field>
        <Field required title={"Способ определения поставщика"}>
            <ComboBox options={toSelectOptions<ProviderSelectionType>((law === "F44" ? ProviderSelectionType44Strings : ProviderSelectionType223StringsAtAggregatedLot), true, "Выберите способ определения поставщика")}
                      disabled={!!lots.length}
                      onChange={a => ProcedureEvents.setProviderSelectionType(a)}
                      value={info.providerSelectionType}/>
        </Field>
        {
            (info.providerSelectionType !== "SINGLE_PROVIDER_PURCHASE") &&
                (info.providerSelectionType !== undefined) &&
                    <Field title={getMarketplaceTitle(info.providerSelectionType)}
                           required>
                        <ComboBox options={[{key: undefined, desc: "Выберите торговую площадку"}, ...marketplaces]}
                                  value={info.marketplace}
                                  onChangeNonStrict={ProcedureEvents.setMarketplace}/>
                    </Field>
        }
        {
            (info.providerSelectionType !== "SINGLE_PROVIDER_PURCHASE") &&
                <>
                    <Field title={"Специализированная организация по подготовке документации"}>
                        <ComboBox options={[{
                            key: undefined,
                            desc: "Выберите организацию по подготовке документации"
                        }, ...specialOptions]}
                                  onChange={ProcedureEvents.setDocumentationPreparer}
                                  value={docPreparer}/>
                    </Field>
                    <Field title={"Специализированная организация по проведению процедуры закупки"}>
                        <HStack>
                            <CheckBox value={info.isPerformerEqualToDocPreparer}
                                      onChange={ProcedureEvents.setIsPerformerEqualToDocPreparer}/>
                            <Label text={"Совпадает с организацией, разрабатывющей документацию"}/>
                        </HStack>
                    </Field>
                    <Field title={""}>
                        <ComboBox options={[{
                            key: undefined,
                            desc: "Выберите организацию по проведению закупки"
                        }, ...specialOptions]}
                                  disabled={info.isPerformerEqualToDocPreparer}
                                  onChange={ProcedureEvents.setProcedurePerformer}
                                  value={performer}/>
                    </Field>
                </>
        }
    </FieldGrid>;
};

export const ReadOnlyInfoBlock = (x: SectionProps) => {
    const [law, info, specials] = useMappedStore(x.formStore, x => [
        x.head.law,
        x.info,
        x.relatedDataStore.specials,
    ]);

    const docPreparer = specials.find(a => a.id === info.documentationPreparer)?.shortName;
    const performer = info.isPerformerEqualToDocPreparer
        ? docPreparer
        : specials.find(a => a.id === info.procedurePerformer)?.shortName;

    return <VGrid columns="1fr 1fr" spacing="15px">
        <FieldStack>
            <Field title={"Закон-основание"}>
                <Label>{law ? LawTypeStrings[law] : ""}</Label>
            </Field>
            <Field title={"ИКЗ"}>
                <Label>Не сформирован</Label>
            </Field>
            <Field title={"Организатор закупки"}>
                <Label>{Auth.currentUserInfo?.selectedParticipant?.fullName}</Label>
            </Field>
            <Field title={"Описание закупки"}>
                <Label text={info.subject ?? ""}/>
            </Field>
            <Field title={"Способ определения поставщика"}>
                <Label text={info.providerSelectionType ? ProviderSelectionTypeStrings[info.providerSelectionType] : ""}/>
            </Field>
            {
                info.decisionNumber && <Field title={"Номер решения о проведении закупки"}>
                    <Label text={info.decisionNumber}/>
                </Field>
            }
            {
                info.decisionDate && <Field title={"Дата решения о проведении закупки"}>
                    <Label text={formatDate(info.decisionDate)}/>
                </Field>
            }
        </FieldStack>
        <FieldStack>
            {
                docPreparer && <Field title={"Специализированная организация по подготовке документации"}>
                    <Label text={docPreparer}/>
                </Field>
            }
            {
                performer && <Field title={"Специализированная организация по проведению процедуры закупки"}>
                    <Label text={performer}/>
                </Field>
            }
        </FieldStack>
    </VGrid>;
};
