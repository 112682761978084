import React from "react";
import {NewsArticleLabel} from "@/api/News";
import styles from "./ArticleLabel.module.css";
import {j} from "@/reactUtils";

export const ArticleLabel: React.FC<{ item: NewsArticleLabel; onLabelSelect?: (l: NewsArticleLabel) => void }> = x => {
    return <div className={j(styles.label, styles[x.item.color])} onClick={x.onLabelSelect ? e => { x.onLabelSelect?.(x.item); e.stopPropagation() } : undefined}>
            { x.item.title }
    </div>;
};
