import { createStore } from "effector";
import { getProcedures } from "@/api/Procedures2020";
import { Procedure } from "@/models/Procedures2020/Procedure";
import { createStoredRemoteDataset, StoredRemoteDataset } from "@/components/table";
import { FilterConditionType, FilterValueType } from "@/api/http";
import Decimal from "decimal.js";

export type TradesFilters = {
    subject: string
    status: string
    providerSelectionType: string
    law: string
    lotSpecPriceTotal: Decimal
};

// Procedures list store

export interface TradesState {
    procedures: StoredRemoteDataset<Procedure, TradesFilters>
    loaded: boolean
}

export const createTradesStore = () => {
    const store = createStore<TradesState>({
        procedures: createStoredRemoteDataset<Procedure, TradesFilters>({
            async load(filters, from, count) {
                return getProcedures(from, count, {
                    subject: [{
                        type: FilterValueType.STRING,
                        conditionType: FilterConditionType.CONTAINS,
                        string: filters.subject
                    }],
                    status: [{
                        type: FilterValueType.STRING,
                        conditionType: FilterConditionType.CONTAINS,
                        string: filters.status
                    }, ...(filters.status ? [] : [{
                        type: FilterValueType.STRING,
                        conditionType: FilterConditionType.EQUAL,
                        string: "PUBLISHED"
                    }, {
                        type: FilterValueType.STRING,
                        conditionType: FilterConditionType.EQUAL,
                        string: "TRADE_FINISHED"
                    }, {
                        type: FilterValueType.STRING,
                        conditionType: FilterConditionType.EQUAL,
                        string: "PUBLICATION_WAITING"
                    }])],
                    law: [{
                        type: FilterValueType.STRING,
                        conditionType: FilterConditionType.CONTAINS,
                        string: filters.law
                    }],
                    providerSelectionType: [{
                        type: FilterValueType.STRING,
                        conditionType: FilterConditionType.CONTAINS,
                        string: filters.providerSelectionType
                    }],
                    priceTotal: [{
                        type: FilterValueType.DECIMAL,
                        conditionType: FilterConditionType.EQUAL,
                        decimal: filters.lotSpecPriceTotal
                    }]
                });
            }
        }),
        loaded: false
    });

    return store;
};
