// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".RJhtQUB_2wS2Ys56LxRw2,\n._21JWN35JZUoMfGBWVuYPc5{\n    width: 24px;\n    height: 24px;\n    padding: 8px;\n    display: flex;\n    align-items: center;\n    justify-content: center;\n    cursor: pointer;\n}\n\n._2FExqpZEpomv13R7B6Fy8k {\n    filter: invert(67%) sepia(93%) saturate(3175%) hue-rotate(185deg) brightness(107%) contrast(101%);\n}\n\n._3Zoyl2CpSMOaiJSMeWX421 {\n    filter: invert(24%) sepia(98%) saturate(6876%) hue-rotate(357deg) brightness(99%) contrast(113%);\n}\n\n._2-4TODVRSrQpEIROMO4GiG {\n    filter: invert(63%) sepia(1%) saturate(1356%) hue-rotate(355deg) brightness(97%) contrast(89%);\n}\n\n.RTAOcYbyhU57khmYUicyk {\n    transform: rotate(180deg);\n}\n\n.W18QbaaDc4WRZYUZWLF9P {\n    cursor: not-allowed;\n    width: 24px;\n    height: 24px;\n    padding: 8px;\n}\n\n._21JWN35JZUoMfGBWVuYPc5:hover {\n    background-color: #b7e0ff;\n    border-radius: 50%;\n    filter: opacity(0.6);\n}\n\n.RJhtQUB_2wS2Ys56LxRw2:hover {\n    filter: opacity(0.6);\n}\n", ""]);
// Exports
exports.locals = {
	"svgButton__wrapper_color": "RJhtQUB_2wS2Ys56LxRw2",
	"svgButton__wrapper_round": "_21JWN35JZUoMfGBWVuYPc5",
	"blueFilter": "_2FExqpZEpomv13R7B6Fy8k",
	"redFilter": "_3Zoyl2CpSMOaiJSMeWX421",
	"grayFilter": "_2-4TODVRSrQpEIROMO4GiG",
	"svgButton__upsideDown": "RTAOcYbyhU57khmYUicyk",
	"svgButton__wrapper_disabled": "W18QbaaDc4WRZYUZWLF9P"
};
module.exports = exports;
