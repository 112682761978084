import React from "react";
import {FTextBox} from "@/components/form";
import {useMappedStore} from "@/storeUtils";
import {Card} from "@/components/Card";
import {SectionProps} from "../store";
import {VStack} from "@/components/layouts";
import {Label} from "@/components/primitive";
import {setMinimalRequirements} from "@/views/ComposedLot/shared/store/minimalRequirements";

export const MinimalRequirementsSection: React.FC<SectionProps> = x => {
    const text = useMappedStore(x.formStore, x => x.minimalRequirements.text);

    return <Card>
        <VStack spacing="3px">
            <Label text="Минимально необходимые требования, предъявляемые к закупаемым товарам, работам, услугам"/>
            <FTextBox lines={5} onChange={setMinimalRequirements} value={text}/>
        </VStack>
    </Card>;
};