import { ChargeSource, PledgeSource } from "@/models/enums/Contracts";
import { Decimal } from "decimal.js";
import { Currency, ObjectStatus } from "@/models/enums";
import { ContractDocument } from "@/models/Contracts";
import { IDtoContractDocument } from "@/models/json/Contracts";
import { IDtoContractExecutionProvision } from '@/models/json/Contracts';
import { IJsonFormattable } from '@/models/IJsonFormattable';
import { File } from '../Documents';
import { DateTimeRange } from '../DateTimeRange';
import {IDtoContractGuaranteeProvision} from "@/models/json/Contracts/IDtoContractGuaranteeProvision";

export class ContractGuaranteeProvision implements IJsonFormattable<IDtoContractGuaranteeProvision> {
    constructor(
        public id: string,
        public createdAt: Date,
        public source: ChargeSource,
        public pledgeSource: PledgeSource | null,
        public status: ObjectStatus | null,
        public amount: Decimal,
        public amountRub: Decimal,
        public currency: Currency,
        public documentNumber: string,
        public documentDate: Date,
        public documentFile: File,

        public duration: DateTimeRange | null,
        public guaranteeDuration: DateTimeRange | null,
        public guaranteeServiceRequirementsInfo: string | null,
        public regNumber: string | null,
        public bin: string | null,
        public orgName: string | null,
        public tin: string | null,
        public orgAddress: string | null,
    ) {}

    public toJson(): IDtoContractGuaranteeProvision {
        return {
            id: this.id,
            createdAt: null!,
            source: this.source,
            pledgeSource: this.pledgeSource,
            status: this.status,
            amount: this.amount.toString(),
            amountRub: this.amountRub.toString(),
            currency: this.currency,
            documentNumber: this.documentNumber,
            documentDate: this.documentDate.toISOString(),
            documentFile: this.documentFile.toJson(),

            duration: this.duration && this.duration.toJson(),
            guaranteeDuration: this.guaranteeDuration && this.guaranteeDuration.toJson(),
            guaranteeServiceRequirementsInfo: this.guaranteeServiceRequirementsInfo,
            regNumber: this.regNumber,
            bin: this.bin,
            orgName: this.orgName,
            tin: this.tin,
            orgAddress: this.orgAddress,
        };
    }

    public static fromJson(dto: IDtoContractGuaranteeProvision): ContractGuaranteeProvision {
        return Object.assign(Object.create(ContractGuaranteeProvision.prototype), {
            id: dto.id,
            createdAt: new Date(dto.createdAt),
            source: dto.source,
            pledgeSource: dto.pledgeSource,
            status: dto.status,
            amount: new Decimal(dto.amount),
            amountRub: new Decimal(dto.amountRub),
            currency: dto.currency,
            documentNumber: dto.documentNumber,
            documentDate: new Date(dto.documentDate),
            documentFile: File.fromJson(dto.documentFile),

            duration: dto.duration && DateTimeRange.fromJson(dto.duration),
            guaranteeDuration: dto.guaranteeDuration && DateTimeRange.fromJson(dto.guaranteeDuration),
            guaranteeServiceRequirementsInfo: dto.guaranteeServiceRequirementsInfo,
            regNumber: dto.regNumber,
            bin: dto.bin,
            orgName: dto.orgName,
            tin: dto.tin,
            orgAddress: dto.orgAddress,
        });
    }
}
