import {  createEvent, createStore, Store } from "effector";
import { LotForm } from "@/views/ComposedLot/shared/store/index";
import { HistoryItem } from "@/models";

export interface HistoryStore {
    historyItems: HistoryItem[]
}

const replace = createEvent<HistoryStore>("replace glossary store");

export const HistoryStore = {
    createStore: (): Store<HistoryStore> => {
        return createStore<HistoryStore>({
            historyItems: []
        })
            .on(replace, (_, l) => l);
    },

    replace: replace,
    observeOnCombinedStore: (combinedStore: Store<LotForm>) => {
    }
};
