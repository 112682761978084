import React, {useEffect, useState} from "react";
import {getParticipantsById} from "@/api/directory";
import {VStack} from "@/components/layouts";
import {Form, FormHeader} from "@/components/form";
import {SuppliersLegalRequisites} from "@/views/ReferenceInfo/Suppliers/sections/SuppliersLegalRequisites";
import {SubjectClassDeliveryAddresses} from "@/views/ReferenceInfo/Suppliers/sections/SuppliersCustomerDetails";
import {SuppliersSuccession} from "@/views/ReferenceInfo/Suppliers/sections/SuppliersSuccession";
import {SuppliersStatisticalCodes} from "@/views/ReferenceInfo/Suppliers/sections/SuppliersStatisticalCodes";
import {SuppliersContactInformation} from "@/views/ReferenceInfo/Suppliers/sections/SuppliersContactInformation";
import {SuppliersSchedule} from "@/views/ReferenceInfo/Suppliers/sections/SuppliersSchedule";
import {SuppliersContactFaces} from "@/views/ReferenceInfo/Suppliers/sections/SuppliersContactFaces";
import {SuppliersBankDetails} from "@/views/ReferenceInfo/Suppliers/sections/SuppliersBankDetails";
import {SuppliersRoles} from "@/views/ReferenceInfo/Suppliers/sections/SuppliersRoles";
import {ParticipantMD} from "@/models";
import { ChangeHistorySection } from "@/views/ComposedLot/edit/tabs/change-history/ChangeHistorySection";

type ViewSubjectClassProps = {
    id?: string | null
};

export const SuppliersView: React.FC<ViewSubjectClassProps> = x => {
    const [suppliers, setSuppliers] = useState<ParticipantMD>();
    useEffect(() => {
        if (x.id) getParticipantsById(x.id).then(setSuppliers);
    }, []);


    const header = <VStack spacing="15px">
        <FormHeader title={`Информация о поставщике - ${x.id}`}/>
    </VStack>;

    const tabs = Form.Tab("customer", "Информация о заказчике", [
        Form.Section("Юридические реквизиты", <SuppliersLegalRequisites info={suppliers!} />),
        Form.Section("Реквизиты заказчика", <SubjectClassDeliveryAddresses />),
        Form.Section("Правопреемственность", <SuppliersSuccession />),
        Form.Section("Статистические коды", <SuppliersStatisticalCodes />),
        Form.Section("Контактная информация", <SuppliersContactInformation />),
        Form.Section("График работы", <SuppliersSchedule />),
        Form.Section("Контактные лица", <SuppliersContactFaces />),
        Form.Section("Банковские реквизиты", <SuppliersBankDetails />),
        Form.Section("Роли", <SuppliersRoles />),
        Form.Section("История изменений", <ChangeHistorySection />)
]);

    return <>
        {
            suppliers
                ? <Form header={header} tabs={[tabs]} />
                : <></>
        }
    </>;
};