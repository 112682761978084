// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, "._2Me2b_M8T5nJKpacvQq7tc > * {\n    vertical-align: middle;\n}\n\n._3T-e_s_6itFJAyPRYdP-k8 {\n    margin: 0 10px 0 0;\n}\n\n.WFMxpDrUMd3AxmrqOOqJ5 {\n    margin-right: 0;\n}", ""]);
// Exports
exports.locals = {
	"component": "_2Me2b_M8T5nJKpacvQq7tc",
	"input": "_3T-e_s_6itFJAyPRYdP-k8",
	"inputEmpty": "WFMxpDrUMd3AxmrqOOqJ5"
};
module.exports = exports;
