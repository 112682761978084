








export default {
    name: 'HeadTitle',
    props: {
        header: String,
    },
};
