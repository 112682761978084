import React from "react";
import {WizardEditableField} from "@/views/ComposedLot/edit/tabs/lot-documents/DocumentsSection/wizard/tabs/Edit";
import styles from "./Question.module.css";
import {TextBox} from "@/components/primitive";

interface WizardEditableTextFieldProps {
    field: WizardEditableField
    value: string
    onChange: (s: string) => void
}

export const WizardEditableTextField: React.FC<WizardEditableTextFieldProps> = x => {
    return <div className={styles.layout}>
        <div className={styles.title}>
            { x.field.name }
        </div>
        <TextBox lines={5}
                 value={x.value}
                 onChange={x.onChange} />
    </div>;
};
