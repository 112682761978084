var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[(_vm.header.filter)?_c('div',{staticStyle:{"min-width":"0","width":"100%"}},[(_vm.filter.type === 'STRING')?_c('TextCellEditor',{attrs:{"cellValue":_vm.filterValue.values[0] ? _vm.filterValue.values[0].string : null},on:{"change":function($event){_vm.filterValue.values = [{
                    type: 'STRING',
                    conditionType: 'CONTAINS',
                    string: $event,
                }]
                _vm.$emit('update-filter')}}}):(_vm.filter.type === 'RANGE' && (_vm.startExplicit || _vm.endExplicit))?_c('DecimalCellEditor',{attrs:{"cellValue":_vm.filterValue.values[0] ? _vm.filterValue.values[0].decimal : null},on:{"change":function($event){_vm.filterValue.values = [{
                    type: 'DECIMAL',
                    conditionType: 'GREATER_THAN_OR_EQUAL',
                    decimal: $event,
                }]
                _vm.$emit('update-filter')}}}):(_vm.filter.type === 'NUMBER')?_c('DecimalCellEditor',{attrs:{"cellValue":_vm.filterValue.values[0] ? _vm.filterValue.values[0].decimal : null},on:{"change":function($event){_vm.filterValue.values = isFinite($event) ? [{
                    type: 'DECIMAL',
                    conditionType: 'EQUAL',
                    decimal: $event,
                }] : []
                _vm.$emit('update-filter')}}}):(_vm.filter.type === 'LONGINT')?_c('DecimalCellEditor',{attrs:{"cellValue":_vm.filterValue.values[0] ? _vm.filterValue.values[0].longint : null},on:{"change":function($event){_vm.filterValue.values = isFinite($event) ? [{
                    type: 'LONGINT',
                    conditionType: 'EQUAL',
                    longint: $event
                }] : []
                _vm.$emit('update-filter')}}}):(_vm.filter.type === 'DATE')?_c('DateCellEditor',{attrs:{"withDay":_vm.filter.withDay,"cellValue":_vm.filterValue.values[0] ? _vm.filterValue.values[0].date : null},on:{"change":function($event){_vm.filterValue.values = $event === 0
                    ? []
                    : [{
                    type: 'DATE',
                    conditionType: 'EQUAL',
                    date: $event,
                }];
                _vm.$emit('update-filter')}}}):(_vm.filter.type === 'SELECT' && !_vm.filter.multiselect)?_c('SelectCellEditor',{attrs:{"cellValue":_vm.filterValue.values[0] ? _vm.filterValue.values[0].string : null,"selectOptions":_vm.distinctOptions(_vm.filter.options)},on:{"change":function($event){_vm.filterValue.values = _vm.allPossibleFiltersForDescription(_vm.filter.options, $event)
                _vm.$emit('update-filter')}}}):_vm._e()],1):_vm._e()])}
var staticRenderFns = []

export { render, staticRenderFns }