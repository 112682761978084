
import React, { ReactNode } from "react";
import styles from "./EshopLink.module.css";

interface EshopLinkProps {
    children?: ReactNode
    onClick?: () => void
    to?: string
    withoutOverflow?: boolean
}

export const EshopLink = (props: EshopLinkProps) => <a href={props.to} className={`${props.withoutOverflow ? styles.eshopLink : styles.eshopLinkOverflow}`} onClick={() => props.onClick && props.onClick()} >
    {props.children}
</a>;