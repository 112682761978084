import {applyN, AutoDto, AutoModel, MDMap, uuid} from "@/models/parsing";
import {Decimal} from 'decimal.js';
import {MeasurementUnit, SubjectDeclaration} from "@/models";
import {ActionClass} from "@/models/ActionClass";
import {
    IDtoActionClass,
    IDtoMeasurementUnit,
    IDtoSubjectDeclaration
} from "@/models/json";
import {
    CharacteristicValue,
    CharacteristicValueTemplate,
    DtoCharacteristicValue, DtoCharacteristicValueTemplate,
    DtoSupplyStage, SupplyStage, Address, DtoAddress
} from "@/models/ComposedLots/specifications";
import {DtoStartPrice, StartPrice} from "@/models/ComposedLots/specifications/StartPrice";
import {forEach} from "lodash";

interface SpecificationMD {
    id: uuid
    subjectDeclaration: MDMap<SubjectDeclaration, IDtoSubjectDeclaration>
    actionClass?: MDMap<ActionClass, IDtoActionClass>
    volume: Decimal
    measurementUnit: MDMap<MeasurementUnit, IDtoMeasurementUnit>
    supplyStages: MDMap<SupplyStage, DtoSupplyStage>[]
    startPrice?: MDMap<StartPrice, DtoStartPrice>
    characteristics: MDMap<CharacteristicValue, DtoCharacteristicValue>[]
    supplyAddress?: null
    additionalCharacteristicJustification?: string
}

export type DtoSpecification = AutoDto<SpecificationMD>;
export type Specification = AutoModel<SpecificationMD>;

type NewSpecification = Pick<Specification, Exclude<keyof Specification, "id">> & { isNew: true; id?: undefined };
type ChangedSpecification = Specification & { isNew: false };
export type SpecificationDraft = NewSpecification | ChangedSpecification;

export const Specification = {
    toDto(info: Specification): DtoSpecification {
        return {
            id: info.id,
            subjectDeclaration: info.subjectDeclaration.toJson(),
            actionClass: info.actionClass?.toJson(),
            volume: info.volume.toString(),
            measurementUnit: info.measurementUnit.toJson(),
            supplyStages: info.supplyStages.map(SupplyStage.toDto),
            characteristics: info.characteristics.map(CharacteristicValue.toDto),
            supplyAddress: null,
            startPrice: applyN(StartPrice.toDto, info.startPrice),
            additionalCharacteristicJustification: info?.additionalCharacteristicJustification
        };
    },
    toTemplate(info: SpecificationDraft): SpecificationTemplate {
        return {
            ...info,
            subjectDeclarationId: info.subjectDeclaration.id,
            actionClassId: info.actionClass?.id,
            measurementUnitId: info.measurementUnit.id,
            characteristics: info.characteristics.map(CharacteristicValue.toTemplate),
            additionalCharacteristicJustification: info.additionalCharacteristicJustification
        };
    },
    fromDto(info: DtoSpecification): Specification {
        return {
            id: info.id,
            subjectDeclaration: SubjectDeclaration.fromJson(info.subjectDeclaration),
            actionClass: applyN(ActionClass.fromJson, info.actionClass),
            volume: new Decimal(info.volume),
            measurementUnit: MeasurementUnit.fromJson(info.measurementUnit),
            supplyStages: info.supplyStages.map(x => SupplyStage.fromDto(x)),
            characteristics: info.characteristics.map(CharacteristicValue.fromDto),
            supplyAddress: null!,
            startPrice: applyN(StartPrice.fromDto, info.startPrice),
            additionalCharacteristicJustification: info.additionalCharacteristicJustification ?? undefined
        };
    }
};

interface SpecificationTemplateMD {
    id?: uuid
    subjectDeclarationId: number
    actionClassId?: number
    volume: Decimal
    measurementUnitId: number
    supplyStages: MDMap<SupplyStage, DtoSupplyStage>[]
    startPrice?: MDMap<StartPrice, DtoStartPrice>
    characteristics: MDMap<CharacteristicValueTemplate, DtoCharacteristicValueTemplate>[]
    supplyAddress?: null
    additionalCharacteristicJustification?: string
}

export type DtoSpecificationTemplate = AutoDto<SpecificationTemplateMD>;
export type SpecificationTemplate = AutoModel<SpecificationTemplateMD>;

export const SpecificationTemplate = {
    toDto(info: SpecificationTemplate): DtoSpecificationTemplate {
        console.log("templateToDto", {info});
        return {
            ...info,
            startPrice: applyN(StartPrice.toDto, info.startPrice),
            volume: info.volume.toString(),
            supplyStages: info.supplyStages.map(SupplyStage.toDto),
            characteristics: info.characteristics.map(CharacteristicValueTemplate.toDto),
            supplyAddress: null,
            additionalCharacteristicJustification: info.additionalCharacteristicJustification
        };
    },
    fromDto(info: DtoSpecificationTemplate): SpecificationTemplate {
        return {
            ...info,
            startPrice: applyN(StartPrice.fromDto, info.startPrice),
            volume: new Decimal(info.volume),
            supplyStages: info.supplyStages.map(x => SupplyStage.fromDto(x)),
            characteristics: info.characteristics.map(CharacteristicValueTemplate.fromDto),
            supplyAddress: null!,
            id: info.id ?? undefined,
            actionClassId: info.actionClassId ?? undefined,
            additionalCharacteristicJustification: info.additionalCharacteristicJustification ?? undefined
        };
    }
};
