import React, {useEffect, useState} from "react";
import styles from "./SubjectClasses.module.css";
import {Store} from "effector";
import {Column, FilterColumn, FilteredSelectionTable, Pager, Table, useStoredRemoteDataset} from "@/components/table";
import {SubjectClassFilters, SubjectClassStore} from "@/views/ReferenceInfo/SubjectClasses/list/store";
import {useStore} from "effector-react";
import {Button, Label, Note} from "@/components/primitive";
import router from "@/router";
import {Expandee, HStack, VStack} from "@/components/layouts";
import {formatDate} from "@/dateUtils";
import {Branch, TreeView} from "@/components/table/TreeView";
import SubjectClassApi from "@/api/SubjectClasses";
import {SubjectClass as SubjectClassAPI} from "@/models";
import {SubjectClass} from "@/models/SubjectClass/SubjectClass";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import { showModal } from "@/EventBus";
import { ExportModal } from "@/views/ReferenceInfo/SubjectClasses/list/ExportModal";

export const SubjectClasses: React.FC<{ storeContainer: { store: Store<SubjectClassStore> } }> = x => {
    const state = useStore(x.storeContainer.store);
    const rds = useStoredRemoteDataset(state.subjectClasses);

    const [filtersVisible, setFiltersVisible] = useState(true);
    const [isTreeView, setTreeViewVisible] = useState(false);
    const [treeData, setTreeData] = useState<Branch<SubjectClassAPI>[]>([]);

    useEffect(() => {
        if (!isTreeView || treeData.length) {
            return;
        }

        SubjectClassApi.getAll(null).then(response => {
            type A = { item: SubjectClassAPI; code: string; children: A[] };
            const parents: A[] = [];
            const getParent = (parent: A, parentCode: string) => {
                if (parent.code === parentCode) return parent;

                let r: A;
                for (const v of parent.children) {
                    r = getParent(v, parentCode);
                }

                // eslint-disable-next-line @typescript-eslint/ban-ts-ignore
                // @ts-ignore
                return r;
            };

            response
                .forEach(v => {
                    const newItem = { item: v, code: v.code, children: [] };

                    if (v.parents.length === 0) {
                        parents.push(newItem);
                        return;
                    }

                    const parent = getParent(
                        parents.find(x => x.code === v.parents[0].code)!,
                        v.parents[v.parents.length - 1].code
                    );
                    parent?.children.push(newItem);
                });

            setTreeData(parents.map(x => ({
                item: x.item,
                children: x.children
            })));
        });
    }, [isTreeView]);

    const columns: Column<SubjectClass>[] = [
        FilterColumn<SubjectClass, SubjectClassFilters>({
            header: "Код КПГЗ",
            cell: c => <Label>
                {c.item.code}
            </Label>,
            filter: {
                type: "string",
                key: "code"
            },
        }),
        FilterColumn<SubjectClass, SubjectClassFilters>({
            header: "Наименование КПГЗ",
            cell: c => <Label onClick={() => router.push(`/reference-info/dict/subject-classes/${c.item.id}`)}
                              style={{ textDecoration: "underline", cursor: "pointer" }}>
                {c.item.description}
            </Label>,
            filter: {
                type: "string",
                key: "name"
            },
            options: { width: "auto" }
        }),
        FilterColumn<SubjectClass, SubjectClassFilters>({
            header: "Код ОКПД 2",
            cell: c => <Label>
                {`${c.item.okpd2.code} ${c.item.okpd2.name}`}
            </Label>,
            filter: {
                type: "string",
                key: "okpd2"
            },
            options: { width: "auto" }
        }),
        FilterColumn<SubjectClass, SubjectClassFilters>({
            header: "Актуальность записи",
            cell: c => <Label>
                {c.item.projectCheck ? "Утвержден" : "Проект"}
            </Label>,
            // filter: {
            //     type: "string",
            //     key: "projectCheck"
            // }
        }),
        Table.Column("Дата последнего изменения", c =>
            <Label>
                {formatDate(c.item.createdDate)}
            </Label>)
    ];

    return <VStack outerStyle={{margin: "30px"}} spacing="15px">
        <Note preset="title">
            <HStack alignItems="center" spacing={"10px"}>
                <div>Список КПГЗ</div>
                <Expandee/>
                <Button title={isTreeView ? "Список КПГЗ" : "Деревом"} onClick={() => setTreeViewVisible(!isTreeView)} />
                <Button icon="aFilter" onClick={() => setFiltersVisible(!filtersVisible)} disabled={isTreeView} />
                <Button title="Экспорт" onClick={() => showModal(ExportModal)} />
            </HStack>
        </Note>
        {!isTreeView ? <>
            <Note preset="panel">
                <FilteredSelectionTable
                    filterStore={rds.filterStore}
                    isFiltersVisible={filtersVisible}
                    mode="single"
                    selectorPosition="hidden"
                    dataset={rds.dataset}
                    columns={columns} />
            </Note>
            <Pager remoteDataset={rds}/>
        </> : <div>{
            isTreeView &&
            <Note preset="panel" style={{ padding: "8px" }}>
                <TreeView<SubjectClassAPI> nodeClassName={styles.treeNode} dataset={treeData}
                                           content={(item) =>
                                               <div className={styles.inner}>
                                                   <FontAwesomeIcon icon="search"
                                                                    color="#3a8ecc"
                                                                    style={{ marginRight: "8px", width: "16px", height: "16px" }}
                                                                    onClick={() => router.push(`/reference-info/dict/subject-classes/${item.id}`)}/>
                                                                { item.code } { item.description }
                                               </div>} />
            </Note>
        }</div>}
    </VStack>;
};
