




import { CreateSession as CreateSessionR } from "./CreateSession";
import { Component, Prop } from "vue-property-decorator";
import {createServicesStore, ServicesStore} from "../store";
import { SubjectClassFilterStore } from '@/components/eshop/SubjectClassFilterStore';

import Vue from "vue";

@Component({ components: { komponent: CreateSessionR as unknown as typeof Vue } })
export default class CreateSession extends Vue {
    @Prop() public ids!: string;
    @Prop() public sessionId!: string;
    @Prop() public service!: string;
    @Prop() public product!: string;
    @Prop() public purchase!: string;
    @Prop() public mode!: string;
    public storeContainer =  Object.freeze({ store: createServicesStore(), subjectClassSelector: new SubjectClassFilterStore() });

}
