




import { Sessions as SessionsR } from "./Sessions";
import { Component, Prop } from "vue-property-decorator";
import Vue from "vue";

@Component({ components: { komponent: SessionsR as unknown as typeof Vue } })
export default class Sessions extends Vue {
    @Prop() public favorites!: boolean;
}
