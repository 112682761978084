import { PageOfUnknown } from '@/api/Products';
import { observer } from "mobx-react";
import React, { useState } from "react";
import styles from './Pager.module.css';
import { getActivePage, getAvailablePages } from '@/api/Pagination';
import {Select} from "@/components/eshop/Select/Select";

type PagerProps = {
    page: PageOfUnknown
    goTo: (payload: { page: number; pageSize: number }) => void
    pageSize: number
    changePageSize: (size: number) => void
};

const PagerImpl: React.FC<PagerProps> = ({ children, goTo, page, pageSize, changePageSize }) => {
    const { totalCount, from, to } = page;
    const canGoBack = from !== 0;
    const canGoForward = to !== totalCount;
    const currentPage = getActivePage(page, pageSize);
    const lastPage = Math.floor(totalCount / pageSize);
    const [currentPageSize, setCurrentPageSize] = useState(pageSize);

    const availablePages = getAvailablePages(page, pageSize);

    const pagerOptions = [
        { name: '10', value: '10' },
        { name: '30', value: '30' },
        { name: '50', value: '50' },
    ];

    const updatePageSize = (pageSize: number) => {
        setCurrentPageSize(pageSize);
        changePageSize(pageSize);
    };

    return <div className={styles.offersPaginator}>
        <div className={styles.offersPaginatorContent}>
            <div className={styles.buttons}>
                <div className={styles.arrowsContainer}>
                    <div className={`${styles.arrowIcon} ${styles.arrowFirst}`} onClick={() => goTo({ page: 0, pageSize: pageSize })} />
                    <div className={`${styles.arrowIcon} ${styles.arrowPrev}`} onClick={() => goTo({ page: currentPage - 1, pageSize: pageSize })} />
                </div>
                <div className={styles.numbersContainer}>
                    {availablePages.map((pageIndex) =>
                        <div className={`${styles.number} ${pageIndex === currentPage ? styles.numberSelected : ''}`} onClick={() => goTo({ page: pageIndex, pageSize: pageSize })} key={pageIndex}>
                            <div>{pageIndex + 1}</div>
                        </div>)}
                </div>
                <div className={styles.arrowsContainer}>
                    <div className={`${styles.arrowIcon} ${styles.arrowNext}`} onClick={() => goTo({ page: currentPage + 1, pageSize: pageSize })} />
                    <div className={`${styles.arrowIcon} ${styles.arrowLast}`} onClick={() => goTo({ page: lastPage, pageSize: pageSize })} />
                </div>
            </div>
            <div className={styles.select}>
                <Select popupOnHover small value={currentPageSize.toString()} options={pagerOptions} onSelect={v => v && updatePageSize(parseInt(v))} />
            </div>
        </div>
        {children}
    </div>;
};

export const Pager = observer(PagerImpl);
