// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, "._3TE3AkdqKLUQPEO7d40et- {\n    position: absolute;\n    right: 0;\n    background-color: #fff;\n    z-index: 2;\n    border: 1px solid #e1e7eb;\n    border-radius: 5px;\n    padding: 10px;\n    min-width: 300px;\n\n    display: flex;\n    flex-direction: column;\n    align-items: end;\n}\n\n._2t6LWR1MRnkZb0tKjiVkAb {\n    min-width: 200px;\n    max-width: 600px;\n    min-height: 100px;\n    max-height: 200px;\n    overflow: auto;\n}\n\n._35VcNvy80MUUk_rke8RLOE {\n    line-height: 2em;\n}\n\n.GABpfxciRlF7Grw8uEipN{\n    color: #4c4c4c;\n    padding-left: 20px;\n\n}\n\n._3GH0YgVMe_GHVckLbvWoTF {\n    width: 21px;\n    height: 21px;\n    top: calc(50% - 10.5px - 0px);\n    cursor: pointer;\n    background-position: center;\n    background-image: url(\"https://eaist2-f.mos.ru/eshop/img/icon-close-999.9770e030323c1c0f85a1.svg\");\n    background-size: 15px;\n    z-index: 1;\n    border-radius: 50%;\n    right: 34px;\n}\n", ""]);
// Exports
exports.locals = {
	"offersPopup": "_3TE3AkdqKLUQPEO7d40et-",
	"infoTable": "_2t6LWR1MRnkZb0tKjiVkAb",
	"offerVendor": "_35VcNvy80MUUk_rke8RLOE",
	"offerValue": "GABpfxciRlF7Grw8uEipN",
	"infoClose": "_3GH0YgVMe_GHVckLbvWoTF"
};
module.exports = exports;
