import React from "react";
import { Card } from "@/components/Card";
import { Provision } from "@/models/Provision/Provision";
import {Field, Fields} from "@/components/form/Field";
import {formatNumber} from "@/NumberFormatting";

type RequestProvisionProps = {
    provision?: Provision
};

export const RequestProvision = ({ provision }: RequestProvisionProps) => {
    return (
        <Card>
            <Fields orientation="horizontal">
                <Field title="Предельный размер обеспечения заявки, %" preset="readonlyValue">
                    {formatNumber(provision?.maxPriceQuotationSession ?? 0, true, 0)}
                </Field>
            </Fields>
        </Card>
    );
};
