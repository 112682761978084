import React  from "react";
import { Card } from "@/components/Card";
import { ContractPayment } from "@/models/Contracts/ContractPayment";
import { Field, Fields } from "@/components/form/Field";
import { Button, TextBox } from "@/components/primitive";
import { HStack } from "@/components/layouts";
import { CustomerInfoModal } from "@/views/Payments/shared/modals/CustomerInfoModal";
import { showModal } from "@/EventBus";
import { formatDate } from "@/dateUtils";
import { formatNumber } from "@/NumberFormatting";
import { UnderlinedVueLink } from "@/components/VueLink";
import { addDays } from "date-fns";

type InfoSectionProps = {
    payment?: ContractPayment
};

export const InfoSection = ({ payment }: InfoSectionProps) => {
    const showCustomerInfo = async () => {
        payment && await showModal(CustomerInfoModal, { customer: payment.customer });
    };

    return (
        <Card>
            <Fields orientation="horizontal" spacing="10px" style={{ fontSize: 14 }}>
                <Field title="РЕГИСТРАЦИОННЫЙ НОМЕР:">
                    {payment?.regNumber ?? ""}
                </Field>
                <Field title="ДАТА РЕГИСТРАЦИИ:">
                    {payment?.registeredAt ? formatDate(payment.registeredAt) : ""}
                </Field>
                <Field title="ЗАКАЗЧИК:">
                    <HStack>
                        <TextBox value={payment?.customer.shortName} readonly />
                        <Button color="white"
                                icon="faExternalLinkAlt"
                                onClick={showCustomerInfo}
                                style={{ borderTopLeftRadius: 0, borderBottomLeftRadius: 0 }} />
                    </HStack>
                </Field>
                <Field title="КБК:">
                    {payment?.kbk ?? ""}
                </Field>
                <Field title="ДАТА СПИСАНИЯ СО СЧЕТА ПЛАТЕЛЬЩИКА:">
                    {payment?.registeredAt ? formatDate(addDays(payment.registeredAt, 1)) : ""}
                </Field>
                <Field title="ДАТА ПОСТУПЛЕНИЯ В БАНК ПЛАТЕЛЬЩИКА:">
                    {payment?.registeredAt ? formatDate(addDays(payment.registeredAt, 1)) : ""}
                </Field>
                <Field title="СУММА , РУБ.:">
                    {payment?.totalPrice ? formatNumber(payment.totalPrice) : ""}
                </Field>
                <Field title="ДЕНЕЖНОЕ ОБЯЗАТЕЛЬСТВО:">
                    {payment?.obligation && (
                        <UnderlinedVueLink href={`/contracts/obligations/${payment.obligation.id}`}>
                            {payment.obligation.regNumber}
                        </UnderlinedVueLink>
                    )}
                </Field>
                <Field title="ОБРАЗЫ ДОКУМЕНТА:">
                    <UnderlinedVueLink href="#">report.pdf</UnderlinedVueLink>
                </Field>
            </Fields>
        </Card>
    );
};
