import React, {useEffect, useMemo, useRef, useState} from "react";
import styles from "./SearchSelect.module.css";
import {ComboBoxProps} from "@/components/primitive/ComboBox";
import {FontIcon, mergeStyles} from "office-ui-fabric-react";
import {clsx} from "@/functionUtils";
import {SelectOption} from "@/models/enums";
import {useOnClickOutside} from "@/reactUtils";

interface SearchSelectProps<T> extends Omit<ComboBoxProps<T>, "onChangeNonStrict"> {
    placeholder?: string
}

const chevronDownIconClass = mergeStyles({
    position: "absolute",
    fontSize: 12,
    top: 0,
    lineHeight: 30,
    right: 8,
    height: 32,
    width: 64,
    textAlign: "end",
    userSelect: "none",
    cursor: "pointer"
});

export const SearchSelect = <T, >(props: SearchSelectProps<T>) => {
    type ValueType = typeof props.value;

    const [query, setQuery] = useState<string>("");
    const [isSelectOpen, setIsSelectOpen] = useState<boolean>(false);
    const [items, setItems] = useState<SelectOption<ValueType>[]>(props.options);

    const listRef = useRef<HTMLUListElement>(null);
    const containerRef = useRef<HTMLDivElement>(null);

    const coords = useMemo(() => {
        const rect = containerRef.current?.getBoundingClientRect();

        return rect ? { top: rect.top, left: rect.left, width: rect.width } : null;
    }, [containerRef.current]);

    const openSelect = () => setIsSelectOpen(isOpen => !isOpen);
    const selectItem = (item: ValueType, itemDesc: string) => {
        setQuery(itemDesc);
        setIsSelectOpen(false);
        setItems(props.options);
        item && props.onChange?.(item);
    };

    useEffect(() => {
        if (query === "") {
            setItems(props.options);
            return;
        }

        const target = query.toLowerCase().trim();

        const newItems = props.options.filter(item => item.desc
            .toLowerCase()
            .includes(target)
        );

        if (newItems.length === 0) {
            setItems([{ key: undefined, desc: "Поиск не дал результатов", disabled: true }]);
            return;
        }

        setItems(newItems);
    }, [query]);

    useOnClickOutside(listRef, openSelect);

    return (
        <div className={styles.container} ref={containerRef}>
            <input type="text"
                   placeholder={props.placeholder}
                   value={query}
                   className={styles.queryInput}
                   onChange={e => setQuery(e.target.value)} />
            <FontIcon iconName="ChevronDown" className={chevronDownIconClass} onClick={openSelect} />
            {(isSelectOpen && coords) &&
                <ul className={styles.selectList}
                    style={{ top: coords.top, left: coords.left, width: coords.width }}
                    ref={listRef}>
                    {items.map(option =>
                        <li key={option.desc}
                            className={clsx(
                                styles.selectItem,
                                option.key === props.value && styles.activeItem,
                                option.disabled && styles.itemDisabled
                            )}
                            onClick={() => selectItem(option.key, option.desc)}>
                            <span className={styles.itemDesc}>{option.desc}</span>
                        </li>)}
                </ul>}
        </div>
    );
};
