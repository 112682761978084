import {observer} from "mobx-react";
import React, {useState} from "react";
import styles from "./SubjectClassTree.module.css";
import {Input} from "@/components/eshop/Input/Input";
import {ISubjectClassContainer, SubjectClassFilterStore} from "@/components/eshop/SubjectClassFilterStore";
import {Checkbox} from "@/components/eshop";
import {ChevronButton} from "@/components/eshop/IconButton/IconButtons";
import {j} from "@/reactUtils";

type SubjectClassSelectorProps = {
    store: SubjectClassFilterStore
    onSelect?: (entry: ISubjectClassContainer) => void
    default?: string
    value?: ISubjectClassContainer | null
    showTree?: boolean
};

export const SubjectClassTree = observer((props: SubjectClassSelectorProps) => {
    const { filter, filteredDataset, selectEntry, selectedEntry, toggleCollapseItem, setFilter } = props.store;
    const [selectedItem, setSelectedItem] = useState<ISubjectClassContainer>();
    selectEntry(props.value ?? null);

    return <div className={styles.selectTreeContainer}>
        <div style={{width: "100%"}}>
            <Input
                value={filter}
                onChange={e => setFilter(e.target.value)}
                placeholder="Введите поисковый запрос"
                fullWidth
                label={"Наименование / Код КПГЗ"}
                background={"#fff"}/>
            <div className={styles.treeContainer}>
                {filteredDataset.map(d => <div
                    className={j(styles.dataRow, d.children.length && d.collapsed && styles.dataRowHasChildrenCollapsed, d.children.length && styles.dataRowHasChildren)}
                    style={{ marginLeft: d.item.level * 10 + 10 }}
                    key={d.item.id}>
                    <div style={{display: "flex", alignItems: "center"}}>
                        <Checkbox bold value={d.item.id === selectedItem?.id} onChange={() => {
                            setSelectedItem(d.item);
                            props.onSelect?.(d.item);
                        }} />
                        <div className={`${styles.itemName} ${selectedEntry?.id === d.item.id && styles.itemNameSelected}`}>
                            {d.item.object.code} {d.item.object.description}
                        </div>
                    </div>
                    {d.children.length > 0 && <ChevronButton hoverType={"color"} rotated={!d.collapsed} onClick={e => toggleCollapseItem(d.actualIndex)} />}
                </div>)}
            </div>
        </div>
    </div>;
});
