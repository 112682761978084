import {autorun, makeAutoObservable} from "mobx";
import {NewsApi, NewsArticle, NewsArticleLabel} from "@/api/News";
import React, {ReactNode} from "react";
import {debouncedAsync} from "@/functionUtils";
import {IPagedItems} from "@/api/Pagination";

export class NewsPageViewModel {
    constructor() {
        makeAutoObservable(this);
        this.reload();
    }

    public error: ReactNode | string | null = null;
    public spin: boolean = false;
    public pageSpin: boolean = false;
    public reachedEnd: boolean = false;

    private _query: string | null = null;
    private _label: NewsArticleLabel | null = null;


    public get query() {
        return this._query;
    }

    public get label() {
        return this._label;
    }

    public set setQuery(q: string | null) {
        this._label = null;
        this._query = q;
        this.reload();
    }
    public set setLabel(l: NewsArticleLabel | null) {
        this._label = l;
        this._query = null;
        this.reload();
    }

    public items: NewsArticle[] = [];
    private _page: IPagedItems<NewsArticle> | null = null;

    public dispose() {

    }

    public reload = async (reset?: boolean) => {
        try {
            this.spin = true;
            let filters = {};
            if (!reset) {
                if (this.label) {
                    filters = { "label": [{ type: "GUID", conditionType: "EQUAL", guid: this.label.id }] };
                } else if (this.query) {
                    filters = { "title": [{ type: "STRING", conditionType: "CONTAINS", string: this.query }] };
                }
            }
            if (reset) {
                this._label = null;
                this._query = null;
            }
            const result = await NewsApi.findAll({ filters });
            this.items = result.items;
            this._page = result;
            if (result.items.length === 0) {
                this.error = <>По вашему запросу <b>“{this.query}”</b> записей не найдено</>;
            } else {
                this.error = null;
            }
        } catch (e) {
            this.error = "Ошибка загрузки новостей";
        } finally {
            this.spin = false;
        }
    };

    public loadMore = debouncedAsync(500, async () => {
        if (this.pageSpin) return;
        if (this.reachedEnd) return;
        if (this._page) {
            if (this._page.to >= this._page.totalCount - 1) {
                this.reachedEnd = true;
                return;
            }
        }
        try {
            this.pageSpin = true;
            const result = await NewsApi.findAll({ from: this._page?.to });
            this.items = [...this.items, ...result.items];
            this._page = result;
            if ((result.to + 1) >= result.totalCount) {
                this.reachedEnd = true;
            }
        } catch (e) {
            // setError("Ошибка загрузки новостей");
        } finally {
            this.pageSpin = false;
        }
    });
}
