import {http, IPagedRequest, prepareRequest} from "@/api/http";
import {IPagedItems} from "@/api/Pagination";
import {MediaItem} from "@/api/Media";

export type NewsArticleLabel = {
    color: string
    id: string
    title: string
};

export type NewsArticle = {
    content: string // markdown
    createdAt: string // Instant
    description?: string | null
    media?: MediaItem | null
    urlImage?: string | null
    label?: NewsArticleLabel | null
    title: string
    updatedAt: string // Instant
    id: string
};

export type NewsArticleUpdate = {
    id?: string
    content?: string
    labelId?: string
    title?: string
    description?: string | null
    mediaId?: string
};

export const convertNewsArticleToUpdate = (v: NewsArticle): NewsArticleUpdate => ({
    id: v.id,
    content: v.content,
    labelId: v.label?.id,
    title: v.title,
    description: v.description,
    mediaId: v.media?.id
});

export const NewsApi = {
    async create(data: NewsArticleUpdate): Promise<NewsArticle> {
        const result = await http.post("/api/v2/admin/news", data);

        return result;
    },
    async update(data: NewsArticleUpdate): Promise<NewsArticle> {
        const result = await http.post("/api/v2/admin/news/" + data.id, data);

        return result;
    },
    async remove(id: string): Promise<void> {
        const result = await http.delete("/api/v2/admin/news/" + id);

        return result;
    },
    async findAll(request: IPagedRequest): Promise<IPagedItems<NewsArticle>> {
        const result = await fetch("/api/v2/news?request=" + prepareRequest({ ...request, sortDescending: true, sortBy: "createdAt" })).then(r => r.json());
        console.log(`news request:`, {request}, "response:", {result});

        return result as IPagedItems<NewsArticle>;
    },
    async findLabels(): Promise<NewsArticleLabel[]> {
        const result = await fetch("/api/v2/news/labels").then(r => r.json());

        return result as NewsArticleLabel[];
    }};
