// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, "._1EP4CUf8ct4ntfkwg0qnDR{\r\n    margin-right: 5px;\r\n    display: flex;\r\n    flex-direction: column;\r\n}\r\n\r\n._1YIi7jiJw0jJSwi6orTAtl{\r\n    width: 10px;\r\n    height: 6px;\r\n    background-repeat: no-repeat;\r\n    background-position: center;\r\n    background-size: 18px 19px;\r\n    color: #a4b1b7;\r\n    background-image: url(\"https://eaist2-f.mos.ru/eshop/img/arrow.53f683772482a0f68c17.svg\");\r\n    cursor: pointer;\r\n}\r\n\r\n.x8fMnI71Xyjxbnv2IhAoQ{\r\n    transform: rotate(180deg);\r\n    margin-bottom: 5px;\r\n}\r\n\r\n._3ff-bx3i8EYmnnh5cGOxyc{\r\n    background-image: url(\"https://eaist2-f.mos.ru/eshop/img/arrow-selected.0d21081ce5faafeab1a8.svg\") !important;\r\n    /*margin-bottom: 5px;*/\r\n}\r\n\r\n.J7-m1LdB8NBt_IQdyO-40{\r\n    background-image: url(\"https://eaist2-f.mos.ru/eshop/img/arrow-selected.0d21081ce5faafeab1a8.svg\") !important;\r\n    transform: rotate(180deg);\r\n    /*margin-bottom: 5px;*/\r\n}\r\n", ""]);
// Exports
exports.locals = {
	"iconSortContainer": "_1EP4CUf8ct4ntfkwg0qnDR",
	"eshopSortButton": "_1YIi7jiJw0jJSwi6orTAtl",
	"sortAsc": "x8fMnI71Xyjxbnv2IhAoQ",
	"sortDescSelected": "_3ff-bx3i8EYmnnh5cGOxyc",
	"sortAscSelected": "J7-m1LdB8NBt_IQdyO-40"
};
module.exports = exports;
