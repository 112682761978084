import VueRouter, { RouteRecord } from "vue-router";
import router from "./router";
import EventBus from "./EventBus";
import { Vue, Component } from "vue-property-decorator";

export declare interface IRoutedModal {
    source: string
    target: { state: "UNRESOLVED" } | { state: "RESOLVED"; route: string } | { state: "RETURNED"; result: object }
    data: object
}

function load<T>(s: string): T | null {
    const item = sessionStorage.getItem(s);
    if (item === null) return null;

    return JSON.parse(item);
}

function store(s: string, v: object | null) {
    if (v === null) sessionStorage.removeItem(s);
    else sessionStorage.setItem(s, JSON.stringify(v));
}

export function getRoutedModal(): IRoutedModal | null {
    return load<IRoutedModal>("routed-modal");
}

function setRoutedModal(modal: IRoutedModal | null): void {
    return store("routed-modal", modal);
}

export function prepareRoutedModal(serializableProp: object) {
    setRoutedModal({
        source: router.currentRoute.path,
        data: serializableProp,
        target: { state: "UNRESOLVED" },
    });
}

export async function setupRoutedModals(r: VueRouter) {
    r.beforeEach(async (to, from, next) => {
        const routedModal = getRoutedModal();
        if (routedModal === null) {
            next();
            return;
        }

        if (routedModal.target.state === "RETURNED") {
            setRoutedModal(null);
            next();
            return;
        }

        switch (routedModal.target.state) {
            case "UNRESOLVED":
                if (from.name === null || from.path !== routedModal.source) {
                    setRoutedModal(null);
                    next();
                    return;
                }

                setRoutedModal({ ...routedModal, target: { state: "RESOLVED", route: to.path } });
                console.log("routed modal resolved to path: ", to.path);
                next();
                return;
            case "RESOLVED":
                if (to.path === routedModal.source && from.name !== null && from.path === routedModal.target.route) {
                    const promise = new Promise<object>(resolve => {
                        EventBus.$emit("request-routed-modal-result", resolve);
                    });
                    const timer = new Promise(resolve => setTimeout(resolve, 10));

                    const raceWinner = await Promise.race([promise, timer]);
                    if (raceWinner === timer) {
                        console.error("could not get result from routed modal");
                        setRoutedModal(null);
                        next();
                    } else {
                        console.log("routed-modal result:", await promise);
                        setRoutedModal({ ...routedModal, target: { state: "RETURNED", result: await promise } });
                        next();
                    }
                } else if (to.path === routedModal.source) {
                    setRoutedModal(null);

                    next();
                    return;
                } else {
                    next();
                    return;
                }

            // there are a lot of uncovered navigation cases that i won't implement because of
            // their rarity (impossibility)
        }
    });
}

@Component
export class RoutedModalReceiver<T extends object> extends Vue {
    protected routedResult: T | null = null;

    protected created() {
        const routedModal = getRoutedModal();
        if (
            routedModal === null ||
            routedModal.source !== router.currentRoute.path ||
            routedModal.target.state !== "RETURNED"
        )
            return;

        this.routedResult = routedModal.target.result as T;
    }
}

@Component
export class RoutedModal<TIn extends object, TOut extends object> extends Vue {
    protected routedProp: TIn | null = null;

    protected created() {
        const routedModal = getRoutedModal();

        if (
            routedModal === null ||
            routedModal.target.state !== "RESOLVED" ||
            routedModal.target.route !== router.currentRoute.path
        )
            return;

        this.routedProp = routedModal.data as TIn;

        EventBus.$on("request-routed-modal-result", this.saveState);
    }

    protected beforeDestroy() {
        EventBus.$off("request-routed-modal-result", this.saveState);
    }

    protected saveState(_: (x: TOut) => void) {
        throw new Error("not implemented");
    }
}
