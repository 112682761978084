import React, {useState} from "react";
import styles from "./Section.module.css";
import {Button} from "@/components/primitive";
import {j} from "@/reactUtils";
interface SectionProps {
    children?: React.ReactNode
    title: string | React.ReactNode
    required?: boolean
    hidden?: boolean
    canBeHidden?: boolean
}

export const Section = (props: SectionProps) => {

    const [hidden, setHidden] = useState<boolean>(false);

    return (
        <div className={styles.section__wrapper}>
            <div className={styles.section__title}>
                {props.title}
                {props.required ? <span className={styles.required}>*</span> : ''}
                {props.canBeHidden &&
                    <div className={styles.section__title__button} onClick={() => {props.canBeHidden && setHidden(!hidden)}}>
                        <span>
                            {hidden ? "развернуть" : "свернуть"}
                        </span>
                        <span style={hidden ? {transform: "rotate(180deg)"} : {}}
                              className={styles.section__title__chevron}>
                            <svg width="12" height="8" viewBox="0 0 12 8" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
                                <path d="M5.28999 0.710012L0.699991 5.30001C0.536164 5.49131 0.450558 5.73739 0.460279 5.98907C0.470001 6.24074 0.574333 6.47948 0.752428 6.65758C0.930522 6.83567 1.16926 6.94 1.42094 6.94972C1.67261 6.95944 1.91869 6.87384 2.10999 6.71001L5.99999 2.83001L9.87999 6.71001C9.96964 6.81469 10.08 6.89971 10.204 6.95974C10.3281 7.01976 10.4632 7.05349 10.6009 7.05881C10.7387 7.06413 10.876 7.04092 11.0043 6.99065C11.1326 6.94038 11.2492 6.86412 11.3466 6.76666C11.4441 6.66921 11.5204 6.55266 11.5706 6.42434C11.6209 6.29601 11.6441 6.15868 11.6388 6.02096C11.6335 5.88324 11.5997 5.74811 11.5397 5.62404C11.4797 5.49998 11.3947 5.38966 11.29 5.30001L6.69999 0.710012C6.51263 0.523761 6.25918 0.41922 5.99499 0.41922C5.73081 0.41922 5.47735 0.523761 5.28999 0.710012Z" fill="black"/>
                            </svg>
                        </span>
                    </div>
                }
            </div>
            <div className={j(styles.section__content, hidden && styles.section__content_hidden)}>
                {props.children}
            </div>
        </div>
    );
};
