import React from "react";
import { observer } from "mobx-react";
import { TooltipIcon } from "@/components/TooltipIcon";
import styles from './Header.module.css';
import { ServicesStore, toggleFilterOpened, toggleNameSelected } from "./store";

export const Header = observer(({ store }: HeaderProps) => <div className={styles.headerContainer}>
    <div className={styles.pageHeader}>Работы и услуги - (СПГЗ)</div>
    <ul className={styles.iconsList}>
        {store.isFilterOpened && <li className={styles.radioButtonsContainer}>
            Поиск по:
            <span className={`${styles.eaistRadioButton} ${store.isSelectedName ? styles.eaistRadioButtonActive : ""}`} onClick={() => toggleNameSelected()}>
                Наименованию СПГЗ
            </span>
            <span className={`${styles.eaistRadioButton} ${!store.isSelectedName ? styles.eaistRadioButtonActive : ""}`} onClick={() => toggleNameSelected()}>
                Идентификатору СПГЗ
            </span>
        </li>}
        <li className={`${styles.iconControl} ${styles.filter}`} onClick={() => toggleFilterOpened()} title="Показать/скрыть фильтр" id="filter_icon" />
        <li className={`${styles.iconControl} ${styles.refresh}`} title="Сбросить параметры поиска" />
        <li className="not-hover">
            <TooltipIcon />
        </li>
    </ul>
</div>);

type HeaderProps = {
    store: ServicesStore
};