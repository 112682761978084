











import {Component, Vue, Prop} from "vue-property-decorator";
import {EditLot} from './EditLot';
import {createLotStore} from "@/views/ComposedLot/shared/store";
import {OnCalculationDone, StartPriceCalculator} from "@/views/ComposedLot/edit/start-price/StartPriceCalculator";
import router from "@/router";
import {Store} from "effector";
import {CalculatorStore, createCalculatorStore} from "@/views/ComposedLot/edit/start-price/store";
import {MeasurementUnit, SubjectDeclaration} from "@/models";
import { Decimal } from 'decimal.js';
import {StartPrice} from "@/models/ComposedLots/specifications/StartPrice";
import {SpecificationDraft} from "@/models/ComposedLots/specifications";

export type EditLotVueStubMode = "main" | "start-price";

@Component({ components: { "main-component": EditLot as unknown as typeof Vue, "start-price-component": StartPriceCalculator as unknown as typeof Vue } })
export default class EditLotVueStub extends Vue {
    @Prop() public objectId!: string;

    public get mode(): EditLotVueStubMode { return (router.currentRoute.query["vsm"] ?? "main") as EditLotVueStubMode }
    public formStoreContainer = Object.freeze({ store: createLotStore({ law: "F44", id: this.objectId }) });

    public onCalculationDone?: OnCalculationDone;
    public calculatorStoreContainer: Readonly<{ store: Store<CalculatorStore> }> | null =
        Object.freeze({
            store: createCalculatorStore({
                id: undefined,
                isNew: true,
                subjectDeclaration: {
                    id: 11331041,
                    subject: "Placeholder (грейдер)"
                } as SubjectDeclaration,
                volume: new Decimal(123),
                measurementUnit: {
                    id: 123123,
                    name: "штука"
                } as MeasurementUnit
            } as SpecificationDraft) });

    public created() {
        if (this.mode !== "main") {
            router.back();
        }
    }

    public calculateStartPrice(specification: SpecificationDraft, onDone: OnCalculationDone) {
        this.onCalculationDone = onDone;
        this.calculatorStoreContainer = Object.freeze({ store: createCalculatorStore(specification) });

        router.push({
            ...router.currentRoute,
            name: router.currentRoute.name ?? undefined,
            query: {...router.currentRoute.query, vsm: "start-price"}
        });
    }

    public calculationDone(startPrice: StartPrice | undefined) {
        this.onCalculationDone?.(startPrice);

        router.back();
    }
}
