































import { ITableColumn, IRowConfiguration } from "@/components/TableAbstractions";
import EventBus, { showModal } from "@/EventBus";
import { Component, Vue, Prop } from "vue-property-decorator";
import DataTable from "@/components/vue/DataTable.vue";
import AddDocument from "./AddDocument.vue";
import { ContractDocument, Contract } from '../../models/Contracts';
import { SimpleRow } from '../../components/vue/form-table/FormFields';
import { IFieldProps } from '../../components/vue/form-table/FieldBase';
import { formatDate } from '../../DateFormatting';
import { ContractScope } from './ContractScope';
import ContractDocuments from '../../api/ContractDocuments';
import { LawType } from "../../models/enums";
import { ConfirmationModal } from "@/views/Contracts/modals/ConfirmationModal";
import {isConcludedEshopContract} from "@/form-declarations/contractStageActions";
import { ContractExecutionOperationState } from "@/models/enums/Contracts";

@Component({ components: { DataTable } })
export default class Documents extends Vue {
    @Prop() public props!: IFieldProps<SimpleRow<ContractScope>>;

    public isActionsLocked = isConcludedEshopContract(this.props.source.contract);

    get headers(): ITableColumn<ContractDocument>[] {
        return [
            {
                title: "№",
                getter: (p, i) => (i + 1).toString(),
                size: "auto",
            },
            {
                title: "Тип документа",
                getter: p => {
                    if (p.type.name.includes("Контракт") && this.props.source.contract.law == LawType.F223) {
                        return p.type.name.replace(/Контракт/, "Договор");
                    } else if (p.type.name.includes("контракт") && this.props.source.contract.law == LawType.F223) {
                        return p.type.name.replace(/контракт/, "договор");
                    }
                    else return p.type.name;
                },
            },
            {
                title: "Номер документа",
                getter: p => p.documentNumber,
            },
            {
                title: "Дата документа",
                getter: p => formatDate(p.date, true),
            },
            {
                title: "​Наименование документа",
                getter: p => p.file.comment,
            },
            ...(
                this.props.source?.contract?.law === "F223" ? [
                  {
                    title: "Обязательность отправки в ЕИС",
                    getter: (p: ContractDocument) => p.isSendingToEisRequired223 ? "Да" : "Нет",
                  },
                  {
                    title: "Размер",
                    getter: (p: ContractDocument) => p.file.length
                  }
                ] : []
            ),
            {
                title: "Дата добавления",
                getter: p => formatDate(p.createdAt, true),
            },
        ];
    }

    public createConfig(): IRowConfiguration<ContractDocument> {
        const that = this;

        return  {
            get useSlotForActionColumn() { return that.props.state === 'ENABLED' && !that.isActionsLocked }
        };
    }

    public config = this.createConfig();

    public get isSigned() {
        return this.props.source.executionHistory.some(exec => exec.state === ContractExecutionOperationState.SIGNED);
    }

    public get dataset(): ContractDocument[] {
        //sc#921 workaround: show electron contract doc only after sign
        return this.isSigned ? this.props.source.documents : this.props.source.documents.filter(doc => doc.type.id !== 16);
    }

    public addEditDocument = async (src: ContractDocument | null) => {
      console.log(this.props.source.documents);
        const props = {
            contract: this.props.source.contract!, //#398
            document: src,
            documentGroup: "document",
            createAction: (r: ContractDocument) => ContractDocuments.create(this.props.source.contract!.id, r),
            contractScope: this.props.source
        };
        const result = await EventBus.callModal<typeof props, ContractDocument>(AddDocument, props);
        if (result.isOk) {
            const r = result.getResult();
            if (!src) {
                this.props.source.documents.push(r);
            } else {
                const ix = this.props.source.documents.findIndex(x => x.id === r.id);
                this.props.source.documents.splice(ix, 1, r);
            }
        }
    };

    public deleteDocument = async (src: ContractDocument) => {
        const confirmation = await showModal(ConfirmationModal, {title: "Удаление", text: "Вы уверены, что хотите удалить документ?"});
        if (!confirmation)
            return;

        await ContractDocuments.delete(src);

        const ix = this.props.source.documents.indexOf(src);
        if (ix !== -1) this.props.source.documents.splice(ix, 1);
    };
}
