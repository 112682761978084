import React from "react";
import {
    Column,
    FilterColumn,
    FilteredSelectionTable,
    FilterStore,
    Table
} from "@/components/table";
import { ContractPayment } from "@/models/Contracts/ContractPayment";
import { ContractPaymentsFilters } from "@/views/Payments/list/store";
import { UnderlinedVueLink } from "@/components/VueLink";
import { formatDate } from "@/dateUtils";
import { formatNumber } from "@/NumberFormatting";

type PaymentsTableProps = {
    payments: ContractPayment[]
    filterStore: FilterStore<ContractPaymentsFilters>
    isFiltersVisible: boolean
    selected?: ContractPayment
    onChange?: (selected: ContractPayment | undefined) => void
};

export const PaymentsTable = (
    {
        payments,
        filterStore,
        isFiltersVisible,
        selected,
        onChange
    }: PaymentsTableProps) => {
    const columns: Column<ContractPayment>[] = [
        FilterColumn<ContractPayment, ContractPaymentsFilters>({
            header: "Регистрационный номер",
            filter: {
                key: "regNumber",
                type: "string"
            },
            cell: ({ item }) => (
                <UnderlinedVueLink href={`/contracts/payments/${item.id}`}>{item.regNumber}</UnderlinedVueLink>
            )
        }),
        FilterColumn<ContractPayment, ContractPaymentsFilters>({
            header: "Дата регистрации",
            filter: {
                key: "registeredAt",
                type: "date"
            },
            cell: ({ item }) => <>{formatDate(item.registeredAt)}</>
        }),
        FilterColumn<ContractPayment, ContractPaymentsFilters>({
            header: "КБК",
            filter: {
                key: "kbk",
                type: "string"
            },
            cell: ({ item }) => <>{item.kbk}</>
        }),
        FilterColumn<ContractPayment, ContractPaymentsFilters>({
            header: "Сумма, руб.",
            filter: {
                key: "totalPrice",
                type: "decimal"
            },
            cell: ({ item }) => <>{formatNumber(item.totalPrice)}</>
        }),
        FilterColumn<ContractPayment, ContractPaymentsFilters>({
            header: "Контракт/Договор",
            filter: {
                key: "contractNumber",
                type: "string",
                hideFilterInput: true
            },
            cell: ({ item }) => <>{item?.obligation?.contract.regNumber}</>
        }),
        FilterColumn<ContractPayment, ContractPaymentsFilters>({
            header: "Регистрационный номер ДО",
            filter: {
                key: "obligationRegNumber",
                type: "string"
            },
            cell: ({ item }) => <>{item?.obligation?.regNumber}</>
        }),
        Table.Column(
            "Участие в этапе договора",
            () => <>Нет</>
        )
    ];

    return (
        <FilteredSelectionTable
            filterStore={filterStore}
            isFiltersVisible={isFiltersVisible}
            columns={columns}
            dataset={payments}
            mode="single"
            selected={selected}
            onChange={onChange}
            selectorPosition={onChange ? "atLeft" : "hidden"}
        />
    );
};
