import { http } from "./http";
import { ContractStagePaymentInfo } from '@/models/Contracts/ContractStagePaymentInfo';
import { IDtoContractStagePaymentInfo } from '@/models/json/Contracts/IDtoContractStagePaymentInfo';
import { ContractStageExecutionInfo, ContractDocument, ContractStageExecutionInfoCreationDto, ContractStageAcceptanceInfo, ContractStage, ContractStageDissolutionInfo } from '@/models/Contracts';
import { IDtoContractStageExecutionInfo } from '@/models/json/Contracts/IDtoContractStageExecutionInfo';
import { IDtoContractStageAcceptanceInfo } from '@/models/json/Contracts/IDtoContractStageAcceptanceInfo';
import { ContractStageScope, ContractScope } from '@/views/CreateContract/ContractScope';
import { IDtoContractStage } from '@/models/json/Contracts';
import { IDtoContractStageDissolutionInfo } from '@/models/json/Contracts/IDtoContractStageDissolutionInfo';

interface DtoContractStageScope {
    stage: IDtoContractStage
    executionInfo: IDtoContractStageExecutionInfo[]
    paymentInfo: IDtoContractStagePaymentInfo[]
    acceptanceInfo: IDtoContractStageAcceptanceInfo[]
    dissolutionInfo: IDtoContractStageDissolutionInfo[]
}

export class Stages {
    private static mapDtoScope(dto: DtoContractStageScope, contractScope: ContractScope): ContractStageScope {
        return {
            contractScope: contractScope,
            stage: ContractStage.fromJson(dto.stage),
            executionInfo: dto.executionInfo.map(ContractStageExecutionInfo.fromJson),
            paymentInfo: dto.paymentInfo.map(ContractStagePaymentInfo.fromJson),
            acceptanceInfo: dto.acceptanceInfo.map(ContractStageAcceptanceInfo.fromJson),
            dissolutionInfo: dto.dissolutionInfo.map(ContractStageDissolutionInfo.fromJson)
        };
    }

    public static async getFromContract(contractScope: ContractScope): Promise<ContractStageScope[]> {
        return (await http.get(`/api/contractStages/fromContract/${contractScope.contract.id}`, undefined) as DtoContractStageScope[])
            .map(x => Stages.mapDtoScope(x, contractScope));
    }

    public static async register(contractScope: ContractScope, stageId: string, completed: boolean, isEis: boolean): Promise<ContractStageScope> {
        return Stages.mapDtoScope(await http.post(`/api/contractStages/${stageId}/register?completed=${completed}&eis=${isEis}`, undefined, "Ошибка при регистрации"), contractScope);
    }

    public static async makeChanges(contractScope: ContractScope, stageId: string): Promise<ContractStageScope> {
        return Stages.mapDtoScope(await http.post(`/api/contractStages/${stageId}/makeChanges`, undefined), contractScope);
    }

    public static async rollback(contractScope: ContractScope, stageId: string): Promise<ContractStageScope> {
        return Stages.mapDtoScope(await http.post(`/api/contractStages/${stageId}/rollback`, undefined), contractScope);
    }
}

export class StagePayment {
    public static async getFromStage(stageId: string): Promise<ContractStagePaymentInfo[]> {
        return (await http.get(`/api/stagePayment/fromStage/${stageId}`, undefined) as IDtoContractStagePaymentInfo[])
            .map(ContractStagePaymentInfo.fromJson);
    }

    public static async create(stageId: string, src: ContractStagePaymentInfo): Promise<ContractStagePaymentInfo> {
        return ContractStagePaymentInfo.fromJson(await http.post(`/api/stagePayment/createFor/${stageId}`, src.toJson()));
    }

    public static async generateFromEis(stageId: string): Promise<ContractStagePaymentInfo> {
        return ContractStagePaymentInfo.fromJson(await http.post(`/api/stagePayment/generate/${stageId}`, undefined));
    }

    public static async update(obj: ContractStagePaymentInfo): Promise<ContractStagePaymentInfo> {
        return ContractStagePaymentInfo.fromJson(await http.put(`/api/stagePayment/${obj.id}`, obj.toJson()));
    }

    public static async delete(obj: ContractStagePaymentInfo): Promise<void> {
        await http.delete(`/api/stagePayment/${obj.id}`);
    }
}

export class StageExecution {
    public static async getFromStage(stageId: string): Promise<ContractStageExecutionInfo[]> {
        return (await http.get(`/api/stageExecution/fromStage/${stageId}`, undefined) as IDtoContractStageExecutionInfo[])
            .map(ContractStageExecutionInfo.fromJson);
    }

    public static async create(stageId: string, src: ContractStageExecutionInfoCreationDto): Promise<ContractStageExecutionInfo> {
        return ContractStageExecutionInfo.fromJson(await http.post(`/api/stageExecution/createFor/${stageId}`, src.toJson()));
    }

    public static async generateFromEis(stageId: string): Promise<ContractStageExecutionInfo> {
        return ContractStageExecutionInfo.fromJson(await http.post(`/api/stageExecution/generate/${stageId}`, undefined));
    }

    public static async update(obj: ContractStageExecutionInfo): Promise<ContractStageExecutionInfo> {
        return ContractStageExecutionInfo.fromJson(await http.put(`/api/stageExecution/${obj.id}`, obj.toJson()));
    }

    public static async delete(obj: ContractStageExecutionInfo): Promise<void> {
        await http.delete(`/api/stageExecution/${obj.id}`);
    }

    public static async addDocument(id: string, doc: ContractDocument): Promise<ContractDocument> {
        return ContractDocument.fromJson(await http.post(`/api/stageExecution/${id}/document`, doc.toJson()));
    }

    public static async deleteDocument(id: string, doc: ContractDocument): Promise<ContractStageExecutionInfo> {
        return ContractStageExecutionInfo.fromJson(await http.delete(`/api/stageExecution/${id}/document/${doc.id}`));
    }
}

export class StageAcceptance {
    public static async getFromStage(stageId: string): Promise<ContractStageAcceptanceInfo[]> {
        return (await http.get(`/api/stageAcceptance/fromStage/${stageId}`, undefined) as IDtoContractStageAcceptanceInfo[])
            .map(ContractStageAcceptanceInfo.fromJson);
    }

    public static async create(stageId: string, src: ContractStageAcceptanceInfo): Promise<ContractStageAcceptanceInfo> {
        return ContractStageAcceptanceInfo.fromJson(await http.post(`/api/stageAcceptance/createFor/${stageId}`, src.toJson()));
    }

    public static async update(obj: ContractStageAcceptanceInfo): Promise<ContractStageAcceptanceInfo> {
        return ContractStageAcceptanceInfo.fromJson(await http.put(`/api/stageAcceptance/${obj.id}`, obj.toJson()));
    }

    public static async delete(obj: ContractStageAcceptanceInfo): Promise<void> {
        await http.delete(`/api/stageAcceptance/${obj.id}`);
    }

    public static async addDocument(id: string, doc: ContractDocument): Promise<ContractDocument> {
        return ContractDocument.fromJson(await http.post(`/api/stageAcceptance/${id}/document`, doc.toJson()));
    }

    public static async deleteDocument(id: string, doc: ContractDocument): Promise<ContractStageAcceptanceInfo> {
        return ContractStageAcceptanceInfo.fromJson(await http.delete(`/api/stageAcceptance/${id}/document/${doc.id}`));
    }
}

export class StageDissolution {
    public static async getFromStage(stageId: string): Promise<ContractStageDissolutionInfo[]> {
        return (await http.get(`/api/stageDissolution/fromStage/${stageId}`, undefined) as IDtoContractStageDissolutionInfo[])
            .map(ContractStageDissolutionInfo.fromJson);
    }

    public static async create(stageId: string, src: ContractStageDissolutionInfo): Promise<ContractStageDissolutionInfo> {
        return ContractStageDissolutionInfo.fromJson(await http.post(`/api/stageDissolution/createFor/${stageId}`, src.toJson()));
    }

    public static async update(obj: ContractStageDissolutionInfo): Promise<ContractStageDissolutionInfo> {
        return ContractStageDissolutionInfo.fromJson(await http.put(`/api/stageDissolution/${obj.id}`, obj.toJson()));
    }

    public static async delete(obj: ContractStageDissolutionInfo): Promise<void> {
        await http.delete(`/api/stageDissolution/${obj.id}`);
    }

    public static async sendToEis(obj: ContractStageDissolutionInfo): Promise<ContractStageDissolutionInfo> {
        return ContractStageDissolutionInfo.fromJson(await http.post(`/api/stageDissolution/${obj.id}/sendToEis`, undefined));
    }
}
