// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, "._1Ot5-YYXgWo83d48uwBEnn {\n    display: flex;\n    justify-content: space-between;\n    flex-direction: row-reverse;\n    /*border-bottom: 2px solid #EFF3F6;*/\n}\n\n.kzlHfm91JZfktlLS_SoSq {\n    display: flex;\n    justify-content: space-around;\n    align-items: center;\n    margin: 0 10px;\n    gap: 5px;\n    /*padding-top: 40px;*/\n}\n\n._1xooNbfM3zUsgDapigL9IE{\n    padding: 8px 15px;\n    text-align: left;\n    font-weight: 400;\n    font-size: 14px;\n}\n\n._1BN1LgzPExjgOeRNDPZPwV{\n    width: 100%;\n    border-collapse: collapse;\n}\n\n._1IgztYJTtgTcJJQSmq-Aoc,\n.Tz2HwccVgaXIC8nqrOWFX {\n    border-bottom: 1px solid #eff3f6;\n    font-size: 14px;\n    color: #999999;\n    width: fit-content;\n}\n\n._3s3f7ksyUidnO9JqZsys8- {\n    background-color: #e9f6ff;\n}\n\n._3rLgoj2LSegys20lSRselK {\n    cursor: pointer;\n}\n", ""]);
// Exports
exports.locals = {
	"pickMasterModalButtons__wrapper": "_1Ot5-YYXgWo83d48uwBEnn",
	"pickMasterModalButtons__container": "kzlHfm91JZfktlLS_SoSq",
	"cell": "_1xooNbfM3zUsgDapigL9IE",
	"cardTable": "_1BN1LgzPExjgOeRNDPZPwV",
	"tableRow": "_1IgztYJTtgTcJJQSmq-Aoc",
	"tableRow__header": "Tz2HwccVgaXIC8nqrOWFX",
	"tableRow_selected": "_3s3f7ksyUidnO9JqZsys8-",
	"tableRow_selectable": "_3rLgoj2LSegys20lSRselK"
};
module.exports = exports;
