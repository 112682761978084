// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_GET_URL_IMPORT___ = require("../../node_modules/css-loader/dist/runtime/getUrl.js");
var ___CSS_LOADER_URL_IMPORT_0___ = require("../assets/list_markers.png");
exports = ___CSS_LOADER_API_IMPORT___(false);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
// Module
exports.push([module.id, "._3ay07lz9ijboEpKawyJi08 {\n    display: flex;\n    flex-direction: column;\n    background: white;\n\n    border-radius: 3px;\n    border-width: 0 1px 1px;\n    border-style: solid;\n    border-color: transparent #ccd1de #bbc1d0;\n}\n\n._17h-Ibn0IlXLHc_umHFuZ- {\n    display: flex;\n    align-items: center;\n\n    background: #eef1f7;\n    border-bottom: 1px solid #dadfea;\n    padding: 20px 30px 20px;\n}\n\n._3zZFYjU5lfT36JTaazSKp_ {\n    font-size: 20px;\n    flex-grow: 1;\n    display: flex;\n}\n\n.nsfD-ungpIBqXWOPOQNTT {\n    flex-grow: 1;\n}\n\n._1reRBIODgAzi-EtJPmhI7E {\n    font-family: \"EaistFont\";\n    padding-top: 3px;\n    cursor: pointer;\n    user-select: none;\n}\n\n.ATEeEnhK8egNag2WR717M {\n    cursor: pointer;\n    user-select: none;\n    margin: 5px 5px;\n\n    width: 12px;\n    height: 12px;\n    background: url(" + ___CSS_LOADER_URL_REPLACEMENT_0___ + ") no-repeat;\n}\n\n._3B1i1yZfbHtjVrSyqBE48F {\n    padding: 30px;\n    flex-grow: 1;\n    display: flex;\n    height: auto;\n    flex-direction: column;\n}\n\n._15cOZWHd_qPT54qHBpq8hT {\n    overflow: hidden;\n    max-height: 0;\n}\n\n._2qcNXWcCChflrq2HlUIXx- {\n    border-top: 1px solid #dadfea;\n    padding: 10px;\n}\n", ""]);
// Exports
exports.locals = {
	"card": "_3ay07lz9ijboEpKawyJi08",
	"cardHeader": "_17h-Ibn0IlXLHc_umHFuZ-",
	"cardHeaderTitle": "_3zZFYjU5lfT36JTaazSKp_",
	"cardTitleContent": "nsfD-ungpIBqXWOPOQNTT",
	"closeButton": "_1reRBIODgAzi-EtJPmhI7E",
	"collapseButton": "ATEeEnhK8egNag2WR717M",
	"cardContent": "_3B1i1yZfbHtjVrSyqBE48F",
	"cardCollapsed": "_15cOZWHd_qPT54qHBpq8hT",
	"cardBottom": "_2qcNXWcCChflrq2HlUIXx-"
};
module.exports = exports;
