










import { FieldAlignment, FieldWidth, valueSpanContext, FComponent } from "./FComponent";
import { Component, Prop, Vue, Inject, Mixins } from "vue-property-decorator";
import { formatDate } from "@/DateFormatting";
import { FValueComponent } from './FValueComponent';

@Component
export default class FDate extends Mixins(FValueComponent) {
    @Prop({ default: "wide" }) public width!: FieldWidth;
    @Prop({ default: "months" }) public mode!: "days" | "months";
    @Prop({ default: null }) public rangeStart!: Date | null;
    @Prop({ default: null }) public rangeEnd!: Date | null;

    public get commonProps() {
        const { width, f } = this;

        return { class: [f(width), ...this.classes] };
    }

    public get editableProps() {
        const { value, disabled, readonly, mode, rangeStart, rangeEnd } = this;

        return { ...this.commonProps, value, disabled, readonly, mode, rangeStart, rangeEnd };
    }

    public get formatted() {
        if (!this.value) return "";

        return formatDate(this.value as Date, this.mode === "days");
    }

    public onInput(d: Date) {
        this.$emit("input", d);
    }
}
