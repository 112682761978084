import React, {useContext} from "react";
import {setPurchasePerUnitEnabled} from "../store/purchasePerUnit";
import {FCheckBox, FormStateContext} from "@/components/form";
import {useMappedStore} from "@/storeUtils";
import {Card} from "@/components/Card";
import {Field, Fields} from "@/components/form/Field";
import {SectionProps} from "../store";

export const PurchasePerUnitSection: React.FC<SectionProps> = x => {
    const state = useContext(FormStateContext);
    const orientation = state === "readonly" ? "vertical" : "horizontal";

    const purchasePerUnit = useMappedStore(x.formStore, x => x.purchasePerUnit);

    return <Card>
        <Fields orientation={orientation}>
            <Field title="Закупка на единицу продукции">
                <FCheckBox value={purchasePerUnit.enabled} onChange={setPurchasePerUnitEnabled} />
            </Field>
        </Fields>
    </Card>;
};