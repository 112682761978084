import { Component, Prop } from "vue-property-decorator";
import CardModal from "@/components/CardModal.vue";

import SubjectDeclarations from "@/api/SubjectDeclarations";
import { SubjectDeclaration } from "@/models/SubjectDeclaration";
import { ModalResult, ModalVue } from "@/view-models/ModalRequest";
import { ISubjectDeclarationSelectionModalArgs } from "../ModalArgs";
import { EditType, IRowConfiguration, ITableColumn, SingleSelection } from "@/components/TableAbstractions";
import { createPaginationContext, PaginationContext } from '@/api/Pagination';
import { containsFilter, startsWithFilter } from '@/components/TableHelpers';
import {SubjectDeclarationRule} from "@/models/enums/SubjectDeclarationRule";

@Component({ components: { CardModal } })
export default class SubjectDeclarationSelectionModal extends ModalVue<ISubjectDeclarationSelectionModalArgs, SubjectDeclaration> {
    @Prop() private prop!: ISubjectDeclarationSelectionModalArgs;

    public showFilters = false;
    public context: PaginationContext<SubjectDeclaration> | null = null;

    public selected: SingleSelection<SubjectDeclaration> = { item: this.prop.selectedSubjectDeclaration, tag: "SINGLE" };

    public async mounted() {
        if (!this.prop.subjectClass) {
            return;
        }

        this.context = await createPaginationContext((n, sz, f) => SubjectDeclarations.getForClass(n, sz, this.prop.subjectClass!.id, f), 10);
    }

    public config: IRowConfiguration<SubjectDeclaration> = {
        selection: {
            showSelectionColumn: true,
            background: "#ffd"
        }
    };

    public rules = [
        {
            text: "Перечень аукционной продукции (Распоряжение Правительства РФ от 21.03.2016 г. N 471-р)",
            color: "#f66"
        },
        {
            text: "Применение запрета на допуск промышленных товаров, происходящих из иностранных государств, за исключением ЕАЭС (ПП РФ от 30.04.2020 г. № 616)",
            color: "#80dd7e"
        },
        {
            text: "Применение ограничений допуска отдельных видов промышленных товаров, происходящих из иностранных государств, за исключением ЕАЭС (ПП РФ от 30.04.2020 г. № 617)",
            color: "#4db4ff"
        },
        {
            text: "Преимущества учреждениям и предприятиям УИС (ст. 28 44-ФЗ, РП РФ от 08.12.2021 № 3500-р)",
            color: "#ccc"
        },
        {
            text: "Преимущества организациям инвалидов (ст. 29 44-ФЗ, РП РФ от 08.12.2021 N 3500-р)",
            color: "#f8c743"
        }
    ];

    private subjectDeclarationRuleToIcon: Record<SubjectDeclarationRule, string> = {
        [SubjectDeclarationRule.ADVANTAGES_FOR_DISABILITIES]: "#f8c743",
        [SubjectDeclarationRule.ADVANTAGES_FOR_UIS]: "#ccc",
        [SubjectDeclarationRule.AUCTION]: "#f66",
        [SubjectDeclarationRule.NONE]: "transparent"
    };

    public headers: ITableColumn<SubjectDeclaration>[] = [
        {
            type: EditType.TEXT,
            title: "Наименование СПГЗ",
            getter: v => v.subject,
            filter: containsFilter("name"),
            size: "minmax(min-content, 1fr)"
        },
        {
            title: "Единицы\nизмерения",
            getter: v => v.measurementUnits[0] ? v.measurementUnits[0].name : "",
            size: "minmax(auto, 120px)",
            headerAlignment: "center",
            itemAlignment: "center",
            filter: containsFilter("measurementUnits")
        },
        {
            title: "Код ОКПД-2",
            getter: v => v.okpd2 ? v.okpd2.code : "",
            size: "minmax(auto, 110px)",
            headerAlignment: "center",
            itemAlignment: "center",
            filter: startsWithFilter("okpd2Code")
        },
        {
            title: "Код КТРУ",
            getter: () => "",
            size: "minmax(auto, 100px)",
            headerAlignment: "center",
            itemAlignment: "center",
            filter: startsWithFilter("okpd2Code")
        },
        {
            title: "Стандартизирован",
            getter: v => v.standardProduct ? "Да" : "Нет",
            size: "minmax(auto, 150px)",
            headerAlignment: "center",
            itemAlignment: "center"
        },
        {
            title: "Инновационная\nпродукция",
            getter: v => v.innovationProduct ? "Да" : "Нет",
            size: "minmax(auto, 130px)",
            headerAlignment: "center",
            itemAlignment: "center"
        },
        {
            title: "Правила",
            getter: () => undefined,
            slot: "rules-column",
            visible: () => this.prop.law === "F44",
            size: "min-content",
            headerAlignment: "center",
            itemAlignment: "center"
        }
    ];

    public selectItemAndExit() {
        if (this.selected.item) {
            this.$emit("close-modal", ModalResult.ok(this.selected.item));
        } else {
            this.$emit("close-modal");
        }
    }

    public openInfo() {
        const routeData = this.$router.resolve("/reference-info/dict/subject-classes");
        window.open(routeData.href, "_blank");
    }
}
