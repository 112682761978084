import React from "react";
import {Section} from "@/components/eshop/Section/Section";
import styles from "@/views/CreateSession/CreateSession.module.css";
import {HeaderInline} from "@/views/CreateSession/Specifications/SpecificationsCard";
import {ServicesStore} from "@/views/CreateSession/store";
import {EshopLink} from "@/components/eshop/EshopLink";
import {goToSessionCard} from "@/views/Sessions/store";
import {Card} from "@/components/eshop/Card/Card";
import {tr} from "date-fns/locale";

interface ListJointSessionsCardProps {
    store: ServicesStore
    canChange: boolean
}

export const ListJointSessionsCard = (props: ListJointSessionsCardProps) => {
    return (
        <Section title={"Список позиций котировочных сессий для формирования совместной КС"} required canBeHidden>
            <Card>
                <table className={styles.cardTable}>
                    <thead>
                    <tr className={styles.tableRow}>
                        <HeaderInline>№</HeaderInline>
                        <HeaderInline>Номер КС</HeaderInline>
                        <HeaderInline>Предмет КС</HeaderInline>
                        <HeaderInline>Заказчик</HeaderInline>
                        <HeaderInline>НЦ КС, руб.</HeaderInline>
                        <HeaderInline>Электронное исполнение</HeaderInline>
                        <HeaderInline>Дата создания КС</HeaderInline>
                        <HeaderInline>Контракт</HeaderInline>
                    </tr>
                    </thead>
                    <tbody>
                    {props.store.includedSessions.map((session, index) => {
                        return (
                            <tr key={index} className={styles.tableRow}>
                                <td className={`${styles.cell} ${styles.cellBody}`}>
                                    {index + 1}
                                </td>
                                <td className={`${styles.cell} ${styles.cellBody}`}>
                                    <EshopLink onClick={() => goToSessionCard(session.id)}>
                                        {session.number}
                                    </EshopLink>
                                </td>
                                <td className={`${styles.cell} ${styles.cellBody}`}>
                                    {session.subject.name}
                                </td>
                                <td className={`${styles.cell} ${styles.cellBody}`}>
                                    <EshopLink>
                                        {session.participant.shortName}
                                    </EshopLink>
                                </td>
                                <td className={`${styles.cell} ${styles.cellBody}`}>
                                    {session.specifications.reduce((p, c) => c.totalPrice + p, 0)}
                                </td>
                                <td className={`${styles.cell} ${styles.cellBody}`}>
                                    {session.eexecution ? "Да" : "Нет"}
                                </td>
                                <td className={`${styles.cell} ${styles.cellBody}`}>
                                    {session.created.substring(0, 10)}
                                </td>
                                <td className={`${styles.cell} ${styles.cellBody}`}>
                                    {session.contract ? session.contract.regNumber : "-"}
                                </td>
                            </tr>
                        );
                    })}
                    {!props.store.includedSessions.length && (
                        <tr className={styles.tableRow}>
                            <td className={styles.cellDisabled} colSpan={8}>
                                <span>Отсутствует список позиций</span>
                            </td>
                        </tr>
                    )}
                    </tbody>
                </table>
            </Card>
        </Section>
    );
};
