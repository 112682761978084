import {SubjectClass} from "@/models/SubjectClass/SubjectClass";
import {createStore} from "effector";
import {getSubjectClasses} from "@/api/directory";
import {createStoredRemoteDataset, StoredRemoteDataset} from "@/components/table";
import {FilterConditionType, FilterValueType} from "@/api/http";

export type SubjectClassFilters = {
    id: number
    code: string
    name: string
    okpd2: string
    projectCheck: boolean
    createdAt: Date
};

export interface SubjectClassStore {
    subjectClasses: StoredRemoteDataset<SubjectClass, SubjectClassFilters>
    loaded: boolean
}

export const createSubjectClassStore = () => {
    return createStore<SubjectClassStore>({
        subjectClasses: createStoredRemoteDataset<SubjectClass, SubjectClassFilters>({
            async load(filters, from, count) {
                return getSubjectClasses(from, count, {
                    id: [{
                        type: FilterValueType.LONGINT,
                        conditionType: FilterConditionType.CONTAINS,
                        longint: filters.id,
                    }],
                    code: [{
                        type: FilterValueType.STRING,
                        conditionType: FilterConditionType.CONTAINS,
                        string: filters.code
                    }],
                    name: [{
                        type: FilterValueType.STRING,
                        conditionType: FilterConditionType.CONTAINS,
                        string: filters.name
                    }],
                    okpd2: [{
                        type: FilterValueType.STRING,
                        conditionType: FilterConditionType.CONTAINS,
                        string: filters.okpd2
                    }],
                    projectCheck: [{
                        type: FilterValueType.STRING,
                        conditionType: FilterConditionType.EQUAL,
                        boolean: filters.projectCheck
                    }, {
                        type: FilterValueType.STRING,
                        conditionType: FilterConditionType.NOT_EQUAL,
                        boolean: filters.projectCheck
                    }],
                    createdAt: [{
                        type: FilterValueType.DATE,
                        conditionType: FilterConditionType.EQUAL,
                        date: filters.createdAt
                    }]
                });
            }
        }),
        loaded: false
    });
};
