import React, {useState} from "react";
import {Store} from "effector";
import {Column, FilterColumn, FilteredSelectionTable, Pager, Table, useStoredRemoteDataset} from "@/components/table";
import {useStore} from "effector-react";
import {Button, Label, Note} from "@/components/primitive";
import router from "@/router";
import {Expandee, HStack, VStack} from "@/components/layouts";
import {
    SubjectDeclarationFilters,
    SubjectDeclarationsStore
} from "@/views/ReferenceInfo/SubjectDeclarations/list/store";
import {SubjectDeclaration} from "@/models/SubjectDeclaration/SubjectDeclaration";
import {formatDate} from "@/dateUtils";
import { showModal } from "@/EventBus";
import { ExportModal } from "@/views/ReferenceInfo/SubjectClasses/list/ExportModal";
import {SubjectClass} from "@/models/SubjectClass/SubjectClass";
import {SubjectClassFilters} from "@/views/ReferenceInfo/SubjectClasses/list/store";

export const SubjectDeclarations: React.FC<{ storeContainer: { store: Store<SubjectDeclarationsStore> } }> = x => {
    const state = useStore(x.storeContainer.store);
    const rds = useStoredRemoteDataset(state.subjectDeclarations);

    const [filtersVisible, setFiltersVisible] = useState(true);

    const columns: Column<SubjectDeclaration>[] = [
        Table.Column("Идентификатор", c =>
            <Label>
                {c.item.id}
            </Label>
        ),
        FilterColumn<SubjectDeclaration, SubjectDeclarationFilters>({
            header: "Идентификатор версии",
            cell: c => <Label>
                {c.item.id}
            </Label>
        }),
        FilterColumn<SubjectDeclaration, SubjectDeclarationFilters>({
            header: "Наименование СПГЗ",
            cell: c => <Label onClick={() => router.push(`/reference-info/dict/subject-declarations/${c.item.id}`)}
                              style={{ textDecoration: "underline", cursor: "pointer" }}>
                {c.item.subject}
            </Label>,
            filter: {
                type: "string",
                key: "subject"
            },
        }),
        FilterColumn<SubjectDeclaration, SubjectDeclarationFilters>({
            header: "КПГЗ",
            cell: c => <Label style={{ textDecoration: "uppercase"}}>
                {c.item.subjectClass.code} {c.item.subjectClass.description}
            </Label>
        }),
        FilterColumn<SubjectDeclaration, SubjectDeclarationFilters>({
            header: "Код ОКПД-2",
            cell: c => <Label>
                {`${c.item.okpd2.code} ${c.item.okpd2.name}`}
            </Label>,
            filter: {
                type: "string",
                key: "okpd2"
            },
        }),
        FilterColumn<SubjectDeclaration, SubjectDeclarationFilters>({
            header: "Актуальность записи",
            cell: c => <Label>
                {c.item.innovationProduct ? "Утверждён" : "Проект"}
            </Label>
        }),
        Table.Column("Дата последнего изменения", c =>
            <Label>
                {formatDate(c.item.createdDate)}
            </Label>)
    ];

    return <VStack outerStyle={{margin: "30px"}} spacing="15px">
        <Note preset="title">
            <HStack alignItems="center" spacing={"10px"}>
                <div>Список СПГЗ</div>
                <Expandee/>
                <Button icon="aFilter" onClick={() => setFiltersVisible(!filtersVisible)}/>
                <Button title="Экспорт" onClick={() => showModal(ExportModal)} />
            </HStack>
        </Note>
        <Note preset="panel">
            <FilteredSelectionTable
                filterStore={rds.filterStore}
                isFiltersVisible={filtersVisible}
                mode="single"
                selectorPosition="hidden"
                dataset={rds.dataset}
                columns={columns}/>
        </Note>
        <Pager remoteDataset={rds}/>
    </VStack>;
};
