import React, {useState} from "react";
import styles from "../CreateSession.module.css";
import {Section} from "@/components/eshop/Section/Section";
import {TooltipIcon} from "@/components/TooltipIcon";
import {Card} from "@/components/eshop/Card/Card";
import {Select} from "@/components/eshop/Select/Select";
import {ChevronButton, RefreshButton} from "@/components/eshop/IconButton/IconButtons";
import {
    SpecificationPaymentTermType,
    SpecificationPaymentTermTypeStrings,
    SpecificationReviewDocumentsTermType,
    SpecificationReviewDocumentsTermTypeStrings,
    SpecificationSendDocumentsTermType,
    SpecificationSendDocumentsTermTypeStrings
} from "@/models/ComposedLots/PaymentStageTerm";
import {
    changeExecutionStages,
    changeShowExecutionWarning,
    changeTerm,
    ExecutionStage,
    onChangePaymentDate,
    onChangePaymentDateOffset,
    onChangeShowSpec,
    ServicesStore,
    SpecificationType,
    SubjectDeclarationForCreationProduct,
    SubjectDeclarationForCreationPurchase,
    SubjectDeclarationForCreationService,
    SupplyStage
} from "@/views/CreateSession/store";
import {HeaderWithTooltip, SortFilter} from "@/views/CreateSession/Session/CreateSession";
import _ from "lodash";
import {SortIcons} from "@/components/SortIcons";
import {HeaderInline} from "@/views/CreateSession/Specifications/SpecificationsCard";
import {EshopLink} from "@/components/eshop/EshopLink";
import {DatePicker} from "@/components/primitive";
import {NumberInput} from "@/components/eshop/NumberInput";
import {j} from "@/reactUtils";
import {createCalendarDayString} from "@/stingUtils";
import {addDays} from "date-fns";
import moment from "moment";


interface ExecutionStagesCardProps {
    store: ServicesStore
    dataType: SpecificationType
    canChange: boolean
}

type ItemsToRender = {
    volume: string | number
    number: number
    total: number
    price: number
    name: string
    measurementUnit: string
}[] | undefined;

export const ExecutionStagesCard = (props: ExecutionStagesCardProps) => {
    const firstDigitRegex = /(\d+)/;

    const SpecificationSendDocumentsOptions = Array.from(Object.keys(SpecificationSendDocumentsTermTypeStrings), (v) => ({value: v.match(firstDigitRegex)?.[0], name: SpecificationSendDocumentsTermTypeStrings[v as SpecificationSendDocumentsTermType] }));
    const SpecificationReviewDocumentsOptions = Array.from(Object.keys(SpecificationReviewDocumentsTermTypeStrings), (v) => ({value: v.match(firstDigitRegex)?.[0], name: SpecificationReviewDocumentsTermTypeStrings[v as SpecificationReviewDocumentsTermType] }));
    const SpecificationPaymentOptions = Array.from(Object.keys(SpecificationPaymentTermTypeStrings), (v) => ({value: v.match(firstDigitRegex)?.[0], name: SpecificationPaymentTermTypeStrings[v as SpecificationPaymentTermType] }));

    const [paymentStageSort, setPaymentStageSort] = useState<SortFilter>(null);
    const executionStages = props.store.executionStages;
    const executionStagesSorted = () => paymentStageSort
        ? _.orderBy(executionStages, a => _.get(a, paymentStageSort.field), [paymentStageSort.sort])
        : executionStages;
    const handleCreateExecutionStagesTemplates = () => {
        let data;
        if (props.dataType === SpecificationType.Services)
            data = props.store.services;
        else if
            (props.dataType === SpecificationType.Products) data = props.store.products;
        else
            data = props.store.purchases;

        const executionStages: ExecutionStage[]  = data.flatMap(item => item.stages.map(stage => {
            const paymentDateOffset = +props.store.sendTerm + +props.store.paymentTerm + +props.store.reviewTerm + (stage.relativePeriod?.startOffset ?? 1) + (stage.relativePeriod?.endOffset ?? 2) + 5;
            const paymentDate = stage.absolutePeriod?.endDate ? addDays(stage.absolutePeriod.endDate, +props.store.paymentTerm + +props.store.reviewTerm + +props.store.sendTerm + 5) : undefined;
            const paymentAmount = item.price * +(stage.volume ?? 1);
            const supplyStage: SupplyStage = {
                volume: stage.volume ?? "1",
                type: item.stageType,
                absolutePeriod: stage.absolutePeriod,
                address: stage.address,
                conditions: stage.conditions,
                relativePeriod: {
                    endOffset: stage.relativePeriod?.endOffset ?? 1,
                    endOffsetType: stage.relativePeriod?.endOffsetType ?? "NORMAL",
                    startOffset: stage.relativePeriod?.startOffset ?? 2,
                    startOffsetType: stage.relativePeriod?.startOffsetType ?? "NORMAL"
                },
            };

            return {
                paymentDate: item.stageType === "ABSOLUTE" ? paymentDate : undefined,
                paymentDateOffset: item.stageType === "RELATIVE" ? paymentDateOffset : undefined,
                paymentAmount: paymentAmount.toFixed(2),
                supplyStage: supplyStage,
                showSupply: false,
                services: props.dataType === SpecificationType.Services ? [item] as SubjectDeclarationForCreationService[] : null,
                products: props.dataType === SpecificationType.Products ? [item] as SubjectDeclarationForCreationProduct[] : null,
                purchases: props.dataType === SpecificationType.DirectPurchase ? [item] as SubjectDeclarationForCreationPurchase[] : null
            };
        }));

        const mergedExecutionStages = mergeExecutionStages(executionStages);
        changeExecutionStages(mergedExecutionStages);
    };

    // used to merge execution stages with the same paymentDate/paymentOffsetDate in one
    const mergeExecutionStages = (execStages: ExecutionStage[]): ExecutionStage[] => {

        if (execStages.length > 1) {
            const relativeStages = execStages.filter(s => s.supplyStage.type === "RELATIVE");
            const absoluteStages = execStages.filter(s => s.supplyStage.type === "ABSOLUTE");
            const groupedRelativeStages = relativeStages.reduce<Record<string, ExecutionStage[]>>((acc, stage) => {
                const key = `${stage.supplyStage.relativePeriod?.startOffset}-${stage.supplyStage.relativePeriod?.endOffset}`;
                if(!acc[key]) acc[key] = [];

                acc[key].push(stage);
                return acc;
            } ,{});

            const groupedAbsoluteStages = absoluteStages.reduce<Record<string, ExecutionStage[]>>((acc, stage) => {
                const key = `${stage.supplyStage.absolutePeriod?.startDate?.valueOf()}-${stage.supplyStage.absolutePeriod?.endDate?.valueOf()}`;
                if(!acc[key]) acc[key] = [];
                
                acc[key].push(stage);
                return acc;
            } ,{});

            return Object.values(groupedRelativeStages).concat(Object.values(groupedAbsoluteStages)).map(stages => ({
                paymentDateOffset: stages[0].paymentDateOffset ?? null,
                paymentDate: stages[0].paymentDate ?? null,
                paymentAmount: (stages.reduce((sum, stage) => sum + +stage.paymentAmount, 0)).toFixed(2),
                products: _.uniq(stages.flatMap(stage => stage.products ?? [])),
                services: _.uniq(stages.flatMap(stage => stage.services ?? [])),
                purchases: _.uniq(stages.flatMap(stage => stage.purchases ?? [])),
                supplyStage: stages[0].supplyStage,
                showSupply: false
            }));
        } else {
            return execStages;
        }
    };

    return (
        <>
            <Section title={<>Этапы исполнения контракта<TooltipIcon /></>} canBeHidden required>
                <Card direction={"vertical"} className={styles.stages__cardWrapper}>
                    <div className={styles.stages__controls}>
                        <span>Срок направления отчетных документов</span>
                        <Select disabled={!props.canChange} width={380} small value={props.store.sendTerm} options={SpecificationSendDocumentsOptions} placeholder={"Выберите значение"} onSelect={v => v && changeTerm({type: "sendTerm", value: v})} />
                        <span>Срок рассмотрения отчетных документов</span>
                        <Select disabled={!props.canChange} width={200} small value={props.store.reviewTerm} options={SpecificationReviewDocumentsOptions} placeholder={"Выберите значение"} onSelect={v => v && changeTerm({type: "reviewTerm", value: v})} />
                        <span>Срок оплаты</span>
                        <Select disabled={!props.canChange} width={200} small value={props.store.paymentTerm} options={SpecificationPaymentOptions} placeholder={"Выберите значение"} onSelect={v => v && changeTerm({type: "paymentTerm", value: v})} />
                        <RefreshButton disabled={!props.canChange}
                                       onClick={() => {
                                           handleCreateExecutionStagesTemplates();
                                           changeShowExecutionWarning(false);
                                       }} />
                    </div>
                    <div className={styles.stagesHint}>
                        {executionStages.length ? "Сроки направления, рассмотрения отчетных документов и оплаты будут учтены при расчете даты оплаты и при формировании проекта контракта" : "Этапы формируются автоматически после добавления графика поставки спецификации"}
                    </div>
                    {props.store.showExecutionStageWarning && <span style={{color: "red", width: "100%", textAlign: "center"}}>
                        Внимание! В связи с изменением данных о спецификациях данные об этапах исполнения могут быть неактуальны. Обновите сведения при необходимости
                    </span>}
                    <table className={styles.cardTable}>
                        <thead>
                            <tr className={styles.tableRow}>
                                <HeaderWithTooltip>
                                    <SortIcons sorted={null} setSort={() => {}} />
                                    Номер этапа
                                </HeaderWithTooltip>
                                <HeaderWithTooltip>
                                    <SortIcons sorted={null} setSort={() => {}} />
                                    Начало поставки
                                </HeaderWithTooltip>
                                <HeaderWithTooltip>
                                    <SortIcons sorted={null} setSort={() => {}} />
                                    Окончание поставки
                                </HeaderWithTooltip>
                                <HeaderWithTooltip>
                                    <SortIcons sorted={null} setSort={() => {}} />
                                    Дата оплаты/Период оплаты
                                </HeaderWithTooltip>
                                <HeaderWithTooltip>
                                    <SortIcons sorted={null} setSort={() => {}} />
                                    Сумма этапа, руб.
                                </HeaderWithTooltip>
                            </tr>
                        </thead>
                        <tbody>
                        {executionStages.length > 0 && executionStagesSorted().map((executionStage, index) => {
                            const number = index + 1;
                            const type = executionStage.supplyStage.type;
                            const supplyStart = type === "ABSOLUTE" ? moment(executionStage.supplyStage.absolutePeriod?.startDate).format("DD.MM.YYYY") : executionStage.supplyStage.relativePeriod?.startOffset;
                            const supplyEnd = type === "ABSOLUTE" ? moment(executionStage.supplyStage.absolutePeriod?.endDate).format("DD.MM.YYYY") : executionStage.supplyStage.relativePeriod?.endOffset;
                            const paymentAmount = executionStage.paymentAmount;

                            const productsToRender: ItemsToRender = executionStage.products?.map(product => {
                                return {
                                    number: product.steId,
                                    name: product.subjectDeclaration.subject,
                                    price: product.price,
                                    volume: product.stages[0].volume ? product.stages[0].volume : 1,
                                    measurementUnit: product.subjectDeclaration.measurementUnits[0].name,
                                    total: product.price * +(product.stages[0].volume ?? 1)
                                };
                            });

                            const servicesToRender: ItemsToRender = executionStage.services?.map(service => {
                                return {
                                    number: service.subjectDeclaration.id,
                                    name: service.subjectDeclaration.subject,
                                    price: service.price,
                                    volume: service.stages[0].volume ? service.stages[0].volume : 1,
                                    measurementUnit: service.subjectDeclaration.measurementUnits[0].name,
                                    total: service.price * +(service.stages[0].volume ?? 1)
                                };
                            });

                            const purchasesToRender: ItemsToRender = executionStage.purchases?.map(purchase => {
                                return {
                                    number: purchase.subjectDeclaration.id,
                                    name: purchase.subjectDeclaration.subject,
                                    price: purchase.price,
                                    volume: purchase.stages[0].volume ? purchase.stages[0].volume : 1,
                                    measurementUnit: purchase.subjectDeclaration.measurementUnits[0].name,
                                    total: purchase.price * +(purchase.stages[0].volume ?? 1)
                                };
                            });
                            return (
                                <React.Fragment key={`${index}`}>
                                    <tr className={styles.tableRow}>
                                        <td className={j(styles.cell, styles.cellBody)}>
                                            <div className={styles.cell_centered}>
                                                {number}
                                                <ChevronButton rotated={executionStage.showSupply} onClick={() => onChangeShowSpec(index)} />
                                                {executionStage.purchases?.length! > 0 && <span className={j(styles.count, !executionStage.purchases?.length && styles.countError)}>{executionStage.purchases?.length}</span>}
                                                {executionStage.services?.length! > 0 && <span className={j(styles.count, !executionStage.services?.length && styles.countError)}>{executionStage.services?.length}</span>}
                                                {executionStage.products?.length! > 0 && <span className={j(styles.count, !executionStage.products?.length && styles.countError)}>{executionStage.products?.length}</span>}
                                            </div>
                                        </td>
                                        <td className={j(styles.cell, styles.cellBody)}>
                                            {type === "ABSOLUTE" ? supplyStart : `${supplyStart} ${createCalendarDayString(supplyStart)} с даты заключения контракта`}
                                        </td>
                                        <td className={j(styles.cell, styles.cellBody)}>
                                            {type === "ABSOLUTE" ? supplyEnd : `${supplyEnd} ${createCalendarDayString(supplyEnd)} с даты заключения контракта`}
                                        </td>
                                        <td className={j(styles.cell, styles.cellBody)}>
                                            {type === "ABSOLUTE" && executionStage.paymentDate &&
                                                <DatePicker value={executionStage.paymentDate ? new Date(executionStage.paymentDate) : undefined}
                                                            disabled={!props.canChange}
                                                            mode="days"
                                                            maxWidth={"300px"}
                                                            appearance="eshop"
                                                            small
                                                            cantBeCleared
                                                            onChange={v => onChangePaymentDate({value: v, index: index})} />
                                            }
                                            {type === "RELATIVE" && <div style={{width: 300, display: "flex", alignItems: "center", gap: "5px"}}>
                                                <NumberInput width={50}
                                                             disabled={!props.canChange}
                                                             small
                                                             disableFixed
                                                             disableMask
                                                             maxValue={1096}
                                                             value={executionStage.paymentDateOffset}
                                                             onChange={v => onChangePaymentDateOffset({value: v, index: index})} />
                                                {createCalendarDayString(executionStage.paymentDateOffset)}
                                            </div>}
                                        </td>
                                        <td className={j(styles.cell, styles.cellBody)}>
                                            {paymentAmount}
                                        </td>
                                    </tr>
                                    {executionStage.showSupply && (
                                        <tr className={styles.tableRow} style={{border: "none"}}>
                                            <td colSpan={5} style={{padding: 0}}>
                                                <table className={styles.cardTable}>
                                                    <thead>
                                                        <tr className={`${styles.tableRow}`} style={{backgroundColor: "#fafafa"}}>
                                                            <HeaderInline>Номер СТЕ</HeaderInline>
                                                            <HeaderInline>Наименование</HeaderInline>
                                                            <HeaderInline>Цена, руб.</HeaderInline>
                                                            <HeaderInline>Количество</HeaderInline>
                                                            <HeaderInline>Ед. измерения</HeaderInline>
                                                            <HeaderInline>Сумма, руб.</HeaderInline>
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                    {executionStage.products?.length! > 0 && executionStage.showSupply && productsToRender?.map((product, productIndex) => {
                                                        return (
                                                            <tr key={productIndex} className={`${styles.tableRow}`} style={{backgroundColor: "#fafafa"}}>
                                                                <td className={styles.cell}>
                                                                    <EshopLink>{product.number}</EshopLink>
                                                                </td>
                                                                <td className={styles.cell}>
                                                                    <EshopLink>{product.name}</EshopLink>
                                                                </td>
                                                                <td className={styles.cell}>{product.price.toFixed(2)}</td>
                                                                <td className={styles.cell}>{product.volume}</td>
                                                                <td className={styles.cell}>{product.measurementUnit}</td>
                                                                <td className={styles.cell}>{product.total.toFixed(2)}</td>
                                                            </tr>
                                                        );
                                                    })}
                                                    {executionStage.services?.length! > 0 && !executionStage.showSupply && servicesToRender?.map((service, serviceIndex) => {
                                                        return (
                                                            <tr key={serviceIndex} className={`${styles.tableRow}`} style={{backgroundColor: "#fafafa"}}>
                                                                <td className={styles.cell}>
                                                                    <EshopLink>{service.number}</EshopLink>
                                                                </td>
                                                                <td className={styles.cell}>
                                                                    <EshopLink>{service.name}</EshopLink>
                                                                </td>
                                                                <td className={styles.cell}>{service.price.toFixed(2)}</td>
                                                                <td className={styles.cell}>{service.volume}</td>
                                                                <td className={styles.cell}>{service.measurementUnit}</td>
                                                                <td className={styles.cell}>{service.total.toFixed(2)}</td>
                                                            </tr>
                                                        );
                                                    })}
                                                    {executionStage.purchases?.length! > 0 && !executionStage.showSupply && purchasesToRender?.map((purchase, purchaseIndex) => {
                                                        return (
                                                            <tr key={purchaseIndex} className={`${styles.tableRow}`} style={{backgroundColor: "#fafafa"}}>
                                                                <td className={styles.cell}>
                                                                    <EshopLink>{purchase.number}</EshopLink>
                                                                </td>
                                                                <td className={styles.cell}>
                                                                    <EshopLink>{purchase.name}</EshopLink>
                                                                </td>
                                                                <td className={styles.cell}>{purchase.price.toFixed(2)}</td>
                                                                <td className={styles.cell}>{purchase.volume}</td>
                                                                <td className={styles.cell}>{purchase.measurementUnit}</td>
                                                                <td className={styles.cell}>{purchase.total.toFixed(2)}</td>
                                                            </tr>
                                                        );
                                                    })}
                                                    </tbody>
                                                </table>
                                            </td>
                                        </tr>
                                    )}
                                </React.Fragment>
                            );
                        })}
                        {!executionStages.length && <tr className={styles.tableRow}>
                            <td colSpan={6} className={styles.cellDisabled}>Нет записей</td>
                        </tr>}
                        </tbody>
                    </table>
                </Card>
            </Section>
        </>
    );
};
