import { IAction, IconType } from "@/views/FormAbstractions";
import { ContractScope } from "@/views/CreateContract/ContractScope";
import { LawType, ObjectStatus } from "@/models/enums";
import { actionL, actionR } from "@/form-declarations/helpers";
import { Contract } from "@/models/Contracts";

type ContractStageFn = (scope: Contract) => IAction<ContractScope>[];

type OptionalActions = "makeChanges" | "save";

const withDefaultActions = (
    stages: IAction<ContractScope>[],
    optionalActions?: { [K in OptionalActions]?: boolean },
): IAction<ContractScope>[] => {
    const actions: IAction<ContractScope>[] = [
        ...stages,
        actionR("close", "red", { type: IconType.CLASS, name: "close" })
    ];

    if (optionalActions?.save) {
        actions.unshift(actionL("save", "blue", { type: IconType.CLASS, name: "save" }, x => !x.loading));
    }

    if (optionalActions?.makeChanges) {
        actions.unshift(actionL("make-changes", "blue", "Внести изменения", x => !x.loading));
    }

    return actions;
};

const contractStages44Simple: ContractStageFn = () => {
    return [];
};

const contractStages44Eshop: ContractStageFn = ({ status, electronicConclusionState }) => {
    switch (status) {
        case (ObjectStatus.CREATING_FIRST):
        case (ObjectStatus.CREATING):
            return withDefaultActions([
                actionL("register", "blue", "Согласовать ФО", x => !x.loading),
            ], { save: true });
        case (ObjectStatus.FO_AGREED):
            return withDefaultActions(electronicConclusionState === "NOT_SENT" ? [
                actionL("make-changes", "blue", "Внести изменения", x => !x.loading),
                actionL("electronic-conclusion", "blue", "Электронное заключение", x => !x.loading)
            ] : [
                actionL("make-changes", "blue", "Внести изменения", x => !x.loading),
                actionL("register", "blue", "Зарегистрировать", x => !x.loading)
            ]);
        case (ObjectStatus.ESHOP_SIGNED_BY_SUPPLIER):
            return withDefaultActions([], { save: true });
        case (ObjectStatus.ESHOP_CONCLUDED):
            return withDefaultActions([
                actionL("register", "blue", "Согласовать ФО", x => !x.loading)
            ], { save: true });
        case (ObjectStatus.EXECUTING):
            return withDefaultActions([
                actionL("make-changes", "blue", "Внести изменения", x => !x.loading),
                actionR("offload-contract", "blue", "Выгрузить контракт в АИС «Портал поставщиков»", x => !x.loading)
            ]);
        case (ObjectStatus.COMPLETED):
            return withDefaultActions([
                actionR("offload-contract", "blue", "Выгрузить контракт в АИС «Портал поставщиков»", x => !x.loading)
            ]);
        default:
            return [];
    }
};

const contractStages223Eshop: ContractStageFn = ({ status }) => {
    switch (status) {
        case (ObjectStatus.CREATING_FIRST):
        case (ObjectStatus.CREATING):
            return withDefaultActions([
                actionL("electronic-conclusion", "blue", "Электронное заключение", x => !x.loading)
            ], { save: true });
        case (ObjectStatus.ESHOP_SIGNED_BY_SUPPLIER):
            return withDefaultActions([], { save: true });
        case (ObjectStatus.ESHOP_CONCLUDED):
            return withDefaultActions([
                actionL("register", "blue", "Зарегистрировать", x => !x.loading)
            ], { save: true });
        case (ObjectStatus.EXECUTING):
            return withDefaultActions([
                actionL("make-changes", "blue", "Внести изменения", x => !x.loading)
            ]);
        default:
            return [];
    }
};

export const contractStagesByLaw = {
    [LawType.F44]: (isEshop: boolean) => isEshop ? contractStages44Eshop : contractStages44Simple,
    [LawType.F223]: (isEshop: boolean) => isEshop ? contractStages223Eshop : contractStages44Simple
};

export const isConcludedEshopContract = (contract: Contract) =>{
    return contract.status === ObjectStatus.ESHOP_CONCLUDED;
};
