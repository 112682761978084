import {
    DtoMonopolySpecification, DtoMonopolySpecificationUpdateTemplate,
    MonopolySpecification,
    MonopolySpecificationUpdateTemplate
} from "@/models/Monopoly/MonopolySpecification";
import { DtoMonopolyDocument, MonopolyDocument } from "@/models/Monopoly/Document";
import { Monopoly, MonopolyStatus } from "@/models/Monopoly/Monopoly";
import { AutoDto, AutoModel, MDMap } from "@/models/parsing";

interface MonopolyTemplateMD {
    supplierId: number
    status: MonopolyStatus
    comment: string
    specifications: MDMap<MonopolySpecificationUpdateTemplate, DtoMonopolySpecificationUpdateTemplate>[]
    documents: MDMap<MonopolyDocument, DtoMonopolyDocument>[]
    subjectPurchase: string
}

export type MonopolyTemplate = AutoModel<MonopolyTemplateMD>;
export type DtoMonopolyTemplate = AutoDto<MonopolyTemplateMD>;

export const MonopolyTemplate = {
    toDto(monopolyTemplate: MonopolyTemplate): DtoMonopolyTemplate {
        return {
            ...monopolyTemplate,
            specifications: monopolyTemplate.specifications.map(MonopolySpecificationUpdateTemplate.toDto),
            documents: monopolyTemplate.documents.map(MonopolyDocument.toDto)
        };
    }
};

export const monopolyToTemplate = (object: Monopoly): MonopolyTemplate => ({
    supplierId: object.supplier.id,
    comment: object?.comment ?? "",
    specifications: object.specifications.map(specification => ({
        id: specification.id,
        spgzId: specification.spgz.id,
        unitPrice: specification.unitPrice,
        volume: specification.volume,
        totalPrice: specification.totalPrice,
        measurementUnitId: specification.measurementUnit.id,
        characteristics: specification.characteristics,
        supplyAddress: specification.supplyAddress,
        supplyStages: specification.supplyStages
    })),
    documents: object.documents,
    status: object.status,
    subjectPurchase: object.subjectPurchase
});
