import React, { useState } from "react";
import { ModalFC } from "@/EventBus";
import { SubcontractSpecification } from "@/models/Contracts/SubcontractSpecification";
import { Table } from "@/components/table";
import { formatNumber } from "@/NumberFormatting";
import { Button, ComboBox, TextBox } from "@/components/primitive";
import { taxPercentOptions } from "@/views/ComposedLot/edit/start-price/CommonPage";
import { Decimal } from 'decimal.js';
import { CardModal } from "@/components/modals/CardModal";
import { Expandee, HStack } from "@/components/layouts";

export const taxPercentOptionsString = taxPercentOptions.map(v => ({ ...v, key: v.key?.toString() }));

export const mapTaxPercentToString: {[key in "TAX_NONE" | "TAX_0" | "TAX_10" | "TAX_20"]: string} = {
    "TAX_NONE": "0",
    "TAX_0": "0",
    "TAX_10": "10",
    "TAX_20": "20"
};

const mapStringToTaxPercent: {[key in "0" | "10" | "20"]: "TAX_0" | "TAX_10" | "TAX_20"} = {
    "0": "TAX_0",
    "10": "TAX_10",
    "20": "TAX_20"
};

export const EditSubcontractorsSpecifications:
    ModalFC<{ specifications?: SubcontractSpecification[] }, SubcontractSpecification[]> = x => {
    const [allowSave, setAllowSave] = useState(false);
    const [editableProps, setEditableProps] = useState<{[key in "volume" | "cost" | "tax"]: string}[]>(
        x.specifications?.map(({ volume, cost, tax }) => ({
            volume: volume.toFixed(0),
            cost: cost.toFixed(0),
            tax: mapTaxPercentToString[tax]
        })) ?? []
    );

    const changeEditableProps = (key: "volume" | "cost" | "tax", index: number) => {
        setAllowSave(true);
        return (v: string) => setEditableProps(
            s => s.map((spec, idx) =>
                (idx === index ? { ...spec, [key]: v } : spec)
            )
        );
    };

    const bottom = <HStack spacing="10px">
        <Expandee />
        <Button onClick={() => x.done(x.specifications?.map((spec, index) => new SubcontractSpecification(
            spec.id,
            new Decimal(editableProps[index].volume),
            new Decimal(editableProps[index].cost),
            mapStringToTaxPercent[editableProps[index].tax as "0" | "10" | "20"],
            spec.specification
        )))} icon="aSave" color="blue" disabled={!allowSave} />
        <Button onClick={() => x.done(x.specifications)} icon="aClose" color="red" />
    </HStack>;

    return <CardModal title="Указание сведений о спецификациях субподрядного договора"
                      close={() => x.done(x?.specifications)}
                      width="65%"
                      bottom={bottom}>
        <Table<SubcontractSpecification> dataset={x?.specifications ?? []} columns={[
            Table.AutoColumn(
                "№",
                c => <>{c.rowIndex + 1}</>
            ),
            Table.Column(
                "СПГЗ",
                c => <>{c.item.specification.specification.subjectDeclaration?.subject ?? ""}</>
            ),
            Table.Column(
                "ОКПД2",
                c => <>{c.item.specification.specification.subjectDeclaration?.okpd2.name ?? ""}</>
            ),
            Table.Column(
                "Ед. измерения",
                c => <>{c.item.specification.specification.subjectDeclaration?.measurementUnits[0].name}</>
            ),
            Table.Column(
                "Объем",
                c => <>{formatNumber(c.item.specification.actualVolume)}</>
            ),
            Table.Column(
                "Сумма",
                c => <>{formatNumber(c.item.specification.actualCost)}</>
            ),
            Table.Column(
                "Объем субподряда",
                c =>
                    <TextBox value={editableProps[c.rowIndex].volume}
                                onChange={changeEditableProps("volume", c.rowIndex)} pattern={/-?[0-9\s]+[.,]?[0-9\s]*/} />
            ),
            Table.Column(
                "Сумма субподряда",
                c => <TextBox value={editableProps[c.rowIndex].cost}
                                 onChange={changeEditableProps("cost", c.rowIndex)} pattern={/-?[0-9\s]+[.,]?[0-9\s]*/} />
            ),
            Table.Column(
                "Цена за единицу, руб.",
                c => <>{formatNumber(new Decimal(editableProps[c.rowIndex].cost || 0)
                    .div(editableProps[c.rowIndex].volume || 1)
                    .mul(100 + ~~editableProps[c.rowIndex].tax)
                    .div(100))}
                </>
            ),
            Table.Column(
                "Цена за единицу без НДС, руб.",
                c => <>{formatNumber(new Decimal(
                    editableProps[c.rowIndex].cost || 0)
                    .div(editableProps[c.rowIndex].volume || 1))}
                </>
            ),
            Table.Column(
                "Ставка НДС",
                c => <ComboBox options={taxPercentOptionsString} value={editableProps[c.rowIndex].tax}
                               onChange={changeEditableProps("tax", c.rowIndex)} />
            )
        ]} />
    </CardModal>;
};
