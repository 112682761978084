import { IDtoStartPrice } from "@/models/json";
import { IJsonFormattable } from "@/models/IJsonFormattable";
import Decimal from "decimal.js";
import { StartPriceMethod } from './enums/StartPriceMethod';
import { Participant } from './Participant';

export class StartPrice implements IJsonFormattable<IDtoStartPrice> {
    constructor(
        public method: StartPriceMethod,
        public taxPercent: Decimal,
        public total: Decimal,
        public perItem: Decimal,
        public ratio: Decimal,
        public justification: string | null,
        public supplier: Participant | null,
    ) {}

    public get multiplied() { return this.total.mul(this.ratio) }

    public toJson(): IDtoStartPrice {
        return { 
            ...this,
            taxPercent: this.taxPercent.toString(),
            total: this.total.toString(),
            perItem: this.perItem.toString(),
            ratio: this.ratio.toString(),
            supplier: this.supplier && this.supplier.toJson(),
        };
    }

    // noinspection JSUnusedGlobalSymbols
    public static fromJson(dto: IDtoStartPrice): StartPrice {
        return Object.assign(Object.create(StartPrice.prototype), dto, {
            ...this,
            taxPercent: new Decimal(dto.taxPercent),
            total: new Decimal(dto.total),
            perItem: new Decimal(dto.perItem),
            ratio: new Decimal(dto.ratio),
            supplier: dto.supplier && Participant.fromJson(dto.supplier),
        });
    }
}
