// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, "._2V1q1ujnChq7dqxMdCnkrb {\n    display: inline-block;\n\n    font-size: 12px;\n    font-weight: 500;\n    color: #2e4358;\n}\n\n._3wZULxhmVifs2zdGCIJzSm {\n    font-size: 14px;\n}\n\n._2Hb_IOIy2xHZTgLNudkqEC {\n    display: inline-block;\n    color: red;\n    font-style: italic;\n    padding: 0 3px 0 1px;\n    font-size: 1.4em;\n    margin-top: -0.3em;\n    height: 0;\n}\n\n._2Hb_IOIy2xHZTgLNudkqEC > img {\n    height: calc(1em / 1.4); /* approx 0.715em, should be 12px (just ok with 12px font) */\n}\n\n._4F2bfHErmEyA1KWuN56Ld {\n    color: #9da8b0;\n}\n\n._2KgxxZaL7HIzWXwBwJ6BTD {\n    font-size: 14px;\n    font-weight: unset;\n}\n\n._1p7-v0-oC2M9LyFK3aF4NC {\n    color: #9da8b0;\n}\n\n/*copied from original eaist*/\n._1cPZkBHIeF0uYxvOj4zuUj {\n    font-size: 20px;\n    line-height: 1.2;\n    font-weight: 400;\n    font-family: robotolight, sans-serif;\n    margin-bottom: 15px;\n}\n\n/*from new eaist modals*/\n._3Zxwd5EEOQ-YDKbb6eKyuW {\n    font: 18px normal Roboto, Sans-Serif;\n    font-weight: 700;\n}\n\n._2oOuihUYk6f4c2cOpqIF2Q {\n    font-size: 14px;\n    font-weight: 700;\n}\n\n._22HjAexnt2G1n--jlTfxlr {\n    font-size: 30px;\n    font-weight: 400;\n}\n\n._18zSZskzJoHjR2rhjLOeHi {\n    font-size: 15px;\n    font-family: roboto, sans-serif;\n    font-style: normal;\n    font-weight: 300;\n    color: rgb(76,76,76);\n}", ""]);
// Exports
exports.locals = {
	"label": "_2V1q1ujnChq7dqxMdCnkrb",
	"medium": "_3wZULxhmVifs2zdGCIJzSm",
	"labelRequired": "_2Hb_IOIy2xHZTgLNudkqEC",
	"disabled": "_4F2bfHErmEyA1KWuN56Ld",
	"readonlyValue": "_2KgxxZaL7HIzWXwBwJ6BTD",
	"readonlyTitle": "_1p7-v0-oC2M9LyFK3aF4NC",
	"headerLarge": "_1cPZkBHIeF0uYxvOj4zuUj",
	"boldBig": "_3Zxwd5EEOQ-YDKbb6eKyuW",
	"boldSmall": "_2oOuihUYk6f4c2cOpqIF2Q",
	"pageTitle": "_22HjAexnt2G1n--jlTfxlr",
	"thinMedium": "_18zSZskzJoHjR2rhjLOeHi"
};
module.exports = exports;
