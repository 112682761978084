import React, {useEffect, useState} from "react";
import {Card} from "@/components/Card";
import {Column, Table} from "@/components/table";
import {
    convertWebinarToUpdate,
    Webinar,
    WebinarChannel,
    WebinarChannelUpdate,
    WebinarsApi,
    WebinarUpdate
} from "@/api/Webinars";
import {Button, CheckBox, DatePicker, DateTimePickerLib, Note, TextBox} from "@/components/primitive";
import {ModalFC, showModal} from "@/EventBus";
import {Expandee, HStack, VGrid, VStack} from "@/components/layouts";
import {CardModal} from "@/components/modals/CardModal";
import {Field} from "@/components/form/Field";
import {getMediaUrl, MediaApi, MediaItem} from "@/api/Media";
import {formatDate} from "@/dateUtils";
import nstyles from "@/views/Login/news/widgets/NewsItem.module.css";
import {NewsImagePlaceholder} from "@/views/Login/news/widgets/NewsItem";
import {UploadVideoModal} from "@/views/Admin/UploadVideoModal";
import styles from "./WebinarAdmin.module.css";
import {ConfirmationModal} from "@/views/Contracts/modals/ConfirmationModal";
import Editor, {theme} from "rich-markdown-editor";

const init: WebinarUpdate = { };

export const EditWebinarModal: ModalFC<{ item?: Webinar; videoLocation?: string }, Webinar> = x => {
    const [update, setUpdate] = useState<WebinarUpdate>(x.item ? convertWebinarToUpdate(x.item) : init);
    const [media, setMedia] = useState<string | undefined>(x.item?.thumbnailLocation);

    const save = async () => {
        if (x.item) {
            return await WebinarsApi.update(x.item.id, update).then(x.done);
        } else if (x.videoLocation) {
            return await WebinarsApi.createVideo({ ...update, videoLocation: x.videoLocation}).then(x.done);
        }
    };

    let canSave = true;
    if (!update.videoName) canSave = false;
    if (!update.description) canSave = false;

    const bottom: React.ReactNode = <HStack spacing="10px">
        <Expandee />
        <Button color="red" title="Закрыть" onClick={() => x.done(undefined)} />
        <Button color="blue" title="Сохранить" disabled={!canSave} onClick={save} />
    </HStack>;

    const uploadThumbnail = async (e: React.FormEvent<HTMLInputElement>) => {
        const result = await WebinarsApi.uploadVideoHandler(e, () => {});
        setUpdate({ ...update, thumbnailLocation: result.id});
        setMedia("/webinars/" + result.path);
    };

    const mediaUrl: string | undefined = media ? WebinarsApi.url + media : undefined;


    return <CardModal title={x.item ? "Редактирование вебинара" : "Создание вебинара"}
                      close={"disabled"}
                      bottom={bottom}
                      width="700px">
        <VStack spacing="10px">
            <Field required title="Заголовок">
                <TextBox value={update.videoName}
                         onChange={v => setUpdate({ ...update, videoName: v })} />
            </Field>
            <Field required title={`Описание`}>
                <div style={{ width: "100%", boxSizing: "border-box", height: "400px", overflow: "auto", padding: "5px", border: "1px solid black" }}>
                    <Editor defaultValue={x.item?.description ?? undefined}
                            key={x.item?.description}
                            uploadImage={async file => {
                                const formData = new FormData();
                                formData.append("file", file.slice(0, file.size, file.type));
                                const result = await MediaApi.uploadMedia(formData);
                                const mediaItem = Object.values(result)[0]?.result;
                                return getMediaUrl(mediaItem) ?? "";
                            }}
                            onImageUploadStop={() => {console.log("upload completed")}}
                            placeholder={"Начните писать текст"}
                        // theme={{ ...theme, zIndex: 1000000}}
                            onChange={v => setUpdate({ ...update, description: v() })} />
                </div>
            </Field>
            <VGrid columns="1fr 1fr" spacing="25px" alignItems="center">
                <div>
                    <Field required title="Дата">
                        <DateTimePickerLib value={update.videoDate ? new Date(update.videoDate) : undefined}
                                           onChange={v => setUpdate({ ...update, videoDate: v?.toISOString() })} />
                    </Field>
                </div>
                <div>
                    <Field title="">
                        <CheckBox value={update.published} onChange={v => setUpdate({ ...update, published: v })}>Опубликовать на главной странице</CheckBox>
                    </Field>
                </div>
            </VGrid>
            <VStack spacing="10px">
                <Field title="Изображение">
                    <div className={nstyles.image} style={{ width: "640px", height: "360px", objectFit: "cover", position: "relative", border: "1px solid black" }}>
                        {
                            mediaUrl
                                ? <img style={{ width: "640px", height: "360px", objectFit: "cover", background: "#DFDFE0" }} src={mediaUrl} />
                                : <NewsImagePlaceholder />
                        }
                        <div className={styles.uploadBg}>
                            <div className={styles.uploadButton}>
                                <svg width="84" height="76" viewBox="0 0 84 76" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M33.5989 46.4002H50.3989V21.2002H62.9989L41.9989 0.200195L20.9989 21.2002H33.5989V46.4002ZM81.2185 52.8346C80.3365 51.8938 74.4523 45.598 72.7723 43.9558C71.595 42.8273 70.0267 42.1981 68.3959 42.2002H61.0165L73.8853 54.775H59.0005C58.7982 54.7713 58.5985 54.821 58.4215 54.919C58.2446 55.0171 58.0966 55.1601 57.9925 55.3336L54.5653 63.2002H29.4325L26.0053 55.3336C25.9006 55.1607 25.7525 55.0181 25.5756 54.9202C25.3988 54.8222 25.1994 54.7721 24.9973 54.775H10.1125L22.9771 42.2002H15.6019C13.9345 42.2002 12.3427 42.868 11.2255 43.9558C9.54549 45.6022 3.66129 51.898 2.77929 52.8346C0.725495 55.0228 -0.404305 56.7658 0.133295 58.9204L2.48949 71.8312C3.02709 73.99 5.39169 75.7624 7.74789 75.7624H76.2583C78.6145 75.7624 80.9791 73.99 81.5167 71.8312L83.8729 58.9204C84.4021 56.7658 83.2765 55.0228 81.2185 52.8346Z" fill="#EAEAEA"/>
                                </svg>
                            </div>
                            <div className={styles.uploadLabel}>
                                Загрузить файл
                            </div>
                            <input type="file"
                                   accept="image/png,image/jpeg"
                                   multiple={false}
                                   onChange={uploadThumbnail} />
                        </div>
                    </div>
                </Field>
            </VStack>
        </VStack>
    </CardModal>;
};

export const ScheduleWebinarSection: React.FC = () => {
    const [item, setItem] = useState<WebinarChannel>();
    const [update, setUpdate] = useState<WebinarChannelUpdate>();
    const [media, setMedia] = useState<string>();

    const reload = async () => {
        const result = await WebinarsApi.getChannel("00000000-0000-0000-0000-000000000000");
        const img = result.offlineImageLocation?.split("/").pop();
        setUpdate({ ...result, offlineImageLocation: img });
        setMedia(img ? "/webinars/images/" + img : undefined);
        setItem(result);
    };

    const save = async () => {
        if (update) {
            await WebinarsApi.updateChannel("00000000-0000-0000-0000-000000000000",
                {
                channelName: update.channelName,
                description: update.description?.replaceAll("\\", ""),
                nextStreamDate: update.nextStreamDate,
                chatEnabled: update.chatEnabled,
                offlineImageLocation: update.offlineImageLocation
            });
            await reload();
        }
    };

    // зануляет nextStreamDate
    const unpublish = async () => {
        const confirm = await showModal(ConfirmationModal, {});
        if (!confirm) return;
        await WebinarsApi.unpublish();
        await reload();
    };

    const uploadThumbnail = async (e: React.FormEvent<HTMLInputElement>) => {
        const result = await WebinarsApi.uploadChannelFileHandler(e);
        setUpdate({ ...update, offlineImageLocation: result.id});
        setMedia("/webinars/" + result.path);
    };

    useEffect(() => {
        reload();
    }, []);

    let canSave = true;
    if (!update?.nextStreamDate) canSave = false;
    if (!update?.channelName) canSave = false;
    if (!update?.description) canSave = false;

    const title = <div style={{ display: "flex", gap: "20px" }}>
        <span>Анонс</span>
        <Expandee />
        <Button title="Сохранить" disabled={!canSave} color="green" onClick={save} />
        { item?.nextStreamDate && <Button title="Снять с публикации" color="red" onClick={unpublish} /> }
    </div>;
    const mediaUrl: string | undefined = media ? WebinarsApi.url + media : undefined;

    if (!update) return <></>;

    return <Card title={title}>
        <Note preset="alert" style={{ marginBottom: "25px" }}>
            Информация, введенная в этой секции, будет использоваться для последующих трансляций<br />
            Управлять статусом публикации архивных записей и загруженных видео можно в секции <b>Архив и публикация</b>
        </Note>
        <div style={{ display: "grid", gridTemplateColumns: "1fr 1fr", gap: "25px" }}>
            <VStack spacing="20px" innerStyle={{ maxWidth: "650px" }}>
                <Field required title="Заголовок">
                    <TextBox value={update.channelName}
                             onChange={v => setUpdate({ ...update, channelName: v })} />
                </Field>
                <Field required title="Описание">
                    <div style={{ width: "100%", boxSizing: "border-box", height: "400px", overflow: "auto", padding: "5px", border: "1px solid black" }}>
                        <Editor defaultValue={item?.description ?? undefined}
                                key={item?.description}
                                uploadImage={async file => {
                                    const formData = new FormData();
                                    formData.append("file", file.slice(0, file.size, file.type));
                                    const result = await MediaApi.uploadMedia(formData);
                                    const mediaItem = Object.values(result)[0]?.result;
                                    return getMediaUrl(mediaItem) ?? "";
                                }}
                                onImageUploadStop={() => {console.log("upload completed")}}
                                placeholder={"Начните писать текст"}
                                // theme={{ ...theme, zIndex: 1000000}}
                                onChange={v => setUpdate({ ...update, description: v() })} />
                    </div>
                    {/*<TextBox lines={10}*/}
                    {/*         placeholder={"Введите краткое описание вебинара - не более 500 символов"}*/}
                    {/*         value={update.description ?? undefined}*/}
                    {/*         onChange={v => setUpdate({ ...update, description: v })} />*/}
                </Field>
                <Field required title="Дата">
                    <DateTimePickerLib value={update.nextStreamDate ? new Date(update.nextStreamDate) : undefined}
                                       onChange={v => setUpdate({ ...update, nextStreamDate: v?.toISOString() })} />
                </Field>

            </VStack>
            <VStack spacing="10px">
                <Field title="Изображение">
                    <div className={nstyles.image} style={{ width: "640px", height: "360px", objectFit: "cover", position: "relative", border: "1px solid black" }}>
                        {
                            mediaUrl
                                ? <img style={{ width: "640px", height: "360px", objectFit: "cover", background: "#DFDFE0" }} src={mediaUrl} />
                                : <NewsImagePlaceholder />
                        }
                        <div className={styles.uploadBg}>
                            <div className={styles.uploadButton}>
                                <svg width="84" height="76" viewBox="0 0 84 76" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M33.5989 46.4002H50.3989V21.2002H62.9989L41.9989 0.200195L20.9989 21.2002H33.5989V46.4002ZM81.2185 52.8346C80.3365 51.8938 74.4523 45.598 72.7723 43.9558C71.595 42.8273 70.0267 42.1981 68.3959 42.2002H61.0165L73.8853 54.775H59.0005C58.7982 54.7713 58.5985 54.821 58.4215 54.919C58.2446 55.0171 58.0966 55.1601 57.9925 55.3336L54.5653 63.2002H29.4325L26.0053 55.3336C25.9006 55.1607 25.7525 55.0181 25.5756 54.9202C25.3988 54.8222 25.1994 54.7721 24.9973 54.775H10.1125L22.9771 42.2002H15.6019C13.9345 42.2002 12.3427 42.868 11.2255 43.9558C9.54549 45.6022 3.66129 51.898 2.77929 52.8346C0.725495 55.0228 -0.404305 56.7658 0.133295 58.9204L2.48949 71.8312C3.02709 73.99 5.39169 75.7624 7.74789 75.7624H76.2583C78.6145 75.7624 80.9791 73.99 81.5167 71.8312L83.8729 58.9204C84.4021 56.7658 83.2765 55.0228 81.2185 52.8346Z" fill="#EAEAEA"/>
                                </svg>
                            </div>
                            <div className={styles.uploadLabel}>
                                Загрузить файл
                            </div>
                            <input type="file"
                                   accept=".png, .jpg, .jpeg"
                                   multiple={false}
                                   onChange={uploadThumbnail} />
                        </div>
                    </div>
                </Field>
                <Field title={""} >
                    <CheckBox value={update.chatEnabled} onChange={v => setUpdate({ ...update, chatEnabled: v })}>Чат</CheckBox>
                </Field>
            </VStack>

        </div>

    </Card>;
};

export const WebinarAdminSection: React.FC<{ type: "archive" | "current"; reload: symbol; onReload: () => void }> = x => {
    const [webinars, setWebinars] = useState<Webinar[]>([]);
    const [pending, setPending] = useState<Webinar[]>([]);
    const reload = async () => {
        x.onReload();
    };

    const load = async () => {
        const result = x.type === "archive" ? await WebinarsApi.findArchived() : await WebinarsApi.findAll();
        const _pending: Webinar[] = [];
        const _result: Webinar[] = [];
        result.forEach(v => v.pending ? _pending.push(v) : _result.push(v));
        try { _result.sort((a, b) => new Date(b.videoDate).getTime() - new Date(a.videoDate).getTime()) } catch (e) {}
        setPending(_pending);
        setWebinars(_result);
    };

    useEffect(() => {
        load();
    }, [x.reload]);

    const edit = async (item: Webinar) => {
        const result = await showModal(EditWebinarModal, { item });
        reload();
    };

    const publish = async (id: number) => {
        await WebinarsApi.publish(id);
        reload();
    };

    const remove = async (item: Webinar) => {
        const confirm = await showModal(ConfirmationModal, {});
        if (!confirm) return;
        await WebinarsApi.remove(item.id);
        reload();
    };


    const columns: Column<Webinar>[] = [
        Table.Column("Название", x => <>{x.item.videoName}</>),
        // Table.Column("Описание", x => <>{x.item.description}</>),
        Table.Column("Дата", x => <>{formatDate(new Date(x.item.videoDate), "time")}</>),
        Table.Column("Медиа", x => <a target="_blank" href={x.item.thumbnailLocation ? WebinarsApi.url + x.item.thumbnailLocation : undefined} rel="noreferrer">{x.item.thumbnailLocation ? "Открыть" : "-"}</a>),
        Table.Column("Видео", x => <a target="_blank" href={x.item.videoLocation ? WebinarsApi.url + x.item.videoLocation : undefined} rel="noreferrer">{x.item.videoLocation ? "Открыть" : "-"}</a>),
        // Table.Column("Статус публикации", x => <>{x.item.published ? "Опубликовано" : "Не опубликовано"}</>),
        Table.AutoColumn("", x => <Button color="yellow" title={x.item.published ? "Снять с публикации" : "Опубликовать"} onClick={() => publish(x.item.id)} /> ),
        Table.AutoColumn("", x => <Button icon="gPencil" color="blue" onClick={() => edit(x.item)} /> ),
        Table.AutoColumn("", x => <Button icon="faTrash" color="red" onClick={() => remove(x.item)} /> ),
    ];

    const title = <div style={{ display: "flex", gap: "20px" }}>
        <span>{x.type === "archive" ? "Архив вебинаров" : "Опубликованные вебинары"}</span>
        <Expandee />
        { x.type === "current" && <Button title="Загрузить видео" color="green" onClick={() => showModal(UploadVideoModal).then(() => reload())} icon="aAdd" /> }
    </div>;

    return <Card title={title}>
        { !!pending.length && <Note preset="alert" style={{ marginBottom: "25px" }}>
            Идет обработка записей вебинаров: { pending.map(v => v.videoName).join("; ") }<br />Публикация записи будет доступна после завершения обработки.
        </Note> }
        <Table dataset={webinars} columns={columns} />
    </Card>;
};
