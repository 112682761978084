import { IPagedItems, mapPage } from "@/api/Pagination";
import { http, IFilterObject } from "@/api/http";
import {Provision} from "@/models/Provision/Provision";
import { uuid } from "@/models/parsing";
import {HistoryItem} from "@/models";

export const getProvisions = async (from: number, count: number, filters: IFilterObject): Promise<IPagedItems<Provision>> =>
    mapPage(
        Provision,
        await http.getPaged("/api/v2/provisions/", { from, count, filters })
    );

export const getProvision = async (id: uuid) =>
    Provision.fromDto(
        await http.get(`/api/v2/provisions/${id}`)
    );

export const getProvisionHistory = async (id: uuid, from: number, count: number, filters: IFilterObject): Promise<IPagedItems<HistoryItem>> =>
    mapPage(
        HistoryItem,
        await http.getPaged(`/api/v2/provisions/${id}/history`, { from, count, filters })
    );
