import { createEvent, createStore } from "effector";
import { Plan } from "@/models/Plans/Plan";
import { uuid } from "@/models/parsing";
import { getPlan, getPlanHistory } from "@/api/Plans2020";
import { createStoredRemoteDataset, StoredRemoteDataset } from "@/components/table";
import { Lot } from "@/models/ComposedLots";
import { getLots } from "@/api/ComposedLots";
import { FilterConditionType, FilterValueType } from "@/api/http";
import Auth from "@/api/Auth";
import { HistoryItem } from "@/models";

export interface PlanState {
    plan?: Plan
    lotsDataset?: StoredRemoteDataset<Lot>
    history: HistoryItem[]
}

export const setPlan = createEvent<Plan>();
export const setHistory = createEvent<HistoryItem[]>();

export const createPlanStore = (planId: uuid) =>{
    const store = createStore<PlanState>({ history: [] })
        .on(setPlan, (x, v) => ({
            ...x,
            plan: v,
            lotsDataset: createStoredRemoteDataset({
                load: (filters, from, count) => getLots(Auth.selectedYear, from, count, {
                    ...filters,
                    law: [
                        { type: FilterValueType.STRING, conditionType: FilterConditionType.EQUAL, string: v.law },
                    ],
                    planId: [
                        { type: FilterValueType.GUID, conditionType: FilterConditionType.EQUAL, guid: planId },
                    ]
                })
            })
        }))
        .on(setHistory, (state, payload) => ({ ...state, history: payload }));

    getPlan(planId).then(setPlan);
    getPlanHistory(planId).then(setHistory);


    return store;
};
