import { http } from "./http";
import { ContractDocument, ContractGuarranteeEvent } from "@/models/Contracts";
import { IDtoContractGuarranteeEvent } from '@/models/json/Contracts/IDtoContractGuarranteeEvent';

export default class {
    public static async getFromContract(contractId: string): Promise<ContractGuarranteeEvent[]> {
        return ((await http.get(
            `/api/contractGuarranteeEvents/fromContract/${contractId}`,
            undefined,
        )) as IDtoContractGuarranteeEvent[]).map(ContractGuarranteeEvent.fromJson);
    }

    public static async create(
        contractId: string,
        src: ContractGuarranteeEvent,
    ): Promise<ContractGuarranteeEvent> {
        return ContractGuarranteeEvent.fromJson(
            await http.post(`/api/contractGuarranteeEvents/createFor/${contractId}`, src.toJson()),
        );
    }

    public static async update(obj: ContractGuarranteeEvent): Promise<ContractGuarranteeEvent> {
        return ContractGuarranteeEvent.fromJson(
            await http.put(`/api/contractGuarranteeEvents/${obj.id}`, obj.toJson()),
        );
    }

    public static async delete(obj: ContractGuarranteeEvent): Promise<void> {
        await http.delete(`/api/contractGuarranteeEvents/${obj.id}`);
    }

    public static async createDocument(obj: ContractGuarranteeEvent, doc: ContractDocument): Promise<ContractDocument> {
        return ContractDocument.fromJson(
            await http.post(`/api/contractGuarranteeEvents/${obj.id}/documents`, doc.toJson()));
    }

    public static async deleteDocument(obj: ContractGuarranteeEvent, doc: ContractDocument): Promise<void> {
        await http.delete(`/api/contractGuarranteeEvents/${obj.id}/documents/${doc.id}`);
    }
}
