import React from "react";
import {SectionProps} from "@/views/Procedures2020/view/store";
import {useMappedStore} from "@/storeUtils";
import {Column, Table} from "@/components/table";
import {Card} from "@/components/Card";
import {VStack} from "@/components/layouts";

/** Обмен с АСУ ГФ */
export const AsuGfExchangeSection: React.FC<SectionProps> = x => {

    /*
        №
        Исходящий файл
        Дата отправки
        Подтверждение получения
        Дата приема подтверждения
        Сведения о согласовании
        Дата получения сведений
    */
    const columns: Column<undefined>[] = [
        Table.Column("№", a => <div></div>, {width: "1fr"}),
        Table.Column("Исходящий файл", a => <div></div>, {width: "2fr"}),
        Table.Column("Дата отправки", a => <div></div>, {width: "2fr"}),
        Table.Column("Подтверждение получения", a => <div></div>, {width: "2fr"}),
        Table.Column("Дата приема подтверждения", a => <div></div>, {width: "2fr"}),
        Table.Column("Сведения о согласовании", a => <div></div>, {width: "2fr"}),
        Table.Column("Дата получения сведений", a => <div></div>, {width: "2fr"}),
    ];

    const sectionStore = useMappedStore(x.formStore, x => ({

    }));


    return <Card>
        <VStack spacing="15px">
            <Table dataset={[]} columns={columns}/>
        </VStack>
    </Card>;
};