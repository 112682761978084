import {AutoDto, AutoModel, uuid} from "@/models/parsing";

interface CancellationReasonMD {
    id: uuid
    reason: string
}

export type CancellationReason = AutoModel<CancellationReasonMD>;
export type DtoCancellationReason = AutoDto<CancellationReasonMD>;

export const CancellationReason = {
    fromDto(dto: DtoCancellationReason): CancellationReason {
        return {
            ...dto
        };
    },
};