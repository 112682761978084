import { uuid } from "@/models/parsing";
import { ContractObligation } from "@/models/Contracts/ContractObligation";
import { createEvent, createStore, Store } from "effector";
import {
    getContractObligation,
    getContractObligationHistory,
    getContractObligationReceipts
} from "@/api/ContractObligations";
import { createStoredRemoteDataset, StoredRemoteDataset } from "@/components/table";
import { ContractObligationReceipt } from "@/models/Contracts/ContractObligationReceipt";
import { HistoryItem } from "@/models";

export type ObligationStoreState = {
    obligation?: ContractObligation
    history: HistoryItem[]
    receipts: StoredRemoteDataset<ContractObligationReceipt>
};

export type ObligationStore = {
    store: Store<ObligationStoreState>
};

export const createObligationStore = (id: uuid): ObligationStore => {
    const setObligation = createEvent<ContractObligation>();
    const setHistory = createEvent<HistoryItem[]>();

    const store = createStore<ObligationStoreState>({
        obligation: undefined,
        history: [],
        receipts: createStoredRemoteDataset<ContractObligationReceipt>({
            async load(_filters, from, count) {
                return getContractObligationReceipts(id, from, count);
            }
        })
    })
        .on(setObligation, (s, obligation) => ({ ...s, obligation }))
        .on(setHistory, (s, history) => ({ ...s, history }));

    getContractObligation(id).then(setObligation);
    getContractObligationHistory(id).then(setHistory);

    return {
        store
    };
};
