import {ModalFC} from "@/EventBus";
import {LotDocumentType} from "@/models/enums/Documents";
import {LotDocument} from "@/models/ComposedLots/documents";
import {CardModal} from "@/components/modals/CardModal";
import React, {useState} from "react";
import {Expandee, HStack} from "@/components/layouts";
import {Button, ComboBox} from "@/components/primitive";
import {Field} from "@/components/form/Field";
import {SelectOption} from "@/models/enums";


export type Country = "RU" | "US" | "AB" | "AU" | "AZ" | "AL" | "DZ" | "BD";
export const CountryStrings: Record<Country, string> = {
    "RU": "РОССИЯ",
    "US": "СОЕДИНЕННЫЕ ШТАТЫ",
    "AB": "Абхазия",
    "AU": "Австралия",
    "AZ": "Азейрбайджан",
    "AL": "Албания",
    "DZ": "Алжир",
    "BD": "Бангладеш",
};

export const CountryOptions: SelectOption<Country>[] = [
    { key: null!, desc: 'Все' },
    { key: 'RU', desc: CountryStrings["RU"] },
    { key: 'US', desc: CountryStrings["US"] },
    { key: 'AB', desc: CountryStrings["AB"] },
    { key: 'AU', desc: CountryStrings["AU"] },
    { key: 'AZ', desc: CountryStrings["AZ"] },
    { key: 'AL', desc: CountryStrings["AL"] },
    { key: 'DZ', desc: CountryStrings["DZ"] },
    { key: 'BD', desc: CountryStrings["BD"] },
];

export const CountrySelectModal: ModalFC<{}, string> = x => {
    const [country, setCountry] = useState<string>();

    const bottom = <HStack spacing={"10px"}>
        <Expandee/>
        <Button color="green"
                title="указать"
                onClick={() => x.done(country)} />
    </HStack>;

    return <CardModal title={"Страна происхождения"}
                      close={() => x.done(undefined)}
                      width={"50%"}
                      bottom={bottom}
    >
        <Field title={"Указать страну происхождения товара, страну производителя"}>
            <ComboBox options={CountryOptions} value={country} onChangeNonStrict={setCountry} />
        </Field>
    </CardModal>;
};
