import {Store} from "effector";
import {
    CalculatorStore, setCurrentMethod, setIsNewPriceSectionVisible, setIsPriceReloadRequired,
    setMarketPricesResult,
    setSelectedMarketPrices
} from "@/views/ComposedLot/edit/start-price/store";
import {MarketPriceDataSource, MarketPriceDataSourceStrings} from "@/models/enums";
import {useMappedStore} from "@/storeUtils";
import React, {createRef, forwardRef, useState} from "react";
import {
    FilterColumn,
    FilteredSelectionTable,
    Pager, setPageSize,
    useStoredRemoteDataset
} from "@/components/table";
import {MarketPrice} from "@/models";
import MarketPrices from "@/api/MarketPrices";
import {HSpread, HStack, VStack} from "@/components/layouts";
import {Button, ComboBox, DatePicker, DecimalBox, Label, TextBox} from "@/components/primitive";
import {Card} from "@/components/Card";
import {formatNumber} from "@/NumberFormatting";
import {formatDate} from "@/dateUtils";
import styles from "./MarketPricesPage.module.css";
import Decimal from "decimal.js";
import {addMonths} from "date-fns";
import {useScroll} from "@/components/ScrollBox";

interface MarketPricesTableProps {
    store: Store<CalculatorStore>
    dataSource: MarketPriceDataSource
    addNew?: () => void
}

const MarketPricesTable = <T,>(x: MarketPricesTableProps) => {
    type Filters = {
        name: string
    };

    const [dataset, selected, reloadRequired] = useMappedStore(x.store, s => [
        s.priceDatasets[x.dataSource],
        s.selectedPrices[x.dataSource],
        s.isPriceReloadRequired && x.dataSource === "MARKET_PRICE"
    ]);

    const [showAll, setShowAll] = useState(false);
    const [isFiltersVisible, setFiltersVisible] = useState(false);

    setPageSize(dataset, showAll ? 1000000 : undefined);
    const remoteDataset = useStoredRemoteDataset(dataset);

    if (reloadRequired) {
        remoteDataset.setCurrentPage(0, true);
        setIsPriceReloadRequired(false);
    }

    const title = <HSpread alignItems="center">
        <div>{MarketPriceDataSourceStrings[x.dataSource]}</div>
        <HStack spacing="10px">
            {
                x.addNew && <Button onClick={x.addNew} icon="aAdd" />
            }
            <Button onClick={() => setFiltersVisible(!isFiltersVisible)} icon="aFilter" />
            <Button onClick={() => setShowAll(!showAll)} title="отобразить все/не все" />
        </HStack>
    </HSpread>;

    return <Card title={title}>
        <VStack spacing="15px">
            <FilteredSelectionTable<MarketPrice, Filters>
                dataset={remoteDataset.dataset}
                filterStore={remoteDataset.filterStore}
                isFiltersVisible={isFiltersVisible}
                selectorPosition={"atLeft"}
                mode="multi"
                selected={selected}
                onChange={s => setSelectedMarketPrices({ dataSource: x.dataSource, selected: s })}
                columns={[
                    FilterColumn({
                        header: "Наименование позиции",
                        filter: {
                            type: "string",
                            key: "name"
                        },
                        cell: x => <>{x.item.name}</>,

                    }),
                    FilterColumn({
                        header: "Цена за единицу с НДС",
                        cell: x => <>{formatNumber(x.item.priceWithTax)}</>,
                    }),
                    FilterColumn({
                        header: "Источник данных",
                        cell: x => <>{MarketPriceDataSourceStrings[x.item.dataSource]}</>,
                    }),
                    FilterColumn({
                        header: "Поставщик",
                        cell: x => <>{x.item.producer?.shortName ?? x.item.producerName ?? ""}</>,
                    }),
                    FilterColumn({
                        header: "Дата начала действия",
                        cell: x => <>{formatDate(x.item.startDate)}</>,
                    }),
                    FilterColumn({
                        header: "Дата окончания действия",
                        cell: x => <>{formatDate(x.item.expirationDate)}</>,
                    })
                ]}/>
            {
                remoteDataset.total === 0 && <div className={styles.noInfo}>
                    Информация отсутствует
                </div>
            }
            <Pager remoteDataset={remoteDataset} />
        </VStack>
    </Card>;
};

const MarketPricesInfoSection = (x: {store: Store<CalculatorStore>; returnBack: () => void}) => {
    const [specification, prices, calculatedAndSaved]
        = useMappedStore(x.store, x => [x.specification, x.selectedPrices, x.marketPricesResult]);

    const [calculatedAndNotSaved, setCalculatedAndNotSaved] = useState<Decimal | undefined>();
    const [calculationError, setCalculationError] = useState<{ text: string; critical: boolean } | undefined>();

    const calculated = calculatedAndNotSaved ?? calculatedAndSaved;

    const calculate = () => {
        const totalSelection = [
            ...prices.MARKET_PRICE,
            ...prices.PROVIDER_PORTAL,
            ...prices.PROVIDER_OFFER
        ];

        if (totalSelection.length < 3) {
            setCalculationError({ text: "Должно быть выбрано не менее трех цен!", critical: true });
            return;
        }

        setCalculationError(undefined);

        const len = totalSelection.length;
        for (let i = 0; i < len; i++) {
            const lhs = totalSelection[i];

            for (let j = i + 1; j < len; j++) {
                const rhs = totalSelection[j];

                const div = lhs.priceWithTax.div(rhs.priceWithTax);
                if (div.gt(1.25) || div.lt(0.8)) {
                    setCalculationError({ text: "Выбранные для расчета цены отличаются более чем на 25%", critical: false });
                }
            }
        }

        setCalculatedAndNotSaved(totalSelection
            .reduce((p, x) => p.add(x.priceWithTax), new Decimal(0))
            .div(totalSelection.length)
            .toNearest(0.01, Decimal.ROUND_DOWN)
            .mul(specification.volume));
    };

    const save = () => {
        if (!calculatedAndNotSaved) return;

        setMarketPricesResult(calculatedAndNotSaved);
        setCurrentMethod("MARKET_PRICES");
        setCalculatedAndNotSaved(undefined);
        x.returnBack();
    };

    return <Card title="Информация для расчёта НМЦ">
        {
            calculationError && <div className={styles.marketPriceCalculationError}>
                {calculationError.text}
            </div>
        }
        <div className={styles.marketPriceInformationCard}>
            <Label className={styles.informationCardLabel}>СПГЗ:</Label>
            <TextBox disabled value={specification.subjectDeclaration.subject} />

            <Label className={styles.informationCardLabel}>Количество:</Label>
            <TextBox disabled value={formatNumber(specification.volume)}/>

            <Label className={styles.informationCardLabel}>Валюта:</Label>
            <TextBox disabled value="Российский рубль"/>

            <Label className={styles.informationCardLabel}>Ед. изм.:</Label>
            <TextBox disabled value={specification.measurementUnit.name}/>

            <Button color="green" title="рассчитать нмц" onClick={calculate}/>

            <Label className={styles.informationCardLabel}>НМЦ:</Label>
            <TextBox disabled value={calculated ? formatNumber(calculated) : ""}/>

            <Button icon="aSave" onClick={save} disabled={calculationError?.critical || !calculatedAndNotSaved}/>
        </div>
    </Card>;
};

const cleanMarketPriceState = {
    name: "",
    price: undefined as undefined | Decimal,
    taxPercent: 0,
    supplier: "",
    taxes: "",
    startDate: undefined as Date | undefined
};

const taxPercentOptions = [
    {key: 0, desc: "0% / не облагается"},
    {key: 10, desc: "10%"},
    {key: 20, desc: "20%"}
];

export const NewMarketPriceSection = forwardRef<HTMLDivElement, {store: Store<CalculatorStore>}>((x, ref) => {
    const visible = useMappedStore(x.store, x => x.isNewPriceSectionVisible);

    const [state, setState] = useState(cleanMarketPriceState);

    const close = () => {
        setState(cleanMarketPriceState);
        setIsNewPriceSectionVisible(false);
    };

    const [file, setFile] = useState("");

    const validate: boolean = !!file;

    const save = async () => {
        if (!state.name) return;
        if (!state.price) return;
        if (!state.startDate) return;

        await MarketPrices.create(new MarketPrice(
            null!,
            state.name,
            state.price.mul(new Decimal(state.taxPercent).mul(-1).add(100)).div(100),
            state.price,
            state.startDate,
            addMonths(state.startDate, 6),
            MarketPriceDataSource.MARKET_PRICE,
            new Decimal(state.taxPercent),
            null,
            state.supplier
        ), x.store.getState().specification.subjectDeclaration.id);
        setState(cleanMarketPriceState);
        setIsNewPriceSectionVisible(false);
        setIsPriceReloadRequired(true);

        setFile("");
    };

    const head = <HSpread alignItems="center">
        <div>Новая цена</div>
        <HStack spacing="10px">
            <Button icon="aClose" color="red" onClick={close} />
            <Button icon="aSave" onClick={save} disabled={!validate} />
        </HStack>
    </HSpread>;


    return <Card title={head} className={visible ? "" : styles.hidden} ref={ref}>
        <div className={styles.informationCard}>
            <Label required className={styles.informationCardLabel}>Наименование позиции:</Label>
            <Label required className={styles.informationCardLabel}>Цена за единицу с НДС:</Label>
            <Label required className={styles.informationCardLabel}>НДС.:</Label>
            <Label required className={styles.informationCardLabel}>Подтверждающий документ:</Label>
            <Label required className={styles.informationCardLabel}>Поставщик:</Label>
            <Label required className={styles.informationCardLabel}>Поставщик (Ручной ввод):</Label>
            <Label className={styles.informationCardLabel}>Таможенные пошлины и сборы:</Label>
            <Label required className={styles.informationCardLabel}>Дата начала действия:</Label>

            <TextBox value={state.name} onChange={x => setState({...state, name: x})}/>
            <DecimalBox value={state.price} onChange={x => setState({...state, price: x})}/>
            <ComboBox options={taxPercentOptions}
                      value={state.taxPercent}
                      onChange={x => setState({...state, taxPercent: x})} />
            <VStack spacing="5px">
                <input type="file" value={file} onChange={e => setFile(e.target.value)} />
                {
                    !file &&
                        <Label preset="thinMedium" style={{ color: "red" }} text="Не прикреплен подтвержадющий документ" />
                }
            </VStack>
            <TextBox disabled/>
            <TextBox value={state.supplier} onChange={x => setState({...state, supplier: x})}/>
            <TextBox value={state.taxes} onChange={x => setState({...state, taxes: x})}/>
            <DatePicker value={state.startDate} onChange={x => setState({...state, startDate: x})}/>
        </div>
    </Card>;
});

export const MarketPricesPage = (x: {store: Store<CalculatorStore>; returnBack: () => void}) => {
    const scroll = useScroll();
    const newPriceSectionRef = createRef<HTMLDivElement>();

    const addNewPrice = () => {
        setIsNewPriceSectionVisible(true);

        if (newPriceSectionRef.current) {
            scroll.scrollToElement(newPriceSectionRef.current, -100);
        }
    };

    return <>
        <MarketPricesInfoSection store={x.store} returnBack={x.returnBack}/>
        <MarketPricesTable dataSource="MARKET_PRICE" store={x.store}/>
        <MarketPricesTable dataSource="PROVIDER_PORTAL" store={x.store}/>
        <MarketPricesTable dataSource="PROVIDER_OFFER" store={x.store} addNew={addNewPrice}/>
        <NewMarketPriceSection store={x.store} ref={newPriceSectionRef}/>
    </>;
};