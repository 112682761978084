import Decimal from "decimal.js";
import { AutoDto, AutoModel } from "@/models/parsing";

interface ProvisionSingleProviderReasonMD {
    content: string
    isAggregated: boolean
    isElectronicExecution: boolean
    maxPrice: Decimal
    maxTotal: Decimal
    maxVolume: Decimal
    serialNumber: number
}

export type ProvisionSingleProviderReason = AutoModel<ProvisionSingleProviderReasonMD>;
export type DtoProvisionSingleProviderReason = AutoDto<ProvisionSingleProviderReasonMD>;

export const ProvisionSingleProviderReason = {
    fromDto(dtoProvisionSingleProviderReason: DtoProvisionSingleProviderReason): ProvisionSingleProviderReasonMD {
        return {
            ...dtoProvisionSingleProviderReason,
            maxPrice: new Decimal(dtoProvisionSingleProviderReason.maxPrice),
            maxTotal: new Decimal(dtoProvisionSingleProviderReason.maxTotal),
            maxVolume: new Decimal(dtoProvisionSingleProviderReason.maxVolume)
        };
    }
};
