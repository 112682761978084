import React, {useState} from "react";
import {Store} from "effector";
import {Column, FilterColumn, FilteredSelectionTable, Pager, Table, useStoredRemoteDataset} from "@/components/table";
import {useStore} from "effector-react";
import {Button, Label, Note} from "@/components/primitive";
import {Expandee, HStack, VStack} from "@/components/layouts";
import {SuppliersStore} from "@/views/ReferenceInfo/Suppliers/list/store";
import {SuppliersFilters} from "@/views/ReferenceInfo/Suppliers/list/store";
import router from "@/router";
import {Supplier} from "@/models/Supplier";
import {statusMapping} from "@/models/SubjectClass/SubjectClass";

export const Suppliers: React.FC<{ storeContainer: { store: Store<SuppliersStore> } }> = x => {
    const state = useStore(x.storeContainer.store);
    const rds = useStoredRemoteDataset(state.suppliers);

    const [filtersVisible, setFiltersVisible] = useState(true);

    const columns: Column<Supplier>[] = [
        FilterColumn<Supplier, SuppliersFilters>({
            header: "Полное наименование",
            cell: c => <Label onClick={() => router.push(`/reference-info/participants/suppliers/${c.item.id}`)}
                              style={{ textDecoration: "underline", cursor: "pointer" }}>
                {c.item.fullName}
            </Label>,
            filter: {
                type: "string",
                key: "fullName",
            },
        }),
        FilterColumn<Supplier, SuppliersFilters>({
            header: "Краткое наименование",
            cell: c => <Label>
                {c.item.shortName}
            </Label>,
            filter: {
                type: "string",
                key: "shortName",
            },
        }),
        FilterColumn<Supplier, SuppliersFilters>({
            header: "ИНН",
            cell: c => <Label>
                {c.item.tin}
            </Label>,
            filter: {
                type: "string",
                key: "tin",
            },
        }),
        FilterColumn<Supplier, SuppliersFilters>({
            header: "КПП",
            cell: c => <Label>
                {c.item.rrc}
            </Label>,
            filter: {
                type: "string",
                key: "rrc",
            },
        }),
        FilterColumn<Supplier, SuppliersFilters>({
            header: "Код налогоплательщика в стране регистрации",
            cell: c => <Label>
                {c.item.regNumber}
            </Label>,
            filter: {
                type: "string",
                key: "spzCode", // TODO: wrong key
            },
        }),
        FilterColumn<Supplier, SuppliersFilters>({
            header: "ОГРН",
            cell: c => <Label>
                {c.item.spzCode}
            </Label>,
            filter: {
                type: "string",
                key: "regNumber",
            },
        }),
        FilterColumn<Supplier, SuppliersFilters>({
            header: "Статус",
            cell: c => <Label>
                {statusMapping[c.item.status]}
            </Label>
        }),
        Table.Column("Головная организация", c =>
            <Label>
                {c.item.parentOrganization}
            </Label>)
    ];

    return <VStack outerStyle={{margin: "30px"}} spacing="15px">
        <Note preset="title">
            <HStack alignItems="center" spacing={"10px"}>
                <div>Список поставщиков</div>
                <Expandee/>
                <Button icon="aFilter" onClick={() => setFiltersVisible(!filtersVisible)}/>
                <Button title="Экспорт" disabled />
            </HStack>
        </Note>
        <Note preset="panel">
            <FilteredSelectionTable
                filterStore={rds.filterStore}
                isFiltersVisible={filtersVisible}
                mode="single"
                selectorPosition="hidden"
                dataset={rds.dataset}
                columns={columns}/>
        </Note>
        <Pager remoteDataset={rds}/>
    </VStack>;
};