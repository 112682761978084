




















import { ITableColumn, IRowConfiguration } from "@/components/TableAbstractions";
import { formatDate } from "@/DateFormatting";
import EventBus from "@/EventBus";
import { ContractClaimWork, ContractDocument } from "@/models/Contracts";
import { ClaimWorkInitiatorStrings, ChargeTypeStrings } from "@/models/enums/Contracts";
import { formatNumber } from "@/NumberFormatting";
import { Component, Vue, Prop } from "vue-property-decorator";
import DataTable from "@/components/vue/DataTable.vue";
import AddClaim from "./AddClaim.vue";
import { SimpleRow } from "@/components/vue/form-table/FormFields";
import { IFieldProps } from "@/components/vue/form-table/FieldBase";
import { ContractScope } from "./ContractScope";
import ContractClaimWorks from "@/api/ContractClaimWorks";
import { ObjectStatus, EnumStrings } from '@/models/enums';

const states: EnumStrings = {
    [ObjectStatus.CREATING]: "Ввод сведений",
    [ObjectStatus.REGISTERING]: "Регистрация",
    [ObjectStatus.REGISTERED]: "Зарегистрирован",
};

@Component({ components: { DataTable } })
export default class Claims extends Vue {
    @Prop() public props!: IFieldProps<SimpleRow<ContractScope>>;

    public headers: ITableColumn<ContractClaimWork>[] = [
        {
            title: "Инициатор",
            getter: src => ClaimWorkInitiatorStrings[src.initiator],
        },
        {
            title: "Этап контракта",
            getter: src => src.stage.name,
        },
        {
            title: "Дата начала",
            getter: src => formatDate(src.startDate),
        },
        {
            title: "Тип взыскания",
            getter: src => ChargeTypeStrings[src.chargeType],
        },
        {
            title: "Начислено, руб.",
            getter: src => formatNumber(src.assessedCharge),
        },
        {
            title: "Дата окончания",
            getter: src => formatDate(src.endDate),
        },
        {
            title: "Взыскано, руб.",
            getter: src => src.paidCharge && formatNumber(src.paidCharge),
        },
        {
            title: "Статус",
            getter: src => states[src.status],
        },
    ];

    private law = this.props.source.contract.law;

    public createConfig(): IRowConfiguration<ContractDocument> {
        const that = this;

        return  {
            get useSlotForActionColumn() {
                return that.props.source.contract && (
                    that.props.source.contract.status === ObjectStatus.EXECUTING ||
                    that.props.source.contract.status === ObjectStatus.DISSOLVED ||
                    that.props.source.contract.status === ObjectStatus.COMPLETED);
            }
        };
    }

    public config = this.createConfig();

    public get dataset(): ContractClaimWork[] { return this.props.source.claimWorks }

    public async addEdit(src: ContractClaimWork | null) {
        const result = await EventBus.callModal<
            { claimWork: ContractClaimWork | null; contractId: string; scope: ContractScope },
            ContractClaimWork
        >(AddClaim, {
            claimWork: src,
            contractId: this.props.source.contract!.id,
            scope: this.props.source,
        });
        if (result.isOk) {
            const r = result.getResult();
            if (!src) {
                this.dataset.push(r);
            } else {
                const ix = this.dataset.findIndex(x => x.id === r.id);
                this.dataset.splice(ix, 1, r);
            }
        }
    }

    public async deleteClaim(src: ContractClaimWork) {
        await ContractClaimWorks.delete(src);

        const ix = this.dataset.indexOf(src);
        if (ix !== -1) this.dataset.splice(ix, 1);
    }

}
