import React from "react";
import {Card} from "@/components/Card";
import {Label} from "@/components/primitive";
import {Field, Fields} from "@/components/form/Field";

export const CustomersStatisticalCodes: React.FC = x => {
    return <Card>
        <Fields orientation="horizontal">
            <Field title="ОКПО">
                <Label>
                    40063648
                </Label>
            </Field>
            <Field title="ОКАТО">
                <Label>
                    Центральный
                </Label>
            </Field>
            <Field title="ОКТМО">
                <Label>
                    123
                </Label>
            </Field>
            <Field title="ОКФС">
                <Label>
                    Муниципальный округ Басманный
                </Label>
            </Field>
            <Field title="ОКОГУ">
                <Label>
                    Собственность субъектов Российской Федерации
                </Label>
            </Field>
            <Field title="ОКОПФ">
                <Label>
                    Здравоохранения
                </Label>
            </Field>
            <Field title="ОКСМ">
                <Label>
                    643
                </Label>
            </Field>
            <Field title="ОКВЭД">
                <Label>
                    85.12 Образование начальное общее
                </Label>
            </Field>
        </Fields>
    </Card>;
};