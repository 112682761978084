import React, { ReactNode, useState } from "react";
import { Card } from "@/components/Card";
import { Field, Fields } from "@/components/form/Field";
import { CheckBox, ComboBox, Label, TextBox } from "@/components/primitive";
import { HSpread, HStack } from "@/components/layouts";

export const CodesSection = () => {
    const [selectedAuction, setSelectedAuction] = useState<number>(1);

    return (
        <Card>
            <Fields orientation="horizontal">
                <CodeItem title="Закупка у единственного поставщика" typical value="169" />
                <CodeItem title="Котировочная сессия" typical />
                <CodeItem title="Открытый конкурс" typical />
                <CodeItem title="Электронный конкурс" typical value="4781" />
                <CodeItem title="Двухэтапный конкурс" typical />
                <CodeItem title="Электронный двухэтапный конкурс" typical />
                <CodeItem title="Открытый аукцион" typical />
                <CodeItem title="Электронный аукцион" typical value="4787">
                    <ComboBox options={[
                        { key: 1, desc: "Двухэтапный" },
                        { key: 2, desc: "Одноэтапный" }
                    ]} value={selectedAuction} style={{ width: "100%" }} onChange={setSelectedAuction} />
                </CodeItem>
                <CodeItem title="Запрос котировок" typical />
                <CodeItem title="Электронный запрос котировок" typical />
                <CodeItem title="Запрос предложений" typical />
                <CodeItem title="Электронный запрос предложений" typical />
                <CodeItem title="Электронный конкурс среди МСП" typical />
                <CodeItem title="Электронный аукцион среди МСП" typical />
                <CodeItem title="Электронный запрос котировок среди МСП" typical />
                <CodeItem title="Электронный запрос предложений среди МСП" typical />
            </Fields>
        </Card>
    );
};

type CodeItemProps = {
    title: string
    typical?: boolean
    value?: string
    children?: ReactNode
};

const CodeItem = ({ title, value, children, typical = false }: CodeItemProps) => (
    <Field title={title}>
        <HSpread alignItems="center" >
            <HStack spacing="10px">
                <TextBox value={value} disabled style={{ width: "100%" }} />
                {children}
            </HStack>
            {typical && (
                <Label style={{ marginRight: "30%" }}>
                    Типовой способ &nbsp;
                    <CheckBox value={false} disabled/>
                </Label>
            )}
        </HSpread>
    </Field>
);
