import React, {useMemo, useState, MouseEvent, useRef} from "react";
import {Column, Table} from "@/components/table";
import {ProvisionSingleProviderReason} from "@/models/Provision/ProvisionSingleProviderReason";
import {Card} from "@/components/Card";
import {Expandee, HGrid, HStack, VGrid} from "@/components/layouts";
import {Field, Fields} from "@/components/form/Field";
import {formatNumber} from "@/NumberFormatting";
import styles from "./PurchasingSection.module.css";
import { j } from "@/reactUtils";

type PurchasingSectionProps = {
    reasons?: ProvisionSingleProviderReason[]
};

export const PurchasingSection = ({ reasons = [] }: PurchasingSectionProps) => {
    const [openedTabs, setOpenedTabs] = useState<number[]>([]);
    const toggleTab = (tabIndex: number) => setOpenedTabs(
        tabs => ~tabs.indexOf(tabIndex) ? tabs.filter(tab => tab !== tabIndex) : [...tabs, tabIndex]
    );

    const openedTabsSet = useMemo(() => new Set(openedTabs), [openedTabs]);

    const columns: Column<ProvisionSingleProviderReason>[] = [
        Table.AutoColumn(
            "Порядковый номер",
            ({ item }) => <div style={{ textAlign: "center" }}>{item.serialNumber}</div>,
            { cellStyle: { padding: "20px 10px" } }
        ),
        Table.Column(
            "Содержание",
            ({ item, rowIndex }) => (
                <HStack spacing="10px">
                    <div style={{ alignSelf: "center" }}>
                        <i className={j(styles.arrow, openedTabsSet.has(rowIndex) ? styles.down : styles.up)} />
                    </div>
                    <div>{item.content}</div>
                </HStack>
            ),
            { cellStyle: { cursor: "pointer" }, cellOnClick: (_, index) => toggleTab(index) }
        )
    ];

    const insets = openedTabs.map(tabIndex =>
        Table.InsetRow(tabIndex + 1, Table.Inset(<TabContent reason={reasons[tabIndex]} />, "1 / -1"))
    );

    return (
        <Card>
            <Table columns={columns} dataset={reasons} insets={insets}  />
        </Card>
    );
};

type TabContentProps = {
    reason: ProvisionSingleProviderReason
};

const TabContent = ({ reason }: TabContentProps) => (
    <HStack spacing="10px">
        <Fields orientation="horizontal" style={{ gridTemplateColumns: "8fr 8fr", width: "100%" }}>
            <Field title="Максимальная цена договора, руб.:" preset="boldSmall">
                {reason?.maxPrice ? formatNumber(reason.maxPrice) :  "Ограничение не задано"}
            </Field>
            <Field title="Максимальная сумма договоров, руб.:" preset="boldSmall">
                {reason?.maxTotal ? formatNumber(reason.maxTotal) : "Ограничение не задано"}
            </Field>
            <Field title="Агрегированная закупка:" preset="boldSmall">
                {reason?.isAggregated ? "Да" : "Нет"}
            </Field>
        </Fields>
        <Fields orientation="horizontal" style={{ gridTemplateColumns: "8fr 8fr", width: "100%" }}>
            <Field title="Максимальный объём закупок от СГОЗ, %:" preset="boldSmall">
                Ограничение не задано
            </Field>
            <Field title="Заключение договора в электронном виде через Портал Поставщиков:" preset="boldSmall">
                {reason?.isElectronicExecution ? "Да" : "Нет"}
            </Field>
        </Fields>
    </HStack>
);
