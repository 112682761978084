import React from "react";
import { Card } from "@/components/Card";
import { Column, Table } from "@/components/table";

export const ArchivalMethods = () => {
    const columns: Column<never>[] = [
        Table.Column(
            "Наименование способа определения поставщика",
            () => <></>
        ),
        Table.Column(
            "Код способа определения поставщика",
            () => <></>
        ),
        Table.Column(
            "Действие",
            () => <></>
        )
    ];

    return (
        <Card>
            <Table columns={columns} dataset={[]} />
        </Card>
    );
};
