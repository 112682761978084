























import { ITableColumn, IRowConfiguration } from "@/components/TableAbstractions";
import EventBus, { showModal } from '@/EventBus';
import { Component, Vue, Prop } from "vue-property-decorator";
import DataTable from "@/components/vue/DataTable.vue";
import AddEconomy from './AddEconomy.vue';
import { Contract, ContractEconomy } from '../../models/Contracts';
import { SimpleRow } from '../../components/vue/form-table/FormFields';
import { IFieldProps } from '../../components/vue/form-table/FieldBase';
import { formatNumber } from '../../NumberFormatting';
import { ContractScope } from './ContractScope';
import ContractEconomies from '../../api/ContractEconomies';
import { ConfirmationModal } from "@/views/Contracts/modals/ConfirmationModal";

@Component({ components: { DataTable } })
export default class Economy extends Vue {
    @Prop() public props!: IFieldProps<SimpleRow<ContractScope>>;

    public get headers(): ITableColumn<ContractEconomy>[] {
        return [
            {
                title: "№",
                getter: (p, i) => (i + 1).toString(),
                size: "auto"
            },
            {
                title: "Источник финансирования",
                getter: p => p.source.source.format(false),
            },
            {
                title: "Сумма, руб.",
                getter: p => formatNumber(p.amount),
            },
        ];
    }

    public createConfig(): IRowConfiguration<ContractEconomy> {
        const that = this;

        return {
            get useSlotForActionColumn() { return that.props.state === 'ENABLED' }
        };
    }

    public config = this.createConfig();

    public get dataset() { return this.props.source.economy }

    public async addEditEconomy(src: ContractEconomy | null) {
        const props = { scope: this.props.source, source: src };
        const result = await EventBus.callModal<typeof props, ContractEconomy>(AddEconomy, props);
        if (result.isOk) {
            const r = result.getResult();
            if (!src) {
                this.dataset.push(r);
            } else {
                const ix = this.dataset.findIndex(x => x.id === r.id);
                this.dataset.splice(ix, 1, r);
            }
        }
    }

    public async deleteEconomy(src: ContractEconomy) {
        const confirmation = await showModal(ConfirmationModal, {title: "Удаление", text: "Вы уверены, что хотите удалить экономию?"});
        if (!confirmation)
            return;

        await ContractEconomies.delete(src);
        
        const ix = this.dataset.indexOf(src);
        if (ix !== -1) this.dataset.splice(ix, 1);
    }
}
