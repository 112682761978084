import { Component, Vue } from "vue-property-decorator";
import HeadTitle from "@/components/HeadTitle.vue";
import SingleTableView from "@/components/SingleTableView.vue";
import { ITableColumn } from "@/components/TableAbstractions";
import router from "@/router";
import {showModal} from "@/EventBus";
import {
    LawTypeStrings,
    SmpTypeStrings,
} from "@/models/enums";
import {
    calculateLotVisibleStatus,
    Lot,
    LotVisibleStatusStrings,
    ProviderSelectionTypeStrings
} from "@/models/ComposedLots";
import {
    PaginationContext,
    createPaginationContext,
} from "@/api/Pagination";
import { containsFilter, decimalGteFilter, longIntFilter, selectFilter } from '@/components/TableHelpers';
import {PickLawTypeModal} from "@/modal-views/PickLawTypeModal";
import { copyLotById, getLots } from "@/api/ComposedLots";
import {applyN} from "@/models/parsing";
import {formatDate} from "@/dateUtils";
import {formatNumber} from "@/NumberFormatting";
import Auth from "@/api/Auth";
import { getTotalPaymentAmount } from "@/views/ComposedLot/shared/store/plannedPayments";

@Component({ components: { SingleTableView, HeadTitle } })
export default class LotsList extends Vue {
    public itemActions: unknown[] = [
        {
            id: "browse",
            title: "Просмотр",
        },
        {
            id: "copy",
            title: "Копирование"
        }
    ];

    public headers: ITableColumn<Lot>[] = [
        {
            title: "Реестровый номер",
            getter: x => x.regNumber,
            url: x => `/plan-objects/composed-lots/${x.id}`,
            filter: longIntFilter("regNumber")
        },
        {
            title: "Закон-основание",
            getter: x => (x.law === "F44" ? "44-ФЗ" : "223-ФЗ"),
            filter: selectFilter("law", LawTypeStrings, false)
        },
        {
            title: "ИКЗ",
            getter: x => x.purchaseCode,
        },
        {
            title: "Предмет закупки",
            getter: x => x.basicInfo.subject,
            filter: containsFilter("subject")
        },
        {
            title: "НМЦ",
            getter: x => x.basicInfo.providerSelectionType !== "SINGLE_PROVIDER_PURCHASE"
                ? formatNumber(
                    x.includedLots.length > 0 ? x.includedLots.reduce(
                        (p, v) => p.add(v.totalPrice), x.totalPrice) : x.totalPrice)
                : getTotalPaymentAmount(x.plannedPayments)
        },
        {
            title: "Способ определения поставщика",
            getter: x => ProviderSelectionTypeStrings[x.basicInfo.providerSelectionType],
            filter: selectFilter("providerSelectionType", ProviderSelectionTypeStrings, false)
        },
        {
            title: "Дата начала закупки",
            getter: x => applyN((d: Date) => formatDate(d, "months"), x.basicInfo.plannedPublishDate),
        },
        {
            title: "Размещение среди СМП/МСП",
            getter: x => SmpTypeStrings[x.basicInfo.smpType],
        },
        {
            title: "Статус",
            getter: x => LotVisibleStatusStrings[calculateLotVisibleStatus(x)],
            filter: selectFilter("status", LotVisibleStatusStrings, true)
        },
        {
            title: "Номер повторной закупки",
            getter: () => "0",
        },
    ];

    public async addObject() {
        const result = await showModal(PickLawTypeModal);
        if (result) {
            router.push("/plan-objects/composed-lots/create/" + result);
        }
    }

    public context: PaginationContext<Lot> | null = null;

    public async mounted() {
        const selectedYear = Auth.selectedYear;
        this.context = await createPaginationContext(
            (n, sz, f) => getLots(selectedYear, n, sz, f), 20);
    }

    public async onItemAction(item: {id: string}, action: string) {
        switch (action) {
            case "browse":
                router.push(`/plan-objects/composed-lots/${item.id}`);
                break;
            case "edit":
                router.push(`/plan-objects/composed-lots/${item.id}/edit`);
                break;
            case "copy":
                if (item?.id) {
                    const result = await copyLotById(item.id);
                    if (result?.id) router.push("/plan-objects/composed-lots/" + result.id);
                }
                break;
        }
    }
}
