import React, {useEffect, useState} from "react";
import styles from "../CreateSession.module.css";
import headerStyles from "../Header.module.css";
import {PrimaryButton} from "@/components/eshop";
import {useStore} from "effector-react";
import moment from "moment";
import {TestButton, WarningButton} from "@/components/eshop/Buttons";
import {SPGZ, statusToString} from "@/api/Sessions";
import {Store} from "effector";
import {firstMaybe} from "@/arrayUtils";
import {TooltipIcon} from "@/components/TooltipIcon";
import {Purchase} from "@/api/DirectPurchases";
import {Product} from "@/api/Products";
import {SubjectClassFilterStore} from "@/components/eshop/SubjectClassFilterStore";
import {
    changeKpgzCode,
    CreationMode,
    loadProducts,
    loadProductsPage,
    loadPurchases,
    loadPurchasesPage,
    loadServices,
    loadServicesPage,
    onChangeMode
} from "../filtersStore";
import {SpecificationsCard} from "../Specifications/SpecificationsCard";
import {ModalAdditionalInfo, ModalCancel, ModalRecall, ModalSend} from "./CreateSessionModals";
import {ProgressCard} from "./ProgressCard";
import {HistoryCard} from "./HistoryCard";
import {GeneralInfoCard} from "./GeneralInfoCard";
import {ContractConditionsCard} from "./ContractConditionsCard";
import {LotCard} from "./LotCard";
import {LotDocumentsCard} from "./LotDocumentsCard";
import {AccountInfoCard} from "./AccountInfoCard";
import router from "@/router";
import {ExecutionStagesCard} from "@/views/CreateSession/Session/ExecutionStagesCard";
import {JoinToSessionCard} from "@/views/CreateSession/Session/JoinToSessionCard";
import {useErrorHandler} from "@/reactUtils";
import {
    changeElectronicExecution,
    createOrUpdateSession,
    findSupplier,
    getLotDocuments,
    getType,
    loadBankAccounts,
    loadExecutionHistory,
    loadHistory,
    loadLots,
    loadSession,
    ServicesStore,
    SpecificationType,
    supplierNotFound,
    updateLotDocuments
} from "../store";

interface CreateSessionProps {
    sessionId?: string
    ids?: string
    mode?: CreationMode
    storeContainer: { store: Store<ServicesStore>; subjectClassSelector: SubjectClassFilterStore }
}

export type SortFilter = { field: string; sort: 'asc' | 'desc' } | null;

export const isSorted = (field: string, filter: SortFilter) => {
    if (!filter) {
        return null;
    } else if (filter.field === field) {
        if (filter.sort === 'asc') {
            return 'asc';
        }
        else {
            return 'desc';
        }
    }
    return null;
};

export const CreateSession: React.FC<CreateSessionProps> = x => {
    const store = useStore(x.storeContainer.store);

    const generalInfoRef = React.createRef<HTMLDivElement>();
    const accountInfoRef = React.createRef<HTMLDivElement>();
    const lotRef = React.createRef<HTMLDivElement>();
    const specificationRef = React.createRef<HTMLDivElement>();
    const executionStagesRef = React.createRef<HTMLDivElement>();
    const contractConditionsRef = React.createRef<HTMLDivElement>();
    const joinToSessionRef = React.createRef<HTMLDivElement>();
    const lotDocumentsRef = React.createRef<HTMLDivElement>();
    const executionHistoryRef = React.createRef<HTMLDivElement>();
    const historyRef = React.createRef<HTMLDivElement>();


    const scroll = (ref: React.RefObject<HTMLDivElement>) =>
        ref.current && ref.current.scrollIntoView({ behavior: 'smooth' });

    useEffect(() => {
        (async function () {
            loadLots({ law: store.law });
            await loadBankAccounts({});

            if (x.sessionId) {
                loadSession({ id: x.sessionId });
            } else {
                const loaded: (Product | SPGZ | Purchase)[] = x.ids && x.mode !== undefined
                    ? x.mode === 'SERVICES'
                        ? await loadServices({ ids: x.ids.split('&') })
                        : x.mode === 'PRODUCTS'
                            ? await loadProducts({ ids: x.ids.split('&') })
                            : await loadPurchases({ ids: x.ids.split('&') })
                    : [];

                const firstLoaded = firstMaybe(loaded);

                const kpgzCode = firstLoaded
                    ? 'spgz' in firstLoaded
                        ? firstLoaded.spgz.subjectClass.code.slice(0, 5)
                        : firstLoaded.subjectClass.code.slice(0, 5)
                    : null;

                if (x.mode) {
                    onChangeMode(x.mode);
                }
                await changeKpgzCode({ kpgzCode });

                if (x.mode === 'SERVICES') {
                    loadServicesPage({ page: 0 });
                }
                if (x.mode === 'PRODUCTS') {
                    changeElectronicExecution(true);
                    loadProductsPage({ page: 0 });
                }
                if (x.mode === 'PURCHASES') {
                    loadPurchasesPage({ page: 0 });
                }
            }
            return;
        })();
    }, []);

    useEffect(() => {
        if (store.currentSession?.id) {
            loadHistory({ id: store.currentSession.id });
            loadExecutionHistory({ id: store.currentSession.id });
            getLotDocuments({ id: store.currentSession.id });
            loadSession({ id: store.currentSession.id });
        }
        return;
    }, [store.currentSession?.id]);

    const [showDocumentHistory, setShowDocumentHistory] = useState(false);
    const [additionalInfo, setAdditionalInfo] = useState<object | null>(null);
    const [showCancel, setShowCancel] = useState(false);
    const [showSend, setShowSend] = useState(false);
    const [showRecall, setShowRecall] = useState(false);

    const [error, makeSafe] = useErrorHandler();


    useEffect(() => {
        setShowCancel(false);
    }, [store.currentSession?.status]);

    const useOnScreen = (ref: React.RefObject<HTMLDivElement>): boolean => {
        const [isIntersecting, setIntersecting] = useState(false);
        const observer = new IntersectionObserver(
            ([entry]) => setIntersecting(entry.isIntersecting)
        );

        useEffect(() => {
            observer.observe(ref.current!);
            return () => { observer.disconnect() };
        }, []);
        return isIntersecting;
    };

    const saveSession = async () => {
        if (canSaveSession) {
            const currentSession = await createOrUpdateSession({
                id: store.currentSession?.id,
                lotId: store.lotsPage.items.find(i => i.regNumber.toString() === store.lotRegNumber?.toString() || '')?.id || '',
                services: dataType === SpecificationType.Services ? store.services : [],
                products: dataType === SpecificationType.Products ? store.products : [],
                purchases: dataType === SpecificationType.DirectPurchase ? store.purchases : [],
                bankId: store.selectedAccount?.toString() || '',
                duration: store.duration || '',
                law: store.law || '',
                electronicExecution: !!store.electronicExecution,
                fio: store.fio,
                email: store.email,
                phone: store.phone,
                purchasePerUnit: store.purchasePerUnit,
                contractProvisionUsed: store.contractProvisionUsed,
                contractProvisionPrice: store.contractProvisionUsed ? parseInt(store.contractProvisionPrice) : 0,
                contractProvisionBankDetailId: store.contractProvisionUsed ? store.contractProvisionBankDetailId : null,
                contractProvisionRecipient: store.contractProvisionUsed ? store.contractProvisionRecipient : '',
                maxPrice: store.maxPrice,
                isGoodsDeliveryByMultipleConsignees: store.isGoodsDeliveryByMultipleConsignees,
                isGoodsDeliveryByRecipientRequest: store.isGoodsDeliveryByRecipientRequest,
                executionStages: store.executionStages,
                isJoin: store.isJoin,
                isMaster: false,
                includedSessionsIds: store.includedSessionsIds,
                includedSessions: [],
                joinCustomersIds: [],
                joinCustomers: [],
                subjectId: null,
                joinPublishDate: undefined,
                subjectJoin: null,
                mainOrganizerId: null,
                aggregatingSessionId: store.aggregatingSessionId,
                status: status ? status : null,
                paymentTerm: store.paymentTerm,
                reviewTerm: store.reviewTerm,
                sendTerm: store.sendTerm
            });
            if (currentSession) {
                await updateLotDocuments({ id: currentSession.session.id, lotDocuments: store.lotDocuments.filter(x => x.type !== '-') });
            }
        }
    };

    const createSession = async () => {
        await saveSession();
        setShowSend(true);
    };

    const onRecallSession = async () => {
        setShowRecall(true);
    };

    const dataType = getType(store);
    const data = dataType === SpecificationType.Services ? store.services : dataType === SpecificationType.Products ? store.products : store.purchases;

    const initialAmount = data.filter(x => x.stages.length > 0).reduce((prev, item) => prev + (parseInt(firstMaybe(item.stages)?.volume || '0') * item.price), 0);
    const { currentSession } = store;

    const status = currentSession?.status;
    const canChange = currentSession === null || status === 'DRAFT' || status === 'UPDATED';
    const canBeSent = status === 'DRAFT' || status === 'CANCEL';
    const sessionCancelled = status === 'SUPPLIER_NOT_FOUND' || status === 'CANCEL';
    const sessionPublished = status === 'PUBLISHED' && !currentSession?.isJoin;
    const sessionLastsMinutes = serverNow().diff(moment(currentSession?.published), 'minutes');
    const canCancel = !(currentSession?.durationHours === 3 || (currentSession?.durationHours === 6 && sessionLastsMinutes >= 60) ||
        (currentSession?.durationHours === 24 && sessionLastsMinutes >= 120));

    const sessionWaitPublished = status === 'PUBLICATION_WAIT_JOIN' && status != undefined;

    const canSaveSession = store.lotRegNumber !== null && store.selectedAccount !== null && store.electronicExecution !== null;

    const visibleStyleElement = [
        { name: 'generalInfoRefVisible', visible: useOnScreen(generalInfoRef) },
        { name: 'accountInfoRefVisible', visible: useOnScreen(accountInfoRef) },
        { name: 'lotRefVisible', visible: useOnScreen(lotRef) },
        { name: 'specificationRefVisible', visible: useOnScreen(specificationRef) },
        { name: 'executionStagesRef', visible: useOnScreen(executionStagesRef) },
        { name: 'contractConditionsRefVisible', visible: useOnScreen(contractConditionsRef) },
        { name: 'joinToSessionRef', visible: useOnScreen(joinToSessionRef) },
        { name: 'lotDocumentsRefVisible', visible: useOnScreen(lotDocumentsRef) },
        { name: 'executionHistoryRefVisible', visible: useOnScreen(executionHistoryRef) },
        { name: 'historyRefVisible', visible: useOnScreen(historyRef) },
    ].find(x => x.visible);

    const visibleStyle = visibleStyleElement && visibleStyleElement.name;

    return <div className={styles.offersPage}>
        <div className={`${styles.sessionsPageContent}`} >
            <div className={headerStyles.headerContainer}>
                <ModalCancel store={store} show={showCancel} setShowCancel={setShowCancel} />
                <ModalAdditionalInfo message={additionalInfo} setAdditionalInfo={setAdditionalInfo} />
                <ModalSend show={showSend} setShowSend={setShowSend} store={store} />
                <ModalRecall show={showRecall} setShowRecall={setShowRecall} store={store} />
                <div className={headerStyles.pageHeader}>
                    <span>{store.currentSession && store.currentSession.subject
                        ? `Котировочная сессия по ${getSpecificationTypeName(dataType)} «${store.currentSession.subject.id}»`
                        : `Новая котировочная сессия по ${getSpecificationTypeName(dataType)}`}</span>
                    <span className={`${headerStyles.status} ${status === 'DRAFT' ? headerStyles.draft : ''} ${sessionCancelled ? headerStyles.cancelled : ''}`}>
                        {statusToString(status || null)}
                    </span>
                </div>
                <div className={headerStyles.sessionsBreadcrumbs}>
                    <div className={visibleStyle === 'generalInfoRefVisible' ? headerStyles.menuOptionsVisible : headerStyles.menuOption} onClick={() => scroll(generalInfoRef)}>
                        Общая информация
                    </div>
                    <div className={visibleStyle === 'accountInfoRefVisible' ? headerStyles.menuOptionsVisible : headerStyles.menuOption} onClick={() => scroll(accountInfoRef)}>
                        Реквизиты заказчика
                    </div>
                    <div className={visibleStyle === 'lotRefVisible' ? headerStyles.menuOptionsVisible : headerStyles.menuOption} onClick={() => scroll(lotRef)}>
                        Лот
                    </div>
                    <div className={visibleStyle === 'specificationRefVisible' ? headerStyles.menuOptionsVisible : headerStyles.menuOption} onClick={() => scroll(specificationRef)}>
                        Спецификации
                    </div>
                    <div className={visibleStyle === 'executionStagesRefVisible' ? headerStyles.menuOptionsVisible : headerStyles.menuOption} onClick={() => scroll(executionStagesRef)}>
                        Этапы исполнения контракта
                    </div>
                    <div className={visibleStyle === 'contractConditionsRefVisible' ? headerStyles.menuOptionsVisible : headerStyles.menuOption} onClick={() => scroll(contractConditionsRef)}>
                        Условия {store.law === "F44" ? 'контракта' : 'договора'}
                    </div>
                    <div className={visibleStyle === 'joinToSessionRefVisible' ? headerStyles.menuOptionsVisible : headerStyles.menuOption} onClick={() => scroll(joinToSessionRef)}>
                        Совместная КС
                    </div>
                    <div className={visibleStyle === 'lotDocumentsRefVisible' ? headerStyles.menuOptionsVisible : headerStyles.menuOption} onClick={() => scroll(lotDocumentsRef)}>
                        Документы котировочной сессии
                    </div>
                    <div className={visibleStyle === 'executionHistoryRefVisible' ? headerStyles.menuOptionsVisible : headerStyles.menuOption} onClick={() => scroll(executionHistoryRef)}>
                        Ход котировочной сессии
                    </div>
                    <div className={visibleStyle === 'historyRefVisible' ? headerStyles.menuOptionsVisible : headerStyles.menuOption} onClick={() => scroll(historyRef)}>
                        История изменений
                    </div>
                </div>
            </div>
            <div className={`${styles.cardsContainer}`} >
                <div ref={generalInfoRef}>
                    <GeneralInfoCard initialAmount={initialAmount} canChange={canChange} data={data} store={store} />
                </div>
                <div ref={accountInfoRef}>
                    <AccountInfoCard store={store} canChange={canChange} />
                </div>
                <div ref={lotRef}>
                    <LotCard canChange={canChange} store={store} />
                </div>
                <div ref={specificationRef}>
                    <SpecificationsCard store={store} services={store.services} products={store.products} purchases={store.purchases} type={dataType} subjectClassSelector={x.storeContainer.subjectClassSelector} />
                </div>
                <div ref={executionStagesRef}>
                    <ExecutionStagesCard store={store} dataType={dataType} canChange={canChange} />
                </div>
                <div ref={contractConditionsRef}>
                    <ContractConditionsCard initialAmount={initialAmount} canChange={canChange} data={data} dataType={dataType} store={store} />
                </div>
                <div ref={joinToSessionRef}>
                    <JoinToSessionCard store={store} status={status} canChange={canChange} />
                </div>
                <div ref={lotDocumentsRef}>
                    <LotDocumentsCard store={store} status={status} showDocumentHistory={showDocumentHistory} />
                </div>
                <div ref={executionHistoryRef}>
                    <ProgressCard store={store} />
                </div>
                <div ref={historyRef}>
                    <HistoryCard store={store} setAdditionalInfo={setAdditionalInfo} />
                </div>
            </div>
            <div className={`${styles.sessionsPageFooter}`} >
                <>
                    <div>
                        <PrimaryButton onClick={() => router.replace("/shop/sessions")}>Закрыть</PrimaryButton>
                        <PrimaryButton>Копировать КС</PrimaryButton>
                        {sessionWaitPublished && <PrimaryButton onClick={onRecallSession}>Отзыв КС</PrimaryButton>}
                    </div>
                </>
                <div>
                    {canBeSent && <PrimaryButton filled onClick={createSession}>Создать КС</PrimaryButton>}
                    {(status === undefined || status === 'DRAFT') &&
                        <PrimaryButton disabled={!canSaveSession} onClick={saveSession}>Сохранить</PrimaryButton>}
                </div>
                {' '}
                {sessionPublished && <>
                    {canCancel && <WarningButton onClick={() => setShowCancel(true)}>Отменить публикацию КС</WarningButton>}
                    <TestButton filled onClick={() => store.currentSession && findSupplier({ id: store.currentSession.id })}>Поставщик найден</TestButton>
                    <TestButton filled onClick={() => store.currentSession && supplierNotFound({ id: store.currentSession.id })}>Поставщик не найден</TestButton>
                </>}
            </div>

        </div>
    </div>;
};

export const formatBytes = (bytes: number, decimals = 2) => {
    if (bytes === 0) return '0 B';

    const kb = 1024;
    const dm = decimals < 0 ? 0 : decimals;
    const sizes = ['B', 'KB', 'MB'];

    const i = Math.floor(Math.log(bytes) / Math.log(kb));

    return parseFloat((bytes / Math.pow(kb, i)).toFixed(dm)) + ' ' + sizes[i];
};

export const getSpecificationTypeName = (type: SpecificationType) => {
    switch (type) {
        case SpecificationType.Services:
            return 'СПГЗ';
        case SpecificationType.Products:
            return 'CTE';
        case SpecificationType.DirectPurchase:
            return 'оферте';
        default:
            break;
    }
};

export const getValue = (str: string) => {
    switch (str) {
        default:
        case "false":
            return false;
        case "true":
            return true;
    }
};

const serverNow = () => moment().add(-3, 'hours');

export const HeaderWithTooltip = (props: { children: React.ReactNode }) =>
    <th className={styles.cell}>
        <div className={styles.header__sortButtons}>
            {props.children}
            <TooltipIcon />
        </div>
    </th>;
