




import {Component, Prop, Vue} from "vue-property-decorator";
import {ViewProcedure} from "./ViewProcedure";
import {createProcedureStore} from "@/views/Procedures2020/view/store";
import {LawType} from "@/models/enums";
import {uuid} from "@/models/parsing";

@Component({components: {komponent: ViewProcedure as unknown as typeof Vue}})
export default class ViewProcedureVueStub extends Vue {
    @Prop() public id?: string;
    @Prop() public law!: LawType;
    @Prop() public lotId?: uuid;

    public storeContainer = Object.freeze({ store: createProcedureStore(this.law, this.id, this.lotId) });

}
