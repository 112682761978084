import React, { useState } from "react";
import {Button, Note} from "@/components/primitive";
import {Expandee, HStack, VStack} from "@/components/layouts";
import {MonopolyTable} from "@/views/Monopoly/list/sections/MonopolyTable";
import { StoredRemoteDataset } from "@/components/table";
import { Monopoly } from "@/models/Monopoly";
import { MonopolyFilters } from "@/views/Monopoly/list/store";
import { conclusionRequestsColumns } from "@/views/Monopoly/list/sections/columns";

type MonopolyObjectsProps = {
    srd: StoredRemoteDataset<Monopoly, MonopolyFilters>
};

export const ConclusionRequests = ({ srd }: MonopolyObjectsProps) => {
    const [isFiltersVisible, setIsFiltersVisible] = useState<boolean>(false);
    const toggleFiltersVisibility = () => setIsFiltersVisible(is => !is);

    return (
        <VStack spacing="15px">
            <Note preset="title" style={{ marginTop: 0 }}>
                <HStack alignItems="center" spacing="10px">
                    <div>Запросы на заключение контракта</div>
                    <Expandee />
                    <Button icon="aAdd" />
                    <Button icon="aFilter" onClick={toggleFiltersVisibility} />
                    <Button icon="faRedoAlt" />
                </HStack>
            </Note>
            <Note preset="panel">
                <MonopolyTable srd={srd}
                               columns={conclusionRequestsColumns}
                               isFiltersVisible={isFiltersVisible} />
            </Note>
        </VStack>
    );
};
