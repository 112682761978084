







































import { Component, Prop, Vue, Watch } from "vue-property-decorator";
import EventBus from "@/EventBus";
import Modal from "@/components/Modal.vue";
import { ModalResult } from "@/view-models/ModalRequest";

@Component({ components: { Modal } })
export default class MockAuthModal extends Vue {
    public username: string = "";
    public password: string = "";

    public hasError = false;

    @Watch("username") @Watch("password") public resetError() {
        this.hasError = false;
    }

    private handleModalEvent($event: Event) {
        $event.stopPropagation();
        EventBus.$emit("close-popup");
    }

    public ok() {
        this.$emit("close-modal", ModalResult.ok(null));
    }

    public async login() {
        try {
            if (this.username && this.password) {
                this.ok();
            } else {
                throw new Error();
            }
        } catch {
            this.hasError = true;
        }
    }
}
