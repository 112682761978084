import React, { useState } from "react";
import { ModalFC } from "@/EventBus";
import { LawType, LawTypeStrings } from "@/models/enums";
import { CardModal } from "@/components/modals/CardModal";
import { Expandee, HStack } from "@/components/layouts";
import { Button, Radio } from "@/components/primitive";

export const SelectLawModal: ModalFC<undefined, LawType> = ({ done }) => {
    const [selectedLaw, setSelectedLaw] = useState<LawType>(LawType.F44);

    const bottom = <HStack>
        <Expandee />
        <Button title="Создать" color="green" onClick={() => done(selectedLaw)} />
    </HStack>;

    return (
        <CardModal title="Выбор закона-основания"
                   close={() => done(undefined)}
                   cardContentStyle={{ padding: "20px 30px" }}
                   bottom={bottom}
                   width="60%">
            <HStack>
                <div style={{ fontSize: "16px", width: "250px" }}>Закон-основание:</div>
                <Radio requiredValue={LawType.F44}
                       value={selectedLaw}
                       style={{ marginLeft: "50px", display: "flex", alignItems: "center"  }}
                       onChange={() => setSelectedLaw(LawType.F44)}>
                    <div style={{ fontSize: "16px", fontWeight: "normal" }}>
                        {LawTypeStrings[LawType.F44]}
                    </div>
                </Radio>
                <Radio requiredValue={LawType.F223}
                       value={selectedLaw}
                       style={{ marginLeft: "50px", display: "flex", alignItems: "center" }}
                       onChange={() => setSelectedLaw(LawType.F223)}>
                    <div style={{ fontSize: "16px", fontWeight: "normal" }}>
                        {LawTypeStrings[LawType.F223]}
                    </div>
                </Radio>
            </HStack>
        </CardModal>
    );
};
