import React, {useState} from "react";
import {Card} from "@/components/Card";
import {SectionProps} from "@/views/Procedures2020/view/store";
import {Expandee, HStack, VStack} from "@/components/layouts";
import {Field, Fields} from "@/components/form/Field";
import { Button, CheckBox, DatePicker, Label, TextBox } from "@/components/primitive";
import {Column, SelectionTable, Table} from "@/components/table";
import {useMappedStore} from "@/storeUtils";
import {ProcedureEvents} from "@/views/Procedures2020/view/store/info";
import {LotDocument} from "@/models/ComposedLots/documents";
import {ProcedureDocument} from "@/models/Procedures2020/ProcedureDocument";
import {ReadOnlyInfoBlock, EditableInfoBlock} from "./InfoBlock";
import {ReadOnlyContactPersonBlock, EditableContactPersonBlock} from "@/views/Procedures2020/view/sections/infoSection/ContactPersonBlock";
import {UploadProcedureDocumentModal} from "@/views/Procedures2020/view/sections/infoSection/UploadProcedureDocumentModal";
import {
    LotDocumentTypeStrings,
    Procedure223DocumentTypeStrings,
    ProcedureDocumentType
} from "@/models/enums/Documents";
import {showModal} from "@/EventBus";
import {Lot} from "@/models/ComposedLots";
import {ProcedureDocsEvents} from "@/views/Procedures2020/view/store/docs";
import {UnderlinedVueLink} from "@/components/VueLink";

export const InfoSection: React.FC<SectionProps> = x => {
    type ProcedureLotDocument = { lot: Lot; document: LotDocument };

    const [selectedProcedureDocument, setSelectedProcedureDocument] = useState<ProcedureDocument | undefined>();

    const [law, lots, documents, decisionNumber, decisionDate, procedureId] = useMappedStore(x.formStore, x => [
        x.head.law,
        x.lots.lots,
        x.docs.procedureDocuments,
        x.info.decisionNumber,
        x.info.decisionDate,
        x.head.id
    ]);

    const lotDocuments = lots
        .flatMap(x => [x, ...x.includedLots])
        .flatMap(x => x.documents.map(d => ({ lot: x.lot, document: d })));

    const addProcedureDocument = async () => {
        const procedureDocument = await showModal(UploadProcedureDocumentModal, {law: law, pst: lots[0]?.lot.basicInfo.providerSelectionType, id: lots[0]?.lot.id, procedureId: procedureId});
        if (procedureDocument)
            ProcedureDocsEvents.addOrReplaceProcedureDocument({newV: procedureDocument});
    };

    const deleteProcedureDocument = async () => {
        if (selectedProcedureDocument)
            ProcedureDocsEvents.removeProcedureDocument(selectedProcedureDocument);
    };

    const lotDocumentsTableColumns: Column<ProcedureLotDocument>[] = [
        Table.Column("№ лота", x => <UnderlinedVueLink href={"/plan-objects/composed-lots/" + x.item.lot.id}>{x.item.lot.regNumber}</UnderlinedVueLink>),
        Table.Column("Наименование лота", x => <UnderlinedVueLink href={"/plan-objects/composed-lots/" + x.item.lot.id}>{x.item.lot.basicInfo.subject}</UnderlinedVueLink>),
        Table.Column("Тип документа", x => <>{LotDocumentTypeStrings[x.item.document.type]}</>),
        Table.Column("Примечание", x => <>{x.item.document.comment}</>),
        Table.Column("Документ", x => <>{x.item.document.file?.name}</>),
        Table.Column("Размер", x => <>{x.item.document.file?.length}</>),
    ];

    const setSendToEis = (sendToEis: boolean, doc: ProcedureDocument) => ProcedureDocsEvents.addOrReplaceProcedureDocument({
        oldV: doc,
        newV: {...doc, sendToEis: sendToEis}
    });

    const purchaseDocumentsTableColumns: Column<ProcedureDocument>[] = [
        Table.Column("Тип документа", x => <span>{Procedure223DocumentTypeStrings[x.item.type as ProcedureDocumentType]}</span>, {width: "3fr"}),
        Table.Column("Примечание", x => <span>{x.item.file.comment}</span>, {width: "1fr"}),
        Table.Column("Отправить в ЕИС/ЭТП", i => x.formAction === "editing"
            ? <CheckBox value={i.item.sendToEis} onChange={a => setSendToEis(a, i.item)} />
            : <span>{i.item.sendToEis ? "Да" : "Нет"}</span>
            , {width: "1fr"}),
        Table.Column("Документ", x => <span>{x.item.file.name}</span>, {width: "3fr"}),
        Table.Column("Размер", x => <div>{x.item.file.length}</div>, {width: "1fr"}),
    ];

    const viewing = x.formAction === "viewing";

    return <Card>
        <VStack spacing={"25px"}>
            {
                viewing
                    ? <ReadOnlyInfoBlock {...x}/>
                    : <EditableInfoBlock {...x}/>
            }

            <VStack spacing="15px">
                <HStack alignItems="center">
                    <Label preset={"headerLarge"} text={"Документы лотов"}/>
                    <Expandee/>
                    <Button icon={"aFilter"}/>
                </HStack>
                <Table dataset={lotDocuments} columns={lotDocumentsTableColumns}/>
            </VStack>

            <VStack spacing={"15px"}>
                <Label preset={"headerLarge"} text={"Документы закупки"}/>

                {
                    !viewing && <>
                        <Fields orientation={"horizontal"}>
                            <Field title={"Номер решения о проведении закупки"}>
                                <TextBox placeholder={"Номер решения о проведении закупки"}
                                         value={decisionNumber}
                                         onChange={a => ProcedureEvents.setDecisionNumber(a)}
                                />
                            </Field>
                            <Field title={"Дата решения о проведении закупки"}>
                                <DatePicker value={decisionDate}
                                            onChange={a => ProcedureEvents.setDecisionDate(a)}

                                />
                            </Field>
                        </Fields>
                    </>
                }
                {
                    documents?.length
                        ? <SelectionTable<ProcedureDocument>
                              dataset={documents as ProcedureDocument[]}
                              mode="single"
                              columns={purchaseDocumentsTableColumns}
                              selectorPosition="hidden"
                              selected={selectedProcedureDocument}
                              onChange={setSelectedProcedureDocument} />
                        : <div>Документов нет</div>
                }
                {
                    !viewing && <HStack spacing={"10px"}>
                        <Button color="green" icon="aAdd" title="добавить" onClick={addProcedureDocument}/>
                        <Button color="red" icon="aDelete" title="удалить" onClick={deleteProcedureDocument}
                                disabled={!selectedProcedureDocument}/>
                    </HStack>
                }
            </VStack>

            {
                viewing
                    ? <ReadOnlyContactPersonBlock {...x}/>
                    : <EditableContactPersonBlock {...x}/>
            }
        </VStack>
    </Card>;
};