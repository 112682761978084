import {Expandee, HStack} from "@/components/layouts";
import {RoundedButton} from "@/components/primitive/RoundedButton";
import {ModalFC} from "@/EventBus";
import {CardModal} from "@/components/modals/CardModal";
import React from "react";

export type SaveLotOption = "save-all" | "save-current-tab";

export const SaveLotModal: ModalFC<undefined, SaveLotOption> = x => {
    const bottom = (
        <HStack innerStyle={{padding: "10px 0"}} spacing={"10px"}>
            <Expandee/>
            <RoundedButton color={"blue"} title={"Да"} onClick={() => x.done("save-all")}/>
            <RoundedButton color={"white"} title={"Нет"} onClick={() => x.done("save-current-tab")}/>
        </HStack>
    );

    return (
        <CardModal
            title={"Сохранить изменения"}
            close={() => x.done(undefined)}
            width={"40%"}
            bottom={bottom}
        >
            <span>
                Вы хотите сохранить информацию, внесенную на других вкладках лота?
                <br/>
                <br/>
                Для сохранения измененной информации на других вкладках нажмите &quot;Да&quot;.
                <br/>
                Для сохранения информации только на текущей вкладке, нажмите &quot;Нет&quot;.
            </span>
        </CardModal>
    );
};