




import {Component, Vue, Prop} from "vue-property-decorator";
import {SuppliersView} from "@/views/ReferenceInfo/Suppliers/sections/SuppliersView";

@Component({ components: { komponent: SuppliersView as unknown as typeof Vue } })
export default class SuppliersViewStub extends Vue {
    @Prop() public id!: string;
}
