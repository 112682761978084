




import {Component, Prop, Vue} from "vue-property-decorator";
import {ViewTrade} from "./ViewTrade";
import {createProcedureStore} from "@/views/Procedures2020/view/store";
import {LawType} from "@/models/enums";
import {FormAction} from "@/views/ComposedLot/shared/store";

@Component({components: {komponent: ViewTrade as unknown as typeof Vue}})
export default class ViewTradeVueStub extends Vue {
    @Prop() public id?: string;
    @Prop() public law!: LawType;
    @Prop() public action!: FormAction;

    public storeContainer = Object.freeze({ store: createProcedureStore(this.law, this.id) });

}
