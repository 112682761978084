import {ModalFC} from "@/EventBus";
import React, {useState} from "react";
import {CardModal} from "@/components/modals/CardModal";
import {Expandee, HStack, VGrid, VStack} from "@/components/layouts";
import {Button, Label, Radio, RadioGroup} from "@/components/primitive";

export interface CloneOptions {
    cloneSubjectDeclaration: boolean
    cloneVolume: boolean
    cloneStartPrice: boolean
    cloneSupplyStages: boolean
}

const Toggle = (x: { title: string; value: boolean; onChange: (v: boolean) => void }) => {
    return <>
        <Label preset="boldSmall" text={x.title}/>
        <RadioGroup>
            <Radio requiredValue={true} value={x.value} onChange={x.onChange}>
                Да
            </Radio>
            <Radio requiredValue={false} value={x.value} onChange={x.onChange}>
                Нет
            </Radio>
        </RadioGroup>
    </>;
};

export const CloneSpecificationModal: ModalFC<undefined, CloneOptions> = x => {
    const [state, setState] = useState<CloneOptions>({
        cloneStartPrice: true,
        cloneSubjectDeclaration: true,
        cloneSupplyStages: true,
        cloneVolume: true
    });

    const toggleSubjectDeclaration = (x: boolean) =>
        setState({
            ...state,
            cloneSubjectDeclaration: x,
            cloneStartPrice: state.cloneStartPrice && x
        });

    const toggleVolume = (x: boolean) =>
        setState({
            ...state,
            cloneVolume: x,
            cloneStartPrice: state.cloneStartPrice && x
        });

    const toggleStartPrice = (x: boolean) =>
        setState({
            ...state,
            cloneStartPrice: state.cloneSubjectDeclaration && state.cloneVolume && x
        });

    const bottom = <HStack spacing="15px">
        <Expandee/>
        <Button title="Сохранить" onClick={() => x.done(state)}/>
        <Button title="Отмена" color="red" onClick={() => x.done(undefined)}/>
    </HStack>;

    return <CardModal width="40%" close={() => x.done(undefined)} title="Клонирование спецификации" bottom={bottom}>
        <VStack spacing="15px">
            <Label preset="boldSmall">
                Копировать следующие объекты:
            </Label>
            <VGrid columns="auto auto auto" style={{ justifyContent: "flex-start" }} spacing="10px 15px" alignItems="center">
                <Toggle value={state.cloneSubjectDeclaration} title="СПГЗ" onChange={toggleSubjectDeclaration}/>
                <Toggle value={state.cloneVolume} title="Объём закупки" onChange={toggleVolume}/>
                <Toggle value={state.cloneStartPrice} title="Расчёт НМЦ" onChange={toggleStartPrice}/>
                <Toggle value={state.cloneSupplyStages} title="График поставки" onChange={x => setState({...state, cloneSupplyStages: x})}/>
            </VGrid>
        </VStack>
    </CardModal>;
};
