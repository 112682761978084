





























































import {IRowConfiguration, ITableColumn} from "@/components/TableAbstractions";
import EventBus, {showModal} from "@/EventBus";
import {Component, Prop, Vue} from "vue-property-decorator";
import DataTable from "@/components/vue/DataTable.vue";
import AddSupply from "./AddSupply.vue";
import {IFieldProps} from '../../components/vue/form-table/FieldBase';
import {SimpleRow} from '../../components/vue/form-table/FormFields';
import {ContractExecutionProvision} from '../../models/Contracts';
import {ChargeSourceStrings} from '../../models/enums/Contracts';
import {formatNumber} from '../../NumberFormatting';
import {ContractScope} from './ContractScope';
import ContractExecutionProvisions from '../../api/ContractExecutionProvisions';
import {ObjectStatusStrings} from '../../models/enums';
import {ConfirmationModal} from "@/views/Contracts/modals/ConfirmationModal";
import {formatDate} from "@/dateUtils";
import {isConcludedEshopContract} from "@/form-declarations/contractStageActions";
import {BankAccount} from "@/models/BankAccount";

@Component({ components: { DataTable } })
export default class Supply extends Vue {
    @Prop() public props!: IFieldProps<SimpleRow<ContractScope>>;

    public isActionsLocked = isConcludedEshopContract(this.props.source.contract);

    public isParticipantEditable() {return this.userParticipantOptions().length > 0}

    public lastId: number | null = null;

    public currentAccountNumber(): BankAccount | null {
        const accounts = this.props.source.contract.customer.accounts.filter(e => e.selected);
        return accounts.length > 0
            ? accounts.filter(e => e.provision).length > 0
                ? accounts.filter(e => e.provision)[0].account
                : accounts.filter(e => e.selected)[0].account
            : null;
    }

    public currentVisible(): boolean {
        return this.userParticipantOptions().length > 0
            ? this.props.source.contract.customer.accounts.filter(e => e.provision).length > 0
            : false;
    }

    public editVisible(value: boolean) {
        const accounts = this.props.source.contract.customer.accounts;
        this.lastId = accounts.filter(e => e.provision).length > 0 ? accounts.filter(e => e.provision)[0].account.id : this.lastId;
        value
            ? this.lastId
                ? accounts.filter(e => e.account.id === this.lastId)[0].provision = true
                : accounts.filter(e => e.selected)[0].provision = true
            : accounts.forEach( e => e.provision = false);
    }

    public userParticipantOptions() {
        return [
            ...this.props.source.contract.customer.accounts
                .filter(e => e.selected)
                .map(e => ({key: e.account, desc: e.account.accountNumber}))
        ];
    }

    public editProvision(value: BankAccount) {
        if (!value) return;
        const accounts = this.props.source.contract.customer.accounts;
        accounts.forEach(e => e.provision = false);
        const index = accounts.findIndex(e => e.account.id === value.id);
        accounts[index].provision = true;
    }

    get headers(): ITableColumn<ContractExecutionProvision>[] {
        return [
            {
                title: "№ в реестре",
                getter: (p, i) => (i + 1).toString(),
                size: "auto"
            },
            {
                title: "Вид обеспечения",
                getter: p => ChargeSourceStrings[p.source],
            },
            {
                title: "​Сумма обеспечения, руб.",
                getter: p => formatNumber(p.amountRub),
            },
            {
                title: "Срок начала действия",
                getter: p => p.duration?.from ? formatDate(p.duration.from) : "",
            },
            {
                title: "​Срок окончания действия",
                getter: p => p.duration?.to ? formatDate(p.duration.to) : "",
            },
            {
                title: "​Статус",
                getter: p => p.status && ObjectStatusStrings[p.status],
            },
        ];
    }

    public createConfig(): IRowConfiguration<ContractExecutionProvision> {
        const that = this;

        return  {
            get useSlotForActionColumn() { return that.props.state === 'ENABLED' && !that.isActionsLocked }
        };
    }

    public config = this.createConfig();

    public get dataset() { return this.props.source.executionProvision }

    public async addEditSupply(src: ContractExecutionProvision | null) {
        const result = await EventBus.callModal<{ src: ContractExecutionProvision | null; contractId: string; law?: string }, ContractExecutionProvision>(
            AddSupply, { src, contractId: this.props.source.contract.id, law: this.props.source.contract.law });
        if (result.isOk) {
            const r = result.getResult();
            if (!src) {
                this.dataset.push(r);
            } else {
                const ix = this.dataset.findIndex(x => x.id === r.id);
                this.dataset.splice(ix, 1, r);
            }
        }
    }

    public async deleteSupply(src: ContractExecutionProvision) {
        const confirmation = await showModal(ConfirmationModal, {title: "Удаление", text: "Вы уверены, что хотите удалить обеспечение?"});
        if (!confirmation)
            return;

        await ContractExecutionProvisions.delete(src);

        const ix = this.dataset.indexOf(src);
        if (ix !== -1) this.dataset.splice(ix, 1);
    }
}
