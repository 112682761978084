import {UnionStrings} from "@/models/enums";
import {Lot} from "@/models/ComposedLots/Lot";

export type LotStatus = "CREATING" | "APPROVED" | "CANCELLED" | "READY_TO_BE_INCLUDED_IN_JOINT_PURCHASE";

export type LotVisibleStatus =
    | "CREATING"
    | "APPROVED"
    | "CANCELLED"
    | "INCLUDED_IN_PLAN"
    | "PUBLISHED_IN_PLAN"
    | "READY_TO_BE_INCLUDED_IN_JOINT_PURCHASE"
    | "INCLUDED_IN_JOINT_PURCHASE";

export const LotVisibleStatusStrings: UnionStrings<LotVisibleStatus> = {
    CREATING: "Формирование",
    APPROVED: "Утвержден",
    CANCELLED: "Отменён",
    INCLUDED_IN_PLAN: "Включён в план-график",
    PUBLISHED_IN_PLAN: "Опубликовано в плане-графике",
    READY_TO_BE_INCLUDED_IN_JOINT_PURCHASE: "Готов к включению в совместный лот",
    INCLUDED_IN_JOINT_PURCHASE: "Включен в совместный лот"
};

export const calculateLotVisibleStatus = (lot: Lot): LotVisibleStatus => {
    if (!lot.planId && lot.status !== "READY_TO_BE_INCLUDED_IN_JOINT_PURCHASE") {
        return lot.status;
    }

    if (lot.status === "CANCELLED")
        return lot.status;

    switch (lot.status) {
        case "APPROVED":
            if(lot.basicInfo.lotJoinType === "MASTER")
                return "PUBLISHED_IN_PLAN";

            if (lot.prevPlanStatus == "PUBLISHED")
                return "PUBLISHED_IN_PLAN";

            switch (lot.planStatus) {
                case "DELETED":
                    return lot.status;
                case "PUBLISHED":
                    return "PUBLISHED_IN_PLAN";
                default:
                    return "INCLUDED_IN_PLAN";
            }
        case "READY_TO_BE_INCLUDED_IN_JOINT_PURCHASE":
            return lot.aggregatingLotId != null ? "INCLUDED_IN_JOINT_PURCHASE" : "READY_TO_BE_INCLUDED_IN_JOINT_PURCHASE";
        default:
            return lot.status;
    }
};