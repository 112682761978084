import React, { useEffect, useState } from "react";
import { approveRegistrationRequest, denyRegistrationRequest, getRegistrationRequests } from "@/api/Admin";
import { Column, Table } from "@/components/table";
import { uuid } from "@/models/parsing";
import { Expandee, HStack, VStack } from "@/components/layouts";
import { Button, DatePicker, TextBox } from "@/components/primitive";
import { DateTimeRange, Participant } from "@/models";
import { Field, Fields } from "@/components/form/Field";
import { formatDate } from "@/dateUtils";
import { isAfter, isBefore } from "date-fns";
import {Card} from "@/components/Card";

export type RegistrationRequest = {
    id: uuid
    name: string
    lastName: string
    middleName?: string

    insuranceNumber: string
    certificateNumber: string

    department: string
    email: string
    isHead: boolean
    organization: Participant
    position: string

    created: string
    group: string | null
};

export const RegistrationRequestsSection: React.FC<{ reload: symbol; onReload: () => void }> = x => {
    const [filtersVisible, setFiltersVisible] = useState<boolean>(true);
    const [requests, setRequests] = useState<RegistrationRequest[]>([]);
    const [filter, setFilter] = useState<string>("");
    const [dateFilter, setDateFilter] = useState<Partial<DateTimeRange>>({});

    const refresh = () => {
        x.onReload();
    };

    useEffect(() => {
        getRegistrationRequests().then(setRequests);
    }, [x.reload]);

    const columns: Column<RegistrationRequest>[] = [
        Table.Column("ФИО", x => <>{`${x.item.lastName} ${x.item.name} ${x.item.middleName ?? ""}`}</>),
        Table.Column("E-mail", x => <>{ x.item.email }</>),
        Table.Column("Сертификат", x => <>{ x.item.certificateNumber }</>),
        Table.Column("Отправлено", x => <>{ formatDate(new Date(x.item.created)) }</>),
        Table.Column("Группа", x => <>{ x.item.group ?? "-" }</>),
        Table.AutoColumn("Действия", x => <HStack spacing="5px">
            <Button icon="faCheck"
                    onClick={() => approveRegistrationRequest(x.item.id).then(refresh)}
                    color="green" />
            <Button icon="gCross"
                    onClick={() => denyRegistrationRequest(x.item.id).then(refresh)}
                    color="red" />
        </HStack>),
    ];

    const filteredByString = filter
        ? requests.filter(r => `${r.name} ${r.lastName} ${r.middleName ?? ""} ${r.certificateNumber} ${r.email} ${r.group}`.toLowerCase().includes(filter.toLowerCase()))
        : requests;
    const filteredByFrom = dateFilter.from !== undefined
        ? filteredByString.filter(r => isAfter(new Date(r.created), dateFilter.from ?? 0))
        : filteredByString;
    const filteredByTo = dateFilter.to !== undefined
        ? filteredByFrom.filter(r => isBefore(new Date(r.created), dateFilter.to ?? 0))
        : filteredByFrom;

    return <Card>
        <VStack spacing="15px">
            <HStack spacing="5px">
                <Expandee />
                <Button icon="aFilter" color="blue" onClick={() => setFiltersVisible(!filtersVisible)} />
                <Button icon="faTrash" color="red" title="Сбросить фильтры" onClick={() => { setDateFilter({}); setFilter("") }} />
            </HStack>
            {
                filtersVisible && <HStack spacing="15px" alignItems="flex-start">
                    <Fields orientation="vertical" style={{ width: "500px" }}>
                        <Field title="Поиск" preset="thinMedium">
                            <TextBox value={filter} onChange={setFilter} style={{ width: "500px" }} placeholder="Введите текст для поиска" />
                        </Field>
                    </Fields>
                    <Expandee />
                    <Fields orientation="vertical">
                        <Field title="От" preset="thinMedium">
                            <HStack spacing="15px">
                                <DatePicker value={dateFilter.from ?? undefined}
                                            onChange={d => setDateFilter(f => ({ ...f, from: d }))} />
                            </HStack>
                        </Field>
                    </Fields>
                    <Fields orientation="vertical">
                        <Field title="До" preset="thinMedium">
                            <HStack spacing="15px">
                                <DatePicker value={dateFilter.to ?? undefined}
                                            onChange={d => setDateFilter(f => ({ ...f, to: d }))} />
                            </HStack>
                        </Field>
                    </Fields>
                </HStack>
            }

            <Table dataset={filteredByTo} columns={columns} />
        </VStack>
    </Card>;
};
