import React, {CSSProperties, useEffect} from "react";
import * as styles from "./Note.module.css";
import {HandleableError, j} from "@/reactUtils";
import {useScroll} from "@/components/ScrollBox";
import {useStore} from "effector-react";

const presets = {
    white: styles.white,
    error: styles.error,
    title: styles.title,
    alert: styles.alert,
    panel: styles.panel,
    panelPadded: styles.panelPadded
};

export type NotePresets = keyof typeof presets;

export const Note = (x: { preset: NotePresets; children: React.ReactNode; style?: CSSProperties }) => {
    return <div className={j(styles.note, presets[x.preset])} style={x.style}>
        {x.children}
    </div>;
};

export const Error = (x: { object: HandleableError; style?: CSSProperties }) => {
    const scroll = useScroll();
    const error = useStore(x.object.store);

    useEffect(() => {
        if (error && !error.handled) {
            x.object.handle();
            scroll.scrollTo(0);
        }
    });

    return error ? <Note preset="error" style={{whiteSpace: "pre-wrap", ...x.style}}>
        {error.text}
    </Note> : null;
};