
























































































import {Component, Prop, Vue, Watch} from "vue-property-decorator";
import CardModal from "@/components/CardModal.vue";
import {ModalResult} from '@/view-models/ModalRequest';
import {ContractStage, ContractStageDissolutionInfo} from '@/models/Contracts';
import {ContractScope} from '../ContractScope';
import {StageDissolution} from "@/api/Stages";

import {DissolutionStatusStrings, LawType, ObjectStatus, toSelectOptions} from '@/models/enums';
import {File} from '@/models/Documents';
import ContractDocumentModal from '@/views/modals/ContractDocumentModal';
import EventBus from '@/EventBus';
import {
  DissolutionReason,
  DissolutionReasonStrings,
  DissolutionType,
  DissolutionTypeStrings
} from '@/models/enums/Contracts/Dissolution';
import {addDays} from 'date-fns';

@Component({ components: { CardModal } })
export default class AddDissolution extends Vue {
    @Prop() private prop!: { scope: ContractScope; stage: ContractStage; source: ContractStageDissolutionInfo | null};

    public get editable() { return this.prop.stage.status === 'CREATING' || this.prop.stage.status === 'CREATING_FIRST' }

    public source: ContractStageDissolutionInfo = new ContractStageDissolutionInfo(
        this.prop.source ? this.prop.source.id : null!,
        this.prop.source ? this.prop.source.createdAt : null!,
        this.prop.source ? this.prop.source.status : ObjectStatus.CREATING,
        this.prop.source ? this.prop.source.executionDate : null!,
        this.prop.source ? this.prop.source.type : null!,
        this.prop.source ? this.prop.source.reason : null!,
        this.prop.source ? this.prop.source.decisionNumber : null!,
        this.prop.source ? this.prop.source.decisionDate : null!,
        this.prop.source ? this.prop.source.isDecisionElectronic : false,
        this.prop.source ? this.prop.source.decision : null!,
        this.prop.source ? this.prop.source.isNotificationImpossible : false,
        this.prop.source ? this.prop.source.notificationDate : null!,
        this.prop.source ? this.prop.source.fasLetter : null,
        this.prop.source ? this.prop.source.fasLetterDate : null,
        this.prop.source ? this.prop.source.fasLetterNumber : null,
        this.prop.source ? this.prop.source.isContractBreachResolved : false
        );

    public get status() {
        return DissolutionStatusStrings[this.source.status];
    }

    public get is44() {
      return this.prop.scope.contract.law === LawType.F44;
    }

    public get executionDate() {
        return this.source.isNotificationImpossible && this.source.type === DissolutionType.CUSTOMER_DECISION
            ? this.source.decisionDate && addDays(this.source.decisionDate, 40)
            : this.source.notificationDate && addDays(this.source.notificationDate, 10);
    }

    public get decisionFieldLabel() {
        switch (this.source.type) {
            case DissolutionType.AGREEMENT:
                return { genitive: "соглашения о расторжении", nominative: "Соглашение о расторжении" };
            case DissolutionType.LAW_DECISION:
                return { genitive: "решения суда", nominative: "Решение суда" };
            default:
                return { genitive: "решения о расторжении", nominative: "Решение о расторжении" };
        }
    }

    @Watch("source.type") public setDefaultReason() {
        switch (this.source.type) {
            case DissolutionType.AGREEMENT:
                this.source.reason = DissolutionReason.ACT_OF_PROVIDENCE;
                break;
            case DissolutionType.CUSTOMER_DECISION:
                this.source.reason = DissolutionReason.SUPPLIER_FAULT;
                break;
        }
    }

    public get unfairSupplierFieldsVisible() {
        return this.source.type !== DissolutionType.SUPPLIER_DECISION &&
            this.source.reason === DissolutionReason.SUPPLIER_FAULT && this.prop.scope?.contract?.law === "F44";
    }

    public get isNotificationImpossibleVisible() {
        return this.prop.scope?.contract?.law === 'F44';
    }

    public typeOptions = toSelectOptions(DissolutionTypeStrings, true);
    public get reasonOptions() {
        switch (this.source.type) {
            case DissolutionType.AGREEMENT:
                return [{
                    key: DissolutionReason.ACT_OF_PROVIDENCE,
                    desc: DissolutionReasonStrings[DissolutionReason.ACT_OF_PROVIDENCE]
                }];
            case DissolutionType.CUSTOMER_DECISION:
                return [{
                    key: DissolutionReason.SUPPLIER_FAULT,
                    desc: DissolutionReasonStrings[DissolutionReason.SUPPLIER_FAULT]
                }];
            default:
                return toSelectOptions(DissolutionReasonStrings, true);
        }
    }
    public async pickDecision(what: string) {
        const result = await EventBus.callModal<File | null, File>(ContractDocumentModal, this.source.decision);
        if (result.isOk) {
            this.source.decision = result.getResult();
        }
    }

    public async pickFasLetter(what: string) {
        const result = await EventBus.callModal<File | null, File>(ContractDocumentModal, this.source.fasLetter);
        if (result.isOk) {
            this.source.fasLetter = result.getResult();
        }
    }

    public get isOk() {
      console.log(this.source);
        return !!this.source.type &&
            !!this.source.reason &&
            !!this.source.decisionNumber && this.source.decisionNumber.trim().length > 0 &&
            (this.source.isDecisionElectronic || !!this.source.decisionDate) &&
            !!this.source.decision /* && (this.source.type !== DissolutionType.CUSTOMER_DECISION || this.source.isNotificationImpossible || !!this.source.notificationDate) */;
        }

    public loading = false;

    public async saveAndClose() {
        if (this.isOk) {
            this.loading = true;

            try {
                const src =
                    this.source.id
                        ? await StageDissolution.update(this.source)
                        : await StageDissolution.create(this.prop.stage.id, this.source);

                this.$emit("close-modal", ModalResult.ok(src));
            } finally {
                this.loading = false;
            }
        }
    }
}
