// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".EMZzoz-NmgZ5H41nBVXYE {\n    background: #eef1f7;\n    border-style: solid;\n    border-color: transparent #ccd1de #bbc1d0;\n    border-width: 0 1px 1px;\n    border-radius: 3px;\n    padding: 20px 30px 20px;\n    font-size: 24px;\n}\n", ""]);
// Exports
exports.locals = {
	"formHeader": "EMZzoz-NmgZ5H41nBVXYE"
};
module.exports = exports;
