import EventBus from "@/EventBus";
import {ApiError, DtoApiError, DtoApiErrorV1} from './ApiError';

export abstract class InteractionError extends Error {
    readonly abstract cause: string[];
    readonly abstract scope?: string;
}

export class RequestError extends InteractionError {
    readonly cause: string[];

    constructor (public readonly error: ApiError, public readonly scope: string | undefined) {
        super(`Request error (with status ${error.status}) at ${error.route} -\n\t${error.cause.join("\n\t")}`);

        this.cause = error.cause;
    }
}

export class ValidationError extends InteractionError {
    readonly cause: string[];
    readonly scope?: string = undefined;

    constructor(...cause: string[]) {
        super(`Validation error -\n\t${cause.join("\n\t")}`);
        this.cause = cause;
    }
}

export function deserializeError(err: DtoApiError, fallbackRoute: string, status: number): ApiError {
    switch (err.version) {
        case undefined: return {
            cause: err.errors,
            route: fallbackRoute,
            status
        };
        case "2": return {...err, status};
    }
}

function raiseErrorCompat(err: ApiError, errScope?: string) {
    const dtov1: DtoApiErrorV1 = { version: undefined, errors: err.cause, scope: errScope };
    EventBus.raiseError(dtov1);
}

export function throwError(err: ApiError, errScope?: string) {
    console.error(`Got HTTP error (with status ${err.status}) at ${err.route} -\n\t${err.cause.join("\n\t")}`);

    raiseErrorCompat(err, errScope);
    throw new RequestError(err, errScope);
}