import React, {useState} from "react";
import {Expandee, HStack, VStack} from "@/components/layouts";
import {Button, ContextMenuHolder, Note} from "@/components/primitive";
import router from "@/router";
import {Column, FilterColumn, FilteredSelectionTable, Pager, Table, useStoredRemoteDataset} from "@/components/table";
import {useStore} from "effector-react";
import {Store} from "effector";
import {showModal} from "@/EventBus";
import {PickLawTypeModal} from "@/modal-views";
import {ProcedureFilters, ProceduresState} from "@/views/Procedures2020/list/store";
import { Procedure, ProcedureStatus, ProcedureStatusStrings } from "@/models/Procedures2020/Procedure";
import { LawType, LawTypeStrings } from "@/models/enums";
import {applyN, uuid} from "@/models/parsing";
import {formatNumber} from "@/NumberFormatting";
import {formatDate} from "@/dateUtils";
import { ProviderSelectionType, ProviderSelectionTypeStrings } from "@/models/ComposedLots";
import Auth from "@/api/Auth";
import {deleteProcedure} from "@/api/Procedures2020";
import {useErrorHandler} from "@/reactUtils";
import { TradesFilters } from "@/views/Procedures2020/trade/list/store";
import {UnderlinedVueLink} from "@/components/VueLink";

const addObject = async () => {
    const law = await showModal(PickLawTypeModal);
    if (law) {
        router.push("/procedures/2020/create/" + law).ignore;
    }
};

const editProcedure = (id: uuid) => {
    router.push(`/procedures/2020/${id}/edit`).ignore;
};

export const Procedures = (x: { storeContainer: { store: Store<ProceduresState> } }) => {
    const [error, makeSafe] = useErrorHandler();
    
    const deleteProcedureAction = (id: uuid) => makeSafe(async () => {
        if (id) {
            await deleteProcedure(id);
            router.replace("/procedures/2020");
        }
    })();

    const state = useStore(x.storeContainer.store);
    const rds = useStoredRemoteDataset(state.procedures);
    const [filtersVisible, setFiltersVisible] = useState(true);

    const columns: Column<Procedure>[] = [
        Table.Column("Реестровый номер", (a) => <>
            {a.item.regNumber}
        </>),
        FilterColumn<Procedure, TradesFilters>({
            header: "Закон-основание",
            cell: a => <>{LawTypeStrings[a.item.law]}</>,
            filter: {
                type: "select",
                options: Object.keys(LawTypeStrings).map(k => ({
                    key: k,
                    desc: LawTypeStrings[k as LawType]
                })),
                values: Object.keys(LawTypeStrings),
                key: "law"
            }
        }),
        FilterColumn<Procedure, ProcedureFilters>({
            header: "Наименование предмета закупки",
            cell: a => <UnderlinedVueLink href={"/procedures/2020/" + a.item.id} >
                {a.item.subject}
            </UnderlinedVueLink>,
            filter: {
                type: "string",
                key: "subject"
            },
            options: {width: "4fr"}
        }),
        Table.Column("Организатор", () => <>
            {Auth.currentUserInfo?.selectedParticipant?.shortName}
        </>),
        FilterColumn<Procedure, TradesFilters>({
            header: "Способ размещения",
            cell: a => <>{a.item.providerSelectionType ? ProviderSelectionTypeStrings[a.item.providerSelectionType] : ""}</>,
            filter: {
                type: "select",
                options: Object.keys(ProviderSelectionTypeStrings).map(k => ({
                    key: k,
                    desc: ProviderSelectionTypeStrings[k as ProviderSelectionType]
                })),
                values: Object.keys(ProviderSelectionTypeStrings),
                key: "providerSelectionType"
            },
            options: {
                width: "2fr"
            }
        }),
        Table.Column("Уровень размещения", () => <>
            Первый
        </>),
        FilterColumn<Procedure, TradesFilters>({
            header: "Сумма, руб.",
            cell: a => <>{formatNumber(a.item.lotSpecPriceTotal)}</>,
            filter: {
                type: "decimal",
                key: "lotSpecPriceTotal"
            }
        }),
        Table.Column("Плановая дата публикации", a => <>
            {applyN(formatDate, a.item.publishTerms?.publishDate) ?? ""}
        </>),
        FilterColumn<Procedure, TradesFilters>({
            header: "Статус",
            cell: a => <>{(a.item.publishTerms != null && a.item.status === "PUBLISH_PENDING")
                ? "Установлены сроки размещения" : ProcedureStatusStrings[a.item.status] }</>,
            filter: {
                type: "select",
                options: Object.keys(ProcedureStatusStrings).map(k => ({
                    key: k,
                    desc: ProcedureStatusStrings[k as ProcedureStatus]
                })),
                values: Object.keys(ProcedureStatusStrings),
                key: "status"
            },
            options: { width: "2fr" }
        }),
        Table.AutoColumn("Действие", a => {
            const [shown, setShown] = useState(false);

            return <ContextMenuHolder shown={shown} hide={() => setShown(false)} items={[
                ["Редактировать", () => editProcedure(a.item.id)],
                "sep",
                ["Удалить", () => deleteProcedureAction(a.item.id)]]}>
                <Button title="действие" onClick={() => setShown(true)}/>
            </ContextMenuHolder>;
        }),
    ];

    return <VStack outerStyle={{margin: "30px"}} spacing="15px">
        <Note preset="title">
            <HStack alignItems="center" spacing={"10px"}>
                <div>Процедуры</div>
                <Expandee/>
                <Button icon="aAdd" onClick={addObject}/>
                <Button icon="aFilter" onClick={() => setFiltersVisible(!filtersVisible)}/>
                <Button icon="faRedoAlt"/>
            </HStack>
        </Note>
        <Note preset="panel">
            <FilteredSelectionTable
                filterStore={rds.filterStore}
                isFiltersVisible={filtersVisible}
                mode="single"
                selectorPosition="hidden"
                dataset={rds.dataset}
                columns={columns}/>
        </Note>
        <Pager remoteDataset={rds}/>
    </VStack>;
};
