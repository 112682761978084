import { createEffect, createEvent, createStore, Store } from "effector";
import {LotForm} from "@/views/ComposedLot/shared/store/index";
import {uuid} from "@/models/parsing";
import {Lot, LotStatus, LotVisibleStatus} from "@/models/ComposedLots";
import {EditReasonType} from "@/models/enums/EditReasonType";
import {autoSV, createSVEvent, origEv} from "@/storeUtils";
import { Contract } from "@/models/Contracts";
import { Procedure } from "@/models/Procedures2020/Procedure";
import {CancellationReason} from "@/models/ComposedLots/CancellationReason";
import {Participant} from "@/models";
import Auth from "@/api/Auth";
import { LawType } from "@/models/enums";
import { showModal } from "@/EventBus";
import { PickFinancialSourceModal } from "@/modal-views";
import { LotPlannedPayment } from "@/models/ComposedLots/LotPlannedPayment";
import Decimal from "decimal.js";
import { LotPlannedPayments } from "@/views/ComposedLot/shared/store/plannedPayments";
import { PickIncludedLotsModal } from "@/modal-views/PickIncludedLotsModal";
import { replaceOrPush } from "@/arrayUtils";
import { LotDocument } from "@/models/ComposedLots/documents";


export interface LotHead {
    instance?: Lot
    visibleStatus?: LotVisibleStatus
    id?: uuid
    regNumber?: number
    purchaseCode?: string
    status?: LotStatus
    procedureId?: uuid
    editReason?: EditReasonType
    infoChanged: boolean
    specsChanged: boolean
    docsChanged: boolean
    addsChanged: boolean
    cancellationReason?: CancellationReason

    relatedContract?: Contract
    relatedProcedure?: Procedure

    customer?: Participant
    performer?: Participant
    mainCustomer?: Participant
    includedLots: Lot[]
}

export const addIncludedLot = createEvent<{ oldV?: Lot; newV: Lot }>("add or edit lotRequestDocument");
export const deleteIncludedLots = createEvent<Lot[]>("delete included lots");

const replace = createEvent<LotHead>("replace lot head");

export const setEditReason = createSVEvent<LotHead>()("editReason");
export const setInfoChanged = createSVEvent<LotHead>()("infoChanged");
export const setSpecsChanged = createSVEvent<LotHead>()("specsChanged");
export const setDocsChanged = createSVEvent<LotHead>()("docsChanged");
export const setAddsChanged = createSVEvent<LotHead>()("addsChanged");

const setRelatedContract = createSVEvent<LotHead>()("relatedContract");
const setRelatedProcedure = createSVEvent<LotHead>()("relatedProcedure");

export const setPerformer = createSVEvent<LotHead>()("performer");
export const setMainCustomer = createSVEvent<LotHead>()("mainCustomer");

export const HeadStore = {
    createStore: (): Store<LotHead> => {
        const store = createStore<LotHead>({
            infoChanged: false,
            specsChanged: false,
            docsChanged: false,
            addsChanged: false,
            customer: Auth.currentUserInfo?.selectedParticipant,
            performer: Auth.currentUserInfo?.selectedParticipant, //#578 default performer on creation
            includedLots: []
        })
            .on(replace, (x, l) => ({
                ...l,
                relatedContract: x.relatedContract,
                relatedProcedure: x.relatedProcedure
            }))
            .on(addIncludedLot, (state, payload) => ({
                ...state,
                includedLots: replaceOrPush(state.includedLots, payload.oldV, payload.newV)
            }))
            .on(deleteIncludedLots, (state, lots) => ({
                ...state,
                includedLots: state.includedLots.filter(x => !lots.some(l => l.id == x.id))
            }))
            .on(setEditReason[origEv], autoSV)
            .on(setInfoChanged[origEv], autoSV)
            .on(setSpecsChanged[origEv], autoSV)
            .on(setDocsChanged[origEv], autoSV)
            .on(setAddsChanged[origEv], autoSV)
            .on(setRelatedContract[origEv], autoSV)
            .on(setRelatedProcedure[origEv], autoSV)
            .on(setPerformer[origEv], autoSV)
            .on(setMainCustomer[origEv], autoSV)
        ;

        store.watch(setEditReason, () => setInfoChanged(true));
        return store;
    },

    setRelatedContract,
    setRelatedProcedure,

    replace: replace,
    observeOnCombinedStore: (combinedStore: Store<LotForm>) => {}
};