import React, { useRef, useState } from "react";
import { ModalFC } from "@/EventBus";
import { ContractObligation } from "@/models/Contracts/ContractObligation";
import { CardModal } from "@/components/modals/CardModal";
import { HStack, VStack } from "@/components/layouts";
import { Button, Radio, RadioGroup } from "@/components/primitive";
import { useStore } from "effector-react";
import { createContractObligationStore } from "@/views/Obligations/shared/store";
import { ObligationsTable } from "@/views/Obligations/shared/ObligationsTable";
import { Pager, useStoredRemoteDataset } from "@/components/table";

export const enum ObligationProperty {
    NO_TRANSFER,
    ADD,
    REPLACE
}

type PickContractObligationModalProps = {
    showProperties?: boolean
    contractNumber?: string
};

export type PickContractObligationModalReturn = {
    obligation: ContractObligation
    obligationProperty?: ObligationProperty
};

export const PickContractObligation: ModalFC<
    PickContractObligationModalProps, PickContractObligationModalReturn
    > = ({ done, showProperties = true, contractNumber }) => {

    const store = useRef(createContractObligationStore(contractNumber).store);

    const { contractObligationsSrd } = useStore(store.current);
    const contractObligations = useStoredRemoteDataset(contractObligationsSrd);

    const [obligationProperty, setObligationProperty] = useState<ObligationProperty>(ObligationProperty.ADD);

    const [selectedObligation, setSelectedObligation] = useState<ContractObligation | undefined>(undefined);

    const bottom = (
        <HStack reverse>
            <Button icon="aSave"
                    disabled={!selectedObligation}
                    onClick={() => selectedObligation && done({ obligation: selectedObligation, obligationProperty })} />
        </HStack>
    );

    return (
        <CardModal title="Выбор денежного обязательства"
                   width="60%"
                   bottom={bottom}
                   close={() => done(undefined)} >
            <VStack spacing="10px">
                {showProperties && (
                    <VStack spacing="5px">
                        <RadioGroup>
                            <Radio requiredValue={ObligationProperty.NO_TRANSFER}
                                   onChange={setObligationProperty}
                                   value={obligationProperty}>
                                Не переносить сведения о поступлениях из денежного обязательства в сведения о поставленных
                                товарах, работах, услугах
                            </Radio>
                            <Radio requiredValue={ObligationProperty.ADD}
                                   onChange={setObligationProperty}
                                   value={obligationProperty}>
                                Добавить сведения о поступлениях из денежного обязательства в существующие сведения о
                                поставленных товарах, работах, услугах
                            </Radio>
                            <Radio requiredValue={ObligationProperty.REPLACE}
                                   onChange={setObligationProperty}
                                   value={obligationProperty}>
                                Заменить текущие сведения о поставленных товарах, работах, услугах сведениями о поступлениях
                                из денежного обязательства
                            </Radio>
                        </RadioGroup>
                    </VStack>
                )}
                <ObligationsTable obligations={contractObligations.dataset}
                                  filterStore={contractObligations.filterStore}
                                  onChange={setSelectedObligation}
                                  selected={selectedObligation}
                                  isFiltersVisible={true} />
                <Pager remoteDataset={contractObligations} />
            </VStack>
        </CardModal>
    );
};
