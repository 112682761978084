import React, {CSSProperties} from "react";
import styles from "@/components/eshop/IconButton/IconButton.module.css";

export interface IconButtonProps {
    onClick?: (e?: React.MouseEvent<unknown, MouseEvent>) => void
    disabled?: boolean
    icon: string
    style?: CSSProperties
    title?: string
    red?: boolean
    gray?: boolean
    hoverType?: "round" | "color"
}

export const IconButton = (props: IconButtonProps) => {

    const hoverType = props.hoverType ?? "round";

    return (
        <div onClick={e => !props.disabled && props.onClick?.(e)} title={props.title}
             className={props.disabled ? styles.svgButton__wrapper_disabled : hoverType === "color" ?  styles.svgButton__wrapper_color : styles.svgButton__wrapper_round}>
            <img className={(props.disabled || props.gray) ? styles.grayFilter : props.red ? styles.redFilter : styles.blueFilter} style={props.style} src={props.icon} alt={props.icon.toString()}/>
        </div>
    );
};
