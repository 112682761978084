import React from "react";
import {Card} from "@/components/Card";
import {Button} from "@/components/primitive";
import {HStack} from "@/components/layouts";
import {PickSpecificationProps} from "@/modal-views/PickSpecificationModal/store";
import {showModal} from "@/EventBus";
import {PickSpecificationModal} from "@/modal-views";

export const SpecificationSection = () => {
    const addOrEditSpecification = async (specification: PickSpecificationProps) => {
        const result = await showModal(PickSpecificationModal, specification);
    };

    return <Card>
        <HStack>
            <Button icon="aAdd" color="green" title="Добавить" onClick={() => addOrEditSpecification} />
        </HStack>
    </Card>;
};
