









import { Component, Prop, Vue } from "vue-property-decorator";
import { IFieldProps } from './FieldBase';
import { AmountPercentRow } from './FormFields';

@Component
export default class AmountPercentField extends Vue {
    @Prop() public props!: IFieldProps<AmountPercentRow<unknown>>;

    private get evaluated() {
        return this.props.row.getter(this.props.source);
    }
}
