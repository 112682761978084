import React, { useState } from "react";
import {Button, Note} from "@/components/primitive";
import {Expandee, HStack, VStack} from "@/components/layouts";
import {MonopolyTable} from "@/views/Monopoly/list/sections/MonopolyTable";
import { StoredRemoteDataset, useStoredRemoteDataset } from "@/components/table";
import { Monopoly } from "@/models/Monopoly";
import { MonopolyFilters } from "@/views/Monopoly/list/store";
import { monopolyObjectsColumns } from "@/views/Monopoly/list/sections/columns";
import { Effect } from "effector";

type MonopolyObjectPageType = "in-progress" | "accepted" | "processed";

type MonopolyObjectsProps = {
    srd: StoredRemoteDataset<Monopoly, MonopolyFilters>
    onMock: () => void
    onClear: () => void
    getTitle: (type: MonopolyObjectPageType) => string
    type: MonopolyObjectPageType
};

export const MonopolyObjects = ({ type, getTitle, srd, onMock, onClear }: MonopolyObjectsProps) => {
    const { setCurrentPage } = useStoredRemoteDataset(srd);

    const [isFiltersVisible, setIsFiltersVisible] = useState<boolean>(true);
    const toggleFiltersVisibility = () => setIsFiltersVisible(is => !is);

    const clearPagerState = () => setCurrentPage(0, true);

    const mock = async () => {
        await onMock();

        clearPagerState();
    };

    const clear = async () => {
        await onClear();

        clearPagerState();
    };


    return (
        <VStack spacing="15px">
            <Note preset="title" style={{ marginTop: 0 }}>
                <HStack alignItems="center" spacing="10px">
                    <div>{`Объекты данных монополиста ${getTitle(type)}`}</div>
                    <Expandee />
                    <Button icon="aAdd" color="orange" title="Создать 3 объекта" onClick={mock} />
                    <Button icon="faTrash" color="orange" title="Удалить объекты" onClick={clear} />
                    <Button icon="aFilter" onClick={toggleFiltersVisibility} />
                    <Button icon="faRedoAlt" />
                    <Button color="green" icon="faSearch" title="Поиск" />
                </HStack>
            </Note>
            <Note preset="panel">
                <MonopolyTable srd={srd}
                               columns={monopolyObjectsColumns(type)}
                               isFiltersVisible={isFiltersVisible} />
            </Note>
        </VStack>
    );
};
