import React from "react";
import {Card} from "@/components/Card";
import {Table} from "@/components/table";
import {Label} from "@/components/primitive";
import router from "@/router";

export const SubjectDeclarationsCharacteristic: React.FC = x => {
    type Template = { template: "" };
    const temp: Template[] = [{ template: "" }];

    return <Card>
            <Table<Template> dataset={temp} columns={[
                Table.AutoColumn("Характеристика", x =>
                    <Label preset="readonlyValue" onClick={() => router.push("/")} style={{ textDecoration: "underline", cursor: "pointer" }}>
                        {x.item.template}
                    </Label>),
                Table.AutoColumn("Тип характеристики", x =>
                    <Label preset="readonlyValue">
                        {x.item.template}
                    </Label>),
                Table.AutoColumn("Тип выбора значений", x =>
                    <Label preset="readonlyValue">
                        {x.item.template}
                    </Label>),
                Table.AutoColumn("Значение по умолчанию", x =>
                    <Label preset="readonlyValue">
                        {x.item.template}
                    </Label>),
                Table.AutoColumn("Условная операция", x =>
                    <Label preset="readonlyValue">
                        {x.item.template}
                    </Label>),
                Table.AutoColumn("Единица измерения", x =>
                    <Label preset="readonlyValue">
                        {x.item.template}
                    </Label>),
                Table.AutoColumn("Значение", x =>
                    <Label preset="readonlyValue">
                        {x.item.template}
                    </Label>),
                Table.AutoColumn("Унаследована", x =>
                    <Label preset="readonlyValue">
                        {x.item.template}
                    </Label>),
                Table.AutoColumn("ID на Портале поставщиков", x =>
                    <Label preset="readonlyValue">
                        {x.item.template}
                    </Label>)
            ]} />
    </Card>;
};