import React from "react";
import { Card } from "@/components/Card";
import { Field, Fields } from "@/components/form/Field";
import { TextBox } from "@/components/primitive";
import { formatNumber } from "@/NumberFormatting";

export const AnnualRevenue = () => {
    return (
        <Card>
            <Fields orientation="horizontal">
                <Field title="Годовая выручка заказчика за отчетный финансовый год">
                    <TextBox value={formatNumber(0)} disabled />
                </Field>
            </Fields>
        </Card>
    );
};
