import { Vue } from "vue-property-decorator";
import { VueClass } from "vue-class-component/lib/declarations";

export class EmptyModalResultUnwrappingError extends Error {}

export class ModalResult<TOut> {
    private constructor(private internalResult: TOut | undefined, public isOk: boolean) {}

    public getResult(): TOut {
        if (!this.isOk) throw new EmptyModalResultUnwrappingError();

        return this.internalResult!;
    }

    public static ok<TOut>(result: TOut) {
        return new ModalResult<TOut>(result, true);
    }
    public static empty<TOut>() {
        return new ModalResult<TOut>(undefined, false);
    }
}

export class ModalVue<TProp, TOut> extends Vue {}

export class ModalRequest<TProp, TOut> {
    constructor(
        public component: VueClass<ModalVue<TProp, TOut>>,
        public prop: TProp,
        public callback: (_: ModalResult<TOut>) => void,
    ) {}
}
