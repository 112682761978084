import React from "react";
import { observer } from "mobx-react";
import styles from "./OffersInfo.module.css";
import { Offer } from "@/api/Products";
import {formatNumber} from "@/NumberFormatting";

type OffersInfoProps = {
    offers: Offer[]
    onClose: () => void
    popupRef: React.Ref<HTMLDivElement>
};

export const OffersInfo = observer((props: OffersInfoProps) => {
    return <div className={styles.offersPopup} ref={props.popupRef}>
        <div className={styles.infoClose} onClick={props.onClose} />
        <table className={styles.infoTable}>
            <tbody>
                <tr>
                    <td className={styles.offerVendor}>Поставщик:</td>
                    <td className={styles.offerValue}>Стоимость с НДС</td>
                </tr>
                {props.offers.map(x => <tr className={styles.offersRow} key={x.id}>
                    <td className={styles.offerVendor}>{x.participant.shortName}:</td>
                    <td className={styles.offerValue}>{formatNumber(x.priceWithNds)} р.</td>
                </tr>)}
            </tbody>
        </table>
    </div>;
});