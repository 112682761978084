import React from "react";
import { Card } from "@/components/Card";
import { MonopolyDocument } from "@/models/Monopoly";
import { Column, Table } from "@/components/table";
import { formatDate } from "@/dateUtils";

type DocumentsSectionProps = {
    documents?: MonopolyDocument[]
};

export const DocumentsSection = ({ documents = [] }: DocumentsSectionProps) => {
    const documentsColumns: Column<MonopolyDocument>[] = [
        Table.AutoColumn(
            "№",
            ({ rowIndex }) => <>{rowIndex + 1}</>
        ),
        Table.Column(
            "Документ",
            ({ item }) => <>{item.file.name}</>,
            { width: "4fr" }
        ),
        Table.Column(
            "Дата получения сведений",
            ({ item }) => {
                return <>{formatDate(item.file.createdAt, "time")}</>;
            }
        )
    ];

    return (
        <Card>
            <Table columns={documentsColumns} dataset={documents} />
        </Card>
    );
};
