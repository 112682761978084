import {ModalFC} from "@/EventBus";
import {Expandee, HStack} from "@/components/layouts";
import {Button} from "@/components/primitive";
import {CardModal} from "@/components/modals/CardModal";
import {Table} from "@/components/table/Table";
import React from "react";


export const ViewDocumentHistoryModal: ModalFC = x => {
    const bottom = <HStack spacing={"10px"}>
        <Expandee/>
        <Button color={"green"} title={"Закрыть"} onClick={x.done}/>
    </HStack>;

    return <CardModal title={"История изменений документов"} close={x.done} width={"70%"} bottom={bottom}>
        <Table dataset={[]} columns={[
            Table.Column("Тип документа", x => <div>x</div>),
            Table.Column("Документ", x => <div>x</div>),
            Table.Column("Дата и время операции", x => <div>x</div>),
            Table.Column("Пользователь", x => <div>x</div>),
            Table.Column("Тип операции", x => <div>x</div>),
        ]}/>
    </CardModal>;
};