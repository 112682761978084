import { IDtoMarketPrice } from "@/models/json";
import { IJsonFormattable } from "@/models/IJsonFormattable";
import { MarketPriceDataSource } from "./enums";
import { Participant } from "./Participant";
import Decimal from "decimal.js";
import { SubjectDeclaration } from './SubjectDeclaration';

export class MarketPrice implements IJsonFormattable<IDtoMarketPrice> {
    constructor(
        public id: number,
        public name: string,
        public priceWithoutTax: Decimal,
        public priceWithTax: Decimal,
        public startDate: Date,
        public expirationDate: Date,
        public dataSource: MarketPriceDataSource,
        public taxPercent: Decimal,

        public producer: Participant | null,
        public producerName: string | null,
        public subjectDeclaration?: SubjectDeclaration
    ) {}

    public toJson(): IDtoMarketPrice {
        return {
            ...this,
            priceWithoutTax: this.priceWithoutTax.toString(),
            priceWithTax: this.priceWithTax.toString(),
            taxPercent: this.taxPercent.toString(),
            startDate: this.startDate.toISOString(),
            expirationDate: this.expirationDate.toISOString(),
            producer: this.producer ? this.producer.toJson() : null,
            subjectDeclaration: this.subjectDeclaration?.toJson()
        };
    }

    // noinspection JSUnusedGlobalSymbols
    public static fromJson(dto: IDtoMarketPrice): MarketPrice {
        return Object.assign(Object.create(MarketPrice.prototype), dto, {
            priceWithTax: new Decimal(dto.priceWithTax),
            priceWithoutTax: new Decimal(dto.priceWithoutTax),
            taxPercent: new Decimal(dto.taxPercent),
            startDate: new Date(dto.startDate),
            expirationDate: new Date(dto.expirationDate),
            producer: dto.producer ? Participant.fromJson(dto.producer) : null,
            subjectDeclaration: dto.subjectDeclaration && SubjectDeclaration.fromJson(dto.subjectDeclaration)
        });
    }
}
