import {createEvent, createStore} from "effector";
import {Plan} from "@/models/Plans/Plan";
import {getArchivedPlans, getPlans} from "@/api/Plans2020";
import Auth from "@/api/Auth";

export interface PlansState {
    plans: Plan[]
    archivedPlans: Plan[]
    loaded: boolean
}

export const createPlansStore = () =>{
    const setPlans = createEvent<Plan[]>();
    const setArchivedPlans = createEvent<Plan[]>();

    const store = createStore<PlansState>({
        plans: [],
        archivedPlans: [],
        loaded: false
    })
        .on(setPlans, (x, v) => ({...x, plans: v, loaded: true}))
        .on(setArchivedPlans, (x, v) => ({ ...x, archivedPlans: v, loaded: true}));

    getPlans(Auth.selectedYear).then(setPlans);
    getArchivedPlans(Auth.selectedYear).then(setArchivedPlans);

    return store;
};