import { AutoDto, AutoModel, MDMap, uuid } from "@/models/parsing";
import { Participant } from "@/models";
import { IDtoParticipant } from "@/models/json";
import { ContractPayment, DtoContractPayment } from "@/models/Contracts/ContractPayment";
import Decimal from "decimal.js";
import { DtoShortContract, ShortContract } from "@/models/Contracts/ShortContract";

interface ContractObligationMD {
    id: uuid
    customer: MDMap<Participant, IDtoParticipant>
    date: Date
    payments: MDMap<ContractPayment, DtoContractPayment>[]
    contract: MDMap<ShortContract, DtoShortContract>
    regNumber: string
    registeredAt: Date
    totalPrice: Decimal
    type: string
    typeNum: number
    userId: uuid
    endDateStage: Date
}

export type ContractObligation = AutoModel<ContractObligationMD>;
export type DtoContractObligation = AutoDto<ContractObligationMD>;

export const ContractObligation = {
    fromDto(dtoContractObligation: DtoContractObligation): ContractObligation {
        return {
            ...dtoContractObligation,
            customer: Participant.fromJson(dtoContractObligation.customer),
            date: new Date(dtoContractObligation.date),
            payments: dtoContractObligation.payments.map(ContractPayment.fromDto),
            contract: ShortContract.fromDto(dtoContractObligation.contract),
            registeredAt: new Date(dtoContractObligation.registeredAt),
            totalPrice: new Decimal(dtoContractObligation.totalPrice),
            endDateStage: new Date(dtoContractObligation.endDateStage)
        };
    },
    toDto(contractObligation: ContractObligation): DtoContractObligation {
        return {
            ...contractObligation,
            customer: contractObligation.customer.toJson(),
            date: contractObligation.date.toISOString(),
            payments: contractObligation.payments.map(ContractPayment.toDto),
            contract: ShortContract.toDto(contractObligation.contract),
            registeredAt: contractObligation.date.toISOString(),
            totalPrice: contractObligation.totalPrice.toString(),
            endDateStage: contractObligation.endDateStage.toISOString()
        };
    }
};
