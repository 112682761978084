import { createEvent, createStore, Store } from "effector";
import { ProcedureForm } from "@/views/Procedures2020/view/store";
import { uuid } from "@/models/parsing";
import { LawType } from "@/models/enums";
import { ProcedureStatus } from "@/models/Procedures2020/Procedure";
import Decimal from "decimal.js";
import { Contract } from "@/models/Contracts";
import { HistoryItem } from "@/models";

export interface ProcedureHeadStore {
    id?: uuid
    law: LawType
    status?: ProcedureStatus
    regNumber?: string
    lotSpecPriceTotal?: Decimal
    relatedContract?: Contract
    history?: HistoryItem<ProcedureStatus>[]
}

const replace = createEvent<ProcedureHeadStore>("replace info section");
const setRelatedContract = createEvent<Contract>("set related contract");
export const setHistory = createEvent<HistoryItem<ProcedureStatus>[]>();

/** Общая информация по процедуре */
export const ProcedureHeadStore = {
    createStore: (law?: LawType): Store<ProcedureHeadStore> => {
        return createStore<ProcedureHeadStore>({
            law: law ?? LawType.F44,
            history: []
        })
            .on(replace, (_, l) => l)
            .on(setRelatedContract, (x, relatedContract) => ({ ...x, relatedContract }))
            .on(setHistory, (state, payload) => ({ ...state, history: payload }));


    },

    replace,
    setRelatedContract,
    observeOnCombinedStore: (combinedStore: Store<ProcedureForm>) => {}
};
