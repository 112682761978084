import styles from "./NewsPage.module.css";
import React, {RefObject, useEffect, useRef, useState} from "react";
import {NewsArticleLabel} from "@/api/News";
import {VStack} from "@/components/layouts";
import {CompactNewsItem, NewsItem} from "@/views/Login/news/widgets/NewsItem";
import {Spinner} from "@/components/primitive/Spinner";
import {colors} from "@/components/primitive/RoundedButton.colors";
import {NewsPageViewModel} from "@/views/Login/news/NewsPage.vm";
import {ScrollToTop} from "@/views/Login/news/widgets/ScrollToTop";
import {observer} from "mobx-react";
import {SearchBar} from "@/views/Login/news/widgets/SearchBar";
import {GradientButton} from "@/views/Login/wizard/LoginWizardModal";

const loader = <div style={{width: "100%", display: "flex", justifyContent: "center"}}>
    <Spinner color={colors.blue.idle.bg}/>
</div>;

const end = <div style={{ width: "100%", display: "flex", justifyContent: "center", padding: "15px 0", flexDirection: "column" }}>
    <div className={styles.gradientText} style={{ fontSize: "24px", lineHeight: "30px", marginBottom: "6px" }}>Вы долистали до конца</div>
    <div style={{ opacity: "0.7" }}>
        <div className={styles.gradientText} style={{ fontSize: "16px", lineHeight: "20px" }}>Теперь вы в курсе всех новостей :)</div>
    </div>
</div>;

export const NewsPage = observer(() => {
    const [vm, setVm] = useState<NewsPageViewModel>();
    useEffect(() => {
        const _vm = new NewsPageViewModel();
        const scrollContainer = document.getElementById("login-wrapper");
        const handleScroll = () => {
            if (_vm.pageSpin) return;
            if (_vm.reachedEnd) return;
            if (window.scrollY + window.innerHeight >=
                document.documentElement.scrollHeight) {
                _vm.loadMore();
            }
        };
        document.body.style.overflowX = "hidden";
        const app = document.getElementById("app");
        if (app) app.style.height = "unset";

        window.addEventListener("scroll", handleScroll);

        setVm(_vm);
        return () => {
            window.removeEventListener("scroll", handleScroll);
            _vm.dispose();
            if (app) app.style.height = "";
            document.body.style.overflowX = "";
        };
    }, []);

    const scrollable1 = useRef<HTMLDivElement>(null);
    const scrollable2 = useRef<HTMLDivElement>(null);
    const setLabel = (v: NewsArticleLabel | null) => {
        if (!vm) return;
        vm.setLabel = v;
    };
    const setQuery = (v: string | null) => {
        if (!vm) return;
        vm.setQuery = v;
    };

    if (!vm) return <></>;

    const handleScroll = (scrollable: RefObject<HTMLDivElement>) => {
        if (vm.pageSpin) return;
        if (vm.reachedEnd) return;
        if (scrollable.current) {
            if ((scrollable.current.scrollHeight - scrollable.current.clientHeight - scrollable.current.scrollTop) < 200 && !vm?.reachedEnd) {
                vm.loadMore();
            }
        }
    };

    const content = <>
        <div className={styles.column}
             ref={scrollable1}
             onScroll={() => handleScroll(scrollable1)}>
            <div className={styles.header}>
                <div style={{ fontWeight: 700, fontSize: "35px", fontFamily: "GothamPro-Medium" }}>Новости</div>
                <div className={styles.mobileSearch}>
                    <SearchBar query={vm.query ?? undefined} label={vm.label ?? undefined} onChange={setQuery} onLabelRemove={() => setLabel(null)} />
                </div>
            </div>
            { vm.items?.map((x, i) => <React.Fragment key={`${x.id}-${i}`}>
                <div style={{ width: "100%", borderTop: "1px solid #CCCFD0" }} />
                <NewsItem item={x} onLabelSelect={setLabel} />
            </React.Fragment>) }
            { vm.pageSpin && loader }
            { vm.reachedEnd && end }
        </div>
        <div className={styles.search}>
            <SearchBar query={vm.query ?? undefined} label={vm.label ?? undefined} onChange={setQuery} onLabelRemove={() => setLabel(null)} />
            <div className={styles.overview}
                 ref={scrollable2}
                 onScroll={() => handleScroll(scrollable2)}>
                { vm.items?.map((x, i) => <React.Fragment key={`${x.id}-${i}`}>
                    <div style={{ width: "100%", borderTop: "1px solid #CCCFD0", marginBottom: "20px" }} />
                    <CompactNewsItem item={x} onLabelSelect={setLabel} />
                </React.Fragment>) }
                { vm.pageSpin && loader }
                { vm.reachedEnd && end }
            </div>
        </div>
        <ScrollToTop />
    </>;

    return <>
        <div className={styles.news}>
            <div className={styles.content}>
                {
                    (vm.spin || vm.error || vm.items.length === 0)
                        ? <div style={{ display: "flex", alignItems: "center", justifyContent: vm.spin ? "center" : undefined }}>
                            {
                                vm.spin
                                    ? <Spinner color={colors.blue.idle.bg} />
                                    : <VStack spacing="40px" alignItems="flex-start">
                                        <div style={{ fontWeight: 400, fontSize: "16px" }}>{vm.error}</div>
                                        <GradientButton solid title="К новостям" onClick={() => vm.reload(true)} />
                                    </VStack>
                            }
                        </div>
                        : content
                }
            </div>
        </div>
        </>;
});
