import {ModalFC, showModal} from "@/EventBus";
import {CardModal} from "@/components/modals/CardModal";
import React, {useEffect, useState} from "react";
import {Card} from "@/components/eshop/Card/Card";
import {RefreshButton, ResetFiltersButton, ShowFiltersButton} from "@/components/eshop/IconButton/IconButtons";
import {Session, SessionNullable, SessionsController} from "@/api/Sessions";
import {Expandee, HStack} from "@/components/layouts";
import {RoundedButton} from "@/components/primitive/RoundedButton";
import styles from "./PickJointSessionMaster.module.css";
import {HeaderInline} from "@/views/CreateSession/Specifications/SpecificationsCard";
import {SortIcons} from "@/components/SortIcons";
import {Pager} from "@/components/eshop";
import {goToCustomerPage, goToJointSessionCard, goToSessionCard} from "@/views/Sessions/store";
import {EshopLink} from "@/components/eshop/EshopLink";
import {changeAggregatingSessionId} from "@/views/CreateSession/store";
import {ErrorModal} from "@/views/Contracts/modals/ConfirmationModal";
import { formatDate } from "@/dateUtils";

export const PickJointSessionMaster: ModalFC<{currentSession: SessionNullable}> = x => {

    const [showFilters, setShowFilters] = useState(true);
    const [sessions, setSessions] = useState<Session[]>([]);
    const [selectedSession, setSelectedSession] = useState<Session>();
    const [modalPageSize, setModalPageSize] = useState<number>(10);

    useEffect(() => {
        (async () => {
            const res = await SessionsController.getAggregations();
            if (res) setSessions(res);
        })();
    }, []);

    const bottom = <HStack spacing={"10px"}>
        <Expandee/>
        <RoundedButton color="white"
                       title="Закрыть"
                       onClick={() => x.done()} />
        <RoundedButton color="blue"
                       title="Выбрать"
                       onClick={() => {
                           if (selectedSession) {
                               // it's ugly but work
                               if (!x.currentSession.specifications.every(s => s.spgz!!.subjectClass.parents.some(kpgz => kpgz.id == selectedSession.subject.id))) {
                                   showModal(ErrorModal, {text: `Выбранная совместная котировочная сессия по соглашению ${selectedSession.number} не соответствует текущей котировочной сессии по атрибуту "КПГЗ совместной КС". Пожалуйста, перевыберите идентификатор совместной закупки или снимите флаг "Совместная КС по соглашению"`});
                               } else {
                                   changeAggregatingSessionId(selectedSession.id);
                               }
                           }
                           x.done();
                       }} disabled={!selectedSession} />
    </HStack>;

    return (
        <CardModal title={"Совместные КС по соглашению"}
                   close={() => x.done()}
                   width={"65%"} bottom={bottom}>
            <Card direction={"vertical"}>
                <div className={styles.pickMasterModalButtons__wrapper}>
                    <div className={styles.pickMasterModalButtons__container}>
                        <ShowFiltersButton onClick={() => setShowFilters(!showFilters)} />
                        <ResetFiltersButton />
                        <RefreshButton />
                    </div>
                </div>
                <table className={styles.cardTable}>
                    <thead>
                        <tr className={styles.tableRow}>
                            <HeaderInline>
                                <SortIcons sorted={null} setSort={() => {}} />
                                Номер совместной КС</HeaderInline>
                            <HeaderInline>
                                <SortIcons sorted={null} setSort={() => {}} />
                                Организатор совместной кс</HeaderInline>
                            <HeaderInline>
                                <SortIcons sorted={null} setSort={() => {}} />
                                Предмет совместной КС</HeaderInline>
                            <HeaderInline>
                                <SortIcons sorted={null} setSort={() => {}} />
                                Закон-основание</HeaderInline>
                            <HeaderInline>
                                <SortIcons sorted={null} setSort={() => {}} />
                                КПГЗ</HeaderInline>
                            <HeaderInline>
                                <SortIcons sorted={null} setSort={() => {}} />
                                Планируемая дата и время запуска совместной КС</HeaderInline>
                        </tr>
                        {showFilters &&
                            <tr className={styles.tableRow}>
                                <th>Номер совместной КС</th>
                                <th>Организатор совместной кс</th>
                                <th>Предмет совместной КС</th>
                                <th>Закон-основание</th>
                                <th>КПГЗ</th>
                                <th>Планируемая дата и время запуска совместной КС</th>
                            </tr>}
                    </thead>
                    <tbody>
                        {sessions.map((session, index) => {
                            return (
                                <tr key={`${session.id}-${index}`}
                                    className={`${styles.tableRow} ${selectedSession?.id === session.id ? styles.tableRow_selected : ""} ${styles.tableRow_selectable}`}
                                    onClick={() => {
                                        setSelectedSession(session);
                                    }}>
                                    <td className={styles.cell}>
                                        <EshopLink onClick={() => session.isJoin ? goToJointSessionCard(session.id) : goToSessionCard(session.id)}>
                                            {session.number}
                                        </EshopLink>
                                    </td>
                                    <td className={styles.cell}>
                                        <EshopLink onClick={() => session.mainOrganizer?.id && goToCustomerPage(session.mainOrganizer.id.toString())}>
                                            {session.mainOrganizer?.shortName}
                                        </EshopLink>
                                    </td>
                                    <td className={styles.cell}>{session.subjectJoin}</td>
                                    <td className={styles.cell}>{session.law}</td>
                                    <td className={styles.cell}>{session.subject.name}/{session.subject.id}</td>
                                    <td className={styles.cell}>{session.joinPublishDate ? formatDate(session.joinPublishDate, "time") : <>&mdash;</>}</td>
                                </tr>
                            );
                        })}
                    </tbody>
                </table>
            </Card>
            <Pager page={{from: 0, to: 1, totalCount: sessions.length, items: sessions}} goTo={() => {}} pageSize={modalPageSize} changePageSize={v => setModalPageSize(v)} />
        </CardModal>
    );
};
