// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, "._2lKMbm6YdkqCHszNY6BCDr {\n    background: #f1f1f1 !important;\n}", ""]);
// Exports
exports.locals = {
	"selectedRow": "_2lKMbm6YdkqCHszNY6BCDr"
};
module.exports = exports;
