import {createEvent, createStore, Store} from "effector";
import {ProcedureForm} from "@/views/Procedures2020/view/store";
import {autoSV, createSVEvent, origEv} from "@/storeUtils";
import {Currency} from "@/models/enums";
import {BankAccount} from "@/models/BankAccount";

export interface PaymentOrderStore {
    /** Взимается плата */
    paymentRequired: boolean

    /** Размер оплаты */
    volume?: string

    /** Валюта */
    currency?: Currency

    /** Облагается НДС */
    taxRequired?: boolean

    /** Текстовое поле под НДС */
    taxDescription?: string

    /** Лицевой счёт */
    account?: BankAccount

    /** Порядок внесения платы */
    text?: string
}

const replace = createEvent<PaymentOrderStore>("replace paymentOrder at documents section");

export const PaymentOrderEvents = {
    setPaymentRequired: createSVEvent<PaymentOrderStore>()("paymentRequired"),
    setVolume: createSVEvent<PaymentOrderStore>()("volume"),
    setCurrency: createSVEvent<PaymentOrderStore>()("currency"),
    setTaxRequired: createSVEvent<PaymentOrderStore>()("taxRequired"),
    setTaxDescription: createSVEvent<PaymentOrderStore>()("taxDescription"),
    setAccount: createSVEvent<PaymentOrderStore>()("account"),
    setText: createSVEvent<PaymentOrderStore>()("text"),
};

/** Секция "Документация и прием заявок" -> Размер и порядок внесения платы, взимаемой за предоставление документации о закупке */
export const PaymentOrderStore = {
    createStore: (): Store<PaymentOrderStore> => {
        return createStore<PaymentOrderStore>({
            paymentRequired: false
        })
            .on(replace, (_, l) => l)
            .on(PaymentOrderEvents.setPaymentRequired[origEv], autoSV)
            .on(PaymentOrderEvents.setVolume[origEv], autoSV)
            .on(PaymentOrderEvents.setCurrency[origEv], autoSV)
            .on(PaymentOrderEvents.setTaxRequired[origEv], autoSV)
            .on(PaymentOrderEvents.setTaxDescription[origEv], autoSV)
            .on(PaymentOrderEvents.setAccount[origEv], autoSV)
            .on(PaymentOrderEvents.setText[origEv], autoSV)

            ;
    },

    replace: replace,
    observeOnCombinedStore: (combinedStore: Store<ProcedureForm>) => {
    }
};