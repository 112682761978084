import { http } from "./http";
import { ContractExecutionProvision } from '@/models/Contracts';
import { IDtoContractExecutionProvision } from '@/models/json/Contracts';

export default class {
    public static async getFromContract(contractId: string): Promise<ContractExecutionProvision[]> {
        return (await http.get(`/api/contractExecutionProvisions/fromContract/${contractId}`, undefined) as IDtoContractExecutionProvision[])
            .map(ContractExecutionProvision.fromJson);
    }

    public static async create(contractId: string, src: ContractExecutionProvision): Promise<ContractExecutionProvision> {
        return ContractExecutionProvision.fromJson(await http.post(`/api/contractExecutionProvisions/createFor/${contractId}`, src.toJson()));
    }

    public static async update(obj: ContractExecutionProvision): Promise<ContractExecutionProvision> {
        return ContractExecutionProvision.fromJson(await http.put(`/api/contractExecutionProvisions/${obj.id}`, obj.toJson()));
    }

    public static async delete(obj: ContractExecutionProvision): Promise<void> {
        await http.delete(`/api/contractExecutionProvisions/${obj.id}`);
    }
}
