import React, {useEffect, useState} from "react";
import {Section} from "@/components/eshop/Section/Section";
import {Checkbox, Pager} from "@/components/eshop";
import {Card} from "@/components/eshop/Card/Card";
import styles from "../CreateSession.module.css";
import {changeJoinCustomersIds, changeJoinPublishDate, ServicesStore} from "@/views/CreateSession/store";
import {MultipleSelect} from "@/components/eshop/Select/MultipleSelect";
import {ToggleButton} from "@/components/eshop/ToggleButton/ToggleButton";
import Auth from "@/api/Auth";
import {RemoveButton} from "@/components/eshop/IconButton/IconButtons";
import {DatePicker, DateTimePickerLib} from "@/components/primitive";
import {FieldSetWrapper} from "@/components/eshop/FieldSetWrapper/FieldSetWrapper";
import { addHours } from 'date-fns';

interface JointSessionCardProps {
    store: ServicesStore
    canChange: boolean
}

type SessionItemsProps = {
    item: string
    disabled?: boolean
    toggled?: boolean
} & ({
        canBeDeleted: true
        deleteFn: (value: string) => void
    } | {
        canBeDeleted?: false
    });

const SessionItem = (props: SessionItemsProps) => {

    return (
        <div className={styles.jointSessions__sessionsItem}>
            <div>{props.item}</div>
            <div style={{display: "flex", alignItems: "center"}}>
                <ToggleButton toggled={props.toggled} disabled={props.disabled} />
                {props.canBeDeleted ? <RemoveButton onClick={() => props.deleteFn(props.item)} /> : <div style={{width: 40, height: 40}}></div>}
            </div>
        </div>
    );
};

export const JointSessionsCard = ({store}: JointSessionCardProps) => {
    const defaultCustomersOptions = Auth.currentUserInfo?.allowedParticipants.filter(i => i.id !== Auth.currentUserInfo?.selectedParticipant.id).map(i => ({value: i.id.toString(), name: i.fullName, key: i.id.toString()}));
    const [customersOptions, setCustomersOptions] = useState<{ value: string; name: string; key: string }[]>(defaultCustomersOptions?.length ? defaultCustomersOptions : []);
    const [showOtherCustomer, setShowOtherCustomer] = useState(false);
    const [jointSessionsPageSize, setJointSessionsPageSize] = useState<number>(10);
    const status = store.currentSession?.status;

    const handleRemoveJoinCustomers = (customerId: string) => {
        const currIds = store.joinCustomersIds;
        changeJoinCustomersIds(currIds.filter(i => i !== customerId).map(i => i));
    };

    return (
        <Section title={"Совместные котировочные сессии"} canBeHidden required>
            <div className={styles.innerCardsWrapper_vertical}>
                <Card direction={"vertical"} padded>
                    <div style={{marginBottom: 16}}>
                        <Checkbox value={showOtherCustomer} caption={"Показать иных заказчиков Москвы"} onChange={() => setShowOtherCustomer(!showOtherCustomer)} bold />
                    </div>
                    <div style={{display: "flex", alignItems: "center", justifyContent: "space-between"}}>
                        <MultipleSelect values={store.joinCustomersIds.map(i => i)}
                                        label={"Ведомственные заказчики в рамках ГРБС"}
                                        onChange={value => changeJoinCustomersIds(value)}
                                        placeholder={"Выбрать заказчика по наименованию/ИНН"}
                                        options={customersOptions} width={"49%"} />
                        {showOtherCustomer && <MultipleSelect values={store.joinCustomersIds.map(i => i)}
                                                              label={"Иные заказчики Москвы"}
                                                              onChange={value => changeJoinCustomersIds(value)}
                                                              placeholder={"Выбрать заказчика по наименованию/ИНН"}
                                                              options={customersOptions} width={"49%"} />}
                    </div>
                </Card>
                <Card direction={"vertical"}>
                    <div className={styles.jointSessions__sessionsItems}>
                        {!store.joinCustomers.length && <SessionItem item={Auth.currentUserInfo?.selectedParticipant.shortName ?? "Ошибка получения выбранной организации"}
                                      canBeDeleted={false}
                                      disabled
                                      toggled={status === "WAIT_PUBLICATION" || status === "PUBLISHED" || status === "SUPPLIER_FOUND" || status === "CONTRACT_CREATED"} />
                        }
                        {!store.joinCustomers.length && store.joinCustomersIds.map((customerId, index) => {
                            return (
                                <SessionItem canBeDeleted
                                             key={index}
                                             item={customersOptions.find(i => i.key === customerId)?.name ?? ""}
                                             deleteFn={() => handleRemoveJoinCustomers(customerId)} disabled />
                            );
                        })}
                        {store.joinCustomers.length > 0 && store.joinCustomers.map((customer, index) => {
                            return (
                                <SessionItem canBeDeleted={customer.id !== Auth.currentUserInfo?.selectedParticipant.id}
                                             key={index}
                                             item={customer.fullName ?? ""}
                                             toggled={!!store.includedSessions.find(i => i.participant.id === customer.id)}
                                             deleteFn={() => {}} disabled />
                            );
                        })}
                    </div>
                    <div className={styles.jointSessions__pagerSection}>
                        <Pager page={{from: 0, to: 1, totalCount: 1, items: [1, 2]}} goTo={() => {}} pageSize={jointSessionsPageSize} changePageSize={v => setJointSessionsPageSize(v)} />
                        <FieldSetWrapper required label={"Планируемая дата и время запуска совместной КС "}>
                            <DateTimePickerLib value={store.joinPublishDate}
                                               onChange={v => changeJoinPublishDate(v)} />
                        </FieldSetWrapper>
                    </div>
                </Card>
            </div>
        </Section>
    );
};
