// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, "._5d593f8NIwtPSGfwyym12 {\n    display: grid;\n    grid-template-columns: 300px 1fr;\n}\n\n._1UcnLy8J9X8Ffs0ZwIUzuf {\n    font-size: 20px;\n    font-family: robotoregular, sans-serif;\n    font-weight: 400;\n    text-overflow: ellipsis;\n    width: 99%;\n\n    margin-bottom: 15px;\n}\n\n._2lydz276qnhkAPWilNX-L9 {\n    display: flex;\n    flex-flow: column;\n\n    height: 400px;\n    overflow-y: auto;\n}\n\n._1dCNCyAs6iCd64AVkOQ8Yb {\n    background-color: #f7f8fa;\n    border-right: 1px solid #dadfea;\n    padding: 10px;\n}\n\n._10WW972okqVBSkNd1C5cML {\n    color: rgb(110, 124, 144);\n    font-size: 12px;\n    padding: 0 4px;\n    cursor: pointer;\n    border-radius: 1px;\n    margin-bottom: 10px;\n    position: relative;\n    transition: 0.3s all;\n    border: 1px solid transparent;\n}\n\n._10WW972okqVBSkNd1C5cML:hover:not(._1Qq-xZjP0Qwo6Cq7BxLdHF) {\n    box-sizing: border-box;\n    border: 1px solid #7C89A7;\n    color: #000;\n    text-decoration: none;\n}\n\n._1Qq-xZjP0Qwo6Cq7BxLdHF {\n    background-color: #d5dae6;\n    border-color: #aaaeb7 #c7cbd7 #d5dae6;\n    color: #000;\n    cursor: default;\n}\n", ""]);
// Exports
exports.locals = {
	"layout": "_5d593f8NIwtPSGfwyym12",
	"title": "_1UcnLy8J9X8Ffs0ZwIUzuf",
	"questions": "_2lydz276qnhkAPWilNX-L9",
	"sidebar": "_1dCNCyAs6iCd64AVkOQ8Yb",
	"question": "_10WW972okqVBSkNd1C5cML",
	"activeQuestion": "_1Qq-xZjP0Qwo6Cq7BxLdHF"
};
module.exports = exports;
