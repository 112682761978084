import React from "react";
import {Card} from "@/components/Card";
import {Table} from "@/components/table";
import {Label} from "@/components/primitive";

export const CustomersIntegration: React.FC = x => {
    return <Card>
        <Table<{}> dataset={[{}]} columns={[
            Table.AutoColumn("Внешние системы", x =>
                <Label>

                </Label>),
            Table.AutoColumn("Внешние системы", x =>
                <Label>

                </Label>),
            Table.AutoColumn("Закон-основание", x =>
                <Label>

                </Label>),
            Table.AutoColumn("Множественность организаций заказчика", x =>
                <Label>

                </Label>),
            Table.AutoColumn("Тип подключения", x =>
                <Label>

                </Label>)
        ]} />
    </Card>;
};
