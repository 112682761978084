import {AutoDto, AutoModel, uuid} from "@/models/parsing";
import {ParticipantStatus} from "@/models/enums";

export interface CustomerMD {
    address: string
    fullName: string
    id: uuid
    isCustomer: boolean
    isExpert: boolean
    isSmall: boolean
    isSocial: boolean
    isSpecial: boolean
    isSupplier: boolean
    parentOrganization: string
    regNumber: string
    rrc: string
    shortName: string
    spzCode: string
    status: ParticipantStatus
    tin: string
}

export type DtoCustomer = AutoDto<CustomerMD>;
export type Customer = AutoModel<CustomerMD>;

export const Customer = {
    toDto: (info: Customer) => ({
        ...info
    }),
    fromDto: (info: DtoCustomer) => ({
        ...info
    })
};
