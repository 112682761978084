// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, "._2EzUiAPYVodB0L1QP_BUbu {\n    display: flex;\n    min-height: 54px;\n    margin: 5px 0;\n    flex-direction: column;\n    font-family: \"Roboto\", sans-serif;\n}\n\n._2iduqlnAp6QuNlzo3oDt2o {\n    border: none;\n    margin-inline-start: 0;\n    margin-inline-end: 0;\n    padding-block-start: 0;\n    padding-inline-start: 0;\n    padding-inline-end: 0;\n    padding-block-end: 0;\n    min-inline-size: 0;\n    display: flex;\n    align-items: center;\n    justify-content: space-between;\n}\n\n.FRWNBOHIG-a6axjL-weAR {\n    color: red;\n}\n\n.sOyUHigRzkPFn2AZvPfwg {\n    border-radius: 5px;\n    border: 1px solid #adadad;\n    padding: 0 10px 5px;\n    margin: 0 10px;\n}\n\n._1Ct1v3xRf8IY7vrXdIXc6k {\n    color: #999999;\n    display: flex;\n    align-items: center;\n    font-size: 14px;\n    margin: 0;\n    padding-left: 5px;\n}\n\n._1Xy5qRU4kSVTm5NvSbwUdS {\n    margin: 0 10px;\n}\n\n._33xts_9mp9eXrwURs2hXnE {\n    cursor: pointer;\n    color: #4db2ff;\n}\n\n._33xts_9mp9eXrwURs2hXnE:hover {\n    color: #3d8ecc;\n}\n\n._1sjz4M7dCsNUjcKB7CSiAM {\n    border: none;\n    font-size: 16px;\n    background-color: transparent;\n    padding: 5px 0;\n    margin-left: 5px;\n}\n", ""]);
// Exports
exports.locals = {
	"fieldsetText__wrapper": "_2EzUiAPYVodB0L1QP_BUbu",
	"fieldsetText__fieldset": "_2iduqlnAp6QuNlzo3oDt2o",
	"required": "FRWNBOHIG-a6axjL-weAR",
	"fieldsetText__fieldset_bordered": "sOyUHigRzkPFn2AZvPfwg",
	"fieldsetText__label": "_1Ct1v3xRf8IY7vrXdIXc6k",
	"fieldsetText__label_bordered": "_1Xy5qRU4kSVTm5NvSbwUdS",
	"fieldsetText_clickable": "_33xts_9mp9eXrwURs2hXnE",
	"fieldsetText__field": "_1sjz4M7dCsNUjcKB7CSiAM"
};
module.exports = exports;
