import React from "react";
import {useMappedStore} from "@/storeUtils";
import {Card} from "@/components/Card";
import {VStack} from "@/components/layouts";
import {Button, Label} from "@/components/primitive";
import {Column, Inset, Table} from "@/components/table/Table";
import * as styles from "./PlannedPaymentsSection.module.css";
import {formatNumber} from "@/NumberFormatting";
import {Note} from "@/components/primitive/Note";
import {SectionProps} from "@/views/ComposedLot/shared/store";
import {
    addPlannedPaymentInteractively,
    deletePlannedPayment,
    editPlannedPayment,
    getTotalPaymentAmount,
    setPlannedPaymentAmount
} from "@/views/ComposedLot/shared/store/plannedPayments";
import {LotPlannedPayment} from "@/models/ComposedLots/LotPlannedPayment";
import {FinancialLimit, FinancialSourceTypeStrings} from "@/models/ComposedLots/FinancialLimit";
import {FComboBox, FDecimalBox} from "@/components/form";
import {LawType} from "@/models/enums/LawType";
import { getTotalStartPricesAmount } from "@/views/ComposedLot/shared/store/specifications";
import {isAggregatedLot} from "@/miscUtils";

export const PlannedPaymentsSection: React.FC<SectionProps> = x => {
    const {sources} = useMappedStore(x.formStore, x => x.plannedPayments);
    const [
        providerSelectionType,
        isCreating,
        singleProviderReasonId,
        law
    ] = useMappedStore(x.formStore, x => [
        x.info.providerSelectionType,
        !x.head.id,
        x.info.singleProviderReasonId,
        x.info.law
    ]);
    const specifications = useMappedStore(x.formStore, x => x.specifications);
    const sorted = [...sources].sort((x, y) => {
        if (x.source.year === y.source.year)
            return x.source.id - y.source.id;
        return x.source.year - y.source.year;
    });

    const viewing = x.formAction === "viewing";

    const insets: Inset[] = [];
    if (sorted.length) {
        let lastYear = 0;

        for (let i = 0; i < sorted.length; i++) {
            if (lastYear !== sorted[i].source.year) {
                insets.push(Table.InsetRow(i, Table.Inset(
                    <div className={styles.yearInset}>{sorted[i].source.year} год</div>, "1 / -1")));
                lastYear = sorted[i].source.year;
            }
        }
    }

    const isAggregatedAndSaved = isAggregatedLot(law, providerSelectionType, singleProviderReasonId) && !isCreating;
    
    const total = getTotalStartPricesAmount(specifications.specificationForms);

    return <Card>
        <VStack spacing="15px">
            <Note preset="white">
                <VStack spacing="3px">
                    <Label preset="readonlyValue">
                        {'Общая сумма закупки: '}
                        <b>
                            {formatNumber(getTotalPaymentAmount(sources))}
                            {' Российский рубль'}
                        </b>
                    </Label>
                    {x.formAction !== "creating" && <Label preset="readonlyValue">
                        {'НМЦ: '}
                        <b>
                            {isAggregatedAndSaved
                                ? formatNumber(getTotalPaymentAmount(sources))
                                : formatNumber(total)}
                            {' Российский рубль'}
                        </b>
                    </Label>}
                </VStack>
            </Note>
            {(!getTotalPaymentAmount(sources).equals(total) && !isCreating && !isAggregatedLot(law, providerSelectionType, singleProviderReasonId)) &&
                <Label preset="readonlyValue" style={{color: '#f22c27'}}>
                    {`Разница между общей суммой закупки и НМЦ составляет ${formatNumber(getTotalPaymentAmount(sources).minus(total))}` }
                </Label>
            }
            {
                sorted.length > 0 &&
                    <Table<LotPlannedPayment> dataset={sorted} insets={insets} columns={[
                        Table.AutoColumn("Тип", x =>
                            <Label preset="readonlyValue">
                                {FinancialSourceTypeStrings[x.item.source.type]}
                            </Label>),
                        Table.Column("Код", x =>
                            <Label preset="readonlyValue">
                                <b>
                                    {FinancialLimit.format(x.item.source)}
                                </b>
                            </Label>),
                        Table.Column(<>Лимит, руб.<br/>Всего /<br/>Остаток</>, x =>
                            <Label preset="readonlyValue">
                                <b>
                                    {formatNumber(x.item.source.price)}
                                    <br/>
                                    {formatNumber(x.item.source.price.sub(x.item.source.plannedAmount))}
                                </b>
                            </Label>),
                        Table.Column("Сумма, руб.", x =>
                            <FDecimalBox value={x.item.amount}
                                         onChange={v => setPlannedPaymentAmount({ item: x.item, amount: v})}/>,
                            { width: "2fr"}),
                        Table.Column("Титул АИП", () =>
                            <FComboBox options={[]} />),
                        Table.Column(<>Лимит по титулу, руб.<br/>Всего /<br/>Остаток</>, () =>
                            <></>),
                        ...(!viewing ? [
                            Table.AutoColumn("", x =>
                                <Button onClick={() => editPlannedPayment(x.item)} icon="aEdit"/>),
                            Table.AutoColumn("", x =>
                                <div style={{ color: "#9eafba", fontSize: "16pt", cursor: "pointer" }}
                                     onClick={() => deletePlannedPayment(x.item)}>
                                    x
                                </div>)] as Column<LotPlannedPayment>[] : [])
                    ]}/>
            }
            { !viewing && <Button color="white" title="добавить" style={{alignSelf: "start"}}
                onClick={() => addPlannedPaymentInteractively(LawType.F44)}/> }
        </VStack>
    </Card>;
};
