import { Component, Prop, Vue } from "vue-property-decorator";
import { IRow, GenericRow, ValueRow, MutatingRow } from "./FormFields";
import { mixins } from 'vue-class-component';

export const enum FieldState {
    ENABLED = "ENABLED",
    DISABLED = "DISABLED",
    READONLY = "READONLY",
}

export declare interface IFieldProps<TRow> {
    row: TRow
    source: TRow extends IRow<infer Src> ? Src : unknown
    bus: Vue
    state: FieldState
}

@Component
export class FieldBase<TRow extends IRow<unknown>> extends Vue {
    @Prop() protected props!: IFieldProps<TRow>;
    protected get row(): TRow {
        return this.props.row;
    }
    protected get source(): TRow extends IRow<infer T> ? T : unknown {
        return this.props.source;
    }
    protected get bus(): Vue {
        return this.props.bus;
    }
}

@Component
export class GenericFieldBase<TRow extends IRow<unknown> & GenericRow<unknown>> extends FieldBase<TRow> {
    protected get readonly(): boolean {
        const row = this.props.row;
        return this.props.state === "READONLY" || !(typeof row.editable === "function" ? row.editable(this.source) : (row.editable === undefined ? true : row.editable));
    }

    protected get enabled(): boolean {
        return this.props.state === "ENABLED";
    }

    protected get fieldDescription(): string | null {
        switch (typeof this.row.fieldDescription) {
            case "function":
                return this.row.fieldDescription(this.source);
            case "string":
                return this.row.fieldDescription;
            default:
                return null;
        }
    }
}

@Component
export class ValueFieldBase<TRow extends IRow<unknown> & ValueRow<unknown> & GenericRow<unknown>> extends GenericFieldBase<TRow> {
    protected get fieldValue(): unknown {
        return this.row.getter(this.source);
    }
}

@Component
export class MutatingFieldBase<TRow extends IRow<unknown> & MutatingRow<unknown> & GenericRow<unknown>> extends ValueFieldBase<TRow> {
    protected get enabled(): boolean {
        return this.props.state === "ENABLED" && !this.isRowReadOnly();
    }

    private isRowReadOnly() {
        switch (typeof this.row.readonly) {
            case "function":
                return this.row.readonly(this.source);
            case "boolean":
                return this.row.readonly;
            default:
                return false;
        }
    }
}
