




import { FComponent } from "@/components/next/FComponent";
import { Component, Prop, Mixins } from "vue-property-decorator";
import { IHint } from "@/components/vue/form-table/FormFields";
import EventBus from "@/EventBus";

@Component
export default class FHint extends Mixins(FComponent) {
    @Prop({ default: "" }) public text!: string;
    @Prop() public link?: { title: string; href: string };

    public showHint(e: MouseEvent) {
        EventBus.$emit("show-hint", { text: this.text, link: this.link }, e);
    }
}
