import React, {useContext} from "react";
import {SectionProps} from "@/views/ComposedLot/shared/store";
import {FCheckBox, FormStateContext} from "@/components/form";
import {useMappedStore} from "@/storeUtils";
import {Card} from "@/components/Card";
import {Field, Fields, VertField} from "@/components/form/Field";
import {Label} from "@/components/primitive";
import {getTotalPaymentAmount} from "@/views/ComposedLot/shared/store/plannedPayments";

export const PublicDiscussionSection: React.FC<SectionProps> = x => {
    const state = useContext(FormStateContext);
    const orientation = state === "readonly" ? "vertical" : "horizontal";

    const [payments, psType] =
        useMappedStore(x.formStore, x => [x.plannedPayments.sources, x.info.providerSelectionType]);

    const limitReached = getTotalPaymentAmount(payments).gt(1_000_000_000);
    const singleProvider = psType === "SINGLE_PROVIDER_PURCHASE";
    const required = limitReached && !singleProvider;

    return <Card>
        <Fields orientation={orientation}>
            <VertField title="Обязательность общественного обсуждения">
                <Label preset="readonlyValue">
                    {required ? "Да" : "Нет"}
                </Label>
            </VertField>
        </Fields>
    </Card>;
};