import React from "react";
import styles from "./RadioButton.module.css";
import {j} from "@/reactUtils";

interface RadioButtonProps {
    value?: string
    onChange?: (e: React.ChangeEvent<HTMLInputElement>) => void
    disabled?: boolean
    label?: string
    checked?: boolean
    id: string
}

export const RadioButton = (props: RadioButtonProps) => {
    return (
        <label htmlFor={props.id} className={styles.radio__wrapper}>
            <input type="radio"
                   value={props.value}
                   onChange={props.onChange}
                   disabled={props.disabled}
                   checked={props.checked}
                   id={props.id} className={styles.radio__input} />
            <div className={styles.radio__fakeInput__wrapper}>
                <div className={j(styles.radio__fakeInput, props.checked ? styles.radio__fakeInput__mark_checked : styles.radio__fakeInput__mark)} />
            </div>
                {props.label}
        </label>
    );
};
