import React, { useState } from "react";
import { ModalFC, showModal } from "@/EventBus";
import { ContractSubcontractor } from "@/models/Contracts";
import { Expandee, HStack } from "@/components/layouts";
import { Button, DatePicker, DecimalBox, TextBox } from "@/components/primitive";
import { CardModal } from "@/components/modals/CardModal";
import { Field, Fields } from "@/components/form/Field";
import { ParticipantSelectionModal } from "@/views/Contracts/modals/ParticipantSelectionModal/ParticipantSelectionModal";
import { Participant } from "@/models";
import { SubcontractSpecification } from "@/models/Contracts/SubcontractSpecification";
import { Table } from "@/components/table";
import { formatNumber } from "@/NumberFormatting";
import {
    EditSubcontractorsSpecifications,
    mapTaxPercentToString
} from "@/views/Contracts/modals/ParticipantSelectionModal/EditSubcontractorsSpecifications";

export const AddEditSubcontractorModal: ModalFC<{ subcontractor?: ContractSubcontractor; participant?: Participant },
    { contractSubcontractor: ContractSubcontractor; subcontractor: Participant }> = x => {
    const [allowSave, setAllowSave] = useState(false);
    const [contractSubcontractor, setContractSubcontractor] =
        useState<Partial<ContractSubcontractor>>(x?.participant ? x.subcontractor! : {});
    const [participant, setParticipant] = useState<Participant | undefined>(x.participant);
    const [editedSubcontractorsSpecifications, setEditedSubcontractorsSpecifications] =
        useState<SubcontractSpecification[] | undefined>(x.subcontractor?.specifications);

    const isEdit = Boolean(x?.participant);

    const changeSubcontractor = (key: keyof ContractSubcontractor) =>
        (v?: ContractSubcontractor[keyof ContractSubcontractor]) => {
            setAllowSave(true);
            setContractSubcontractor(s => ({ ...s, [key]: v } as ContractSubcontractor));
        };

    const validateRequiredFields =
        ({ subcontractor, amount, conclusionDate, contractSubject }: Partial<ContractSubcontractor>) =>
            Boolean(subcontractor && amount && conclusionDate && contractSubject);

    const selectParticipant = async () => await showModal(ParticipantSelectionModal);

    const bottom = <HStack spacing={"10px"}>
        <Expandee />
        <Button onClick={() => x.done({
            contractSubcontractor: Object.assign(contractSubcontractor, {
                specifications: editedSubcontractorsSpecifications ?? contractSubcontractor.specifications
            }) as ContractSubcontractor,
            subcontractor: participant as Participant
        })} icon="aSave" color="blue" disabled={!(validateRequiredFields(contractSubcontractor) && allowSave)} />
        <Button onClick={() => x.done(undefined)} icon="aClose" color="red" />
    </HStack>;

    return <CardModal title="Субподрядчики"
                      close={() => x.done(undefined)}
                      width="50%"
                      bottom={bottom}>
        <Fields orientation="horizontal">
            <Field title="Поставщик" required>
                <div style={{ display: "flex" }}>
                    <TextBox value={participant?.fullName}
                             onClick={async () => {
                                 const result = await selectParticipant();
                                 setParticipant(result);
                                 changeSubcontractor("subcontractor")(result);
                             }}
                             style={{ marginRight: "5px" }}
                             readonly />
                    <Button onClick={async () => {
                        const result = await selectParticipant();
                        setParticipant(result);
                        changeSubcontractor("subcontractor")(result);
                    }} icon="faSearch" disabled={isEdit}  />
                </div>
            </Field>
            <Field title="Сумма" required>
                <DecimalBox value={contractSubcontractor?.amount}
                            onChange={changeSubcontractor("amount")} />
            </Field>
            <Field title="Дата заключения" required>
                <DatePicker value={contractSubcontractor?.conclusionDate}
                            onChange={changeSubcontractor("conclusionDate")} />
            </Field>
            <Field title="Номер договора">
                <TextBox value={contractSubcontractor?.contractNumber}
                         onChange={changeSubcontractor("contractNumber")} />
            </Field>
            <Field title="Предмет договора" required>
                <TextBox value={contractSubcontractor?.contractSubject}
                         onChange={changeSubcontractor("contractSubject")}
                         lines={8} />
            </Field>
        </Fields>
        <div>
            <div style={{ fontSize: "20px", marginTop: "15px" }}>Спецификаии субподрядного договора</div>
            <Button title="Внести изменения" color="green"
                    onClick={async () => {
                        setAllowSave(true);
                        setEditedSubcontractorsSpecifications(
                            await showModal(
                                EditSubcontractorsSpecifications,
                                { specifications: editedSubcontractorsSpecifications }
                            )
                        );}}
                    style={{ margin: "15px 0 10px" }} />
            <div>
                <Table<SubcontractSpecification> dataset={editedSubcontractorsSpecifications ?? []} columns={[
                    Table.AutoColumn(
                        "№",
                        c => <>{c.rowIndex + 1}</>
                    ),
                    Table.Column(
                        "СПГЗ",
                        c => <>{c.item.specification.specification.subjectDeclaration?.subject ?? ""}</>
                    ),
                    Table.Column(
                        "ОКПД2",
                        c => <>{c.item.specification.specification.subjectDeclaration?.okpd2.name ?? ""}</>
                    ),
                    Table.Column(
                        "Объем субподряда",
                        c => <>{formatNumber(c.item.volume)}</>
                    ),
                    Table.Column(
                        "Сумма субподряда",
                        c => <>{formatNumber(c.item.cost)}</>
                    ),
                    Table.Column(
                        "Цена за единицу, руб.",
                        c => <>{formatNumber(c.item.cost
                            .div(c.item.volume)
                            .mul(100 + ~~mapTaxPercentToString[c.item.tax])
                            .div(100))}
                        </>
                    ),
                    Table.Column(
                        "Цена за единицу без НДС, руб.",
                        c => <>{formatNumber(c.item.cost.div(c.item.volume))}</>
                    ),
                    Table.Column(
                        "Ставка НДС",
                        c => <>{mapTaxPercentToString[c.item.tax]}%</>
                    ),
                ]} />
            </div>
        </div>
    </CardModal>;
};
