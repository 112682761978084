import { Component, Prop, Vue, Watch } from "vue-property-decorator";
import CardModal from "@/components/CardModal.vue";
import TreeView from "@/components/TreeView.vue";
import FormTable from "@/components/vue/FormTable.vue";
import { ITableColumn, SingleSelection, IRowConfiguration, SelectionColumnControl } from "@/components/TableAbstractions";
import { ModalVue, ModalResult } from '@/view-models/ModalRequest';
import { Participant } from '@/models';
import Participants from '@/api/Participants';
import { PaginationContext, createPaginationContext } from '@/api/Pagination';
import { FilterValueType, FilterConditionType } from '@/api/http';

@Component({ components: { CardModal, TreeView, FormTable } })
export default class SelectExpert extends ModalVue<null, Participant> {
    public participantHeaders: ITableColumn<Participant>[] = [
        {
            title: "Название организации",
            getter(p) { return p.shortName }
        },
        {
            title: "ИНН",
            getter(p) { return p.tin },
            size: "auto"
        },
        {
            title: "КПП",
            getter(p) { return p.rrc },
            size: "auto"
        },
    ];

    public context: PaginationContext<Participant> | null = null;

    public selection: SingleSelection<Participant> = { tag: "SINGLE", item: null };
    public config: IRowConfiguration<Participant> = {
        selection: {
            showSelectionColumn: true,
            selectionColumnControl: SelectionColumnControl.CHECKBOX,
            background: "#ccc"
        }
    };

    public readonly tinFilter = {
        type: FilterValueType.STRING,
        conditionType: FilterConditionType.CONTAINS,
        string: "",
    };

    @Watch("tinFilter.string") public reload() {
        if (this.context) this.context.load(0);
    }

    public async created() {
        this.context = await createPaginationContext((n, sz, f) => Participants.get(n, sz, f), 15, {
            isExpert: [{
                type: FilterValueType.BOOLEAN,
                conditionType: FilterConditionType.EQUAL,
                boolean: true,
            }],
            tinOrName: [this.tinFilter]
        });
    }

    public select() {
        if (this.selection.item)
            this.$emit("close-modal", ModalResult.ok(this.selection.item));
    }
}
