import React from "react";

export const WizardFinishTab: React.FC<{ ready: boolean }> = x => {
    return <div>
        {
            x.ready
                ? <div>Вы заполнили все вкладки. Сформируйте шаблон.</div>
                : <div>Для формирования заполните все вкладки.<br />Вы ещё не заполнили все обязательные редактируемые области.</div>
        }
    </div>;
};
