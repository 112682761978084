import {asDate, asDecimal, AutoDto, AutoModel, MDMap} from "@/models/parsing";

import Decimal from "decimal.js";
import {UnionStrings} from "@/models/enums";
import {
    DtoSpecification,
    DtoSpecificationTemplate, DtoSupplyStage, Specification,
    SpecificationTemplate, SupplyStage
} from "@/models/ComposedLots/specifications";
import {DtoLotFinancialSourceUsage, LotFinancialSourceUsage} from "@/models/ComposedLots/LotFinancialSourceUsage";

export interface DtoPaymentStage {
    supplyStage: DtoSupplyStage
    specifications?: DtoSpecification[]
    paymentDateOffset?: number
    paymentDate?: string
    financialSourceUsage: DtoLotFinancialSourceUsage[]
}

export interface PaymentStage {
    supplyStage: SupplyStage
    specifications?: Specification[]
    paymentDateOffset?: number
    paymentDate?: Date
    financialSourceUsage: LotFinancialSourceUsage[]
}

export interface PaymentStageTemplateMD {
    supplyStage: MDMap<SupplyStage, DtoSupplyStage>
    specificationIds?: string[]
    paymentDateOffset?: number
    paymentDate?: Date
    financialSourceUsage: MDMap<LotFinancialSourceUsage, DtoLotFinancialSourceUsage>[]
}

export type DtoPaymentStageTemplate = AutoDto<PaymentStageTemplateMD>;
export type PaymentStageTemplate = AutoModel<PaymentStageTemplateMD>;

export const PaymentStageTemplate = {
    toDto(info: PaymentStageTemplate): DtoPaymentStageTemplate {
        return {
            ...info,
            specificationIds: info.specificationIds,
            supplyStage: SupplyStage.toDto(info.supplyStage),
            paymentDate: info.paymentDate?.toISOString(),
            financialSourceUsage: info.financialSourceUsage.map(LotFinancialSourceUsage.toDto)
        };
    },
    fromDto(stage: DtoPaymentStageTemplate): PaymentStageTemplate {
        return {
            paymentDate: asDate(stage.paymentDate),
            specificationIds: stage.specificationIds ?? undefined,
            supplyStage: SupplyStage.fromDto(stage.supplyStage),
            paymentDateOffset: stage.paymentDateOffset ?? undefined,
            financialSourceUsage: stage.financialSourceUsage.map(LotFinancialSourceUsage.fromDto)
        };
    }
};
export const PaymentStage = {
    toDto(stage: PaymentStage): DtoPaymentStage {
        return {
            paymentDate: stage.paymentDate?.toISOString(),
            specifications: stage.specifications ? stage.specifications.map(Specification.toDto) : undefined,
            supplyStage: SupplyStage.toDto(stage.supplyStage),
            paymentDateOffset: stage.paymentDateOffset,
            financialSourceUsage: stage.financialSourceUsage.map(LotFinancialSourceUsage.toDto)
        };
    },
    fromDto(stage: DtoPaymentStage): PaymentStage {
        return {
            paymentDate: asDate(stage.paymentDate),
            paymentDateOffset: stage.paymentDateOffset,
            specifications: stage.specifications ? stage.specifications.map(Specification.fromDto) : undefined,
            supplyStage: SupplyStage.fromDto(stage.supplyStage),
            financialSourceUsage: stage.financialSourceUsage.map(LotFinancialSourceUsage.fromDto)
        };
    },
    toTemplate(stage: PaymentStage): PaymentStageTemplate {
        return {
            specificationIds: stage.specifications?.map(v => v.id) ?? [],
            supplyStage: stage.supplyStage,
            paymentDate: stage.paymentDate,
            paymentDateOffset: stage.paymentDateOffset,
            financialSourceUsage: stage.financialSourceUsage
        };
    }
};

export type PaymentDateReports = | "FIVE_DAYS" | "FIVE_DAYS_BEFORE_END_MONTH" | "THIRTY_DAYS";

export const PaymentDateReportsStrings: UnionStrings<PaymentDateReports> = {
    FIVE_DAYS: "5 дней",
    FIVE_DAYS_BEFORE_END_MONTH: "5 дней перед концом месяца",
    THIRTY_DAYS: "30 дней"
};

export type PaymentDateConsiderationReports = | "THIRTY_DAYS" | "TWO_DAYS" | "ONE_DAY";

export const PaymentDateConsiderationReportsStrings: UnionStrings<PaymentDateConsiderationReports> = {
    THIRTY_DAYS: "30 рабочих дней",
    TWO_DAYS: "2 календарных дня",
    ONE_DAY: "1 рабочий день"
};

export type PaymentDueDate = | "TEN_DAYS" | "SEVEN_DAYS" | "TWO_DAYS";

export const PaymentDueDate: UnionStrings<PaymentDueDate> = {
    TEN_DAYS: "10 рабочих дней",
    SEVEN_DAYS: "7 рабочих дней",
    TWO_DAYS: "2 календарных дня"
};