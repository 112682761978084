


















import CardModal from "@/components/CardModal.vue";
import { ModalVue } from "@/view-models/ModalRequest";
import { Component, Prop } from "vue-property-decorator";
import { ApiError } from '../../../api/ApiError';

@Component({ components: { CardModal } })
export default class ErrorModal extends ModalVue<ApiError, null> {
    @Prop() private prop!: ApiError;
}
