import CardModal from "@/components/CardModal.vue";
import DataTable from "@/components/vue/DataTable.vue";
import { ModalVue } from "@/view-models/ModalRequest";
import { RelatedModalTable } from "@/views/FormAbstractions";
import { Component, Prop } from "vue-property-decorator";
import EventBus from '@/EventBus';

@Component({ components: { CardModal, DataTable } })
export default class RelatedModal extends ModalVue<RelatedModalTable, void> {
    @Prop() private prop!: RelatedModalTable;

    public created() {
        EventBus.$on("close-related-objects-modal", this.closeModal);
    }

    public beforeDestroy() {
        EventBus.$off("close-related-objects-modal", this.closeModal);
    }

    public closeModal() {
        this.$emit("close-modal");
    }
}
