import { Currency } from '@/models/enums';
import Decimal from 'decimal.js';
import { Participant } from '@/models';
import { IJsonFormattable } from '../IJsonFormattable';
import { IDtoContractSubcontractor } from '@/models/json/Contracts';
import { SubcontractSpecification } from "@/models/Contracts/SubcontractSpecification";

export class ContractSubcontractor implements IJsonFormattable<IDtoContractSubcontractor> {
    constructor(
        public id: string,
        public subcontractor: Participant,
        public amount: Decimal,
        public currency: Currency,
        public conclusionDate: Date,
        public contractNumber: string,
        public contractSubject: string,
        public specifications: SubcontractSpecification[],
    ) {}

    public toJson(): IDtoContractSubcontractor {
        return {
            ...this,
            subcontractor: this.subcontractor.toJson(),
            amount: this.amount.toString(),
            conclusionDate: this.conclusionDate.toISOString(),
            specifications: this.specifications.map(x => x.toJson())
        };
    }

    public static fromJson(dto: IDtoContractSubcontractor): ContractSubcontractor {
        return Object.assign(Object.create(ContractSubcontractor.prototype), dto, {
            subcontractor: Participant.fromJson(dto.subcontractor),
            amount: new Decimal(dto.amount),
            conclusionDate: new Date(dto.conclusionDate),
            specifications: dto.specifications.map(s => SubcontractSpecification.fromJson(s))
        });
    }
}
