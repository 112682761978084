import React from "react";
import { Card } from "@/components/Card";
import { Column, Table } from "@/components/table";
import { HistoryItem } from "@/models";
import { formatDate } from "@/dateUtils";
import { Label } from "@/components/primitive";

type HistorySectionProps = {
    history: HistoryItem[]
};

export const HistorySection = ({ history }: HistorySectionProps) => {
    const columns: Column<HistoryItem>[] = [
        Table.Column(
            "Операция",
            ({ item }) => <>{item.message}</>,
            { width: "3fr", headerStyle: { textAlign: "center" }, cellStyle: { textAlign: "center" } }
        ),
        Table.Column(
            "Дата и время",
            ({ item }) => <>{formatDate(item.date)}</>,
            { width: "1fr", headerStyle: { textAlign: "center" }, }
        ),
        Table.Column(
            "Скачать XML",
            () => (
                <Label style={{ textDecoration: "underline", cursor: "pointer" }}>
                    report.pdf
                </Label>
            ),
            { width: "2fr", headerStyle: { textAlign: "center" }, cellStyle: { textAlign: "center" } }
        )
    ];

    return (
        <Card>
            <Table columns={columns} dataset={history}  />
        </Card>
    );
};
