// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, "\n._3bcj5a78nHJuugrny-sHVy{\n    height: 40px;\n    display: flex;\n    align-items: center;\n    justify-content: space-between;\n}\n\n._1BnlkUb1Zb8fL74upPp2Dc {\n    display: flex;\n    gap: 10px;\n    align-items: center;\n}\n\n._2E_XnyyPQJasSZXgnOr8cf {\n    display: flex;\n    user-select: none;\n    gap: 10px;\n    align-items: center;\n}\n\n._3tqaW_llf4vSSDkdmFpjjg {\n    display: flex;\n}\n\n.ncGsd7Dwb8mjLIBYn_M_2 {\n    display: flex;\n    gap: 15px;\n    align-items: center;\n}\n\n.GrqfWHL3xgKAuXQoXE5OA {\n    font-size: 13px;\n    color: black;\n    cursor: pointer;\n    flex: 1;\n}\n\n._3ruwLn1S7ll9JfoRUOoI{\n    font-size: 13px;\n    color: #d0d0d0;\n    cursor: pointer;\n    flex: 1;\n}\n\n.ibrNdToml_Jiq8xB416QX{\n    opacity: 0.3; cursor: default !important;\n}\n\n.DF2vWrRaeL7gNh6sL51MO{\n    width: 75px;\n}\n\n.oza9rz_-7kxle9kZzMC-V {\n    background: url(\"https://eaist2-f.mos.ru/eshop/img/icons.2939f7a5d1c8c55bf53b.png\") -9px -482px no-repeat;\n\n    background-repeat: no-repeat;\n    background-repeat: no-repeat;\n    width: 25px;\n    flex-basis: 25px;\n    height: 35px;\n    cursor: pointer;\n}\n\n._25bhBdqjLRCVY1vJwSFO3q {\n    background: url(\"https://eaist2-f.mos.ru/eshop/img/icons.2939f7a5d1c8c55bf53b.png\") -9px -482px no-repeat;\n}\n\n._1D4B2imN98LU3mRw7e59Wd {\n    background: url(\"https://eaist2-f.mos.ru/eshop/img/icons.2939f7a5d1c8c55bf53b.png\") -7px -402px no-repeat;\n}\n\n._2QnnKABMWLGo3Y1Eq6B6nR {\n    background: url(\"https://eaist2-f.mos.ru/eshop/img/icons.2939f7a5d1c8c55bf53b.png\") -45px -402px no-repeat;\n}\n\n._2Y5JLgtmq-SgekiF0JF18j {\n    background: url(\"https://eaist2-f.mos.ru/eshop/img/icons.2939f7a5d1c8c55bf53b.png\") -46px -482px no-repeat;\n}\n\n", ""]);
// Exports
exports.locals = {
	"offersPaginator": "_3bcj5a78nHJuugrny-sHVy",
	"offersPaginatorContent": "_1BnlkUb1Zb8fL74upPp2Dc",
	"buttons": "_2E_XnyyPQJasSZXgnOr8cf",
	"arrowsContainer": "_3tqaW_llf4vSSDkdmFpjjg",
	"numbersContainer": "ncGsd7Dwb8mjLIBYn_M_2",
	"number": "GrqfWHL3xgKAuXQoXE5OA",
	"numberSelected": "_3ruwLn1S7ll9JfoRUOoI",
	"blurred": "ibrNdToml_Jiq8xB416QX",
	"select": "DF2vWrRaeL7gNh6sL51MO",
	"arrowIcon": "oza9rz_-7kxle9kZzMC-V",
	"arrowFirst": "_25bhBdqjLRCVY1vJwSFO3q",
	"arrowPrev": "_1D4B2imN98LU3mRw7e59Wd",
	"arrowNext": "_2QnnKABMWLGo3Y1Eq6B6nR",
	"arrowLast": "_2Y5JLgtmq-SgekiF0JF18j"
};
module.exports = exports;
