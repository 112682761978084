import React from "react";
import styles from "./CardInnerWrapper.module.css";
import {j} from "@/reactUtils";

interface CardInnerWrapperProps {
    children?: React.ReactNode
    className?: string
}

export const CardInnerWrapper = (props: CardInnerWrapperProps) => {
    return (
        <div className={j(styles.card__inner__wrapper, props.className)}>
            {props.children}
        </div>
    );
};
