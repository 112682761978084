import React, {useEffect, useRef, useState} from "react";
import {Form, FormHeader} from "@/components/form";
import {UsersSection} from "@/views/Register/requests/UsersSection";
import {ArchivedUsersSection} from "@/views/Register/requests/ArchivedUsersSection";
import {RegistrationRequestsSection} from "@/views/Register/requests/RegistrationRequestsSection";
import {NewsAdminSection} from "@/views/Admin/NewsAdmin";
import {ScheduleWebinarSection, WebinarAdminSection} from "@/views/Admin/WebinarAdmin";
import {VStack} from "@/components/layouts";
import {LoginWizardSlideAdminSection} from "@/views/Admin/LoginWizardAdmin";
import {ChatAdminSection} from "@/views/Admin/ChatAdmin";
import {StreamKeySection} from "@/views/Admin/StreamKey";
import EventBus from "@/EventBus";
import {makeAutoObservable, runInAction} from "mobx";
import {observer} from "mobx-react";
import {Spinner} from "@/components/primitive/Spinner";
import {colors} from "@/components/primitive/RoundedButton.colors";
import {PrivateWebinarSection} from "@/views/Admin/WebinarPrivateAdmin";
import { SupplementaryMaterialsAdminSection } from "@/views/Admin/SupplementaryMaterialsAdmin";

const loader = <div style={{
    width: "100%",
    position: "absolute",
    height: "100%",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    zIndex: "2",
    backdropFilter: "blur(5px)"
}}>
    <Spinner color={colors.blue.idle.bg}/>
</div>;

class AdminPageViewModel {
    constructor() {
        makeAutoObservable(this);
    }

    init = () => {
        EventBus.$on("show-webinars-spinner", this.showSpinner);
    };

    spinnerVisible = false;
    spinnerPromises: Promise<unknown>[] = [];


    private showSpinner = (endPromise: Promise<unknown>) => {
        runInAction(() => { this.spinnerVisible = true });


        if (this.spinnerPromises.length === 0) {
            const intervalPromise = new Promise(resolve => setInterval(resolve, 100));
            this.spinnerPromises.push(intervalPromise);
            intervalPromise.finally(() => this.hideSpinner(intervalPromise));
        }

        this.spinnerPromises.push(endPromise);
        endPromise
            .catch(() => {})
            .finally(() => this.hideSpinner(endPromise));
    };

    private hideSpinner = (promise: Promise<unknown>) => {
        const ix = this.spinnerPromises.indexOf(promise);
        if (ix === -1) return;

        this.spinnerPromises.splice(ix, 1);
        if (this.spinnerPromises.length === 0) this.spinnerVisible = false;
    };

    dispose = () => {
        EventBus.$off("show-webinars-spinner", this.showSpinner);
    };
}

const Page: React.FC = observer(() => {
    const [reload, setReload] = useState(Symbol());
    const onReload = () => setReload(Symbol());

    const [vm, setVm] = useState<AdminPageViewModel>();
    useEffect(() => {
        const _vm = new AdminPageViewModel();
        setVm(_vm);
        _vm.init();
        return () => _vm.dispose();
    }, []);

    const header = <VStack spacing="15px">
        <FormHeader title="Администрирование" />
    </VStack>;

    const tabs = [
        Form.Tab("users", "Пользователи", [
            Form.Section("Активные пользователи", <UsersSection reload={reload} onReload={onReload} />),
            Form.Section("Архив", <ArchivedUsersSection reload={reload} onReload={onReload} />),
            Form.Section("Заявки на регистрацию", <RegistrationRequestsSection reload={reload} onReload={onReload} />),
        ]),
        Form.Tab("news", "Новости", [
            Form.Section("Новости", <NewsAdminSection key="news" />),
        ]),
        Form.Tab("supplementary_materials", "Дополнительные материалы", [
            Form.Section("Управление файлами", <SupplementaryMaterialsAdminSection key={"supplementary_materials"} />),
        ]),
        Form.Tab("webinars", "Вебинары", [
            Form.Section("Планирование", <ScheduleWebinarSection />),
            Form.Section("Ключ потока", <StreamKeySection type="public" channelLoc="00000000-0000-0000-0000-000000000000" />),
            Form.Section("Вебинары на главной", <WebinarAdminSection type="current" reload={reload} onReload={onReload}/>),
            Form.Section("Архив", <WebinarAdminSection type="archive" reload={reload} onReload={onReload} />)
        ]),
        Form.Tab("private", "Закрытые трансляции", [
            Form.Section("Планирование первого закрытого потока", <PrivateWebinarSection channelLoc="first" />),
            Form.Section("Планирование второго закрытого потока", <PrivateWebinarSection channelLoc="second" />),
        ]),
        Form.Tab("chat", "Чат", [
            Form.Section("Модерация чата трансляции", <ChatAdminSection />),
        ]),
        Form.Tab("wizard", "Слайдшоу", [
            Form.Section("Слайды", <LoginWizardSlideAdminSection />)
        ])
    ];

    return <>
        { vm?.spinnerVisible && loader }
        <Form tabs={tabs} header={header} />
    </>;
});

export const AdminPage = () => {
    return <Page />;
};