

























import { Component, Vue, Prop } from "vue-property-decorator";
import FormTable from "@/components/vue/FormTable.vue";
import CardModal from "@/components/CardModal.vue";
import {makeFormHelpers, pathfactory} from "@/form-declarations/helpers";
import { SimpleRow } from "@/components/vue/form-table/FormFields";
import { ContractSubcontractor } from '@/models/Contracts';
import { showModal } from '@/EventBus';
import DataTable from "@/components/vue/DataTable.vue";
import { IRowConfiguration, ITableColumn } from "@/components/TableAbstractions";
import { ContractEconomy } from "@/models/Contracts";
import { IFieldProps } from "@/components/vue/form-table/FieldBase";
import { ContractScope } from "@/views/CreateContract/ContractScope";
import { AddEditSubcontractorModal } from "@/views/Contracts/modals/AddEditSubcontractorModal";
import ContractSubcontractors from "@/api/ContractSubcontractors";
import { Currency } from "@/models/enums";
import { Participant } from "@/models";
import { ConfirmationModal } from "@/views/Contracts/modals/ConfirmationModal";
import {isConcludedEshopContract} from "@/form-declarations/contractStageActions";

const h = makeFormHelpers<ContractSubcontractor>();
const p = pathfactory<ContractSubcontractor>();

@Component({ components: { FormTable, CardModal, DataTable } })
export default class AddSubcontractor extends Vue {
    @Prop() public props!: IFieldProps<SimpleRow<ContractScope>>;

    public isActionsLocked = isConcludedEshopContract(this.props.source.contract);

    public get headers(): ITableColumn<ContractSubcontractor>[] {
        return [
            {
                title: "№",
                getter: (p, i) => (i + 1).toString(),
                size: "auto"
            },
            {
                title: "Поставщик",
                getter: p => p.subcontractor.fullName,
            },
            {
                title: "СМП",
                getter: p => p.subcontractor.isSmp ? "Да" : "Нет",
            },
            {
                title: "Сумма",
                getter: p => p.amount,
            }
        ];
    }

    public createConfig(): IRowConfiguration<ContractEconomy> {
        const that = this;

        return {
            get useSlotForActionColumn() { return !!that.props.state && !that.isActionsLocked }
        };
    }

    public config = this.createConfig();

    public get dataset() { return this.props.source.subcontractors }

    public async updateCreateSubcontractor(
        contractSubcontractor: ContractSubcontractor, subcontractor: Participant, type: "create" | "update"
    ) {
        const _contractSubcontractor = new ContractSubcontractor(
            contractSubcontractor.id,
            subcontractor,
            contractSubcontractor.amount,
            Currency.RUB,
            contractSubcontractor.conclusionDate,
            contractSubcontractor.contractNumber,
            contractSubcontractor.contractSubject,
            contractSubcontractor?.specifications ?? []
        );

        return type === "update"
            ? await ContractSubcontractors.update(_contractSubcontractor)
            : await ContractSubcontractors.create(this.props.source.contract.id, _contractSubcontractor);
    }

    public async addSubcontractor() {
        const result = await showModal(AddEditSubcontractorModal, {});

        if (!result) return;

        const addedContractSubcontractor =
            await this.updateCreateSubcontractor(result.contractSubcontractor, result.subcontractor, "create");

        this.dataset.push(addedContractSubcontractor);
    }

    public async editSubcontractor(subcontractor: ContractSubcontractor, participant: Participant) {
        const result = await showModal(AddEditSubcontractorModal, {
            subcontractor,
            participant
        });

        if (!result) return;

        const addedContractSubcontractor =
            await this.updateCreateSubcontractor(result.contractSubcontractor, result.subcontractor, "update");

        const oldParticipantIndex = this.dataset.findIndex(p => p.id === result.contractSubcontractor.id);
        this.$set(this.dataset, oldParticipantIndex, addedContractSubcontractor);
    }

    public async deleteSubcontractor(subcontractorId: string) {
        const confirmation = await showModal(ConfirmationModal, {
            title: "Удаление",
            text: "Вы действительно хотите удалить этого субподрядчика?"
        });

        if (!confirmation) return;

        await ContractSubcontractors.delete(subcontractorId);
        const ix = this.dataset.findIndex(sub => sub.id === subcontractorId);
        if (ix !== -1) this.dataset.splice(ix, 1);
    }
}
