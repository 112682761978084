import { CardModal } from "@/components/modals/CardModal";
import { Button, DecimalBox } from "@/components/primitive";
import {Column, SelectionTable, Table} from "@/components/table";
import { formatDate } from "@/dateUtils";
import { ModalFC } from "@/EventBus";
import { ContractDocument, ContractStageExecutionInfo } from "@/models/Contracts";
import Decimal from "decimal.js";
import React, { useEffect, useState } from "react";

type LinkWithSupplierExecutionProps = {
    executionInfo: ContractStageExecutionInfo[]
    executions?: ContractStageExecutionInfo[] | null
};

type LinkWithSupplierExecutionReturn = {
    selected: ContractStageExecutionInfo[] // тут не селектед
};

const PrimaryDocNumberCell: React.FC<{ exec: ContractStageExecutionInfo }> = x => {
    const doc = x.exec.documents.find(doc => doc.id === x.exec.primaryDocumentId);
    if (!doc) return <>-</>;
    return <>
        { doc.documentNumber }
    </>;
};

const PrimaryDocDateCell: React.FC<{ exec: ContractStageExecutionInfo }> = x => {
    const doc = x.exec.documents.find(doc => doc.id === x.exec.primaryDocumentId);
    if (!doc) return <>-</>;
    return <>
        { formatDate(doc.createdAt, "days") }
    </>;
};

const PrimaryDocSumCell: React.FC<{ exec: ContractStageExecutionInfo; execInfo: ContractStageExecutionInfo[] }> = x => {
    const doc = x.exec.documents.find(doc => doc.id === x.exec.primaryDocumentId);
    if (!doc) return <>-</>;

    const calculateSum = (docId: string) => {
        const executionSpec = x.execInfo.find(i => i.primaryDocumentId === docId)?.specifications;

        if(!executionSpec || !executionSpec.length) {
            return null;
        }
        return executionSpec.reduce((acc, spec) => acc.plus(spec.cost), new Decimal(0));
    };
    const result = calculateSum(doc.id);
    if(!result) return <>-</>;

    return <DecimalBox value={result} disabled precision={2}/>;
};

export const LinkWithSupplierExecution: ModalFC<LinkWithSupplierExecutionProps, LinkWithSupplierExecutionReturn> = x => {
    const [selected, setSelected] = useState<ContractStageExecutionInfo[]>(x.executions ?? []);

    const handleSave = () => {
        const resultExecInfo = x.executionInfo.filter(info => selected.some(doc => doc.id === info.primaryDocumentId));
        x.done({ selected });
    };

    const bottom = <Button icon="aSave" onClick={handleSave}/>;

    const columns: Column<ContractStageExecutionInfo>[] = [
        Table.Column("Дата документа", cell => <PrimaryDocDateCell exec={cell.item}/>),
        Table.Column("Номер документа", cell => <PrimaryDocNumberCell exec={cell.item} />),
        Table.Column("Сумма, руб", cell => <PrimaryDocSumCell exec={cell.item} execInfo={x.executionInfo}/>),
    ];

    return (
        <CardModal title={"Связь с исполнениями поставщиком"}
                          cardContentStyle={{ padding: 25 }}
                          cardHeaderStyle={{ backgroundColor: "white" }}
                          bottom={bottom}
                          close={() => x.done(undefined)}
                          width="800px">
            <SelectionTable
                dataset={x.executionInfo}
                mode="multi"
                onChange={setSelected}
                selected={x.executionInfo.filter(item => selected.some(i => i.id === item.id))}
                selectorHeader={<>Выбрать</>}
                columns={columns} />
        </CardModal>
    );
};
