import React from "react";
import {SectionProps} from "@/views/Procedures2020/view/store";
import {useMappedStore} from "@/storeUtils";
import {Card} from "@/components/Card";
import {VGrid, VStack} from "@/components/layouts";
import {Field, Fields} from "@/components/form/Field";
import {Label} from "@/components/primitive";

/** Сроки проведения */
export const TimeLimitationSection: React.FC<SectionProps> = x => {

    /*
        Дата публикации извещения на официальном сайте
        Приём заявок
        Дата и время окончания рассмотрения и оценки первых частей заявок
        Дата подачи окончательных предложений о цене контракта
        Дата и время окончания рассмотрения и оценки вторых частей заявок
    */

    const sectionStore = useMappedStore(x.formStore, x => ({
        publishTerms: x.timeLimitations.publishTerms,
        providerSelectionType: x.info.providerSelectionType
    }));

    const preciseFirstFinish = sectionStore.providerSelectionType === "E_EXAM";
    const needDocExplanation = sectionStore.providerSelectionType === "E_EXAM" || sectionStore.providerSelectionType === "E_AUC";
    const needFirstReq = sectionStore.providerSelectionType === "E_EXAM" || sectionStore.providerSelectionType === "E_AUC";
    const needSecondReq = sectionStore.providerSelectionType === "E_EXAM";
    const needFinalOffers = sectionStore.providerSelectionType === "E_EXAM";
    const needAuc = sectionStore.providerSelectionType === "E_AUC";

    const publishDateString = sectionStore.publishTerms?.publishDate.toLocaleDateString() ?? "";
    const documentExplanationRangeString = sectionStore.publishTerms
        ? `${sectionStore.publishTerms?.documentExplanationRangeFrom?.toLocaleDateString() ?? ""} до ${sectionStore.publishTerms?.documentExplanationRangeTo?.toLocaleDateString() ?? ""}`
        : "";
    const finalOffersDateString = sectionStore.publishTerms?.finalOffersDate?.toLocaleDateString() ?? "";
    const requestAcceptRangeString = sectionStore.publishTerms
        ? `с ${sectionStore.publishTerms?.requestAcceptRangeFrom?.toLocaleDateString() ?? ""} по ${sectionStore.publishTerms?.requestAcceptRangeTo?.toLocaleDateString() ?? ""}`
        : "";
    const firstRequestConsiderationDateString = `${sectionStore.publishTerms?.firstRequestConsiderationFinishDate?.toLocaleDateString() ?? ""}`;
    const secondRequestConsiderationDateString = `${sectionStore.publishTerms?.secondRequestConsiderationFinishDate?.toLocaleDateString() ?? ""}`;
    const executionDateString = sectionStore.publishTerms?.executionDate?.toLocaleDateString() ?? "";


    return <Card>
        <VStack spacing="15px">
           <VGrid columns="1fr 1fr">
               <Fields orientation="vertical">
                    <Field title="Дата публикации извещения на официальном сайте">
                        <Label text={publishDateString}/>
                    </Field>
                   {
                       needDocExplanation &&
                           <Field title="Период предоставления разъяснений положений документации">
                               <Label text={documentExplanationRangeString}/>
                           </Field>
                   }
                   {
                       needFinalOffers &&
                           <Field title="Дата подачи окончательных предложений о цене контракта">
                               <Label text={finalOffersDateString}/>
                           </Field>
                   }
                   {
                       needAuc &&
                           <Field title="Дата проведения электронного аукциона">
                               <Label text={executionDateString}/>
                           </Field>
                   }
               </Fields>
               <Fields orientation="vertical">
                   <Field title="Приём заявок">
                       <Label text={requestAcceptRangeString}/>
                   </Field>
                   {
                       needFirstReq &&
                       <Field title="Дата и время окончания рассмотрения и оценки первых частей заявок">
                           <Label text={firstRequestConsiderationDateString}/>
                       </Field>
                   }
                   {
                       needSecondReq &&
                       <Field title="Дата и время окончания рассмотрения и оценки вторых частей заявок">
                           <Label text={secondRequestConsiderationDateString}/>
                       </Field>
                   }
               </Fields>
           </VGrid>

        </VStack>
    </Card>;
};