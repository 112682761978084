




import {Component, Prop, Vue} from "vue-property-decorator";
import {CreatePlan} from './CreatePlan';
import {LawType} from "@/models/enums";
import {createPlansStore} from "@/views/Plans2020/create/store";

@Component({ components: { komponent: CreatePlan as unknown as typeof Vue } })
export default class CreatePlanVueStub extends Vue {
    @Prop() public law!: LawType;
    public storeContainer = Object.freeze({ store: createPlansStore(this.law) });
}
