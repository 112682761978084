import * as primitives from "../primitive";
import React, {ComponentType, createContext, ReactElement, useContext} from "react";
import {ComboBox, ComboBoxProps, Label, LabelPresets} from "../primitive";
import {formatDate} from "@/dateUtils";
import {RadioGroup} from "@/components/primitive/RadioGroup";
import {formatNumber} from "@/NumberFormatting";
import { FileInput } from "@/components/primitive/FileInput";

export type FormState = "disabled" | "enabled" | "readonly";

export const FormStateContext = createContext<FormState>("readonly");
export const FormState = FormStateContext.Provider;

function formAttached<Props extends { disabled?: boolean; readonly?: boolean }>(X: ComponentType<Props>, defaultRepr: (p: Props) => string) {
    const component = (p: Props & { valueRepr?: string }) => {
        const state = useContext(FormStateContext);

        return state === "readonly"
            ? <Label text={p.valueRepr ?? defaultRepr(p)} preset="readonlyValue"/>
            : <X disabled={state === "disabled"} {...p} />;
    };
    component.displayName = "F" + X.displayName;

    return component;
}

function specAttached<Props>(X: ComponentType<Props>, mapProps: (s: FormState) => Partial<Props>) {
    const component = (p: Props) => {
        const state = useContext(FormStateContext);

        return <X {...mapProps(state)} {...p} />;
    };
    component.displayName = "F" + X.displayName;

    return component;
}

// simple
export const FTextBox = formAttached(primitives.TextBox,
    x => x.value ?? "");
export const FIntBox = formAttached(primitives.IntBox,
    x => x.value !== undefined ? formatNumber(x.value) : "");
export const FFloatBox = formAttached(primitives.FloatBox,
    x => x.value !== undefined ? formatNumber(x.value) : "");
export const FDecimalBox = formAttached(primitives.DecimalBox,
    x => x.value !== undefined ? formatNumber(x.value) : "");
export const FDatePicker = formAttached(primitives.DatePicker,
    x => x.value ? formatDate(x.value, x.mode) : "");
export const FCheckBox = formAttached(primitives.CheckBox,
    x => x.value === undefined ? "" : x.value ? "Да" : "Нет");
export const FSlider = formAttached(primitives.Slider,
    x => x.value?.toString() ?? "");
export const FBoxPicker = formAttached(primitives.BoxPicker,
    x => x.value ?? "");
export const FFileInput = formAttached(FileInput,
    x => x.value ?? "");

// special
export const FButton = specAttached(primitives.Button, x => ({disabled: x !== "enabled"}));
export const FLabel = specAttached(primitives.Label, x => ({preset: x !== "enabled" ? "disabled" as LabelPresets : undefined}));

// manual
export const FComboBox = <T,>(x: ComboBoxProps<T> & { valueRepr?: string }): ReactElement => {
    const state = useContext(FormStateContext);

    return state === "readonly"
        ? <Label text={x.valueRepr ?? x.options.find(o => o.key === x.value)?.desc ?? ""} preset="readonlyValue"/>
        : <ComboBox disabled={state === "disabled"} {...x} />;
};

export const FRadioGroup: React.FC<{group?: string} & ({noRepr: true} | {valueRepr: string})> = x => {
    const state = useContext(FormStateContext);

    const repr = (x as {noRepr: boolean}).noRepr
        ? ""
        : (x as {valueRepr: string}).valueRepr;

    return state === "readonly"
        ? <Label text={repr} preset="readonlyValue"/>
        : <RadioGroup group={x.group} disabled={state === "disabled"}>
            {x.children}
        </RadioGroup>;
};