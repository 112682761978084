import { EnumStrings } from '../EnumStrings';

export const enum ContractExecutionOperationType {
    ELECTRONIC_CONTRACT_PROJECT_SENT = "ELECTRONIC_CONTRACT_PROJECT_SENT",
    CONTRACT_PROJECT_SENT = "CONTRACT_PROJECT_SENT",
    CONTRACT_PROJECT_SIGNED = "CONTRACT_PROJECT_SIGNED",
    CONTRACT_CONCLUDED = "CONTRACT_CONCLUDED",
    DISAGREEMENTS_SENT = "DISAGREEMENTS_SENT"
}

export const ContractExecutionOperationTypeStrings: EnumStrings = {
    [ContractExecutionOperationType.CONTRACT_PROJECT_SENT]: "Проект контракта направлен",
    [ContractExecutionOperationType.CONTRACT_PROJECT_SIGNED]: "Проект контракта подписан поставщиком",
    [ContractExecutionOperationType.DISAGREEMENTS_SENT]: "Поставщик направил протокол разногласий",
    [ContractExecutionOperationType.CONTRACT_CONCLUDED]: "Контракт заключен",
    [ContractExecutionOperationType.ELECTRONIC_CONTRACT_PROJECT_SENT]: "Электронный контракт направлен"
};