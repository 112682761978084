import React, {useEffect, useMemo, useState} from "react";
import {ModalFC} from "@/EventBus";
import {CardModal} from "@/components/modals/CardModal";
import {ContractStage, ExecutionInfoSpecification} from "@/models/Contracts";
import {ContractScope} from "@/views/CreateContract/ContractScope";
import {Expandee, HStack, VStack} from "@/components/layouts";
import {Column, Table} from "@/components/table/Table";
import {SelectionTable} from "@/components/table";
import {
    ExecutionSpecificationsModalViewModel
} from "@/views/CreateContract/execution/ExecutionSpecificationsModal/ExecutionSpecificationsModal.vm";
import {Button, ComboBox, DecimalBox} from "@/components/primitive";
import {Field} from "@/components/form/Field";
import {CountryOptions} from "@/views/Contracts/modals/CountrySelectModal";
import Decimal from "decimal.js";
import {observer} from "mobx-react";
import { formatAddress } from "@/models/ComposedLots/specifications";

export interface ExecutionSpecificationsModalProps {
    scope: ContractScope
    stage: ContractStage
    uncommittedSpecifications: ExecutionInfoSpecification[]
}

function concatOkpd2(x: { code: string; name: string } | null | undefined) {
    if (!x) return "";
    return `${x.code} ${x.name}`;
}

export const ExecutionSpecificationsModal: ModalFC<ExecutionSpecificationsModalProps, ExecutionInfoSpecification[]> = observer(x => {
    const [vm, setVm] = useState<ExecutionSpecificationsModalViewModel>();

    useEffect(() => {
        const _vm = new ExecutionSpecificationsModalViewModel(x);
        setVm(_vm);
        return () => {
            _vm.dispose();
        };
    }, []);

    const save = () => {
        const result = vm!.save();
        if (result) x.done(result);
    };

    const columns: Column<ExecutionInfoSpecification>[] = [
        Table.ObservableAutoColumn("№", v => <>{v.rowIndex + 1}</>),
        Table.ObservableColumn("СПГЗ", v => <>{v.item.specification.specification.subjectDeclaration?.subject ?? ""}</>, { width: "auto" }),
        Table.ObservableColumn("ОКПД2", v => <>{concatOkpd2(v.item.specification.specification.subjectDeclaration.okpd2)}</>, { width: "auto" }),
        Table.ObservableColumn("Ед. измерения", v => <>{v.item.specification.specification.measurementUnit?.name ?? ""}</>, { width: "auto" }),
        Table.ObservableAutoColumn("Объем", v => {
            const index = (v.item as ExecutionInfoSpecification & {index: number}).index;

            return <DecimalBox readonly disabled value={v.item.specification.stages[index]?.volume ?? new Decimal(0)} />;
        }, { width: "auto" }),
        Table.ObservableAutoColumn("Поставлено",
                v => {
                    const index = (v.item as ExecutionInfoSpecification & {index: number}).index;
                    const stage = v.item.specification.stages[index];

                    return <DecimalBox readonly
                                     disabled
                                     value={stage?.volume ?? new Decimal(0)} />;},
            { width: "auto" }),
        Table.ObservableAutoColumn("Объем поставки",
            v => <DecimalBox value={v.item.volume}
                             onChange={c => {
                                 v.item.volume = (c ?? new Decimal(0));
                                 v.item.cost = (c ?? new Decimal(0)).mul(v.item.specification.actualCost.div(v.item.specification.actualVolume)).toNearest(0.01, Decimal.ROUND_UP);
                             }} />,
            { width: "auto" }),
        Table.ObservableAutoColumn("Сумма поставки, руб.", v => <DecimalBox value={v.item.cost}
                                                                  onChange={c => { v.item.cost = (c ?? new Decimal(0)) }} />,
            { width: "auto" }),
        Table.ObservableAutoColumn("Поставлено на сумму, руб.",
                v => {
                    const index = (v.item as ExecutionInfoSpecification & {index: number}).index;
                    const totalVolume = v.item.specification.stages.reduce((acc, st) => acc.add(st.volume), new Decimal(0));
                    const stage = v.item.specification.stages[index];
                    if(!stage) return <DecimalBox readonly disabled value={new Decimal(0)} />;
                    const volumeRatio = stage.volume.div(totalVolume);
                    const cost = v.item.specification.actualCost.mul(volumeRatio);

                    return <DecimalBox readonly disabled value={cost} />;},
            { width: "auto" }),
        Table.ObservableColumn("Адрес поставки",
                v => {
                    const index = (v.item as ExecutionInfoSpecification & {index: number}).index;
                return <>
                {/* should I update it for specification.stages or specification.specification.supplyAddress or specification.specification.supplyStages or everything? */}
                <button style={{ border: "none", background: "none", fontSize: "13px", color: "#3366CC", textDecoration: "underline", cursor: "pointer" }} 
                    onClick={() => vm!.pickAddress(v.item, index)}>
        { v.item.specification.stages[index]?.address ? formatAddress(v.item.specification.stages[index].address!) : "Не указан" }
        </button>
                </>;},
            { width: "auto" }),
        Table.ObservableColumn("Страна происхождения",
                v => <ComboBox options={CountryOptions} value={v.item.country} onChange={c => { v.item.country = c }} noPropagation/>,
            { width: "150px" }),
        Table.ObservableColumn("Страна регистрации производителя",
            v => <ComboBox options={CountryOptions} value={v.item.registrationCountry} onChange={c => { v.item.registrationCountry = c }} noPropagation/>,
            { width: "150px" }),
    ];

    if (!vm) return <></>;

    const bottom = <HStack>
        <Button icon="aSave" onClick={save} />
        <Expandee />
    </HStack>;

    return <CardModal title="Указание сведений о поставке"
                      close={() => x.done(undefined)}
                      bottom={bottom}
                      width="1350px">
        <VStack spacing="20px">
            <Field title="Указать страну происхождения товара, страну производителя">
                <HStack spacing="15px">
                    <div style={{ width: "500px" }}>
                        <ComboBox options={CountryOptions}
                                  value={vm.country}
                                  onChangeNonStrict={v => vm!.country = (v ?? null)} />
                    </div>
                    <Button color="green"
                            onClick={() => vm.setCountry()}
                            title="Указать" />
                    <Button color="green"
                            onClick={() => vm.setCompleteDelivery()}
                            title="Полная поставка" />
                </HStack>
            </Field>

            <SelectionTable dataset={vm.draft}
                            selected={vm.selected}
                            onChange={v => { vm!.setSelected(v) }}
                            columns={columns}
                            mode="multi" />
        </VStack>
    </CardModal>;
});
