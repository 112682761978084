import {UnionStrings} from "@/models/enums";

export type Article15Accordance =
    | "NO"
    | "PART_4"
    | "PART_5"
    | "PART_6";

export const Article15AccordanceStrings: UnionStrings<Article15Accordance> = {
    NO: "Нет",
    PART_4: "В соответствии с ч.4 ст.15 44-ФЗ",
    PART_5: "В соответствии с ч.5 ст.15 44-ФЗ",
    PART_6: "В соответствии с ч.6 ст.15 44-ФЗ"
};