import React, {useState} from "react";
import {Card} from "@/components/Card";
import {SectionProps} from "@/views/Procedures2020/view/store";
import {HStack, VStack} from "@/components/layouts";
import {Field, Fields} from "@/components/form/Field";
import {
    CheckBox, ComboBox,
    Label,
    Radio
} from "@/components/primitive";
import {Currency, CurrencyStrings, toSelectOptions} from "@/models/enums";
import {BankAccount} from "@/models/BankAccount";
import {useMappedStore} from "@/storeUtils";
import {DocumentationOrderEvents} from "@/views/Procedures2020/view/store/DocumentsRequestsSection/documentationOrder";
import {RequestAcceptanceOrderEvents} from "@/views/Procedures2020/view/store/DocumentsRequestsSection/requestAcceptanceOrder";
import {PaymentOrderEvents} from "@/views/Procedures2020/view/store/DocumentsRequestsSection/paymentOrder";
import {FCheckBox, FIntBox, FormState, FRadioGroup, FTextBox} from "@/components/form";
import {actionToState} from "@/views/ComposedLot/shared/store";

export const DocumentsRequestsSection: React.FC<SectionProps> = x => {

    const viewing = x.formAction === "viewing";

    const required = !viewing;

    const sectionStore = useMappedStore(x.formStore, x => ({
        documentationOrder: x.documentationOrder,
        requestAcceptanceOrder: x.requestAcceptanceOrder,
        paymentOrder: x.paymentOrder,
        info: x.info
    }));

    const docs = <VStack spacing={"25px"}>
        <Label preset={"headerLarge"} text={"Место и порядок предоставления документации о процедуре закупки"}/>
        <Fields spacing={"25px"} orientation={x.formAction === "viewing" ? "vertical" : "horizontal"}>
            <Field required={required} title="Адрес">
                <FTextBox value={sectionStore.documentationOrder.address} onChange={a => DocumentationOrderEvents.setAddress(a)}/>
            </Field>
            <Field title="Кабинет">
                <FTextBox value={sectionStore.documentationOrder.room} onChange={a => DocumentationOrderEvents.setRoom(a)}/>
            </Field>
            <Field required={required} title="Электронная почта">
                <FTextBox value={sectionStore.documentationOrder.email} onChange={a => DocumentationOrderEvents.setEmail(a)}/>
            </Field>
            <Field required={required} title="Телефон">
                <FTextBox value={sectionStore.documentationOrder.phone} onChange={a => DocumentationOrderEvents.setPhone(a)} placeholder={"+7(555)555-55-55"}/>
            </Field>
            <Field required={required} title="Факс">
                <FTextBox value={sectionStore.documentationOrder.fax} onChange={a => DocumentationOrderEvents.setFax(a)} placeholder={"+7(555)555-55-55"}/>
            </Field>
            <Field title="Контактное лицо">
                <FTextBox value={sectionStore.documentationOrder.contactPerson} onChange={a => DocumentationOrderEvents.setContactPerson(a)}/>
            </Field>
            <Field title="Стандартный порядок">
                <FCheckBox valueRepr={sectionStore.documentationOrder.default ? "Да" : "Нет"} value={sectionStore.documentationOrder.default} onChange={a => DocumentationOrderEvents.setDefault(a)}/>
            </Field>
            <Field required={required} title="Порядок предоставления">
                <FTextBox lines={6} value={sectionStore.documentationOrder.text} onChange={a => DocumentationOrderEvents.setText(a)} disabled={sectionStore.documentationOrder.default}/>
            </Field>
        </Fields>
    </VStack>;

    /** 223 && E_AUC case only*/
    const docsAlter = <VStack spacing={"25px"}>
        <Label preset={"headerLarge"} text={"Место и порядок предоставления документации о процедуре закупки"}/>
        <Fields spacing={"25px"} orientation={x.formAction === "viewing" ? "vertical" : "horizontal"}>
            <Field required={required} title="Сайт">
                <FTextBox value={sectionStore.documentationOrder.website} onChange={a => DocumentationOrderEvents.setWebsite(a)}/>
            </Field>
            <Field title="Стандартный порядок">
                <FCheckBox valueRepr={sectionStore.documentationOrder.default ? "Да" : "Нет"} value={sectionStore.documentationOrder.default} onChange={a => DocumentationOrderEvents.setDefault(a)}/>
            </Field>
            <Field required={required} title="Порядок предоставления">
                <FTextBox lines={6} value={sectionStore.documentationOrder.text} onChange={a => DocumentationOrderEvents.setText(a)} disabled={sectionStore.documentationOrder.default}/>
            </Field>
        </Fields>
    </VStack>;

    const requests = <VStack spacing={"25px"}>
        <Label preset={"headerLarge"} text={"Место и порядок приема заявок на участие в процедуре закупки"} />
        <Fields spacing={"25px"} orientation={x.formAction === "viewing" ? "vertical" : "horizontal"}>
            <Field required={required} title="Адрес">
                <FTextBox value={sectionStore.requestAcceptanceOrder.address} onChange={a => RequestAcceptanceOrderEvents.setAddress(a)}/>
            </Field>
            <Field title="Кабинет">
                <FTextBox value={sectionStore.requestAcceptanceOrder.room} onChange={a => RequestAcceptanceOrderEvents.setRoom(a)}/>
            </Field>
            <Field required={required} title="Электронная почта">
                <FTextBox value={sectionStore.requestAcceptanceOrder.email} onChange={a => RequestAcceptanceOrderEvents.setEmail(a)}/>
            </Field>
            <Field required={required} title="Телефон">
                <FTextBox value={sectionStore.requestAcceptanceOrder.phone} onChange={a => RequestAcceptanceOrderEvents.setPhone(a)} placeholder={"+7(555)555-55-55"}/>
            </Field>
            <Field required={required} title="Факс">
                <FTextBox value={sectionStore.requestAcceptanceOrder.fax} onChange={a => RequestAcceptanceOrderEvents.setFax(a)} placeholder={"+7(555)555-55-55"}/>
            </Field>
            <Field title="Контактное лицо">
                <FTextBox value={sectionStore.requestAcceptanceOrder.contactPerson} onChange={a => RequestAcceptanceOrderEvents.setContactPerson(a)}/>
            </Field>
            <Field required={required} title="Количество копий заявки">
                <FIntBox value={sectionStore.requestAcceptanceOrder.copies} onChange={a => RequestAcceptanceOrderEvents.setCopies(a)}/>
            </Field>
            <Field title="Стандартный порядок">
                <FCheckBox valueRepr={sectionStore.requestAcceptanceOrder.default ? "Да" : "Нет"} value={sectionStore.requestAcceptanceOrder.default} onChange={a => RequestAcceptanceOrderEvents.setDefault(a)}/>
            </Field>
            <Field required={required} title="Порядок подачи">
                <FTextBox lines={6} value={sectionStore.requestAcceptanceOrder.text} onChange={a => RequestAcceptanceOrderEvents.setText(a)} disabled={sectionStore.requestAcceptanceOrder.default}/>
            </Field>
        </Fields>
    </VStack>;

    const payment = <VStack spacing={"25px"}>
        <Label preset={"headerLarge"} text={"Размер и порядок внесения платы, взимаемой за предоставление документации о закупке"}/>
        <Fields spacing={"25px"} orientation={x.formAction === "viewing" ? "vertical" : "horizontal"}>
            <Field title="Взимается плата">
                <HStack spacing={"50px"}>
                    <FRadioGroup valueRepr={sectionStore.paymentOrder.paymentRequired ? "Да" : "Нет"}>
                        <Radio requiredValue={true} value={sectionStore.paymentOrder.paymentRequired} onChange={() => PaymentOrderEvents.setPaymentRequired(true)}>
                            Да
                        </Radio>
                        <Radio requiredValue={false} value={sectionStore.paymentOrder.paymentRequired} onChange={() => PaymentOrderEvents.setPaymentRequired(false)}>
                            Нет
                        </Radio>
                    </FRadioGroup>
                </HStack>
            </Field>
            {
                sectionStore.paymentOrder.paymentRequired &&
                <>
                    <Field required={required} title="Размер оплаты">
                        <FTextBox value={sectionStore.paymentOrder.volume} onChange={a => PaymentOrderEvents.setVolume(a)}/>
                    </Field>
                    <Field required={required} title="Валюта">
                        <ComboBox options={toSelectOptions(CurrencyStrings)} value={sectionStore.paymentOrder.currency} onChange={a => PaymentOrderEvents.setCurrency(a as Currency)}/>
                    </Field>
                    <Field title="Облагается НДС">
                        <CheckBox value={sectionStore.paymentOrder.taxRequired} onChange={a => PaymentOrderEvents.setTaxRequired(a)}/>
                    </Field>
                    {
                        sectionStore.paymentOrder.taxRequired &&
                        <Field title="">
                            <FTextBox lines={5} value={sectionStore.paymentOrder.taxDescription} onChange={a => PaymentOrderEvents.setTaxDescription(a)}/>
                        </Field>
                    }
                    <Field required={required} title="Лицевой счёт">
                        <ComboBox options={[]} value={sectionStore.paymentOrder.account} onChange={a => PaymentOrderEvents.setAccount(a as BankAccount)}/>
                    </Field>
                    <Field required={required} title="БИК">
                        <FTextBox value={sectionStore.paymentOrder.account?.bin} disabled/>
                    </Field>
                    <Field required={required} title="Наименование банка">
                        <FTextBox value={sectionStore.paymentOrder.account?.bankName} disabled/>
                    </Field>
                    <Field required={required} title="Город">
                        <FTextBox value={sectionStore.paymentOrder.account?.city} disabled/>
                    </Field>
                    <Field required={required} title="Корр. счёт">
                        <FTextBox value={sectionStore.paymentOrder.account?.correspondentAccount} disabled/>
                    </Field>
                    <Field required={required} title="Порядок внесения платы">
                        <FTextBox lines={5} value={sectionStore.paymentOrder.text} onChange={a => PaymentOrderEvents.setText(a)}/>
                    </Field>
                </>
            }
        </Fields>
    </VStack>;

    return <Card>
        <FormState value={actionToState(x.formAction)}>
        <VStack spacing={"50px"}>
            {
                sectionStore.info.providerSelectionType !== "E_AUC"
                    ? docs
                    : docsAlter
            }
            {
                (![
                    "E_AUC",
                    "E_EXAM",
                    "E_TWO_STAGE_EXAM",
                    "E_OFFERS_REQUEST",
                    "E_QUOTATIONS_REQUEST",
                ].find(a => a === sectionStore.info.providerSelectionType)) &&
                    requests
            }

            {
                (sectionStore.info.providerSelectionType !== "QUOTATIONS_REQUEST") &&
                    payment
            }
        </VStack>
        </FormState>
    </Card>;
};