




import {Products as ProductsR} from "./Products";
import {Component, Prop} from "vue-property-decorator";
import {createProductsStore} from "./store";
import { SubjectClassFilterStore } from '@/components/eshop/SubjectClassFilterStore';

import Vue from 'vue';

@Component({ components: { komponent: ProductsR as unknown as typeof Vue } })
export default class Products extends Vue {
    @Prop() public favorites!: boolean;
    public storeContainer =  Object.freeze({ subjectClassSelector: new SubjectClassFilterStore() });
}
