import React from "react";
import styles from './Checkbox.module.css';
import {j} from "@/reactUtils";

type CheckboxProps = {
    caption?: string
    onChange?: () => void
    value?: boolean
    disabled?: boolean
    title?: string
    bold?: boolean
};

export const Checkbox = ({ caption, onChange, value, disabled, title, bold }: CheckboxProps) => {
    return (
        <div title={title} onClick={e => e.stopPropagation()}  className={styles.eshopCheckboxComponent}>
            <label className={styles.containerCheckbox}>
                <input onClick={() => (!disabled && onChange) && onChange()} className={styles.inputCheckbox} />
                <div className={styles.checkboxWrapper}>
                    <span className={j(styles.checkMark, value && styles.checkMarkActive, disabled && styles.disabled, bold ? styles.checkbox__border_bold : styles.checkbox__border_default)} />
                </div>
                {caption && <div className={styles.checkboxCaption}>{caption}</div>}
            </label>
        </div>
    );
};
