import React, {useEffect, useState} from "react";
import Admin from "@/api/Admin";
import {
    Column,
    createStoredRemoteDataset, FilterColumn,
    FilteredSelectionTable, Pager,
    Table,
    useStoredRemoteDataset
} from "@/components/table";
import { Expandee, HStack, VStack } from "@/components/layouts";
import { Button } from "@/components/primitive";
import { User } from "@/models/User";
import { useStore } from "effector-react";
import {createUsersStore, UserFilters} from "@/views/Register/requests/UsersStore";
import router from "@/router";
import EventBus, {showModal} from "@/EventBus";
import {ConfirmationModal} from "@/views/Contracts/modals/ConfirmationModal";
import AddUserModal from "@/views/AddUserModal.vue";
import {formatDate} from "@/DateFormatting";
import {Card} from "@/components/Card";
import { UploadUsersModal } from "@/views/modals/UploadUsersModal/UploadUsersModal";

const roleStrings: { [key: string]: string } = {
    SUPERUSER: "Администратор",
    USER: "Пользователь"
};

export const UsersSection: React.FC<{ reload: symbol; onReload: () => void }> = x => {
    const [filtersVisible, setFiltersVisible] = useState<boolean>(false);

    const [store] = useState(() => createUsersStore());
    const state = useStore(store);
    const rds = useStoredRemoteDataset(state.users);

    const refresh = () => {
        x.onReload();
    };

    useEffect(() => {
        rds.setCurrentPage(rds.currentPage, true);
    }, [x.reload]);

    const editUser = async (user: User | null) => {
        const r = await EventBus.callModal<User | null, { user: User; password: string | null }>(AddUserModal, user);
        const result = r.getResult();

        if (r.isOk) {
            const result = r.getResult();
            if (user) {
                await Admin.editUser({
                    ...result.user,
                    group: result.user.group,
                    selectedParticipantId: result.user.selectedParticipant.id,
                    allowedParticipantIds: result.user.allowedParticipants.map(x => x.id),
                    comment: result.user.comment ?? undefined
                }, result.password);
            } else {
                await Admin.createUser({
                    ...result.user,
                    group: result.user.group,
                    selectedParticipantId: result.user.selectedParticipant.id,
                    allowedParticipantIds: result.user.allowedParticipants.map(x => x.id),
                    comment: result.user.comment ?? undefined
                }, result.password);
            }
        }


        refresh();
    };

    const deleteUser = async (user: User) => {
        const confirmation = await showModal(ConfirmationModal, {title: "Удаление", text: "Вы уверены, что хотите удалить пользователя?"});
        if (!confirmation)
            return;

        await Admin.deleteUser(user);

        refresh();
    };

    const lockUser = async (user: User) => {
        await Admin.lockUser(user);

        refresh();
    };

    const resetUser = async (user: User) => {
        const ok = await showModal(ConfirmationModal, {
            title: "Сброс пользователя",
            text: "Вы уверены что хотите удалить все цепочки пользователя и сгенерировать их заново?"
        });

        if (!ok) return;

        await Admin.resetUser(user);
    };

    const unlockUser = async (user: User) => {
        await Admin.unlockUser(user);

        refresh();
    };

    const impersonateUser = async (user: User) => {
        await Admin.impersonateUser(user);
        router.resetTo('/');
        window.location.reload();

        refresh();
    };

    const columns: Column<User>[] = [
        FilterColumn<User, UserFilters>({
            header: "Логин",
            cell: x => <>{ x.item.username }</>,
            filter: {
                type: "string",
                key: "username"
            },
            options: {
                width: "auto"
            }
        }),
        FilterColumn<User, UserFilters>({
            header: "Имя",
            cell: x => <>{x.item.fullName}</>,
            filter: {
                type: "any",
                key: "any"
            },
        }),
        Table.Column("Выбранная организация", x => <>{x.item.selectedParticipant.shortName}</>),
        Table.Column("Роль", x => <>{roleStrings[x.item.role]}</>, { width: "auto" }),
        Table.Column("Заблокирован", x => <>{x.item.status === "LOCKED" ? "Да" : "Нет"}</>, { width: "auto" }),
        Table.Column("Комментарий", x => <>{x.item.comment ? x.item.comment : "-"}</>, { width: "200px" }),
        Table.Column("Группа", x => <>{x.item.group ? x.item.group : "-"}</>, { width: "200px" }),
        Table.Column("Создан", x => <>{x.item.created ? formatDate(x.item.created, true) : "-"}</>, { width: "100px" }),
        Table.AutoColumn("Действия", x => <HStack spacing="5px">
            <Button color="blue" icon="gPencil" onClick={() => editUser(x.item)} />
            <Button color="blue" icon="gUser" onClick={() => impersonateUser(x.item)} />
            <Button icon="faRecycle" color="red" onClick={() => resetUser(x.item)}/>
            {x.item.status !== "LOCKED"
                ? <Button color="orange" icon="aLock" onClick={() => lockUser(x.item)}/>
                : <Button color="blue" icon="aLock" onClick={() => unlockUser(x.item)} />}
            <Button color="red" icon="aDelete" onClick={() => deleteUser(x.item)} />
        </HStack>, { width: "auto" }),
    ];

    const handleUploadUsersClick = async () => {
        const result = await showModal(UploadUsersModal);
        if (result) x.onReload();
    };

    const title = <HStack>
        <span>
            Активные пользователи
        </span>
        <Expandee />
        <Button color="green" icon="aAdd" title="Загрузить пользователей" onClick={handleUploadUsersClick} style={{marginRight: "10px"}}/>
        <Button color="green" icon="aAdd" title="Создать" onClick={() => editUser(null)} />
    </HStack>;

    return <Card title={title}>
        <VStack spacing="15px">
            <HStack spacing="5px">
                <Expandee />
                <Button icon="aFilter" color="blue" onClick={() => setFiltersVisible(!filtersVisible)} />
            </HStack>

            <FilteredSelectionTable filterStore={rds.filterStore}
                                    mode="single"
                                    isFiltersVisible={filtersVisible}
                                    selectorPosition="hidden"
                                    dataset={rds.dataset}
                                    columns={columns} />
            <Pager remoteDataset={rds} />
        </VStack>
    </Card>;
};

