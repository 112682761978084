import { EnumStrings } from '../EnumStrings';

export const enum ContractExecutionOperationState {
    NONE = "NONE",
    PROJECT = "PROJECT",
    // SIGINING_PENDING = "SIGNING_PENDING",
    SEND_TO_SUPPLIER = "SEND_TO_SUPPLIER",
    RECEIVED_SIGN_FROM_SUPPLIER = "RECEIVED_SIGN_FROM_SUPPLIER",
    RECEIVED_DISAGREEMENT_FROM_SUPPLIER = "RECEIVED_DISAGREEMENT_FROM_SUPPLIER",
    SIGNED = "SIGNED",
    CHANGING = "CHANGING",
}

export const ContractExecutionOperationStateStrings: EnumStrings = {
    [ContractExecutionOperationState.NONE]: "-",
    [ContractExecutionOperationState.PROJECT]: "Проект",
    [ContractExecutionOperationState.SEND_TO_SUPPLIER]: "Подписание",
    [ContractExecutionOperationState.RECEIVED_SIGN_FROM_SUPPLIER]: "Подписание",
    [ContractExecutionOperationState.RECEIVED_DISAGREEMENT_FROM_SUPPLIER]: "Подписание",
    [ContractExecutionOperationState.SIGNED]: "Подписан",
    [ContractExecutionOperationState.CHANGING]: "Подписание",
};