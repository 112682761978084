import { IDtoContractExecutionOperation } from '@/models/json/Contracts';
import { IJsonFormattable } from '@/models/IJsonFormattable';
import { User } from '../User';
import { ContractExecutionOperationType } from '../enums/Contracts/ContractExecutionOperationType';
import { TransmissionStatus } from '../enums/Contracts/TransmissionStatus';

export class ContractExecutionOperation implements IJsonFormattable<IDtoContractExecutionOperation> {
    constructor(
        public id: string,
        public createdAt: Date,
        public operation: ContractExecutionOperationType,
        public sendReceiveDate: Date | null,
        public replyDeadline: Date | null,
        public operator: User | null,
        public comment: string | null,
        public transmissionStatus: TransmissionStatus,
    ) {}
    
    public toJson(): IDtoContractExecutionOperation {
        return {
            ...this,
            createdAt: this.createdAt && this.createdAt.toISOString(),
            sendReceiveDate: this.sendReceiveDate && this.sendReceiveDate.toISOString(),
            replyDeadline: this.replyDeadline && this.replyDeadline.toISOString(),
            operator: this.operator && this.operator.toJson()
        };
    }
    
    public static fromJson(dto: IDtoContractExecutionOperation): ContractExecutionOperation {
        return Object.assign(Object.create(ContractExecutionOperation.prototype), dto, {
            createdAt: new Date(dto.createdAt),
            sendReceiveDate: dto.sendReceiveDate && new Date(dto.sendReceiveDate),
            replyDeadline: dto.replyDeadline && new Date(dto.replyDeadline),
            operator: dto.operator && User.fromJson(dto.operator)
        });
    }
}
