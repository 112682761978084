import React from "react";
import {Card} from "@/components/Card";
import {Label} from "@/components/primitive";
import {Table} from "@/components/table";

export const SuppliersContactFaces: React.FC = x => {
    type Template = { fio: string; ph: string; mail: string };
    const dataset: Template[] = [
        {
            fio: "Иванов Героргий",
            ph: "+7 (919) 468-40-50 (Единый)",
            mail: "huhiuh@gmail.com"
        },
        {
            fio: "Ковалёв Виктор",
            ph: "+7 (495) 657-61-95 (Единый)",
            mail: "kvest.stroy@mail.ru"
        }
    ];

    return <Card>
        <Table<Template> dataset={dataset} columns={[
            Table.AutoColumn("ФИО", x =>
                <Label>
                    {x.item.fio}
                </Label>),
            Table.AutoColumn("Телефон", x =>
                <Label>
                    {x.item.ph}
                </Label>),
            Table.AutoColumn("Электронная почта", x =>
                <Label>
                    {x.item.mail}
                </Label>),
        ]} />
    </Card>;
};