




import {Component, Vue, Prop} from "vue-property-decorator";
import {SubjectDeclarationsView} from './SubjectDeclarationsView';

@Component({ components: { komponent: SubjectDeclarationsView as unknown as typeof Vue } })
export default class ViewSubjectClassVueStub extends Vue {
    @Prop() public id!: string;
}
