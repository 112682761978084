import {SubjectClass} from "@/models";
import {typifyVueModal} from "@/EventBus";
import {ISubjectClassSelectionModalArgs} from "@/views/modals/ModalArgs";
import SubjectClassSelectionModal from "@/views/modals/SubjectClassSelectionModal/SubjectClassSelectionModal.vue";

interface PickSubjectClassProps {
    root?: SubjectClass
    selected?: SubjectClass
}

export const PickSubjectClassModal = typifyVueModal<
    PickSubjectClassProps,
    SubjectClass,
    ISubjectClassSelectionModalArgs>(
    SubjectClassSelectionModal,
    m => ({ root: m.root ?? null, subjectClass: m.selected ?? null }));