import {createEvent, createStore, Store} from "effector";
import {ProcedureForm} from "@/views/Procedures2020/view/store";
import {autoSV, createSVEvent, origEv} from "@/storeUtils";

export interface DocumentationOrderStore {
    /** Адрес */
    address?: string

    /** Кабинет */
    room?: string

    /** Электронная почта */
    email?: string

    /** Телефон */
    phone?: string

    /** Факс */
    fax?: string

    /** Контактное лицо */
    contactPerson?: string

    /** Стандартный порядок */
    default?: boolean

    /** Порядок предоставления */
    text?: string

    /** Сайт */
    website?: string
}
const defaultOrder = "После даты размещения извещения о проведении закупки заказчик на основании поданного в письменной форме заявления любого заинтересованного лица в течение двух рабочих дней с даты получения соответствующего заявления обязан предоставить такому лицу документацию в порядке, указанном в извещении о проведении закупки. При этом документация предоставляется в форме документа на бумажном носителе после внесения данным лицом платы за предоставление документации, если данная плата установлена и указание об этом содержится в извещении о проведении закупки, за исключением случаев предоставления конкурсной документации в форме электронного документа.";

const replace = createEvent<DocumentationOrderStore>("replace documentationOrder at documents section");

export const DocumentationOrderEvents = {
    setAddress: createSVEvent<DocumentationOrderStore>()("address"),
    setRoom: createSVEvent<DocumentationOrderStore>()("room"),
    setEmail: createSVEvent<DocumentationOrderStore>()("email"),
    setPhone: createSVEvent<DocumentationOrderStore>()("phone"),
    setFax: createSVEvent<DocumentationOrderStore>()("fax"),
    setContactPerson: createSVEvent<DocumentationOrderStore>()("contactPerson"),
    setDefault: createSVEvent<DocumentationOrderStore>()("default"),
    setText: createSVEvent<DocumentationOrderStore>()("text"), //TODO: set default text when checkbox is enabled
    setWebsite: createSVEvent<DocumentationOrderStore>()("website"), //TODO: set default text when checkbox is enabled
};

/** Секция "Документация и прием заявок" -> Место и порядок предоставления документации о процедуре закупки */
export const DocumentationOrderStore = {
    createStore: (): Store<DocumentationOrderStore> => {
        return createStore<DocumentationOrderStore>({
            text: defaultOrder,

        })
            .on(replace, (_, l) => l)
            .on(DocumentationOrderEvents.setAddress[origEv], autoSV)
            .on(DocumentationOrderEvents.setRoom[origEv], autoSV)
            .on(DocumentationOrderEvents.setEmail[origEv], autoSV)
            .on(DocumentationOrderEvents.setPhone[origEv], autoSV)
            .on(DocumentationOrderEvents.setFax[origEv], autoSV)
            .on(DocumentationOrderEvents.setContactPerson[origEv], autoSV)
            .on(DocumentationOrderEvents.setDefault[origEv], autoSV)
            .on(DocumentationOrderEvents.setText[origEv], autoSV)
            .on(DocumentationOrderEvents.setWebsite[origEv], autoSV)
            ;
    },

    replace: replace,
    observeOnCombinedStore: (combinedStore: Store<ProcedureForm>) => {
    }
};