import React from "react";
import { Card } from "@/components/Card";
import { SectionProps } from "@/views/ComposedLot/shared/store";
import { HSpread } from "@/components/layouts";
import { Field, Fields } from "@/components/form/Field";
import { useMappedStore } from "@/storeUtils";
import {
    AdditionalInfoEvents as E, AdditionalInfoOptions, AdditionalInfoOptions2022
} from "@/views/ComposedLot/shared/store/additionalInfo";
import { appendUniq, removeUniq } from "@/arrayUtils";
import { FCheckBox, FDecimalBox, FTextBox } from "@/components/form";
import { DecimalBox, Label } from "@/components/primitive";

/*
Под каждым пунктом описано, какие чекбоксы отключаются при его активации

   1 Товары, происходящие из иностранного государства или группы иностранных государств, допускаемые на территорию Российской Федерации; преимущества предоставляются в соответствии с приказом Минфина РФ от 4 июня 2018 г. № 126н
-
   2 Применение запрета для товаров, входящих в Перечень сельскохозяйственной продукции, сырья и продовольствия, страной происхождения которых является Турецкая Республика и которые запрещены с 1 января 2016 г. к ввозу в Российскую Федерацию (утверждено постановлением Правительства Российской Федерации от 30 ноября 2015 г. № 1296)
1, 6, 7, 8
   3 Применение запрета для товаров, входящих в Перечень отдельных видов товаров машиностроения, происходящих из иностранных государств, в соответствии с постановлением Правительства Российской Федерации от 14 июля 2014 г. № 656
1, 6, 7, 8
   4 Применение запрета на допуск отдельных видов товаров легкой промышленности, происходящих из иностранных государств, в соответствии с постановлением Правительства Российской Федерации от 11 августа 2014 г. N 791
1, 6, 7, 8
   5 Применение запрета на допуск программ для электронных вычислительных машин и баз данных, реализуемых независимо от вида договора на материальном носителе и (или) в электронном виде по каналам связи, происходящих из иностранных государств, а также исключительных прав на такое программное обеспечение и прав использования такого программного обеспечения, в соответствии с положениями постановления Правительства Российской Федерации от 16 ноября 2015 г. № 1236
1, 6, 7, 8
   6 Применение ограничений допуска отдельных видов пищевых продуктов, происходящих из иностранных государств в соответствии с постановлением Правительства Российской Федерации от 22 августа 2016 г. № 832
-
   7 Применение ограничений допуска отдельных видов медицинских изделий, происходящих из иностранных государств в соответствии с постановлением Правительства Российской Федерации от 05 февраля 2015 г. № 102
-
   8 Применение ограничений допуска отдельных видов лекарственных препаратов, включенных в Перечень жизненно необходимых и важнейших лекарственных препаратов и происходящих из иностранных государств в соответствии с постановлением Правительства Российской Федерации от 30 ноября 2015 г. № 1289
-
   9 Применение ограничений и условий допуска отдельных видов радиоэлектронной продукции, происходящих из иностранных государств, для целей осуществления закупок для обеспечения государственных и муниципальных нужд в соответствии с постановлением Правительства Российской Федерации от 10 июля 2019 г. N 878
-
   10 Предметом контракта является поставка товара, необходимого для нормального жизнеобеспечения в случаях, указанных в ч. 9 ст. 37 44-ФЗ
-
   11 Выполнение научно-исследовательских, опытно-конструкторских, технологических работ или оказание консультационных услуг
-
   12 Возможность снижения цены контракта без изменения количества товара, объема работы или услуги, качества поставляемого товара, выполняемой работы, оказываемой услуги и иных условий контракта (при исполнении контракта) (44-ФЗ, ст. 95, п. 1, пп. 1, а)
-
   13 Возможность изменения количества товара, объема работы или услуги в пределах 10% (при исполнении контракта) (44-ФЗ, ст. 95, п. 1, пп. 1, б)
взаимоисключающие с 14
   14 Возможность изменения объема и (или) видов выполняемых работ по контракту на выполнение работ по строительству, реконструкции, капитальному ремонту, сносу объекта капитального строительства, проведению работ по сохранению объектов культурного наследия (44-ФЗ, ст. 95, ч.1, п.1, пп. в)
взаимоисключающие с 13
   15 Возможность увеличения количества поставляемого товара при заключении контракта (44-ФЗ, ст. 34, п. 18)
-
 */

interface AdditionalInfoSectionCheckbox {
    id: string
    title: string
    disables: AdditionalInfoSectionCheckbox[]
}

export const AdditionalInfoSection: React.FC<SectionProps> = x => {
    const [additionalInfo, publishYear] = useMappedStore(x.formStore, a => [a.additionalInfo, a.info.plannedNotificationPublishYear]);

    const cardHeader = <HSpread alignItems="center">
        <span>Дополнительные сведения</span>
    </HSpread>;

    return <Card title={cardHeader}>
        <Fields orientation={"horizontal"}>
            <Field title={"Преимущества учреждениям УИС (%)"}>
                <DecimalBox value={additionalInfo.advantagesForUis}
                            style={{ width: "10%" }}
                            disabled
                            onChange={E.setAdvantagesForUis}/>
            </Field>
            <Field title={"Преимущества организациям инвалидов (%)"}>
                <DecimalBox value={additionalInfo.advantagesForDisabledPeopleOrganizations}
                            style={{ width: "10%" }}
                            disabled
                            onChange={E.setAdvantagesForDisabledPeopleOrganizations}/>
            </Field>
            <Field title={"Условия запрета допуска иностранных товаров"}>
                <FTextBox value={additionalInfo.foreignProductsForbidConditions}
                          onChange={E.setForeignProductsForbidConditions}
                          lines={5}/>
            </Field>
            <Field title={"Объём иностранных товаров"}>
                <FDecimalBox value={additionalInfo.foreignProductsAmount}
                             onChange={E.setForeignProductsAmount}/>
            </Field>
            {
                Object.entries(AdditionalInfoOptions)
                    .map(([id, text]) => <Field key={id} title={text}>
                        <FCheckBox value={additionalInfo.options.includes(id)}
                                   onChange={a => a
                                       ? E.setOptions(appendUniq(additionalInfo.options, id))
                                       : E.setOptions(removeUniq(additionalInfo.options, id))
                                   }/>
                    </Field>)
            }
        </Fields>
    </Card>;
};
