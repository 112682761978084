import React, { ReactElement, ReactNode } from "react";
import { MonopolyStatus } from "@/models/Monopoly";

type StatusSwitcherProps = {
    children: ReactElement<SwitcherItemProps> | ReactElement<SwitcherItemProps>[]
    status: MonopolyStatus | ((s: MonopolyStatus) => boolean)
};

export const StatusSwitcher = ({ children, status }: StatusSwitcherProps) => {
    const childrenArray = React.Children.toArray(children) as ReactElement<SwitcherItemProps>[];

    const filteredItems =  childrenArray.filter(child => {
        if (!React.isValidElement(child)) {
            return false;
        }

        return typeof status === "function" ? status(child.props.source) : child.props.source === status;
    });

    return <>{filteredItems}</>;
};

type SwitcherItemProps = {
    children: ReactNode
    source: MonopolyStatus
};

export const SwitcherItem = ({ children }: SwitcherItemProps) => <>{children}</>;
