




import {Component, Vue} from "vue-property-decorator";
import {Plans} from './Plans';
import {createPlansStore} from "@/views/Plans2020/list/store";

@Component({ components: { komponent: Plans as unknown as typeof Vue } })
export default class PlansVueStub extends Vue {
    public storeContainer = Object.freeze({ store: createPlansStore() });
}
