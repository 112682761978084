import {Customer} from "@/models/Customer";
import {createStoredRemoteDataset, StoredRemoteDataset} from "@/components/table";
import {createStore} from "effector";
import {getCustomers} from "@/api/directory";
import {FilterConditionType, FilterValueType} from "@/api/http";

export interface CustomersFilters {
    fullName: string
    shortName: string
    tin: string
    rrc: string
    regNumber: string
    spzCode: string
}

export interface CustomersStore {
    customers: StoredRemoteDataset<Customer, CustomersFilters>
    loaded: boolean
}

export const createCustomersStore = () => {
    return createStore<CustomersStore>({
        customers: createStoredRemoteDataset<Customer, CustomersFilters>({
            async load(filters, from, count) {
                return getCustomers(from, count, {
                    fullName: [{
                        type: FilterValueType.STRING,
                        conditionType: FilterConditionType.CONTAINS,
                        string: filters.fullName,
                    }],
                    shortName: [{
                        type: FilterValueType.STRING,
                        conditionType: FilterConditionType.CONTAINS,
                        string: filters.shortName,
                    }],
                    tin: [{
                        type: FilterValueType.STRING,
                        conditionType: FilterConditionType.CONTAINS,
                        string: filters.tin,
                    }],
                    rrc: [{
                        type: FilterValueType.STRING,
                        conditionType: FilterConditionType.CONTAINS,
                        string: filters.rrc,
                    }],
                    regNumber: [{
                        type: FilterValueType.STRING,
                        conditionType: FilterConditionType.CONTAINS,
                        string: filters.regNumber,
                    }],
                    spzCode: [{
                        type: FilterValueType.STRING,
                        conditionType: FilterConditionType.CONTAINS,
                        string: filters.spzCode,
                    }]
                });
            }
        }),
        loaded: false,
    });
};