import React from "react";
import { DirectPurchasesStore, toggleTwoColumnMode, addPurchaseToContract, goCreate, getSameLaw, unselectAll } from './store';
import { ContainerButtons } from "@/components/eshop";
import { Select } from "@/components/eshop/Select/Select";
import { TooltipIcon } from "@/components/TooltipIcon";
import styles from "@/components/eshop/Header.module.css";
import { usePopup } from "../Products/ProductsCard";
import { SelectedInfo } from "../Products/SelectedInfo";
import {createJointSession} from "@/views/Products/store";

export const Header = (props: { store: DirectPurchasesStore }) => {
    const selectedPurchases = props.store.selectedPurchases;
    const disableCreateContract = selectedPurchases.some(p => p.allowContract44 === false && p.allowContract223 === false);

    const [showSelected, toggleShowSelected, selectedPopupRef] = usePopup();
    const anySelected = !!props.store.selectedPurchases.length;
    return <div className={styles.filtersHeader}>
        <ContainerButtons
            onCreateJointSession={() => createJointSession()}
            showCreateJointSession
            disableCreateJointSession={false}
            onCreateSession={() => goCreate()}
            disableCreateSession={true}
            onCreateContract={() => addPurchaseToContract(selectedPurchases.map(p => ({ ...p, selectedLaw: getSameLaw([p]) ?? 'F44' })))}
            disableCreateContract={disableCreateContract || selectedPurchases.length === 0}
            showCreateContract
            showCreateSession={false}/>
        <div className={styles.panelSection}
            onMouseLeave={() => anySelected && toggleShowSelected()}
            onMouseEnter={() => anySelected && toggleShowSelected()}
        >
            Выбрано: {props.store.selectedPurchases.length} из {props.store.page.totalCount}
            {showSelected &&
                <SelectedInfo
                    unselectAll={() => unselectAll()}
                    items={props.store.selectedPurchases}
                    onClose={toggleShowSelected}
                    popupRef={selectedPopupRef} />}
        </div>
        <div className={styles.panelSection}>
            <span className={styles.sortTitle}>
                Сортировка
            </span>
            <Select value={''} options={[]} />
            <button className={styles.sortAscButton} />
        </div>
        <div className={styles.panelSection}>
            <div className={`${styles.iconButtonOneCol} ${!props.store.isFilterOpened ? styles.iconButtonOneColEnabled : ''}`} onClick={() => toggleTwoColumnMode()} />
            <div className={`${styles.iconButtonTwoCol} ${props.store.isFilterOpened ? styles.iconButtonTwoColEnabled : ''}`} onClick={() => toggleTwoColumnMode()} />
            <TooltipIcon />
        </div>
    </div>;
};
