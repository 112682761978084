import React, {useEffect, useMemo, useState} from "react";
import {ModalFC} from "@/EventBus";
import {CardModal} from "@/components/modals/CardModal";
import {RoundedButton} from "@/components/primitive/RoundedButton";
import {Expandee, HStack, VStack} from "@/components/layouts";
import {Participant} from "@/models";
import {CheckBox} from "@/components/primitive";
import {SearchBox, Spinner, SpinnerSize} from "office-ui-fabric-react";
import styles from "./UserParticipantSelectionModal.module.css";
import {getLotParticipantsBypassReady} from "@/api/directory";

export const UserParticipantSelectionModal: ModalFC<{ allowed?: Participant[] }, Participant> = x => {
    const [selected, setSelected] = useState<Participant>();
    const [filterString, setFilterString] = useState("");
    const [allowed, setAllowed] = useState<Participant[]>(x?.allowed ?? []);

    useEffect(() => {
        if (allowed.length > 0) {
            return;
        }

        getLotParticipantsBypassReady()
            .then(setAllowed);
    }, []);

    const filtered = allowed.filter(p =>
        p.fullName?.toLocaleLowerCase().includes(filterString.toLocaleLowerCase()) ||
        p.shortName?.toLocaleLowerCase().includes(filterString.toLocaleLowerCase()) ||
        p.tin?.toLocaleLowerCase().startsWith(filterString.toLocaleLowerCase()) ||
        p.regNumber?.toLocaleLowerCase().startsWith(filterString.toLocaleLowerCase())
    );

    const bottom: React.ReactNode = <HStack spacing="10px">
        <Expandee />
        <RoundedButton color="blue" title="Выбрать" disabled={!selected} onClick={() => x.done(selected)} />
        <RoundedButton color="white" title="Закрыть" onClick={() => x.done(undefined)} />
    </HStack>;

    return <CardModal title={"Выбор организации"}
                      cardContentStyle={{ padding: 0 }}
                      cardHeaderStyle={{ backgroundColor: "white" }}
                      bottom={bottom}
                      close={() => x.done(undefined)}
                      width="800px">
        <VStack>
            <HStack alignItems="center" outerClassName={styles.listItem}>
                <SearchBox style={{ width: "450px" }}
                           value={filterString}
                           onChange={e => setFilterString(e?.target.value ?? "")}
                           placeholder="Наименование / ИНН / ОГРН" />
                <Expandee />
                <CheckBox labelPreset="thinMedium">
                    Искать среди всех
                </CheckBox>
            </HStack>
            {filtered.length > 0
                ? filtered.map(p => <div key={`participant-${p.id}`}
                                               onClick={() => setSelected(p)}
                                               className={`${styles.listItem} ${selected?.id === p.id ? styles.selected : ""}`}>
                    { p.fullName }
                </div>)
                : <Spinner style={{ marginBottom: "10px" }} />
            }
        </VStack>
    </CardModal>;
};
