import React, {createRef, CSSProperties, useState} from "react";

import * as styles from "./TimePicker.module.css";
import {TextBox} from "@/components/primitive/TextBox";
import {formatDate, truncateDate, DateFormatMode} from "@/dateUtils";
import {ModalFC, showModal} from "@/EventBus";
import {Cell, DatePickerModalProps, DatePickerProps} from "@/components/primitive/DatePicker.types";
import {VGrid, HGrid} from "@/components/layouts";
import {createCells, getHeader, weekDays, getLargerMode, getAdjPeriodDate} from "@/components/primitive/DatePicker.utils";
import {j} from "@/reactUtils";
import {compareAsc, getHours, getMinutes, set} from 'date-fns';
import {Time, TimePickerModalProps, TimePickerProps} from "@/components/primitive/TimePicker/TimePicker.types";
import {Label, Slider} from "@/components/primitive";
import _ from "lodash";
import {applyN} from "@/models/parsing";

const modalWidthPx = 212;
const modalHeightPx = 162;
const marginPx = 5;

const pad = (v: number) => (v < 10) ? "0" + v : v;

export const extractTime = (d: Date): Time => ({hours: d.getHours(), minutes: d.getMinutes()});
export const formatTime = (t: Time) => `${pad(t.hours)}:${pad(t.minutes)}`;
export const appendTimeToDate = (d: Date, t: Time) => set(_.clone(d), {hours: t.hours, minutes: t.minutes});


const TimePickerModal: ModalFC<TimePickerModalProps> = x => {
    const formatted = x.value ? formatTime(x.value) : "";
    const style: CSSProperties = {
        width: modalWidthPx,
        height: modalHeightPx,
        top: x.top,
        left: x.left
    };

    const [time, setTime] = useState(x.time);
    const change = (n: Time) => {
        setTime(n);
        x.onChange(n);
    };

    return <HGrid className={styles.picker} style={style} spacing="3px" rows="1fr 2fr 1fr">
            <div className={styles.header}>
                <div className={styles.headerInner}>
                    <Label text="Выберите время" preset="boldSmall"/>
                </div>
            </div>
        <VGrid className={styles.content} columns="1fr 2fr" spacing="3px" alignItems="center">
            <Label preset="boldSmall" text="Время"/>
                <Label preset="readonlyValue" text={formatTime(time)}/>
            <Label preset="boldSmall" text="Часы"/>
                <Slider minValue={0} maxValue={23} value={time.hours} onChange={a => change({...time, hours: a})}/>
            <Label preset="boldSmall" text="Минуты"/>
                <Slider minValue={0} maxValue={59} value={time.minutes} onChange={a => change({...time, minutes: a})}/>
        </VGrid>

        <div className={styles.bottom}>
            <div className={styles.bottomInner} onClick={() => change({hours: new Date().getHours(), minutes: new Date().getMinutes()})}>
                <div className={styles.calendar}/>
                сейчас
            </div>
        </div>
    </HGrid>;
};



export const TimePicker: React.FC<TimePickerProps> = x => {
    const formatted = x.value ? formatTime(x.value) : "";
    const ref = createRef<HTMLDivElement>();

    const showPickerModal = () => {
        if (x.readonly || x.disabled) return;

        const el = ref.current!;
        const rect = el.getBoundingClientRect();

        const innerHeightPx = window.innerHeight;

        let top = rect.top + rect.height + marginPx;

        if (top + modalHeightPx > innerHeightPx) {
            if (rect.top - modalHeightPx - marginPx >= 0) {
                top = rect.top - modalHeightPx - marginPx;
            } else {
                top = rect.top - modalHeightPx / 2;
            }
        }

        const value = {hours: x.value?.hours ?? 0, minutes: x.value?.minutes ?? 0};

        return showModal(TimePickerModal, {
            value: value,
            top: top,
            left: rect.left,
            time: value,
            onChange: x.onChange ?? (() => {})
        });
    };

    const maxWidth = x.maxWidth ?? "100px";

    return <div className={styles.pickerWrapper} style={{maxWidth: maxWidth}} onClick={showPickerModal} ref={ref}>
        <TextBox value={formatted} readonly disabled={x.disabled}/>
    </div>;
};
