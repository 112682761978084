import React, {useRef, useState} from "react";
import {Checkbox} from "@/components/eshop";
import styles from "./Select.module.css";
import {TooltipIcon} from "@/components/TooltipIcon";
import {j, useOnClickOutside} from "@/reactUtils";
import {SelectionProps} from "@/components/eshop/Select/Select";


interface SelectOption {
    value: string
    name: string
    key: string
    disabled?: boolean
    tooltip?: string
}
type MultipleSelectProps = Omit<SelectionProps, "value" | "options"> & {
    values: string[]
    options: SelectOption[]
    onChange?: (value: string[]) => void
};

export const MultipleSelect = (props: MultipleSelectProps) => {
    const value = props.values;
    const [visible, setVisible] = useState(false);
    const ref = useRef<HTMLFieldSetElement | null>(null);
    const selectorWidth = ref.current?.offsetWidth ? Math.floor(ref.current?.offsetWidth) - 2 : 170;
    useOnClickOutside(ref,() => setVisible(false));

    const handleClick = (v: SelectOption) => {
        if (value.includes(v.key)) {
            props.onChange?.(value.filter(k => k !== (v.key)));
        } else {
            props.onChange?.([...value, v.key]);
        }
    };

    return (
        <div style={{width: props.width}} className={j(styles.selector__wrapper, props.small && styles.selector__wrapper_small)} onMouseOver={() =>{props.popupOnHover && setVisible(true)}} onMouseLeave={() => {props.popupOnHover && setVisible(false)}}>
            <fieldset ref={ref} className={j(styles.select__fieldset, props.disabled && styles.select__fieldset_disabled, props.fullWidth && styles.select__fieldset_fullWidth, props.small && styles.select__fieldset_small)}>
                {props.label &&
                    <legend className={j(styles.select__label, props.disabled && styles.select__label_disabled)}>
                        {props.label}
                        {props.required ? <span className={styles.required}>*</span> : ''}
                        {props.withTooltip && <TooltipIcon />}
                        {props.unit && <span className={styles.unit}>{", " + props.unit}</span>}
                    </legend>
                }
                <div className={styles.selectorContainer}>
                    <div onClick={() => !props.disabled && setVisible(!visible)}
                         className={j(styles.selector, props.disabled && styles.disabled)}>
                        <div className={styles.selectorContent}>
                            <div className={j(styles.selectorMainContent, props.placeholder && styles.placeholder)}>
                                {!props.values.length && props.placeholder}
                                {props.values.length > 0 && "Выбрано: " + props.values.length}
                            </div>
                            {!props.disabled && <div className={styles.selectorControl}>
                                <svg width="18" height="18" viewBox="0 0 24 24">
                                    <path d="M7,10l5,5,5-5Z"></path>
                                </svg>
                            </div>}
                        </div>
                        {!props.disabled && <div style={{width: selectorWidth}} className={j(styles.selectorPopup, props.small && styles.selectorPopup_small, visible ? '' : styles.selectorHidden)}>
                            {props.options.map((x, i) => (
                                <div key={i}
                                     title={x.tooltip}
                                     className={j(x.disabled ? styles.disabledOption : value.includes(x.key) ? styles.optionMultiple_selected : styles.optionMultiple)}>
                                    <Checkbox value={value.includes(x.key)} disabled={x.disabled} onChange={() => handleClick(x)} caption={x.name} />
                                </div>
                            ))}
                        </div>}
                    </div>
                </div>

            </fieldset>
        </div>
    );
};
