import React, {useEffect, useState} from "react";
import {
    unarchiveUser
} from "@/api/Admin";
import {
    Column,
    FilterColumn,
    FilteredSelectionTable, Pager,
    Table,
    useStoredRemoteDataset
} from "@/components/table";
import { Expandee, HStack, VStack } from "@/components/layouts";
import { Button } from "@/components/primitive";
import { User } from "@/models/User";
import { createArchivedUsersStore, UserFilters } from "@/views/Register/requests/store";
import { useStore } from "effector-react";
import {Card} from "@/components/Card";

export const ArchivedUsersSection: React.FC<{ reload: symbol; onReload: () => void }> = x => {
    const [filtersVisible, setFiltersVisible] = useState<boolean>(false);

    const [store] = useState(() => createArchivedUsersStore());
    const state = useStore(store);
    const rds = useStoredRemoteDataset(state.users);

    const refresh = () => {
        x.onReload();
    };

    useEffect(() => {
        rds.setCurrentPage(0, true);
    }, [x.reload]);

    const columns: Column<User>[] = [
        FilterColumn<User, UserFilters>({
            header: "ФИО",
            cell: x => <>{x.item.fullName}</>,
            filter: {
                type: "string",
                key: "lastName"
            },
        }),
        FilterColumn<User, UserFilters>({
            header: "E-mail",
            cell: x => <>{ x.item.username }</>,
            filter: {
                type: "string",
                key: "username"
            },
        }),
        Table.Column("Комментарий", x => <>{x.item.comment ?? ""}</>),
        Table.AutoColumn("Действия", x => <HStack spacing="5px">
            <Button icon="faArrowCircleUp"
                    title="Активировать"
                    onClick={() => unarchiveUser(x.item.id).then(refresh)}
                    color="green" />
        </HStack>),
    ];

    return <Card>
        <VStack spacing="15px">
            <HStack spacing="5px">
                <Expandee />
                <Button icon="aFilter" color="blue" onClick={() => setFiltersVisible(!filtersVisible)} />
            </HStack>

            <FilteredSelectionTable filterStore={rds.filterStore}
                                    mode="single"
                                    isFiltersVisible={filtersVisible}
                                    selectorPosition="hidden"
                                    dataset={rds.dataset}
                                    columns={columns} />
            <Pager remoteDataset={rds} />
        </VStack>
    </Card>;
};

