import Vue from "vue";
import Router, { RawLocation } from "vue-router";
import Pvhd from "@/views/Pvhd/Pvhd.vue";
import CreateContract from "@/views/CreateContract";
import Contracts from "@/views/Contracts";

import { ErrorHandler } from "vue-router/types/router";
import { prepareRoutedModal } from "./routed-modals";
import { LawType } from "./models/enums";

import CreateLotVueStub from "@/views/ComposedLot/create/CreateLotVueStub.vue";
import EditLotVueStub from "@/views/ComposedLot/edit/EditLotVueStub.vue";
import LotsList from "@/views/ComposedLot/list/LotsList.vue";
import ViewLotVueStub from "@/views/ComposedLot/edit/ViewLotVueStub.vue";
import PlansVueStub from "@/views/Plans2020/list/PlansVueStub.vue";
import CreatePlanVueStub from "@/views/Plans2020/create/CreatePlanVueStub.vue";
import ViewPlanVueStub from "@/views/Plans2020/view/ViewPlanVueStub.vue";
import ProceduresVueStub from "@/views/Procedures2020/list/ProceduresVueStub.vue";
import CreateProcedureVueStub from "./views/Procedures2020/view/CreateProcedureVueStub.vue";
import EditProcedureVueStub from "./views/Procedures2020/view/EditProcedureVueStub.vue";
import ViewProcedureVueStub from "./views/Procedures2020/view/ViewProcedureVueStub.vue";
import ViewTradeVueStub from "./views/Procedures2020/trade/ViewTradeVueStub.vue";
import TradesVueStub from "@/views/Procedures2020/trade/list/TradesVueStub.vue";
import ViewSubjectClassVueStubK from "@/views/ReferenceInfo/SubjectClasses/list/SubjectClassesVueStub.vue";
import ViewSubjectClassVueStubS from "@/views/ReferenceInfo/SubjectDeclarations/sections/SubjectDeclarationsViewVueStub.vue";
import ViewSubjectClassVueStubC from "@/views/ReferenceInfo/Customers/sections/CustomersViewVueStub.vue";
import CustomersVueStub from "@/views/ReferenceInfo/Customers/list/CustomersVueStub.vue";
import SuppliersVueStub from "@/views/ReferenceInfo/Suppliers/list/SuppliersVueStub.vue";
import SuppliersViewStub from "@/views/ReferenceInfo/Suppliers/sections/SuppliersViewStub.vue";
import SubjectClassesVueStub from "@/views/ReferenceInfo/SubjectClasses/sections/SubjectClassesVueStub.vue";
import SubjectDeclarationVueStub from "@/views/ReferenceInfo/SubjectDeclarations/list/SubjectDeclarationVueStub.vue";
import Services from "@/views/Services";
import Products from "@/views/Products";
import DirectPurchase from "@/views/DirectPurchase";
import Sessions from "@/views/Sessions";
import CreateSession from "./views/CreateSession";
import ConclusionRequestsVueStub from "@/views/Monopoly/list/ConclusionRequestsVueStub.vue";
import { CreateConclusionRequestsVueStub } from "@/views/Monopoly/create/CreateConclusionRequest";
import ConclusionRequestsAcceptedVueStub from "@/views/Monopoly/list/ConclusionRequestsAcceptedVueStub.vue";
import ConclusionRequestsProcessedVueStub from "@/views/Monopoly/list/ConclusionRequestsProcessedVueStub.vue";
import MonopolyObjectViewVueStub from "@/views/Monopoly/view/MonopolyObject/MonopolyObjectViewVueStub.vue";
import { PaymentsListVueStub } from "@/views/Payments/list";
import { ObligationsVueStub } from "@/views/Obligations/list";
import { PaymentViewVueStub } from "@/views/Payments/view";
import ObligationViewVueStub from "@/views/Obligations/view/ObligationViewVueStub.vue";
import ProvisionListVueStub from "@/views/Provision/list/ProvisionListVueStub.vue";
import { ProvisionViewVueStub } from "@/views/Provision/view";
import AdminPageVueStub from "@/views/Admin/AdminPageVueStub.vue";
import CreateJointSession from "@/views/CreateSession/JointSession/CreateJointSession.vue";

const createRouter = () => {
    return new ExtendedRouter({
        routes: [
            {
                path: "/",
                redirect: () => {
                    return "/plan-objects/composed-lots";
                },
            },
            {
                path: "/plan-objects",
                redirect: () => "/plan-objects/composed-lots",
            },
            {
                path: "/plan-objects/composed-lots",
                component: LotsList,
            },
            {
                path: "/plan-objects/composed-lots/create/" + LawType.F44,
                component: CreateLotVueStub,
                props: route => ({ lawType: LawType.F44, financialLimitId: route.query.financialLimitId }),
            },
            {
                path: "/plan-objects/composed-lots/create/" + LawType.F223,
                component: CreateLotVueStub,
                props: () => ({ lawType: LawType.F223 }),
            },
            {
                path: "/plan-objects/composed-lots/:objectId/edit",
                component: EditLotVueStub,
                props: true
            },
            {
                path: "/plan-objects/composed-lots/:objectId",
                component: ViewLotVueStub,
                props: true
            },
            {
                path: "/plan-objects/requests-for-conclusion/in-progress",
                component: ConclusionRequestsVueStub,
                props: () => ({ type: "in-progress" }),
            },
            {
                path: "/plan-objects/requests-for-conclusion/accepted",
                component: ConclusionRequestsAcceptedVueStub,
                props: () => ({ type: "accepted" }),
            },
            {
                path: "/plan-objects/requests-for-conclusion/processed",
                component: ConclusionRequestsProcessedVueStub,
                props: () => ({ type: "processed" }),
            },
            {
                path: "/plan-objects/requests-for-conclusion/create",
                component: CreateConclusionRequestsVueStub
            },
            {
                path: "/plan-objects/monopoly-objects/:objectId",
                component: MonopolyObjectViewVueStub,
                props: true
            },
            {
                path: "/procedures/2020",
                component: ProceduresVueStub,
            },
            {
                path: "/procedures/2020/trades",
                component: TradesVueStub,
            },
            {
                path: "/procedures/2020/create/F44",
                component: CreateProcedureVueStub,
                props: route => ({ law: LawType.F44, lotId: route.query.lotId }),
            },
            {
                path: "/procedures/2020/create/F223",
                component: CreateProcedureVueStub,
                props: route => ({ law: LawType.F223, lotId: route.query.lotId }),
            },
            {
                path: "/procedures/2020/:id/edit",
                component: EditProcedureVueStub,
                props: route => ({ id: route.params.id }),
            },
            {
                path: "/procedures/2020/:id/trade",
                component: ViewTradeVueStub,
                props: route => ({ id: route.params.id, action: "viewing" }),
            },
            {
                path: "/procedures/2020/:id",
                component: ViewProcedureVueStub,
                props: route => ({ id: route.params.id, action: "viewing" }),
            },
            {
                path: "/plans/2020",
                component: PlansVueStub,
            },
            {
                path: "/plans/2020/new",
                component: CreatePlanVueStub,
                props: route => ({ law: route.query.law }),
            },
            {
                path: "/plans/2020/:planId",
                component: ViewPlanVueStub,
                props: route => ({ planId: route.params.planId }),
            },
            {
                path: "/contracts/obligations",
                component: ObligationsVueStub
            },
            {
                path: "/contracts/obligations/:obligationId",
                component: ObligationViewVueStub,
                props: route => ({
                    obligationId: route.params.obligationId
                })

            },
            {
                path: "/contracts/payments",
                component: PaymentsListVueStub
            },
            {
                path: "/contracts/payments/:paymentId",
                component: PaymentViewVueStub,
                props: route => ({
                    paymentId: route.params.paymentId
                })
            },
            {
                path: "/contracts",
                component: Contracts,
            },
            {
                path: "/contracts/:id",
                component: CreateContract,
                props: route => ({
                    objectId: route.params.id || null,
                }),
            },
            {
                path: "/reference-info",
                redirect: "/reference-info/dict/subject-classes",
            },
            {
                path: "/reference-info/dict",
                redirect: "/reference-info/dict/subject-classes",
            },
            {
                path: "/reference-info/dict/subject-classes",
                component: ViewSubjectClassVueStubK,
            },
            {
                path: "/reference-info/dict/subject-classes/:id",
                component: SubjectClassesVueStub,
                props: route => ({
                    id: route.params.id || null,
                }),
            },
            {
                path: "/reference-info/dict/subject-declarations/:id",
                component: ViewSubjectClassVueStubS,
                props: route => ({
                    id: route.params.id || null,
                }),
            },
            {
                path: "/reference-info/dict/subject-declarations",
                component: SubjectDeclarationVueStub,
            },
            {
                path: "/reference-info/participants",
                redirect: "/reference-info/participants/customers",
            },
            {
                path: "/reference-info/participants/customers",
                component: CustomersVueStub
            },
            {
                path: "/reference-info/participants/customers/:id",
                component: ViewSubjectClassVueStubC,
                props: route => ({
                    id: route.params.id || null
                })
            },
            {
                path: "/reference-info/participants/suppliers",
                component: SuppliersVueStub,
            },
            {
                path: "/reference-info/participants/suppliers/:id",
                component: SuppliersViewStub,
                props: route => ({
                    id: route.params.id || null
                })
            },
            {
                path: "/finance",
                redirect: "/finance/pfhd",
            },
            {
                path: "/finance/moscowbudget",
                redirect: "/finance/pfhd",
            },
            {
                path: "/finance/pfhd",
                component: Pvhd,
            },
            {
                path: "/finance/federal-funds",
                redirect: "/finance/pfhd",
            },
            {
                path: "/admin",
                component: AdminPageVueStub,
                props: true
            },
            {
                path: "/shop/dict/services",
                component: Services,
                props: _ => ({
                    favorites: false
                })
            },
            {
                path: "/shop/dict/products",
                component: Products,
            },
            {
                path: "/shop/dict/direct-purchase",
                component: DirectPurchase,
            },
            {
                path: "/shop/dict",
                redirect: "/shop/dict/products",
            },
            {
                path: "/shop/favorite/products",
                component: Products,
                props: _ => ({
                    favorites: true
                })
            },
            {
                path: "/shop/favorite/direct-purchase",
                component: DirectPurchase,
                props: _ => ({
                    favorites: true
                })
            },
            {
                path: "/shop/favorite/services",
                component: Services,
                props: _ => ({
                    favorites: true
                })
            },
            {
                path: "/shop/favorite",
                redirect: "/shop/favorite/products",
            },
            {
                path: "/shop/sessions",
                component: Sessions,
            },
            {
                path: "/shop/create-session/service",
                component: CreateSession,
                props: _ => ({
                    mode: "SERVICES"
                })
            },
            {
                path: "/shop/create-session/service/:ids",
                component: CreateSession,
                props: route => ({
                    ids: route.params.ids || null,
                    mode: "SERVICES"
                })
            },
            {
                path: "/shop/create-session/product",
                component: CreateSession,
                props: _ => ({
                    mode: "PRODUCTS"
                })
            },
            {
                path: "/shop/create-session/product/:ids",
                component: CreateSession,
                props: route => ({
                    ids: route.params.ids || null,
                    mode: "PRODUCTS"
                })
            },
            {
                path: "/shop/joint-sessions/new",
                component: CreateJointSession
            },
            {
                path: "/shop/joint-sessions/:id",
                component: CreateJointSession,
                props: route => ({
                    sessionId: route.params.id || null
                })
            },
            {
                path: "/shop/create-session/purchase",
                component: CreateSession,
                props: _ => ({
                    mode: "PURCHASES"
                })
            },
            {
                path: "/shop/create-session/purchase/:ids",
                component: CreateSession,
                props: route => ({
                    ids: route.params.ids || null,
                    mode: "PURCHASES"
                })
            },
            {
                path: "/shop/sessions/:id",
                component: CreateSession,
                props: route => ({
                    sessionId: route.params.id || null,
                    service: undefined,
                })
            },
            {
                path: "/provision",
                component: ProvisionListVueStub
            },
            {
                path: "/provision/:id",
                component: ProvisionViewVueStub,
                props: route => ({
                    provisionId: route.params.id || null
                })
            },
        ],
    });
};

class ExtendedRouter extends Router {
    matcher: unknown;

    public pushRoutedModal<T extends object>(
        loc: RawLocation,
        prop: T,
        onComplete?: () => void,
        onAbort?: ErrorHandler,
    ) {
        prepareRoutedModal(prop);
        this.push(loc, onComplete, onAbort);
    }

    public resetTo(route: RawLocation) {
        this.matcher = createRouter().matcher;
        this.replace(route);
    }
}

Vue.use(ExtendedRouter);

export default createRouter();
