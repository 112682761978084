import React, {useContext} from "react";
import {Card} from "@/components/Card";
import {Field, Fields} from "@/components/form/Field";
import {FComboBox, FormStateContext} from "@/components/form";
import {Label} from "@/components/primitive";
import {formatNumber} from "@/NumberFormatting";
import Auth from "@/api/Auth";
import {User} from "@/models/User";
import {showModal} from "@/EventBus";
import {ParticipantInfo} from "@/views/Monopoly/shared/modals/ParticipantInfo";

export const InfoSection = () => {
    const state = useContext(FormStateContext);
    const orientation = state === "readonly" ? "vertical" : "horizontal";

    const { selectedParticipant } = Auth.currentUserInfo as User;

    const showParticipantInfo = async () => await showModal(ParticipantInfo, { participant: selectedParticipant });

    return <Card>
        <Fields orientation={orientation}>
            <Field title="Заказчик" preset="boldSmall">
                <Label preset="readonlyValue"
                       style={{ color: "#428bca", cursor: "pointer" }}
                       onClick={showParticipantInfo}
                       text={selectedParticipant.shortName} />
            </Field>
            <Field title="Поставщик" preset="boldSmall">
                <FComboBox options={[{ key: 1, desc: "МОЭК" }]} style={{ maxWidth: "450px" }} />
            </Field>
            <Field title="НМЦ контракта" preset="boldSmall">
                <Label preset="readonlyValue" text={formatNumber(0)} />
            </Field>
        </Fields>
    </Card>;
};
