import { IDtoActionClass } from "@/models/json";
import { IJsonFormattable } from "@/models/IJsonFormattable";
import { NoValue } from './Common';

export class ActionClass implements IJsonFormattable<IDtoActionClass> {
    constructor(
        public id: number,
        public code: string,
        public name: string,
        public description: string,
    ) {}

    public toJson(): IDtoActionClass {
        return { ...this };
    }

    public toString() {
        return this.code + " " + this.name;
    }

    // noinspection JSUnusedGlobalSymbols
    public static fromJson(dto: IDtoActionClass): ActionClass {
        if (!dto) return null!; // todo REMOVE

        return Object.assign(Object.create(ActionClass.prototype), dto, {
            // nothing
        });
    }
}
