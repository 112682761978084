export type AddressType =
    | "FIAS"
    | "BTI"
    | "OUTSIDE_OF_RF"
    | "LANDMARK";

export const AddressType: { [ key in AddressType ]: AddressType} = {
    FIAS: "FIAS",
    BTI: "BTI",
    OUTSIDE_OF_RF: "OUTSIDE_OF_RF",
    LANDMARK: "LANDMARK",
};