import {ModalFC} from "@/EventBus";
import { Expandee, HStack, VStack } from "@/components/layouts";
import {CardModal} from "@/components/modals/CardModal";
import { Column, Table } from "@/components/table";
import { ContactPerson } from "@/models/Procedures2020/ContactPerson";
import { Person } from "@/views/Procedures2020/view/sections/infoSection/SelectContactPersonModal";
import React, { useState } from "react";
import styles from '@/views/CreateSession/CreateSession.module.css';
import { PrimaryButton } from "@/components/eshop";

type PickContactModalProps = {
    existing?: Person
    persons: Person[]
};

export const PickContactModal: ModalFC<PickContactModalProps, ContactPerson> = x => {
    const columns: Column<Person>[] = [
        Table.Column("Основной", a => <div>
            {a.item.primary ? "Да" : "Нет"}
        </div>, {width: "1fr"}),
        Table.Column("Фамилия", a => <div>
            {a.item.lastName}
        </div>, {width: "3fr"}),
        Table.Column("Имя", a => <div>
            {a.item.firstName}
        </div>, {width: "3fr"}),
        Table.Column("Отчество", a => <div>
            {a.item.middleName}
        </div>, {width: "3fr"}),
        Table.Column("E-mail", a => <div>
            {a.item.mail}
        </div>, {width: "3fr"}),
        Table.Column("Телефон", a => <div>
            {a.item.phone}
        </div>, {width: "3fr"}),

    ];

    const [contactPerson, setContactPerson] = useState<Partial<ContactPerson>>(x.existing ? {
        name: `${x.existing.lastName} ${x.existing.firstName} ${x.existing.middleName}`,
        email: x.existing.mail,
        phoneNumber: x.existing.phone,
    } : {});
    const [selectedIndex, setSelectedIndex] = useState<number | undefined>(() => {
        if(!x.existing) return undefined;
        const idx = x.persons.findIndex(p => p.phone === x.existing!.phone);
        if(idx === -1) return undefined;
        return idx;
    });

    const select = (person: Person, index: number) => {
        setSelectedIndex(index);
        setContactPerson({
            email: person.mail,
            name: `${person.lastName} ${person.firstName} ${person.middleName}`,
            phoneNumber: person.phone
        });
    };

    const isOk = !!(contactPerson.name && contactPerson.email && contactPerson.phoneNumber);
    const save = () => {
        if (contactPerson.name && contactPerson.email && contactPerson.phoneNumber)
            x.done({
                name: contactPerson.name,
                email: contactPerson.email,
                phoneNumber: contactPerson.phoneNumber
            });
    };

    return (
        <CardModal title="Выбрать контактное лицо" close={() => x.done(undefined)} width="1000px">
                <table className={styles.cardTable}>
                    <thead>
                        <tr className={styles.tableRow}>
                            <th className={styles.cell}>
                                Основной
                            </th>
                            <th className={styles.cell}>
                                Фамилия
                            </th>
                            <th className={styles.cell}>
                                Имя
                            </th>
                            <th className={styles.cell}>
                                Отчество
                            </th>
                            <th className={styles.cell}>
                                Email
                            </th>
                            <th className={styles.cell}>
                                Телефон
                            </th>
                        </tr>
                    </thead>
                    <tbody>
                        {x.persons.map((p, i) => <tr key={i} className={styles.tableRow}
                            onClick={() => select(p, i)} style={i === selectedIndex ? {backgroundColor: "#f5f5f5"} : {}}>
                            <td className={`${styles.cell} ${styles.cellBody}`}>{p.primary ? "Да" : "Нет"}</td>
                            <td className={`${styles.cell} ${styles.cellBody}`}>{p.lastName}</td>
                            <td className={`${styles.cell} ${styles.cellBody}`}>{p.firstName}</td>
                            <td className={`${styles.cell} ${styles.cellBody}`}>{p.middleName}</td>
                            <td className={`${styles.cell} ${styles.cellBody}`}>{p.mail}</td>
                            <td className={`${styles.cell} ${styles.cellBody}`}>{p.phone}</td>
                        </tr>)}
                    </tbody>
                </table>
                <HStack outerStyle={{paddingTop: 30}}>
                    <Expandee/>
                    <PrimaryButton onClick={() => x.done(undefined)}>Отменить</PrimaryButton>
                    <PrimaryButton filled disabled={!isOk}  onClick={save}>Выбрать</PrimaryButton>
                </HStack>
        </CardModal>
    );
};
