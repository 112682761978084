import { DateTimeRange } from "@/models";
import { File } from "@/models/Documents";
import { IJsonFormattable } from "@/models/IJsonFormattable";
import { IDtoContractAdditionalConclusion } from "@/models/json/Contracts/IDtoContractAdditionalConclusion";
import { EnumStrings, LawType } from '../enums';

export const enum AdditionalConclusionChange {
    COST_CHANGE = "COST_CHANGE",
    SUPPLIER_CHANGE = "SUPPLIER_CHANGE",
    CUSTOMER_CHANGE = "CUSTOMER_CHANGE",
    GOODS_CHANGE = "GOODS_CHANGE",
    CONTACT_INFORMATION_CHANGE = "CONTACT_INFORMATION_CHANGE",
    TERMS_CHANGE = "TERMS_CHANGE",
    DOCUMENT_FORMS_CHANGE = "DOCUMENT_FORMS_CHANGE",
    FINANCIAL_ORDER_CHANGE = "FINANCIAL_ORDER_CHANGE",
    FINANCIAL_SOURCE_CHANGE = "FINANCIAL_SOURCE_CHANGE",
    CONDITIONS_CHANGE = "CONDITIONS_CHANGE",
    STAGE_TERMS_CHANGE = "STAGE_TERMS_CHANGE",
    CONDITIONS_CHANGE_P13 = "CONDITIONS_CHANGE_P13",
    OTHER = "OTHER",
}

export const AdditionalConclusionChangeStrings44: EnumStrings = {
    [AdditionalConclusionChange.COST_CHANGE]:
        "Изменение суммы контракта (ч.1 ст.95 44-ФЗ)",
    [AdditionalConclusionChange.SUPPLIER_CHANGE]:
        "Смена поставщика (ч.5 ст.95 44-ФЗ)",
    [AdditionalConclusionChange.CUSTOMER_CHANGE]:
        "Перемена заказчика",
    [AdditionalConclusionChange.GOODS_CHANGE]:
        "Замена поставляемых товаров (ч.7 ст.95 44-ФЗ)",
    [AdditionalConclusionChange.CONTACT_INFORMATION_CHANGE]:
        "Изменение реквизитов сторон контракта",
    [AdditionalConclusionChange.TERMS_CHANGE]:
        "Изменение сроков исполнения и действия контрактов (п.6 ч.1 ст.95 44-ФЗ: п.6 ст.161 Бюджетного кодекса Российской Федерации)",
    [AdditionalConclusionChange.DOCUMENT_FORMS_CHANGE]:
        "Корректировка форм отчетных документов",
    [AdditionalConclusionChange.FINANCIAL_ORDER_CHANGE]:
        "Изменение порядка финансирования контракта (год бюджета, источник финансирования, КБК, КОСГУ/КРП, л/с и другое)",
    [AdditionalConclusionChange.FINANCIAL_SOURCE_CHANGE]:
        "Изменение обеспечения исполнения контракта или гарантийных обязательств",
    [AdditionalConclusionChange.CONDITIONS_CHANGE]:
        "Изменение условий контракта в соответствии с ч.65 ст.112 44-ФЗ",
    [AdditionalConclusionChange.STAGE_TERMS_CHANGE]:
        "Изменение срок исполнения отдельного этапа (этапов) в рамках срока исполнения контракта в соответствии с п. 12 ч. 1 ст. 95 44-ФЗ",
    [AdditionalConclusionChange.CONDITIONS_CHANGE_P13]:
        "Изменение условий контракта в соответствии с п.13 ч.1 ст.95 44-ФЗ",
    [AdditionalConclusionChange.OTHER]:
        "​Другое",
};

export const AdditionalConclusionChangeStrings223: EnumStrings = {
    [AdditionalConclusionChange.COST_CHANGE]:
        "Изменение суммы контракта",
    [AdditionalConclusionChange.SUPPLIER_CHANGE]:
        "Смена поставщика",
    [AdditionalConclusionChange.CUSTOMER_CHANGE]:
        "Перемена заказчика",
    [AdditionalConclusionChange.CONTACT_INFORMATION_CHANGE]:
        "Изменение реквизитов сторон договора",
    [AdditionalConclusionChange.TERMS_CHANGE]:
        "Изменение сроков исполнения и действия договоров",
    [AdditionalConclusionChange.DOCUMENT_FORMS_CHANGE]:
        "Корректировка форм отчетных документов",
    [AdditionalConclusionChange.FINANCIAL_SOURCE_CHANGE]:
        "Изменение обеспечения исполнения договора",
    [AdditionalConclusionChange.FINANCIAL_ORDER_CHANGE]:
        "Изменение порядка финансирования контракта (год бюджета, источник финансирования, КБК, КОСГУ/КРП, л/с и другое)",
    [AdditionalConclusionChange.OTHER]:
        "​Другое",
};


export class AdditionalConclusionExplanationType {
    constructor(
        public id: number,
        public name: string,
        public foundingLaw: LawType,
    ) {}
}

export class ContractAdditionalConclusion implements IJsonFormattable<IDtoContractAdditionalConclusion> {
    constructor(
        public id: string,
        public createdAt: Date,
        // tslint:disable-next-line
        public number: string,
        public isElectronic: boolean,
        public date: Date,
        public terms: DateTimeRange,
        public document: File | null,
        public changes: Array<AdditionalConclusionChange>,

        public costChangeExplanation: AdditionalConclusionExplanationType | null,
        public projectDocument: File | null,
        public comment: string | null,
    ) {}

    public toJson(): IDtoContractAdditionalConclusion {
        return {
            ...this,
            createdAt: null!,
            terms: this.terms.toJson(),
            document: this.document && this.document.toJson(),
            projectDocument: this.projectDocument && this.projectDocument.toJson(),
            date: this.date && this.date.toISOString(),
        };
    }

    public static fromJson(dto: IDtoContractAdditionalConclusion): ContractAdditionalConclusion {
        return new ContractAdditionalConclusion(
            dto.id,
            new Date(dto.createdAt),
            dto.number,
            dto.isElectronic,
            new Date(dto.date),
            DateTimeRange.fromJson(dto.terms),
            dto.document && File.fromJson(dto.document),
            dto.changes,
            dto.costChangeExplanation && new AdditionalConclusionExplanationType(
                dto.costChangeExplanation.id,
                dto.costChangeExplanation.name,
                dto.costChangeExplanation.foundingLaw,
            ),
            dto.projectDocument && File.fromJson(dto.projectDocument),
            dto.comment
        );
    }

    public static empty(): ContractAdditionalConclusion {
        return new ContractAdditionalConclusion(
            null!,
            null!,
            '',
            false,
            null!,
            null!,
            null!,
            [],
            null,
            null,
            null
        );
    }
}
