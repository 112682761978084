import { IJsonFormattable } from '@/models/IJsonFormattable';
import { IDtoBankAccount } from './json/IDtoBankAccount';

export class BankAccount implements IJsonFormattable<IDtoBankAccount> {
    constructor(
        public id: number,
        
        public accountNumber: string,
        public transactionalAccountNumber: string,
        public correspondentAccount: string,
        public bankName: string,
        public bin: string,
        public receiver: string,
        public city: string,
    ) {}
    
    public toJson(): IDtoBankAccount {
        return {
            ...this
        };
    }
    
    public static fromJson(dto: IDtoBankAccount): BankAccount {
        return Object.assign(Object.create(BankAccount.prototype), {
            ...dto
        });
    }
}
