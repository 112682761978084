



















































import {Component, Prop, Vue} from "vue-property-decorator";
import FormTable from "@/components/vue/FormTable.vue";
import CardModal from "@/components/CardModal.vue";
import StageTable from "./StageTable.vue";
import {ModalResult} from '@/view-models/ModalRequest';
import {ContractDocument, ContractStage, ContractStageAcceptanceInfo} from '@/models/Contracts';
import {ContractScope} from '../ContractScope';
import {StageAcceptance} from "@/api/Stages";
import EventBus, {showModal} from '@/EventBus';
import {hs} from '@/components/TableHelpers';

import AddDocument from "../AddDocument.vue";
import SelectExpert from "./SelectExpert";
import {Participant} from '@/models';
import {ConfirmationModal} from "@/views/Contracts/modals/ConfirmationModal";

@Component({ components: { FormTable, CardModal, StageTable } })
export default class AddAcceptance extends Vue {
    @Prop() private prop!: { scope: ContractScope; stage: ContractStage; source: ContractStageAcceptanceInfo | null};

    public get editable() { return this.prop.stage.status === 'CREATING' || this.prop.stage.status === 'CREATING_FIRST' }

    public source = new ContractStageAcceptanceInfo(
        this.prop.source ? this.prop.source.id : null!,
        this.prop.source ? this.prop.source.stageId : null!,
        this.prop.source ? this.prop.source.createdAt : null!,
        this.prop.source ? this.prop.source.documents : [],
        this.prop.source ? this.prop.source.violationsDetected : false,
        this.prop.source ? this.prop.source.isExternal : false,
        this.prop.source ? this.prop.source.expert : null!,
        this.prop.source ? this.prop.source.expertContractNumber : null!,
    );

    public documentsHeaders = hs<ContractDocument>(
        hs.any("№", (_, n) => (n + 1).toString(), "auto"),
        hs.any("Тип документа", x => x.type.name),
        hs.any("Номер документа", x => x.documentNumber),
        hs.date("Дата документа", x => x.date),
        hs.any("Наименование документа", x => x.file.comment)
    );

    public loading = false;

    public async addEditDocument(doc: ContractDocument | null) {
        const props = {
            contract: this.prop.scope.contract,
            document: doc,
            noPost: !this.prop.source,
            createAction: (r: ContractDocument) => StageAcceptance.addDocument(this.prop.source!.id, r),
            documentGroup: "execution",
            blockType: "acceptance"
        };
        const result = await EventBus.callModal<typeof props, ContractDocument>(AddDocument, props);

        if (result.isOk) {
            const r = result.getResult();
            if (!doc) {
                this.source.documents.push(r);
            } else {
                const ix = this.source.documents.findIndex(x => x.id === r.id);
                this.source.documents.splice(ix, 1, r);
            }
        }
    }

    public async deleteDocument(doc: ContractDocument) {
        const confirmation = await showModal(ConfirmationModal, {title: "Удаление", text: "Вы уверены, что хотите удалить документ?"});
        if (!confirmation)
            return;

        if (!this.prop.source) {
            const ix = this.source.documents.indexOf(doc);
            if (ix !== -1)
                this.source.documents.splice(ix, 1);
        } else {
            await StageAcceptance.deleteDocument(this.prop.source.id, doc);

            const ix = this.source.documents.indexOf(doc);
            if (ix !== -1) {
                this.source.documents.splice(ix, 1);
            }
        }
    }

    public async pickExpert() {
        const r = await EventBus.callModal<null, Participant>(SelectExpert, null);
        if (r.isOk) this.source.expert = r.getResult();
    }

    public async saveAndClose() {
        // if (!this.documents.length) {
        //     // TODO: modal - В текущее исполнение поставщиком необходимо добавить хотя бы один документ.
        //     return
        // }

        this.loading = true;

        try {
            if (this.prop.source) {
                const src = await StageAcceptance.update(this.source);
                this.$emit("close-modal", ModalResult.ok(src));
            } else {
                const src = await StageAcceptance.create(this.prop.stage.id, this.source);
                this.$emit("close-modal", ModalResult.ok(src));
            }
        } finally {
            this.loading = false;
        }
    }
}
