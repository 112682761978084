import React from "react";
import { TextAlignProperty } from "csstype";

interface SliderProps {
    value?: number
    placeholder?: string
    multiline?: boolean
    disabled?: boolean
    alignment?: TextAlignProperty
    minValue?: number
    maxValue?: number
    onChange?: (value: number) => void
}

export const Slider: React.FC<SliderProps> = x => {
    return <input
        className="input"
        type="range"
        min={x.minValue}
        max={x.maxValue}
        style={{ textAlign: x.alignment }}
        value={x.value}
        placeholder={x.placeholder}
        disabled={x.disabled}
        onChange={e => x.onChange?.(e.target.valueAsNumber)}
    />;
};
