import React, { useState } from "react";
import { ModalFC } from "@/EventBus";
import { CardModal } from "@/components/modals/CardModal";
import { BankAccount } from "@/models/BankAccount";
import { Grid } from "@/components/layouts";

type DetailedInfoType = {
    name: string
    shortName: string
    inn: number
    kpp: number
    ogrn: string
    okpo: number
    unk: number
    legalAddress: string
    factAddress: string
};

export const ShowAccountInfoDetailsModal: ModalFC<{account: BankAccount | undefined}, void> = x => {
    const [detailedInfo, setDetailedInfo] = useState<DetailedInfoType>({
        name: x.account?.receiver ?? "",
        shortName: "",
        inn: x.account?.id ? x.account.id * 12345 : 659283771238,
        kpp: x.account?.id ? x.account.id * 2134 : 789128641123,
        ogrn: x.account?.id ? `${x.account.id}${x.account.id.toString().split('').reverse().join('')}${x.account.id}` : "4681098237281376811",
        okpo: x.account?.id ? x.account.id * 344 : 379042813,
        unk: x.account?.id ? x.account.id : 4168312,
        legalAddress: "146893, Москва, ул.Введенская д.14",
        factAddress: "Москва, ул.Введенская д.14"
    });

    if(!x.account) return <div>Информация о заказчике отсутствует</div>;

    return (
        <CardModal title="Информация о заказчике" close={() => x.done()} width="1000px">
            <Grid orientation="vertical" spacing="20px" areas={"1fr 1fr"}>
                <div style={{color: "#999"}}>Наименование</div>
                <div>{detailedInfo.name ? detailedInfo.name : <>&mdash;</>}</div>
                <div style={{color: "#999"}}>Краткое наименование</div>
                <div>{detailedInfo.shortName ? detailedInfo.shortName : <>&mdash;</>}</div>
                <div style={{color: "#999"}}>ИНН</div>
                <div>{detailedInfo.inn}</div>
                <div style={{color: "#999"}}>КПП</div>
                <div>{detailedInfo.kpp}</div>
                <div style={{color: "#999"}}>ОГРН</div>
                <div>{detailedInfo.ogrn}</div>
                <div style={{color: "#999"}}>ОКПО</div>
                <div>{detailedInfo.okpo}</div>
                <div style={{color: "#999"}}>УНК</div>
                <div>{detailedInfo.unk}</div>
                <div style={{color: "#999"}}>Юридический адрес</div>
                <div>{detailedInfo.legalAddress}</div>
                <div style={{color: "#999"}}>Фактический адрес</div>
                <div>{detailedInfo.factAddress}</div>
            </Grid>
        </CardModal>
    );
};