















import { Component, Vue, Prop } from "vue-property-decorator";

@Component
export default class ProvisionSidebar extends Vue {
    @Prop({ default: false })
    public collapsed!: boolean;

    public toggle() {
        this.collapsed = !this.collapsed;
        this.$emit("toggle", this.collapsed);
    }
}
