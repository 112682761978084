import React from "react";
import { ModalFC } from "@/EventBus";
import { HStack } from "@/components/layouts";
import { Button, Label } from "@/components/primitive";
import { CardModal } from "@/components/modals/CardModal";

export const SupplyStageConfirmModal: ModalFC<undefined, boolean> = ({ done }) => {
    const bottom = (
        <HStack reverse>
            <Button icon="faCheck" title="Продолжить" onClick={() => done(true)} />
        </HStack>
    );

    return (
        <CardModal title="Предупреждение"
                   close={() => done(false)}
                   width="60%"
                   bottom={bottom}>
            <Label preset="readonlyValue">Во избежание ошибок округления, объем следует указывать целочисленным.</Label>
        </CardModal>
    );
};
