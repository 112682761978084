import { convertPaged, IPagedItems } from "./Pagination";
import { http, IFilterObject, FilterValueType, FilterConditionType } from "./http";
import { Participant } from '@/models';
import { SingleProviderReason } from '@/models/SingleProviderReason';
import { IDtoSingleProviderReason } from '@/models/json/IDtoSingleProviderReason';
import { IDtoBankAccount } from '@/models/json/IDtoBankAccount';
import { BankAccount } from '@/models/BankAccount';
import {LawType} from "@/models/enums";

export default class {
    public static async get(from: number, count: number, filters: IFilterObject): Promise<IPagedItems<Participant>> {
        return convertPaged(
            await http.getPaged(
                "/api/directory/participants",
                {
                    from: from,
                    count: count,
                    filters: filters
                },
                true,
            ),
            Participant,
        );
    }

    public static async getSpecial(from: number, count: number, filters?: IFilterObject): Promise<IPagedItems<Participant>> {
        const specFilter = {
            type: FilterValueType.BOOLEAN,
            conditionType: FilterConditionType.EQUAL,
            boolean: true,
        };

        return convertPaged(
            await http.getPaged(
                "/api/directory/participants",
                {
                    from: from,
                    count: count,
                    filters: filters ? { ...filters, special: [specFilter] } : { special: [specFilter] }
                },
                true,
            ),
            Participant,
        );
    }

    public static async getSingleProviderReasons(participantId: number, law?: LawType): Promise<SingleProviderReason[]> {
        return (await http.get(`/api/directory/singleProviderReasons/${participantId}${law ? "/" + law : ""}`, true))
            .map((x: IDtoSingleProviderReason) => SingleProviderReason.fromJson(x));
    }

    public static async getAccounts(participantId: number): Promise<BankAccount[]> {
        return (await http.get(`/api/directory/accounts/${participantId}`, true))
            .map((x: IDtoBankAccount) => BankAccount.fromJson(x));
    }

    public static async getByTIN(from: number, count: number, tin: string, filters?: IFilterObject): Promise<IPagedItems<Participant>> {
        const tinFilter = {
            type: FilterValueType.STRING,
            conditionType: FilterConditionType.EQUAL,
            string: tin,
        };

        return convertPaged(
            await http.getPaged(
                "/api/directory/participants",
                {
                    from: from,
                    count: count,
                    filters: filters ? { ...filters, tin: [tinFilter] } : { tin: [tinFilter] }
                },
                true,
            ),
            Participant,
        );
    }
}
