import {ModalFC} from "@/EventBus";
import {Expandee, HStack, VStack} from "@/components/layouts";
import {Button, CheckBox, DatePicker} from "@/components/primitive";
import {CardModal} from "@/components/modals/CardModal";
import React, {useEffect, useState} from "react";
import {ProviderSelectionType} from "@/models/ComposedLots";
import {Field, Fields} from "@/components/form/Field";
import {PublishTerms} from "@/models/Procedures2020/PublishTerms";
import {TimePicker} from "@/components/primitive/TimePicker/TimePicker";
import {addBusinessDays} from "date-fns";
import Decimal from "decimal.js";
import { addDays, addBusinessDays as fAddBusinessDays } from "date-fns/fp";
import {LawType} from "@/models/enums";
import {observer} from "mobx-react";
import {makeAutoObservable} from "mobx";

/*
Заполнение сроков проведения
Дата публикации извещения на официальном сайте
Период предоставления разъяснений положений документации
Прием заявок

Дата окончания рассмотрения первых частей заявок
Дата проведения электронного аукциона
 */

type Mutable<Type> = {
    -readonly [Key in keyof Type]: Type[Key];
};

type CommonProviders = "E_AUC" | "E_EXAM" | "E_QUOTATIONS_REQUEST" | "E_OFFERS_REQUEST" | "QUOTATIONS_REQUEST";
type CommonPublishTerms = Mutable<Partial<PublishTerms> & { documentExplanationRangeFrom?: Date; documentExplanationRangeTo?: Date }>;
type DatePeriod = { min?: Date; max?: Date };
type FirstReqProviders = "E_EXAM" | "E_AUC";
type SecondReqProviders = "E_EXAM";

// ???
const implementPeriodFrom = (initialDate: Date = new Date()) =>
    (minFn?: CurriedFn1<Date, Date>, maxFn?: CurriedFn1<Date, Date>) => ({
        min: minFn?.(initialDate) ?? initialDate,
        max: maxFn?.(initialDate)
    });

class ProcedurePublishDateModalViewModel {
    constructor(private readonly init: ModalProps) {
        makeAutoObservable(this);
    }

    public readonly needDocExplanation = this.init.providerSelectionType === "E_EXAM" || this.init.providerSelectionType === "E_AUC";
    public readonly needFirstReq = this.init.providerSelectionType === "E_EXAM" || this.init.providerSelectionType === "E_AUC" && this.init.law != LawType.F44;
    public readonly needSecondReq = this.init.providerSelectionType === "E_EXAM";
    public readonly needFinalOffers = this.init.providerSelectionType === "E_EXAM";
    public readonly needAuc = this.init.providerSelectionType === "E_AUC";

    public readonly providerSelectionType = this.init.providerSelectionType as CommonProviders;
    public readonly totalPrice = this.init.totalPrice;

    public publishTerms: CommonPublishTerms = this.init.terms ? {
        publishDate: this.init.terms.publishDate,
        executionDate: this.init.terms.executionDate,
        finalOffersDate: this.init.terms.finalOffersDate,
        firstRequestConsiderationFinishDate: this.init.terms.firstRequestConsiderationFinishDate,
        secondRequestConsiderationFinishDate: this.init.terms.secondRequestConsiderationFinishDate,
        requestAcceptRangeFrom: this.init.terms.requestAcceptRangeFrom,
        requestAcceptRangeTo: this.init.terms.requestAcceptRangeTo,
        documentExplanationRangeFrom: this.init.terms.documentExplanationRangeFrom,
        documentExplanationRangeTo: this.init.terms.documentExplanationRangeTo,
        requestAcceptRangeFromTime: this.init.terms.requestAcceptRangeFromTime,
        requestAcceptRangeToTime: this.init.terms.requestAcceptRangeToTime,
    } : {
        publishDate: new Date(),
        requestAcceptRangeFrom: new Date(),
        documentExplanationRangeFrom: this.needDocExplanation
            ? new Date()
            : undefined,
        documentExplanationRangeTo: this.needDocExplanation
            ? addDays(4, new Date())
            : undefined,
        requestAcceptRangeFromTime: {hours: 9, minutes: 0},
        requestAcceptRangeToTime: {hours: 9, minutes: 0},
    };

    public get periodFromRequestAcceptRange() { return implementPeriodFrom(this.publishTerms?.requestAcceptRangeFrom) }

    public get requestAcceptRangePeriod(): Record<CommonProviders, DatePeriod> {
        return {
            E_AUC: this.periodFromRequestAcceptRange(
                this.totalPrice?.lte(30e6) ? addDays(8) : addDays(15)
            ),
            E_EXAM: this.periodFromRequestAcceptRange(addDays(16)),
            E_OFFERS_REQUEST: this.periodFromRequestAcceptRange(
                this.totalPrice?.lte(15e6) ? fAddBusinessDays(5) : fAddBusinessDays(7)
            ),
            E_QUOTATIONS_REQUEST: this.periodFromRequestAcceptRange(
                this.totalPrice?.lte(7e6) ? fAddBusinessDays(5) : fAddBusinessDays(6)
            ),
            QUOTATIONS_REQUEST: this.periodFromRequestAcceptRange(
                this.totalPrice?.lte(7e6) ? fAddBusinessDays(5) : fAddBusinessDays(6)
            )
        };
    }

    // clear all subscriptions
    public dispose = () => {};
}

interface ModalProps {
    terms?: PublishTerms
    providerSelectionType: ProviderSelectionType
    totalPrice?: Decimal
    law: LawType
}

export const ProcedurePublishDateModal: ModalFC<ModalProps, PublishTerms> = observer(x => {
    const [vm, setVm] = useState<ProcedurePublishDateModalViewModel>();
    useEffect(() => {
        const _vm = new ProcedurePublishDateModalViewModel(x);
        setVm(_vm);
        return () => {
            _vm.dispose();
        };
    }, []);

    const [fill, setFill] = useState(false);

    if (!vm) return <></>;

    const periodFromFirstRequestConsiderationFinishDate = implementPeriodFrom(vm.publishTerms.requestAcceptRangeTo);
    const firstRequestConsiderationFinishDatePeriod: { [key in FirstReqProviders]: DatePeriod } = {
        E_AUC: periodFromFirstRequestConsiderationFinishDate(undefined, fAddBusinessDays(1)),
        E_EXAM: periodFromFirstRequestConsiderationFinishDate(undefined, fAddBusinessDays(1))
    };

    const periodFromSecondRequestConsiderationFinishDate = implementPeriodFrom(vm.publishTerms.firstRequestConsiderationFinishDate);
    const secondRequestConsiderationFinishDatePeriod: { [key in SecondReqProviders]: DatePeriod } = {
        E_EXAM: periodFromSecondRequestConsiderationFinishDate(undefined, fAddBusinessDays(3))
    };

    const periodDocumentExplanationRange = implementPeriodFrom(vm.publishTerms.requestAcceptRangeTo);
    const documentExplanationFinishDatePeriod: { [key in FirstReqProviders]: DatePeriod } = {
        E_AUC: periodDocumentExplanationRange(undefined, fAddBusinessDays(-3)),
        E_EXAM: periodDocumentExplanationRange(undefined, fAddBusinessDays(-3))
    };

    const fields = <>
        <Field preset="boldSmall" title="Дата публикации извещения на официальном сайте">
            <DatePicker value={vm.publishTerms?.publishDate}
                        onChange={d => { vm.publishTerms.publishDate = d }} />
        </Field>
        {
            vm.needDocExplanation && <>
                <Field preset="boldSmall" title="Период предоставления разъяснений положений документации">
                    <HStack spacing="10px" alignItems="center">
                        <div style={{width: "20px"}}>С</div>
                        <DatePicker value={vm.publishTerms.documentExplanationRangeFrom}
                                    disabled />
                        <div>по</div>
                        <DatePicker value={vm.publishTerms.requestAcceptRangeTo
                                        ? addBusinessDays(vm.publishTerms.requestAcceptRangeTo, -3)
                                        : undefined}
                                    disabled />
                    </HStack>
                </Field>
                </>
        }
        <Field preset="boldSmall" title="Прием заявок">
            <HStack spacing="10px" alignItems="center">
                <div style={{width: "20px"}}>
                    С
                </div>
                <DatePicker value={vm.publishTerms.requestAcceptRangeFrom}
                            disabled
                            onChange={d => { vm.publishTerms.requestAcceptRangeFrom = d } }/>
                <TimePicker value={vm.publishTerms.requestAcceptRangeFromTime} onChange={d => { vm.publishTerms.requestAcceptRangeFromTime = d }}/>
            </HStack>
        </Field>
        <Field title="">
            <HStack  spacing="10px" alignItems="center">
                <div style={{width: "20px"}}>
                    по
                </div>
                <DatePicker value={vm.publishTerms.requestAcceptRangeTo}
                            minDate={vm.requestAcceptRangePeriod?.[vm.providerSelectionType]?.min}
                            maxDate={vm.requestAcceptRangePeriod?.[vm.providerSelectionType]?.max}
                            onChange={d => {
                                vm.publishTerms.requestAcceptRangeTo = d;
                                if (d !== undefined) {
                                    vm.publishTerms.executionDate = addBusinessDays(d, 1);
                                    vm.publishTerms.firstRequestConsiderationFinishDate =  addBusinessDays(d, 1);
                                } else {
                                    vm.publishTerms.executionDate = undefined;
                                    vm.publishTerms.firstRequestConsiderationFinishDate =  undefined;
                                }
                            }} />
                <TimePicker value={vm.publishTerms.requestAcceptRangeToTime} onChange={d => { vm.publishTerms.requestAcceptRangeToTime = d }}/>
            </HStack>
        </Field>
        {
            vm.needFirstReq &&
                <Field preset="boldSmall" title="Дата окончания рассмотрения первых частей заявок">
                    <DatePicker value={vm.publishTerms.firstRequestConsiderationFinishDate}
                                minDate={firstRequestConsiderationFinishDatePeriod?.[vm.providerSelectionType as FirstReqProviders].min}
                                maxDate={firstRequestConsiderationFinishDatePeriod?.[vm.providerSelectionType as FirstReqProviders].max}
                                onChange={d => {
                                    if (d !== undefined) { vm.publishTerms.executionDate = addBusinessDays(d, 1) }
                                    vm.publishTerms.firstRequestConsiderationFinishDate = d;
                                }} />
                </Field>
        }
        {
            vm.needFinalOffers &&
                <Field preset="boldSmall" title="Дата подачи окончательных предложений о цене контракта">
                    <DatePicker value={vm.publishTerms.firstRequestConsiderationFinishDate
                        ? addBusinessDays(vm.publishTerms.firstRequestConsiderationFinishDate, 1)
                        : undefined}
                                disabled />
                </Field>
        }
        {
            vm.needSecondReq &&
                <Field preset="boldSmall" title="Дата окончания рассмотрения и оценки вторых частей заявок">
                    <DatePicker value={vm.publishTerms.secondRequestConsiderationFinishDate}
                                minDate={vm.publishTerms.firstRequestConsiderationFinishDate
                                    ? addBusinessDays(vm.publishTerms.firstRequestConsiderationFinishDate, 2)
                                    : undefined}
                                maxDate={secondRequestConsiderationFinishDatePeriod[vm.providerSelectionType as SecondReqProviders].max}
                                onChange={d => { vm.publishTerms.secondRequestConsiderationFinishDate = d }}/>
                </Field>
        }
        {
            vm.needAuc &&
                <Field preset="boldSmall" title="Дата подведения итогов определения поставщика">
                    <DatePicker value={vm.publishTerms.executionDate}
                                minDate={vm.publishTerms.requestAcceptRangeTo
                                    ? vm.publishTerms.requestAcceptRangeTo
                                    : undefined}
                                maxDate={vm.publishTerms.requestAcceptRangeTo
                                    ? addDays(7, vm.publishTerms.requestAcceptRangeTo)
                                    : undefined}
                                onChange={d => { vm.publishTerms.executionDate = d }}
                                disabled
                    />
                </Field>
        }

    </>;

    const bottom = <HStack spacing={"10px"}>
        <Expandee/>
        <Button color="green"
                title="Сохранить"
                onClick={() => x.done(vm?.publishTerms as PublishTerms)}/>
        <Button color="red"
                icon="aClose"
                onClick={() => x.done(undefined)} />
    </HStack>;

    return <CardModal title="Сроки проведения"
                      close={() => x.done(undefined)}
                      width="70%"
                      bottom={bottom}>
        <VStack spacing={"15px"}>
            <Fields orientation="horizontal">
                <Field preset="boldSmall" title="Заполнение сроков проведения">
                    <CheckBox value={fill}
                              onChange={setFill}/>
                </Field>
                {
                    fill && fields
                }
            </Fields>
        </VStack>
    </CardModal>;
});
