import { IFilterObject, FilterValueType, FilterConditionType } from "@/api/http";
import { PageOfServices, Service, ServicesController } from "@/api/Services";
import { firstMaybe } from "@/arrayUtils";
import { ISubjectClassContainer } from "@/components/eshop/SubjectClassFilterStore";
import { showModal } from "@/EventBus";
import { debounced } from "@/functionUtils";
import router from "@/router";
import { createEvent, createEffect, createStore } from "effector";
import { ErrorModal } from "../Contracts/modals/ConfirmationModal";

export type ServicesFilterStore = {
    subject: string | null
    kpgz: ISubjectClassContainer | null
    id: number | null
    favorites: boolean
    pageSize: number
};

export const toggleFilterOpened = createEvent();
export const toggleNameSelected = createEvent();
export const toggleShowInfo = createEvent();

export const loadServicesPage = createEffect({
    name: "loadServicesPage",
    handler: async (payload: { page: number }) => {
        const filtersState = $filters.getState();
        return await ServicesController.get({ from: payload.page * filtersState.pageSize, count: filtersState.pageSize }, filtersState.favorites);
    }
});

export const setFavorite = createEffect({
    name: "setFavorite",
    handler: async (payload: { id: number; favorite: boolean }) => {

        return await ServicesController.setFavorite(
            payload.id, payload.favorite
        );
    }
});

export const changeSubject = createEvent<string>();
export const changeId = createEvent<number>();
export const changeKpgz = createEvent<ISubjectClassContainer | null>();
export const changeFavorites = createEvent<boolean>();
export const changePageSize = createEffect({
    name: "changePageSize",
    handler: (payload: { pageSize: number }) => {
        return payload.pageSize;
    }
});

const createFilterStore = () =>
    createStore<ServicesFilterStore>({
        subject: null,
        kpgz: null,
        id: null,
        favorites: false,
        pageSize: 10
    })
        .on(changeSubject, (state, subject) => {
            timeout();
            return ({ ...state, subject });
        })
        .on(changeId, (state, id) => {
            timeout();
            return ({ ...state, id });
        })
        .on(changeKpgz, (state, kpgz) => {
            timeout();
            return ({ ...state, kpgz });
        })
        .on(changeFavorites, (state, favorites) => ({ ...state, favorites }))
        .on(changePageSize, (state, { pageSize }) => {
            return { ...state, pageSize };
        })
        .on(changePageSize.done, (state) => {
            searchServices({ page: 0 });
            return { ...state };
        });

export const $filters = createFilterStore();

export const goCreate = createEvent();

export const onSelectItem = createEvent<Service>();

export const searchServices = createEffect({
    name: "searchServices",
    handler: async (payload: { page: number }) => {
        const filtersState = $filters.getState();
        const filters: IFilterObject = {};

        if (filtersState.subject) {
            filters["subject"] = [{
                type: FilterValueType.STRING,
                conditionType: FilterConditionType.CONTAINS,
                string: filtersState.subject
            }];
        }

        if (filtersState.id) {
            filters["id"] = [{
                type: FilterValueType.LONGINT,
                conditionType: FilterConditionType.CONTAINS,
                longint: filtersState.id
            }];
        }

        if (filtersState.kpgz?.object.code) {
            filters["kpgzCode"] = [{
                type: FilterValueType.STRING,
                conditionType: FilterConditionType.STARTS_WITH,
                string: filtersState.kpgz.object.code
            }];
        }

        return await ServicesController.get({
            from: payload.page * filtersState.pageSize,
            count: filtersState.pageSize,
            filters: filters
        }, filtersState.favorites);
    }
});

export type ServicesStore = {
    isFilterOpened: boolean
    isSelectedName: boolean
    showInfo: boolean
    page: PageOfServices
    selectedServices: Service[]
};

const createServicesStore = () =>
    createStore<ServicesStore>({
        isFilterOpened: false,
        isSelectedName: true,
        showInfo: false,
        selectedServices: [],
        page: {
            totalCount: 0,
            from: 0,
            to: 0,
            items: []
        }
    })
        .on(goCreate, (state) => {
            const kpgzGroup = firstMaybe(state.selectedServices)?.subjectClass.code.slice(0, 5) || '';
            const isValidKpgz = state.selectedServices.every(x => x.subjectClass.code.slice(0, 5) === kpgzGroup);
            if (!isValidKpgz) {
                showModal(ErrorModal, { text: 'Невозможно создать одну котировочную сессию на основе СПГЗ с разными КПГЗ' });
                return;
            }
            router.replace(`/shop/create-session/service/${state.selectedServices.map(s => s.id).join('&')}`);
            return { ...state };
        })
        .on(onSelectItem, (state, service) => {
            const index = state.selectedServices.findIndex(p => p.id === service.id);
            if (index === -1) {
                state.selectedServices.push(service);
            } else {
                state.selectedServices.splice(index, 1);
            }
            return { ...state, selectedServices: state.selectedServices };
        })
        .on(loadServicesPage.done, (state, { result }) => ({ ...state, page: result, }))
        .on(searchServices.done, (state, { result }) => ({ ...state, page: result }))
        .on(toggleFilterOpened, state => ({ ...state, isFilterOpened: !state.isFilterOpened }))
        .on(toggleShowInfo, state => ({ ...state, showInfo: !state.showInfo }))
        .on(toggleNameSelected, state => ({ ...state, isSelectedName: !state.isSelectedName }));

export const $services = createServicesStore();


const timeout = debounced(1500, () => { searchServices({ page: 0 }) });
