import { Component, Vue, Mixins, Inject, Prop, Provide } from "vue-property-decorator";

export const formColumnsContext = Symbol("formColumnsContext");
export const fieldLayoutContext = Symbol("fieldLayoutContext");
export const fieldStateContext = Symbol("fieldStateContext");
export const fieldRatioContext = Symbol("fieldRatioContext");
export const labelSpanContext = Symbol("labelSpanContext");
export const valueSpanContext = Symbol("valueSpanContext");

export type FieldLayout = "vertical" | "horizontal";
export type FieldState = "enabled" | "disabled" | "readonly";
export type FieldColor = "normal" | "grey";
export type FieldAlignment = "left" | "right";
export type FieldWidth = "wide" | "compact";
export type FieldRatio = [number, number];

@Component
export class FComponent extends Vue {
    @Inject({ from: fieldLayoutContext, default: () => () => "horizontal" }) public injectedLayout!: () => FieldLayout;
    @Inject({ from: fieldRatioContext, default: () => () => [1, 3] }) public injectedRatio!: () =>  FieldRatio;
    @Inject({ from: fieldStateContext, default: () => () => "readonly" }) public injectedState!: () => FieldState;

    @Prop() public layout?: FieldLayout;
    @Prop() public ratio?: FieldRatio;
    @Prop() public state?: FieldState;

    @Provide(fieldLayoutContext) public get resolvedLayout(): () => FieldLayout {
        return () => this.layout || this.injectedLayout();
    }

    @Provide(fieldRatioContext) public get resolvedRatio(): () => FieldRatio {
        return () => this.ratio || this.injectedRatio();
    }

    @Provide(fieldStateContext) public get resolvedState(): () => FieldState {
        return () => this.state || this.injectedState();
    }

    public get evaluatedLayout() { return this.resolvedLayout() }
    public get evaluatedRatio() { return this.resolvedRatio() }
    public get evaluatedState() { return this.resolvedState() }

    public get evaluatedInjectedLayout() { return this.injectedLayout() }
    public get evaluatedInjectedRatio() { return this.injectedRatio() }
    public get evaluatedInjectedState() { return this.injectedState() }

    public get disabled() {
        return this.resolvedState() === "disabled";
    }

    public get readonly() {
        return this.resolvedState() === "readonly";
    }

    public get enabled() {
        return this.resolvedState() === "enabled";
    }

    @Inject({ from: formColumnsContext, default: () => () => 1 }) public injectedColumns!: () => number;
    @Prop() public span?: number;
    @Prop({ default: false }) public invalid!: boolean;
    @Prop() public value!: unknown;

    @Prop() public gridColumn?: string;

    public get resolvedGridColumn() {
        return this.gridColumn || `auto / span ${this.span || 1}`;
    }

    public get classes() {
        const { f, resolvedState, resolvedLayout, disabled } = this;

        return [f(resolvedState()), f(resolvedLayout())];
    }

    public f(x: string) {
        return `f-${x}`;
    }
}
