import React, {
    createRef, FC,
    forwardRef,
    RefObject,
    useEffect, useMemo,
    useState
} from "react";
import * as styles from "./Form.module.css";
import {VisibilityProperty} from "csstype";
import {CardTitleContext} from '../Card';
import router from "@/router";
import {createScrollRef, ScrollBox, ScrollRef} from "@/components/ScrollBox";

interface FormProps {
    header?: React.ReactNode
    footer?: React.ReactNode
    navInset?: React.ReactNode

    tabs: Tab[]
    hideTabs?: boolean
    onTabChange?: (tab: Tab | undefined) => void
}

export interface Tab {
    id: string
    name: string
    sections: Section[]
}

export interface Section {
    name: string
    hiddenInHeader?: boolean
    children: React.ReactNode
}

const notNull: <T>(t: T | null | undefined, msg: string) => T = (t, m) => {
    if (t === null || t === undefined) {
        throw new Error(m);
    }

    return t;
};

interface Form extends FC<FormProps> {
    Tab: (id: string, name: string, sections: Section[]) => Tab
    HiddenSection: (name: string, children: React.ReactNode) => Section
    Section: (name: string, children: React.ReactNode) => Section
}

export const Form: Form = x => {
    const tabs = x.tabs;

    const routedPage = router.currentRoute.query.page as string | undefined;

    const [selectedTabId, setSelectedTabId] = useState(routedPage ?? tabs[0]?.id ?? "no-tab");
    const selectedTab = tabs.find(tab => tab.id === selectedTabId);

    useEffect(() => {
        x.onTabChange?.(selectedTab);
    }, [selectedTab]);

    const currentSections = (selectedTab?.sections ?? [])
        .map(sec => ({ ...sec, ref: createRef<HTMLDivElement>() }));

    const formRef = createRef<HTMLDivElement>();
    const navInnerRef = createRef<HTMLDivElement>();
    const navOuterRef = createRef<HTMLDivElement>();
    const scrollRef = createScrollRef();

    useEffect(() => {
        const form = notNull(formRef.current, "Failed to obtain formRef");

        // if (selectedTab changed)
        if (form.dataset.tabId && form.dataset.tabId !== selectedTabId) {
            // scrolling to top
            scrollRef.current?.scrollTo(0);
        }

        form.dataset.tabId = selectedTabId;
    });

    const scrollThresholdPx = 100;

    const scroll = (el: HTMLElement) => {
        scrollRef.current?.scrollToElement(el, -navOuterRef.current!.clientHeight - scrollThresholdPx / 2);
    };

    const scrollToSection = (section: typeof currentSections[0]) => {
        scroll(notNull(section.ref.current, "Failed to obtain node from section ref (in scroll)"));
    };

    const changeTab = (tabId: string) => {
        setSelectedTabId(tabId);

        const route = {path: router.currentRoute.path, query: {...router.currentRoute.query, page: tabId}};
        router.replace(route);
    };

    interface NavProps {
        inset?: React.ReactNode
        ref?: RefObject<HTMLDivElement>
        className?: string
        isOuter?: boolean
        scrollRef: ScrollRef
    }

    const Nav: React.FC<NavProps> = forwardRef((p, ref) => {
        const [currentSectionIx, setCurrentSectionIx] = useState(0);
        const [visibility, setVisibility] = useState<VisibilityProperty>("visible");

        useEffect(() => {
            const scroll = notNull(p.scrollRef.current, "Failed to obtain scrollRef");
            const navInner = notNull(navInnerRef.current, "Failed to obtain navInnerRef");

            const update = (pos: number) => {
                let currentSec = 0;

                for (const section of currentSections) {
                    const sectionEl = section.ref.current;

                    if (sectionEl === null)
                        break;

                    if (sectionEl.offsetTop - pos - scrollThresholdPx >= navInner.offsetTop) {
                        break;
                    } else {
                        currentSec++;
                    }
                }

                setCurrentSectionIx(Math.max(0, currentSec - 1));

                if (p.isOuter) {
                    const visible = pos > navInner.offsetTop;
                    setVisibility(visible ? "visible" : "hidden");
                }
            };

            update(scroll.getOffset(navInner));
            const subscription = scroll.position.watch(update);

            return () => { subscription.unsubscribe() };
        });

        return <div className={styles.formNav + " " + (p.className ?? "")} ref={ref} style={{ visibility }}>
            {!x.hideTabs && <div className={styles.formNavTabs}>
                {
                    tabs.length > 1 ? tabs.map((tab, index) =>
                        <div
                            className={tab.id === selectedTabId ? styles.selectedTabBtn : styles.tabBtn}
                            key={`${tab.id}-${index}-${tab.sections.length}`}
                            onClick={() => changeTab(tab.id)}>
                            {tab.name}
                        </div>
                    ) : null
                }
            </div>}
            {!x.hideTabs && <div className={styles.formNavSections}>
                {
                    currentSections.map((section, i) => {
                        const className =
                            section.hiddenInHeader
                                ? styles.hiddenSectionBtn
                                : currentSectionIx === i
                                    ? styles.selectedSectionBtn
                                    : styles.sectionBtn;
                        return <div className={className} key={`${i}-${section.name}`} onClick={() => scrollToSection(section)}>
                            {section.name}
                        </div>;
                    })
                }
            </div>}
            {
                p.inset && <div className={styles.formNavInsetOuter}>
                    <div className={styles.formNavInsetInner}>
                        {p.inset}
                    </div>
                </div>
            }
        </div>;
    }) as React.FC<NavProps>;

    Nav.displayName = "FormNavigatorNav";

    return <div className={styles.form} ref={formRef}>
        <ScrollBox className={styles.formOverflow} scrollRef={scrollRef}>
            <div className={styles.formContent}>
                {
                    x.header && <div className={styles.formHeader}>
                        {x.header}
                    </div>
                }
                <Nav ref={navInnerRef} className={styles.formNavInner} inset={x.navInset} scrollRef={scrollRef}/>
                <div>
                    {
                        currentSections.map((section, i) =>
                            <div ref={section.ref} key={`${i}-${section.name}`} className={styles.sectionWrap}>
                                <CardTitleContext.Provider value={section.name}>
                                    {section.children}
                                </CardTitleContext.Provider>
                            </div>
                        )
                    }
                </div>
                {
                    x.footer && <div className={styles.formFooter}>
                        {x.footer}
                    </div>
                }
            </div>
        </ScrollBox>
        <div ref={navOuterRef} className={styles.formNavAbsWrap}>
            <Nav inset={x.navInset} isOuter scrollRef={scrollRef} />
            <div className={styles.formNavAbsPad}/>
        </div>
    </div>;
};

Form.Tab = (id, name, sections) => ({ id, name, sections });
Form.Section = (name, children) => ({ name, children });
Form.HiddenSection = (name, children) => ({ name, children, hiddenInHeader: true });
