import NavPanel from "@/components/NavPanel.vue";
import Expander from "@/components/Expander.vue";
import Card from "@/components/vue/Card.vue";
import { defaultStats, IStatsItem } from "@/components/Stats";
import Stats from "@/components/Stats.vue";
import { Component, Prop, Vue, Watch } from "vue-property-decorator";
import { IForm, ISection, FormPage } from "@/views/FormAbstractions";

import EventBus from "@/EventBus";
import { FieldState } from "@/components/vue/form-table/FieldBase";
import RelatedObjects from "@/components/RelatedObjects.vue";
import smoothScroll from '@/components/SmoothScroll';
import { ApiError } from '@/api/ApiError';
import router from '@/router';

interface INavSection {
    title: string
    active: boolean
}

@Component({ components: { NavPanel, Card, Expander, RelatedObjects, Stats } })
export default class FormBase extends Vue {
    @Prop() private formDeclaration!: IForm<unknown>;
    @Prop() private source!: unknown;
    @Prop() private bus!: Vue;
    @Prop({ default: null }) private notice!: null | string;
    @Prop({ default: FieldState.READONLY }) private state!: FieldState;
    @Prop({ default: false }) private onModal!: boolean;
    @Prop({ default: null }) private statsContext!: null | string;
    @Prop({ default: true }) private showErrorInHeader!: boolean;

    public error: ApiError | null = null;

    public created() {
        if (this.formDeclaration.pages.length) {
            const routedPage = router.currentRoute.query.page as string | undefined | null;

            if (routedPage) {
                this.setCurrentPage(this.formDeclaration.pages.find(x => x.id === routedPage) || this.formDeclaration.pages[0]);
            } else {
                this.setCurrentPage(this.formDeclaration.pages[0]);
            }
        }

        EventBus.$on("raise-error", this.raiseError);
        EventBus.$on("discard-error", this.discardError);
    }

    private raiseError(error: ApiError) {
        this.error = this.showErrorInHeader ? error : null;
        this.$emit("error", error);
    }

    private discardError() {
        this.error = null;
    }

    private currentPage: FormPage<unknown> = {
        id: null!,
        showNavigationHeader: false,
        sections: []
    };

    public setCurrentPage(v: FormPage<unknown>) {
        this.currentPage = v;
        this.navSections =
            this.currentPage
                .sections
                .map((x, ix) => ({
                    title: x.name,
                    active: true,
                    visible: x.visibleInNavPanel === undefined ? true : x.visibleInNavPanel
                }));

        const route = {path: router.currentRoute.path, query: {...router.currentRoute.query, page: v.id}};
        router.replace(route);
    }

    @Watch("formDeclaration") public resetDeclaration(newDecl: IForm<unknown>, oldDecl: IForm<unknown>) {
        if (newDecl.pages.length !== oldDecl.pages.length)
            this.setCurrentPage(newDecl.pages[0] || {
                showNavigationHeader: false,
                sections: []
            });

        const ix = oldDecl ? Math.max(0, oldDecl.pages.indexOf(this.currentPage)) : 0;
        this.setCurrentPage(newDecl.pages[ix] || {
            showNavigationHeader: false,
            sections: []
        });
    }

    @Watch("error") public onErrorChanged(e: ApiError | null) {
        if (e)
            smoothScroll(0, this.$refs["page-content"] as Element);
    }

    private isScrolledOverPanel = false;

    private navSections: INavSection[] = [];

    private get stats(): IStatsItem[][] {
        return defaultStats[this.statsContext as string] || [];
    }

    private get filteredPages(): FormPage<unknown>[] {
        return this.formDeclaration.pages
            .filter(x => !!x.title && (!x.visible || !this.source || x.visible(this.source)));
    }

    private get filteredNavSections(): INavSection[] {
        return this.navSections.filter((x, ix) => {
            const sec = this.currentPage.sections[ix];
            return (!sec.visible || sec.visible(this.source));
        });
    }

    private get filteredSections(): ISection<unknown>[] {
        return this.currentPage.sections.filter(x => {
            return !x.visible || x.visible(this.source);
        });
    }

    public mounted() {
        if (this.onModal) return;

        (this.$refs["page-content"] as Element).addEventListener("scroll", this.onScrollRaw);
    }

    private sectionAt(ix: number) {
        for (const sec of this.$refs.section as (Vue | Element)[]) {
            const el = sec instanceof Vue ? ((sec as Vue).$el as HTMLElement) : (sec as HTMLElement);
            if (parseInt(el.dataset.sectionIx!, 10) === ix) return el;
        }
    }

    public selectSection(foo: unknown, sectionIndex: number) {
        const section = this.sectionAt(sectionIndex);

        const header = (this.$refs["dynamic-panel"] as Vue).$el as HTMLElement;
        const inPage = (this.$refs["in-page-panel"] as Vue).$el as HTMLElement;
        const offset = (header ? header.clientHeight : 0) + inPage.clientHeight;

        smoothScroll(section!.offsetTop - offset, (this.$refs["page-content"] as Element));
    }

    public beforeDestroy() {
        if (this.onModal) return;
        (this.$refs["page-content"] as Element).removeEventListener("scroll", this.onScrollRaw);

        EventBus.$off("raise-error", this.raiseError);
        EventBus.$off("discard-error", this.discardError);
    }

    public scrollPosition = 0;

    private onScrollRaw(ev: Event) {
        this.onScroll((ev.target as Element).scrollTop);
    }

    private onScroll(pos: number) {
        if (this.onModal || !this.currentPage.showNavigationHeader) return;

        this.scrollPosition = pos;

        const panel = (this.$refs["in-page-panel"] as Vue).$el as HTMLElement;
        const offset = panel.offsetTop;

        this.isScrolledOverPanel = pos > offset;

        for (const sec of this.navSections) {
            sec.active = false;
        }

        const secCount = this.filteredNavSections.length;
        const dynPanelVue = (this.$refs["dynamic-panel"] as Vue).$el as HTMLElement;
        const dynamicPanelHeight = dynPanelVue ? dynPanelVue.clientHeight : 0;

        for (let i = 0; i < secCount; i++) {
            const diff = this.sectionAt(i)!.offsetTop - pos - dynamicPanelHeight;
            if (diff < 0) {
                continue;
            }

            this.filteredNavSections[Math.max(i - 1, 0)].active = true;
            break;
        }
    }
}
