import {observer} from "mobx-react";
import React, {useEffect, useState} from "react";
import {TooltipIcon} from "../TooltipIcon";
import styles from "./FormControlEshopInput.module.css";

interface NumberInputProps {
    onChange?: (value: number) => void
    onKeyDown?: (e: React.KeyboardEvent<HTMLInputElement>) => void
    placeholder?: string
    value?: number | null
    small?: boolean
    disabled?: boolean
    clearable?: boolean
    checkEmpty?: boolean
    type?: 'number'
    validate?: (v: string, refValue?: number) => {text: string}
    width?: string | number
    // used for disable number formatting !(2 -> 2,00)
    disableFixed?: boolean
    // used for disable the price mask in displaying value !(222222 -> 222 222)
    disableMask?: boolean
    maxValue?: number | string
}

export const NumberInput = (props: NumberInputProps) => {
    const [value, setValue] = useNumberInput(
        () => props.value || null,
        n => props?.onChange && props.onChange(n),
        props.disableFixed,
        props.disableMask,
        props.maxValue
    );

    let errorText = "";
    if(props.validate) {
        errorText = props.validate(value).text;
    }

    const errorIcon = (
        <svg width="16" height="16" viewBox="0 0 16 16" fill="#f66" xmlns="http://www.w3.org/2000/svg">
            <path d="M2.73174 14.0007C2.48512 13.9983 2.2439 13.9315 2.03122 13.8067C1.81853 13.6818 1.64182 13.5032 1.5195 13.2891C1.39718 13.0749 1.33279 12.8326 1.3333 12.586C1.33381 12.3393 1.39889 12.0971 1.5221 11.8835L6.78903 2.70054C6.91109 2.48734 7.08821 2.31009 7.30075 2.18687C7.51328 2.06365 7.7543 1.99872 7.99997 1.99872C8.24564 1.99872 8.48665 2.06365 8.69919 2.18687C8.91172 2.31009 9.08754 2.48734 9.2096 2.70054L14.4765 11.8835C14.6017 12.0985 14.6673 12.3431 14.6666 12.5918C14.6659 12.8406 14.599 13.0846 14.4726 13.2988C14.3516 13.5123 14.1757 13.6898 13.9635 13.8132C13.7514 13.9365 13.511 14.0011 13.2656 14.0007H2.73174ZM7.41924 4.34572L3.23695 11.6686C3.17945 11.7698 3.14923 11.8843 3.14971 12.0007C3.15018 12.1171 3.18124 12.2313 3.23955 12.3321C3.29787 12.4328 3.38234 12.5165 3.48304 12.5749C3.58374 12.6332 3.69739 12.6642 3.81377 12.6647H12.1849C12.3014 12.6644 12.416 12.6338 12.5169 12.5755C12.6178 12.5173 12.7019 12.4335 12.7604 12.3327C12.8189 12.2319 12.8497 12.1178 12.8502 12.0013C12.8508 11.8848 12.8205 11.7699 12.763 11.6686L8.57809 4.34572C8.51985 4.24344 8.43636 4.15878 8.3346 4.09963C8.23285 4.04048 8.11767 4.00913 7.99997 4.00913C7.88227 4.00913 7.76579 4.04048 7.66403 4.09963C7.56228 4.15878 7.47878 4.24344 7.42054 4.34572H7.41924ZM7.30075 11.8796V10.4701H8.70049V11.8796H7.30075ZM7.30075 8.35289V6.94338C7.30075 6.75773 7.3739 6.57986 7.50518 6.44859C7.63645 6.31731 7.81562 6.24351 8.00127 6.24351C8.18692 6.24351 8.36479 6.31731 8.49606 6.44859C8.62734 6.57986 8.70049 6.75773 8.70049 6.94338V8.35289C8.70049 8.53854 8.62734 8.7164 8.49606 8.84768C8.36479 8.97895 8.18692 9.05275 8.00127 9.05275C7.81562 9.05275 7.63645 8.97895 7.50518 8.84768C7.3739 8.7164 7.30075 8.53854 7.30075 8.35289Z" fill="#f66"/>
        </svg>
    );


    return <div className={styles.eshopInputWrap} style={{width: props.width}}>
        <input type={props.type}
                disabled={props.disabled}
                value={value}
                placeholder={props.placeholder}
                onChange={e => setValue(e.target.value)}
                onKeyDown={props.onKeyDown}
                className={`${styles.formControlEshopInput} ${props.small ? styles.inputSmall : ''} ${props.disabled ? styles.disabled : ''} ${errorText ? styles.error : ''}`} />
                    { errorText && <div className={`${styles.priceErrorHint}`}>
                        <div style={{width: 16, height: 16}}>
                            {errorIcon}
                    </div>
                        {errorText}
                    </div> }
    </div>;
};

interface FormControlEshopProps {
    label: React.ReactNode
    children?: React.ReactElement
    withTooltip?: boolean
    boldLabel?: boolean
}


const useNumberInput: (getter: () => number | null, setter: (val: number) => void, disableFixed?: boolean, disableMask?: boolean, maxValue?: number | string) => [string, (newValue: string) => void] = (getter, setter, disableFixed, disableMask, maxValue) => {
    const value = getter();
    const [displayingValue, setDisplayingValue] = useState('');

    useEffect(() => {
        if (value && maxValue && value > maxValue) {
            setDisplayingValue(maxValue.toString());
        } else {
            if (disableMask) {
                setDisplayingValue(value !== null ? (disableFixed ? value.toString() : value.toFixed(2)) : '');
            } else {
                setDisplayingValue(value !== null ? format(disableFixed ? value.toString() : value.toFixed(2)) : '');
            }
        }
    }, [value]);

    // Здесь задаётся первоначальное форматирование

    return [displayingValue, (value: string) => {
        const newValue = value === '' ? '0' : value.replaceAll(' ', '');
        // 1. Валидируем значение первый раз — на предмет корректности ввода. Здесь запрещаем всё, кроме чисел, запятой, знака минус, и т.д. — дальше отсюда проходят только такие строчки, которые мы разрешаем вводить в принципе в контрол. Это предлагаю регуляркой написать.
        const regex = new RegExp("^(-?[1-9]+\\d*([,]\\d{0,2})?)$|^(-?0[,]\\d{0,2}[1-9]*)$|^0$");
        if (!regex.test(newValue)) {
            return;
        }

        let formatted = disableMask ? newValue : format(newValue);

        if (disableFixed && newValue.includes(",")) {
            formatted = formatted.slice(0, -3);
        }
        // 2. Новое текстовое значение записываем в промежуточную хранилку
        setDisplayingValue(formatted);

        // 3. пробуем парсить в число
        const newValueParsed = parseFloat(newValue.replace(',', '.'));


        // 4. Если это число, то в стор
        if (!isNaN(newValueParsed)) {
            setter(newValueParsed);
        }
    }];
};



function format(value: string): string {
    const fixed = value.replaceAll('.', ',');
    if (parseFloat(fixed) === null) return "";

    const [head, rest] = fixed.split(",");
    const lte = fixed[0] === "-";
    let firstBlkLen = (head.length - (lte ? 1 : 0)) % 3;
    if (firstBlkLen > 0 && lte) {
        firstBlkLen++;
    }

    const regex = /-?\d{3}/g;
    const headPart = head.substr(0, firstBlkLen);
    const parts = headPart.length ? [headPart] : [];
    const remaining = head.substr(firstBlkLen);

    do {
        const match = regex.exec(remaining);
        if (match === null) {
            break;
        }

        parts.push(match[0]);
    } while (true);

    return parts.join(" ") + (rest !== undefined ? "," + rest : '');
}

// ^(-?[1-9]+\\d*([.]\\d+)?)$|^(-?0[.]\\d*[1-9]+)$|^0$
