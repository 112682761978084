import React, {useRef, useState} from "react";
import styles from "./ConclusionRequests.module.css";
import { VStack } from "@/components/layouts";
import { j } from "@/reactUtils";
import { MonopolyObjects } from "@/views/Monopoly/list/sections/MonopolyObjects";
import { useStore } from "effector-react";
import { MonopolyStore } from "@/views/Monopoly/list/store";
import { ConclusionRequests } from "@/views/Monopoly/list/sections/ConclusionRequests";

export const enum MonopolyTab {
    MONOPOLY_OBJECTS,
    REQUESTS_FOR_CONCLUSION
}

type MonopolySectionProps = {
    storeComponent: {
        storeContainer: MonopolyStore
    }
};

export const MonopolySection = ({ storeComponent }: MonopolySectionProps) => {
    const state = useStore(storeComponent.storeContainer.store);
    const { monopolyObjects, conclusionRequests } = state;

    const [activeTab, setActiveTab] = useState<MonopolyTab>(MonopolyTab.MONOPOLY_OBJECTS);

    const hiddenForFuture = useRef(false);

    const getTitleByType = (type: "in-progress" | "accepted" | "processed") => {
        switch(type) {
            case "in-progress":
                return "в работе";
            case "accepted":
                return "принятые";
            case "processed":
                return "обработанные";
            default:
                return "";
        }
    };

    return <VStack outerStyle={{ margin: "30px" }} spacing="15px">
        <div className={styles.tabs}>
            <div onClick={() => setActiveTab(MonopolyTab.MONOPOLY_OBJECTS)}
                 className={j(styles.tab, activeTab === MonopolyTab.MONOPOLY_OBJECTS && styles.active)}>
                {`Объекты данных монополиста ${getTitleByType(storeComponent.storeContainer.type)}`}
            </div>
            {hiddenForFuture.current && <div onClick={() => setActiveTab(MonopolyTab.REQUESTS_FOR_CONCLUSION)}
                  className={j(styles.tab, activeTab === MonopolyTab.REQUESTS_FOR_CONCLUSION && styles.active)}>
                Запросы на заключение контракта
            </div>}
        </div>
        {activeTab === MonopolyTab.MONOPOLY_OBJECTS
            ? <MonopolyObjects srd={monopolyObjects}
                               type={storeComponent.storeContainer.type}
                               getTitle={getTitleByType}
                               onClear={() => storeComponent.storeContainer.clearMonopolyObjectsFx()}
                               onMock={() => storeComponent.storeContainer.mockMonopolyObjectsFx()}/>
            : <ConclusionRequests srd={conclusionRequests}/>}
    </VStack>;
};
