import { AutoDto, AutoModel, uuid } from "@/models/parsing";
import Decimal from "decimal.js";

interface ShortContractMD {
    id: uuid
    regNumber: string
    registeredAt: Date
    totalPrice: Decimal
    conclusionDate: Date
    contractNumber: string
    purchaseCode: string
}

export type ShortContract = AutoModel<ShortContractMD>;
export type DtoShortContract = AutoDto<ShortContractMD>;

export const ShortContract = {
    fromDto(dtoShortContract: DtoShortContract): ShortContract {
        return {
            ...dtoShortContract,
            registeredAt: new Date(dtoShortContract.registeredAt),
            totalPrice: new Decimal(dtoShortContract.totalPrice),
            conclusionDate: new Date(dtoShortContract.conclusionDate)
        };
    },
    toDto(shortContract: ShortContract): DtoShortContract {
        return {
            ...shortContract,
            registeredAt: shortContract.registeredAt.toISOString(),
            totalPrice: shortContract.totalPrice.toString(),
            conclusionDate: shortContract.registeredAt.toISOString()
        };
    }
};
