import {CompatEnum, UnionStrings} from "./EnumStrings";

export type MarketPriceDataSource =
    | "MARKET_PRICE"
    | "PROVIDER_PORTAL"
    | "PROVIDER_OFFER";

export const MarketPriceDataSource: CompatEnum<MarketPriceDataSource> = {
    MARKET_PRICE: "MARKET_PRICE",
    PROVIDER_PORTAL: "PROVIDER_PORTAL",
    PROVIDER_OFFER: "PROVIDER_OFFER"
};

export const MarketPriceDataSourceStrings: UnionStrings<MarketPriceDataSource> = {
    MARKET_PRICE: "Рыночные цены",
    PROVIDER_PORTAL: "Портал поставщиков",
    PROVIDER_OFFER: "Предложения поставщиков",
};
