import React from "react";
import {Card} from "@/components/Card";
import {Field, Fields} from "@/components/form/Field";
import {Label} from "@/components/primitive";

export const SuppliersSuccession: React.FC = x => {
    return <Card>
        <Fields orientation="horizontal">
            <Field title="Реорганизованные ЮЛ">
                <Label>

                </Label>
            </Field>
        </Fields>
    </Card>;
};