// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, "._3PoYwf1Cdw0p_T4Wztfip- {\n    --primary-color: #428bca;\n\n    display: flex;\n\n    margin-left: 5px;\n    margin-bottom: 0;\n}\n\n._2d3WA06EFSr9QbjCGqVMwt {\n    font-weight: 700;\n    color: var(--primary-color);\n\n    padding: 10px 15px;\n\n    border-top-left-radius: 8px;\n    border-top-right-radius: 8px;\n\n    cursor: pointer;\n}\n\n._2d3WA06EFSr9QbjCGqVMwt._2SqqTEyVwELxVSC6ZSXrVQ:hover {\n    background-color: #1c9dbd;\n    color: #fff;\n}\n\n._2d3WA06EFSr9QbjCGqVMwt:not(._2SqqTEyVwELxVSC6ZSXrVQ):hover {\n    color: #2a6496;\n}\n\n._2SqqTEyVwELxVSC6ZSXrVQ {\n    background-color: var(--primary-color);\n    color: #fff;\n}\n", ""]);
// Exports
exports.locals = {
	"tabs": "_3PoYwf1Cdw0p_T4Wztfip-",
	"tab": "_2d3WA06EFSr9QbjCGqVMwt",
	"active": "_2SqqTEyVwELxVSC6ZSXrVQ"
};
module.exports = exports;
