// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, "._1pT4XI8-VDbnsYFh75gIY0 {\n    --border: 1px solid #d7dee3;\n\n    display: grid;\n\n    border-left: var(--border);\n    border-bottom: var(--border);\n}\n\n._3bUsRlMTgkW5CDqAGpytEZ, ._2kglqVpHiP1vtTieXiGoHR {\n    border-right: var(--border);\n    border-top: var(--border);\n\n    font-size: 13px;\n    padding: 10px 15px 10px 15px;\n}\n\n._2kglqVpHiP1vtTieXiGoHR {\n    background: white;\n    color: #2d3e50;\n}\n\n._3bUsRlMTgkW5CDqAGpytEZ {\n    background: #eef1f7;\n    color: #2e4358;\n    font-weight: 700;\n}", ""]);
// Exports
exports.locals = {
	"Table": "_1pT4XI8-VDbnsYFh75gIY0",
	"TableHeaderCell": "_3bUsRlMTgkW5CDqAGpytEZ",
	"TableValueCell": "_2kglqVpHiP1vtTieXiGoHR"
};
module.exports = exports;
