import {applyN, AutoDto, AutoModel, MDMap} from "@/models/parsing";
import {MeasurementUnit} from "@/models";
import {IDtoMeasurementUnit} from "@/models/json";
import {CharacteristicConditionType, CharacteristicValueType} from "@/models/enums";
import {CharacteristicSelectionMode} from "@/models/ComposedLots/specifications/CharacteristicSelectionMode";

interface CharacteristicDeclarationMD {
    id: number
    name: string
    valueType: CharacteristicValueType
    conditionType: CharacteristicConditionType
    selectionMode: CharacteristicSelectionMode
    defaultFirst?: string
    defaultSecond?: string
    enumValues: string[]
    measurementUnit?: MDMap<MeasurementUnit, IDtoMeasurementUnit>
    isAdditional: boolean
}

export type DtoCharacteristicDeclaration = AutoDto<CharacteristicDeclarationMD>;
export type CharacteristicDeclaration = AutoModel<CharacteristicDeclarationMD>;

const gtGte: CharacteristicConditionType[] = ["EQUAL", "GREATER_THAN_OR_EQUAL", "GREATER_THAN", "RANGE"];
const ltLte: CharacteristicConditionType[] = ["EQUAL", "LESS_THAN_OR_EQUAL", "LESS_THAN", "RANGE"];

const children: { [key in CharacteristicConditionType]: CharacteristicConditionType[] } = {
    GREATER_THAN: gtGte,
    GREATER_THAN_OR_EQUAL: gtGte,
    LESS_THAN: ltLte,
    LESS_THAN_OR_EQUAL: ltLte,
    NOT_EQUAL: ["EQUAL", "NOT_EQUAL", "GREATER_THAN", "GREATER_THAN_OR_EQUAL", "LESS_THAN", "LESS_THAN_OR_EQUAL", "RANGE"],
    RANGE: ["EQUAL", "RANGE"],
    ENUM: ["ENUM"],
    EQUAL: ["EQUAL"],
};

export const getChildConditionTypes = (type: CharacteristicConditionType) => children[type];

export const CharacteristicDeclaration = {
    toDto(info: CharacteristicDeclaration): DtoCharacteristicDeclaration {
        return {
            ...info,
            measurementUnit: info.measurementUnit?.toJson()
        };
    },
    fromDto(info: DtoCharacteristicDeclaration): CharacteristicDeclaration {
        return {
            ...info,
            defaultFirst: info.defaultFirst ?? undefined,
            defaultSecond: info.defaultSecond ?? undefined,
            measurementUnit: applyN(MeasurementUnit.fromJson, info.measurementUnit),

            selectionMode: "MULTI_SELECTION" // TODO
        };
    }
};