import React from "react";
import { $contracts, changeLaw, changeLotId, changeVolume, createContract, loadLots223, loadLots44 } from "./store";
import { Checkbox, PrimaryButton } from "@/components/eshop";
import { Select } from "@/components/eshop/Select/Select";
import { useStore } from "effector-react";
import { useEffect } from "react";
import styles from "../CreateSession/CreateSession.module.css";
import { Modal } from "@/components/eshop/Modal";
import { FormGroup, FormControlEshopInput } from "@/components/eshop/FormControlEshopInput";
import { TooltipIcon } from "@/components/TooltipIcon";
import router from "@/router";
import { firstMaybe } from "@/arrayUtils";
import { formatNumber } from "@/NumberFormatting";
import {Card} from "@/components/eshop/Card/Card";
import {Section} from "@/components/eshop/Section/Section";

export const ContractCreate = (props: { show: boolean; closeModal: () => void }) => {
    const { show, closeModal } = props;
    const store = useStore($contracts);
    const products = store.products;

    useEffect(() => {
        loadLots44({});
        loadLots223({});
        return;
    }, []);

    const lawOptions = [{ name: "44-ФЗ", value: "F44" }, { name: "223-ФЗ", value: "F223" }];

    const controls = () => {
        const create = () => {
            if (!products) return;
            products.map(async product => {
                const response = await createContract({
                    productId: product.product.id,
                    lotId: product.lotId!,
                    law: product.law,
                    amount: product.volume
                });
                if (response) {
                    const routeData = router.resolve({ path: `/contracts/${response.id}` });
                    window.open(routeData.href, '_blank');
                }
            });
            closeModal();
        };

        return <>
            <PrimaryButton filled disabled={products.some(p => !p.lotId)} onClick={create}>Создать контракт</PrimaryButton>
        </>;
    };

    const HeaderWithTooltip = (props: { children: React.ReactNode }) =>
        <th className={styles.cell}>
            <div style={{ display: 'flex' }}>
                {props.children}
                <TooltipIcon />
            </div>
        </th>;

    return show
        ? <Modal contentOverflow onClose={() => closeModal()} controls={controls()} title="Создание контракта">
            <div className={styles.createContractModalContainer}>
                {products.map(product => {
                    const referentPrice = parseFloat(product.product.referentPrice || '0');
                    const totalPrice = (product.volume || 0) * referentPrice;

                    return <div key={product.product.id} className={styles.createContractModalItemContainer}>
                        <Section title={<>Общие сведения<TooltipIcon /></>} required>
                            <Card padded gap={30}>
                                <div className={styles.paper}>
                                    <FormGroup label="Закон-основание" withTooltip boldLabel>
                                        <Select value={product.law}
                                            onSelect={v => v && changeLaw({ productId: product.product.id, law: v })}
                                            options={lawOptions}
                                            fullWidth />
                                    </FormGroup>
                                </div>
                                <div>
                                    <FormGroup label="Код КПГЗ" withTooltip boldLabel>
                                        <div>{product.product.spgz.subjectClass.code.slice(0, 5)}</div>
                                    </FormGroup>
                                </div>
                                <div>
                                    <FormGroup label="Общая сумма, руб" withTooltip boldLabel>
                                        <div>{formatNumber(totalPrice)} руб</div>
                                    </FormGroup>
                                </div>
                            </Card>
                        </Section>
                        <Section title={<>Лот<TooltipIcon /></>} required>
                            <Card padded>
                                <div className={styles.buttonsContainer}>
                                    <div className={`${styles.iconControl} ${styles.filter}`} title="Показать/скрыть фильтр" />
                                    <div className={`${styles.iconControl} ${styles.refresh}`} title="Сбросить параметры поиска" />
                                </div>
                            </Card>
                            <table className={styles.cardTable}>
                                <thead>
                                    <tr className={styles.tableRow}>
                                        <HeaderWithTooltip>Реестр. № лота</HeaderWithTooltip>
                                        <HeaderWithTooltip>Год</HeaderWithTooltip>
                                        <HeaderWithTooltip>Наименование лота</HeaderWithTooltip>
                                        <HeaderWithTooltip>Основание заключения</HeaderWithTooltip>
                                        <HeaderWithTooltip>Остаток финансирования</HeaderWithTooltip>
                                    </tr>
                                </thead>
                                <tbody>
                                    {(product.law === 'F44' ? store.lotsPage44 : store.lotsPage223).items.map(l => <tr onClick={() => changeLotId({ productId: product.product.id, lotId: l.id })} key={l.regNumber} className={styles.tableRow}>
                                        <td className={`${styles.cell} ${styles.cellBody} ${l.id === product.lotId ? styles.cellSelected : ''}`}>{l.regNumber}</td>
                                        <td className={`${styles.cell} ${styles.cellBody} ${l.id === product.lotId ? styles.cellSelected : ''}`}>{l.basicInfo.contractConclusionYear}</td>
                                        <td className={`${styles.cell} ${styles.cellBody} ${l.id === product.lotId ? styles.cellSelected : ''}`}>{l.basicInfo.subject}</td>
                                        <td className={`${styles.cell} ${styles.cellBody} ${l.id === product.lotId ? styles.cellSelected : ''}`}>{l.singleProviderReason?.name}</td>
                                        <td className={`${styles.cell} ${styles.cellBody} ${l.id === product.lotId ? styles.cellSelected : ''}`}>
                                            {formatNumber(l.plannedPayments?.reduce((a, b) => a + parseFloat(b.amount), 0) ?? 0)}
                                        </td>
                                    </tr>)}
                                </tbody>
                            </table>
                        </Section>
                        <Section title={<>Спецификации<TooltipIcon /></>} required>
                            <table className={styles.cardTable}>
                                <thead>
                                    <tr className={styles.tableRow}>
                                        <th className={styles.cell} />
                                        <HeaderWithTooltip>Наименование</HeaderWithTooltip>
                                        <HeaderWithTooltip>Цена</HeaderWithTooltip>
                                        <HeaderWithTooltip>Количество</HeaderWithTooltip>
                                        <HeaderWithTooltip>Ед.измерения</HeaderWithTooltip>
                                        <HeaderWithTooltip>Сумма, руб</HeaderWithTooltip>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr key={product.product.id} className={styles.tableRow}>
                                        <td className={`${styles.cell} ${styles.cellBody}`}>
                                            <Checkbox  />
                                        </td>
                                        <td className={`${styles.cell} ${styles.cellBody}`}>{product?.product.name}</td>
                                        <td className={`${styles.cell} ${styles.cellBody}`}>
                                            <FormControlEshopInput
                                                disabled
                                                value={referentPrice || undefined}
                                                small
                                                onChange={() => { }} placeholder="Введите значение" />
                                        </td>
                                        <td className={`${styles.cell} ${styles.cellBody}`}>
                                            <FormControlEshopInput value={product.volume}
                                                small
                                                onChange={e => changeVolume({
                                                    productId: product.product.id,
                                                    volume: Number(e.target.value.replace(RegExp("[^0-9.]"), ""))
                                                })} placeholder="Введите значение" /></td>
                                        <td className={`${styles.cell} ${styles.cellBody}`}>{firstMaybe(product.product.spgz.measurementUnits)?.name || ''}</td>
                                        <td className={`${styles.cell} ${styles.cellBody}`}>{formatNumber(product.volume * referentPrice)} руб.</td>
                                    </tr>
                                </tbody>
                            </table>
                        </Section>
                    </div>;
                })}
            </div>
        </Modal >
        : <div />;
};
