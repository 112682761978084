




import { Component, Prop, Vue } from "vue-property-decorator";
import { ObligationView } from "@/views/Obligations/view/ObligationView";
import { createObligationStore } from "@/views/Obligations/view/store";

@Component({ components: { komponent: ObligationView as unknown as typeof Vue } })
export default class ObligationViewVueStub extends Vue {
    @Prop() private obligationId!: string;

    public storeContainer = Object.freeze(createObligationStore(this.obligationId));
}
