






import { Component, Prop, Provide, Vue, Mixins } from "vue-property-decorator";
import { FieldState, formColumnsContext, fieldStateContext, FComponent } from "./FComponent";

@Component
export default class FGrid extends Mixins(FComponent) {
    @Prop({ default: 1 }) public columns!: number;

    public get grid() {
        return `auto-flow / repeat(${this.columns}, 1fr)`;
    }
}
