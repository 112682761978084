import {LotDocumentType} from "@/models/enums/Documents";
import {AutoDto, AutoModel, MDMap, uuid} from "@/models/parsing";
import {File, DtoFile} from "@/models/ComposedLots/documents/File";
import { NumberedLot } from "@/models/ComposedLots/documents/NumberedLot";

interface LotDocumentMD {
    id: uuid
    lot: NumberedLot
    type: LotDocumentType
    sendToEis: boolean
    comment?: string
    file: MDMap<File, DtoFile>
}

export type LotDocument = AutoModel<LotDocumentMD>;
export type DtoLotDocument = AutoDto<LotDocumentMD>;

export const LotDocument = {
    toDto(document: LotDocument): DtoLotDocument {
        return {
            ...document,
            file: File.toDto(document.file),
            lot: NumberedLot.toDto(document.lot)
        };
    },
    fromDto(document: DtoLotDocument): LotDocument {
        return {
            ...document,
            file: File.fromDto(document.file),
            lot: NumberedLot.fromDto(document.lot),
            comment: document.comment ?? undefined
        };
    },
};