import React from "react";
import { ContractObligation } from "@/models/Contracts/ContractObligation";
import { Column, FilterColumn, FilteredSelectionTable, FilterStore, Pager, Table } from "@/components/table";
import { ContractObligationFilters } from "@/views/Obligations/shared/store";
import { formatDate } from "@/dateUtils";
import { formatNumber } from "@/NumberFormatting";
import { UnderlinedVueLink } from "@/components/VueLink";

type ObligationsTableProps = {
    obligations: ContractObligation[]
    filterStore: FilterStore<ContractObligationFilters>
    isFiltersVisible: boolean
    linked?: boolean
    selected?: ContractObligation
    onChange?: (selected: ContractObligation | undefined) => void
};

export const ObligationsTable = (
    {
        obligations,
        filterStore,
        isFiltersVisible,
        selected,
        onChange,
        linked = false
    }: ObligationsTableProps) => {
    const columns: Column<ContractObligation>[] = [
        FilterColumn<ContractObligation, ContractObligationFilters>({
            header: "Регистрационный номер",
            filter: {
                key: "regNumber",
                type: "string"
            },
            cell: ({ item }) => linked
                ? <UnderlinedVueLink href={`/contracts/obligations/${item.id}`}>{item.regNumber}</UnderlinedVueLink>
                : <>{item.regNumber}</>
        }),
        FilterColumn<ContractObligation, ContractObligationFilters>({
            header: "Дата регистрации",
            filter: {
                key: "registeredAt",
                type: "date"
            },
            cell: ({ item }) => <>{formatDate(item.registeredAt)}</>
        }),
        FilterColumn<ContractObligation, ContractObligationFilters>({
            header: "Тип документа",
            filter: {
                key: "type",
                type: "string"
            },
            cell: ({ item }) => <>{item.type}</>
        }),
        FilterColumn<ContractObligation, ContractObligationFilters>({
            header: "Сумма, руб.",
            filter: {
                key: "totalPrice",
                type: "decimal"
            },
            cell: ({ item }) => <>{formatNumber(item.totalPrice)}</>
        }),
        FilterColumn<ContractObligation, ContractObligationFilters>({
            header: "Контракт/Договор",
            filter: {
                key: "contractNumber",
                type: "string",
                hideFilterInput: true,
            },
            cell: ({ item }) => <>{item.contract.regNumber}</>
        }),
        FilterColumn<ContractObligation, ContractObligationFilters>({
            header: "Номер БО",
            cell: () => <></>
        }),
        FilterColumn<ContractObligation, ContractObligationFilters>({
            header: "Учтено в этапе Контракт/Договора",
            cell: () => <>Да</>
        }),
        FilterColumn<ContractObligation, ContractObligationFilters>({
            header: "Статус",
            cell: () => <>Загружено</>
        })
    ];

    return (
        <FilteredSelectionTable<ContractObligation, ContractObligationFilters>
            columns={columns}
            dataset={obligations}
            filterStore={filterStore}
            onChange={onChange}
            selected={selected}
            isFiltersVisible={isFiltersVisible}
            mode="single"
            selectorPosition={onChange ? "atLeft" : "hidden"} />
    );
};
