import React, { useEffect, useState } from "react";
import { CardModal } from "@/components/modals/CardModal";
import { ModalFC, showModal } from "@/EventBus";
import { MonopolySpecification } from "@/models/Monopoly/MonopolySpecification";
import { Card } from "@/components/Card";
import { Expandee, HStack, VStack } from "@/components/layouts";
import { Button, CheckBox, DecimalBox, Label, TextBox } from "@/components/primitive";
import { Field, Fields } from "@/components/form/Field";
import { Column, Table } from "@/components/table";
import { CharacteristicValue } from "@/models/ComposedLots/specifications";
import { CharacteristicConditionTypeStrings } from "@/models/enums";
import { MonopolySupplyStage } from "@/models/Monopoly/MonopolySupplyStage";
import { formatDate } from "@/dateUtils";
import { SupplyStageConfirmModal, SupplyStageVolumeChangeModal } from "@/views/Monopoly/shared/modals/SupplyStage";
import Decimal from "decimal.js";

type SpecificationEditModalProps = {
    specification: MonopolySpecification
};

export const SpecificationEditModal: ModalFC<SpecificationEditModalProps, MonopolySpecification> =
    ({
         specification: initialSpecification,
         done
    }) => {
    const [supplyStages, setSupplyStages] = useState<MonopolySupplyStage[]>(initialSpecification.supplyStages);
    const [specification, setSpecification] = useState<MonopolySpecification>(initialSpecification);
    const characteristicsColumns: Column<CharacteristicValue>[] = [
        Table.Column(
            "Характеристика",
            ({ item }) => <>{item.declaration.name}</>
        ),
        Table.Column(
            "Единица измерения",
            ({ item }) => <>{item.declaration.measurementUnit?.name ?? ""}</>
        ),
        Table.Column(
            "Условная операция",
            ({ item }) => <>{CharacteristicConditionTypeStrings[item.conditionType]}</>
        ),
        Table.Column(
            "Значение",
            () => <></>
        )
    ];

    const supplyColumns: Column<MonopolySupplyStage>[] = [
        Table.Column(
            "Объём поставки",
            ({ item }) => <>{item.volume.toString()}</>
        ),
        Table.Column(
            "Дата начала поставки",
            ({ item }) => <>{formatDate(item.absolutePeriod.startDate)}</>
        ),
        Table.Column(
            "Дата окончания поставки",
            ({ item }) => <>{formatDate(item.absolutePeriod.endDate)}</>
        ),
        Table.AutoColumn(
            "Действие",
            ({ item, rowIndex }) => (
                <Button icon="gPencil" onClick={() => editSupplyStageVolume(item, rowIndex)} />
            )
        )
    ];

    useEffect(() => {
        setSpecification(specification => ({
            ...specification,
            supplyStages: [...supplyStages],
            ...supplyStages.reduce(
                (acc, supplyStage) => ({
                    volume: acc.volume.add(supplyStage.volume),
                    totalPrice: acc.totalPrice.add(specification.unitPrice.mul(supplyStage.volume))
                }),
                { volume: new Decimal(0), totalPrice: new Decimal(0) }
            ),
        }));
    }, [supplyStages]);

    const editSupplyStageVolume = async (supplyStage: MonopolySupplyStage, idx: number) => {
        const confirmed = await showModal(SupplyStageConfirmModal);

        if (!confirmed) {
            return;
        }

        const result = await showModal(SupplyStageVolumeChangeModal, { supplyStage });

        if (result) {
            setSupplyStages(supplyStages => {
                const tempSupplyStages = [...supplyStages];

                tempSupplyStages.splice(idx, 1, result);
                return tempSupplyStages;
            });
        }
    };

    const bottom = (
        <HStack spacing="10px">
            <Expandee />
            <Button icon="aSave" onClick={() => done(specification)} />
            <Button icon="aClose" color="red" onClick={() => done(undefined)} />
        </HStack>
    );

    return (
        <CardModal title="Редактирование спецификации"
                   width="60%"
                   cardContentStyle={{ padding: "0" }}
                   bottom={bottom}
                   close={() => done(undefined)}>
            <VStack>
                <Card title="Содержание закупки" style={{ border: "none" }}>
                    <VStack spacing="10px">
                        <Label preset="boldSmall">
                            Для добавления спецификации в детализированный объект закупки укажите конечный КПГЗ
                        </Label>
                        <Fields orientation="horizontal">
                            <Field title="КПГЗ" preset="boldSmall">
                                <TextBox value={`${specification.spgz.subjectClass.code} ${specification.spgz.subjectClass.description}`}
                                         style={{ width: "60%" }}
                                         disabled />
                            </Field>
                            <Field title="СПГЗ" preset="boldSmall">
                                <TextBox value={specification.spgz.subject}
                                         style={{ width: "60%" }}
                                         disabled />
                            </Field>
                        </Fields>
                        <Card title="Обязательные характеристики" style={{ border: "none" }}>
                            <Table columns={characteristicsColumns} dataset={specification.characteristics} />
                        </Card>
                        <Card title="Необязательные характеристики" style={{ border: "none" }}>
                            <Table columns={characteristicsColumns} dataset={specification.characteristics} />
                        </Card>
                    </VStack>
                </Card>
                <Card title="Цена" style={{ border: "none" }}>
                    <Fields orientation="horizontal">
                        <Field title="Объём закупки" preset="boldSmall">
                            <DecimalBox value={specification.volume}
                                        disabled
                                        style={{ width: "20%" }} />
                        </Field>
                        <Field title="Единица измерения" preset="boldSmall">
                            <TextBox value={specification.measurementUnit.name}
                                     disabled
                                     style={{ width: "40%" }} />
                        </Field>
                        <Field title="Цена за единицу" preset="boldSmall">
                            <DecimalBox value={specification.unitPrice}
                                        disabled
                                        style={{ width: "20%" }} />
                        </Field>
                        <Field title="Сумма, руб" preset="boldSmall">
                            <DecimalBox value={specification.totalPrice}
                                        disabled
                                        style={{ width: "20%" }} />
                        </Field>
                    </Fields>
                </Card>
                <Card title="Адрес поставки" style={{ border: "none" }}>
                    <Fields orientation="horizontal">
                        <Field title="Адрес поставки ФИАС" preset="boldSmall">
                            <TextBox placeholder="Поиск по ФИАС" disabled />
                        </Field>
                        <Field title="Отсутствует в справочнике" preset="boldSmall">
                            <CheckBox value={true} disabled />
                        </Field>
                        <Field title="Адрес поставки" preset="boldSmall">
                            <TextBox value={specification.supplyAddress}
                                     disabled
                                     lines={5} />
                        </Field>
                    </Fields>
                </Card>
                <Card title="График поставки" style={{ border: "none" }}>
                    <Table columns={supplyColumns} dataset={supplyStages} />
                </Card>
            </VStack>
        </CardModal>
    );
};