import React from "react";
import router from "@/router";
import {Underlined} from "@/components/primitive";
import styles from "./VueLink.module.css";

export const VueLink = (x: { href: string; children?: React.ReactNode}) => {
    const resolved = router.resolve(x.href);
    return <a href={"#" + resolved.href} onClick={() => router.push(x.href)}>
        {x.children}
    </a>;
};

export const UnderlinedVueLink = (x: { href: string; children?: React.ReactNode}) => {
    const resolved = router.resolve(x.href);

    return <a href={resolved.href} onClick={() => router.push(x.href)} className={styles.underlinedLink}>
        <Underlined>
            {x.children}
        </Underlined>
    </a>;
};