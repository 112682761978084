import { observer } from "mobx-react";
import React from "react";
import { TooltipIcon } from "@/components/TooltipIcon";
import { Checkbox, FavoriteButton } from "@/components/eshop";
import { onSelectItemService, ServicesStore } from "../store";
import styles from "@/components/eshop/ServicesTable.module.css";
import { EshopLink } from "@/components/eshop/EshopLink";
import { FormControlEshopInput } from "@/components/eshop/FormControlEshopInput";
import { SubjectClassSelector } from "@/components/eshop/SubjectClassSelector";
import { PageOfServices, Service } from "@/api/Services";
import { SubjectClassFilterStore } from "@/components/eshop/SubjectClassFilterStore";
import { changeId, changeKpgz, changeSubject, ServicesFilterStore } from "../filtersStore";

type ServiceTableProps = {
    page: PageOfServices
    store: ServicesStore
    filterStore: ServicesFilterStore
    subjectClassSelector: SubjectClassFilterStore
};

export const CreateSessionServicesTable = (props: ServiceTableProps) => {
    const isSelected = (id: number) => props.store.selectedNewIds.indexOf(id) !== -1;
    const alreadyAdded = (id: string) => props.store.services.map(p => p.idForKey).includes(id);
    const { page } = props;

    return <div className={styles.tableWrapper}>
        <div className={styles.offersTable}>
            <TableHeader store={props.store} filterStore={props.filterStore} subjectClassSelector={props.subjectClassSelector} />
            <div className={styles.tableBodyWrap} id="tableContent">
                {page.items.map(r => <ServiceRow alreadyAdded={alreadyAdded(r.id.toString())} checked={isSelected(r.id)} onSelect={onSelectItemService} key={r.subject} item={r} />)}
            </div>
        </div>
    </div>;
};

const ServiceRow = (props: { item: Service; alreadyAdded: boolean; checked: boolean; onSelect: (id: number) => void }) => {
    const { item } = props;

    return <ul className={styles.tableRow}>
        <li className={styles.controlContainer}>
            <Checkbox onChange={() => !props.alreadyAdded && props.onSelect(props.item.id)}
                value={props.checked || props.alreadyAdded} />
        </li>
        <li className={styles.controlContainer}>
            <FavoriteButton favorite={false} />
        </li>
        <li className={styles.tableCellContent}>
            <EshopLink>
                {item.subject}
            </EshopLink>
        </li>
        <li className={styles.tableCellContent}>
            <EshopLink>
                {props.item.subjectClass.code} - {props.item.subjectClass.description}
            </EshopLink>
        </li>
    </ul>;
};

const TableHeader = observer((props: { store: ServicesStore; filterStore: ServicesFilterStore; subjectClassSelector: SubjectClassFilterStore }) => {
    const { isSelectedName } = props.store;
    return <div className={styles.offersTableHeader} id="tableHeader">
        <ul className={styles.headerContainer}>
            <li className={styles.controlContainerHeader}>
                <Checkbox />
            </li>
            <li className={styles.tableCellServiceContent}>
                <div className={styles.eshopSortButton}>
                    <div className={`${styles.arrowIcon} ${styles.arrowIconUp}`} />
                    <div className={`${styles.arrowIcon}`} />
                </div>
                Наименование СПГЗ <TooltipIcon />
            </li>
            <li className={styles.tableCellServiceContent}>
                КПГЗ <TooltipIcon />
            </li>
        </ul>
        <div className={styles.filtersContainer}>
            <div className={styles.filterInput}>
                <FormControlEshopInput
                    onChange={e => isSelectedName ? changeSubject(e.target.value) : changeId(parseInt(e.target.value))}
                    placeholder={isSelectedName ? "Введите наименование СПГЗ" : "Введите идентификатор СПГЗ"} />
            </div>
            <div className={styles.filterInput}>
                <SubjectClassSelector value={props.filterStore.kpgz || null} onSelect={e => e && changeKpgz({ kpgz: e })} store={props.subjectClassSelector} />
            </div>
        </div>
    </div>;
});
