import {
    TextRow,
    MultilineTextRow,
    IColumn,
    IRow,
    IHint,
    SelectionRow,
    RawComponentRow,
    DateRow,
    CheckboxRow,
    IntegerRow,
    DecimalRow,
    SimpleRow,
    AmountPercentRow,
    PickRow,
    DateRangeRow,
    RadioRow,
} from "@/components/vue/form-table/FormFields";
import { ISection, FormStyle, IFieldSection, Icon, IAction, IconType } from "@/views/FormAbstractions";
import FieldSection from "@/views/form-renderers/FieldSection/FieldSection.vue";
import { SelectOption, EnumStrings, toSelectOptions } from "@/models/enums";
import { VueClass } from "vue-class-component/lib/declarations";
import { Vue } from "vue-property-decorator";
import { DateTimeRange } from "@/models/DateTimeRange";

type Diff<TLhs, TRhs> = TLhs extends TRhs ? never : TLhs;
type Props<T> = { [key in keyof T]?: T[key] };
type KeyofT<T> = keyof T;

interface Fieldmodded<T> {
    readonly _fieldmod: Props<IRow<T>> & { converter?: Converter<T, unknown> }
}

interface ExplicitFieldHelpers<T> extends Fieldmodded<T> {
    // fields
    roText: (title: string, getter: (_: T) => unknown, props?: Props<TextRow<T>>) => TextRow<T>
    text: (
        title: string,
        getter: (_: T) => unknown,
        setter: (x: T, y: unknown) => void,
        props?: Props<TextRow<T>>,
    ) => TextRow<T>
    decimal: (
        title: string,
        getter: (_: T) => unknown,
        setter: (x: T, y: unknown) => void,
        props?: Props<DecimalRow<T>>,
    ) => DecimalRow<T>
    multiline: (
        title: string,
        getter: (_: T) => unknown,
        setter: (x: T, y: unknown) => void,
        props?: Props<MultilineTextRow<T>>,
    ) => MultilineTextRow<T>

    date: (
        title: string,
        getter: (_: T) => Date | null,
        setter: (x: T, y: Date) => void,
        props?: Props<DateRow<T>>,
    ) => DateRow<T>
    dateRange: (
        title: string,
        getter: (_: T) => DateTimeRange | null,
        setter: (x: T, y: DateTimeRange) => void,
        props?: Props<DateRangeRow<T>>,
    ) => DateRangeRow<T>

    select: <O extends SelectOptions>(
        title: string,
        getter: (_: T) => unknown,
        setter: (x: T, y: unknown) => void,
        opts: O,
        props?: Props<SelectionRow<T>>,
    ) => SelectionRow<T>
    checkbox: (
        title: string,
        getter: (_: T) => unknown,
        setter: (x: T, y: unknown) => void,
        props?: Props<CheckboxRow<T>>,
    ) => CheckboxRow<T>

    // raw fields
    raw: <V>(component: VueClass<Vue>, getter: (_: T) => V, setter: (x: T, y: V) => void) => RawComponentRow<T>
}

type Acc<T> = KeyofT<NotNull<T>>;
type Ar4T<T, T1 extends keyof T, T2 extends K<T[T1]> = undefined, T3 extends K<DA<T[T1], T2>> = undefined> =
    | [T1, Acc<T[T1]>?, Acc<NotNull<T[T1]>[Defined<T2>]>?, Acc<NotNull<NotNull<T[T1]>[Defined<T2>]>[Defined<T3>]>?]
    | T1;
type Indexer = string | number | symbol;
type Ar4Ut = [string, string?, string?, string?];

type Defined<T> = T extends undefined ? never : T;
type NotNull<T> = T extends null ? never : T;
type K<T> = keyof NotNull<T> | undefined;
type DA<T, A extends keyof NotNull<T> | undefined> = NotNull<T>[Defined<A>];
type Access<
    T,
    T1 extends keyof T,
    T2 extends K<T[T1]> = undefined,
    T3 extends K<DA<T[T1], T2>> = undefined
> = T3 extends undefined
    ? T2 extends undefined
        ? T[T1]
        : NotNull<T[T1]>[Defined<T2>]
    : NotNull<NotNull<T[T1]>[Defined<T2>]>[Defined<T3>];

type TypedProps<T, TRm> = { [k in keyof T]: T[k] extends TRm ? k : never }[keyof T];

type BoolField<
    T,
    T1 extends keyof T,
    T2 extends K<T[T1]> = undefined,
    T3 extends K<DA<T[T1], T2>> = undefined
> = TypedProps<Access<T, T1, T2, T3>, boolean>;

type Sub0<O extends Indexer, D extends Indexer> = { [k in O]: (Record<D, never> & Record<Indexer, k>)[k] };
type Omit<O, D extends Indexer, T extends Sub0<keyof O, D> = Sub0<keyof O, D>> = Pick<O, T[keyof O]>;

type WithoutBoolField<
    O extends Indexer,
    T,
    T1 extends keyof T,
    T2 extends K<T[T1]> = undefined,
    T3 extends K<DA<T[T1], T2>> = undefined
> = Omit<Access<T, T1, T2, T3>, O>;

export function pathfactory<T>() {
    return <
        T1 extends keyof T,
        T2 extends keyof NotNull<T[T1]>,
        T3 extends keyof NotNull<NotNull<T[T1]>[T2]>,
        T4 extends keyof NotNull<NotNull<NotNull<T[T1]>[T2]>[T3]>
    >(
        k1: T1,
        k2?: T2,
        k3?: T3,
        k4?: T4,
    ): Ar4T<T, T1, T2, T3> => (k2 ? (k3 ? (k4 ? [k1, k2, k3, k4] : [k1, k2, k3]) : [k1, k2]) : [k1]);
}

type SelectOptions = SelectOption<unknown>[] | EnumStrings | Promise<SelectOption<unknown>[]>;
type AvailSelectOptions = SelectOption<unknown>[] | Promise<SelectOption<unknown>[]>;

export declare interface Converter<T, T2> {
    convert(t: T): T2
    convertBack(t2: T2): T
}

interface FieldHelpers<T> extends Fieldmodded<T> {
    // modifiers
    readonly _sectionmod: Props<IFieldSection<T>>
    required(): FieldHelpers<T>
    converted<T2>(converter: Converter<unknown, T2>): FieldHelpers<T>
    hinted(hint: IHint): FieldHelpers<T>
    visible(v: (_: T) => boolean): FieldHelpers<T>
    readonly(r?: (_: T) => boolean): FieldHelpers<T>
    const(r?: (_: T) => boolean): FieldHelpers<T>
    explicit(): ExplicitFieldHelpers<T>

    // sections
    fields: (header: string, style: FormStyle, columnCount: number, ...columns: IColumn<T>[]) => IFieldSection<T>
    fieldColumn: (header: string, style: FormStyle, rows: IRow<T>[]) => IFieldSection<T>

    // fields
    enum: <T1 extends keyof T, T2 extends K<T[T1]> = undefined, T3 extends K<DA<T[T1], T2>> = undefined>(
        title: string,
        pp: Ar4T<T, T1, T2, T3>,
        strings: EnumStrings,
        props?: Props<TextRow<T>>,
    ) => TextRow<T>
    text: <T1 extends keyof T, T2 extends K<T[T1]> = undefined, T3 extends K<DA<T[T1], T2>> = undefined>(
        title: string,
        pp: Ar4T<T, T1, T2, T3>,
        props?: Props<TextRow<T>>,
    ) => TextRow<T>
    integer: <T1 extends keyof T, T2 extends K<T[T1]> = undefined, T3 extends K<DA<T[T1], T2>> = undefined>(
        title: string,
        pp: Ar4T<T, T1, T2, T3>,
        props?: Props<IntegerRow<T>>,
    ) => IntegerRow<T>
    decimal: <T1 extends keyof T, T2 extends K<T[T1]> = undefined, T3 extends K<DA<T[T1], T2>> = undefined>(
        title: string,
        pp: Ar4T<T, T1, T2, T3>,
        props?: Props<DecimalRow<T>>,
    ) => DecimalRow<T>
    multiline: <T1 extends keyof T, T2 extends K<T[T1]> = undefined, T3 extends K<DA<T[T1], T2>> = undefined>(
        title: string,
        pp: Ar4T<T, T1, T2, T3>,
        props?: Props<MultilineTextRow<T>>,
    ) => MultilineTextRow<T>
    date: <T1 extends keyof T, T2 extends K<T[T1]> = undefined, T3 extends K<DA<T[T1], T2>> = undefined>(
        title: string,
        pp: Ar4T<T, T1, T2, T3>,
        props?: Props<DateRow<T>>,
    ) => DateRow<T>
    dateRange: <T1 extends keyof T, T2 extends K<T[T1]> = undefined, T3 extends K<DA<T[T1], T2>> = undefined>(
        title: string,
        pp: Ar4T<T, T1, T2, T3>,
        props?: Props<DateRangeRow<T>>,
    ) => DateRangeRow<T>

    select: <
        O extends SelectOptions,
        T1 extends keyof T,
        T2 extends K<T[T1]> = undefined,
        T3 extends K<DA<T[T1], T2>> = undefined
    >(
        title: string,
        pp: Ar4T<T, T1, T2, T3>,
        opts: O,
        props?: Props<SelectionRow<T>>,
    ) => SelectionRow<T>
    radio: <
        O extends SelectOptions,
        T1 extends keyof T,
        T2 extends K<T[T1]> = undefined,
        T3 extends K<DA<T[T1], T2>> = undefined
    >(
        title: string,
        pp: Ar4T<T, T1, T2, T3>,
        opts: O,
        groupName: string,
        props?: Props<RadioRow<T>>,
    ) => RadioRow<T>
    inlineRadio: <
        O extends SelectOptions,
        T1 extends keyof T,
        T2 extends K<T[T1]> = undefined,
        T3 extends K<DA<T[T1], T2>> = undefined
    >(
        title: string,
        pp: Ar4T<T, T1, T2, T3>,
        opts: O,
        groupName: string,
        props?: Props<RadioRow<T>>,
    ) => RadioRow<T>
    checkbox: <T1 extends keyof T, T2 extends K<T[T1]> = undefined, T3 extends K<DA<T[T1], T2>> = undefined>(
        title: string,
        pp: Ar4T<T, T1, T2, T3>,
        props?: Props<CheckboxRow<T>>,
    ) => CheckboxRow<T>
    amountPercent: <T1 extends keyof T, T2 extends K<T[T1]> = undefined, T3 extends K<DA<T[T1], T2>> = undefined>(
        title: string,
        pp: Ar4T<T, T1, T2, T3>,
        description: string,
        props?: Props<AmountPercentRow<T>>,
    ) => AmountPercentRow<T>
    pick: <T1 extends keyof T, T2 extends K<T[T1]> = undefined, T3 extends K<DA<T[T1], T2>> = undefined>(
        title: string,
        pp: Ar4T<T, T1, T2, T3>,
        id: string,
        props?: Props<PickRow<T>>,
    ) => PickRow<T>

    // raw fields
    raw: <T1 extends keyof T, T2 extends K<T[T1]> = undefined, T3 extends K<DA<T[T1], T2>> = undefined>(
        component: VueClass<Vue>,
        pp: Ar4T<T, T1, T2, T3>,
    ) => RawComponentRow<T>
}

const eo = {} as Indexable4;
function nn(v: unknown): Indexable4 {
    return (v ? v : eo) as Indexable4;
}

type Indexable1 = {[key: string]: unknown};
type Indexable2 = {[key: string]: Indexable1};
type Indexable3 = {[key: string]: Indexable2};
type Indexable4 = {[key: string]: Indexable3};

function genGetter(ppOrP: Ar4Ut | string): (_: unknown) => unknown {
    const pp: Ar4Ut =
        typeof ppOrP === "string" ? [ppOrP as string] as Ar4Ut : ppOrP as Ar4Ut;
    switch (pp.length) {
        case 1:
            return t => (t as Indexable1)[pp[0]];
        case 2:
            return t => nn((t as Indexable2)[pp[0]])[pp[1]!];
        case 3:
            return t => nn(nn((t as Indexable3)[pp[0]])[pp[1]!])[pp[2]!];
        case 4:
            return t => nn(nn(nn((t as Indexable4)[pp[0]])[pp[1]!])[pp[2]!])[pp[3]!];
    }
}

function genSetter(ppOrP: Ar4Ut | string): (_: unknown, v: unknown) => void {
    const pp: Ar4Ut =
        typeof ppOrP === "string" || typeof ppOrP === "symbol" || typeof ppOrP === "number" ? [ppOrP] : ppOrP;
    switch (pp.length) {
        case 1:
            return (t, v) => ((t as Indexable1)[pp[0]] = v);
        case 2:
            return (t, v) => ((t as Indexable2)[pp[0]][pp[1]!] = v);
        case 3:
            return (t, v) => ((t as Indexable3)[pp[0]][pp[1]!][pp[2]!] = v);
        case 4:
            return (t, v) => ((t as Indexable4)[pp[0]][pp[1]!][pp[2]!][pp[3]!] = v);
    }
}

function getterConv<T>(getter: (_: T) => unknown, that: Fieldmodded<T>): (_: T) => unknown {
    const conv = that._fieldmod.converter;
    return conv ? (t: T) => conv.convert(getter(t) as never) as unknown : getter;
}

function setterConv<T>(setter: (_: T, v: never) => void, that: Fieldmodded<T>): (_: T, v: never) => void {
    const conv = that._fieldmod.converter;
    return conv ? (t, v) => setter(t, conv.convertBack(v) as never) : setter;
}

const fieldHelpers: FieldHelpers<unknown> = {
    _fieldmod: {},
    _sectionmod: {},
    required() {
        return { ...this, _fieldmod: { ...this._fieldmod, required: true } };
    },
    const(r) {
        return { ...this, _fieldmod: { ...this._fieldmod, editable: (v) => (r && !r(v)) || false } };
    },
    hinted(hint) {
        return { ...this, _fieldmod: { ...this._fieldmod, hint: hint } };
    },
    readonly(v) {
        return { ...this, _fieldmod: { ...this._fieldmod, readonly: v || true } };
    },
    visible(v) {
        return {
            ...this,
            _fieldmod: { ...this._fieldmod, visible: v },
            _sectionmod: { ...this._sectionmod, visible: v },
        };
    },
    converted(c) {
        return { ...this, _fieldmod: { ...this._fieldmod, converter: c } };
    },

    explicit() {
        const explicit: ExplicitFieldHelpers<unknown> = {
            _fieldmod: this._fieldmod,
            text(title, getter, setter, mixin) {
                return {
                    title: title,
                    type: "TEXT",

                    getter: getterConv(getter, this),
                    setter: setterConv(setter, this),
                    ...(mixin ? mixin : {}),
                    ...(this._fieldmod as {}),
                };
            },
            decimal(title, getter, setter, mixin) {
                return {
                    title: title,
                    type: "DECIMAL",

                    getter: getterConv(getter, this),
                    setter: setterConv(setter, this),
                    ...(mixin ? mixin : {}),
                    ...(this._fieldmod as {}),
                };
            },
            roText(title, getter, mixin) {
                return {
                    title: title,
                    type: "TEXT",

                    getter: getterConv(getter, this),
                    readonly: true,

                    ...(mixin ? mixin : {}),
                    ...(this._fieldmod as {}),
                };
            },
            multiline(title, getter, setter, mixin) {
                return {
                    title: title,
                    type: "MULTILINE_TEXT",

                    getter: getterConv(getter, this),
                    setter: setterConv(setter, this),
                    ...(mixin ? mixin : {}),
                    ...(this._fieldmod as {}),
                };
            },
            date(title, getter, setter, mixin) {
                return {
                    title: title,
                    type: "DATE_FIELD",

                    getter: getterConv(getter, this),
                    setter: setterConv(setter, this),
                    ...(mixin ? mixin : {}),
                    ...(this._fieldmod as {}),
                };
            },
            dateRange(title, getter, setter, mixin) {
                return {
                    title: title,
                    type: "DATE_RANGE",

                    getter: getterConv(getter, this),
                    setter: setterConv(setter, this),
                    ...(mixin ? mixin : {}),
                    ...(this._fieldmod as {}),
                };
            },
            select(title, getter, setter, opts, mixin) {
                return {
                    title: title,
                    type: "SELECT",

                    getter: getterConv(getter, this),
                    setter: setterConv(setter, this),

                    selectOptions:
                        (opts as Promise<unknown>).then || (opts as unknown[]).length
                            ? (opts as AvailSelectOptions)
                            : toSelectOptions(opts as EnumStrings),
                    ...(mixin ? mixin : {}),
                    ...(this._fieldmod as {}),
                };
            },
            checkbox(title, getter, setter, mixin) {
                return {
                    title: title,
                    type: "CHECKBOX",

                    getter: getterConv(getter, this),
                    setter: setterConv(setter, this),

                    ...(mixin ? mixin : {}),
                    ...(this._fieldmod as {}),
                };
            },
            raw(component, getter, setter) {
                return {
                    type: "RAW",
                    component: component,
                    full: true,

                    getter: getterConv(getter, this),
                    setter: setterConv(setter, this),
                    ...(this._fieldmod as {}),
                };
            },
        };

        return explicit;
    },

    fields(header, style, columnCount, ...columns) {
        return {
            type: "FIELDS",
            name: header,
            component: FieldSection,
            data: {
                formStyle: style,
                columns: columns,
                columnCount: columnCount,
            },
            ...(this._sectionmod as {}),
        };
    },
    fieldColumn(header, style, rows) {
        return {
            type: "FIELDS",
            name: header,
            component: FieldSection,
            data: {
                formStyle: style,
                columns: [{ rows: rows }],
                columnCount: 1,
            },
            ...(this._sectionmod as {}),
        };
    },

    enum(title, pp, strings, mixin) {
        const getter = genGetter(pp);
        return {
            title: title,
            type: "TEXT",

            getter: x => strings[getter(x) as string],
            editable: false,

            ...(mixin ? mixin : {}),
            ...(this._fieldmod as {}),
        };
    },
    text(title, pp, mixin) {
        return {
            title: title,
            type: "TEXT",

            getter: getterConv(genGetter(pp), this),
            setter: setterConv(genSetter(pp), this),
            ...(mixin ? mixin : {}),
            ...(this._fieldmod as {}),
        };
    },
    integer(title, pp, mixin) {
        return {
            title: title,
            type: "INTEGER",

            getter: getterConv(genGetter(pp), this),
            setter: setterConv(genSetter(pp), this),
            ...(mixin ? mixin : {}),
            ...(this._fieldmod as {}),
        };
    },
    decimal(title, pp, mixin) {
        return {
            title: title,
            type: "DECIMAL",

            getter: getterConv(genGetter(pp), this),
            setter: setterConv(genSetter(pp), this),
            ...(mixin ? mixin : {}),
            ...(this._fieldmod as {}),
        };
    },
    multiline(title, pp, mixin) {
        return {
            title: title,
            type: "MULTILINE_TEXT",

            getter: getterConv(genGetter(pp), this),
            setter: setterConv(genSetter(pp), this),
            ...(mixin ? mixin : {}),
            ...(this._fieldmod as {}),
        };
    },
    date(title, pp, mixin) {
        return {
            title: title,
            type: "DATE_FIELD",

            getter: getterConv(genGetter(pp), this),
            setter: setterConv(genSetter(pp), this),
            ...(mixin ? mixin : {}),
            ...(this._fieldmod as {}),
        };
    },
    dateRange(title, pp, mixin) {
        return {
            title: title,
            type: "DATE_RANGE",

            getter: getterConv(genGetter(pp), this),
            setter: setterConv(genSetter(pp), this),
            ...(mixin ? mixin : {}),
            ...(this._fieldmod as {}),
        };
    },
    select(title, pp, opts, mixin) {
        return {
            title: title,
            type: "SELECT",

            getter: getterConv(genGetter(pp), this),
            setter: setterConv(genSetter(pp), this),

            selectOptions:
                (opts as Promise<unknown>).then || (opts as unknown[]).length
                    ? (opts as AvailSelectOptions)
                    : toSelectOptions(opts as EnumStrings),
            ...(mixin ? mixin : {}),
            ...(this._fieldmod as {}),
        };
    },
    radio(title, pp, opts, groupName, mixin) {
        return {
            title: title,
            type: "RADIO_SELECT",

            groupName: groupName,

            getter: getterConv(genGetter(pp), this),
            setter: setterConv(genSetter(pp), this),

            selectOptions:
                (opts as Promise<unknown>).then || (opts as unknown[]).length
                    ? (opts as AvailSelectOptions)
                    : toSelectOptions(opts as EnumStrings),
            ...(mixin ? mixin : {}),
            ...(this._fieldmod as {}),
        };
    },
    inlineRadio(title, pp, opts, groupName, mixin) {
        return {
            title: title,
            type: "INLINE_RADIO_SELECT",

            groupName: groupName,

            getter: getterConv(genGetter(pp), this),
            setter: setterConv(genSetter(pp), this),

            selectOptions:
                (opts as Promise<unknown>).then || (opts as unknown[]).length
                    ? (opts as AvailSelectOptions)
                    : toSelectOptions(opts as EnumStrings),
            ...(mixin ? mixin : {}),
            ...(this._fieldmod as {}),
        };
    },
    checkbox(title, pp, mixin) {
        return {
            title: title,
            type: "CHECKBOX",

            getter: getterConv(genGetter(pp), this),
            setter: setterConv(genSetter(pp), this),

            ...(mixin ? mixin : {}),
            ...(this._fieldmod as {}),
        };
    },
    amountPercent(title, pp, description, mixin) {
        return {
            title: title,
            type: "AMOUNT_PERCENT",

            getter: getterConv(genGetter(pp), this),
            setter: setterConv(genSetter(pp), this),

            description: description,

            ...(mixin ? mixin : {}),
            ...(this._fieldmod as {}),
        };
    },
    pick(title, pp, id, mixin) {
        return {
            title: title,
            type: "PICK",

            id: id,

            getter: getterConv(genGetter(pp), this),
            setter: setterConv(genSetter(pp), this),

            ...(mixin ? mixin : {}),
            ...(this._fieldmod as {}),
        };
    },
    raw(component, pp) {
        return {
            type: "RAW",
            component: component,
            full: true,

            getter: getterConv(genGetter(pp), this),
            setter: setterConv(genSetter(pp), this),
        };
    },
};

export function makeFormHelpers<T>() {
    return fieldHelpers as FieldHelpers<T>;
}

export function actionL<T = unknown>(
    id: string,
    color?: string,
    iconOrTitle?: Icon | string,
    isOk?: (_: T) => boolean,
): IAction<T> {
    return {
        name: id,
        icon: typeof iconOrTitle === "string" ? undefined : iconOrTitle,
        title: typeof iconOrTitle === "string" ? iconOrTitle : undefined,
        color: color,
        isEnabled: isOk,
    };
}

export function actionR<T = unknown>(
    id: string,
    color?: string,
    iconOrTitle?: Icon | string,
    isOk?: (_: T) => boolean,
): IAction<T> {
    return {
        name: id,
        icon: typeof iconOrTitle === "string" ? undefined : iconOrTitle,
        title: typeof iconOrTitle === "string" ? iconOrTitle : undefined,
        color: color,
        isRightAligned: true,
        isEnabled: isOk,
    };
}

export function merge<T>(l: IAction<T>[], r: IAction<T>[], ...m: IAction<T>[]) {
    return [...l, ...m, ...r];
}

export function classIcon(id: string): Icon {
    return { name: id, type: IconType.CLASS };
}

export const commonReadonlyActionsL = [
    actionL("print", "blue", classIcon("print")),
    actionL("clone", "blue", classIcon("clone")),
    actionL("edit", "blue", classIcon("edit")),
    actionL("delete", "red", classIcon("delete")),
];

export const commonReadonlyNoEditActionsL = [
    actionL("print", "blue", classIcon("print")),
    actionL("clone", "blue", classIcon("clone")),
];

export const commonReadonlyActionsR = [actionR("close", "red", classIcon("close"))];
