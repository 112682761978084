import {createStore} from "effector";
import {getProcedures} from "@/api/Procedures2020";
import {Procedure} from "@/models/Procedures2020/Procedure";
import {createStoredRemoteDataset, StoredRemoteDataset} from "@/components/table";
import {FilterConditionType, FilterValueType} from "@/api/http";
import Decimal from "decimal.js";

export type ProcedureFilters = {
    subject: string
    regNumber: string
    law: string
    providerSelectionType: string
    status: string
    lotSpecPriceTotal: Decimal
};

// Procedures list store

export interface ProceduresState {
    procedures: StoredRemoteDataset<Procedure, ProcedureFilters>
    loaded: boolean
}

export const createProceduresStore = () =>{

    const store = createStore<ProceduresState>({
        procedures: createStoredRemoteDataset<Procedure, ProcedureFilters>({
            async load(filters, from, count) {
                return getProcedures(from, count, {
                    subject: [{
                        type: FilterValueType.STRING,
                        conditionType: FilterConditionType.CONTAINS,
                        string: filters.subject
                    }],
                    law: [{
                        type: FilterValueType.STRING,
                        conditionType: FilterConditionType.CONTAINS,
                        string: filters.law
                    }],
                    providerSelectionType: [{
                        type: FilterValueType.STRING,
                        conditionType: FilterConditionType.CONTAINS,
                        string: filters.providerSelectionType
                    }],
                    status: [{
                        type: FilterValueType.STRING,
                        conditionType: FilterConditionType.CONTAINS,
                        string: filters.status
                    }, ...(filters.status ? [] : [{
                        type: FilterValueType.STRING,
                        conditionType: FilterConditionType.EQUAL,
                        string: "PUBLISH_PENDING"
                    }, {
                        type: FilterValueType.STRING,
                        conditionType: FilterConditionType.EQUAL,
                        string: "GRBS_AGREED"
                    }, {
                        type: FilterValueType.STRING,
                        conditionType: FilterConditionType.EQUAL,
                        string: "GRBS_AGREEMENT_PENDING"
                    }, {
                        type: FilterValueType.STRING,
                        conditionType: FilterConditionType.EQUAL,
                        string: "CREATING"
                    }, {
                        type: FilterValueType.STRING,
                        conditionType: FilterConditionType.EQUAL,
                        string: "FO_AGREED"
                    }, {
                        type: FilterValueType.STRING,
                        conditionType: FilterConditionType.EQUAL,
                        string: "FO_AGREEMENT_PENDING"
                    }])],
                    priceTotal: [{
                        type: FilterValueType.DECIMAL,
                        conditionType: FilterConditionType.EQUAL,
                        decimal: filters.lotSpecPriceTotal
                    }]
                });
            }
        }),
        loaded: false
    });

    return store;
};
