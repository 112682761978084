import React, {CSSProperties, ReactNode} from "react";
import {toast} from "react-toastify";
import styles from "./toasts.module.css";

interface IErrorToastProps {
    message: ReactNode
    title?: ReactNode
}

interface IInfoToastProps {
    message: ReactNode
    title?: ReactNode
}

const Toast = ({ props }: { props: IErrorToastProps }) => {

    return (
        <div className={styles.ToastWrapper}>
            { !!props.title && (
                <span className={styles.ToastTitle}>{props.title}</span>
            )}
            <p className={styles.ToastMessage}>{props.message}</p>
        </div>
    );
};

export const toastError = (x: IErrorToastProps) => {
    const style: CSSProperties = {
        backdropFilter: "blur(50px)",
        whiteSpace: "pre-wrap",
        boxSizing: "border-box",
        background: "#f66",
        borderRadius: "10px",
        color: "#f8fafb",
        width: "550px"
    };

    toast.error(<Toast props={x} />, { position: "top-center", style });
};

export const toastErrorRight = (x: IErrorToastProps) => {
    const style: CSSProperties = {
        backdropFilter: "blur(50px)",
        whiteSpace: "pre-wrap",
        boxSizing: "border-box",
        background: "#f66",
        borderRadius: "10px",
        color: "#f8fafb"
    };

    toast.error(<Toast props={x} />, { position: "bottom-right", style });
};

export const toastSuccess = (x: IInfoToastProps) => {
    const style: CSSProperties = {
        backdropFilter: "blur(50px)",
        whiteSpace: "pre-wrap",
        boxSizing: "border-box",
        background: "#80dd7e",
        borderRadius: "10px",
        color: "#f8fafb",
        width: "550px"
    };

    toast.info(<Toast props={x} />, { position: "top-center", style });
};
