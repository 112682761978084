




import { Component, Vue } from "vue-property-decorator";
import { PaymentsList } from "@/views/Payments/list/PaymentsList";
import {createContractPaymentsStore} from "@/views/Payments/list/store";

@Component({ components: { komponent: PaymentsList as unknown as typeof Vue } })
export default class PaymentsListVueStub extends Vue {
    public storeContainer = Object.freeze(createContractPaymentsStore());
}
