import React from "react";
import {Card} from "@/components/Card";
import {Table} from "@/components/table";
import {Label} from "@/components/primitive";

export const CustomersContactFaces: React.FC = x => {
    return <Card>
        <Table<{}> dataset={[{}]} columns={[
            Table.AutoColumn("ФИО", x =>
                <Label>
                </Label>),
            Table.AutoColumn("Телефон", x =>
                <Label>
                </Label>),
            Table.AutoColumn("Электронная почта", x =>
                <Label>
                </Label>),
        ]} />

    </Card>;
};