import {AutoDto, AutoModel, uuid} from "@/models/parsing";

interface PurchaseCategoryMD {
    id: uuid
    name: string
    paragraph: string
}

export type PurchaseCategory = AutoModel<PurchaseCategoryMD>;
export type DtoPurchaseCategory = AutoDto<PurchaseCategoryMD>;

export const PurchaseCategory = {
    toDto(dto: PurchaseCategory): uuid {
        return dto.id;
    },
    fromDto(dto: DtoPurchaseCategory): PurchaseCategory {
        return {
            ...dto
        };
    },
};
