import {CompatEnum, UnionStrings} from './EnumStrings';

export type FinancialSourceType =
    | "FIN_ACTIONS"
    | "OWN_FUNDS"
    | "LIMIT";

export const FinancialSourceType: CompatEnum<FinancialSourceType> = {
    FIN_ACTIONS: "FIN_ACTIONS",
    OWN_FUNDS: "OWN_FUNDS",
    LIMIT: "LIMIT",
};

export const FinancialSourceTypeStrings: UnionStrings<FinancialSourceType> = {
    FIN_ACTIONS: "ПФХД",
    LIMIT: "Лимит",
    OWN_FUNDS: "Собственные средства",
};