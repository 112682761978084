// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, "._3PmwWdM_dK2IN5cB8jUhdQ {\n    display: flex;\n    flex-direction: column;\n    align-items: center;\n    border-radius: 5px;\n    border: 1px solid #eff3f6;\n    max-width: 100%;\n    margin: 4px 4px 20px;\n    /*position: relative;*/\n    min-width: 0;\n    word-wrap: break-word;\n    background-clip: border-box;\n    font-size: 16px;\n    font-family: \"Roboto\", sans-serif;\n}\n\n.NCyr4sqWJvMwF8jiBGXbN {\n    display: flex;\n    flex-direction: column;\n    width: 100%;\n}\n\n._2o_2kROTbRrJ-4v4-t8TGP {\n    display: none;\n}\n\n._3Opz8Tn7o_cDIqkMWJVyWb {\n    background-color: #eff3f6;\n    border-top-right-radius: 4px;\n    border-top-left-radius: 4px;\n    width: 100%;\n    font-size: 20px;\n    font-weight: 500;\n    display: flex;\n    align-items: center;\n    height: 45px;\n    font-family: inherit;\n    padding: 8px 8px 8px 20px;\n    box-sizing: border-box;\n    user-select: none;\n    white-space: nowrap;\n}\n\n.kItP5N5lrszdgnJkAzIvj {\n    font-weight: 400;\n    font-size: 16px;\n    display: flex;\n    align-items: center;\n    width: 100%;\n    justify-content: flex-end;\n    gap: 5px;\n    padding: 5px;\n    cursor: pointer;\n}\n\n._3Opz8Tn7o_cDIqkMWJVyWb ._1ry7Xnh9apfV_RChpmyxXC {\n    color: red;\n}\n", ""]);
// Exports
exports.locals = {
	"section__wrapper": "_3PmwWdM_dK2IN5cB8jUhdQ",
	"section__content": "NCyr4sqWJvMwF8jiBGXbN",
	"section__content_hidden": "_2o_2kROTbRrJ-4v4-t8TGP",
	"section__title": "_3Opz8Tn7o_cDIqkMWJVyWb",
	"section__title__button": "kItP5N5lrszdgnJkAzIvj",
	"required": "_1ry7Xnh9apfV_RChpmyxXC"
};
module.exports = exports;
