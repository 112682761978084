import {UnionStrings} from "./EnumStrings";

export type CharacteristicConditionType =
    | "EQUAL"
    | "NOT_EQUAL"
    | "GREATER_THAN"
    | "LESS_THAN"
    | "RANGE"
    | "GREATER_THAN_OR_EQUAL"
    | "LESS_THAN_OR_EQUAL"
    | "ENUM";

// compat
export const CharacteristicConditionType: { [key in CharacteristicConditionType]: CharacteristicConditionType } = {
    EQUAL: "EQUAL",
    NOT_EQUAL: "NOT_EQUAL",
    GREATER_THAN: "GREATER_THAN",
    LESS_THAN: "LESS_THAN",
    RANGE: "RANGE",
    GREATER_THAN_OR_EQUAL: "GREATER_THAN_OR_EQUAL",
    LESS_THAN_OR_EQUAL: "LESS_THAN_OR_EQUAL",
    ENUM: "ENUM",
};

export const CharacteristicConditionTypeStrings: UnionStrings<CharacteristicConditionType> = {
    EQUAL: "Равно",
    NOT_EQUAL: "Не равно",
    GREATER_THAN: "Больше",
    LESS_THAN: "Меньше",
    RANGE: "Диапазон",
    GREATER_THAN_OR_EQUAL: "Больше или равно",
    LESS_THAN_OR_EQUAL: "Меньше или равно",
    ENUM: "Перечисление",
};
