import React from "react";
import {Card} from "@/components/Card";
import {SectionProps} from "@/views/ComposedLot/shared/store";
import {Button, Label, Note} from "@/components/primitive";
import {Expandee, HSpread, VStack} from "@/components/layouts";
import {useMappedStore} from "@/storeUtils";
import {FDecimalBox, FormState, FTextBox} from "@/components/form";
import {Column, Table} from "@/components/table/Table";
import {Criteria} from "@/models/ComposedLots/documents/LotCriteria";
import {addOrEditCriteria, addOrReplaceSubCriteria, removeSubCriteria} from "@/views/ComposedLot/shared/store/criteria";
import Decimal from 'decimal.js';
import {showModal} from "@/EventBus";
import {AddContentModal} from "@/views/ComposedLot/edit/tabs/lot-documents/CriteriaSection/AddContentModal";

export const CriteriaSection: React.FC<SectionProps> = x => {
    const [
        criteria,
        assessmentOrder,
        specificationItems
    ] = useMappedStore(x.formStore, a => [
        a.criteria.criteria,
        a.criteria.assessmentOrder,
        a.specifications.specificationForms
    ]);

    const updateCriteriaContent = async (old: Criteria) => {
        const result = await showModal(AddContentModal, {title: "Содержание", content: old.content});
        if (result) {
            const updatedCriteria = {...old, content: result};
            addOrEditCriteria({oldV: old, newV: updatedCriteria});
        }
    };

    const updateCriteriaAssessmentOrder = async (old: Criteria) => {
        const result = await showModal(AddContentModal, {title: "Порядок оценки", content: old.assessmentOrder});
        if (result) {
            const updatedCriteria = {...old, assessmentOrder: result};
            addOrEditCriteria({oldV: old, newV: updatedCriteria});
        }
    };


    const cardHeader = <HSpread alignItems="center">
        <span>Критерии оценки</span>
        {
            (x.formAction === "editing") &&
            <Label required>{}</Label>
        }
        <Expandee/>
        {
            (x.formAction === "editing") &&
            <Button color={"green"}
                    title={"обновить критерии"}
            />
        }

    </HSpread>;

    const criteriaColumns: Column<CriteriaDatasetLine>[] = [
        Table.Column("Название", s => <>
            <FormState value={(s.item.parent && x.formAction === "editing") ? "enabled" : "readonly"}>
                <FTextBox
                    value={s.item.item.name}
                    onChange={val => {
                        const parent = s.item.parent;
                        if (parent)
                            return addOrEditCriteria({
                                oldV: s.item.parent,
                                newV: addOrReplaceSubCriteria(
                                    parent,
                                    {
                                        ...s.item.item,
                                        name: val
                                    },
                                    s.item.item
                                )
                            });
                    }}
                />
            </FormState>
        </>),
        Table.Column("Значимость (%)", s => <>
            <FormState value={(x.formAction === "editing") ? "enabled" : "readonly"}>
                <FDecimalBox
                    value={s.item.item.value ?? undefined}
                    onChange={val => {
                        const parent = s.item.parent;
                        if (parent)
                            return addOrEditCriteria({
                                oldV: s.item.parent,
                                newV: addOrReplaceSubCriteria(
                                    parent,
                                    {
                                        ...s.item.item,
                                        value: val ?? undefined
                                    },
                                    s.item.item
                                )
                            });
                        return addOrEditCriteria({
                            oldV: s.item.item,
                            newV: {...s.item.item, value: val ?? undefined}
                        });
                    }}
                />
                {
                    s.item.item.value?.comparedTo(new Decimal(100)) === 1
                    &&
                    <Label text={"Значение не может быть меньше 0 или превышать 100."}
                           style={{
                               marginTop: "5px",
                               color: "red",
                               fontStyle: "italic"
                           }}
                           preset={"readonlyValue"}/>
                }
            </FormState>
        </>, {width: "150px"}),
        Table.AutoColumn("Содержание", s =>
                <>
                    <span style={{padding: "5px"}}>
                        {s.item.item.content ? "Задано" : "Не задано"}
                    </span>
                    {
                        x.formAction === "editing" &&
                        <Button icon={"aEdit"}
                                onClick={async () => await updateCriteriaContent(s.item.item)}
                        />
                    }

                </>
            , {
                cellStyle: {
                    alignItems: "center",
                    justifyContent: "center",
                    display: "flex",
                    flexFlow: "column",
                }
            }
        ),
        Table.AutoColumn("Порядок оценки", s =>
                <>
                    <span style={{padding: "5px"}}>
                        {s.item.item.assessmentOrder ? "Задано" : "Не задано"}
                    </span>
                    {
                        x.formAction === "editing" &&
                        <Button icon={"aEdit"}
                                onClick={async () => await updateCriteriaAssessmentOrder(s.item.item)}
                        />
                    }
                </>
            , {
                cellStyle: {
                    alignItems: "center",
                    justifyContent: "center",
                    display: "flex",
                    flexFlow: "column",
                }
            }
        ),
    ];
    const criteriaColumnsEdit: Column<CriteriaDatasetLine>[] = [
        Table.AutoColumn("Добавить показатель", s => <>
            {
                (x.formAction === "editing") && (s.item.item.canAddSubcriteria) &&
                <Button icon={"aAdd"}
                        onClick={() => {
                            addOrEditCriteria({
                                oldV: s.item.item,
                                newV: addOrReplaceSubCriteria(s.item.item, {
                                    name: "",
                                    assessmentOrder: "",
                                    content: "",
                                    value: new Decimal(0)
                                })
                            });
                        }}
                />
            }

        </>
            ,{
                cellStyle: {
                    alignItems: "center",
                    justifyContent: "center",
                    display: "flex",
                    flexFlow: "column",
                }
            }
        ),
        Table.AutoColumn("Удалить", s => {
            const parent = s.item.parent;
            return ((x.formAction === "editing") && parent) ?
                <>
                    {
                        <Button color={"red"}
                                icon={"aDelete"}
                                onClick={() => addOrEditCriteria({
                                    oldV: parent,
                                    newV: removeSubCriteria(parent, s.item.item)
                                })}
                        />
                    }
                </>
                :
                <></>;
        })
    ];
    // If an item has a parent - we assume it's a Subcriteria
    const convertToCriteriaDatasetLines = (a: Criteria[]): CriteriaDatasetLine[] => {
        return a.sort((x, y) => x.orderNumber - y.orderNumber).map(x => ((x.subcriteria && x.subcriteria.length) ?
                [{
                    parent: undefined,
                    item: x
                }, ...x.subcriteria.map(n => (
                        {
                            parent: x,
                            item: n
                        }
                    )
                )]
                :
                {
                    parent: undefined,
                    item: x
                }
        )).flat() as CriteriaDatasetLine[];
    };

    interface CriteriaDatasetLine {
        readonly parent?: Criteria
        readonly item: Criteria
    }

    return <Card title={cardHeader}>
        <VStack spacing="15px">
            {
                (specificationItems.length)
                    ? <Table dataset={convertToCriteriaDatasetLines(criteria)}
                             columns={
                                 x.formAction === "editing"
                                     ? [...criteriaColumns, ...criteriaColumnsEdit]
                                     : criteriaColumns}/>
                    : x.formAction === "editing"
                    ? <Note preset={"alert"} style={{marginBottom: "10px"}}>
                        {`Заполнение раздела "Критерии оценки" возможно только после первого сохранения СПГЗ в разделе "Спецификации"`}
                    </Note>
                    : <Table dataset={[]}
                             columns={criteriaColumns}/>
            }
        </VStack>
    </Card>;
};
