




import {Component, Vue, Prop} from "vue-property-decorator";
import {EditLot} from './EditLot';
import {createLotStore, FormAction} from "@/views/ComposedLot/shared/store";

@Component({ components: { komponent: EditLot as unknown as typeof Vue } })
export default class ViewLotVueStub extends Vue {
    @Prop() public objectId!: string;

    public storeContainer = Object.freeze({ store: createLotStore({ law: "F44", id: this.objectId }) });
}
