import {ModalFC} from "@/EventBus";
import {Expandee, HStack, VStack} from "@/components/layouts";
import {Button, ComboBox} from "@/components/primitive";
import {CardModal} from "@/components/modals/CardModal";
import React, {useState} from "react";
import {CancellationReason} from "@/models/ComposedLots/CancellationReason";
import {Field, Fields} from "@/components/form/Field";
import {SelectOption} from "@/models/enums";

export const CancelLotModal: ModalFC<{reasons: CancellationReason[]}, CancellationReason> = x => {

    const options: SelectOption<CancellationReason>[] = x.reasons.map(r => ({
        key: r,
        desc: r.reason
    }));

    const [reason, setReason] = useState<CancellationReason>();

    const bottom = <HStack spacing={"10px"}>
        <Expandee />
        <Button color="red"
                title="закрыть"
                onClick={() => x.done(undefined)} />
        <Button color="green"
                title="отменить лот"
                disabled={!reason}
                onClick={() => x.done(reason)} />
    </HStack>;

    return <CardModal title={"Отмена лота"}
                      close={() => x.done(undefined)}
                      width={"60%"}
                      bottom={bottom}
    >
        <VStack spacing={"15px"}>
            <Fields orientation={"horizontal"}>
                <Field required
                       title={"Укажите причину отмены опубликованного лота"}>
                    <ComboBox options={options} value={reason} onChange={setReason} />
                </Field>
            </Fields>
        </VStack>
    </CardModal>;
};