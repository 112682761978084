









import { FComponent } from "@/components/next/FComponent";
import { Component, Prop, Mixins } from "vue-property-decorator";
import { IHint } from "@/components/vue/form-table/FormFields";
import EventBus from "@/EventBus";
import { ApiError } from '../../api/ApiError';

@Component
export default class FError extends Mixins(FComponent) {
    @Prop({ default: () => {} }) public errorStyle!: {};

    public error: ApiError | null = null;

    public created() {
        EventBus.$on("raise-error", this.raiseError);
        EventBus.$on("discard-error", this.discardError);
    }

    private raiseError(error: ApiError) {
        this.error = error;
    }

    private discardError() {
        this.error = null;
    }

    public beforeDestroy() {
        EventBus.$off("raise-error", this.raiseError);
        EventBus.$off("discard-error", this.discardError);
    }
}
