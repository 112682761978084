import {autoSV, createSVEvent, origEv} from "@/storeUtils";
import {createEvent, createStore, Store} from "effector";
import {LotForm} from "@/views/ComposedLot/shared/store/index";
import {uuid} from "@/models/parsing";
import {PurchaseCategory} from "@/models/ComposedLots/PurchaseCategory";

export interface LotPurchaseCategories {
    categories: PurchaseCategory[]
}

export const setPurchaseCategoriesIds = createSVEvent<LotPurchaseCategories>()("categories");
export const addPurchaseCategory = createEvent<PurchaseCategory>("add category");
export const removePurchaseCategory = createEvent<PurchaseCategory>("remove category");

const replace = createEvent<LotPurchaseCategories>("replace purchaseCategories");

export const PurchaseCategoriesStore = {
    createStore: (): Store<LotPurchaseCategories> => {
        const store = createStore<LotPurchaseCategories>({ categories: [] })
            .on(replace, (_, l) => l)
            .on(addPurchaseCategory, (s, p) =>
                !s.categories.find(({ id }) => id === p.id) ? { ...s, categories: [...s.categories, p] } : s)
            .on(removePurchaseCategory, (s, p) => ({
                ...s,
                categories: s.categories.filter(({ id }) => id !== p.id)
            }))
            .on(setPurchaseCategoriesIds[origEv], autoSV);

        return store;
    },

    replace: replace,
    observeOnCombinedStore: (combinedStore: Store<LotForm>) => {}
};