import React from "react";
import { observer } from "mobx-react";
import { TooltipIcon } from "@/components/TooltipIcon";
import { SessionsStore, resetFilters, toggleFilterOpened } from "./store";
import styles from './Header.module.css';

export const Header = observer(({ store }: HeaderProps) => <div className={styles.headerContainer}>
    <div className={styles.pageHeader}>Котировочные сессии</div>
    <ul className={styles.iconsList}>
        <li className={`${styles.iconControl} ${styles.filter}`} onClick={() => toggleFilterOpened()} title="Показать/скрыть фильтр" id="filter_icon" />
        <li className={`${styles.iconControl} ${styles.filter}`} onClick={() => toggleFilterOpened()} title="Показать/скрыть фильтр" id="filter_icon" />
        <li className={`${styles.iconControl} ${styles.refresh}`} onClick={() => resetFilters()} title="Сбросить параметры поиска" />
        <li className="not-hover">
            <TooltipIcon />
        </li>
    </ul>
</div>);

type HeaderProps = {
    store: SessionsStore
};
