import { IDtoSubjectDeclaration } from "@/models/json";
import { IJsonFormattable } from "@/models/IJsonFormattable";
import { Okpd2, SubjectClass, MeasurementUnit, Characteristic } from "@/models";
import { SubjectDeclarationRule } from "@/models/enums/SubjectDeclarationRule";

export class SubjectDeclaration implements IJsonFormattable<IDtoSubjectDeclaration> {
    constructor(
        public id: number,
        public subjectClass: SubjectClass,
        public subject: string,
        public okpd2: Okpd2,
        public projectCheck: boolean,
        public measurementUnits: MeasurementUnit[],
        public characteristics: Characteristic[],
        public standardProduct: boolean,
        public innovationProduct: boolean,
        public rules: SubjectDeclarationRule[],
        public isFavorite: boolean
    ) {}

    public toString() {
        return this.subject;
    }

    public toJson(): IDtoSubjectDeclaration {
        return {
            ...this,
            okpd2: this.okpd2.toJson(),
            subjectClass: this.subjectClass.toJson(),
            measurementUnits: this.measurementUnits.map(x => x.toJson()),
            characteristics: this.characteristics.map(x => x.toJson()),
        };
    }

    // noinspection JSUnusedGlobalSymbols
    public static fromJson(dto: IDtoSubjectDeclaration): SubjectDeclaration {
        return Object.assign(Object.create(SubjectDeclaration.prototype), dto, {
            okpd2: Okpd2.fromJson(dto.okpd2),
            subjectClass: SubjectClass.fromJson(dto.subjectClass),
            measurementUnits: dto.measurementUnits.map(x => MeasurementUnit.fromJson(x)),
            characteristics: dto.characteristics?.map(x => Characteristic.fromJson(x)) ?? [],
        });
    }
}
