import { render, staticRenderFns } from "./FNotice.vue?vue&type=template&id=6ce76e82&scoped=true&"
var script = {}
import style0 from "./FNotice.vue?vue&type=style&index=0&id=6ce76e82&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "6ce76e82",
  null
  
)

export default component.exports