








































import { Component, Prop, Vue } from "vue-property-decorator";
import CardModal from "@/components/CardModal.vue";
import Card from "@/components/vue/Card.vue";
import FormTable from "@/components/vue/FormTable.vue";
import { ModalVue, ModalResult } from "@/view-models/ModalRequest";
import TextField from "@/components/vue/form-table/TextField.vue";
import PurchaseObjectDetailedTerm from "@/models/PurchaseObjectDetailedTerm";
import { formatNumber, format } from "@/NumberFormatting";
import { IColumn } from '@/components/vue/form-table/FormFields';
import Decimal from 'decimal.js';
import { ITableColumn, SingleSelection } from '@/components/TableAbstractions';
import {SupplyStage as SupplyStageOld} from '@/models';
import { formatDate } from '@/DateFormatting';
import DataTable from "@/components/vue/DataTable.vue";
import { SupplyStagePeriodType, DayType } from '../../../models/enums';
import { StartPriceMethodStrings } from '../../../models/enums/StartPriceMethod';
import {Specification, SupplyStage} from "@/models/ComposedLots/specifications";
import {IDtoSupplyStage} from "@/models/json";

@Component({ components: { CardModal, TextField, Card, FormTable, DataTable } })
export default class SpecificationDetailsModal extends ModalVue<null, null> {
    @Prop() private prop!: {spec: Specification; id: number};

    private formatDate(stage: SupplyStageOld, end: boolean): string {
        switch (stage.type) {
            case SupplyStagePeriodType.RELATIVE:
                const offset = end ? stage.relativePeriod.endOffset : stage.relativePeriod.startOffset;
                const type = end ? stage.relativePeriod.endOffsetType : stage.relativePeriod.startOffsetType;
                const typeStr = type === DayType.NORMAL ? "календарных" : "рабочих";
                return `${offset} ${typeStr} дней с момента заключения контракта`;
            default:
                return (
                    (end
                        ? stage.absolutePeriod.endDate ? stage.absolutePeriod.endDate .toLocaleDateString() : ""
                        : stage.absolutePeriod.startDate ? stage.absolutePeriod.startDate.toLocaleDateString() : "") + ""
                );
        }
    }

  private convertSupplyStageToOld = (s: SupplyStage): SupplyStageOld =>
      SupplyStageOld.fromJson(SupplyStage.toDto(s) as IDtoSupplyStage);

    public mounted() {
        if (this.prop !== null) {
            this.supplyStages = this.prop.spec.supplyStages.map(x => this.convertSupplyStageToOld(x));
        }
    }

    public supplyStages: SupplyStageOld[] = [];

    public schedHeaders: ITableColumn<SupplyStageOld>[] = [
        {
            title: "Объем поставки",
            getter(v) {
                return v.volume;
            },
        },
        {
            title: "Дата начала поставки",
            getter: v => this.formatDate(v, false),
        },
        {
            title: "Дата окончания поставки",
            getter: v => this.formatDate(v, true),
        },
        {
            title: "Условия поставки",
            getter(v) {
                return v.conditions;
            },
        },
    ];

    public purchaseInfoColumns: IColumn<Specification>[] = [
        {
            rows: [
                {
                    title: "KПГЗ",
                    type: "TEXT",
                    editable: false,
                    getter(src) {
                        return (src.subjectDeclaration?.subjectClass.code ?? "") + " " + (src.subjectDeclaration?.subjectClass.description ?? "");
                    },
                    setter() {},
                },
                {
                    title: "ОКПД",
                    type: "TEXT",
                    editable: false,
                    getter(src) {
                        return (src.subjectDeclaration?.okpd2.code ?? "") + " " + (src.subjectDeclaration?.okpd2.name ?? "");
                    },
                    setter() {},
                },
            ],
        },
        {
            rows: [
                {
                    title: "СПГЗ",
                    type: "TEXT",
                    editable: false,
                    getter(src) {
                        return src.subjectDeclaration?.subject ?? "";
                    },
                    setter() {},
                },
                {
                    title: "ОКПД-2",
                    type: "TEXT",
                    editable: false,
                    getter(src) {
                        return (src.subjectDeclaration?.okpd2.code ?? "") + " " + (src.subjectDeclaration?.okpd2.name ?? "");
                    },
                    setter() {},
                },
            ],
        },
    ];
    public priceColumns: IColumn<Specification>[] = [
        {
            rows: [
                {
                    title: "Метод определения цены",
                    type: "TEXT",
                    editable: false,
                    getter(src) {
                        return StartPriceMethodStrings[src.startPrice!.method];
                    },
                    setter() {},
                },
                {
                    title: "Объём закупки",
                    type: "TEXT",
                    editable: false,
                    getter(src) {
                        return src.volume + ' ' + src.measurementUnit?.name ?? "";
                    },
                    setter() {},
                },
                {
                    title: "Цена за единицу",
                    type: "TEXT",
                    editable: false,
                    getter(src) {
                        return formatNumber(src.startPrice!.perItem) +' рублей';
                    },
                    setter() {},
                },
            ],
        },
        {
            rows: [
                {
                    title: "",
                    type: "TEXT",
                    editable: false,
                    getter(src) {
                        return '';
                    },
                    setter() {},
                },
                {
                    title: "",
                    type: "TEXT",
                    editable: false,
                    getter(src) {
                        return '';
                    },
                    setter() {},
                },{
                    title: "",
                    type: "TEXT",
                    editable: false,
                    getter(src) {
                        return '';
                    },
                    setter() {},
                },
                {
                    title: "Сумма закупки",
                    type: "TEXT",
                    editable: false,
                    getter(src) {

                        return formatNumber(src.startPrice!.total)+' рублей';
                    },
                    setter() {},
                },
            ],
        },
    ];

    public cancel() {
        this.$emit("close-modal", ModalResult.ok(null));
    }
}
