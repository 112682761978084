import { IDtoParticipant } from "@/models/json";
import { AutoDto, AutoModel, MDMap, uuid } from "@/models/parsing";
import { Participant } from "@/models/Participant";
import Decimal from "decimal.js";
import {
    DtoProvisionSingleProviderReason,
    ProvisionSingleProviderReason
} from "@/models/Provision/ProvisionSingleProviderReason";

export const enum ProvisionStatus {
    PROJECT = "PROJECT",
    SENT_FOR_PUBLICATION = "SENT_FOR_PUBLICATION" ,
    PUBLISHED = "PUBLISHED",
    ERROR_PUBLISHING = "ERROR_PUBLISHING"
}

export const ProvisionStatusStrings: Record<keyof typeof ProvisionStatus, string> = {
    [ProvisionStatus.PROJECT]: "Проект",
    [ProvisionStatus.SENT_FOR_PUBLICATION]: "Отправлено на публикацию",
    [ProvisionStatus.PUBLISHED]: "Опубликовано",
    [ProvisionStatus.ERROR_PUBLISHING]: "Ошибка публикации"
};

interface ProvisionMD {
    id: uuid
    approvedAt: Date
    approvedAuthority: string
    bankWarrantyDays: number
    canChangeSingleProviderReason: boolean
    changed505FZ: boolean
    customer: MDMap<Participant, IDtoParticipant>

    dayForRequestProposalOverSum: number
    daysForRequestProposal: number
    depositDays: number
    effectiveAt: Date
    effectiveAt505FZ: Date
    maxPriceQuotationSession: Decimal
    minValueEnforcement: Decimal
    possibilityOfChangeInfo: boolean
    possibilityOfRefusal: boolean
    typicalProvision: boolean
    canChangeDaysByMRG: boolean
    provisionRequestAmountLimit: number
    reasonsSingleProvider: MDMap<ProvisionSingleProviderReason, DtoProvisionSingleProviderReason>[]

    regNumber: number
    status: string
    sumForRequestProposal: number
}

export type Provision = AutoModel<ProvisionMD>;
export type DtoProvision = AutoDto<ProvisionMD>;

export const Provision = {
    fromDto(dtoProvision: DtoProvision): Provision {
        return {
            ...dtoProvision,
            approvedAt: new Date(dtoProvision.approvedAt),
            customer: Participant.fromJson(dtoProvision.customer),
            effectiveAt: new Date(dtoProvision.effectiveAt),
            effectiveAt505FZ: new Date(dtoProvision.effectiveAt),
            maxPriceQuotationSession: new Decimal(dtoProvision.maxPriceQuotationSession),
            minValueEnforcement: new Decimal(dtoProvision.minValueEnforcement),
            reasonsSingleProvider: dtoProvision.reasonsSingleProvider.map(ProvisionSingleProviderReason.fromDto)
        };
    }
};
