import {LotRequestStage} from "@/models/enums/LotRequestStage";
import {AutoDto, AutoModel, uuid} from "@/models/parsing";
import {LawType} from "@/models/enums";

interface LotRequestDocumentMD {
    id: uuid
    name: string
    order: string
    comment: string
    required: boolean
    stage: LotRequestStage
}

export type DtoLotRequestDocument = AutoDto<LotRequestDocumentMD>;
export type LotRequestDocument = AutoModel<LotRequestDocumentMD>;

export const LotRequestDocument = {
    toDto(requestDocument: LotRequestDocument): DtoLotRequestDocument {
        return {
            ...requestDocument
        };
    },
    fromDto(requestDocument: DtoLotRequestDocument): LotRequestDocument {
        return {
            ...requestDocument,
        };
    },
};
