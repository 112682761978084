import { Component, Prop } from "vue-property-decorator";
import CardModal from "@/components/CardModal.vue";
import TreeView from "@/components/TreeView.vue";
import FormTable from "@/components/vue/FormTable.vue";
import DataTable from "@/components/vue/DataTable.vue";
import { ITableColumn, SingleSelection, IRowConfiguration, SelectionColumnControl } from "@/components/TableAbstractions";
import { ModalVue, ModalResult } from '@/view-models/ModalRequest';
import { Participant } from '@/models';
import { ParticipantStatusStrings } from '@/models/enums';

@Component({ components: { CardModal, TreeView, FormTable, DataTable } })
export default class ParticipantSelectionModal extends ModalVue<string, Participant> {
    @Prop() private prop!: { participants: Participant[] };

    public participantHeaders: ITableColumn<Participant>[] = [
        {
            title: "Полное наименование",
            getter(p) { return p.fullName }
        },
        {
            title: "Краткое наименование",
            getter(p) { return p.shortName }
        },
        {
            title: "ИНН",
            getter(p) { return p.tin }
        },
        {
            title: "КПП",
            getter(p) { return p.rrc }
        },
        {
            title: "ОРГН",
            getter(p) { return p.regNumber }
        },
        {
            title: "Статус",
            getter(p) { return ParticipantStatusStrings[p.status] }
        }
    ];

    public selection: SingleSelection<Participant> = { tag: "SINGLE", item: null };
    public config: IRowConfiguration<Participant> = {
        selection: {
            showSelectionColumn: true,
            selectionColumnControl: SelectionColumnControl.CHECKBOX,
            background: "#ccc"
        }
    };

    public select() {
        if (this.selection.item)
            this.$emit("close-modal", ModalResult.ok(this.selection.item));
    }
}
