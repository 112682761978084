import { ModalFC } from "@/EventBus";
import Auth from "@/api/Auth";
import { RadioButton } from "@/components/eshop/RadioButton/RadioButton";
import { HStack, VStack } from "@/components/layouts";
import { CardModal } from "@/components/modals/CardModal";
import { RoundedButton } from "@/components/primitive/RoundedButton";
import React, { useEffect, useState } from "react";
import styles from "./PickAddressFromListModal.module.css";
import { CheckBox } from "@/components/primitive";
import { getParticipantsById } from "@/api/directory";

export const PickAddressFromListModal: ModalFC<{existing?: string}, string> = x => {
    const [selectAddress, setSelectedAddress] = useState(x.existing);
    const [addresses, setAddresses] = useState<string[]>([]);

    const save = () => {
        if(!selectAddress) return;
        x.done(selectAddress);
    };

    useEffect(() => {
        const pId = Auth.currentUserInfo?.selectedParticipant.id;
        if(pId) getParticipantsById(pId.toString()).then(data => setAddresses(data.deliveryAddresses.map(a => a.addressLine)));
    }, []);

    const bottom = <HStack reverse spacing="15px">
        <RoundedButton color="white" title="Закрыть" onClick={() => x.done(undefined)}/>
        <RoundedButton color="blue" title="Выбрать" disabled={!selectAddress} onClick={save}/>
    </HStack>;

    console.log(Auth.currentUserInfo?.selectedParticipant.address);

    return <CardModal title="Выбор адреса поставки из списка" width="40%" close={() => x.done(undefined)} bottom={bottom}
                      cardStyle={{ overflowY: "auto", overflowX: "hidden" }} cardContentStyle={{ overflowY: "auto", overflowX: "hidden", maxHeight: 400, padding: "10px 0" }}>
                <VStack spacing="5px">
                    {addresses.map(a => <div className={styles.addressRow} key={a}>
                            <div className={styles.addressRow}>
                                <CheckBox value={a===selectAddress} onChange={() => setSelectedAddress(a === selectAddress ? undefined : a)} />
                                <div style={{width: "100%"}} onClick={() => setSelectedAddress(a === selectAddress ? undefined : a)}>{a}</div>
                            </div>
                        </div>
                        )}
                </VStack>
            </CardModal>;
};