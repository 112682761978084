import { Participant } from "@/models";
import { IDtoParticipant } from "@/models/json";
import { ContractParticipantAccount } from "@/models/Contracts";
import { IDtoContractParticipantAccount } from "@/models/json/Contracts";
import { IDtoContractParticipant } from "@/models/json/Contracts";
import { IJsonFormattable } from "@/models/IJsonFormattable";

export class ContractParticipant implements IJsonFormattable<IDtoContractParticipant> {
    constructor(public participant: Participant, public accounts: Array<ContractParticipantAccount>) {}

    public toJson(): IDtoContractParticipant {
        return this.participant
            ? {
                  participant: this.participant.toJson(),
                  accounts: this.accounts.map(x => x.toJson()),
              }
            : null!;
    }

    public static fromJson(dto: IDtoContractParticipant): ContractParticipant {
        return Object.assign(
            Object.create(ContractParticipant.prototype),
            dto
                ? {
                      participant: Participant.fromJson(dto.participant),
                      accounts: dto.accounts.map(x => ContractParticipantAccount.fromJson(x)),
                  }
                : {},
        );
    }
}
