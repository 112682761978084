import React, { useMemo } from "react";
import { Expandee, HStack, VStack } from "@/components/layouts";
import { Button } from "@/components/primitive";
import { Form, FormHeader, FormState } from "@/components/form";
import { actionToState, FormAction } from "@/views/ComposedLot/shared/store";
import { MonopolyObjectStore } from "@/views/Monopoly/view/MonopolyObject/store";
import { useStore } from "effector-react";
import { MonopolyStatus, MonopolyStatusStrings } from "@/models/Monopoly";
import { InfoSection } from "@/views/Monopoly/shared/sections/object/InfoSection";
import { HistorySection } from "@/views/Monopoly/shared/sections/object/HistorySection";
import { DocumentsSection, SpecificationsSection } from "@/views/Monopoly/shared/sections/object";
import { StatusSwitcher, SwitcherItem } from "@/views/Monopoly/shared/StatusSwitcher";
import { Relations } from "@/views/Monopoly/view/MonopolyObject/Relations";

type MonopolyObjectViewProps = {
    storeContainer: MonopolyObjectStore
};

export const MonopolyObjectView = ({ storeContainer }: MonopolyObjectViewProps) => {
    const { object, history, lot } = useStore(storeContainer.store);

    const { acceptFx, divergenceFx, sendFx, divergenceFullFx, cancelDivergenceFx,  events } = storeContainer;

    const state: FormAction = object?.status === MonopolyStatus.DIVERGENCE ? "editing" : "viewing";

    const title = useMemo(
        () => object?.status && object?.regNumber
            ? `Объект данных монополиста - ${object?.regNumber} - ${MonopolyStatusStrings[object.status]}`
            : "Объект данных монополиста",
        [object?.status, object?.regNumber]
    );

    const header = <VStack spacing="15px">
        {object && <Relations object={object} lot={lot} />}
        <FormHeader title={title} />
    </VStack>;

    const navInset = object && (
        <HStack spacing="10px">
            <StatusSwitcher status={object?.status}>
                <SwitcherItem source={MonopolyStatus.CREATING}>
                    <Expandee />
                    <Button title="Принять" color="green" onClick={() => acceptFx()} />
                    <Button title="Протокол разногласий"
                            onClick={() =>
                                object && divergenceFx({ documents: object.documents, commentary: object?.comment ?? "" })} />
                </SwitcherItem>
                <SwitcherItem source={MonopolyStatus.ACCEPTED}>
                    <Expandee/>
                    <Button title="Запрос отказа от заключения контракта"
                            color="green"
                            onClick={() => divergenceFullFx()} />
                </SwitcherItem>
                <SwitcherItem source={MonopolyStatus.DIVERGENCE}>
                    <Button title="Отправить"
                            color="green" onClick={() => sendFx({ object })} />
                    <Expandee />
                    <Button icon="aClose" color="red" onClick={() => cancelDivergenceFx()} />
                </SwitcherItem>
            </StatusSwitcher>
        </HStack>
    );

    const infoTab = Form.Tab("info", "Общие сведения", [
        Form.Section("Общие сведения", <InfoSection object={object} events={events} />),
        Form.Section("Спецификации", <SpecificationsSection specifications={object?.specifications} events={events} />),
        Form.Section("Документы", <DocumentsSection documents={object?.documents} />),
        Form.Section("История изменений", <HistorySection historySrd={history} />),
    ]);

    return (
        <FormState value={actionToState(state)}>
           <Form header={header} navInset={navInset} tabs={[infoTab]} />
        </FormState>
    );
};
