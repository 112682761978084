// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, "\n._3WomiUoanFmGcCd7BLO_kV {\n    height: 100% !important;\n    background-color: white;\n}\n\n\n.NZ_oCeelnvSHt5pfxxi1F {\n    height: calc(100% - 8px);\n}\n\n._32qeE_8iEd7sBGaReD1m3C{\n    display: flex;\n    justify-content: center;\n    padding: 20px 10px;\n    font-size: 13px;\n    text-align: center;\n    flex-direction: column;\n}\n\n._1H7982ycDESg0O2n8xVSSW{\n    display: flex;\n    justify-content: space-around;\n    padding-top: 40px;\n}", ""]);
// Exports
exports.locals = {
	"offersPage": "_3WomiUoanFmGcCd7BLO_kV",
	"sessionsPageContent": "NZ_oCeelnvSHt5pfxxi1F",
	"modalControlsContainer": "_32qeE_8iEd7sBGaReD1m3C",
	"buttonsContainer": "_1H7982ycDESg0O2n8xVSSW"
};
module.exports = exports;
