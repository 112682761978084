import { usePopup } from "@/views/Products/ProductsCard";
import React, { useState } from "react";
import styles from './SortIcons.module.css';

interface SortProps {
    sorted: 'asc' | 'desc' | null
    setSort: (sort: 'asc' | 'desc') => void
}

export const SortIcons = (props: SortProps) => {
    return <>
        <div className={styles.iconSortContainer}>
            <div title="По возрастанию"
                className={`${styles.eshopSortButton} ${styles.sortAsc} ${props.sorted === 'asc' && styles.sortAscSelected}`}
                onClick={() => props.setSort("asc")} />
            <div title="По убыванию"
                className={`${styles.eshopSortButton} ${props.sorted === 'desc' && styles.sortDescSelected}`}
                onClick={() => props.setSort("desc")} />
        </div>
    </>;
};
