import React from "react";
import {Card} from "@/components/Card";
import {Field, Fields} from "@/components/form/Field";
import {Label, CheckBox} from "@/components/primitive";
import {CustomerMD} from "@/models/Customer";
import {ParticipantMD} from "@/models";

export const CustomersContactInformation: React.FC<{ info: ParticipantMD }> = x => {
    return <Card>
        <Fields orientation="horizontal">
            <Field title="Фактический адрес">
                <Label>{x.info.address}</Label>
            </Field>
            <Field title="Часовая зона">
                <Label>Омское время</Label>
            </Field>
            <Field title="Факс">
                <Label>

                </Label>
            </Field>
            <Field title="Адрес интернет сайта">
                <Label>

                </Label>
            </Field>
            <Field title="Совпадает с юридическим адресом">
                <CheckBox value={true} disabled />
            </Field>
        </Fields>
    </Card>;
};