import {autoSV, createSVEvent, origEv} from "@/storeUtils";
import {createEvent, createStore, Store} from "effector";
import {LotForm} from "@/views/ComposedLot/shared/store/index";
import {
    AdditionalRequirementsSections2022
} from "@/views/ComposedLot/edit/tabs/additional-info/AdditionalRequirementsData";

export interface LotAdditionalRequirements {
    hasAdditionalRequirements: boolean
    hasAdditionalRequirements2022: boolean
    selectedOption: string
    options: string[]
    hasAdditionalSupplierRequirements: boolean
    supplierRequirements?: string
    supplierRequirementsSubstantiation?: string
}

export const AdditionalRequirementsEvents = {
    setHasAdditionalRequirements: createSVEvent<LotAdditionalRequirements>()("hasAdditionalRequirements"),
    setHasAdditionalRequirements2022: createSVEvent<LotAdditionalRequirements>()("hasAdditionalRequirements2022"),
    setHaAddt: createEvent(),
    setSelectedOption: createSVEvent<LotAdditionalRequirements>()("selectedOption"),
    setOptions: createSVEvent<LotAdditionalRequirements>()("options"),
    setHasAdditionalSupplierRequirements: createSVEvent<LotAdditionalRequirements>()("hasAdditionalSupplierRequirements"),
    setSupplierRequirements: createSVEvent<LotAdditionalRequirements>()("supplierRequirements"),
    setSupplierRequirementsSubstantiation: createSVEvent<LotAdditionalRequirements>()("supplierRequirementsSubstantiation"),
};

const replace = createEvent<LotAdditionalRequirements>("replace additional requirements");

export const AdditionalRequirementsStore = {
    createStore: (): Store<LotAdditionalRequirements> => {
        return createStore<LotAdditionalRequirements>({
            hasAdditionalRequirements: false,
            hasAdditionalRequirements2022: false,
            selectedOption: AdditionalRequirementsSections2022.F44[0].title,
            options: [],
            hasAdditionalSupplierRequirements: false
        })
            .on(replace, (_, l) => l)
            .on(AdditionalRequirementsEvents.setSelectedOption[origEv], autoSV)
            .on(AdditionalRequirementsEvents.setOptions[origEv], autoSV)
            .on(AdditionalRequirementsEvents.setHasAdditionalSupplierRequirements[origEv], autoSV)
            .on(AdditionalRequirementsEvents.setSupplierRequirements[origEv], autoSV)
            .on(AdditionalRequirementsEvents.setSupplierRequirementsSubstantiation[origEv], autoSV)
            .on(AdditionalRequirementsEvents.setHasAdditionalRequirements[origEv], (state) => ({
                ...state, hasAdditionalRequirements: !state.hasAdditionalRequirements, hasAdditionalRequirements2022: false
            }))
            .on(AdditionalRequirementsEvents.setHasAdditionalRequirements2022, (state) => ({
                ...state, hasAdditionalRequirements2022: !state.hasAdditionalRequirements2022, hasAdditionalRequirements: false
                }),
            );
    },

    replace: replace,
    observeOnCombinedStore: (combinedStore: Store<LotForm>) => {
        combinedStore
            .map(x => x.info.law)
            .watch(law => law === "F44" &&
                AdditionalRequirementsEvents.setSelectedOption(
                    AdditionalRequirementsSections2022[law]?.[0].title
                )
            );
    }
};
