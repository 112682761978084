






import { ITableColumn, IRowConfiguration, SelectionColumnControl } from "@/components/TableAbstractions";
import { Component, Vue, Prop } from "vue-property-decorator";
import DataTable from "@/components/vue/DataTable.vue";
import { SimpleRow } from '../../components/vue/form-table/FormFields';
import { IFieldProps } from '../../components/vue/form-table/FieldBase';
import { Contract, ContractParticipantAccount } from '../../models/Contracts';
import { BankAccount } from '../../models/BankAccount';
import { ContractScope } from './ContractScope';

@Component({ components: { DataTable } })
export default class OrdererAccounts extends Vue {
    @Prop() public props!: IFieldProps<SimpleRow<ContractScope>>;

    private createTableConfig(): IRowConfiguration<ContractParticipantAccount> {
        const that = this;

        return {
            selection: {
                showSelectionColumn: true,
                selectionColumnPosition: "right",
                selectionColumnControl: SelectionColumnControl.CHECKBOX,
                selectionColumnHeader: "Выбрать",
                explicitChecked(t) { return t.selected },
                get locked() { return that.props.state !== "ENABLED" },
                predicate(t) { return !that.props.source.financialSources.find(x => !!x.account && x.account.id === t.account.id) },
                predicateAction: "lock"
            }
        };
    }
    public tableConfig: IRowConfiguration<ContractParticipantAccount> = this.createTableConfig();

    public get headers(): ITableColumn<ContractParticipantAccount>[] {
        return [
            {
                title: "Лицевой счёт",
                getter: p => p.account.accountNumber,
            },
            {
                title: "Корреспондентский счет",
                getter: p => p.account.correspondentAccount,
            },
            {
                title: "​Расчётный счёт",
                getter: p => p.account.transactionalAccountNumber,
            },
            {
                title: "Банк",
                getter: p => p.account.bankName,
            },
            {
                title: "​Бик",
                getter: p => p.account.bin,
            },
        ];
    }

    public get dataset() { 
        const set = (this.props.row.getter(this.props.source) || []) as ContractParticipantAccount[];
        if(set.length) {
            return set.sort((a, b) => a.account.id - b.account.id);
        }
        return set;
        }
}
