




















































import {Component, Prop, Vue} from "vue-property-decorator";
import FormTable from "@/components/vue/FormTable.vue";
import CardModal from "@/components/CardModal.vue";
import StageTable from "./StageTable.vue";
import Decimal from "decimal.js";
import {ModalResult} from '@/view-models/ModalRequest';
import {
  ContractDocument,
  ContractDocumentType,
  ContractStage,
  ContractStageExecutionInfo,
  ContractStageExecutionInfoCreationDto,
  ExecutionInfoSpecification
} from '@/models/Contracts';
import {ContractScope} from '../ContractScope';
import {StageExecution} from "@/api/Stages";
import EventBus, {showModal} from '@/EventBus';
import {hs} from '@/components/TableHelpers';

import AddDocument from "../AddDocument.vue";
import {formatNumber} from '@/NumberFormatting';
import {ConfirmationModal, ErrorModal} from "@/views/Contracts/modals/ConfirmationModal";
import {generateContractObligations} from "@/api/ContractPayments";
import {
  ObligationProperty,
  PickContractObligation,
  PickContractObligationModalReturn
} from "@/modal-views/PickContractObligation";
import {getContractObligation} from "@/api/ContractObligations";
import {File} from "@/models/Documents";
import {ContractDocumentStatus} from "@/models/enums/Contracts";
import {ExecutionSpecificationsModal} from "@/views/CreateContract/execution/ExecutionSpecificationsModal";
import {isFuture} from "date-fns";
import { formatAddress } from "@/models/ComposedLots/specifications";

@Component({ components: { FormTable, CardModal, StageTable } })
export default class AddExecution extends Vue {
    @Prop() private prop!: { scope: ContractScope; stage: ContractStage; source: ContractStageExecutionInfo | null};

    public get editable() { return this.prop.stage.status === 'CREATING' || this.prop.stage.status === 'CREATING_FIRST' }

    public documentsHeaders = hs<ContractDocument>(
        hs.any("№", (_, n) => (n + 1).toString(), "auto"),
        hs.any("Тип документа", x => x.type.name),
        hs.any("Номер документа", x => x.documentNumber),
        hs.date("Дата документа", x => x.date),
        hs.any("Наименование документа", x => x.file.comment),
        hs.slot("Основной документ исполнения", x => x, "primary-document"),
        hs.any("Денежное обязательство", () => "-"),
    );

    public specificationsHeaders = hs<ExecutionInfoSpecification>(
        hs.any("№", (_, n) => (n + 1).toString(), "auto"),
        hs.any("СПГЗ", x => x.specification.specification.subjectDeclaration?.subject ?? ""),
        hs.any("ОКПД2", x => x.specification.specification.subjectDeclaration?.okpd2 && (
            x.specification.specification.subjectDeclaration.okpd2.code + " " +
            x.specification.specification.subjectDeclaration.okpd2.name)),
        hs.any("Объем поставки", x => x.volume.isNaN() ? "" : formatNumber(x.volume)),
        hs.any("Сумма поставки, руб.", x => x.cost.isNaN() ? "" : formatNumber(x.cost)),
        hs.any("Адрес поставки", x => x.address ? x.address.toString() : "Не указан"),
        hs.any("Страна происхождения", x => x.country),
        hs.any("Страна регистрации производителя", x => x.registrationCountry),
    );

    public penaltyHeaders = hs<unknown>(
        hs.any("№", (_, n) => (n + 1).toString(), "auto"),
        hs.any("Тип взыскания", () => ""),
        hs.any("Размер начисленной неустойки (штрафа, пени), руб.", () => ""),
        hs.any("Номер документа", () => ""),
        hs.any("Дата документа", () => ""),
        hs.any("Наименование документа", () => ""),
        hs.any("Действие", () => ""),
    );

    public loading = false;

    public documents = this.prop.source && this.prop.source.documents || [];
    public primaryDocument: ContractDocument | null =
        this.prop.source && this.prop.source.documents.find(x => x.id === this.prop.source!.primaryDocumentId) || null;

    private async generateObligations() {
        await generateContractObligations(this.prop.stage.id);
    }

    private  addContractObligation = async () => {
        const contractNumber = this.prop.scope.contract.regNumber;
        const { obligation, obligationProperty } = await showModal(PickContractObligation, { showProperties: true, contractNumber }) as PickContractObligationModalReturn;

        if (obligation) {
            const newDocument: ContractDocument = new ContractDocument(
                null!,
                new File(null!, 1, "report.pdf", "report.pdf"),
                new ContractDocumentType(550, "Универсальный передаточный документ", "execution", "37"),
                obligation.typeNum.toString(),
                obligation.date,
                obligation.date,
                obligation.date,
                ContractDocumentStatus.NOT_SENT,
                true
            );

            const fullObligation = await getContractObligation(obligation.id);

            this.documents.push(newDocument);


            const specifications = this.prop.scope.contract.specifications.map(spec => ({
              address: null,
              cost: spec.actualCost,
              country: "RU",
              registrationCountry: "RU",
              createdAt: null!,
              volume: spec.actualVolume,
              specification: spec,
              eisNumber: null
            }));

            if (obligationProperty === ObligationProperty.NO_TRANSFER) {
                return;
            }

            if (obligationProperty === ObligationProperty.ADD) {
                this.specifications.push(...specifications);
            }

            if (obligationProperty === ObligationProperty.REPLACE) {
                this.specifications = specifications;
            }
        }
    };

    public async addEditDocument(doc: ContractDocument | null) {
        const props = {
            contract: this.prop.scope.contract,
            document: doc,
            noPost: !this.prop.source,
            createAction: (r: ContractDocument) => StageExecution.addDocument(this.prop.source!.id, r),
            documentGroup: "execution",
            blockType: "supplierExecution",
            isExecutingBySupplier: true,
            validateFn: (doc: ContractDocument) => {
              const errors: string[] = [];

              if (doc.date && isFuture(doc.date)) {
                errors.push(`Поле "Дата документа" обязательное и не должно превышать текущей даты`);
              }
              return errors;
            }
        };
        const result = await EventBus.callModal<typeof props, ContractDocument>(AddDocument, props);

        if (result.isOk) {
            const r = result.getResult();
            if (!doc) {
                this.documents.push(r);
            } else {
                const ix = this.documents.findIndex(x => x.id === r.id);
                this.documents.splice(ix, 1, r);
            }

            if (!this.primaryDocument)
                this.primaryDocument = r;
        }
    }

    public async deleteDocument(doc: ContractDocument) {
        const confirmation = await showModal(ConfirmationModal, {title: "Удаление", text: "Вы уверены, что хотите удалить данное исполнение поставщиком?"});
        if (!confirmation)
            return;

        if (!this.prop.source) {
            if (this.primaryDocument === doc)
                this.primaryDocument = null;

            const ix = this.documents.indexOf(doc);
            if (ix !== -1)
                this.documents.splice(ix, 1);
        } else {
            const newSrc = await StageExecution.deleteDocument(this.prop.source.id, doc);
            this.primaryDocument = newSrc.primaryDocumentId && this.documents.find(x => x.id === newSrc.primaryDocumentId) || null;
            this.prop.source.primaryDocumentId = newSrc.primaryDocumentId;

            const ix = this.documents.indexOf(doc);
            if (ix !== -1) {
                this.documents.splice(ix, 1);
            }
        }
    }

    public specifications = this.prop.source ? this.prop.source.specifications : [];
    public get totalCost() {
        const cost = this.specifications.reduce((r, v) => r.add(v.cost), new Decimal(0));
        if (cost.eq(0)) return "";

        return formatNumber(cost);
    }

    public async editSpecifications() {
        const props = {
            scope: this.prop.scope,
            stage: this.prop.stage,
            noPost: true,
            uncommittedSpecifications: this.specifications
        };
        const result = await showModal(ExecutionSpecificationsModal, props);

        if (result) {
            this.specifications = result;
        }
    }

    public async saveAndClose() {
        if (!this.documents.length) {
            await showModal(ErrorModal, { text: "В текущее исполнение поставщиком необходимо добавить хотя бы один документ" });
            return;
        }

        if (!this.primaryDocument) {
            await showModal(ErrorModal, { text: "Выберите основной документ" });
            return;
        }

        this.loading = true;

        try {
            if (this.prop.source) {
                const dto = new ContractStageExecutionInfo(
                    this.prop.source.id,
                    null!,
                    null!,
                    [],
                    this.specifications,
                    this.primaryDocument.id,
                    this.prop.source.contractObligation
                );
                const src = await StageExecution.update(dto);
                this.$emit("close-modal", ModalResult.ok(src));
            } else {
                const src = await StageExecution.create(this.prop.stage.id, new ContractStageExecutionInfoCreationDto(
                    this.documents,
                    this.specifications,
                    this.documents.indexOf(this.primaryDocument)
                ));

                this.$emit("close-modal", ModalResult.ok(src));
            }
        } finally {
            this.loading = false;
        }
    }
}
