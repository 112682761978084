// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".OlEKKda6aOxThFbYJC0jH{\n    width: 100%;\n    height: 120px;\n    border-radius: 4px;\n    border: dashed #97989a 1px;\n    background-color: #f2f3f5;\n    font-size: 13px;\n    display: flex;\n    justify-content: center;\n    align-items: center;\n    flex-direction: column;\n    margin: 0 auto;\n}\n\n.tccVScl2mI0GGdbxrzCkj{\n    padding: 30px;\n}\n\n._2wAdxpNiaKVLtEFWg_Dm5n{\n    display: flex;\n    justify-content: center;\n    margin-top: 16px;\n    padding-bottom: 30px;\n}\n\n._16KcIiMJI-5CjWGzdof72K{\n    color: #3d8ecc;\n    cursor: pointer;\n    text-decoration: none;\n}", ""]);
// Exports
exports.locals = {
	"dragArea": "OlEKKda6aOxThFbYJC0jH",
	"dragAreaContent": "tccVScl2mI0GGdbxrzCkj",
	"dragAreaInfo": "_2wAdxpNiaKVLtEFWg_Dm5n",
	"linkButton": "_16KcIiMJI-5CjWGzdof72K"
};
module.exports = exports;
