import React, {CSSProperties, ReactElement, ReactNode, useContext} from 'react';
import styles from "./Radio.module.css";
import {RadioGroupContext} from "@/components/primitive/RadioGroup";
import {Label, LabelPresets} from "@/components/primitive/Label";
import {j} from "@/reactUtils";

interface RadioBaseProps {
    value?: boolean
    disabled?: boolean
    group?: string
    onChange?: (value: boolean) => void

    labelPreset?: LabelPresets
    style?: CSSProperties
    className?: string
}

export const RadioBase: React.FC<RadioBaseProps> = x => {
    return <Label className={j(styles.component, x.className)} preset={x.labelPreset} onClick={x.disabled ? undefined : ev => {
        if (ev.target === ev.currentTarget) return;
        x.onChange?.(true);
    }} style={x.style}>
        <input
            className={j(styles.input, !x.children ? styles.inputEmpty : null)}
            type="radio"
            checked={x.value}
            disabled={x.disabled}
            name={x.group}
            readOnly
        />

        {x.children}
    </Label>;
};

interface RadioProps<T> {
    value?: T
    requiredValue: T
    group?: string
    onChange?: (value: T) => void
    disabled?: boolean

    children?: ReactNode
    labelPreset?: LabelPresets
    style?: CSSProperties
    className?: string
}

export const Radio: <T,>(x: RadioProps<T>) => ReactElement = x => {
    const context = useContext(RadioGroupContext);
    const group = x.group ?? context.group;

    return <RadioBase
        value={x.value === x.requiredValue}
        className={x.className}
        style={x.style}
        group={group}
        onChange={() => x.onChange?.(x.requiredValue)}
        disabled={x.disabled ?? context.disabled}
        labelPreset={x.labelPreset}>
        {x.children}
    </RadioBase>;
};