import { convertPaged, IPagedItems } from "./Pagination";
import { http, IFilterObject } from "./http";
import { Contract } from "@/models/Contracts/Contract";
import { LawType } from '@/models/enums';
import {
    IDtoContractStage,
    IDtoContractExecutionHistoryEntry,
    IDtoContractFinancialSource,
    IDtoContractDocument,
    IDtoContractSubcontractor,
    IDtoContractExecutionProvision,
    IDtoContractClaimWork,
    IDtoContractEconomy } from '@/models/json/Contracts';
import { IDtoContractStageExecutionInfo } from '@/models/json/Contracts/IDtoContractStageExecutionInfo';
import { IDtoContractStagePaymentInfo } from '@/models/json/Contracts/IDtoContractStagePaymentInfo';
import { IDtoContractStageAcceptanceInfo } from '@/models/json/Contracts/IDtoContractStageAcceptanceInfo';
import { IDtoContractStageDissolutionInfo } from '@/models/json/Contracts/IDtoContractStageDissolutionInfo';
import { ContractStageScope, ContractScope } from '@/views/CreateContract/ContractScope';
import {
    ContractFinancialSource,
    ContractDocument,
    ContractSubcontractor,
    ContractAdditionalConclusion,
    ContractClaimWork,
    ContractEconomy,
    ContractExecutionHistoryEntry,
    ContractGuarranteeEvent,
    ContractStage,
    ContractStageExecutionInfo,
    ContractStagePaymentInfo,
    ContractStageAcceptanceInfo,
    ContractStageDissolutionInfo,
    ContractExecutionProvision,
    SupplierEvasion} from '@/models/Contracts';
import { IDtoContractGuarranteeEvent } from '@/models/json/Contracts/IDtoContractGuarranteeEvent';
import { IDtoContractAdditionalConclusion } from '@/models/json/Contracts/IDtoContractAdditionalConclusion';
import {uuid} from "@/models/parsing";
import {IDtoContractGuaranteeProvision} from "@/models/json/Contracts/IDtoContractGuaranteeProvision";
import {ContractGuaranteeProvision} from "@/models/Contracts/ContractGuaranteeProvision";

interface DtoContractStageScope {
    stage: IDtoContractStage
    executionInfo: IDtoContractStageExecutionInfo[]
    paymentInfo: IDtoContractStagePaymentInfo[]
    acceptanceInfo: IDtoContractStageAcceptanceInfo[]
    dissolutionInfo: IDtoContractStageDissolutionInfo[]
}

interface DtoContractObjects {
    stages: DtoContractStageScope[]

    financialSources: IDtoContractFinancialSource[]
    documents: IDtoContractDocument[]
    subcontractors: IDtoContractSubcontractor[]
    executionProvision: IDtoContractExecutionProvision[]
    guaranteeProvision: IDtoContractGuaranteeProvision[]
    additionalConclusions: IDtoContractAdditionalConclusion[]
    claimWorks: IDtoContractClaimWork[]
    economy: IDtoContractEconomy[]
    executionHistory: IDtoContractExecutionHistoryEntry[]
    guarranteeEvents: IDtoContractGuarranteeEvent[]
}

interface ContractObjects {
    stageScopes: ContractStageScope[]

    financialSources: ContractFinancialSource[]
    documents: ContractDocument[]
    subcontractors: ContractSubcontractor[]
    executionProvision: ContractExecutionProvision[]
    guaranteeProvision: ContractGuaranteeProvision[]
    additionalConclusions: ContractAdditionalConclusion[]
    claimWorks: ContractClaimWork[]
    economy: ContractEconomy[]
    executionHistory: ContractExecutionHistoryEntry[]
    guarranteeEvents: ContractGuarranteeEvent[]
}

export default class {
    private static mapDtoStageScope(dto: DtoContractStageScope, contractScope: ContractScope): ContractStageScope {
        return {
            contractScope: contractScope,
            stage: ContractStage.fromJson(dto.stage),
            executionInfo: dto.executionInfo.map(ContractStageExecutionInfo.fromJson),
            paymentInfo: dto.paymentInfo.map(ContractStagePaymentInfo.fromJson),
            acceptanceInfo: dto.acceptanceInfo.map(ContractStageAcceptanceInfo.fromJson),
            dissolutionInfo: dto.dissolutionInfo.map(ContractStageDissolutionInfo.fromJson)
        };
    }

    private static mapDtoScope(dto: DtoContractObjects, contractScope: ContractScope): ContractObjects {
        return {
            stageScopes: dto.stages.map(x => this.mapDtoStageScope(x, contractScope)),
            financialSources: dto.financialSources.map(ContractFinancialSource.fromJson),
            documents: dto.documents.map(ContractDocument.fromJson),
            subcontractors: dto.subcontractors.map(ContractSubcontractor.fromJson),
            executionProvision: dto.executionProvision.map(ContractExecutionProvision.fromJson),
            guaranteeProvision: dto.guaranteeProvision.map(ContractGuaranteeProvision.fromJson),
            additionalConclusions: dto.additionalConclusions.map(ContractAdditionalConclusion.fromJson),
            claimWorks: dto.claimWorks.map(ContractClaimWork.fromJson),
            economy: dto.economy.map(ContractEconomy.fromJson),
            executionHistory: dto.executionHistory.map(ContractExecutionHistoryEntry.fromJson),
            guarranteeEvents: dto.guarranteeEvents.map(ContractGuarranteeEvent.fromJson),
        };
    }

    public static async get(from: number, count: number, filters: IFilterObject): Promise<IPagedItems<Contract>> {
        return convertPaged(
            await http.getPaged("/api/contracts", {
                from: from,
                count: count,
                filters: filters,
            }),
            Contract,
        );
    }

    public static async getById(id: string): Promise<Contract> {
        return Contract.fromJson({ ...(await http.get(`/api/contracts/${id}`)), id });
    }

    public static async getObjects(scope: ContractScope): Promise<ContractObjects> {
        return this.mapDtoScope(await http.get(`/api/contracts/${scope.contract.id}/objects`, undefined) as DtoContractObjects, scope);
    }

    public static async create(law: LawType, procedureId: string, lotId: string, supplierId: number): Promise<Contract> {
        return Contract.fromJson(await http.post(
            `/api/contracts/create?law=${law}&procedureId=${procedureId}&lotId=${lotId}&exactSupplierId=${supplierId}`, undefined));
    }

    public static async createFromProcedure2020(law: LawType, procedureId: uuid, lotId: uuid, supplierId: number): Promise<Contract> {
        return Contract.fromJson(await http.post(
            `/api/contracts/createFromNewProcedure?law=${law}&procedureId=${procedureId}&lotId=${lotId}&exactSupplierId=${supplierId}`, undefined));
    }

    public static async createJointPurchaseContracts2020(law: LawType, procedureId: uuid, lotId: uuid, supplierId: number): Promise<void> {
        await http.post(`/api/contracts/createJointPurchaseContracts?law=${law}&procedureId=${procedureId}&lotId=${lotId}&exactSupplierId=${supplierId}`, undefined);
    }

    public static async createFromLot2020(lotId: uuid): Promise<Contract> {
        return Contract.fromJson(await http.post(
            `/api/contracts/createFromNewLot?LotId=${lotId}`, undefined));
    }

    public static async createFromMonopoly(lotId: uuid, law: LawType): Promise<{ id: uuid }> {
        return await http.post("/api/v2/contracts/fromMonopolyObject", { lotId, law });
    }

    public static async createFromLot(lotId: string): Promise<Contract> {
        return Contract.fromJson(await http.post(
            `/api/contracts/createFromLot?lotId=${lotId}`, undefined));
    }

    public static async save(obj: Contract): Promise<Contract> {
        return Contract.fromJson(await http.put(`/api/contracts/${obj.id}`, obj.toJson()));
    }

    public static async register(obj: Contract): Promise<Contract> {
        return Contract.fromJson(await http.post(`/api/contracts/register/${obj.id}`, obj.toJson(), "Ошибка при регистрации"));
    }

    public static async rollback(obj: Contract): Promise<Contract> {
        return Contract.fromJson(await http.post(`/api/contracts/rollback/${obj.id}`, undefined));
    }

    public static async registerEvasion(obj: Contract, evasion: SupplierEvasion): Promise<Contract> {
        return Contract.fromJson(await http.post(`/api/contracts/${obj.id}/supplierEvasion/register`, evasion.toJson()));
    }

    public static async saveEvasion(obj: Contract, evasion: SupplierEvasion): Promise<Contract> {
        return Contract.fromJson(await http.post(`/api/contracts/${obj.id}/supplierEvasion/save`, evasion.toJson()));
    }

    public static async makeChanges(obj: Contract): Promise<Contract> {
        return Contract.fromJson(await http.post(`/api/contracts/makeChanges/${obj.id}`, undefined));
    }

    public static async delete(obj: Contract): Promise<void> {
        await http.delete(`/api/contracts/${obj.id}`);
    }

    public static async electronicConclusionValidate(obj: Contract): Promise<void> {
        await http.post(`api/contractExecutionHistory/${obj.id}/validate`, obj.toJson());
    }
}
