import { EnumStrings } from './EnumStrings';

export const enum Currency {
    RUB = "RUB",
    USD = "USD"
}

export const CurrencyStrings: EnumStrings = {
    [Currency.RUB]: "Российский рубль",
    [Currency.USD]: "Доллар США"
};