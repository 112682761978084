import React, { useState } from "react";
import { MonopolySupplyStage } from "@/models/Monopoly/MonopolySupplyStage";
import { ModalFC } from "@/EventBus";
import { CardModal } from "@/components/modals/CardModal";
import { Field, Fields } from "@/components/form/Field";
import { Button, DatePicker, DecimalBox } from "@/components/primitive";
import Decimal from "decimal.js";
import { Expandee, HStack } from "@/components/layouts";

type SupplyStageVolumeChangeModalProps = {
    supplyStage: MonopolySupplyStage
};

export const SupplyStageVolumeChangeModal: ModalFC<SupplyStageVolumeChangeModalProps, MonopolySupplyStage> =
    ({
        done,
        supplyStage
    }) => {
    const [volume, setVolume] = useState<Decimal | undefined>(supplyStage.volume);

    const bottom = (
        <HStack spacing="10px">
            <Expandee />
            <Button icon="aSave" onClick={() => done({ ...supplyStage, volume: volume ?? new Decimal(0) })} />
            <Button icon="aClose" color="red" onClick={() => done(undefined)} />
        </HStack>
    );

    return (
        <CardModal title="Период поставки"
                   close={() => done(undefined)}
                   bottom={bottom}
                   width="60%">
            <Fields orientation="horizontal">
                <Field title="Объем">
                    <DecimalBox value={volume} onChange={setVolume} style={{ width: "40%" }} />
                </Field>
                <Field title="Дата начала">
                    <DatePicker value={supplyStage.absolutePeriod.startDate} disabled />
                </Field>
                <Field title="Дата окончания">
                    <DatePicker value={supplyStage.absolutePeriod.endDate} disabled />
                </Field>
            </Fields>
        </CardModal>
    );
};
