import { PrimaryButton, ContainerButtons, Pager } from "@/components/eshop";
import { Modal } from "@/components/eshop/Modal";
import React from "react";
import {  ServicesStore, SpecificationType } from "../store";
import styles from '../CreateSession.module.css';
import { searchPurchases } from "@/views/DirectPurchase/store";
import { searchProducts, changePageSize } from "@/views/Products/store";
import { searchServices } from "@/views/Services/store";
import { loadServices, loadProducts, loadPurchases, $filters } from "../filtersStore";
import { CreateSessionProductsTable } from "./CreateSessionProductsTable";
import { CreateSessionPurchasesTable } from "./CreateSessionPurchasesTable";
import { CreateSessionServicesTable } from "./CreateSessionServicesTable";
import { SubjectClassFilterStore } from "@/components/eshop/SubjectClassFilterStore";

interface ModalSearchProps {
    show: boolean
    setShowSearch: (show: boolean) => void
    dataType: SpecificationType
    store: ServicesStore
    subjectClassSelector: SubjectClassFilterStore
}

export const ModalSearch = (props: ModalSearchProps) => {
    const { show, setShowSearch, dataType, store } = props;
    const filtersState = $filters.getState();

    const onAdd = () => {
        if (dataType === SpecificationType.Services) {
            loadServices({ ids: store.selectedNewIds.map(id => id.toString()) });
        }
        if (dataType === SpecificationType.Products) {
            loadProducts({ ids: store.selectedNewIds.map(id => id.toString()) });
        }
        if (dataType === SpecificationType.DirectPurchase) {
            loadPurchases({ ids: store.selectedNewIds.map(id => id.toString()) });
        }
        setShowSearch(false);
    };

    const controls = () => {
        return <>
            <div className={styles.specificationsCardControls}>
                <Pager page={dataType === SpecificationType.Services ? store.servicesPage : dataType === SpecificationType.Products ? store.productsPage : store.purchasesPage}
                    goTo={dataType === SpecificationType.Services ? searchServices : dataType === SpecificationType.Products ? searchProducts : searchPurchases}
                    pageSize={filtersState.pageSize}
                    changePageSize={v => changePageSize({ pageSize: v })}>
                    {false && <ContainerButtons onCreateJointSession={() => undefined} showCreateJointSession={false} disableCreateJointSession={true} onCreateSession={() => { }} disableCreateSession={true} showCreateContract={false} disableCreateContract={true} showCreateSession={true}/>}
                </Pager>
                <PrimaryButton filled onClick={() => onAdd()}>
                    Добавить
                </PrimaryButton>
            </div>
        </>;
    };
    return show
        ? <Modal onClose={() => setShowSearch(false)}
            controls={controls()}
            contentOverflow
            title={`Выберите ${dataType === SpecificationType.Services ? 'СПГЗ' : dataType === SpecificationType.Products ? 'CTE' : 'оферты'}`}>
            {dataType === SpecificationType.Services
                ? <CreateSessionServicesTable store={store} page={store.servicesPage} filterStore={filtersState} subjectClassSelector={props.subjectClassSelector} />
                : dataType === SpecificationType.Products
                    ? <CreateSessionProductsTable store={store} page={store.productsPage} />
                    : <CreateSessionPurchasesTable store={store} page={store.purchasesPage} />
            }
        </Modal >
        : <div />;
};
