import React, { useEffect, useState } from "react";
import { $filters, $sessions, loadSessionsPage, onDeleteSelected, onPublishSelected } from "./store";
import { observer } from "mobx-react";
import { useStore } from "effector-react";
import { Header } from "./Header";
import styles from "./Sessions.module.css";
import { SessionsTable } from "./SessionsTable";
import { Modal } from "@/components/eshop/Modal";
import { PrimaryButton } from "@/components/eshop";
import _ from "lodash";
import { WarningButton } from "@/components/eshop/Buttons";

type ModalMode = 'delete' | 'send';

export const Sessions = () => {
    const store = useStore($sessions);
    const filterstore = useStore($filters);
    const [modalMode, setModalMode] = useState<ModalMode | null>(null);
    const [modalModeSingle, setModalModeSingle] = useState<{ mode: ModalMode; id: string } | null>(null);
    useEffect(() => {
        loadSessionsPage({ page: 0 });
        return;
    }, []);

    const modalConfirm = (mode: ModalMode | null) => {
        const sessionsToSend = _.compact(store.selectedIds.map(id => store.page.items.find(s => s.id === id)))
            .filter(s => s.status === 'DRAFT' || s.status === 'CANCEL');

        const onSend = () => {
            onPublishSelected({ ids: sessionsToSend.map(s => s.id) });
            setModalMode(null);
        };

        const onDelete = () => {
            onDeleteSelected({ ids: sessionsToSend.map(s => s.id) });
            setModalMode(null);
        };

        return mode !== null
            ? <Modal small onClose={() => setModalMode(null)} title={<div />}>
                <div className={styles.modalControlsContainer}>
                    <div>
                        {sessionsToSend.length
                            ? `Из выбранных будут ${mode === 'send' ? 'отправлены' : 'удалены'} КС в количестве ${sessionsToSend.length} (только в статусах "Черновик" и "Ошибка публикации")`
                            : `Нет КС, доступных для отправки (КС в статусах "Черновик" и "Ошибка публикации")`}
                    </div>
                    <div className={styles.buttonsContainer}>
                        <PrimaryButton filled onClick={() => setModalMode(null)}>Отмена</PrimaryButton>
                        {mode === 'send'
                            ? <PrimaryButton filled disabled={sessionsToSend.length === 0} onClick={() => onSend()}>Отправить</PrimaryButton>
                            : <WarningButton disabled={sessionsToSend.length === 0} onClick={() => onDelete()}>Удалить</WarningButton>}

                    </div>
                </div>
            </Modal >
            : <div />;
    };

    const modalConfirmSingle = (value: { mode: ModalMode; id: string } | null) => {
        const sessionToSend = store.page.items.find(s => s.id === value?.id);

        const onSend = () => {
            if (sessionToSend) {
                onPublishSelected({ ids: [sessionToSend.id] });
            }
            setModalModeSingle(null);
        };

        const onDelete = () => {
            if (sessionToSend) {
                onDeleteSelected({ ids: [sessionToSend.id] });
            }
            setModalModeSingle(null);
        };

        return value !== null
            ? <Modal small onClose={() => setModalModeSingle(null)} title={<div />}>
                <div className={styles.modalControlsContainer}>
                    <div>
                        {`Действительно хотите ${value.mode === 'send' ? 'создать' : 'удалить'} котировочную сессию №${sessionToSend?.number}?`}
                    </div>
                    <div className={styles.buttonsContainer}>
                        <PrimaryButton filled onClick={() => setModalModeSingle(null)}>Нет</PrimaryButton>
                        {value.mode === 'send'
                            ? <PrimaryButton filled onClick={() => onSend()}>Да</PrimaryButton>
                            : <WarningButton onClick={() => onDelete()}>Да</WarningButton>}

                    </div>
                </div>
            </Modal >
            : <div />;
    };

    const { page } = store;
    return <div className={styles.offersPage}>
        <div className={`${styles.sessionsPageContent}`} >
            {modalConfirm(modalMode)}
            {modalConfirmSingle(modalModeSingle)}
            <Header store={store} />
            <SessionsTable
                filtersStore={filterstore}
                store={store}
                data={page.items}
                onSend={() => setModalMode('send')}
                onDelete={() => setModalMode('delete')}
                onSendSingle={(id: string) => setModalModeSingle({ mode: 'send', id })}
                onDeleteSingle={(id: string) => setModalModeSingle({ mode: 'delete', id })} />
        </div>
    </div>;
};
