


















import { ITableColumn, IRowConfiguration } from "@/components/TableAbstractions";
import { formatDate } from "@/DateFormatting";
import EventBus from "@/EventBus";
import { ContractClaimWork, ContractDocument, ContractGuarranteeEvent } from "@/models/Contracts";
import { ClaimWorkInitiatorStrings, ChargeTypeStrings } from "@/models/enums/Contracts";
import { formatNumber } from "@/NumberFormatting";
import { Component, Vue, Prop } from "vue-property-decorator";
import DataTable from "@/components/vue/DataTable.vue";
import AddGuaranteeEvent from "./AddGuaranteeEvent.vue";
import { SimpleRow } from "@/components/vue/form-table/FormFields";
import { IFieldProps } from "@/components/vue/form-table/FieldBase";
import { ContractScope } from "./ContractScope";
import ContractGuarranteeEvents from "@/api/ContractGuarranteeEvents";
import { ObjectStatus, EnumStrings } from '@/models/enums';
import { hs } from '@/components/TableHelpers';
import { GuarranteeEventResultStrings } from '@/models/enums/Contracts/GuarranteeEventResult';

//TODO: #351 add button condition here

@Component({ components: { DataTable } })
export default class GuaranteeEvents extends Vue {
    @Prop() public props!: IFieldProps<SimpleRow<ContractScope>>;

    public headers = hs<ContractGuarranteeEvent>(
        hs.date("Дата обращения", x => x.date),
        hs.any("Спецификация", x => x.specification.specification.subjectDeclaration?.subject ?? "", "3fr"),
        hs.any("Обнаруженные недостатки", x => x.issues),
        hs.any("Результат обращения", x => GuarranteeEventResultStrings[x.result]),
        hs.any("Примечание", x => x.comments)
    );

    public createConfig(): IRowConfiguration<ContractDocument> {
        const that = this;

        return  {
            get useSlotForActionColumn() {
                return that.props.source.contract && (
                    that.props.source.contract.status === ObjectStatus.EXECUTING ||
                    that.props.source.contract.status === ObjectStatus.DISSOLVED ||
                    that.props.source.contract.status === ObjectStatus.COMPLETED);
            }
        };
    }

    public canAdd() { return this.props.source.stageScopes.find(x => x.stage.completed) }

    public config = this.createConfig();

    public get dataset(): ContractGuarranteeEvent[] { return this.props.source.guarranteeEvents }

    public async addEdit(src: ContractGuarranteeEvent | null) {
        const props = {
            scope: this.props.source,
            source: src
        };
        const result = await EventBus.callModal<typeof props, ContractGuarranteeEvent>(AddGuaranteeEvent, props);
        if (result.isOk) {
            const r = result.getResult();
            if (!src) {
                this.dataset.push(r);
            } else {
                const ix = this.dataset.findIndex(x => x.id === r.id);
                this.dataset.splice(ix, 1, r);
            }
        }
    }

    public async deleteEvent(src: ContractGuarranteeEvent) {
        await ContractGuarranteeEvents.delete(src);

        const ix = this.dataset.indexOf(src);
        if (ix !== -1) this.dataset.splice(ix, 1);
    }
}
