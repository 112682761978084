import {HStack, VGrid, VStack} from "@/components/layouts";
import {Button, Label} from "@/components/primitive";
import {ProcedureEvents} from "@/views/Procedures2020/view/store/info";
import {showModal} from "@/EventBus";
import {
    Person,
    SelectContactPersonModal
} from "@/views/Procedures2020/view/sections/infoSection/SelectContactPersonModal";
import {Field, FieldGrid, FieldStack} from "@/components/form/Field";
import React from "react";
import {SectionProps} from "@/views/Procedures2020/view/store";
import {useMappedStore} from "@/storeUtils";

const mockPersons: Person[] = [
    {
        primary: true,
        firstName: "Максим",
        lastName: "Волков",
        middleName: "Максимович",
        mail: "business@eaist.emu",
        phone: "+7 (495) 727-28-37",
    },
    {
        primary: false,
        firstName: "Александр",
        lastName: "Дикарев",
        middleName: "Владимирович",
        mail: "contact@eaist.emu",
        phone: "+7 (495) 000-72-27",
    },
    {
        primary: false,
        firstName: "Александра",
        lastName: "Петухова",
        middleName: "Артёмовна",
        mail: "sales@eaist.emu",
        phone: "+7 (495) 495-49-94",
    },
];

export const EditableContactPersonBlock = (x: SectionProps) => {
    const contactPerson = useMappedStore(x.formStore, x => x.info.contactPerson);

    const pickPerson = async () => {
        const person = await showModal(SelectContactPersonModal, {existing: contactPerson, persons: mockPersons});

        if (person)
            ProcedureEvents.setContactPerson(person);
    };

    const heightStyle = { height: "30px" };

    return <VStack spacing="15px">
        <Label preset="headerLarge" text={"Ответственное должностное лицо"}/>
        <FieldGrid>
            <Field required title="ФИО" preset="readonlyValue">
                <HStack spacing="15px" alignItems="center" innerStyle={heightStyle}>
                    <Label text={contactPerson?.name ?? ""} preset="readonlyValue"/>
                    <Button icon={"aEdit"} onClick={pickPerson} />
                </HStack>
            </Field>
            <Field required title="Электронная почта" preset="readonlyValue">
                <HStack alignItems="center" innerStyle={heightStyle}>
                    <Label text={contactPerson?.email ?? ""} preset="readonlyValue"/>
                </HStack>
            </Field>
            <Field required title="Телефон" preset="readonlyValue">
                <HStack alignItems="center" innerStyle={heightStyle}>
                    <Label text={contactPerson?.phoneNumber ?? ""} preset="readonlyValue"/>
                </HStack>
            </Field>
        </FieldGrid>
    </VStack>;
};

export const ReadOnlyContactPersonBlock = (x: SectionProps) => {
    const contactPerson = useMappedStore(x.formStore, x => x.info.contactPerson);

    return <VStack spacing="15px">
        <Label preset="headerLarge" text="Ответственное должностное лицо"/>
        <VGrid columns="1fr 1fr 1fr">
            <FieldStack>
                <Field title="ФИО">
                    <Label text={contactPerson?.name ?? ""} preset="medium"/>
                </Field>
            </FieldStack>
            <FieldStack>
                <Field title="Электронная почта">
                    <Label text={contactPerson?.email ?? ""} preset="medium"/>
                </Field>
            </FieldStack>
            <FieldStack>
                <Field title="Телефон">
                    <Label text={contactPerson?.phoneNumber ?? ""} preset="medium"/>
                </Field>
            </FieldStack>
        </VGrid>
    </VStack>;
};