// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_GET_URL_IMPORT___ = require("../../node_modules/css-loader/dist/runtime/getUrl.js");
var ___CSS_LOADER_URL_IMPORT_0___ = require("./eaist-font.ttf");
var ___CSS_LOADER_URL_IMPORT_1___ = require("./glyphicons-halflings-regular.woff");
var ___CSS_LOADER_URL_IMPORT_2___ = require("./Roboto-Bold.ttf");
var ___CSS_LOADER_URL_IMPORT_3___ = require("./Roboto-Medium.ttf");
var ___CSS_LOADER_URL_IMPORT_4___ = require("./Roboto-Regular.ttf");
var ___CSS_LOADER_URL_IMPORT_5___ = require("./Roboto-Light.ttf");
var ___CSS_LOADER_URL_IMPORT_6___ = require("./GothamPro.woff");
var ___CSS_LOADER_URL_IMPORT_7___ = require("./GothamPro-Medium.ttf");
exports = ___CSS_LOADER_API_IMPORT___(false);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
var ___CSS_LOADER_URL_REPLACEMENT_1___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_1___);
var ___CSS_LOADER_URL_REPLACEMENT_2___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_2___);
var ___CSS_LOADER_URL_REPLACEMENT_3___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_3___);
var ___CSS_LOADER_URL_REPLACEMENT_4___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_4___);
var ___CSS_LOADER_URL_REPLACEMENT_5___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_5___);
var ___CSS_LOADER_URL_REPLACEMENT_6___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_6___);
var ___CSS_LOADER_URL_REPLACEMENT_7___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_7___);
// Module
exports.push([module.id, "html {\n    height: 100%;\n}\n\nbody {\n    margin: 0;\n    height: 100%;\n\n    background: #d5dae6;\n}\n\n@font-face {\n    font-family: \"EaistFont\";\n    src: url(" + ___CSS_LOADER_URL_REPLACEMENT_0___ + ");\n}\n\n@font-face {\n    font-family: \"Glyphicons Halflings\";\n    src: url(" + ___CSS_LOADER_URL_REPLACEMENT_1___ + ");\n}\n\n@font-face {\n    font-family: \"Roboto\";\n    font-weight: 600;\n    src: url(" + ___CSS_LOADER_URL_REPLACEMENT_2___ + ");\n}\n\n@font-face {\n    font-family: \"Roboto\";\n    font-weight: 500;\n    src: url(" + ___CSS_LOADER_URL_REPLACEMENT_3___ + ");\n}\n\n@font-face {\n    font-family: \"Roboto\";\n    font-weight: 400;\n    src: url(" + ___CSS_LOADER_URL_REPLACEMENT_4___ + ");\n}\n\n@font-face {\n    font-family: \"Roboto\";\n    font-weight: 300;\n    src: url(" + ___CSS_LOADER_URL_REPLACEMENT_5___ + ");\n}\n\n@font-face {\n    font-family: 'GothamPro';\n    src: url(" + ___CSS_LOADER_URL_REPLACEMENT_6___ + ") format('woff');\n}\n\n@font-face {\n    font-family: 'GothamPro-Medium';\n    src: url(" + ___CSS_LOADER_URL_REPLACEMENT_7___ + ");\n}\n\n:root {\n    --sidebar-width: 360px;\n}\n\n\n\n@media only screen and (max-width: 1440px) {\n    :root {\n        --sidebar-width: 260px;\n    }\n}\n\n::-webkit-scrollbar {\n    width: 12px;\n    height: 12px;\n    cursor: pointer;\n}\n\n::-webkit-scrollbar-corner {\n    background-color: transparent;\n}\n\n::-webkit-scrollbar-thumb {\n    background-color: rgba(0,0,0,0.1);\n    min-height: 40px;\n    cursor: pointer;\n}\n\n::-webkit-scrollbar-thumb:hover {\n    background-color: rgba(0,0,0,0.4);\n}\n\n::-webkit-scrollbar-thumb,\n::-webkit-scrollbar-track {\n    border: 3px solid transparent;\n    background-clip: padding-box;\n    border-radius: 6px;\n}\n\n::-webkit-scrollbar-track {\n    background-color: rgba(0,0,0,0.03);\n    cursor: pointer;\n}\n", ""]);
// Exports
module.exports = exports;
