import { uuid } from "@/models/parsing";
import { Monopoly, MonopolyDocumentTemplate } from "@/models/Monopoly";
import { http, IFilterObject } from "@//api/http";
import { IPagedItems, mapPage } from "@//api/Pagination";
import { ShortSupplier } from "@/models/Monopoly/ShortSupplier";
import { MonopolyHistoryItem } from "@/models/Monopoly/MonopolyHistory";
import { LawType } from "@/models/enums";
import { LotPlannedPaymentTemplate } from "@/models/ComposedLots/LotPlannedPayment";
import { MonopolyTemplate } from "@/models/Monopoly/MonopolyTemplate";

export const getRequestForConclusion = async (id: uuid): Promise<Monopoly> => (
    Monopoly.fromDto(await http.get(`/api/v2/requestForConclusion/${id}`))
);

export const getMonopolyObjectsPaged = async (year: number, from: number, count: number, filters?: IFilterObject): Promise<IPagedItems<Monopoly>> => (
    mapPage(
        Monopoly,
        await http.getPaged(`/api/v2/monopolyObject?year=${year}`, { from, count, filters }),
    )
);

export const getSuppliers = async () => (
    ShortSupplier.fromDto(await http.get("/api/v2/requestForConclusion/suppliers"))
);

export const mockMonopolyObjects = async (): Promise<void> =>
    http.post("/api/v2/monopolyObject/createMockObjects", {});

export const clearMonopolyObjects = async (): Promise<void> =>
    http.delete("/api/v2/monopolyObject/clear");

export const getMonopolyObject = async (id: uuid): Promise<Monopoly> =>
    Monopoly.fromDto(await http.get(`/api/v2/monopolyObject/${id}`));

export const getHistory = async (id: uuid, from: number, count: number): Promise<IPagedItems<MonopolyHistoryItem>> =>
    mapPage(
        MonopolyHistoryItem,
        await http.getPaged(`/api/v2/monopolyObject/${id}/history`, { from, count })
    );

export const acceptMonopolyObject = async (id: uuid, lawType: LawType, plannedPayments: LotPlannedPaymentTemplate[]): Promise<Monopoly> =>
    Monopoly.fromDto(
        await http.put(
            `/api/v2/monopolyObject/${id}/accept?lawType=${lawType}`,
            plannedPayments.map(LotPlannedPaymentTemplate.toDto)
        )
    );

export const divergenceMonopolyObject = async (id: uuid, documents: MonopolyDocumentTemplate[], comment: string) =>
    Monopoly.fromDto(
        await http.put(
            `/api/v2/monopolyObject/${id}/divergence`,
            { documents: documents.map(MonopolyDocumentTemplate.toDto), comment }
        )
    );

export const sendMonopolyObject = async (id: uuid, objectTemplate: MonopolyTemplate) =>
    await http.post(
        `/api/v2/monopolyObject/${id}/send`,
        MonopolyTemplate.toDto(objectTemplate)
    );

export const cancelDivergence = async (id: uuid) =>
    await Monopoly.fromDto(
        await http.put(`/api/v2/monopolyObject/${id}/cancelDivergence`, undefined)
    );
