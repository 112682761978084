export const createCalendarDayString = (value?: number | string | null) => {
    if (!value) return "";
    const valueToStr = value.toString();

    if (valueToStr === "1") return "календарный день";

    if (valueToStr.length === 2 && valueToStr[0] === "1") return "календарных дней";

    switch (valueToStr[valueToStr.length - 1]) {
        case "1":
            return "календарный день";
        case "2":
        case "3":
        case "4":
            return "календарных дня";
        default:
            return "календарных дней";
    }
};
