// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, "._16OvMgfkk3a1jra5JurQeI {\n    display: flex;\n    white-space: nowrap;\n}\n\n\n._2nOF74fHWBwOQHQ6ZfXTQd {\n    display: inline-block;\n    width: auto;\n    padding-left: 28px;\n    min-height: 16px;\n    min-width: 16px;\n    background-repeat: no-repeat;\n    background-position: left center;\n    background-size: contain;\n\n    background-image: url(\"https://eaist2-f.mos.ru/eshop/img/infinity.12a0c4fa085fddb854ef.png\");\n\n    box-sizing: border-box;\n\n    margin-left: 5px;\n}\n\n._2s97qvnTwm4mShmMZOeN_V {\n    border-left: 5px solid rgba(255, 102, 102, 0.4);\n}\n\n._1G-faijVtnfyCPoRcJ7jU8 {\n    border-left: 5px solid rgba(77, 178, 255, 0.4);\n}\n\n.fahrFg23LT7Wia5vyu5bN {\n    border-left: 5px solid rgba(248, 203, 78, 0.45);\n}\n", ""]);
// Exports
exports.locals = {
	"offerQuantity": "_16OvMgfkk3a1jra5JurQeI",
	"infinityIcon": "_2nOF74fHWBwOQHQ6ZfXTQd",
	"red": "_2s97qvnTwm4mShmMZOeN_V",
	"blue": "_1G-faijVtnfyCPoRcJ7jU8",
	"yellow": "fahrFg23LT7Wia5vyu5bN"
};
module.exports = exports;
