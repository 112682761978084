import {ModalFC, showModal, typifyVueModal} from "@/EventBus";
import {Expandee, HStack, VStack} from "@/components/layouts";
import {Button, Label, TextBox} from "@/components/primitive";
import {CardModal} from "@/components/modals/CardModal";
import React, {useEffect, useState} from "react";
import {Field, Fields} from "@/components/form/Field";
import {Participant} from "@/models";
import ParticipantSelectionModal from "@/views/modals/ParticipantSelectionModal";
import Participants from "@/api/Participants";
import {FilterConditionType, FilterValueType} from "@/api/http";

const PickParticipantModal = typifyVueModal<{ participants: Participant[] }, Participant>(ParticipantSelectionModal);

export interface ContractCreationModalProps {
    tin: string
}

export const ContractCreationModal: ModalFC<ContractCreationModalProps, Participant> = x => {
    const [participant, setParticipant] = useState<Participant | undefined>();
    const [participants, setParticipants] = useState<Participant[]>([]);

    useEffect(() => {
        Participants.getByTIN(0, 1000, x.tin, {
            isSupplier: [{
                type: FilterValueType.BOOLEAN,
                conditionType: FilterConditionType.EQUAL,
                boolean: true,
            }]
        })
            .then(result => setParticipants(result.items));
    }, []);

    useEffect(() => {
        participants?.[0] && setParticipant(participants[0]);
    }, [participants]);

    const pickParticipant =  async (): Promise<Participant | undefined> => {
        const result = await showModal(PickParticipantModal, { participants });
        if (result) {
            setParticipant(result);
        } else return;
    };

    const bottom = <HStack spacing="10px">
        <Button color="green"
                title="Сформировать контракт"
                onClick={() => x.done(participant)}/>
        <Expandee/>
    </HStack>;

    return <CardModal title="Формирование контракта"
                      close={() => x.done(undefined)}
                      width="70%"
                      bottom={bottom}>
        <VStack spacing="15px">
            <Fields orientation="vertical">
                <Field title="Наименование участника">
                    <Label text={participant?.fullName ?? ""}/>
                </Field>
                <Field title="Адрес">
                    <Label text={participant?.address ?? ""}/>
                </Field>
            </Fields>
            <Fields orientation="horizontal">
                <Field title="Выбор поставщика из НСИ">
                    {
                        participant
                            ? <TextBox disabled value={participant?.fullName}/>
                            : participants.length > 0
                                ? <Button title="Выбрать" onClick={async () => await pickParticipant()} style={{ maxWidth: "100px" }} />
                                : <></>

                    }
                </Field>
            </Fields>
        </VStack>
    </CardModal>;
};