import React from "react";
import {
    WizardAnswer,
    WizardQuestion
} from "@/views/ComposedLot/edit/tabs/lot-documents/DocumentsSection/wizard/tabs/Quiz";
import styles from "./Question.module.css";
import { VStack } from "@/components/layouts";
import { Radio, RadioGroup } from "@/components/primitive";

interface WizardQuestionProps {
    question: WizardQuestion
    selectedAnswer: WizardAnswer
    onChangeAnswer?: (question: WizardQuestion, answer: WizardAnswer) => void
}

export const WizardQuestionView: React.FC<WizardQuestionProps> = x => {
    const getAnswerById = (id: WizardAnswer["id"]) => x.question.answers.find(a => a.id === id) ?? x.selectedAnswer;

    return <div className={styles.layout}>
        <div className={styles.title}>
            { x.question.question }
        </div>
        <VStack spacing="10px">
            <RadioGroup>
                {
                    x.question.answers.map(a =>
                      <Radio
                        value={x.selectedAnswer?.id}
                        onChange={(qId) => x.onChangeAnswer?.(x.question, getAnswerById(qId))}
                        key={`answer-${a.id}`}
                        requiredValue={a.id}>
                        { a.text }
                    </Radio>)
                }
            </RadioGroup>
        </VStack>
    </div>;
};
