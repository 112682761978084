import { AutoDto, AutoModel, MDMap, uuid } from "@/models/parsing";
import Decimal from "decimal.js";
import {
    DtoSubjectDeclaration,
    MeasurementUnit,
    SubjectDeclaration
} from "@/models/SubjectDeclaration/SubjectDeclaration";

interface ContractObligationReceiptMD {
    id: uuid
    actualCost: Decimal
    volume: Decimal
    measurementUnit: MeasurementUnit
    country: string
    subjectDeclaration: MDMap<SubjectDeclaration, DtoSubjectDeclaration>
}

export type ContractObligationReceipt = AutoModel<ContractObligationReceiptMD>;
export type DtoContractObligationReceipt = AutoDto<ContractObligationReceiptMD>;

export const ContractObligationReceipt = {
    fromDto(dtoContractObligationReceipts: DtoContractObligationReceipt): ContractObligationReceipt {
        return {
            ...dtoContractObligationReceipts,
            actualCost: new Decimal(dtoContractObligationReceipts.actualCost),
            volume: new Decimal(dtoContractObligationReceipts.volume),
            subjectDeclaration: SubjectDeclaration.fromDto(dtoContractObligationReceipts.subjectDeclaration)
        };
    }
};
