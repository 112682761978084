import {asDecimal, AutoDto, AutoModel, MDMap} from "@/models/parsing";
import {CharacteristicConditionType} from "@/models/enums";
import Decimal from "decimal.js";
import {
    CharacteristicDeclaration,
    DtoCharacteristicDeclaration
} from "@/models/ComposedLots/specifications/CharacteristicDeclaration";

interface CharacteristicValueMD {
    declaration: MDMap<CharacteristicDeclaration, DtoCharacteristicDeclaration>
    conditionType: CharacteristicConditionType
    enumValues: string[]
    numberValueFirst?: Decimal
    subConditionTypeFirst: CharacteristicConditionType
    numberValueSecond?: Decimal
    subConditionTypeSecond: CharacteristicConditionType
}

export type DtoCharacteristicValue = AutoDto<CharacteristicValueMD>;
export type CharacteristicValue = AutoModel<CharacteristicValueMD>;

export const CharacteristicValue = {
    toDto(info: CharacteristicValue): DtoCharacteristicValue {
        return {
            ...info,
            declaration: CharacteristicDeclaration.toDto(info.declaration),
            numberValueFirst: info.numberValueFirst?.toString(),
            numberValueSecond: info.numberValueSecond?.toString()
        };
    },
    toTemplate(info: CharacteristicValue): CharacteristicValueTemplate {
        return {
            ...info,
            declarationId: info.declaration.id
        };
    },
    fromDto(info: DtoCharacteristicValue): CharacteristicValue {
        return {
            ...info,
            declaration: CharacteristicDeclaration.fromDto(info.declaration),
            numberValueFirst: asDecimal(info.numberValueFirst),
            numberValueSecond: asDecimal(info.numberValueSecond)
        };
    }
};

interface CharacteristicValueTemplateMD {
    declarationId: number
    conditionType: CharacteristicConditionType
    enumValues: string[]
    numberValueFirst?: Decimal
    subConditionTypeFirst: CharacteristicConditionType
    numberValueSecond?: Decimal
    subConditionTypeSecond: CharacteristicConditionType
}

export type DtoCharacteristicValueTemplate = AutoDto<CharacteristicValueTemplateMD>;
export type CharacteristicValueTemplate = AutoModel<CharacteristicValueTemplateMD>;

export const CharacteristicValueTemplate = {
    toDto(info: CharacteristicValueTemplate): DtoCharacteristicValueTemplate {
        return {
            ...info,
            numberValueFirst: info.numberValueFirst?.toString(),
            numberValueSecond: info.numberValueSecond?.toString()
        };
    },
    fromDto(info: DtoCharacteristicValueTemplate): CharacteristicValueTemplate {
        return {
            ...info,
            numberValueFirst: asDecimal(info.numberValueFirst),
            numberValueSecond: asDecimal(info.numberValueSecond)
        };
    }
};