import React from "react";
import {Card} from "@/components/Card";
import {Field, Fields} from "@/components/form/Field";
import {Label} from "@/components/primitive";
import {ParticipantMD} from "@/models";

export const SuppliersLegalRequisites: React.FC<{ info: ParticipantMD }> = x => {
    return <Card>
        <Fields orientation="horizontal">
            <Field title="Типы поставщика">
                <Label>
                    {x.info.fullName}
                </Label>
            </Field>
            <Field title="ИНН">
                <Label>
                    {x.info.tin}
                </Label>
            </Field>
            <Field title="КПП">
                <Label>
                    {x.info.rrc}
                </Label>
            </Field>
            <Field title="Дата постановки на учет в налоговом органе">
                <Label>
                    04.05.2016
                </Label>
            </Field>
            <Field title="Организационно-правовая форма">
                <Label>
                    Общества с ограниченной ответственностью
                </Label>
            </Field>
            <Field title="Полное наименование">
                <Label>
                    {x.info.fullName}
                </Label>
            </Field>
            <Field title="Краткое наименование">
                <Label>
                    {x.info.shortName}
                </Label>
            </Field>
            <Field title="Фирменное наименование">
                <Label>

                </Label>
            </Field>
            <Field title="ОГРН">
                <Label>
                    {x.info.regNumber}
                </Label>
            </Field>
            <Field title="Страна">
                <Label>
                    Российская Федерация
                </Label>
            </Field>
            <Field title="Юридический адрес">
                <Label>
                    115304 г. Москва Кантемировская дом 3, корп.3, пом VIII, комн.2
                </Label>
            </Field>
            <Field title="Головная организация">
                <Label>
                    10000000 Правительство Москвы
                </Label>
            </Field>
            <Field title="Код ИКО">
                <Label>

                </Label>
            </Field>
            <Field title="Статус">
                <Label>
                    Зарегистрирован
                </Label>
            </Field>
        </Fields>
    </Card>;
};