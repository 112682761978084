import {ModalFC} from "@/EventBus";
import {CardModal} from "@/components/modals/CardModal";
import React from "react";
import {Expandee, HStack} from "@/components/layouts";
import {Label} from "@/components/primitive";
import {RoundedButton} from "@/components/primitive/RoundedButton";


export const ConfirmationModal: ModalFC<{text?: string | React.ReactNode; title?: string | React.ReactNode; notify?: boolean; width?: string}, boolean> = x => {

    const bottom = <HStack spacing={"10px"}>
        <Expandee/>
        {
            x.notify
                ? <RoundedButton color="blue"
                          title="Ок"
                          onClick={() => x.done(true)} />
                : <>
                    <RoundedButton color="white"
                            title="Нет"
                            onClick={() => x.done(false)} />
                    <RoundedButton color="blue"
                            title="Да"
                            onClick={() => x.done(true)} />
                </>
        }
    </HStack>;

    const message: string | React.ReactNode = x.text ?? "Вы уверены?";
    const title: string | React.ReactNode = x.title ?? "Подтверждение";

    return <CardModal title={title}
                      close={() => x.done(false)}
                      width={x.width ?? "50%"}
                      cardHeaderStyle={{ backgroundColor: "white" }}
                      bottom={bottom}>
        <Label style={{ fontSize: "18px", fontWeight: "normal"}}>
            {message}
        </Label>
    </CardModal>;
};

export const ErrorModal: ModalFC<{text?: string | React.ReactNode; title?: string}, void> = x => {

    const bottom = <HStack spacing={"10px"}>
        <Expandee/>
        <RoundedButton color="white"
                title="Закрыть"
                onClick={() => x.done()} />
    </HStack>;

    const message = x.text ?? "Произошла ошибка";
    const title = x.title ?? "Ошибка";

    return <CardModal title={title}
                      cardStyle={{ border: "unset" }}
                      cardHeaderStyle={{ backgroundColor: "#FF6666", color: "#F0F0F0" }}
                      close={() => x.done()}
                      width={"30%"}
                      bottom={bottom}>
        {!x.text || typeof x.text === "string" 
            ? <span style={{ whiteSpace: "pre-wrap" }}>
                { message }
              </span>
            : x.text}
    </CardModal>;
};
