






import { Component, Prop, Vue } from "vue-property-decorator";
import { ITableColumn, ITableAction } from "@/components/TableAbstractions";

@Component
export default class DataTableActionCell extends Vue {
    @Prop() private item!: unknown;
}
