// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_GET_URL_IMPORT___ = require("../../node_modules/css-loader/dist/runtime/getUrl.js");
var ___CSS_LOADER_URL_IMPORT_0___ = require("../assets/list_markers.png");
exports = ___CSS_LOADER_API_IMPORT___(false);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
// Module
exports.push([module.id, "\n.expander[data-v-b5c7cbd6] {\n    display: flex;\n    flex-direction: column;\n    background: white;\n\n    border-radius: 3px;\n    border-width: 0 1px 1px;\n    border-style: solid;\n    border-color: transparent #ccd1de #bbc1d0;\n\n    padding: 20px 20px 20px 38px;\n}\n.flat[data-v-b5c7cbd6] {\n    border: none;\n}\n.fullwidth[data-v-b5c7cbd6] {\n    width: calc(100% - 58px);\n}\n.fullwidth .expander-toggle[data-v-b5c7cbd6] {\n    width: 100%;\n}\n.fullwidth .expander-text[data-v-b5c7cbd6] {\n    flex-grow: 1;\n}\n.expander-toggle[data-v-b5c7cbd6] {\n    display: flex;\n    align-items: center;\n\n    cursor: pointer;\n    user-select: none;\n}\n.expander-icon[data-v-b5c7cbd6] {\n    width: 12px;\n    height: 12px;\n    background: url(" + ___CSS_LOADER_URL_REPLACEMENT_0___ + ") no-repeat;\n}\n.expander-icon.open[data-v-b5c7cbd6] {\n    background-position-y: -12px;\n}\n.expander-text[data-v-b5c7cbd6] {\n    font-size: 12px;\n    font-weight: 500;\n\n    margin-left: 10px;\n    width: 100%;\n}\n", ""]);
// Exports
module.exports = exports;
