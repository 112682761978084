import React from "react";
import {Card} from "@/components/Card";
import {Field, Fields} from "@/components/form/Field";
import {Label} from "@/components/primitive";

export const CustomersTitleDeed: React.FC = x => {
    return <Card>
        <Fields orientation="horizontal">
            <Field title="Положение/устав">
                <Label>

                </Label>
            </Field>
            <Field title="Кем утвержден">
                <Label>

                </Label>
            </Field>
        </Fields>
    </Card>;
};