













import { Component, Prop, Vue } from "vue-property-decorator";

@Component
export default class Radio extends Vue {
    @Prop() private value!: boolean;
    @Prop() private group!: string;
    @Prop({ default: false }) private disabled!: boolean;
}
