import React from "react";
import {Card} from "@/components/Card";
import {Field, Fields} from "@/components/form/Field";
import {Label} from "@/components/primitive";
import {CustomerMD} from "@/models/Customer";
import {ParticipantMD} from "@/models";

export const CustomersCustomersDetails: React.FC<{ info: ParticipantMD }> = x => {
    return <Card>
        <Fields orientation="horizontal">
            <Field title="ИНН">
                <Label>
                    {x.info.tin}
                </Label>
            </Field>
            <Field title="КПП">
                <Label>
                    {x.info.rrc}
                </Label>
            </Field>
            <Field title="Дата постановки на учет в налоговом органе">
                <Label>
                    01.01.2013
                </Label>
            </Field>
            <Field title="Организационно-правовая форма">
                <Label>
                    Государственные бюджетные учреждения субъектов Российской Федерации
                </Label>
            </Field>
            <Field title="Полное наименование">
                <Label>
                    {x.info.fullName}
                </Label>
            </Field>
            <Field title="Краткое наименование">
                <Label>
                    {x.info.fullName}
                </Label>
            </Field>
            <Field title="ОГРН">
                <Label>
                    {x.info.regNumber}
                </Label>
            </Field>
            <Field title="Страна">
                <Label>
                    Российская Федерация
                </Label>
            </Field>
            <Field title="Головная организация">
                <Label>

                </Label>
            </Field>
            <Field title="Статус">
                <Label>
                    Архив
                </Label>
            </Field>
            <Field title="Юридический адрес">
                <Label>
                    {x.info.address}
                </Label>
            </Field>
        </Fields>
    </Card>;
};