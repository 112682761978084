import {ModalFC, showModal} from "@/EventBus";
import {CardModal} from "@/components/modals/CardModal";
import React, {useEffect, useState} from "react";
import {WebinarsApi} from "@/api/Webinars";
import {ProgressBar} from "@/components/primitive/ProgressBar";
import {EditWebinarModal} from "@/views/Admin/WebinarAdmin";
import {Expandee, HStack, VStack} from "@/components/layouts";
import {Button, Note} from "@/components/primitive";
import {observer} from "mobx-react";
import {makeAutoObservable} from "mobx";

class UploadVideoModalViewModel {
    constructor() {
        makeAutoObservable(this);
    }

    value = 0;
    hidden = true;
    private abortController: null | AbortController = null;
    filename: string | null = null;

    setValue = (value: number) => {
        console.log("vm progress updated");
        this.value = value;
    };
    setHidden = (value: boolean) => {
        this.hidden = value;
    };

    upload = async (e: React.FormEvent<HTMLInputElement>) => {
        try {
            this.setHidden(false);
            this.setValue(0);
            this.abortController = new AbortController();
            const result = await WebinarsApi.uploadVideoHandler(e, p => {
                console.log("onprogress fired", p);
                this.setValue(Math.round((p.loaded / p.total) * 100));
            }, this.abortController);
            return result;
        } catch (e) {
            console.error("unable to upload video", e);
        } finally {
            this.setValue(0);
            this.setHidden(true);
        }
    };

    abort = () => {
        this.abortController?.abort();
        this.value = 0;
        this.hidden = true;
        this.filename = null;
    };

    dispose = () => {
        this.abortController?.abort();
    };
}

export const UploadVideoModal: ModalFC<void, {}> = observer(x => {
    const [vm, setVm] = useState<UploadVideoModalViewModel>();
    useEffect(() => {
        const _vm = new UploadVideoModalViewModel();
        setVm(_vm);
        return () => _vm.dispose();
    }, []);
    const [result, setResult] = useState<{ id: string; path: string }>();
    if (!vm) return <></>;

    const upload = async (e: React.FormEvent<HTMLInputElement>) => {
        const result = await vm.upload(e);
        if (!result) return;
        const modalResult = await showModal(EditWebinarModal, { videoLocation: result.id });
        x.done(modalResult);
    };

    const bottom = <HStack spacing="20px" alignItems="center">
        <Expandee />
        {
            vm.hidden
                ? <Button title="Закрыть" color="white" onClick={x.done} />
                : <Button title="Отменить" color="red" onClick={vm.abort} />
        }
        <Button title="Далее"
                disabled={!result} />
    </HStack>;

    const progress = <VStack spacing="20px">
        <div>Загружается файл { vm.filename }</div>
        <ProgressBar progress={vm.value} />
    </VStack>;

    const pick = <VStack spacing="20px">
        <Note preset="alert">
            Выберите видеофайл в формате <b>mp4</b> для загрузки
        </Note>
        <input type="file"
               accept="video/mp4"
               multiple={false}
               onChange={upload} />
    </VStack>;

    return <CardModal title="Шаг 1/2: Загрузка видео"
                      bottom={bottom}
                      close={"disabled"}
                      width="550px">
        <VStack spacing="20px">
            { vm.hidden ? pick : progress  }
        </VStack>

    </CardModal>;
});