import { observer } from "mobx-react";
import React, { useState } from "react";
import { Checkbox } from "@/components/eshop";
import { Select } from "@/components/eshop/Select/Select";
import { changeIsPopular, changeProductName, changeReferentPriceMax, changeReferentPriceMin, changeSteId } from "../filtersStore";
import styles from "@/components/eshop/ServicesTable.module.css";
import { EshopLink } from "@/components/eshop/EshopLink";
import { PageOfProducts, Product } from "@/api/Products";
import { FormControlEshopInput } from "@/components/eshop/FormControlEshopInput";
import { firstMaybe } from "@/arrayUtils";
import { ServicesStore, onSelectItemService } from "../store";
import { formatNumber } from "@/NumberFormatting";
import { NumberInput } from "@/components/eshop/NumberInput";

type ProductTableProps = {
    page: PageOfProducts
    store: ServicesStore
};

export const CreateSessionProductsTable = (props: ProductTableProps) => {
    const isSelected = (id: string) => props.store.selectedNewIds.indexOf(id) !== -1;
    const alreadyAdded = (id: string) => props.store.products.map(p => p.idForKey).includes(id);
    const { page } = props;

    return <div className={styles.tableWrapper}>
        <div className={styles.offersTable}>
            <TableHeader store={props.store} />
            <div className={styles.tableBodyWrap} id="tableContent">
                {page.items.map((r, index) => <ProductRow alreadyAdded={alreadyAdded(r.id)} checked={isSelected(r.id)} onSelect={onSelectItemService} key={index} item={r} />)}
            </div>
        </div>
    </div>;
};

const ProductRow = (props: { item: Product; alreadyAdded: boolean; checked: boolean; onSelect: (id: string) => void }) => {
    const { item } = props;

    return <ul className={styles.tableRow}>
        <li className={styles.controlContainer}>
            <Checkbox onChange={() => !props.alreadyAdded && props.onSelect(props.item.id)}
                value={props.checked || props.alreadyAdded} />
        </li>
        <li className={styles.tableCellContent}>
            <EshopLink>
                {item.steId ? item.steId : "Не присвоен"}
            </EshopLink>
        </li>
        <li className={styles.tableCellContent}>
            <EshopLink>
                {item.name}
            </EshopLink>
        </li>
        <li className={styles.tableCellContent}>
            <EshopLink>
                {item.referentPrice ? formatNumber(item.referentPrice) : "-"}
            </EshopLink>
        </li>
        <li className={styles.tableCellContent}>
            <EshopLink>
                {firstMaybe(item.spgz.measurementUnits)?.name || ""}
            </EshopLink>
        </li>
        <li className={styles.tableCellContent}>
            <EshopLink>
                {item.isPopular ? "Да" : "Нет"}
            </EshopLink>
        </li>
    </ul>;
};

const TableHeader = observer((props: { store: ServicesStore }) => {
    const popularOptions = [{ name: "Все", value: "null" }, { name: "Да", value: "true" }, { name: "Нет", value: "false" }];

    const [isPopular, setIsPopular] = useState<boolean | null>(null);

    const updateIsPopular = (isPopular: boolean | null) => {
        setIsPopular(isPopular);
        changeIsPopular(isPopular);
    };


    return <div className={styles.offersTableHeader} id="tableHeader">
        <ul className={styles.headerContainer}>
            <li className={styles.controlContainerHeader}>
            </li>
            <li className={styles.tableCellContent}>
                Реестр. №
            </li>
            <li className={styles.tableCellContent}>
                Название CTE
            </li>
            <li className={styles.tableCellContent}>
                <div className={styles.eshopSortButton}>
                    <div className={`${styles.arrowIcon} ${styles.arrowIconUp}`} />
                    <div className={`${styles.arrowIcon}`} />
                </div>
                Референтная цена, руб
            </li>
            <li className={styles.tableCellContent}>
                Ед. изм.
            </li>
            <li className={styles.tableCellContent}>
                Востребованная продукция
            </li>
        </ul>
        <ul className={styles.headerContainer} style={{alignItems: "flex-start"}}>
            <li className={styles.controlContainerHeader}>
            </li>
            <li className={styles.tableCellContent}>
                <FormControlEshopInput small onChange={e => changeSteId(parseInt(e.target.value))} placeholder="Введите значение" />
            </li>
            <li className={styles.tableCellContent}>
                <FormControlEshopInput small onChange={e => changeProductName(e.target.value)} placeholder="Введите значение" />
            </li>
            <li className={styles.tableFilterCellContent}>
                <NumberInput
                    placeholder="от"
                    onChange={e => changeReferentPriceMin(e)} small />
                <NumberInput
                    placeholder="до"
                    onChange={e => changeReferentPriceMax(e)} small />
            </li>
            <li className={styles.tableCellContent}>
            </li>
            <li className={styles.tableCellContent}>
                <Select value={isPopular === null ? "null" : isPopular.toString()}
                        onSelect={v => updateIsPopular(getValue(v))}
                        options={popularOptions}
                        fullWidth small />
            </li>
        </ul>
    </div>;
});

const getValue = (str: string | undefined) => {
    switch (str) {
        default:
        case "null":
            return null;
        case "true":
            return true;
        case "false":
            return false;
    }
};
