






























import { TextAlignment } from '@/components/vue/form-table/FormFields';
import { Component, Prop, Vue } from "vue-property-decorator";

@Component
export default class TextBox extends Vue {
    @Prop() public value!: string;
    @Prop({ default: "" }) public placeholder!: string;
    @Prop({ default: false }) public multiline!: boolean;
    @Prop({ default: false }) public disabled!: boolean;
    @Prop({ default: false }) public readonly!: boolean;
    @Prop({ default: false }) public isDecimalField!: boolean;
    @Prop({ default: 3 }) public lines!: number;
    @Prop({ default: TextAlignment.LEFT }) public alignment!: TextAlignment;
    @Prop({ default: () => /.*/s }) public pattern!: RegExp;

    public focus() {
        (this.$el as HTMLElement).focus();
    }

    public setCaretPosition(pos: number) {
        const a = (this.$el as (HTMLInputElement & HTMLTextAreaElement));
        a.setSelectionRange(pos, pos);
    }

    public onInput(ev: Event) {
        const target = ev.target! as HTMLInputElement;
        const result = this.pattern.exec(this.isDecimalField ? target.value.replaceAll(" ", "") : target.value);
        const fixed = result ? result[0] : "";
        target.value = fixed;
        this.$emit('input', fixed);
    }
}
