import {CompatEnum, EnumStrings, UnionStrings} from '../EnumStrings';

export type ProcedureDocumentType44 =
    | "SALE_ORDER"
    | "OTHER"
    | "COMPARATIVE_TABLE"
    | "REQUEST_FORM";

export type ProcedureDocumentType223 =
    | "SALE_ORDER"
    | "OTHER"
    | "PROCEDURE_DOCS"
    | "COMPARATIVE_TABLE"
    | "REQUEST_FORM";

export type ProcedureDocumentType = ProcedureDocumentType44 | ProcedureDocumentType223;

export const ProcedureDocumentType: CompatEnum<ProcedureDocumentType> = {
    SALE_ORDER: "SALE_ORDER",
    OTHER: "OTHER",
    PROCEDURE_DOCS: "PROCEDURE_DOCS",
    COMPARATIVE_TABLE: "COMPARATIVE_TABLE",
    REQUEST_FORM: "REQUEST_FORM"
};

export const Procedure223DocumentTypeStrings: UnionStrings<ProcedureDocumentType223> = {
    SALE_ORDER: "Приказ о проведении закупки",
    OTHER: "Прочее",
    PROCEDURE_DOCS: "Документация о закупке",
    COMPARATIVE_TABLE: "Сравнительная таблица внесённых изменений",
    REQUEST_FORM: "Форма заявки"
};

export const Procedure44DocumentTypeStrings: UnionStrings<ProcedureDocumentType44> = {
    SALE_ORDER: "Приказ о проведении закупки",
    OTHER: "Прочее",
    COMPARATIVE_TABLE: "Сравнительная таблица внесённых изменений",
    REQUEST_FORM: "Форма заявки"
};
