import { http } from "./http";
import { ContractExecutionProvision } from '@/models/Contracts';
import { IDtoContractExecutionProvision } from '@/models/json/Contracts';
import {ContractGuaranteeProvision} from "@/models/Contracts/ContractGuaranteeProvision";
import {IDtoContractGuaranteeProvision} from "@/models/json/Contracts/IDtoContractGuaranteeProvision";

export default class {
    public static async getFromContract(contractId: string): Promise<ContractGuaranteeProvision[]> {
        return (await http.get(`/api/contractGuaranteeProvisions/fromContract/${contractId}`, undefined) as IDtoContractGuaranteeProvision[])
            .map(ContractGuaranteeProvision.fromJson);
    }

    public static async create(contractId: string, src: ContractGuaranteeProvision): Promise<ContractGuaranteeProvision> {
        return ContractGuaranteeProvision.fromJson(await http.post(`/api/contractGuaranteeProvisions/createFor/${contractId}`, src.toJson()));
    }

    public static async update(obj: ContractGuaranteeProvision): Promise<ContractGuaranteeProvision> {
        return ContractGuaranteeProvision.fromJson(await http.put(`/api/contractGuaranteeProvisions/${obj.id}`, obj.toJson()));
    }

    public static async delete(obj: ContractGuaranteeProvision): Promise<void> {
        await http.delete(`/api/contractGuaranteeProvisions/${obj.id}`);
    }
}
