import {AutoDto, AutoModel, MDMap} from "@/models/parsing";
import {Currency} from "@/models/enums";
import {BankAccount} from "@/models/BankAccount";
import {IDtoBankAccount} from "@/models/json/IDtoBankAccount";

interface PaymentOrderMD {
    paymentRequired: boolean
    volume?: string
    currency?: Currency
    taxRequired?: boolean
    taxDescription?: string
    account?: MDMap<BankAccount, IDtoBankAccount>
    default?: boolean
    text?: string
}

export type PaymentOrder = AutoModel<PaymentOrderMD>;
export type DtoPaymentOrder = AutoDto<PaymentOrderMD>;

export const PaymentOrder = {
    toDto(ord: PaymentOrder): DtoPaymentOrder {
        return {
            ...ord,
        };
    },
    fromDto(ord: DtoPaymentOrder): PaymentOrder {
        return {
            ...ord,
            volume: ord.volume ?? undefined,
            currency: ord.currency ?? undefined,
            taxRequired: ord.taxRequired ?? undefined,
            taxDescription: ord.taxDescription ?? undefined,
            account: ord.account ? BankAccount.fromJson(ord.account) : undefined,
            default: ord.default ?? undefined,
            text: ord.text ?? undefined,
        };
    },
};

