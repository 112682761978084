import React, { MouseEvent } from "react";
import {VGrid} from "@/components/layouts";
import {Button, TextBox} from "@/components/primitive/index";

interface BoxPickerProps {
    value?: string
    onPick?: (e: MouseEvent) => void

    disabled?: boolean
}

export const BoxPicker: React.FC<BoxPickerProps> = x =>
    <VGrid columns="1fr auto" spacing="10px">
        <TextBox value={x.value} readonly onClick={x.disabled ? undefined : x.onPick} disabled={x.disabled} />
        <Button onClick={x.disabled ? undefined : x.onPick} icon="gPencil" disabled={x.disabled} />
    </VGrid>;