// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, "._2-qCVI-yMGwL4EO6XHqTOS {\n    display: inline-block;\n    width: 50px;\n    height: 50px;\n    border: 3px solid rgba(255,255,255,.3);\n    border-radius: 50%;\n    border-top-color: #fff;\n    animation: _1pjS04cuV4ludmedncHc2Y 1s ease-in-out infinite;\n}\n\n@keyframes _1pjS04cuV4ludmedncHc2Y {\n    to {\n        transform: rotate(360deg);\n    }\n}\n@-webkit-keyframes _1pjS04cuV4ludmedncHc2Y {\n    to {\n        -webkit-transform: rotate(360deg);\n    }\n}\n", ""]);
// Exports
exports.locals = {
	"spinner": "_2-qCVI-yMGwL4EO6XHqTOS",
	"spin": "_1pjS04cuV4ludmedncHc2Y"
};
module.exports = exports;
