import CardModal from "@/components/CardModal.vue";
import { DocumentModalProps, DocumentTable } from "@/form-declarations/documentation";
import { ModalResult, ModalVue } from "@/view-models/ModalRequest";
import { VueClass } from "vue-class-component/lib/declarations";
import { Component, Prop } from "vue-property-decorator";

@Component({ components: { CardModal } })
export default class DocumentModal extends ModalVue<DocumentTable, void> {
    @Prop() private template!: VueClass<unknown>;
    @Prop() private prop!: DocumentModalProps;

    private save() {
        this.$emit("close-modal", ModalResult.ok({ table: this.prop.table, save: "permanent" }));
    }

    private draft() {
        this.$emit("close-modal", ModalResult.ok({ table: this.prop.table, save: "draft" }));
    }

    private close() {
        this.$emit("close-modal", ModalResult.ok({ table: this.prop.table }));
    }
}
