import { ContractFinancialSourceUsage } from "@/models/Contracts";
import { IDtoContractStage } from "@/models/json/Contracts";
import { IJsonFormattable } from "@/models/IJsonFormattable";
import { ObjectStatus } from '../enums';

export class ContractStage implements IJsonFormattable<IDtoContractStage> {
    constructor(
        public id: string,
        public createdAt: Date,
        public startDate: Date | null,
        public endDate: Date | null,
        public status: ObjectStatus,
        public completed: boolean,
        public wasCompleted: boolean,
        public name: string,
        public description: string,
        public stageSourceUsage: Array<ContractFinancialSourceUsage>,
        public reviewDocumentsDate: Date | null,
        public sendDocumentsDate: Date | null,
        public paymentDate: Date | null,
        public paymentOffset: number | null,
    ) {}

    public toJson(): IDtoContractStage {
        return {
            id: this.id,
            status: this.status,
            createdAt: (this.createdAt ? this.createdAt.toISOString() : null)!,
            startDate: this.startDate && this.startDate.toISOString(),
            endDate: this.endDate ? this.endDate.toISOString() : null,
            name: this.name,
            description: this.description,
            stageSourceUsage: this.stageSourceUsage.map(x => x.toJson()),
            completed: this.completed,
            reviewDocumentsDate: this.reviewDocumentsDate ? this.reviewDocumentsDate.toISOString() : null,
            sendDocumentsDate: this.sendDocumentsDate ? this.sendDocumentsDate.toISOString() : null,
            paymentDate: this.paymentDate ? this.paymentDate.toISOString() : null,
            paymentOffset: this.paymentOffset
        };
    }

    public static empty() {
        return new ContractStage(null!, null!, null!, null!, null!, false, false, "Новый этап", "", [], null!, null!, null, null);
    }

    public static fromJson(dto: IDtoContractStage): ContractStage {
        return Object.assign(Object.create(ContractStage.prototype), {
            id: dto.id,
            status: dto.status,
            createdAt: dto.createdAt ? new Date(dto.createdAt) : null,
            startDate: dto.startDate && new Date(dto.startDate),
            endDate: dto.endDate ? new Date(dto.endDate) : null,
            completed: dto.completed,
            wasCompleted: dto.completed,
            name: dto.name,
            description: dto.description,
            stageSourceUsage: dto.stageSourceUsage.map(x => ContractFinancialSourceUsage.fromJson(x)),
            reviewDocumentsDate: dto.reviewDocumentsDate ? new Date(dto.reviewDocumentsDate) : null,
            sendDocumentsDate: dto.sendDocumentsDate ? new Date(dto.sendDocumentsDate) : null,
            paymentDate: dto.paymentDate ? new Date(dto.paymentDate) : null,
            paymentOffset: dto.paymentOffset
        });
    }
}
