



















import { Component, Prop, Vue, Watch } from "vue-property-decorator";
import { MutatingFieldBase } from "@/components/vue/form-table/FieldBase";
import { IRow, SimpleRow, TextAlignment } from './FormFields';
import Decimal from "decimal.js";
import { mixins } from "vue-class-component";

@Component
export default class IntegerField extends mixins(MutatingFieldBase) {
    private numberString = "";
    private suppressChange = false;

    protected mounted() {
        this.numberString = this.fieldValue === null ? "" : this.valueAsInteger.toString();
    }

    public get valueAsInteger() { return this.fieldValue as number }

    @Watch("fieldValue") private updateValue() {
        if (!this.suppressChange) this.numberString = this.fieldValue === null ? "" : this.valueAsInteger.toString();
        else this.suppressChange = false;
    }

    private get inputString(): string {
        return this.numberString;
    }
    private set inputString(v: string) {
        this.numberString = v;

        let num = Number(v);
        if (num !== (num | 0)) num = NaN;

        this.$emit("set-value", { row: this.row, value: num });

        this.suppressChange = true;
    }
}
