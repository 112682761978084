import React from "react";
import { Lot } from "@/models/ComposedLots";
import { HStack } from "@/components/layouts";
import { Relation } from "@/components/Relation";
import { createLimitRelation } from "@/views/Procedures2020/shared/relations";
import { Monopoly } from "@/models/Monopoly";
import router from "@/router";

type RelationsProps = {
    lot?: Lot
    object: Monopoly
};

export const Relations = ({ lot, object }: RelationsProps) => {
    const objectRelation: Relation<Monopoly> = {
        typeName: "Объект ДМ",
        typeNamePlural: "Объекты ДМ",
        objects: [{ item: object, name: object?.regNumber.toString() }],
        columns: [],
        open: ({ id }) => router.replace(`/plan-objects/monopoly-objects/${id}`)
    };

    const lotRelation: Relation<Lot | undefined> = {
        typeName: "Лот",
        typeNamePlural: "Лот",
        objects: [{ item: lot, name: lot?.basicInfo.subject ?? "" }],
        columns: [],
        open: (lot) => lot && router.push(`/plan-objects/composed-lots/${lot.id}`)
    };

    return (
        <HStack>
            <Relation relation={objectRelation} />
            {lot && <>
                <Relation relation={createLimitRelation([{ lot }])} />
                <Relation relation={lotRelation} />
            </>}
        </HStack>
    );
};
