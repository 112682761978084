import {createStore} from "effector";
import {createStoredRemoteDataset, StoredRemoteDataset} from "@/components/table";
import {FilterConditionType, FilterValueType} from "@/api/http";
import { User } from "@/models/User";
import { getArchivedUsers } from "@/api/Admin";

export type UserFilters = {
    lastName: string
    username: string
};

export interface UsersState {
    users: StoredRemoteDataset<User, UserFilters>
    loaded: boolean
}

export const createArchivedUsersStore = () =>{

    const store = createStore<UsersState>({
        users: createStoredRemoteDataset<User, UserFilters>({
            async load(filters, from, count) {
                return getArchivedUsers(from, count, {
                    lastName: [{
                        type: FilterValueType.STRING,
                        conditionType: FilterConditionType.CONTAINS,
                        string: filters.lastName
                    }],
                    username: [{
                        type: FilterValueType.STRING,
                        conditionType: FilterConditionType.CONTAINS,
                        string: filters.username
                    }]
                });
            }
        }),
        loaded: false
    });

    return store;
};