import { File } from "@/models/Documents";
import { ContractDocumentType } from "@/models/Contracts";
import { ContractDocumentStatus } from "@/models/enums/Contracts";
import { IDtoContractDocument } from '@/models/json/Contracts';
import { IJsonFormattable } from '@/models/IJsonFormattable';

export class ContractDocument implements IJsonFormattable<IDtoContractDocument> {
    constructor(
        public id: string,
        public file: File,
        public type: ContractDocumentType,
        public documentNumber: string,
        public date: Date | null,
        public dateSigned: Date | null,
        public createdAt: Date,
        public status: ContractDocumentStatus,
        public isObligationDocument?: boolean
    ) {}

    // #565  «Договор», «Приложение к договору», «График исполнения договора», «Дополнительное соглашение»
    public get isSendingToEisRequired223() {
        switch (this.type.id) {
            case 4: // Договор
            case 10: // Приложение к договору
            case 11123: // График исполнения договора
            case 2501: // Доп соглашение
                return true;
            default: return false;
        }
    }

    public toJson(): IDtoContractDocument {
        return {
            id: this.id,
            file: this.file.toJson(),
            type: this.type.toJson(),
            number: this.documentNumber,
            date: this.date && this.date.toISOString(),
            dateSigned: this.dateSigned && this.dateSigned.toISOString(),
            createdAt: this.createdAt && this.createdAt.toISOString(),
            status: this.status,
        };
    }

    public static fromJson(dto: IDtoContractDocument): ContractDocument {
        return Object.assign(Object.create(ContractDocument.prototype), {
            id: dto.id,
            file: File.fromJson(dto.file),
            type: ContractDocumentType.fromJson(dto.type),
            documentNumber: dto.number,
            date: dto.date && new Date(dto.date),
            dateSigned: dto.dateSigned && new Date(dto.dateSigned),
            createdAt: new Date(dto.createdAt),
            status: dto.status,
        });
    }
}
