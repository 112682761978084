// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, "._2KommyUPr5nGEcu4qCODLQ {\n    height: auto;\n    min-height: calc(100% - 120px);\n    padding: 20px;\n\n    display: flex;\n    flex-direction: row;\n    justify-content: flex-start;\n    align-items: flex-start;\n    align-content: flex-start;\n    flex-wrap: wrap;\n}\n\n._3i4XARSrdf4AEwLihQNKhP {\n    min-height: calc(100% - 155px) !important;\n}", ""]);
// Exports
exports.locals = {
	"tableContent": "_2KommyUPr5nGEcu4qCODLQ",
	"tableContentOpenTableInfo": "_3i4XARSrdf4AEwLihQNKhP"
};
module.exports = exports;
