




import {Component, Prop, Vue} from "vue-property-decorator";
import {ViewPlan} from './ViewPlan';
import {uuid} from "@/models/parsing";
import {createPlanStore} from "@/views/Plans2020/view/store";

@Component({ components: { komponent: ViewPlan as unknown as typeof Vue } })
export default class ViewPlanVueStub extends Vue {
    @Prop() public planId!: uuid;
    public storeContainer = Object.freeze({ store: createPlanStore(this.planId) });
}
