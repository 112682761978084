// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, "._2BoI8-RxFB3xeWhnZr5Ig5 {\n    width: 100%;\n    display: flex;\n    align-items: center;\n    justify-content: space-between;\n    gap: 20px\n}\n\n.yWA2QaE6birrC7x8229_D {\n    font-size: 18px;\n    height: 40px;\n    min-width: 200px;\n    border: 0;\n    outline: none;\n    margin-left: 10px;\n    margin-top: 2px;\n    line-height: 60px;\n    color: #344049;\n\n    width: 100%;\n}\n\n.yWA2QaE6birrC7x8229_D:focus {\n    border: none;\n    outline: none;\n}\n\n.yWA2QaE6birrC7x8229_D::placeholder {\n    color: #b5b5b5;\n    opacity: 1;\n}\n\n._3X4EVpIwXE8s0t9OTK5DcG {\n    height: 27px;\n    width: 27px;\n}\n\n/* mobile safari hack */\n@supports (-webkit-backdrop-filter: blur(1px)) {\n    ._39G1wo9k6IAIBAnAozTEW2 {\n        height: intrinsic;\n    }\n}", ""]);
// Exports
exports.locals = {
	"searchbar": "_2BoI8-RxFB3xeWhnZr5Ig5",
	"input": "yWA2QaE6birrC7x8229_D",
	"svgWrapper": "_3X4EVpIwXE8s0t9OTK5DcG",
	"svg": "_39G1wo9k6IAIBAnAozTEW2"
};
module.exports = exports;
