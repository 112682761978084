import { createEvent, createStoreObject, Store } from "effector";
import { FormState } from "@/components/form";
import { getPaymentStagesTotal, LotInfo, LotInfoStore, setPaymentTerm, setReviewDocumentsTerm, setSendDocumentsTerm } from "./lotInfo";
import { LotRelatedData, mockPersons, RelatedDataStore } from "./relatedData";
import { LotPurchasePerUnit, PurchasePerUnitStore } from "./purchasePerUnit";
import { getTotalPaymentAmount, LotPlannedPayments, PlannedPaymentsStore } from "./plannedPayments";
import { LotProvisionF, LotProvisions, ProvisionsStore, provisionToTemplate } from "./provisions";
import { LawType, SmpType } from "@/models/enums";
import {
    calculateLotVisibleStatus,
    ContractType,
    Lot,
    LotTemplate,
    PaymentStage,
    SpecialPurchaseType
} from "@/models/ComposedLots";
import { LotProvision } from "@/models/ComposedLots/LotProvision";
import { uuid } from "@/models/parsing";
import { getLotById, getLotHistory, getLotProductSpecifications, getLotSpecifications } from "@/api/ComposedLots";
import { AdditionalInfoStore, LotAdditionalInfo } from "@/views/ComposedLot/shared/store/additionalInfo";
import { LotResponsibilityInfo, ResponsibilityInfoStore } from "@/views/ComposedLot/shared/store/responsibilityInfo";
import {
    AdditionalRequirementsStore,
    LotAdditionalRequirements
} from "@/views/ComposedLot/shared/store/additionalRequirements";
import { LotSpecifications } from "@/views/ComposedLot/shared/store/specifications";
import { getGlossaryItems, GlossaryStore, LotGlossary } from "@/views/ComposedLot/shared/store/glossary";
import { DocumentsStore, LotDocuments } from "@/views/ComposedLot/shared/store/documents";
import { HeadStore, LotHead } from "@/views/ComposedLot/shared/store/head";
import { CriteriaStore, LotCriteria, setAssessmentOrder } from "@/views/ComposedLot/shared/store/criteria";
import Decimal from "decimal.js";
import { getLotDocuments } from "@/api/directory/documents";
import {
    getActTypes,
    listLegalActs,
    LotLegalActs,
    LotLegalActsStore
} from "@/views/ComposedLot/shared/store/legalActs";
import { LotMinimalRequirements, MinimalRequirementsStore } from "@/views/ComposedLot/shared/store/minimalRequirements";
import { vAssert, vCheck, vDefined, vNotEmpty, vNotEmptyWhen } from "@/api/validation";
import { HandleableError, SafeFnFactory } from "@/reactUtils";
import {
    AdditionalRequirementsSections,
    AdditionalRequirementsSections2022
} from '../../edit/tabs/additional-info/AdditionalRequirementsData';
import { Minus } from "@/components/layouts/Common";
import Contracts from "@/api/Contracts";
import { getProcedure } from "@/api/Procedures2020";
import { HistoryStore } from "@/views/ComposedLot/shared/store/history";
import { LotPurchaseCategories, PurchaseCategoriesStore } from "@/views/ComposedLot/shared/store/purchaseCategories";
import { PurchaseCategory } from "@/models/ComposedLots/PurchaseCategory";
import { addDays } from "date-fns";
import { compareSupplyStageByDate } from "@/models/ComposedLots/specifications";
import {
    PaymentTermType,
    ReviewDocumentsTermType,
    SendDocumentsTermType
} from "@/models/ComposedLots/PaymentStageTerm";
import { toJS } from "mobx";
import {isAggregatedLot} from "@/miscUtils";

export interface LotForm {
    head: LotHead
    info: LotInfo
    purchasePerUnit: LotPurchasePerUnit
    plannedPayments: LotPlannedPayments
    provisions: LotProvisions
    relatedData: LotRelatedData
    additionalInfo: LotAdditionalInfo
    responsibilityInfo: LotResponsibilityInfo
    additionalRequirements: LotAdditionalRequirements
    specifications: typeof LotSpecifications
    glossary: LotGlossary
    documents: LotDocuments
    criteria: LotCriteria
    legalActs: LotLegalActs
    minimalRequirements: LotMinimalRequirements
    historyStore: HistoryStore
    purchaseCategories: LotPurchaseCategories
    productSpecifications: typeof LotSpecifications
}

export type FormAction = "creating" | "viewing" | "editing";
export interface SectionProps {
    formAction: FormAction
    formStore: Store<LotForm>
    makeSafe: SafeFnFactory<void>
    error: HandleableError
}
export const actionToState = (a: FormAction): FormState => {
    switch (a) {
        case "editing":
        case "creating": return "enabled";
        case "viewing": return "readonly";
    }
};

export const replaceLot = createEvent<Lot>("replace lot");

const convertProvision = (l: LotProvision): LotProvisionF => ({
    used: l.used,
    acceptor: l.acceptor,
    account: l.account,
    order: l.additionalInfo ?? "",
    percent: l.percent,
    minimalTerm: l.minimalTerm,
    specialOrder: !!l.additionalInfo,
    chargedWarranty: l.chargedWarranty,
    warrantyServiceReq: l.warrantyServiceReq,
    manufacturersWarrantyReq: l.manufacturersWarrantyReq,
    warrantyTerm: l.warrantyTerm,
});

const del = <T, K extends keyof T>(x: T, k: K): Minus<T, { [k in K]: unknown }> => {
    const copy = { ...x };
    delete copy[k];
    return copy;
};

replaceLot.watch(l => {
    const tabs = {
            infoChanged: false,
            specsChanged: false,
            docsChanged: false,
            addsChanged: false
    };
    HeadStore.replace({
        ...l,
        instance: l,
        visibleStatus: calculateLotVisibleStatus(l),
        procedureId: l.procedureId,
        cancellationReason: l.cancellationReason,
        performer: l.performer,
        customer: l.customer,
        mainCustomer: l.mainCustomer,
        includedLots: l.includedLots,
        ...tabs,
    });

    LotInfoStore.replace({
        ...del(l, "basicInfo"),
        ...l.basicInfo,
        singleProviderReasonId: l.singleProviderReason?.id
    });
    PlannedPaymentsStore.replace({ sources: l.plannedPayments });
    PurchasePerUnitStore.replace({ enabled: l.purchasePerUnit });
    ProvisionsStore.replace({
        contract: convertProvision(l.contractProvision),
        request: convertProvision(l.requestProvision),
        guarantee: convertProvision(l.guaranteeProvision),
    });
    MinimalRequirementsStore.replace({ text: l.minimalRequirements });

    AdditionalInfoStore.replace(l.additionalPurchaseInfo);

    const reqs = l.additionalRequirements;
    const data = reqs?.supplierRequirementsIs2022 ? AdditionalRequirementsSections2022[l.law] : AdditionalRequirementsSections[l.law];
    const section = data.find(x => x.title === reqs.section) ?? data[0];

    AdditionalRequirementsStore.replace({
        hasAdditionalRequirements: !!reqs.section && !reqs?.supplierRequirementsIs2022,
        selectedOption: section.title,
        hasAdditionalSupplierRequirements: !!reqs.supplierRequirements,
        hasAdditionalRequirements2022: !!reqs?.section && reqs?.supplierRequirementsIs2022,
        supplierRequirements: reqs.supplierRequirements,
        supplierRequirementsSubstantiation: reqs.supplierRequirementsSubstantiation,
        options: reqs.options
    });

    setAssessmentOrder(l.criteriaAssessmentOrder);
});

const loadLot = async (id: uuid) => {
    // TODO: exception handling
    const lot = await getLotById(id);
    const docs = await getLotDocuments(id);

    const specs = await getLotSpecifications(id);
    const productSpecs = await getLotProductSpecifications(id);

    const terms = await getGlossaryItems({count: 100, from: 0, filters: {}}).then(x => x.items);
    const legalActs = await listLegalActs({count: 100, from: 0, filters: {}}).then(x => x.items);
    const legalActTypes = await getActTypes({count: 100, from: 0, filters: {}}).then(x => x.items);
    const history = await getLotHistory(id);

    replaceLot({...lot, paymentStages: lot.paymentStages.map(p => ({ ...p, specification: specs.find(x => x.supplyStages.some(s => s.volume.eq(p.supplyStage.volume)))}))});
    HistoryStore.replace({historyItems: history});
    LotSpecifications.replace(specs);
    LotSpecifications.replaceProducts(productSpecs);
    DocumentsStore.replace({lotDocuments: docs.lotDocuments, lotRequestDocuments: docs.requestDocuments});
    CriteriaStore.replace({criteria: docs.lotCriteria, assessmentOrder: lot.criteriaAssessmentOrder});
    GlossaryStore.replace({glossaryItems: terms, selectedItems: docs.lotTerms});
    LotLegalActsStore.replace({attachedActs: docs.lotNpa, listedActs: legalActs, types: legalActTypes});
    ResponsibilityInfoStore.replace({
        ...lot.responsibilityInfo,
        clientResponsibleContractPerson: lot.responsibilityInfo?.clientResponsibleContractPerson ?? mockPersons[0],
        clientResponsiblePerson: lot.responsibilityInfo?.clientResponsiblePerson ?? mockPersons[0]
    });
    PurchaseCategoriesStore.replace({
        categories: lot.purchaseCategories
    });

    // todo: remove duplicated code
    AdditionalInfoStore.replace(lot.additionalPurchaseInfo);

    const reqs = lot.additionalRequirements;
    const data = reqs?.supplierRequirementsIs2022 ? AdditionalRequirementsSections2022[lot.law] : AdditionalRequirementsSections[lot.law];
    const section = data.find(x => x.title === reqs.section) ?? data[0];

    AdditionalRequirementsStore.replace({
        hasAdditionalRequirements: !!reqs.section && !reqs?.supplierRequirementsIs2022,
        selectedOption: section.title,
        hasAdditionalSupplierRequirements: !!reqs.supplierRequirements,
        hasAdditionalRequirements2022: !!reqs?.section && reqs?.supplierRequirementsIs2022,
        supplierRequirements: reqs.supplierRequirements,
        supplierRequirementsSubstantiation: reqs.supplierRequirementsSubstantiation,
        options: reqs.options
    });

    (async () => {
        if (lot.contractId)
            HeadStore.setRelatedContract(await Contracts.getById(lot.contractId));
    })().ignore;

    (async () => {
        if (lot.procedureId)
            HeadStore.setRelatedProcedure(await getProcedure(lot.procedureId));
    })().ignore;
};

type CreateLotOptions = {
    law: LawType
    financialLimitId?: string
    id?: uuid
};

export const createLotStore = ({ law, financialLimitId, id }: CreateLotOptions): Store<LotForm> => {
    const info = LotInfoStore.createStore(law);
    const plannedPayments = PlannedPaymentsStore.createStore(financialLimitId);

    const updatePaymentStages = (isProductSpec?: boolean): void => {
        if (isProductSpec) return; // TODO: разобраться и выяснить что такое продакт спецификейшнс, сейчас они перетирают пеймент стейджи
        const currentStore = info.getState();
        if (!currentStore.sendDocumentsTerm) {
            setSendDocumentsTerm("WORK_3_DAYS");
        }
        if (!currentStore.reviewDocumentsTerm) {
            setReviewDocumentsTerm("WORK_30_DAYS");
        }
        if (!currentStore.paymentTerm) {
            setPaymentTerm("WORK_7_DAYS");
        }
        // На редактировании или на добавлении спецификации мы должны не добавить новый этап поставки, а заменить их целиком!
        const paymentStages: PaymentStage[] = [];
        const specsToChange = isProductSpec ? LotSpecifications.productSpecificationForms : LotSpecifications.specificationForms;
        const p = { items: specsToChange.map(v => v.validated).flatMap(v => v ? [v] : []) };
        const getDaysFromTerm = (term?: SendDocumentsTermType | ReviewDocumentsTermType | PaymentTermType | null): number => {
            if (!term) return 0;
            const map = {
                NORMAL_3_DAYS: 3,
                WORK_3_DAYS: 3,
                WORK_7_DAYS: 7,
                WORK_9_DAYS: 9,
                WORK_30_DAYS: 30,
            };
            return map[term];
        };

        // Один этап поставки в кажджой спецификации соответствует одному этапу оплаты
        p.items.forEach(specification => {
            specification.supplyStages.forEach(ss => {
                // Если у нас уже есть этот этап оплаты, то мы его дополняем
                const found = paymentStages.findIndex(ps => compareSupplyStageByDate(ss, ps.supplyStage));
                if (found !== -1) {
                    // TODO: как-нибудь дополняем
                    const old = paymentStages[found];
                    paymentStages[found] = {
                        ...old,
                        specifications: [...(old.specifications ?? []), specification]
                    };
                } else {
                    // или создаем новый
                    const paymentStage: PaymentStage = {
                        paymentDate: ss.type === "ABSOLUTE" ? addDays(ss.endDate, [currentStore.sendDocumentsTerm, currentStore.paymentTerm, currentStore.reviewDocumentsTerm].reduce((p, c) => p + getDaysFromTerm(c), 0) + 14) : undefined,
                        paymentDateOffset: ss.type === "RELATIVE" ? ss.endOffset + [currentStore.sendDocumentsTerm, currentStore.paymentTerm, currentStore.reviewDocumentsTerm].reduce((p, c) => p + getDaysFromTerm(c), 0) + 14 : undefined,
                        specifications: [specification],
                        supplyStage: { ...ss },
                        financialSourceUsage: []
                    };
                    paymentStages.push(paymentStage);
                }
            });
        });

        let shouldBeUpdated = false;

        // Сравниваем с предыдущим графиком оплаты. Если не поменялся - используем тот что был в сторе. Если поменялся - берем новый и теряем платежи.
        const old = currentStore.paymentStages;
        old.forEach(oldPaymentStage => {
            const found = paymentStages.findIndex(ps => compareSupplyStageByDate(oldPaymentStage.supplyStage, ps.supplyStage));
            if (found !== -1) {
                paymentStages[found] = {
                    ...paymentStages[found],
                    financialSourceUsage: oldPaymentStage.financialSourceUsage
                };
            }
        });

        // Сравнивать будем по сумме денег спецификаций во всех этапах оплаты, прибавляя единицу к каждой спецификации
        let countOld = 0;
        let countNew = 0;

        old.forEach(ps => ps.specifications?.forEach(spec => countOld = countOld + 1 + (spec.startPrice?.total.toNumber() ?? 0)));
        paymentStages.forEach(ps => ps.specifications?.forEach(spec => countNew = countNew + 1 + (spec.startPrice?.total.toNumber() ?? 0)));

        if (countNew !== countOld) shouldBeUpdated = true;
        if (old.length !== paymentStages.length) shouldBeUpdated = true;
        old.forEach(((ps, index) => {
            if (ps.paymentDate?.toISOString() !== paymentStages[index]?.paymentDate?.toISOString()) shouldBeUpdated = true;
            if (ps.paymentDateOffset !== paymentStages[index]?.paymentDateOffset) shouldBeUpdated = true;
        }));

        // Также необходимо проставить сроки оплаты если у нас их по какой-то причине нет
        const paymentTerm = currentStore.paymentTerm ?? "WORK_7_DAYS";
        const reviewDocumentsTerm = currentStore.reviewDocumentsTerm ?? "NORMAL_3_DAYS";
        const sendDocumentsTerm = currentStore.sendDocumentsTerm ?? "WORK_30_DAYS";

        const result = { ...currentStore, paymentTerm, reviewDocumentsTerm, sendDocumentsTerm };

        if (shouldBeUpdated) result.paymentStages = paymentStages;

        // Проверяем на расхождение id у financialSourceUsage.sourceId и plannedPayments
        result.paymentStages.forEach((ps, i) => {
            ps.financialSourceUsage.forEach(fsu => {
                const foundSource = plannedPayments.getState().sources.find(pp => pp.source?.id === fsu.sourceId);
                // Задаем sourceId от первого если ничего не нашлось
                if (!foundSource) {
                    console.warn(`
                    sourceId mismatch at paymentStages[${i}]
                    Included sourceIds: [${ps.financialSourceUsage.map(v => v.sourceId).join(",")}]
                    Available sourceIds: [${plannedPayments.getState().sources.map(v => v.source?.id).join(",")}]
                    `);
                    if (plannedPayments.getState().sources.length) {
                        fsu = {  ...fsu, sourceId: plannedPayments.getState().sources[0]?.source.id };
                    }
                }
            });
        });

        LotInfoStore.replace(result);
    };

    if (LotSpecifications.onUpdateSpecification) LotSpecifications.onUpdateSpecification = null;
        LotSpecifications.onUpdateSpecification = updatePaymentStages; // выяснить откуда брался LotInfo в параметрах //из info.getState()

    const combined = createStoreObject( {
        head: HeadStore.createStore(),
        info,
        relatedData: RelatedDataStore.createStore(),
        purchasePerUnit: PurchasePerUnitStore.createStore(),
        plannedPayments,
        provisions: ProvisionsStore.createStore(),
        additionalInfo: AdditionalInfoStore.createStore(),
        responsibilityInfo: ResponsibilityInfoStore.createStore(),
        additionalRequirements: AdditionalRequirementsStore.createStore(),
        specifications: LotSpecifications,
        productSpecifications: LotSpecifications,
        glossary: GlossaryStore.createStore(),
        documents: DocumentsStore.createStore(),
        criteria: CriteriaStore.createStore(),
        legalActs: LotLegalActsStore.createStore(),
        minimalRequirements: MinimalRequirementsStore.createStore(),
        historyStore: HistoryStore.createStore(),
        purchaseCategories: PurchaseCategoriesStore.createStore()
    });

    HeadStore.observeOnCombinedStore(combined);
    LotInfoStore.observeOnCombinedStore(combined);
    RelatedDataStore.observeOnCombinedStore(combined);
    PurchasePerUnitStore.observeOnCombinedStore(combined);
    PlannedPaymentsStore.observeOnCombinedStore(combined);
    ProvisionsStore.observeOnCombinedStore(combined);
    AdditionalInfoStore.observeOnCombinedStore(combined);
    ResponsibilityInfoStore.observeOnCombinedStore(combined);
    AdditionalRequirementsStore.observeOnCombinedStore(combined);
    GlossaryStore.observeOnCombinedStore(combined);
    DocumentsStore.observeOnCombinedStore(combined);
    CriteriaStore.observeOnCombinedStore(combined);
    LotLegalActsStore.observeOnCombinedStore(combined);
    MinimalRequirementsStore.observeOnCombinedStore(combined);
    HistoryStore.observeOnCombinedStore(combined);
    PurchaseCategoriesStore.observeOnCombinedStore(combined);

    if (id) loadLot(id).ignore;

    return combined;
};

export const formToTemplate = (state: LotForm, isNewLot: boolean, draft?: boolean): LotTemplate => {
    const isMedicines = state.info.specialPurchaseType === "MEDICINES";
    const isSmallVolume = state.info.specialPurchaseType !== "NO" && !isMedicines;
    const isMaster = state.info.lotJoinType === "MASTER";
    const requestProvisionPercentValue = Boolean(state.provisions.request.used && state.provisions.request.percent?.equals(0));
    const contractProvisionPercentValue = Boolean(state.provisions.contract.used && state.provisions.contract.percent?.equals(0));
    const guaranteeProvisionPercentValue = Boolean(state.provisions.guarantee.used && state.provisions.guarantee.percent?.equals(0));

    const isQuotationSession = false;
    const isSingleProviderPurchaseUnder600k = state.info.singleProviderReasonId === 1 || state.info.singleProviderReasonId === 2;
    const isAggregated = isQuotationSession || isSingleProviderPurchaseUnder600k;

    const subjectClassRequired = state.info.lotJoinType !== "MASTER" && !isSmallVolume && !isAggregated;

    const paymentStagesTotal = getPaymentStagesTotal(state.info.paymentStages);
    // TODO calc total price
    const totalPriceSpecification = state.specifications.specificationForms.map(s => s.startPrice?.total ?? new Decimal(0)).reduce((v, s) => s.add(v), new Decimal(0));

    const isPaymentStagesFilled = paymentStagesTotal.paymentAmount.eq(totalPriceSpecification);

    const startPrice = getTotalPaymentAmount(state.plannedPayments.sources);
    const requestProvisionPercent = state.provisions.request.percent;

    const isRequestPercentValidLow = Boolean(state.provisions.request.used &&
        requestProvisionPercent?.gte(0.5) && requestProvisionPercent?.lte(1));
    const isRequestPercentValidHigh = Boolean(state.provisions.request.used &&
        requestProvisionPercent?.gte(0.5) && requestProvisionPercent?.lte(5));

    const isSpecValid = () => {
        const specForms = state.specifications.specificationForms;
        const stagesNotEmpty = specForms.every(spec => spec.supplyStages.length !== 0);
        const validStages = specForms.every(spec => spec.supplyStages.every(ss => ss.isStageWithoutAddressValid));
        return (stagesNotEmpty && validStages) || isNewLot || isAggregatedLot(state.info.law, state.info.providerSelectionType, state.info.singleProviderReasonId);
    };

    const isSpecStageAddressValid = () => {
        return state.specifications.specificationForms.every(spec => spec.supplyStages.every(ss => ss.isStageAddressValid))
                || isNewLot || isAggregatedLot(state.info.law, state.info.providerSelectionType, state.info.singleProviderReasonId);
    };

    const asserted = draft
        ? {
            payments: state.plannedPayments.sources,
            subject: state.info.subject,
            providerSelectionType: state.info.providerSelectionType,
            contractType: state.info.contractType,
            subjectClassId: state.info.subjectClass?.id,
            requestProvisionPercent: undefined,
            contractProvisionPercent: undefined,
            guaranteeProvisionPercent: undefined,
            requestProvisionPercentLowPrice: undefined,
            requestProvisionPercentHighPrice: undefined,
            paymentStagesTotal: undefined,
            plannedPaymentTotal: undefined,
            subjectClassFromSecondLevel: undefined
        }
        : vAssert({
            payments: vNotEmptyWhen(!isMaster, state.plannedPayments.sources, "Не указано финансирование"),
            subject: vNotEmpty(state.info.subject, "Заполните \"Предмет закупки\""),
            providerSelectionType: vDefined(state.info.providerSelectionType, "Заполните \"Способ определения поставщика\""),
            contractType: vNotEmptyWhen(!isSmallVolume && !isAggregated ,state.info.contractType, "Заполните \"Тип контракта\""),
            subjectClassId: vNotEmptyWhen(subjectClassRequired && !isAggregated, state.info.subjectClass?.id, "Укажите КПГЗ"),
            requestProvisionPercent: vCheck(!requestProvisionPercentValue, 'Заполните "Размер обеспечения заявки (%)"'),
            contractProvisionPercent: vCheck(!contractProvisionPercentValue,    'Заполните "Размер обеспечения исполнения контракта (%)"'),
            guaranteeProvisionPercent: vCheck(!guaranteeProvisionPercentValue, 'Заполните "Размер обеспечения исполнения гарантийных обязательств (%)"'),
            requestProvisionPercentLowPrice: vCheck(state.provisions.request.used && startPrice.lte(20e6) && state.info.law === "F44"
                    ? isRequestPercentValidLow
                    : true,
                "Размер обеспечения заявки должен составлять от 0.5% до 1% начальной (максимальной) цены контракта"),
            requestProvisionPercentHighPrice: vCheck(state.provisions.request.used && startPrice.gt(20e6)
                && state.info.law === "F44" && state.info.plannedNotificationPublishYear > 2021
                    ? isRequestPercentValidHigh
                    : true,
                "Размер обеспечения заявки должен составлять от 0.5% до 5% начальной (максимальной) цены контракта"),
            paymentStagesTotal: vCheck(isAggregated || isPaymentStagesFilled || !isSmallVolume || isNewLot,
                "Сумма процентов из этапов оплаты должна равняться 100%"),

            plannedPaymentTotal: vCheck( isSmallVolume || state.info.lotJoinType === "SLAVE" ||  isNewLot || state.info.law === LawType.F223 || paymentStagesTotal.paymentAmount.eq(startPrice),
                "Общая сумма по источникам финансирования во всех этапах оплаты должна быть равна общей сумме финансирования лота"),

            subjectClassFromSecondLevel:
                vCheck((state.info.subjectClass?.code?.split(".")?.length ?? 0) > 1 || isSmallVolume || isMaster || isAggregated,
                    "Может быть выбрана позиция КПГЗ 2-го уровня и ниже"),
            specifications: vCheck(isSpecValid(), "Необходимо добавить график поставки"),
            stageAddress: vCheck(isSpecStageAddressValid(), "Для каждого графика поставки должен быть указан адрес"),
        });

    const payments = state.plannedPayments.sources.map(x => ({
        sourceId: x.source.id,
        amount: x.amount
    }));

    const calcPercent = (type: SmpType): Decimal => {
        switch (type) {
            case "NO":
                return new Decimal(0.00);
            case "FULL":
                return new Decimal(100.00);
            default:
                return new Decimal(0.01);
        }
    };

    const specialPurchaseContractType = (type: SpecialPurchaseType): ContractType => {
        switch (type) {
            case "SMALL_VOLUME_SIMPLE":
                return "GOODS_PROVISION";
            case "BUILDING_MAINTENANCE":
                return "WORK_EXECUTION";
            case "TEACHING_SERVICE":
                return "SERVICE_PROVISION";
            case "BUSINESS_TRIPS":
                return "WORK_EXECUTION";
            default:
                return "GOODS_PROVISION";
        }
    };

    return {
        basicInfo: {
            ...state.info,
            subject: state.info.subject,
            providerSelectionType: asserted.providerSelectionType!,
            advancePercent: state.info.advancePercent ?? new Decimal(0),
            smpPercent: state.info.smpPercent ?? calcPercent(state.info.smpType),
            contractType: asserted.contractType ?? specialPurchaseContractType(state.info.specialPurchaseType),
            isMonopoly: state.head.instance?.basicInfo.isMonopoly ?? false
        },
        singleProviderId: state.info.singleProvider?.id,
        singleProviderReasonId: state.info.singleProviderReasonId,
        subjectClassId: asserted.subjectClassId,
        purchasePerUnit: state.purchasePerUnit.enabled,
        contractProvision: provisionToTemplate(state.provisions.contract),
        requestProvision: provisionToTemplate(state.provisions.request),
        guaranteeProvision: provisionToTemplate(state.provisions.guarantee, state.info.law, draft),
        plannedPayments: payments,
        minimalRequirements: state.minimalRequirements.text,
        performerId: state.head.performer?.id,
        customerId: state.head.customer?.id,
        mainCustomerId: state.head.mainCustomer?.id,
        includedLotIds: state.head.includedLots.map(x => x.id),
        purchaseCategories: state.purchaseCategories.categories.map(PurchaseCategory.toDto),
        paymentStages: state.info.paymentStages.map(PaymentStage.toTemplate)
    };
};
