import React, { FC } from "react";
import {Card} from "@/components/Card";
import {Column, Table} from "@/components/table";
import {SectionProps} from "@/views/Procedures2020/view/store";
import {format} from "date-fns";
import {simulateDownload} from "@/miscUtils";
import {Button} from "@/components/primitive";
import {ProcedureProtocol} from "@/models/Procedures2020/Procedure";
import {useStore} from "effector-react";
import {getProcedure, getProtocol} from "@/api/Procedures2020";
import {ProcedureProtocolsStore} from "@/views/Procedures2020/view/store/protocols";

export const ProtocolsSection: FC<SectionProps> = x => {

    const store = useStore(x.formStore);
    const protocols = store.protocols.procedureProtocols;

    const handleGetProtocol = async () => {
        if (store.head.id) {
            const procedure = await getProcedure(store.head.id);
            const res = await getProtocol(store.head.id, procedure);
            if (res.protocols?.length) ProcedureProtocolsStore.setProcedureProtocols(res.protocols);
        }
    };

    const simulateXMLDownload = () => simulateDownload("protocol.xml", "data:application/xml;base64,UEsFBgAAAAAAAAAAAAAAAAAAAAAAAA==");

    const title = (
        <div style={{width: "100%", display: "flex", alignItems: "center", justifyContent: "space-between"}}>
            <span>Протоколы закупки</span>
            {store.head.law === "F223" && !protocols.length && <Button color="green" title="Получить протоколы" onClick={handleGetProtocol} />}
        </div>
    );

    const columns: Column<ProcedureProtocol>[] = [
        Table.Column("Название", x => <>{x.item.name}</>),
        Table.Column("Статус", x => <>{x.item.status}</>),
        Table.Column("Дата подписания", x => <>{format(new Date(x.item.signingDate), "dd.MM.yyyy HH:mm")}</>),
        Table.Column("Дата публикации", x => <>{format(new Date(x.item.publishDate), "dd.MM.yyyy HH:mm")}</>),
        Table.Column("Действие", () => <></>),
        Table.Column("Сведение от ЕИС", () => <span style={{
            textDecoration: "underline",
            color: "#428bca",
            cursor: "pointer"
        }} onClick={simulateXMLDownload}>XML</span>),
    ];

    const errorMessage = (
        <Card title={title}>
            <span style={{backgroundColor: "#F5F5DC", color: "brown", padding: 15, textAlign: "center"}}>
                {`Отсутствуют протоколы по данной закупке. Для их получения необходимо нажать на кнопку "Получить протоколы" в блоке "Протоколы закупки".`}
            </span>
        </Card>
    );

    return (
        protocols.length > 0 ?
            <Card title={title}>
                <Table dataset={protocols} columns={columns} />
            </Card>
            : errorMessage
    );
};
