import React from "react";
import {Card} from "@/components/Card";
import {Field, Fields} from "@/components/form/Field";
import {Label, CheckBox} from "@/components/primitive";
import {Table} from "@/components/table";

export const SuppliersStatisticalCodes: React.FC = x => {
    type Template = { template: string };
    const dataset: Template[] = [{ template: "Производство санитарно-технических работ, монтаж отопительных систем и систем кондиционирования воздуха" }];

    return <Card>
        <Fields orientation="horizontal">
            <Field title="ОКПО">
                <Label>02350520</Label>
            </Field>
            <Field title="ОКАТО">
                <Label>Царицыно</Label>
            </Field>
            <Field title="ОКТМО">
                <Label>Муниципальный округ Царицыно</Label>
            </Field>
            <Field title="ОКФС">
                <Label>

                </Label>
            </Field>
            <Field title="ОКОПФ">
                <CheckBox value={false} />
            </Field>
            <Field title="ОКСМ">
                <Label>643</Label>
            </Field>
            <Field title="ОКВЭД">
                <Table<Template> hideHeaders dataset={dataset} columns={[
                    Table.AutoColumn("Код", x =>
                    <Label>
                        43.22
                    </Label>),
                    Table.AutoColumn("Описание", x =>
                        <Label>
                            {x.item.template}
                        </Label>)
                ]} />
            </Field>
        </Fields>
    </Card>;
};