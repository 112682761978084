import {Card} from "@/components/Card";
import {HStack} from "@/components/layouts";
import {Button} from "@/components/primitive";
import React from "react";

export const DocumentsSections = () => {
    return <Card>
        <HStack>
            <Button color="green" title="Добавить" />
        </HStack>
    </Card>;
};
