import { SubjectClass } from "@/models/SubjectClass";
import SubjectClasses from "@/api/SubjectClasses";
import { action, computed, makeObservable, observable } from "mobx";

type Item = {
    item: ISubjectClassContainer
    actualIndex: number
    parent: Item | undefined
    collapsed: boolean
    children: Item[]
};

export interface ISubjectClassSelectionModalArgs {
    subjectClass: SubjectClass | null
    root: SubjectClass | null
}

export interface ISubjectClassContainer {
    id: number
    object: SubjectClass
    collapsed: boolean
    selected: boolean
    level: number
}

export class SubjectClassFilterStore {
    constructor() {
        makeObservable(this);
        this.loadAll();
    }

    @action
    toggleCollapseItem = (index: number) => {
        this.entries[index] = { ...this.entries[index], collapsed: !this.entries[index].collapsed };
    };

    @action
    selectEntry = (entry: ISubjectClassContainer | null) => {
        this.selectedEntry = entry;
    };

    public loadedClasses: ISubjectClassContainer[] | null = null;

    private prop: ISubjectClassSelectionModalArgs = { subjectClass: null, root: null };
    @observable
    public entries: ISubjectClassContainer[] = [];
    @observable
    public selectedEntry: ISubjectClassContainer | null = null;
    @observable
    public filter = "";

    @action private resetItemsCollapse = () =>
        this.entries = this.entries.map(e => ({ ...e, collapsed: !this.filter || this.filter.length < 3 }));

    @action
    public setFilter = (value: string) => {
        this.filter = value;
        this.resetItemsCollapse();
    };

    private loadAll = () => {
        const code = this.prop.subjectClass ? this.prop.subjectClass.code : "INVALID";
        const rootCode = this.prop.root ? this.prop.root.code : "";

        if (this.loadedClasses) {
            this.entries = this.loadedClasses.filter(x => x.object.code.startsWith(rootCode.split(".")[0]));
            for (const entry of this.entries) {
                entry.collapsed = !code.startsWith(entry.object.code) || code === entry.object.code;
                entry.selected = this.prop.subjectClass ? this.prop.subjectClass.id === entry.id : false;
            }
        } else {
            SubjectClasses.getAll().then(this.onSubjectClassesLoad);
        }
    };

    @action
    private onSubjectClassesLoad = (response: SubjectClass[]) => {
        const code = this.prop.subjectClass ? this.prop.subjectClass.code : "INVALID";
        const rootCode = this.prop.root ? this.prop.root.code : "";

        this.loadedClasses = response
            .sort((x, y) => (x.code > y.code ? 1 : -1))
            .map(x => ({
                id: x.id,
                object: x,
                collapsed: !code.startsWith(x.code) || code === x.code,
                selected: this.prop.subjectClass ? this.prop.subjectClass.id === x.id : false,
                level: x.code.split(".").length - 1,
            }));
        this.entries = this.loadedClasses.filter(x => x.object.code.startsWith(rootCode.split(".")[0]));
    };

    private recursiveCollapseCheck: (x: Item | undefined) => boolean = x => x === undefined ? true : !(x.parent ? x.parent.collapsed : false) && this.recursiveCollapseCheck(x.parent);

    private recursiveFilterCheck: (item: Item, filteredIds: number[], treatFilteredAsFailed: boolean) => boolean = (item, filteredIds, treatFilteredAsFailed) => {
        if (item === undefined) {
            return false;
        } else {
            const found = filteredIds.indexOf(item.item.id) !== -1;
            if (found && !treatFilteredAsFailed) {
                return true;
            }

            if (!found && treatFilteredAsFailed) {
                return true;
            }

            const hasChildrenFiltered = !!item.children.find(x => this.recursiveFilterCheck(x, filteredIds, treatFilteredAsFailed));

            return hasChildrenFiltered;
        }
    };

    @computed public get filteredDataset() {
        if (!this.entries.length) {
            return [];
        }

        const dataset = this.entries;

        const filter = {
            raw: this.filter,
            lowercased: this.filter.toLowerCase()
        };

        const result = dataset.reduce(
            (state, value) => SubjectClassFilterStore.treeVisitor(state, value, filter),
            emptyTraversalState());

        const { filtered, failedFilter, ordered } = result;

        const failedPrefilterChosen = failedFilter.length < filtered.length;
        const preFiltered = (failedPrefilterChosen ? failedFilter : filtered).map(item => item.item.id);

        const filteredNonCollapsed = ordered.filter(x => this.recursiveFilterCheck(x, preFiltered, failedPrefilterChosen));
        const filteredAndCollapseChecked = filteredNonCollapsed.filter(x => this.recursiveCollapseCheck(x));

        return filteredAndCollapseChecked.map(x => ({ ...x, hasChildren: !!filteredNonCollapsed.find(o => o.parent === x) }));
    }

    private static treeVisitor = (state: TraversalState, e: ISubjectClassContainer, filter: Filter) => {
        const item: Item = { item: e, actualIndex: state.count, parent: state.prev, collapsed: e.collapsed, children: [] };
        if (state.curLevel < e.level) {
            if (state.prev !== undefined) {
                state.prev.children.push(item);
            }
        } else {
            const parent = SubjectClassFilterStore.getAncestor(state.prev, state.curLevel - e.level);

            item.parent = parent;
            if (parent !== undefined) {
                parent.children.push(item);
            }
        }

        state.count++;
        state.curLevel = e.level;
        state.prev = item;
        state.ordered.push(item);
        SubjectClassFilterStore.matchesSubjectClass(item.item, filter)
            ? state.filtered.push(item)
            : state.failedFilter.push(item);

        return state;
    };



    private static matchesSubjectClass = (item: { object: SubjectClass }, filter: Filter) => filter.raw.length < 3 ||
        item.object.description.toLowerCase().includes(filter.lowercased) ||
        item.object.code.startsWith(filter.raw);

    private static getAncestor = (item: Item | undefined, order: number): Item | undefined => {
        if (order === -1) {
            return item;
        }

        if (item === undefined) {
            return undefined;
        }

        return SubjectClassFilterStore.getAncestor(item.parent, order - 1);
    };
}

type TraversalState = {
    count: number
    prev: Item | undefined
    curLevel: number
    filtered: Item[]
    failedFilter: Item[]
    ordered: Item[]
};

const emptyTraversalState = (): TraversalState => ({
    count: 0,
    curLevel: 0,
    prev: undefined,
    filtered: [],
    failedFilter: [],
    ordered: []
});

type Filter = {
    raw: string
    lowercased: string
};