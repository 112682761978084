import React, {FC, useEffect, useState} from "react";
import {ModalFC, showModal} from "@/EventBus";
import {CardModal} from "@/components/modals/CardModal";
import {Expandee, HStack, VGrid, VStack} from "@/components/layouts";
import {Button, ComboBox, DecimalBox, IntBox, Label, TextBox} from "@/components/primitive";
import {Lot, LotStatus, ProviderSelectionType} from "@/models/ComposedLots";
import {
    createStoredRemoteDataset, Pager,
    SelectionTable,
    setFilterValue,
    StoredRemoteDataset,
    Table,
    useStoredRemoteDataset
} from "@/components/table";
import {getIncludableLots} from "@/api/ComposedLots";
import {uuid} from "@/models/parsing";
import {useStore} from "effector-react";
import {FilterConditionType, FilterValueType} from "@/api/http";
import {SmpType, SmpTypeStrings, toSelectOptions} from "@/models/enums";
import Decimal from 'decimal.js';
import {formatNumber} from "@/NumberFormatting";
import {PickAnyParticipantModal} from "@/modal-views/PickParticipantModal";
import {Participant} from "@/models";
import {Store} from "effector";
import {LotForm} from "@/views/ComposedLot/shared/store";

const FieldWithEraser: FC<{ label: string; erase: () => void; disabled?: boolean }> = x => {
    return <>
        <Label text={x.label} />
        { x.children }
        <HStack>
            <Button disabled={x?.disabled} icon="faEraser" color="green" onClick={x.erase} />
        </HStack>
    </>;
};

interface Filters {
    subject?: string
    year?: number
    customer?: Participant
    contractProvisionPercent?: Decimal
    requestProvisionPercent?: Decimal
    regNumber?: string
    smpType?: SmpType
    planId?: string
    status?: LotStatus
    providerSelectionType?: ProviderSelectionType
    totalPriceFrom?: Decimal
    totalPriceTo?: Decimal
}

const LotsFilters: FC<{ srds: StoredRemoteDataset<Lot, Filters>; formStore: Store<LotForm> }> = x => {
    const filterStore = x.srds.getState().filterStore;
    const filters = useStore(filterStore);

    const includedLots = x.formStore.getState().head.includedLots;

    const isFirstLotHasConProvisionPercent = includedLots[0]?.contractProvision?.used;
    const firstLotConProvisionPercent = isFirstLotHasConProvisionPercent ? includedLots[0]?.contractProvision?.percent : undefined;

    const isFirstLotHasReqProvisionPercent = includedLots[0]?.requestProvision?.used;
    const firstLotReqProvisionPercent = isFirstLotHasReqProvisionPercent ? includedLots[0]?.requestProvision?.percent : undefined;

    const canEditPercentFields = isFirstLotHasConProvisionPercent || isFirstLotHasReqProvisionPercent;

    const pickCustomer = async () => {
        const customer = await showModal(PickAnyParticipantModal, {});
        if (customer) {
            setFilterValue(filterStore, "customer", customer);
        }
    };

    useEffect(() => {
        isFirstLotHasConProvisionPercent && setFilterValue(filterStore, "contractProvisionPercent", firstLotConProvisionPercent);
        isFirstLotHasReqProvisionPercent && setFilterValue(filterStore, "requestProvisionPercent", firstLotReqProvisionPercent);
    }, [isFirstLotHasConProvisionPercent, isFirstLotHasReqProvisionPercent]);

    return <VGrid columns="1fr 2fr auto" spacing="10px" alignItems="center">
        <FieldWithEraser label="Наименование" erase={() => setFilterValue(filterStore, "subject", undefined)}>
            <TextBox onChange={x => setFilterValue(filterStore, "subject", x)} value={filters.subject} />
        </FieldWithEraser>
        <FieldWithEraser label="Заказчик" erase={() => setFilterValue(filterStore, "customer", undefined)}>
            <TextBox value={filters.customer?.fullName} readonly onClick={pickCustomer} />
        </FieldWithEraser>
        <FieldWithEraser label="Год" erase={() => setFilterValue(filterStore, "year", undefined)}>
            <IntBox onChange={x => setFilterValue(filterStore, "year", x)} value={filters.year} />
        </FieldWithEraser>
        <FieldWithEraser label="Процент обеспечения исполнения контракта" disabled={canEditPercentFields} erase={() => setFilterValue(filterStore, "contractProvisionPercent", undefined)}>
            <DecimalBox readonly={canEditPercentFields} onChange={x => setFilterValue(filterStore, "contractProvisionPercent", x)} value={!canEditPercentFields ? filters.contractProvisionPercent : firstLotConProvisionPercent} />
        </FieldWithEraser>
        <FieldWithEraser label="Процент обеспечения заявки" disabled={canEditPercentFields} erase={() => setFilterValue(filterStore, "requestProvisionPercent", undefined)}>
            <DecimalBox readonly={canEditPercentFields} onChange={x => setFilterValue(filterStore, "requestProvisionPercent", x)} value={canEditPercentFields ? filters.requestProvisionPercent : firstLotReqProvisionPercent } />
        </FieldWithEraser>
        <FieldWithEraser label="Тип размещения среди СМП" erase={() => setFilterValue(filterStore, "smpType", undefined)}>
            <ComboBox options={[{ key: undefined, desc: "" }, ...toSelectOptions(SmpTypeStrings)]}
                      allowVisibleUndefinedKey
                      value={filters.smpType} onChangeNonStrict={x => setFilterValue(filterStore, "smpType", x)} />
        </FieldWithEraser>
        <FieldWithEraser label="Реестровый номер" erase={() => setFilterValue(filterStore, "regNumber", undefined)}>
            <TextBox onChange={x => setFilterValue(filterStore, "regNumber", x)} value={filters.regNumber} />
        </FieldWithEraser>
        <FieldWithEraser label="Сумма закупки от" erase={() => {
            setFilterValue(filterStore, "totalPriceFrom", undefined);
            setFilterValue(filterStore, "totalPriceTo", undefined);
        }}>
            <VGrid columns="1fr auto 1fr" spacing="10px" alignItems="center">
                <DecimalBox onChange={x => setFilterValue(filterStore, "totalPriceFrom", x)} value={filters.totalPriceFrom} />
                <Label text="до"/>
                <DecimalBox onChange={x => setFilterValue(filterStore, "totalPriceTo", x)} value={filters.totalPriceTo} />
            </VGrid>
        </FieldWithEraser>
    </VGrid>;
};

const LotsTable: FC<{ srds: StoredRemoteDataset<Lot, Filters>; selected: Lot[]; select(lots: Lot[]): void; formStore: Store<LotForm>}> = x => {
    const rds = useStoredRemoteDataset(x.srds);
    const includedLots = x.formStore.getState().head.includedLots;
    const includedLotsIds = includedLots.map(lot => lot.id);

    const availableLots = rds.dataset.filter(lot => !includedLotsIds.includes(lot.id));

    return <>
        <SelectionTable<Lot> mode="multi" selected={x.selected} onChange={s => x.select(s)} dataset={availableLots} columns={[
            Table.Column("Реестровый номер", x => <>{x.item.regNumber}</>),
            Table.Column("Наименование", x => <>{x.item.basicInfo.subject}</>),
            Table.Column("Год", x => <>{x.item.basicInfo.plannedNotificationPublishYear}</>),
            Table.Column("Заказчик", x => <>{x.item.customer?.fullName}</>),
            Table.Column("Процент обеспечения исполнения контракта", x => <>{x.item.contractProvision.used ? formatNumber(x.item.contractProvision.percent) : ""}</>),
            Table.Column("НМЦ контракта, руб.", x => <>{formatNumber(x.item.totalPrice)}</>),
            Table.Column("СМП", x => <>{SmpTypeStrings[x.item.basicInfo.smpType]}</>),
        ]} />
        <Pager remoteDataset={rds} />
    </>;
};

export const PickIncludedLotsModal: ModalFC<{ lotId?: uuid; mainCustomerId: number; providerSelectionType?: ProviderSelectionType; formStore: Store<LotForm> }, Lot[]> = x => {
    const [srds] = useState(() => createStoredRemoteDataset<Lot, Filters>({
        pageSize: 10,
        async load(filters, from, count) {
            return await getIncludableLots(from, count, {
                subject: [{ type: FilterValueType.STRING, conditionType: FilterConditionType.CONTAINS, string: filters.subject }],
                year: [{ type: FilterValueType.LONGINT, conditionType: FilterConditionType.EQUAL, longint: filters.year }],
                customer: [{ type: FilterValueType.LONGINT, conditionType: FilterConditionType.EQUAL, longint: filters.customer?.id }],
                contractProvisionPercent: [{ type: FilterValueType.DECIMAL, conditionType: FilterConditionType.EQUAL, decimal: filters.contractProvisionPercent }],
                requestProvisionPercent: [{ type: FilterValueType.DECIMAL, conditionType: FilterConditionType.EQUAL, decimal: filters.requestProvisionPercent }],
                regNumber: [{ type: FilterValueType.STRING, conditionType: FilterConditionType.STARTS_WITH, string: filters.regNumber }],
                smpType: [{ type: FilterValueType.STRING, conditionType: FilterConditionType.EQUAL, string: filters.smpType }],
                planId: [{ type: FilterValueType.STRING, conditionType: FilterConditionType.EQUAL, string: filters.planId }],
                status: [{ type: FilterValueType.STRING, conditionType: FilterConditionType.EQUAL, string: filters.status }],
                providerSelectionType: [{ type: FilterValueType.STRING, conditionType: FilterConditionType.EQUAL, string: x.providerSelectionType ?? filters.providerSelectionType }],
                totalPrice: [
                    { type: FilterValueType.DECIMAL, conditionType: FilterConditionType.GREATER_THAN_OR_EQUAL, decimal: filters.totalPriceFrom },
                    { type: FilterValueType.DECIMAL, conditionType: FilterConditionType.LESS_THAN_OR_EQUAL, decimal: filters.totalPriceTo },
                ],
                mainCustomer: [{ type: FilterValueType.LONGINT, conditionType: FilterConditionType.EQUAL, longint: x.mainCustomerId }]
            }, x.lotId);
        }
    }));

    const [selected, setSelected] = useState<Lot[]>([]);

    const footer = <HStack spacing="10px">
        <Expandee/>
        <Button title="Выбрать" color="green" onClick={() => x.done(selected) } />
        <Button icon="gCross" color="red" onClick={() => x.done(undefined)} />
    </HStack>;

    return <CardModal title="Добавление лота" close={() => x.done(undefined)} width="60%" bottom={footer}>
        <VStack spacing="15px">
            <LotsFilters srds={srds} formStore={x.formStore} />
            <LotsTable srds={srds} selected={selected} select={setSelected} formStore={x.formStore} />
        </VStack>
    </CardModal>;
};