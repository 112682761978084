import { Component, Prop, Vue, Watch } from "vue-property-decorator";
import EventBus from './EventBus';
import LoginModal from './LoginModal.vue';
import {ReactWrapper} from "vuera";
import {LoginPage2} from "@/LoginPage2";

@Component({ components: { 'react-wrapper': ReactWrapper } })
export default class App extends Vue {
    @Prop() route!: "login" | "news";
    public loginPage = LoginPage2;
}
