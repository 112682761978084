
import React from "react";
import { PrimaryButton } from "./Buttons";
import styles from "./Buttons.module.css";

interface ContainerButtonsProps {
    showCreateContract: boolean
    showCreateSession: boolean
    showCreateJointSession: boolean
    onCreateSession: () => void
    onCreateJointSession: () => void
    onCreateContract?: () => void
    disableCreateSession: boolean
    disableCreateContract: boolean
    disableCreateJointSession: boolean
}

export const ContainerButtons = (props: ContainerButtonsProps) => <div className={styles.containerButtons}>
    {props.showCreateJointSession && <PrimaryButton filled onClick={props.onCreateJointSession} disabled={props.disableCreateJointSession}>
        Создать совместную КС
    </PrimaryButton>}
    {props.showCreateContract && <PrimaryButton filled onClick={props.onCreateContract} disabled={props.disableCreateContract}>
        Создать контракт/договор
    </PrimaryButton>}
    {props.showCreateSession && <PrimaryButton filled onClick={props.onCreateSession} disabled={props.disableCreateSession}>
        Создать КС
    </PrimaryButton>}
</div>;
