












import { MutatingFieldBase } from "@/components/vue/form-table/FieldBase";
import { mixins } from "vue-class-component";
import { Component, Prop } from "vue-property-decorator";
import { formatDate } from '@/DateFormatting';
import { DateRow } from './FormFields';

@Component
export default class DateField extends mixins<MutatingFieldBase<DateRow<unknown>>>(MutatingFieldBase) {
    @Prop({ default: "months" })
    public mode!: "days" | "months";

    @Prop({ default: () => ({ month: "numeric", year: "numeric" }) })
    public format!: Intl.DateTimeFormatOptions;

    public get formattedDate() { return formatDate(this.fieldValue as Date, this.row.type === "DATE_FIELD") }

    public change(value: Date) {
        this.$emit("set-value", { row: this.row, value });
    }
}
