import { EnumStrings } from "../EnumStrings";

export const enum ChargeSource {
    NONE = "NONE",
    PLEDGE = "PLEDGE",
    BANK_GUARANTEE = "BANK_GUARANTEE",
}

export const ChargeSourceStrings: EnumStrings = {
    [ChargeSource.BANK_GUARANTEE]: "Независимая гарантия",
    [ChargeSource.PLEDGE]: "Залог денежных средств",
};

export const enum PledgeSource {
    CASH = "CASH",
    DEPOSIT = "DEPOSIT",
}

export const PledgeSourceStrings: EnumStrings = {
    [PledgeSource.DEPOSIT]: "В форме вклада (депозита)",
    [PledgeSource.CASH]: "В форме денежных средств",
};
