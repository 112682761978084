import {http, IPagedRequest, prepareRequest} from "@/api/http";
import {IPagedItems} from "@/api/Pagination";
import {MediaItem} from "@/api/Media";

export type SupplementaryMaterialsArticleLabel = {
    color: string
    id: string
    title: string
};

export type SupplementaryMaterialsArticle = {
    createdAt: string // Instant
    description?: string | null
    media?: MediaItem | null
    label?: SupplementaryMaterialsArticleLabel | null
    title: string
    updatedAt: string // Instant
    id: string
};

export type SupplementaryMaterialsArticleUpdate = {
    id?: string
    labelId?: string
    title?: string
    description?: string | null
    mediaId?: string
};

export const convertSupplementaryMaterialsArticleToUpdate = (v: SupplementaryMaterialsArticle): SupplementaryMaterialsArticleUpdate => ({
    id: v.id,
    labelId: v.label?.id,
    title: v.title,
    description: v.description,
    mediaId: v.media?.id
});

export const SupplementaryMaterialsApi = {
    async create(data: SupplementaryMaterialsArticleUpdate): Promise<SupplementaryMaterialsArticle> {
        const result = await http.post("/api/v2/admin/supplementaryMaterials", data);

        return result;
    },
    async update(data: SupplementaryMaterialsArticleUpdate): Promise<SupplementaryMaterialsArticle> {
        const result = await http.post("/api/v2/admin/supplementaryMaterials/" + data.id, data);

        return result;
    },
    async remove(id: string): Promise<void> {
        const result = await http.delete("/api/v2/admin/supplementaryMaterials/" + id);

        return result;
    },
    async findAll(request: IPagedRequest): Promise<IPagedItems<SupplementaryMaterialsArticle>> {
        const result = await fetch("/api/v2/supplementaryMaterials?request=" + prepareRequest({ ...request, sortDescending: true, sortBy: "createdAt" })).then(r => r.json());
        console.log(`supplementaryMaterials request:`, {request}, "response:", {result});

        return result as IPagedItems<SupplementaryMaterialsArticle>;
    },
    async findLabels(): Promise<SupplementaryMaterialsArticleLabel[]> {
        const result = await fetch("/api/v2/supplementaryMaterials/labels").then(r => r.json());

        return result as SupplementaryMaterialsArticleLabel[];
    }};
