












import { Component, Prop, Vue } from "vue-property-decorator";
import { ValueFieldBase } from "@/components/vue/form-table/FieldBase";
import { mixins } from "vue-class-component";
import { PickRow } from "./FormFields";

@Component
export default class PickField extends mixins<ValueFieldBase<PickRow<unknown>>>(ValueFieldBase) {
    private emitFormPick() {
        if (this.enabled) this.$emit("form-pick", this.row.id, this.fieldValue);
    }

    private clearDocument() {}
}
