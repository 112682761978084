import React from "react";
import { DirectPurchasesStore } from "./store";
import { observer } from "mobx-react";
import { DirectCard } from "./DirectPurchaseCard";
import styles from "./DirectPurchaseTable.module.css";

export const DirectPurchaseTable = observer((props: { store: DirectPurchasesStore }) => {
    return <div className={`${props.store.showInfo ? styles.tableContentOpenTableInfo : ''} ${styles.tableContent}`}>
        {props.store.page.items.map(d => <DirectCard key={d.id} store={props.store} data={d} />)}
    </div>;
});