import { IJsonFormattable } from '@/models/IJsonFormattable';
import { IDtoTariff } from './json/IDtoTariff';
import Decimal from 'decimal.js';
import { Participant } from './Participant';

export class Tariff implements IJsonFormattable<IDtoTariff> {
    constructor(
        public id: number,
        public subjectDeclarationId: number,
        public priceWithTax: Decimal,
        public priceWithoutTax: Decimal,
        public tax: Decimal,
        public taxPercent: Decimal,
        
        public supplier: Participant,
        public zone: {
            id: number
            name: string
        }
    ) {}
        
    public toJson(): IDtoTariff {
        return {
            ...this,
            priceWithTax: this.priceWithTax.toString(),
            priceWithoutTax: this.priceWithTax.toString(),
            tax: this.priceWithTax.toString(),
            taxPercent: this.priceWithTax.toString(),
            supplier: this.supplier && this.supplier.toJson()
        };
    }
    
    public static fromJson(dto: IDtoTariff): Tariff {
        return Object.assign(Object.create(Tariff.prototype), {
            ...dto,
            priceWithTax: new Decimal(dto.priceWithTax),
            priceWithoutTax: new Decimal(dto.priceWithTax),
            tax: new Decimal(dto.priceWithTax),
            taxPercent: new Decimal(dto.priceWithTax),
            supplier: dto.supplier && Participant.fromJson(dto.supplier)
        });
    }
}
