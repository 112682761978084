import * as t from "io-ts";
import { subjectClass } from "./DirectPurchases";
import { http2 as http, IPagedRequest } from './http';
import { handleCodecErrors, handleErrors, pagedCodec } from './httpHelpers';

const serviceCodec = t.type({
    isFavorite: t.union([t.undefined, t.null, t.boolean]),
    id: t.number,
    subject: t.union([t.undefined, t.null, t.string]),
    subjectClass
});

const pageOfServicesCodec = pagedCodec(serviceCodec);

const pageOfUnknownCodec = pagedCodec(t.unknown);

export type Service = t.TypeOf<typeof serviceCodec>;

export type PageOfServices = t.TypeOf<typeof pageOfServicesCodec>;

export type PageOfUnknown = t.TypeOf<typeof pageOfUnknownCodec>;

export class ServicesController {
    public static async get(request: IPagedRequest, favorite: boolean) {
        return await http.getPagedServicesWithFavorite("/api/v2/directory/subjectDeclaration", request, favorite)
            .then(handleErrors)
            .then(result => pageOfServicesCodec.decode(result))
            .then(handleCodecErrors);
    }
    public static async setFavorite(id: number, favorite: boolean) {
        return await http.patch(`/api/v2/subjectDeclaration/${id}?isFavorite=${favorite ? 'true' : 'false'}`)
            .then(handleErrors)
            .then(result => pageOfServicesCodec.decode(result))
            .then(handleCodecErrors);
    }
}