import {WizardQuestion} from "@/views/ComposedLot/edit/tabs/lot-documents/DocumentsSection/wizard/tabs/Quiz";
import {WizardEditableField} from "@/views/ComposedLot/edit/tabs/lot-documents/DocumentsSection/wizard/tabs/Edit";

export const F44Questions: WizardQuestion[] = [
    {
        id: "q1",
        question: "Требуется ли размещение в качестве приложения к контракту спецификации товаров (с указанием цены за единицу товара на этапе заключения контракта)?",
        answers: [
            {id: "q1a1", text: "Да"},
            {id: "q1a2", text: "Нет"}
        ]
    },
    {
        id: "q2",
        question: "Каким образом производится оплата поставленного товаров по Контракту (наличие/отсутствие этапов)?",
        answers: [
            {id: "q2a1", text: "Ежеквартально"},
            {id: "q2a2", text: "По факту поставки каждой партии товаров"},
            {id: "q2a3", text: "Ежемесячно"},
            {id: "q2a4", text: "По факту поставки всего объема товаров"}
        ]
    },
    {
        id: "q3",
        question: "Каким образом осуществляется поставка товаров?",
        answers: [
            {id: "q3a1", text: "Поэтапно в соответствии с Графиком поставки товаров (Календарным планом)"},
            {id: "q3a2", text: "По заявкам"}
        ]
    },
    {
        id: "q4",
        question: "Будет ли производиться оплата по договору из средств субсидии?",
        answers: [
            {id: "q4a1", text: "Нет"},
            {id: "q4a2", text: "Да"}
        ]
    },
    {
        id: "q5",
        question: "Организационно-правовая форма учреждения ГБУ/ГКУ?",
        answers: [
            {id: "q5a1", text: "Нет"},
            {id: "q5a2", text: "Да"}
        ]
    },

];

export const F44Fields: WizardEditableField[] = [
    {
        id: "f1",
        name: "Размер обеспечения контракта при применении антидемпинговых мер в соответствии с требованием части 1 или 2 статьи 37 Закона о контрактной системе"
    }
];

export const F223Fields: WizardEditableField[] = [];

export const F223Questions: WizardQuestion[] = [
    {
        id: "q1",
        question: "Требуется ли размещение в качестве приложения к контракту спецификации товаров (с указанием цены за единицу товара на этапе заключения контракта)?",
        answers: [
            {id: "q1a1", text: "Да"},
            {id: "q1a2", text: "Нет"}
        ]
    },
    {
        id: "q2",
        question: "Каким образом производится оплата поставленного товаров по Контракту (наличие/отсутствие этапов)?",
        answers: [
            {id: "q2a1", text: "Ежеквартально"},
            {id: "q2a2", text: "По факту поставки каждой партии товаров"},
            {id: "q2a3", text: "Ежемесячно"},
            {id: "q2a4", text: "По факту поставки всего объема товаров"}
        ]
    },
    {
        id: "q3",
        question: "Каким образом осуществляется поставка товаров?",
        answers: [
            {id: "q3a1", text: "Поэтапно в соответствии с Графиком поставки товаров (Календарным планом)"},
            {id: "q3a2", text: "По заявкам"}
           ]
    },
    {
        id: "q4",
        question: "Начальная (максимальная) цена договора превышает 50 млн. рублей?",
        answers: [
            {id: "q4a1", text: "Да"},
            {id: "q4a2", text: "Нет"}
        ]
    },
    {
        id: "q5",
        question: "Будет ли производиться оплата по договору из средств субсидии?",
        answers: [
            {id: "q5a1", text: "Да"},
            {id: "q5a2", text: "Нет"}
        ]
    },
    {
        id: "q6",
        question: "Организационно-правовая форма учреждения ГБУ/ГКУ?",
        answers: [
            {id: "q6a1", text: "Да"},
            {id: "q6a2", text: "Нет"}
        ]
    }
];

export const EMQuestions: WizardQuestion[] = [
    {
        id: "q1",
        question: "Предусмотрен ли авансовый платёж договором?",
        answers: [
            {id: "q1a1", text: "Да"},
            {id: "q1a2", text: "Нет"}
        ]
    },
    {
        id: "q2",
        question: "Будет ли производиться оплата аванса в размере 100%?",
        answers: [
            {id: "q2a1", text: "Да"},
            {id: "q2a2", text: "Нет"}
        ]
    },
    {
        id: "q3",
        question: "Присутствует ли этапность оплаты по контракту?",
        answers: [
            {id: "q3a1", text: "Да"},
            {id: "q3a2", text: "Нет"}
        ]
    },
    {
        id: "q4",
        question: "Есть ли гарантийный срок?",
        answers: [
            {id: "q4a1", text: "Да"},
            {id: "q4a2", text: "Нет"}
        ]
    },
    {
        id: "q5",
        question: "Осуществляется ли оказание услуг для Пользователя?",
        answers: [
            {id: "q5a1", text: "Услуги оказываются для Пользователя"},
            {id: "q5a2", text: "Услуги оказываются для Заказчика"}
        ]
    },
    {
        id: "q6",
        question: "Предметом контракта являются услуги тех.заказчика в строительстве?",
        answers: [
            {id: "q6a1", text: "Да"},
            {id: "q6a2", text: "Нет"}
        ]
    },
    {
        id: "q7",
        question: "Требуется ли размещение в качестве приложения к контракту сметы услуг (с указанием цены за единицу услуг на этапе заключения контракта)?",
        answers: [
            {id: "q7a1", text: "Да"},
            {id: "q7a2", text: "Нет"}
        ]
    },

];

export const formContractQuestions: WizardQuestion[] = [
    {
        id: "q1",
        question: "Будет ли использоваться приложение Адресный перечень ?",
        answers: [
            {id: "q1a1", text: "Да"},
            {id: "q1a2", text: "Нет"}
        ]
    }
];

export const EMFields: WizardEditableField[] = [];

export const QuizData = {
    "F44": {
        questions: F44Questions,
        fields: F44Fields,
        documentName: "контракта"
    },
    "F223": {
        questions: F223Questions,
        fields: F223Fields,
        documentName: "договора"
    },
    "EM-F44": {
        questions: EMQuestions,
        fields: EMFields,
        documentName: "контракта"
    },
    "EM-F223": {
        questions: EMQuestions,
        fields: EMFields,
        documentName: "договора"
    },
    "CONTRACT-TEMPLATE": {
        questions: formContractQuestions,
        fields: EMFields,
        documentName: "конракта"
    }
};

export type QuizTypes = keyof typeof QuizData;