import { ContractFinancialSource } from "@/models/Contracts";
import { IDtoContractFinancialSource } from "@/models/json/Contracts";
import { Decimal } from "decimal.js";
import { IDtoContractFinancialSourceUsage } from '@/models/json/Contracts';
import { IJsonFormattable } from '@/models/IJsonFormattable';

export class ContractFinancialSourceUsage implements IJsonFormattable<IDtoContractFinancialSourceUsage> {
    constructor(
        public source: ContractFinancialSource,
        public stageAmount: Decimal,
        public advanceAmount: Decimal
    ) {}
    
    public toJson(): IDtoContractFinancialSourceUsage {
        return {
            source: this.source.toJson(),
            stageAmount: this.stageAmount.toString(),
            advanceAmount: this.advanceAmount && this.advanceAmount.isFinite() ? this.advanceAmount.toString() : null,
        };
    }
    
    public static fromJson(dto: IDtoContractFinancialSourceUsage): ContractFinancialSourceUsage {
        return Object.assign(Object.create(ContractFinancialSourceUsage.prototype), {
            source: ContractFinancialSource.fromJson(dto.source),
            stageAmount: new Decimal(dto.stageAmount),
            advanceAmount: dto.advanceAmount && new Decimal(dto.advanceAmount) || new Decimal(NaN),
        });
    }
}
