// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, "._1kSLdSCKV-7anPiZmD9F-p {\n    cursor: pointer;\n    border: 1px solid #4db2ff;\n    color: #4db2ff;\n    background-color: white;\n    display: inline-block;\n    height: 40px;\n    border-radius: 50px;\n    padding: 8px 29px;\n    font-size: 16px;\n    white-space: nowrap;\n    text-decoration: none;\n    box-sizing: border-box;\n    margin: 0px 10px;\n    line-height: 24px;\n}\n\n._2n4eff2vV1FyAO8oTtkxcE{\n    background-color: #4db2ff;\n    color: white;\n}\n\n._1qM6g36wZk8PJrYXGVkXZc {\n    opacity: 0.3;\n    pointer-events: none;\n}\n\n._1sozGuHjdBNuBV2gIyfG8s {\n    display: flex;\n    justify-content: space-between;\n    padding-left: 20px;\n    gap: 20px;\n}\n\n.h_r3VY-DkNlMy3uhMGxU2 {\n    border: 1px solid #ff6666;\n    color: #ff6666;\n    background-color: white;\n}\n\n.va9PSIlCIlSTnKDwnmOM8 {\n    background-color: #ff6666;\n    color: white;\n}\n\n._3BlI--jHSzCZU23zdWhFpx {\n    border: 1px solid #ffc366;\n    color: #ffc366;\n    background-color: white;\n}\n\n._2MQdT9vLxxw_3pc8UVNLu6 {\n    background-color: #ffc366;\n    color: white;\n}\n\n._3MosfFuuukRHQ-LX-4mhOk {\n    border: 1px solid #e97b39;\n    color: #e97b39;\n    background-color: white;\n}\n\n._1mmD-WMr5kdU2jcd1_3UOI {\n    background-color: #e97b39;\n    color: white;\n}\n\n._1PsGT7XUe2SmuWPSxvgl1d {\n    border: 1px solid #999;\n    color: #999;\n    background-color: white;\n}\n\n.-sulFT5iqkG__MkyYt-Mg {\n    background-color: #999;\n    color: white;\n}\n\n._1q4nntgpCfn6NEktL3OenT{\n    opacity: 0.3;\n    pointer-events: none;\n}\n", ""]);
// Exports
exports.locals = {
	"primaryButton": "_1kSLdSCKV-7anPiZmD9F-p",
	"primaryFilled": "_2n4eff2vV1FyAO8oTtkxcE",
	"primaryButtonDisabled": "_1qM6g36wZk8PJrYXGVkXZc _1kSLdSCKV-7anPiZmD9F-p",
	"containerButtons": "_1sozGuHjdBNuBV2gIyfG8s",
	"warning": "h_r3VY-DkNlMy3uhMGxU2",
	"warningFilled": "va9PSIlCIlSTnKDwnmOM8",
	"test": "_3BlI--jHSzCZU23zdWhFpx",
	"testFilled": "_2MQdT9vLxxw_3pc8UVNLu6",
	"copy": "_3MosfFuuukRHQ-LX-4mhOk",
	"copyFilled": "_1mmD-WMr5kdU2jcd1_3UOI",
	"cancel": "_1PsGT7XUe2SmuWPSxvgl1d",
	"cancelFilled": "-sulFT5iqkG__MkyYt-Mg",
	"disabled": "_1q4nntgpCfn6NEktL3OenT"
};
module.exports = exports;
