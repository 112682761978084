import React from "react";
import {ModalFC} from "@/EventBus";
import {Participant} from "@/models";
import {CardModal} from "@/components/modals/CardModal";
import {Label} from "@/components/primitive";

type ParticipantInfoProps = {
    participant: Participant
};

const ParticipantInfoItem = ({ title, info }: { title: string; info: string }) => <>
    <Label preset="readonlyValue">{title}</Label>
    <Label preset="readonlyValue">{info}</Label>
</>;

export const ParticipantInfo: ModalFC<ParticipantInfoProps, void> = ({ participant, done }) => {
    return <CardModal title="Информация об организаторе торгов" width="50%" close={done}>
        <div style={{
            display: "grid",
            gridTemplateColumns: "3fr 7fr",
            gridTemplateRows: "1fr repeat(11, min-content)",
            lineHeight: "1.5"
        }}>
            <ParticipantInfoItem title="Наименование" info={participant.fullName} />
            <ParticipantInfoItem title="Сокращенное наименование" info={participant.shortName} />
            <ParticipantInfoItem title="ИНН" info={participant.tin} />
            <ParticipantInfoItem title="КПП" info={participant.rrc} />
            <ParticipantInfoItem title="ОГРН" info="1037739376223" />
            <ParticipantInfoItem title="ОКПО" info="03324424" />
            <ParticipantInfoItem title="УНК" info="450037" />
            <ParticipantInfoItem title="Юридический адрес" info={participant?.address ?? ""} />
            <ParticipantInfoItem title="Фактический адрес" info={participant?.address ?? ""} />
            <ParticipantInfoItem title="Контактное лицо" info="Горшенин Сергей Сергеевич" />
            <ParticipantInfoItem title="Телефонный номер" info="+7 (495) 950-42-85" />
            <ParticipantInfoItem title="Электронная почта" info="gorshenin_ss@123.ru" />
        </div>
    </CardModal>;
};
