





















import { Component, Prop, Vue, Watch } from "vue-property-decorator";
import { MutatingFieldBase } from "@/components/vue/form-table/FieldBase";
import { IRow, DecimalRow, TextAlignment, MutatingTextInputRowBase } from './FormFields';
import Decimal from "decimal.js";
import { mixins } from "vue-class-component";
import { formatNumber, stringifyNumber } from "@/NumberFormatting";

@Component
export default class DecimalField extends mixins<MutatingFieldBase<DecimalRow<unknown> & MutatingTextInputRowBase<unknown>>>(MutatingFieldBase) {
    private decimalString = "";
    private suppressChange = false;
    @Prop({ default: TextAlignment.RIGHT }) private alignment!: TextAlignment;

    public get valueAsDecimal() { return this.fieldValue as Decimal }

    private setDecimalString() {
        if (this.valueAsDecimal && this.valueAsDecimal.isFinite())
            this.decimalString = this.row.precision
                ? stringifyNumber(this.valueAsDecimal, this.row.precision)
                : this.valueAsDecimal.toString();
        else this.decimalString = "";
    }

    public mounted() {
        this.setDecimalString();
    }

    @Watch("fieldValue") private updateValue() {
        if (!this.suppressChange) this.setDecimalString();
        else this.suppressChange = false;
    }

    private get inputString(): string {
        return this.decimalString;
    }

    private set inputString(v: string) {
        this.decimalString = v;

        try {
            this.$emit("set-value", { row: this.row, value: new Decimal(v.replace(",", ".")) });
        } catch {
            this.$emit("set-value", { row: this.row, value: new Decimal(NaN) });
        }

        this.suppressChange = true;
    }
}
