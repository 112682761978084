import { DatePickerModel } from "./DatePickerModel";
import { Component, Prop, Vue, Watch } from "vue-property-decorator";
import EventBus from '@/EventBus';
import DatePickerModal from "./DatePickerModal.vue";
import IMask from "imask";
import { IMaskComponent } from "vue-imask";

@Component({
    components: {
        "imask-input": IMaskComponent
    }
})
export default class DatePicker extends Vue {
    @Prop()
    public value!: Date | number | null;

    @Prop({ default: false })
    public readonly!: boolean;

    @Prop({ default: false })
    public disabled!: boolean;

    @Prop({ default: "months" })
    public mode!: "days" | "months";

    @Prop({ default: null })
    public rangeStart!: Date | null;

    @Prop({ default: null })
    public rangeEnd!: Date | null;

    @Watch("rangeStart") @Watch("rangeEnd") public setRange() {
        if (this.model) {
            this.model.setRangeStart(this.rangeStart);
            this.model.setRangeEnd(this.rangeEnd);
        }
    }
    public model: DatePickerModel = null!;
    private skipRefresh = false;

    public patternDay = 'd{.}`m{.}`Y';
    public blocksDay = {
        d: {
            mask: IMask.MaskedRange,
            from: 1,
            to: 31,
            maxLength: 2,
        },
        m: {
            mask: IMask.MaskedRange,
            from: 1,
            to: 12,
            maxLength: 2,
        },
        Y: {
            mask: IMask.MaskedRange,
            from: 1900,
            to: 2100,
        }
    };

    public patternMonth = 'm{.}`Y';
    public blocksMonth = {
        m: {
            mask: IMask.MaskedRange,
            from: 1,
            to: 12,
            maxLength: 2,
        },
        Y: {
            mask: IMask.MaskedRange,
            from: 1900,
            to: 2100,
        }
    };

    public parseMonth = function (str: string) {
        const d = str.split('.');
        return new Date(`${d[0]}.01.${d[1]} 03:00 +0300`);
    };

    public formatMonth = function (date: Date) {
        if (!date) return '';
        return Intl.DateTimeFormat("ru", {month: "numeric", year: "numeric"}).format(date);
    };

    public parseDay = function (str: string) {
        const d = str.split('.');
        return new Date(`${d[1]}.${d[0]}.${d[2]} 03:00 +0300`);
    };

    public formatDay = function (date: Date) {
        if (!date) return '';
        return Intl.DateTimeFormat("ru", {day: "numeric", month: "numeric", year: "numeric"}).format(date);
    };

    @Watch("value") public setValue() {
        if (!this.value) {
            this.skipRefresh = true;
            this.model.assign(true, null, this.mode);
        } else {
            this.skipRefresh = true;
            this.model.assign(!this.value, this.value, this.mode);

            this.model.empty = false;
        }
    }

    @Watch("model.resultValue") public updateValue() {

        if (this.skipRefresh) {
            this.skipRefresh = false;
            return;
        }

        this.$emit("change", this.model.resultValue);
        this.$emit("input", this.model.resultValue);
    }

    @Watch("mode") public updateMode() {
        this.model.setMode(this.mode);
    }

    public created() {
        this.skipRefresh = true;
        this.model = new DatePickerModel(
            !!this.value, this.value, this.mode, this.rangeStart, this.rangeEnd);
        if (this.value) this.model.empty = false;
    }

    private forceClosePromiseResolver: (() => void) | null = null;

    public show() {
        if (this.readonly || this.disabled) return;

        const el = this.$el as HTMLElement;
        const rect = el.getBoundingClientRect();

        const modalHeight = 220;
        const innerHeight = window.innerHeight;

        let top = rect.top + 35;

        if (top + modalHeight > innerHeight) {
            if (rect.top - modalHeight - 5 >= 0) {
                top = rect.top - modalHeight - 5;
            } else {
                top = rect.top - modalHeight / 2;
            }
        }

        if (this.forceClosePromiseResolver)
            this.forceClosePromiseResolver();

        const forceClosePromise = new Promise(resolve => this.forceClosePromiseResolver = resolve);

        EventBus.callModal(DatePickerModal, {
            model: this.model,
            top: top,
            left: rect.left,
            forceClose: forceClosePromise
        });
    }

    public clear() {
        this.$emit("change", 0);
        this.$emit("input", 0);
        this.model.assign(true, 0, this.mode);
    }

    public hide() {
        // do nothing (causes bugs)
    }

    public onInput(date: Date) {
        if (date != null) {
            this.$emit("change", date);
            this.$emit("input", date);
        }
    }

    public onBlur() {
        if (this.model != null && this.value != null) this.model.value = this.value;
    }
}