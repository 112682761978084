// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, "\n._2upNkD2rc64R8IC_0fzX0y {\n    background-color: white;\n    position: absolute;\n    width: calc(100%);\n    height: calc(100% - 0px);\n    transition: 0.3s;\n}\n\n._3X75SpPrKc01z6Bxf-OYk5 {\n    display: flex;\n    justify-content: flex-start;\n    align-items: stretch;\n    height: calc(100% - 68px);\n}\n\n._1EF-w8eT55cLBHywMpybDu {\n    width: 100%;\n    display: block;\n    overflow: auto;\n}\n\n._1LgJkaFHoVh3ZtpLIotrij {\n    margin: 20px 30px;\n}", ""]);
// Exports
exports.locals = {
	"container": "_2upNkD2rc64R8IC_0fzX0y",
	"tableAndFilters": "_3X75SpPrKc01z6Bxf-OYk5",
	"tableAndPager": "_1EF-w8eT55cLBHywMpybDu",
	"pager": "_1LgJkaFHoVh3ZtpLIotrij"
};
module.exports = exports;
