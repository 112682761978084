import { MarketPrice } from "@/models";
import { MarketPriceDataSource } from "@/models/enums";
import { FilterValueType, http, IFilterObject, FilterConditionType } from "./http";
import { convertPaged, IPagedItems } from "./Pagination";

export default class {
    public static async get(
        from: number,
        count: number,
        dataSource: MarketPriceDataSource,
        subjectDeclarationId: number,
        filters: IFilterObject,
    ): Promise<IPagedItems<MarketPrice>> {
        return convertPaged(
            await http.getPaged(
                "/api/directory/marketPrices",
                {
                    from,
                    count,
                    filters: {
                        ...filters,
                        subjectDeclarationId: [{
                            type: FilterValueType.LONGINT,
                            conditionType: FilterConditionType.EQUAL,
                            longint: subjectDeclarationId,
                        }],
                        dataSource: [{
                            type: FilterValueType.STRING,
                            conditionType: FilterConditionType.EQUAL,
                            string: dataSource,
                        }],
                    },
                },
                false
            ),
            MarketPrice,
        );
    }

    public static async create(price: MarketPrice, subjectDeclarationId: number) {
        return MarketPrice.fromJson(await http.post("/api/directory/marketPrices/" + subjectDeclarationId, price.toJson()));
    }
}
