import React, {CSSProperties, ReactElement} from "react";
import {SelectOption} from "@/models/enums";
import {Dropdown, IDropdownOption, IDropdownStyles, ResponsiveMode} from "office-ui-fabric-react";

export type ComboBoxProps<T> = {
    options: SelectOption<T>[]
    value?: T
    disabled?: boolean
    style?: CSSProperties
    noPropagation?: boolean
    onChangeNonStrict?: (value: T | undefined) => void
    onChange?: (value: T) => void
    allowVisibleUndefinedKey?: boolean
    multi?: boolean
};

export const styles: Partial<IDropdownStyles> = {
    dropdown: {
        border: "none",
        overflow: "hidden",
        selectors: {
            ['&:hover .ms-Dropdown-title']: [{
                borderColor: "#b2b2b2 #e2e2e2 #e2e2e2",
            }],
            ['&:focus:after']: [{
                border: "none !important"
            }]
        }
    },
    root: {
        overflowX: "hidden",
        selectors: {
            ["& .is-disabled .ms-Dropdown-title"]: {
                backgroundColor: "#eee !important"
            }
        }
    },
    title: {
        display: "block",
        paddingTop: "0.3em",
        paddingLeft: "10px",
        backgroundColor: "#fafafa",
        border: "1px solid #b2b2b2 #e2e2e2 #e2e2e2",
        borderColor: "#b2b2b2 #e2e2e2 #e2e2e2",
        fontSize: "14px",
        lineHeight: "19px",
        height: "30px",
        boxSizing: "border-box",
        transition: "border-color 0.15s ease-in-out 0.3s",
        boxShadow: "inset 0 1px 1px #e1e1e1 !important",
        borderRadius: 0,
        color: "#555",
        selectors: {
            "&:hover": {
                borderColor: "#b2b2b2 #e2e2e2 #e2e2e2 !important",
            },
            "&:focus": {
                outline: 0,
                borderColor: "#66afe9 !important",
            },
            "&[disabled]": {
                background: "#eee !important"
            },
        },
        overflow: "hidden"
    },
    dropdownItemDisabled: {
        color: "#999 !important",
        height: "unset !important"
    },
    dropdownItem: {
        color: "#555 !important",
        height: "unset !important",
        selectors: {
            "&:hover": {
                backgroundColor: "#bad0ff !important"
            }
        }
    },
    dropdownOptionText: {
        whiteSpace: "normal !important"
    },
    dropdownItemSelected: {
        backgroundColor: "#7fa8fd !important",
        height: "unset !important",
        color: "#fff !important",
    },
};

export const ComboBox: <T>(x: ComboBoxProps<T>) => ReactElement = x => {
    type T = typeof x.options[0]["key"];

    const mappedOptions = x.options.map((o, i) => ({
        key: i,
        text: o.desc,
        data: o.key,
        hidden: x.allowVisibleUndefinedKey ? false : o.key === undefined,
        disabled: o.disabled
    } as IDropdownOption));

    const selectedIndex = x.options.findIndex(o => o.key === x.value);

    return <Dropdown options={mappedOptions}
                     onClick={e => {
                        if(x.noPropagation)
                            e.stopPropagation();
                     }}
                     multiSelect={x.multi}
                     styles={x.style ? { ...styles, root: { ...(styles.root as {}), ...x.style } } as typeof styles : styles}
                     responsiveMode={ResponsiveMode.xxxLarge}
                     selectedKey={selectedIndex}
                     onChange={(_, o) => {
                         if (o === undefined) return;

                         const d = o.data as T | undefined;

                         x.onChangeNonStrict?.(d);
                         if (d !== undefined) {
                             x.onChange?.(d);
                         }
                     }}
                     disabled={x.disabled}
                     calloutProps={{ calloutMaxHeight: 500 }} />;
};
