import React from "react";
import {Expandee, HStack, VStack} from "@/components/layouts";
import {Button, Error} from "@/components/primitive";
import {Form, FormHeader, FormState} from "@/components/form";
import {useErrorHandler} from "@/reactUtils";
import router from "@/router";
import {actionToState} from "@/views/ComposedLot/shared/store";
import {InfoSection} from "@/views/Monopoly/shared/sections/request/InfoSection";
import {SpecificationSection} from "@/views/Monopoly/shared/sections/request/SpecificationsSection";
import {DocumentsSections} from "@/views/Monopoly/shared/sections/request/DocumentsSections";
import {CommentarySection} from "@/views/Monopoly/shared/sections/request/CommentarySection";

export const CreateConclusionRequest = () =>{
    const [error, makeSafe] = useErrorHandler();

    const dismiss = () => {
        router.replace("/plan-objects/requests-for-conclusion");
    };

    const save = makeSafe(async () => {
        console.log("saved");
    });

    const header = <VStack spacing="15px">
        <Error object={error}/>
        <FormHeader title="Новый запрос на заключение контракта"/>
    </VStack>;

    const navInset = <HStack>
        <Button title="Сохранить" color="green" onClick={save} />
        <Expandee/>
        <Button color="red" icon="aClose" onClick={() => dismiss()}/>
    </HStack>;

    const infoTab = Form.Tab("info", "Общие сведения", [
        Form.Section("Общие сведения", <InfoSection />),
        Form.Section("Спецификации", <SpecificationSection />),
        Form.Section("Документы", <DocumentsSections />),
        Form.Section("Комментарий", <CommentarySection />)
    ]);

    return <FormState value={actionToState("creating")}>
        <Form header={header} navInset={navInset} tabs={[infoTab]} />
    </FormState>;
};
