import {asDate, AutoDto, AutoModel} from "@/models/parsing";
import {Time} from "@/components/primitive/TimePicker/TimePicker.types";
import {appendTimeToDate} from "@/components/primitive/TimePicker/TimePicker";

interface PublishTermsMD {
    publishDate: Date
    requestAcceptRangeFrom: Date
    requestAcceptRangeFromTime: Time

    requestAcceptRangeTo: Date
    requestAcceptRangeToTime: Time

    documentExplanationRangeFrom?: Date
    documentExplanationRangeTo?: Date
    firstRequestConsiderationFinishDate?: Date
    secondRequestConsiderationFinishDate?: Date
    executionDate?: Date
    finalOffersDate?: Date
}

export type PublishTerms = AutoModel<PublishTermsMD>;
export type DtoPublishTerms = AutoDto<PublishTermsMD>;

export const PublishTerms = {
    toTemplate(trm: PublishTerms): PublishTermsTemplate {
        return {
            publishDate: trm.publishDate,
            documentExplanationRangeFrom: trm.documentExplanationRangeFrom,
            documentExplanationRangeTo: trm.documentExplanationRangeTo,
            firstRequestConsiderationFinishDate: trm.firstRequestConsiderationFinishDate,
            secondRequestConsiderationFinishDate: trm.secondRequestConsiderationFinishDate,
            executionDate: trm.executionDate,
            finalOffersDate: trm.finalOffersDate,
            requestAcceptRangeFrom: appendTimeToDate(trm.requestAcceptRangeFrom, trm.requestAcceptRangeFromTime),
            requestAcceptRangeTo: appendTimeToDate(trm.requestAcceptRangeTo, trm.requestAcceptRangeToTime)
        };
    },
    fromDto(trm: DtoPublishTerms): PublishTerms {
        const requestAcceptFromTime = {hours: new Date(trm.requestAcceptRangeFrom).getHours(), minutes: new Date(trm.requestAcceptRangeFrom).getMinutes()};
        const requestAcceptToTime = {hours: new Date(trm.requestAcceptRangeTo).getHours(), minutes: new Date(trm.requestAcceptRangeTo).getMinutes()};
        return {
            publishDate: asDate(trm.publishDate),
            requestAcceptRangeFrom: asDate(trm.requestAcceptRangeFrom),
            requestAcceptRangeFromTime: requestAcceptFromTime,
            requestAcceptRangeTo: asDate(trm.requestAcceptRangeTo),
            requestAcceptRangeToTime: requestAcceptToTime,
            documentExplanationRangeFrom: asDate(trm.documentExplanationRangeFrom),
            documentExplanationRangeTo: asDate(trm.documentExplanationRangeTo),
            firstRequestConsiderationFinishDate: asDate(trm.firstRequestConsiderationFinishDate),
            secondRequestConsiderationFinishDate: asDate(trm.secondRequestConsiderationFinishDate),
            executionDate: asDate(trm.executionDate),
            finalOffersDate: asDate(trm.finalOffersDate),
        };
    },
};

interface PublishTermsTemplateMD {
    publishDate: Date
    requestAcceptRangeFrom: Date
    requestAcceptRangeTo: Date
    documentExplanationRangeFrom?: Date
    documentExplanationRangeTo?: Date
    firstRequestConsiderationFinishDate?: Date
    secondRequestConsiderationFinishDate?: Date
    executionDate?: Date
    finalOffersDate?: Date
}

export type PublishTermsTemplate = AutoModel<PublishTermsTemplateMD>;
export type DtoPublishTermsTemplate = AutoDto<PublishTermsTemplateMD>;


export const PublishTermsTemplate = {
    toDto(trm: PublishTermsTemplate): DtoPublishTermsTemplate {
        return {
            publishDate: trm.publishDate.toISOString() ?? new Date().toISOString(),
            requestAcceptRangeFrom: trm.requestAcceptRangeFrom.toISOString() ?? new Date().toISOString(),
            requestAcceptRangeTo: trm.requestAcceptRangeTo.toISOString() ?? new Date().toISOString(),
            documentExplanationRangeFrom: trm.documentExplanationRangeFrom?.toISOString() ?? null,
            documentExplanationRangeTo: trm.documentExplanationRangeTo?.toISOString() ?? null,
            firstRequestConsiderationFinishDate: trm.firstRequestConsiderationFinishDate?.toISOString() ?? null,
            secondRequestConsiderationFinishDate: trm.secondRequestConsiderationFinishDate?.toISOString() ?? null,
            executionDate: trm.executionDate?.toISOString() ?? null,
            finalOffersDate: trm.finalOffersDate?.toISOString() ?? null
        };
    },
};
