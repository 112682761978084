import {autoSV, createSVEvent, origEv} from "@/storeUtils";
import {createEvent, createStore, Store} from "effector";
import {LotForm} from "@/views/ComposedLot/shared/store/index";

export interface Person {
    isGeneral: boolean
    firstName: string
    middleName: string
    lastName: string
    email: string
    cellPhone: string
}

interface Name {
    firstName: string
    middleName: string
    lastName: string
}

export const fullName = (x?: Name) => x ? `${x.lastName} ${x.firstName} ${x.middleName}` : "";


export interface LotResponsibilityInfo {
    // Информация об ответственном должностном лице заказчика
    clientResponsiblePerson?: Person
    // Информация о контрактной службе/управляющем, ответственных за заключение контракта
    clientResponsibleContractPerson?: Person
}

export const setClientResponsiblePerson = createSVEvent<LotResponsibilityInfo>()("clientResponsiblePerson");
export const setClientResponsibleContractPerson = createSVEvent<LotResponsibilityInfo>()("clientResponsibleContractPerson");

const replace = createEvent<LotResponsibilityInfo>("replace responsibility info");

export const ResponsibilityInfoStore = {
    createStore: (): Store<LotResponsibilityInfo> => {
        return createStore<LotResponsibilityInfo>({})
            .on(replace, (_, l) => l)
            .on(setClientResponsiblePerson[origEv], autoSV)
            .on(setClientResponsibleContractPerson[origEv], autoSV)
            ;
    },

    replace: replace,
    observeOnCombinedStore: (combinedStore: Store<LotForm>) => {}
};