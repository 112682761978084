import React, {CSSProperties} from "react";
import styles from "./Input.module.css";
import {TooltipIcon} from "@/components/TooltipIcon";
import {j} from "@/reactUtils";

interface InputProps {
    label?: string
    value: string | number | readonly string[]
    onChange: (e: { target: { value: string } }) => void
    onKeyDown?: (e: React.KeyboardEvent<HTMLInputElement>) => void
    disabled?: boolean
    clearable?: boolean
    checkEmpty?: boolean
    withTooltip?: boolean
    required?: boolean
    withHint?: boolean
    readonly?: boolean
    fullWidth?: boolean
    background?: string
    placeholder?: string
}

export const Input = (props: InputProps) => {
    return (
        <div className={j(styles.input__wrapper, props.fullWidth && styles.input__wrapper_fullWidth)}>
            <fieldset style={{background: props.background, padding: props.background && 0}}
                      className={j(styles.input__fieldset, props.disabled && styles.input__fieldset_disabled, props.fullWidth && styles.input__fieldset_fullWidth)}>
                {props.label &&
                    <legend className={j(styles.input__label, props.disabled && styles.input__label_disabled)}>
                        {props.label}
                        {props.required ? <span className={styles.required}>*</span> : ''}
                        {props.withTooltip && <TooltipIcon />}
                    </legend>
                }
                <input value={props.value}
                       onChange={props.onChange}
                       onKeyDown={props.onKeyDown}
                       className={styles.input__field}
                       maxLength={100}
                       disabled={props.disabled}
                       placeholder={props.placeholder} />
                {(props.clearable && !props.disabled) && <div onClick={() => props.onChange && props.onChange({ target: { value: '' } })} className={styles.closeButton} />}
            </fieldset>
            {props.withHint && props.value && <div className={styles.input__hint}>Осталось {100 - props.value?.toString().length} символов из 100 </div>}
        </div>
    );
};
