






















import { Component, Prop, Vue } from "vue-property-decorator";
import CardModal from "@/components/CardModal.vue";
import { ModalVue, ModalResult } from "@/view-models/ModalRequest";
import { LawType } from "@/models/enums";

@Component({ components: { CardModal } })
export default class LawSelectionModal extends ModalVue<null, LawType> {
    public selectedLawType: LawType = LawType.F44;

    public confirm() {
        this.$emit("close-modal", ModalResult.ok(this.selectedLawType));
    }
}
