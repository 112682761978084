export const supplyRegions = [
    { value: "", name: "Все регионы" },
    { value: "MO", name: "обл Московская" },
    { value: "MOSCOW", name: "г Москва" },
    { value: "MOSCOW_AND_MO", name: "г Москва и обл Московская" }
] as const;

const supplyRegionsValues = supplyRegions.map(r => r.value);

export type SupplyRegion = typeof supplyRegionsValues[number];

export const sortByOptions = [
    { value: "stuNumber", name: "по релевантности" },
    { value: "refPrice", name: "по референтной цене" },
] as const;

const sortByOptionsValues = sortByOptions.map(r => r.value);

export type SortBy = typeof sortByOptionsValues[number];
