import React, { useContext, useState } from "react";
import { FormStateContext } from "@/components/form";
import { useMappedStore } from "@/storeUtils";
import { Card } from "@/components/Card";
import { Field, Fields } from "@/components/form/Field";
import { SectionProps } from "../store";
import { HStack, VStack } from "@/components/layouts";
import { FileInput } from "@/components/primitive/FileInput";
import { Button, Label } from "@/components/primitive";
import { LotDocument } from "@/models/ComposedLots/documents";
import { Column, SelectionTable, Table } from "@/components/table";
import _ from "lodash";
import { Lot } from "@/models/ComposedLots";
import { showModal } from "@/EventBus";
import { PickIncludedLotsModal } from "@/modal-views/PickIncludedLotsModal";
import {addIncludedLot, deleteIncludedLots} from "@/views/ComposedLot/shared/store/head";
import { SmpTypeStrings } from "@/models/enums";
import { formatNumber } from "@/NumberFormatting";
import { formatDate } from "@/dateUtils";
import { Decimal } from 'decimal.js';

export const JointBiddingSection: React.FC<SectionProps> = x => {
    const state = useContext(FormStateContext);
    const orientation = state === "readonly" ? "vertical" : "horizontal";

    const [doc, setDoc] = useState<Partial<LotDocument>>(
        {
            id: undefined!, // todo: type-checked
            type: undefined,
            file: undefined,
            sendToEis: false,
            comment: undefined
        }
    );
    const [selected, setSelected] = useState<Lot[]>([]);
    const [includedLots, providerSelectionType] = useMappedStore(x.formStore, x => [x.head.includedLots, x.info.providerSelectionType]);

    const groupedByCustomerLots = _(includedLots).groupBy(x => x.customer?.shortName).map((value, key) => ({
        key: key,
        lots: value
    })).value();

    const addLot = async () => {
        const state = x.formStore.getState();
        const customer = state.head.mainCustomer ?? state.head.customer;
        if (!customer) return;

        const result = await showModal(PickIncludedLotsModal, {
            lotId: state.head.id,
            providerSelectionType: providerSelectionType,
            mainCustomerId: customer.id,
            formStore: x.formStore
        });
        if (result) {
            for (const lot of result) {
                addIncludedLot({newV: lot});
            }
        }
    };

    const deleteSelected = () => {
        deleteIncludedLots(selected);
        setSelected([]);
    };

    const columns: Column<Lot>[] = [
        Table.AutoColumn("Номер", x => <>{x.rowIndex + 1}</>),
        Table.AutoColumn("Реестровый номер", x => <>{x.item.regNumber}</>),
        Table.AutoColumn("Наименование", x => <a rel="noreferrer" target="_blank" href={"/#/plan-objects/composed-lots/" + x.item.id} style={{ textDecoration: "underline", cursor: "pointer", color: "unset" }}>{x.item.basicInfo.subject}</a>),
        Table.AutoColumn("Год", x => <>{x.item.basicInfo.plannedNotificationPublishYear}</>),
        Table.AutoColumn("Заказчик", x => <>{x.item.customer?.shortName}</>),
        Table.AutoColumn("НМЦ контракта, руб.", x => <>{formatNumber(x.item.totalPrice)}</>),
        Table.AutoColumn("СМП", x => <>{SmpTypeStrings[x.item.basicInfo.smpType]}</>),
        Table.AutoColumn("Соглашение", () => <>{}</>),
        Table.AutoColumn("Дата формирования", x => <>{formatDate(x.item.basicInfo.creationDate)}</>)
    ];

    return <Card>
        <Fields orientation={orientation} style={{ marginBottom: "10px" }}>
            {state !== "readonly" && <Field title="Соглашение" required={true}>
                <VStack spacing="5px" alignItems="start">
                    <HStack spacing="15px" alignItems="start">
                        {
                            !doc.file &&
                            <FileInput onChange={a => setDoc({...doc, file: a})}/>
                        }

                        <Label text={doc.file?.name ?? "Файл не выбран."}/>
                        {
                            doc.file &&
                            <Button color={"red"} icon={"aClose"} onClick={() => setDoc({...doc, file: undefined})}/>
                        }
                    </HStack>
                    <Label style={{whiteSpace: "normal"}}
                           text={"Допустимые типы файлов: doc, docx, xls, xlsx, pdf, tiff, jpg, jpeg, zip, rar"}
                    />
                    <Label style={{whiteSpace: "normal"}}
                           text={"Максимальный размер файла - 10 МБ"}
                    />
                </VStack>
            </Field>}
            <Field title="НМЦ">
                <Label preset="readonlyValue"
                       text={`${formatNumber(
                           includedLots.reduce((acc, lot) => acc.add(lot.totalPrice), new Decimal(0))
                       )} Российский рубль`}
                       style={{ marginBottom: "20px" }} />
            </Field>
        </Fields>
        <VStack spacing="15px">
            <Label text={"Список заказчиков"}/>
            <Table<{ key: string; lots: Lot[] }> dataset={groupedByCustomerLots} columns={[
                Table.AutoColumn("Наименование", x => <>{x.item.key}</>),
                Table.AutoColumn("Количество лотов", x => <>{x.item.lots.length}</>),
            ]}/>
            {
                includedLots?.length
                    ? <>
                        <Label text="Список позиций плана-графика для формирования совместного лота"/>
                        {state !== "readonly"
                            ? <SelectionTable<Lot> selectorPosition={"atLeft"}
                                                   mode="multi"
                                                   onChange={setSelected}
                                                   dataset={includedLots}
                                                   selected={selected}
                                                   columns={columns}/>
                            : <Table dataset={includedLots} columns={columns} />}
                    </>
                    : <Label text="Список пуст"/>
            }
            {
                (x.formAction !== "viewing") &&
                <HStack spacing="10px">
                    <Button color={"green"}
                            icon={"aAdd"}
                            disabled={!providerSelectionType}
                            title={"Добавить"}
                            onClick={addLot}/>
                    {
                        !!includedLots?.length && <Button color={"red"}
                                                        title={"Удалить"}
                                                        onClick={deleteSelected}/>
                    }
                </HStack>
            }
        </VStack>
    </Card>;
};