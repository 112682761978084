import styles from "./Select.module.css";
import {TooltipIcon} from "@/components/TooltipIcon";
import React, {useRef, useState} from "react";
import {j, useOnClickOutside} from "@/reactUtils";

export interface SelectionProps {
    label?: React.ReactNode
    withTooltip?: boolean
    required?: boolean
    disabled?: boolean
    value: string
    options: readonly { readonly value: string | undefined; readonly name: string; readonly disabled?: boolean; tooltip?: string }[]
    onSelect?: (value: string | undefined) => void
    fullWidth?: boolean
    unit?: string
    placeholder?: string
    small?: boolean
    popupOnHover?: boolean
    width?: number | string
}

export const Select = (props: SelectionProps) => {
    const [visible, setVisible] = useState(false);
    const ref = useRef<HTMLFieldSetElement | null>(null);
    const selectorWidth = ref.current?.offsetWidth ? Math.floor(ref.current?.offsetWidth) - 2 : 170;

    useOnClickOutside(ref,() => setVisible(false));

    return (
        <div style={{width: props.width}} className={j(styles.selector__wrapper, props.small && styles.selector__wrapper_small)} onMouseOver={() =>{props.popupOnHover && setVisible(true)}} onMouseLeave={() => {props.popupOnHover && setVisible(false)}}>
            <fieldset ref={ref} className={j(styles.select__fieldset, props.disabled && styles.select__fieldset_disabled, props.fullWidth && styles.select__fieldset_fullWidth, props.small && styles.select__fieldset_small)}>
                {props.label &&
                    <legend className={j(styles.select__label, props.disabled && styles.select__label_disabled)}>
                        {props.label}
                        {props.required ? <span className={styles.required}>*</span> : ''}
                        {props.withTooltip && <TooltipIcon />}
                        {props.unit && <span className={styles.unit}>{", " + props.unit}</span>}
                    </legend>
                }
                <div className={styles.selectorContainer}>
                    <div onClick={() => !props.disabled && setVisible(!visible)}
                         className={j(styles.selector, props.disabled && styles.disabled)}>
                        <div className={styles.selectorContent}>
                            <div className={styles.selectorMainContent}>
                                {!props.value.length && props.placeholder}
                                {props.options.find(x => x.value === props.value)?.name}
                            </div>
                            {!props.disabled && <div className={styles.selectorControl}>
                                <svg width="18" height="18" viewBox="0 0 24 24">
                                    <path d="M7,10l5,5,5-5Z"></path>
                                </svg>
                            </div>}
                        </div>
                        {!props.disabled && <div style={{width: selectorWidth}} className={j(styles.selectorPopup, props.small && styles.selectorPopup_small, visible ? '' : styles.selectorHidden)}>
                            {props.options.map((x, i) => (
                                <div
                                    key={i}
                                    title={x.tooltip}
                                    className={x.disabled ? styles.disabledOption : props.value === x.value ? styles.option_selected : styles.option}
                                    onClick={() => (!x.disabled && props.onSelect) && props.onSelect(x.value)}>
                                    <div className={styles.selectorPopupItemText}>{x.name}</div>
                                </div>
                            ))}
                        </div>}
                    </div>
                </div>
            </fieldset>
        </div>
    );
};
