






























import { Component, Prop, Vue } from "vue-property-decorator";
import { MutatingFieldBase } from "@/components/vue/form-table/FieldBase";
import { mixins } from "vue-class-component";
import { CheckboxRow } from './FormFields';

@Component
export default class CheckBoxField extends mixins<MutatingFieldBase<CheckboxRow<unknown>>>(MutatingFieldBase) {}
