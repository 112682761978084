import React, { useState } from "react";
import { CardModal } from "@/components/modals/CardModal";
import { Expandee, HStack, VStack } from "@/components/layouts";
import { RoundedButton } from "@/components/primitive/RoundedButton";
import { Label, TextBox } from "@/components/primitive";
import { ModalFC } from "@/EventBus";
import styles from "./ForgotPasswordModal.module.css";
import { forgotPasswordAction } from "@/api/Register";

export const ForgotPasswordModal: ModalFC<{}, {}> = x => {
    const [email, setEmail] = useState<string>("");
    const validateEmail = (e: string) => /[^@]+@[^\.]+\..+/.test(e);

    const submit = () => {
        forgotPasswordAction(email);
        x.done(undefined);
    };

    const bottom: React.ReactNode = <HStack spacing="10px">
        <Expandee />
        <RoundedButton color="white" title="Отмена" onClick={() => x.done(undefined)} />
        <RoundedButton color="blue" title="Отправить" disabled={!validateEmail(email)} onClick={submit} />
    </HStack>;

    return <CardModal title="Восстановление пароля"
                      close={() => x.done(undefined)}
                      width="600px"
                      cardContentStyle={{ minHeight: "300px" }}
                      cardHeaderStyle={{ backgroundColor: "white" }}
                      bottom={bottom}>
        <VStack spacing="25px">
            <Label preset="thinMedium">
                Для восстановления пароля укажите <b>адрес электронной почты</b> связанные с вашим аккаунтом в Единой автоматизированной информационной системе торгов города Москвы
            </Label>
            <TextBox placeholder="E-mail"
                     value={email}
                     className={styles.eaistInput}
                     onChange={setEmail} />
        </VStack>
    </CardModal>;
};