// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, "._1I2GkMSYeDoAhdmAckw-7Y {\n    width: fit-content;\n    height: 20px;\n    text-overflow: ellipsis;\n}\n\n._2tioWfoLCI1sI4wBj0-4ja {\n    position: relative;\n    margin-bottom: 0;\n    cursor: pointer;\n    height: 100%;\n    user-select: none;\n    display: flex;\n    gap: 10px;\n}\n\n._2pfEEjToYhJT-FxMm65pQp {\n    position: absolute;\n    z-index: -1;\n    opacity: 0;\n}\n\n._3ITX5oAscahRFvro7nak70 {\n    display: block;\n    background-color: #fff;\n    cursor: pointer;\n    height: 16px;\n    width: 16px;\n}\n\n._3kXdfCWVk5sPvCHq4x5i-I {\n    border: 2px solid #4c4c4c;\n}\n\n._2mQ_P8x_TrENIl-iBFwpdv {\n    border: 1px solid #CBD5DB;\n    padding: 1px;\n}\n\n.Ik-LgUW9kTMbsKujw2Rcq {\n    background-color: #4db2ff;\n    border: 2px solid #4db2ff;\n    padding: 0;\n}\n\n._1uMeP_FXqAwTMuj6RLPhi9 {\n    width: 20px;\n    height: 20px;\n}\n\n.Ik-LgUW9kTMbsKujw2Rcq::after {\n    left: 6px;\n    top: 0;\n    width: 6px;\n    height: 13px;\n    border: solid white;\n    border-width: 0 2px 2px 0;\n    -webkit-transform: rotate(45deg);\n    -ms-transform: rotate(45deg);\n    transform: rotate(45deg);\n\n    content: \"\";\n    position: absolute;}\n\n._3R9HaPwCj5YJc2tjsIUQ2N {\n    white-space: pre-wrap;\n    overflow: hidden;\n    text-overflow: ellipsis;\n    color: #4c4c4c;\n    font-weight: 400;\n}\n\n.Kzx8DPnSPpR3w0hqmb0um {\n    background-color: #f5f5f5;\n    border-color: #e1e7eb;\n    cursor: not-allowed !important;\n}\n", ""]);
// Exports
exports.locals = {
	"eshopCheckboxComponent": "_1I2GkMSYeDoAhdmAckw-7Y",
	"containerCheckbox": "_2tioWfoLCI1sI4wBj0-4ja",
	"inputCheckbox": "_2pfEEjToYhJT-FxMm65pQp",
	"checkMark": "_3ITX5oAscahRFvro7nak70",
	"checkbox__border_bold": "_3kXdfCWVk5sPvCHq4x5i-I",
	"checkbox__border_default": "_2mQ_P8x_TrENIl-iBFwpdv",
	"checkMarkActive": "Ik-LgUW9kTMbsKujw2Rcq",
	"checkboxWrapper": "_1uMeP_FXqAwTMuj6RLPhi9",
	"checkboxCaption": "_3R9HaPwCj5YJc2tjsIUQ2N",
	"disabled": "Kzx8DPnSPpR3w0hqmb0um"
};
module.exports = exports;
