import { FinancialActionSource } from "@/models";
import { IDtoFinancialActionSource } from "@/models/json";
import { Decimal } from "decimal.js";
import { BankAccount } from "@/models/BankAccount";
import { IDtoBankAccount } from "@/models/json/IDtoBankAccount";
import { IDtoContractFinancialSource } from '@/models/json/Contracts';
import { IJsonFormattable } from '@/models/IJsonFormattable';

export class ContractFinancialSource implements IJsonFormattable<IDtoContractFinancialSource> {
    constructor(
        public id: string,
        public source: FinancialActionSource,
        public amount: Decimal,
        public account: BankAccount | null,
    ) {}
    
    public toJson(): IDtoContractFinancialSource {
        return {
            id: this.id,
            source: this.source.toJson(),
            amount: this.amount.toString(),
            account: this.account && this.account.toJson(),
        };
    }
    
    public static fromJson(dto: IDtoContractFinancialSource): ContractFinancialSource {
        return Object.assign(Object.create(ContractFinancialSource.prototype), {
            id: dto.id,
            source: FinancialActionSource.fromJson(dto.source),
            amount: new Decimal(dto.amount),
            account: dto.account && BankAccount.fromJson(dto.account),
        });
    }
}
