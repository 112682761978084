import { IDtoFile } from '@/models/json/Documents';
import { IJsonFormattable } from '@/models/IJsonFormattable';

export class File implements IJsonFormattable<IDtoFile> {
    constructor(
        public id: string,
        public length: number,
        public fileName: string,
        public comment: string,
    ) {}
    
    public toJson(): IDtoFile {
        return {
            id: this.id,
            length: this.length,
            fileName: this.fileName,
            comment: this.comment,
        };
    }

    public toString() {
        return this.comment;
    }
    
    public static fromJson(dto: IDtoFile): File {
        return Object.assign(Object.create(File.prototype), {
            id: dto.id,
            length: dto.length,
            fileName: dto.fileName,
            comment: dto.comment,
        });
    }
}
