import React, { useState } from "react";
import styles from "./OrganizationCard.module.css";
import formStyles from "./RegisterForm.module.css";
import { Participant } from "@/models";
import { Expandee, HSpread, HStack, VStack } from "@/components/layouts";
import { ModalFC, showModal } from "@/EventBus";
import { CardModal } from "@/components/modals/CardModal";
import { Field, Fields } from "@/components/form/Field";
import { Button, DatePicker, Label, TextBox } from "@/components/primitive";
import { RoundedButton } from "@/components/primitive/RoundedButton";
import { addYears } from "date-fns";
import { FileInput } from "@/components/primitive/FileInput";
import { Role } from "@/views/Register/roles/roles";
import { RoleSelectModal } from "@/views/Register/roles/RoleSelectModal";

interface RegisterFormOrganizationCardProps {
    organization: Participant
    onDelete: () => void
}

type RangeDocument = {
    name: string
    number: string
    date: Date
    from: Date
    to: Date
    file: {
        name: string
    }
};

const AddRangeDocumentModal: ModalFC<{}, RangeDocument> = x => {

    const [doc, setDoc] = useState<RangeDocument>({
        name: "",
        number: "",
        date: new Date(),
        from: new Date(),

        to: addYears(new Date(), 1),
        file: {
           name: ""
        }
    });

    const setName = (s: string) => setDoc({ ...doc, name: s });
    const setFrom = (d: Date) => setDoc({ ...doc, from: d });
    const setTo = (d: Date) => setDoc({ ...doc, to: d });
    const setDate = (d: Date) => setDoc({ ...doc, date: d });
    const setNumber = (s: string) => setDoc({ ...doc, number: s });

    const bottom: React.ReactNode = <>
        <HStack alignItems="center" spacing="15px">
            <Expandee />
            <RoundedButton title="Отмена"
                           onClick={() => x.done(undefined)}
                           color="white" />
            <RoundedButton title="Прикрепить"
                           onClick={() => x.done(doc)}
                           color="blue" />
        </HStack>
    </>;

    return <CardModal title="Добавить документ"
                      cardHeaderStyle={{ backgroundColor: "white" }}
                      close={() => x.done(undefined)}
                      bottom={bottom}
                      width="660px">
        <Fields orientation="horizontal">
            <Field preset="thinMedium" title="Название">
                <TextBox className={formStyles.eaistInput}
                         onChange={setName}
                         value={doc.name} />
            </Field>
            <Field preset="thinMedium" title="Номер">
                <TextBox className={formStyles.eaistInput}
                         onChange={setNumber}
                         value={doc.number} />
            </Field>
            <Field preset="thinMedium" title="Дата">
                <DatePicker textBoxClassName={formStyles.eaistInput}
                            onChange={setDate}
                            value={doc.date} />
            </Field>
            <Field preset="thinMedium" title="Период действия">
                <HStack alignItems="center" spacing="8px">
                    <DatePicker textBoxClassName={formStyles.eaistInput}
                                onChange={setFrom}
                                value={doc.from}/>
                    <div>-</div>
                    <DatePicker textBoxClassName={formStyles.eaistInput}
                                onChange={setTo}
                                value={doc.to} />
                </HStack>
            </Field>
            <Field title="">
                <FileInput value={doc.file.name}
                           onChange={f => setDoc({...doc, file: { ...doc.file, name: f.name }})} />
            </Field>
        </Fields>
    </CardModal>;
};

export const RegisterFormOrganizationCard: React.FC<RegisterFormOrganizationCardProps> = x => {
    const [doc, setDoc] = useState<RangeDocument>();
    const [roles, setRoles] = useState<Role[]>([]);

    const addDocument = () => showModal(AddRangeDocumentModal, {}).then(setDoc);
    const pickRoles = () => showModal(RoleSelectModal, {}).then(r => r ? setRoles(r) : {});

    const attachedDoc: React.ReactNode = doc
        ? <VStack>
        <div className={formStyles.footerLink}>
            { doc.name?.length ? doc.name : doc.file.name }
        </div>
        <div className={styles.greyText}>
            { `с ${doc.date.toISOString().slice(0, 10)}` }
        </div>
        <div className={styles.greyText}>
            { `${doc.date.toISOString().slice(0, 10)} по ${doc.date.toISOString().slice(0, 10)}` }
        </div>
    </VStack>
    : <></>;

    return <div className={styles.card}>
        <div className={styles.titleBlock}>
            <HSpread alignItems="center">
                <div className={styles.title}>
                    { x.organization.shortName }
                </div>
                <div onClick={x.onDelete}
                     className={styles.closeButton}>
                    &#xe803;
                </div>
            </HSpread>
            {
                doc
                    ? attachedDoc
                    : <>
                        <div onClick={addDocument}>
                            <a className={formStyles.footerLink}>
                                Прикрепить документ
                            </a>
                        </div>
                        <div className={styles.warning}>
                            Необходимо прикрепить документ
                        </div>
                    </>
            }
        </div>
        <div className={styles.roleBlock}>
            <HStack alignItems="center" outerStyle={{ borderBottom: "2px solid #e6e6e6", paddingBottom: "20px" }}>
                <div>
                    <Label preset="thinMedium">
                        Роли
                    </Label>
                </div>
                <Expandee />
                <div>
                    <Button icon="aAdd" onClick={pickRoles} />
                </div>
            </HStack>
            {
                !roles.length && <HStack>
                    <Expandee />
                        <div style={{ color: "red", padding: "15px" }}>Необходимо назначить не меньше одной роли</div>
                    <Expandee />
                </HStack>
            }
            {
                roles.map(r => <div className={styles.role} key={`role-${r.id}`}>
                    <Label preset="thinMedium">
                        { r.name }
                    </Label>
                </div>)
            }
        </div>
    </div>;
};
