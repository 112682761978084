



import { Vue, Component, Prop } from "vue-property-decorator";
import { createPaymentStore } from "@/views/Payments/view/store";
import { PaymentView } from "@/views/Payments/view";

@Component({ components: { komponent: PaymentView as unknown as typeof Vue } })
export default class PaymentViewVueStub extends Vue {
    @Prop() private paymentId!: string;

    public storeContainer = Object.freeze(createPaymentStore(this.paymentId));
}
