import { IDtoOkpd2 } from "@/models/json";
import { IJsonFormattable } from "@/models/IJsonFormattable";

export class Okpd2 implements IJsonFormattable<IDtoOkpd2> {
    constructor(public id: number, public code: string, public name: string) {}

    public toJson(): IDtoOkpd2 {
        return { ...this };
    }

    // noinspection JSUnusedGlobalSymbols
    public static fromJson(dto: IDtoOkpd2): Okpd2 {
        return Object.assign(Object.create(Okpd2.prototype), dto);
    }

    public toString() {
        return this.code + " " + this.name;
    }
}
