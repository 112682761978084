// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, "._2NyP0ZrhWoGQeW56WdaK-v {\n    color: #ccc;\n    transition: opacity 0.3s ease;\n    opacity: 1;\n    padding-left: 10px;\n    height: 40px;\n    overflow: hidden;\n    text-overflow: ellipsis;\n    padding-right: 30px;\n    color: #4c4c4c;\n    transition: border-color .3s ease-in-out;\n    border-color: #e1e7eb;\n    font-size: 16px;\n    display: block;\n    width: 100%;\n    padding: .375rem .75rem;\n    padding-right: 0.75rem;\n    padding-left: 0.75rem;\n    font-weight: 400;\n    line-height: 1.5;\n    color: #495057;\n    background-color: #fff;\n    background-clip: padding-box;\n    border: 1px solid #e1e7eb;\n    border-radius: 5px;\n    margin: 0;\n    font-family: inherit;\n    box-sizing: border-box;\n}\n\n._2NyP0ZrhWoGQeW56WdaK-v:focus {\n    outline: none !important;\n}\n\n._1v4JCFwmEkDf619GAmg5f {\n    position: relative;\n    width: 100%;\n}\n\n._7a0utcS1Q74lGOIKoBrdu{\n    height: 32px;\n    font-size: 14px;\n}\n\n._2rR5bd6MCKyaogWec05piS {\n    margin-bottom: 1rem;\n}\n\n._1P6nLzASHkki-_zk8G2SYu{\n    display: inline-flex;\n    margin-bottom: .5rem;\n    font-weight: 400;\n}\n\n._2ikqlYlgtRi8dRP10w7-B6{\n    font-weight: 700;\n}\n\n._3_IDkJfZgvjpmmeXKc1kES {\n    background-color: #f5f5f5;\n    border-color: #e1e7eb;\n}\n\n.j-Zt1xIjuXhOjWfV79Jab{\n    border: 1px solid #f66;\n    color: #f66;\n}\n\n.j-Zt1xIjuXhOjWfV79Jab::placeholder {\n    color: #f66;\n}\n\n._3HTaboATQLU-6ax484fNKc{\n    top: 9px;\n    right: 10px;\n    background-image: url(\"https://eaist2-f.mos.ru/eshop/img/icon-close-999.9770e030323c1c0f85a1.svg\");\n    width: 15px;\n    height: 15px;\n    background-size: 15px 15px;\n    background-repeat: no-repeat;\n    position: absolute;\n    cursor: pointer;\n}\n\n._3IxI0GdBCERKjLtVn4uEip {\n    display: flex;\n    align-items: center;\n    gap: 5px;\n    font-size: 12px;\n    color: #f66;\n    margin-top: 10px;\n    position: absolute;\n    max-width: 250px;\n}\n", ""]);
// Exports
exports.locals = {
	"formControlEshopInput": "_2NyP0ZrhWoGQeW56WdaK-v",
	"eshopInputWrap": "_1v4JCFwmEkDf619GAmg5f",
	"inputSmall": "_7a0utcS1Q74lGOIKoBrdu",
	"form": "_2rR5bd6MCKyaogWec05piS",
	"formLabel": "_1P6nLzASHkki-_zk8G2SYu",
	"boldLabel": "_2ikqlYlgtRi8dRP10w7-B6",
	"disabled": "_3_IDkJfZgvjpmmeXKc1kES",
	"error": "j-Zt1xIjuXhOjWfV79Jab",
	"closeButton": "_3HTaboATQLU-6ax484fNKc",
	"priceErrorHint": "_3IxI0GdBCERKjLtVn4uEip"
};
module.exports = exports;
