import React from "react";
import { Provision } from "@/models/Provision/Provision";
import { Card } from "@/components/Card";
import { Label } from "@/components/primitive";
import { Field, Fields } from "@/components/form/Field";
import { VStack } from "@/components/layouts";

type ProvisionDatesProps = {
    provision?: Provision
};

export const ProvisionDatesSection = ({ provision }: ProvisionDatesProps) => {
    return (
        <Card>
            <VStack spacing="15px">
                <Label preset="readonlyValue">
                    Извещение о проведении запроса предложений размещается Заказчиком не менее чем за  7  рабочих дней до окончания срока подачи предложений
                </Label>
                <Label preset="readonlyValue">
                    Если НМЦ договора превышает  100 000 000  рублей, извещение о проведении запроса предложений размещается Заказчиком не менее чем за  20  дней до окончания срока подачи предложений
                </Label>
                <Fields orientation="horizontal">
                    <Field title="Возможность изменения срока публикации извещения по решению МРГ"
                           preset="readonlyValue">
                        {provision?.canChangeDaysByMRG ? "Да" : "Нет"}
                    </Field>
                </Fields>
            </VStack>
        </Card>
    );
};
