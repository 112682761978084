import {ModalFC} from "@/EventBus";
import {CardModal} from "@/components/modals/CardModal";
import React, {useState} from "react";
import {Expandee, HStack} from "@/components/layouts";
import {RoundedButton} from "@/components/primitive/RoundedButton";
import {ISubjectClassContainer, SubjectClassFilterStore} from "@/components/eshop/SubjectClassFilterStore";
import router from "@/router";
import {SubjectClassTree} from "@/components/eshop/SubjectClassTree/SubjectClassTree";

export const SubjectClassSelectModal: ModalFC<{store: SubjectClassFilterStore; value?: string}, ISubjectClassContainer> = x => {

    const [selectedSubject, setSelectedSubject] = useState<ISubjectClassContainer>();

    const bottom = <HStack spacing={"10px"}>
        <Expandee/>
        <RoundedButton color="white"
                       title="Закрыть"
                       onClick={() => x.done(undefined)} />
        <RoundedButton color="blueWhite"
                       title="Заявка на КПГЗ"
                       disabled
                       onClick={() => {}} />
        <RoundedButton color="blueWhite"
                       title="Полный справочник КПГЗ"
                       onClick={() => {
                           const data = router.resolve("/reference-info/dict/subject-classes");
                           window.open(data.href, "_blank");
                       }} />
        <RoundedButton color="blue"
                       title="Выбрать"
                       onClick={() => selectedSubject && x.done(selectedSubject)} />
    </HStack>;
    return (
        <CardModal title={"Выбор КПГЗ"}
                   close={() => x.done(undefined)}
                   width={"40%"}
                   bottom={bottom}>
            <div style={{height: 450}}>
                <SubjectClassTree store={x.store} default={x.value} onSelect={v => v && setSelectedSubject(v)} />
            </div>
        </CardModal>
    );
};
