import React, {useEffect, useState} from "react";
import {getParticipantsById} from "@/api/directory";
import {VStack} from "@/components/layouts";
import {Form, FormHeader} from "@/components/form";
import {CustomersCustomersDetails} from "@/views/ReferenceInfo/Customers/sections/CustomersCustomersDetails";
import {CustomersLegalRequisites} from "@/views/ReferenceInfo/Customers/sections/CustomersLegalRequisites";
import {CustomersSuccession} from "@/views/ReferenceInfo/Customers/sections/CustomersSuccession";
import {CustomersStatisticalCodes} from "@/views/ReferenceInfo/Customers/sections/CustomersStatisticalCodes";
import {CustomersContactInformation} from "@/views/ReferenceInfo/Customers/sections/CustomersContactInformation";
import {CustomersDeliveryAddresses} from "@/views/ReferenceInfo/Customers/sections/CustomersDeliveryAddresses";
import {CustomersSchedule} from "@/views/ReferenceInfo/Customers/sections/CustomersSchedule";
import {CustomersContactFaces} from "@/views/ReferenceInfo/Customers/sections/CustomersContactFaces";
import {CustomersBankDetails} from "@/views/ReferenceInfo/Customers/sections/CustomersBankDetails";
import {CustomersContracts} from "@/views/ReferenceInfo/Customers/sections/CustomersContracts";
import {CustomersIntegration} from "@/views/ReferenceInfo/Customers/sections/CustomersIntegration";
import {CustomersTitleDeed} from "@/views/ReferenceInfo/Customers/sections/CustomersTitleDeed";
import {CustomersRoles} from "@/views/ReferenceInfo/Customers/sections/CustomersRoles";
import {CustomersPaper} from "@/views/ReferenceInfo/Customers/sections/CustomersPaper";
import {ParticipantMD} from "@/models";
import { ChangeHistorySection } from "@/views/ComposedLot/edit/tabs/change-history/ChangeHistorySection";

type ViewSubjectClassProps = {
    id?: string | null
};

export const CustomersView: React.FC<ViewSubjectClassProps> = x => {
    const [customers, setCustomers] = useState<ParticipantMD>();
    useEffect(() => {
        if (x.id) getParticipantsById(x.id).then(setCustomers);
    }, []);


    const header = <VStack spacing="15px">
        <FormHeader title={`Информация о заказчике - ${x.id}`}/>
    </VStack>;

    const tabs = [Form.Tab("customer", "Информация о заказчике", [
            Form.Section("Юридические реквизиты", <CustomersLegalRequisites info={customers!} />),
            Form.Section("Реквизиты заказчика", <CustomersCustomersDetails info={customers!}/>),
            Form.Section("Правопреемственность", <CustomersSuccession />),
            Form.Section("Статистические коды", <CustomersStatisticalCodes />),
            Form.Section("Контактная информация", <CustomersContactInformation info={customers!} />),
            Form.Section("Адреса поставки", <CustomersDeliveryAddresses />),
            Form.Section("График работы", <CustomersSchedule />)]),
        Form.Tab("info", "Общие сведения", [
            Form.Section("Контактные лица", <CustomersContactFaces />),
            Form.Section("Банковские реквизиты", <CustomersBankDetails />),
            Form.Section("Контракты со специализированной организацией", <CustomersContracts />),
            Form.Section("Интеграции", <CustomersIntegration />),
            Form.Section("Правоустанавливающий документ", <CustomersTitleDeed />),
            Form.Section("Роли", <CustomersRoles />),
            Form.Section("Бумажные способы определения поставщика по 44-ФЗ", <CustomersPaper />),
            Form.Section("История изменений", <ChangeHistorySection />)
        ]),
    ];

    return <>
        {
            customers
                ? <Form header={header} tabs={tabs} />
                : <></>
        }
    </>;
};