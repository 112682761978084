import CardModal from "@/components/CardModal.vue";
import { ModalVue, ModalResult } from "@/view-models/ModalRequest";
import { Component, Prop } from "vue-property-decorator";
import { File } from '@/models/Documents';

@Component({ components: { CardModal } })
export default class ContractDocumentModal extends ModalVue<File | null, File> {
    @Prop() private prop!: File | null;

    public file = this.prop || null;

    public get name() { return this.file ? this.file.comment : "" }
    public set name(v: string) {
        if (this.file)
            this.file.comment = v;
    }

    public onFileSelect(e: Event) {
        const input = e.target as HTMLInputElement;

        if (input.files && input.files.length === 1) {
            this.file = new File(null!, input.files[0].size, input.files[0].name, input.files[0].name);
        }

        input.files = null;
    }

    public saveFile() {
        this.$emit("close-modal", ModalResult.ok(this.file));
    }
}
