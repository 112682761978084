import React from "react";
import {Form, FormHeader, FormState, Section} from "@/components/form";
import {actionToState, FormAction} from "@/views/ComposedLot/shared/store";
import {Expandee, HStack, VStack} from "@/components/layouts";
import {Button, Error} from "@/components/primitive";
import {InfoSection} from "@/views/Procedures2020/view/sections/infoSection/InfoSection";
import {Store} from "effector";
import {ProcedureForm, procedureToTemplate, replaceProcedure} from "@/views/Procedures2020/view/store";
import {SectionProps} from "@/views/Procedures2020/view/store";
import {DocumentsRequestsSection} from "@/views/Procedures2020/view/sections/documentsSection/DocumentsRequestsSection";
import {LotsSection} from "@/views/Procedures2020/view/sections/lotsSection/LotsSection";
import {useErrorHandler} from "@/reactUtils";
import {useMappedStore} from "@/storeUtils";
import {ProcedureStatus, ProcedureStatusStrings} from "@/models/Procedures2020/Procedure";
import {
    confirmPublishProcedure,
    createProcedure, deleteProcedure, dismissProcedure,
    finishTrade,
    generateProcedureDocument, publishProcedure, revokeFromGrbs, sendProcedureToFO,
    sendProcedureToGrbs, setPublishTerms,
    updateProcedure
} from "@/api/Procedures2020";
import router from "@/router";
import {Relation} from "@/components/Relation";
import {
    createContractRelation,
    createLimitRelation,
    createLotRelation
} from "@/views/Procedures2020/shared/relations";
import {LawType} from "@/models/enums";
import {ProviderSelectionType} from "@/models/ComposedLots";
import {MkrRequestsSection} from "@/views/Procedures2020/view/sections/mkrRequests/MkrRequestsSection";
import {AsuGfExchangeSection} from "@/views/Procedures2020/view/sections/asuGfExchange/AsuGfExchangeSection";
import {ChangesHistorySection} from "@/views/Procedures2020/view/sections/changesHistory/ChangesHistorySection";
import {EtpExchangeSection} from "@/views/Procedures2020/view/sections/etpExchange/EtpExchangeSection";
import {TimeLimitationSection} from "@/views/Procedures2020/view/sections/timeLimitationSection/TimeLimitationSection";
import {simulateDownload} from "@/miscUtils";
import {showModal} from "@/EventBus";
import {ProcedurePublishDateModal} from "@/views/Procedures2020/view/sections/ProcedurePublishDateModal";
import {PublishTerms} from "@/models/Procedures2020/PublishTerms";
import {uuid} from "@/models/parsing";
import {ConfirmationModal} from "@/views/Contracts/modals/ConfirmationModal";

const getTitle = (action: FormAction, status: ProcedureStatus, regNumber?: string, pt?: PublishTerms) => {
    if (action === "viewing") {
        if (regNumber) {
            if (status === "PUBLISH_PENDING" && pt?.publishDate) {
                return "Процедура - " + regNumber + " - " + "Установлены сроки размещения";
            } else {
                return "Процедура - " + regNumber + " - " + ProcedureStatusStrings[status];
            }
        } else {
            return "Процедура";
        }
    } else if (action === "creating") {
        return "Новая процедура";
    } else return "Редактирование процедуры";
};

const Relations = (x: { store: Store<ProcedureForm> }) => {
    const [id, subj, lots, contract] = useMappedStore(x.store, x => [
        x.head.id, x.info.subject, x.lots.lots, x.head.relatedContract
    ]);
    if (!id) return null;

    const tradeR: Relation<undefined> = {
        typeName: "Торги",
        typeNamePlural: "Торги",
        objects: [{
            item: undefined,
            name: subj ?? ""
        }],
        columns: [],
        open: () => router.push(`/procedures/2020/${id}/trade`)
    };

    return <HStack>
        <Relation relation={createLimitRelation(lots)}/>
        <Relation relation={createLotRelation(lots)}/>
        <Relation relation={tradeR}/>
        { contract && <Relation relation={createContractRelation(contract)}/> }
    </HStack>;
};

export const ViewProcedure = (x: { storeContainer: { store: Store<ProcedureForm> }; action: FormAction }) => {
    const [error, makeSafe] = useErrorHandler();

    const [id, regNumber, status, providerSelectionType, law, publishTerms, lots, totalPrice] = useMappedStore(x.storeContainer.store, x => [
        x.head.id,
        x.head.regNumber,
        x.head.status ?? "CREATING",
        x.info.providerSelectionType,
        x.head.law,
        x.timeLimitations.publishTerms,
        x.lots.lots,
        x.head.lotSpecPriceTotal
    ]);

    const sectionProps: SectionProps = {
        formStore: x.storeContainer.store,
        formAction: x.action
    };

    const header = <VStack spacing="15px">
        { x.action === "viewing" && <Relations store={x.storeContainer.store} /> }
        <Error object={error} />
        <FormHeader title={getTitle(x.action, status, regNumber, publishTerms)}/>
    </VStack>;

    const dismiss = (action: FormAction, id?: uuid) => {
        switch (action) {
            case "creating":
                router.replace("/procedures/2020");
                break;
            case "viewing":
                router.replace("/procedures/2020");
                break;
            case "editing":
                if (id) router.replace(`/procedures/2020/${id}`);
                break;
        }
    };

    const save = makeSafe(async () => {
        const state = x.storeContainer.store.getState();
        const template = procedureToTemplate(state);

        switch (x.action) {
            case "editing":
                const id = state.head.id;
                if (id) {
                    await updateProcedure(id, template);
                    router.replace("/procedures/2020/" + id).ignore;
                }
                break;
            case "creating":
                const newProcedure = await createProcedure(state.head.law, template);
                router.replace("/procedures/2020/" + newProcedure.id).ignore;
                break;
        }
    });

    const generateDoc = makeSafe(async () => {
        if (id) {
            await generateProcedureDocument(id);
            simulateDownload("Документация.zip", "data:application/zip;base64,UEsFBgAAAAAAAAAAAAAAAAAAAAAAAA==");
        }
    });

    const sendToGrbs = makeSafe(async () => {
        if (id) {
            const confirm = await showModal(ConfirmationModal, {title: "Подтверждение действия", text: "Вы уверены, что хотите отправить процедуру на согласование в РГ ГРБС?"});
            if (confirm) {
                const procedure = await sendProcedureToGrbs(id);
                replaceProcedure(procedure);
            }
        }
    });

    const sendToFO = makeSafe(async () => {
        if (id) {
            const procedure = await sendProcedureToFO(id);
            replaceProcedure(procedure);
        }
    });

    const revokeGrbs = makeSafe(async () => {
        if (id) {
            const confirm = await showModal(ConfirmationModal, {title: "Подтверждение действия", text: "Вы уверены, что отозвать с рассмотрения ГРБС?"});
            if (confirm) {
                const procedure = await revokeFromGrbs(id);
                replaceProcedure(procedure);
            }
        }
    });

    const publish = makeSafe(async () => {
        if (id) {
            const procedure = await publishProcedure(id);
            replaceProcedure(procedure);
        }
    });

    const confirmPublish = makeSafe(async () => {
        if (id) {
            const procedure = await confirmPublishProcedure(id);
            replaceProcedure(procedure);
        }
    });

    const initializeDismiss = makeSafe(async () => {
        if (id) {
            const procedure = await dismissProcedure(id);
            replaceProcedure(procedure);
        }
    });

    const finish = makeSafe(async () => {
        if (id) {
            const procedure = await finishTrade(id);
            replaceProcedure(procedure);
        }
    });

    const deleteProcedureAction = makeSafe(async () => {
        if (id) {
            await deleteProcedure(id);
            router.replace("/procedures/2020");
        }
    });

    /** Calls ProcedurePublishDateModal, then sends POST setPublishTerms, takes updated procedure as a response and replaces it in store */
    const setPublishTermsModal = makeSafe(async () => {
        const result = await showModal(ProcedurePublishDateModal, {
            terms: publishTerms,
            providerSelectionType: providerSelectionType ?? "E_AUC",
            totalPrice: totalPrice,
            law: law
        });

        if (result && id) replaceProcedure(await setPublishTerms(id, result));
    });

    const navInset =
        x.action === "viewing"
            ? <HStack spacing="10px">
                {
                    lots[0]?.lot.basicInfo.providerSelectionType !== "E_QUOTATIONS_REQUEST"
                        &&
                        status !== "TRADE_FINISHED"
                        &&
                        <Button color="green" title="Документация" onClick={generateDoc}/>
                }
                {
                    status !== "TRADE_FINISHED" && <>
                        <Button color="green" title="Просмотреть и сформировать документацию" onClick={generateDoc}/>
                        <Button color="green" title="Сформировать и изменить документацию" onClick={generateDoc}/>
                    </>
                }
                {
                    (status === "TRADE_FINISHED" || status === "PUBLISHED") &&
                    <Button title={"внести изменения"} color={"green"} onClick={() => router.push(`/procedures/2020/${id}/edit`)} disabled={!id}/>
                }
                {
                    law === "F44" && <>
                        {status === "CREATING" && <Button color="green" title="На согласование в РГ ГРБС" onClick={sendToGrbs}/>}
                        {status === "GRBS_AGREEMENT_PENDING" && <Button color="green" title="Отозвать с рассмотрения РГ ГРБС" onClick={revokeGrbs}/>}
                        {((status === "PUBLISH_PENDING" && !publishTerms?.publishDate) || status === "FO_AGREED") && <Button color="green"
                                                                 title="Установить сроки публикации"
                                                                 onClick={setPublishTermsModal}/>}
                        {status === "GRBS_AGREED" && <Button color="green" title="Отправить на публикацию" onClick={publish}/>}
                        {(status === "PUBLISH_PENDING" && publishTerms?.publishDate) && <Button color="green" title="На согласование ФО" onClick={sendToFO}/>}
                        {(status === "FO_AGREED" && publishTerms?.publishDate) && <Button color="green" title="Опубликовать" onClick={confirmPublish}/>}
                        {status === "PUBLISHED" && <Button color="orange" title="Завершить торги (тест)" onClick={finish} />}
                    </>
                }
                {
                    law === "F223" && <>
                        {status === "CREATING" && <Button color="green" title="Отправить на публикацию" onClick={publish}/>}
                        {status === "PUBLISH_PENDING" && <Button color="green"
                                                                 title="Установить сроки публикации"
                                                                 onClick={setPublishTermsModal}/>}
                        {(status === "PUBLISH_PENDING" && publishTerms?.publishDate) && <Button color="green" title="Опубликовать" onClick={confirmPublish}/>}
                        {status === "PUBLISHED" && <Button color="orange" title="Завершить торги (тест)" onClick={finish} />}
                    </>
                }
                <Expandee/>
                {
                    (status !== "TRADE_FINISHED" && status !== "PUBLISHED") &&
                        <Button icon="aEdit" onClick={() => router.push(`/procedures/2020/${id}/edit`)} disabled={!id}/>
                }
                {
                    status === "PUBLISHED" &&
                        <Button color="red" onClick={initializeDismiss} title="Сформировать информацию об отмене" />
                }
                <Button color="red" icon="aClose" onClick={() => dismiss(x.action, id)}/>
                {status === "CREATING" && <Button color="red" icon="aDelete" onClick={() => deleteProcedureAction()}/>}
            </HStack>
            : <HStack>
                <Button icon="aSave" onClick={save}/>
                <Expandee/>
                <Button color="red" icon="aClose" onClick={() => dismiss(x.action, id)}/>
            </HStack>;

    const sections = (law: LawType, providerSelectionType?: ProviderSelectionType): Section[] => {
        /** filters viewingFull sections depending on ProcedureStatus */
        const filterViewing = (s: Section[], status: ProcedureStatus) =>
            !(status === "PUBLISH_PENDING" || status === "PUBLISHED" || status === "TRADE_FINISHED")
                ? s.filter(a => a.name !== "Сроки проведения")
                : s;

        /** every possible section in creating state */
        const creatingFull: Section[] = [
            Form.Section("Общие сведения", <InfoSection {...sectionProps}/>),
            Form.Section("Документация и прием заявок", <DocumentsRequestsSection {...sectionProps}/>),
            Form.Section("Лоты", <LotsSection {...sectionProps}/>),
        ];

        /** every possible section in viewing/editing state */
        const viewingFull: Section[] = [
            Form.Section("Сроки проведения", <TimeLimitationSection {...sectionProps}/>),
            Form.Section("Обмен с АСУ ГФ", <AsuGfExchangeSection {...sectionProps}/>),
            Form.Section("Запросы в МКР", <MkrRequestsSection {...sectionProps}/>),
            Form.Section((law === "F44") ? "Обмен с ЕИС" : "Обмен с ЭТП", <EtpExchangeSection {...sectionProps}/>),
            Form.Section("История изменений", <ChangesHistorySection {...sectionProps}/>),
        ];

        const complete = x.action === "creating" ? creatingFull : [...creatingFull, ...filterViewing(viewingFull, status)];

        const noRequests = complete.filter(a => a.name !== "Документация и прием заявок");

        if (law === "F44") {
            if(providerSelectionType === "OPEN_AUC")
                return complete;
            else
                return noRequests;
        }
        else {
            if(providerSelectionType === "SINGLE_PROVIDER_PURCHASE")
                return noRequests;
            else
                return complete;
        }
    };

    const tab = Form.Tab("info", "Общие сведения", sections(law, providerSelectionType));

    return <FormState value={actionToState(x.action)}>
        <Form header={header} navInset={navInset} tabs={[tab]}/>
    </FormState>;
};

