import React, {useState} from "react";
import {IconButton, IconButtonProps} from "@/components/eshop/IconButton/IconButton";

import PencilIcon from "@/assets/icons/pencil-icon.svg";
import PlusIcon from "@/assets/icons/plus-icon.svg";
import GraphIcon from "@/assets/icons/graph-icon.svg";
import ShowFilterIcon from "@/assets/icons/filters-icon.svg";
import ResetFilterIcon from "@/assets/icons/reset-filters-icon.svg";
import RefreshIcon from "@/assets/icons/refresh-icon.svg";
import TrashIcon from "@/assets/icons/trash-icon.svg";
import DetailsIcon from "@/assets/icons/details-icon.svg";
import CopyIcon from "@/assets/icons/copy-icon.svg";
import ChevronIcon from "@/assets/icons/chevron-icon.svg";

type IconButtons = Omit<IconButtonProps, "icon">;

export const RefreshButton = (props: IconButtons) => {
    return (
        <IconButton icon={RefreshIcon}{...props} title={"Обновить"} />
    );
};

export const AddGraphButton = (props: IconButtons) => {
    return (
        <IconButton icon={GraphIcon}{...props} title={"Добавить график"} />
    );
};

export const PlusButton = (props: IconButtons) => {
    return (
        <IconButton icon={PlusIcon}{...props} title={"Добавить"} />
    );
};

export const RemoveButton = (props: IconButtons) => {
    return (
        <IconButton icon={TrashIcon}{...props} red title={"Удалить"} />
    );
};

export const ShowFiltersButton = (props: IconButtons) => {
    return (
        <IconButton icon={ShowFilterIcon}{...props} title={"Показать фильтры"} />
    );
};

export const ResetFiltersButton = (props: IconButtons) => {
    return (
        <IconButton icon={ResetFilterIcon}{...props} title={"Сбросить фильтры"} />
    );
};

export const DetailsButton = (props: IconButtons) => {
    return (
        <IconButton icon={DetailsIcon}{...props} title={"Показать характеристики"} />
    );
};

export const CopyButton = (props: IconButtons) => {
    return (
        <IconButton icon={CopyIcon}{...props} title={props.title ?? "Скопировать"} />
    );
};

export const ChevronButton = (props: IconButtons & { rotated: boolean }) => {

    const [rotated, setRotated] = useState(props.rotated);

    return (
        <IconButton icon={ChevronIcon}
                    onClick={e => {setRotated(!rotated); props.onClick?.(e)}}
                    style={{transform: rotated ?  "" : "rotate(180deg)"}} />
    );
};
