// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, "._3SMr-y95c4rWDV7tNztTnt {\n    width: 100%;\n    box-sizing: border-box;\n    height: 30px;\n}\n\n._1G-sz_p8JPaRuLcFWBsiBg {\n    box-sizing: border-box;\n    width: 100%;\n\n    resize: vertical;\n}\n\n._3SMr-y95c4rWDV7tNztTnt,\n._1G-sz_p8JPaRuLcFWBsiBg {\n    background-color: #fafafa;\n    border-width: 1px;\n    border-style: solid;\n    border-color: #b2b2b2 #e2e2e2 #e2e2e2;\n\n    padding: 6px 10px;\n    font-size: 14px;\n    line-height: 19px;\n    vertical-align: middle;\n    margin: 0;\n    background-image: none;\n    border-radius: 0;\n    transition: border-color 0.15s ease-in-out 0.3s;\n    box-shadow: inset 0 1px 1px #e1e1e1 !important;\n    font-family: \"Roboto\", Helvetica, Arial, sans-serif;\n}\n\n._3SMr-y95c4rWDV7tNztTnt:disabled,\n._1G-sz_p8JPaRuLcFWBsiBg:disabled {\n    background-color: #eee;\n}\n\n._3SMr-y95c4rWDV7tNztTnt:focus,\n._1G-sz_p8JPaRuLcFWBsiBg:focus {\n    border-color: #66afe9;\n    outline: 0;\n}\n", ""]);
// Exports
exports.locals = {
	"input": "_3SMr-y95c4rWDV7tNztTnt",
	"textarea": "_1G-sz_p8JPaRuLcFWBsiBg"
};
module.exports = exports;
