import { IJsonFormattable } from '@/models/IJsonFormattable';
import { IDtoContractResponsiblePerson } from "@/models/json/Contracts/IDtoContractResponsiblePerson";

export class ContractResponsiblePerson implements IJsonFormattable<IDtoContractResponsiblePerson> {
    constructor(
        public id: string,
        public firstName: string,
        public lastName: string,
        public patronymic: string,
        public email: string,
        public phone: string,
        public isMain: boolean
    ) {}
    
    public toJson(): IDtoContractResponsiblePerson {
        return {
            id: this.id,
            firstName: this.firstName,
            lastName: this.lastName,
            patronymic: this.patronymic,
            email: this.email,
            phone: this.phone,
            isMain: this.isMain,
        };
    }
    
    public static fromJson(dto: IDtoContractResponsiblePerson): ContractResponsiblePerson {
        return Object.assign(Object.create(ContractResponsiblePerson.prototype), {
            id: dto.id,
            firstName: dto.firstName,
            lastName: dto.lastName,
            patronymic: dto.patronymic,
            email: dto.email,
            phone: dto.phone,
            isMain: dto.isMain,
        });
    }
}
