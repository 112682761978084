import React from "react";
import { Card } from "@/components/Card";
import { Field, Fields } from "@/components/form/Field";
import { Provision } from "@/models/Provision/Provision";
import { Label } from "@/components/primitive";
import { VStack } from "@/components/layouts";
import { formatNumber } from "@/NumberFormatting";

type ConclusionSectionProps = {
    provision?: Provision
};

export const ConclusionSection = ({ provision }: ConclusionSectionProps) => {
    return (
        <Card>
            <VStack spacing="15px">
                <Fields orientation="horizontal" style={{ gridTemplateColumns: "6fr 8fr" }}>
                    <Field title="Возможность отказа от заключеия договора с победителем в случае существенного изменения обстоятельств, из которых он исходил при проведении процедуры закупки"
                           preset="readonlyValue">
                        {provision?.possibilityOfRefusal ? "Да" : "Нет"}
                    </Field>
                    <Field title="Возможность внесения в ручном режиме информации о договорах, заключенных с юридическими лицами"
                           preset="readonlyValue">
                        {provision?.possibilityOfChangeInfo ? "Да" : "Нет"}
                    </Field>
                </Fields>
                <Label preset="boldSmall" style={{ fontSize: 14 }}>
                    Возможность заключения договора с ед. поставщиком по итогам несостоявшейся закупки
                </Label>
                <Fields orientation="horizontal" style={{ gridTemplateColumns: "6fr 8fr" }}>
                    <Field title="Конкурс" preset="boldSmall">
                        <div></div>
                    </Field>
                    <Field title="Аукцион" preset="boldSmall">
                        <div></div>
                    </Field>
                    <Field title="Максимальная цена котировочной сессии, руб.:"
                           preset="readonlyValue">
                        {provision?.maxPriceQuotationSession
                            ? formatNumber(provision.maxPriceQuotationSession)
                            : "Ограничение не задано"}
                    </Field>
                </Fields>
            </VStack>
        </Card>
    );
};
