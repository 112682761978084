import Decimal from "decimal.js";

export type uuid = string;

export const asDecimal = <S extends string | undefined | null>(s: S):
    Decimal | (S extends null | undefined ? undefined : never) =>
    s !== null && s !== undefined ? new Decimal(s as string) : undefined as never;
export const asDate = <S extends string | undefined | null>(s: S):
    Date | (S extends null | undefined ? undefined : never) =>
    s !== null && s !== undefined ? new Date(s as string) : undefined as never;
export const applyN = <T, R, S extends T | undefined | null>(m: (_: T) => R, s: S):
    R | (S extends null | undefined ? undefined : never) =>
    s !== null && s !== undefined ? m(s!) : undefined as never;

declare const dtoTSymbol: unique symbol;
declare const tSymbol: unique symbol;

export type MDMap<T, DtoT> = { readonly [dtoTSymbol]: DtoT; readonly [tSymbol]: T };
export interface MDSerializer<T, DtoT> {
    toDto(t: T): DtoT
}
export interface MDDeserializer<T, DtoT> {
    fromDto(dtoT: DtoT): T
}
export type MDMapper<T, DtoT> = MDSerializer<T, DtoT> & MDDeserializer<T, DtoT>;

type UndefinedToNull<T> =
    T extends undefined ? T | null : T;

type NullToUndefined<T> =
    T extends null ? Exclude<T, null> | undefined : T;

type Dtoize<T> =
    T extends MDMap<infer NonDtoT, infer DtoT>
        ? DtoT | UndefinedToNull<Exclude<T, MDMap<NonDtoT, DtoT>>>
        : T extends Array<infer A>
            ? Array<Dtoize<A>> | UndefinedToNull<Exclude<T, Array<A>>>
            : T extends Date
                ? string | UndefinedToNull<Exclude<T, Date>>
                : T extends Decimal
                    ? string | UndefinedToNull<Exclude<T, Decimal>>
                    : UndefinedToNull<T>;

type Modelize<T> =
    T extends MDMap<infer NonDtoT, infer DtoT>
        ? NonDtoT | NullToUndefined<Exclude<T, MDMap<NonDtoT, DtoT>>>
        : T extends Array<infer A>
            ? Array<Modelize<A>> | NullToUndefined<Exclude<T, Array<A>>>
            : NullToUndefined<T>;

export type AutoDto<T> = {
    readonly [key in keyof T]: Dtoize<T[key]>;
};

export type AutoModel<T> = {
    readonly [key in keyof T]: Modelize<T[key]>;
};