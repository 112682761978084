// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, "._3lcSErlma2_TpSUKhqIWIP {\n    border-width: 0 1px 1px;\n    border-style: solid;\n    border-color: transparent #ccd1de #dadfea;\n    background-color: #f7f8fa;\n    border-radius: 3px 3px 0 0;\n    box-shadow: 0 1px 1px 0 rgba(54,71,96,.21);\n    padding: 9px 15px;\n}\n\n._3jmmPBmOpQwZ4tmr_DfjpN {\n    border: 1px solid transparent;\n    padding: 7px;\n    font-size: 12px;\n    border-radius: 3px;\n    cursor: pointer;\n    user-select: none;\n}\n\n._3jmmPBmOpQwZ4tmr_DfjpN._1JA_8eWyRb8TJ9UEhD2M_t, ._3jmmPBmOpQwZ4tmr_DfjpN:hover {\n    background: #d5dae6;\n    border-color: #aaaeb7 #c7cbd7 #d5dae6;\n}", ""]);
// Exports
exports.locals = {
	"navPanel": "_3lcSErlma2_TpSUKhqIWIP",
	"navButton": "_3jmmPBmOpQwZ4tmr_DfjpN",
	"selected": "_1JA_8eWyRb8TJ9UEhD2M_t"
};
module.exports = exports;
