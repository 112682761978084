import React, {ReactNode} from "react";
import styles from "./FieldSetWrapper.module.css";
import {TooltipIcon} from "@/components/TooltipIcon";

interface FieldSetWrapperProps {
    label?: string
    withTooltip?: boolean
    children: ReactNode
    required?: boolean
}

export const FieldSetWrapper = (props: FieldSetWrapperProps) => {
    return (
        <div className={styles.fieldSetWrapper__wrapper}>
            <fieldset className={styles.fieldSetWrapper__fieldset}>
                {props.label &&
                    <legend className={styles.fieldSetWrapper__label}>
                        {props.label}
                        {props.required ? <span style={{ color: "red" }}>*</span> : ''}
                        {props.withTooltip && <TooltipIcon />}
                    </legend>
                }
                {props.children}
            </fieldset>
        </div>
    );
};
