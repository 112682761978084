import { IDtoSubjectClass } from "@/models/json";
import { IJsonFormattable } from "@/models/IJsonFormattable";
import { Okpd2 } from "@/models";
import { NoValue } from './Common';

export class SubjectClass implements IJsonFormattable<IDtoSubjectClass> {
    constructor(
        public id: number,
        public code: string,
        public description: string,
        public okpd2: Okpd2 | null,
        public projectCheck: boolean,
        public parents: Array<{ id: number; code: string; name: string }>
    ) {}

    public toJson(): IDtoSubjectClass {
        return { ...this, okpd2: this.okpd2 ? this.okpd2.toJson() : null };
    }

    public toString() {
        return this.code + " " + this.description;
    }

    public toLongString() {
        return this.code + " " + this.parents.map(x => `${x.name}/`) + this.description;
    }

    // noinspection JSUnusedGlobalSymbols
    public static fromJson(dto: IDtoSubjectClass): SubjectClass {
        if (!dto) return null!; // todo REMOVE

        return Object.assign(Object.create(SubjectClass.prototype), dto, {
            okpd2: dto.okpd2 ? Okpd2.fromJson(dto.okpd2) : null,
        });
    }
}
