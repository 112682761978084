





























































































































































    import ContractClaimWorks from "@/api/ContractClaimWorks";
    import CardModal from "@/components/CardModal.vue";
    import DataTable from "@/components/vue/DataTable.vue";
    import {makeFormHelpers} from "@/form-declarations/helpers";
    import {
        ChargeSource,
        ChargeType,
        ChargeTypeStrings,
        ClaimWorkInitiator,
        ClaimWorkReason,
        ClaimWorkReasonStrings,
        ContractDocumentStatusStrings,
    } from "@/models/enums/Contracts";
    import AddDocument from "@/views/CreateContract/AddDocument.vue";
    import {ContractScope} from "@/views/CreateContract/ContractScope";
    import {Component, Prop, Vue, Watch} from "vue-property-decorator";
    import {ContractClaimWork, ContractDocument} from "../../models/Contracts";
    import {ObjectStatus, SelectOption} from "../../models/enums";
    import {ModalResult} from "../../view-models/ModalRequest";
    import EventBus from "@/EventBus";
    import {formatDate} from "@/DateFormatting";
    import {ITableColumn} from "@/components/TableAbstractions";
    import ErrorModal from './modals/ErrorModal.vue';

@Component({ components: { DataTable, CardModal } })
export default class AddClaim extends Vue {
    @Prop() private prop!: { claimWork: ContractClaimWork | null; contractId: string; scope: ContractScope };

    public creatingFirst = !this.prop.claimWork;

    public source = new ContractClaimWork(
        this.prop.claimWork ? this.prop.claimWork.id : null!,
        this.prop.claimWork ? this.prop.claimWork.status : ObjectStatus.CREATING,
        this.prop.claimWork ? this.prop.claimWork.initiator : ClaimWorkInitiator.CUSTOMER,
        this.prop.claimWork ? this.prop.claimWork.startDate : null!,
        this.prop.claimWork ? this.prop.claimWork.stage : null!,
        this.prop.claimWork ? this.prop.claimWork.chargeType : null!,
        this.prop.claimWork ? this.prop.claimWork.chargeSource : ChargeSource.NONE,
        this.prop.claimWork ? this.prop.claimWork.reason : null!,
        this.prop.claimWork ? this.prop.claimWork.assessedCharge : null!,
        this.prop.claimWork ? this.prop.claimWork.endDate : null!,
        this.prop.claimWork ? this.prop.claimWork.paidCharge : null!,
        this.prop.claimWork ? this.prop.claimWork.documents : [],
    );

    public hasPledgeProvision = !!this.prop.scope.executionProvision.find(x => x.source === ChargeSource.PLEDGE);
    public hasGuaranteeProvision = !!this.prop.scope.executionProvision.find(x => x.source === ChargeSource.BANK_GUARANTEE);
    public hasProvision = this.hasPledgeProvision || this.hasGuaranteeProvision;

    public get creating() { return this.source.status === ObjectStatus.CREATING }

    public async pickFile(src: ContractDocument | null) {
        const props = {
            document: src,
            documentGroup: "claimWork",
            readonly: !this.creating,
            noPost: !this.source.id,
            createAction: async (doc: ContractDocument) => ContractClaimWorks.createDocument(this.source, doc)
        };
        const result = await EventBus.callModal<typeof props, ContractDocument>(AddDocument, props);
        if (result.isOk) {
            const r = result.getResult();
            if (!src) {
                this.source.documents.push(r);
            } else {
                const ix = this.source.documents.findIndex(x => x.id === r.id);
                this.source.documents.splice(ix, 1, r);
            }
        }
    }

    public async deleteFile(src: ContractDocument) {
        if (this.source.id)
            await ContractClaimWorks.deleteDocument(this.source, src);

        const i = this.source.documents.indexOf(src);

        if (i >= 0) {
            this.source.documents.splice(i, 1);
        }
    }

    public async sendFile(src: ContractDocument) {
        const r = await ContractClaimWorks.sendDocument(this.source, src);

        const ix = this.source.documents.findIndex(x => x.id === r.id);
        this.source.documents.splice(ix, 1, r);
    }

    public async makeChanges() {
        this.source = await ContractClaimWorks.makeChanges(this.source);

        const ix = this.prop.scope.claimWorks.findIndex(x => x.id === this.source.id);
        this.prop.scope.claimWorks.splice(ix, 1, this.source);
    }

    public loading = false;

    public async saveAndClose() {
        const modelErrors = [];
        if (!this.source.startDate)
            modelErrors.push("Поле \"Дата начала претензионной работы\" обязательное, необходимо заполнить.");

        if (!this.source.chargeType)
            modelErrors.push("Поле \"Тип взыскания\" обязательное, необходимо заполнить.");

        if (!this.source.reason)
            modelErrors.push("Поле \"Основание для проведения\" обязательное, необходимо заполнить.");

        if (!this.source.stage)
            modelErrors.push("Поле \"Этап контракта\" обязательное, необходимо заполнить.");

        if (!this.source.assessedCharge)
            modelErrors.push("Поле \"Размер начисленной неустойки\" обязательное, необходимо заполнить.");

        if (modelErrors.length > 0) {
            EventBus.callModal(ErrorModal, { errors: modelErrors });
            return;
        }

        if (!this.source.documents.find(x => x.type.id === 166 || x.type.id === 167)) { // hi hardcode
            EventBus.callModal(ErrorModal, {
                errors: [
                    "В сведения о претензионной работе необходимо добавить " +
                    "хотя бы один документ-основание (тип документа \"Письмо\" " +
                    "или \"Решение суда\")"
                ]
            });
            return;
        }

        if (this.source.paidCharge &&
            this.source.paidCharge.gt(0) &&
            !this.source.documents.find(x => x.type.id === 168)) { // hi hardcode
            EventBus.callModal(ErrorModal, {
                errors: [
                    "В случае указания суммы взысканных средств в сведения " +
                    "о претензионной работе необходимо добавить хотя бы одно " +
                    "платежное поручение (тип документа \"Платежное поручение\")"
                ]
            });
            return;
        }

        /*В случае указания суммы взысканных средств в сведения о претензионной работе необходимо добавить хотя бы одно платежное поручение (тип документа "Платежное поручение") */

        this.loading = true;

        try {
            const src = this.source.id
                ? await ContractClaimWorks.update(this.source)
                : await ContractClaimWorks.create(this.prop.contractId, this.source);
            this.$emit("close-modal", ModalResult.ok(src));
        } finally {
            this.loading = false;
        }
    }

    public get stages(): SelectOption<string>[] {
        return [
            {
                key: null!,
                desc: "",
            },
            ...this.prop.scope.contract.newStages.map(x => ({
                key: x.id,
                desc: x.name,
            })),
        ];
    }

    public get chargeTypeOptions(): SelectOption<string>[] {
        return [
            {
                key: null!,
                desc: "",
            },
            ...Object.keys(ChargeTypeStrings).map(key => ({
                key,
                desc: ChargeTypeStrings[key as ChargeType],
            })),
        ];
    }

    public get reasonOptions(): SelectOption<string>[] {
        return [
            {
                key: null!,
                desc: "",
            },
            ...Object.keys(ClaimWorkReasonStrings).map(key => ({
                key,
                desc: ClaimWorkReasonStrings[key as ClaimWorkReason],
            })),
        ];
    }

    public get stage(): string | null {
        if (!this.source.stage) return null;

        const found = this.prop.scope.contract.newStages.find(stage => this.source.stage.id === stage.id);

        if (found) return found.id;

        return null;
    }

    public set stage(id: string | null) {
        this.source.stage = this.prop.scope.contract.newStages.find(stage => id === stage.id) || null!;
    }

    public enableSourceType = false;

    @Watch("enableSourceType") public resetSource() {
        if (!this.enableSourceType) {
            this.source.chargeSource = ChargeSource.NONE;
        }
    }

    public headers: ITableColumn<ContractDocument>[] = [
        {
            title: "№",
            getter: (p, i) => (i + 1).toString(),
            size: "auto",
        },
        {
            title: "Тип документа",
            getter: p => p.type.name,
        },
        {
            title: "Номер документа",
            getter: p => p.documentNumber,
        },
        {
            title: "Дата документа",
            getter: p => formatDate(p.date, true),
        },
        {
            title: "​Наименование документа",
            getter: p => p.file.comment,
        },
        {
            title: "​Статус отправки поставщику",
            getter: p => ContractDocumentStatusStrings[p.status],
        },
    ];
}
