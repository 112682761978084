import React, { useState } from "react";
import { CardModal } from "@/components/modals/CardModal";
import { ModalFC } from "@/EventBus";
import { Expandee, HStack, VStack } from "@/components/layouts";
import { Button, Label, Radio, RadioGroup } from "@/components/primitive";

export const ExportModal: ModalFC<void, void> = x => {
    const [action, setAction] = useState<"filter" | "date">("filter");

    const bottom: React.ReactNode = <HStack spacing="10px">
        <Expandee />
        <Button title="Экспорт" color="blue" onClick={() => x.done()} />
        <Button title="Закрыть" color="red" onClick={() => x.done()} />
    </HStack>;

    return <CardModal title="Экспорт"
                      width="1000px"
                      bottom={bottom}
                      close={() => x.done()}>
        <VStack spacing="10px">
            <Label preset="boldSmall">
                Выберите необходимое действие:
            </Label>
            <RadioGroup>
                    <Radio requiredValue="filter"
                           value={action}
                           onChange={setAction}>
                        Выгрузить КПГЗ с учетом применения фильтра или расширенного поиска
                    </Radio>
                <Radio requiredValue="date"
                       value={action}
                       onChange={setAction}>
                    Выгрузить КПГЗ, созданные после указанной даты и времени
                </Radio>
            </RadioGroup>
        </VStack>
    </CardModal>;
};