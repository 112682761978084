import React, {useState} from "react";
import {HStack, VStack} from "@/components/layouts";
import {Label} from "@/components/primitive";
import styles from "./StartPriceCalculator.module.css";
import {j} from "@/reactUtils";
import {Store} from "effector";
import {
    CalculatorStore
} from "@/views/ComposedLot/edit/start-price/store";
import {MarketPricesPage} from "@/views/ComposedLot/edit/start-price/MarketPricesPage";
import {CommonPage} from "@/views/ComposedLot/edit/start-price/CommonPage";
import {StartPrice} from "@/models/ComposedLots/specifications/StartPrice";
import {ScrollBox} from "@/components/ScrollBox";

export type OnCalculationDone = (startPrice: StartPrice | undefined) => void;

export const StartPriceCalculator = (x: { storeContainer: { store: Store<CalculatorStore> }; calculationDone: OnCalculationDone }) => {
    const [page, setPage] = useState<"common" | "market-prices">("common");

    return <ScrollBox>
        <VStack innerStyle={{padding: "30px"}} spacing="15px">
            <Label preset="pageTitle">Расчет НМЦ</Label>
            <HStack innerClassName={styles.navPanel}>
                <div className={j(styles.navButton, {[styles.selected]: page === "common"})}
                     onClick={() => setPage("common")}>
                    НМЦ СПГЗ
                </div>
                <div className={j(styles.navButton, {[styles.selected]: page === "market-prices"})}
                     onClick={() => setPage("market-prices")}>
                    Метод анализа рыночной стоимости закупаемых товаров, работ, услуг
                </div>
            </HStack>
            {
                page === "common" && <CommonPage store={x.storeContainer.store} calculateMarketPrice={() => setPage("market-prices")} save={x.calculationDone}/>
            }
            {
                page === "market-prices" &&
                    <MarketPricesPage store={x.storeContainer.store} returnBack={() => setPage("common")}/>
            }
        </VStack>
    </ScrollBox>;
};