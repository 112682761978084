// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, "._3TP06gwNiE67Hnn44T2-RQ {\n    display: grid;\n    grid-template-columns: 3fr 7fr;\n    border: 1px solid #e6e6e6;\n}\n\n._3TP06gwNiE67Hnn44T2-RQ > *:first-child {\n    border-right: 1px solid #e6e6e6;\n}\n\n._3TP06gwNiE67Hnn44T2-RQ > * {\n    padding: 30px 20px;\n}\n\n.CLZ1wpLYu6--w30N7_wzf {\n    display: flex;\n    justify-content: flex-start;\n    flex-flow: column;\n}\n\n.CLZ1wpLYu6--w30N7_wzf > *:not(:last-child) {\n    margin-bottom: 20px;\n}\n\n._2wWFq1iEnZ3Gme6nrm9rjO {\n    font-family: \"EaistFont\";\n    cursor: pointer;\n    user-select: none;\n}\n\n._2Z2PGDQRSMVoQqyKw_iFSB {\n    margin-left: 10px;\n    color: #999999;\n    text-align: end;\n    width: 200px;\n}\n\n.KwRR8PsABuSIj3ZpnZHat {\n    font-size: 24px;\n    color: #4d4d4d;\n    line-height: 32px;\n    overflow: hidden;\n    white-space: nowrap;\n    text-overflow: ellipsis;\n    max-width: 300px;\n}\n\n._311LfNYeQEYvfd7ke3Nkzf {\n    font-size: 12px;\n    color: #ff6666;\n}\n\n._2ZI7ZsiLEPc_khB6xJJjMg {\n    box-sizing: border-box;\n    display: flex;\n    width: 100%;\n    padding: 20px;\n    border-bottom: 1px solid #e6e6e6;\n    font-size: 16px;\n    transition: all 0.1s ease;\n}\n\n._2ZI7ZsiLEPc_khB6xJJjMg:hover {\n    background: #f8fafb;\n}", ""]);
// Exports
exports.locals = {
	"card": "_3TP06gwNiE67Hnn44T2-RQ",
	"titleBlock": "CLZ1wpLYu6--w30N7_wzf",
	"closeButton": "_2wWFq1iEnZ3Gme6nrm9rjO",
	"greyText": "_2Z2PGDQRSMVoQqyKw_iFSB",
	"title": "KwRR8PsABuSIj3ZpnZHat",
	"warning": "_311LfNYeQEYvfd7ke3Nkzf",
	"role": "_2ZI7ZsiLEPc_khB6xJJjMg"
};
module.exports = exports;
