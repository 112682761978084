import { IJsonFormattable } from "@/models/IJsonFormattable";
import { IDtoSingleProviderReason } from './json/IDtoSingleProviderReason';

export class SingleProviderReason implements IJsonFormattable<IDtoSingleProviderReason> {
    constructor(public id: number, public name: string) {}

    public toJson(): IDtoSingleProviderReason {
        return { ...this };
    }

    // noinspection JSUnusedGlobalSymbols
    public static fromJson(dto: IDtoSingleProviderReason): SingleProviderReason {
        return Object.assign(Object.create(SingleProviderReason.prototype), dto);
    }
}
