import React from "react";
import {SectionProps} from "@/views/Procedures2020/view/store";
import {useMappedStore} from "@/storeUtils";
import {Column, Table} from "@/components/table";
import {Card} from "@/components/Card";
import {VStack} from "@/components/layouts";

/** Обмен с ЭТП */
export const EtpExchangeSection: React.FC<SectionProps> = x => {

/*
    Номер
    Исходящий файл
    Дата отправки
    Конфирм от ЭТП
    Дата приема
    Сведения о публикации
    Дата приема
    Конфирм
    Дата отправки
*/
    const columns: Column<undefined>[] = [
        Table.Column("Номер", a => <div></div>, {width: "1fr"}),
        Table.Column("Исходящий файл", a => <div></div>, {width: "2fr"}),
        Table.Column("Дата отправки", a => <div></div>, {width: "2fr"}),
        Table.Column("Конфирм от ЭТП", a => <div></div>, {width: "2fr"}),
        Table.Column("Дата приема", a => <div></div>, {width: "1fr"}),
        Table.Column("Сведения о публикации", a => <div></div>, {width: "1fr"}),
        Table.Column("Дата приема", a => <div></div>, {width: "1fr"}),
        Table.Column("Конфирм", a => <div></div>, {width: "1fr"}),
        Table.Column("Дата отправки", a => <div></div>, {width: "1fr"}),
    ];

    const sectionStore = useMappedStore(x.formStore, x => ({

    }));


    return <Card>
        <VStack spacing="15px">
            <Table dataset={[]} columns={columns}/>
        </VStack>
    </Card>;
};