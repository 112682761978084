import {AutoDto, AutoModel} from "@/models/parsing";

interface FileMD {
    id: string
    name: string
    comment?: string
    length: number
}

export type File = AutoModel<FileMD>;
export type DtoFile = AutoDto<FileMD>;

export const File = {
    toDto(f: File): DtoFile {
        return {
            ...f
        };
    },
    fromDto(f: DtoFile): File {
        return {
            ...f,
            comment: f.comment ?? undefined
        };
    },
};