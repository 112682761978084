import {createEffect, createEvent, createStore} from "effector";
import {createStoredRemoteDataset, StoredRemoteDataset} from "@/components/table";
import {FilterConditionType, FilterValueType} from "@/api/http";
import { User } from "@/models/User";
import Admin, {getUsers} from "@/api/Admin";
import EventBus, {showModal} from "@/EventBus";
import {ConfirmationModal} from "@/views/Contracts/modals/ConfirmationModal";
import AddUserModal from "@/views/AddUserModal.vue";
import router from "@/router";

export type UserFilters = {
    any: string
    username: string
};

export interface UsersState {
    users: StoredRemoteDataset<User, UserFilters>
    loaded: boolean
}

export const createUsersStore = () =>{
    const store = createStore<UsersState>({
        users: createStoredRemoteDataset<User, UserFilters>({
            async load(filters, from, count) {
                return getUsers(from, count, {
                    any: [{
                        type: FilterValueType.ANY,
                        conditionType: FilterConditionType.CONTAINS,
                        string: filters.any
                    }],
                    username: [{
                        type: FilterValueType.STRING,
                        conditionType: FilterConditionType.CONTAINS,
                        string: filters.username
                    }],
                });
            }
        }),
        loaded: false
    });

    return store;
};
